// core
import React, {
  useRef, useEffect, useMemo,
} from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import { push } from 'connected-react-router';

// lodash
import {
  isEmpty, map, includes, split,
} from 'lodash';

// UI
import {
  Badge,
  Box, CircularProgress,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover as MuiPopover,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Warning as WarningIcon } from '@material-ui/icons';
import {
  Bell,
} from 'react-feather';

// config
import selectors from '../engine/config/selectors';

// actions
import systemMessageActionsAsync from '../engine/core/systemMessage/saga/asyncAction';
import systemMessageActions from '../engine/core/systemMessage/action';
import pendingActions from '../engine/core/pendingActions/action';
import pendingTableActions from '../engine/core/pendingActions/table/action';
import helpersActions from '../engine/core/helpers/action';
import helpersActionsAsync from '../engine/core/helpers/saga/asyncAction';

// routes
import { pageLinks } from '../routes';
import accessList from '../engine/config/accessList';
import { useAccessList } from '../ui/_hooks/useAccessList';

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

const WarningError = styled(WarningIcon)`
  color: ${(props) => props.theme.palette.error.main};
`;

const Warning = styled(WarningIcon)`
  color: ${(props) => props.theme.palette.warning.light};
`;

const SuccessWarning = styled(WarningIcon)`
  color: ${(props) => props.theme.palette.success.light};
`;

const NotificationHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

function Notification(props) {
  const {
    item,
  } = props;
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(helpersActions.setIsOpenNotification(false));
    if (item.event === 'exportFinished') {
      dispatch(helpersActionsAsync.getDownloadExportAsync());
      return;
    }
    if (item?.relates?.type === 'pendingActions' && !isEmpty(item.relates?.items)) {
      dispatch(push(pageLinks.notifications.tasks));
      dispatch(pendingActions.setDisableReload(true));
      dispatch(pendingTableActions.setFilters([
        { columnName: 'id:typeRequest:in', value: item.relates?.items },
      ]));
      return;
    }
    dispatch(push(pageLinks.systemMessage.all));
    dispatch(systemMessageActions.setDisableReload(true));
    dispatch(systemMessageActions.setFilters([
      { columnName: 'id', value: [item.id] },
    ]));
  };

  const avatar = useMemo(() => {
    if (item?.priority === 1) return <WarningError />;
    if (item?.priority === 2) return <Warning />;
    return <SuccessWarning />;
  }, [item]);

  const text = useMemo(() => {
    if (includes(item.text, 'посиланням')) return `${split(item.text, 'посиланням')[0]} посиланням`;
    return item.text;
  }, [item]);

  return (
    <ListItem divider button onClick={() => handleClick()}>
      <ListItemAvatar>
        {avatar}
      </ListItemAvatar>
      <ListItemText
        primary={text}
        primaryTypographyProps={{
          variant: 'subtitle2',
          color: 'textPrimary',
        }}
      />
    </ListItem>
  );
}

Notification.propTypes = {
  item: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

function NotificationsDropdown(props) {
  const {
    userInfo, disableNotifications, isOpen,
    systemMessageAllList, pendingDownloadExport,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const ref = useRef(null);
  const accessSystemMessageList = useAccessList(accessList.systemMessage_list_get);

  useEffect(() => {
    if (userInfo && userInfo.id && !disableNotifications && accessSystemMessageList) {
      dispatch(systemMessageActionsAsync.getAllListAsync({
        entity: 'systemMessage',
        sortBy: 'createdAt',
        sortDir: 'desc',
        'isRead:typeRequest:=': 'false',
        'users.id': userInfo.id,
        limit: 10,
        page: 0,
      }));
    }
  }, [dispatch, userInfo, disableNotifications, accessSystemMessageList]);

  useEffect(() => {
    setInterval(() => {
      dispatch(pendingActions.setDisableNotifications(false));
    }, 600000);
  }, [dispatch]);

  const handleOpen = () => {
    dispatch(helpersActions.setIsOpenNotification(true));
  };

  const handleClose = () => {
    dispatch(helpersActions.setIsOpenNotification(false));
  };

  const handleAllAction = () => {
    dispatch(helpersActions.setIsOpenNotification(false));
    dispatch(push(pageLinks.systemMessage.all));
    dispatch(systemMessageActions.setDisableReload(true));
    dispatch(systemMessageActions.setFilters([
      { columnName: 'id', value: null },
      { columnName: 'users.id', value: userInfo.id },
    ]));
  };

  return (
    <>
      <Tooltip title={!pendingDownloadExport ? t('Message') : t('Wait! The file is being uploaded!')}>
        <IconButton
          color="inherit"
          ref={ref}
          {...!pendingDownloadExport ? {
            onClick: handleOpen,
          } : {}}
        >
          {!pendingDownloadExport
            ? <Indicator badgeContent={systemMessageAllList.toJS().length}><Bell /></Indicator>
            : <CircularProgress size={25} color="inherit" />}
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <NotificationHeader p={2}>
          <Typography variant="subtitle1" color="textPrimary">
            {!isEmpty(systemMessageAllList.toJS())
              ? t('Message')
              : t('There are no new messages')}
          </Typography>
        </NotificationHeader>
        {!isEmpty(systemMessageAllList.toJS()) && (
          <List disablePadding>
            {map(systemMessageAllList.toJS(), (item) => (
              <Notification
                key={item.id}
                item={item}
              />
            ))}
          </List>
        )}
        <Box p={1} display="flex" justifyContent="center">
          <Button size="small" onClick={handleAllAction}>
            {t('All messages')}
          </Button>
        </Box>
      </Popover>
    </>
  );
}

NotificationsDropdown.displayName = 'NotificationsDropdown';

NotificationsDropdown.propTypes = {
  userInfo: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  disableNotifications: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  systemMessageAllList: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingDownloadExport: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    userInfo: selectors.user.userInfo(state),
    systemMessageAllList: selectors.systemMessage.systemMessageAllList(state),
    disableNotifications: selectors.pendingActions.disableNotifications(state),
    isOpen: selectors.helpers.isOpenNotification(state),
    pendingDownloadExport: selectors.helpers.pendingDownloadExport(state),
  };
}

export default compose(
  connect(mapStateToProps, null),
)(NotificationsDropdown);
