// Helpers
import actionCreator from '../../_helpers/actionCreator';
// Types
import types from './types';

const permissionsGroupsActions = Object.freeze({
  setPermissionsGroupsData(data) {
    return actionCreator(types.SET_PERMISSIONS_GROUPS_DATA, data);
  },
  setPermissionsGroupById(data) {
    return actionCreator(types.SET_PERMISSIONS_GROUP_BY_ID, data);
  },
  setPermissionsGroupDataById(data) {
    return actionCreator(types.SET_PERMISSIONS_GROUP_DATA_BY_ID, data);
  },
  setDeletePermissionsGroup(data) {
    return actionCreator(types.SET_DELETE_PERMISSIONS_GROUP, data);
  },
  setIsModalOpen(data) {
    return actionCreator(types.SET_PERMISSIONS_GROUPS_IS_MODAL_OPEN, data);
  },
  setPermissionsData(data) {
    return actionCreator(types.SET_PERMISSIONS_DATA, data);
  },
});

export default permissionsGroupsActions;
