// core
import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import { Field, Form, reduxForm } from 'redux-form/lib/immutable';

// lodash
import { isNumber, isEmpty, map } from 'lodash';

// config
import selectors from '../../../engine/config/selectors';

// helpers
import { formFields } from '../helper/form';

// parts
import RenderTextField from '../../../ui/Form/RenderTextField';
import Modal from '../../../components/Modal/Modal';
import DialogActions from '../../../components/Modal/components/DialogActions';

// actions
import paymentReceiversActionAsync from '../../../engine/core/paymentReceivers/saga/asyncAction';
import paymentReceiversActions from '../../../engine/core/paymentReceivers/action';
import accessList from '../../../engine/config/accessList';
import { useAccessList } from '../../../ui/_hooks/useAccessList';

function AddPaymentReceiversModal(props) {
  const {
    getPaymentReceiversById,
    handleSubmit,
    isModalOpen,
    pendingPostPaymentReceivers,
    initialize,
    destroy,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const accessPut = useAccessList(accessList.paymentReceivers_put);
  const accessEdit = !isEmpty(getPaymentReceiversById) ? accessPut : true;

  const handleCloseModal = () => dispatch(paymentReceiversActions.setModalOpen(false));

  const handleSubmits = (formData) => {
    const jsonData = formData.toJSON();
    const checkId = !isEmpty(getPaymentReceiversById) && isNumber(getPaymentReceiversById.id);

    dispatch(paymentReceiversActionAsync[checkId ? 'putPaymentReceiversByIdAsync' : 'postPaymentReceiversAsync'](
      checkId ? { ...getPaymentReceiversById, ...jsonData } : { ...jsonData },
    ));
  };

  useEffect(() => {
    if (!isEmpty(getPaymentReceiversById)) {
      initialize({ ...getPaymentReceiversById });
    }
    if (isEmpty(getPaymentReceiversById)) destroy();
  }, [
    initialize, destroy, getPaymentReceiversById,
  ]);

  return (
    <Modal
      title={!isEmpty(getPaymentReceiversById) ? t('Change payment receiver') : t('New payment receiver')}
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
      displayDialogActions={false}
      maxWidth="xs"
    >
      <Form onSubmit={handleSubmit(handleSubmits)}>
        {map(Object.keys(formFields), (formField) => (
          <Field
            key={formField}
            name={formFields[formField]}
            id={formFields[formField]}
            label={t(formField)}
            margin="normal"
            variant="standard"
            my={2}
            fullWidth
            type="text"
            component={RenderTextField}
            disabled={!accessEdit}
          />
        ))}

        <DialogActions
          pending={pendingPostPaymentReceivers}
          handleCloseModal={handleCloseModal}
          visibleButtonSend={accessEdit}
        />
      </Form>
    </Modal>
  );
}

AddPaymentReceiversModal.displayName = 'AddPaymentReceiversModal';

AddPaymentReceiversModal.propTypes = {
  getPaymentReceiversById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  pendingPostPaymentReceivers: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  destroy: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
};

function mapStateToProps(state) {
  return {
    getPaymentReceiversById: selectors.paymentReceivers.getPaymentReceiversById(state),
    isModalOpen: selectors.paymentReceivers.isModalOpen(state),
    pendingPostPaymentReceivers: selectors.paymentReceivers.pendingPostPaymentReceivers(state),
  };
}

export default compose(
  reduxForm({
    form: 'AddPaymentReceiversModal',
  }),
  connect(mapStateToProps, null),
)(AddPaymentReceiversModal);
