const asyncTypes = Object.freeze({
  GET_SYSTEM_MESSAGE_ASYNC: 'GET_SYSTEM_MESSAGE_ASYNC',
  GET_SYSTEM_MESSAGE_INITIAL_STATE_ASYNC: 'GET_SYSTEM_MESSAGE_INITIAL_STATE_ASYNC',
  GET_SYSTEM_MESSAGE_BY_ID_ASYNC: 'GET_SYSTEM_MESSAGE_BY_ID_ASYNC',
  POST_SYSTEM_MESSAGE_ASYNC: 'POST_SYSTEM_MESSAGE_ASYNC',
  PUT_SYSTEM_MESSAGE_BY_ID_ASYNC: 'PUT_SYSTEM_MESSAGE_BY_ID_ASYNC',
  DELETE_SYSTEM_MESSAGE_ASYNC: 'DELETE_SYSTEM_MESSAGE_ASYNC',
  GET_SYSTEM_MESSAGE_ALL_LIST_ASYNC: 'GET_SYSTEM_MESSAGE_ALL_LIST_ASYNC',
  PATCH_SYSTEM_MESSAGE_BY_ID_ASYNC: 'PATCH_SYSTEM_MESSAGE_BY_ID_ASYNC',
});

export default asyncTypes;
