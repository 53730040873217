// Core
import { call, put } from 'redux-saga/effects';

// config
import { api } from '../../../../config/api';

// actions
import actions from '../../action';

export function* callGetTaxByIdWorker({ payload: id }) {
  const {
    setTaxById, setTaxDataById,
  } = actions;
  yield put(setTaxById({
    pending: true,
  }));

  const response = yield call(api.taxes.getTaxById, id);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    yield put(setTaxDataById(data));
  }

  yield put(setTaxById({
    pending: false,
  }));
}
