// Helpers
import actionCreator from '../../_helpers/actionCreator';
// Types
import types from './types';

const actions = Object.freeze({
  setDocumentTemplatesById(data) {
    return actionCreator(types.SET_DOCUMENT_TEMPLATES_BY_ID, data);
  },
  setDocumentTemplatesData(data) {
    return actionCreator(types.SET_DOCUMENT_TEMPLATES_DATA, data);
  },
  setCurrentPage(currentPage) {
    return actionCreator(types.SET_DOCUMENT_TEMPLATES_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return actionCreator(types.SET_DOCUMENT_TEMPLATES_FILTERS, filters);
  },
  setSorting(sorting) {
    return actionCreator(types.SET_DOCUMENT_TEMPLATES_SORTING, sorting);
  },
  setPageSize(pageSize) {
    return actionCreator(types.SET_DOCUMENT_TEMPLATES_PAGE_SIZE, pageSize);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_DOCUMENT_TEMPLATES_INITIAL_STATE, initialState);
  },
  setReload(reload) {
    return actionCreator(types.SET_DOCUMENT_TEMPLATES_RELOAD, reload);
  },
  setModalOpen(modalOpen) {
    return actionCreator(types.SET_DOCUMENT_TEMPLATES_MODAL_OPEN, modalOpen);
  },
  setDeleteDocumentTemplate(data) {
    return actionCreator(types.SET_DELETE_DOCUMENT_TEMPLATE, data);
  },
  setAutocompleteOptions(options) {
    return actionCreator(types.SET_DOCUMENT_TEMPLATES_AUTOCOMPLETE_OPTIONS, options);
  },
  setPendingAutocompleteOptions(pending) {
    return actionCreator(types.SET_PENDING_DOCUMENT_TEMPLATES_AUTOCOMPLETE_OPTIONS, pending);
  },
  setColumnWidths(data) {
    return actionCreator(types.SET_DOCUMENT_TEMPLATES_COLUMN_WIDTHS, data);
  },
  setColumnOrder(data) {
    return actionCreator(types.SET_DOCUMENT_TEMPLATES_COLUMN_ORDER, data);
  },
  setHiddenColumnNames(data) {
    return actionCreator(types.SET_DOCUMENT_TEMPLATES_HIDDEN_COLUMN_NAMES, data);
  },
  setOriginalFile(data) {
    return actionCreator(types.SET_DOCUMENT_TEMPLATES_ORIGINAL_FILE, data);
  },
});

export default actions;
