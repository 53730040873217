// Core
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { spacing } from '@material-ui/system';
import * as PropTypes from 'prop-types';

// ui
import {
  Box, Grid,
  Button, Divider as MuiDivider,
  Typography,
} from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import styled from 'styled-components';

// actions
import actions from '../../../engine/core/currencies/currencyRates/action';

// parts
import CurrencyRatesTable from './CurrencyRatesTable';
import CurrencyRateModal from './CurrencyRateModal';

// hooks
import { useCurrencyRates } from './_hooks/useCurrencyRates';
import selectors from '../../../engine/config/selectors';
import accessList from '../../../engine/config/accessList';
import { useAccessList } from '../../../ui/_hooks/useAccessList';

// styles
const Divider = styled(MuiDivider)(spacing);

function CurrencyRates(props) {
  const { isModalOpen } = props;
  useCurrencyRates();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const accessPost = useAccessList(accessList.currencyRates_post);

  const handleOpenModal = () => {
    dispatch(actions.setIsModalOpen(true));
  };

  return (
    <>
      <Helmet title={t('Courses')} />
      <Typography variant="h3" gutterBottom display="inline">
        {t('Courses')}
      </Typography>

      <Divider my={6} />

      {accessPost && (
        <Box mb={2}>
          <Grid item xs={12} sm={6} container alignItems="center">
            <Button
              variant="contained"
              color="secondary"
              ml={2}
              onClick={handleOpenModal}
            >
              {t('ADD')}
              <AddIcon />
            </Button>
          </Grid>
        </Box>
      )}

      <CurrencyRatesTable />

      {isModalOpen && (
        <CurrencyRateModal />
      )}
    </>
  );
}

CurrencyRates.displayName = 'CurrencyRates';

CurrencyRates.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    isModalOpen: selectors.currencyRates.isModalOpen(state),
  };
}

export default compose(
  connect(mapStateToProps, null),
)(CurrencyRates);
