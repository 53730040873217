// core
import React, { useCallback, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form/lib/immutable';

// lodash
import map from 'lodash/map';

// helpers
import helpersActionsAsync from '../../../../engine/core/helpers/saga/asyncAction';
import helpersActions from '../../../../engine/core/helpers/action';
import str2bool from '../../../_helpers/str2bool';
import { getValueFormatDate, formatDate } from '../../../../engine/_helpers/formatterDate';

// hooks
import { useEventsAutocompleteAsync } from '../../../_hooks/useEventsAutocompleteAsync';

// config
import selectors from '../../../../engine/config/selectors';
import { listTablesName } from '../../../../engine/config/listTablesName';
import globalStatusesAction from '../../../../engine/config/globalStatusesAction';

// library
import { formName } from './formName';

// parts
import Modal from '../../../../components/Modal/Modal';
import Select from '../../../Form/Select';
import FieldClient from '../../../Form/FieldsAutocomplete/FieldClient';
import FieldEmployee from '../../../Form/FieldsAutocomplete/FieldEmployee';
import { formatTableName } from '../СhosenCheckboxes/СhosenCheckboxes';
import FieldJurisdiction from '../../../Form/FieldsAutocomplete/FieldJurisdiction';
import DatePickers from '../../../Form/DatePickers';

const itemsSelect = [
  { name: <Trans>Yes</Trans>, value: 'true' },
  { name: <Trans>No</Trans>, value: 'false' },
];

const TableModal = ({
  isModal,
  handleModalClose,
  tableName,
  reloadTable,
  fieldsForModal,
  handleSubmit,
  handleSimpleMenuClose,
  initialize,
  checkboxes,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [pending, setPending] = useState(false);

  const {
    getAutocompleteLists,
    handleGetAsyncData,
  } = useEventsAutocompleteAsync(false);

  const objectStatuses = useSelector(selectors.helpers.getObjectStatuses);
  const allCompanies = useSelector(selectors.companiesTable.allCompanies);

  useEffect(() => {
    initialize({});
  }, []);// eslint-disable-line

  const fnReset = useCallback(() => {
    setPending(false);
    handleSimpleMenuClose(false);
    dispatch(reloadTable(true));
    dispatch(helpersActions.setCheckboxClear());
    handleModalClose(false);
  }, [dispatch, handleSimpleMenuClose, handleModalClose, setPending, reloadTable]);

  // Массовое изменение
  const handleSubmits = (dataImmutable) => {
    setPending(true);
    const data = dataImmutable.toJS();

    switch (tableName) {
      case listTablesName.trademarks:
      case listTablesName.industrial:
      case listTablesName.inventions:
      case listTablesName.utility:
      case listTablesName.pendingActions:

        dispatch(helpersActionsAsync.putMultipleAsync({
          body: {
            entityName: formatTableName(tableName),
            entityIds: [...checkboxes],
            fields: {
              status: data.status,
              clients: data.client?.id,
              attorneyId: data.person?.id,
              jurisdictions: data.jurisdictions?.id,
              ...data.company ? {
                company: data.company,
              } : {},
              ...data.boost ? {
                boost: str2bool(data.boost),
              } : {},
              ...data.eApplication ? {
                eApplication: str2bool(data.eApplication),
              } : {},
              ...data.finishAt ? {
                finishAt: getValueFormatDate(formatDate(data.finishAt), 'finishAt'),
              } : {},
              ...data.users ? {
                users: map(data.users, 'id'),
              } : {},
            },
          },
          callBack: fnReset,
        }));

        break;

      default:
        break;
    }
  };

  return (
    <Modal
      isModalOpen={isModal}
      handleCloseModal={() => handleModalClose(false)}
      pending={pending}
      handleSend={handleSubmit(handleSubmits)}
    >
      {map(fieldsForModal, (field, idx) => {
        const { name, type, label } = field;

        switch (tableName) {
          case listTablesName.trademarks:
          case listTablesName.industrial:
          case listTablesName.inventions:
          case listTablesName.utility:
          case listTablesName.pendingActions:
            switch (type) {
              case 'select':
                return (
                  <Field
                    key={idx.toString()}
                    name={name}
                    labelId={label}
                    label={label}
                    component={Select}
                    items={objectStatuses.toJS()}
                    margin="normal"
                    fullWidth
                    displayEmpty
                    textEmpty={t('Do not change')}
                  />
                );
              case 'selectCompany':
                return (
                  <Field
                    key={idx.toString()}
                    name={name}
                    labelId={label}
                    label={label}
                    component={Select}
                    items={allCompanies.toJS()}
                    margin="normal"
                    fullWidth
                    displayEmpty
                    textEmpty={t('Do not change')}
                  />
                );

              case 'autocompleteClient':
                return (
                  <FieldClient
                    key={idx.toString()}
                    name={name}
                    label={label}
                    getAutocompleteLists={getAutocompleteLists}
                    getAsyncData={handleGetAsyncData}
                  />
                );

              case 'autocompleteEmployee':
                return (
                  <FieldEmployee
                    key={idx.toString()}
                    name={name}
                    label={label}
                    getAutocompleteLists={getAutocompleteLists}
                    getAsyncData={handleGetAsyncData}
                  />
                );

              case 'autocompleteJurisdictions':
                return (
                  <FieldJurisdiction
                    key={idx.toString()}
                    name={name}
                    label={label}
                    getAutocompleteLists={getAutocompleteLists}
                  />
                );

              case 'checkbox':
                return (
                  <Field
                    key={idx.toString()}
                    name={name}
                    labelId={label}
                    label={label}
                    component={Select}
                    displayEmpty
                    textEmpty={t('Do not change')}
                    items={itemsSelect}
                    margin="normal"
                    fullWidth
                  />
                );

              case 'selectAction':
                return (
                  <Field
                    key={idx.toString()}
                    name={name}
                    labelId={label}
                    label={label}
                    items={globalStatusesAction}
                    fullWidth
                    component={Select}
                  />
                );

              case 'employeeAction':
                return (
                  <FieldEmployee
                    key={idx.toString()}
                    name={name}
                    label={label}
                    getAutocompleteLists={getAutocompleteLists}
                    getAsyncData={handleGetAsyncData}
                    propsField={{
                      multiple: true,
                    }}
                  />
                );

              case 'date':
                return (
                  <Field
                    key={idx.toString()}
                    name={name}
                    labelId={label}
                    label={label}
                    fullWidth
                    component={DatePickers}
                  />
                );

              default:
                return null;
            }

          default:
            return null;
        }
      })}
    </Modal>
  );
};

TableModal.propTypes = {
  isModal: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  handleSimpleMenuClose: PropTypes.func.isRequired,
  tableName: PropTypes.string.isRequired,
  reloadTable: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  fieldsForModal: PropTypes.oneOfType([PropTypes.array]).isRequired,
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  checkboxes: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
};

export default compose(
  reduxForm({
    form: formName,
  }),
)(TableModal);
