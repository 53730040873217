// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import types from './types';

const actions = Object.freeze({
  setCalculationsData(calculationsData) {
    return actionCreator(types.SET_CALCULATIONS_DATA, calculationsData);
  },
  setCurrentPage(currentPage) {
    return actionCreator(types.SET_CALCULATIONS_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return actionCreator(types.SET_CALCULATIONS_FILTERS, filters);
  },
  setSorting(sorting) {
    return actionCreator(types.SET_CALCULATIONS_SORTING, sorting);
  },
  setColumnWidths(data) {
    return actionCreator(types.SET_CALCULATIONS_COLUMN_WIDTHS, data);
  },
  setColumnOrder(data) {
    return actionCreator(types.SET_CALCULATIONS_COLUMN_ORDER, data);
  },
  setHiddenColumnNames(data) {
    return actionCreator(types.SET_CALCULATIONS_HIDDEN_COLUMN_NAMES, data);
  },
  setPageSize(pageSize) {
    return actionCreator(types.SET_CALCULATIONS_PAGE_SIZE, pageSize);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_CALCULATIONS_INITIAL_STATE, initialState);
  },
  setReload(reload) {
    return actionCreator(types.SET_CALCULATIONS_RELOAD, reload);
  },
  setDeleteCalculation(data) {
    return actionCreator(types.SET_DELETE_CALCULATION, data);
  },
});

export default actions;
