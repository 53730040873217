// Core
import { call, put, select } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../config/api';
import selectors from '../../../../config/selectors';

// init
import i18n from '../../../../init/i18n';

// actions
import pendingActionsActions from '../../action';
import tableActions from '../../table/action';
import helpersActions from '../../../helpers/action';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../ui/_helpers/setNotificationMessage';

export function* callDeletePendingActionWorker() {
  const { setDeletePendingAction, setReload, setDisableNotifications } = pendingActionsActions;
  const { setModal } = helpersActions;

  yield put(setDeletePendingAction({
    pendingDelete: true,
  }));

  const pendingActionIdDelete = yield select(selectors.pendingActions.pendingActionIdDelete);
  const response = yield call(api.pendingActions.deletePendingAction, pendingActionIdDelete);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      yield put(setModal({
        isModalOpen: false,
      }));
      yield put(setDeletePendingAction({
        id: '',
      }));
      yield put(setReload(true));
      yield put(tableActions.setReload(true));
      yield put(setDisableNotifications(false));
      yield put(setSuccessMessage(i18n.t('Pending action deleted!'), response.status.toString()));
    }
  }

  yield put(setDeletePendingAction({
    pendingDelete: false,
  }));
}
