// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const systemMessageActionAsync = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_SYSTEM_MESSAGE_ASYNC, params);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_SYSTEM_MESSAGE_INITIAL_STATE_ASYNC, path);
  },
  getSystemMessageByIdAsync(id) {
    return actionCreator(asyncTypes.GET_SYSTEM_MESSAGE_BY_ID_ASYNC, id);
  },
  postSystemMessageAsync(data) {
    return actionCreator(asyncTypes.POST_SYSTEM_MESSAGE_ASYNC, data);
  },
  putSystemMessageByIdAsync(data) {
    return actionCreator(asyncTypes.PUT_SYSTEM_MESSAGE_BY_ID_ASYNC, data);
  },
  patchSystemMessageByIdAsync(data) {
    return actionCreator(asyncTypes.PATCH_SYSTEM_MESSAGE_BY_ID_ASYNC, data);
  },
  deleteSystemMessageAsync() {
    return actionCreator(asyncTypes.DELETE_SYSTEM_MESSAGE_ASYNC);
  },
  getAllListAsync(params) {
    return actionCreator(asyncTypes.GET_SYSTEM_MESSAGE_ALL_LIST_ASYNC, params);
  },
});

export default systemMessageActionAsync;
