// core
import React, { memo, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';

// lodash
import {
  isEmpty, concat, map, find, uniq,
  includes, without, filter, replace,
} from 'lodash';

// ui
import {
  Button as MuiButton,
  Checkbox as MuiCheckbox, Typography,
  FormControlLabel as MuiFormControlLabel, TextField,
} from '@material-ui/core';

// config
import selectors from '../../../../../engine/config/selectors';

// parts
import Loader from '../../../../../components/Loader';
import Modal from '../../../../../components/Modal/Modal';

// actions
import mktuTemplatesAction from '../../../../../engine/core/tradeMarks/mktuTemplates/action';
import proposalActions from '../../../../../engine/core/tradeMarks/proposal/action';
import proposalAsyncActions from '../../../../../engine/core/tradeMarks/proposal/saga/asyncAction';

const Button = styled(MuiButton)(spacing);

const Checkbox = styled(MuiCheckbox)`
  padding: 2px;
`;
const FormControlLabel = styled(MuiFormControlLabel)`
  margin-left: -5px;
`;

function AllTermsModal(props) {
  const {
    classById, searchMktuClass,
    isModalOpen, mktuClass,
    pendingGetClassById, selectMktuClasses,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [readyGetTerms, setReadyGetTerms] = useState(false);
  const [selectedTerms, setSelectedTerms] = useState([]);
  const [allClassTerms, setAllClassTerms] = useState([]);
  const [filterClassTerms, setFilterClassTerms] = useState([]);

  const handleCloseModal = () => {
    dispatch(mktuTemplatesAction.setModalAllTermsOpen(false));
    setSelectedTerms([]);
    setReadyGetTerms(false);
    dispatch(proposalActions.setClassById({}));
  };

  useEffect(() => {
    if (!isEmpty(mktuClass) && !isEmpty(allClassTerms) && isModalOpen) {
      const findMktuClass = find(selectMktuClasses.toJS(), { id: mktuClass.id });
      if (!isEmpty(findMktuClass)) {
        setSelectedTerms(findMktuClass.name === 'All'
          ? allClassTerms
          : findMktuClass.name.split('; '));
      }
    }
  }, [
    selectMktuClasses, mktuClass, allClassTerms,
    setSelectedTerms, isModalOpen,
  ]);

  useEffect(() => {
    if (!isEmpty(classById.toJS()) && !isEmpty(mktuClass)) {
      const headerTermsArray = mktuClass.label.split('; ');
      const classTermsArray = Object.values(classById.toJS());
      const findMktuClass = find(selectMktuClasses.toJS(), { id: mktuClass.id });
      const customTerms = isEmpty(findMktuClass) || findMktuClass.name === 'All'
        ? []
        : filter(findMktuClass.name.split('; '), (item) => !includes(classTermsArray, item));
      const allTerm = [...classTermsArray, ...customTerms].sort();
      setAllClassTerms(uniq(concat(headerTermsArray, allTerm)));
      setFilterClassTerms(uniq(concat(headerTermsArray, allTerm)));
    }
  }, [
    classById, setAllClassTerms, setFilterClassTerms,
    selectMktuClasses, mktuClass,
  ]);

  useEffect(() => {
    if (!isEmpty(searchMktuClass) && isEmpty(mktuClass)) {
      const classTermsArray = searchMktuClass.name.split('; ');
      const findMktuClass = find(selectMktuClasses.toJS(), { id: searchMktuClass.id });
      if (!isEmpty(findMktuClass)) {
        setSelectedTerms(findMktuClass.name === 'All'
          ? classTermsArray
          : findMktuClass.name.split('; '));
      }
      setAllClassTerms(uniq(classTermsArray));
      setFilterClassTerms(uniq(classTermsArray));
    }
  }, [
    searchMktuClass, setAllClassTerms, setFilterClassTerms,
    mktuClass, selectMktuClasses,
  ]);

  useEffect(() => {
    if (isModalOpen && !readyGetTerms && !isEmpty(mktuClass) && isEmpty(classById.toJS())) {
      setReadyGetTerms(true);
      dispatch(proposalAsyncActions.getClassByIdAsync({
        id: mktuClass.classNum,
        lang: 'ua',
      }));
    }
  }, [
    dispatch, mktuClass, classById, isModalOpen,
    readyGetTerms, setReadyGetTerms,
  ]);

  const handleSubmits = () => {
    if (selectedTerms.length) {
      const findMktuClass = !isEmpty(mktuClass)
        ? find(selectMktuClasses.toJS(), { id: mktuClass.id })
        : find(selectMktuClasses.toJS(), { id: searchMktuClass.id });
      const mktuClassWithTerms = {
        ...!isEmpty(mktuClass) ? mktuClass : searchMktuClass,
        name: selectedTerms.join('; '),
      };
      if (isEmpty(findMktuClass)) {
        dispatch(proposalActions.setSelectMktuClasses([
          ...selectMktuClasses,
          mktuClassWithTerms,
        ]));
      } else {
        dispatch(proposalActions.setSelectMktuClasses([
          ...selectMktuClasses.toJS().filter(
            (selectMktuClass) => (selectMktuClass.id !== findMktuClass.id),
          ),
          mktuClassWithTerms,
        ]));
      }
    }
    handleCloseModal();
  };

  const handleSelectedTerms = (checked, term) => {
    const trimTerm = replace(replace(term, '<strong>', ''), '</strong>', '');
    if (checked) {
      setSelectedTerms([
        ...selectedTerms,
        trimTerm,
      ]);
      return;
    }
    setSelectedTerms(without(selectedTerms, trimTerm));
  };

  const handleAddHeaderTerms = () => {
    const headerTermsArray = mktuClass.label.split('; ');
    setSelectedTerms(uniq(concat(headerTermsArray, selectedTerms)));
  };

  const handleChange = (e) => {
    const filteredClassTerm = filter(allClassTerms, (term) => includes(term, e.target.value));
    setFilterClassTerms(filteredClassTerm);
  };

  return (
    <Modal
      title={`${t('Terms')} ${mktuClass.classNum || searchMktuClass.classNum}${t('st class')}`}
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
      maxWidth="xs"
      handleSend={handleSubmits}
      dialogProps={{
        scroll: 'paper',
      }}
    >
      {!pendingGetClassById
        ? (
          <>
            <TextField
              onChange={handleChange}
              name="search"
              id="search"
              label={t('Search')}
              margin="normal"
              variant="standard"
              fullWidth
              type="text"
            />
            {!isEmpty(filterClassTerms) && !isEmpty(mktuClass) && (
              <Button
                my={2}
                variant="outlined"
                color="primary"
                size="small"
                onClick={handleAddHeaderTerms}
              >
                {t('Choose a title')}
              </Button>
            )}
            {!isEmpty(filterClassTerms) && map(filterClassTerms, (term, index) => {
              const trimTerm = replace(replace(term, '<strong>', ''), '</strong>', '');
              return (
                <div key={index}>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={includes(selectedTerms, trimTerm)}
                        onChange={(e) => {
                          handleSelectedTerms(e.target.checked, term);
                        }}
                      />
                    )}
                    label={(
                      <Typography dangerouslySetInnerHTML={{ __html: term }} />
                    )}
                  />
                </div>
              );
            })}
          </>
        )
        : <Loader /> }
    </Modal>
  );
}

AllTermsModal.displayName = 'AllTermsModal';

AllTermsModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  pendingGetClassById: PropTypes.bool.isRequired,
  mktuClass: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  searchMktuClass: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  classById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  selectMktuClasses: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

AllTermsModal.defaultProps = {
  mktuClass: {},
  searchMktuClass: {},
};

function mapStateToProps(state) {
  return {
    isModalOpen: selectors.mktuTemplates.isModalAllTermsOpen(state),
    pendingGetClassById: selectors.tradeMarksProposal.pendingGetClassById(state),
    classById: selectors.tradeMarksProposal.classById(state),
    selectMktuClasses: selectors.tradeMarksProposal.selectMktuClasses(state),
  };
}

export default compose(connect(mapStateToProps, null))(memo(AllTermsModal));
