// Core
import { List, Map } from 'immutable';

// Instruments
import types from './types';
import globalStateKeys from '../../../config/globalStateKeys';

export const initialState = Map({
  data: Map({
    items: List(),
  }),
  pending: false,
  itemByHashId: Map({
    data: Map(),
    pendingPut: false,
    pendingPost: false,
    pending: false,
  }),
  pendingDelete: false,
  isModalOpen: false,
  defaultName: '',
  newProductCategory: Map(),
});

export const stateKeys = Object.freeze({
  controller: 'productCategory',
  defaultName: 'defaultName',
  newProductCategory: 'newProductCategory',
});

export const productCategoryReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_PRODUCT_CATEGORY_DATA: {
      const { items } = payload;
      return state.setIn([globalStateKeys.data, globalStateKeys.items], List(items));
    }
    case types.SET_PENDING_PRODUCT_CATEGORY_DATA: {
      return state.setIn([globalStateKeys.pending], payload);
    }
    case types.SET_PENDING_DELETE_PRODUCT_CATEGORY: {
      return state.setIn([globalStateKeys.pendingDelete], payload);
    }
    case types.SET_PRODUCT_CATEGORY_BY_ID: {
      return state.mergeIn([globalStateKeys.itemByHashId], Map(payload));
    }
    case types.SET_PRODUCT_CATEGORY_IS_MODAL_OPEN: {
      return state.setIn([globalStateKeys.isModalOpen], payload);
    }
    case types.SET_PRODUCT_CATEGORY_DEFAULT_NAME: {
      return state.setIn([stateKeys.defaultName], payload);
    }
    case types.SET_NEW_PRODUCT_CATEGORY: {
      return state.setIn([stateKeys.newProductCategory], Map(payload));
    }
    default:
      return state;
  }
};
