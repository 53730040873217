// core
import React, { useEffect } from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';

// ui
import {
  Button, Divider as MuiDivider,
  Typography, Box,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import {
  Add as AddIcon,
} from '@material-ui/icons';

// actions
import helpersActions from '../../../engine/core/helpers/action';
import applicantsActions from '../../../engine/core/applicants/action';

// parts
import AddApplicantModal from '../../../components/AddApplicantModal/AddApplicantModal';
import ApplicantsTable from './ApplicantsTable';

import { useApplicants } from './_hooks/useApplicants';

// selectors
import selectors from '../../../engine/config/selectors';
import accessList from '../../../engine/config/accessList';
import { useAccessList } from '../../../ui/_hooks/useAccessList';

// styles
const Divider = styled(MuiDivider)(spacing);

function Applicants(props) {
  const {
    isModalOpenAddApplicant,
  } = props;
  useApplicants();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const accessPost = useAccessList(accessList.applicants_post);

  const handleOpenCLoseModalApplicant = () => {
    dispatch(helpersActions.setModal({ isModalOpenAddApplicant: true }));
  };

  useEffect(() => function cleanup() {
    dispatch(applicantsActions.setApplicantsList([]));
  }, [dispatch]);

  return (
    <>
      <Helmet title={t('Applicants')} />
      <Typography variant="h3" gutterBottom display="inline">
        {t('Applicants')}
      </Typography>
      <Divider my={6} />

      {accessPost && (
        <Box mb={2}>
          <Button variant="contained" color="primary" onClick={handleOpenCLoseModalApplicant}>
            {t('ADD')}
            <AddIcon />
          </Button>
        </Box>
      )}

      <ApplicantsTable />

      {isModalOpenAddApplicant && (
        <AddApplicantModal isTable />
      )}
    </>
  );
}

Applicants.displayName = 'Applicants';

Applicants.propTypes = {
  isModalOpenAddApplicant: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    isModalOpenAddApplicant: selectors.helpers.isModalOpenAddApplicant(state),
  };
}

export default compose(
  connect(mapStateToProps, null),
)(Applicants);
