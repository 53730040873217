// Core
import { all, call, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetClientsWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
} from './workers';
import types from '../types';

function* watchGetClients() {
  yield takeEvery(asyncTypes.GET_CLIENTS_ASYNC, callGetClientsWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_CLIENTS_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_CLIENTS_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_CLIENTS_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_CLIENTS_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_CLIENTS_SORTING, callSetToLocalDataWorker);
}
function* watchSetColumnWidths() {
  yield takeEvery(types.SET_CLIENTS_COLUMN_WIDTHS, callSetToLocalDataWorker);
}
function* watchSetColumnOrder() {
  yield takeEvery(types.SET_CLIENTS_COLUMN_ORDER, callSetToLocalDataWorker);
}
function* watchSetHiddenColumnNames() {
  yield takeEvery(types.SET_CLIENTS_HIDDEN_COLUMN_NAMES, callSetToLocalDataWorker);
}

export function* watchersClientsTable() {
  yield all([
    call(watchGetClients),
    call(watchSetCurrentPage),
    call(watchSetPageSize),
    call(watchSetFilters),
    call(watchSetSorting),
    call(watchGetInitialState),
    call(watchSetColumnWidths),
    call(watchSetColumnOrder),
    call(watchSetHiddenColumnNames),
  ]);
}
