// core
import React, {
  useState,
} from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';

// lodash
import { isEmpty } from 'lodash';

// config
import selectors from '../../../engine/config/selectors';

// parts
import Modal from '../../../components/Modal/Modal';
import AutocompleteGlobalSearch from '../../../ui/Form/AutocompleteGlobalSearch';

// actions
import actions from '../../../engine/core/email/email/action';

function LinkTheEntityModal(props) {
  const {
    isModalOpen, pendingPost, handleSend,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [caseValue, setCaseValue] = useState({});

  const handleCloseModal = () => {
    dispatch(actions.setIsModalOpenLinkEntity(false));
  };

  const handleChangeEntity = (item) => {
    setCaseValue(item);
  };

  const handleSubmits = () => {
    if (!isEmpty(caseValue)) handleSend(caseValue);
  };

  return (
    <Modal
      title={t('Tie the case')}
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
      pending={pendingPost}
      handleSend={handleSubmits}
    >
      <AutocompleteGlobalSearch
        label={t('Case')}
        handleChange={handleChangeEntity}
        helperText={t('Enter the № case, № application, № registration or name')}
        disableIcon
        disableClear
        entities={
          ['trademarks', 'utility', 'inventions', 'industrial']
        }
      />
    </Modal>
  );
}

LinkTheEntityModal.displayName = 'LinkTheEntityModal';

LinkTheEntityModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  pendingPost: PropTypes.bool.isRequired,
  handleSend: PropTypes.func,
};

LinkTheEntityModal.defaultProps = {
  handleSend: () => {},
};

function mapStateToProps(state) {
  return {
    isModalOpen: selectors.email.isModalOpenLinkEntity(state),
    pendingPost: selectors.email.pendingPostSignature(state),
  };
}

export default compose(
  connect(mapStateToProps, null),
)(LinkTheEntityModal);
