// Core
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

// lodash
import { isEmpty, map } from 'lodash';

// parts
import DxTable from '../../../ui/Table/DxTable';

// actions
import actions from '../../../engine/core/tradeMarks/rebounds/action';

// config
import selectors from '../../../engine/config/selectors';
import ConfirmModal from '../../../components/ConfirmModal';
import asyncAction from '../../../engine/core/tradeMarks/rebounds/saga/asyncAction';
import helpersActions from '../../../engine/core/helpers/action';

import { listTablesName, listEntityName } from '../../../engine/config/listTablesName';
import accessList from '../../../engine/config/accessList';

const tableName = listTablesName.trademarksMktu;
const entityName = listEntityName.mktu;

function ReboundsTable(props) {
  const {
    reboundsData, totalCount, pending,
    currentPage, filters, sorting,
    pageSize, pendingDeleteRebound, checkboxes,
    columnWidths, columnOrder, hiddenColumnNames,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const columns = [
    { name: 'id', title: t('ID') },
    { name: 'title', title: t('Title') },
    { name: 'classes', title: t('Classes') },
    { name: 'createdAt', title: t('Created At') },
    { name: 'clients', title: t('Client') },
    { name: 'trademarks', title: t('Trademark'), customField: 'documents' },
  ];

  const tableColumnExtensions = [
    { columnName: 'id', width: 50 },
    { columnName: 'title', width: '20rem' },
    { columnName: 'classes', width: '15rem' },
    { columnName: 'clients', width: '20rem' },
    { columnName: 'trademarks', width: '15rem' },
    { columnName: 'createdAt', width: '15rem' },
  ];

  // Paging
  const onCurrentPage = (page) => dispatch(actions.setCurrentPage(page));
  const onPageSize = (size) => dispatch(actions.setPageSize(size));
  // Filtering
  const setFilters = (getFilters) => dispatch(actions.setFilters(getFilters));
  // Sorting
  const onSortingChange = (getSorting) => dispatch(actions.setSorting(getSorting));
  const onColumnWidthsChange = (getColumnWidths) => dispatch(
    actions.setColumnWidths(getColumnWidths),
  );
  const onColumnOrderChange = (getColumnOrder) => dispatch(
    actions.setColumnOrder(getColumnOrder),
  );
  const onHiddenColumnNamesChange = (getHiddenColumnNames) => dispatch(
    actions.setHiddenColumnNames(getHiddenColumnNames),
  );

  return (
    <>
      <DxTable
        name="ReboundsTable"
        isLoading={pending}
        columns={columns}
        rows={reboundsData.toJS().items}
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageSize={onPageSize}
        onCurrentPage={onCurrentPage}
        filtersAction={setFilters}
        filters={filters}
        // checkboxes ------------------------
        tableName={tableName}
        entityName={entityName}
        reloadTable={actions.setReload}
        selection={checkboxes.toJS()}
        onSelectionChange={(checkboxSelect) => {
          dispatch(helpersActions.setCheckbox({
            tableName,
            checkboxes: checkboxSelect,
          }));
        }}
        // ------------------------
        sorting={sorting}
        onSortingChange={onSortingChange}
        tableColumnExtensions={!isEmpty(columnWidths) && columnWidths.length === columns.length
          ? columnWidths : tableColumnExtensions}
        onColumnWidthsChange={onColumnWidthsChange}
        columnOrder={!isEmpty(columnOrder) ? columnOrder : map(columns, 'name')}
        onColumnOrderChange={onColumnOrderChange}
        hiddenColumnNames={hiddenColumnNames}
        onHiddenColumnNamesChange={onHiddenColumnNamesChange}
        accessEdit={accessList.mktu_put}
        accessDelete={accessList.mktu_delete}
      />

      <ConfirmModal
        buttonSendText="DELETE"
        pending={pendingDeleteRebound}
        handleSend={() => {
          dispatch(asyncAction.deleteReboundAsync());
        }}
      />
    </>
  );
}

ReboundsTable.displayName = 'ReboundsTable';

ReboundsTable.propTypes = {
  reboundsData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  totalCount: PropTypes.number.isRequired,
  pending: PropTypes.bool.isRequired,
  pendingDeleteRebound: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  sorting: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  pageSize: PropTypes.number.isRequired,
  checkboxes: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  columnWidths: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  columnOrder: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  hiddenColumnNames: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
};

ReboundsTable.defaultProps = {};

function mapStateToProps(state) {
  return {
    reboundsData: selectors.reboundsTable.reboundsData(state),
    pending: selectors.reboundsTable.pending(state),
    pendingDeleteRebound: selectors.reboundsTable.pendingDeleteRebound(state),

    // Paging
    totalCount: selectors.reboundsTable.totalCount(state),
    currentPage: selectors.reboundsTable.currentPage(state),
    pageSize: selectors.reboundsTable.pageSize(state),

    // Filtering
    filters: selectors.reboundsTable.filters(state),

    // Sorting
    sorting: selectors.reboundsTable.sorting(state),

    // ColumnWidths
    columnWidths: selectors.reboundsTable.columnWidths(state),
    columnOrder: selectors.reboundsTable.columnOrder(state),
    hiddenColumnNames: selectors.reboundsTable.hiddenColumnNames(state),

    checkboxes: selectors.helpers.getCheckboxes(state, tableName),
  };
}

function mapDispatchToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(ReboundsTable);
