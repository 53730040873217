// Core
import { call, put } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import filter from 'lodash/filter';
import uniqBy from 'lodash/uniqBy';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import includes from 'lodash/includes';
import toLower from 'lodash/toLower';

// config
import { api } from '../../../../config/api';

// actions
import actions from '../../action';

// init
import i18n from '../../../../init/i18n';

// helpers
import { setErrorMessage } from '../../../../../ui/_helpers/setNotificationMessage';

export function* callPostAutocompleteGroupWorker({ payload }) {
  const {
    setAutocompleteGroupOptions, mergeAutocompleteGroupOFilter,
    setAutocompleteGroupFilter,
  } = actions;
  yield put(setAutocompleteGroupOptions({
    pendingPost: true,
  }));
  const {
    entities, returnFields, operators, isFilter, isEmail,
    fields, value, operator, body, groupByEntities,
  } = payload;

  const getFieldName = (key) => {
    switch (key) {
      case 'trademarks': {
        return 'trademarksId';
      }
      case 'utility': {
        return 'utilityId';
      }
      case 'inventions': {
        return 'inventionsId';
      }
      case 'industrial': {
        return 'industrialId';
      }
      case 'clients': {
        return 'clientsId';
      }
      case 'users': {
        return 'usersId';
      }
      case 'documents': {
        return 'documentsId';
      }
      case 'orders': {
        return 'ordersId';
      }
      default: {
        return '';
      }
    }
  };

  const checkIncludes = (valueResponse, searchValue) => (
    includes(toLower(valueResponse), toLower(searchValue)) ? valueResponse : ''
  );

  const optionsGroup = (items, valueSearch) => {
    const setGroups = map(items, (valueItems, key) => map(valueItems, (item) => {
      const registrationNumber = checkIncludes(item.registrationNumber, valueSearch);
      const applicationNumber = checkIncludes(item.applicationNumber, valueSearch);
      const internalTitle = checkIncludes(item.internalTitle, valueSearch);
      const caseNumber = checkIncludes(item.caseNumber, valueSearch);
      const email = checkIncludes(item.email, valueSearch);
      const phone = checkIncludes(item.phone, valueSearch);
      const inNumber = checkIncludes(item.inNumber, valueSearch);
      const outNumber = checkIncludes(item.outNumber, valueSearch);
      const orderNum = checkIncludes(item.orderNum, valueSearch);

      const getMatch = registrationNumber || applicationNumber || internalTitle || caseNumber
        || email || phone || inNumber || outNumber || orderNum || item.id;

      return {
        groupTitle: i18n.t(key),
        fieldName: getFieldName(key),
        entity: key,
        ...item,
        title: item.title || item.name ? `${item.title || item.name} (${getMatch})` : getMatch,
      };
    }));

    return reduce(setGroups, (result, v) => [...result, ...v], []);
  };

  const getOperator = (field) => {
    if (operator && isString(operator)) {
      return operator;
    }

    if (isObject(operators) && operators[field]) {
      return operators[field];
    }

    return 'or';
  };
  const response = yield call(api.search.postSearchAutocompleteGroup, body || {
    entities,
    request: map(fields, (field) => ({
      field,
      value,
      operator: getOperator(field),
    })),
    return: [
      'id', ...returnFields,
    ],
  });
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setAutocompleteGroupOptions({
        items: [],
      }));
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      const { items, totalCount } = data;
      if (isFilter) {
        yield put(mergeAutocompleteGroupOFilter(items));
        yield put(setAutocompleteGroupOptions({
          pendingPost: false,
        }));
        return;
      }
      if (isEmail) {
        yield put(setAutocompleteGroupFilter(items));
        yield put(setAutocompleteGroupOptions({
          pendingPost: false,
        }));
        return;
      }
      const groups = optionsGroup(items, value);
      const filterByRegistrationNumber = filter(groups, (group) => group.registrationNumber);
      const filterByApplicationNumber = filter(groups, (group) => group.applicationNumber);
      const filterByCaseNumber = filter(groups, (group) => group.caseNumber);
      const filterById = filter(groups, (group) => group.id);
      const removeDuplicates = uniqBy([
        ...filterByRegistrationNumber,
        ...filterByApplicationNumber,
        ...filterByCaseNumber,
        ...filterById,
      ], 'id');

      yield put(setAutocompleteGroupOptions({
        items: groupByEntities ? groups : removeDuplicates,
        totalCount,
      }));
    }
  }

  yield put(setAutocompleteGroupOptions({
    pendingPost: false,
  }));
}
