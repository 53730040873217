// Core
import { apply, select, put } from 'redux-saga/effects';

// Engine
import localData from '../../../../config/localData';
import selectors from '../../../../config/selectors';
import types from '../../types';
import actions from '../../action';

export function* callSetToLocalDataWorker({ type, payload }) {
  const pathname = yield select(selectors.router.pathname);
  const filters = yield select(selectors.dashboard.filters);
  const data = {
    filters: filters.toJS(),
  };

  let currentData;
  yield put(actions.setReload(true));
  switch (type) {
    case types.SET_DASHBOARD_FILTERS: {
      currentData = { filters: payload };
      break;
    }
    default: {
      break;
    }
  }

  yield apply(localData, localData.setItem, [pathname, { ...data, ...currentData }]);
}
