// core
import React, { useCallback, memo } from 'react';
import Paper from '@material-ui/core/Paper';
import * as PropTypes from 'prop-types';
import {
  Grid,
  VirtualTable, TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';

// lodash
import {
  isEqual,
} from 'lodash';

// parts
import TableCell from '../../ui/Table/components/TableCell/TableCell';
import Loader from '../../components/Loader';

function DxVirtualTable(props) {
  const {
    rows,
    columns,
    isLoading,
    tableColumnExtensions,
  } = props;

  const getRowId = useCallback((row) => row.id, []);
  const Root = (allProps) => <Grid.Root {...allProps} style={{ height: '100%' }} />;

  return (
    <Paper style={{ height: '100%' }}>
      {isLoading ? (
        <Loader />
      ) : (
        <Grid
          rows={rows}
          columns={columns}
          getRowId={getRowId}
          rootComponent={Root}
        >
          <VirtualTable
            columnExtensions={tableColumnExtensions}
            cellComponent={TableCell}
          />
          <TableHeaderRow />
        </Grid>
      )}
    </Paper>
  );
}

DxVirtualTable.defaultProps = {
  rows: [],
  columns: [],
  tableColumnExtensions: [],
  isLoading: false,
};
DxVirtualTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  tableColumnExtensions: PropTypes.oneOfType([PropTypes.array]),
};

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.rows, nextProps.rows)
  && isEqual(prevProps.columns, nextProps.columns)
  && isEqual(prevProps.isLoading, nextProps.isLoading)
  && isEqual(prevProps.tableColumnExtensions, nextProps.tableColumnExtensions);
}

export default memo(DxVirtualTable, areEqual);
