// Core
import {
  useCallback, useEffect, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

// actions
import asyncActions from '../../../engine/core/systemMessage/saga/asyncAction';

// config
import selectors from '../../../engine/config/selectors';
import { listEntityName } from '../../../engine/config/listTablesName';

// Hook
import { useParamsRequest } from '../../../ui/_hooks/useParamsRequest';

const entityName = listEntityName.systemMessage;

export function useSystemMessage() {
  const currentPage = useSelector(selectors.systemMessage.currentPage);
  const limit = useSelector(selectors.systemMessage.pageSize);
  const filters = useSelector(selectors.systemMessage.filters);
  const sorting = useSelector(selectors.systemMessage.sorting);
  const reload = useSelector(selectors.systemMessage.reload);
  const disableReload = useSelector(selectors.systemMessage.disableReload);
  const routeKey = useSelector(selectors.router.key);
  const path = useSelector(selectors.router.pathname);
  const dispatch = useDispatch();
  const { orderBy, paramsByFilter } = useParamsRequest({ filters, sorting, entityName });

  const getListAsync = useCallback(() => {
    const params = {
      ...paramsByFilter,
      ...orderBy,
      limit,
      page: currentPage + 1,
    };
    dispatch(asyncActions.getListAsync(params));
  }, [paramsByFilter, currentPage, orderBy, limit, dispatch]);

  const getInitialStateAsync = useCallback(() => {
    dispatch(asyncActions.getInitialStateAsync(path));
  }, [dispatch, path]);

  const getInitialState = useRef(true);

  useEffect(() => {
    if (getInitialState.current && !disableReload) {
      getInitialStateAsync();
      getInitialState.current = false;
    }

    if (reload) {
      getListAsync();
    }
  }, [getListAsync,
    getInitialStateAsync, disableReload, reload, routeKey]);
}
