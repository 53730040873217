export default Object.freeze({
  SET_CURRENCIES_DATA: 'SET_CURRENCIES_DATA',
  SET_CURRENCIES_RELOAD: 'SET_CURRENCIES_RELOAD',
  SET_CURRENCIES_CURRENT_PAGE: 'SET_CURRENCIES_CURRENT_PAGE',
  SET_CURRENCIES_FILTERS: 'SET_CURRENCIES_FILTERS',
  SET_CURRENCIES_SORTING: 'SET_CURRENCIES_SORTING',
  SET_CURRENCIES_PAGE_SIZE: 'SET_CURRENCIES_PAGE_SIZE',
  SET_CURRENCIES_INITIAL_STATE: 'SET_CURRENCIES_INITIAL_STATE',
  SET_DELETE_CURRENCY: 'SET_DELETE_CURRENCY',
  SET_CURRENCIES_AUTOCOMPLETE_OPTIONS: 'SET_CURRENCIES_AUTOCOMPLETE_OPTIONS',
  SET_PENDING_CURRENCIES_AUTOCOMPLETE_OPTIONS: 'SET_PENDING_CURRENCIES_AUTOCOMPLETE_OPTIONS',
  SET_CURRENCY_PENDING_POST: 'SET_CURRENCY_PENDING_POST',
  SET_CURRENCIES_IS_MODAL_OPEN: 'SET_CURRENCIES_IS_MODAL_OPEN',
  SET_CURRENCIES_COLUMN_WIDTHS: 'SET_CURRENCIES_COLUMN_WIDTHS',
  SET_CURRENCIES_COLUMN_ORDER: 'SET_CURRENCIES_COLUMN_ORDER',
  SET_CURRENCIES_HIDDEN_COLUMN_NAMES: 'SET_CURRENCIES_HIDDEN_COLUMN_NAMES',
  SET_PENDING_CURRENCY_CALC: 'SET_PENDING_CURRENCY_CALC',
  SET_CURRENCY_CALC: 'SET_CURRENCY_CALC',
});
