export default Object.freeze({
  SET_PROPOSAL_DOCUMENTS_IN_DATA: 'SET_PROPOSAL_DOCUMENTS_IN_DATA',
  SET_PROPOSAL_DOCUMENTS_IN_CURRENT_PAGE: 'SET_PROPOSAL_DOCUMENTS_IN_CURRENT_PAGE',
  SET_PROPOSAL_DOCUMENTS_IN_PAGE_SIZE: 'SET_PROPOSAL_DOCUMENTS_IN_PAGE_SIZE',
  SET_PROPOSAL_DOCUMENTS_IN_FILTERS: 'SET_PROPOSAL_DOCUMENTS_IN_FILTERS',
  SET_PROPOSAL_DOCUMENTS_IN_SORTING: 'SET_PROPOSAL_DOCUMENTS_IN_SORTING',
  SET_PROPOSAL_DOCUMENTS_IN_INITIAL_STATE: 'SET_PROPOSAL_DOCUMENTS_IN_INITIAL_STATE',
  SET_PROPOSAL_DOCUMENTS_IN_RELOAD: 'SET_PROPOSAL_DOCUMENTS_IN_RELOAD',
  SET_PROPOSAL_DOCUMENTS_IN_BY_ID: 'SET_PROPOSAL_DOCUMENTS_IN_BY_ID',
  SET_PENDING_DELETE_DOCUMENTS_IN: 'SET_PENDING_DELETE_DOCUMENTS_IN',
  SET_DELETE_DOCUMENTS_DATA_IN: 'SET_DELETE_DOCUMENTS_DATA_IN',
  SET_DOCUMENTS_IN_ENTITY: 'SET_DOCUMENTS_IN_ENTITY',
});
