// Core
import React, { memo, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import { push } from 'connected-react-router';

// lodash
import {
  isEmpty, filter, uniqWith, isEqual, map,
} from 'lodash';

// UI
import {
  Card as MuiCard, ButtonGroup,
  CardContent, Button,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';

// parts
import OutputDocumentTable from './components/OutputDocumentTable';
import InputDocumentTable from './components/InputDocumentTable';
import ConfirmModal from '../../../../components/ConfirmModal';
// import DocumentsModal from '../../../../components/DocumentsModal/DocumentsModal';
import TableEntitiesModal from '../../../../components/TableEntitiesModal/TableEntitiesModal';

// actions
import helpersActions from '../../../../engine/core/helpers/action';
import proposalAsyncActions from '../../../../engine/core/tradeMarks/proposal/saga/asyncAction';
import documentsOutAction from '../../../../engine/core/tradeMarks/proposal/documents/out/action';
import documentsInAction from '../../../../engine/core/tradeMarks/proposal/documents/input/action';
import depDocActions from '../../../../engine/core/departmentDocuments/action';

// config
import selectors from '../../../../engine/config/selectors';

// routes
import { pageLinks } from '../../../../routes';
import { useAccessList } from '../../../../ui/_hooks/useAccessList';
import accessList from '../../../../engine/config/accessList';

// styles
const Card = styled(MuiCard)(spacing);

function ProposalDocuments(props) {
  const {
    match, isModalOpenConfirm, isModalOpenTableEntities,
    documentsOutData, documentsInData, name, proposal,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { params } = match;
  const accessPost = useAccessList(accessList.documents_post);

  const handleGoToCreateDocumentPage = () => {
    if (!isEmpty(params) && !isEmpty(params.hashId)) {
      dispatch(helpersActions.setLastObject({ entityId: params.hashId, entityName: name }));
      dispatch(depDocActions.setDepartmentDocumentEntityList({ [name]: [proposal] }));
      dispatch(push(pageLinks.documents.departmentDocuments.new));
    } else {
      dispatch(helpersActions.setModal({ isModalOpen: true }));
    }
  };

  const handleSelectDocumentsModal = () => {
    if (!isEmpty(params) && !isEmpty(params.hashId)) {
      dispatch(helpersActions.setModal({ isModalOpenTableEntities: true }));
    } else {
      dispatch(helpersActions.setModal({ isModalOpen: true }));
    }
  };

  useEffect(() => {
    if (!isEmpty(proposal)) {
      dispatch(documentsInAction.setDocumentsInEntity(proposal));
    }
  }, [dispatch, proposal]);

  useEffect(() => {
    if (name && !isEmpty(params.hashId)) {
      dispatch(documentsOutAction.setDocumentsEntityName({
        id: params.hashId, name,
      }));
    }
  }, [dispatch, name, params]);

  useEffect(() => function cleanup() {
    dispatch(documentsOutAction.setDocumentsEntityName({ id: '', name: '' }));
    dispatch(documentsInAction.setDocumentsInEntity({}));
  }, [dispatch]);

  const handleSelectDocuments = (documents) => {
    const filterDocumentsOut = filter(documents, (items) => items.type === 'out');
    const filterDocumentsIn = filter(documents, (items) => items.type === 'in');
    const allDocumentsOut = uniqWith([
      ...documentsOutData.toJS().items,
      ...filterDocumentsOut,
    ], isEqual);
    const allDocumentsIn = uniqWith([
      ...documentsInData.toJS().items,
      ...filterDocumentsIn,
    ], isEqual);
    const allDocuments = uniqWith([
      ...documents,
      ...documentsInData.toJS().items,
      ...documentsOutData.toJS().items,
    ], isEqual);
    if (!isEmpty(documents)) {
      dispatch(proposalAsyncActions.postAddDocumentsAsync({
        id: Number(params.hashId),
        params: {
          ids: map(allDocuments, (item) => item.id),
        },
      }));
      dispatch(documentsOutAction.setProposalDocumentsOutData({
        items: allDocumentsOut,
        totalCount: allDocumentsOut.length,
        pending: false,
      }));
      dispatch(documentsInAction.setProposalDocumentsInData({
        items: allDocumentsIn,
        totalCount: allDocumentsIn.length,
        pending: false,
      }));
    }
    dispatch(helpersActions.setModal({ isModalOpenTableEntities: false }));
  };

  return !isEmpty(params.hashId) ? (
    <>
      {isModalOpenConfirm && (
        <ConfirmModal
          buttonSendText=""
          buttonCancelText="CLEARLY"
          description="You need to save the proposal!"
        />
      )}
      {isModalOpenTableEntities && (
        <TableEntitiesModal
          handleSend={handleSelectDocuments}
        />
      )}
      <Card mb={3}>
        <CardContent>
          <ButtonGroup aria-label="contained primary button group">
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSelectDocumentsModal}
            >
              {t('SELECT')}
            </Button>
            {accessPost && (
              <Button
                variant="outlined"
                color="primary"
                onClick={handleGoToCreateDocumentPage}
              >
                {t('CREATE')}
              </Button>
            )}
          </ButtonGroup>
        </CardContent>
      </Card>
      <InputDocumentTable match={match} name={name} />
      <OutputDocumentTable match={match} name={name} />
    </>
  ) : <div>Documents</div>;
}

ProposalDocuments.displayName = 'ProposalDocuments';

ProposalDocuments.propTypes = {
  match: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  name: PropTypes.string,
  proposal: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  documentsOutData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  documentsInData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  isModalOpenConfirm: PropTypes.bool.isRequired,
  isModalOpenTableEntities: PropTypes.bool.isRequired,
};

ProposalDocuments.defaultProps = {
  match: {},
  name: '',
  proposal: {},
};

function mapStateToProps(state) {
  return {
    documentsOutData: selectors.proposalDocumentsOut.proposalDocumentsOutData(state),
    documentsInData: selectors.proposalDocumentsIn.proposalDocumentsInData(state),
    isModalOpenConfirm: selectors.helpers.isModalOpen(state),
    isModalOpenTableEntities: selectors.helpers.isModalOpenTableEntities(state),
  };
}

function mapDispatchToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(memo(ProposalDocuments));
