// Helpers
import actionCreator from '../../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const proposalActionAsync = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_PROPOSAL_ASYNC, params);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_PROPOSAL_INITIAL_STATE_ASYNC, path);
  },
  getAutocompleteOptionsAsync(params) {
    return actionCreator(asyncTypes.GET_TRADE_MARKS_PROPOSAL_AUTOCOMPLETE_OPTIONS_ASYNC, params);
  },
  getRegistryByNumberAsync(params) {
    return actionCreator(asyncTypes.GET_REGISTRY_BY_NUMBER_ASYNC, params);
  },
  getClassesAsync(params) {
    return actionCreator(asyncTypes.GET_GET_CLASSES_ASYNC, params);
  },
  getClassByIdAsync(params) {
    return actionCreator(asyncTypes.GET_CLASS_BY_ID_ASYNC, params);
  },
  getFindCompanyAsync(params) {
    return actionCreator(asyncTypes.GET_FIND_COMPANY_ASYNC, params);
  },
  getMktuByIdAsync(params) {
    return actionCreator(asyncTypes.GET_MKTU_BY_ID_PROPOSAL_ASYNC, params);
  },
  postMktuTemplateAsync(params) {
    return actionCreator(asyncTypes.POST_MKTU_TEMPLATE_ASYNC, params);
  },
  postProposalAsync(params) {
    return actionCreator(asyncTypes.POST_PROPOSAL_ASYNC, params);
  },
  deleteProposalAsync() {
    return actionCreator(asyncTypes.DELETE_PROPOSAL_ASYNC);
  },
  postTradeMarksSignAsync(params) {
    return actionCreator(asyncTypes.POST_TRADEMARKS_SING_ASYNC, params);
  },
  getProposalByIdAsync(id) {
    return actionCreator(asyncTypes.GET_TRADEMARKS_PROPOSAL_BY_ID_ASYNC, id);
  },
  putProposalAsync(params) {
    return actionCreator(asyncTypes.PUT_PROPOSAL_ASYNC, params);
  },
  postCreateImageAsync(params) {
    return actionCreator(asyncTypes.POST_CREATE_IMAGE_ASYNC, params);
  },
  postAddDocumentsAsync(params) {
    return actionCreator(asyncTypes.POST_ADD_DOCUMENTS_ASYNC, params);
  },
  getNextNumberAsync(params) {
    return actionCreator(asyncTypes.GET_TRADEMARKS_PROPOSAL_NEXT_NUMBER_ASYNC, params);
  },
  putProposalCloneAsync(params) {
    return actionCreator(asyncTypes.PUT_TRADEMARKS_PROPOSAL_CLONE_ASYNC, params);
  },
});

export default proposalActionAsync;
