export default Object.freeze({
  SET_MONITORING_DATA: 'SET_MONITORING_DATA',
  SET_MONITORING_CURRENT_PAGE: 'SET_MONITORING_CURRENT_PAGE',
  SET_MONITORING_PAGE_SIZE: 'SET_MONITORING_PAGE_SIZE',
  SET_MONITORING_FILTERS: 'SET_MONITORING_FILTERS',
  SET_MONITORING_SORTING: 'SET_MONITORING_SORTING',
  SET_MONITORING_INITIAL_STATE: 'SET_MONITORING_INITIAL_STATE',
  SET_MONITORING_RELOAD: 'SET_MONITORING_RELOAD',
  SET_DELETE_MONITORING: 'SET_DELETE_MONITORING',
  SET_TABS: 'SET_TABS',
  SET_MONITORING_REPORT_DATA: 'SET_MONITORING_REPORT_DATA',
  SET_MONITORING_BY_ID: 'SET_MONITORING_BY_ID',
  SET_MONITORING_COLUMN_WIDTHS: 'SET_MONITORING_COLUMN_WIDTHS',
  SET_MONITORING_COLUMN_ORDER: 'SET_MONITORING_COLUMN_ORDER',
  SET_MONITORING_HIDDEN_COLUMN_NAMES: 'SET_MONITORING_HIDDEN_COLUMN_NAMES',
});
