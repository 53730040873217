// Core
import { apply, put } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../../config/api';

// actions
import actions from '../../action';
import { setErrorMessage } from '../../../../../../ui/_helpers/setNotificationMessage';

export function* callPostCreateImageWorker({ payload: params }) {
  yield put(actions.setPendingCreatedImage(true));
  const response = yield apply(api, api.trademarks.postCreateImage, [params]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      yield put(actions.setCreatedImage(data));
    }
  }
  yield put(actions.setPendingCreatedImage(false));
}
