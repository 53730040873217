// Core
import React from 'react';
import { useDispatch } from 'react-redux';

// actions
import tariffsActions from '../../engine/core/prices/tariffs/action';
import depDocsActions from '../../engine/core/departmentDocuments/action';
import docsTemActions from '../../engine/core/documentTemplates/action';

// reducers
import { initialState as tariffsInitialState } from '../../engine/core/prices/tariffs/reducer';
import { initialState as departmentDocumentsInitialState } from '../../engine/core/departmentDocuments/reducer';
import { initialState as documentTemplatesInitialState } from '../../engine/core/documentTemplates/reducer';

// config
import globalStateKeys from '../../engine/config/globalStateKeys';

export function useCleanUpDefaultFilters() {
  const dispatch = useDispatch();

  const setActions = React.useCallback((initialState, actions) => {
    const dataInitialState = initialState.get(globalStateKeys.data);

    dispatch(actions.setFilters(dataInitialState.get(globalStateKeys.filters).toJS()));
    dispatch(actions.setSorting(dataInitialState.get(globalStateKeys.sorting).toJS()));
    dispatch(actions.setCurrentPage(dataInitialState.get(globalStateKeys.currentPage)));
  }, [dispatch]);

  // tariffs
  const tariffsResetFilters = React.useCallback(() => {
    setActions(tariffsInitialState, tariffsActions);
  }, [setActions]);

  // Department Documents
  const departmentDocumentsResetFilters = React.useCallback(() => {
    setActions(departmentDocumentsInitialState, depDocsActions);
  }, [setActions]);

  // Document Templates
  const documentTemplatesResetFilters = React.useCallback(() => {
    setActions(documentTemplatesInitialState, docsTemActions);
  }, [setActions]);

  return {
    tariffsResetFilters,
    departmentDocumentsResetFilters,
    documentTemplatesResetFilters,
  };
}
