// Core
import { apply, put } from 'redux-saga/effects';

import { keys, has } from 'lodash';
// config
import { api } from '../../../../../config/api';
import { listEntityName } from '../../../../../config/listTablesName';

// actions
import actions from '../../action';

const entity = listEntityName.tariffs;

export function* callGetAutocompleteOptionsWorker({ payload: params }) {
  const name = keys(params)[0];
  const response = yield apply(api, api.prices.getTariffsAutocomplete, [{ ...params, entity }]);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(actions.setAutocompleteOptions({ [name]: [] }));
    } else {
      const { items } = data;
      yield put(actions.setAutocompleteOptions({ [name]: items }));
    }
  }
}
