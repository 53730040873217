const asyncTypes = Object.freeze({
  GET_PAYMENT_RECEIVERS_ASYNC: 'GET_PAYMENT_RECEIVERS_ASYNC',
  GET_PAYMENT_RECEIVERS_INITIAL_STATE_ASYNC: 'GET_PAYMENT_RECEIVERS_INITIAL_STATE_ASYNC',
  POST_PAYMENT_RECEIVERS_ASYNC: 'POST_PAYMENT_RECEIVERS_ASYNC',
  GET_PAYMENT_RECEIVERS_BY_ID_ASYNC: 'GET_PAYMENT_RECEIVERS_BY_ID_ASYNC',
  PUT_PAYMENT_RECEIVERS_BY_ID_ASYNC: 'PUT_PAYMENT_RECEIVERS_BY_ID_ASYNC',
  DELETE_PAYMENT_RECEIVERS: 'DELETE_PAYMENT_RECEIVERS',
});

export default asyncTypes;
