// core
import React, {
  useEffect, useState, useMemo,
} from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import {
  Field, Form, reduxForm, change,
} from 'redux-form/lib/immutable';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import { push } from 'connected-react-router';
import { Link as MuiLinkRouter } from 'react-router-dom';

// lodash
import {
  isEmpty, find, filter, isEqual, floor, divide,
  forEach, map, uniqWith, includes,
} from 'lodash';

// ui
import {
  Button, CardContent, Divider as MuiDivider, Grid as MuiGrid, Box,
  Typography, Card as MuiCard, Menu, Link as MuiLink, MenuItem, CircularProgress,
  ButtonGroup, Table, TableBody, TableCell as MuiTableCell, TableRow,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import {
  Add as AddIcon, NavigateBefore as NavigateBeforeIcon,
} from '@material-ui/icons';

// helpers
import { formName, fieldsAutocomplete } from './helper/form';
import { filterRemoveEntitiesInOrderById } from './helper/filterEntitiesInOrderById';
import { formatDate, getValueFormatDate, getObjectReverseFormatDate } from '../../../engine/_helpers/formatterDate';

// actions
import asyncActions from '../../../engine/core/company/orders/saga/asyncAction';
import ordersActions from '../../../engine/core/company/orders/action';
import chargesAsyncActions from '../../../engine/core/charges/saga/asyncAction';
import chargesActions from '../../../engine/core/charges/action';
import departmentDocumentsActionAsync from '../../../engine/core/departmentDocuments/saga/asyncAction';
import departmentDocumentsAction from '../../../engine/core/departmentDocuments/action';
import helpersActions from '../../../engine/core/helpers/action';
import helpersAsyncActions from '../../../engine/core/helpers/saga/asyncAction';
import currenciesAsyncAction from '../../../engine/core/currencies/currenciesList/saga/asyncAction';
import currenciesActions from '../../../engine/core/currencies/currenciesList/action';

// config
import selectors from '../../../engine/config/selectors';

// parts
import DxTable from '../../../ui/Table/DxTable';
import Loader from '../../../components/Loader';
import DatePickers from '../../../ui/Form/DatePickers';
import renderTextField from '../../../ui/Form/RenderTextField';
import AddChargesModal from '../../../components/AddChargesModal/AddChargesModal';
import ConfirmModal from '../../../components/ConfirmModal';
import TableEntitiesModal from '../../../components/TableEntitiesModal/TableEntitiesModal';
import AutocompleteGlobalSearch from '../../../ui/Form/AutocompleteGlobalSearch';
import Select from '../../../ui/Form/Select';
import DocViewerModal from '../../../components/DocViewerModal/DocViewerModal';
import ChipEntity from '../../../ui/Chip/ChipEntity';

// hooks
import { useSetFieldsAutocomplete } from '../../../ui/Form/FieldsAutocomplete/_hook/useSetFieldsAutocomplete';
import { useSetFieldAutocompleteByIdEntity } from '../../../ui/Form/FieldsAutocomplete/_hook/useSetFieldAutocompleteByIdEntity';

// routes
import { pageLinks } from '../../../routes';
import accessList from '../../../engine/config/accessList';
import { useAccessList } from '../../../ui/_hooks/useAccessList';

// styles
const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const Grid = styled(MuiGrid)(spacing);
const TypographySub = styled(Typography)`
  color: ${(props) => props.theme.palette.grey['500']};
`;
const Link = styled(MuiLink)`
border-bottom: 1px dashed;
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
`;
const LinkRouter = styled(MuiLinkRouter)`
  text-decoration: underline;
  color: #1976d2;
  display: flex;
  align-items: center;
`;
const TableCell = styled(MuiTableCell)`
  text-align: right;
  flex-direction: row-reverse;
  padding-right: 0;
  font-weight: bold;
`;

function Order(props) {
  const {
    initialize, handleSubmit, match,
    isModalOpenAddCharges, getOrderById, chargesData,
    pendingCharges, selectedCompany, allCompanies, currencyValues,
    documentsData, pendingDocuments, totalCountDocuments,
    pendingPutOrderById, pendingOrderById, pendingPostOrder,
    valid, getChargesIds, currenciesData, isModalOpenDocViewer,
    documentsOrder, currentPageDocuments, pageSizeDocuments, isModalOpenConfirm,
    isModalOpenTableEntities, lastObject, getNextNumber, entityList,
  } = props;
  const { t } = useTranslation();
  const [readyInitialize, setReadyInitialize] = useState(false);
  const dispatch = useDispatch();
  const [readyOrder, setReadyOrder] = useState(false);
  const [readyGetCharges, setReadyGetCharges] = useState(false);
  const [simpleMenu, setSimpleMenu] = useState(null);
  const [selectionCosts, setSelectionCosts] = useState([]);
  const [selectionDocuments, setSelectionDocuments] = useState([]);
  const [modalDescription, setModalDescription] = useState('');
  const [openModal, setOpenModal] = useState('documents');
  const [sumCosts, setSumCosts] = useState(0);
  const { params } = match;
  const accessPut = useAccessList(accessList.orders_edit);
  const accessChargesList = useAccessList(accessList.charges_list_get);
  const accessChargesPost = useAccessList(accessList.charges_post);
  const accessDocumentsTemplateList = useAccessList(accessList.documentsTemplate_list_get);
  const accessDocumentsList = useAccessList(accessList.documents_list_get);
  const accessDocumentsPost = useAccessList(accessList.documents_post);
  const accessCurrenciesList = useAccessList(accessList.currencies_list_get);
  const accessCompanyList = useAccessList(accessList.company_list_get);
  const accessEdit = !isEmpty(params) ? accessPut : true;

  useSetFieldAutocompleteByIdEntity({
    formName, params: lastObject.toJS(),
  });

  const { getEntitiesById, isReadyToUse } = useSetFieldsAutocomplete({
    initialize, data: getOrderById,
  });

  const toggleMenu = (event) => {
    setSimpleMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setSimpleMenu(null);
  };

  const handleAddCharge = () => {
    if (!isEmpty(entityList.toJS()) && !isEmpty(entityList.toJS().clients)) {
      dispatch(helpersActions.setModal({ isModalOpenAddCharges: true }));
    } else {
      dispatch(helpersActions.setModal({ isModalOpen: true }));
      setModalDescription('You need to choose a client!');
    }
  };

  const handleGoToCreateDocumentPage = () => {
    if (!isEmpty(params) && !isEmpty(params.hashId)) {
      dispatch(departmentDocumentsAction.setDepartmentDocumentEntityList({
        orders: [{ ...getOrderById, title: getOrderById.orderNum }],
      }));
      dispatch(push(pageLinks.documents.departmentDocuments.new));
      setTimeout(() => {
        dispatch(helpersActions.setLastObject({ entityId: params.hashId, entityName: 'orders' }));
      });
    } else {
      dispatch(helpersActions.setModal({ isModalOpen: true }));
      setModalDescription('You need to save the order!');
    }
  };

  const handleSelectDocumentsModal = () => {
    if (!isEmpty(params) && !isEmpty(params.hashId)) {
      setOpenModal('documents');
      dispatch(ordersActions.setDocumentsOrder(documentsData.toJS().items));
      dispatch(helpersActions.setModal({ isModalOpenTableEntities: true }));
    } else {
      dispatch(helpersActions.setModal({ isModalOpen: true }));
      setModalDescription('You need to save the order!');
    }
  };

  const handleDocumentTemplateModal = () => {
    if (!isEmpty(params) && !isEmpty(params.hashId)) {
      setOpenModal('documentTemplates');
      dispatch(helpersActions.setModal({ isModalOpenTableEntities: true }));
    } else {
      dispatch(helpersActions.setModal({ isModalOpen: true }));
      setModalDescription('You need to save the order!');
    }
  };

  const handleSubmits = (formData) => {
    const json = formData.toJS();

    const getIDSValues = () => ({
      [fieldsAutocomplete.clients]: entityList.toJS()[fieldsAutocomplete.clients]?.id || null,
      [fieldsAutocomplete.inventions]: entityList.toJS()[fieldsAutocomplete.inventions]?.id || null,
      [fieldsAutocomplete.trademarks]: entityList.toJS()[fieldsAutocomplete.trademarks]?.id || null,
      [fieldsAutocomplete.utility]: entityList.toJS()[fieldsAutocomplete.utility]?.id || null,
      [fieldsAutocomplete.industrial]: entityList.toJS()[fieldsAutocomplete.industrial]?.id || null,
    });
    if (!isEmpty(json)) {
      dispatch(asyncActions[!isEmpty(params) && !isEmpty(params.hashId)
        ? 'putOrderByIdAsync' : 'postOrderAsync']({
        ...json,
        ...!isEmpty(json.createdAt) ? {
          createdAt: getValueFormatDate(formatDate(json.createdAt), 'createdAt'),
        } : {},
        ...!isEmpty(documentsData.toJS()) && !isEmpty(documentsData.toJS().items) ? {
          documents: map(documentsData.toJS().items, 'id'),
        } : {},
        ...!isEmpty(chargesData.toJS()) && !isEmpty(chargesData.toJS().items) ? {
          charges: map(chargesData.toJS().items, 'id'),
        } : {},
        ...getIDSValues(),
        ...isEmpty(params) && !isEmpty(lastObject.toJS())
          && lastObject.toJS().entityName && lastObject.toJS().entityId ? {
            params: lastObject.toJS(),
          } : {},
      }));
    }
  };

  useEffect(() => {
    if (accessCurrenciesList) dispatch(currenciesAsyncAction.getListAsync());
  }, [dispatch, accessCurrenciesList]);

  useEffect(() => function cleanup() {
    dispatch(ordersActions.setDocumentsOrder([]));
    dispatch(ordersActions.setOrderById({ data: {} }));
    dispatch(chargesActions.setChargesData({
      items: [],
      totalCount: 0,
      pending: false,
    }));
    dispatch(departmentDocumentsAction.setDepartmentDocumentsData({
      items: [],
      totalCount: 0,
      pending: false,
    }));
    dispatch(helpersActions.setPredefinedInputs({}));
    dispatch(helpersActions.setLastObject({}));
    dispatch(helpersActions.setAutocompleteGroupOptions({ items: [] }));
    dispatch(ordersActions.setOrderEntityList({}));
    dispatch(currenciesActions.setCurrenciesData({
      items: [],
      totalCount: 0,
      pending: false,
    }));
  }, [dispatch]);

  useEffect(() => {
    if (isEmpty(params)) {
      dispatch(asyncActions.getNextNumberAsync({
        field: 'orderNum',
      }));
      dispatch(chargesActions.setChargesData({
        items: [],
        totalCount: 0,
        pending: false,
      }));
      dispatch(departmentDocumentsAction.setDepartmentDocumentsData({
        items: [],
        totalCount: 0,
        pending: false,
      }));
    }
  }, [
    dispatch, params,
  ]);

  useEffect(() => {
    if (!readyOrder && isEmpty(getOrderById) && !isEmpty(params) && !isEmpty(params.hashId)) {
      setReadyOrder(true);
      dispatch(asyncActions.getOrderByIdAsync(params.hashId));
      if (accessChargesList) dispatch(chargesAsyncActions.getListAsync({ 'orders.id': params.hashId }));
      if (accessCurrenciesList) {
        dispatch(departmentDocumentsActionAsync.getListAsync({
          'orders.id': params.hashId,
          limit: pageSizeDocuments,
          page: 1,
        }));
      }
    }
  }, [
    dispatch, getOrderById, params, accessCurrenciesList,
    readyOrder, setReadyOrder, pageSizeDocuments, accessChargesList,
  ]);

  useEffect(() => {
    if (!readyGetCharges && !isEmpty(getChargesIds.toJS())) {
      setReadyGetCharges(true);
      const chargesIds = !isEmpty(chargesData.toJS()) && !isEmpty(chargesData.toJS().items)
        ? map(chargesData.toJS().items, 'id')
        : [];
      dispatch(chargesActions.setChargesIds([]));
      if (!isEmpty(params.hashId)) {
        const listCharges = [
          ...chargesData.toJS().items,
          ...map(getChargesIds.toJS(), (id) => ({ id })),
        ];
        dispatch(chargesActions.setChargesData({
          items: listCharges,
          totalCount: listCharges.length,
          pending: false,
        }));
        setTimeout(() => handleSubmit());
      } else if (accessChargesList) {
        dispatch(chargesAsyncActions.getListAsync({
          Id: [
            ...getChargesIds.toJS(),
            ...chargesIds,
          ],
        }));
      }
    }
  }, [
    dispatch, getChargesIds, chargesData, params, accessChargesList,
    readyGetCharges, setReadyGetCharges, handleSubmit,
  ]);

  useEffect(() => {
    if (!readyInitialize
      && !isEmpty(getOrderById)
      && !isEmpty(params)
      && isReadyToUse) {
      setReadyInitialize(true);
      initialize({
        ...filterRemoveEntitiesInOrderById(getOrderById),
        ...getObjectReverseFormatDate(getOrderById.createdAt, 'createdAt'),
        ...!isEmpty(getOrderById.company) ? {
          company: getOrderById.company.id,
        } : {},
      });
      dispatch(ordersActions.setOrderEntityList(getEntitiesById));
    }
  }, [
    params, initialize, getOrderById,
    setReadyInitialize, getEntitiesById,
    readyInitialize, isReadyToUse, dispatch,
  ]);

  useEffect(() => {
    if (isEmpty(params) && !isEmpty(allCompanies.toJS())) {
      dispatch(change(formName, 'company', selectedCompany || 1));
    }
  }, [dispatch, selectedCompany, params, allCompanies]);

  useEffect(() => {
    if (!isEmpty(getNextNumber) && isEmpty(params)) {
      dispatch(change(formName, 'orderNum', getNextNumber));
      dispatch(ordersActions.setOrderNextNumber(''));
    }
  }, [
    getNextNumber, dispatch, params,
  ]);

  const basePriseCode = useMemo(() => {
    const findCurrency = find(currenciesData.toJS().items, 'default');
    return findCurrency?.code || '';
  }, [currenciesData]);

  const accountPriseCode = useMemo(() => {
    const findCurrency = find(currenciesData.toJS().items, { id: getOrderById?.currency });
    return findCurrency?.code || '';
  }, [getOrderById, currenciesData]);

  const columnsCosts = [
    { name: 'name', title: t('Title') },
    { name: 'sum', title: basePriseCode ? `${t('Amount')} (${basePriseCode})` : t('Amount') },
    { name: 'rate', title: t('Course') },
    { name: 'sumBill', title: accountPriseCode ? `${t('To pay')} (${accountPriseCode})` : t('To pay'), customField: 'order' },
  ];

  const editingStateColumnExtensionsCosts = [
    { columnName: 'name', editingEnabled: false },
    { columnName: 'rate' },
    { columnName: 'sum', editingEnabled: false },
    { columnName: 'sumBill' },
  ];
  const tableColumnExtensionsCosts = [
    // { columnName: 'name', width: '60%' },
    { columnName: 'sum', width: '130px', align: 'right' },
    { columnName: 'rate', width: '130px', align: 'right' },
    { columnName: 'sumBill', width: '130px', align: 'right' },
  ];

  const columnsDocuments = [
    // { name: 'id', title: t('ID') },
    { name: 'title', title: t('Title'), customField: 'titleDepartmentDocuments' },
    { name: 'documentClassifier', title: t('Classification') },
    { name: 'type', title: t('Type') },
    { name: 'inNumber', title: t('In number') },
    { name: 'outNumber', title: t('Out number') },
    { name: 'documentDate', title: t('Date') },
    { name: 'receivingDate', title: t('Received At') },
    { name: 'nextAction', title: t('Action up to') },
    { name: 'trademarks', title: t('Trade marks'), customField: 'documents' },
    { name: 'industrial', title: t('Industrial designs'), customField: 'documents' },
    { name: 'inventions', title: t('inventions'), customField: 'documents' },
    { name: 'utility', title: t('Utility models'), customField: 'documents' },
  ];

  const columnsDocumentsTemplates = [
    { name: 'title', title: t('Title'), customField: 'titleDocumentTemplates' },
    { name: 'documentClassifier', title: t('Type') },
    { name: 'createdAt', title: t('Created At') },
  ];

  // Paging
  const onCurrentPageDocuments = (page) => dispatch(departmentDocumentsAction.setCurrentPage(page));
  const onPageSizeDocuments = (size) => dispatch(departmentDocumentsAction.setPageSize(size));

  const handleSelectionCosts = (selectedCosts) => {
    const a = selectedCosts.reduce((acc, costId) => {
      const findCost = find(chargesData.toJS().items, { id: costId });
      return !isEmpty(findCost) ? acc + findCost.sum : acc;
    }, 0);
    setSumCosts(a);
    setSelectionCosts(selectedCosts);
  };

  const handleSelectDocuments = (documents) => {
    const allDocuments = uniqWith([
      ...documents,
      ...documentsOrder.toJS(),
    ], isEqual);
    dispatch(helpersActions.setModal({ isModalOpenTableEntities: false }));
    setTimeout(() => {
      dispatch(departmentDocumentsAction.setDepartmentDocumentsData({
        items: allDocuments,
        totalCount: allDocuments.length,
        pending: false,
      }));
      dispatch(departmentDocumentsAction.setCurrentPage(0));
    });
  };

  const handleSelectDocumentTemplates = (id) => {
    if (!isEmpty(params) && !isEmpty(params.hashId) && id) {
      dispatch(asyncActions.postInvoiceAsync({
        order: Number(params.hashId),
        template: id,
      }));
    }
  };

  const handlePayed = () => {
    if (!isEmpty(selectionCosts)) {
      forEach(selectionCosts, (costId) => {
        const findCost = find(chargesData.toJS().items, { id: costId });
        if (!isEmpty(findCost) && findCost.sum > findCost.sumPayed) {
          dispatch(helpersAsyncActions.putMultipleAsync({
            body: {
              entityName: 'charges',
              entityIds: [findCost.id],
              fields: {
                sumPayed: findCost.sum,
              },
            },
            callBack: () => {
              dispatch(chargesAsyncActions.getListAsync({ 'orders.id': params.hashId }));
            },
          }));
        }
      });
      closeMenu();
    }
  };

  const handleDelete = () => {
    if (!isEmpty(selectionCosts)) {
      forEach(selectionCosts, (costId) => {
        dispatch(helpersAsyncActions.putMultipleAsync({
          body: {
            entityName: 'charges',
            entityIds: [costId],
            fields: {
              orders: null,
            },
          },
          callBack: () => {
            const filterCharges = filter(chargesData.toJS().items,
              (item) => !includes(selectionCosts, item.id));
            dispatch(chargesActions.setChargesData({
              items: filterCharges,
              totalCount: filterCharges.length,
              pending: false,
            }));
            setSelectionCosts([]);
            if (!isEmpty(params.hashId)) handleSubmit();
          },
        }));
      });
      closeMenu();
    }
  };

  const handleEditingChange = ({ changed }) => {
    const values = Object.values(changed)[0];
    const ids = Object.keys(changed);
    if (!isEmpty(values)) {
      const findCharge = find(chargesData.toJS().items, { id: Number(ids[0]) });
      const getValue = values.rate && findCharge.sum
        ? {
          rate: Number(values.rate),
          sumBill: floor(divide(findCharge.sum, Number(values.rate)), 2),
        }
        : { ...values };
      if (!values.rate) {
        const filterCharges = map(chargesData.toJS().items, (item) => (
          item.id === Number(ids[0]) ? {
            ...item,
            ...getValue,
          } : item));
        dispatch(chargesActions.setChargesData({
          items: filterCharges,
          totalCount: filterCharges.length,
          pending: false,
        }));
      }
      dispatch(helpersAsyncActions.putMultipleAsync({
        body: {
          entityName: 'charges',
          entityIds: ids,
          fields: getValue,
        },
        callBack: () => {
          if (!isEmpty(params.hashId) && values.sumBill) {
            dispatch(asyncActions.getOrderByIdAsync(params.hashId));
          }
          if (!isEmpty(params.hashId) && values.rate) handleSubmit();
        },
      }));
    }
  };

  const linkBackToObject = useMemo(() => {
    if (!isEmpty(lastObject.toJS()) && lastObject.toJS().entityName && lastObject.toJS().entityId) {
      switch (lastObject.toJS().entityName) {
        case 'trademarks': {
          return pageLinks.tradeMarksRoutes.proposal.edit(lastObject.toJS().entityId);
        }
        case 'inventions': {
          return pageLinks.inventions.edit(lastObject.toJS().entityId);
        }
        case 'utility': {
          return pageLinks.utilityModels.edit(lastObject.toJS().entityId);
        }
        case 'industrial': {
          return pageLinks.industrialDesigns.edit(lastObject.toJS().entityId);
        }
        default: {
          return pageLinks.company.orders.all;
        }
      }
    }
    return pageLinks.company.orders.all;
  }, [lastObject]);

  const handleChangeEntity = (item) => {
    if (!isEmpty(item)) {
      dispatch(ordersActions.mergeOrderEntityList({
        entityName: item.entity,
        items: item,
      }));
    }
  };

  const handleDeleteEntity = (entityName, id) => {
    if (entityName && id) {
      dispatch(ordersActions.mergeOrderEntityList({
        entityName,
        items: {},
      }));
    }
  };

  const handleChangeCurrency = () => {
    if (!isEmpty(params.hashId)) {
      setTimeout(() => {
        handleSubmit();
      });
    }
  };

  const amountWithoutTaxes = useMemo(() => floor(getOrderById.billSum, 2) || 0, [getOrderById]);
  const amountWithTaxes = useMemo(() => floor(getOrderById.billSumTaxes, 2) || 0, [getOrderById]);

  useEffect(() => {
    if (!isModalOpenTableEntities) {
      dispatch(helpersActions.setTableName('DocumentsOrder'));
    }
  }, [isModalOpenTableEntities, dispatch]);

  return !pendingOrderById ? (
    <>
      <Helmet title={t('Orders')} />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h3" gutterBottom display="inline">
          {t('Orders')}
        </Typography>
        <LinkRouter to={linkBackToObject}>
          <NavigateBeforeIcon size="small" color="primary" />
          {!isEmpty(lastObject.toJS()) ? t('Get back to business') : t('Up to the list')}
        </LinkRouter>
      </Box>
      <Divider my={6} />

      {isModalOpenAddCharges && (
        <AddChargesModal
          handleSend={(json) => {
            if (!isEmpty(json)) {
              if (readyGetCharges) setReadyGetCharges(false);
              dispatch(chargesAsyncActions.postChargeAsync({
                ...json,
                ...!isEmpty(entityList.toJS()) && !isEmpty(entityList.toJS().clients) ? {
                  clients: entityList.toJS().clients?.id,
                } : {},
                ...currencyValues ? {
                  currency: currencyValues,
                } : {},
              }));
            }
          }}
        />
      )}

      {isModalOpenConfirm && (
        <ConfirmModal
          buttonSendText=""
          buttonCancelText="CLEARLY"
          description={modalDescription}
        />
      )}

      {isModalOpenTableEntities && (
        <TableEntitiesModal
          {...openModal === 'documentTemplates' ? {
            handleSend: handleSelectDocumentTemplates,
            titleModal: 'Select document template',
            entities: 'documentTemplates',
            showTableExtraFilter: false,
            selectionRow: true,
            columns: columnsDocumentsTemplates,
            defaultFilters: [
              { columnName: 'documentClassifier', value: 207 },
            ],
          } : {
            handleSend: handleSelectDocuments,
          }}
        />
      )}

      <Form onSubmit={handleSubmit(handleSubmits)}>
        <Card mb={6}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              {t('Basic information')}
            </Typography>

            <Grid container spacing={6}>
              <Grid item xs={12} md={4}>
                <Field
                  name="orderNum"
                  id="orderNum"
                  label={t('Order number')}
                  margin="normal"
                  variant="standard"
                  my={2}
                  fullWidth
                  component={renderTextField}
                  disabled={!accessEdit}
                />

                <Field
                  id="totalSum"
                  name="totalSum"
                  label={t('Order amount')}
                  variant="standard"
                  margin="normal"
                  fullWidth
                  my={2}
                  disabled
                  component={renderTextField}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Field
                  id="createdAt"
                  labelId="createdAt"
                  name="createdAt"
                  margin="normal"
                  variant="standard"
                  my={2}
                  fullWidth
                  label={t('Created At')}
                  component={DatePickers}
                  disabled={!accessEdit}
                />

                <Field
                  id="payedSum"
                  name="payedSum"
                  label={t('Payed')}
                  variant="standard"
                  margin="normal"
                  fullWidth
                  my={2}
                  disabled
                  component={renderTextField}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Field
                  name="currency"
                  id="currency"
                  label={t('Currency')}
                  labelId="currency"
                  my={2}
                  items={currenciesData.toJS().items}
                  onChange={handleChangeCurrency}
                  fullWidth
                  component={Select}
                  disabled={!accessEdit}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {accessChargesList && (
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" paragraph>
                {t('Costs')}
              </Typography>
              {accessEdit && accessChargesPost && (
                <Box mb={3}>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={handleAddCharge}
                  >
                    {t('ADD')}
                    <AddIcon />
                  </Button>
                </Box>
              )}
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <DxTable
                    name="CostsOrder"
                    tableName="CostsOrder"
                    columns={columnsCosts}
                    rows={chargesData.toJS().items}
                    tableColumnExtensions={tableColumnExtensionsCosts}
                    isLoading={!isEmpty(chargesData.toJS()) && !isEmpty(chargesData.toJS().items)
                      ? pendingCharges
                      : false}
                    disablePaging
                    {...accessEdit ? {
                      editingExtensions: editingStateColumnExtensionsCosts,
                      onEditingChange: handleEditingChange,
                      selection: selectionCosts,
                      onSelectionChange: handleSelectionCosts,
                    } : {}}
                    disableHiddenColumn
                    disableColumnOrder
                    disableColumnWidth
                    disableExport
                    disableMenu
                  />
                  {!isEmpty(chargesData.toJS().items) && accessEdit && (
                    <Table>
                      <colgroup>
                        <col style={{ width: '90%' }} />
                        <col style={{ width: '10%' }} />
                      </colgroup>
                      <TableBody>
                        <TableRow>
                          <TableCell>{t('Amount without taxes')}</TableCell>
                          <TableCell>{amountWithoutTaxes}</TableCell>
                        </TableRow>
                        {!isEmpty(getOrderById) && !isEmpty(getOrderById.taxesOrders) && (
                          <>
                            {map(getOrderById.taxesOrders, (item, index) => (
                              <TableRow key={index}>
                                <TableCell>{`${t(item.taxes?.name)} (${item.rate}%)`}</TableCell>
                                <TableCell>{item.sum}</TableCell>
                              </TableRow>
                            ))}
                            {/* <TableRow>
                              <TableCell />
                              <TableCell>{t('The amount of taxes')}</TableCell>
                              <TableCell>{amountOfTaxes}</TableCell>
                            </TableRow> */}
                            <TableRow>
                              <TableCell>{t('Amount with taxes')}</TableCell>
                              <TableCell>{amountWithTaxes}</TableCell>
                            </TableRow>
                          </>
                        )}
                      </TableBody>
                    </Table>
                  )}
                </Grid>
              </Grid>

              <Grid container spacing={6}>
                <Grid item xs={12} md={4}>
                  {selectionCosts.length ? (
                    <>
                      <Typography>
                        {`${t('Chosen')}: ${selectionCosts.length} ${t('in the amount of')} ${sumCosts} ${t('UAH')}`}
                      </Typography>

                      <Link to="/" onClick={toggleMenu} aria-owns="simple-menu" aria-haspopup="true">
                        {t('With the chosen ones')}
                      </Link>
                      <Menu
                        id="simple-menu"
                        anchorEl={simpleMenu}
                        open={Boolean(simpleMenu)}
                        onClose={closeMenu}
                      >
                        <MenuItem onClick={handlePayed}>
                          {t('Payed')}
                        </MenuItem>
                        <MenuItem onClick={handleDelete}>
                          {t('Delete')}
                        </MenuItem>
                      </Menu>
                    </>
                  ) : null}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}

        {accessDocumentsList && (
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" paragraph>
                {t('Documents')}
              </Typography>
              {accessEdit && (
                <Box mb={3}>
                  <ButtonGroup aria-label="contained primary button group">
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={handleSelectDocumentsModal}
                    >
                      {t('SELECT')}
                    </Button>
                    {accessDocumentsPost && (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleGoToCreateDocumentPage}
                      >
                        {t('CREATE')}
                      </Button>
                    )}
                    {accessDocumentsTemplateList && (
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={handleDocumentTemplateModal}
                      >
                        {t('CREATE INVOICE')}
                      </Button>
                    )}
                  </ButtonGroup>
                </Box>
              )}

              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <DxTable
                    name="DocumentsOrder"
                    tableName="DocumentsOrder"
                    columns={columnsDocuments}
                    rows={documentsData.toJS().items}
                    totalCount={totalCountDocuments}
                    isLoading={!isEmpty(documentsData.toJS())
                      && !isEmpty(documentsData.toJS().items)
                      ? pendingDocuments
                      : false}
                    pageSize={pageSizeDocuments}
                    currentPage={currentPageDocuments}
                    onPageSize={onPageSizeDocuments}
                    onCurrentPage={onCurrentPageDocuments}
                    {...accessEdit ? {
                      selection: selectionDocuments,
                      onSelectionChange: setSelectionDocuments,
                    } : {}}
                    editComponentWidth={60}
                    disableHiddenColumn
                    disableColumnOrder
                    disableColumnWidth
                    disableExport
                    disableMenu
                  />
                </Grid>
              </Grid>

              {isModalOpenDocViewer && <DocViewerModal />}

              <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                  {selectionDocuments.length ? (
                    <>
                      <Typography>
                        {`${t('Chosen')}: ${selectionDocuments.length}`}
                      </Typography>

                      <Link to="/">
                        {t('Delete favorites')}
                      </Link>
                    </>
                  ) : null}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}

        <Card mb={6}>
          <CardContent>
            <Typography variant="h6" paragraph>
              {t('Connections')}
            </Typography>
            <Grid container spacing={6}>
              <Grid item xs={12} md={6}>
                <AutocompleteGlobalSearch
                  label={t('Add connections')}
                  handleChange={handleChangeEntity}
                  helperText={t('Enter the name of the customer, № case, № application, № registration or name')}
                  disableIcon
                  entities={
                    ['trademarks', 'utility', 'inventions', 'industrial', 'clients']
                  }
                  disabled={!accessEdit}
                />
              </Grid>
              <Grid item xs={12}>
                {!isEmpty(entityList.toJS()) && (
                  map(Object.keys(entityList.toJS()), (entity, index) => {
                    if (!isEmpty(entityList.toJS()[entity])) {
                      const item = entityList.toJS()[entity];
                      return (
                        <Grid key={index}>
                          <TypographySub variant="subtitle1" gutterBottom>
                            {t(entity)}
                          </TypographySub>
                          <ChipEntity
                            id={item.id}
                            entity={entity}
                            label={item.label || item.title || item.id}
                            handleDelete={handleDeleteEntity}
                            disabled={!accessEdit}
                          />
                        </Grid>
                      );
                    }
                    return null;
                  })
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {accessCompanyList && (
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {t('Legal person')}
              </Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <Field
                    name="company"
                    id="company"
                    label={t('Legal person')}
                    labelId="company"
                    my={1}
                    items={allCompanies.toJS()}
                    fullWidth
                    component={Select}
                    disabled={!accessEdit}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}

        {accessEdit && (
          <Box mt={3}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!valid || pendingPutOrderById || pendingPostOrder}
            >
              {pendingPutOrderById || pendingPostOrder
                ? <CircularProgress size={25} color="inherit" />
                : t('SAVE')}
            </Button>
          </Box>
        )}
      </Form>
    </>
  ) : <Loader />;
}

Order.displayName = 'Order';

Order.propTypes = {
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  getOrderById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  match: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  chargesData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  documentsData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  isModalOpenAddCharges: PropTypes.bool.isRequired,
  isModalOpenTableEntities: PropTypes.bool.isRequired,
  isModalOpenConfirm: PropTypes.bool.isRequired,
  pendingCharges: PropTypes.bool.isRequired,
  totalCountCharges: PropTypes.number.isRequired,
  pendingDocuments: PropTypes.bool.isRequired,
  totalCountDocuments: PropTypes.number.isRequired,
  pageSizeDocuments: PropTypes.number.isRequired,
  currentPageDocuments: PropTypes.number.isRequired,
  pendingPutOrderById: PropTypes.bool.isRequired,
  pendingOrderById: PropTypes.bool.isRequired,
  pendingPostOrder: PropTypes.bool.isRequired,
  isModalOpenDocViewer: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  getChargesIds: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  documentsOrder: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  lastObject: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  getNextNumber: PropTypes.string.isRequired,
  entityList: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  selectedCompany: PropTypes.string.isRequired,
  allCompanies: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  currenciesData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  currencyValues: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

Order.defaultProps = {
  match: {},
  currencyValues: '',
};

function mapStateToProps(state) {
  return {
    getOrderById: selectors.ordersTable.getOrderById(state),
    documentsOrder: selectors.ordersTable.documentsOrder(state),
    pendingPutOrderById: selectors.ordersTable.pendingPutOrderById(state),
    pendingOrderById: selectors.ordersTable.pendingOrderById(state),
    pendingPostOrder: selectors.ordersTable.pendingPostOrder(state),
    getNextNumber: selectors.ordersTable.getNextNumber(state),
    entityList: selectors.ordersTable.entityList(state),
    currenciesData: selectors.currencies.currenciesData(state),

    // charges
    chargesData: selectors.charges.chargesData(state),
    pendingCharges: selectors.charges.pending(state),
    totalCountCharges: selectors.charges.totalCount(state),
    getChargesIds: selectors.charges.getChargesIds(state),

    // departmentDocuments
    documentsData: selectors.departmentDocuments.departmentDocumentsData(state),
    pendingDocuments: selectors.departmentDocuments.pending(state),
    totalCountDocuments: selectors.departmentDocuments.totalCount(state),
    currentPageDocuments: selectors.departmentDocuments.currentPage(state),
    pageSizeDocuments: selectors.departmentDocuments.pageSize(state),

    isModalOpenAddCharges: selectors.helpers.isModalOpenAddCharges(state),
    isModalOpenTableEntities: selectors.helpers.isModalOpenTableEntities(state),
    isModalOpenConfirm: selectors.helpers.isModalOpen(state),
    isModalOpenDocViewer: selectors.helpers.isModalOpenDocViewer(state),

    lastObject: selectors.helpers.lastObject(state),
    selectedCompany: selectors.helpers.selectedCompany(state),
    allCompanies: selectors.companiesTable.allCompanies(state),
    currencyValues: selectors.form.getFormValues(state, formName).get('currency'),

  };
}

export default compose(
  reduxForm({
    form: formName,
  }),
  connect(mapStateToProps, null),
)(Order);
