/**
 * @props-required: handleSend
 * @props-not-required: defaultFilters
 * defaultFilters - указать фильтры по умолчанию
 * @example for JSX:
 <TableEntitiesModal
    defaultFilters={[{ columnName: 'documentClassifier', value: 1 }]}
    handleSend={(ids) => {}}
 />
 * */
// core
import React, { useEffect, useState, memo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';

// lodash
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

// config
import selectors from '../../engine/config/selectors';

// parts
import Modal from '../Modal/Modal';
import TableEntities from './component/TableEntities';

// actions
import helpersActions from '../../engine/core/helpers/action';
import depDocModalActions from '../../engine/core/departmentDocuments/documentsModal/action';
import documentTypesTableActions from '../../engine/core/documentTypes/action';
import documentTemplatesActions from '../../engine/core/documentTemplates/action';
import tradeMarksActions from '../../engine/core/tradeMarks/proposal/action';
import industrialActions from '../../engine/core/industrialDesigns/action';
import inventionsActions from '../../engine/core/inventions/action';
import utilityActions from '../../engine/core/utilityModels/action';
import emailActions from '../../engine/core/email/email/action';

// hooks
import { useCleanUpDefaultFilters } from '../../ui/_hooks/useCleanUpDefaultFilters';

function TableEntitiesModal(props) {
  const {
    isModalOpen, showTableExtraFilter,
    handleSend, selectedEntities, selectionRow,
    defaultFilters, handleClose,
    titleModal, entities, columns,
    filteringColumn, sortingColumn,
  } = props;
  const { t } = useTranslation();
  const {
    departmentDocumentsResetFilters, documentTemplatesResetFilters,
  } = useCleanUpDefaultFilters();
  const dispatch = useDispatch();
  const selections = selectedEntities.toJS();
  const [readyDefaultFilters, setReadyDefaultFilters] = useState(false);

  const handleCloseModal = () => {
    dispatch(helpersActions.setModal({ isModalOpenTableEntities: false }));
    dispatch(helpersActions.setAutocompleteGroupOptions({ items: [] }));
    handleClose();
  };

  useEffect(() => function cleanup() {
    const emptyData = { items: [], totalCount: 0, pending: false };
    dispatch(helpersActions.setSelectedEntities([]));

    if (entities === 'documents') {
      dispatch(depDocModalActions.setDepartmentDocumentsDataModal(emptyData));
      dispatch(documentTypesTableActions.setDocumentTypesData(emptyData));
      departmentDocumentsResetFilters();
    }

    if (entities === 'documentTemplates') {
      dispatch(documentTemplatesActions.setDocumentTemplatesData(emptyData));
      dispatch(documentTypesTableActions.setDocumentTypesData(emptyData));
      documentTemplatesResetFilters();
    }

    if (entities === 'trademarks') dispatch(tradeMarksActions.setProposalData(emptyData));
    if (entities === 'industrial') dispatch(industrialActions.setProposalData(emptyData));
    if (entities === 'inventions') dispatch(inventionsActions.setProposalData(emptyData));
    if (entities === 'utility') dispatch(utilityActions.setProposalData(emptyData));
    if (entities === 'emailLetters') dispatch(emailActions.setEmailLetters([]));
  }, [
    dispatch, entities, departmentDocumentsResetFilters,
    documentTemplatesResetFilters,
  ]);

  const handleSendSelections = () => {
    handleSend(selections);
    dispatch(helpersActions.setSelectedEntities([]));
  };

  useEffect(() => {
    if (!isEmpty(defaultFilters) && !readyDefaultFilters) {
      setReadyDefaultFilters(true);
      if (entities === 'documents') {
        dispatch(depDocModalActions.setFilters(defaultFilters));
      }
      if (entities === 'documentTemplates') {
        dispatch(documentTemplatesActions.setFilters(defaultFilters));
      }
      if (entities === 'trademarks') {
        dispatch(tradeMarksActions.setFilters(defaultFilters));
      }
      if (entities === 'industrial') {
        dispatch(industrialActions.setFilters(defaultFilters));
      }
      if (entities === 'inventions') {
        dispatch(inventionsActions.setFilters(defaultFilters));
      }
      if (entities === 'utility') {
        dispatch(utilityActions.setFilters(defaultFilters));
      }
      if (entities === 'emailLetters') {
        dispatch(emailActions.setEmailLettersFilters(defaultFilters));
      }
    }
  }, [
    dispatch, setReadyDefaultFilters,
    defaultFilters, readyDefaultFilters,
    entities,
  ]);

  return (
    <Modal
      dialogProps={{
        maxWidth: 'md',
      }}
      title={t(titleModal)}
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
      handleSend={handleSendSelections}
      visibleButtonSend={!selectionRow}
    >
      {/* {((!isEmpty(defaultFilters) && !isEmpty(filters))
        || isEmpty(defaultFilters) || isEmpty(filters)
        || (isEmpty(defaultFilters) && !isEmpty(filters)))
      && ( */}
      <TableEntities
        isDefaultFilters={!isEmpty(defaultFilters)}
        entities={entities}
        columns={columns}
        filteringColumn={filteringColumn}
        sortingColumn={sortingColumn}
        showTableExtraFilter={showTableExtraFilter}
        selectionRow={selectionRow}
        handleSend={handleSend}
      />
      {/* )} */}
    </Modal>
  );
}

TableEntitiesModal.propTypes = {
  handleSend: PropTypes.func.isRequired,
  selectedEntities: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  // filters: PropTypes.oneOfType([
  //   PropTypes.array,
  // ]).isRequired,
  defaultFilters: PropTypes.oneOfType([
    PropTypes.array,
  ]),
  handleClose: PropTypes.func,
  titleModal: PropTypes.string,
  entities: PropTypes.string,
  showTableExtraFilter: PropTypes.bool,
  columns: PropTypes.oneOfType([
    PropTypes.array,
  ]),
  filteringColumn: PropTypes.oneOfType([
    PropTypes.array,
  ]),
  sortingColumn: PropTypes.oneOfType([
    PropTypes.array,
  ]),
  selectionRow: PropTypes.bool,
};

TableEntitiesModal.defaultProps = {
  defaultFilters: [],
  handleClose: () => {},
  titleModal: 'Select document',
  entities: 'documents',
  columns: [],
  filteringColumn: [],
  sortingColumn: [],
  showTableExtraFilter: true,
  selectionRow: false,
};

function mapStateToProps(state) {
  return {
    isModalOpen: selectors.helpers.isModalOpenTableEntities(state),
    selectedEntities: selectors.helpers.selectedEntities(state),

    filters: selectors.departmentDocuments.filters(state),
  };
}

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.selectedEntities, nextProps.selectedEntities)
  && isEqual(prevProps.isModalOpen, nextProps.isModalOpen)
  && isEqual(prevProps.defaultFilters, nextProps.defaultFilters)
  && isEqual(prevProps.titleModal, nextProps.titleModal)
  && isEqual(prevProps.entities, nextProps.entities)
  && isEqual(prevProps.showTableExtraFilter, nextProps.showTableExtraFilter)
  && isEqual(prevProps.columns, nextProps.columns)
  && isEqual(prevProps.filteringColumn, nextProps.filteringColumn)
  && isEqual(prevProps.sortingColumn, nextProps.sortingColumn)
  && isEqual(prevProps.selectionRow, nextProps.selectionRow);
}

export default compose(
  connect(mapStateToProps, null),
)(memo(TableEntitiesModal, areEqual));
