// Core
import { apply, put } from 'redux-saga/effects';

// config
import { api } from '../../../../../../config/api';
import { listEntityName } from '../../../../../../config/listTablesName';

// actions
import actions from '../../action';

const entity = listEntityName.employees;

export function* callGetEmployeesWorker({ payload: params }) {
  yield put(actions.setEmployeesData({ pending: true }));

  const response = yield apply(api, api.company.getEmployees, [{ ...params, entity }]);

  let data = {
    items: [],
    totalCount: 0,
    pending: false,
  };

  if (response && response.status >= 200 && response.status < 400) {
    data = Object.assign(data, response.data);
  }
  yield put(actions.setEmployeesData(data));
  yield put(actions.setReload(false));
}
