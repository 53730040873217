// Helpers
import actionCreator from '../../../../../_helpers/actionCreator';

// Types
import types from './types';

const actions = Object.freeze({
  setProposalDocumentsOutById(data) {
    return actionCreator(types.SET_PROPOSAL_DOCUMENTS_OUT_BY_ID, data);
  },
  setProposalDocumentsOutData(data) {
    return actionCreator(types.SET_PROPOSAL_DOCUMENTS_OUT_DATA, data);
  },
  setCurrentPage(currentPage) {
    return actionCreator(types.SET_PROPOSAL_DOCUMENTS_OUT_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return actionCreator(types.SET_PROPOSAL_DOCUMENTS_OUT_FILTERS, filters);
  },
  setSorting(sorting) {
    return actionCreator(types.SET_PROPOSAL_DOCUMENTS_OUT_SORTING, sorting);
  },
  setPageSize(pageSize) {
    return actionCreator(types.SET_PROPOSAL_DOCUMENTS_OUT_PAGE_SIZE, pageSize);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_PROPOSAL_DOCUMENTS_OUT_INITIAL_STATE, initialState);
  },
  setReload(reload) {
    return actionCreator(types.SET_PROPOSAL_DOCUMENTS_OUT_RELOAD, reload);
  },
  setPendingDeleteDocumentsOut(data) {
    return actionCreator(types.SET_PENDING_DELETE_DOCUMENTS_OUT, data);
  },
  setDeleteDocumentsDataOut(data) {
    return actionCreator(types.SET_DELETE_DOCUMENTS_DATA_OUT, data);
  },
  setSelectedDocuments(data) {
    return actionCreator(types.SET_DOCUMENTS_OUT_SELECTED_DOCUMENTS, data);
  },
  setDocumentsEntityName(data) {
    return actionCreator(types.SET_DOCUMENTS_OUT_ENTITY_NAME, data);
  },
});

export default actions;
