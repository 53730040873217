/**
 * Use to redux-form only where Autocomplete fields
 * @props-required: params, initialize
 * @props-not-required: multiple
 * params - параметры должны быть entityName и entityId
 * formName - обязательно
 * multiple - если Autocomplete multiple
 * @description:
 * Есть функции getProposalById, setProposalById. Они содержат switch/case,
 * где можно добавлять новые кейсы для других автокомплит полей.
 * */
// Core
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form/lib/immutable';

//
import isEmpty from 'lodash/isEmpty';

// actions Async
import tradeMarksActionsAsync from '../../../../engine/core/tradeMarks/proposal/saga/asyncAction';
import utilityModelsActionsAsync from '../../../../engine/core/utilityModels/saga/asyncAction';
import inventionsActionsAsync from '../../../../engine/core/inventions/saga/asyncAction';
import industrialDesignsActionsAsync from '../../../../engine/core/industrialDesigns/saga/asyncAction';
import ordersActionsAsync from '../../../../engine/core/company/orders/saga/asyncAction';

// actions
import industrialDesignsActions from '../../../../engine/core/industrialDesigns/action';
import inventionsActions from '../../../../engine/core/inventions/action';
import utilityModelsActions from '../../../../engine/core/utilityModels/action';
import tradeMarksActions from '../../../../engine/core/tradeMarks/proposal/action';
import ordersActions from '../../../../engine/core/company/orders/action';

// config
import selectors from '../../../../engine/config/selectors';

export function useSetFieldAutocompleteByIdEntity({ params, formName, multiple }) {
  const dispatch = useDispatch();
  const getTradeMarksProposalById = useSelector(selectors.tradeMarksProposal.getProposalById);
  const getInventionsProposalById = useSelector(selectors.inventionsProposal.getProposalById);
  const getIndustrialDesignsProposalById = useSelector(
    selectors.industrialDesignsProposal.getProposalById,
  );
  const getUtilityModelsProposalById = useSelector(selectors.utilityModelsProposal.getProposalById);
  const getOrderById = useSelector(selectors.ordersTable.getOrderById);
  const [readyProposalById, setReadyProposalById] = React.useState(false);
  const [readyInitialize, setReadyInitialize] = React.useState(false);
  const tradeMarksProposalById = getTradeMarksProposalById.toJS();
  const inventionsProposalById = getInventionsProposalById.toJS();
  const industrialDesignsProposal = getIndustrialDesignsProposalById.toJS();
  const utilityModelsProposal = getUtilityModelsProposalById.toJS();

  const getProposalById = React.useMemo(() => {
    switch (params.entityName) {
      case 'trademarks': {
        return tradeMarksProposalById;
      }
      case 'utility': {
        return utilityModelsProposal;
      }
      case 'inventions': {
        return inventionsProposalById;
      }
      case 'industrial': {
        return industrialDesignsProposal;
      }
      case 'orders': {
        return getOrderById;
      }
      default:
        return false;
    }
  }, [
    inventionsProposalById,
    params, tradeMarksProposalById,
    industrialDesignsProposal,
    utilityModelsProposal, getOrderById,
  ]);

  const setProposalById = React.useCallback(() => {
    switch (params.entityName) {
      case 'trademarks': {
        dispatch(tradeMarksActionsAsync.getProposalByIdAsync(params.entityId));
        break;
      }
      case 'utility': {
        dispatch(utilityModelsActionsAsync.getProposalByIdAsync(params.entityId));
        break;
      }
      case 'inventions': {
        dispatch(inventionsActionsAsync.getProposalByIdAsync(params.entityId));
        break;
      }
      case 'industrial': {
        dispatch(industrialDesignsActionsAsync.getProposalByIdAsync(params.entityId));
        break;
      }
      case 'orders': {
        dispatch(ordersActionsAsync.getOrderByIdAsync(params.entityId));
        break;
      }
      default:
        break;
    }
  }, [
    dispatch, params,
  ]);

  React.useEffect(() => {
    if (!isEmpty(params) && params.entityId && !readyProposalById) {
      setReadyProposalById(true);
      setProposalById();
    }
  }, [
    setProposalById,
    params, dispatch,
    readyProposalById,
    setReadyProposalById,
  ]);

  React.useEffect(() => {
    const checkParamsEntity = !isEmpty(params) && params.entityId && params.entityName;
    if (checkParamsEntity && !readyInitialize && !isEmpty(getProposalById)) {
      setReadyInitialize(true);
      dispatch(
        change(formName, params.entityName, multiple ? [getProposalById] : getProposalById),
      );
    }
  }, [
    multiple, dispatch, formName,
    params, readyInitialize,
    setReadyInitialize, getProposalById,
  ]);

  React.useEffect(() => function cleanup() {
    dispatch(industrialDesignsActions.setProposalDataById({}));
    dispatch(inventionsActions.setProposalDataById({}));
    dispatch(utilityModelsActions.setProposalDataById({}));
    dispatch(tradeMarksActions.setProposalDataById({}));
    dispatch(ordersActions.setOrderById({}));
  }, [dispatch]);
}
