// Core
import { List, Map } from 'immutable';

// Instruments
import { isNil } from 'lodash';
import types from './types';
import { pageSizes } from '../../config/globalConfig';
import globalStateKeys from '../../config/globalStateKeys';

export const initialState = Map({
  itemByHashId: Map({
    data: {},
    group: List(),
    pending: false,
    pendingPut: false,
    pendingPost: false,
    alwaysFilters: {},
  }),
  delete: Map({
    pendingDelete: false,
    id: '',
  }),
  autocompleteOptions: Map({
    items: Map(),
  }),
  data: Map({
    items: List(),
    currentPage: 0,
    filters: List(),
    pending: false,
    sorting: List(),
    totalCount: 0,
    pageSize: pageSizes[0],
    reload: false,
  }),
  pending: false,
  tabs: Map({
    all: [],
    fees: [],
    services: [],
    works: [],
  }),
  selections: Map({
    all: [],
    fees: [],
    services: [],
    works: [],
    worksChildren: [],
    collectedSections: [],
    fullSectionsData: [],
    chosen: 0,
    sum: 0,
  }),
  isModalOpenChargesTable: false,
  chargesIds: List(),
  selectionsModal: List(),
  sumChargesInput: Map(),
  isModalOpenChargesEdit: false,
});

export const stateKeys = Object.freeze({
  controller: 'charges',
  chargesIds: 'chargesIds',
  sumChargesInput: 'sumChargesInput',
  isModalOpenChargesTable: 'isModalOpenChargesTable',
  isModalOpenChargesEdit: 'isModalOpenChargesEdit',
});

export const chargesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_CHARGES_DATA: {
      const { items, totalCount, pending } = payload;
      if (pending) {
        return state.setIn([globalStateKeys.data, globalStateKeys.pending], pending);
      }
      return state
        .setIn([globalStateKeys.data, globalStateKeys.pending], pending)
        .setIn([globalStateKeys.data, globalStateKeys.items], List(items))
        .setIn([globalStateKeys.data, globalStateKeys.totalCount], totalCount);
    }
    case types.SET_CHARGES_RELOAD: {
      return state.setIn([globalStateKeys.data, globalStateKeys.reload], payload);
    }
    case types.SET_CHARGES_CURRENT_PAGE: {
      return state.setIn([globalStateKeys.data, globalStateKeys.currentPage], payload);
    }
    case types.SET_CHARGES_FILTERS: {
      const filters = payload.filter((item) => !isNil(item.value));
      return state.setIn([globalStateKeys.data, globalStateKeys.filters], List(filters));
    }
    case types.SET_CHARGES_SORTING: {
      return state.setIn([globalStateKeys.data, globalStateKeys.sorting], List(payload));
    }
    case types.SET_CHARGES_PAGE_SIZE: {
      return state.setIn([globalStateKeys.data, globalStateKeys.pageSize], payload);
    }
    case types.SET_CHARGES_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, sorting = [],
      } = payload;
      const reload = true;
      return state
        .setIn([globalStateKeys.data, globalStateKeys.currentPage], currentPage)
        .setIn([globalStateKeys.data, globalStateKeys.filters], List(filters))
        .setIn([globalStateKeys.data, globalStateKeys.pageSize], pageSize)
        .setIn([globalStateKeys.data, globalStateKeys.totalCount], totalCount)
        .setIn([globalStateKeys.data, globalStateKeys.reload], reload)
        .setIn([globalStateKeys.data, globalStateKeys.sorting], List(sorting));
    }
    case types.SET_CHARGE_BY_ID: {
      return state.mergeIn([globalStateKeys.itemByHashId], Map(payload));
    }
    case types.SET_DELETE_CHARGE: {
      return state.mergeIn([globalStateKeys.delete], payload);
    }
    case types.SET_CHARGES_AUTOCOMPLETE_OPTIONS: {
      return state.mergeIn([
        globalStateKeys.autocompleteOptions,
        globalStateKeys.items,
      ], Map(payload));
    }
    case types.SET_CHARGES_TABS: {
      return state.mergeIn([
        globalStateKeys.tabs,
      ], payload);
    }
    case types.SET_CHARGES_SELECTIONS: {
      return state.mergeIn([
        globalStateKeys.selections,
      ], Map(payload));
    }
    case types.SET_CHARGES_IDS: {
      return state.setIn([stateKeys.chargesIds], List(payload));
    }
    case types.SET_MODAL_CHARGES_TABLE: {
      return state.setIn([stateKeys.isModalOpenChargesTable], payload);
    }
    case types.SET_MODAL_CHARGES_EDIT: {
      return state.setIn([stateKeys.isModalOpenChargesEdit], payload);
    }
    case types.SET_CHARGES_SELECTIONS_MODAL: {
      return state.setIn([
        globalStateKeys.selectionsModal,
      ], List(payload));
    }
    case types.SET_SUM_CHARGES_INPUT: {
      return state.setIn([
        stateKeys.sumChargesInput,
      ], Map(payload));
    }
    default:
      return state;
  }
};
