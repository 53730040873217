// Core
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';

// UI
import {
  Typography, Divider as MuiDivider,
  Box, ButtonGroup, Button,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import {
  Add as AddIcon,
} from '@material-ui/icons';

// actions
import categoryActions from '../../../engine/core/prices/category/action';

// parts
import AddCategoryModal from './components/AddCategoryModal';
import CategoriesTable from './components/CategoriesTable';

// config
import selectors from '../../../engine/config/selectors';

// hook
import { useCategories } from './_hooks/useCategories';
import accessList from '../../../engine/config/accessList';
import { useAccessList } from '../../../ui/_hooks/useAccessList';

// styles
const Divider = styled(MuiDivider)(spacing);

function Categories(props) {
  const {
    isModalOpen,
  } = props;
  useCategories();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const accessPost = useAccessList(accessList.costsCategories_post);

  useEffect(() => function cleanup() {
    dispatch(categoryActions.setCategoriesData({
      items: [],
      totalCount: 0,
      pending: false,
    }));
  }, [dispatch]);

  return (
    <>
      <Helmet title={t('Categories')} />
      <Typography variant="h3" gutterBottom display="inline">
        {t('Categories')}
      </Typography>
      <Divider my={6} />

      {accessPost && (
        <Box mb={2}>
          <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                dispatch(categoryActions.setIsModalOpen(true));
              }}
            >
              {t('ADD')}
              <AddIcon />
            </Button>
          </ButtonGroup>
        </Box>
      )}

      <CategoriesTable />

      {isModalOpen && (
        <AddCategoryModal />
      )}
    </>
  );
}

Categories.displayName = 'Categories';

Categories.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    isModalOpen: selectors.pricesCategory.isModalOpen(state),
  };
}

function mapDispatchToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Categories);
