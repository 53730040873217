// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const pendingActionsAsync = Object.freeze({
  getPendingActionsAsync(params) {
    return actionCreator(asyncTypes.GET_PENDING_ACTIONS_ASYNC, params);
  },
  getPendingActionByIdAsync(id) {
    return actionCreator(asyncTypes.GET_PENDING_ACTION_BY_ID_ASYNC, id);
  },
  putPendingActionByIdAsync(employee) {
    return actionCreator(asyncTypes.PUT_PENDING_ACTION_BY_ID_ASYNC, employee);
  },
  patchPendingActionByIdAsync(action) {
    return actionCreator(asyncTypes.PATCH_PENDING_ACTION_BY_ID_ASYNC, action);
  },
  postPendingActionAsync(employee) {
    return actionCreator(asyncTypes.POST_PENDING_ACTION_ASYNC, employee);
  },
  deletePendingActionAsync(data) {
    return actionCreator(asyncTypes.DELETE_PENDING_ACTION_ASYNC, data);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_PENDING_ACTIONS_INITIAL_STATE_ASYNC, path);
  },
});

export default pendingActionsAsync;
