// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import types from './types';

const actions = Object.freeze({
  setEmailTemplateById(data) {
    return actionCreator(types.SET_EMAIL_TEMPLATE_BY_ID, data);
  },
  setEmailTemplateDataById(data) {
    return actionCreator(types.SET_EMAIL_TEMPLATE_DATA_BY_ID, data);
  },
  setEmailTemplateData(data) {
    return actionCreator(types.SET_EMAIL_TEMPLATE_DATA, data);
  },
  setCurrentPage(currentPage) {
    return actionCreator(types.SET_EMAIL_TEMPLATE_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return actionCreator(types.SET_EMAIL_TEMPLATE_FILTERS, filters);
  },
  setSorting(sorting) {
    return actionCreator(types.SET_EMAIL_TEMPLATE_SORTING, sorting);
  },
  setColumnWidths(data) {
    return actionCreator(types.SET_EMAIL_TEMPLATE_COLUMN_WIDTHS, data);
  },
  setColumnOrder(data) {
    return actionCreator(types.SET_EMAIL_TEMPLATE_COLUMN_ORDER, data);
  },
  setHiddenColumnNames(data) {
    return actionCreator(types.SET_EMAIL_TEMPLATE_HIDDEN_COLUMN_NAMES, data);
  },
  setPageSize(pageSize) {
    return actionCreator(types.SET_EMAIL_TEMPLATE_PAGE_SIZE, pageSize);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_EMAIL_TEMPLATE_INITIAL_STATE, initialState);
  },
  setReload(reload) {
    return actionCreator(types.SET_EMAIL_TEMPLATE_RELOAD, reload);
  },
  setDeleteEmailTemplate(data) {
    return actionCreator(types.SET_DELETE_EMAIL_TEMPLATE, data);
  },
  setAutocompleteOptions(options) {
    return actionCreator(types.SET_EMAIL_TEMPLATE_AUTOCOMPLETE_OPTIONS, options);
  },
});

export default actions;
