// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const loggingActionAsync = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_LOGGING_ASYNC, params);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_LOGGING_INITIAL_STATE_ASYNC, path);
  },
  getLoggingByIdAsync(id) {
    return actionCreator(asyncTypes.GET_LOGGING_BY_ID_ASYNC, id);
  },
  putLoggingByIdAsync(id) {
    return actionCreator(asyncTypes.PUT_LOGGING_BY_ID_ASYNC, id);
  },
  deleteLoggingAsync() {
    return actionCreator(asyncTypes.DELETE_LOGGING_ASYNC);
  },
});

export default loggingActionAsync;
