// Core
import { call, put, select } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../config/api';
import selectors from '../../../../config/selectors';
import globalStateKeys from '../../../../config/globalStateKeys';

// init
import i18n from '../../../../init/i18n';

// actions
import action from '../../action';
import helpersActions from '../../../helpers/action';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../ui/_helpers/setNotificationMessage';

// reducers
import { initialState } from '../../reducer';

export function* callDeletePaymentAllocationsWorker({ payload: id }) {
  const { setDeletePaymentAllocations, setReload, setSelections } = action;
  const { setModal } = helpersActions;

  yield put(setDeletePaymentAllocations({
    pendingDelete: true,
  }));

  const paymentIdDelete = yield select(selectors.paymentAllocations.paymentAllocationsIdDelete);
  const response = yield call(
    api.paymentAllocations.deletePaymentAllocations,
    id || paymentIdDelete,
  );

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      const dataInitialState = initialState.get(globalStateKeys.selections);
      yield put(setSelections(dataInitialState));
      yield put(setModal({
        isModalOpen: false,
      }));
      yield put(setDeletePaymentAllocations({
        id: '',
      }));
      yield put(setReload(true));
      yield put(setSuccessMessage(i18n.t('Payment deleted!'), response.status.toString()));
    }
  }

  yield put(setDeletePaymentAllocations({
    pendingDelete: false,
  }));
}
