export default Object.freeze({
  SET_COMPANIES_DATA: 'SET_COMPANIES_DATA',
  SET_COMPANY_BY_ID: 'SET_COMPANY_BY_ID',
  SET_COMPANIES_RELOAD: 'SET_COMPANIES_RELOAD',
  SET_COMPANIES_CURRENT_PAGE: 'SET_COMPANIES_CURRENT_PAGE',
  SET_COMPANIES_FILTERS: 'SET_COMPANIES_FILTERS',
  SET_COMPANIES_SORTING: 'SET_COMPANIES_SORTING',
  SET_COMPANIES_PAGE_SIZE: 'SET_COMPANIES_PAGE_SIZE',
  SET_COMPANIES_INITIAL_STATE: 'SET_COMPANIES_INITIAL_STATE',
  SET_DELETE_COMPANY: 'SET_DELETE_COMPANY',
  SET_COMPANIES_AUTOCOMPLETE_OPTIONS: 'SET_COMPANIES_AUTOCOMPLETE_OPTIONS',
  SET_PENDING_COMPANIES_AUTOCOMPLETE_OPTIONS: 'SET_PENDING_COMPANIES_AUTOCOMPLETE_OPTIONS',
  SET_PENDING_POST_CHECK_FTP: 'SET_PENDING_POST_CHECK_FTP',
  SET_ALL_COMPANIES: 'SET_ALL_COMPANIES',
  SET_COMPANIES_COLUMN_WIDTHS: 'SET_COMPANIES_COLUMN_WIDTHS',
  SET_COMPANIES_COLUMN_ORDER: 'SET_COMPANIES_COLUMN_ORDER',
  SET_COMPANIES_HIDDEN_COLUMN_NAMES: 'SET_COMPANIES_HIDDEN_COLUMN_NAMES',
  SET_COMPANY_ORIGINAL_LOGO: 'SET_COMPANY_ORIGINAL_LOGO',
  SET_COMPANY_ORIGINAL_DOC_HEADING: 'SET_COMPANY_ORIGINAL_DOC_HEADING',
});
