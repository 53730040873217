// Core
import { all, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callUploadFileWorker,
} from './workers';

function* watchUploadFile() {
  yield takeEvery(asyncTypes.UPLOAD_FILE_ASYNC, callUploadFileWorker);
}

export function* watchersUploadFile() {
  yield all([
    watchUploadFile(),
  ]);
}
