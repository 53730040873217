// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const asyncActions = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_TAGS_ASYNC, params);
  },
  getTagByIdAsync(id) {
    return actionCreator(asyncTypes.GET_TAG_BY_ID_ASYNC, id);
  },
  deleteTagAsync(id) {
    return actionCreator(asyncTypes.DELETE_TAG_ASYNC, id);
  },
  postTagAsync(data) {
    return actionCreator(asyncTypes.POST_TAG_ASYNC, data);
  },
  putTagByIdAsync(data) {
    return actionCreator(asyncTypes.PUT_TAG_BY_ID_ASYNC, data);
  },
});

export default asyncActions;
