// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import types from './types';

const actions = Object.freeze({
  setReboundsData(calculationsData) {
    return actionCreator(types.SET_REBOUNDS_DATA, calculationsData);
  },
  setCurrentPage(currentPage) {
    return actionCreator(types.SET_REBOUNDS_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return actionCreator(types.SET_REBOUNDS_FILTERS, filters);
  },
  setSorting(sorting) {
    return actionCreator(types.SET_REBOUNDS_SORTING, sorting);
  },
  setColumnWidths(data) {
    return actionCreator(types.SET_REBOUNDS_COLUMN_WIDTHS, data);
  },
  setColumnOrder(data) {
    return actionCreator(types.SET_REBOUNDS_COLUMN_ORDER, data);
  },
  setHiddenColumnNames(data) {
    return actionCreator(types.SET_REBOUNDS_HIDDEN_COLUMN_NAMES, data);
  },
  setPageSize(pageSize) {
    return actionCreator(types.SET_REBOUNDS_PAGE_SIZE, pageSize);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_REBOUNDS_INITIAL_STATE, initialState);
  },
  setReboundsOptions(options) {
    return actionCreator(types.SET_REBOUNDS_AUTOCOMPLETE_OPTIONS, options);
  },
  setPendingReboundsOptions(pending) {
    return actionCreator(types.SET_PENDING_REBOUNDS_AUTOCOMPLETE_OPTIONS, pending);
  },
  setReload(reload) {
    return actionCreator(types.SET_REBOUNDS_RELOAD, reload);
  },
  setDeleteRebound(data) {
    return actionCreator(types.SET_DELETE_REBOUND, data);
  },
  setReboundById(data) {
    return actionCreator(types.SET_REBOUND_BY_ID, data);
  },
  setReboundPdfById(pdf) {
    return actionCreator(types.SET_REBOUND_PDF_BY_ID, pdf);
  },
  setPendingReboundPdf(pending) {
    return actionCreator(types.SET_PENDING_REBOUND_PDF, pending);
  },
  setPendingReboundById(pending) {
    return actionCreator(types.SET_PENDING_REBOUND_BY_ID, pending);
  },
  setSearchRebound(data) {
    return actionCreator(types.SET_SEARCH_REBOUND, data);
  },
  setPendingSearchRebound(pending) {
    return actionCreator(types.SET_PENDING_SEARCH_REBOUND, pending);
  },
  setModalCreateOpen(isOpen) {
    return actionCreator(types.SET_REBOUND_MODAL_CREATE_IS_OPEN, isOpen);
  },
  setModalAllTermsOpen(isOpen) {
    return actionCreator(types.SET_REBOUND_MODAL_ALL_TERMS_IS_OPEN, isOpen);
  },
  setModalYourListTermsOpen(isOpen) {
    return actionCreator(types.SET_REBOUND_MODAL_YOUR_LIST_TERMS_IS_OPEN, isOpen);
  },
});

export default actions;
