// Core
import React from 'react';

// UI
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';

const RadioButton = ({ disabled, input, ...rest }) => ( // eslint-disable-line
  <FormControl disabled={disabled}>
    <RadioGroup {...input} {...rest} />
  </FormControl>
);

export default RadioButton;
