// Core
import { List, Map } from 'immutable';

// Instruments
import types from './types';
import globalStateKeys from '../../config/globalStateKeys';

const initialState = Map({
  data: Map({
    items: List(),
    pending: true,
    totalCount: 0,
  }),
  delete: Map({
    pendingDelete: false,
    id: '',
  }),
  isModalOpen: false,
  pendingPost: false,
});

export const stateKeys = Object.freeze({
  controller: 'usersFilters',
});

export const usersFiltersReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_USERS_FILTERS_DATA: {
      const { items, totalCount, pending } = payload;
      if (pending) {
        return state.setIn([globalStateKeys.data, globalStateKeys.pending], pending);
      }
      return state
        .setIn([globalStateKeys.data, globalStateKeys.pending], pending)
        .setIn([globalStateKeys.data, globalStateKeys.items], List(items))
        .setIn([globalStateKeys.data, globalStateKeys.totalCount], Number(totalCount));
    }
    case types.SET_DELETE_USERS_FILTER: {
      return state.mergeIn([globalStateKeys.delete], payload);
    }
    case types.SET_USERS_FILTERS_IS_MODAL_OPEN: {
      return state.setIn([globalStateKeys.isModalOpen], payload);
    }
    case types.SET_USERS_FILTERS_PENDING_POST: {
      return state.setIn([globalStateKeys.pendingPost], payload);
    }
    default:
      return state;
  }
};
