// Core
import { apply, put } from 'redux-saga/effects';
import { change } from 'redux-form/lib/immutable';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../config/api';
import { messageRequestSent } from '../../../../config/globalConfig';

// actions
import actions from '../../action';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../ui/_helpers/setNotificationMessage';
import i18n from '../../../../init/i18n';

export function* callPostSendWorker({ payload: params }) {
  yield put(actions.setPendingSend(true));

  const response = yield apply(api, api.send.postSend, [params]);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      yield put(setSuccessMessage(i18n.t(messageRequestSent), response.status.toString()));
      yield put(actions.setModal({ isModalOpenSend: false }));
      yield put(actions.setCheckboxClose(true));
      if (params.trademarks || params.inventions || params.industrial || params.utility) {
        yield put(change('ProposalAdd', 'status', 3));
      }
    }
  }

  yield put(actions.setPendingSend(false));
}
