// core
import React from 'react';
import { Field } from 'redux-form/lib/immutable';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

// lodash
import AutocompleteField from '../../../../../ui/Form/AutocompleteField';

// parts
import selectors from '../../../../../engine/config/selectors';

function TermFromDocument(props) {
  const { data, name, disabled } = props;
  const { t } = useTranslation();

  return (
    <Field
      fullWidth
      name={name}
      id={name}
      label={t('Term From Document')}
      margin="normal"
      options={data.toJS().items}
      component={AutocompleteField}
      disabled={disabled}
    />
  );
}

TermFromDocument.displayName = 'TermFromDocument';

TermFromDocument.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

TermFromDocument.defaultProps = {
  disabled: false,
};

function mapStateToProps(state) {
  return {
    data: selectors.documentTypes.documentTypesData(state),
  };
}

export default compose(
  connect(mapStateToProps, null),
)(TermFromDocument);
