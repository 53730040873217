// core
import React, { useEffect } from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import { push } from 'connected-react-router';

// ui
import {
  Button, Divider as MuiDivider,
  Typography, ButtonGroup as MuiButtonGroup,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';

// actions
import actions from '../../../engine/core/email/email/action';
import asyncAction from '../../../engine/core/email/email/saga/asyncAction';

// parts
import EmailMessagesTable from './EmailMessagesTable';

// hook
import { useEmailMessages } from './_hooks/useEmailMessages';

// selectors
import selectors from '../../../engine/config/selectors';

// routes
import { pageLinks } from '../../../routes';
import Chat from './Chat';
import accessList from '../../../engine/config/accessList';
import { useAccessList } from '../../../ui/_hooks/useAccessList';

// styles
const Divider = styled(MuiDivider)(spacing);
const ButtonGroup = styled(MuiButtonGroup)(spacing);

function EmailMessages(props) {
  const { isModalOpenMessages, query, blockRetrievalRequest } = props;
  useEmailMessages(blockRetrievalRequest);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const accessPost = useAccessList(accessList.emailLetters_post);
  const accessEmailGet = useAccessList(accessList.email_list_get);

  const handleOpenModal = () => {
    dispatch(actions.setIsModalOpenMessage(true));
  };

  const handleSettings = () => {
    dispatch(push(pageLinks.email.settings));
  };

  useEffect(() => function cleanup() {
    dispatch(actions.setEmailMessagesData({
      items: [],
      pending: false,
      nextPage: 0,
    }));
    dispatch(actions.setEmails([]));
    dispatch(actions.setIsModalOpenMessage(false));
    dispatch(actions.setSelectedEmail({}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(asyncAction.getEmailsAsync(query.toJS()));
  }, [dispatch, query]);

  return (
    <>
      {!blockRetrievalRequest && (
        <>
          <Helmet title={t('Mail')} />
          <Typography variant="h3" gutterBottom display="inline">
            {t('Mail')}
          </Typography>
          <Divider my={6} />
        </>
      )}

      {isModalOpenMessages && !blockRetrievalRequest && <Chat />}

      {!blockRetrievalRequest && (
        <ButtonGroup color="primary" aria-label="extra filter buttons group" mb={4}>
          {accessPost && (
            <Button variant="contained" color="primary" onClick={handleOpenModal}>
              {t('WRITE')}
            </Button>
          )}
          {accessEmailGet && (
            <Button
              variant="outlined"
              color="primary"
              onClick={handleSettings}
            >
              {t('SETTINGS')}
            </Button>
          )}
        </ButtonGroup>
      )}

      <EmailMessagesTable blockRetrievalRequest={blockRetrievalRequest} />
    </>
  );
}

EmailMessages.displayName = 'EmailMessages';

EmailMessages.propTypes = {
  isModalOpenMessages: PropTypes.bool.isRequired,
  query: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  blockRetrievalRequest: PropTypes.bool,
};

EmailMessages.defaultProps = {
  blockRetrievalRequest: false,
};

function mapStateToProps(state) {
  return {
    isModalOpenMessages: selectors.email.isModalOpenMessage(state),
    query: selectors.router.query(state),
  };
}

export default compose(
  connect(mapStateToProps, null),
)(EmailMessages);
