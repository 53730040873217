// Core
import { call, put } from 'redux-saga/effects';

// config
import { api } from '../../../../config/api';

// actions
import actions from '../../action';

export function* callGetChargeByIdWorker({ payload: id }) {
  const {
    setChargeById,
  } = actions;
  yield put(setChargeById({
    pending: true,
  }));

  const response = yield call(api.charges.getChargeById, id);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;

    yield put(setChargeById({
      data,
    }));
  }

  yield put(setChargeById({
    pending: false,
  }));
}
