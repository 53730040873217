// Core
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

// lodash
import { isEmpty, map, find } from 'lodash';

// actions
import actions from '../../../engine/core/currencies/currencyRates/action';
import actionAsync from '../../../engine/core/currencies/currencyRates/saga/asyncAction';
import helpersActions from '../../../engine/core/helpers/action';
import helpersAsyncAction from '../../../engine/core/helpers/saga/asyncAction';
import currenciesActions from '../../../engine/core/currencies/currenciesList/action';
import currenciesActionAsync from '../../../engine/core/currencies/currenciesList/saga/asyncAction';

// parts
import DxTable from '../../../ui/Table/DxTable';
import ConfirmModal from '../../../components/ConfirmModal';

// config
import selectors from '../../../engine/config/selectors';

// hooks
import { listTablesName, listEntityName } from '../../../engine/config/listTablesName';
import accessList from '../../../engine/config/accessList';

const tableName = listTablesName.currencyRates;
const entityName = listEntityName.currencyRates;

function CurrencyRatesTable(props) {
  const {
    currencyRatesData, totalCount, pending,
    currentPage, filters, sorting,
    pageSize, pendingDeleteCurrencyRate,
    checkboxes, isModalOpen, columnWidths,
    columnOrder, hiddenColumnNames, currenciesData,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const columns = [
    { name: 'id', title: t('ID') },
    { name: 'currency.code', title: t('Currency') },
    { name: 'date', title: t('Course date') },
    { name: 'rateFixed', title: t('Fixed rate') },
    { name: 'rateReal', title: t('The real course') },
  ];
  const editingStateColumnExtensionsCosts = [
    { columnName: 'id', editingEnabled: false },
    { columnName: 'currency.code', editingEnabled: false },
    { columnName: 'date' },
    { columnName: 'rateFixed' },
    { columnName: 'rateFixed' },
  ];

  const tableColumnExtensions = [
    { columnName: 'id', width: 50 },
    { columnName: 'currency.code', width: '20rem' },
    { columnName: 'date', width: '20rem' },
    { columnName: 'rateFixed', width: '10rem' },
    { columnName: 'rateReal', width: '10rem' },
  ];

  const handleEditingChange = ({ changed }) => {
    const values = Object.values(changed)[0];
    const ids = Object.keys(changed);
    if (!isEmpty(values)) {
      const filterData = map(currencyRatesData.toJS().items, (item) => (
        item.id === Number(ids[0]) ? {
          ...item,
          ...values.currency ? {
            currency: find(currenciesData.toJS().items, { id: values.currency }) || '',
          } : values,
        } : item));
      dispatch(actions.setCurrencyRatesData({
        items: filterData,
        totalCount: filterData.length,
        pending: false,
      }));
      dispatch(helpersAsyncAction.putMultipleAsync({
        body: {
          entityName: 'currencyRates',
          entityIds: ids,
          fields: values,
        },
      }));
    }
  };

  useEffect(() => {
    dispatch(currenciesActionAsync.getListAsync());
  }, [dispatch]);

  useEffect(() => function cleanup() {
    const emptyValues = {
      items: [],
      totalCount: 0,
      pending: false,
    };
    dispatch(actions.setCurrencyRatesData(emptyValues));
    dispatch(currenciesActions.setCurrenciesData(emptyValues));
  }, [dispatch]);

  // Paging
  const onCurrentPage = (page) => dispatch(actions.setCurrentPage(page));
  const onPageSize = (size) => dispatch(actions.setPageSize(size));
  // Filtering
  const setFilters = (getFilters) => dispatch(actions.setFilters(getFilters));

  // Sorting
  const onSortingChange = (getSorting) => dispatch(
    actions.setSorting(getSorting),
  );
  const onColumnWidthsChange = (getColumnWidths) => dispatch(
    actions.setColumnWidths(getColumnWidths),
  );
  const onColumnOrderChange = (getColumnOrder) => dispatch(
    actions.setColumnOrder(getColumnOrder),
  );
  const onHiddenColumnNamesChange = (getHiddenColumnNames) => dispatch(
    actions.setHiddenColumnNames(getHiddenColumnNames),
  );

  return (
    <>
      <DxTable
        name="CurrencyRates"
        isLoading={pending}
        columns={columns}
        rows={currencyRatesData.toJS().items}
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageSize={onPageSize}
        onCurrentPage={onCurrentPage}
        filtersAction={setFilters}
        onSortingChange={onSortingChange}
        filters={filters}
        // checkboxes ------------------------
        tableName={tableName}
        reloadTable={actions.setReload}
        selection={checkboxes.toJS()}
        onSelectionChange={(checkboxSelect) => {
          dispatch(helpersActions.setCheckbox({
            tableName,
            checkboxes: checkboxSelect,
          }));
        }}
        // ------------------------
        entityName={entityName}
        editingExtensions={editingStateColumnExtensionsCosts}
        onEditingChange={handleEditingChange}
        sorting={sorting}
        isModalOpen={isModalOpen}
        tableColumnExtensions={!isEmpty(columnWidths) && columnWidths.length === columns.length
          ? columnWidths : tableColumnExtensions}
        onColumnWidthsChange={onColumnWidthsChange}
        columnOrder={!isEmpty(columnOrder) ? columnOrder : map(columns, 'name')}
        onColumnOrderChange={onColumnOrderChange}
        hiddenColumnNames={hiddenColumnNames}
        onHiddenColumnNamesChange={onHiddenColumnNamesChange}
        accessEdit={accessList.currencyRates_put}
        accessDelete={accessList.currencyRates_delete}
      />

      <ConfirmModal
        buttonSendText="DELETE"
        pending={pendingDeleteCurrencyRate}
        handleSend={() => {
          dispatch(actionAsync.deleteCurrencyRateAsync());
        }}
      />
    </>
  );
}

CurrencyRatesTable.displayName = 'CurrencyRatesTable';

CurrencyRatesTable.propTypes = {
  currencyRatesData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  totalCount: PropTypes.number.isRequired,
  pending: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  sorting: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  columnWidths: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  columnOrder: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  hiddenColumnNames: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  pageSize: PropTypes.number.isRequired,
  pendingDeleteCurrencyRate: PropTypes.bool.isRequired,
  checkboxes: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  currenciesData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

function mapStateToProps(state) {
  return {
    currencyRatesData: selectors.currencyRates.currencyRatesData(state),
    pending: selectors.currencyRates.pending(state),
    pendingDeleteCurrencyRate: selectors.currencyRates.pendingDeleteCurrencyRate(state),
    isModalOpen: selectors.currencyRates.isModalOpen(state),
    currenciesData: selectors.currencies.currenciesData(state),

    // Paging
    totalCount: selectors.currencyRates.totalCount(state),
    currentPage: selectors.currencyRates.currentPage(state),
    pageSize: selectors.currencyRates.pageSize(state),

    // Filtering
    filters: selectors.currencyRates.filters(state),

    // Sorting
    sorting: selectors.currencyRates.sorting(state),

    // ColumnWidths
    columnWidths: selectors.currencyRates.columnWidths(state),
    columnOrder: selectors.currencyRates.columnOrder(state),
    hiddenColumnNames: selectors.currencyRates.hiddenColumnNames(state),

    checkboxes: selectors.helpers.getCheckboxes(state, tableName),
  };
}

function mapDispatchToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(CurrencyRatesTable);
