// Core
import { List, Map } from 'immutable';

// Instruments
import { isNil } from 'lodash';
import types from './types';
import { pageSizes } from '../../config/globalConfig';
import globalStateKeys from '../../config/globalStateKeys';

export const initialState = Map({
  data: Map({
    items: List(),
    currentPage: 0,
    filters: List(),
    pending: false,
    sorting: List([{ columnName: 'createdAt', direction: 'asc' }, { columnName: 'id', direction: 'asc' }]),
    totalCount: 0,
    pageSize: pageSizes[0],
    reload: false,
    columnWidths: List(),
    columnOrder: List(),
    hiddenColumnNames: List(),
  }),
  itemByHashId: Map({
    data: Map(),
    pending: false,
    pendingPut: false,
  }),
  autocompleteOptions: Map({
    items: Map(),
    pending: false,
  }),
  delete: Map({
    pendingDelete: false,
    id: '',
  }),
  pendingPostProposal: false,
  pending: false,
  uploadFiles: List(),
  pendingPutProposal: false,
  pendingGetProposalById: false,
  pendingGetRegistryByNumber: false,
  registryByNumber: Map(),
  imagesVariant: Map({
    items: List(),
  }),
  company: {
    pending: false,
    item: Map(),
  },
  getNextNumber: '',
});

export const stateKeys = Object.freeze({
  controller: 'industrialDesignsProposal',
  uploadFiles: 'uploadFiles',
  pendingPutProposal: 'pendingPutProposal',
  imagesVariant: 'imagesVariant',
  pendingPostProposal: 'pendingPostProposal',
  pendingGetProposalById: 'pendingGetProposalById',
  pendingGetRegistryByNumber: 'pendingGetRegistryByNumber',
  registryByNumber: 'registryByNumber',
  company: 'company',
});

export const industrialDesignsProposalReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_INDUSTRIAL_DESIGNS_IMAGE: {
      const { variantIndex, data, data: { number } } = payload;
      return state.mergeIn([
        stateKeys.imagesVariant,
        globalStateKeys.items,
        variantIndex,
        number,
      ], Map(data));
    }
    case types.SET_INDUSTRIAL_DESIGNS_IMAGES_VARIANT_LIST: {
      return state.mergeIn([
        stateKeys.imagesVariant,
        globalStateKeys.items,
      ], List(payload));
    }
    case types.SET_INDUSTRIAL_DESIGNS_NEW_IMAGES_VARIANT_LIST: {
      return state.setIn([
        stateKeys.imagesVariant,
        globalStateKeys.items,
      ], List(payload));
    }
    case types.SET_INDUSTRIAL_DESIGNS_IMAGES_MOVE: {
      const { variantIndex, data } = payload;

      return state.setIn([
        stateKeys.imagesVariant,
        globalStateKeys.items,
        variantIndex,
      ], List(data));
    }
    case types.SET_INDUSTRIAL_DESIGNS_IMAGES_VARIANT_REMOVE: {
      const { variantIndex } = payload;
      return state.deleteIn([
        stateKeys.imagesVariant,
        globalStateKeys.items,
        variantIndex,
      ]);
    }
    case types.SET_INDUSTRIAL_DESIGNS_IMAGES_REMOVE: {
      const { variantIndex, imageIndex } = payload;
      return state.deleteIn([
        stateKeys.imagesVariant,
        globalStateKeys.items,
        variantIndex,
        imageIndex,
      ]);
    }
    case types.SET_INDUSTRIAL_DESIGNS_IMAGES_NAME: {
      const { variantIndex, imageIndex, name } = payload;
      return state.setIn([
        stateKeys.imagesVariant,
        globalStateKeys.items,
        variantIndex,
        imageIndex,
        globalStateKeys.name,
      ], name);
    }
    case types.SET_INDUSTRIAL_DESIGNS_IMAGES_CLEAR: {
      return state.setIn([
        stateKeys.imagesVariant,
        globalStateKeys.items,
      ], List());
    }
    case types.SET_INDUSTRIAL_DESIGNS_PROPOSAL_DATA: {
      const { items, totalCount, pending } = payload;
      if (pending) {
        return state.setIn([globalStateKeys.data, globalStateKeys.pending], pending);
      }
      return state
        .setIn([globalStateKeys.data, globalStateKeys.pending], pending)
        .setIn([globalStateKeys.data, globalStateKeys.items], List(items))
        .setIn([globalStateKeys.data, globalStateKeys.totalCount], totalCount);
    }
    case types.SET_INDUSTRIAL_DESIGNS_PROPOSAL_RELOAD: {
      return state.setIn([globalStateKeys.data, globalStateKeys.reload], payload);
    }
    case types.SET_INDUSTRIAL_DESIGNS_PROPOSAL_CURRENT_PAGE: {
      return state.setIn([globalStateKeys.data, globalStateKeys.currentPage], payload);
    }
    case types.SET_INDUSTRIAL_DESIGNS_PROPOSAL_FILTERS: {
      const filters = payload.filter((item) => !isNil(item.value));
      return state.setIn([globalStateKeys.data, globalStateKeys.filters], List(filters));
    }
    case types.SET_INDUSTRIAL_DESIGNS_PROPOSAL_SORTING: {
      return state.setIn([globalStateKeys.data, globalStateKeys.sorting], List(payload));
    }
    case types.SET_INDUSTRIAL_DESIGNS_PROPOSAL_COLUMN_WIDTHS: {
      return state.setIn([globalStateKeys.data, globalStateKeys.columnWidths], List(payload));
    }
    case types.SET_INDUSTRIAL_DESIGNS_PROPOSAL_COLUMN_ORDER: {
      return state.setIn([globalStateKeys.data, globalStateKeys.columnOrder], List(payload));
    }
    case types.SET_INDUSTRIAL_DESIGNS_PROPOSAL_HIDDEN_COLUMN_NAMES: {
      return state.setIn([globalStateKeys.data, globalStateKeys.hiddenColumnNames], List(payload));
    }
    case types.SET_INDUSTRIAL_DESIGNS_PROPOSAL_PAGE_SIZE: {
      return state.setIn([globalStateKeys.data, globalStateKeys.pageSize], payload);
    }
    case types.SET_INDUSTRIAL_DESIGNS_PROPOSAL_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, sorting = [],
        columnWidths, columnOrder, hiddenColumnNames,
      } = payload;
      const reload = true;
      const currentSorting = sorting.length
        ? sorting
        : initialState.getIn([globalStateKeys.data, globalStateKeys.sorting]);
      return state
        .setIn([globalStateKeys.data, globalStateKeys.currentPage], currentPage)
        .setIn([globalStateKeys.data, globalStateKeys.filters], List(filters))
        .setIn([globalStateKeys.data, globalStateKeys.pageSize], pageSize)
        .setIn([globalStateKeys.data, globalStateKeys.totalCount], totalCount)
        .setIn([globalStateKeys.data, globalStateKeys.reload], reload)
        .setIn([globalStateKeys.data, globalStateKeys.columnWidths], List(columnWidths))
        .setIn([globalStateKeys.data, globalStateKeys.columnOrder], List(columnOrder))
        .setIn([globalStateKeys.data, globalStateKeys.hiddenColumnNames], List(hiddenColumnNames))
        .setIn([globalStateKeys.data, globalStateKeys.sorting], List(currentSorting));
    }
    case types.SET_DELETE_INDUSTRIAL_DESIGN_PROPOSAL: {
      return state.mergeIn([globalStateKeys.delete], payload);
    }
    case types.SET_INDUSTRIAL_PROPOSAL_AUTOCOMPLETE_OPTIONS: {
      return state.mergeIn([
        globalStateKeys.autocompleteOptions,
        globalStateKeys.items,
      ], Map(payload));
    }
    case types.SET_INDUSTRIAL_PROPOSAL_DATA_BY_ID: {
      return state.setIn([globalStateKeys.itemByHashId, globalStateKeys.data], Map(payload));
    }
    case types.SET_PENDING_INDUSTRIAL_PROPOSAL_AUTOCOMPLETE_OPTIONS: {
      return state.setIn([
        globalStateKeys.autocompleteOptions,
        globalStateKeys.pending,
      ], payload);
    }
    case types.SET_INDUSTRIAL_DESIGNS_PROPOSAL_UPLOAD_FILES: {
      return state.setIn([stateKeys.uploadFiles], List(payload));
    }
    case types.SET_INDUSTRIAL_DESIGNS_PENDING_PUT_PROPOSAL: {
      return state.setIn([stateKeys.pendingPutProposal], payload);
    }
    case types.SET_INDUSTRIAL_DESIGNS_PENDING_POST_PROPOSAL: {
      return state.setIn([stateKeys.pendingPostProposal], payload);
    }
    case types.SET_PENDING_GET_SET_INDUSTRIAL_DESIGNS_PROPOSAL_BY_ID: {
      return state.setIn([globalStateKeys.pendingGetProposalById], payload);
    }
    case types.SET_INDUSTRIAL_DESIGNS_PROPOSAL_COMPANY: {
      return state.setIn([
        stateKeys.company,
        globalStateKeys.item,
      ], Map(payload));
    }
    case types.SET_INDUSTRIAL_DESIGNS_PENDING_GET_FIND_COMPANY: {
      return state.setIn([stateKeys.company, globalStateKeys.pending], payload);
    }
    case types.SET_INDUSTRIAL_DESIGNS_PENDING_GET_REGISTRY_BY_NUMBER: {
      return state.setIn([stateKeys.pendingGetRegistryByNumber], payload);
    }
    case types.SET_INDUSTRIAL_DESIGNS_REGISTRY_BY_NUMBER: {
      return state.setIn([stateKeys.registryByNumber], Map(payload));
    }
    case types.SET_INDUSTRIAL_DESIGNS_NEXT_NUMBER: {
      return state.setIn([globalStateKeys.getNextNumber], payload);
    }

    default:
      return state;
  }
};
