const asyncTypes = Object.freeze({
  GET_EMPLOYEE_BY_ID_ASYNC: 'GET_EMPLOYEE_BY_ID_ASYNC',
  PUT_EMPLOYEE_BY_ID_ASYNC: 'PUT_EMPLOYEE_BY_ID_ASYNC',
  POST_EMPLOYEE_ASYNC: 'POST_EMPLOYEE_ASYNC',
  POST_INVITE_EMPLOYEE_ASYNC: 'POST_INVITE_EMPLOYEE_ASYNC',
  GET_EMPLOYEE_AUTOCOMPLETE_ASYNC: 'GET_EMPLOYEE_AUTOCOMPLETE_ASYNC',
  GET_KEYS_DATA_ASYNC: 'GET_KEYS_DATA_ASYNC',
  POST_KEY_DATA_ASYNC: 'POST_KEY_DATA_ASYNC',
  CSK_DATA_ASYNC: 'CSK_DATA_ASYNC',
  DELETE_KEY_ASYNC: 'DELETE_KEY_ASYNC',
  DELETE_EMPLOYEE_ASYNC: 'DELETE_EMPLOYEE_ASYNC',
  GET_RESPONSIBLE_EMPLOYEE_BY_ID_ASYNC: 'GET_RESPONSIBLE_EMPLOYEE_BY_ID_ASYNC',
});

export default asyncTypes;
