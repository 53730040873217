// core
import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import {
  Field, Form, reduxForm,
} from 'redux-form/lib/immutable';

// lodash
import {
  filter,
} from 'lodash';

// config
import selectors from '../../../engine/config/selectors';

// parts
import RenderTextField from '../../../ui/Form/RenderTextField';
import Modal from '../../../components/Modal/Modal';
import DialogActions from '../../../components/Modal/components/DialogActions';
import Select from '../../../ui/Form/Select';

// actions
import importActions from '../../../engine/core/importObjects/action';

// style

function ImportObjectsModal(props) {
  const {
    handleSubmit,
    isModalOpen, initialize,
    destroy, handleSend,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [readyInitialize, setReadyInitialize] = useState(false);

  const handleCloseModal = () => {
    dispatch(importActions.setIsModalOpenObject(false));
  };
  useEffect(() => function cleanup() {
    destroy();
  }, [destroy, dispatch]);

  useEffect(() => {
    if (!readyInitialize) {
      initialize({ entity: 'trademarks' });
      setReadyInitialize(true);
    }
  }, [readyInitialize, initialize]);

  const handleSubmits = (formData) => {
    const json = formData.toJS();
    handleSend({
      entityName: json.entity,
      items: [
        ...filter(json.applicationNumber?.split(/\s*[\n,]\s*/), (item) => item),
        ...filter(json.registrationNumbers?.split(/\s*[\n,]\s*/), (item) => item),
      ],
    });
    dispatch(importActions.setIsModalOpenObject(false));
  };

  return (
    <Modal
      title={t('Object numbers')}
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
      displayDialogActions={false}
    >
      <Form onSubmit={handleSubmit(handleSubmits)}>
        <Field
          name="entity"
          id="entity"
          label={t('Object type')}
          labelId="entity"
          my={1}
          items={[
            { value: 'trademarks', name: t('trademarks') },
            { value: 'inventions', name: t('inventions') },
            { value: 'utility', name: t('utility') },
          ]}
          fullWidth
          component={Select}
        />
        <Field
          fullWidth
          multiline
          rows={2}
          name="applicationNumber"
          id="applicationNumber"
          label={t('Application numbers')}
          margin="normal"
          variant="outlined"
          component={RenderTextField}
        />
        <Field
          name="registrationNumbers"
          id="registrationNumbers"
          label={t('Registration numbers')}
          margin="normal"
          variant="outlined"
          my={2}
          fullWidth
          type="text"
          multiline
          component={RenderTextField}
          rows={2}
        />
        <DialogActions
          handleCloseModal={handleCloseModal}
          buttonTextSend="ADD"
        />
      </Form>
    </Modal>
  );
}

ImportObjectsModal.displayName = 'ImportObjectsModal';

ImportObjectsModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  destroy: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  handleSend: PropTypes.func,
};

ImportObjectsModal.defaultProps = {
  handleSend: () => {},
};

function mapStateToProps(state) {
  return {
    isModalOpen: selectors.importObjects.isModalOpenObject(state),
  };
}

export default compose(
  reduxForm({
    form: 'ImportObjectsModal',
  }),
  connect(mapStateToProps, null),
)(ImportObjectsModal);
