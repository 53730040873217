// Core
import { call, put } from 'redux-saga/effects';

// config
import { api } from '../../../../config/api';

// actions
import action from '../../action';

export function* callGetActionTypeByIdWorker({ payload: id }) {
  const { setActionTypeById } = action;
  yield put(setActionTypeById({ pending: true }));

  const response = yield call(api.actionTypes.getActionTypeById, id);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    yield put(setActionTypeById({ data }));
  }

  yield put(setActionTypeById({ pending: false }));
}
