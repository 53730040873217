// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const fieldsSettingsActionAsync = Object.freeze({
  getFieldsSettingsAsync(params) {
    return actionCreator(asyncTypes.GET_FIELDS_SETTINGS_ASYNC, params);
  },
  getFieldsEntityAsync(params) {
    return actionCreator(asyncTypes.GET_FIELDS_ENTITY_ASYNC, params);
  },
});

export default fieldsSettingsActionAsync;
