// core
import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';

// ui
import { Button as MuiButton, CssBaseline, Typography } from '@material-ui/core';
import { spacing } from '@material-ui/system';

const Button = styled(MuiButton)(spacing);

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)}px;
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;
const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.body.background};
  }
`;

const Root = styled.div`
  max-width: 520px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;
function Page500() {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Wrapper>
        <Helmet title="500 Error" />
        <Typography component="h1" variant="h1" align="center" gutterBottom>
          500
        </Typography>
        <Typography component="h2" variant="h5" align="center" gutterBottom>
          Internal server error.
        </Typography>
        <Typography component="h2" variant="body1" align="center" gutterBottom>
          The server encountered something unexpected that didnt allow it to
          complete the request.
        </Typography>

        <Button
          component={Link}
          to="/"
          variant="contained"
          color="secondary"
          mt={2}
        >
          Return to website
        </Button>
      </Wrapper>
    </Root>

  );
}

export default Page500;
