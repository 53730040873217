// core
import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import {
  Field, Form, reduxForm,
} from 'redux-form/lib/immutable';

// config
import selectors from '../../../engine/config/selectors';

// parts
import RenderTextField from '../../../ui/Form/RenderTextField';
import Modal from '../../../components/Modal/Modal';
import DialogActions from '../../../components/Modal/components/DialogActions';
import Select from '../../../ui/Form/Select';

// actions
import asyncAction from '../../../engine/core/taxes/saga/asyncAction';
import actions from '../../../engine/core/taxes/action';
import categoryAsyncActions from '../../../engine/core/prices/category/saga/asyncAction';
import categoryActions from '../../../engine/core/prices/category/action';
import accessList from '../../../engine/config/accessList';
import { useAccessList } from '../../../ui/_hooks/useAccessList';

function TaxModal(props) {
  const {
    handleSubmit,
    isModalOpen,
    destroy,
    pendingPostTax,
    categoriesData,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const accessCategoriesList = useAccessList(accessList.costsCategories_list_get);

  const handleCloseModal = () => {
    dispatch(actions.setIsModalOpen(false));
  };
  useEffect(() => {
    if (accessCategoriesList) dispatch(categoryAsyncActions.getListAsync());
  }, [dispatch, accessCategoriesList]);

  useEffect(() => function cleanup() {
    destroy();
    dispatch(categoryActions.setCategoriesData({
      items: [],
      totalCount: 0,
      pending: false,
    }));
  }, [destroy, dispatch]);

  const handleSubmits = (formData) => {
    const json = formData.toJS();
    dispatch(asyncAction.postTaxAsync(json));
  };

  return (
    <Modal
      title={t('Tax')}
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
      displayDialogActions={false}
    >
      <Form onSubmit={handleSubmit(handleSubmits)}>
        <Field
          name="name"
          id="name"
          label={t('Name')}
          margin="normal"
          variant="standard"
          my={2}
          fullWidth
          type="text"
          component={RenderTextField}
          required
        />

        <Field
          name="costsCategories"
          id="costsCategories"
          label={t('Categories')}
          labelId="costsCategories"
          items={categoriesData.toJS().items}
          fullWidth
          multiple
          component={Select}
        />

        <DialogActions
          pending={pendingPostTax}
          handleCloseModal={handleCloseModal}
        />
      </Form>
    </Modal>
  );
}

TaxModal.displayName = 'TaxModal';

TaxModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  pendingPostTax: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  destroy: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  categoriesData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

TaxModal.defaultProps = {};

function mapStateToProps(state) {
  return {
    isModalOpen: selectors.taxes.isModalOpen(state),
    pendingPostTax: selectors.taxes.pendingPostTax(state),
    categoriesData: selectors.pricesCategory.categoriesData(state),
  };
}

export default compose(
  reduxForm({
    form: 'TaxModal',
  }),
  connect(mapStateToProps, null),
)(TaxModal);
