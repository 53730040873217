import { matchSorter } from 'match-sorter';

export const searchByProperty = (list, query, searchBy) => matchSorter(list, query, {
  keys: [
    searchBy,
    `children.*.${searchBy}`,
    `children.*.children.*.${searchBy}`,
    `children.*.children.*.children.*.${searchBy}`,
    `children.*.children.*.children.*.children.*.${searchBy}`,
    `children.*.children.*.children.*.children.*.children.*.${searchBy}`,
    `children.*.children.*.children.*.children.*.children.*.children.*.${searchBy}`,
  ],
});
