const asyncTypes = Object.freeze({
  GET_PRICES_TARIFF_TABLE_ASYNC: 'GET_PRICES_TARIFF_TABLE_ASYNC',
  GET_PRICES_TARIFF_TABLE_INITIAL_STATE_ASYNC: 'GET_PRICES_TARIFF_TABLE_INITIAL_STATE_ASYNC',
  POST_PRICES_TARIFF_TABLE_ASYNC: 'POST_PRICES_TARIFF_TABLE_ASYNC',
  GET_PRICES_TARIFF_TABLE_BY_ID_ASYNC: 'GET_PRICES_TARIFF_TABLE_BY_ID_ASYNC',
  PUT_PRICES_TARIFF_TABLE_BY_ID_ASYNC: 'PUT_PRICES_TARIFF_TABLE_BY_ID_ASYNC',
  DELETE_PRICES_TARIFF_TABLE: 'DELETE_PRICES_TARIFF_TABLE',
  DELETE_PRICES_TARIFF_ASYNC: 'DELETE_PRICES_TARIFF_ASYNC',
  POST_PRICES_TARIFF_ASYNC: 'POST_PRICES_TARIFF_ASYNC',
  PUT_PRICES_TARIFF_ASYNC: 'PUT_PRICES_TARIFF_ASYNC',
  GET_PRICES_TARIFF_ID: 'GET_PRICES_TARIFF_ID',
});

export default asyncTypes;
