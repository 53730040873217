// Core
import {
  useCallback, useEffect, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

// lodash
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import keys from 'lodash/keys';
import isArray from 'lodash/isArray';

// Engine
import asyncActions from '../../../../engine/core/tradeMarks/proposal/saga/asyncAction';
import selectors from '../../../../engine/config/selectors';
import { listEntityName } from '../../../../engine/config/listTablesName';

// Hook
import { useParamsRequest } from '../../../../ui/_hooks/useParamsRequest';

const entityName = listEntityName.trademarks;

export function useProposalList() {
  const currentPage = useSelector(selectors.tradeMarksProposal.currentPage);
  const limit = useSelector(selectors.tradeMarksProposal.pageSize);
  const filters = useSelector(selectors.tradeMarksProposal.filters);
  const sorting = useSelector(selectors.tradeMarksProposal.sorting);
  const reload = useSelector(selectors.tradeMarksProposal.reload);
  const selectedCompany = useSelector(selectors.helpers.selectedCompany);
  const fieldsEntity = useSelector(selectors.fieldsSetting.fieldsEntity);
  const routeKey = useSelector(selectors.router.key);
  const path = useSelector(selectors.router.pathname);
  const dispatch = useDispatch();
  const { orderBy, paramsByFilter } = useParamsRequest({ filters, sorting, entityName });

  const getListAsync = useCallback(() => {
    const params = {
      ...paramsByFilter,
      ...includes(keys(paramsByFilter), 'title:typeRequest:like') ? {
        'title:typeRequest:like': paramsByFilter['title:typeRequest:like'],
        'internalTitle:typeRequest:like': paramsByFilter['title:typeRequest:like'],
        ...!isEmpty(paramsByFilter.connector) ? {
          connector: [
            ...isArray(paramsByFilter.connector)
              ? paramsByFilter.connector
              : [null, paramsByFilter.connector[1]],
            'or',
          ],
        } : {
          connector: { 1: 'or' },
        },
      } : {},
      ...selectedCompany ? {
        companyId: selectedCompany,
      } : {},
      ...orderBy,
      limit,
      page: currentPage + 1,
    };
    dispatch(asyncActions.getListAsync(params));
  }, [paramsByFilter, selectedCompany, currentPage, orderBy, limit, dispatch]);

  const getInitialStateAsync = useCallback(() => {
    dispatch(asyncActions.getInitialStateAsync(path));
  }, [dispatch, path]);

  const getInitialState = useRef(true);

  useEffect(() => {
    if (getInitialState.current) {
      getInitialStateAsync();
      getInitialState.current = false;
    }
    if (reload && !isEmpty(fieldsEntity.toJS())) {
      getListAsync();
    }
  }, [getListAsync, getInitialStateAsync, reload, routeKey, fieldsEntity]);
}
