// Core
import { apply, put } from 'redux-saga/effects';

// config
import { api } from '../../../../../config/api';

// actions
import actions from '../../action';

export function* callGetCheckEmailWorker({ payload }) {
  yield put(actions.setPendingEmailProvider(true));

  const response = yield apply(api, api.email.getCheckEmail, [payload]);

  if (response && response.status >= 200 && response.status < 400) {
    yield put(actions.setEmailProvider(response.data?.provider || ''));
  }

  yield put(actions.setPendingEmailProvider(false));
}
