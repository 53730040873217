// Core
import { all, call, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetNotificationsWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callDeleteNotificationWorker,
  callPostNotificationWorker,
  callGetNotificationByIdWorker,
  callPutNotificationByIdWorker,
  callGetAutocompleteOptionsWorker,
} from './workers';
import types from '../types';

function* watchGetNotifications() {
  yield takeEvery(asyncTypes.GET_NOTIFICATIONS_ASYNC, callGetNotificationsWorker);
}
function* watchGetInitialState() {
  yield takeEvery(
    asyncTypes.GET_NOTIFICATIONS_INITIAL_STATE_ASYNC,
    callGetInitialStateWorker,
  );
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_NOTIFICATIONS_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_NOTIFICATIONS_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_NOTIFICATIONS_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_NOTIFICATIONS_SORTING, callSetToLocalDataWorker);
}
function* watchSetColumnWidths() {
  yield takeEvery(types.SET_NOTIFICATIONS_COLUMN_WIDTHS, callSetToLocalDataWorker);
}
function* watchSetColumnOrder() {
  yield takeEvery(types.SET_NOTIFICATIONS_COLUMN_ORDER, callSetToLocalDataWorker);
}
function* watchSetHiddenColumnNames() {
  yield takeEvery(types.SET_NOTIFICATIONS_HIDDEN_COLUMN_NAMES, callSetToLocalDataWorker);
}

function* watchDeleteNotification() {
  yield takeEvery(asyncTypes.DELETE_NOTIFICATION_ASYNC, callDeleteNotificationWorker);
}

function* watchPostNotification() {
  yield takeEvery(asyncTypes.POST_NOTIFICATION_ASYNC, callPostNotificationWorker);
}

function* watchGetNotificationById() {
  yield takeEvery(
    asyncTypes.GET_NOTIFICATION_BY_ID_ASYNC,
    callGetNotificationByIdWorker,
  );
}

function* watchPutNotificationById() {
  yield takeEvery(
    asyncTypes.PUT_NOTIFICATION_BY_ID_ASYNC,
    callPutNotificationByIdWorker,
  );
}

function* watchGetAutocompleteOptions() {
  yield takeEvery(
    asyncTypes.GET_NOTIFICATIONS_AUTOCOMPLETE_OPTIONS_ASYNC,
    callGetAutocompleteOptionsWorker,
  );
}

export function* watchersNotificationsTable() {
  yield all([
    call(watchSetCurrentPage),
    call(watchSetPageSize),
    call(watchSetFilters),
    call(watchSetSorting),
    call(watchGetInitialState),
    call(watchGetAutocompleteOptions),
    call(watchGetNotifications),
    call(watchDeleteNotification),
    call(watchPostNotification),
    call(watchGetNotificationById),
    call(watchPutNotificationById),
    call(watchSetColumnWidths),
    call(watchSetColumnOrder),
    call(watchSetHiddenColumnNames),
  ]);
}
