const asyncTypes = Object.freeze({
  GET_TAXES_ASYNC: 'GET_TAXES_ASYNC',
  GET_TAXES_INITIAL_STATE_ASYNC: 'GET_TAXES_INITIAL_STATE_ASYNC',
  GET_TAX_BY_ID_ASYNC: 'GET_TAX_BY_ID_ASYNC',
  POST_TAX_ASYNC: 'POST_TAX_ASYNC',
  PUT_TAX_BY_ID_ASYNC: 'PUT_TAX_BY_ID_ASYNC',
  DELETE_TAX_ASYNC: 'DELETE_TAX_ASYNC',
});

export default asyncTypes;
