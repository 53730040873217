const asyncTypes = Object.freeze({
  GET_CLIENT_BY_ID_ASYNC: 'GET_CLIENT_BY_ID_ASYNC',
  PUT_CLIENT_BY_ID_ASYNC: 'PUT_CLIENT_BY_ID_ASYNC',
  DELETE_CLIENT_ASYNC: 'DELETE_CLIENT_ASYNC',
  POST_CLIENT_ASYNC: 'POST_CLIENT_ASYNC',
  GET_CLIENTS_AUTOCOMPLETE_ASYNC: 'GET_CLIENTS_AUTOCOMPLETE_ASYNC',
  POST_MERGE_CLIENTS_ASYNC: 'POST_MERGE_CLIENTS_ASYNC',
  POST_CLIENTS_SENT_CREDENTIALS_ASYNC: 'POST_CLIENTS_SENT_CREDENTIALS_ASYNC',
});

export default asyncTypes;
