// core
import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import { Field, Form, reduxForm } from 'redux-form/lib/immutable';
import styled from 'styled-components';

// lodash
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

// ui
import {
  Box,
} from '@material-ui/core';

// config
import selectors from '../../../engine/config/selectors';
import globalDocumentType from '../../../engine/config/globalDocumentType';
import globalObjectTypes from '../../../engine/config/globalObjectTypes';

// parts
import RenderTextField from '../../../ui/Form/RenderTextField';
import Select from '../../../ui/Form/Select';
import Loader from '../../../components/Loader';
import Modal from '../../../components/Modal/Modal';
import DialogActions from '../../../components/Modal/components/DialogActions';
import FieldNotifications from '../../../ui/Form/FieldsAutocomplete/FieldNotifications';
import AutocompleteField from '../../../ui/Form/AutocompleteField';

// actions
import actionAsync from '../../../engine/core/documentTypes/saga/asyncAction';
import actions from '../../../engine/core/documentTypes/action';
import categoryAsyncActions from '../../../engine/core/documentTypes/category/saga/asyncAction';
import categoryActions from '../../../engine/core/documentTypes/category/action';
import permissionsGroupsAsyncAction from '../../../engine/core/permissionsGroups/saga/asyncAction';

// hooks
import { useEventsAutocompleteAsync } from '../../../ui/_hooks/useEventsAutocompleteAsync';
import accessList from '../../../engine/config/accessList';
import { useAccessList } from '../../../ui/_hooks/useAccessList';

// styles
const ModalPaper = styled(Box)`
  min-height: 400px;
`;

function AddEditDocumentTypeModel(props) {
  const {
    handleSubmit, isModalOpen, pendingPostDocumentType,
    pendingPutDocumentType, getDocumentTypeById, categoriesData,
    initialize, pendingDocumentTypeById, objectStatuses, permissionsGroupsData,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const accessPut = useAccessList(accessList.clients_put);
  const accessGroupsList = useAccessList(accessList.groups_list_get);
  const accessCategoryList = useAccessList(accessList.documentClassifierCategory_list_get);
  const accessEdit = !isEmpty(getDocumentTypeById) ? accessPut : true;

  const {
    getAutocompleteLists, handleGetAsyncData,
  } = useEventsAutocompleteAsync(false);

  useEffect(() => {
    if (accessGroupsList) dispatch(permissionsGroupsAsyncAction.getListAsync());
  }, [dispatch, accessGroupsList]);

  const handleOpenCloseModal = () => {
    dispatch(actions.setDocumentTypeById({
      isModalOpen: false,
      data: {},
    }));
  };
  const modifyToIds = (items) => (
    !isEmpty(items) ? map(items.toJS ? items.toJS() : items, 'id') : []
  );

  const handleSubmits = (formData) => {
    const json = formData.toJSON();
    dispatch(actionAsync[!isEmpty(getDocumentTypeById)
      ? 'putDocumentTypeByIdAsync'
      : 'postDocumentTypeAsync']({
      ...json,
      notifications: modifyToIds(json.notifications),
      ...!isEmpty(json?.category) ? {
        category: modifyToIds(json.category),
      } : {},
      ...!isEmpty(json?.rolesAccess) ? {
        rolesAccess: modifyToIds(json.rolesAccess),
      } : {},
    }));
  };

  useEffect(() => {
    initialize(!isEmpty(getDocumentTypeById) ? getDocumentTypeById : {});
  }, [
    getDocumentTypeById, initialize,
  ]);

  useEffect(() => {
    if (accessCategoryList) dispatch(categoryAsyncActions.getListAsync());
  }, [dispatch, accessCategoryList]);

  useEffect(() => function cleanup() {
    const emptyData = { items: [], pending: false, totalCount: 0 };
    dispatch(categoryActions.setDocumentTypesCategoryData(emptyData));
  }, [dispatch]);

  return (
    <Modal
      title={t('Document type')}
      isModalOpen={isModalOpen}
      handleCloseModal={handleOpenCloseModal}
      displayDialogActions={false}
    >
      <ModalPaper>
        {!pendingDocumentTypeById ? (
          <Form onSubmit={handleSubmit(handleSubmits)}>
            <Field
              name="name"
              id="name"
              label={t('Title')}
              margin="normal"
              variant="standard"
              my={2}
              fullWidth
              type="text"
              component={RenderTextField}
              disabled={!accessEdit}
            />

            <Field
              name="shortName"
              id="shortName"
              label={t('Short name')}
              margin="normal"
              variant="standard"
              my={2}
              fullWidth
              type="text"
              component={RenderTextField}
              disabled={!accessEdit}
            />

            <Field
              name="category"
              id="category"
              label={t('Category')}
              labelId="category"
              my={2}
              margin="normal"
              fullWidth
              multiple
              options={categoriesData.toJS().items}
              component={AutocompleteField}
              disabled={!accessEdit}
            />

            <Field
              name="rolesAccess"
              id="rolesAccess"
              label={t('Access level')}
              labelId="rolesAccess"
              my={2}
              margin="normal"
              fullWidth
              multiple
              options={permissionsGroupsData.toJS().items}
              component={AutocompleteField}
              disabled={!accessEdit}
            />

            <Field
              name="idDocType"
              id="idDocType"
              label={t('Code of the document in the Office')}
              margin="normal"
              variant="standard"
              my={2}
              fullWidth
              type="text"
              component={RenderTextField}
              disabled={!accessEdit}
            />

            <FieldNotifications
              name="notifications"
              formName="AddDocumentTypeModel"
              getAutocompleteLists={getAutocompleteLists}
              getAsyncData={handleGetAsyncData}
              propsField={{
                multiple: true,
                disabled: !accessEdit,
              }}
            />

            <Field
              name="objectStatuses"
              id="objectStatuses"
              label={t('Transfer to status')}
              labelId="objectStatuses"
              my={1}
              items={objectStatuses}
              fullWidth
              component={Select}
              disabled={!accessEdit}
            />

            <Field
              name="type"
              id="type"
              label={t('Type')}
              labelId="type"
              my={2}
              items={globalDocumentType}
              fullWidth
              component={Select}
              disabled={!accessEdit}
            />

            <Field
              name="objectType"
              id="objectType"
              label={t('Object type')}
              labelId="objectType"
              my={2}
              items={globalObjectTypes}
              fullWidth
              component={Select}
              disabled={!accessEdit}
            />

            <DialogActions
              pending={pendingPutDocumentType || pendingPostDocumentType}
              buttonTextSend="CONFIRM"
              handleCloseModal={handleOpenCloseModal}
              visibleButtonSend={accessEdit}
            />
          </Form>
        ) : <Loader position />}
      </ModalPaper>
    </Modal>
  );
}

AddEditDocumentTypeModel.displayName = 'AddEditDocumentTypeModel';

AddEditDocumentTypeModel.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  pendingPostDocumentType: PropTypes.bool.isRequired,
  pendingPutDocumentType: PropTypes.bool.isRequired,
  pendingDocumentTypeById: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  getDocumentTypeById: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  objectStatuses: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  categoriesData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  permissionsGroupsData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

AddEditDocumentTypeModel.defaultProps = {
  getDocumentTypeById: {},
};

function mapStateToProps(state) {
  return {
    isModalOpen: selectors.documentTypes.isModalOpen(state),
    pendingDocumentTypeById: selectors.documentTypes.pendingDocumentTypeById(state),
    pendingPostDocumentType: selectors.documentTypes.pendingPostDocumentType(state),
    pendingPutDocumentType: selectors.documentTypes.pendingPutDocumentType(state),
    getDocumentTypeById: selectors.documentTypes.getDocumentTypeById(state),
    objectStatuses: selectors.helpers.getObjectStatuses(state),
    categoriesData: selectors.documentTypesCategory.documentTypesCategoryData(state),
    permissionsGroupsData: selectors.permissionsGroups.permissionsGroupsData(state),
  };
}

export default compose(
  reduxForm({
    form: 'AddDocumentTypeModel',
  }),
  connect(mapStateToProps, null),
)(AddEditDocumentTypeModel);
