// Core
import { List, Map } from 'immutable';

// Instruments
import { isNil } from 'lodash';
import types from './types';
import { pageSizes } from '../../config/globalConfig';
import globalStateKeys from '../../config/globalStateKeys';

export const initialState = Map({
  itemByHashId: {
    data: {},
    pending: false,
    pendingPut: false,
    pendingPost: false,
  },
  data: Map({
    items: List(),
    currentPage: 0,
    pending: true,
    totalCount: 0,
    filters: List(),
    sorting: List(),
    pageSize: pageSizes[0],
    reload: false,
    isModalOpen: false,
    columnWidths: List(),
    columnOrder: List(),
    hiddenColumnNames: List(),
  }),
  autocompleteOptions: Map({
    items: Map(),
  }),
  delete: Map({
    pendingDelete: false,
    id: '',
  }),
  pending: false,
});

export const stateKeys = Object.freeze({
  controller: 'paymentReceivers',
});

export const paymentReceiversReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_PAYMENT_RECEIVERS_DATA: {
      const { items, totalCount, pending } = payload;
      if (pending) {
        return state.setIn([globalStateKeys.data, globalStateKeys.pending], pending);
      }
      return state
        .setIn([globalStateKeys.data, globalStateKeys.pending], pending)
        .setIn([globalStateKeys.data, globalStateKeys.items], List(items))
        .setIn([globalStateKeys.data, globalStateKeys.totalCount], totalCount);
    }
    case types.SET_PAYMENT_RECEIVERS_RELOAD: {
      return state.setIn([globalStateKeys.data, globalStateKeys.reload], payload);
    }
    case types.SET_DELETE_PAYMENT_RECEIVERS: {
      return state.mergeIn([globalStateKeys.delete], payload);
    }
    case types.SET_PAYMENT_RECEIVERS_MODAL_OPEN: {
      if (payload) {
        return state.setIn([globalStateKeys.data, globalStateKeys.isModalOpen], payload);
      }
      return state
        .setIn([globalStateKeys.data, globalStateKeys.isModalOpen], payload)
        .setIn([globalStateKeys.itemByHashId, globalStateKeys.data], {});
    }
    case types.SET_PAYMENT_RECEIVERS_CURRENT_PAGE: {
      return state.setIn([globalStateKeys.data, globalStateKeys.currentPage], payload);
    }
    case types.SET_PAYMENT_RECEIVERS_PAGE_SIZE: {
      return state.setIn([globalStateKeys.data, globalStateKeys.pageSize], payload);
    }
    case types.SET_PAYMENT_RECEIVERS_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, sorting = [],
        columnWidths, columnOrder, hiddenColumnNames,
      } = payload;
      const reload = true;
      const currentSorting = sorting.length
        ? sorting
        : initialState.getIn([globalStateKeys.data, globalStateKeys.sorting]);
      return state
        .setIn([globalStateKeys.data, globalStateKeys.currentPage], currentPage)
        .setIn([globalStateKeys.data, globalStateKeys.filters], List(filters))
        .setIn([globalStateKeys.data, globalStateKeys.pageSize], pageSize)
        .setIn([globalStateKeys.data, globalStateKeys.totalCount], totalCount)
        .setIn([globalStateKeys.data, globalStateKeys.reload], reload)
        .setIn([globalStateKeys.data, globalStateKeys.columnWidths], List(columnWidths))
        .setIn([globalStateKeys.data, globalStateKeys.columnOrder], List(columnOrder))
        .setIn([globalStateKeys.data, globalStateKeys.hiddenColumnNames], List(hiddenColumnNames))
        .setIn([globalStateKeys.data, globalStateKeys.sorting], List(currentSorting));
    }
    case types.SET_PAYMENT_RECEIVERS_BY_ID: {
      return state.mergeIn([globalStateKeys.itemByHashId], payload);
    }
    case types.SET_PAYMENT_RECEIVERS_FILTER: {
      const filters = payload.filter((item) => !isNil(item.value));
      return state.setIn([globalStateKeys.data, globalStateKeys.filters], List(filters));
    }
    case types.SET_PAYMENT_RECEIVERS_SORTING: {
      return state.setIn([globalStateKeys.data, globalStateKeys.sorting], List(payload));
    }
    case types.SET_PAYMENT_RECEIVERS_COLUMN_WIDTHS: {
      return state.setIn([globalStateKeys.data, globalStateKeys.columnWidths], List(payload));
    }
    case types.SET_PAYMENT_RECEIVERS_COLUMN_ORDER: {
      return state.setIn([globalStateKeys.data, globalStateKeys.columnOrder], List(payload));
    }
    case types.SET_PAYMENT_RECEIVERS_HIDDEN_COLUMN_NAMES: {
      return state.setIn([globalStateKeys.data, globalStateKeys.hiddenColumnNames], List(payload));
    }
    default:
      return state;
  }
};
