// core
import React, {
  useMemo, memo, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';

// lodash
import {
  isEmpty, some, map, isEqual,
  find, floor, divide,
} from 'lodash';

// ui
import {
  FormGroup as FormGroupMui,
  Checkbox, FormControlLabel,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import DxTable from '../../../../../ui/Table/DxTable';

// parts
import RecalculationModal from './RecalculationModal';

// actions
import chargesActionsAsync from '../../../../../engine/core/charges/saga/asyncAction';
import helpersAsyncActions from '../../../../../engine/core/helpers/saga/asyncAction';
import currenciesAsyncActions from '../../../../../engine/core/currencies/currenciesList/saga/asyncAction';
import currenciesActions from '../../../../../engine/core/currencies/currenciesList/action';
import actions from '../../../../../engine/core/charges/action';

// config
import selectors from '../../../../../engine/config/selectors';

// hooks
import { useProposalCharges } from '../_hooks/useProposalCharges';
import accessList from '../../../../../engine/config/accessList';
import { useAccessList } from '../../../../../ui/_hooks/useAccessList';

// styles
const FormGroup = styled(FormGroupMui)(spacing);

function ChargesTabs(props) {
  const {
    tab, chargesSelections, pageSize, currenciesData,
    totalCount, pending, currentPage, isModalOpenChargesEdit,
    chargesTabs, filters, sorting, isModalOpenAddCharges,
  } = props;
  // const { params } = match;
  useProposalCharges({
    stopInitialState: true,
  });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [toCurrency, setToCurrency] = useState(0);
  const [editCharge, setEditCharge] = useState({});
  const [getIds, setIds] = useState([]);
  const tabs = chargesTabs.toJS();
  const selection = chargesSelections.toJS();
  const accessCurrenciesList = useAccessList(accessList.currencies_list_get);

  const tabItems = useMemo(() => {
    switch (tab) {
      case 1: {
        return tabs.fees;
      }
      case 2: {
        return tabs.services;
      }
      case 3: {
        return tabs.works;
      }
      default:
        return tabs.all;
    }
  }, [
    tab, tabs,
  ]);

  const getSelections = useMemo(() => {
    switch (tab) {
      case 1: {
        return selection.fees;
      }
      case 2: {
        return selection.services;
      }
      case 3: {
        return selection.works;
      }
      default:
        return selection.all;
    }
  }, [tab, selection]);

  useEffect(() => {
    if (accessCurrenciesList) dispatch(currenciesAsyncActions.getListAsync());
  }, [dispatch, accessCurrenciesList]);

  useEffect(() => function cleanup() {
    dispatch(currenciesActions.setCurrenciesData({
      items: [],
      totalCount: 0,
      pending: false,
    }));
  }, [dispatch]);

  const basePriseCode = useMemo(() => {
    const findCurrency = find(currenciesData.toJS().items, 'default');
    return findCurrency?.code || '';
  }, [currenciesData]);

  const columns = [
    { name: 'id', title: t('ID') },
    { name: 'name', title: t('Title') },
    { name: 'sum', title: basePriseCode ? `${t('Amount')} (${basePriseCode})` : t('Amount') },
    { name: 'orders', title: t('Order'), customField: 'chargesField' },
    { name: 'chargeDate', title: t('Date') },
    { name: 'sumBill', title: t('To pay') },
    { name: 'sumPayed', title: t('Payed') },
    { name: 'currency', title: t('Currency') },
    { name: 'rate', title: t('Course') },
    { name: 'includeToBill', title: t('To the account') },
  ];

  const editingStateColumnExtensions = [
    { columnName: 'id', editingEnabled: false },
    { columnName: 'name', editingEnabled: false },
    { columnName: 'sum' },
    { columnName: 'sumPayed' },
    { columnName: 'orders', editingEnabled: false },
    { columnName: 'chargeDate', editingEnabled: false },
    { columnName: 'rate' },
    { columnName: 'sumBill' },
    { columnName: 'currency' },
    { columnName: 'includeToBill' },
  ];

  // Paging
  const onCurrentPage = (page) => dispatch(actions.setCurrentPage(page));
  const onPageSize = (size) => dispatch(actions.setPageSize(size));

  // Filtering
  const setFilters = (getFilters) => dispatch(actions.setFilters(getFilters));

  // Sorting
  const onSortingChange = (getSorting) => dispatch(actions.setSorting(getSorting));

  const handleChangeSections = (child) => (event) => {
    dispatch(chargesActionsAsync.setSectionsAsync({
      tab, checked: event.target.checked, child,
    }));
  };

  useEffect(() => {
    if (!isModalOpenChargesEdit && (!isEmpty(editCharge) || toCurrency)) {
      setToCurrency(0);
      setEditCharge({});
      setIds([]);
    }
  }, [
    isModalOpenChargesEdit, setEditCharge, setToCurrency, editCharge, toCurrency,
  ]);

  const handleEditingChange = ({ changed }) => {
    const values = Object.values(changed)[0];
    const ids = Object.keys(changed);
    if (isEmpty(values)) return;
    if (values.currency) {
      setToCurrency(values.currency);
      setEditCharge(find(tabs.all, { id: Number(ids[0]) }) || {});
      setIds(ids);
      dispatch(actions.setModalChargesEdit(true));
    } else {
      const findItem = find(tabs.all, { id: Number(ids[0]) });
      const newValues = values.rate ? {
        ...values,
        sumBill: floor(divide(findItem.sum || 0, Number(values.rate)), 2),
      } : values;
      const chargesAll = map(tabs.all, (item) => (
        item.id === Number(ids[0]) ? { ...item, ...newValues } : item));
      const chargesWorks = map(tabs.works, (item) => (
        item.id === Number(ids[0]) ? { ...item, ...newValues } : item));
      dispatch(actions.setTabs({
        all: chargesAll,
        fees: chargesAll,
        services: chargesAll,
        works: chargesWorks,
      }));
      dispatch(helpersAsyncActions.putMultipleAsync({
        body: {
          entityName: 'charges',
          entityIds: ids,
          fields: newValues,
        },
      }));
      if (values.sum) {
        dispatch(actions.setChargesData({
          items: chargesAll,
          pending: false,
          totalCount,
        }));
        setTimeout(() => {
          dispatch(chargesActionsAsync.setSectionsAsync({
            tab, selected: getSelections,
          }));
        });
      }
    }
  };

  const onSelectionChange = (selected) => {
    dispatch(chargesActionsAsync.setSectionsAsync({
      tab, selected,
    }));
  };

  const RenderDetailComponent = (args) => {
    const { children } = args.row;

    return (
      <FormGroup ml={5.5}>
        {!isEmpty(children) ? map(children, (child) => (
          <FormControlLabel
            key={child.id}
            checked={some(selection.worksChildren, (value) => value === child.id)}
            control={(
              <Checkbox
                name={`${child.id}`}
                onChange={handleChangeSections(child)}
                color="primary"
              />
            )}
            label={child.name}
          />
        )) : []}
      </FormGroup>
    );
  };

  return (
    <>
      <DxTable
        name="Charges table"
        columns={columns}
        rows={tabItems}
        isLoading={pending}
        // Paging
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageSize={onPageSize}
        onCurrentPage={onCurrentPage}
        editingExtensions={editingStateColumnExtensions}
        onEditingChange={handleEditingChange}
        // selections
        selection={getSelections}
        {...tab === 3 ? {
          detailComponent: RenderDetailComponent,
          defaultExpandedRowIds: selection.works,
        } : {}}
        onSelectionChange={onSelectionChange}
        filtersAction={setFilters}
        filters={filters}
        sorting={sorting}
        onSortingChange={onSortingChange}
        isModalOpen={isModalOpenAddCharges || isModalOpenChargesEdit}
        disableHiddenColumn
        disableColumnOrder
        disableColumnWidth
        disableExport
      />

      {isModalOpenChargesEdit && (
        <RecalculationModal
          toCurrency={toCurrency}
          editCharge={editCharge}
          ids={getIds}
        />
      )}
    </>
  );
}

ChargesTabs.propTypes = {
  tab: PropTypes.number.isRequired,
  match: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  chargesTabs: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  chargesSelections: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  // table
  totalCount: PropTypes.number.isRequired,
  pending: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  sorting: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  isModalOpenAddCharges: PropTypes.bool.isRequired,
  isModalOpenChargesEdit: PropTypes.bool.isRequired,
  currenciesData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

ChargesTabs.defaultProps = {
  match: {},
};

function mapStateToProps(state) {
  return {
    pending: selectors.charges.pending(state),
    chargesTabs: selectors.charges.tabs(state),
    chargesSelections: selectors.charges.selections(state),
    // Paging
    totalCount: selectors.charges.totalCount(state),
    currentPage: selectors.charges.currentPage(state),
    pageSize: selectors.charges.pageSize(state),

    // Filtering
    filters: selectors.charges.filters(state),

    sorting: selectors.charges.sorting(state),

    isModalOpenAddCharges: selectors.helpers.isModalOpenAddCharges(state),
    isModalOpenChargesEdit: selectors.charges.isModalOpenChargesEdit(state),
    currenciesData: selectors.currencies.currenciesData(state),
  };
}

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.pending, nextProps.pending)
    && isEqual(prevProps.chargesTabs, nextProps.chargesTabs)
    && isEqual(prevProps.chargesSelections, nextProps.chargesSelections)
    && isEqual(prevProps.currentPage, nextProps.currentPage)
    && isEqual(prevProps.pageSize, nextProps.pageSize)
    && isEqual(prevProps.totalCount, nextProps.totalCount)
    && isEqual(prevProps.currenciesData, nextProps.currenciesData)
    && isEqual(prevProps.isModalOpenChargesEdit, nextProps.isModalOpenChargesEdit)
    && isEqual(prevProps.isModalOpenAddCharges, nextProps.isModalOpenAddCharges);
}

export default compose(
  connect(mapStateToProps, null),
)(memo(ChargesTabs, areEqual));
