// Core
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

// Lodash
import {
  map, isEmpty,
} from 'lodash';

// parts
import { Box } from '@material-ui/core';
import DxTable from '../../../ui/Table/DxTable';
import CheckDocuments from '../../../components/CheckDocuments/CheckDocuments';

// actions
import actions from '../../../engine/core/receiving/action';
import asyncActions from '../../../engine/core/receiving/saga/asyncAction';
import helpersActions from '../../../engine/core/helpers/action';

// config
import selectors from '../../../engine/config/selectors';
import ConfirmModal from '../../../components/ConfirmModal';

import { listTablesName, listEntityName } from '../../../engine/config/listTablesName';
import { useAccessList } from '../../../ui/_hooks/useAccessList';
import accessList from '../../../engine/config/accessList';
import DocViewerModal from '../../../components/DocViewerModal/DocViewerModal';

const tableName = listTablesName.indocs;
const entityName = listEntityName.indocs;

function TariffTable(props) {
  const {
    pendingDelete, data, totalCount, pending, currentPage, filters, pageSize, checkboxes,
    sorting, columnWidths, columnOrder, hiddenColumnNames, isModalOpenDocViewer,
    pendingPreviewFileById,
  } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const accessCheckPost = useAccessList(accessList.documents_check_post);

  const columns = [
    { name: 'id', title: t('ID') },
    { name: 'textContent', title: t('Content') },
    { name: 'receivedAt', title: t('Received At') },
    { name: 'outDocs', title: t('OutDocs') },
    { name: 'trademarks', title: t('trademarks') },
    { name: 'industrial', title: t('industrial') },
    { name: 'inventions', title: t('inventions') },
    { name: 'utility', title: t('utility') },
  ];

  const tableColumnExtensions = [
    { columnName: 'id', width: 50 },
    { columnName: 'textContent', width: '15rem' },
    { columnName: 'receivedAt', width: '10rem' },
    { columnName: 'outDocs', width: '15rem' },
    { columnName: 'trademarks', width: '10rem' },
    { columnName: 'industrial', width: '10rem' },
    { columnName: 'inventions', width: '10rem' },
    { columnName: 'utility', width: '10rem' },
  ];

  // Paging
  const onCurrentPage = (page) => dispatch(actions.setCurrentPage(page));
  const onPageSize = (size) => dispatch(actions.setPageSize(size));

  // Filtering
  const setFilters = (getFilters) => dispatch(actions.setFilters(getFilters));

  // Sorting
  const onSortingChange = (getSorting) => dispatch(actions.setSorting(getSorting));
  const onColumnWidthsChange = (getColumnWidths) => dispatch(
    actions.setColumnWidths(getColumnWidths),
  );
  const onColumnOrderChange = (getColumnOrder) => dispatch(
    actions.setColumnOrder(getColumnOrder),
  );
  const onHiddenColumnNamesChange = (getHiddenColumnNames) => dispatch(
    actions.setHiddenColumnNames(getHiddenColumnNames),
  );

  return (
    <>
      {accessCheckPost && (
        <Box mb={3}>
          <CheckDocuments />
        </Box>
      )}

      <DxTable
        name="TariffTable"
        isLoading={pending || pendingPreviewFileById}
        columns={columns}
        rows={data.toJS().items}
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageSize={onPageSize}
        onCurrentPage={onCurrentPage}
        // checkboxes ------------------------
        tableName={tableName}
        reloadTable={actions.setReload}
        selection={checkboxes.toJS()}
        onSelectionChange={(checkboxSelect) => {
          dispatch(helpersActions.setCheckbox({
            tableName,
            checkboxes: checkboxSelect,
          }));
        }}
        // ------------------------
        filtersAction={setFilters}
        filters={filters}
        entityName={entityName}
        sorting={sorting}
        onSortingChange={onSortingChange}
        tableColumnExtensions={!isEmpty(columnWidths) && columnWidths.length === columns.length
          ? columnWidths : tableColumnExtensions}
        onColumnWidthsChange={onColumnWidthsChange}
        columnOrder={!isEmpty(columnOrder) ? columnOrder : map(columns, 'name')}
        onColumnOrderChange={onColumnOrderChange}
        hiddenColumnNames={hiddenColumnNames}
        onHiddenColumnNamesChange={onHiddenColumnNamesChange}
        editComponentWidth={60}
        accessDelete={accessList.inDocs_delete}
      />

      {isModalOpenDocViewer && <DocViewerModal />}
      <ConfirmModal
        buttonSendText="DELETE"
        pending={pendingDelete}
        handleSend={() => dispatch(asyncActions.delete())}
      />
    </>
  );
}

TariffTable.displayName = 'TariffTable';

TariffTable.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingDelete: PropTypes.bool.isRequired,
  pending: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalCount: PropTypes.number,
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  sorting: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  checkboxes: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  columnWidths: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  columnOrder: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  hiddenColumnNames: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  isModalOpenDocViewer: PropTypes.bool.isRequired,
  pendingPreviewFileById: PropTypes.bool.isRequired,
};

TariffTable.defaultProps = {
  totalCount: 0,
};

function mapStateToProps(state) {
  return {
    data: selectors.receiving.receivingData(state),
    pending: selectors.receiving.pending(state),
    pendingDelete: selectors.receiving.pendingDelete(state),

    // Paging
    totalCount: selectors.receiving.totalCount(state),
    currentPage: selectors.receiving.currentPage(state),
    pageSize: selectors.receiving.pageSize(state),

    // Filtering
    filters: selectors.receiving.filters(state),

    sorting: selectors.receiving.sorting(state),

    // ColumnWidths
    columnWidths: selectors.receiving.columnWidths(state),
    columnOrder: selectors.receiving.columnOrder(state),
    hiddenColumnNames: selectors.receiving.hiddenColumnNames(state),

    checkboxes: selectors.helpers.getCheckboxes(state, tableName),
    isModalOpenDocViewer: selectors.helpers.isModalOpenDocViewer(state),
    pendingPreviewFileById: selectors.helpers.pendingPreviewFileById(state),
  };
}

function mapDispatchToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(TariffTable);
