export default Object.freeze({
  SET_UTILITY_MODELS_PROPOSAL_DATA_BY_ID: 'SET_UTILITY_MODELS_PROPOSAL_DATA_BY_ID',
  SET_UTILITY_MODELS_PROPOSAL_DATA: 'SET_UTILITY_MODELS_PROPOSAL_DATA',
  SET_UTILITY_MODELS_PROPOSAL_CURRENT_PAGE: 'SET_UTILITY_MODELS_PROPOSAL_CURRENT_PAGE',
  SET_UTILITY_MODELS_PROPOSAL_PAGE_SIZE: 'SET_UTILITY_MODELS_PROPOSAL_PAGE_SIZE',
  SET_UTILITY_MODELS_PROPOSAL_FILTERS: 'SET_UTILITY_MODELS_PROPOSAL_FILTERS',
  SET_UTILITY_MODELS_PROPOSAL_SORTING: 'SET_UTILITY_MODELS_PROPOSAL_SORTING',
  SET_UTILITY_MODELS_PROPOSAL_INITIAL_STATE: 'SET_UTILITY_MODELS_PROPOSAL_INITIAL_STATE',
  SET_UTILITY_MODELS_PROPOSAL_RELOAD: 'SET_UTILITY_MODELS_PROPOSAL_RELOAD',
  SET_DELETE_UTILITY_MODEL_PROPOSAL: 'SET_DELETE_UTILITY_MODEL_PROPOSAL',
  SET_UTILITY_MODELS_PROPOSAL_AUTOCOMPLETE_OPTIONS: 'SET_UTILITY_MODELS_PROPOSAL_AUTOCOMPLETE_OPTIONS',
  SET_PENDING_UTILITY_MODELS_PROPOSAL_AUTOCOMPLETE_OPTIONS: 'SET_PENDING_UTILITY_MODELS_PROPOSAL_AUTOCOMPLETE_OPTIONS',
  SET_PENDING_GET_SET_UTILITY_MODELS_PROPOSAL_BY_ID: 'SET_PENDING_GET_SET_UTILITY_MODELS_PROPOSAL_BY_ID',
  SET_UTILITY_MODELS_PROPOSAL_UPLOAD_FILES: 'SET_UTILITY_MODELS_PROPOSAL_UPLOAD_FILES',
  SET_UTILITY_MODELS_PROPOSAL_SIGN_LIST: 'SET_UTILITY_MODELS_PROPOSAL_SIGN_LIST',
  SET_UTILITY_MODELS_PENDING_POST_PROPOSAL: 'SET_UTILITY_MODELS_PENDING_POST_PROPOSAL',
  SET_UTILITY_MODELS_PENDING_PUT_PROPOSAL: 'SET_UTILITY_MODELS_PENDING_PUT_PROPOSAL',
  SET_UTILITY_MODELS_PROPOSAL_SIGN_MODAL_IS_OPEN: 'SET_UTILITY_MODELS_PROPOSAL_SIGN_MODAL_IS_OPEN',
  SET_UTILITY_MODELS_PROPOSAL_SIGN_PENDING: 'SET_UTILITY_MODELS_PROPOSAL_SIGN_PENDING',
  SET_UTILITY_MODELS_PENDING_GET_REGISTRY_BY_NUMBER: 'SET_UTILITY_MODELS_PENDING_GET_REGISTRY_BY_NUMBER',
  SET_UTILITY_MODELS_REGISTRY_BY_NUMBER: 'SET_UTILITY_MODELS_REGISTRY_BY_NUMBER',
  SET_UTILITY_MODELS_PROPOSAL_NEXT_NUMBER: 'SET_UTILITY_MODELS_PROPOSAL_NEXT_NUMBER',
  SET_UTILITY_MODELS_PROPOSAL_COLUMN_WIDTHS: 'SET_UTILITY_MODELS_PROPOSAL_COLUMN_WIDTHS',
  SET_UTILITY_MODELS_PROPOSAL_COLUMN_ORDER: 'SET_UTILITY_MODELS_PROPOSAL_COLUMN_ORDER',
  SET_UTILITY_MODELS_PROPOSAL_HIDDEN_COLUMN_NAMES: 'SET_UTILITY_MODELS_PROPOSAL_HIDDEN_COLUMN_NAMES',
});
