// Core
import { call, put } from 'redux-saga/effects';
import { Map } from 'immutable';

// config
import { api } from '../../../../../config/api';

// actions
import actions from '../../action';
import asyncAction from '../asyncAction';

export function* callGetSearchByIdWorker({ payload: id }) {
  const {
    setSearchById, setSelectedClasses,
  } = actions;
  const { setTabsAsync } = asyncAction;
  yield put(setSearchById({
    pending: true,
  }));

  const response = yield call(api.trademarks.getSearchById, id);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    yield put(setSearchById({
      data: Map(data),
    }));
    yield put(setSelectedClasses(data?.classes ? data.classes.split(',') : []));

    yield put(setTabsAsync(data.results));
  }

  yield put(setSearchById({
    pending: false,
  }));
}
