// core
import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import { Field, Form, reduxForm } from 'redux-form/lib/immutable';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';

// lodash
import {
  filter, isNumber, map, find,
  isEqual, isEmpty,
} from 'lodash';

// ui
import {
  Button, Typography, List,
  ListItem as MuiListItem, Box, IconButton,
} from '@material-ui/core';
import {
  Close as CloseIcon,
} from '@material-ui/icons';
import Select from '../../../../../../ui/Form/Select';

// config
import selectors from '../../../../../../engine/config/selectors';

// helpers
import { formFields } from '../helper/form';
import { formNameTariffTable } from '../../helper/form';

// parts
import RenderTextField from '../../../../../../ui/Form/RenderTextField';
import Modal from '../../../../../../components/Modal/Modal';
import DialogActions from '../../../../../../components/Modal/components/DialogActions';

// actions
import actionsAsync from '../../../../../../engine/core/prices/tariffs/tariff/saga/asyncAction';
import actions from '../../../../../../engine/core/prices/tariffs/tariff/action';

const ListItem = styled(MuiListItem)(spacing);

function TariffModal(props) {
  const {
    getDataById,
    handleSubmit,
    hashID,
    isModalOpen,
    pendingPost,
    initialize,
    destroy,
    currenciesData,
    formValues,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openFields, setOpenFields] = useState(false);
  const [prices, setPrices] = useState([]);

  const handleCloseModal = () => dispatch(actions.setModalOpen(false));

  const handleSubmits = (formData) => {
    const jsonData = formData.toJSON();
    const checkId = !isEmpty(getDataById) && isNumber(getDataById.id);

    const { basePrice } = jsonData;

    dispatch(actionsAsync
      // eslint-disable-next-line no-unexpected-multiline
      [checkId ? 'putTariffTableByIdAsync' : 'postTariffTableAsync'](
        checkId
          ? {
            ...getDataById,
            ...jsonData,
            basePrice: +basePrice,
            prices,
          }
          : {
            ...jsonData,
            cost: hashID,
            basePrice: +basePrice,
            prices,
          },
      ));
  };

  useEffect(() => {
    if (!isEmpty(getDataById)) {
      const { cost } = getDataById;

      initialize({
        ...getDataById,
        cost: cost?.id,
      });
      setPrices(getDataById.prices || []);
    }
  }, [initialize, getDataById, setPrices]);

  useEffect(() => function cleanup() {
    destroy();
    setPrices([]);
  }, [dispatch, destroy, setPrices]);

  const handleAddPrice = () => {
    setPrices([
      ...prices,
      {
        currencyId: formValues.toJS().currency,
        price: formValues.toJS().price,
      },
    ]);
    setOpenFields(false);
  };

  const handleDeletePrice = (id) => {
    setPrices([
      ...filter(prices, (item) => item.currencyId !== id),
    ]);
  };

  return (
    <Modal
      title={t('Warehouse tariff')}
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
      displayDialogActions={false}
      maxWidth="xs"
    >
      <Form onSubmit={handleSubmit(handleSubmits)}>
        {map(Object.keys(formFields), (nameField) => {
          if (formFields[nameField] === 'type') {
            return (
              <Field
                key={nameField}
                name={formFields[nameField]}
                id={formFields[nameField]}
                label={t(nameField)}
                margin="normal"
                labelId="role"
                items={[
                  {
                    name: t('Fixed'),
                    value: '1',
                  },
                  {
                    name: t('Quantitative'),
                    value: '2',
                  },
                  {
                    name: t('Hourly'),
                    value: '3',
                  },
                ]}
                variant="standard"
                my={2}
                fullWidth
                type="text"
                component={Select}
              />
            );
          }

          if (formFields[nameField] === 'basePrice') {
            return (
              <Field
                key={nameField}
                name={formFields[nameField]}
                id={formFields[nameField]}
                label={t(nameField)}
                margin="normal"
                variant="standard"
                step="0.01"
                my={2}
                fullWidth
                type="number"
                component={RenderTextField}
              />
            );
          }

          return (
            <Field
              key={nameField}
              name={formFields[nameField]}
              id={formFields[nameField]}
              label={t(nameField)}
              margin="normal"
              variant="standard"
              my={2}
              fullWidth
              type="text"
              component={RenderTextField}
            />
          );
        })}
        {!isEmpty(prices) && (
          <>
            <Typography variant="subtitle2">
              {t('Prices')}
            </Typography>
            <List>
              {map(prices, (item, index) => {
                const findItem = find(currenciesData.toJS().items,
                  { id: Number(item.currencyId) });
                return (
                  <ListItem key={`${item.currencyId}${index}`} pl={0}>
                    <Box>
                      {`${findItem?.name} - ${item.price}`}
                    </Box>
                    <IconButton
                      aria-label="Delete"
                      size="small"
                      onClick={() => handleDeletePrice(item.currencyId)}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </ListItem>
                );
              })}
            </List>
          </>
        )}

        {!openFields && (
          <Button
            mt={2}
            variant="outlined"
            color="primary"
            onClick={() => setOpenFields(true)}
          >
            {t('Add a price in the currency')}
          </Button>
        )}

        {openFields && (
          <>
            <Field
              name="currency"
              id="currency"
              label={t('Currency')}
              labelId="currency"
              my={2}
              items={currenciesData.toJS().items}
              fullWidth
              component={Select}
            />

            <Field
              name="price"
              id="price"
              label={t('Price')}
              margin="normal"
              variant="standard"
              my={2}
              fullWidth
              type="text"
              component={RenderTextField}
            />

            <Button
              mt={2}
              variant="contained"
              color="primary"
              onClick={handleAddPrice}
            >
              {t('ADD')}
            </Button>
          </>
        )}
        <DialogActions
          pending={pendingPost}
          handleCloseModal={handleCloseModal}
        />
      </Form>
    </Modal>
  );
}

TariffModal.displayName = 'TariffModal';

TariffModal.propTypes = {
  getDataById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  pendingPost: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  destroy: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  hashID: PropTypes.string.isRequired,
  currenciesData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  formValues: PropTypes.oneOfType([
    PropTypes.object,
  ]),
};

TariffModal.defaultProps = {
  formValues: {},
};

function mapStateToProps(state) {
  return {
    getDataById: selectors.pricesTariff.getTariffTableById(state),
    isModalOpen: selectors.pricesTariff.isModalOpen(state),
    pendingPost: selectors.pricesTariff.pendingPostTariffTable(state),
    hashID: selectors.pricesTariff.getHashID(state),
    currenciesData: selectors.currencies.currenciesData(state),
    formValues: selectors.form.getFormValues(state, formNameTariffTable),
  };
}

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.getDataById, nextProps.getDataById)
    && isEqual(prevProps.isModalOpen, nextProps.isModalOpen)
    && isEqual(prevProps.pendingPost, nextProps.pendingPost)
    && isEqual(prevProps.currenciesData, nextProps.currenciesData)
    && isEqual(prevProps.formValues, nextProps.formValues)
    && isEqual(prevProps.hashID, nextProps.hashID);
}

export default compose(
  reduxForm({
    form: formNameTariffTable,
  }),
  connect(mapStateToProps, null),
)(React.memo(TariffModal, areEqual));
