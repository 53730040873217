export default Object.freeze({
  id: 'id',
  item: 'item',
  data: 'data',
  items: 'items',
  pending: 'pending',
  report: 'report',
  modal: 'modal',
  types: 'types',
  multiple: 'multiple',
  selectionsModal: 'selectionsModal',
  selections: 'selections',
  isOpen: 'isOpen',
  goToListPage: 'goToListPage',
  isModalOpen: 'isModalOpen',
  pendingPut: 'pendingPut',
  pendingPost: 'pendingPost',
  filtered: 'filtered',
  tabs: 'tabs',
  delete: 'delete',
  pendingDelete: 'pendingDelete',
  totalCount: 'totalCount',
  currentPage: 'currentPage',
  pageSize: 'pageSize',
  filters: 'filters',
  sorting: 'sorting',
  reload: 'reload',
  itemByHashId: 'itemByHashId',
  autocompleteOptions: 'autocompleteOptions',
  classes: 'classes',
  selectClasses: 'selectClasses',
  sign: 'sign',
  deleteNested: 'deleteNested',
  images: 'images',
  name: 'name',
  type: 'type',
  pendingGetProposalById: 'pendingGetProposalById',
  uploadFiles: 'uploadFiles',
  getNextNumber: 'getNextNumber',
  entityList: 'entityList',
  columnWidths: 'columnWidths',
  columnOrder: 'columnOrder',
  hiddenColumnNames: 'hiddenColumnNames',
});
