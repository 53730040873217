const asyncTypes = Object.freeze({
  GET_SEARCHES_ASYNC: 'GET_SEARCHES_ASYNC',
  GET_SEARCHES_INITIAL_STATE_ASYNC: 'GET_SEARCHES_INITIAL_STATE_ASYNC',
  GET_SEARCH_BY_ID_ASYNC: 'GET_SEARCH_BY_ID_ASYNC',
  SET_NUMBER_PER_PAGE_ASYNC: 'SET_NUMBER_PER_PAGE_ASYNC',
  POST_SEARCH_ASYNC: 'POST_SEARCH_ASYNC',
  POST_QUICK_SEARCH_ASYNC: 'POST_QUICK_SEARCH_ASYNC',
  PUT_SEARCH_BY_ID_ASYNC: 'PUT_SEARCH_BY_ID_ASYNC',
  GET_SEARCH_REPORT_DATA_ASYNC: 'GET_SEARCH_REPORT_DATA_ASYNC',
  DELETE_SEARCH_ASYNC: 'DELETE_SEARCH_ASYNC',
  SET_TABS_ASYNC: 'SET_TABS_ASYNC',
});

export default asyncTypes;
