// Core
import { apply, put, select } from 'redux-saga/effects';

// lodash
import { map, find } from 'lodash';

// config
import { api } from '../../../../../config/api';
import selectors from '../../../../../config/selectors';
import { listEntityName } from '../../../../../config/listTablesName';

// actions
import actions from '../../action';

const entity = listEntityName.emailLetters;

export function* callGetThreadsMessagesWorker({ payload: id }) {
  yield put(actions.setPendingThreadsMessages(true));

  const emailMessageData = yield select(selectors.email.emailMessageData);
  const threadsMessages = yield select(selectors.email.threadsMessages);

  const findMessage = find(emailMessageData.toJS().items, { id });

  yield put(actions.setEmailMessagesData({
    ...emailMessageData.toJS(),
    items: map(emailMessageData.toJS().items, (item) => {
      const elem = item.id === id ? { ...item, isRead: true } : item;
      return elem;
    }),
  }));

  const response = yield apply(api, api.email.getThreadMessages,
    [{
      entity,
      'thread:typeRequest:=': [findMessage.senderEmail, findMessage.recipients?.[0]?.email],
      'email.id:typeRequest:=': findMessage.email,
      limit: 50,
      page: (threadsMessages.toJS().length / 50) + 1,
    }]);

  let data = {
    items: [],
  };

  if (response && response.status >= 200 && response.status < 400) {
    data = Object.assign(data, response.data);
  }

  yield put(actions.mergeThreadsMessages(data.items));
  yield put(actions.setTotalCountThreadsMessages(response?.data?.totalCount || 0));
  yield put(actions.setPendingThreadsMessages(false));
}
