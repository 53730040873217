/* eslint-disable */
// Core
import React, {
  memo, useCallback, useState, useRef, useEffect, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
// import { spacing } from '@material-ui/system';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { AutoSizer } from 'react-virtualized';

// lodash
import {
  find, isEqual, map, isArray, filter, isEmpty,
} from 'lodash';

// UI
import {
  Typography, Grid as MuiGrid, Paper,
  SvgIcon, IconButton,
} from '@material-ui/core';
import {
  Grid, PagingPanel, Table,
  TableFilterRow, TableHeaderRow,
  TableEditColumn, TableSelection,
  TableRowDetail, TableInlineCellEditing,
  TableColumnReordering, DragDropProvider,
  TableColumnVisibility, ColumnChooser,
  Toolbar, TableColumnResizing, ExportPanel,
} from '@devexpress/dx-react-grid-material-ui';
import {
  CustomPaging, EditingState,
  // IntegratedFiltering,
  SelectionState,
  IntegratedSelection,
  SortingState,
  RowDetailState,
} from '@devexpress/dx-react-grid';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import saveAs from 'file-saver';

// icons
// import MuiArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
// import MuiArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
// import TableCellMui from '@material-ui/core/TableCell';
import { ReactComponent as DownloadExcel } from '../_helpers/img/download.svg';

// actions
import helpersActions from '../../engine/core/helpers/action';
import fieldsSettingsActionAsync from '../../engine/core/fieldsSettings/saga/asyncAction';
import fieldsSettingsActions from '../../engine/core/fieldsSettings/action';

// config
import selectors from '../../engine/config/selectors';
import { useAccessList } from '../_hooks/useAccessList';
import accessList from '../../engine/config/accessList';

// parts
import DxFilteringState from './components/TableFilter/DxFilteringState';
import FilterCell from './components/TableFilter/TableFilter';
import NoDataCell from './components/TableNoDataCell/NoDataCell';
import TableCell from './components/TableCell/TableCell';
import ActionsCell from './components/ActionsCell/ActionsCell';
import DxLoader from './components/DxLoader';
import ChosenCheckboxes from './components/СhosenCheckboxes/СhosenCheckboxes';
import DxPagingState from './components/TablePaging/DxPagingState';
import { pageSizes } from '../../engine/config/globalConfig';
import TableInlineEditingCell from './components/TableInlineEditingCell/TableInlineEditingCell';

// styles
// const MuiGridSpacing = styled(MuiGrid)(spacing);
const MuiGridStyle = styled(MuiGrid)`
  position: relative;
  flex-grow: 1;
  min-height: 125px;

  .MuiTableCell-body {
    white-space: inherit;
  }
  .MuiInputBase-root {
    font-size: 14px;
  }
`;

const MuiGridRowStyle = styled(MuiGrid)`
  &:hover {
    cursor: pointer;
  }
`;

const TypographyEmpty = styled(Typography)`
  text-align: center;
`;

// const ButtonSort = styled(MuiButton)`
//   min-width: 40px;
//   padding: 6px 4px;
//   &:hover {
//     background: transparent;
//   }
//   & ~ .arrow-sort {
//     visibility: hidden;
//   }
//   &:hover ~ .arrow-sort,
//   & ~ .arrow-sort.active {
//     visibility: inherit;
//   }
//   &:disabled {
//     color: rgba(0, 0, 0, 0.87);
//   }
// `;

// const LinkRecruit = styled(Link)`
//   font-size: 16px;
//   border-bottom: 1px dashed;
//   &:hover {
//     text-decoration: none;
//     cursor: pointer;
//   }
// `;

// const styleArrowsSort = (ComponentIcon) => styled(ComponentIcon)`
//   font-size: 17px;
//   color: ${props => props.theme.palette.grey[500]};
// `;

const Wrapper = styled.div`
  position: fixed;
  bottom: 0px;
  height: 20px;
  z-index: 555;
  overflow-x: scroll;
  overflow-y:hidden;
`;

// const ArrowDownwardIcon = styleArrowsSort(MuiArrowDownwardIcon);
// const ArrowUpwardIcon = styleArrowsSort(MuiArrowUpwardIcon);

function DxTable(props) {
  const {
    rows,
    columns,
    currentPage,
    pageSize,
    totalCount,
    onPageSize,
    onCurrentPage,
    filtersAction,
    defaultFilters,
    filters,
    tableName,
    entityName,
    sorting,
    onSortingChange,
    defaultSorting,
    selection,
    fieldsForModal,
    selectionRow,
    onSelectionChange,
    isLoading,
    detailComponent,
    defaultExpandedRowIds,
    setExpandedRowIds,
    expandedRowIds,
    reloadTable,
    editComponentWidth,
    editingExtensions,
    onEditingChange,
    isModalOpen,
    tableColumnExtensions,
    onColumnWidthsChange,
    columnOrder,
    onColumnOrderChange,
    hiddenColumnNames,
    onHiddenColumnNamesChange,
    disableHiddenColumn,
    disableColumnOrder,
    disableColumnWidth,
    createDocument,
    disablePaging,
    disableExport,
    disableMenu,
    disableTableScroll,
    accessEdit,
    accessDelete,
  } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [disableScroll, setDisableScroll] = useState(false);
  // const ref = useRef(null);
  const exporterRef = useRef(null);
  const tableColumnExtensionsId = [{ columnName: 'id', width: '50px' }];
  const objectStatuses = useSelector(selectors.helpers.getObjectStatuses);
  const imagesWithBase64 = useSelector(selectors.helpers.imagesWithBase64);
  const accessEntityFields = useAccessList(accessList.entity_fields_list_get);

  // const ToolbarComponent = useCallback(
  //   ({ children }) => (
  //     <Toolbar.Root>
  //       {children}
  //       {!disableColumnWidth && !isEmpty(rows) && (
  //         <Typography variant="subtitle2">
  //           {`${t('Page')} ${currentPage + 1} ${t('from')} ${ceil(totalCount/pageSize)}`}
  //         </Typography>
  //       )}
  //     </Toolbar.Root>
  //   ), [rows, currentPage, totalCount, pageSize],
  // );

  useEffect(() => {
    if (entityName && accessEntityFields) {
      dispatch(fieldsSettingsActionAsync.getFieldsEntityAsync(entityName));
    }
  }, [entityName, dispatch, accessEntityFields]);

  useEffect(() => {
    dispatch(helpersActions.setTableName(tableName));
  }, [tableName, dispatch]);

  useEffect(() => function cleanup() {
    dispatch(helpersActions.setTableName(''));
    dispatch(fieldsSettingsActions.setFieldsEntity({}));
  }, [dispatch]);

  const getFilters = useMemo(() => (
    filter(filters, (currentFilter) => !currentFilter.advanced)
  ), [filters]);

  const DetailCellComponent = useCallback(
    (actionsProps) => (
      <TableRowDetail.Cell {...actionsProps} style={{ ...actionsProps.style, width: '100%', padding: 0 }}>
        {detailComponent({ ...actionsProps })}
      </TableRowDetail.Cell>
    ), [detailComponent],
  );

  const EmptyMessageComponent = useCallback(() => (
    <TypographyEmpty variant="h4" gutterBottom>
      {t('Nothing to show')}
    </TypographyEmpty>
  ), [t]);

  const noDataCell = useCallback((dataCell) => (
    <NoDataCell colSpan={dataCell.colSpan} isLoading={isLoading} />
  ), [isLoading]);

  const getRowId = useCallback((row) => row.id, []);

  const startExport = useCallback((options) => {
    exporterRef.current.exportGrid(options);
  }, [exporterRef]);

  const onSave = (workbook) => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${tableName}.xlsx`);
    });
  };

  const customizeCell = (cell, row, column) => {
    if (column.name === 'holders') {
      cell.value = map(row?.holders, 'name').join(', ');
    }
    if (column.name === 'applicants') {
      cell.value = map(row?.applicants, 'name').join(', ');
    }
    if (column.name === 'tags') {
      cell.value = map(row?.tags, 'name').join(', ');
    }
    if (column.name === 'classes' && isArray(row.classes)) {
      cell.value = row?.classes?.join(', ');
    }
    if (column.name === 'lastDocuments' || column.name === 'documentClassifier'
    || column.name === 'jurisdictions' || column.name === 'clients') {
      cell.value = row?.[column.name]?.name;
    }
    if (column.name === 'inventions' || column.name === 'trademarks' || column.name === 'industrial'
    || column.name === 'utility' || column.name === 'documents') {
      cell.value = row?.[column.name]?.label || row?.[column.name]?.id;
    }
    if (column.name === 'status') {
      if (column.customField === 'statusAction') {
        const statuses = [
          { name: t('Actively'), value: 1 },
          { name: t('Done'), value: 2 },
          { name: t('Canceled'), value: 3 },
        ];
        const foundStatusAction = find(statuses, { value: row.status });
        cell.value = foundStatusAction?.name;
      } else {
        const foundStatusNumber = find(objectStatuses.toJS(), { id: Math.abs(row.status) });
        cell.value = foundStatusNumber?.label;
      }
    }
    if (column.name === 'boost' || column.name === 'payed') {
      cell.value = row?.[column.name] ? t('Yes') : t('No');
    }
    if (column.name === 'renewal') {
      cell.value = row?.renewal?.dateTo;
    }
    if (column.name === 'type') {
      cell.value = row?.type === 'in' ? t('Incoming') : t('Outgoing');
    }
    if (column.name === 'users') {
      cell.value = row?.[column.name]?.label;
    }
    if (column.name === 'title' && column.customField === 'titleLogo') {
      if (row.logo) {
        const findImage = find(imagesWithBase64.toJS(), { id: row.id });
        if (!isEmpty(findImage)) {
          cell.value = undefined;
          const image = cell.workbook.addImage({
            base64: findImage.base64,
            extension: 'png',
          });
  
          cell.worksheet.getRow(cell.row).height = 90;
          cell.worksheet.addImage(image, {
            tl: { col: cell.col - 1, row: cell.row - 1 },
            br: { col: cell.col, row: cell.row },
          });
        }
      } else {
        cell.value = row.title || row.name || row.label;
      }
    }
    if (column.name === 'firstImage') {
      const findImage = find(imagesWithBase64.toJS(), { id: row.id });
      if (!isEmpty(findImage)) {
        cell.value = undefined;
        const image = cell.workbook.addImage({
          base64: findImage.base64,
          extension: 'png',
        });

        cell.worksheet.getRow(cell.row).height = 90;
        cell.worksheet.addImage(image, {
          tl: { col: cell.col - 1, row: cell.row - 1 },
          br: { col: cell.col, row: cell.row },
        });
      }
    }
  };

  const table = document.getElementsByTagName("table")[tableName === 'Documents_Out' ? 1 : 0];
  let containerTable = null;

  if (table) {
    containerTable = table.closest("div");
    containerTable.style.overflowX = 'auto';
  }

  const handleScroll = useCallback(() => {
    if (document.body.scrollHeight > document.documentElement.scrollTop + window.innerHeight + 200
      && disableScroll) {
      setDisableScroll(false);
      // if (ref.current && containerTable) {
      //   ref.current.scrollLeft = containerTable.scrollLeft;
      // }
    }
    if (document.body.scrollHeight < document.documentElement.scrollTop + window.innerHeight + 200
      && !disableScroll) {
      setDisableScroll(true);
    }
  }, [setDisableScroll, disableScroll, window, document, table]);

  useEffect(() => {
    if (table) {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [table, disableScroll]);

  const onScroll = (e) => {
    if (containerTable) {
      containerTable.scrollLeft = e.target.scrollLeft;
    }
  };

  // if (ref.current && containerTable && ref.current.scrollLeft !== containerTable.scrollLeft) {
  //   ref.current.scrollLeft = containerTable.scrollLeft;
  // }

  const sortingStateColumnExtensions = [{ columnName: 'firstImage', sortingEnabled: false }];

  return (
    <>
      <MuiGridStyle>
        <AutoSizer disableHeight>
          {({ width }) => (
            <>
              {!disableTableScroll && !isLoading && table && table.offsetWidth - 50 > width && (
                <Wrapper
                  onScroll={onScroll}
                  style={{ width, display: disableScroll ? 'none' : 'block' }}
                  ref={(component) => {
                    if (component && containerTable) component.scrollLeft = containerTable?.scrollLeft
                  }}
                >
                  <div style={{width: table.offsetWidth, height: 20}} />
                </Wrapper>
              )}
              <Paper
                style={{width}}
              >
                <Grid
                  rows={rows}
                  columns={columns}
                  getRowId={getRowId}
                  {...selectionRow ? {
                    rootComponent: MuiGridRowStyle,
                  } : {}}
                >

                  {!disablePaging && (
                    <DxPagingState
                      currentPage={currentPage}
                      pageSize={pageSize}
                      onPageSize={onPageSize}
                      onCurrentPage={onCurrentPage}
                      totalCount={totalCount}
                    />
                  )}
                  <DxFilteringState
                    // filteringExtensions={filteringExtensions}
                    filtersAction={filtersAction}
                    defaultFilters={defaultFilters}
                    filters={getFilters}
                  />
                  <SortingState
                    sorting={sorting}
                    onSortingChange={onSortingChange}
                    columnExtensions={sortingStateColumnExtensions}
                    defaultSorting={defaultSorting}
                  />

                  <SelectionState
                    selection={selection}
                    onSelectionChange={onSelectionChange}
                  />

                  {Boolean(detailComponent) && (
                    <RowDetailState
                      expandedRowIds={expandedRowIds}
                      onExpandedRowIdsChange={setExpandedRowIds}
                      defaultExpandedRowIds={defaultExpandedRowIds}
                    />
                  )}

                  {!disablePaging && (
                    <CustomPaging
                      totalCount={totalCount}
                    />
                  )}

                  {!editingExtensions && <EditingState onCommitChanges={null} />}
                  {editingExtensions && onEditingChange && (
                    <EditingState
                      onCommitChanges={onEditingChange}
                      columnExtensions={editingExtensions}
                    />
                  )}

                  {!disableColumnOrder && <DragDropProvider />}

                  <Table
                    cellComponent={TableCell}
                    noDataCellComponent={noDataCell}
                    columnExtensions={[
                      ...tableColumnExtensionsId,
                      ...tableColumnExtensions,
                    ]}
                  />

                  {!disableColumnWidth && (
                    <TableColumnResizing
                      columnWidths={tableColumnExtensions}
                      minColumnWidth={50}
                      onColumnWidthsChange={onColumnWidthsChange}
                    />
                  )}

                  {!disableColumnOrder && (
                    <TableColumnReordering
                      order={columnOrder}
                      onOrderChange={onColumnOrderChange}
                    />
                  )}

                  {Boolean(detailComponent) && (
                    <TableRowDetail
                      cellComponent={DetailCellComponent}
                    />
                  )}

                  <TableEditColumn
                    width={editComponentWidth}
                    cellComponent={ActionsCell}
                  />

                  {editingExtensions && (
                    <TableInlineCellEditing
                      cellComponent={TableInlineEditingCell}
                      startEditAction={'click'}
                      selectTextOnEditStart={false}
                    />
                  )}

                  <TableHeaderRow
                    showSortingControls={Boolean(onSortingChange)}
                    // titleComponent={({ children }) => (
                    //   <Typography noWrap>
                    //     <Tooltip title={children} arrow placement="top-start">
                    //       <strong>{children}</strong>
                    //     </Tooltip>
                    //   </Typography>
                    // )}
                    // sortLabelComponent={({ disabled, onSort, column, direction }) => (
                    //   <>
                    //     <ButtonSort
                    //       disabled={disabled}
                    //       onMouseUp={onSort}
                    //     >
                    //       <Typography noWrap>
                    //         <Tooltip title={column.title} arrow placement="top-start">
                    //           <strong>{column.title}</strong>
                    //         </Tooltip>
                    //       </Typography>
                    //     </ButtonSort>
                    //     {direction === 'desc' && (
                    //       <ArrowDownwardIcon
                    //         className={`arrow-sort active`}
                    //       />
                    //     )}
                    //     {direction === 'asc' && (
                    //       <ArrowUpwardIcon
                    //         className={`arrow-sort active`}
                    //       />
                    //     )}
                    //     {!direction && (
                    //       <ArrowUpwardIcon
                    //         className={`arrow-sort`}
                    //       />
                    //     )}
                    //   </>
                    // )}
                  />

                  {!disableHiddenColumn && (
                    <TableColumnVisibility
                      hiddenColumnNames={hiddenColumnNames}
                      onHiddenColumnNamesChange={onHiddenColumnNamesChange}
                      emptyMessageComponent={EmptyMessageComponent}
                    />
                  )}
                  {(!disableHiddenColumn || !disableExport) && (
                    <Toolbar
                      // {...!disableHiddenColumn ? {
                      //   rootComponent: ToolbarComponent,
                      // } : {}}
                    />
                  )}
                  {!disableExport && (
                    <ExportPanel
                      startExport={startExport}
                      messages={{
                        exportAll: t('Export all data'),
                        exportSelected: t('Export selected rows'),
                      }}
                      toggleButtonComponent={({ buttonRef, onToggle }) => (
                        <IconButton onClick={onToggle} ref={buttonRef}>
                          <SvgIcon fontSize="small"><DownloadExcel /></SvgIcon>
                        </IconButton>
                      )}
                    />
                  )}
                  {!disableHiddenColumn && <ColumnChooser />}

                  {filters && <TableFilterRow cellComponent={FilterCell} />}
                  {/* <IntegratedFiltering/> */}
                  <IntegratedSelection />
                  {selection && (
                    <TableSelection
                      selectionColumnWidth={40}
                      showSelectAll
                    />
                  )}
                  {selectionRow && (
                    <TableSelection
                      selectionColumnWidth={40}
                      selectByRowClick
                      showSelectionColumn={false}
                    />
                  )}
                  {!disablePaging && (
                    <PagingPanel
                      // containerComponent={PagingComponent}
                      messages={{ rowsPerPage: '' }}
                      pageSizes={pageSizes}
                    />
                  )}
                </Grid>
                {!disableExport && (
                  <GridExporter
                    ref={exporterRef}
                    rows={rows}
                    columns={map(columns, (item) =>
                      item.name === 'boost' ? { name: 'boost', title: t('Accelerated') } : item)}
                    getRowId={getRowId}
                    selection={selection}
                    onSave={onSave}
                    customizeCell={customizeCell}
                  />
                )}
              </Paper>
            </>
          )}
        </AutoSizer>
        {(isLoading && rows.length > 0) && (
          <DxLoader />
        )}
      </MuiGridStyle>

      <ChosenCheckboxes
        tableName={tableName}
        checkboxes={selection}
        reloadTable={reloadTable}
        fieldsForModal={fieldsForModal}
        createDocument={createDocument}
        disableMenu={disableMenu}
        accessEdit={accessEdit}
        accessDelete={accessDelete}
        rows={rows}
      />
    </>
  );
}

DxTable.defaultProps = {
  pageSize: 20,
  editComponentWidth: 25,
  currentPage: 0,
  rows: [],
  columns: [],
  defaultFilters: [],
  defaultExpandedRowIds: [],
  sorting: [],
  columnOrder: [],
  hiddenColumnNames: [],
  filtersAction: () => {
  },
  reloadTable: () => {},
  createDocument: () => {},
  fieldsForModal: [],
  tableName: '',
  entityName: '',
  accessEdit: '',
  accessDelete: '',
  // menuItems: [],
  isModalOpen: false,
  disableHiddenColumn: false,
  disableColumnOrder: false,
  disableColumnWidth: false,
  disablePaging: false,
  disableExport: false,
  disableMenu: false,
  disableTableScroll: false,
  tableColumnExtensions: [],
};
DxTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  defaultFilters: PropTypes.oneOfType([PropTypes.array]),
  defaultExpandedRowIds: PropTypes.oneOfType([PropTypes.array]),
  sorting: PropTypes.oneOfType([PropTypes.array]),
  columnOrder: PropTypes.oneOfType([PropTypes.array]),
  hiddenColumnNames: PropTypes.oneOfType([PropTypes.array]),
  tableColumnExtensions: PropTypes.oneOfType([PropTypes.array]),
  filtersAction: PropTypes.func,
  tableName: PropTypes.string,
  entityName: PropTypes.string,
  accessEdit: PropTypes.string,
  accessDelete: PropTypes.string,
  disableMenu: PropTypes.bool,
  reloadTable: PropTypes.func,
  createDocument: PropTypes.func,
  fieldsForModal: PropTypes.oneOfType([PropTypes.array]),
  // menuItems: PropTypes.oneOfType([PropTypes.array]),
  disableHiddenColumn: PropTypes.bool,
  disableColumnOrder: PropTypes.bool,
  disableColumnWidth: PropTypes.bool,
  disableExport: PropTypes.bool,
  disableTableScroll: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  editComponentWidth: PropTypes.number,
};

function areEqual(prevProps, nextProps) {
  return (prevProps.isModalOpen && nextProps.isModalOpen)
  || (isEqual(prevProps.rows, nextProps.rows)
  && isEqual(prevProps.columns, nextProps.columns)
  && isEqual(prevProps.sorting, nextProps.sorting)
  && isEqual(prevProps.pageSize, nextProps.pageSize)
  && isEqual(prevProps.filters, nextProps.filters)
  && isEqual(prevProps.currentPage, nextProps.currentPage)
  && isEqual(prevProps.editComponentWidth, nextProps.editComponentWidth)
  && isEqual(prevProps.defaultFilters, nextProps.defaultFilters)
  && isEqual(prevProps.defaultExpandedRowIds, nextProps.defaultExpandedRowIds)
  && isEqual(prevProps.fieldsForModal, nextProps.fieldsForModal)
  && isEqual(prevProps.tableName, nextProps.tableName)
  && isEqual(prevProps.disableMenu, nextProps.disableMenu)
  && isEqual(prevProps.tableColumnExtensions, nextProps.tableColumnExtensions)
  && isEqual(prevProps.totalCount, nextProps.totalCount)
  && isEqual(prevProps.defaultSorting, nextProps.defaultSorting)
  && isEqual(prevProps.selectionRow, nextProps.selectionRow)
  && isEqual(prevProps.isLoading, nextProps.isLoading)
  && isEqual(prevProps.selection, nextProps.selection)
  && isEqual(prevProps.columnOrder, nextProps.columnOrder)
  && isEqual(prevProps.hiddenColumnNames, nextProps.hiddenColumnNames)
  && isEqual(prevProps.disableExport, nextProps.disableExport)
  && isEqual(prevProps.disablePaging, nextProps.disablePaging)
  && isEqual(prevProps.disableTableScroll, nextProps.disableTableScroll)
  && isEqual(prevProps.entityName, nextProps.entityName)
  && isEqual(prevProps.accessEdit, nextProps.accessEdit)
  && isEqual(prevProps.accessDelete, nextProps.accessDelete)
  && isEqual(prevProps.editingExtensions, nextProps.editingExtensions));
}

export default memo(DxTable, areEqual);
