// core
import React, { useEffect } from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';

// ui
import {
  Divider as MuiDivider,
  Typography,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';

// parts
import ProductsTable from './components/ProductsTable';

// actions
import actions from '../../engine/core/products/action';

// hooks
import { useProducts } from './_hooks/useProducts';

// styles
const Divider = styled(MuiDivider)(spacing);
function Products() {
  useProducts();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => function cleanup() {
    dispatch(actions.setProductsData({
      items: [],
      pending: false,
      totalCount: 0,
    }));
  }, [dispatch]);

  return (
    <>
      <Helmet title={t('Products')} />
      <Typography variant="h3" gutterBottom display="inline">
        {t('Products')}
      </Typography>
      <Divider my={6} />

      <ProductsTable />
    </>
  );
}

Products.displayName = 'Products';

Products.propTypes = {};

function mapStateToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, null),
)(Products);
