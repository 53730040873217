export default Object.freeze({
  SET_WORKS_DATA: 'SET_WORKS_DATA',
  SET_WORKS_CURRENT_PAGE: 'SET_WORKS_CURRENT_PAGE',
  SET_WORKS_PAGE_SIZE: 'SET_WORKS_PAGE_SIZE',
  SET_WORKS_FILTERS: 'SET_WORKS_FILTERS',
  SET_WORKS_SORTING: 'SET_WORKS_SORTING',
  SET_WORKS_INITIAL_STATE: 'SET_WORKS_INITIAL_STATE',
  SET_WORKS_RELOAD: 'SET_WORKS_RELOAD',
  SET_DELETE_WORK: 'SET_DELETE_WORK',
  SET_WORKS_AUTOCOMPLETE_OPTIONS: 'SET_WORKS_AUTOCOMPLETE_OPTIONS',
});
