// Core
import { call, put, select } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../../config/api';
import selectors from '../../../../../config/selectors';

// init
import i18n from '../../../../../init/i18n';

// actions
import searchesActions from '../../action';
import helpersActions from '../../../../helpers/action';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../../ui/_helpers/setNotificationMessage';

export function* callDeleteSearchWorker() {
  const { setDeleteSearch, setReload } = searchesActions;
  const { setModal } = helpersActions;

  yield put(setDeleteSearch({
    pendingDelete: true,
  }));

  const searchIdDelete = yield select(selectors.tradeMarksSearches.searchIdDelete);
  const response = yield call(api.trademarks.deleteSearch, searchIdDelete);

  if (response && response.status >= 200 && response.status < 400) {
    yield put(setDeleteSearch({
      pendingDelete: false,
    }));
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
      return;
    }
    yield put(setModal({
      isModalOpen: false,
    }));
    yield put(setDeleteSearch({
      id: '',
    }));
    yield put(setReload(true));
    yield put(setSuccessMessage(i18n.t('Search deleted!'), response.status.toString()));
  }

  yield put(setDeleteSearch({
    pendingDelete: false,
  }));
}
