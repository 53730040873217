// core
import React, { memo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// ui
import {
  Dialog, DialogContent,
  Grid, IconButton, DialogTitle,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

// parts
import DialogActions from './components/DialogActions';

function Modal(props) {
  const {
    handleSend, isModalOpen, dialogProps, dialogActionsChildren,
    handleCloseModal, displayDialogActions, title,
    pending, buttonTextSend, buttonTextClose, children,
    visibleButtonSend, maxWidth,
  } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth
      open={isModalOpen}
      onClose={handleCloseModal}
      aria-labelledby="form-dialog-title"
      maxWidth={maxWidth}
      {...dialogProps}
    >
      {Boolean(title) && (
        <DialogTitle id="form-dialog-title">
          <Grid container>
            <Grid item xs={6} container alignItems="center">
              {t(title)}
            </Grid>
            <Grid item xs={6} container justify="flex-end">
              <IconButton aria-label="close" onClick={handleCloseModal}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
      )}
      <DialogContent>
        {children}
      </DialogContent>
      {displayDialogActions && (
        <DialogActions
          handleCloseModal={handleCloseModal}
          dialogActionsChildren={dialogActionsChildren}
          buttonTextClose={buttonTextClose}
          pending={pending}
          buttonTextSend={buttonTextSend}
          handleSend={handleSend}
          visibleButtonSend={visibleButtonSend}
        />
      )}
    </Dialog>
  );
}

Modal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  maxWidth: PropTypes.string,
  buttonTextSend: PropTypes.string,
  buttonTextClose: PropTypes.string,
  pending: PropTypes.bool,
  displayDialogActions: PropTypes.bool,
  visibleButtonSend: PropTypes.bool,
  handleSend: PropTypes.func,
  dialogProps: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  dialogActionsChildren: PropTypes.node,
};

Modal.defaultProps = {
  title: '',
  pending: false,
  displayDialogActions: true,
  visibleButtonSend: true,
  buttonTextSend: 'CONFIRM',
  buttonTextClose: 'CANCEL',
  handleSend: () => {},
  dialogProps: {},
  dialogActionsChildren: '',
  maxWidth: 'sm',
};

export default memo(Modal);
