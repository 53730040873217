// core
import React, { useCallback } from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import { push } from 'connected-react-router';

// lodash
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

// UI
import {
  ListItemIcon,
  MenuItem, Select,
  Typography,
} from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';

// config
import selectors from '../engine/config/selectors';

// actions
import helpersActions from '../engine/core/helpers/action';
import tradeMarksActions from '../engine/core/tradeMarks/proposal/action';
import industrialDesignsActions from '../engine/core/industrialDesigns/action';
import utilityModelsActions from '../engine/core/utilityModels/action';
import inventionsActions from '../engine/core/inventions/action';
import tariffsActions from '../engine/core/prices/tariffs/action';
import paymentsActions from '../engine/core/company/payments/action';
import clientsActions from '../engine/core/company/clients/table/action';
import ordersActions from '../engine/core/company/orders/action';
import documentsActions from '../engine/core/departmentDocuments/action';

// routes
import { pageLinks } from '../routes';

const SelectContainer = styled.div`
  width: 200px;
  margin-top: 2px;
  margin-right: 20px;
`;

function SelectCompany(props) {
  const {
    allCompanies, selectedCompany, pathname,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleReloadTable = useCallback(() => {
    switch (pathname) {
      case pageLinks.tradeMarksRoutes.proposal.all: {
        dispatch(tradeMarksActions.setReload(true));
        break;
      }
      case pageLinks.industrialDesigns.all: {
        dispatch(industrialDesignsActions.setReload(true));
        break;
      }
      case pageLinks.utilityModels.all: {
        dispatch(utilityModelsActions.setReload(true));
        break;
      }
      case pageLinks.inventions.all: {
        dispatch(inventionsActions.setReload(true));
        break;
      }
      case pageLinks.prices.tariffs.all: {
        dispatch(tariffsActions.setReload(true));
        break;
      }
      case pageLinks.documents.departmentDocuments.all: {
        dispatch(documentsActions.setReload(true));
        break;
      }
      case pageLinks.company.payments.all: {
        dispatch(paymentsActions.setReload(true));
        break;
      }
      case pageLinks.company.clients.all: {
        dispatch(clientsActions.setReload(true));
        break;
      }
      case pageLinks.company.orders.all: {
        dispatch(ordersActions.setReload(true));
        break;
      }
      default: {
        break;
      }
    }
  }, [dispatch, pathname]);

  const handleChange = (e) => {
    if (e.target.value === 'create') {
      dispatch(push(pageLinks.company.companies.new));
    } else {
      dispatch(helpersActions.setSelectedCompany(e.target.value));
      handleReloadTable();
    }
  };

  return (
    <SelectContainer>
      <Select
        labelId="selectCompany"
        fullWidth
        displayEmpty
        value={selectedCompany}
        onChange={handleChange}
      >
        <MenuItem value="">{t('All companies')}</MenuItem>
        {!isEmpty(allCompanies.toJS()) && map(allCompanies.toJS(), (item) => (
          <MenuItem key={item.id} value={`${item.id}`}>{item.name}</MenuItem>
        ))}
        <MenuItem value="create">
          <ListItemIcon>
            <AddIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <Typography variant="inherit" color="primary">{t('Add')}</Typography>
        </MenuItem>
      </Select>
    </SelectContainer>
  );
}

SelectCompany.displayName = 'SelectCompany';

SelectCompany.propTypes = {
  allCompanies: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  selectedCompany: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    allCompanies: selectors.companiesTable.allCompanies(state),
    selectedCompany: selectors.helpers.selectedCompany(state),
    pathname: selectors.router.pathname(state),
  };
}

export default compose(
  connect(mapStateToProps, null),
)(SelectCompany);
