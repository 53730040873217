// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import types from './types';

const integrationActions = Object.freeze({
  setIntegrationsData(data) {
    return actionCreator(types.SET_INTEGRATIONS_DATA, data);
  },
  setIntegrationById(data) {
    return actionCreator(types.SET_INTEGRATION_BY_ID, data);
  },
  setIntegrationDataById(data) {
    return actionCreator(types.SET_INTEGRATION_DATA_BY_ID, data);
  },
  setCurrentPage(currentPage) {
    return actionCreator(types.SET_INTEGRATIONS_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return actionCreator(types.SET_INTEGRATIONS_FILTERS, filters);
  },
  setSorting(sorting) {
    return actionCreator(types.SET_INTEGRATIONS_SORTING, sorting);
  },
  setColumnWidths(data) {
    return actionCreator(types.SET_INTEGRATIONS_COLUMN_WIDTHS, data);
  },
  setColumnOrder(data) {
    return actionCreator(types.SET_INTEGRATIONS_COLUMN_ORDER, data);
  },
  setHiddenColumnNames(data) {
    return actionCreator(types.SET_INTEGRATIONS_HIDDEN_COLUMN_NAMES, data);
  },
  setPageSize(pageSize) {
    return actionCreator(types.SET_INTEGRATIONS_PAGE_SIZE, pageSize);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_INTEGRATIONS_INITIAL_STATE, initialState);
  },
  setReload(reload) {
    return actionCreator(types.SET_INTEGRATIONS_RELOAD, reload);
  },
  setDeleteIntegration(data) {
    return actionCreator(types.SET_DELETE_INTEGRATION, data);
  },
  setAutocompleteOptions(data) {
    return actionCreator(types.SET_INTEGRATIONS_AUTOCOMPLETE_OPTIONS, data);
  },
  setPendingAutocompleteOptions(pending) {
    return actionCreator(types.SET_PENDING_INTEGRATIONS_AUTOCOMPLETE_OPTIONS, pending);
  },
  setPendingPostCheckFtp(data) {
    return actionCreator(types.SET_PENDING_INTEGRATIONS_POST_CHECK_FTP, data);
  },
  setIsModalOpen(data) {
    return actionCreator(types.SET_INTEGRATIONS_IS_MODAL_OPEN, data);
  },
});

export default integrationActions;
