// Core
import { apply, put } from 'redux-saga/effects';

// config
import { api } from '../../../../../config/api';

// actions
import actions from '../../action';

export function* callGetFindCompanyWorker({ payload: params }) {
  yield put(actions.setPendingFindCompany(true));

  const response = yield apply(api, api.findCompany.getFindCompany, [params]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    const { address, name, fis } = data;

    const responseParse = yield apply(api, api.findCompany.parserAddress, [address]);

    if (responseParse && responseParse.status >= 200 && response.status < 400) {
      yield put(actions.setCompany({ ...responseParse.data, name, fis }));
    }
  }
  yield put(actions.setPendingFindCompany(false));
}
