// core
import React, { useEffect, useCallback, useState } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import * as PropTypes from 'prop-types';
import { List } from 'immutable';

// lodash
import isEmpty from 'lodash/isEmpty';

// UI
import {
  Typography,
  Divider as MuiDivider,
  Grid, Box,
  Button as MuiButton,
} from '@material-ui/core';
import {
  Add,
  InsertInvitation,
  ListAlt,
} from '@material-ui/icons';

// parts
import Calendar from './components/Calendar/Calendar';
import ActionsList from './components/ActionsTable/ActionsList';

// routes
import { routersLink, pageLinks } from '../../routes';

// actions
import pendingActions from '../../engine/core/pendingActions/action';
import pendingTableActions from '../../engine/core/pendingActions/table/action';
import employeesTableActions from '../../engine/core/company/employees/table/action';

// selectors
import selectors from '../../engine/config/selectors';
import { useAccessList } from '../../ui/_hooks/useAccessList';
import accessList from '../../engine/config/accessList';

// style
const Button = styled(MuiButton)(spacing);
const Divider = styled(MuiDivider)(spacing);

function ActionsPage(props) {
  const { match, blockRetrievalRequest } = props;
  const { params } = match;
  const dispatch = useDispatch();
  const [pageAction, setPageAction] = useState(!isEmpty(params.hashId) ? 2 : null);
  const path = useSelector(selectors.router.pathname);
  const { t } = useTranslation();
  const accessPost = useAccessList(accessList.pendingActions_post);

  useEffect(() => function cleanup() {
    dispatch(pendingActions.setPendingActions({ data: List() }));
    dispatch(pendingTableActions.setPendingActionsData({
      items: [],
      pending: false,
      totalCount: 0,
    }));
    dispatch(employeesTableActions.setEmployeesData({
      items: [],
      totalCount: 0,
      pending: false,
    }));
    dispatch(pendingActions.setDisableReload(false));
  }, [dispatch]);

  const renderActions = useCallback(() => {
    const { table, controller: calendar } = routersLink.actions;

    switch (true) {
      case pageAction === 1:
      case calendar === path: {
        return <Calendar blockRetrievalRequest={blockRetrievalRequest} />;
      }
      case pageAction === 2:
      case table === path: {
        return <ActionsList blockRetrievalRequest={blockRetrievalRequest} />;
      }
      default:
        return '';
    }
  }, [path, pageAction, blockRetrievalRequest]);

  return (
    <>
      {isEmpty(params.hashId) && (
        <>
          <Helmet title="Calendar" />
          <Typography variant="h3" gutterBottom display="inline">
            {t('Calendar')}
          </Typography>

          <Divider my={6} />
        </>
      )}

      <Box mb={2}>
        <Grid container>
          <Grid item xs={12} sm={6} container alignItems="center">
            <Typography variant="body1" gutterBottom display="inline">
              {t('The next steps')}
            </Typography>
            {blockRetrievalRequest && accessPost && (
              <Button
                variant="contained"
                color="secondary"
                ml={2}
                onClick={() => {
                  dispatch(pendingActions.setIsModalOpen(true));
                }}
              >
                {t('ADD')}
                <Add />
              </Button>
            )}
          </Grid>

          <Grid item xs={12} sm={6} container justify="flex-end">
            <Button
              color="secondary"
              mr={2}
              onClick={() => {
                if (!isEmpty(params.hashId)) {
                  setPageAction(1);
                } else {
                  dispatch(push(pageLinks.actions.calendar));
                  setPageAction(null);
                }
              }}
            >
              <InsertInvitation fontSize="small" />
              {' '}
              {t('Calendar')}
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                if (!isEmpty(params.hashId)) {
                  setPageAction(2);
                } else {
                  dispatch(push(pageLinks.actions.table));
                  setPageAction(null);
                }
              }}
            >
              <ListAlt fontSize="small" />
              {' '}
              {t('List')}
            </Button>
          </Grid>
        </Grid>
      </Box>

      {renderActions()}
    </>
  );
}

ActionsPage.propTypes = {
  match: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  blockRetrievalRequest: PropTypes.bool,
};
ActionsPage.defaultProps = {
  match: {},
  blockRetrievalRequest: true,
};

export default ActionsPage;
