// Core
import { call, put } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';
import pickBy from 'lodash/pickBy';

// config
import { api } from '../../../../config/api';
import { messageRequestSuccess } from '../../../../config/globalConfig';

// init
import i18n from '../../../../init/i18n';

// actions
import actions from '../../action';
import helpersActions from '../../../helpers/action';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../ui/_helpers/setNotificationMessage';

export function* callPutApplicantWorker({ payload }) {
  const { setApplicantsData } = actions;

  yield put(setApplicantsData({
    pendingPut: true,
  }));

  const filterParams = pickBy(payload, (value, key) => key !== 'isTable');

  const response = yield call(api.applicants.putApplicant, filterParams);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
      yield put(setApplicantsData({ pendingPut: false }));
      return;
    }
    if (payload.isTable) {
      yield put(helpersActions.setModal({ isModalOpenAddApplicant: false }));
      yield put(actions.setApplicantById({}));
    }
    yield put(setApplicantsData({
      reload: true,
    }));
    yield put(setSuccessMessage(i18n.t(messageRequestSuccess), response.status.toString()));
  }
  yield put(setApplicantsData({ pendingPut: false }));
}
