// Helpers
import actionCreator from '../../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const asyncActions = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_PAYMENTS_ASYNC, params);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_PAYMENTS_INITIAL_STATE_ASYNC, path);
  },
  deletePaymentAsync() {
    return actionCreator(asyncTypes.DELETE_PAYMENT_ASYNC);
  },
  postPaymentsApplyAsync(data) {
    return actionCreator(asyncTypes.POST_PAYMENTS_APPLY_ASYNC, data);
  },
  postPaymentAsync(data) {
    return actionCreator(asyncTypes.POST_PAYMENT_ASYNC, data);
  },
  getPaymentByIdAsync(id) {
    return actionCreator(asyncTypes.GET_PAYMENT_BY_ID_ASYNC, id);
  },
  putPaymentByIdAsync(data) {
    return actionCreator(asyncTypes.PUT_PAYMENT_BY_ID_ASYNC, data);
  },
});

export default asyncActions;
