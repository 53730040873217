// core
import 'date-fns';
import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import ukLocale from 'date-fns/locale/uk';
import moment from 'moment';

// lodash
import { isNil } from 'lodash';

// ui
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

function DatePickers(props) {
  const {
    label,
    labelId,
    input: { value, onBlur, ...inputProp },
    formatDate, extra,
    ...rest
  } = props;

  const [valueDate, setValDate] = React.useState(null);
  const [checkValueDate, setCheckValueDate] = React.useState(false);

  useEffect(() => {
    if (!checkValueDate && !value) {
      setValDate(null);
    } else {
      setValDate(value ? new Date(value).toISOString() : value);
    }
  }, [value, checkValueDate]);

  const onChange = (date) => {
    if (Date.parse(date)) {
      inputProp.onChange(extra ? moment(date).format('YYYY-MM-DD') : date.toISOString());
      return;
    }
    inputProp.onChange(null);
    if (isNil(date)) {
      setCheckValueDate(false);
      return;
    }
    setCheckValueDate(true);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ukLocale}>
      <KeyboardDatePicker
        {...rest}
        {...inputProp}
        disableToolbar
        variant="inline"
        onChange={onChange}
        value={valueDate}
        format={formatDate}
        margin="normal"
        autoComplete="off"
        id={labelId}
        label={label}
        autoOk
        fullWidth
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </MuiPickersUtilsProvider>
  );
}

DatePickers.displayName = 'DatePickers';

DatePickers.propTypes = {
  labelId: PropTypes.string.isRequired,
  value: PropTypes.string,
  formatDate: PropTypes.string,
  extra: PropTypes.bool,
  label: PropTypes.string.isRequired,
  input: PropTypes.oneOfType([
    PropTypes.object,
  ]),
};

DatePickers.defaultProps = {
  input: {},
  value: null,
  formatDate: 'dd.MM.yyyy',
  extra: false,
};

export default DatePickers;
