export default Object.freeze({
  SET_PRICES_TARIFFS_DATA: 'SET_PRICES_TARIFFS_DATA',
  SET_PRICES_TARIFFS_CURRENT_PAGE: 'SET_PRICES_TARIFFS_CURRENT_PAGE',
  SET_PRICES_TARIFFS_PAGE_SIZE: 'SET_PRICES_TARIFFS_PAGE_SIZE',
  SET_PRICES_TARIFFS_FILTERS: 'SET_PRICES_TARIFFS_FILTERS',
  SET_PRICES_TARIFFS_SORTING: 'SET_PRICES_TARIFFS_SORTING',
  SET_PRICES_TARIFFS_INITIAL_STATE: 'SET_PRICES_TARIFFS_INITIAL_STATE',
  SET_PRICES_TARIFFS_RELOAD: 'SET_PRICES_TARIFFS_RELOAD',
  SET_DELETE_PRICES_TARIFF: 'SET_DELETE_PRICES_TARIFF',
  SET_PRICES_TARIFFS_AUTOCOMPLETE_OPTIONS: 'SET_PRICES_TARIFFS_AUTOCOMPLETE_OPTIONS',
  SET_PRICES_TARIFFS_TYPES: 'SET_PRICES_TARIFFS_TYPES',
  SET_PRICES_TARIFFS_COLUMN_WIDTHS: 'SET_PRICES_TARIFFS_COLUMN_WIDTHS',
  SET_PRICES_TARIFFS_COLUMN_ORDER: 'SET_PRICES_TARIFFS_COLUMN_ORDER',
  SET_PRICES_TARIFFS_HIDDEN_COLUMN_NAMES: 'SET_PRICES_TARIFFS_HIDDEN_COLUMN_NAMES',
});
