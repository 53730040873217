// core
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';

// lodash
import isEmpty from 'lodash/isEmpty';

// ui
import { CssBaseline } from '@material-ui/core';

// config
import selectors from '../engine/config/selectors';

// routes
import { routersLink } from '../routes';

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }
  body {
    background: ${(props) => props.theme.body.background};
  }
`;

const Root = styled.div`
  max-width: 520px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

function Auth({ children }) {
  const userInfo = useSelector(selectors.user.userInfo);

  if (!isEmpty(userInfo)) {
    return <Redirect to={routersLink.actions} />;
  }

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {children}
    </Root>
  );
}

Auth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Auth;
