// core
import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import { push } from 'connected-react-router';
import { List } from 'immutable';

// lodash
import isEmpty from 'lodash/isEmpty';

// ui
import {
  Box,
  Button, ButtonGroup, Divider as MuiDivider,
  Typography,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import { Add as AddIcon } from '@material-ui/icons';

// searches
import monitoringActions from '../../../engine/core/tradeMarks/monitoring/action';

// parts
import MonitoringTable from './MonitoringTable';

// hooks
import { useMonitoring } from './_hooks/useMonitoring';
import { useAccessList } from '../../../ui/_hooks/useAccessList';

// routes
import { pageLinks } from '../../../routes';

// config
import accessList from '../../../engine/config/accessList';
import selectors from '../../../engine/config/selectors';

// styles
const Divider = styled(MuiDivider)(spacing);

function Monitoring(props) {
  const {
    getMonitoringById,
  } = props;
  useMonitoring();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const accessPost = useAccessList(accessList.monitoring_post);

  const clearMonitoringTabs = useCallback((nameTab) => {
    dispatch(monitoringActions.setTabs(nameTab, {
      totalCount: 0,
      pageSize: 0,
      items: List(),
      selectedBlocks: [],
    }));
  }, [
    dispatch,
  ]);

  useEffect(() => {
    if (!isEmpty(getMonitoringById)) {
      clearMonitoringTabs('national');
      clearMonitoringTabs('madrid');
      dispatch(monitoringActions.setMonitoringById({
        data: {},
      }));
    }
  }, [
    dispatch,
    clearMonitoringTabs,
    getMonitoringById,
  ]);

  return (
    <>
      <Helmet title={t('Monitoring')} />
      <Typography variant="h3" gutterBottom display="inline">
        {t('Monitoring')}
      </Typography>
      <Divider my={6} />

      {accessPost && (
        <Box mb={2}>
          <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                dispatch(push(pageLinks.tradeMarksRoutes.monitoring.new));
              }}
            >
              {t('ADD')}
              <AddIcon />
            </Button>
          </ButtonGroup>
        </Box>
      )}

      <MonitoringTable />
    </>
  );
}

Monitoring.displayName = 'Monitoring';

Monitoring.propTypes = {
  getMonitoringById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

function mapStateToProps(state) {
  return {
    getMonitoringById: selectors.tradeMarksMonitoring.getMonitoringById(state),
  };
}

export default compose(
  connect(mapStateToProps, null),
)(Monitoring);
