const asyncTypes = Object.freeze({
  GET_EMAIL_TEMPLATE_ASYNC: 'GET_EMAIL_TEMPLATE_ASYNC',
  GET_EMAIL_TEMPLATE_INITIAL_STATE_ASYNC: 'GET_EMAIL_TEMPLATE_INITIAL_STATE_ASYNC',
  DELETE_EMAIL_TEMPLATE_ASYNC: 'DELETE_EMAIL_TEMPLATE_ASYNC',
  POST_EMAIL_TEMPLATE_ASYNC: 'POST_EMAIL_TEMPLATE_ASYNC',
  GET_EMAIL_TEMPLATE_BY_ID_ASYNC: 'GET_EMAIL_TEMPLATE_BY_ID_ASYNC',
  PUT_EMAIL_TEMPLATE_BY_ID_ASYNC: 'PUT_EMAIL_TEMPLATE_BY_ID_ASYNC',
  GET_EMAIL_TEMPLATE_AUTOCOMPLETE_OPTIONS_ASYNC: 'GET_EMAIL_TEMPLATE_AUTOCOMPLETE_OPTIONS_ASYNC',
});

export default asyncTypes;
