// core
import * as PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import {
  Field, Form, formValueSelector, reduxForm,
} from 'redux-form/immutable';
import styled from 'styled-components';

// lodash
import {
  filter,
  isEmpty, isNil,
  map,
  pickBy,
} from 'lodash';

// ui
import {
  Box,
  Grid,
} from '@material-ui/core';

// config
import globalObjectTypes from '../../../../engine/config/globalObjectTypes';
import selectors from '../../../../engine/config/selectors';

// parts
import Loader from '../../../../components/Loader';
import Modal from '../../../../components/Modal/Modal';
import DialogActions from '../../../../components/Modal/components/DialogActions';
import RecursiveTreeView from '../../../../components/RecursiveTreeView/RecursiveTreeView';
import RenderTextField from '../../../../ui/Form/RenderTextField';
import Select from '../../../../ui/Form/Select';

// actions
import tariffsActionsAsync from '../../../../engine/core/prices/tariffs/saga/asyncAction';
import worksActions from '../../../../engine/core/prices/works/action';
import worksActionsAsyncWorks from '../../../../engine/core/prices/works/saga/asyncAction';

// helpers
import { searchByProperty } from '../../../../components/RecursiveTreeView/helper/searchByProperty';
import accessList from '../../../../engine/config/accessList';
import { listEntityName } from '../../../../engine/config/listTablesName';
import { useAccessList } from '../../../../ui/_hooks/useAccessList';
import { formFields, formName } from './helpers/form';

const formSelector = formValueSelector(formName);

// styles
const BoxContainerChoose = styled(Box)`
  overflow-y: scroll;
  max-height: calc(70vh - 200px);
`;

function AddWorksModal(props) {
  const {
    handleSubmit, worksData, objectType, tariffIdTypes,
    pendingWorks, fieldSearch, pending, initialize,
    getWorkById, isEditWork, isModalOpenAddWork, handleSend,
    destroy,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [expandedServices, setExpandedServices] = useState([]);
  const tariffIdTypesData = tariffIdTypes;
  const accessPut = useAccessList(accessList.clients_put);
  const accessEdit = isEditWork ? accessPut : true;

  const searchOptions = {
    query: fieldSearch,
    searchBy: 'name',
  };

  const getWorks = useMemo(() => {
    const works = worksData.toJS().items;
    return !isEmpty(works) ? searchByProperty(works, fieldSearch, 'name') : [];
  }, [worksData, fieldSearch]);

  // close modal
  const handleCloseModal = () => {
    dispatch(worksActions.setWorksData({
      isModalOpenAddWork: false,
    }));
    handleSend();
    destroy();
  };

  const handleSubmits = (formData) => {
    const data = formData.toJS();
    const getIds = (reg) => {
      const activeCheckboxes = pickBy(data, (value) => value);
      const keys = map(activeCheckboxes, (value, key) => `${key}`);
      const filterKeys = filter(keys, (key) => (reg).test(key));
      return map(filterKeys, (key) => key.split('-')[1]);
    };
    const costsIds = getIds(/costs/);
    const costs = {
      name: data.name,
      costs: costsIds,
    };
    if (isEditWork) {
      dispatch(worksActionsAsyncWorks.putWorkAsync({ id: getWorkById.id, costs }));
    } else {
      dispatch(worksActionsAsyncWorks.postWorkAsync({ name: data.name, costs: costsIds }));
    }
    handleCloseModal();
  };
  // choose object type
  const handleChangeObjectType = (event) => {
    dispatch(tariffsActionsAsync.setTariffsTypesAsync(event.target.value ? {
      [formFields.objectType]: event.target.value,
      entity: listEntityName.tariffs,
      limit: 100,
    } : {}));
  };
  // data for checkboxes checked
  const selectCostsData = useCallback(() => {
    const selectCosts = {};
    const workIdChoosed = getWorks?.filter((item) => item.id === getWorkById.id);
    if (Array.isArray(workIdChoosed[0].costs)) {
      map(workIdChoosed[0].costs, (cost) => {
        selectCosts[`costs-${cost.id}`] = true;
        return selectCosts;
      });
    }
    return selectCosts;
  }, [getWorks, getWorkById.id]);

  useEffect(() => {
    if (isModalOpenAddWork) {
      dispatch(tariffsActionsAsync.setTariffsTypesAsync());
    }
  }, [dispatch, isModalOpenAddWork]);

  useEffect(() => {
    if (!isEmpty(getWorks) && !isNil(getWorkById.id) && isEditWork) {
      const selectedCostsData = selectCostsData();
      initialize({
        [formFields.name]: `${getWorkById.name || ''}`,
        [formFields.objectType]: `${objectType || ''}`,
        ...selectedCostsData,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isEditWork, getWorks, getWorkById.id, getWorkById.name,
    objectType, selectCostsData,
  ]);

  return (
    <Modal
      title={isEditWork ? t('Change work') : t('Add work')}
      isModalOpen={isModalOpenAddWork}
      handleCloseModal={handleCloseModal}
      displayDialogActions={false}
    >
      <Form onSubmit={handleSubmit(handleSubmits)}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Field
              margin="normal"
              fullWidth
              my={2}
              component={RenderTextField}
              id={formFields.name}
              name={formFields.name}
              label={isEditWork ? t('Work name') : t('Enter name of work')}
              disabled={!accessEdit}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              my={2}
              fullWidth
              displayEmpty
              component={Select}
              label={t('Object type')}
              items={globalObjectTypes}
              id={formFields.objectType}
              name={formFields.objectType}
              labelId={formFields.objectType}
              onChange={handleChangeObjectType}
              disabled={!accessEdit}
            />
          </Grid>
        </Grid>
        <Box mb={4}>
          <Field
            fullWidth
            name={formFields.search}
            label={t('Search')}
            margin="normal"
            component={RenderTextField}
            disabled={!accessEdit}
          />
        </Box>
        {(tariffIdTypesData.toJS().pending || pendingWorks)
        && <Box mt={4}><Loader /></Box>}

        {!tariffIdTypesData.toJS().pending && accessEdit && (
          <BoxContainerChoose>
            {!isEmpty(tariffIdTypesData.toJS().services) ? (
              <RecursiveTreeView
                defaultExpanded={expandedServices}
                searchOptions={searchOptions}
                handleExpanded={(ids) => {
                  setExpandedServices(ids);
                }}
                data={{
                  children: tariffIdTypesData.toJS().services,
                }}
              />
            ) : t('No data')}
          </BoxContainerChoose>
        )}
        <DialogActions
          pending={pending}
          handleCloseModal={handleCloseModal}
          visibleButtonSend={accessEdit}
        />
      </Form>
    </Modal>
  );
}

AddWorksModal.displayName = 'AddWorksModal';

AddWorksModal.propTypes = {
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  tariffIdTypes: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  handleSend: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  pendingWorks: PropTypes.bool.isRequired,
  worksData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pending: PropTypes.bool,
  objectType: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  fieldSearch: PropTypes.string,
  getWorkById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  isEditWork: PropTypes.bool.isRequired,
  isModalOpenAddWork: PropTypes.bool.isRequired,
  destroy: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
};

AddWorksModal.defaultProps = {
  objectType: '',
  fieldSearch: '',
  pending: false,
};

function mapStateToProps(state) {
  return {
    worksData: selectors.pricesWorks.getWorksData(state),
    pendingWorks: selectors.pricesWorks.pendingGetWorks(state),
    isModalOpenAddWork: selectors.pricesWorks.isModalOpenAddWork(state),
    getWorkById: selectors.pricesWorks.getWorkById(state),
    tariffIdTypes: selectors.pricesTariffs.tariffIdTypes(state),
    fieldSearch: formSelector(state, formFields.search),
  };
}

export default compose(
  reduxForm({
    form: formName,
  }),
  connect(mapStateToProps, null),
)(AddWorksModal);
