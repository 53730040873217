// Core
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';
import * as PropTypes from 'prop-types';
import {
  Field, Form, reduxForm, change,
} from 'redux-form/immutable';
import { List } from 'immutable';

// lodash
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import find from 'lodash/find';
import without from 'lodash/without';
import uniq from 'lodash/uniq';

// UI
import {
  Typography, CardContent, Box, Button, FormControlLabel, Radio,
  Grid, CircularProgress, Card as MuiCard, Chip as MuiChip, ButtonGroup,
} from '@material-ui/core';

// parts
import FieldClient from '../../ui/Form/FieldsAutocomplete/FieldClient';
import RenderTextField from '../../ui/Form/RenderTextField';
import RadioButton from '../../ui/Form/RadioButton';
import TableObjectsModal from './components/TableObjectsModal';
import ChipEntity from '../../ui/Chip/ChipEntity';
import ImportObjectsModal from './components/ImportObjectsModal';

// config
import selectors from '../../engine/config/selectors';

// actions
import clientsActions from '../../engine/core/company/clients/action';
import importAsyncAction from '../../engine/core/importObjects/saga/asyncAction';
import importActions from '../../engine/core/importObjects/action';

// hooks
import { useEventsAutocompleteAsync } from '../../ui/_hooks/useEventsAutocompleteAsync';

// styles
const Chip = styled(MuiChip)(spacing);
const Card = styled(MuiCard)(spacing);
const TypographySub = styled(Typography)`
  color: ${(props) => props.theme.palette.grey['500']};
`;

const formName = 'ImportUkratent';

function ImportUkrpatent(props) {
  const {
    handleSubmit, clientById, entityList, isModalOpenObject,
    isModalOpen, pendingSpecialDocuments, specialDocuments,
    entityListNumber,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [disableImport, setDisableImport] = useState(false);
  const {
    getAutocompleteLists,
    handleGetSelectOption,
    handleGetAsyncData,
  } = useEventsAutocompleteAsync();

  useEffect(() => function cleanup() {
    dispatch(importActions.setSelectedObjects({}));
    dispatch(importActions.setEntityList({
      trademarks: List(),
      utility: List(),
      inventions: List(),
    }));
    dispatch(importActions.setEntityListNumber({
      trademarks: List(),
      utility: List(),
      inventions: List(),
    }));
    dispatch(importActions.setSpecialDocuments({}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(change(formName, 'parseType', 'objects'));
  }, [dispatch]);

  const handleClientsGetSelectOption = (elem) => {
    if (!elem) {
      dispatch(clientsActions.setClientById({ data: {} }));
      return;
    }
    handleGetSelectOption(elem, 'clients');
  };

  const handleSubmits = (formData) => {
    const jsFormData = formData.toJS();
    delete jsFormData.client;
    dispatch(importAsyncAction.postSpezDocumentsAsync({
      ...jsFormData,
      ...!isEmpty(clientById.toJS()) ? {
        client: clientById.toJS().id,
      } : {},
      ...disableImport ? {
        import: false,
        parseType: 'objects',
      } : {
        ...jsFormData.parseType === 'objects' ? {
          import: true,
        } : {},
        ...!isEmpty(entityList.toJS()?.trademarks) || !isEmpty(entityListNumber.toJS()?.trademarks)
          ? {
            trademarks: [
              ...!isEmpty(specialDocuments.toJS()?.trademarks) ? [
                ...map(entityList.toJS()?.trademarks, (id) => {
                  const findEntity = find(specialDocuments.toJS()?.trademarks, { id });
                  return findEntity.applicationNumber || findEntity.registrationNumber;
                }),
              ] : [],
              ...entityListNumber.toJS()?.trademarks,
            ],
          } : {},
        ...!isEmpty(entityList.toJS()?.utility) || !isEmpty(entityListNumber.toJS()?.utility)
          ? {
            utility: [
              ...!isEmpty(specialDocuments.toJS()?.utility) ? [
                ...map(entityList.toJS()?.utility, (id) => {
                  const findEntity = find(specialDocuments.toJS()?.utility, { id });
                  return findEntity.applicationNumber || findEntity.registrationNumber;
                }),
              ] : [],
              ...entityListNumber.toJS()?.utility,
            ],
          } : {},
        ...!isEmpty(entityList.toJS()?.inventions) || !isEmpty(entityListNumber.toJS()?.inventions)
          ? {
            inventions: [
              ...!isEmpty(specialDocuments.toJS()?.inventions) ? [
                ...map(entityList.toJS()?.inventions, (id) => {
                  const findEntity = find(specialDocuments.toJS()?.inventions, { id });
                  return findEntity.applicationNumber || findEntity.registrationNumber;
                }),
              ] : [],
              ...entityListNumber.toJS()?.inventions,
            ],
          } : {},
      },
    }));
    setDisableImport(false);
  };

  const handleDeleteEntity = (entityName, id) => {
    if (entityName && id) {
      dispatch(importActions.mergeEntityList({
        entityName,
        items: [
          ...!isEmpty(entityList.toJS()) && !isEmpty(entityList.toJS()[entityName]) ? [
            ...without(entityList.toJS()[entityName], id),
          ] : [],
        ],
      }));
      dispatch(importActions.mergeSelectedObjects({
        entityName,
        items: [
          ...!isEmpty(entityList.toJS()) && !isEmpty(entityList.toJS()[entityName]) ? [
            ...without(entityList.toJS()[entityName], id),
          ] : [],
        ],
      }));
    }
  };

  const handleSelectObjects = () => {
    dispatch(importActions.setSelectedObjects(entityList.toJS()));
    if (!isEmpty(specialDocuments.toJS())) {
      dispatch(importActions.setIsModalOpen(true));
      return;
    }
    setDisableImport(true);
    setTimeout(() => {
      handleSubmit();
    }, 50);
  };

  const handleOpenModalObject = () => {
    dispatch(importActions.setIsModalOpenObject(true));
  };

  const handleSendObjects = (data) => {
    dispatch(importActions.mergeEntityListNumber({
      entityName: data.entityName,
      items: uniq([
        ...entityListNumber.toJS()[data.entityName] || [],
        ...data.items,
      ]),
    }));
  };
  const handleDeleteEntityNumber = (entityName, value) => {
    if (entityName && value) {
      dispatch(importActions.mergeEntityListNumber({
        entityName,
        items: [
          ...!isEmpty(entityListNumber.toJS()) && !isEmpty(entityListNumber.toJS()[entityName]) ? [
            ...without(entityListNumber.toJS()[entityName], value),
          ] : [],
        ],
      }));
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(handleSubmits)}>
        <Card mb={6}>
          <CardContent>
            <Typography variant="h4" gutterBottom>
              {t('Access to the Cabinet')}
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} md={3}>
                <Field
                  name="login"
                  id="login"
                  label={t('Username')}
                  margin="normal"
                  variant="standard"
                  my={2}
                  fullWidth
                  type="text"
                  required
                  component={RenderTextField}
                />
                <Field
                  name="password"
                  id="password"
                  label={t('Password')}
                  margin="normal"
                  variant="standard"
                  my={2}
                  fullWidth
                  type="password"
                  required
                  component={RenderTextField}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card mb={6}>
          <CardContent>
            <Typography variant="h4" gutterBottom>
              {t('Connections')}
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <FieldClient
                  name="client"
                  getAutocompleteLists={getAutocompleteLists}
                  formName={formName}
                  getAsyncData={handleGetAsyncData}
                  propsField={{
                    getSelectOption: handleClientsGetSelectOption,
                  }}
                />
                {!isEmpty(clientById.toJS()) && (
                  <Grid container spacing={4} item xs={12} alignItems="center">
                    <Grid item xs={12} container direction="column">
                      <Typography variant="subtitle2">
                        {clientById.toJS()?.name}
                      </Typography>
                      {!isEmpty(clientById.toJS().addresses)
                      && !isEmpty(clientById.toJS().addresses[0]) && (
                        <Typography variant="body2" gutterBottom>
                          {t('Street')}
                          {': '}
                          {clientById.toJS().addresses[0]?.address}
                          <br />
                          {t('Country')}
                          {': '}
                          {clientById.toJS().addresses[0]?.country}
                          <br />
                          {t('City')}
                          {': '}
                          {clientById.toJS().addresses[0]?.city}
                        </Typography>
                      )}
                      {!isEmpty(clientById.toJS().phone) && (
                        <Typography variant="body2" gutterBottom>
                          {t('Phone')}
                          {': '}
                          {clientById.toJS()?.phone}
                        </Typography>
                      )}
                      {!isEmpty(clientById.toJS().email) && (
                        <Typography variant="body2" gutterBottom>
                          {t('Email')}
                          {': '}
                          {clientById.toJS()?.email}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card mb={6}>
          <CardContent>
            <Typography variant="h4" gutterBottom>
              {t('Import settings')}
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <TypographySub variant="subtitle1" gutterBottom>
                  {t('What we import')}
                </TypographySub>
                <Field name="parseType" row component={RadioButton}>
                  <FormControlLabel
                    value="objects"
                    control={<Radio />}
                    label={t('Objects')}
                  />
                  <FormControlLabel
                    value="documents"
                    control={<Radio />}
                    label={t('Documents')}
                  />
                </Field>
                <TypographySub variant="subtitle1" gutterBottom>
                  {t('Target objects from SPEZ-1')}
                </TypographySub>
                {(isEmpty(entityList.toJS()) || (
                  isEmpty(entityList.toJS()?.trademarks)
                  && isEmpty(entityList.toJS()?.utility)
                  && isEmpty(entityList.toJS()?.inventions)
                  && isEmpty(entityListNumber.toJS()?.trademarks)
                  && isEmpty(entityListNumber.toJS()?.utility)
                  && isEmpty(entityListNumber.toJS()?.inventions))) && (
                  <Chip
                    color="primary"
                    label={t('All')}
                    m={1}
                  />
                )}
                {!isEmpty(entityList.toJS()) && (
                  map(Object.keys(entityList.toJS()), (entity, index) => {
                    if (!isEmpty(entityList.toJS()[entity])
                    || !isEmpty(entityListNumber.toJS()[entity])) {
                      return (
                        <Grid item xs={12} key={index}>
                          <TypographySub variant="subtitle1" gutterBottom>
                            {t(entity)}
                          </TypographySub>
                          {map(entityList.toJS()[entity], (item) => {
                            const selected = find(specialDocuments.toJS()[entity], { id: item });
                            return (
                              <ChipEntity
                                key={item}
                                id={item}
                                entity={entity}
                                label={selected.applicationNumber || item}
                                handleDelete={handleDeleteEntity}
                              />
                            );
                          })}
                          {map(entityListNumber.toJS()[entity], (item) => (
                            <ChipEntity
                              key={item}
                              id={item}
                              entity={entity}
                              label={item}
                              handleDelete={handleDeleteEntityNumber}
                            />
                          ))}
                        </Grid>
                      );
                    }
                    return null;
                  })
                )}
                <Box mt={4}>
                  <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleSelectObjects}
                    >
                      {pendingSpecialDocuments
                        ? <CircularProgress size={25} color="inherit" />
                        : t('SELECT')}
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleOpenModalObject}
                    >
                      {t('SPECIFY')}
                    </Button>
                  </ButtonGroup>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Box mt={2}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
          >
            {pendingSpecialDocuments
              ? <CircularProgress size={25} color="inherit" />
              : t('IMPORT')}
          </Button>
        </Box>
      </Form>

      {isModalOpen && <TableObjectsModal />}
      {isModalOpenObject && (
        <ImportObjectsModal
          handleSend={handleSendObjects}
        />
      )}
    </>
  );
}

ImportUkrpatent.displayName = 'ImportUkrpatent';

ImportUkrpatent.propTypes = {
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  clientById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  entityList: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  specialDocuments: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingSpecialDocuments: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  isModalOpenObject: PropTypes.bool.isRequired,
  entityListNumber: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

ImportUkrpatent.defaultProps = {};

function mapStateToProps(state) {
  return {
    clientById: selectors.clients.getClientById(state),
    entityList: selectors.importObjects.entityList(state),
    entityListNumber: selectors.importObjects.entityListNumber(state),
    pendingSpecialDocuments: selectors.importObjects.pendingSpecialDocuments(state),
    specialDocuments: selectors.importObjects.specialDocuments(state),
    isModalOpen: selectors.importObjects.isModalOpen(state),
    isModalOpenObject: selectors.importObjects.isModalOpenObject(state),
  };
}

function mapDispatchToProps() {
  return {};
}

export default compose(
  reduxForm({
    form: formName,
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(ImportUkrpatent);
