export default Object.freeze({
  SET_RECEIVING_DATA: 'SET_RECEIVING_DATA',
  SET_RECEIVING_CURRENT_PAGE: 'SET_RECEIVING_CURRENT_PAGE',
  SET_RECEIVING_PAGE_SIZE: 'SET_RECEIVING_PAGE_SIZE',
  SET_RECEIVING_INITIAL_STATE: 'SET_RECEIVING_INITIAL_STATE',
  SET_RECEIVING_RELOAD: 'SET_RECEIVING_RELOAD',
  SET_RECEIVING_MODAL_OPEN: 'SET_RECEIVING_MODAL_OPEN',
  SET_RECEIVING_BY_ID: 'SET_RECEIVING_BY_ID',
  SET_DELETE_RECEIVING: 'SET_DELETE_RECEIVING',
  SET_RECEIVING_FILTERS: 'SET_RECEIVING_FILTERS',
  SET_RECEIVING_SORTING: 'SET_RECEIVING_SORTING',
  SET_PENDING_POST_CHECK_DOCUMENTS: 'SET_PENDING_POST_CHECK_DOCUMENTS',
  SET_RECEIVING_COLUMN_WIDTHS: 'SET_RECEIVING_COLUMN_WIDTHS',
  SET_RECEIVING_COLUMN_ORDER: 'SET_RECEIVING_COLUMN_ORDER',
  SET_RECEIVING_HIDDEN_COLUMN_NAMES: 'SET_RECEIVING_HIDDEN_COLUMN_NAMES',
});
