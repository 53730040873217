// Core
import { apply, put } from 'redux-saga/effects';

import { keys, has } from 'lodash';
// config
import { api } from '../../../../config/api';
import { listEntityName } from '../../../../config/listTablesName';

// actions
import actions from '../../action';

const entity = listEntityName.charges;

export function* callGetChargesAutocompleteWorker({ payload: params }) {
  const name = keys(params)[0];
  const response = yield apply(api, api.charges.getChargesAutocomplete, [{ ...params, entity }]);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(actions.setAutocompleteOptions({ [name]: [] }));
      return;
    }
    const { items } = data;
    yield put(actions.setAutocompleteOptions({
      [name]: items,
    }));
  }
}
