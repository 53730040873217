// Helpers
import actionCreator from '../../_helpers/actionCreator';
// Types
import types from './types';

const productsActions = Object.freeze({
  setProductsData(data) {
    return actionCreator(types.SET_PRODUCTS_DATA, data);
  },
  setProductById(data) {
    return actionCreator(types.SET_PRODUCT_BY_ID, data);
  },
  setProductDataById(data) {
    return actionCreator(types.SET_PRODUCT_DATA_BY_ID, data);
  },
  setCurrentPage(currentPage) {
    return actionCreator(types.SET_PRODUCTS_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return actionCreator(types.SET_PRODUCTS_FILTERS, filters);
  },
  setSorting(sorting) {
    return actionCreator(types.SET_PRODUCTS_SORTING, sorting);
  },
  setColumnWidths(data) {
    return actionCreator(types.SET_PRODUCTS_COLUMN_WIDTHS, data);
  },
  setColumnOrder(data) {
    return actionCreator(types.SET_PRODUCTS_COLUMN_ORDER, data);
  },
  setHiddenColumnNames(data) {
    return actionCreator(types.SET_PRODUCTS_HIDDEN_COLUMN_NAMES, data);
  },
  setPageSize(pageSize) {
    return actionCreator(types.SET_PRODUCTS_PAGE_SIZE, pageSize);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_PRODUCTS_INITIAL_STATE, initialState);
  },
  setReload(reload) {
    return actionCreator(types.SET_PRODUCTS_RELOAD, reload);
  },
  setDeleteProduct(data) {
    return actionCreator(types.SET_DELETE_PRODUCT, data);
  },
  setIsModalOpen(data) {
    return actionCreator(types.SET_PRODUCTS_IS_MODAL_OPEN, data);
  },
});

export default productsActions;
