export const euSignCodes = Object.freeze({
  0xFFFF: 'Невідома помилка',
  0xFFFE: 'Бібліотеку не ініціалізовано',
  0x0001: 'Операція не підтримується',
  0x0002: 'Невірний параметр',
  0x0003: 'Виникла помилка при завантаженні базових бібліотек',
  0x0004: 'Виникла помилка при зчитуванні параметрів з системного реєстру',
  0x0005: 'Виникла помилка при передачі запиту на сервер ЦСК за протоколом HTTP',
  0x0006: 'Виникла помилка при виділенні памяті',
  0x0008: 'Автентифікація на proxy-сервері не можлива',
  0x0009: 'Діалог з оператором не підтримується',
  0x000A: 'Виникла помилка при завантаженні файлу з HTTP-сервера',
  0x000B: 'Виникла помилка при записі параметрів у системний реєстр',
  0x000C: 'Операція відмінена оператором',
  0x000D: 'Доступ до сервера ЦСК не можливий (увімкнено offline-режим)',
  0x0011: 'Виникла помилка при роботі з носіями ключової інформації',
  0x0012: 'Виникла помилка при доступі до носія ключової інформації',
  0x0013: 'Виникла помилка при зчитуванні особистого ключа з носія ключової інформацii',
  0x0014: 'Виникла помилка при записі особистого ключа на носій ключової інформації',
  0x0015: 'Носій ключової інформації не підтримує знищення даних',
  0x0016: 'Виникла помилка при видаленні особистого ключа з носія ключової інформації',
  0x0017: 'Виникла помилка при очищенні носія ключової інформації',
  0x0018: 'Виникла помилка при відкритті особистого ключа (невірний пароль чи ключ пошкоджений)',
  0x0021: 'Виникла помилка при розборі даних (пошкоджені дані чи невірний формат)',
  0x0022: 'Виникла помилка при виконанні криптоперетворень',
  0x0023: 'Невірний підпиc',
  0x0024: 'Виникла помилка при автентифікації (дані автентифікації пошкоджені)',
  0x0025: 'Власник особистого ключа відсутній у списку одержувачів зашифрованих даних',
  0x0031: 'Виникла помилка при роботі з файловим сховищем сертифікатів та СВC',
  0x0032: 'Сертифікат пошкоджений',
  0x0033: 'Сертифікат не знайдено',
  0x0034: 'Сертифікат не чинний за строком дії',
  0x0035: 'Сертифікат не чинний (при перевірці за допомогою СВС)',
  0x0036: 'СВС пошкоджений',
  0x0037: 'Не знайдено діючих СВС',
  0x0041: 'Виникла помилка при отриманні позначки часу',
  0x0042: 'Відповідь від TSP-сервера пошкоджена',
  0x0043: 'Сертифікат TSP-сервера не знайденo',
  0x0044: 'Сертифікат TSP-сервера не чинний',
  0x0051: 'Виникла помилка при спробі отримати статус сертифіката за протоколом OCSP',
  0x0052: 'Відповідь від OCSP-сервера пошкоджена',
  0x0053: 'Сертифікат не чинний (при перевірці за протоколом OCSP)',
  0x0054: 'Сертифікат OCSP-сервера не знайдено',
  0x0055: 'Сертифікат OCSP-сервера не чинний',
  0x0061: 'Виникла помилка при роботі з LDAP-сервером',
});
