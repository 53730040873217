// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const asyncActions = Object.freeze({
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_DASHBOARD_INITIAL_STATE_ASYNC, path);
  },
  getDashboardAsync(params) {
    return actionCreator(asyncTypes.GET_DASHBOARD_ASYNC, params);
  },
});

export default asyncActions;
