// Core
import { call, put } from 'redux-saga/effects';

// config
import { api } from '../../../../../config/api';

// actions
import action from '../../action';

export function* callGetEmailSignatureByIdWorker({ payload: id }) {
  const {
    setSignatureData, setPendingSignature,
  } = action;
  yield put(setPendingSignature(true));

  const response = yield call(api.emailSignatures.getEmailSignatureById, id);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    yield put(setSignatureData(data));
  }

  yield put(setPendingSignature(false));
}
