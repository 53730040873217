const asyncTypes = Object.freeze({
  GET_DOCUMENTS_TYPE_ASYNC: 'GET_DOCUMENTS_TYPE_ASYNC',
  POST_SEND_ASYNC: 'POST_SEND_ASYNC',
  DELETE_MULTIPLE_ASYNC: 'DELETE_MULTIPLE_ASYNC',
  PUT_MULTIPLE_ASYNC: 'PUT_MULTIPLE_ASYNC',
  POST_AUTOCOMPLETE_GROUP_ASYNC: 'POST_AUTOCOMPLETE_GROUP_ASYNC',
  GET_OBJECT_STATUSES_ASYNC: 'GET_OBJECT_STATUSES_ASYNC',
  POST_SIGN_ASYNC: 'POST_SIGN_ASYNC',
  POST_SUPPORT_TICKETS_ASYNC: 'POST_SUPPORT_TICKETS_ASYNC',
  GET_INITIAL_VISIBLE_SIDEBAR_ASYNC: 'GET_INITIAL_VISIBLE_SIDEBAR_ASYNC',
  POST_CREATE_PACKET_ASYNC: 'POST_CREATE_PACKET_ASYNC',
  GET_FILES_BASE_64_ASYNC: 'GET_FILES_BASE_64_ASYNC',
  GET_IMAGES_BASE_64_ASYNC: 'GET_IMAGES_BASE_64_ASYNC',
  POST_CALC_PATENTS_RENEWAL_ASYNC: 'POST_CALC_PATENTS_RENEWAL_ASYNC',
  GET_DOWNLOAD_FILE_ASYNC: 'GET_DOWNLOAD_FILE_ASYNC',
  GET_PREVIEW_FILE_BY_ID_ASYNC: 'GET_PREVIEW_FILE_BY_ID_ASYNC',
  GET_DOWNLOAD_EXPORT_ASYNC: 'GET_DOWNLOAD_EXPORT_ASYNC',
  SET_SAVE_FRONTEND_SETTING: 'SET_SAVE_FRONTEND_SETTING',
});

export default asyncTypes;
