// ui
import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import {
  Box,
  Card, CardContent,
  CircularProgress,
  Divider as MuiDivider,
  Grid,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';
import Button from '@material-ui/core/Button';
import { NavigateBefore as NavigateBeforeIcon } from '@material-ui/icons';

// lodash
import find from 'lodash/find';
import values from 'lodash/values';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

// hooks
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import { Field, Form, reduxForm } from 'redux-form/lib/immutable';
import { Redirect, Link as MuiLink } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import { useEventsAutocompleteAsync } from '../../../../ui/_hooks/useEventsAutocompleteAsync';

// actions
import asyncActionCategory from '../../../../engine/core/prices/category/saga/asyncAction';
import asyncActionPaymentReceivers from '../../../../engine/core/paymentReceivers/saga/asyncAction';
import actions from '../../../../engine/core/prices/tariffs/tariff/action';
import asyncAction from '../../../../engine/core/prices/tariffs/tariff/saga/asyncAction';

// libraries
import Loader from '../../../../components/Loader';
import Select from '../../../../ui/Form/Select';

// engine
import selectors from '../../../../engine/config/selectors';

// _helper
import { fields, formNameTariff } from './helper/form';
import { pageLinks, routersLink } from '../../../../routes';

// components
import RenderTextField from '../../../../ui/Form/RenderTextField';
import TermFromDocument from './Fields/TermFromDocument';
import PaymentReceivers from './Fields/PaymentReceivers';
import TariffTable from './Table/TariffTable';

// config
import globalObjectTypes from '../../../../engine/config/globalObjectTypes';
import { validators } from '../../../../ui/_helpers/validation';
import accessList from '../../../../engine/config/accessList';
import { useAccessList } from '../../../../ui/_hooks/useAccessList';

// styles
const Divider = styled(MuiDivider)(spacing);
const Link = styled(MuiLink)`
  text-decoration: underline;
  color: #1976d2;
  display: flex;
  align-items: center;
`;

const Tariff = (props) => {
  const {
    handleSubmit, goToPageTariffs, categoriesData, valid,
    pending, pendingPut, pendingPost, match, allCompanies,
    editItem, initialize, documentTypesData, selectedCompany,
  } = props;

  const { getAutocompleteLists } = useEventsAutocompleteAsync();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const editHashId = match.params?.hashId;
  const accessPut = useAccessList(accessList.costs_put);
  const accessCategoryList = useAccessList(accessList.costsCategories_list_get);
  const accessPaymentReceiversList = useAccessList(accessList.paymentReceivers_list_get);
  const accessCompanyList = useAccessList(accessList.company_list_get);
  const accessEdit = !isEmpty(match.params) ? accessPut : true;

  useEffect(() => {
    if (editHashId) {
      dispatch(asyncAction.getTariffID(editHashId));
      dispatch(actions.setHashID(editHashId));
    }
  }, [editHashId, dispatch]);

  // Получение данных для инпутов
  useEffect(() => {
    getAutocompleteLists(values(fields));
    if (accessPaymentReceiversList) dispatch(asyncActionPaymentReceivers.getListAsync());
    if (accessCategoryList) {
      dispatch(asyncActionCategory.getListAsync({
        sortBy: 'createdAt',
        sortDir: 'asc',
        limit: 100,
        page: 1,
      }));
    }
  }, [getAutocompleteLists, dispatch, accessCategoryList, accessPaymentReceiversList]);

  // Когда происходит Redirect
  useEffect(() => {
    dispatch(actions.setGotToPageTariffs(false));
  }, [goToPageTariffs, dispatch]);

  // Инициализация redux-form
  useEffect(() => {
    initialize(editHashId ? {
      ...editItem,
      termFromDocument: find(documentTypesData.toJS().items, { id: editItem.termFromDocument }),
      category: editItem.category || '',
      paymentReceivers: editItem.paymentReceivers || '',
      ...!isEmpty(editItem.company) ? {
        company: editItem.company.id,
      } : {},
    } : {
      category: '',
      paymentReceivers: '',
      company: selectedCompany || 1,
    });
  }, [editItem, editHashId, documentTypesData, initialize, selectedCompany]);

  // Отправка данных
  const handleSubmits = (params) => {
    const data = params.toJS();

    const getData = {
      ...data,
      [fields.termFromDocument]: data.termFromDocument?.id,
      [fields.category]: !data.category ? null : data.category,
      [fields.paymentReceivers]: !data.paymentReceivers ? null : data.paymentReceivers,
    };

    if (editHashId) {
      dispatch(asyncAction.putTariffAsync({
        id: editHashId,
        ...getData,
      }));
    } else {
      dispatch(asyncAction.postTariffAsync({ ...getData }));
    }
  };

  return !pending ? (
    <>
      <Helmet title={t('tariff')} />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h3" display="inline">
          { t('tariff') }
        </Typography>
        <Link to={pageLinks.prices.tariffs.all}>
          <NavigateBeforeIcon size="small" color="primary" />
          {t('Up to the list')}
        </Link>
      </Box>
      <Divider my={6} />

      <Form onSubmit={handleSubmit(handleSubmits)}>
        <Box mb={5}>
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" paragraph>
                {t('General Information')}
              </Typography>

              <Grid container spacing={6}>

                <Grid item xs={12} md={4}>
                  <Field
                    required
                    validate={validators.required}
                    name={fields.name}
                    id={fields.name}
                    label={t('Name')}
                    margin="normal"
                    variant="standard"
                    my={2}
                    fullWidth
                    type="text"
                    component={RenderTextField}
                    disabled={!accessEdit}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Field
                    name={fields.billName}
                    id={fields.billName}
                    label={t('billName')}
                    margin="normal"
                    variant="standard"
                    my={2}
                    fullWidth
                    type="text"
                    component={RenderTextField}
                    disabled={!accessEdit}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Field
                    name={fields.code}
                    id={fields.code}
                    label={t('Code')}
                    margin="normal"
                    variant="standard"
                    my={2}
                    fullWidth
                    type="text"
                    component={RenderTextField}
                    disabled={!accessEdit}
                  />
                </Grid>

              </Grid>

              <Grid container spacing={6}>

                <Grid item xs={12} md={4}>
                  <Field
                    required
                    validate={validators.required}
                    name={fields.type}
                    id={fields.type}
                    label={t('Type')}
                    my={2}
                    labelId="role"
                    items={[
                      {
                        name: t('Service'),
                        value: '1',
                      },
                      {
                        name: t('collection'),
                        value: '2',
                      },
                    ]}
                    fullWidth
                    disabled={!accessEdit}
                    component={Select}
                  />

                </Grid>

                <Grid item xs={12} md={4}>
                  <Field
                    required
                    validate={validators.required}
                    name={fields.objectType}
                    id={fields.objectType}
                    label={t('Object type')}
                    margin="normal"
                    labelId="role"
                    items={globalObjectTypes}
                    variant="standard"
                    my={2}
                    fullWidth
                    type="text"
                    component={Select}
                    disabled={!accessEdit}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Field
                    displayEmpty
                    textEmpty={t('Not selected')}
                    name={fields.category}
                    id={fields.category}
                    label={t('Category')}
                    margin="normal"
                    labelId="role"
                    items={categoriesData.toJS().items}
                    variant="standard"
                    my={2}
                    fullWidth
                    type="text"
                    component={Select}
                    disabled={!accessEdit}
                  />
                </Grid>

              </Grid>

              <Grid container spacing={6}>

                <Grid item xs={12} md={4}>
                  <TermFromDocument
                    name={fields.termFromDocument}
                    disabled={!accessEdit}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Field
                    name={fields.term}
                    id={fields.term}
                    label={t('term')}
                    margin="normal"
                    variant="standard"
                    my={2}
                    fullWidth
                    type="number"
                    component={RenderTextField}
                    disabled={!accessEdit}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <PaymentReceivers disabled={!accessEdit} />
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Box>

        {accessCompanyList && (
          <Box mb={5}>
            <Card mb={6}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {t('Legal person')}
                </Typography>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <Field
                      name="company"
                      id="company"
                      label={t('Legal person')}
                      labelId="company"
                      my={1}
                      items={allCompanies.toJS()}
                      fullWidth
                      component={Select}
                      disabled={!accessEdit}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        )}

        <Card mb={6}>
          <CardContent>
            <TariffTable id={editHashId} accessEdit={accessEdit} />
          </CardContent>
        </Card>

        {accessEdit && (
          <Box mt={3}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!valid}
            >
              {
                pendingPut || pendingPost
                  ? <CircularProgress size={25} color="inherit" />
                  : t('SAVE')
              }
            </Button>
          </Box>
        )}
      </Form>

      {
        goToPageTariffs
        && <Redirect to={routersLink.prices.controller + routersLink.prices.tariffs} />
      }
    </>
  ) : <Loader />;
};

Tariff.displayName = 'Tariff';

Tariff.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  goToPageTariffs: PropTypes.bool.isRequired,
  categoriesData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pending: PropTypes.bool.isRequired,
  pendingPut: PropTypes.bool.isRequired,
  pendingPost: PropTypes.bool.isRequired,
  match: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  editItem: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  initialize: PropTypes.func.isRequired,
  documentTypesData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  valid: PropTypes.bool.isRequired,
  selectedCompany: PropTypes.string.isRequired,
  allCompanies: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

function mapStateToProps(state) {
  return {
    goToPageTariffs: selectors.pricesTariff.goToPageTariff(state),
    pending: selectors.pricesTariff.pendingTariff(state),
    pendingPut: selectors.pricesTariff.pendingTariffPut(state),
    pendingPost: selectors.pricesTariff.pendingTariffPost(state),
    editItem: selectors.pricesTariff.getEditItem(state),
    categoriesData: selectors.pricesCategory.categoriesData(state),
    documentTypesData: selectors.documentTypes.documentTypesData(state),
    selectedCompany: selectors.helpers.selectedCompany(state),
    allCompanies: selectors.companiesTable.allCompanies(state),
  };
}

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.handleSubmit, nextProps.handleSubmit)
    && isEqual(prevProps.goToPageTariffs, nextProps.goToPageTariffs)
    && isEqual(prevProps.categoriesData, nextProps.categoriesData)
    && isEqual(prevProps.match, nextProps.match)
    && isEqual(prevProps.editItem, nextProps.editItem)
    && isEqual(prevProps.documentTypesData, nextProps.documentTypesData)
    && isEqual(prevProps.initialize, nextProps.initialize)
    && isEqual(prevProps.valid, nextProps.valid)
    && isEqual(prevProps.pending, nextProps.pending)
    && isEqual(prevProps.pendingPut, nextProps.pendingPut)
    && isEqual(prevProps.selectedCompany, nextProps.selectedCompany)
    && isEqual(prevProps.allCompanies, nextProps.allCompanies)
    && isEqual(prevProps.pendingPost, nextProps.pendingPost);
}

export default compose(
  reduxForm({
    form: formNameTariff,
  }),
  connect(mapStateToProps, null),
)(React.memo(Tariff, areEqual));
