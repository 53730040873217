export const formName = 'Action';

export const fieldsAutocomplete = Object.freeze({
  status: 'status',
  title: 'title',
  end: 'end',

  // fetch
  originalUsers: 'original.users',
  originalIndustrial: 'original.industrial',
  originalUtility: 'original.utility',
  originalTrademarks: 'original.trademarks',
  originalInventions: 'original.inventions',

  // redux-form
  users: 'users',
  industrial: 'industrial',
  utility: 'utility',
  trademarks: 'trademarks',
  inventions: 'inventions',
  documents: 'documents',
  charges: 'charges',

  applicationNumber: 'applicationNumber',
});
