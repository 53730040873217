// Core
import React from 'react';
import * as PropTypes from 'prop-types';

// lodash
import isEqual from 'lodash/isEqual';

// Engine
import { FilteringState } from '@devexpress/dx-react-grid';

function DxFilteringState(props) {
  const {
    filters, defaultFilters, filtersAction,
  } = props;

  return (
    <FilteringState
      onFiltersChange={filtersAction}
      filters={filters}
      defaultFilters={defaultFilters}
    />
  );
}

DxFilteringState.displayName = 'DxFilteringState';
DxFilteringState.propTypes = {
  filtersAction: PropTypes.func.isRequired,
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]),
  defaultFilters: PropTypes.oneOfType([
    PropTypes.array,
  ]),
};
DxFilteringState.defaultProps = {
  filters: [],
  defaultFilters: [],
};

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.filters, nextProps.filters)
    && isEqual(prevProps.defaultFilters, nextProps.defaultFilters);
}

export default React.memo(DxFilteringState, areEqual);
