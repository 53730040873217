// Core
import { apply, put, select } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../config/api';
import selectors from '../../../../config/selectors';
import { messageRequestSuccess } from '../../../../config/globalConfig';

// actions
import actions from '../../action';
import asyncActions from '../asyncAction';
import helpersActions from '../../../helpers/action';
import { setErrorMessage, setSuccessMessage } from '../../../../../ui/_helpers/setNotificationMessage';
import i18n from '../../../../init/i18n';

export function* callPutProposalWorker({ payload: params }) {
  yield put(actions.setPendingPutProposal(true));

  const permissionSign = yield select(selectors.helpers.permissionSign);

  const response = yield apply(api, api.industrialDesigns.putProposal, [params]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      yield put(helpersActions.setNotPendingGetById(true));
      yield put(setSuccessMessage(i18n.t(messageRequestSuccess), response.status.toString()));
      yield put(asyncActions.getProposalByIdAsync(data.id));
      if (permissionSign) {
        yield put(helpersActions.setSignIsModalOpen(true));
      }
    }
  }
  yield put(actions.setPendingPutProposal(false));
  yield put(helpersActions.setPermissionSign(false));
}
