// Core
import { put } from 'redux-saga/effects';

// lodash
import isBoolean from 'lodash/isBoolean';
import isEmpty from 'lodash/isEmpty';

// Engine
import localData from '../../../../config/localData';

// action
import actions from '../../action';

export function* callGetInitialVisibleSidebarWorker({ payload }) {
  const dataLocal = yield localData.getItem(payload.url);
  if (payload.url === 'visibleSidebar') {
    yield put(actions.setVisibleSidebar(isBoolean(dataLocal) ? dataLocal : true));
  }
  if (payload.url === 'signatureInfo' && !isEmpty(dataLocal)) {
    yield put(actions.setSignatureInfo(dataLocal));
  }
  if (payload.url === 'selectedCompany' && dataLocal) {
    yield put(actions.setSelectedCompany(dataLocal));
  }
  if (payload.url === 'currentLongMenu' && !isEmpty(dataLocal)) {
    const [entity, index, data] = dataLocal;
    if (payload.entity === entity) {
      yield put(actions.setCurrentLongMenu(entity, index, data));
      yield put(actions.setInitialLongMenu(true));
    }
  }
}
