// Core
import { all, call, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetPaymentAllocationsWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callDeletePaymentAllocationsWorker,
  callSetSelectionsWorker,
} from './workers';
import types from '../types';

function* watchGetPaymentAllocations() {
  yield takeEvery(asyncTypes.GET_PAYMENT_ALLOCATIONS_ASYNC, callGetPaymentAllocationsWorker);
}
function* watchGetInitialState() {
  yield takeEvery(
    asyncTypes.GET_PAYMENT_ALLOCATIONS_INITIAL_STATE_ASYNC, callGetInitialStateWorker,
  );
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_PAYMENT_ALLOCATIONS_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_PAYMENT_ALLOCATIONS_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_PAYMENT_ALLOCATIONS_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_PAYMENT_ALLOCATIONS_SORTING, callSetToLocalDataWorker);
}
function* watchDeletePaymentAllocations() {
  yield takeEvery(asyncTypes.DELETE_PAYMENT_ALLOCATIONS_ASYNC, callDeletePaymentAllocationsWorker);
}
function* watchSetSelectionsWorker() {
  yield takeEvery(asyncTypes.SET_SECTIONS_PAYMENT_ALLOCATIONS_ASYNC, callSetSelectionsWorker);
}

export function* watchersPaymentAllocations() {
  yield all([
    call(watchGetPaymentAllocations),
    call(watchSetCurrentPage),
    call(watchSetPageSize),
    call(watchSetFilters),
    call(watchSetSorting),
    call(watchGetInitialState),
    call(watchDeletePaymentAllocations),
    call(watchSetSelectionsWorker),
  ]);
}
