// Core
import { apply, put } from 'redux-saga/effects';
// Engine
import { authService } from '../../../../config/api';
import userActions from '../../action';
import i18n, { locale } from '../../../../init/i18n';
// Data
import { uiActions } from '../../../ui/action';

export function* callAuthCleanUpWorker() {
  // Set initial application state
  yield put(uiActions.setInitialApplicationState());

  // Clear auth data
  yield apply(authService, authService.setUserInfo, [{}]);

  // Clear user info
  yield put(userActions.setUserInfo({}));

  // Set default language
  yield apply(i18n, i18n.changeLanguage, [locale.ua]);

  sessionStorage.removeItem('rememberMe');
}
