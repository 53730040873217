// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import types from './types';

const actions = Object.freeze({
  setMadridCasesData(calculationsData) {
    return actionCreator(types.SET_MADRID_CASE_DATA, calculationsData);
  },
  setCurrentPage(currentPage) {
    return actionCreator(types.SET_MADRID_CASE_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return actionCreator(types.SET_MADRID_CASE_FILTERS, filters);
  },
  setSorting(sorting) {
    return actionCreator(types.SET_MADRID_CASE_SORTING, sorting);
  },
  setColumnWidths(data) {
    return actionCreator(types.SET_MADRID_CASE_COLUMN_WIDTHS, data);
  },
  setColumnOrder(data) {
    return actionCreator(types.SET_MADRID_CASE_COLUMN_ORDER, data);
  },
  setHiddenColumnNames(data) {
    return actionCreator(types.SET_MADRID_CASE_HIDDEN_COLUMN_NAMES, data);
  },
  setPageSize(pageSize) {
    return actionCreator(types.SET_MADRID_CASE_PAGE_SIZE, pageSize);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_MADRID_CASE_INITIAL_STATE, initialState);
  },
  setReload(reload) {
    return actionCreator(types.SET_MADRID_CASE_RELOAD, reload);
  },
  setDeleteMadridCase(data) {
    return actionCreator(types.SET_DELETE_MADRID_CASE, data);
  },
  setMadridCaseDataById(data) {
    return actionCreator(types.SET_MADRID_CASE_DATA_BY_ID, data);
  },
  setMadridCaseById(data) {
    return actionCreator(types.SET_MADRID_CASE_BY_ID, data);
  },
  setModalAddOpen(isOpen) {
    return actionCreator(types.SET_MADRID_CASE_MODAL_ADD_IS_OPEN, isOpen);
  },
});

export default actions;
