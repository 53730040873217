// Core
import { apply, put, select } from 'redux-saga/effects';

// Engine
import i18n, { locale } from '../../../../init/i18n';
import { api } from '../../../../config/api';
import selectors from '../../../../config/selectors';

// actions
import userActions from '../../action';

export function* callPutCurrentLanguageWorker({ payload: language }) {
  const { id: hashId } = yield select(selectors.user.userInfo);
  const response = yield apply(api, api.user.putUserLanguage, [{ language, hashId }]);

  if (response && response.status >= 200 && response.status < 400) {
    yield apply(i18n, i18n.changeLanguage, [locale[language]]);
    yield put(userActions.setUserInfo({
      language,
    }));
  }
}
