// Core
import { call, put } from 'redux-saga/effects';
// import { push } from 'connected-react-router';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../../config/api';
import { messageRequestSuccess } from '../../../../../config/globalConfig';

// init
import i18n from '../../../../../init/i18n';

// actions
import ordersActions from '../../action';
import asyncActions from '../asyncAction';
import chargesAsyncActions from '../../../../charges/saga/asyncAction';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../../ui/_helpers/setNotificationMessage';

// routes
// import { pageLinks } from '../../../../../../routes';

export function* callPutOrderByIdWorker({ payload: order }) {
  const { setOrderById } = ordersActions;

  yield put(setOrderById({
    pendingPut: true,
  }));

  const response = yield call(api.company.putOrderById, order);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      yield put(setSuccessMessage(i18n.t(messageRequestSuccess), response.status.toString()));
      yield put(asyncActions.getOrderByIdAsync(data.id));
      yield put(chargesAsyncActions.getListAsync({ ordersId: data.id }));
      // yield put(push(pageLinks.company.orders.all));
    }
  }

  yield put(setOrderById({
    pendingPut: false,
  }));
}
