// core
import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { Field, reduxForm } from 'redux-form/immutable';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useDispatch, connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

// ui
import {
  Button as MuiButton,
  Paper,
  Typography, CircularProgress,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';

// parts
import RenderTextField from '../../ui/Form/RenderTextField';

// helpers
import { validators } from '../../ui/_helpers/validation';

// config
import selectors from '../../engine/config/selectors';

// actions
import userActionAsync from '../../engine/core/user/saga/asyncAction';

const Button = styled(MuiButton)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;
  width: 100%;

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

function ResetPassword(props) {
  const { handleSubmit, valid, pending } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleSubmits = (formData) => {
    const jsonFormData = formData.toJSON();
    dispatch(userActionAsync.resetPasswordAsync(jsonFormData));
  };
  return (
    <Wrapper>
      <Helmet title={t('Reset Password')} />
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        {t('Reset Password')}
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        {t('Enter your email to reset your password')}
      </Typography>
      <form onSubmit={handleSubmit(handleSubmits)}>
        <Field
          name="email"
          component={RenderTextField}
          id="email"
          label={t('Email')}
          margin="normal"
          htmlFor="password"
          fullWidth
          required
          validate={[validators.required, validators.email]}
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          mb={2}
          disabled={!valid || pending}
        >
          {pending ? <CircularProgress size={25} color="inherit" /> : t('Send')}
        </Button>

      </form>
    </Wrapper>
  );
}

function mapStateToProps(state) {
  return {
    pending: selectors.user.pendingResetPassword(state),
  };
}

ResetPassword.propTypes = {
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  pending: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
};

export default compose(
  reduxForm({
    form: 'resetPassword',
  }),
  connect(mapStateToProps, null),
)(ResetPassword);
