// core
import React, {
  memo, useCallback,
} from 'react';
import { spacing } from '@material-ui/system';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Field, change,
} from 'redux-form/immutable';

// lodash
import { isEqual } from 'lodash';

// UI
import {
  CardContent, Grid as MuiGrid,
  Typography as MuiTypography, Card as MuiCard,
} from '@material-ui/core';

// config
import {
  priorityOptionsTypeFirst, priorityOptionsTypeSecond,
} from '../../../../../engine/config/priorityTypes';

// parts
import Select from '../../../../../ui/Form/Select';
import RenderTextField from '../../../../../ui/Form/RenderTextField';
import DatePickers from '../../../../../ui/Form/DatePickers';
import ButtonSubmit from '../../../../../ui/Button/ButtonSubmit';

// styles
const Card = styled(MuiCard)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);

function CardPriority(props) {
  const {
    formName, pending, formFields, priorityType,
    priorityTypeValues, disabled,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleChangePriority = useCallback(() => {
    if (priorityType === 'first') {
      dispatch(change(formName, formFields.dataPriorityExhibitionDate, null));
      dispatch(change(formName, formFields.dataPriorityEarlyAppNumber, null));
      dispatch(change(formName, formFields.dataPriorityEarlyAppDate, null));
      dispatch(change(formName, formFields.dataPriorityCountry, null));
      return;
    }
    dispatch(change(formName, formFields.dataPriorityEarlyAppNumber, null));
    dispatch(change(formName, formFields.dataPriorityEarlyAppDate, null));
    dispatch(change(formName, formFields.dataPriorityCountry, null));
    dispatch(change(formName, formFields.dataPriorityNationalParentAppDetails, null));
    dispatch(change(formName, formFields.dataPriorityNationalAppDetails, null));
    dispatch(change(formName, formFields.dataPriorityType, null));
    dispatch(change(formName, formFields.dataPriorityClaim, null));
    dispatch(change(formName, formFields.dataPriorityAppNumber, null));
  }, [dispatch, priorityType, formFields, formName]);

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h2" gutterBottom>
          {t('Priority')}
        </Typography>

        <Grid container spacing={4}>
          <Grid container item xs={12}>
            <Grid item xs={12} md={4}>
              <Field
                name={formFields.dataPriorityType}
                id={formFields.dataPriorityType}
                label={t('Priority')}
                labelId="Priority"
                my={1}
                items={priorityType === 'first'
                  ? priorityOptionsTypeFirst
                  : priorityOptionsTypeSecond}
                fullWidth
                component={Select}
                onChange={handleChangePriority}
                disabled={disabled}
              />
            </Grid>
          </Grid>

          {priorityTypeValues === 'earlyapp' && priorityType === 'first' && (
            <>
              <Grid container item xs={12}>
                <Grid item xs={12} md={4}>
                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    {t('Submitting a prior application')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>

                <Field
                  name={formFields.dataPriorityEarlyAppNumber}
                  id={formFields.dataPriorityEarlyAppNumber}
                  label={t('Application number')}
                  margin="normal"
                  variant="standard"
                  my={1}
                  fullWidth
                  type="text"
                  component={RenderTextField}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  name={formFields.dataPriorityEarlyAppDate}
                  id={formFields.dataPriorityEarlyAppDate}
                  labelId={formFields.dataPriorityEarlyAppDate}
                  label={t('Date of submission')}
                  margin="normal"
                  variant="standard"
                  my={1}
                  fullWidth
                  type="text"
                  formatDate="dd.MM.yyyy"
                  component={DatePickers}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  name={formFields.dataPriorityCountry}
                  id={formFields.dataPriorityCountry}
                  label={t('Country code')}
                  margin="normal"
                  variant="standard"
                  my={1}
                  fullWidth
                  type="text"
                  component={RenderTextField}
                  disabled={disabled}
                />
              </Grid>
            </>
          )}

          {priorityTypeValues === 'exhibition' && (
            <>
              <Grid item xs={12} md={4}>
                <Typography variant="h6" color="textSecondary" gutterBottom>
                  {t('Exhibition priority')}
                </Typography>
                <Field
                  name={formFields.dataPriorityExhibitionDate}
                  id={formFields.dataPriorityExhibitionDate}
                  labelId={formFields.dataPriorityExhibitionDate}
                  label={t('Exhibition opening date')}
                  margin="normal"
                  variant="standard"
                  my={1}
                  fullWidth
                  type="text"
                  formatDate="dd.MM.yyyy"
                  component={DatePickers}
                  disabled={disabled}
                />
              </Grid>
            </>
          )}

          {priorityTypeValues !== 'noPriority' && priorityType === 'second' && (
            <>
              <Grid item xs={12} md={4}>

                <Field
                  name={formFields.dataPriorityClaim}
                  id={formFields.dataPriorityClaim}
                  label={t('Items of the formula')}
                  margin="normal"
                  variant="standard"
                  my={1}
                  fullWidth
                  type="text"
                  component={RenderTextField}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={4}>

                <Field
                  name={formFields.dataPriorityAppNumber}
                  id={formFields.dataPriorityAppNumber}
                  label={t('Application number')}
                  margin="normal"
                  variant="standard"
                  my={1}
                  fullWidth
                  type="text"
                  component={RenderTextField}
                  disabled={disabled}
                />
              </Grid>
            </>
          )}
          {priorityTypeValues === 'earlyapp' && priorityType === 'second' && (
            <>
              <Grid container item xs={12}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    {t('Submission of a preliminary application in a state party to the Paris Convention')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>

                <Field
                  name={formFields.dataPriorityEarlyAppNumber}
                  id={formFields.dataPriorityEarlyAppNumber}
                  label={t('Preliminary application number')}
                  margin="normal"
                  variant="standard"
                  my={1}
                  fullWidth
                  type="text"
                  component={RenderTextField}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  name={formFields.dataPriorityEarlyAppDate}
                  id={formFields.dataPriorityEarlyAppDate}
                  labelId={formFields.dataPriorityEarlyAppDate}
                  label={t('Date of submission')}
                  margin="normal"
                  variant="standard"
                  my={1}
                  fullWidth
                  type="text"
                  formatDate="dd.MM.yyyy"
                  component={DatePickers}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  name={formFields.dataPriorityCountry}
                  id={formFields.dataPriorityCountry}
                  label={t('Country code')}
                  margin="normal"
                  variant="standard"
                  my={1}
                  fullWidth
                  type="text"
                  component={RenderTextField}
                  disabled={disabled}
                />
              </Grid>
            </>
          )}
          {priorityTypeValues === 'nationalParentApp' && priorityType === 'second' && (
            <>
              <Grid container item xs={12}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    {t('Submission to the Office of a preliminary application from which this application is selected')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  name={formFields.dataPriorityNationalParentAppDetails}
                  id={formFields.dataPriorityNationalParentAppDetails}
                  label={t('Number and date of submission to the Office of the preliminary application from which this application is selected')}
                  margin="normal"
                  variant="standard"
                  my={1}
                  fullWidth
                  type="text"
                  component={RenderTextField}
                  disabled={disabled}
                />
              </Grid>
            </>
          )}
          {priorityTypeValues === 'nationalApp' && (
            <>
              <Grid container item xs={12}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    {t('Submission of a preliminary application to the Institution')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>

                <Field
                  name={formFields.dataPriorityNationalAppDetails}
                  id={formFields.dataPriorityNationalAppDetails}
                  label={t('Preliminary application number and date of submission to the Institution')}
                  margin="normal"
                  variant="standard"
                  my={1}
                  fullWidth
                  type="text"
                  component={RenderTextField}
                  disabled={disabled}
                />
              </Grid>
            </>
          )}
          {!disabled && (
            <Grid item xs={12}>
              <ButtonSubmit pending={pending} />
            </Grid>
          )}

        </Grid>
      </CardContent>
    </Card>
  );
}
CardPriority.propTypes = {
  formName: PropTypes.string.isRequired,
  pending: PropTypes.bool.isRequired,
  priorityType: PropTypes.string.isRequired,
  formFields: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  priorityTypeValues: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

CardPriority.defaultProps = {
  disabled: false,
};

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.formName, nextProps.formName)
  && isEqual(prevProps.priorityType, nextProps.priorityType)
  && isEqual(prevProps.formFields, nextProps.formFields)
  && isEqual(prevProps.priorityTypeValues, nextProps.priorityTypeValues)
  && isEqual(prevProps.disabled, nextProps.disabled)
  && isEqual(prevProps.pending, nextProps.pending);
}

export default memo(CardPriority, areEqual);
