import React from 'react';
import { Trans } from 'react-i18next';

export const priorityOptionsTypeFirst = [
  { name: <Trans>Not declare</Trans>, value: 'noPriority' },
  { name: <Trans>Submitting a prior application</Trans>, value: 'earlyapp' },
  { name: <Trans>Exhibition priority</Trans>, value: 'exhibition' },
];
export const priorityOptionsTypeSecond = [
  { name: <Trans>Not declare</Trans>, value: 'noPriority' },
  { name: <Trans>Submission of a preliminary application in a state party to the Paris Convention</Trans>, value: 'earlyapp' },
  { name: <Trans>Submission to the Office of a preliminary application from which this application is selected</Trans>, value: 'nationalParentApp' },
  { name: <Trans>Submission of a preliminary application to the Institution</Trans>, value: 'nationalApp' },
];
