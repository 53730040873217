// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const asyncActions = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_CHARGES_ASYNC, params);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_CHARGES_INITIAL_STATE_ASYNC, path);
  },
  getChargeByIdAsync(id) {
    return actionCreator(asyncTypes.GET_CHARGE_BY_ID_ASYNC, id);
  },
  postChargeAsync(data) {
    return actionCreator(asyncTypes.POST_CHARGE_ASYNC, data);
  },
  putChargeByIdAsync(data) {
    return actionCreator(asyncTypes.PUT_CHARGE_BY_ID_ASYNC, data);
  },
  deleteChargeAsync(chargeId) {
    return actionCreator(asyncTypes.DELETE_CHARGE_ASYNC, chargeId);
  },
  setSectionsAsync(data) {
    return actionCreator(asyncTypes.SET_SECTIONS_CHARGES_ASYNC, data);
  },
  getChargesAutocomplete(text) {
    return actionCreator(asyncTypes.GET_CHARGES_AUTOCOMPLETE_ASYNC, text);
  },
  postPaymentDetailsAsync(data) {
    return actionCreator(asyncTypes.POST_CHARGES_PAYMENT_DETAILS, data);
  },
});

export default asyncActions;
