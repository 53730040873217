// Core
import { call, put } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';

// init
import i18n from '../../../../../init/i18n';

// config
import { api } from '../../../../../config/api';
import { messageRequestSent } from '../../../../../config/globalConfig';

// actions
import employeesActions from '../../action';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../../ui/_helpers/setNotificationMessage';

export function* callPostInviteEmployeeWorker({ payload: employee }) {
  const { setEmployees } = employeesActions;

  yield put(setEmployees({
    pendingPost: true,
  }));

  const response = yield call(api.company.postInviteEmployee, employee);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      yield put(setSuccessMessage(i18n.t(messageRequestSent), response.status.toString()));
      yield put(setEmployees({
        isModalOpen: false,
      }));
    }
  }

  yield put(setEmployees({
    pendingPost: false,
  }));
}
