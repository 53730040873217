// core
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

// ui
import {
  Box, Tabs as MuiTabs, Tab,
  Button, ButtonGroup, Divider as MuiDivider,
  Typography,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import { Add as AddIcon } from '@material-ui/icons';

// parts
import DocumentTypesTable from './Types/DocumentTypesTable';
import CategoriesTable from './Categories/CategoriesTable';

// actions
import actions from '../../engine/core/documentTypes/action';
import categoryActions from '../../engine/core/documentTypes/category/action';
import accessList from '../../engine/config/accessList';
import { useAccessList } from '../../ui/_hooks/useAccessList';

// styles
const Divider = styled(MuiDivider)(spacing);
const Tabs = styled(MuiTabs)(spacing);

function DocumentTypes() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [valueTab, setValueTab] = useState(0);
  const accessPost = useAccessList(accessList.documentClassifier_post);
  const accessCategoryPost = useAccessList(accessList.documentClassifierCategory_post);

  const checkAccess = (accessPost && valueTab === 0) || (accessCategoryPost && valueTab === 1);

  const handleChangeTab = (e, value) => {
    setValueTab(value);
  };

  const handleAdd = () => {
    if (valueTab === 0) {
      dispatch(actions.setDocumentTypeById({ isModalOpen: true }));
      return;
    }
    dispatch(categoryActions.setDocumentTypeCategoryById({ isModalOpen: true }));
  };

  useEffect(() => function cleanup() {
    const emptyData = { items: [], pending: false, totalCount: 0 };
    dispatch(actions.setDocumentTypesData(emptyData));
    dispatch(categoryActions.setDocumentTypesCategoryData(emptyData));
  }, [dispatch]);

  return (
    <>
      <Helmet title={t('Document types')} />
      <Typography variant="h3" gutterBottom display="inline">
        {valueTab === 0 ? t('Document types') : t('Categories')}
      </Typography>
      <Divider my={6} />

      {checkAccess && (
        <Box mb={2}>
          <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleAdd}
            >
              {t('ADD')}
              <AddIcon />
            </Button>
          </ButtonGroup>
        </Box>
      )}

      <Tabs
        mb={5}
        value={valueTab}
        onChange={handleChangeTab}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label={t('Types')} />
        <Tab label={t('Categories')} />
      </Tabs>

      {valueTab === 0 ? (
        <DocumentTypesTable />
      ) : (
        <CategoriesTable />
      )}

    </>
  );
}

export default DocumentTypes;
