// core
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import {
  Field, Form, reduxForm,
} from 'redux-form/lib/immutable';

// lodash
import {
  isEmpty,
} from 'lodash';

// ui
import {
  Grid, Divider as MuiDivider, Card as MuiCard,
  ButtonGroup, Button, Collapse,
  Typography, CardContent as MuiCardContent,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';

// selectors
import selectors from '../../engine/config/selectors';

// routes
// import accessList from '../../engine/config/accessList';
// import { useAccessList } from '../../ui/_hooks/useAccessList';

// actions
import actions from '../../engine/core/dashboard/action';

// parts
import Select from '../../ui/Form/Select';
import ReduxDateRange from '../../ui/DateRange/ReduxDateRange';

// styles
const Divider = styled(MuiDivider)(spacing);
const CardFilters = styled(MuiCard)`
  margin-bottom: 24px;
`;

const CardContent = styled(MuiCardContent)`
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(2)}px;
  }
`;

function DashboardFilters(props) {
  const {
    handleSubmit, filters, initialize, destroy,
    getFormValues, objectStatuses,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isOpenFilters, setIsOpenFilters] = useState(false);

  const handleSubmits = (formData) => {
    const jsFormData = formData.toJS();
    dispatch(actions.setFilters(jsFormData));
  };

  useEffect(() => {
    if (!isEmpty(filters.toJS()) && isEmpty(getFormValues.toJS()) && !isOpenFilters) {
      initialize(filters.toJS());
      setIsOpenFilters(true);
    }
  }, [filters, isOpenFilters, getFormValues, initialize]);

  const handleResetFilters = () => {
    dispatch(actions.setFilters({}));
    setIsOpenFilters(false);
    destroy();
  };

  return (
    <>
      <Grid justify="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {t('Dashboard')}
          </Typography>
        </Grid>

        <Grid item>
          <ButtonGroup variant="contained" color="primary" aria-label="extra filter buttons group">
            <Button variant="contained" color="primary" onClick={() => setIsOpenFilters((prev) => !prev)}>
              {t('FILTERS')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={isEmpty(filters.toJS())}
              onClick={handleResetFilters}
            >
              {t('RESET FILTERS')}
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <Divider my={6} />

      <Collapse in={isOpenFilters}>
        <CardFilters>
          <CardContent>
            <Form onSubmit={handleSubmit(handleSubmits)}>
              <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={3}>
                  <Field
                    name="entity"
                    labelId="entity"
                    component={Select}
                    label={t('Object type')}
                    items={[
                      { value: 'trademarks', name: t('trademarks') },
                      { value: 'industrial', name: t('industrial') },
                      { value: 'inventions', name: t('inventions') },
                      { value: 'utility', name: t('utility') },
                    ]}
                    margin="normal"
                    fullWidth
                    displayEmpty
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Field
                    name="status"
                    id="status"
                    label={t('Status')}
                    labelId="status"
                    my={1}
                    items={objectStatuses}
                    fullWidth
                    component={Select}
                    displayEmpty
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Field
                    name="date"
                    id="date"
                    label={t('Period')}
                    margin="normal"
                    variant="standard"
                    my={1}
                    fullWidth
                    type="text"
                    defaultFormat="YYYY-MM-DD"
                    component={ReduxDateRange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    {t('APPLY')}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </CardContent>
        </CardFilters>
      </Collapse>
    </>
  );
}

DashboardFilters.displayName = 'DashboardFilters';

DashboardFilters.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  destroy: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  filters: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  getFormValues: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  objectStatuses: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

DashboardFilters.defaultProps = {};

function mapStateToProps(state) {
  return {
    clientsData: selectors.clientsTable.clientsData(state),
    filters: selectors.dashboard.filters(state),
    getFormValues: selectors.form.getFormValues(state, 'DashboardFilters'),
    objectStatuses: selectors.helpers.getObjectStatuses(state),
  };
}

export default compose(
  reduxForm({
    form: 'DashboardFilters',
  }),
  connect(mapStateToProps, null),
)(DashboardFilters);
