/**
 * Use when need to get postal address
 * @parameters addresses
 * */

// lodash
import find from 'lodash/find';

export const getPostalAddress = (addresses = []) => {
  const findAddress = find(addresses, { name: 'postal' });

  return findAddress || addresses?.[0] || {};
};
