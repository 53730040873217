// Core
import { apply, put } from 'redux-saga/effects';

// lodash
import { has } from 'lodash';

// config
import { api } from '../../../../config/api';
import { listEntityName } from '../../../../config/listTablesName';

// actions
import actions from '../../action';

const entity = listEntityName.applicants;

export function* callGetApplicantsListWorker({ payload: params }) {
  const {
    setApplicantsData,
    setApplicantsList,
  } = actions;
  yield put(setApplicantsData({
    pending: true,
  }));

  let newTotalCount = 0;
  const response = yield apply(api, api.applicants.getApplicants, [{ ...params, entity }]);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (!has(data, 'error')) {
      const { items, totalCount } = data;
      newTotalCount = totalCount;
      yield put(setApplicantsList(items));
    }
  }

  yield put(setApplicantsData({
    pending: false,
    totalCount: newTotalCount,
    reload: false,
  }));
}
