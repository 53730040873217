export default Object.freeze({
  SET_PRODUCTS_DATA: 'SET_PRODUCTS_DATA',
  SET_PRODUCT_BY_ID: 'SET_PRODUCT_BY_ID',
  SET_PRODUCTS_RELOAD: 'SET_PRODUCTS_RELOAD',
  SET_PRODUCTS_CURRENT_PAGE: 'SET_PRODUCTS_CURRENT_PAGE',
  SET_PRODUCTS_FILTERS: 'SET_PRODUCTS_FILTERS',
  SET_PRODUCTS_SORTING: 'SET_PRODUCTS_SORTING',
  SET_PRODUCTS_PAGE_SIZE: 'SET_PRODUCTS_PAGE_SIZE',
  SET_PRODUCTS_INITIAL_STATE: 'SET_PRODUCTS_INITIAL_STATE',
  SET_DELETE_PRODUCT: 'SET_DELETE_PRODUCT',
  SET_PRODUCTS_IS_MODAL_OPEN: 'SET_PRODUCTS_IS_MODAL_OPEN',
  SET_PRODUCT_DATA_BY_ID: 'SET_PRODUCT_DATA_BY_ID',
  SET_PRODUCTS_COLUMN_WIDTHS: 'SET_PRODUCTS_COLUMN_WIDTHS',
  SET_PRODUCTS_COLUMN_ORDER: 'SET_PRODUCTS_COLUMN_ORDER',
  SET_PRODUCTS_HIDDEN_COLUMN_NAMES: 'SET_PRODUCTS_HIDDEN_COLUMN_NAMES',
});
