// Core
import { all, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetPermissionsGroupsWorker,
  callGetPermissionsGroupByIdWorker,
  callPutPermissionsGroupByIdWorker,
  callPostPermissionsGroupWorker,
  callDeletePermissionsGroupWorker,
  callGetPermissionsWorker,
} from './workers';

function* watchGetPermissionsGroups() {
  yield takeEvery(asyncTypes.GET_PERMISSIONS_GROUPS_ASYNC, callGetPermissionsGroupsWorker);
}
function* watchGetPermissionsGroupById() {
  yield takeEvery(asyncTypes.GET_PERMISSIONS_GROUP_BY_ID_ASYNC, callGetPermissionsGroupByIdWorker);
}
function* watchPostPermissionsGroup() {
  yield takeEvery(asyncTypes.POST_PERMISSIONS_GROUP_ASYNC, callPostPermissionsGroupWorker);
}
function* watchPutPermissionsGroupById() {
  yield takeEvery(asyncTypes.PUT_PERMISSIONS_GROUP_BY_ID_ASYNC, callPutPermissionsGroupByIdWorker);
}
function* watchDeletePermissionsGroup() {
  yield takeEvery(asyncTypes.DELETE_PERMISSIONS_GROUP_ASYNC, callDeletePermissionsGroupWorker);
}

function* watchGetPermissions() {
  yield takeEvery(asyncTypes.GET_PERMISSIONS_ASYNC, callGetPermissionsWorker);
}

export function* watchersPermissionsGroups() {
  yield all([
    watchGetPermissionsGroups(),
    watchGetPermissionsGroupById(),
    watchPostPermissionsGroup(),
    watchPutPermissionsGroupById(),
    watchDeletePermissionsGroup(),
    watchGetPermissions(),
  ]);
}
