// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const asyncActions = Object.freeze({
  getSettingsAsync(params) {
    return actionCreator(asyncTypes.GET_SETTINGS_ASYNC, params);
  },
  putSettingAsync(params) {
    return actionCreator(asyncTypes.PUT_SETTING_ASYNC, params);
  },
});

export default asyncActions;
