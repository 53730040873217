const asyncTypes = Object.freeze({
  GET_UTILITY_MODELS_PROPOSAL_ASYNC: 'GET_UTILITY_MODELS_PROPOSAL_ASYNC',
  GET_UTILITY_MODELS_PROPOSAL_INITIAL_STATE_ASYNC: 'GET_UTILITY_MODELS_PROPOSAL_INITIAL_STATE_ASYNC',
  DELETE_UTILITY_MODEL_PROPOSAL_ASYNC: 'DELETE_UTILITY_MODEL_PROPOSAL_ASYNC',
  GET_UTILITY_MODELS_PROPOSAL_AUTOCOMPLETE_OPTIONS_ASYNC: 'GET_UTILITY_MODELS_PROPOSAL_AUTOCOMPLETE_OPTIONS_ASYNC',
  GET_UTILITY_MODELS_PROPOSAL_BY_ID_ASYNC: 'GET_UTILITY_MODELS_PROPOSAL_BY_ID_ASYNC',
  GET_UTILITY_MODELS_REGISTRY_BY_NUMBER_ASYNC: 'GET_UTILITY_MODELS_REGISTRY_BY_NUMBER_ASYNC',
  POST_UTILITY_MODELS_PROPOSAL_ASYNC: 'POST_UTILITY_MODELS_PROPOSAL_ASYNC',
  PUT_UTILITY_MODEL_PROPOSAL_ASYNC: 'PUT_UTILITY_MODEL_PROPOSAL_ASYNC',
  GET_UTILITY_MODELS_PROPOSAL_NEXT_NUMBER_ASYNC: 'GET_UTILITY_MODELS_PROPOSAL_NEXT_NUMBER_ASYNC',
  PUT_UTILITY_MODELS_PROPOSAL_CLONE_ASYNC: 'PUT_UTILITY_MODELS_PROPOSAL_CLONE_ASYNC',
});

export default asyncTypes;
