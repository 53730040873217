// Core
import React, {
  useState, useMemo, useEffect, memo, useCallback,
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  Field, Form, reduxForm, change,
} from 'redux-form/lib/immutable';
import { compose } from 'recompose';
import { connect, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';

// Lodash
import {
  isEqual, isEmpty, filter, isArray,
  map, keys, upperFirst, find,
  reduce, split, isString, pullAt,
} from 'lodash';

// UI
import {
  Collapse, Card as MuiCard,
  CardContent, ButtonGroup as MuiButtonGroup,
  Grid, Button as MuiButton, IconButton as MuiIconButton,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import {
  AddCircleOutline,
  RemoveCircleOutline,
  Search,
} from '@material-ui/icons';

// helpers
import { formName } from './form';

// actions
import usersFiltersActions from '../../../../engine/core/usersFilters/action';
import usersFiltersAsyncAction from '../../../../engine/core/usersFilters/saga/asyncAction';

// config
import selectors from '../../../../engine/config/selectors';
import accessList from '../../../../engine/config/accessList';
import { useAccessList } from '../../../_hooks/useAccessList';

// Parts
import RenderTextField from '../../../Form/RenderTextField';
import Select from '../../../Form/Select';
import ReduxDateRange from '../../../DateRange/ReduxDateRange';
import SaveFiltersModal from './helper/SaveFiltersModal';
import { validators } from '../../../_helpers/validation';
import DatePickers from '../../../Form/DatePickers';
import AutocompleteChips from '../../../Form/AutocompleteChips';

// Styled
const IconButton = styled(MuiIconButton)(spacing);
// const Box = styled(MuiBox)(spacing);
const Card = styled(MuiCard)(spacing);
const Button = styled(MuiButton)(spacing);
const ButtonGroup = styled(MuiButtonGroup)`
  margin-bottom: ${(props) => props.theme.spacing(4)}px;
  margin-top: ${(props) => props.theme.spacing(2)}px;
`;
const ButtonGroupFooter = styled(MuiButtonGroup)`
  margin-top: ${(props) => props.theme.spacing(4)}px;
`;
const Wrapper = styled.div`
  margin-bottom: ${(props) => props.theme.spacing(4)}px;
`;
const RemoveCircleOutlineIcon = styled(RemoveCircleOutline)`
  color: ${(props) => props.theme.palette.error.main};
`;
const AddCircleOutlineIcon = styled(AddCircleOutline)`
  color: ${(props) => props.theme.palette.primary.main};
`;
const SearchIcon = styled(Search)`
  margin-right: ${(props) => props.theme.spacing(2)}px;
`;
// const TypographySub = styled(Typography)`
//   margin-right: ${(props) => props.theme.spacing(3)}px;
// `;

function TableExtraFilter(props) {
  const {
    handleSubmit, filters, usersFiltersData, initialize, destroy,
    resetFilters, isModalOpen, pathname, fieldFilter, fieldConnector,
    // openForm,
    children, hiddenControls,
    filtersAction,
    fieldsEntity, objectStatuses,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [isUserFilters, setIsUserFilters] = useState(false);
  const [lengthFilters, setLengthFilters] = useState(0);
  const fieldFilters = fieldFilter.toJS ? fieldFilter.toJS() : fieldFilter;
  const fieldConnectors = fieldConnector.toJS ? fieldConnector.toJS() : fieldConnector;

  const accessUsersFilters = useAccessList(accessList.usersFilters_list_get);

  useEffect(() => {
    if (isEmpty(usersFiltersData.toJS().items) && accessUsersFilters && !isUserFilters) {
      dispatch(usersFiltersAsyncAction.getListAsync());
      setIsUserFilters(true);
    }
  }, [dispatch, usersFiltersData, accessUsersFilters, isUserFilters]);

  const fieldsByFilters = useMemo(() => {
    if (!isEmpty(fieldsEntity.toJS())) {
      return reduce(fieldsEntity.toJS(), (result, value, key) => {
        const fields = split(key, '.');
        if (fields.length === 2) result[fields[1]] = value; // eslint-disable-line
        if (fields.length === 3) {
          result[fields[1]] = { ...result[fields[1]], [fields[2]]: value }; // eslint-disable-line
        }
        return result;
      }, {});
    }
    return {};
  }, [fieldsEntity]);

  const getItems = (items) => map(keys(items), (item) => ({
    value: item,
    name: t(upperFirst(item)),
  }));

  const handleChangeField = (index) => {
    dispatch(change(formName, `filters[${index}].value`, ''));
    dispatch(change(formName, `filters[${index}].secondField`, ''));
    dispatch(change(formName, `filters[${index}].type`, ''));
  };

  const handleChangeSecondField = (index) => {
    dispatch(change(formName, `filters[${index}].value`, ''));
    dispatch(change(formName, `filters[${index}].type`, ''));
  };

  const handleSubmits = (formData) => {
    const jsFormData = formData.toJS();
    const list = map(jsFormData.filters, (item) => ({
      value: item.value,
      type: item.type,
      columnName: `${item.field}${item.secondField ? `.${item.secondField}` : ''}`,
      advanced: true,
    }));
    filtersAction([
      ...list,
      ...!isEmpty(jsFormData.connector) ? [{
        value: jsFormData.connector,
        columnName: 'connector',
        advanced: true,
      }] : [],
    ]);
  };

  const handleDeleteFilters = (item) => {
    const listFilters = [...isArray(fieldFilters) ? fieldFilters : []];
    const connectors = isArray(fieldConnectors) ? fieldConnectors : [];
    const listConnectors = [...connectors];
    pullAt(listFilters, item.id);
    pullAt(listConnectors, item.id);
    setLengthFilters(lengthFilters - 1);
    initialize({
      filters: listFilters,
      connector: listConnectors,
    });
  };
  const handleDeleteSavedFilter = (userFilter) => {
    dispatch(usersFiltersActions.setDeleteUsersFilter({ id: userFilter.id }));
    dispatch(usersFiltersAsyncAction.deleteUsersFilterAsync());
  };

  const handleSaveFilters = () => {
    dispatch(usersFiltersActions.setIsModalOpen(true));
  };

  const handleResetFilters = () => {
    filtersAction([]);
    resetFilters();
    setChecked(false);
    setLengthFilters(0);
    destroy();
  };

  const handleAddFilter = () => {
    setLengthFilters(lengthFilters + 1);
    if (lengthFilters) dispatch(change(formName, `connector[${lengthFilters}]`, 'and'));
  };

  const handleChangeType = (index) => {
    dispatch(change(formName, `filters[${index}].value`, ''));
  };

  const advancedFilters = useMemo(() => (
    filter(filters, (currentFilter) => !currentFilter.always && currentFilter.advanced)
  ), [filters]);

  const savedFilters = useMemo(() => (
    filter(usersFiltersData.toJS().items, (item) => item.location === pathname)
  ), [pathname, usersFiltersData]);

  const getSecondItems = useCallback((index) => {
    if (!isEmpty(fieldFilters) && !isEmpty(fieldFilters[index])
    && fieldFilters[index].field && !isEmpty(fieldsByFilters)) {
      const value = fieldsByFilters[fieldFilters[index].field];
      if (!isEmpty(value) && isString(value.type)) return [];
      return value;
    }
    return [];
  }, [fieldFilters, fieldsByFilters]);

  const getSelectedField = useCallback((index) => {
    if (!isEmpty(fieldFilters) && !isEmpty(fieldFilters[index])
    && fieldFilters[index].field && !isEmpty(fieldsByFilters)) {
      const value = fieldsByFilters[fieldFilters[index]?.field];
      if (!isEmpty(value) && isString(value.type)) return value;
      const valueSecond = value[fieldFilters[index]?.secondField];
      if (!isEmpty(valueSecond) && isString(valueSecond.type)) return valueSecond;
      return {};
    }
    return {};
  }, [fieldFilters, fieldsByFilters]);

  const handleChangeSavedFilters = (event, newInputValue) => {
    if (!isEmpty(newInputValue)) {
      const element = find(savedFilters, { id: newInputValue?.id });
      const list = filter(element?.filters || [], (item) => item.columnName !== 'connector');
      const filtersList = map(list, (item) => ({
        value: item.value,
        type: item.type,
        field: split(item.columnName, '.')[0],
        ...split(item.columnName, '.')[1] ? {
          secondField: split(item.columnName, '.')[1],
        } : {},
      }));
      const findConnector = find(element?.filters || [], { columnName: 'connector' });
      initialize({
        filters: filtersList,
        ...!isEmpty(findConnector) ? {
          connector: findConnector.value,
        } : {},
      });
      setLengthFilters(filtersList.length);
      setChecked(true);
    }
  };

  useEffect(() => {
    if (!isEmpty(advancedFilters) && isEmpty(fieldFilters) && !checked) {
      const list = filter(advancedFilters, (item) => item.columnName !== 'connector');
      const filtersList = map(list, (item) => ({
        value: item.value,
        type: item.type,
        field: split(item.columnName, '.')[0],
        ...split(item.columnName, '.')[1] ? {
          secondField: split(item.columnName, '.')[1],
        } : {},
      }));
      const findConnector = find(advancedFilters, { columnName: 'connector' });
      initialize({
        filters: filtersList,
        ...!isEmpty(findConnector) ? {
          connector: findConnector.value,
        } : {},
      });
      setLengthFilters(filtersList.length);
      setChecked(true);
    }
  }, [advancedFilters, checked, fieldFilters, initialize]);

  return (
    <Wrapper>
      {!hiddenControls && (
        <ButtonGroup variant="contained" color="primary" aria-label="extra filter buttons group">
          {children}

          <Button variant="contained" color="primary" onClick={() => setChecked((prev) => !prev)}>
            {t('ADVANCED SEARCH')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={isEmpty(filters)}
            onClick={handleResetFilters}
          >
            {t('RESET FILTERS')}
          </Button>
        </ButtonGroup>
      )}
      {isModalOpen && (
        <SaveFiltersModal filters={advancedFilters} />
      )}
      <Collapse in={checked}>
        <Card>
          <CardContent>
            {!isEmpty(savedFilters) && (
              <Grid item xs={12} md={3}>
                <AutocompleteChips
                  label={t('Saved filters')}
                  margin="normal"
                  input={{
                    name: 'savedFilters',
                    onChange: () => {},
                  }}
                  disableLabel
                  fullWidth
                  options={savedFilters}
                  handleChange={handleChangeSavedFilters}
                  handleDeleteTag={handleDeleteSavedFilter}
                  disableEditIcon
                  disableEditing
                />
              </Grid>
            )}
            <Form onSubmit={handleSubmit(handleSubmits)}>
              {lengthFilters > 0 && (
                map(new Array(lengthFilters), (item, index) => (
                  <React.Fragment key={index}>
                    <Grid container spacing={4} alignItems="center">
                      {index > 0 && (
                        <Grid item xs={12} md={1}>
                          <Field
                            name={`connector[${index}]`}
                            labelId={`connector[${index}]`}
                            component={Select}
                            type="text"
                            items={[
                              { name: 'AND', value: 'and' },
                              { name: 'OR', value: 'or' },
                            ]}
                            label={t('Connection')}
                            margin="normal"
                            fullWidth
                            required
                          />
                        </Grid>
                      )}
                      {index === 0 && lengthFilters > 1 && (
                        <Grid item xs={12} md={1} />
                      )}
                      <Grid item xs={12} md={3}>
                        <Field
                          name={`filters[${index}].field`}
                          labelId={`filters[${index}].field`}
                          component={Select}
                          label={t('Field')}
                          items={getItems(fieldsByFilters)}
                          margin="normal"
                          fullWidth
                          onChange={() => handleChangeField(index)}
                          required
                        />
                      </Grid>

                      {!isEmpty(getSecondItems(index)) && (
                        <Grid item xs={12} md={2}>
                          <Field
                            name={`filters[${index}].secondField`}
                            labelId={`filters[${index}].secondField`}
                            component={Select}
                            label={t('Field')}
                            items={getItems(getSecondItems(index))}
                            margin="normal"
                            fullWidth
                            onChange={() => handleChangeSecondField(index)}
                            required
                          />
                        </Grid>
                      )}

                      {!isEmpty(getSelectedField(index)) && (
                        <>
                          <Grid item xs={12} md={2}>
                            <Field
                              name={`filters[${index}].type`}
                              labelId={`filters[${index}].type`}
                              component={Select}
                              type="text"
                              items={[
                                ...getSelectedField(index).type !== 'datetime' ? [
                                  { name: t('Equals'), value: '=' },
                                  { name: t('Not equal'), value: '!=' },
                                  { name: t('Contains'), value: 'like' },
                                  { name: t('Not contains'), value: 'not like' },
                                ] : [
                                  { name: t('Between'), value: 'between' },
                                  { name: t('More'), value: '>' },
                                  { name: t('Less'), value: '<' },
                                ],
                              ]}
                              label={t('Operator')}
                              margin="normal"
                              {...getSelectedField(index).type === 'datetime' ? {
                                onChange: () => handleChangeType(index),
                              } : {}}
                              fullWidth
                              required
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            {(() => {
                              switch (getSelectedField(index).type) {
                                case 'datetime': {
                                  if (fieldFilters[index]?.type === 'between') {
                                    return (
                                      <Field
                                        name={`filters[${index}].value`}
                                        component={ReduxDateRange}
                                        type="text"
                                        label={t('Value')}
                                        margin="normal"
                                        fullWidth
                                        validate={validators.required}
                                        required
                                        defaultFormat="YYYY-MM-DD"
                                      />
                                    );
                                  }
                                  return (
                                    <Field
                                      id={`filters[${index}].value`}
                                      labelId={`filters[${index}].value`}
                                      name={`filters[${index}].value`}
                                      fullWidth
                                      label={t('Value')}
                                      component={DatePickers}
                                      validate={validators.required}
                                      required
                                      formatDate="yyyy-MM-dd"
                                      extra
                                    />
                                  );
                                }
                                case 'boolean': {
                                  return (
                                    <Field
                                      name={`filters[${index}].value`}
                                      labelId={`filters[${index}].value`}
                                      component={Select}
                                      type="text"
                                      items={[
                                        { name: t('Yes'), value: 'true' },
                                        { name: t('No'), value: 'false' },
                                      ]}
                                      label={t('Value')}
                                      margin="normal"
                                      fullWidth
                                      required
                                    />
                                  );
                                }
                                default: {
                                  if (getSelectedField(index).name === 'status') {
                                    return (
                                      <Field
                                        name={`filters[${index}].value`}
                                        labelId={`filters[${index}].value`}
                                        component={Select}
                                        type="text"
                                        items={objectStatuses.toJS()}
                                        label={t('Value')}
                                        margin="normal"
                                        fullWidth
                                        required
                                      />
                                    );
                                  }
                                  return (
                                    <Field
                                      name={`filters[${index}].value`}
                                      component={RenderTextField}
                                      type="text"
                                      label={t('Value')}
                                      margin="normal"
                                      fullWidth
                                      required
                                    />
                                  );
                                }
                              }
                            })()}
                          </Grid>
                        </>
                      )}
                      <IconButton
                        mt={8}
                        aria-label="Delete"
                        size="small"
                        onClick={() => handleDeleteFilters(index)}
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                    </Grid>
                  </React.Fragment>
                ))
              )}
              <Button
                mt={2}
                variant="text"
                color="primary"
                onClick={() => handleAddFilter()}
              >
                <AddCircleOutlineIcon />
                {t('Add filter')}
              </Button>
              <Grid item xs={12}>
                <ButtonGroupFooter variant="contained" color="primary" aria-label="extra filter buttons group">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    <SearchIcon fontSize="small" />
                    {t('APPLY')}
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleSaveFilters}
                  >
                    {t('SAVE')}
                  </Button>
                </ButtonGroupFooter>
              </Grid>
            </Form>
          </CardContent>
        </Card>
      </Collapse>
    </Wrapper>
  );
}

TableExtraFilter.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  destroy: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  // openForm: PropTypes.bool,
  hiddenControls: PropTypes.bool,
  isModalOpen: PropTypes.bool.isRequired,
  filtersAction: PropTypes.func,
  resetFilters: PropTypes.func,
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  children: PropTypes.node, // eslint-disable-line
  fieldsEntity: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  objectStatuses: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  usersFiltersData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pathname: PropTypes.string.isRequired,
  fieldFilter: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  fieldConnector: PropTypes.oneOfType([
    PropTypes.object,
  ]),
};

TableExtraFilter.defaultProps = {
  // openForm: false,
  hiddenControls: false,
  filtersAction: () => {},
  resetFilters: () => {},
  fieldFilter: {},
  fieldConnector: {},
};

function mapStateToProps(state) {
  return {
    fieldsEntity: selectors.fieldsSetting.fieldsEntity(state),
    objectStatuses: selectors.helpers.getObjectStatuses(state),
    isModalOpen: selectors.usersFilters.isModalOpen(state),
    usersFiltersData: selectors.usersFilters.usersFiltersData(state),
    pathname: selectors.router.pathname(state),
    fieldFilter: selectors.form.getFormValues(state, formName).get('filters'),
    fieldConnector: selectors.form.getFormValues(state, formName).get('connector'),
  };
}

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.fields, nextProps.fields)
    && isEqual(prevProps.filters, nextProps.filters)
    && isEqual(prevProps.objectStatuses, nextProps.objectStatuses)
    && isEqual(prevProps.isModalOpen, nextProps.isModalOpen)
    && isEqual(prevProps.submitFieldChange, nextProps.submitFieldChange)
    && isEqual(prevProps.fieldsEntity, nextProps.fieldsEntity)
    && isEqual(prevProps.usersFiltersData, nextProps.usersFiltersData)
    && isEqual(prevProps.pathname, nextProps.pathname)
    && isEqual(prevProps.fieldFilter, nextProps.fieldFilter)
    && isEqual(prevProps.fieldConnector, nextProps.fieldConnector)
    && isEqual(prevProps.hiddenControls, nextProps.hiddenControls);
}

export default compose(
  connect(mapStateToProps, null),
  reduxForm({
    form: formName,
  }),
)(memo(TableExtraFilter, areEqual));
