// core
import React, {
  memo, useCallback,
} from 'react';
import { spacing } from '@material-ui/system';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';

// lodash
import { isEmpty, isEqual } from 'lodash';

// UI
import {
  CardContent, Grid as MuiGrid,
  Typography as MuiTypography, Card as MuiCard,
  ButtonGroup, Button as MuiButton,
} from '@material-ui/core';

// actions
import helpersActions from '../../../../../engine/core/helpers/action';
import applicantsActions from '../../../../../engine/core/applicants/action';

// config
import selectors from '../../../../../engine/config/selectors';

// parts
import FieldClient from '../../../../../ui/Form/FieldsAutocomplete/FieldClient';
import AddClientModal from '../../../../../components/AddClientModal/AddClientModal';
import ButtonSubmit from '../../../../../ui/Button/ButtonSubmit';

// hooks
import { useEventsAutocompleteAsync } from '../../../../../ui/_hooks/useEventsAutocompleteAsync';

// styles
const Card = styled(MuiCard)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Button = styled(MuiButton)(spacing);

function CardClient(props) {
  const {
    formName, pending, name, isModalOpenAddClient, disabled,
  } = props;
  const {
    getAutocompleteLists,
    handleGetAsyncData,
  } = useEventsAutocompleteAsync();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClientsGetSelectOption = useCallback((elem) => {
    if (!isEmpty(elem)) {
      dispatch(applicantsActions.setAutocompleteOptions({ name: null }));
      dispatch(applicantsActions.setApplicantsInitialList({ items: [] }));
    }
  }, [dispatch]);

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h2" gutterBottom>
          {t('Client')}
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <FieldClient
              name={name}
              label="Customer Search / Selection"
              getAutocompleteLists={getAutocompleteLists}
              getAsyncData={handleGetAsyncData}
              formName={formName}
              propsField={{
                getSelectOption: handleClientsGetSelectOption,
                disabled,
              }}
            />
          </Grid>
          {!disabled && (
            <Grid item xs={12}>
              <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                <ButtonSubmit pending={pending} />
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => dispatch(helpersActions.setModal({
                    isModalOpenAddClient: true,
                  }))}
                >
                  {t('NEW CLIENT')}
                </Button>
              </ButtonGroup>
            </Grid>
          )}

          {isModalOpenAddClient && (
            <AddClientModal />
          )}

        </Grid>
      </CardContent>
    </Card>
  );
}
CardClient.propTypes = {
  formName: PropTypes.string.isRequired,
  pending: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  isModalOpenAddClient: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

CardClient.defaultProps = {
  disabled: false,
};

function mapStateToProps(state) {
  return {
    isModalOpenAddClient: selectors.helpers.isModalOpenAddClient(state),
  };
}

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.formName, nextProps.formName)
  && isEqual(prevProps.name, nextProps.name)
  && isEqual(prevProps.isModalOpenAddClient, nextProps.isModalOpenAddClient)
  && isEqual(prevProps.disabled, nextProps.disabled)
  && isEqual(prevProps.pending, nextProps.pending);
}

export default compose(
  connect(mapStateToProps, null),
)(memo(CardClient, areEqual));
