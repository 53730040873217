export default Object.freeze({
  SET_PENDING_ACTION_BY_ID: 'SET_PENDING_ACTION_BY_ID',
  SET_PENDING_ACTIONS: 'SET_PENDING_ACTIONS',
  SET_DELETE_PENDING_ACTION: 'SET_DELETE_PENDING_ACTION',
  SET_PENDING_ACTION_CALENDAR_RELOAD: 'SET_PENDING_ACTION_CALENDAR_RELOAD',
  SET_PENDING_ACTION_CALENDAR_FILTERS: 'SET_PENDING_ACTION_CALENDAR_FILTERS',
  SET_PENDING_ACTION_CALENDAR_INITIAL_STATE: 'SET_PENDING_ACTION_CALENDAR_INITIAL_STATE',
  SET_PENDING_ACTIONS_ALL_LIST: 'SET_PENDING_ACTIONS_ALL_LIST',
  SET_PENDING_ACTIONS_DISABLE_RELOAD: 'SET_PENDING_ACTIONS_DISABLE_RELOAD',
  MERGE_PENDING_ACTION_ENTITY_LIST: 'MERGE_PENDING_ACTION_ENTITY_LIST',
  SET_PENDING_ACTION_ENTITY_LIST: 'SET_PENDING_ACTION_ENTITY_LIST',
  SET_PENDING_ACTION_MODAL_OPEN: 'SET_PENDING_ACTION_MODAL_OPEN',
  SET_PENDING_ACTIONS_DISABLE_NOTIFICATIONS: 'SET_PENDING_ACTIONS_DISABLE_NOTIFICATIONS',
});
