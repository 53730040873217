// Core
import { apply, put, select } from 'redux-saga/effects';
import { initialize } from 'redux-form/immutable';

// lodash
import {
  pickBy, find, has, isEmpty,
  values, isArray, map, isObject,
} from 'lodash';

// config
import { api } from '../../../../../config/api';
import selectors from '../../../../../config/selectors';
import { formFields } from '../../../../../../pages/TradeMarks/Proposal/helper/form';

// actions
import actionsApplicants from '../../../../applicants/action';
import actions from '../../action';
import reboundsAsyncAction from '../../../rebounds/saga/asyncAction';
import uploadFileActions from '../../../../uploadFile/action';

// helpers
import { countryOptions } from '../../../../../../ui/_helpers/country';
import { setErrorMessage } from '../../../../../../ui/_helpers/setNotificationMessage';
import { getObjectReverseFormatDate } from '../../../../../_helpers/formatterDate';

export function* callGetRegistryByNumberWorker({ payload: params }) {
  yield put(actions.setPendingRegistryByNumber(true));

  const mktuClasses = yield select(selectors.tradeMarksProposal.classes);

  const filterParams = pickBy(params, (value, key) => key !== 'disableInitialize' && key !== 'saveFields');
  const response = yield apply(api, api.trademarks.getFromRegistry, [filterParams]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data: responseData } = response;
    if (has(responseData, 'error')) {
      yield put(setErrorMessage(responseData.error, response.status.toString()));
    } else {
      const {
        data, title, status, holders, documents, publication,
      } = responseData;
      const {
        postalAddress, additionalApplicationData, logo,
        applicants, attorney, standardCharacters,
      } = data;
      const formData = {
        ...responseData,
        data: {
          ...data,
          standardCharacters: `${standardCharacters}`,
          postalAddress: {
            ...postalAddress,
            country: find(countryOptions, { abbreviation: postalAddress?.country }),
          },
          additionalApplicationData,
          logo,
          holders,
          ...!isEmpty(attorney) && isArray(attorney) ? {
            attorney: attorney[0],
          } : {},
        },
        publication: {
          ...publication,
          ...getObjectReverseFormatDate(responseData.publication?.date, 'date'),
        },
        [formFields.title]: title,
        [formFields.status]: status,
        ...getObjectReverseFormatDate(responseData.applicationDate, formFields.applicationDate),
        ...getObjectReverseFormatDate(responseData.expiryDate, formFields.expiryDate),
        ...getObjectReverseFormatDate(responseData.registrationDate, formFields.registrationDate),
        ...getObjectReverseFormatDate(responseData.initialPublication,
          formFields.initialPublication),
        ...!isEmpty(params.saveFields) ? {
          ...params.saveFields,
        } : {},
      };
      delete formData.documents;
      delete formData.holders;

      if (!isEmpty(formData.mktu) && !isEmpty(mktuClasses.toJS())) {
        const classNumArray = Object.keys(formData.mktu.selection);
        formData.mktu = map(classNumArray, (item) => {
          const selectClassNum = find(mktuClasses.toJS(), { classNum: String(Number(item)) });
          selectClassNum.name = map(formData.mktu.selection[item], (term) => (
            isObject(term) ? term.value : term
          )).join('; ');
          return selectClassNum;
        });
      }

      if (!params.disableInitialize) {
        if (!isEmpty(formData.mktu)) {
          yield put(actions.setSelectMktuClasses(formData.mktu));
          const selection = {};
          map(formData.mktu, (item) => {
            selection[item.id] = item?.name;
          });
          yield put(reboundsAsyncAction.postReboundAsync({
            title: formData.title,
            selection,
            reload: true,
          }));
        }
        delete formData.mktu;
        yield put(actions.setProposalDataById(formData));
        yield put(initialize('ProposalAdd', formData, formFields));
        yield put(uploadFileActions.setAllUploadFiles(values(documents)));
        if (isArray(holders)) {
          yield put(actionsApplicants.setNewOwnersListData(holders));
        }
        yield put(actionsApplicants.setNewApplicantsListData(applicants));
      } else {
        yield put(actions.setRegistryByNumber(formData));
      }
    }

    yield put(actions.setPendingRegistryByNumber(false));
  }
}
