// Core
import { apply, put } from 'redux-saga/effects';

// config
import { api } from '../../../../config/api';

// actions
import actions from '../../action';

export function* callGetNextNumberWorker({ payload: params }) {
  const response = yield apply(api, api.departmentDocuments.getNextNumber, [params]);

  if (response && response.status >= 200 && response.status < 400) {
    yield put(actions.setDepartmentDocumentNextNumber(response.data.nextNumber || ''));
  }
}
