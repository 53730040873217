// Core
import { call, put } from 'redux-saga/effects';

// config
import { api } from '../../../../../config/api';

// actions
import actions from '../../action';

export function* callGetCompanyByIdWorker({ payload: id }) {
  const {
    setCompanyById, setOriginalLogo, setOriginalDocHeading,
  } = actions;
  yield put(setCompanyById({
    pending: true,
  }));

  const response = yield call(api.company.getCompanyById, id);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    yield put(setCompanyById({ data }));
    yield put(setOriginalLogo(data?.logo?.link || ''));
    yield put(setOriginalDocHeading(data?.logo?.docHeading || ''));
  }

  yield put(setCompanyById({
    pending: false,
  }));
}
