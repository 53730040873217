export default Object.freeze({
  SET_EMAIL_TEMPLATE_DATA: 'SET_EMAIL_TEMPLATE_DATA',
  SET_EMAIL_TEMPLATE_CURRENT_PAGE: 'SET_EMAIL_TEMPLATE_CURRENT_PAGE',
  SET_EMAIL_TEMPLATE_PAGE_SIZE: 'SET_EMAIL_TEMPLATE_PAGE_SIZE',
  SET_EMAIL_TEMPLATE_FILTERS: 'SET_EMAIL_TEMPLATE_FILTERS',
  SET_EMAIL_TEMPLATE_SORTING: 'SET_EMAIL_TEMPLATE_SORTING',
  SET_EMAIL_TEMPLATE_INITIAL_STATE: 'SET_EMAIL_TEMPLATE_INITIAL_STATE',
  SET_EMAIL_TEMPLATE_RELOAD: 'SET_EMAIL_TEMPLATE_RELOAD',
  SET_DELETE_EMAIL_TEMPLATE: 'SET_DELETE_EMAIL_TEMPLATE',
  SET_EMAIL_TEMPLATE_BY_ID: 'SET_EMAIL_TEMPLATE_BY_ID',
  SET_EMAIL_TEMPLATE_DATA_BY_ID: 'SET_EMAIL_TEMPLATE_DATA_BY_ID',
  SET_EMAIL_TEMPLATE_AUTOCOMPLETE_OPTIONS: 'SET_EMAIL_TEMPLATE_AUTOCOMPLETE_OPTIONS',
  SET_EMAIL_TEMPLATE_COLUMN_WIDTHS: 'SET_EMAIL_TEMPLATE_COLUMN_WIDTHS',
  SET_EMAIL_TEMPLATE_COLUMN_ORDER: 'SET_EMAIL_TEMPLATE_COLUMN_ORDER',
  SET_EMAIL_TEMPLATE_HIDDEN_COLUMN_NAMES: 'SET_EMAIL_TEMPLATE_HIDDEN_COLUMN_NAMES',
});
