// Core
import { all, call, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetOrdersWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callDeleteOrderWorker,
  callGetAutocompleteOptionsWorker,
  callGetOrderByIdWorker,
  callPutOrderByIdWorker,
  callPostOrderWorker,
  callPostInvoiceWorker,
  callGetNextNumberWorker,
} from './workers';
import types from '../types';

function* watchGetOrders() {
  yield takeEvery(asyncTypes.GET_ORDERS_ASYNC, callGetOrdersWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_ORDERS_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchGetOrderById() {
  yield takeEvery(asyncTypes.GET_ORDER_BY_ID_ASYNC, callGetOrderByIdWorker);
}
function* watchPostOrder() {
  yield takeEvery(asyncTypes.POST_ORDER_ASYNC, callPostOrderWorker);
}
function* watchPostInvoice() {
  yield takeEvery(asyncTypes.POST_INVOICE_ASYNC, callPostInvoiceWorker);
}
function* watchPutOrderById() {
  yield takeEvery(asyncTypes.PUT_ORDER_BY_ID_ASYNC, callPutOrderByIdWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_ORDERS_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_ORDERS_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_ORDERS_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_ORDERS_SORTING, callSetToLocalDataWorker);
}
function* watchSetColumnWidths() {
  yield takeEvery(types.SET_ORDERS_COLUMN_WIDTHS, callSetToLocalDataWorker);
}
function* watchSetColumnOrder() {
  yield takeEvery(types.SET_ORDERS_COLUMN_ORDER, callSetToLocalDataWorker);
}
function* watchSetHiddenColumnNames() {
  yield takeEvery(types.SET_ORDERS_HIDDEN_COLUMN_NAMES, callSetToLocalDataWorker);
}
function* watchDeleteOrder() {
  yield takeEvery(asyncTypes.DELETE_ORDER_ASYNC, callDeleteOrderWorker);
}

function* watchGetAutocompleteOptions() {
  yield takeEvery(
    asyncTypes.GET_ORDERS_AUTOCOMPLETE_OPTIONS_ASYNC,
    callGetAutocompleteOptionsWorker,
  );
}

function* watchGetNextNumber() {
  yield takeEvery(asyncTypes.GET_ORDER_NEXT_NUMBER_ASYNC, callGetNextNumberWorker);
}

export function* watchersOrdersTable() {
  yield all([
    call(watchGetOrders),
    call(watchGetOrderById),
    call(watchPostOrder),
    call(watchPostInvoice),
    call(watchPutOrderById),
    call(watchSetCurrentPage),
    call(watchSetPageSize),
    call(watchSetFilters),
    call(watchSetSorting),
    call(watchGetInitialState),
    call(watchDeleteOrder),
    call(watchGetAutocompleteOptions),
    call(watchGetNextNumber),
    call(watchSetColumnWidths),
    call(watchSetColumnOrder),
    call(watchSetHiddenColumnNames),
  ]);
}
