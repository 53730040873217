const asyncTypes = Object.freeze({
  GET_JURISDICTIONS_ASYNC: 'GET_JURISDICTIONS_ASYNC',
  GET_JURISDICTIONS_INITIAL_STATE_ASYNC: 'GET_JURISDICTIONS_INITIAL_STATE_ASYNC',
  GET_JURISDICTION_BY_ID_ASYNC: 'GET_JURISDICTION_BY_ID_ASYNC',
  POST_JURISDICTION_ASYNC: 'POST_JURISDICTION_ASYNC',
  PUT_JURISDICTION_BY_ID_ASYNC: 'PUT_JURISDICTION_BY_ID_ASYNC',
  DELETE_JURISDICTION_ASYNC: 'DELETE_JURISDICTION_ASYNC',
  GET_JURISDICTIONS_AUTOCOMPLETE_OPTIONS_ASYNC: 'GET_JURISDICTIONS_AUTOCOMPLETE_OPTIONS_ASYNC',
});

export default asyncTypes;
