// core
import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';

// lodash
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import find from 'lodash/find';

// ui
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';

// parts
import DxTable from '../../../../../ui/Table/DxTable';
import ConfirmModal from '../../../../../components/ConfirmModal';

// actions
import helpersActions from '../../../../../engine/core/helpers/action';
import ordersActions from '../../../../../engine/core/company/orders/action';
import ordersActionAsync from '../../../../../engine/core/company/orders/saga/asyncAction';

// hooks
import { useOrders } from '../../../../Company/Orders/_hooks/useOrders';

// config
import selectors from '../../../../../engine/config/selectors';

import { listTablesName, listEntityName } from '../../../../../engine/config/listTablesName';
import accessList from '../../../../../engine/config/accessList';

const tableName = listTablesName.orders;
const entityName = listEntityName.orders;

function OrdersTable(props) {
  const {
    ordersData, totalCount, pending, currenciesData,
    currentPage, pageSize, checkboxes,
    pendingDeleteOrder, filters, sorting, isModalOpenAddCharges,
  } = props;
  useOrders({
    stopInitialState: true,
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const rows = ordersData.toJS().items;

  const basePriseCode = useMemo(() => {
    const findCurrency = find(currenciesData.toJS().items, 'default');
    return findCurrency?.code || '';
  }, [currenciesData]);

  const columns = [
    { name: 'id', title: t('ID') },
    { name: 'orderNum', title: t('Number') },
    { name: 'createdAt', title: t('Date') },
    { name: 'totalSum', title: basePriseCode ? `${t('Sum')} (${basePriseCode})` : t('Sum') },
    { name: 'billSum', title: t('To pay') },
    { name: 'currency', title: t('Currency') },
    { name: 'payedSum', title: t('Payed') },
  ];

  const onCurrentPage = (page) => dispatch(ordersActions.setCurrentPage(page));
  const onPageSize = (size) => dispatch(ordersActions.setPageSize(size));

  // Filtering
  const setFilters = (getFilters) => dispatch(ordersActions.setFilters(getFilters));

  // Sorting
  const onSortingChange = (getSorting) => dispatch(ordersActions.setSorting(getSorting));

  const DetailComponent = (args) => {
    const { charges } = args.row;

    return !isEmpty(charges) ? (
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t('Title')}</TableCell>
            <TableCell>{basePriseCode ? `${t('Sum')} (${basePriseCode})` : t('Sum')}</TableCell>
            <TableCell>{args?.row?.currency?.code ? `${t('To pay')} (${args?.row?.currency?.code})` : t('To pay')}</TableCell>
            <TableCell>{t('Payed')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {map(charges, (row, index) => (
            <TableRow key={row?.id || index}>
              <TableCell component="th" scope="row">
                {row?.name}
              </TableCell>
              <TableCell>{row?.sum}</TableCell>
              <TableCell>{row?.sumBill}</TableCell>
              <TableCell>{row?.sumPayed}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    ) : <Box p={4}>{t('No orders')}</Box>;
  };

  return (
    <>
      <DxTable
        name="Orders table"
        columns={columns}
        isLoading={pending}
        rows={rows}
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageSize={onPageSize}
        onCurrentPage={onCurrentPage}
        detailComponent={DetailComponent}
        // checkboxes ------------------------
        tableName={tableName}
        reloadTable={ordersActions.setReload}
        selection={checkboxes.toJS()}
        onSelectionChange={(checkboxSelect) => {
          dispatch(helpersActions.setCheckbox({
            tableName,
            checkboxes: checkboxSelect,
          }));
        }}
        // ------------------------
        filtersAction={setFilters}
        filters={filters}
        entityName={entityName}
        sorting={sorting}
        onSortingChange={onSortingChange}
        isModalOpen={isModalOpenAddCharges}
        disableHiddenColumn
        disableColumnOrder
        disableColumnWidth
        disableExport
        accessDelete={accessList.orders_delete}
      />

      <ConfirmModal
        buttonSendText="DELETE"
        pending={pendingDeleteOrder}
        handleSend={() => {
          dispatch(ordersActionAsync.deleteOrderAsync());
        }}
      />
    </>
  );
}

OrdersTable.propTypes = {
  ordersData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  totalCount: PropTypes.number.isRequired,
  pending: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  pendingDeleteOrder: PropTypes.bool.isRequired,
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  sorting: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  checkboxes: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  isModalOpenAddCharges: PropTypes.bool.isRequired,
  currenciesData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

function mapStateToProps(state) {
  return {
    ordersData: selectors.ordersTable.ordersData(state),
    pending: selectors.ordersTable.pending(state),
    pendingDeleteOrder: selectors.ordersTable.pendingDeleteOrder(state),
    // Paging
    totalCount: selectors.ordersTable.totalCount(state),
    currentPage: selectors.ordersTable.currentPage(state),
    pageSize: selectors.ordersTable.pageSize(state),

    // Filtering
    filters: selectors.ordersTable.filters(state),

    sorting: selectors.ordersTable.sorting(state),

    checkboxes: selectors.helpers.getCheckboxes(state, tableName),
    isModalOpenAddCharges: selectors.helpers.isModalOpenAddCharges(state),
    currenciesData: selectors.currencies.currenciesData(state),
  };
}

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.pending, nextProps.pending)
    && isEqual(prevProps.pendingDeleteOrder, nextProps.pendingDeleteOrder)
    && isEqual(prevProps.currentPage, nextProps.currentPage)
    && isEqual(prevProps.pageSize, nextProps.pageSize)
    && isEqual(prevProps.ordersData, nextProps.ordersData)
    && isEqual(prevProps.chargesData, nextProps.chargesData)
    && isEqual(prevProps.match, nextProps.match)
    && isEqual(prevProps.totalCount, nextProps.totalCount)
    && isEqual(prevProps.currenciesData, nextProps.currenciesData)
    && isEqual(prevProps.isModalOpenAddCharges, nextProps.isModalOpenAddCharges);
}

export default compose(
  connect(mapStateToProps, null),
)(OrdersTable, areEqual);
