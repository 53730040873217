// Core
import { all, call, takeEvery } from 'redux-saga/effects';
// Types
import { types } from '../types';
// Workers
import {
  callSetNotificationMessage,
} from './workers';

function* watchSetNotificationMessageWorker() {
  yield takeEvery(types.SET_NOTIFICATION_MESSAGE, callSetNotificationMessage);
}

export function* watchersUI() {
  yield all([
    call(watchSetNotificationMessageWorker),
  ]);
}
