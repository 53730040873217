// Core
import { call, put } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../../config/api';

// init
import i18n from '../../../../../init/i18n';

// actions
import actions from '../../action';
import helpersActions from '../../../../helpers/action';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../../ui/_helpers/setNotificationMessage';

export function* callDeleteDocumentTypeCategoryWorker({ payload: id }) {
  const { setPendingDeleteDocumentTypeCategory, setReload } = actions;
  const { setModal } = helpersActions;

  yield put(setPendingDeleteDocumentTypeCategory(true));

  const response = yield call(api.documentTypesCategory.deleteDocumentsTypeCategory, id);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      yield put(setModal({ isModalOpen: false }));
      yield put(setReload(true));
      yield put(setSuccessMessage(i18n.t('Document deleted!'), response.status.toString()));
    }
  }

  yield put(setPendingDeleteDocumentTypeCategory(false));
}
