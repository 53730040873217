/**
 * @props-required: handleSend
 * @props-not-required: objectType, pending, choose
 * objectType - указать номер типа (1, 2 ...) первого поля "objectType", если нужно
 * pending - процесс отправки
 * choose - поле селект choose
 * @example for JSX:
 <AddChargesModal
    handleSend={(json) => {
      // список id-шников costs и works
    }}
 />
 * */
// core
import React, {
  useEffect, useState, useCallback, useMemo,
} from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import {
  Field, Form, formValueSelector, reduxForm, change,
} from 'redux-form/immutable';
import styled from 'styled-components';

// lodash
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import pickBy from 'lodash/pickBy';
import isEqual from 'lodash/isEqual';

// ui
import {
  Button, Grid, Box,
  CircularProgress,
} from '@material-ui/core';

// config
import selectors from '../../engine/config/selectors';
import globalObjectTypes from '../../engine/config/globalObjectTypes';

// parts
import Modal from '../Modal/Modal';
import DialogActions from '../Modal/components/DialogActions';
import Select from '../../ui/Form/Select';
import RecursiveTreeView from '../RecursiveTreeView/RecursiveTreeView';
import Loader from '../Loader';
import RenderTextField from '../../ui/Form/RenderTextField';
import Checkbox from '../../ui/Form/Checkbox';

// actions
import helpersActions from '../../engine/core/helpers/action';
import tariffsActionsAsync from '../../engine/core/prices/tariffs/saga/asyncAction';
import tariffsActions from '../../engine/core/prices/tariffs/action';
import worksActionsAsync from '../../engine/core/works/saga/asyncAction';

// helpers
import { formName, formFields } from './helpers/form';
import { searchByProperty } from '../RecursiveTreeView/helper/searchByProperty';
import { fieldNameWorks } from '../../engine/_helpers/fieldNameCheckboxes';

// hooks
import { useCleanUpDefaultFilters } from '../../ui/_hooks/useCleanUpDefaultFilters';
import { listEntityName } from '../../engine/config/listTablesName';

const formSelector = formValueSelector(formName);

// styles
const BoxContainerChoose = styled(Box)`
  overflow-y: scroll;
  min-height: 100px;
  max-height: calc(70vh - 200px);
`;

function AddChargesModal(props) {
  const {
    handleSend, handleSubmit, worksData,
    isModalOpenAddCharges, pendingWorks, fieldSearch, choose,
    initialize, objectType, tariffIdTypes, pending, fieldChoose,
    fieldObjectType,
  } = props;
  const { t } = useTranslation();
  const { tariffsResetFilters } = useCleanUpDefaultFilters();
  const [readyChoose, setReadyChoose] = useState(false);
  const [readyInitialize, setReadyInitialize] = useState(false);
  const [expandedServices, setExpandedServices] = useState([]);
  const dispatch = useDispatch();
  const tariffIdTypesData = tariffIdTypes.toJS();
  const searchOptions = {
    query: fieldSearch,
    searchBy: 'name',
  };

  const getWorks = useMemo(() => {
    const works = worksData.toJS().items;
    return !isEmpty(works) ? searchByProperty(works, fieldSearch, 'name') : [];
  }, [
    worksData, fieldSearch,
  ]);

  const setChoose = useCallback((value) => {
    if (value === '1') {
      dispatch(tariffsActionsAsync.setTariffsTypesAsync(fieldObjectType ? {
        [formFields.objectType]: fieldObjectType,
        entity: listEntityName.tariffs,
        limit: 100,
      } : {}));
    }
    if (value === '2') {
      dispatch(worksActionsAsync.getListAsync());
    }
  }, [
    dispatch, fieldObjectType,
  ]);

  const handleChangeChoose = (event) => {
    dispatch(change(formName, formFields.search, ''));
    setChoose(event.target.value);
  };

  const handleCloseModal = () => {
    initialize({});
    dispatch(helpersActions.setModal({
      isModalOpenAddCharges: false,
    }));
    dispatch(tariffsActions.setTariffsTypes({
      services: [],
    }));
    tariffsResetFilters();
  };

  const handleSubmits = (formData) => {
    const json = formData.toJSON();
    const getIds = (reg) => {
      const activeCheckboxes = pickBy(json, (value) => value);
      const keys = map(activeCheckboxes, (value, key) => `${key}`);
      const filterKeys = filter(keys, (key) => (reg).test(key));
      return map(filterKeys, (key) => key.split('-')[1]);
    };
    const costs = getIds(/costs/);
    const works = getIds(/works/);
    handleSend({
      ...!isEmpty(costs) ? {
        costs,
      } : {},
      ...!isEmpty(works) ? {
        works,
      } : {},
    });
  };

  const handleChangeObjectType = (event) => {
    if (fieldChoose === '1') {
      dispatch(tariffsActionsAsync.setTariffsTypesAsync(event.target.value ? {
        [formFields.objectType]: event.target.value,
        entity: listEntityName.tariffs,
        limit: 100,
      } : {}));
    }
  };

  useEffect(() => {
    if (!readyInitialize) {
      setReadyInitialize(true);
      initialize({
        [formFields.objectType]: `${objectType || ''}`,
        [formFields.choose]: choose,
      });
    }
  }, [
    choose, initialize, objectType, readyChoose,
    readyInitialize, setReadyInitialize,
  ]);

  useEffect(() => {
    if (readyInitialize && !readyChoose && choose
      && ((objectType && fieldObjectType) || !objectType)) {
      setReadyChoose(true);
      setChoose(choose);
    }
  }, [
    readyChoose, setReadyChoose, objectType,
    setChoose, choose, readyInitialize, fieldObjectType,
  ]);

  return (
    <Modal
      title={t('Add costs')}
      isModalOpen={isModalOpenAddCharges}
      handleCloseModal={handleCloseModal}
      displayDialogActions={false}
    >
      <Form onSubmit={handleSubmit(handleSubmits)}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Field
              my={2}
              fullWidth
              displayEmpty
              component={Select}
              label={t('Object type')}
              items={globalObjectTypes}
              id={formFields.objectType}
              name={formFields.objectType}
              labelId={formFields.objectType}
              onChange={handleChangeObjectType}
            />
          </Grid>

          <Grid item xs={6}>
            <Field
              name={formFields.choose}
              id={formFields.choose}
              label={t('Choose')}
              labelId={formFields.choose}
              my={2}
              items={[
                { name: t('Tariffs'), value: '1' },
                { name: t('Works'), value: '2' },
              ]}
              fullWidth
              onChange={handleChangeChoose}
              component={Select}
            />
          </Grid>
        </Grid>

        <Box mb={4}>
          <Field
            fullWidth
            name={formFields.search}
            label={t('Search')}
            margin="normal"
            component={RenderTextField}
          />
        </Box>

        {((fieldChoose === '1' && tariffIdTypesData.pending)
          || (fieldChoose === '2' && pendingWorks))
        && <Box mt={6} mb={6}><Loader /></Box>}

        {!tariffIdTypesData.pending && fieldChoose === '1' && (
          <BoxContainerChoose>
            {!isEmpty(tariffIdTypesData.services) ? (
              <RecursiveTreeView
                defaultExpanded={expandedServices}
                searchOptions={searchOptions}
                handleExpanded={(ids) => setExpandedServices(ids)}
                data={{
                  children: tariffIdTypesData.services,
                }}
              />
            ) : t('No data')}
          </BoxContainerChoose>
        )}

        {!pendingWorks && fieldChoose === '2' && (
          <BoxContainerChoose>
            {!isEmpty(getWorks) ? map(getWorks, (item) => (
              <Field
                key={item.id}
                component={Checkbox}
                margin="none"
                type="checkbox"
                name={fieldNameWorks(item.id)}
                label={item.name}
              />
            )) : t('No data')}
          </BoxContainerChoose>
        )}
        <DialogActions
          dialogActionsChildren={(
            <>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={pending}
              >
                {pending ? <CircularProgress size={25} color="inherit" /> : t('CONFIRM')}
              </Button>
              <Button
                variant="outlined"
                onClick={handleCloseModal}
                color="primary"
              >
                {t('CANCEL')}
              </Button>
            </>
          )}
        />
      </Form>
    </Modal>
  );
}

AddChargesModal.propTypes = {
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  tariffIdTypes: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  handleSend: PropTypes.func.isRequired,
  isModalOpenAddCharges: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  pendingWorks: PropTypes.bool.isRequired,
  worksData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pending: PropTypes.bool,
  objectType: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  fieldSearch: PropTypes.string,
  fieldObjectType: PropTypes.string,
  fieldChoose: PropTypes.string,
  choose: PropTypes.string,
};

AddChargesModal.defaultProps = {
  objectType: '',
  fieldSearch: '',
  fieldObjectType: '',
  fieldChoose: '',
  choose: '1',
  pending: false,
};

function mapStateToProps(state) {
  return {
    isModalOpenAddCharges: selectors.helpers.isModalOpenAddCharges(state),
    tariffIdTypes: selectors.pricesTariffs.tariffIdTypes(state),
    worksData: selectors.works.worksData(state),
    pendingWorks: selectors.works.pending(state),
    fieldSearch: formSelector(state, formFields.search),
    fieldObjectType: formSelector(state, formFields.objectType),
    fieldChoose: formSelector(state, formFields.choose),
  };
}
function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.tariffIdTypes, nextProps.tariffIdTypes)
  && isEqual(prevProps.worksData, nextProps.worksData)
  && isEqual(prevProps.pendingWorks, nextProps.pendingWorks)
  && isEqual(prevProps.fieldSearch, nextProps.fieldSearch)
  && isEqual(prevProps.fieldObjectType, nextProps.fieldObjectType)
  && isEqual(prevProps.fieldChoose, nextProps.fieldChoose)
  && isEqual(prevProps.pending, nextProps.pending)
  && isEqual(prevProps.objectType, nextProps.objectType)
  && isEqual(prevProps.choose, nextProps.choose)
  && isEqual(prevProps.isModalOpenAddCharges, nextProps.isModalOpenAddCharges);
}

export default compose(
  reduxForm({
    form: formName,
  }),
  connect(mapStateToProps, null),
)(React.memo(AddChargesModal, areEqual));
