// Helpers
import actionCreator from '../../_helpers/actionCreator';
// Types
import types from './types';

const actions = Object.freeze({
  setSettingsData(data) {
    return actionCreator(types.SET_SETTINGS_DATA, data);
  },
  mergeSettingsResponsibleList(data) {
    return actionCreator(types.MERGE_SETTINGS_RESPONSIBLE_LIST, data);
  },
  setSettingsResponsibleList(data) {
    return actionCreator(types.SET_SETTINGS_RESPONSIBLE_LIST, data);
  },
});

export default actions;
