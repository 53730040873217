// core
import React, {
  memo, useEffect, useState, useCallback, useMemo,
} from 'react';
import {
  reduxForm, Field, Form,
} from 'redux-form/immutable';
import { compose } from 'recompose';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';

import styled from 'styled-components';

// ui
import {
  CircularProgress, Button as MuiButton,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';

// lodash
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';

// actions
import helpersActions from '../../engine/core/helpers/action';
import helpersActionAsync from '../../engine/core/helpers/saga/asyncAction';
import integrationsAsyncActions from '../../engine/core/company/integration/saga/asyncAction';
import sendingAsyncActions from '../../engine/core/sending/saga/asyncAction';

// config
import selectors from '../../engine/config/selectors';

// parts
import Select from '../../ui/Form/Select';
import Modal from '../Modal/Modal';
import DialogActions from '../Modal/components/DialogActions';

// style
const Button = styled(MuiButton)(spacing);

function Send(props) {
  const {
    integrationsData, type, entityIds, isModalOpen, pendingIntegrationsData,
    handleSubmit, destroy, disabledButton, pendingSend, visibleButton, startSend,
  } = props;
  const [getIntegrations, setGetIntegrations] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(helpersActions.setModal({ isModalOpenSend: false }));
    destroy();
  };

  const handleGetIntegrations = () => {
    dispatch(integrationsAsyncActions.getListAsync());
    setGetIntegrations(true);
  };

  useEffect(() => {
    if (startSend && !getIntegrations) {
      dispatch(helpersActions.setStartSend(false));
      dispatch(integrationsAsyncActions.getListAsync());
      setGetIntegrations(true);
    }
  }, [
    dispatch, getIntegrations, setGetIntegrations, startSend,
  ]);

  const handleSendAsync = useCallback((params) => {
    if (type === 'outdocs') {
      dispatch(sendingAsyncActions.postOutDocsSendAsync({
        id: params.outdocs,
        authorityIntegration: params.authorityIntegration,
      }));
    } else {
      dispatch(helpersActionAsync.postSendAsync(params));
    }
  }, [dispatch, type]);

  const filterIntegrations = useMemo(() => (
    filter(integrationsData.toJS().items, 'send')
  ), [integrationsData]);

  useEffect(() => {
    if (!isEmpty(filterIntegrations) && getIntegrations) {
      if (filterIntegrations.length === 1) {
        forEach(entityIds, (id) => {
          handleSendAsync({
            [type]: id,
            authorityIntegration: filterIntegrations?.[0]?.id,
          });
        });
      } else {
        dispatch(helpersActions.setModal({ isModalOpenSend: true }));
      }
      setGetIntegrations(false);
    }
  }, [
    dispatch, getIntegrations, filterIntegrations,
    setGetIntegrations, type, entityIds, handleSendAsync,
  ]);

  const handleSubmits = (formData) => {
    const json = formData.toJS();
    forEach(entityIds, (id) => {
      handleSendAsync({
        [type]: id,
        ...json,
      });
    });
  };

  return (
    <>
      {visibleButton && (
        <Button
          disabled={disabledButton}
          variant="contained"
          color="primary"
          onClick={handleGetIntegrations}
        >
          {(getIntegrations && pendingIntegrationsData) || pendingSend
            ? <CircularProgress size={25} color="inherit" />
            : t('SEND')}
        </Button>
      )}
      <Modal
        title={t('Integration')}
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        displayDialogActions={false}
      >
        <Form onSubmit={handleSubmit(handleSubmits)}>
          <Field
            name="authorityIntegration"
            id="authorityIntegration"
            label={t('Integration')}
            labelId="authorityIntegration"
            my={2}
            items={filterIntegrations}
            fullWidth
            component={Select}
          />
          <DialogActions
            pending={pendingSend}
            handleCloseModal={handleCloseModal}
          />
        </Form>
      </Modal>
    </>
  );
}

Send.propTypes = {
  integrationsData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  type: PropTypes.string.isRequired,
  entityIds: PropTypes.oneOfType([
    PropTypes.array,
  ]),
  disabledButton: PropTypes.bool,
  isModalOpen: PropTypes.bool.isRequired,
  startSend: PropTypes.bool.isRequired,
  pendingSend: PropTypes.bool.isRequired,
  pendingIntegrationsData: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  destroy: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  visibleButton: PropTypes.bool,
};

Send.defaultProps = {
  entityIds: [],
  disabledButton: false,
  visibleButton: true,
};

function mapStateToProps(state) {
  return {
    isModalOpen: selectors.helpers.isModalOpenSend(state),
    pendingSend: selectors.helpers.pendingSend(state),
    startSend: selectors.helpers.startSend(state),
    integrationsData: selectors.integration.integrationsData(state),
    pendingIntegrationsData: selectors.integration.pending(state),
  };
}

function mapDispatchToProps() {
  return {};
}

export default compose(
  reduxForm({
    form: 'selectIntegration',
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(memo(Send));
