// Core
import React, { useCallback, useMemo, memo } from 'react';
import { connect, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

// parts
import {
  Box, Button, Card, CardContent, Typography,
} from '@material-ui/core';
import { filter, isEmpty, isEqual } from 'lodash';
import DxTable from '../../../ui/Table/DxTable';

// actions
import actions from '../../../engine/core/receiving/action';
import asyncActions from '../../../engine/core/receiving/saga/asyncAction';
import helpersActions from '../../../engine/core/helpers/action';

// config
import selectors from '../../../engine/config/selectors';
import ConfirmModal from '../../ConfirmModal';
import { initialState } from '../../../engine/core/sending/reducer';
import globalStateKeys from '../../../engine/config/globalStateKeys';

import { listTablesName } from '../../../engine/config/listTablesName';
import accessList from '../../../engine/config/accessList';

const tableName = listTablesName.indocs;

function TableIndocs(props) {
  const {
    pendingDelete, data, totalCount, pending, currentPage, filters, pageSize, checkboxes,
    sorting,
  } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const columns = [
    { name: 'id', title: t('ID') },
    { name: 'textContent', title: t('Content') },
    { name: 'receivedAt', title: t('Received At') },
    { name: 'outDocs', title: t('OutDocs') },
  ];

  // Paging
  const onCurrentPage = (page) => dispatch(actions.setCurrentPage(page));
  const onPageSize = (size) => dispatch(actions.setPageSize(size));

  // Reset filters
  const resetFilters = useCallback(() => {
    const dataInitialState = initialState.get(globalStateKeys.data);
    dispatch(actions.setFilters(dataInitialState.get(globalStateKeys.filters).toJS()));
    dispatch(actions.setCurrentPage(dataInitialState.get(globalStateKeys.currentPage)));
  }, [dispatch]);

  // Filtering
  const setFilters = (getFilters) => dispatch(actions.setFilters(getFilters));

  // Sorting
  const onSortingChange = (getSorting) => dispatch(actions.setSorting(getSorting));

  const isEmptyFilters = useMemo(() => (
    isEmpty(filter(filters, (currentFilter) => !currentFilter.always))), [filters]);

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" paragraph>
          {t('InDocs')}
        </Typography>
        <Box mb={2}>
          <Button
            mr={2}
            variant="contained"
            color="primary"
            disabled={isEmptyFilters}
            onClick={resetFilters}
          >
            {t('RESET FILTERS')}
          </Button>
        </Box>

        <DxTable
          name="TariffTable"
          isLoading={pending}
          columns={columns}
          rows={data.toJS().items}
          totalCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          // checkboxes ------------------------
          tableName={tableName}
          reloadTable={actions.setReload}
          selection={checkboxes.toJS()}
          onSelectionChange={(checkboxSelect) => {
            dispatch(helpersActions.setCheckbox({
              tableName,
              checkboxes: checkboxSelect,
            }));
          }}
          // ------------------------
          onPageSize={onPageSize}
          onCurrentPage={onCurrentPage}
          filtersAction={setFilters}
          filters={filters}
          sorting={sorting}
          onSortingChange={onSortingChange}
          disableHiddenColumn
          disableColumnOrder
          disableColumnWidth
          disableExport
          accessEdit={accessList.documents_put}
          accessDelete={accessList.documents_delete}
        />

        <ConfirmModal
          buttonSendText="DELETE"
          pending={pendingDelete}
          handleSend={() => dispatch(asyncActions.delete())}
        />
      </CardContent>
    </Card>
  );
}

TableIndocs.displayName = 'TableIndocs';

TableIndocs.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingDelete: PropTypes.bool.isRequired,
  pending: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalCount: PropTypes.number,
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  sorting: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  checkboxes: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

TableIndocs.defaultProps = {
  totalCount: 0,
};

function mapStateToProps(state) {
  return {
    data: selectors.receiving.receivingData(state),
    pending: selectors.receiving.pending(state),
    pendingDelete: selectors.receiving.pendingDelete(state),

    // Paging
    totalCount: selectors.receiving.totalCount(state),
    currentPage: selectors.receiving.currentPage(state),
    pageSize: selectors.receiving.pageSize(state),

    // Filtering
    filters: selectors.receiving.filters(state),

    sorting: selectors.receiving.sorting(state),

    checkboxes: selectors.helpers.getCheckboxes(state, tableName),
  };
}

function mapDispatchToProps() {
  return {};
}

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.checkboxes, nextProps.checkboxes)
  && isEqual(prevProps.currentPage, nextProps.currentPage)
  && isEqual(prevProps.data, nextProps.data)
  && isEqual(prevProps.filters, nextProps.filters)
  && isEqual(prevProps.pageSize, nextProps.pageSize)
  && isEqual(prevProps.pending, nextProps.pending)
  && isEqual(prevProps.pendingDelete, nextProps.pendingDelete)
  && isEqual(prevProps.sorting, nextProps.sorting)
  && isEqual(prevProps.totalCount, nextProps.totalCount);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(memo(TableIndocs, areEqual));
