// Core
import { call, put } from 'redux-saga/effects';
import { Map } from 'immutable';
import { change } from 'redux-form/lib/immutable';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../../config/api';
import { messageRequestSent } from '../../../../../config/globalConfig';

// actions
import employeesActions from '../../action';
import asyncAction from '../asyncAction';
import uploadFileActions from '../../../../uploadFile/action';

// init
import i18n from '../../../../../init/i18n';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../../ui/_helpers/setNotificationMessage';

export function* callPostKeyDataWorker({ payload: key }) {
  const { setKeysData } = employeesActions;
  const { setUploadFile } = uploadFileActions;

  yield put(setKeysData({
    pendingPost: true,
  }));

  const response = yield call(api.company.postKey, key);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      yield put(setKeysData({ openFieldsCreateKey: false }));
      yield put(setUploadFile({
        file: Map(),
      }));
      yield put(asyncAction.getKeysDataAsync());
      yield put(change('Employee', 'csk', ''));
      yield put(change('Employee', 'passwordKey', ''));
      yield put(setSuccessMessage(i18n.t(messageRequestSent), response.status.toString()));
    }
  }

  yield put(setKeysData({
    pendingPost: false,
  }));
}
