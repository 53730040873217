// core
import React, { memo, useMemo, useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import styled from 'styled-components';

// lodash
import {
  isEmpty, isArray, map, difference, find,
  filter,
} from 'lodash';

// ui
import {
  Box, Select, MenuItem, Grid,
} from '@material-ui/core';

// config
import selectors from '../../../engine/config/selectors';
import { listEntityName } from '../../../engine/config/listTablesName';

// parts
import DxTable from '../../../ui/Table/DxTable';
import StaticTableExtraFilter from '../../../ui/Table/components/TableExtraFilter/StaticTableExtraFilter';

// actions
import helpersActions from '../../../engine/core/helpers/action';

import depDocModalActions from '../../../engine/core/departmentDocuments/documentsModal/action';
import documentTemplatesActions from '../../../engine/core/documentTemplates/action';
import tradeMarksActions from '../../../engine/core/tradeMarks/proposal/action';
import industrialActions from '../../../engine/core/industrialDesigns/action';
import inventionsActions from '../../../engine/core/inventions/action';
import utilityActions from '../../../engine/core/utilityModels/action';
import emailActions from '../../../engine/core/email/email/action';

// hooks
import { useTableEntitiesModal } from '../_hooks/useTableEntitiesModal';
import { useCleanUpDefaultFilters } from '../../../ui/_hooks/useCleanUpDefaultFilters';
import { useEventsAutocompleteAsync } from '../../../ui/_hooks/useEventsAutocompleteAsync';

// helpers
import { formName as formNameExtraFilter } from '../../../ui/Table/components/TableExtraFilter/form';
import { convertToFilterDataSearchGroup } from '../../../ui/Table/components/TableExtraFilter/helper/fieldsSearchGroup';

// styles
const BoxContainerTable = styled(Box)`
  overflow-y: scroll;
  height: 40vh;
  min-height: 40vh;
`;
const BoxContainerExtraFilter = styled(Box)`
  .MuiCardContent-root {
    padding-bottom: 16px;
    padding-top: 0;
  }
  .MuiCardContent-root > .MuiPaper-root {
    box-shadow: none;
  }
`;

function TableEntities(props) {
  const {
    entities, columns, isDefaultFilters,
    showTableExtraFilter,
    selectedEntities, filteringColumn, sortingColumn, selectionRow,
    handleSend,

    // documents
    filtersDocuments, pendingDocuments, totalCountDocuments,
    currentPageDocuments, pageSizeDocuments, documentsData,

    // documentsTemplates
    filtersDocumentTemplates, pendingDocumentTemplates, totalCountDocumentTemplates,
    currentPageDocumentTemplates, pageSizeDocumentTemplates, documentTemplatesData,

    // trademarks
    filtersTrademarks, pendingTrademarks, totalCountTrademarks,
    currentPageTrademarks, pageSizeTrademarks, trademarksData,

    // industrial
    industrialData, pendingIndustrial, filtersIndustrial,
    currentPageIndustrial, pageSizeIndustrial, totalCountIndustrial,

    // inventions
    inventionsData, pendingInventions, filtersInventions,
    currentPageInventions, pageSizeInventions, totalCountInventions,

    // utility
    utilityData, pendingUtility, filtersUtility,
    currentPageUtility, pageSizeUtility, totalCountUtility,

    // emailLetters
    listLetters, pendingEmailLetters, filtersEmailLetters, listEmail,
    emailLettersCurrentPage, emailLettersPageSize, emailLettersTotalCount,

    // Advanced Search
    fieldClientsInput, clientsData,
    clientsAutocompleteOptions,
    autocompleteGroup, formValues,
  } = props;
  useTableEntitiesModal({
    entities,
    ...isDefaultFilters ? { stopInitialState: true } : {},
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selections = selectedEntities.toJS();
  // Reset filters
  const {
    departmentDocumentsResetFilters, documentTemplatesResetFilters,
  } = useCleanUpDefaultFilters();
  // Advanced Search
  const {
    handleGetAsyncData, getAutocompleteLists,
  } = useEventsAutocompleteAsync();
  const extraFiltersFields = [
    {
      name: 'clientsId',
      requestName: 'clients',
      type: 'input',
      label: t('Client'),
      changeToStore: true,
      multiple: true,
      autocomplete: true,
      options: isEmpty(fieldClientsInput)
        ? clientsData.toJS().items
        : clientsAutocompleteOptions.get('name'),
    },
    {
      name: 'caseNumberGroup',
      requestName: 'caseNumberGroup',
      type: 'input',
      label: t('Case number'),
      multiple: true,
      autocomplete: true,
      isGroupBy: true,
      options: autocompleteGroup.toJS().items,
    },
  ];

  const defaultColumns = [
    { name: 'title', title: t('Title'), customField: 'titleDepartmentDocuments' },
    { name: 'number', title: t('Number') },
    { name: 'type', title: t('Type') },
    { name: 'documentClassifier', title: t('Document classifier') },
    { name: 'documentDate', title: t('Document date') },
  ];

  const getActions = useMemo(() => {
    switch (entities) {
      case 'documents': return depDocModalActions;
      case 'documentTemplates': return documentTemplatesActions;
      case 'trademarks': return tradeMarksActions;
      case 'industrial': return industrialActions;
      case 'inventions': return inventionsActions;
      case 'utility': return utilityActions;
      default: return depDocModalActions;
    }
  }, [entities]);

  // Paging
  const onCurrentPage = (page) => {
    if (entities === 'emailLetters') {
      dispatch(emailActions.setEmailLettersCurrentPage(page));
      return;
    }
    dispatch(getActions.setCurrentPage(page));
  };
  const onPageSize = (size) => {
    if (entities === 'emailLetters') {
      dispatch(emailActions.setEmailLettersPageSize(size));
      return;
    }
    dispatch(getActions.setPageSize(size));
  };
  // Filtering
  const setFilters = (getFilters) => {
    if (entities === 'emailLetters') {
      dispatch(emailActions.setEmailLettersFilters(getFilters));
      dispatch(emailActions.setEmailLettersCurrentPage(0));
      return;
    }
    dispatch(getActions.setFilters(getFilters));
  };

  const setFiltersAdvanced = (getFilters) => {
    dispatch(getActions.setFilters(
      convertToFilterDataSearchGroup({
        formValues, // поля формы расширенного поиска
        filters: getFilters,
        fieldNameSearchGroup: 'caseNumberGroup', // имя поля глобального поиска
      }),
    ));
  };

  // Reset filters
  const resetFilters = useCallback(() => {
    switch (entities) {
      case 'documents': {
        departmentDocumentsResetFilters();
        break;
      }
      case 'documentTemplates': {
        documentTemplatesResetFilters();
        break;
      }
      default: break;
    }
  }, [
    departmentDocumentsResetFilters, entities,
    documentTemplatesResetFilters,
  ]);

  const getRows = useMemo(() => {
    switch (entities) {
      case 'documents': return documentsData.toJS().items;
      case 'documentTemplates': return documentTemplatesData.toJS().items;
      case 'trademarks': return trademarksData.toJS().items;
      case 'industrial': return industrialData.toJS().items;
      case 'inventions': return inventionsData.toJS().items;
      case 'utility': return utilityData.toJS().items;
      case 'emailLetters': return listLetters.toJS();
      default: return [];
    }
  }, [
    documentsData, entities, documentTemplatesData, trademarksData,
    industrialData, inventionsData, utilityData, listLetters,
  ]);

  const onSelectionChange = (selected) => {
    if (selectionRow && handleSend && isArray(selected) && !isEmpty(selected)) {
      handleSend(selected[0]);
      return;
    }

    const idBySelections = map(selections, (selection) => selection.id);
    const newSelectedEntities = difference(selected, idBySelections);
    if (isEmpty(newSelectedEntities)) {
      const removeDocuments = difference(idBySelections, selected);
      dispatch(helpersActions.setSelectedEntities(
        selections.filter((selection) => selection.id !== removeDocuments[0]),
      ));
      return;
    }
    const listSelectedEntities = map(newSelectedEntities, (select) => (
      find(getRows, { id: select })
    ));
    dispatch(helpersActions.setSelectedEntities(
      [...selections, ...listSelectedEntities],
    ));
  };

  const getPending = useMemo(() => {
    switch (entities) {
      case 'documents': return pendingDocuments;
      case 'documentTemplates': return pendingDocumentTemplates;
      case 'trademarks': return pendingTrademarks;
      case 'industrial': return pendingIndustrial;
      case 'inventions': return pendingInventions;
      case 'utility': return pendingUtility;
      case 'emailLetters': return pendingEmailLetters;
      default: return false;
    }
  }, [
    pendingDocuments, entities, pendingDocumentTemplates, pendingTrademarks,
    pendingIndustrial, pendingInventions, pendingUtility, pendingEmailLetters,
  ]);

  const getTotalCount = useMemo(() => {
    switch (entities) {
      case 'documents': return totalCountDocuments;
      case 'documentTemplates': return totalCountDocumentTemplates;
      case 'trademarks': return totalCountTrademarks;
      case 'industrial': return totalCountIndustrial;
      case 'inventions': return totalCountInventions;
      case 'utility': return totalCountUtility;
      case 'emailLetters': return emailLettersTotalCount;
      default: return 0;
    }
  }, [
    totalCountDocuments, entities, totalCountDocumentTemplates, totalCountTrademarks,
    totalCountIndustrial, totalCountInventions, totalCountUtility, emailLettersTotalCount,
  ]);

  const getPageSize = useMemo(() => {
    switch (entities) {
      case 'documents': return pageSizeDocuments;
      case 'documentTemplates': return pageSizeDocumentTemplates;
      case 'trademarks': return pageSizeTrademarks;
      case 'industrial': return pageSizeIndustrial;
      case 'inventions': return pageSizeInventions;
      case 'utility': return pageSizeUtility;
      case 'emailLetters': return emailLettersPageSize;
      default: return 20;
    }
  }, [
    pageSizeDocuments, entities, pageSizeDocumentTemplates, pageSizeTrademarks,
    pageSizeIndustrial, pageSizeInventions, pageSizeUtility, emailLettersPageSize,
  ]);

  const getCurrentPage = useMemo(() => {
    switch (entities) {
      case 'documents': return currentPageDocuments;
      case 'documentTemplates': return currentPageDocumentTemplates;
      case 'trademarks': return currentPageTrademarks;
      case 'industrial': return currentPageIndustrial;
      case 'inventions': return currentPageInventions;
      case 'utility': return currentPageUtility;
      case 'emailLetters': return emailLettersCurrentPage;
      default: return 0;
    }
  }, [
    currentPageDocuments, entities, currentPageDocumentTemplates, currentPageTrademarks,
    currentPageIndustrial, currentPageInventions, currentPageUtility, emailLettersCurrentPage,
  ]);

  const getFilters = useMemo(() => {
    switch (entities) {
      case 'documents': return filtersDocuments;
      case 'documentTemplates': return filtersDocumentTemplates;
      case 'trademarks': return filtersTrademarks;
      case 'industrial': return filtersIndustrial;
      case 'inventions': return filtersInventions;
      case 'utility': return filtersUtility;
      case 'emailLetters': return filtersEmailLetters;
      default: return false;
    }
  }, [
    filtersDocuments, entities, filtersDocumentTemplates, filtersTrademarks,
    filtersIndustrial, filtersInventions, filtersUtility, filtersEmailLetters,
  ]);

  const handleFolder = (e) => {
    dispatch(emailActions.setEmailLettersFilters([
      ...filter(getFilters, (item) => item.columnName !== 'folder' && item.columnName !== 'senderEmail'),
      { columnName: 'folder', value: e.target.value },
    ]));
    dispatch(emailActions.setEmailLettersCurrentPage(0));
  };

  const handleEmail = (e) => {
    dispatch(emailActions.setEmailLettersFilters([
      ...filter(getFilters, (item) => item.columnName !== 'email.id' && item.columnName !== 'senderEmail'),
      { columnName: 'email.id', value: e.target.value },
    ]));
    dispatch(emailActions.setEmailLettersCurrentPage(0));
  };

  const valueFolder = useMemo(() => {
    const findItem = find(getFilters, (item) => item.columnName !== 'folder');
    return findItem?.value || 'INBOX';
  }, [getFilters]);

  const columnsEmailLetters = [
    { name: 'id', title: t('ID') },
    {
      name: 'senderEmail',
      title: valueFolder === 'SENT' ? t('Whom') : t('From whom'),
      folder: valueFolder,
    },
    { name: 'entity', title: t('Case') },
    { name: 'subject', title: t('Subject') },
    { name: 'snippet', title: t('Message') },
    { name: 'date', title: t('Date') },
  ];

  const getColumns = useMemo(() => {
    const tabColumns = entities === 'emailLetters' ? columnsEmailLetters : columns;
    return !isEmpty(tabColumns) ? tabColumns : defaultColumns;
  }, [columns, entities]);// eslint-disable-line

  return (
    <>
      {showTableExtraFilter && (
        <BoxContainerExtraFilter>
          <StaticTableExtraFilter
            openForm
            hiddenControls
            submitFieldChange
            fields={extraFiltersFields}
            filters={getFilters}
            filtersAction={setFiltersAdvanced}
            resetFilters={resetFilters}
            // Autocomplete
            handleGetAsyncData={handleGetAsyncData}
            handleOnFocus={getAutocompleteLists}
          />
        </BoxContainerExtraFilter>
      )}
      {entities === 'emailLetters' && (
        <Grid container spacing={4} mb={3}>
          {!isEmpty(listEmail.toJS()) && (
            <Grid item xs={12} md={3}>
              <Select
                labelId="filterEmail"
                fullWidth
                displayEmpty
                defaultValue=""
                onChange={handleEmail}
                disabled={getPending}
              >
                {map(listEmail.toJS(), (item) => (
                  <MenuItem key={item.id} value={item.id}>{item.email}</MenuItem>
                ))}
              </Select>
            </Grid>
          )}
          <Grid item xs={12} md={3}>
            <Select
              labelId="filterFolder"
              fullWidth
              defaultValue="INBOX"
              onChange={handleFolder}
              disabled={getPending}
            >
              <MenuItem value="INBOX">{t('Inbox')}</MenuItem>
              <MenuItem value="SENT">{t('The Sent')}</MenuItem>
              <MenuItem value="TRASH">{t('Deleted')}</MenuItem>
            </Select>
          </Grid>
        </Grid>
      )}
      <BoxContainerTable>
        <DxTable
          name={entities}
          columns={getColumns}
          rows={getRows}
          isLoading={getPending}
          // Paging
          totalCount={getTotalCount}
          pageSize={getPageSize}
          currentPage={getCurrentPage}
          onPageSize={onPageSize}
          onCurrentPage={onCurrentPage}
          // filters
          filtersAction={setFilters}
          filters={getFilters}
          filteringExtensions={filteringColumn}
          sortingStateColumnExtensions={sortingColumn}
          selectionRow
          {...!selectionRow ? {
            selection: map(selections, (selection) => selection.id),
          } : {
            selectionRow: true,
          }}
          onSelectionChange={onSelectionChange}
          {...entities === 'documents' ? {
            tableName: 'documentsModal',
            entityName: listEntityName.documents,
          } : {}}
          {...entities === 'documentTemplates' ? {
            tableName: 'DocumentsOrder',
            entityName: listEntityName.documentsTemplate,
          } : {}}
          {...entities === 'trademarks' ? {
            tableName: 'trademarksModal',
            entityName: listEntityName.trademarks,
          } : {}}
          {...entities === 'industrial' ? {
            tableName: 'industrialModal',
            entityName: listEntityName.industrial,
          } : {}}
          {...entities === 'inventions' ? {
            tableName: 'inventionsModal',
            entityName: listEntityName.inventions,
          } : {}}
          {...entities === 'utility' ? {
            tableName: 'utilityModal',
            entityName: listEntityName.utility,
          } : {}}
          {...entities === 'emailLetters' ? {
            tableName: 'emailLettersModal',
            entityName: listEntityName.emailLetters,
          } : {}}
          disableHiddenColumn
          disableColumnOrder
          disableColumnWidth
          disableExport
          disableMenu
          disableTableScroll
        />
      </BoxContainerTable>
    </>
  );
}

TableEntities.propTypes = {
  selectedEntities: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  isDefaultFilters: PropTypes.bool,
  entities: PropTypes.string.isRequired,
  columns: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  filteringColumn: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  sortingColumn: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  showTableExtraFilter: PropTypes.bool.isRequired,
  selectionRow: PropTypes.bool.isRequired,
  handleSend: PropTypes.func,

  // documents
  documentsData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingDocuments: PropTypes.bool.isRequired,
  filtersDocuments: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  currentPageDocuments: PropTypes.number.isRequired,
  pageSizeDocuments: PropTypes.number.isRequired,
  totalCountDocuments: PropTypes.number.isRequired,

  // documentTemplates
  documentTemplatesData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingDocumentTemplates: PropTypes.bool.isRequired,
  filtersDocumentTemplates: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  currentPageDocumentTemplates: PropTypes.number.isRequired,
  pageSizeDocumentTemplates: PropTypes.number.isRequired,
  totalCountDocumentTemplates: PropTypes.number.isRequired,

  // trademarks
  trademarksData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingTrademarks: PropTypes.bool.isRequired,
  filtersTrademarks: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  currentPageTrademarks: PropTypes.number.isRequired,
  pageSizeTrademarks: PropTypes.number.isRequired,
  totalCountTrademarks: PropTypes.number.isRequired,

  // industrial
  industrialData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingIndustrial: PropTypes.bool.isRequired,
  filtersIndustrial: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  currentPageIndustrial: PropTypes.number.isRequired,
  pageSizeIndustrial: PropTypes.number.isRequired,
  totalCountIndustrial: PropTypes.number.isRequired,

  // inventions
  inventionsData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingInventions: PropTypes.bool.isRequired,
  filtersInventions: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  currentPageInventions: PropTypes.number.isRequired,
  pageSizeInventions: PropTypes.number.isRequired,
  totalCountInventions: PropTypes.number.isRequired,

  // utility
  utilityData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingUtility: PropTypes.bool.isRequired,
  filtersUtility: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  currentPageUtility: PropTypes.number.isRequired,
  pageSizeUtility: PropTypes.number.isRequired,
  totalCountUtility: PropTypes.number.isRequired,

  // emailLetters
  listLetters: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingEmailLetters: PropTypes.bool.isRequired,
  filtersEmailLetters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  emailLettersCurrentPage: PropTypes.number.isRequired,
  emailLettersPageSize: PropTypes.number.isRequired,
  emailLettersTotalCount: PropTypes.number.isRequired,
  listEmail: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,

  // Advanced Search
  clientsAutocompleteOptions: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  clientsData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  fieldClientsInput: PropTypes.string,
  autocompleteGroup: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  formValues: PropTypes.oneOfType([
    PropTypes.object,
  ]),
};

TableEntities.defaultProps = {
  isDefaultFilters: false,
  handleSend: () => {},
  // Advanced Search
  fieldClientsInput: '',
  formValues: {},
};

function mapStateToProps(state) {
  const getFormValues = selectors.form.getFormValues(state, formNameExtraFilter);

  return {
    selectedEntities: selectors.helpers.selectedEntities(state),

    // documents
    documentsData: selectors.departmentDocumentsModal.departmentDocumentsModalData(state),
    pendingDocuments: selectors.departmentDocumentsModal.pending(state),
    filtersDocuments: selectors.departmentDocumentsModal.filters(state),
    totalCountDocuments: selectors.departmentDocumentsModal.totalCount(state),
    currentPageDocuments: selectors.departmentDocumentsModal.currentPage(state),
    pageSizeDocuments: selectors.departmentDocumentsModal.pageSize(state),

    // documentTemplates
    documentTemplatesData: selectors.documentTemplates.documentTemplatesData(state),
    pendingDocumentTemplates: selectors.documentTemplates.pending(state),
    filtersDocumentTemplates: selectors.documentTemplates.filters(state),
    totalCountDocumentTemplates: selectors.documentTemplates.totalCount(state),
    currentPageDocumentTemplates: selectors.documentTemplates.currentPage(state),
    pageSizeDocumentTemplates: selectors.documentTemplates.pageSize(state),

    // trademarks
    trademarksData: selectors.tradeMarksProposal.proposalData(state),
    pendingTrademarks: selectors.tradeMarksProposal.pending(state),
    totalCountTrademarks: selectors.tradeMarksProposal.totalCount(state),
    currentPageTrademarks: selectors.tradeMarksProposal.currentPage(state),
    pageSizeTrademarks: selectors.tradeMarksProposal.pageSize(state),
    filtersTrademarks: selectors.tradeMarksProposal.filters(state),

    // industrial
    industrialData: selectors.industrialDesignsProposal.proposalData(state),
    pendingIndustrial: selectors.industrialDesignsProposal.pending(state),
    totalCountIndustrial: selectors.industrialDesignsProposal.totalCount(state),
    currentPageIndustrial: selectors.industrialDesignsProposal.currentPage(state),
    pageSizeIndustrial: selectors.industrialDesignsProposal.pageSize(state),
    filtersIndustrial: selectors.industrialDesignsProposal.filters(state),

    // inventions
    inventionsData: selectors.inventionsProposal.proposalData(state),
    pendingInventions: selectors.inventionsProposal.pending(state),
    totalCountInventions: selectors.inventionsProposal.totalCount(state),
    currentPageInventions: selectors.inventionsProposal.currentPage(state),
    pageSizeInventions: selectors.inventionsProposal.pageSize(state),
    filtersInventions: selectors.inventionsProposal.filters(state),

    // utility
    utilityData: selectors.utilityModelsProposal.proposalData(state),
    pendingUtility: selectors.utilityModelsProposal.pending(state),
    totalCountUtility: selectors.utilityModelsProposal.totalCount(state),
    currentPageUtility: selectors.utilityModelsProposal.currentPage(state),
    pageSizeUtility: selectors.utilityModelsProposal.pageSize(state),
    filtersUtility: selectors.utilityModelsProposal.filters(state),

    // emailLetters
    listLetters: selectors.email.listLetters(state),
    pendingEmailLetters: selectors.email.pendingEmailLetters(state),
    emailLettersTotalCount: selectors.email.emailLettersTotalCount(state),
    emailLettersCurrentPage: selectors.email.emailLettersCurrentPage(state),
    emailLettersPageSize: selectors.email.emailLettersPageSize(state),
    filtersEmailLetters: selectors.email.emailLettersFilters(state),
    listEmail: selectors.email.listEmail(state),

    // Advanced Search
    autocompleteGroup: selectors.helpers.autocompleteGroup(state),
    clientsData: selectors.clientsTable.clientsData(state),
    fieldClientsInput: getFormValues.get('clientsIdInput'),
    clientsAutocompleteOptions: selectors.clients.autocompleteOptions(state),
    formValues: getFormValues,
  };
}

export default compose(
  connect(mapStateToProps, null),
)(memo(TableEntities));
