// Helpers
import actionCreator from '../../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const currenciesActionAsync = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_CURRENCIES_ASYNC, params);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_CURRENCIES_INITIAL_STATE_ASYNC, path);
  },
  postCurrencyAsync(data) {
    return actionCreator(asyncTypes.POST_CURRENCY_ASYNC, data);
  },
  putCurrencyByIdAsync(data) {
    return actionCreator(asyncTypes.PUT_CURRENCY_BY_ID_ASYNC, data);
  },
  deleteCurrencyAsync() {
    return actionCreator(asyncTypes.DELETE_CURRENCY_ASYNC);
  },
  getAutocompleteOptionsAsync(params) {
    return actionCreator(asyncTypes.GET_CURRENCIES_AUTOCOMPLETE_OPTIONS_ASYNC, params);
  },
  postCurrencyCalcAsync(data) {
    return actionCreator(asyncTypes.POST_CURRENCY_CALC_ASYNC, data);
  },
});

export default currenciesActionAsync;
