import React from 'react';
import * as PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

// UI
import {
  // Grid as MuiGrid,
  LinearProgress,
} from '@material-ui/core';

// styles
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress);

function ProgressBar(props) {
  const {
    value,
  } = props;

  return (
    <BorderLinearProgress variant="determinate" value={value} />
  );
}

ProgressBar.displayName = 'ProgressBar';

ProgressBar.propTypes = {
  value: PropTypes.number,
};

ProgressBar.defaultProps = {
  value: false,
};

export default ProgressBar;
