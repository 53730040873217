// Core
import { all, call, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetWorksWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callDeleteWorkWorker,
  callGetAutocompleteOptionsWorker,
} from './workers';
import types from '../types';

function* watchGetWorks() {
  yield takeEvery(asyncTypes.GET_WORKS_ASYNC, callGetWorksWorker);
}
function* watchGetInitialState() {
  yield takeEvery(
    asyncTypes.GET_WORKS_INITIAL_STATE_ASYNC,
    callGetInitialStateWorker,
  );
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_WORKS_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_WORKS_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_WORKS_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_WORKS_SORTING, callSetToLocalDataWorker);
}

function* watchDeleteWork() {
  yield takeEvery(asyncTypes.DELETE_WORK_ASYNC, callDeleteWorkWorker);
}

function* watchGetAutocompleteOptions() {
  yield takeEvery(
    asyncTypes.GET_WORKS_AUTOCOMPLETE_OPTIONS_ASYNC,
    callGetAutocompleteOptionsWorker,
  );
}

export function* watchersWorks() {
  yield all([
    call(watchGetWorks),
    call(watchSetCurrentPage),
    call(watchSetPageSize),
    call(watchSetFilters),
    call(watchSetSorting),
    call(watchGetInitialState),
    call(watchDeleteWork),
    call(watchGetAutocompleteOptions),
  ]);
}
