// Core
import { Map } from 'immutable';

// Instruments
import types from './types';

// config
import globalStateKeys from '../../../config/globalStateKeys';

const initialState = Map({
  itemByHashId: Map({
    data: Map(),
    pending: false,
    pendingPut: false,
  }),
  delete: Map({
    pendingDelete: false,
    id: '',
  }),
  data: {
    pendingPost: false,
  },
  autocompleteOptions: Map({
    items: Map(),
    pending: false,
  }),
  pendingMergeClients: false,
  pendingSentCredentials: false,
  isModalOpenMerge: false,
  isModalOpenAddress: false,
});

export const stateKeys = Object.freeze({
  controller: 'clients',
  clientId: 'clientId',
  pendingMergeClients: 'pendingMergeClients',
  isModalOpenMerge: 'isModalOpenMerge',
  isModalOpenAddress: 'isModalOpenAddress',
  pendingSentCredentials: 'pendingSentCredentials',
});

export const clientsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_CLIENT_DATA: {
      return state.mergeIn([globalStateKeys.data], payload);
    }
    case types.SET_CLIENT_BY_ID: {
      return state.setIn([globalStateKeys.itemByHashId], Map({
        ...payload,
        ...payload.data ? {
          data: Map(payload.data),
        } : {},
      }));
    }
    case types.SET_DELETE_CLIENT: {
      return state.mergeIn([globalStateKeys.delete], payload);
    }
    case types.SET_PENDING_CLIENT_AUTOCOMPLETE_OPTIONS: {
      return state.setIn([
        globalStateKeys.autocompleteOptions,
        globalStateKeys.pending,
      ], payload);
    }
    case types.SET_CLIENT_AUTOCOMPLETE_OPTIONS: {
      return state.mergeIn([
        globalStateKeys.autocompleteOptions,
        globalStateKeys.items,
      ], Map(payload));
    }
    case types.SET_PENDING_POST_MERGE_CLIENTS: {
      return state.setIn([stateKeys.pendingMergeClients], payload);
    }
    case types.SET_IS_MODAL_OPEN_MERGE_CLIENTS: {
      return state.setIn([stateKeys.isModalOpenMerge], payload);
    }
    case types.SET_IS_MODAL_OPEN_ADDRESS_CLIENTS: {
      return state.setIn([stateKeys.isModalOpenAddress], payload);
    }
    case types.SET_PENDING_CLIENTS_SENT_CREDENTIALS: {
      return state.setIn([stateKeys.pendingSentCredentials], payload);
    }
    default:
      return state;
  }
};
