// Core
import React, { memo, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import * as PropTypes from 'prop-types';

// ui
import {
  Typography, Divider as MuiDivider,
  CardContent, Box, Link as MuiLink,
  FormControlLabel as MuiFormControlLabel,
  Grid, Card as MuiCard, Checkbox, Avatar as MuiAvatar,
} from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';

// lodash
import {
  isObject, isEqual, isEmpty, find,
  map, includes, without, replace, sortBy,
} from 'lodash';

// parts
import Loader from '../../../../../components/Loader';
import AllTermsModal from './AllTermsModal';
import YourListTermsModal from './YourListTermsModal';

// actions
import reboundsAction from '../../../../../engine/core/tradeMarks/rebounds/action';
import proposalActions from '../../../../../engine/core/tradeMarks/proposal/action';

// config
import selectors from '../../../../../engine/config/selectors';

// styles
// const Link = styled(MuiLink)(spacing);
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);

const Avatar = styled(MuiAvatar)`
  background-color: ${blue[500]};
`;
const CheckboxClass = styled(Checkbox)`
  height: calc(100% - 40px);
`;

const FormControlLabel = styled(MuiFormControlLabel)`
  opacity: 0.5;
`;
const Link = styled(MuiLink)`
margin-right: 10px;
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
`;

function AllClasses(props) {
  const {
    mktuClasses, selectMktuClasses, langMktuValue,
    getReboundById, langValue, disabled,
    getSearchRebound, pendingSearchRebound,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedMktuToModal, setSelectedMktuToModal] = useState({});
  const [searchMktuToModal, setSearchMktuToModal] = useState({});

  const CheckboxList = (propsCheck) => {
    const {
      index, checked, onChange, item,
      originalClass,
    } = propsCheck;

    return !isEmpty(item.term) ? (
      <>
        {index < 3
          ? (
            <div key={index}>
              <MuiFormControlLabel
                control={(
                  <Checkbox
                    checked={checked}
                    onChange={(e) => {
                      onChange(e.target.checked, item.term, originalClass);
                    }}
                  />
                )}
                label={(
                  <Typography dangerouslySetInnerHTML={{ __html: item.term }} />
                )}
              />
            </div>
          ) : null}
        {index === 3
          ? (
            <div key={index}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={checked}
                    onChange={(e) => {
                      onChange(e.target.checked, item.term);
                    }}
                  />
                )}
                label={(
                  <Typography dangerouslySetInnerHTML={{ __html: item.term }} />
                )}
              />
            </div>
          ) : null}
      </>
    ) : null;
  };

  const termsToArray = (terms) => map(terms, (term) => {
    if (isObject(term)) {
      return term.value;
    }
    return term;
  }).join('; ');

  useEffect(() => {
    if (!isEmpty(getReboundById.toJS()) && !isEmpty(mktuClasses.toJS())
      && isEmpty(selectMktuClasses.toJS())) {
      const templateClassIdArray = Object.keys(getReboundById.toJS().selected);
      const selectTemplateClass = map(templateClassIdArray, (templateClassId) => {
        const selectClass = find(mktuClasses.toJS(), { id: Number(templateClassId) });
        const termsArray = getReboundById.toJS().selected[templateClassId];
        return ({
          ...selectClass,
          name: termsArray.length === 1 && termsArray[0] === 'selection'
            ? 'All'
            : termsToArray(termsArray),
        });
      });
      dispatch(proposalActions.setSelectMktuClasses(selectTemplateClass));
    }
  }, [
    getReboundById, mktuClasses,
    selectMktuClasses, dispatch,
  ]);

  const handleMktuClasses = (mktuClass) => {
    const findMktuClass = find(selectMktuClasses.toJS(), { classNum: mktuClass.classNum });
    if (isEmpty(findMktuClass)) {
      dispatch(proposalActions.setSelectMktuClasses([...selectMktuClasses, {
        ...mktuClass,
        name: 'All',
      }]));
    } else {
      dispatch(proposalActions.setSelectMktuClasses(selectMktuClasses.toJS().filter(
        (selectMktuClass) => (selectMktuClass.id !== mktuClass.id),
      )));
    }
  };

  const handleModalAllTermsOpen = (mktuClass) => {
    setSelectedMktuToModal(mktuClass);
    setSearchMktuToModal({});
    dispatch(reboundsAction.setModalAllTermsOpen(true));
  };
  const handleModalYourListTermsOpen = (mktuClass) => {
    setSelectedMktuToModal(mktuClass);
    dispatch(reboundsAction.setModalYourListTermsOpen(true));
  };

  const handleModalTermsSearchOpen = (searchClass, originalClass) => {
    const terms = map(searchClass.items, 'term').join('; ');
    setSearchMktuToModal({
      ...originalClass,
      name: terms,
    });
    setSelectedMktuToModal({});
    dispatch(reboundsAction.setModalAllTermsOpen(true));
  };

  const handleSelectedTerms = (checked, term, originalClass) => {
    const trimTerm = replace(replace(term, '<strong>', ''), '</strong>', '');
    const findMktuClass = find(selectMktuClasses.toJS(), { classNum: originalClass.classNum });
    if (isEmpty(findMktuClass)) {
      dispatch(proposalActions.setSelectMktuClasses([
        ...selectMktuClasses,
        {
          ...originalClass,
          name: `${trimTerm}; `,
        },
      ]));
    } else {
      dispatch(proposalActions.setSelectMktuClasses([
        ...selectMktuClasses.toJS().filter(
          (selectClass) => (selectClass.classNum !== originalClass.classNum),
        ),
        {
          ...originalClass,
          name: checked
            ? [
              ...findMktuClass.name.split('; '),
              trimTerm,
            ].join('; ')
            : without(findMktuClass.name.split('; '), trimTerm).join('; '),
        },
      ]));
    }
  };

  return !pendingSearchRebound ? (
    <>
      {!isEmpty(mktuClasses.toJS()) && isEmpty(getSearchRebound.toJS())
        ? map(sortBy(mktuClasses.toJS(), 'id'), (mktuClass) => {
          const selectMktuClass = find(selectMktuClasses.toJS(), { classNum: mktuClass.classNum });
          const isChecked = !isEmpty(selectMktuClass) && selectMktuClass.name === 'All';
          const isIndeterminate = !isEmpty(selectMktuClass) && selectMktuClass.name !== 'All';
          return (
            <Card key={mktuClass.id} mb={3}>
              <CardContent>
                <Grid container wrap="nowrap" spacing={2}>
                  <Grid item>
                    <Avatar>{mktuClass.classNum}</Avatar>
                    <CheckboxClass
                      checked={isChecked}
                      indeterminate={isIndeterminate}
                      name="Checkbox"
                      onChange={() => handleMktuClasses(mktuClass)}
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs>
                    <Typography>{mktuClass.label}</Typography>
                  </Grid>
                </Grid>
                <Divider my={2} />
                {!disabled && (
                  <Box mt={2}>
                    <Link to="/" onClick={() => handleModalAllTermsOpen(mktuClass)} mr={4}>
                      {t('Terms')}
                    </Link>
                    <Link to="/" onClick={() => handleModalYourListTermsOpen(mktuClass)}>
                      {t('Edit list')}
                    </Link>
                  </Box>
                )}
              </CardContent>
            </Card>
          );
        }) : null}

      {!isEmpty(getSearchRebound.toJS()) && !isEmpty(getSearchRebound.toJS().items)
        ? map(Object.keys(getSearchRebound.toJS().items), (mktuId) => {
          const selectMktuClass = find(selectMktuClasses.toJS(), { id: Number(mktuId) });
          const isChecked = !isEmpty(selectMktuClass) && selectMktuClass.name === 'All';
          const isIndeterminate = !isEmpty(selectMktuClass) && selectMktuClass.name !== 'All';
          const searchClass = getSearchRebound.toJS().items[mktuId];
          const originalClass = find(mktuClasses.toJS(), { id: Number(mktuId) });
          return (
            <Card key={mktuId} mb={3}>
              <CardContent>
                <Grid container wrap="nowrap" spacing={2}>
                  <Grid item>
                    <Avatar>{selectMktuClass?.classNum}</Avatar>
                    <CheckboxClass
                      checked={isChecked}
                      indeterminate={isIndeterminate}
                      name="Checkbox"
                      onChange={() => handleMktuClasses(originalClass)}
                    />
                  </Grid>
                  <Grid item xs>
                    <Typography>{searchClass.title}</Typography>
                    {!isEmpty(searchClass) && !isEmpty(searchClass.items)
                      && map(searchClass.items, (item, index) => {
                        const trimTerm = replace(replace(item.term, '<strong>', ''), '</strong>', '');
                        const isCheckedTerm = !isEmpty(selectMktuClass)
                          && (selectMktuClass.name === 'All'
                          || includes(selectMktuClass.name, trimTerm));
                        return (
                          <CheckboxList
                            key={index}
                            index={index}
                            checked={isCheckedTerm}
                            originalClass={originalClass}
                            onChange={handleSelectedTerms}
                            item={item}
                          />
                        );
                      })}
                    {!isEmpty(searchClass) && !isEmpty(searchClass.items)
                      && searchClass.items.length > 4
                      ? (
                        <Link
                          variant="subtitle2"
                          to="/"
                          onClick={() => handleModalTermsSearchOpen(searchClass, originalClass)}
                        >
                          . . .
                        </Link>
                      )
                      : null}
                  </Grid>
                </Grid>
                <Divider my={2} />
                <Box mt={2}>
                  <Link to="/" onClick={() => handleModalAllTermsOpen(originalClass)} mr={4}>
                    {t('Terms')}
                  </Link>
                  <Link to="/" onClick={() => handleModalYourListTermsOpen(originalClass)}>
                    {t('Edit list')}
                  </Link>
                </Box>
              </CardContent>
            </Card>
          );
        }) : null}

      <AllTermsModal
        mktuClass={selectedMktuToModal}
        searchMktuClass={searchMktuToModal}
        langMktuValue={langMktuValue || langValue}
      />
      <YourListTermsModal
        mktuClass={selectedMktuToModal}
        langMktuValue={langMktuValue || langValue}
      />
    </>
  ) : <Loader />;
}

AllClasses.displayName = 'AllClasses';

AllClasses.propTypes = {
  mktuClasses: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  selectMktuClasses: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  getReboundById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  getSearchRebound: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingSearchRebound: PropTypes.bool.isRequired,
  langMktuValue: PropTypes.string,
  langValue: PropTypes.string,
  disabled: PropTypes.bool,
};
AllClasses.defaultProps = {
  langMktuValue: '',
  langValue: '',
  disabled: false,
};

function mapStateToProps(state) {
  return {
    mktuClasses: selectors.tradeMarksProposal.classes(state),
    selectMktuClasses: selectors.tradeMarksProposal.selectMktuClasses(state),

    getReboundById: selectors.rebounds.getReboundById(state),
    getSearchRebound: selectors.rebounds.getSearchRebound(state),
    pendingSearchRebound: selectors.rebounds.pendingSearchRebound(state),

    langMktuValue: selectors.form.getFormValues(state, 'ProposalAdd').get('langMktu'),
    langValue: selectors.form.getFormValues(state, 'Rebound').get('lang'),
  };
}

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.getReboundById, nextProps.getReboundById)
  && isEqual(prevProps.getSearchRebound, nextProps.getSearchRebound)
  && isEqual(prevProps.mktuClasses, nextProps.mktuClasses)
  && isEqual(prevProps.pendingSearchRebound, nextProps.pendingSearchRebound)
  && isEqual(prevProps.langMktuValue, nextProps.langMktuValue)
  && isEqual(prevProps.langValue, nextProps.langValue)
  && isEqual(prevProps.disabled, nextProps.disabled)
  && isEqual(prevProps.selectMktuClasses, nextProps.selectMktuClasses);
}

export default compose(connect(mapStateToProps, null))(memo(AllClasses, areEqual));
