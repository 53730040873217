// Core
import { all, call, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetPaymentsWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callDeletePaymentWorker,
  callPostPaymentsApplyWorker,
  callGetPaymentByIdWorker,
  callPostPaymentWorker,
  callPutPaymentByIdWorker,
} from './workers';
import types from '../types';

function* watchGetPayments() {
  yield takeEvery(asyncTypes.GET_PAYMENTS_ASYNC, callGetPaymentsWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_PAYMENTS_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_PAYMENTS_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_PAYMENTS_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_PAYMENTS_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_PAYMENTS_SORTING, callSetToLocalDataWorker);
}
function* watchSetColumnWidths() {
  yield takeEvery(types.SET_PAYMENTS_COLUMN_WIDTHS, callSetToLocalDataWorker);
}
function* watchSetColumnOrder() {
  yield takeEvery(types.SET_PAYMENTS_COLUMN_ORDER, callSetToLocalDataWorker);
}
function* watchSetHiddenColumnNames() {
  yield takeEvery(types.SET_PAYMENTS_HIDDEN_COLUMN_NAMES, callSetToLocalDataWorker);
}
function* watchDeletePayment() {
  yield takeEvery(asyncTypes.DELETE_PAYMENT_ASYNC, callDeletePaymentWorker);
}
function* watchPostPaymentsApply() {
  yield takeEvery(asyncTypes.POST_PAYMENTS_APPLY_ASYNC, callPostPaymentsApplyWorker);
}
function* watchGetPaymentById() {
  yield takeEvery(asyncTypes.GET_PAYMENT_BY_ID_ASYNC, callGetPaymentByIdWorker);
}
function* watchPostPayment() {
  yield takeEvery(asyncTypes.POST_PAYMENT_ASYNC, callPostPaymentWorker);
}
function* watchPutPaymentById() {
  yield takeEvery(asyncTypes.PUT_PAYMENT_BY_ID_ASYNC, callPutPaymentByIdWorker);
}

export function* watchersPaymentsTable() {
  yield all([
    call(watchGetPayments),
    call(watchSetCurrentPage),
    call(watchSetPageSize),
    call(watchSetFilters),
    call(watchSetSorting),
    call(watchGetInitialState),
    call(watchDeletePayment),
    call(watchPostPaymentsApply),
    call(watchGetPaymentById),
    call(watchPostPayment),
    call(watchPutPaymentById),
    call(watchSetColumnWidths),
    call(watchSetColumnOrder),
    call(watchSetHiddenColumnNames),
  ]);
}
