const asyncTypes = Object.freeze({
  GET_PRODUCTS_ASYNC: 'GET_PRODUCTS_ASYNC',
  GET_PRODUCTS_INITIAL_STATE_ASYNC: 'GET_PRODUCTS_INITIAL_STATE_ASYNC',
  GET_PRODUCT_BY_ID_ASYNC: 'GET_PRODUCT_BY_ID_ASYNC',
  POST_PRODUCT_ASYNC: 'POST_PRODUCT_ASYNC',
  PUT_PRODUCT_BY_ID_ASYNC: 'PUT_PRODUCT_BY_ID_ASYNC',
  DELETE_PRODUCT_ASYNC: 'DELETE_PRODUCT_ASYNC',
});

export default asyncTypes;
