// Core
import { call, put } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../../config/api';

// actions
import actions from '../../action';
import asyncAction from '../asyncAction';
import helpersActions from '../../../../helpers/action';

// helpers
import { setErrorMessage } from '../../../../../../ui/_helpers/setNotificationMessage';

export function* callDeleteEmailWorker({ payload: id }) {
  const { setPendingDeleteEmail, setSelectedEmail } = actions;
  const { getEmailsAsync } = asyncAction;
  const { setModal } = helpersActions;

  yield put(setPendingDeleteEmail(true));

  const response = yield call(api.email.deleteEmail, id);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      yield put(setSelectedEmail({}));
      yield put(getEmailsAsync());
      yield put(setModal({
        isModalOpen: false,
      }));
    }
  }

  yield put(setPendingDeleteEmail(false));
}
