// Core
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';

// Lodash
import {
  map, isEmpty,
} from 'lodash';

// UI
import {
  Button,
} from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';

// hooks
import { useAccessList } from '../../../ui/_hooks/useAccessList';

// actions
import actionsTable from '../../../engine/core/company/clients/table/action';
import helpersActions from '../../../engine/core/helpers/action';
import clientsActionAsync from '../../../engine/core/company/clients/saga/asyncAction';

// parts
import ConfirmModal from '../../../components/ConfirmModal';
import TableExtraFilter from '../../../ui/Table/components/TableExtraFilter/TableExtraFilter';
import MergeClientsModal from './MergeClientsModal';
import DxTable from '../../../ui/Table/DxTable';

// config
import selectors from '../../../engine/config/selectors';
import accessList from '../../../engine/config/accessList';

// routes
import { pageLinks } from '../../../routes';

import { listTablesName, listEntityName } from '../../../engine/config/listTablesName';

const tableName = listTablesName.clients;
const entityName = listEntityName.clients;

function ClientsTable(props) {
  const {
    clientsData, totalCount, pending,
    currentPage, filters, sorting,
    pageSize, pendingDeleteClient, isModalOpenMerge,
    checkboxes, columnWidths, columnOrder, hiddenColumnNames,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const accessPost = useAccessList(accessList.clients_post);

  const columns = [
    { name: 'id', title: t('ID') },
    { name: 'name', title: t('Name') },
    { name: 'phone', title: t('Phone') },
    { name: 'email', title: t('Email') },
  ];

  const tableColumnExtensions = [
    { columnName: 'id', width: 50 },
    { columnName: 'name', width: '40rem' },
    { columnName: 'phone', width: '20rem' },
    { columnName: 'email', width: '20rem' },
  ];

  // Paging
  const onCurrentPage = (page) => dispatch(actionsTable.setCurrentPage(page));
  const onPageSize = (size) => dispatch(actionsTable.setPageSize(size));
  // Filtering
  const setFilters = (getFilters) => dispatch(actionsTable.setFilters(getFilters));
  // Sorting
  const onSortingChange = (getSorting) => dispatch(actionsTable.setSorting(getSorting));
  const onColumnWidthsChange = (getColumnWidths) => dispatch(
    actionsTable.setColumnWidths(getColumnWidths),
  );
  const onColumnOrderChange = (getColumnOrder) => dispatch(
    actionsTable.setColumnOrder(getColumnOrder),
  );
  const onHiddenColumnNamesChange = (getHiddenColumnNames) => dispatch(
    actionsTable.setHiddenColumnNames(getHiddenColumnNames),
  );

  const handleGoToCreateClientPage = () => {
    dispatch(push(pageLinks.company.clients.new));
  };

  return (
    <>
      <TableExtraFilter
        filters={filters}
        filtersAction={setFilters}
      >
        {accessPost && (
          <Button variant="contained" color="primary" fullWidth onClick={handleGoToCreateClientPage}>
            {t('ADD')}
            <AddIcon />
          </Button>
        )}
      </TableExtraFilter>
      <DxTable
        name="Clients"
        isLoading={pending}
        columns={columns}
        rows={clientsData.toJS().items}
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageSize={onPageSize}
        onCurrentPage={onCurrentPage}
        filtersAction={setFilters}
        filters={filters}
        // checkboxes ------------------------
        tableName={tableName}
        reloadTable={actionsTable.setReload}
        selection={checkboxes.toJS()}
        onSelectionChange={(checkboxSelect) => {
          dispatch(helpersActions.setCheckbox({
            tableName,
            checkboxes: checkboxSelect,
          }));
        }}
        // ------------------------
        entityName={entityName}
        tableColumnExtensions={!isEmpty(columnWidths) && columnWidths.length === columns.length
          ? columnWidths : tableColumnExtensions}
        onColumnWidthsChange={onColumnWidthsChange}
        columnOrder={!isEmpty(columnOrder) ? columnOrder : map(columns, 'name')}
        onColumnOrderChange={onColumnOrderChange}
        hiddenColumnNames={hiddenColumnNames}
        onHiddenColumnNamesChange={onHiddenColumnNamesChange}
        sorting={sorting}
        onSortingChange={onSortingChange}
        isModalOpen={isModalOpenMerge}
        accessEdit={accessList.clients_put}
        accessDelete={accessList.clients_delete}
      />

      <ConfirmModal
        buttonSendText="DELETE"
        pending={pendingDeleteClient}
        handleSend={() => {
          dispatch(clientsActionAsync.deleteClientAsync());
        }}
      />

      {isModalOpenMerge && <MergeClientsModal />}
    </>
  );
}

ClientsTable.displayName = 'ClientsTable';

ClientsTable.propTypes = {
  clientsData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  currentPage: PropTypes.number.isRequired,
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  sorting: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  columnWidths: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  columnOrder: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  hiddenColumnNames: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  pageSize: PropTypes.number.isRequired,
  pendingDeleteClient: PropTypes.bool.isRequired,
  totalCount: PropTypes.number,
  pending: PropTypes.bool,
  checkboxes: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  isModalOpenMerge: PropTypes.bool.isRequired,
};

ClientsTable.defaultProps = {
  totalCount: 0,
  pending: false,
};

function mapStateToProps(state) {
  return {
    clientsData: selectors.clientsTable.clientsData(state),
    pending: selectors.clientsTable.pending(state),
    pendingDeleteClient: selectors.clients.pendingDeleteClient(state),

    // Paging
    totalCount: selectors.clientsTable.totalCount(state),
    currentPage: selectors.clientsTable.currentPage(state),
    pageSize: selectors.clientsTable.pageSize(state),

    // Filtering
    filters: selectors.clientsTable.filters(state),

    // Sorting
    sorting: selectors.clientsTable.sorting(state),

    // ColumnWidths
    columnWidths: selectors.clientsTable.columnWidths(state),
    columnOrder: selectors.clientsTable.columnOrder(state),
    hiddenColumnNames: selectors.clientsTable.hiddenColumnNames(state),

    checkboxes: selectors.helpers.getCheckboxes(state, tableName),
    isModalOpenMerge: selectors.clients.isModalOpenMerge(state),
  };
}

function mapDispatchToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(ClientsTable);
