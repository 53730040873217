// Core
import { call, put } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../../config/api';
import { messageRequestSuccess } from '../../../../../config/globalConfig';

// init
import i18n from '../../../../../init/i18n';

// actions
import actions from '../../action';
import asyncAction from '../asyncAction';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../../ui/_helpers/setNotificationMessage';

export function* callPutCompanyByIdWorker({ payload: company }) {
  const { setCompanyById } = actions;

  yield put(setCompanyById({
    pendingPut: true,
  }));

  const response = yield call(api.company.putCompanyById, company);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      yield put(setSuccessMessage(i18n.t(messageRequestSuccess), response.status.toString()));
      yield put(asyncAction.getCompanyByIdAsync(data.id));
      yield put(asyncAction.getAllCompaniesAsync());
    }
  }

  yield put(setCompanyById({
    pendingPut: false,
  }));
}
