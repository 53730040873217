// Core
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

// lodash
import { isEmpty, map } from 'lodash';

// parts
import DxTable from '../../../ui/Table/DxTable';

// actions
import helpersActions from '../../../engine/core/helpers/action';
import searchesActions from '../../../engine/core/tradeMarks/searches/action';
import searchesActionAsync from '../../../engine/core/tradeMarks/searches/saga/asyncAction';
import tradeMarksAsyncActions from '../../../engine/core/tradeMarks/proposal/saga/asyncAction';
import tradeMarksActions from '../../../engine/core/tradeMarks/proposal/action';
import clientsAsyncActions from '../../../engine/core/company/clients/table/saga/asyncAction';
import clientsActions from '../../../engine/core/company/clients/table/action';

// parts
import ConfirmModal from '../../../components/ConfirmModal';

// config
import selectors from '../../../engine/config/selectors';

import { listTablesName, listEntityName } from '../../../engine/config/listTablesName';
import { useAccessList } from '../../../ui/_hooks/useAccessList';
import accessList from '../../../engine/config/accessList';

const tableName = listTablesName.trademarksSearch;
const entityName = listEntityName.search;

function SearchesTable(props) {
  const {
    searchesData, totalCount, pending,
    currentPage, filters, sorting,
    pageSize, pendingDeleteSearch, checkboxes,
    columnWidths, columnOrder, hiddenColumnNames,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const accessTrademarksList = useAccessList(accessList.trademarks_list_get);
  const accessClientsList = useAccessList(accessList.clients_list_get);

  useEffect(() => function cleanup() {
    const emptyData = { items: [], pending: false, totalCount: 0 };
    dispatch(searchesActions.setSearchesData(emptyData));
    dispatch(tradeMarksActions.setProposalData(emptyData));
    dispatch(clientsActions.setClientsData(emptyData));
  }, [dispatch]);

  useEffect(() => {
    const paramsList = {
      sortBy: 'createdAt',
      sortDir: 'asc',
      limit: 300,
      page: 1,
    };
    if (accessTrademarksList) dispatch(tradeMarksAsyncActions.getListAsync(paramsList));
    if (accessClientsList) dispatch(clientsAsyncActions.getListAsync(paramsList));
  }, [dispatch, accessTrademarksList, accessClientsList]);

  const columns = [
    { name: 'id', title: t('ID') },
    { name: 'query.keywords', title: t('Word') },
    { name: 'query.classes', title: t('Classes') },
    { name: 'createdAt', title: t('Created At') },
    { name: 'client', title: t('Client') },
    { name: 'trademarks', title: t('Trademark'), customField: 'Autocomplete' },
  ];

  const sortingStateColumnExtensions = [
    { columnName: 'id' },
    { columnName: 'query.keywords' },
    { columnName: 'query.classes', sortingEnabled: false },
    { columnName: 'createdAt' },
    { columnName: 'client', sortingEnabled: false },
    { columnName: 'trademarks', sortingEnabled: false },
  ];

  const tableColumnExtensions = [
    { columnName: 'id', width: 50 },
    { columnName: 'query.keywords', width: '15rem' },
    { columnName: 'query.classes', width: '15rem' },
    { columnName: 'client', width: '20rem' },
    { columnName: 'trademarks', width: '15rem' },
    { columnName: 'createdAt', width: '15rem' },
  ];

  // Paging
  const onCurrentPage = (page) => dispatch(searchesActions.setCurrentPage(page));
  const onPageSize = (size) => dispatch(searchesActions.setPageSize(size));
  // Filtering
  const setFilters = (getFilters) => dispatch(searchesActions.setFilters(getFilters));
  // Sorting
  const onSortingChange = (getSorting) => dispatch(searchesActions.setSorting(getSorting));
  const onColumnWidthsChange = (getColumnWidths) => dispatch(
    searchesActions.setColumnWidths(getColumnWidths),
  );
  const onColumnOrderChange = (getColumnOrder) => dispatch(
    searchesActions.setColumnOrder(getColumnOrder),
  );
  const onHiddenColumnNamesChange = (getHiddenColumnNames) => dispatch(
    searchesActions.setHiddenColumnNames(getHiddenColumnNames),
  );

  return (
    <>
      <DxTable
        name="ReboundsTable"
        isLoading={pending}
        columns={columns}
        rows={searchesData.toJS().items}
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageSize={onPageSize}
        onCurrentPage={onCurrentPage}
        filtersAction={setFilters}
        filters={filters}
        entityName={entityName}
        sortingStateColumnExtensions={sortingStateColumnExtensions}
        sorting={sorting}
        // checkboxes ------------------------
        tableName={tableName}
        reloadTable={searchesActions.setReload}
        selection={checkboxes.toJS()}
        onSelectionChange={(checkboxSelect) => {
          dispatch(helpersActions.setCheckbox({
            tableName,
            checkboxes: checkboxSelect,
          }));
        }}
        // ------------------------
        onSortingChange={onSortingChange}
        tableColumnExtensions={!isEmpty(columnWidths) && columnWidths.length === columns.length
          ? columnWidths : tableColumnExtensions}
        onColumnWidthsChange={onColumnWidthsChange}
        columnOrder={!isEmpty(columnOrder) ? columnOrder : map(columns, 'name')}
        onColumnOrderChange={onColumnOrderChange}
        hiddenColumnNames={hiddenColumnNames}
        onHiddenColumnNamesChange={onHiddenColumnNamesChange}
        accessEdit={accessList.search_put}
        accessDelete={accessList.search_delete}
      />

      <ConfirmModal
        buttonSendText="DELETE"
        pending={pendingDeleteSearch}
        handleSend={() => {
          dispatch(searchesActionAsync.deleteSearchAsync());
        }}
      />
    </>
  );
}

SearchesTable.displayName = 'SearchesTable';

SearchesTable.propTypes = {
  searchesData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  totalCount: PropTypes.number.isRequired,
  pending: PropTypes.bool.isRequired,
  pendingDeleteSearch: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  sorting: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  pageSize: PropTypes.number.isRequired,
  checkboxes: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  columnWidths: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  columnOrder: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  hiddenColumnNames: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
};

SearchesTable.defaultProps = {};

function mapStateToProps(state) {
  return {
    searchesData: selectors.tradeMarksSearches.searchesData(state),
    pending: selectors.tradeMarksSearches.pending(state),
    pendingDeleteSearch: selectors.tradeMarksSearches.pendingDeleteSearch(state),

    // Paging
    totalCount: selectors.tradeMarksSearches.totalCount(state),
    currentPage: selectors.tradeMarksSearches.currentPage(state),
    pageSize: selectors.tradeMarksSearches.pageSize(state),

    // Filtering
    filters: selectors.tradeMarksSearches.filters(state),

    // Sorting
    sorting: selectors.tradeMarksSearches.sorting(state),

    // ColumnWidths
    columnWidths: selectors.tradeMarksSearches.columnWidths(state),
    columnOrder: selectors.tradeMarksSearches.columnOrder(state),
    hiddenColumnNames: selectors.tradeMarksSearches.hiddenColumnNames(state),

    checkboxes: selectors.helpers.getCheckboxes(state, tableName),
  };
}

function mapDispatchToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(SearchesTable);
