// Helpers
import actionCreator from '../../_helpers/actionCreator';
// Types
import types from './types';

const userActions = Object.freeze({
  setUserInfo(userInfo) {
    return actionCreator(types.SET_USER_INFO, userInfo);
  },
  setUserPending(pending) {
    return actionCreator(types.SET_USER_PENDING, pending);
  },
  setUserPendingResetPassword(pending) {
    return actionCreator(types.SET_USER_PENDING_RESET_PASSWORD, pending);
  },
});

export default userActions;
