// Core
import { all, call, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetDocumentTypesWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callDeleteDocumentTypeWorker,
  callPostDocumentTypeWorker,
  callGetDocumentTypeByIdWorker,
  callPutDocumentTypeByIdWorker,
  callGetAutocompleteOptionsWorker,
} from './workers';
import types from '../types';

function* watchGetDocumentTypes() {
  yield takeEvery(asyncTypes.GET_DOCUMENT_TYPES_ASYNC, callGetDocumentTypesWorker);
}
function* watchGetInitialState() {
  yield takeEvery(
    asyncTypes.GET_DOCUMENT_TYPES_INITIAL_STATE_ASYNC,
    callGetInitialStateWorker,
  );
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_DOCUMENT_TYPES_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_DOCUMENT_TYPES_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_DOCUMENT_TYPES_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_DOCUMENT_TYPES_SORTING, callSetToLocalDataWorker);
}
function* watchSetColumnWidths() {
  yield takeEvery(types.SET_DOCUMENT_TYPES_COLUMN_WIDTHS, callSetToLocalDataWorker);
}
function* watchSetColumnOrder() {
  yield takeEvery(types.SET_DOCUMENT_TYPES_COLUMN_ORDER, callSetToLocalDataWorker);
}
function* watchSetHiddenColumnNames() {
  yield takeEvery(types.SET_DOCUMENT_TYPES_HIDDEN_COLUMN_NAMES, callSetToLocalDataWorker);
}

function* watchDeleteDocumentType() {
  yield takeEvery(asyncTypes.DELETE_DOCUMENT_TYPE_ASYNC, callDeleteDocumentTypeWorker);
}

function* watchPostDocumentType() {
  yield takeEvery(asyncTypes.POST_DOCUMENT_TYPE_ASYNC, callPostDocumentTypeWorker);
}

function* watchGetDocumentTypeById() {
  yield takeEvery(
    asyncTypes.GET_DOCUMENT_TYPE_BY_ID_ASYNC,
    callGetDocumentTypeByIdWorker,
  );
}

function* watchPutDocumentTypeById() {
  yield takeEvery(
    asyncTypes.PUT_DOCUMENT_TYPE_BY_ID_ASYNC,
    callPutDocumentTypeByIdWorker,
  );
}

function* watchGetAutocompleteOptions() {
  yield takeEvery(
    asyncTypes.GET_DOCUMENT_TYPES_AUTOCOMPLETE_OPTIONS_ASYNC,
    callGetAutocompleteOptionsWorker,
  );
}

export function* watchersDocumentTypesTable() {
  yield all([
    call(watchSetCurrentPage),
    call(watchSetPageSize),
    call(watchSetFilters),
    call(watchSetSorting),
    call(watchGetInitialState),
    call(watchGetAutocompleteOptions),
    call(watchGetDocumentTypes),
    call(watchDeleteDocumentType),
    call(watchPostDocumentType),
    call(watchGetDocumentTypeById),
    call(watchPutDocumentTypeById),
    call(watchSetColumnWidths),
    call(watchSetColumnOrder),
    call(watchSetHiddenColumnNames),
  ]);
}
