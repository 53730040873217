// Core
import { apply, put } from 'redux-saga/effects';

// lodash
import { map } from 'lodash';

// config
import { api } from '../../../../../config/api';
import { listEntityName } from '../../../../../config/listTablesName';

// actions
import actions from '../../action';

const entity = listEntityName.orders;

export function* callGetOrdersWorker({ payload: params }) {
  yield put(actions.setOrdersData({ pending: true }));
  const response = yield apply(api, api.company.getOrders, [{ ...params, entity }]);

  let dataInit = {
    items: [],
    totalCount: 0,
    pending: false,
  };

  if (response && response.status >= 200 && response.status < 400) {
    const { data: { items } } = response;
    const modifyItems = map(items, (item) => ({
      ...item,
      label: item.orderNum,
    }));
    dataInit = Object.assign(dataInit, {
      ...response.data,
      items: modifyItems,
    });
  }
  yield put(actions.setOrdersData(dataInit));
  yield put(actions.setReload(false));
}
