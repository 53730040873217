// Core
import { apply, put } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../../config/api';
import { messageRequestSent } from '../../../../../config/globalConfig';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../../ui/_helpers/setNotificationMessage';

// init
import i18n from '../../../../../init/i18n';

export function* callPostAddDocumentsWorker({ payload: params }) {
  const response = yield apply(api, api.trademarks.postAddDocuments, [params]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      yield put(setSuccessMessage(i18n.t(messageRequestSent), response.status.toString()));
    }
  }
}
