// Core
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';

// UI
import {
  Typography, Divider as MuiDivider, Tabs as MuiTabs, Tab,
} from '@material-ui/core';

// parts
import ImportObjects from './ImportObjects';
import ImportUkrpatent from './ImportUkrpatent';
import ImportFromExcel from './ImportFromExcel';
import accessList from '../../engine/config/accessList';
import { useAccessList } from '../../ui/_hooks/useAccessList';

// styles
const Divider = styled(MuiDivider)(spacing);
const Tabs = styled(MuiTabs)(spacing);

function Import() {
  const { t } = useTranslation();
  const [valueTab, setValueTab] = useState(0);
  const accessSpezPost = useAccessList(accessList.documents_spez_post);
  const accessImportPost = useAccessList(accessList.entity_import_post);
  const accessExcelPost = useAccessList(accessList.entity_import_excel_post);

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  return (
    <>
      <Helmet title={t('Import')} />
      <Typography variant="h3" gutterBottom display="inline">
        {t('Import')}
      </Typography>

      <Divider my={6} />

      <Tabs
        mb={5}
        value={valueTab}
        onChange={handleChangeTab}
        indicatorColor="primary"
        textColor="primary"
      >
        {accessSpezPost && <Tab label={t('From the SPEZ-1 office')} />}
        {accessImportPost && <Tab label={t('From the open register')} />}
        {accessExcelPost && <Tab label={t('From Excel')} />}
      </Tabs>

      {valueTab === 0 && accessSpezPost && (<ImportUkrpatent />)}
      {valueTab === 1 && (<ImportObjects />)}
      {valueTab === 2 && (<ImportFromExcel />)}
    </>
  );
}

Import.displayName = 'Import';

Import.propTypes = {
};

Import.defaultProps = {};

function mapStateToProps() {
  return {
  };
}

function mapDispatchToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Import);
