const asyncTypes = Object.freeze({
  GET_INTEGRATIONS_ASYNC: 'GET_INTEGRATIONS_ASYNC',
  GET_INTEGRATIONS_INITIAL_STATE_ASYNC: 'GET_INTEGRATIONS_INITIAL_STATE_ASYNC',
  GET_INTEGRATION_BY_ID_ASYNC: 'GET_INTEGRATION_BY_ID_ASYNC',
  POST_INTEGRATION_ASYNC: 'POST_INTEGRATION_ASYNC',
  PUT_INTEGRATION_BY_ID_ASYNC: 'PUT_INTEGRATION_BY_ID_ASYNC',
  DELETE_INTEGRATION_ASYNC: 'DELETE_INTEGRATION_ASYNC',
  GET_INTEGRATIONS_AUTOCOMPLETE_OPTIONS_ASYNC: 'GET_INTEGRATIONS_AUTOCOMPLETE_OPTIONS_ASYNC',
  POST_INTEGRATIONS_CHECK_FTP_ASYNC: 'POST_INTEGRATIONS_CHECK_FTP_ASYNC',
});

export default asyncTypes;
