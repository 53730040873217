// Core
import { all, call, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetEmailTemplateWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callDeleteEmailTemplateWorker,
  callPostEmailTemplateWorker,
  callGetEmailTemplateByIdWorker,
  callPutEmailTemplateByIdWorker,
  callGetAutocompleteOptionsWorker,
} from './workers';
import types from '../types';

function* watchGetEmailTemplate() {
  yield takeEvery(asyncTypes.GET_EMAIL_TEMPLATE_ASYNC, callGetEmailTemplateWorker);
}
function* watchGetInitialState() {
  yield takeEvery(
    asyncTypes.GET_EMAIL_TEMPLATE_INITIAL_STATE_ASYNC,
    callGetInitialStateWorker,
  );
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_EMAIL_TEMPLATE_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_EMAIL_TEMPLATE_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_EMAIL_TEMPLATE_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_EMAIL_TEMPLATE_SORTING, callSetToLocalDataWorker);
}
function* watchSetColumnWidths() {
  yield takeEvery(types.SET_EMAIL_TEMPLATE_COLUMN_WIDTHS, callSetToLocalDataWorker);
}
function* watchSetColumnOrder() {
  yield takeEvery(types.SET_EMAIL_TEMPLATE_COLUMN_ORDER, callSetToLocalDataWorker);
}
function* watchSetHiddenColumnNames() {
  yield takeEvery(types.SET_EMAIL_TEMPLATE_HIDDEN_COLUMN_NAMES, callSetToLocalDataWorker);
}

function* watchDeleteEmailTemplate() {
  yield takeEvery(
    asyncTypes.DELETE_EMAIL_TEMPLATE_ASYNC,
    callDeleteEmailTemplateWorker,
  );
}

function* watchPostEmailTemplate() {
  yield takeEvery(asyncTypes.POST_EMAIL_TEMPLATE_ASYNC, callPostEmailTemplateWorker);
}

function* watchGetEmailTemplateById() {
  yield takeEvery(
    asyncTypes.GET_EMAIL_TEMPLATE_BY_ID_ASYNC,
    callGetEmailTemplateByIdWorker,
  );
}

function* watchPutEmailTemplateById() {
  yield takeEvery(
    asyncTypes.PUT_EMAIL_TEMPLATE_BY_ID_ASYNC,
    callPutEmailTemplateByIdWorker,
  );
}

function* watchGetAutocompleteOptions() {
  yield takeEvery(
    asyncTypes.GET_EMAIL_TEMPLATE_AUTOCOMPLETE_OPTIONS_ASYNC,
    callGetAutocompleteOptionsWorker,
  );
}

export function* watchersEmailTemplateTable() {
  yield all([
    call(watchSetCurrentPage),
    call(watchSetPageSize),
    call(watchSetFilters),
    call(watchSetSorting),
    call(watchGetInitialState),
    call(watchGetAutocompleteOptions),
    call(watchGetEmailTemplate),
    call(watchDeleteEmailTemplate),
    call(watchPostEmailTemplate),
    call(watchGetEmailTemplateById),
    call(watchPutEmailTemplateById),
    call(watchSetColumnWidths),
    call(watchSetColumnOrder),
    call(watchSetHiddenColumnNames),
  ]);
}
