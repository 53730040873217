// core
import React, {
  useEffect, useState, useCallback, useMemo,
} from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import {
  Field, Form, reduxForm,
} from 'redux-form/immutable';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import { Link as MuiLink } from 'react-router-dom';
import { Map } from 'immutable';

// lodash
import {
  isEmpty, filter, includes, find, map, keys,
} from 'lodash';

// ui
import {
  Button as MuiButton, CardContent, Divider as MuiDivider, Box,
  Typography, Card as MuiCard, CircularProgress, Grid as MuiGrid,
  ButtonGroup,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import {
  NavigateBefore as NavigateBeforeIcon,
  Add as AddIcon,
} from '@material-ui/icons';

// parts
import Loader from '../../../components/Loader';
import renderTextField from '../../../ui/Form/RenderTextField';
import FieldJurisdiction from '../../../ui/Form/FieldsAutocomplete/FieldJurisdiction';
import FieldProductCategory from '../../../ui/Form/FieldsAutocomplete/FieldProductCategory';
import AddProductCategoryModal from './AddProductCategoryModal';
import DxTable from '../../../ui/Table/DxTable';
import TableEntitiesModal from '../../../components/TableEntitiesModal/TableEntitiesModal';
import ProposalCalendar from '../../../components/ProposalCalendar/ProposalCalendar';

// actions
import actionAsync from '../../../engine/core/products/saga/asyncAction';
import action from '../../../engine/core/products/action';
import productCategoryAction from '../../../engine/core/products/productCategory/action';
import productCategoryAsyncAction from '../../../engine/core/products/productCategory/saga/asyncAction';
import helpersAction from '../../../engine/core/helpers/action';

// hooks
import { useEventsAutocompleteAsync } from '../../../ui/_hooks/useEventsAutocompleteAsync';
import { useAccessList } from '../../../ui/_hooks/useAccessList';

// config
import selectors from '../../../engine/config/selectors';
import accessList from '../../../engine/config/accessList';

// routes
import { pageLinks } from '../../../routes';
// import SimpleTable from '../../ImportObjects/components/SimpleTable';

// styles
const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const Button = styled(MuiButton)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Link = styled(MuiLink)`
  text-decoration: underline;
  color: #1976d2;
  display: flex;
  align-items: center;
`;

const formName = 'Product';

function Product(props) {
  const {
    initialize, handleSubmit, pendingProductById, getProductById,
    pendingPostProduct, pendingPutProductById, match, isModalOpen,
    isModalOpenTableEntities,
  } = props;
  const { t } = useTranslation();
  const {
    getAutocompleteLists,
  } = useEventsAutocompleteAsync();
  const dispatch = useDispatch();
  const [readyDepartmentDocument, setReadyDepartmentDocument] = useState(false);
  const [readyInitialize, setReadyInitialize] = useState(false);
  const [selectionTrademarks, setSelectionTrademarks] = useState([]);
  const [listTrademarks, setListTrademarks] = useState([]);
  const [selectionIndustrial, setSelectionIndustrial] = useState([]);
  const [listIndustrial, setListIndustrial] = useState([]);
  const [selectionInventions, setSelectionInventions] = useState([]);
  const [listInventions, setListInventions] = useState([]);
  const [selectionUtility, setSelectionUtility] = useState([]);
  const [listUtility, setListUtility] = useState([]);
  const [selectionDocuments, setSelectionDocuments] = useState([]);
  const [listDocuments, setListDocuments] = useState([]);
  const [entityModal, setEntityModal] = useState('documents');
  const { params } = match;
  const accessPut = useAccessList(accessList.products_put);
  const accessProductCategoryDelete = useAccessList(accessList.productCategory_delete);
  const accessProductCategoryPost = useAccessList(accessList.productCategory_post);
  const accessProductCategoryGet = useAccessList(accessList.productCategory_get);
  const accessTrademarksListGet = useAccessList(accessList.trademarks_list_get);
  const accessIndustrialListGet = useAccessList(accessList.industrial_list_get);
  const accessInventionsListGet = useAccessList(accessList.inventions_get);
  const accessUtilityListGet = useAccessList(accessList.utility_list_get);
  const accessDocumentsListGet = useAccessList(accessList.documents_list_get);
  const accessEdit = !isEmpty(params) ? accessPut : true;

  useEffect(() => function cleanup() {
    dispatch(action.setProductDataById({}));
  }, [dispatch]);

  const modifyToIds = (items) => (!isEmpty(items)
    ? filter(map(items.toJS ? items.toJS() : items, (item) => item.id), (elem) => elem)
    : []);

  const handleSubmits = (formData) => {
    const json = formData.toJS();
    const category = json.productCategory;
    delete json.productCategory;

    dispatch(actionAsync[!isEmpty(params)
      ? 'putProductByIdAsync'
      : 'postProductAsync']({
      ...json,
      ...!isEmpty(category) ? {
        category: category?.id,
      } : {},
      ...!isEmpty(json.jurisdictions) ? {
        jurisdictions: modifyToIds(json.jurisdictions),
      } : {},
      ...!isEmpty(listTrademarks) ? {
        trademarks: modifyToIds(listTrademarks),
      } : {},
      ...!isEmpty(listIndustrial) ? {
        industrial: modifyToIds(listIndustrial),
      } : {},
      ...!isEmpty(listInventions) ? {
        inventions: modifyToIds(listInventions),
      } : {},
      ...!isEmpty(listUtility) ? {
        utility: modifyToIds(listUtility),
      } : {},
      ...!isEmpty(listDocuments) ? {
        documents: modifyToIds(listDocuments),
      } : {},
    }));
  };

  const paramsByFilterPendingAction = useMemo(() => ({
    ...!isEmpty(listTrademarks) ? {
      'trademarks.id:typeRequest:in': modifyToIds(listTrademarks),
    } : {},
    ...!isEmpty(listIndustrial) ? {
      'industrial.id:typeRequest:in': modifyToIds(listIndustrial),
    } : {},
    ...!isEmpty(listInventions) ? {
      'inventions.id:typeRequest:in': modifyToIds(listInventions),
    } : {},
    ...!isEmpty(listUtility) ? {
      'utility.id:typeRequest:in': modifyToIds(listUtility),
    } : {},
    ...!isEmpty(listDocuments) ? {
      'documents.id:typeRequest:in': modifyToIds(listDocuments),
    } : {},
  }), [listTrademarks, listIndustrial, listInventions, listUtility, listDocuments]);

  useEffect(() => {
    if (!readyDepartmentDocument && !isEmpty(params) && !isEmpty(params.hashId)) {
      setReadyDepartmentDocument(true);
      dispatch(actionAsync.getProductByIdAsync(params.hashId));
    }
  }, [dispatch, params, readyDepartmentDocument]);

  useEffect(() => {
    if (!readyInitialize && !isEmpty(getProductById.toJS())
      && !isEmpty(params) && !isEmpty(params.hashId)) {
      setReadyInitialize(true);
      initialize({
        ...!isEmpty(getProductById.toJS().category) ? {
          productCategory: getProductById.toJS().category,
        } : {},
        ...!isEmpty(getProductById.toJS().name) ? {
          name: getProductById.toJS().name,
        } : {},
        ...!isEmpty(getProductById.toJS().jurisdictions) ? {
          jurisdictions: getProductById.toJS().jurisdictions,
        } : {},
        ...getProductById.toJS().id ? {
          id: getProductById.toJS().id,
        } : {},
      });
      setListTrademarks(getProductById.toJS()?.trademarks || []);
      setListIndustrial(getProductById.toJS()?.industrial || []);
      setListInventions(getProductById.toJS()?.inventions || []);
      setListUtility(getProductById.toJS()?.utility || []);
      setListDocuments(getProductById.toJS()?.documents || []);
    }
  }, [params, initialize, getProductById, readyInitialize]);

  const handleCreateNew = useCallback((value) => {
    if (accessProductCategoryPost) {
      dispatch(productCategoryAction.setIsModalOpen(true));
      if (value) dispatch(productCategoryAction.setDefaultName(value));
    }
  }, [dispatch, accessProductCategoryPost]);

  const handleEdit = useCallback((item) => {
    if (item && accessProductCategoryGet) {
      dispatch(productCategoryAction.setProductCategoryById({ data: Map(item) }));
      dispatch(productCategoryAction.setIsModalOpen(true));
    }
  }, [dispatch, accessProductCategoryGet]);

  const handleDelete = useCallback((item) => {
    if (item && item.id && accessProductCategoryDelete) {
      dispatch(productCategoryAsyncAction.deleteProductCategoryAsync(item.id));
    }
  }, [dispatch, accessProductCategoryDelete]);

  const columnsTrademarks = [
    { name: 'id', title: t('ID') },
    { name: 'caseNumber', title: t('Case number') },
    { name: 'title', title: t('Image'), customField: 'titleLogo' },
    { name: 'clients', title: t('Client'), customField: 'objectTable' },
    { name: 'jurisdictions', title: t('Jurisdiction') },
    { name: 'applicationNumber', title: t('Application number') },
    { name: 'registrationNumber', title: t('Registration number') },
    { name: 'status', title: t('Status') },
    { name: 'pendingActions', title: t('Action up to') },
    { name: 'createdAt', title: t('Created At') },
  ];

  const columnsDocuments = [
    { name: 'id', title: t('ID') },
    { name: 'title', title: t('Title'), customField: 'titleDepartmentDocuments' },
    { name: 'type', title: t('Type') },
    { name: 'inNumber', title: t('In number') },
    { name: 'outNumber', title: t('Out number') },
    { name: 'documentDate', title: t('Date') },
    { name: 'receivingDate', title: t('Received At') },
    { name: 'nextAction', title: t('Action up to') },
  ];

  const handleAddEntity = (name) => {
    setEntityModal(name);
    dispatch(helpersAction.setModal({ isModalOpenTableEntities: true }));
  };

  const handleDeleteTrademarks = () => {
    setListTrademarks(filter(listTrademarks, (item) => !includes(selectionTrademarks, item.id)));
    setSelectionTrademarks([]);
  };

  const handleSelectTrademarks = (list) => {
    const filterList = filter(list, (item) => !find(listTrademarks, { id: item.id }));
    const allList = [...listTrademarks, ...filterList];
    dispatch(helpersAction.setModal({ isModalOpenTableEntities: false }));
    setTimeout(() => {
      setListTrademarks(allList);
    });
  };

  const handleDeleteIndustrial = () => {
    setListIndustrial(filter(listIndustrial, (item) => !includes(selectionIndustrial, item.id)));
    setSelectionIndustrial([]);
  };

  const handleSelectIndustrial = (list) => {
    const filterList = filter(list, (item) => !find(listIndustrial, { id: item.id }));
    const allList = [...listIndustrial, ...filterList];
    dispatch(helpersAction.setModal({ isModalOpenTableEntities: false }));
    setTimeout(() => {
      setListIndustrial(allList);
    });
  };

  const handleDeleteInventions = () => {
    setListInventions(filter(listInventions, (item) => !includes(selectionInventions, item.id)));
    setSelectionInventions([]);
  };

  const handleSelectInventions = (list) => {
    const filterList = filter(list, (item) => !find(listInventions, { id: item.id }));
    const allList = [...listInventions, ...filterList];
    dispatch(helpersAction.setModal({ isModalOpenTableEntities: false }));
    setTimeout(() => setListInventions(allList));
  };

  const handleDeleteUtility = () => {
    setListUtility(filter(listUtility, (item) => !includes(selectionUtility, item.id)));
    setSelectionUtility([]);
  };

  const handleSelectUtility = (list) => {
    const filterList = filter(list, (item) => !find(listUtility, { id: item.id }));
    const allList = [...listUtility, ...filterList];
    dispatch(helpersAction.setModal({ isModalOpenTableEntities: false }));
    setTimeout(() => setListUtility(allList));
  };

  const handleDeleteDocuments = () => {
    setListDocuments(filter(listDocuments, (item) => !includes(selectionDocuments, item.id)));
    setSelectionDocuments([]);
  };

  const handleSelectDocuments = (list) => {
    const filterList = filter(list, (item) => !find(listDocuments, { id: item.id }));
    const allList = [...listDocuments, ...filterList];
    dispatch(helpersAction.setModal({ isModalOpenTableEntities: false }));
    setTimeout(() => setListDocuments(allList));
  };

  return !pendingProductById ? (
    <>
      <Helmet title={t('Product')} />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h3" gutterBottom display="inline">
          {t('Product')}
        </Typography>
        <Link to={pageLinks.products.all}>
          <NavigateBeforeIcon size="small" color="primary" />
          {t('Up to the list')}
        </Link>
      </Box>

      <Divider my={6} />

      {isModalOpen && <AddProductCategoryModal />}

      {isModalOpenTableEntities && (
        <TableEntitiesModal
          {...entityModal === 'trademarks' ? {
            handleSend: handleSelectTrademarks,
            titleModal: 'Select trademarks',
            entities: 'trademarks',
            showTableExtraFilter: false,
            columns: columnsTrademarks,
          } : {}}
          {...entityModal === 'industrial' ? {
            handleSend: handleSelectIndustrial,
            titleModal: 'Select industrial',
            entities: 'industrial',
            showTableExtraFilter: false,
            columns: columnsTrademarks,
          } : {}}
          {...entityModal === 'inventions' ? {
            handleSend: handleSelectInventions,
            titleModal: 'Select inventions',
            entities: 'inventions',
            showTableExtraFilter: false,
            columns: columnsTrademarks,
          } : {}}
          {...entityModal === 'utility' ? {
            handleSend: handleSelectUtility,
            titleModal: 'Select utility models',
            entities: 'utility',
            showTableExtraFilter: false,
            columns: columnsTrademarks,
          } : {}}
          {...entityModal === 'documents' ? {
            handleSend: handleSelectDocuments,
          } : {}}
        />
      )}

      <Form onSubmit={handleSubmit(handleSubmits)}>
        <Card mb={6}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              {t('Basic information')}
            </Typography>

            <Grid container spacing={6}>
              <Grid item xs={12} md={6}>
                <Field
                  id="name"
                  name="name"
                  label={t('Title')}
                  variant="standard"
                  margin="normal"
                  fullWidth
                  my={2}
                  component={renderTextField}
                  disabled={!accessEdit}
                />

                <FieldProductCategory
                  name="productCategory"
                  getAutocompleteLists={getAutocompleteLists}
                  formName={formName}
                  handleCreateNew={handleCreateNew}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                  propsField={{
                    disabled: !accessEdit,
                  }}
                />

                <FieldJurisdiction
                  name="jurisdictions"
                  label="Jurisdictions"
                  getAutocompleteLists={getAutocompleteLists}
                  formName={formName}
                  propsField={{
                    multiple: true,
                    disabled: !accessEdit,
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card mb={6}>
          <CardContent>
            <Typography variant="h6" paragraph>
              {t('Trade marks')}
            </Typography>

            {accessEdit && (
              <Box mt={4}>
                <ButtonGroup color="primary" aria-label="contained primary button group">
                  {accessTrademarksListGet && (
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() => handleAddEntity('trademarks')}
                    >
                      {t('ADD')}
                      <AddIcon />
                    </Button>
                  )}
                  {!isEmpty(selectionTrademarks) && (
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleDeleteTrademarks}
                    >
                      {t('DELETE SELECTED')}
                    </Button>
                  )}
                </ButtonGroup>
              </Box>
            )}
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <DxTable
                  name="TrademarksProduct"
                  columns={columnsTrademarks}
                  rows={listTrademarks}
                  // tableColumnExtensions={tableColumnExtensionsCosts}
                  isLoading={false}
                  disablePaging
                  {...accessEdit ? {
                    selection: selectionTrademarks,
                    onSelectionChange: setSelectionTrademarks,
                  } : {}}
                  disableHiddenColumn
                  disableColumnOrder
                  disableColumnWidth
                  disableExport
                  disableMenu
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card mb={6}>
          <CardContent>
            <Typography variant="h6" paragraph>
              {t('Industrial designs')}
            </Typography>

            {accessEdit && (
              <Box mt={4}>
                <ButtonGroup color="primary" aria-label="contained primary button group">
                  {accessIndustrialListGet && (
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() => handleAddEntity('industrial')}
                    >
                      {t('ADD')}
                      <AddIcon />
                    </Button>
                  )}
                  {!isEmpty(selectionIndustrial) && (
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleDeleteIndustrial}
                    >
                      {t('DELETE SELECTED')}
                    </Button>
                  )}
                </ButtonGroup>
              </Box>
            )}
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <DxTable
                  name="IndustrialProduct"
                  columns={columnsTrademarks}
                  rows={listIndustrial}
                  // tableColumnExtensions={tableColumnExtensionsCosts}
                  isLoading={false}
                  disablePaging
                  {...accessEdit ? {
                    selection: selectionIndustrial,
                    onSelectionChange: setSelectionIndustrial,
                  } : {}}
                  disableHiddenColumn
                  disableColumnOrder
                  disableColumnWidth
                  disableExport
                  disableMenu
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card mb={6}>
          <CardContent>
            <Typography variant="h6" paragraph>
              {t('Utility models')}
            </Typography>

            {accessEdit && (
              <Box mt={4}>
                <ButtonGroup color="primary" aria-label="contained primary button group">
                  {accessUtilityListGet && (
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() => handleAddEntity('utility')}
                    >
                      {t('ADD')}
                      <AddIcon />
                    </Button>
                  )}
                  {!isEmpty(selectionUtility) && (
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleDeleteUtility}
                    >
                      {t('DELETE SELECTED')}
                    </Button>
                  )}
                </ButtonGroup>
              </Box>
            )}
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <DxTable
                  name="UtilityProduct"
                  columns={columnsTrademarks}
                  rows={listUtility}
                  // tableColumnExtensions={tableColumnExtensionsCosts}
                  isLoading={false}
                  disablePaging
                  {...accessEdit ? {
                    selection: selectionUtility,
                    onSelectionChange: setSelectionUtility,
                  } : {}}
                  disableHiddenColumn
                  disableColumnOrder
                  disableColumnWidth
                  disableExport
                  disableMenu
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card mb={6}>
          <CardContent>
            <Typography variant="h6" paragraph>
              {t('Inventions')}
            </Typography>

            {accessEdit && (
              <Box mt={4}>
                <ButtonGroup color="primary" aria-label="contained primary button group">
                  {accessInventionsListGet && (
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() => handleAddEntity('inventions')}
                    >
                      {t('ADD')}
                      <AddIcon />
                    </Button>
                  )}
                  {!isEmpty(selectionInventions) && (
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleDeleteInventions}
                    >
                      {t('DELETE SELECTED')}
                    </Button>
                  )}
                </ButtonGroup>
              </Box>
            )}
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <DxTable
                  name="InventionsProduct"
                  columns={columnsTrademarks}
                  rows={listInventions}
                  // tableColumnExtensions={tableColumnExtensionsCosts}
                  isLoading={false}
                  disablePaging
                  {...accessEdit ? {
                    selection: selectionInventions,
                    onSelectionChange: setSelectionInventions,
                  } : {}}
                  disableHiddenColumn
                  disableColumnOrder
                  disableColumnWidth
                  disableExport
                  disableMenu
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card mb={6}>
          <CardContent>
            <Typography variant="h6" paragraph>
              {t('Documents')}
            </Typography>

            {accessEdit && (
              <Box mt={4}>
                <ButtonGroup color="primary" aria-label="contained primary button group">
                  {accessDocumentsListGet && (
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() => handleAddEntity('documents')}
                    >
                      {t('ADD')}
                      <AddIcon />
                    </Button>
                  )}
                  {!isEmpty(selectionDocuments) && (
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleDeleteDocuments}
                    >
                      {t('DELETE SELECTED')}
                    </Button>
                  )}
                </ButtonGroup>
              </Box>
            )}
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <DxTable
                  name="DocumentsProduct"
                  columns={columnsDocuments}
                  rows={listDocuments}
                  // tableColumnExtensions={tableColumnExtensionsCosts}
                  isLoading={false}
                  disablePaging
                  {...accessEdit ? {
                    selection: selectionDocuments,
                    onSelectionChange: setSelectionDocuments,
                  } : {}}
                  disableHiddenColumn
                  disableColumnOrder
                  disableColumnWidth
                  disableExport
                  disableMenu
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card mb={6}>
          <CardContent>
            <Typography variant="h6" paragraph>
              {t('Calendar')}
            </Typography>
            <ProposalCalendar
              match={match}
              actionId="documents.id:typeRequest:="
              disableFilterId
              filtersAll={{
                ...paramsByFilterPendingAction,
                ...!isEmpty(paramsByFilterPendingAction) ? {
                  connector: map(keys(paramsByFilterPendingAction), (item, i) => (i > 0 ? 'or' : null)),
                } : {},
              }}
            />
          </CardContent>
        </Card>

        {accessEdit && (
          <Box mt={3}>
            <Button
              mr={2}
              variant="contained"
              color="primary"
              type="submit"
              disabled={pendingPutProductById || pendingPostProduct}
            >
              {pendingPutProductById || pendingPostProduct
                ? <CircularProgress size={25} color="inherit" />
                : t('SAVE')}
            </Button>
          </Box>
        )}
      </Form>
    </>
  ) : <Loader />;
}

Product.displayName = 'Product';

Product.propTypes = {
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  getProductById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingProductById: PropTypes.bool.isRequired,
  pendingPostProduct: PropTypes.bool.isRequired,
  pendingPutProductById: PropTypes.bool.isRequired,
  match: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  isModalOpen: PropTypes.bool.isRequired,
  isModalOpenTableEntities: PropTypes.bool.isRequired,
};

Product.defaultProps = {
  match: {},
};

function mapStateToProps(state) {
  return {
    getProductById: selectors.products.getProductById(state),
    pendingProductById: selectors.products.pendingProductById(state),
    pendingPostProduct: selectors.products.pendingPostProduct(state),
    pendingPutProductById: selectors.products.pendingPutProductById(state),
    isModalOpen: selectors.productCategory.isModalOpen(state),
    isModalOpenTableEntities: selectors.helpers.isModalOpenTableEntities(state),
  };
}

export default compose(
  reduxForm({
    form: 'Product',
  }),
  connect(mapStateToProps, null),
)(Product);
