// Core
import { apply, put } from 'redux-saga/effects';

// config
import { api } from '../../../../../config/api';
import { listEntityName } from '../../../../../config/listTablesName';

// actions
import actions from '../../action';

const entity = listEntityName.classes;

export function* callGetClassesWorker({ payload: params }) {
  const response = yield apply(api, api.trademarks.getClasses, [{ ...params, entity }]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    yield put(actions.setClasses(data.items));
  }
}
