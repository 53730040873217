// Core
import React, { useEffect, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';

// Lodash
import {
  includes, filter, map, isNil,
  isEmpty, find, forEach, uniq,
} from 'lodash';

// UI
import {
  Button,
} from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';

// parts
import DxTable from '../../../ui/Table/DxTable';
import ConfirmModal from '../../../components/ConfirmModal';
import TableExtraFilter from '../../../ui/Table/components/TableExtraFilter/TableExtraFilter';
import Send from '../../../components/Send/Send';
import Sign from '../../../components/Sign/Sign';
import DocViewerModal from '../../../components/DocViewerModal/DocViewerModal';

// actions
import helpersActions from '../../../engine/core/helpers/action';
import departmentDocumentsActions from '../../../engine/core/departmentDocuments/action';
import departmentDocumentsActionAsync from '../../../engine/core/departmentDocuments/saga/asyncAction';

// config
import selectors from '../../../engine/config/selectors';
import accessList from '../../../engine/config/accessList';
import { listTablesName, listEntityName } from '../../../engine/config/listTablesName';

// hooks
import { useAccessList } from '../../../ui/_hooks/useAccessList';

// routes
import { pageLinks } from '../../../routes';

const tableName = listTablesName.documents;
const entityName = listEntityName.documents;

function DepartmentDocumentsTable(props) {
  const {
    departmentDocumentsData, totalCount, pending,
    currentPage, filters, sorting, isModalOpen,
    pageSize, pendingDeleteDepartmentDocument, checkboxes, selectedDocuments,
    columnWidths, columnOrder, hiddenColumnNames, isModalOpenDocViewer,
    pendingPreviewFileById, fileSign, documentIdSend,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const [documentIdSend, setDocumentIdSend] = useState(null);
  // const [fileSign, setFileSign] = useState({});

  const accessPost = useAccessList(accessList.documents_post);

  useEffect(() => {
    if (!isModalOpen) dispatch(departmentDocumentsActions.setFileSign([]));
  }, [isModalOpen, dispatch]);

  useEffect(() => function cleanup() {
    dispatch(departmentDocumentsActions.setFileSign([]));
    dispatch(departmentDocumentsActions.setDocumentIdSend(''));
  }, [dispatch]);

  const columns = [
    { name: 'id', title: t('ID') },
    { name: 'title', title: t('Title'), customField: 'titleDepartmentDocuments' },
    { name: 'documentClassifier', title: t('Classification') },
    { name: 'type', title: t('Type') },
    { name: 'inNumber', title: t('In number') },
    { name: 'outNumber', title: t('Out number') },
    { name: 'documentDate', title: t('Date') },
    { name: 'receivingDate', title: t('Received At') },
    { name: 'nextAction', title: t('Action up to') },
    { name: 'trademarks', title: t('Trade marks'), customField: 'documents' },
    { name: 'industrial', title: t('Industrial designs'), customField: 'documents' },
    { name: 'inventions', title: t('inventions'), customField: 'documents' },
    { name: 'utility', title: t('Utility models'), customField: 'documents' },
  ];

  const tableColumnExtensions = [
    { columnName: 'id', width: 50 },
    { columnName: 'title', width: '15rem' },
    { columnName: 'type', width: '8rem' },
    { columnName: 'outNumber', width: '6rem' },
    { columnName: 'inNumber', width: '6rem' },
    { columnName: 'documentClassifier', width: '15rem' },
    { columnName: 'documentDate', width: '6rem' },
    { columnName: 'receivingDate', width: '6rem' },
    { columnName: 'nextAction', width: '6rem' },
    { columnName: 'trademarks', width: '9rem' },
    { columnName: 'industrial', width: '8rem' },
    { columnName: 'inventions', width: '8rem' },
    { columnName: 'utility', width: '8rem' },
  ];

  // Paging
  const onCurrentPage = (page) => dispatch(departmentDocumentsActions.setCurrentPage(page));
  const onPageSize = (size) => dispatch(departmentDocumentsActions.setPageSize(size));
  // Filtering
  const setFilters = (getFilters) => dispatch(departmentDocumentsActions.setFilters(getFilters));
  // Sorting
  const onSortingChange = (getSorting) => dispatch(
    departmentDocumentsActions.setSorting(getSorting),
  );
  const onColumnWidthsChange = (getColumnWidths) => dispatch(
    departmentDocumentsActions.setColumnWidths(getColumnWidths),
  );
  const onColumnOrderChange = (getColumnOrder) => dispatch(
    departmentDocumentsActions.setColumnOrder(getColumnOrder),
  );
  const onHiddenColumnNamesChange = (getHiddenColumnNames) => dispatch(
    departmentDocumentsActions.setHiddenColumnNames(getHiddenColumnNames),
  );

  const onSelectionChange = (checkboxSelect) => {
    const filterSelection = filter(selectedDocuments.toJS(),
      (item) => includes(checkboxSelect, item.id));
    const filterCheckbox = filter(checkboxSelect, (id) => !find(filterSelection, { id }));
    const newSelection = [];
    if (!isEmpty(filterCheckbox)) {
      forEach(filterCheckbox, (id) => {
        const findDoc = find(departmentDocumentsData.toJS().items, { id });
        if (!isEmpty(findDoc)) newSelection.push(findDoc);
      });
    }
    dispatch(departmentDocumentsActions.setSelectedDocuments([
      ...filterSelection,
      ...newSelection,
    ]));
    dispatch(helpersActions.setCheckbox({
      tableName,
      checkboxes: checkboxSelect,
    }));
  };

  const signDocuments = useMemo(() => {
    const filterSelectedDoc = filter(selectedDocuments.toJS(), (item) => !item.signs && item.file);
    return !isEmpty(fileSign.toJS()) ? fileSign.toJS() : map(filterSelectedDoc, (item) => (
      {
        id: item.id,
        files: [
          item?.file?.fileId,
          ...!isEmpty(item?.additionalFiles) ? item.additionalFiles : [],
        ],
      }
    ));
  }, [selectedDocuments, fileSign]);

  const uniqFileId = useMemo(() => {
    const uniqFile = [];
    forEach(signDocuments, (item) => (
      uniqFile.push(...item.files)
    ));
    return uniq(uniqFile);
  }, [signDocuments]);

  const sendDocId = useMemo(() => {
    const filterSelectedDoc = filter(selectedDocuments.toJS(), (item) => item.signs);
    return documentIdSend ? [documentIdSend] : map(filterSelectedDoc, 'id');
  }, [selectedDocuments, documentIdSend]);

  const handleGoToCreateDocumentPage = () => {
    dispatch(push(pageLinks.documents.departmentDocuments.new));
  };

  return (
    <>
      <TableExtraFilter
        filters={filters}
        filtersAction={setFilters}
      >
        {accessPost && (
          <Button variant="contained" color="primary" fullWidth onClick={handleGoToCreateDocumentPage}>
            {t('ADD')}
            <AddIcon />
          </Button>
        )}
      </TableExtraFilter>

      <DxTable
        name="DepartmentDocumentsTable"
        isLoading={pending || pendingPreviewFileById}
        columns={columns}
        rows={departmentDocumentsData.toJS().items}
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageSize={onPageSize}
        onCurrentPage={onCurrentPage}
        filtersAction={setFilters}
        // checkboxes ------------------------
        entityName={entityName}
        tableName={tableName}
        reloadTable={departmentDocumentsActions.setReload}
        selection={checkboxes.toJS()}
        onSelectionChange={onSelectionChange}
        // ------------------------
        filters={filters}
        sorting={sorting}
        onSortingChange={onSortingChange}
        isModalOpen={isModalOpen || isModalOpenDocViewer}
        tableColumnExtensions={!isEmpty(columnWidths) && columnWidths.length === columns.length
          ? columnWidths : tableColumnExtensions}
        onColumnWidthsChange={onColumnWidthsChange}
        columnOrder={!isEmpty(columnOrder) ? columnOrder : map(columns, 'name')}
        onColumnOrderChange={onColumnOrderChange}
        hiddenColumnNames={hiddenColumnNames}
        onHiddenColumnNamesChange={onHiddenColumnNamesChange}
        editComponentWidth={60}
        accessEdit={accessList.documents_put}
        accessDelete={accessList.documents_delete}
      />

      {isModalOpen && (
        <Sign
          type="documents"
          reloadDocumentsOut
          dataAllFiles={signDocuments}
          files={uniqFileId}
        />
      )}
      {!isNil(sendDocId) && (
        <Send
          visibleButton={false}
          type="documents"
          entityIds={sendDocId}
        />
      )}

      {isModalOpenDocViewer && <DocViewerModal />}

      <ConfirmModal
        buttonSendText="DELETE"
        pending={pendingDeleteDepartmentDocument}
        handleSend={() => {
          dispatch(departmentDocumentsActionAsync.deleteDepartmentDocumentAsync());
        }}
      />
    </>
  );
}

DepartmentDocumentsTable.displayName = 'DepartmentDocumentsTable';

DepartmentDocumentsTable.propTypes = {
  departmentDocumentsData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pending: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  pendingDeleteDepartmentDocument: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  sorting: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  columnWidths: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  columnOrder: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  hiddenColumnNames: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  pageSize: PropTypes.number.isRequired,
  totalCount: PropTypes.number,
  checkboxes: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  selectedDocuments: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  isModalOpenDocViewer: PropTypes.bool.isRequired,
  pendingPreviewFileById: PropTypes.bool.isRequired,
  fileSign: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  documentIdSend: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

DepartmentDocumentsTable.defaultProps = {
  totalCount: 0,
};

function mapStateToProps(state) {
  const departmentDocuments = selectors.departmentDocuments.pendingDeleteDepartmentDocument(state);

  return {
    isModalOpen: selectors.helpers.signIsModalOpen(state),
    departmentDocumentsData: selectors.departmentDocuments.departmentDocumentsData(state),
    pending: selectors.departmentDocuments.pending(state),
    pendingDeleteDepartmentDocument: departmentDocuments,
    selectedDocuments: selectors.departmentDocuments.selectedDocuments(state),
    fileSign: selectors.departmentDocuments.fileSign(state),
    documentIdSend: selectors.departmentDocuments.documentIdSend(state),

    // Paging
    totalCount: selectors.departmentDocuments.totalCount(state),
    currentPage: selectors.departmentDocuments.currentPage(state),
    pageSize: selectors.departmentDocuments.pageSize(state),

    // Filtering
    filters: selectors.departmentDocuments.filters(state),

    // Sorting
    sorting: selectors.departmentDocuments.sorting(state),

    // ColumnWidths
    columnWidths: selectors.departmentDocuments.columnWidths(state),
    columnOrder: selectors.departmentDocuments.columnOrder(state),
    hiddenColumnNames: selectors.departmentDocuments.hiddenColumnNames(state),

    checkboxes: selectors.helpers.getCheckboxes(state, tableName),
    isModalOpenDocViewer: selectors.helpers.isModalOpenDocViewer(state),
    pendingPreviewFileById: selectors.helpers.pendingPreviewFileById(state),
  };
}

function mapDispatchToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(DepartmentDocumentsTable);
