// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import types from './types';

const actions = Object.freeze({
  setEmailMessagesData(data) {
    return actionCreator(types.SET_EMAIL_MESSAGES_DATA, data);
  },
  setCurrentPage(currentPage) {
    return actionCreator(types.SET_EMAIL_MESSAGES_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return actionCreator(types.SET_EMAIL_MESSAGES_FILTERS, filters);
  },
  setPageSize(pageSize) {
    return actionCreator(types.SET_EMAIL_MESSAGES_PAGE_SIZE, pageSize);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_EMAIL_MESSAGES_INITIAL_STATE, initialState);
  },
  setReload(reload) {
    return actionCreator(types.SET_EMAIL_MESSAGES_RELOAD, reload);
  },
  setSorting(data) {
    return actionCreator(types.SET_EMAIL_MESSAGES_SORTING, data);
  },
  setColumnWidths(data) {
    return actionCreator(types.SET_EMAIL_MESSAGES_COLUMN_WIDTHS, data);
  },
  setColumnOrder(data) {
    return actionCreator(types.SET_EMAIL_MESSAGES_COLUMN_ORDER, data);
  },
  setHiddenColumnNames(data) {
    return actionCreator(types.SET_EMAIL_MESSAGES_HIDDEN_COLUMN_NAMES, data);
  },
  setIsModalOpenMessage(data) {
    return actionCreator(types.SET_EMAIL_MESSAGES_IS_MODAL_OPEN, data);
  },
  setThreadsMessages(data) {
    return actionCreator(types.SET_EMAIL_THREADS_MESSAGES, data);
  },
  mergeThreadsMessages(data) {
    return actionCreator(types.MERGE_EMAIL_THREADS_MESSAGES, data);
  },
  setPendingThreadsMessages(data) {
    return actionCreator(types.SET_PENDING_EMAIL_THREADS_MESSAGES, data);
  },
  setTotalCountThreadsMessages(data) {
    return actionCreator(types.SET_EMAIL_TOTAL_COUNT_THREADS_MESSAGES, data);
  },
  setNextPageThreadsMessages(data) {
    return actionCreator(types.SET_EMAIL_NEXT_PAGE_THREADS_MESSAGES, data);
  },

  setPendingPostEmailSend(data) {
    return actionCreator(types.SET_PENDING_POST_SEND_EMAIL, data);
  },
  setEmailPreviewText(data) {
    return actionCreator(types.SET_PREVIEW_TEXT_EMAIL, data);
  },
  setPendingPostEmailPreview(data) {
    return actionCreator(types.SET_PENDING_POST_PREVIEW_EMAIL, data);
  },
  setPendingDownloadAttachment(data) {
    return actionCreator(types.SET_PENDING_DOWNLOAD_ATTACHMENT, data);
  },
  setIsModalOpenLinkEntity(data) {
    return actionCreator(types.SET_IS_MODAL_OPEN_LINK_ENTITY, data);
  },
  setSelectedEmail(data) {
    return actionCreator(types.SET_SELECTED_EMAIL, data);
  },
  setEmailProvider(data) {
    return actionCreator(types.SET_EMAIL_PROVIDER, data);
  },
  setPendingEmailProvider(data) {
    return actionCreator(types.SET_PENDING_EMAIL_PROVIDER, data);
  },

  setListSignatures(data) {
    return actionCreator(types.SET_EMAIL_SIGNATURES_LIST, data);
  },
  setIsModalOpenSignature(data) {
    return actionCreator(types.SET_EMAIL_SIGNATURES_IS_MODAL_OPEN, data);
  },
  setSignatureData(data) {
    return actionCreator(types.SET_EMAIL_SIGNATURES_DATA, data);
  },
  setPendingSignature(data) {
    return actionCreator(types.SET_EMAIL_SIGNATURES_PENDING, data);
  },
  setPendingPostSignature(data) {
    return actionCreator(types.SET_EMAIL_SIGNATURES_PENDING_POST, data);
  },
  setPendingPutSignature(data) {
    return actionCreator(types.SET_EMAIL_SIGNATURES_PENDING_PUT, data);
  },
  setPendingByIdSignature(data) {
    return actionCreator(types.SET_EMAIL_SIGNATURES_PENDING_BY_ID, data);
  },
  setPendingDeleteSignature(data) {
    return actionCreator(types.SET_EMAIL_SIGNATURES_PENDING_DELETE, data);
  },

  setEmails(data) {
    return actionCreator(types.SET_EMAIL_LIST, data);
  },
  setIsModalOpenEmail(data) {
    return actionCreator(types.SET_EMAIL_IS_MODAL_OPEN, data);
  },
  setEmailData(data) {
    return actionCreator(types.SET_EMAIL_DATA, data);
  },
  setPendingEmails(data) {
    return actionCreator(types.SET_EMAIL_PENDING, data);
  },
  setPendingPostEmail(data) {
    return actionCreator(types.SET_EMAIL_PENDING_POST, data);
  },
  setPendingPutEmail(data) {
    return actionCreator(types.SET_EMAIL_PENDING_PUT, data);
  },
  setPendingByIdEmail(data) {
    return actionCreator(types.SET_EMAIL_PENDING_BY_ID, data);
  },
  setPendingDeleteEmail(data) {
    return actionCreator(types.SET_EMAIL_PENDING_DELETE, data);
  },

  setEmailLetters(data) {
    return actionCreator(types.SET_EMAIL_LETTERS_LIST, data);
  },
  setEmailLettersFilters(data) {
    return actionCreator(types.SET_EMAIL_LETTERS_FILTERS, data);
  },
  setEmailLettersCurrentPage(currentPage) {
    return actionCreator(types.SET_EMAIL_LETTERS_CURRENT_PAGE, currentPage);
  },
  setEmailLettersTotalCount(filters) {
    return actionCreator(types.SET_EMAIL_LETTERS_TOTAL_COUNT, filters);
  },
  setEmailLettersPageSize(pageSize) {
    return actionCreator(types.SET_EMAIL_LETTERS_PAGE_SIZE, pageSize);
  },
  setEmailLettersReload(reload) {
    return actionCreator(types.SET_EMAIL_LETTERS_RELOAD, reload);
  },
  setIsModalOpenLetter(data) {
    return actionCreator(types.SET_EMAIL_LETTERS_IS_MODAL_OPEN, data);
  },
  setLetterData(data) {
    return actionCreator(types.SET_EMAIL_LETTERS_DATA, data);
  },
  setPendingLetters(data) {
    return actionCreator(types.SET_EMAIL_LETTERS_PENDING, data);
  },
  setPendingPostLetter(data) {
    return actionCreator(types.SET_EMAIL_LETTERS_PENDING_POST, data);
  },
  setPendingByIdLetter(data) {
    return actionCreator(types.SET_EMAIL_LETTERS_PENDING_BY_ID, data);
  },
  setPendingDeleteLetter(data) {
    return actionCreator(types.SET_EMAIL_LETTERS_PENDING_DELETE, data);
  },
});

export default actions;
