// Core
import { all, call, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetPaymentReceiversWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callPostPaymentReceiversWorker,
  callGetPaymentReceiversByIdWorker,
  callPutPaymentReceiversByIdWorker,
  callDeletePaymentReceiversWorker,
} from './workers';
import types from '../types';

function* watchGetPaymentReceivers() {
  yield takeEvery(asyncTypes.GET_PAYMENT_RECEIVERS_ASYNC, callGetPaymentReceiversWorker);
}
function* watchGetInitialState() {
  yield takeEvery(
    asyncTypes.GET_PAYMENT_RECEIVERS_INITIAL_STATE_ASYNC,
    callGetInitialStateWorker,
  );
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_PAYMENT_RECEIVERS_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_PAYMENT_RECEIVERS_PAGE_SIZE, callSetToLocalDataWorker);
}

function* watchPostPaymentReceivers() {
  yield takeEvery(asyncTypes.POST_PAYMENT_RECEIVERS_ASYNC, callPostPaymentReceiversWorker);
}

function* watchSetFilters() {
  yield takeEvery(types.SET_PAYMENT_RECEIVERS_FILTER, callSetToLocalDataWorker);
}

function* watchSetSorting() {
  yield takeEvery(types.SET_PAYMENT_RECEIVERS_SORTING, callSetToLocalDataWorker);
}
function* watchSetColumnWidths() {
  yield takeEvery(types.SET_PAYMENT_RECEIVERS_COLUMN_WIDTHS, callSetToLocalDataWorker);
}
function* watchSetColumnOrder() {
  yield takeEvery(types.SET_PAYMENT_RECEIVERS_COLUMN_ORDER, callSetToLocalDataWorker);
}
function* watchSetHiddenColumnNames() {
  yield takeEvery(types.SET_PAYMENT_RECEIVERS_HIDDEN_COLUMN_NAMES, callSetToLocalDataWorker);
}

function* watchDeleteDocumentType() {
  yield takeEvery(asyncTypes.DELETE_PAYMENT_RECEIVERS, callDeletePaymentReceiversWorker);
}

function* watchGetPaymentReceiversById() {
  yield takeEvery(
    asyncTypes.GET_PAYMENT_RECEIVERS_BY_ID_ASYNC,
    callGetPaymentReceiversByIdWorker,
  );
}

function* watchPutPaymentReceiversById() {
  yield takeEvery(
    asyncTypes.PUT_PAYMENT_RECEIVERS_BY_ID_ASYNC,
    callPutPaymentReceiversByIdWorker,
  );
}

export function* watchersPaymentReceiversTable() {
  yield all([
    call(watchGetPaymentReceivers),
    call(watchSetCurrentPage),
    call(watchSetPageSize),
    call(watchGetInitialState),
    call(watchDeleteDocumentType),
    call(watchPostPaymentReceivers),
    call(watchGetPaymentReceiversById),
    call(watchPutPaymentReceiversById),
    call(watchSetFilters),
    call(watchSetSorting),
    call(watchSetColumnWidths),
    call(watchSetColumnOrder),
    call(watchSetHiddenColumnNames),
  ]);
}
