/* eslint-disable */
// Core
import React, { memo, useEffect } from 'react';
import { change, resetSection } from 'redux-form/immutable';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { List, Map } from 'immutable';
import { withStyles } from '@material-ui/core/styles';

// lodash
import {
  findIndex, isEqual, isEmpty, filter, isNull, map,
} from 'lodash';

// UI
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import { TextField, CircularProgress, Checkbox } from '@material-ui/core';

// Icons
import {
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
} from '@material-ui/icons';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Uppercase = styled.span`
  text-transform: uppercase;
  margin-left: 3px;
`;

const Autocomplete = withStyles(() => ({
  popper: {
    maxWidth: '500px',
    minWidth: '150px',
    width: 'fit-content!important'
  },
}))(MuiAutocomplete);

function AutocompleteJurisdiction(props) {
  const {
    multiple, label, options: getOptions, fieldValue,
    loading, getSelectOption, input, margin, defaultValue, handleChange,
    meta: {touched, invalid, error},

    handleOnFocus, handleInputChange, meta, changeToStore,
    disableLabel, disabled,
  } = props;

  const dispatch = useDispatch();
  const options = filter(getOptions, (option) => option.name || option.id);

  const [inputValue, setInputValue] = React.useState('');
  const [value, setValue] = React.useState(multiple ? [] : null);
  const [isSetDefaultValue, setIsSetDefaultValue] = React.useState(false);
  const timerId = React.useRef(0);
  const handleOnInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    if (event?.type === 'change') {
      if (timerId.current) {
        clearTimeout(timerId.current);
      }
    }

    if (changeToStore) {
      dispatch(change(meta.form, `${input.name}Input`, newInputValue));
    }

    if (handleInputChange) {
      handleInputChange(newInputValue);
    }
  };
  const handleOnChange = (event, newInputValue) => {
    setValue(newInputValue);
    getSelectOption(newInputValue);
    input.onChange(newInputValue);
    if (changeToStore) {
      dispatch(resetSection(meta.form, `${input.name}Input`));
    }
    handleChange(event, newInputValue);
  };
  useEffect(() => {
    if (input.value === '') {
      setValue(multiple ? [] : null);
      setIsSetDefaultValue(false);
    }
    if (!isSetDefaultValue) {
      if (List.isList(input.value) || Map.isMap(input.value) ) {
        if (!isEmpty(options)) {
          let currentOptions = multiple ? [] : null;
          if (multiple) {
            currentOptions = map(input.value.toJS(), (option) => {
              return find(options, option)
            });
          } else {
            currentOptions = options[findIndex(options, input.value.toJS())];
          }
          setValue(currentOptions);
          setIsSetDefaultValue(true);
        } else {
          setValue(input.value.toJS());
          setIsSetDefaultValue(true);
        }
      }else if(!isEmpty(input.value)) {
        setValue(input.value);
        setIsSetDefaultValue(true);
      }else if(!isEmpty(defaultValue)) {
        setValue(defaultValue);
        setIsSetDefaultValue(true);
      }
    }
  }, [input.value, defaultValue]);
  const handleGetOptionSelected = (option, value) => {
    return   (isEqual(value, option));
  };
  const handlerGetOptionLabel = (option) => `${option?.name} (${option?.code})`;

  return (
    <Autocomplete
      options={options}
      value={isNull(fieldValue) ? fieldValue : fieldValue || value}
      multiple={multiple}
      disableCloseOnSelect={multiple}
      getOptionLabel={handlerGetOptionLabel}
      inputValue={inputValue}
      getOptionSelected={handleGetOptionSelected}
      loading={loading}
      onChange={handleOnChange}
      onInputChange={handleOnInputChange}
      onFocus={handleOnFocus}
      disabled={disabled}
      fullWidth
      renderOption={(option, {selected}) => (multiple ?
          (<>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{marginRight: 8}}
              checked={selected}
            />
              {option.name}
              <Uppercase>{`(${option.code})`}</Uppercase>
          </>) : (
            <>
              {option.icon && (
                <img
                  style={{ height: 20, marginRight: 5 }}
                  src={option.icon}
                  alt="Image"
                />
              )}
              {option.name}
              <Uppercase>{`(${option.code})`}</Uppercase>
            </>
          )
      )
      }
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            variant="standard"
            {...!disableLabel ? {
              label,
            } : {}}
            placeholder={label}
            margin={margin}
            autoComplete="off"
            error={touched && invalid}
            helperText={touched && error}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )
      }}
    />
  );
}

AutocompleteJurisdiction.defaultProps = {
  multiple: false,
  loading: false,
  isGroupBy: false,
  disableLabel: false,
  label: '',
  options: [],
  input: {
    onChange: () => {
    }
  },
  meta: {
    touched: false,
    invalid: false,
    error: false,
  },
  handleChange: () => {},
  getSelectOption: () => {},
  handleOnFocus: () => {},
  handleInputChange: () => {},
  disabled: false,
};

export default memo(AutocompleteJurisdiction)

