// Core
import { call, put } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';
import { setErrorMessage, setSuccessMessage } from '../../../../../../../ui/_helpers/setNotificationMessage';

// config
import { api } from '../../../../../../config/api';
import { messageRequestSuccess } from '../../../../../../config/globalConfig';

// actions
import actions from '../../action';

export function* callPostTariffWorker({ payload }) {
  const { setGotToPageTariffs, setPendingPost } = actions;

  yield put(setPendingPost(true));
  const response = yield call(api.prices.postTariff, { ...payload });

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;

    yield put(setPendingPost(false));

    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      yield put(setGotToPageTariffs(true));
      yield put(setSuccessMessage(messageRequestSuccess, response.status.toString()));
    }
  }
}
