// Core
import { apply, put } from 'redux-saga/effects';

// config
import { api } from '../../../../config/api';
// import { listEntityName } from '../../../../config/listTablesName';

// actions
import actions from '../../action';

// const entity = listEntityName.permissionsGroup;

export function* callGetPermissionsWorker({ payload: params }) {
  yield put(actions.setPermissionsData({ pending: true }));
  const response = yield apply(api, api.permissionsGroups.getPermissions, [params]);

  let dataInit = {
    items: [],
    totalCount: 0,
    pending: false,
  };

  if (response && response.status >= 200 && response.status < 400) {
    dataInit = Object.assign(dataInit, response.data);
  }

  yield put(actions.setPermissionsData(dataInit));
}
