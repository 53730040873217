/* eslint-disable */
// core
import React, { Component } from 'react';
import * as PropTypes from 'prop-types';

// ui
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

// Icons
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

// Parts
import DateRange from './DateRange';

class DataRangeModal extends Component {
  static propTypes = {
    children: PropTypes.node,
    open: PropTypes.bool,
    title: PropTypes.string,
    handleDataRangeToggle: PropTypes.func.isRequired,
    onDateRangeChange: PropTypes.func,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
  };

  static displayName = 'DataRangeModal';

  onDateRangeChange = (range) => {
    const { onDateRangeChange } = this.props;
    if (typeof onDateRangeChange === 'function') {
      onDateRangeChange(range);
    }
  };

  render() {
    const {
      children, open, title, startDate, endDate, handleDataRangeToggle,
    } = this.props;
    return (
      <Dialog open={open} onClose={handleDataRangeToggle}>
        {title && (
          <DialogTitle disableTypography>
            <Typography variant="h6">{title}</Typography>
            <IconButton aria-label="Close" onClick={handleDataRangeToggle}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
        )}
        <DateRange
          onChange={this.onDateRangeChange}
          startDate={startDate}
          endDate={endDate}
        />
        {children}
      </Dialog>
    );
  }
}

export default DataRangeModal;
