/* eslint-disable */
// Core
import React, {
  useState, useMemo, useEffect, useCallback, memo,
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  Field, Form, reduxForm, change,
} from 'redux-form/lib/immutable';
import { compose } from 'recompose';
import { connect, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';

// Lodash
import isEqual from 'lodash/isEqual';
import values from 'lodash/values';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import isUndefined from 'lodash/isUndefined';
import forEach from 'lodash/forEach';
import map from 'lodash/map';

// UI
import {
  Paper, Collapse, Card as MuiCard,
  CardContent, ButtonGroup as MuiButtonGroup,
  Grid, Button,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';

// helpers
import { formName } from './form';

// config
import selectors from '../../../../engine/config/selectors';

// Parts
import RenderTextField from '../../../Form/RenderTextField';
import Select from '../../../Form/Select';
import ReduxDateRange from '../../../DateRange/ReduxDateRange';
import AutocompleteField from '../../../Form/AutocompleteField';

// actions
import employeesActions from '../../../../engine/core/company/employees/action';
import helpersActions from '../../../../engine/core/helpers/action';
import applicantsActions from '../../../../engine/core/applicants/action';

// Styled
const Card = styled(MuiCard)(spacing);
const ButtonGroup = styled(MuiButtonGroup)`
  margin-bottom: ${(props) => props.theme.spacing(4)}px;
  margin-top: ${(props) => props.theme.spacing(2)}px;
`;
const Wrapper = styled.div`
  margin-bottom: ${(props) => props.theme.spacing(4)}px;
`;

function StaticTableExtraFilter(props) {
  const {
    handleSubmit, fields,
    filtersAction, resetFilters, reset, filters,
    handleGetAsyncData, openForm,
    children, getFormValues, hiddenControls,
    submitFieldChange, handleOnFocus,

    pendingClientsData, pendingAClientsAutocomplete,
    pendingEmployeesData, pendingApplicantsData,
    pendingEmployeesAutocomplete, pendingApplicantsAutocomplete,
    pendingAutocompleteGroup,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [hasField, setHasField] = useState(false);

  const onFocusAuto = (name) => {
    if (handleOnFocus) {
      handleOnFocus([name]);
    }
  };

  useEffect(() => {
    if (!isEmpty(filters)) {
      forEach(filters, (item) => dispatch(change(formName, item.columnName, item.value)));
    }
  }, [filters, dispatch]);

  useEffect(() => function cleanup() {
    dispatch(employeesActions.setAutocompleteOptions({ isAttorney: [] }));
    dispatch(helpersActions.setAutocompleteGroupOptions({ items: [] }));
    dispatch(applicantsActions.setApplicantsList([]));
  }, [dispatch]);

  const handleResetFilters = () => {
    reset();
    resetFilters();
    setChecked(false);
  };

  const isEmptyFilters = useMemo(() => (
    isEmpty(filter(filters, (currentFilter) => !currentFilter.always))
  ), [filters]);

  // сбор данных по полям, если применяется props "submitFieldChange" к полю
  const collectValuesToSubmit = useCallback((name, value) => {
    filtersAction([
      ...filter(filters, (item) => item.columnName !== name),
      ...value ? [{
        columnName: name,
        value,
      }] : [],
    ]);
  }, [
    filters, filtersAction,
  ]);

  const isEmptyFields = useMemo(() => (
    isEmpty(filter(values(getFormValues), (value) => !isEmpty(value)))
  ), [getFormValues]);
  // если есть фильтры в расширенном поиске - показать форму
  // также если есть props "openForm" - форма с фильтрами сразу откроется
  useEffect(() => {
    if ((!isEmptyFields && !checked && !hasField) || (openForm && !hasField)) {
      setHasField(true);
      setChecked((prev) => !prev);
    }
  }, [
    isEmptyFields, setChecked, openForm,
    checked, hasField, setHasField,
  ]);

  const getLoading = useCallback((name) => {
    switch (name) {
      case 'clients': {
        return pendingClientsData || pendingAClientsAutocomplete;
      }
      case 'attorneyId': {
        return pendingEmployeesAutocomplete;
      }
      case 'applicant': {
        return pendingApplicantsData || pendingApplicantsAutocomplete;
      }
      case 'users':
      case 'person': {
        return pendingEmployeesData || pendingAClientsAutocomplete;
      }
      case 'caseNumberGroup': {
        return pendingAutocompleteGroup;
      }
      default: {
        return false;
      }
    }
  }, [
    pendingClientsData, pendingAClientsAutocomplete,
    pendingEmployeesData, pendingApplicantsData,
    pendingEmployeesAutocomplete, pendingApplicantsAutocomplete,
    pendingAutocompleteGroup,
  ]);

  return (
    <Wrapper>
      {!hiddenControls && (
        <ButtonGroup variant="contained" color="primary" aria-label="extra filter buttons group">
          {children}

          <Button mr={2} variant="contained" color="primary" onClick={() => setChecked((prev) => !prev)}>
            {t('ADVANCED SEARCH')}
          </Button>
          <Button
            mr={2}
            variant="contained"
            color="primary"
            disabled={isEmptyFilters}
            onClick={handleResetFilters}
          >
            {t('RESET FILTERS')}
          </Button>
        </ButtonGroup>
      )}
      <Collapse in={checked}>
        <Card>
          <CardContent>
            <Paper>
              <Form onSubmit={handleSubmit(() => {})}>
                <Grid container spacing={6} alignItems="flex-end">

                  {map(fields, (field) => {
                    const {
                      name, type, hasAccess, options, requestName,
                    } = field;
                    if (!isUndefined(hasAccess) && !hasAccess) {
                      return;
                    }
                    switch (type) {
                      case 'input': {
                        if (field?.autocomplete) {
                          const [startLoading, setStartLoading] = useState(false);
                          const isGroupBy = Boolean(field.isGroupBy);
                          return (
                            <Grid key={name} item xs={12} md={3}>
                              <Field
                                multiple={Boolean(field.multiple)}
                                fullWidth
                                name={name}
                                component={AutocompleteField}
                                type="text"
                                label={field.label || name}
                                margin="normal"
                                isGroupBy={isGroupBy}
                                changeToStore={Boolean(field.changeToStore)}
                                options={options}
                                getAsyncData={handleGetAsyncData}
                                // getSelectOption={getSelectOption}
                                loading={startLoading ? getLoading(requestName) : false}
                                {...isEmpty(options) ? {
                                  handleOnFocus: () => {
                                    setStartLoading(true);
                                    onFocusAuto(requestName);
                                  },
                                } : {}}
                                {...submitFieldChange ? {
                                  handleChange: (event, value) => {
                                    collectValuesToSubmit(name, value);
                                  },
                                } : {}}
                              />
                            </Grid>
                          );
                        }
                        return (
                          <Grid key={name} item xs={12} md={3}>
                            <Field
                              name={name}
                              component={RenderTextField}
                              type="text"
                              label={t(name)}
                              margin="normal"
                              fullWidth
                              {...submitFieldChange ? {
                                onChange: (event) => {
                                  collectValuesToSubmit(name, event.target.value);
                                },
                              } : {}}
                            />
                          </Grid>
                        );
                      }
                      case 'select': {
                        return (
                          <Grid key={name} item xs={12} md={3}>
                            <Field
                              name={name}
                              labelId={name}
                              component={Select}
                              label={field.label || name}
                              items={field.items}
                              displayEmpty={field.displayEmpty ? field.displayEmpty : false}
                              margin="normal"
                              fullWidth
                              {...submitFieldChange ? {
                                onChange: (event) => {
                                  collectValuesToSubmit(name, event.target.value);
                                },
                              } : {}}
                            />
                          </Grid>
                        );
                      }
                      case 'date': {
                        return (
                          <Grid key={name} item xs={12} md={3}>
                            <Field
                              name={name}
                              component={ReduxDateRange}
                              type="text"
                              label={field.label || name}
                              margin="normal"
                              fullWidth
                              {...submitFieldChange ? {
                                onChange: (ranges) => {
                                  collectValuesToSubmit(name, ranges);
                                },
                              } : {}}
                            />
                          </Grid>
                        );
                      }
                      default: {
                        return (
                          <Grid key={name} item xs={12} md={3}>
                            <Field
                              name={name}
                              component={RenderTextField}
                              type="text"
                              label={t(name)}
                              margin="normal"
                              fullWidth
                              {...submitFieldChange ? {
                                onChange: (event) => {
                                  collectValuesToSubmit(name, event.target.value);
                                },
                              } : {}}
                            />
                          </Grid>
                        );
                      }
                    }
                  })}
                </Grid>
              </Form>
            </Paper>
          </CardContent>
        </Card>
      </Collapse>
    </Wrapper>
  );
}

StaticTableExtraFilter.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  getFormValues: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingClientsData: PropTypes.bool.isRequired,
  pendingAClientsAutocomplete: PropTypes.bool.isRequired,
  pendingEmployeesData: PropTypes.bool.isRequired,
  pendingEmployeesAutocomplete: PropTypes.bool.isRequired,
  pendingApplicantsData: PropTypes.bool.isRequired,
  pendingApplicantsAutocomplete: PropTypes.bool.isRequired,
  pendingAutocompleteGroup: PropTypes.bool.isRequired,
  openForm: PropTypes.bool,
  hiddenControls: PropTypes.bool,
  submitFieldChange: PropTypes.bool,
  filtersAction: PropTypes.func,
  resetFilters: PropTypes.func,
  handleOnFocus: PropTypes.func,
  handleGetAsyncData: PropTypes.func,
  fields: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  children: PropTypes.node,
};

StaticTableExtraFilter.defaultProps = {
  openForm: false,
  hiddenControls: false,
  submitFieldChange: false,
  filtersAction: () => {},
  resetFilters: () => {},
  handleOnFocus: () => {},
  handleGetAsyncData: () => {},
};

function mapStateToProps(state) {
  // const { fields, filters } = ownProps;
  // const params = {};
  // if (fields && filters) {
  //   fields.reduce((acc, { name }) => {
  //     const filter = find(filters, { columnName: name });
  //     if (!isEmpty(filter)) {
  //       acc[name] = filter.value;
  //     }
  //     return acc;
  //   }, params);
  // }

  return {
    getFormValues: selectors.form.getFormValues(state, formName),
    pendingClientsData: selectors.clientsTable.pending(state),
    pendingAClientsAutocomplete: selectors.clients.pendingAutocompleteOptions(state),
    pendingEmployeesData: selectors.employeesTable.pending(state),
    pendingEmployeesAutocomplete: selectors.employees.pendingAutocompleteOptions(state),
    pendingApplicantsData: selectors.applicants.pendingApplicants(state),
    pendingApplicantsAutocomplete: selectors.applicants.pendingApplicantsAutocomplete(state),
    pendingAutocompleteGroup: selectors.helpers.pendingAutocompleteGroup(state),
  };
}

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.fields, nextProps.fields)
    && isEqual(prevProps.filters, nextProps.filters)
    && isEqual(prevProps.getFormValues, nextProps.getFormValues)
    && isEqual(prevProps.pendingClientsData, nextProps.pendingClientsData)
    && isEqual(prevProps.pendingAClientsAutocomplete, nextProps.pendingAClientsAutocomplete)
    && isEqual(prevProps.pendingEmployeesData, nextProps.pendingEmployeesData)
    && isEqual(prevProps.pendingEmployeesAutocomplete, nextProps.pendingEmployeesAutocomplete)
    && isEqual(prevProps.pendingApplicantsData, nextProps.pendingApplicantsData)
    && isEqual(prevProps.pendingApplicantsAutocomplete, nextProps.pendingApplicantsAutocomplete)
    && isEqual(prevProps.pendingAutocompleteGroup, nextProps.pendingAutocompleteGroup)
    && isEqual(prevProps.openForm, nextProps.openForm)
    && isEqual(prevProps.submitFieldChange, nextProps.submitFieldChange)
    && isEqual(prevProps.hiddenControls, nextProps.hiddenControls);
}

export default compose(
  connect(mapStateToProps, null),
  reduxForm({
    form: formName,
  }),
)(memo(StaticTableExtraFilter, areEqual));
