// Core
import { apply, put } from 'redux-saga/effects';

// config
import { api } from '../../../../../config/api';
import { listEntityName } from '../../../../../config/listTablesName';

// actions
import actions from '../../action';
import helpersActions from '../../../../helpers/action';

const entity = listEntityName.trademarks;

export function* callGetProposalWorker({ payload: params }) {
  yield put(actions.setProposalData({ pending: true }));
  const response = yield apply(api, api.trademarks.getProposal, [{ ...params, entity }]);

  let data = {
    items: [],
    totalCount: 0,
    pending: false,
  };

  if (response && response.status >= 200 && response.status < 400) {
    data = Object.assign(data, response.data);
    yield put(helpersActions.setSuccessDetBase64(true));
  }
  yield put(actions.setProposalData(data));
  yield put(actions.setReload(false));
}
