// Core
import { call, put } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../../config/api';

// actions
import clientsActions from '../../action';
import clientsTableActions from '../../table/action';
import helpersActions from '../../../../helpers/action';

// helpers
import { setErrorMessage } from '../../../../../../ui/_helpers/setNotificationMessage';

export function* callPostMergeClientsWorker({ payload: data }) {
  const { setPendingPostMerge, setIsModalOpenMerge } = clientsActions;

  yield put(setPendingPostMerge(true));

  const response = yield call(api.company.postMergeClients, data);

  if (response && response.status >= 200 && response.status < 400) {
    if (has(response.data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      yield put(setIsModalOpenMerge(false));
      yield put(clientsTableActions.setReload(true));
      yield put(helpersActions.setCheckboxClear());
    }
  }

  yield put(setPendingPostMerge(false));
}
