const asyncTypes = Object.freeze({
  GET_CURRENCY_RATES_ASYNC: 'GET_CURRENCY_RATES_ASYNC',
  GET_CURRENCY_RATES_INITIAL_STATE_ASYNC: 'GET_CURRENCY_RATES_INITIAL_STATE_ASYNC',
  POST_CURRENCY_RATE_ASYNC: 'POST_CURRENCY_RATE_ASYNC',
  PUT_CURRENCY_RATE_BY_ID_ASYNC: 'PUT_CURRENCY_RATE_BY_ID_ASYNC',
  DELETE_CURRENCY_RATE_ASYNC: 'DELETE_CURRENCY_RATE_ASYNC',
  GET_CURRENCY_RATES_AUTOCOMPLETE_OPTIONS_ASYNC: 'GET_CURRENCY_RATES_AUTOCOMPLETE_OPTIONS_ASYNC',
});

export default asyncTypes;
