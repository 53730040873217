// Core
import React, { useEffect, memo, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';

// lodash
import {
  isNil, isEmpty, isEqual, map, filter,
  uniq, forEach, find, includes,
} from 'lodash';

// UI
import {
  Card as MuiCard, Typography,
  CardContent,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';

// parts
import DxTable from '../../../../../ui/Table/DxTable';
import Sign from '../../../../../components/Sign/Sign';
import ConfirmModal from '../../../../../components/ConfirmModal';
import DocViewerModal from '../../../../../components/DocViewerModal/DocViewerModal';

// hooks
import { useOutputDocument } from '../_hooks/useOutputDocument';

// actions
import actions from '../../../../../engine/core/tradeMarks/proposal/documents/out/action';
import helpersActions from '../../../../../engine/core/helpers/action';
import asyncActions from '../../../../../engine/core/tradeMarks/proposal/documents/out/saga/asyncAction';
import depDocActions from '../../../../../engine/core/departmentDocuments/action';

// parts
import Send from '../../../../../components/Send/Send';

// config
import selectors from '../../../../../engine/config/selectors';

// routes
import { listTablesName } from '../../../../../engine/config/listTablesName';
import accessList from '../../../../../engine/config/accessList';

// styles
const Card = styled(MuiCard)(spacing);

const tableName = listTablesName.trademarksDocumentsOut;

function OutputDocumentTable(props) {
  const {
    documentsData, match, totalCount, selectedDocuments, pendingPreviewFileById,
    pending, currentPage, filters, deleteData, isModalOpenDocViewer, fileSign,
    sorting, pageSize, name, isModalOpen, pendingDelete, checkboxes, documentIdSend,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { params } = match;
  useOutputDocument(params.hashId, name);

  const columnsTableOut = [
    { name: 'id', title: t('ID') },
    { name: 'title', title: t('Title'), customField: 'ProposalDocuments' },
    { name: 'documentClassifier', title: t('Classification') },
    { name: 'inNumber', title: t('In number') },
    { name: 'outNumber', title: t('Out number') },
    { name: 'documentDate', title: t('Date') },
    { name: 'receivingDate', title: t('Received At') },
    { name: 'nextAction', title: t('Action up to') },
  ];

  // Paging
  const onCurrentPage = (page) => dispatch(actions.setCurrentPage(page));
  const onPageSize = (size) => dispatch(actions.setPageSize(size));
  // Filtering
  const setFilters = (getFilters) => dispatch(actions.setFilters(getFilters));
  // Sorting
  const onSortingChange = (getSorting) => dispatch(
    actions.setSorting(getSorting),
  );

  const onSelectionChange = (checkboxSelect) => {
    const filterSelection = filter(selectedDocuments.toJS(),
      (item) => includes(checkboxSelect, item.id));
    const filterCheckbox = filter(checkboxSelect, (id) => !find(filterSelection, { id }));
    const newSelection = [];
    if (!isEmpty(filterCheckbox)) {
      forEach(filterCheckbox, (id) => {
        const findDoc = find(documentsData.toJS().items, { id });
        if (!isEmpty(findDoc)) newSelection.push(findDoc);
      });
    }
    dispatch(actions.setSelectedDocuments([
      ...filterSelection,
      ...newSelection,
    ]));
    dispatch(helpersActions.setCheckbox({
      tableName,
      checkboxes: checkboxSelect,
    }));
  };

  useEffect(() => {
    if (!isModalOpen) dispatch(depDocActions.setFileSign([]));
  }, [isModalOpen, dispatch]);

  useEffect(() => function cleanup() {
    dispatch(depDocActions.setFileSign([]));
    dispatch(depDocActions.setDocumentIdSend(''));
  }, [dispatch]);

  const signDocuments = useMemo(() => {
    const filterSelectedDoc = filter(selectedDocuments.toJS(), (item) => !item.signs && item.file);
    return !isEmpty(fileSign.toJS()) ? fileSign.toJS() : map(filterSelectedDoc, (item) => (
      {
        id: item.id,
        files: [
          item?.file?.fileId,
          ...!isEmpty(item?.additionalFiles) ? item.additionalFiles : [],
        ],
      }
    ));
  }, [selectedDocuments, fileSign]);

  const uniqFileId = useMemo(() => {
    const uniqFile = [];
    forEach(signDocuments, (item) => (
      uniqFile.push(...item.files)
    ));
    return uniq(uniqFile);
  }, [signDocuments]);

  const sendDocId = useMemo(() => {
    const filterSelectedDoc = filter(selectedDocuments.toJS(), (item) => item.signs);
    return documentIdSend ? [documentIdSend] : map(filterSelectedDoc, 'id');
  }, [selectedDocuments, documentIdSend]);

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h2" gutterBottom>
          {t('Output documents')}
        </Typography>
        <DxTable
          name="ProposalDocumentsTableOut"
          isLoading={pending || pendingPreviewFileById}
          columns={columnsTableOut}
          rows={documentsData.toJS().items}
          // Paging
          totalCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageSize={onPageSize}
          onCurrentPage={onCurrentPage}
          // Filters
          filtersAction={setFilters}
          filters={filters}
          // Sorting
          sorting={sorting}
          // checkboxes ------------------------
          tableName={tableName}
          reloadTable={actions.setReload}
          selection={checkboxes.toJS()}
          onSelectionChange={onSelectionChange}
          editComponentWidth={60}
          // ------------------------
          onSortingChange={onSortingChange}
          isModalOpen={isModalOpen || isModalOpenDocViewer}
          disableHiddenColumn
          disableColumnOrder
          disableColumnWidth
          disableExport
          accessEdit={accessList.documents_put}
          accessDelete={accessList.documents_delete}
        />

        {isModalOpenDocViewer && <DocViewerModal />}

        {isModalOpen && (
          <Sign
            type="documents"
            reloadDocumentsOut
            dataAllFiles={signDocuments}
            files={uniqFileId}
          />
        )}
        {!isEmpty(deleteData.toJS()) && (
          <ConfirmModal
            buttonSendText="REMOVE"
            description="Are you sure you want to remove document?"
            pending={pendingDelete}
            handleSend={() => {
              dispatch(asyncActions.postDeleteDocumentsOutAsync());
            }}
            handleCloseModal={() => dispatch(actions.setDeleteDocumentsDataOut({}))}
          />
        )}
        {!isNil(sendDocId) && (
          <Send
            visibleButton={false}
            type="documents"
            entityIds={sendDocId}
          />
        )}
      </CardContent>
    </Card>
  );
}

OutputDocumentTable.displayName = 'ProposalDocuments';

OutputDocumentTable.propTypes = {
  documentsData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  match: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  name: PropTypes.string,
  totalCount: PropTypes.number.isRequired,
  pending: PropTypes.bool.isRequired,
  pendingDelete: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  sorting: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  pageSize: PropTypes.number.isRequired,
  checkboxes: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  deleteData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  selectedDocuments: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  isModalOpenDocViewer: PropTypes.bool.isRequired,
  pendingPreviewFileById: PropTypes.bool.isRequired,
  documentIdSend: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  fileSign: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

OutputDocumentTable.defaultProps = {
  match: {},
  name: '',
};

function mapStateToProps(state) {
  return {
    isModalOpen: selectors.helpers.signIsModalOpen(state),
    documentsData: selectors.proposalDocumentsOut.proposalDocumentsOutData(state),
    pendingDelete: selectors.proposalDocumentsOut.pendingDelete(state),
    deleteData: selectors.proposalDocumentsOut.deleteData(state),
    pending: selectors.proposalDocumentsOut.pending(state),
    selectedDocuments: selectors.proposalDocumentsOut.selectedDocuments(state),
    // Paging
    totalCount: selectors.proposalDocumentsOut.totalCount(state),
    currentPage: selectors.proposalDocumentsOut.currentPage(state),
    pageSize: selectors.proposalDocumentsOut.pageSize(state),
    // Filtering
    filters: selectors.proposalDocumentsOut.filters(state),
    // Sorting
    sorting: selectors.proposalDocumentsOut.sorting(state),

    checkboxes: selectors.helpers.getCheckboxes(state, tableName),
    isModalOpenDocViewer: selectors.helpers.isModalOpenDocViewer(state),
    pendingPreviewFileById: selectors.helpers.pendingPreviewFileById(state),

    documentIdSend: selectors.departmentDocuments.documentIdSend(state),
    fileSign: selectors.departmentDocuments.fileSign(state),
  };
}

function mapDispatchToProps() {
  return {};
}

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.checkboxes, nextProps.checkboxes)
  && isEqual(prevProps.currentPage, nextProps.currentPage)
  && isEqual(prevProps.deleteData, nextProps.deleteData)
  && isEqual(prevProps.documentsData, nextProps.documentsData)
  && isEqual(prevProps.filters, nextProps.filters)
  && isEqual(prevProps.isModalOpen, nextProps.isModalOpen)
  && isEqual(prevProps.match, nextProps.match)
  && isEqual(prevProps.name, nextProps.name)
  && isEqual(prevProps.pageSize, nextProps.pageSize)
  && isEqual(prevProps.pending, nextProps.pending)
  && isEqual(prevProps.pendingDelete, nextProps.pendingDelete)
  && isEqual(prevProps.sorting, nextProps.sorting)
  && isEqual(prevProps.selectedDocuments, nextProps.selectedDocuments)
  && isEqual(prevProps.isModalOpenDocViewer, nextProps.isModalOpenDocViewer)
  && isEqual(prevProps.pendingPreviewFileById, nextProps.pendingPreviewFileById)
  && isEqual(prevProps.totalCount, nextProps.totalCount);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(memo(OutputDocumentTable, areEqual));
