import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
// Config
const baseUrl = process.env.REACT_APP_API;
const apiVersion = process.env.REACT_APP_API_VERSION;
const saveMissingTranslate = process.env.REACT_APP_SAVE_MISSING_TRANSLATE === 'true';
const i18nDebug = process.env.REACT_APP_I18N_DEBUG === 'true';
const path = require('path');

export const locale = Object.freeze({
  ua: 'ua',
  en: 'en',
});

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: locale.ua,
    preload: [locale.ua, locale.en],
    debug: i18nDebug,
    fallbackLng: locale.ua,
    saveMissing: saveMissingTranslate,
    keySeparator: false,
    react: {
      bindStore: false,
      bindI18n: 'languageChanged',
      wait: false,
      useSuspense: false,
    },
    backend: {
      loadPath: saveMissingTranslate
        ? path.join(__dirname, '../../build/locales/{{lng}}/{{ns}}.json')
        : path.join(__dirname, '/locales/{{lng}}/{{ns}}.json'),
      addPath: `${baseUrl}/api/${apiVersion}/translates`,
      jsonIndent: 2,
    },
  });

export default i18n;
