// Core
import { apply, put } from 'redux-saga/effects';

// lodash
import map from 'lodash/map';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';

// config
import { api } from '../../../../../config/api';

// helpers
import { fieldNameCosts } from '../../../../../_helpers/fieldNameCheckboxes';

// actions
import actions from '../../action';

export function* callTariffsTypesWorker({ payload: params }) {
  const {
    setTariffsTypes,
  } = actions;
  yield put(setTariffsTypes({
    pending: true,
  }));

  const filterCategory = (items, isCategory) => filter(items, (item) => (isCategory
    ? item.category
    : !item.category));
  const getCategoriesIds = (items) => [
    'root',
    ...map(filterCategory(items, true), (item) => item.category.id),
  ];
  const getChildren = (children) => map(children, (item) => ({
    id: `${item.id}`,
    name: `${item.code} - ${item.name}`,
    checkbox: {
      name: fieldNameCosts(item.id),
      label: item.code ? `${item.code} - ${item.name}` : item.name,
    },
  }));
  const groupByCategory = (items) => {
    const withCategory = filterCategory(items, true);
    const withoutCategory = filterCategory(items, false);

    return [
      ...!isEmpty(withCategory) ? map(groupBy(withCategory, 'category.id'), (value, key) => ({
        id: `category${key}`,
        name: value[0].category.name,
        children: getChildren(value),
      })) : [],
      ...!isEmpty(withoutCategory) ? getChildren(withoutCategory) : [],
    ];
  };
  const responseServices = yield apply(api, api.prices.getTariffs, [params]);

  if (responseServices && responseServices.status >= 200 && responseServices.status < 400) {
    const { data } = responseServices;
    yield put(setTariffsTypes({
      services: groupByCategory(data.items),
      // Собранные id по которым раскроется дерево компонента "TreeView" по умолчанию
      categoriesIdsServices: getCategoriesIds(data.items),
    }));
  }

  yield put(setTariffsTypes({
    pending: false,
  }));
}
