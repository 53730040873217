// Helpers
import actionCreator from '../../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const asyncActions = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_MKTU_TEMPLATES_ASYNC, params);
  },
  getMktuTemplateByIdWorker(id) {
    return actionCreator(asyncTypes.GET_MKTU_TEMPLATE_BY_ID_ASYNC, id);
  },
  getMktuTemplatePdfByIdWorker(id) {
    return actionCreator(asyncTypes.GET_MKTU_TEMPLATE_PDF_BY_ID_ASYNC, id);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_MKTU_TEMPLATES_INITIAL_STATE_ASYNC, path);
  },
  postMktuTemplatesAsync(mktuTemplate) {
    return actionCreator(asyncTypes.POST_MKTU_TEMPLATES_ASYNC, mktuTemplate);
  },
  putMktuTemplateByIdAsync(mktuTemplate) {
    return actionCreator(asyncTypes.PUT_MKTU_TEMPLATE_BY_ID_ASYNC, mktuTemplate);
  },
  deleteMktuTemplateAsync() {
    return actionCreator(asyncTypes.DELETE_MKTU_TEMPLATE_ASYNC);
  },
  getAutocompleteOptionsAsync(params) {
    return actionCreator(asyncTypes.GET_MKTU_TEMPLATES_AUTOCOMPLETE_OPTIONS_ASYNC, params);
  },
});

export default asyncActions;
