export default Object.freeze({
  SET_MADRID_CASE_DATA: 'SET_MADRID_CASE_DATA',
  SET_MADRID_CASE_CURRENT_PAGE: 'SET_MADRID_CASE_CURRENT_PAGE',
  SET_MADRID_CASE_PAGE_SIZE: 'SET_MADRID_CASE_PAGE_SIZE',
  SET_MADRID_CASE_FILTERS: 'SET_MADRID_CASE_FILTERS',
  SET_MADRID_CASE_SORTING: 'SET_MADRID_CASE_SORTING',
  SET_MADRID_CASE_INITIAL_STATE: 'SET_MADRID_CASE_INITIAL_STATE',
  SET_MADRID_CASE_RELOAD: 'SET_MADRID_CASE_RELOAD',
  SET_DELETE_MADRID_CASE: 'SET_DELETE_MADRID_CASE',
  SET_MADRID_CASE_BY_ID: 'SET_MADRID_CASE_BY_ID',
  SET_MADRID_CASE_DATA_BY_ID: 'SET_MADRID_CASE_DATA_BY_ID',
  SET_MADRID_CASE_MODAL_ADD_IS_OPEN: 'SET_MADRID_CASE_MODAL_ADD_IS_OPEN',
  SET_MADRID_CASE_COLUMN_WIDTHS: 'SET_MADRID_CASE_COLUMN_WIDTHS',
  SET_MADRID_CASE_COLUMN_ORDER: 'SET_MADRID_CASE_COLUMN_ORDER',
  SET_MADRID_CASE_HIDDEN_COLUMN_NAMES: 'SET_MADRID_CASE_HIDDEN_COLUMN_NAMES',
});
