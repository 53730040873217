// core
import React, {
  memo,
} from 'react';
import { spacing } from '@material-ui/system';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import { Field } from 'redux-form/immutable';

// lodash
import { isEqual, isEmpty } from 'lodash';

// UI
import {
  CardContent, Grid as MuiGrid,
  Typography as MuiTypography, Card as MuiCard,
  CircularProgress, ButtonGroup,
  Button as MuiButton,
} from '@material-ui/core';

// actions
import helpersActionAsync from '../../../../../engine/core/helpers/saga/asyncAction';

// config
import selectors from '../../../../../engine/config/selectors';

// parts
import RenderTextField from '../../../../../ui/Form/RenderTextField';
import DatePickers from '../../../../../ui/Form/DatePickers';
import ButtonSubmit from '../../../../../ui/Button/ButtonSubmit';

// styles
const Card = styled(MuiCard)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Button = styled(MuiButton)(spacing);

function CardMaintainingAndCost(props) {
  const {
    pending, formFields, entity, disabled,
    match, getProposalById, pendingPostRenewal,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { params } = match;

  return (
    <>
      {!isEmpty(params.hashId) && (getProposalById.toJS()?.status > 7
        || getProposalById.toJS()?.registrationNumber) && (
        <Card mb={6}>
          <CardContent>
            <Typography variant="h2" gutterBottom>
              {t('Maintaining effect')}
            </Typography>

            <Grid container spacing={4}>
              <Grid item xs={12} md={3}>
                <Field
                  name={formFields.yearPaid}
                  id={formFields.yearPaid}
                  label={t('Number of the paid year')}
                  margin="normal"
                  variant="standard"
                  my={1}
                  fullWidth
                  type="text"
                  component={RenderTextField}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Field
                  name={formFields.renewalDateFrom}
                  id={formFields.renewalDateFrom}
                  labelId={formFields.renewalDateFrom}
                  label={t('Maintaining effect from')}
                  margin="normal"
                  variant="standard"
                  my={1}
                  fullWidth
                  type="text"
                  formatDate="dd.MM.yyyy"
                  component={DatePickers}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Field
                  name={formFields.renewalDateTo}
                  id={formFields.renewalDateTo}
                  labelId={formFields.renewalDateTo}
                  label={t('Maintaining validity until')}
                  margin="normal"
                  variant="standard"
                  my={1}
                  fullWidth
                  type="text"
                  formatDate="dd.MM.yyyy"
                  component={DatePickers}
                  disabled={disabled}
                />
              </Grid>

              {!disabled && (
                <Grid item xs={12}>
                  <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                    <ButtonSubmit pending={pending} />
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => dispatch(helpersActionAsync.postCalcPatentsRenewalAsync({
                        [entity]: [params.hashId],
                      }))}
                    >
                      {pendingPostRenewal ? <CircularProgress size={25} color="inherit" /> : t('CALCULATE')}
                    </Button>
                  </ButtonGroup>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      )}

      {entity !== 'industrial' && (
        <Card mb={6}>
          <CardContent>
            <Typography variant="h2" gutterBottom>
              {t('Data for cost calculations')}
            </Typography>

            <Grid container spacing={4}>
              <Grid item xs={12} md={3}>
                <Field
                  name={formFields.claimNumber}
                  id={formFields.claimNumber}
                  label={t('Number of points of formula')}
                  margin="normal"
                  variant="standard"
                  my={1}
                  fullWidth
                  type="text"
                  component={RenderTextField}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Field
                  name={formFields.claimNumberIndependent}
                  id={formFields.claimNumberIndependent}
                  label={t('Number of independent formula points')}
                  margin="normal"
                  variant="standard"
                  my={1}
                  fullWidth
                  type="text"
                  component={RenderTextField}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Field
                  name={formFields.pagesNumber}
                  id={formFields.pagesNumber}
                  label={t('Number of pages of description and drawings')}
                  margin="normal"
                  variant="standard"
                  my={1}
                  fullWidth
                  type="text"
                  component={RenderTextField}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Field
                  name={formFields.pagesNumberPriority}
                  id={formFields.pagesNumberPriority}
                  label={t('Number of pages of the priority document')}
                  margin="normal"
                  variant="standard"
                  my={1}
                  fullWidth
                  type="text"
                  component={RenderTextField}
                  disabled={disabled}
                />
              </Grid>

              {!disabled && (
                <Grid item xs={12}>
                  <ButtonSubmit pending={pending} />
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      )}
    </>
  );
}
CardMaintainingAndCost.propTypes = {
  match: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pending: PropTypes.bool.isRequired,
  pendingPostRenewal: PropTypes.bool.isRequired,
  formFields: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  getProposalById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  entity: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

CardMaintainingAndCost.defaultProps = {
  disabled: false,
};

function mapStateToProps(state) {
  return {
    pendingPostRenewal: selectors.helpers.pendingPostRenewal(state),
  };
}

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.match, nextProps.match)
  && isEqual(prevProps.formFields, nextProps.formFields)
  && isEqual(prevProps.pendingPostRenewal, nextProps.pendingPostRenewal)
  && isEqual(prevProps.getProposalById, nextProps.getProposalById)
  && isEqual(prevProps.entity, nextProps.entity)
  && isEqual(prevProps.disabled, nextProps.disabled)
  && isEqual(prevProps.pending, nextProps.pending);
}

export default compose(
  connect(mapStateToProps, null),
)(memo(CardMaintainingAndCost, areEqual));
