// Core
import { call, put } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';
import isBoolean from 'lodash/isBoolean';

// config
import { api } from '../../../../config/api';

// init
import i18n from '../../../../init/i18n';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../ui/_helpers/setNotificationMessage';

// actions
import actions from '../../action';

export function* callPostSpezDocumentsWorker({ payload: params }) {
  const {
    setSpecialDocuments, setPendingSpecialDocuments,
    setIsModalOpen,
  } = actions;

  yield put(setPendingSpecialDocuments(true));

  const response = yield call(api.importObjects.postSpezDocuments, params);

  yield put(setPendingSpecialDocuments(false));

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
      return;
    }
    if (isBoolean(params.import) && !params.import) {
      yield put(setSpecialDocuments(data));
      yield put(setIsModalOpen(true));
      return;
    }
    yield put(setSuccessMessage(i18n.t('Successfully queued for processing')));
  }
}
