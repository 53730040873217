// Core

import { apply, put } from 'redux-saga/effects';
import { List } from 'immutable';

// lodash
import map from 'lodash/map';
import pickBy from 'lodash/pickBy';

// config
import { api } from '../../../../../config/api';
import { listEntityName } from '../../../../../config/listTablesName';

// actions
import actions from '../../action';
import calendarActions from '../../../action';

// helpers
import { getObjectReverseFormatDate } from '../../../../../_helpers/formatterDate';

const entity = listEntityName.pendingActions;

export function* callGetPendingActionsTableWorker({ payload: params }) {
  yield put(actions.setPendingActionsData({ pending: true }));

  const filterParams = pickBy(params, (value, key) => key !== 'isCard');

  const response = yield apply(api,
    api.pendingActions.getPendingActionsTable, [{ ...filterParams, entity }]);

  let data = {
    items: [],
    totalCount: 0,
    pending: false,
  };

  if (response && response.status >= 200 && response.status < 400) {
    data = Object.assign(data, response.data);

    if (params.isCard) {
      yield put(calendarActions.setPendingActions({
        data: List(map(data.items, (item) => {
          const {
            title, createdAt, finishAt,
            status, label, id,
          } = item;

          return {
            ...item,
            id,
            title,
            label,
            createdAt,
            ...status ? {
              status: `${status}`,
            } : {},
            ...getObjectReverseFormatDate(finishAt, 'start'),
            ...getObjectReverseFormatDate(finishAt, 'end'),
          };
        })),
      }));
      yield put(calendarActions.setReload(false));
    }
  }
  yield put(actions.setPendingActionsData(data));
  yield put(actions.setReload(false));
}
