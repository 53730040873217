// Core
import axios from 'axios';
// import { replace } from 'connected-react-router';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

// lodash
import get from 'lodash/get';
import has from 'lodash/has';

// Engines
// import { routersLink } from '../../routes';
import { store } from '../init/store';
import userActionAsync from '../core/user/saga/asyncAction';
// Helpers

import { setErrorMessage } from '../../ui/_helpers/setNotificationMessage';
import { convertJSObjectToSearchUrlParams, convertJSObjectToUrlParams } from '../../ui/_helpers/api';

export const baseUrl = process.env.REACT_APP_API;
export const apiVersion = process.env.REACT_APP_API_VERSION;
export const apiURL = {
  refreshTokenUrl: 'token/refresh',
  usersLogin: 'authentication',
};

export const authService = Object.freeze({
  userInfoKey: 'userInfo',
  setToken(token) {
    const userInfo = this.getUserInfo();
    userInfo.token = token;
    this.setUserInfo(userInfo);
  },
  getToken() {
    const userInfo = this.getUserInfo();
    return userInfo.token;
  },
  setRefreshToken(token) {
    const userInfo = this.getUserInfo();
    userInfo.refreshToken = token;
    this.setUserInfo(userInfo);
  },
  getRefreshToken() {
    const userInfo = this.getUserInfo();
    return userInfo.refreshToken;
  },
  getUserInfo() {
    const userInfoFromStore = localStorage.getItem(this.userInfoKey) || '{}';
    return JSON.parse(userInfoFromStore);
  },
  setUserInfo(userInfo = {}) {
    const userInfoToStorage = JSON.stringify(userInfo);
    localStorage.setItem(this.userInfoKey, userInfoToStorage);
  },
});

const instance = axios.create({
  baseURL: `${baseUrl}/api/${apiVersion}/`,
  headers: { 'Content-Type': 'application/json' },
  paramsSerializer: (paramsAll) => {
    let result = '';
    Object.keys(paramsAll).forEach((keyParam) => {
      result += `${keyParam}=${encodeURIComponent(paramsAll[keyParam])}&`;
    });
    return result.substr(0, result.length - 1);
  },
});
const instanceCors = axios.create({
  headers: { 'Content-Type': 'application/json' },
});

const refreshAuthLogic = (failedRequest) => instance.post(
  apiURL.refreshTokenUrl,
  { refreshToken: authService.getRefreshToken() }, { skipAuthRefresh: true },
).then((tokenRefreshResponse) => {
  const rememberMeSession = sessionStorage.getItem('rememberMe');
  if (rememberMeSession !== null) {
    const {
      data: { token, refreshToken },
    } = tokenRefreshResponse;
    if (token) {
      authService.setToken(token);
      authService.setRefreshToken(refreshToken);
      /* eslint-disable-next-line  */
      failedRequest.response.config.headers.Authorization = `Bearer ${token}`;
    }
  } else {
    const { data: { message, code } } = tokenRefreshResponse;
    store.dispatch(userActionAsync.logOutAsync(false));
    store.dispatch(setErrorMessage(message, code.toString()));
  }
});
createAuthRefreshInterceptor(instance, refreshAuthLogic, { skipWhileRefreshing: false });

const handlerError = (error) => {
  const errorResponse = error.response;
  if (errorResponse) {
    const { data, status, statusText } = errorResponse;
    if (status >= 400) {
      if (status === 401) {
        const currentURL = get(errorResponse.config, 'url');
        if (currentURL === apiURL.refreshTokenUrl) {
          store.dispatch(userActionAsync.logOutAsync(false));
          store.dispatch(setErrorMessage(data.error, status.toString()));
          return null;
        }
        if (
          currentURL === apiURL.refreshTokenUrl
          || currentURL === apiURL.usersLogin
        ) {
          store.dispatch(setErrorMessage(data.error, status.toString()));
          return error.response;
        }
      } else if (status === 404) {
        store.dispatch(setErrorMessage(data.title, status.toString()));
        // store.dispatch(replace(routersLink.errors.status404));
      } else if (status === 500) {
        store.dispatch(setErrorMessage(data.title, status.toString()));
        // store.dispatch(replace(routersLink.errors.status500));
      } else if (!has(data, 'error')) {
        store.dispatch(setErrorMessage({
          ...data,
          message: data?.title || statusText,
        }, status.toString()));
      } else {
        store.dispatch(setErrorMessage(data.error, status.toString()));
        return errorResponse;
      }
    }
  }
  return error.response;
};

instance.interceptors.response.use(
  (response) => (response),
  handlerError,
);

instance.interceptors.request.use((config) => {
  const extendedConfig = config;
  const token = authService.getToken();
  if (token) {
    extendedConfig.headers.Authorization = `Bearer ${token}`;
  }
  return extendedConfig;
});

export const api = Object.freeze({
  // Fetch
  uploadFile(file, onUploadProgress) {
    return instance.post('files', file, { onUploadProgress });
  },
  user: {
    signIn(userData) {
      return instance.post('authentication', userData, { skipAuthRefresh: true });
    },
    resetPassword(userData) {
      return instance.post('password-request', userData);
    },
    getUserInfo() {
      return instance.get('authorization');
    },
    logout(refreshToken) {
      return instance.get('logout', refreshToken);
    },
    refreshToken(userData) {
      return instance.post(apiURL.refreshTokenUrl, userData);
    },
    putUserLanguage({ hashId, language }) {
      return instance.put(`users/${hashId}`, { language });
    },
  },
  search: {
    postSearchAutocompleteGroup(data) {
      return instance.post('search', data);
    },
  },
  charges: {
    getCharges(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('charges', { params });
    },
    getChargesAutocomplete(args) {
      const params = convertJSObjectToSearchUrlParams(args);
      return instance.get('charges', { params });
    },
    deleteCharge(id) {
      return instance.delete(`charges/${id}`);
    },
    postCharge(data) {
      return instance.post('charges', data);
    },
    getChargeById(id) {
      return instance.get(`charges/${id}`);
    },
    putChargeById(data) {
      const { id } = data;
      return instance.put(`charges/${id}`, data);
    },
    postPaymentDetails(data) {
      return instance.post('charges/paymentDetails', data);
    },
  },
  receiving: {
    getReceiving(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('indocs', { params });
    },
    deleteReceiving(data) {
      return instance.delete('indocs', { data });
    },
    postCheckDocuments(data) {
      return instance.post('checkDocuments', data);
    },
    getDownloadInDocs(params) {
      return instance.get('download/indocs', { params, responseType: 'blob' });
    },
  },
  departmentDocuments: {
    getDepartmentDocuments(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('documents', { params });
    },
    getDocumentsAutocomplete(args) {
      const params = convertJSObjectToSearchUrlParams(args);
      return instance.get('documents', { params });
    },
    deleteDepartmentDocument(id) {
      return instance.delete(`documents/${id}`);
    },
    postDepartmentDocument(data) {
      return instance.post('documents', data);
    },
    getDepartmentDocumentById(id) {
      return instance.get(`documents/${id}`);
    },
    putDepartmentDocumentById(data) {
      const { id } = data;
      return instance.put(`documents/${id}`, data);
    },
    getNextNumber(params) {
      return instance.get('documents/getNextNumber', { params });
    },
  },
  documentTemplates: {
    getDocumentTemplates(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('documentTemplate', { params });
    },
    getDocumentTemplatesAutocomplete(args) {
      const params = convertJSObjectToSearchUrlParams(args);
      return instance.get('documentTemplate', { params });
    },
    deleteDocumentTemplate(id) {
      return instance.delete(`documentTemplate/${id}`);
    },
    postDocumentTemplates(data) {
      return instance.post('documentTemplate', data);
    },
    getDocumentTemplatesById(id) {
      return instance.get(`documentTemplate/${id}`);
    },
    putDocumentTemplateById(data) {
      const { id } = data;
      return instance.put(`documentTemplate/${id}`, data);
    },
  },
  sending: {
    getSending(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('outdocs', { params });
    },
    postOutDocsSend(data) {
      const { id } = data;
      return instance.post(`outdocs/${id}/send`, data);
    },
    getDownloadOutDocs(params) {
      return instance.get('download/outdocs', { params, responseType: 'blob' });
    },
  },
  importObjects: {
    postImportObjects(data) {
      return instance.post('import-objects', data);
    },
    postSpezDocuments(params) {
      return instance.post('getSpezDocuments', params);
    },
    postImportExcel(data) {
      return instance.post('importExcel', data);
    },
  },
  export: {
    postExport(data) {
      return instance.post('export', data);
    },
  },
  settings: {
    getSettings(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('settings', { params });
    },
    putSettingById(data) {
      const { id } = data;
      return instance.put(`settings/${id}`, data);
    },
  },
  paymentReceivers: {
    getPaymentReceivers(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('paymentReceivers', { params });
    },
    postPaymentReceivers(data) {
      return instance.post('paymentReceivers', data);
    },
    getPaymentReceiversById(id) {
      return instance.get(`paymentReceivers/${id}`);
    },
    putPaymentReceiversById(data) {
      const { id } = data;
      return instance.put(`paymentReceivers/${id}`, data);
    },
    deletePaymentReceivers(id) {
      return instance.delete(`paymentReceivers/${id}`);
    },
  },
  paymentAllocations: {
    getPaymentAllocations(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('paymentAllocations', { params });
    },
    deletePaymentAllocations(id) {
      return instance.delete(`paymentAllocations/${id}`);
    },
  },
  pendingActions: {
    getPendingActionsTable(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('pending-actions', { params });
    },
    getPendingActionById(id) {
      return instance.get(`pending-actions/${id}`);
    },
    putPendingActionById(data) {
      const { id } = data;
      return instance.put(`pending-actions/${id}`, data);
    },
    patchPendingActionById(params) {
      const { id, ...data } = params;
      return instance.patch(`pending-actions/${id}`, data);
    },
    postPendingAction(data) {
      return instance.post('pending-actions', data);
    },
    deletePendingAction(id) {
      return instance.delete(`pending-actions/${id}`);
    },
  },
  systemMessage: {
    getSystemMessage(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('systemMessage', { params });
    },
    getSystemMessageById(id) {
      return instance.get(`systemMessage/${id}`);
    },
    patchSystemMessageById(params) {
      const { id, ...data } = params;
      return instance.patch(`systemMessage/${id}`, data);
    },
    postSystemMessage(data) {
      return instance.post('systemMessage', data);
    },
    deleteSystemMessage(id) {
      return instance.delete(`systemMessage/${id}`);
    },
  },
  company: {
    getKeys() {
      return instance.get('keys');
    },
    postKey(data) {
      return instance.post('keys', data);
    },
    deleteKey(id) {
      return instance.delete(`keys/${id}`);
    },
    getCsk() {
      return fetch('https://ca.informjust.ua/download/Soft/CAs.json')
        .then((response) => response.json())
        .catch(handlerError);
    },
    getDocuments(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('company/documents', { params });
    },
    deleteDocument(id) {
      return instance.delete(`company/documents/${id}`);
    },
    getDocumentsType() {
      return instance.get('documentTypes');
    },
    getCompanies(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('company', { params });
    },
    postCompany(data) {
      return instance.post('company', data);
    },
    getCompaniesAutocomplete(args) {
      const params = convertJSObjectToSearchUrlParams(args);
      return instance.get('company', { params });
    },
    getCompanyById(id) {
      return instance.get(`company/${id}`);
    },
    putCompanyById(data) {
      const { id } = data;
      return instance.put(`company/${id}`, data);
    },
    deleteCompany(id) {
      return instance.delete(`company/${id}`);
    },
    getClients(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('clients', { params });
    },
    postClient(data) {
      return instance.post('clients', data);
    },
    postSentCredentials(data) {
      return instance.post('clients/sentCredentials', data);
    },
    postMergeClients(data) {
      return instance.post('clients/merge', data);
    },
    getClientsAutocomplete(args) {
      const params = convertJSObjectToSearchUrlParams(args);
      return instance.get('clients', { params });
    },
    getClientById(id) {
      return instance.get(`clients/${id}`);
    },
    putClientById(data) {
      const { id } = data;
      return instance.put(`clients/${id}`, data);
    },
    deleteClient(id) {
      return instance.delete(`clients/${id}`);
    },
    deleteEmployee(id) {
      return instance.delete(`employees/${id}`);
    },
    getPayments(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('payments', { params });
    },
    getPaymentById(id) {
      return instance.get(`payments/${id}`);
    },
    postPayment(data) {
      return instance.post('payments', data);
    },
    putPaymentById(data) {
      const { id } = data;
      return instance.put(`payments/${id}`, data);
    },
    postPaymentsApply(data) {
      return instance.post('payments/apply', data);
    },
    deletePayment(id) {
      return instance.delete(`payments/${id}`);
    },
    getOrders(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('orders', { params });
    },
    postOrder(data) {
      return instance.post('orders', data);
    },
    postInvoice(data) {
      return instance.post('createInvoice', data);
    },
    getOrdersAutocomplete(args) {
      const params = convertJSObjectToSearchUrlParams(args);
      return instance.get('orders', { params });
    },
    getOrderById(id) {
      return instance.get(`orders/${id}`);
    },
    putOrderById(data) {
      const { id } = data;
      return instance.put(`orders/${id}`, data);
    },
    deleteOrder(id) {
      return instance.delete(`orders/${id}`);
    },
    getOrderNextNumber(params) {
      return instance.get('orders/getNextNumber', { params });
    },
    getEmployees(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('employees', { params });
    },
    getEmployeesAutocomplete(args) {
      const params = convertJSObjectToSearchUrlParams(args);
      return instance.get('employees', { params });
    },
    getEmployeeById(id) {
      return instance.get(`employees/${id}`);
    },
    putEmployeeById(data) {
      const { id } = data;
      return instance.put(`employees/${id}`, data);
    },
    postEmployee(data) {
      return instance.post('employees', data);
    },
    postInviteEmployee(data) {
      return instance.post('employees/invite', data);
    },
  },
  integration: {
    getIntegrations(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('authorityIntegration', { params });
    },
    getIntegrationById(id) {
      return instance.get(`authorityIntegration/${id}`);
    },
    postIntegration(data) {
      return instance.post('authorityIntegration', data);
    },
    deleteIntegration(id) {
      return instance.delete(`authorityIntegration/${id}`);
    },
    getIntegrationsAutocomplete(args) {
      const params = convertJSObjectToSearchUrlParams(args);
      return instance.get('authorityIntegration', { params });
    },
    putIntegrationById(data) {
      const { id } = data;
      return instance.put(`authorityIntegration/${id}`, data);
    },
  },
  currencies: {
    getCurrencies(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('currencies', { params });
    },
    postCurrency(data) {
      return instance.post('currencies', data);
    },
    deleteCurrency(id) {
      return instance.delete(`currencies/${id}`);
    },
    getCurrenciesAutocomplete(args) {
      const params = convertJSObjectToSearchUrlParams(args);
      return instance.get('currencies', { params });
    },
    putCurrencyById(data) {
      const { id } = data;
      return instance.put(`currencies/${id}`, data);
    },
    postCurrencyCalc(data) {
      return instance.post('currencies/calc', data);
    },
  },
  currencyRates: {
    getCurrencyRates(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('currencyRates', { params });
    },
    postCurrencyRate(data) {
      return instance.post('currencyRates', data);
    },
    deleteCurrencyRate(id) {
      return instance.delete(`currencyRates/${id}`);
    },
    getCurrencyRatesAutocomplete(args) {
      const params = convertJSObjectToSearchUrlParams(args);
      return instance.get('currencyRates', { params });
    },
    putCurrencyRateById(data) {
      const { id } = data;
      return instance.put(`currencyRates/${id}`, data);
    },
  },
  jurisdiction: {
    getJurisdictions(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('jurisdictions', { params });
    },
    getJurisdictionById(id) {
      return instance.get(`jurisdictions/${id}`);
    },
    postJurisdiction(data) {
      return instance.post('jurisdictions', data);
    },
    deleteJurisdiction(id) {
      return instance.delete(`jurisdictions/${id}`);
    },
    getJurisdictionsAutocomplete(args) {
      const params = convertJSObjectToSearchUrlParams(args);
      return instance.get('jurisdictions', { params });
    },
    putJurisdictionById(data) {
      const { id } = data;
      return instance.put(`jurisdictions/${id}`, data);
    },
  },
  taxes: {
    getTaxes(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('taxes', { params });
    },
    getTaxById(id) {
      return instance.get(`taxes/${id}`);
    },
    postTax(data) {
      return instance.post('taxes', data);
    },
    deleteTax(id) {
      return instance.delete(`taxes/${id}`);
    },
    putTaxById(data) {
      const { id } = data;
      return instance.put(`taxes/${id}`, data);
    },
  },
  inventions: {
    getProposalById(id) {
      return instance.get(`inventions/${id}`);
    },
    getProposal(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('inventions', { params });
    },
    getProposalAutocomplete(args) {
      const params = convertJSObjectToSearchUrlParams(args);
      return instance.get('inventions', { params });
    },
    deleteProposal(id) {
      return instance.delete(`inventions/${id}`);
    },
    postProposal(params) {
      return instance.post('inventions', params);
    },
    putProposal({ id, params }) {
      return instance.put(`inventions/${id}`, params);
    },
    getFromRegistry(params) {
      return instance.get('inventions/getFromRegistry', { params });
    },
    getNextNumber(params) {
      return instance.get('inventions/getNextNumber', { params });
    },
    putProposalCloneById(id) {
      return instance.put(`inventions/${id}/clone`);
    },
  },

  trademarks: {
    getProposal(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('trademarks', { params });
    },
    getProposalById(id) {
      return instance.get(`trademarks/${id}`);
    },
    postProposal(params) {
      return instance.post('trademarks', params);
    },
    putProposal({ id, params }) {
      return instance.put(`trademarks/${id}`, params);
    },
    deleteProposal(id) {
      return instance.delete(`trademarks/${id}`);
    },
    getProposalAutocomplete(args) {
      const params = convertJSObjectToSearchUrlParams(args);
      return instance.get('trademarks', { params });
    },
    getNextNumber(params) {
      return instance.get('trademarks/getNextNumber', { params });
    },
    postCreateImage(params) {
      return instance.post('trademarks/createImage', params);
    },
    postAddDocuments({ id, params }) {
      return instance.post(`trademarks/${id}/addDocuments`, params);
    },
    postRemoveDocuments({ id, params }) {
      return instance.post(`trademarks/${id}/removeDocuments`, params);
    },
    putProposalCloneById(id) {
      return instance.put(`trademarks/${id}/clone`);
    },
    getFromRegistry(params) {
      return instance.get('trademarks/getFromRegistry', { params });
    },

    getEstimates(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('trademarks/estimates', { params });
    },
    deleteEstimate(id) {
      return instance.delete(`trademarks/estimates/${id}`);
    },
    getRebounds(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('trademarks/mktu', { params });
    },
    getReboundsAutocomplete(args) {
      const params = convertJSObjectToSearchUrlParams(args);
      return instance.get('trademarks/mktu', { params });
    },
    getReboundById(id) {
      return instance.get(`trademarks/mktu/${id}`);
    },
    getReboundPdfById(id) {
      return instance.get(`trademarks/mktu/${id}/createFiles`);
    },
    postRebound(data) {
      return instance.post('trademarks/mktu', data);
    },
    postSearchRebound(data) {
      return instance.post('trademarks/mktu/search', data);
    },
    putReboundById(data) {
      const { id } = data;
      return instance.put(`trademarks/mktu/${id}`, data);
    },
    deleteRebound(id) {
      return instance.delete(`trademarks/mktu/${id}`);
    },
    getClasses(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('trademarks/mktu/classes', { params });
    },
    getClassById(data) {
      const { id, lang, entity } = data;
      const params = convertJSObjectToUrlParams({ lang, entity });
      return instance.get(`trademarks/mktu/classes/${id}`, { params });
    },
    getMktuTemplates(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('trademarks/mktu/template', { params });
    },
    getMktuTemplatesAutocomplete(args) {
      const params = convertJSObjectToSearchUrlParams(args);
      return instance.get('trademarks/mktu/template', { params });
    },
    getMktuTemplatesById(id) {
      return instance.get(`trademarks/mktu/template/${id}`);
    },
    postMktuTemplate(data) {
      return instance.post('trademarks/mktu/template', data);
    },
    getMktuTemplatePdfById(id) {
      return instance.get(`trademarks/mktu/template/${id}/createFiles`);
    },
    putMktuTemplateById(data) {
      const { id } = data;
      return instance.put(`trademarks/mktu/template/${id}`, data);
    },
    deleteMktuTemplate(id) {
      return instance.delete(`trademarks/mktu/template/${id}`);
    },
    getSearches(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('trademarks/search', { params });
    },
    postQuickSearch(data) {
      return instance.post('trademarks/quicksearch', data);
    },
    getSearchReport(searchId, params) {
      // TODO: позже немного иначе нужно сформировать параметры
      return instance.get(`trademarks/search/${searchId}/report?${params}`);
    },
    getSearchById(id) {
      return instance.get(`trademarks/search/${id}`);
    },
    deleteSearch(id) {
      return instance.delete(`trademarks/search/${id}`);
    },
    postSearch(data) {
      return instance.post('trademarks/search', data);
    },
    putSearchById(data) {
      const { id } = data;
      return instance.put(`trademarks/search/${id}`, data);
    },
    getMonitoring(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('trademarks/monitoring', { params });
    },
    postMonitoring(data) {
      return instance.post('trademarks/monitoring', data);
    },
    putMonitoringById(data) {
      const { id } = data;
      return instance.put(`trademarks/monitoring/${id}`, data);
    },
    getMonitoringById(id) {
      return instance.get(`trademarks/monitoring/${id}`);
    },
    getMonitoringReport(searchId, params) {
      // TODO: позже немного иначе нужно сформировать параметры
      return instance.get(`trademarks/monitoring/${searchId}/report?${params}`);
    },
    deleteMonitoring(id) {
      return instance.delete(`trademarks/monitoring/${id}`);
    },
  },
  madridCase: {
    getMadridCases(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('madridCase', { params });
    },
    getMadridCaseById(id) {
      return instance.get(`madridCase/${id}`);
    },
    postMadridCase(data) {
      return instance.post('madridCase', data);
    },
    deleteMadridCase(id) {
      return instance.delete(`madridCase/${id}`);
    },
    putMadridCaseById(data) {
      const { id } = data;
      return instance.put(`madridCase/${id}`, data);
    },
  },
  industrialDesigns: {
    getProposal(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('industrial', { params });
    },
    getProposalById(id) {
      return instance.get(`industrial/${id}`);
    },
    getProposalAutocomplete(args) {
      const params = convertJSObjectToSearchUrlParams(args);
      return instance.get('industrial', { params });
    },

    deleteProposal(id) {
      return instance.delete(`industrial/${id}`);
    },

    postProposal(params) {
      return instance.post('industrial', params);
    },
    putProposal({ id, params }) {
      return instance.put(`industrial/${id}`, params);
    },
    getFromRegistry(params) {
      return instance.get('industrial/getFromRegistry', { params });
    },
    getNextNumber(params) {
      return instance.get('industrial/getNextNumber', { params });
    },
    putProposalCloneById(id) {
      return instance.put(`industrial/${id}/clone`);
    },
  },
  utilityModels: {
    getProposal(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('utility', { params });
    },
    getProposalAutocomplete(args) {
      const params = convertJSObjectToSearchUrlParams(args);
      return instance.get('utility', { params });
    },
    deleteProposal(id) {
      return instance.delete(`utility/${id}`);
    },
    postProposal(params) {
      return instance.post('utility', params);
    },
    getProposalById(id) {
      return instance.get(`utility/${id}`);
    },
    putProposal({ id, params }) {
      return instance.put(`utility/${id}`, params);
    },
    getFromRegistry(params) {
      return instance.get('utility/getFromRegistry', { params });
    },
    getNextNumber(params) {
      return instance.get('utility/getNextNumber', { params });
    },
    putProposalCloneById(id) {
      return instance.put(`utility/${id}/clone`);
    },
  },
  fieldsSetting: {
    getFieldsSettings(params) {
      return instance.get('fields', { params });
    },
    getFieldsEntity(name) {
      return instance.get(`fields/${name}`);
    },
  },
  prices: {
    getCategories(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('costsCategories', { params });
    },
    getCategoryById(id) {
      return instance.get(`costsCategories/${id}`);
    },
    deleteCategory(id) {
      return instance.delete(`costsCategories/${id}`);
    },
    putCategory(data) {
      const { id } = data;
      return instance.put(`costsCategories/${id}`, data);
    },
    postCategory(data) {
      return instance.post('costsCategories', data);
    },
    getTariffTable(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('costsComponents', { params });
    },
    postTariffTable(data) {
      return instance.post('costsComponents', data);
    },
    getTariffTableById(id) {
      return instance.get(`costsComponents/${id}`);
    },
    putTariffTableById(data) {
      const { id } = data;
      return instance.put(`costsComponents/${id}`, data);
    },
    deleteTariffTable(id) {
      return instance.delete(`costsComponents/${id}`);
    },
    getTariffs(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('costs', { params });
    },
    postTariff(data) {
      return instance.post('costs', data);
    },
    putTariff(data) {
      const { id } = data;
      return instance.put(`costs/${id}`, data);
    },
    getTariffID(id) {
      return instance.get(`costs/${id}`);
    },
    deleteTariff(id) {
      return instance.delete(`costs/${id}`);
    },
    getTariffsAutocomplete(args) {
      const params = convertJSObjectToSearchUrlParams(args);
      return instance.get('costs', { params });
    },
  },
  documentTypes: {
    getDocumentsType(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('documentTypes', { params });
    },
    getDocumentTypeById(id) {
      return instance.get(`documentTypes/${id}`);
    },
    postDocumentType(data) {
      return instance.post('documentTypes', data);
    },
    deleteDocumentsType(id) {
      return instance.delete(`documentTypes/${id}`);
    },
    getDocumentTypesAutocomplete(args) {
      const params = convertJSObjectToSearchUrlParams(args);
      return instance.get('documentTypes', { params });
    },
    putDocumentTypeById(data) {
      const { id } = data;
      return instance.put(`documentTypes/${id}`, data);
    },
  },
  products: {
    getProducts(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('products', { params });
    },
    getProductById(id) {
      return instance.get(`products/${id}`);
    },
    postProduct(data) {
      return instance.post('products', data);
    },
    deleteProduct(id) {
      return instance.delete(`products/${id}`);
    },
    putProductById(data) {
      const { id } = data;
      return instance.put(`products/${id}`, data);
    },
  },
  productCategory: {
    getProductCategory(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('productCategory', { params });
    },
    getProductCategoryById(id) {
      return instance.get(`productCategory/${id}`);
    },
    postProductCategory(data) {
      return instance.post('productCategory', data);
    },
    deleteProductCategory(id) {
      return instance.delete(`productCategory/${id}`);
    },
    putProductCategoryById(data) {
      const { id } = data;
      return instance.put(`productCategory/${id}`, data);
    },
  },
  email: {
    getEmail(args) {
      return instance.get('email', { params: args });
    },
    getEmailById(id) {
      return instance.get(`email/${id}`);
    },
    postEmail(data) {
      return instance.post('email', data);
    },
    deleteEmail(id) {
      return instance.delete(`email/${id}`);
    },
    putEmailById(data) {
      const { id } = data;
      return instance.put(`email/${id}`, data);
    },
    postEmailSend({ id, data }) {
      return instance.post(`email/${id}/send`, data);
    },
    getCheckNew(id) {
      return instance.get(`email/${id}/check-new`);
    },
    getCheckEmail(params) {
      return instance.get('email/check', { params });
    },
    postEmailPreview({ id, data }) {
      return instance.post(`email/${id}/preview`, data);
    },
    getEmailMessages(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('emailLetters', { params });
    },
    getDownloadAttachment({ id, params }) {
      return instance.get(`emailLetters/${id}/attachment`, { params });
    },
    getThreadMessages(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('emailLetters/thread', { params });
    },
  },
  emailSignatures: {
    getEmailSignatures(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('emailSignature', { params });
    },
    getEmailSignatureById(id) {
      return instance.get(`emailSignature/${id}`);
    },
    postEmailSignature(data) {
      return instance.post('emailSignature', data);
    },
    deleteEmailSignature(id) {
      return instance.delete(`emailSignature/${id}`);
    },
    putEmailSignatureById(data) {
      const { id } = data;
      return instance.put(`emailSignature/${id}`, data);
    },
  },
  emailLetters: {
    getEmailLetters(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('emailLetters', { params });
    },
    getEmailLetterById(id) {
      return instance.get(`emailLetters/${id}`);
    },
    postEmailLetter(data) {
      return instance.post('emailLetters', data);
    },
    deleteEmailLetter(id) {
      return instance.delete(`emailLetters/${id}`);
    },
  },
  emailTemplate: {
    getEmailTemplate(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('emailTemplate', { params });
    },
    getEmailTemplateById(id) {
      return instance.get(`emailTemplate/${id}`);
    },
    postEmailTemplate(data) {
      return instance.post('emailTemplate', data);
    },
    deleteEmailTemplate(id) {
      return instance.delete(`emailTemplate/${id}`);
    },
    getEmailTemplateAutocomplete(args) {
      const params = convertJSObjectToSearchUrlParams(args);
      return instance.get('emailTemplate', { params });
    },
    putEmailTemplateById(data) {
      const { id } = data;
      return instance.put(`emailTemplate/${id}`, data);
    },
  },
  documentTypesCategory: {
    getDocumentsTypeCategory(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('documentTypeCategory', { params });
    },
    getDocumentTypeCategoryById(id) {
      return instance.get(`documentTypeCategory/${id}`);
    },
    postDocumentTypeCategory(data) {
      return instance.post('documentTypeCategory', data);
    },
    deleteDocumentsTypeCategory(id) {
      return instance.delete(`documentTypeCategory/${id}`);
    },
    getDocumentTypeCategoryAutocomplete(args) {
      const params = convertJSObjectToSearchUrlParams(args);
      return instance.get('documentTypeCategory', { params });
    },
    putDocumentTypeCategoryById(data) {
      const { id } = data;
      return instance.put(`documentTypeCategory/${id}`, data);
    },
  },
  tags: {
    getTags(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('tags', { params });
    },
    getTagById(id) {
      return instance.get(`tags/${id}`);
    },
    putTagById(data) {
      const { id } = data;
      return instance.put(`tags/${id}`, data);
    },
    postTag(data) {
      return instance.post('tags', data);
    },
    deleteTag(id) {
      return instance.delete(`tags/${id}`);
    },
  },
  actionTypes: {
    getActionTypes(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('pending-actions-types', { params });
    },
    getActionTypeById(id) {
      return instance.get(`pending-actions-types/${id}`);
    },
    putActionTypeById(data) {
      const { id } = data;
      return instance.put(`pending-actions-types/${id}`, data);
    },
    postActionType(data) {
      return instance.post('pending-actions-types', data);
    },
    deleteActionType(id) {
      return instance.delete(`pending-actions-types/${id}`);
    },
  },
  notifications: {
    getNotifications(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('notifications', { params });
    },
    getNotificationById(id) {
      return instance.get(`notifications/${id}`);
    },
    postNotification(data) {
      return instance.post('notifications', data);
    },
    deleteNotification(id) {
      return instance.delete(`notifications/${id}`);
    },
    getNotificationsAutocomplete(args) {
      const params = convertJSObjectToSearchUrlParams(args);
      return instance.get('notifications', { params });
    },
    putNotificationById(data) {
      const { id } = data;
      return instance.put(`notifications/${id}`, data);
    },
  },
  applicants: {
    getApplicantAutocomplete(args) {
      const params = convertJSObjectToSearchUrlParams(args);
      return instance.get('applicants', { params });
    },
    getApplicants(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('applicants', { params });
    },
    getApplicantById(id) {
      return instance.get(`applicants/${id}`);
    },
    postApplicant(data) {
      return instance.post('applicants', data);
    },
    putApplicant(data) {
      const { id } = data;
      return instance.put(`applicants/${id}`, data);
    },
    deleteApplicant(id) {
      return instance.delete(`applicants/${id}`);
    },
  },
  objectStatuses() {
    return instance.get('objectStatuses');
  },
  works: {
    getWorks(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('works', { params });
    },
    getWorksAutocomplete(args) {
      const params = convertJSObjectToSearchUrlParams(args);
      return instance.get('works', { params });
    },
    deleteWork(id) {
      return instance.delete(`works/${id}`);
    },
    putWork(data) {
      const { costs, id } = data;
      return instance.put(`works/${id}`, costs);
    },
    getWorkById(id) {
      return instance.get(`works/${id}`);
    },
    postWork(data) {
      return instance.post('works', data);
    },
  },
  permissionsGroups: {
    getPermissionsGroups(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('groups', { params });
    },
    deletePermissionsGroup(id) {
      return instance.delete(`groups/${id}`);
    },
    putPermissionsGroup(data) {
      const { id } = data;
      return instance.put(`groups/${id}`, data);
    },
    getPermissionsGroupById(id) {
      return instance.get(`groups/${id}`);
    },
    postPermissionsGroup(data) {
      return instance.post('groups', data);
    },
    getPermissions(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('permissions', { params });
    },
  },
  logging: {
    getLogging(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('logging', { params });
    },
    deleteLog(id) {
      return instance.delete(`logging/${id}`);
    },
    putLog(id) {
      return instance.put(`logging/${id}/revert`);
    },
    getLogById(id) {
      return instance.get(`logging/${id}`);
    },
  },
  multiple: {
    deleteMultiple(data) {
      return instance.delete('remove', { data });
    },
    putMultiple(data) {
      return instance.put('update', data);
    },
  },
  findCompany: {
    getFindCompany(params) {
      return instance.get('helpers/findCompany', { params });
    },
    parserAddress(address) {
      return instance.post('parserAddress', { address });
    },
  },
  registry: {
    getRegistryByNumber(params) {
      return instanceCors.post('http://159.69.155.26/api/v1/registry', params);
    },
  },
  sign: {
    postSign(params) {
      return instance.post('sign', params);
    },
    postCreatePacket(params) {
      return instance.post('createPacket', params);
    },
  },
  files: {
    getBase64(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('helpers/getBase64', { params });
    },
    getDownloadFile(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('download', { params, responseType: 'blob' });
    },
    getDownloadExport() {
      return instance.get('download/export', { responseType: 'blob' });
    },
    getPreviewFileById(id) {
      return instance.get(`files/${id}/preview`);
    },
  },
  send: {
    postSend(params) {
      return instance.post('send', params);
    },
  },
  patent: {
    postCheckFtp(params) {
      return instance.post('checkftp', params);
    },
    postCalcPatentsRenewal(params) {
      return instance.post('calcPatentsRenewal', params);
    },
  },
  supportTickets: {
    postSupportTickets(params) {
      return instance.post('supportTickets', params);
    },
  },
  usersFilters: {
    getUsersFilters(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('usersFilters', { params });
    },
    deleteUsersFilters(id) {
      return instance.delete(`usersFilters/${id}`);
    },
    postUsersFilters(data) {
      return instance.post('usersFilters', data);
    },
  },
  dashboard: {
    getDashboard(params) {
      // const params = convertJSObjectToUrlParams(args);
      return instance.get('dashboard', { params });
    },
  },
});
