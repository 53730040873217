// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import types from './types';

const clientsActions = Object.freeze({
  setCategoriesData(categoriesData) {
    return actionCreator(types.SET_PRICES_CATEGORIES_DATA, categoriesData);
  },
  setDeleteCategory(category) {
    return actionCreator(types.SET_DELETE_PRICES_CATEGORY, category);
  },
  setCategoryById(data) {
    return actionCreator(types.SET_PRICES_CATEGORY_BY_ID, data);
  },
  setCategoryDataById(data) {
    return actionCreator(types.SET_PRICES_CATEGORY_DATA_BY_ID, data);
  },
  setCurrentPage(currentPage) {
    return actionCreator(types.SET_PRICES_CATEGORIES_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return actionCreator(types.SET_PRICES_CATEGORIES_FILTERS, filters);
  },
  setSorting(sorting) {
    return actionCreator(types.SET_PRICES_CATEGORIES_SORTING, sorting);
  },
  setColumnWidths(data) {
    return actionCreator(types.SET_PRICES_CATEGORIES_COLUMN_WIDTHS, data);
  },
  setColumnOrder(data) {
    return actionCreator(types.SET_PRICES_CATEGORIES_COLUMN_ORDER, data);
  },
  setHiddenColumnNames(data) {
    return actionCreator(types.SET_PRICES_CATEGORIES_HIDDEN_COLUMN_NAMES, data);
  },
  setPageSize(pageSize) {
    return actionCreator(types.SET_PRICES_CATEGORIES_PAGE_SIZE, pageSize);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_PRICES_CATEGORIES_INITIAL_STATE, initialState);
  },
  setReload(reload) {
    return actionCreator(types.SET_PRICES_CATEGORIES_RELOAD, reload);
  },
  setIsModalOpen(data) {
    return actionCreator(types.SET_PRICES_CATEGORIES_IS_MODAL_OPEN, data);
  },
});

export default clientsActions;
