// core
import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import {
  Field, Form, reduxForm, change,
} from 'redux-form/lib/immutable';
import styled from 'styled-components';
import { Map } from 'immutable';

// lodash
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import find from 'lodash/find';

// ui
import {
  Box, ListItem as MuiListItem, List as MuiList,
  IconButton as MuiIconButton,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import {
  Close as CloseIcon,
  Description as MuiDescription,
} from '@material-ui/icons';
import { DropzoneAreaBase } from 'material-ui-dropzone';

// config
import selectors from '../../../engine/config/selectors';

// helpers
import { formFields } from '../helper/form';

// parts
import RenderTextField from '../../../ui/Form/RenderTextField';
import AutocompleteField from '../../../ui/Form/AutocompleteField';
import Modal from '../../../components/Modal/Modal';
import DialogActions from '../../../components/Modal/components/DialogActions';
import ProgressBar from '../../../components/ProgressBar';

// actions
import documentTemplatesActionAsync from '../../../engine/core/documentTemplates/saga/asyncAction';
import documentTemplatesActions from '../../../engine/core/documentTemplates/action';
import helpersActionAsync from '../../../engine/core/helpers/saga/asyncAction';
import helpersActions from '../../../engine/core/helpers/action';
import uploadFileActionAsync from '../../../engine/core/uploadFile/saga/asyncAction';
import uploadFileActions from '../../../engine/core/uploadFile/action';
import { useAccessList } from '../../../ui/_hooks/useAccessList';
import accessList from '../../../engine/config/accessList';

// styles
const Description = styled(MuiDescription)(spacing);
const IconButton = styled(MuiIconButton)(spacing);

const List = styled(MuiList)`
  display: flex;
`;

const ListItem = styled(MuiListItem)`
  width: auto;
  padding-left: 0;
`;

function AddDocumentModal(props) {
  const {
    getDocumentTemplatesById,
    handleSubmit,
    isModalOpen,
    documentTypes,
    pendingPostDocumentTemplates,
    initialize,
    destroy,
    pendingDocumentsType,
    uploadFile,
    originalFile,
    titleValues,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [readyInitialize, setReadyInitialize] = useState(false);
  const [progress, setProgress] = useState(0);
  const accessPut = useAccessList(accessList.documentsTemplate_put);
  const accessDocTypeList = useAccessList(accessList.documentClassifier_list_get);
  const accessEdit = !isEmpty(getDocumentTemplatesById) ? accessPut : true;

  const handleCloseModal = () => {
    dispatch(documentTemplatesActions.setModalOpen(false));
    dispatch(documentTemplatesActions.setOriginalFile({}));
  };

  useEffect(() => function cleanup() {
    dispatch(helpersActions.setDocumentsType([]));
  }, [dispatch]);

  const handleSubmits = (formData) => {
    const json = formData.toJSON();
    const checkId = !isEmpty(getDocumentTemplatesById) && isNumber(getDocumentTemplatesById.id);
    dispatch(documentTemplatesActionAsync[checkId
      ? 'putDocumentTemplatesByIdAsync'
      : 'postDocumentTemplatesAsync']({
      ...checkId ? {
        id: getDocumentTemplatesById.id,
      } : {},
      ...!isEmpty(json.title)
        ? { [formFields.title]: json.title }
        : {},
      ...!isEmpty(json.documentClassifier) && json.documentClassifier.id ? {
        [formFields.documentClassifier]: json.documentClassifier.id,
      } : {},
      ...!isEmpty(json.documentClassifier) && json.documentClassifier.size ? {
        [formFields.documentClassifier]: json.documentClassifier.toJSON().id,
      } : {},
      ...!isEmpty(getDocumentTemplatesById.file) ? {
        ...getDocumentTemplatesById.file.fileId !== originalFile.toJS()?.fileId ? {
          file: getDocumentTemplatesById.file.fileId,
        } : {},
      } : {
        file: '',
      },
    }));
  };

  useEffect(() => {
    if (!isEmpty(uploadFile.toJS())) {
      dispatch(documentTemplatesActions.setDocumentTemplatesById({
        data: {
          ...getDocumentTemplatesById,
          file: uploadFile.toJS(),
        },
      }));
      dispatch(uploadFileActions.setUploadFile({
        file: Map(),
      }));
      setProgress(0);
    }
  }, [dispatch, uploadFile, getDocumentTemplatesById]);

  const handleUploadFiles = (files) => {
    if (!isEmpty(files) && files[0]?.data) {
      dispatch(uploadFileActionAsync.uploadFileAsync({
        file: {
          file: files[0]?.data,
          originalName: files[0]?.file?.name,
        },
        onUploadProgress: (event) => {
          setProgress(Math.round((100 * event.loaded) / event.total));
        },
      }));
    }
  };

  const handleDeleteFile = () => {
    dispatch(documentTemplatesActions.setDocumentTemplatesById({
      data: {
        ...getDocumentTemplatesById,
        file: {},
      },
    }));
  };

  useEffect(() => {
    if (!readyInitialize) {
      setReadyInitialize(true);
      if (!isEmpty(getDocumentTemplatesById) && isNumber(getDocumentTemplatesById.id)) {
        const selectedDocumentType = !isEmpty(getDocumentTemplatesById.documentClassifier)
          ? find(documentTypes.toJS(), { id: getDocumentTemplatesById.documentClassifier.id })
          : '';
        initialize({
          ...getDocumentTemplatesById,
          ...!isEmpty(getDocumentTemplatesById.documentClassifier) && !isEmpty(selectedDocumentType)
            ? { documentClassifier: selectedDocumentType }
            : {},
        });
      }
      if (isEmpty(getDocumentTemplatesById)) destroy();
    }
  }, [
    destroy, initialize, documentTypes,
    getDocumentTemplatesById, setReadyInitialize,
    readyInitialize,
  ]);

  const handleOnFocus = () => {
    if (isEmpty(documentTypes.toJS()) && accessDocTypeList) {
      dispatch(helpersActionAsync.getDocumentsTypeAsync());
    }
  };

  const handleChangeType = (docType) => {
    if (!isEmpty(docType) && !titleValues) {
      dispatch(change('AddDocument', formFields.title, docType.label || docType.name));
    }
  };

  return (
    <Modal
      title={t(!isEmpty(getDocumentTemplatesById) && isNumber(getDocumentTemplatesById.id)
        ? 'Change template'
        : 'New template')}
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
      displayDialogActions={false}
    >
      <Form onSubmit={handleSubmit(handleSubmits)}>
        <Field
          name={formFields.title}
          id={formFields.title}
          label={t('Title')}
          margin="normal"
          variant="standard"
          my={2}
          fullWidth
          type="text"
          component={RenderTextField}
          disabled={!accessEdit}
        />

        <Field
          changeToStore
          options={documentTypes.toJS()}
          handleOnFocus={handleOnFocus}
          loading={pendingDocumentsType}
          multiple={false}
          name={formFields.documentClassifier}
          id={formFields.documentClassifier}
          component={AutocompleteField}
          onChange={handleChangeType}
          type="text"
          label={t('Type')}
          margin="normal"
          fullWidth
          disabled={!accessEdit}
        />

        {!isEmpty(getDocumentTemplatesById) && !isEmpty(getDocumentTemplatesById.file) && (
        <List>
          <ListItem>
            <Description mr={1} />
            <Box mr={1}>
              {getDocumentTemplatesById.file.filename}
            </Box>
            {accessEdit && (
              <IconButton
                aria-label="Delete"
                size="small"
                onClick={handleDeleteFile}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            )}
          </ListItem>
        </List>
        )}

        {isEmpty(getDocumentTemplatesById.file) && (
          <Box mt={2} mb={2}>
            {progress ? (
              <ProgressBar value={progress} />
            ) : (
              <DropzoneAreaBase
                dropzoneText={t('Drag and drop a file here or click')}
                showPreviewsInDropzone={false}
                showAlerts={false}
                clearOnUnmount
                filesLimit={1}
                maxFileSize={21000000}
                onAdd={(files) => {
                  handleUploadFiles(files, formFields.file);
                }}
              />
            )}
          </Box>
        )}
        <DialogActions
          pending={pendingPostDocumentTemplates}
          handleCloseModal={handleCloseModal}
          visibleButtonSend={accessEdit}
        />
      </Form>
    </Modal>
  );
}

AddDocumentModal.displayName = 'AddDocumentModal';

AddDocumentModal.propTypes = {
  getDocumentTemplatesById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  documentTypes: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingPostDocumentTemplates: PropTypes.bool.isRequired,
  pendingDocumentsType: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  destroy: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  uploadFile: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  originalFile: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  titleValues: PropTypes.string,
};

AddDocumentModal.defaultProps = {
  titleValues: '',
};

function mapStateToProps(state) {
  return {
    getDocumentTemplatesById: selectors.documentTemplates.getDocumentTemplatesById(state),
    isModalOpen: selectors.documentTemplates.isModalOpen(state),
    originalFile: selectors.documentTemplates.originalFile(state),
    documentTypes: selectors.helpers.documentsType(state),
    pendingDocumentsType: selectors.helpers.pendingDocumentsType(state),
    pendingPostDocumentTemplates: selectors.documentTemplates.pendingPostDocumentTemplates(state),
    uploadFile: selectors.uploadFile.uploadFile(state),
    titleValues: selectors.form.getFormValues(state, 'AddDocument').get(formFields.title),
  };
}

export default compose(
  reduxForm({
    form: 'AddDocument',
  }),
  connect(mapStateToProps, null),
)(AddDocumentModal);
