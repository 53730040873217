// core
import { useCallback, useState, useMemo } from 'react';

// lodash
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import isArray from 'lodash/isArray';

export function useMain({
  formField, getAutocompleteLists, formFieldInput,
}) {
  const [startLoading, setStartLoading] = useState(false);
  const [startData, setStartData] = useState({});
  const handleOnFocus = (name, data) => () => {
    const items = data.toJS().items || data.toJS();
    if (isEmpty(items)) {
      setStartLoading(true);
      getAutocompleteLists([name]);
    }
    if (!isEmpty(formField) && isEmpty(startData)) {
      setStartData({
        name, data: formField,
      });
    }
  };

  const dataItems = useCallback((data) => {
    const items = data.toJS().items || data.toJS();
    if (!isEmpty(startData)) {
      return uniqBy([
        ...isArray(startData.data) ? startData.data : [startData.data], ...items,
      ], 'id');
    }

    if (!isEmpty(formField)) {
      return uniqBy([
        ...!isEmpty(formField) ? [formField] : [], ...items,
      ], 'id');
    }

    return items;
  }, [formField, startData]);

  const checkOptions = useMemo(() => isEmpty(formFieldInput) || !isEmpty(formField), [
    formFieldInput, formField,
  ]);

  return {
    dataItems,
    startLoading,
    checkOptions,
    handleOnFocus,
  };
}
