// core
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// lodash
import isEmpty from 'lodash/isEmpty';

// Engine
import selectors from '../../engine/config/selectors';

// Helpers
import { convertSortingObjectToUrlParams } from '../_helpers/api';

export function useParamsRequest({
  filters = [], sorting = [], entityName = '',
}) {
  const fieldsEntity = useSelector(selectors.fieldsSetting.fieldsEntity);
  const filtersStringify = JSON.stringify(filters);
  const sortingStringify = JSON.stringify(sorting);

  const orderBy = useMemo(() => (
    convertSortingObjectToUrlParams(JSON.parse(sortingStringify))
  ), [sortingStringify]);

  const paramsByFilter = useMemo(() => {
    const params = entityName ? { entity: entityName } : {};

    return JSON.parse(filtersStringify).reduce((acc, values, index) => {
      const {
        columnName, value, type = '', advanced = false,
      } = values;
      const newColumnName = isEmpty(fieldsEntity.toJS()[`${entityName}.${columnName}`])
        && !isEmpty(fieldsEntity.toJS()[`${entityName}.${columnName}.${columnName === 'pendingActions' ? 'finishAt' : 'id'}`])
        ? `${columnName}.${columnName === 'pendingActions' ? 'finishAt' : 'id'}` : columnName;
      const name = `${newColumnName}${type ? `:typeRequest:${type}` : ''}${advanced && columnName !== 'connector' ? `:indexRequest:${index}` : ''}`;
      acc[name] = value;
      return acc;
    }, params);
  }, [filtersStringify, fieldsEntity, entityName]);

  return {
    orderBy,
    paramsByFilter,
  };
}
