// core
import React, {
  memo,
} from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';

// lodash
import isEqual from 'lodash/isEqual';
import DxTable from '../../../../ui/Table/DxTable';

// actions
import paymentAllocationsActionsAsync from '../../../../engine/core/paymentAllocations/saga/asyncAction';

// config
import selectors from '../../../../engine/config/selectors';
import actions from '../../../../engine/core/paymentAllocations/action';

// hooks
import { useDistribution } from '../_hooks/useDistribution';

function DistributionTable(props) {
  const {
    selections, pageSize,
    totalCount, pending, currentPage,
    paymentAllocationsData,
  } = props;
  useDistribution({
    stopInitialState: true,
  });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const getSelections = selections.toJS();

  const columns = [
    { name: 'name', title: t('Title') },
    { name: 'type', title: t('Type') },
    { name: 'orders', title: t('Orders') },
    { name: 'appliedSum', title: t('Distributed') },
  ];

  // Paging
  const onCurrentPage = (page) => dispatch(actions.setCurrentPage(page));
  const onPageSize = (size) => dispatch(actions.setPageSize(size));

  const handleSelectionChange = (selected) => {
    dispatch(paymentAllocationsActionsAsync.setSectionsAsync({
      selected,
    }));
  };

  return (
    <DxTable
      name="Distribution table"
      columns={columns}
      rows={paymentAllocationsData.toJS().items}
      isLoading={pending}
      // Paging
      totalCount={totalCount}
      pageSize={pageSize}
      currentPage={currentPage}
      onPageSize={onPageSize}
      onCurrentPage={onCurrentPage}
      // selections
      selection={getSelections.all}
      onSelectionChange={handleSelectionChange}
      disableHiddenColumn
      disableColumnOrder
      disableColumnWidth
      disableExport
    />
  );
}

DistributionTable.propTypes = {
  paymentAllocationsData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  selections: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  totalCount: PropTypes.number.isRequired,
  pending: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
};

DistributionTable.defaultProps = {
};

function mapStateToProps(state) {
  return {
    paymentAllocationsData: selectors.paymentAllocations.paymentAllocationsData(state),
    pending: selectors.paymentAllocations.pending(state),
    selections: selectors.paymentAllocations.selections(state),
    // Paging
    totalCount: selectors.paymentAllocations.totalCount(state),
    currentPage: selectors.paymentAllocations.currentPage(state),
    pageSize: selectors.paymentAllocations.pageSize(state),
  };
}

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.pending, nextProps.pending)
    && isEqual(prevProps.paymentAllocationsData, nextProps.paymentAllocationsData)
    && isEqual(prevProps.selections, nextProps.selections)
    && isEqual(prevProps.currentPage, nextProps.currentPage)
    && isEqual(prevProps.pageSize, nextProps.pageSize)
    && isEqual(prevProps.totalCount, nextProps.totalCount);
}

export default compose(
  connect(mapStateToProps, null),
)(memo(DistributionTable, areEqual));
