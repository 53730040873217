// Core
import React, { useEffect, useState, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import Helmet from 'react-helmet';
import {
  Field, Form, reduxForm, change,
} from 'redux-form/immutable';
import * as PropTypes from 'prop-types';
import {
  List, Map, merge, remove,
} from 'immutable';
import moment from 'moment';
import { Link as MuiLink } from 'react-router-dom';

// ui
import {
  Typography, Divider as MuiDivider, Tabs, Tab, Badge as MuiBadge,
  CardContent, Box, Button as MuiButton, FormControlLabel, IconButton,
  Grid, Card as MuiCard, Radio, Tooltip, CircularProgress, InputAdornment,
} from '@material-ui/core';
import {
  // HelpOutline as HelpOutlineIcon,
  FilterList as FilterListIcon,
  Clear as ClearIcon,
  NavigateBefore as NavigateBeforeIcon,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';

// lodash
import {
  isEmpty, filter, includes,
  size, toLower, isArray, map,
} from 'lodash';

// parts
import RadioButton from '../../../../ui/Form/RadioButton';
import RenderTextField from '../../../../ui/Form/RenderTextField';
import CardAutocompleteClient from '../../../../components/CardAutocompleteClient';
import SearchCardTabs from '../../Searches/Search/SearchCardTabs';
import Select from '../../../../ui/Form/Select';
import ReduxDateRange from '../../../../ui/DateRange/ReduxDateRange';

// actions
import monitoringActionsAsync from '../../../../engine/core/tradeMarks/monitoring/saga/asyncAction';
import monitoringActions from '../../../../engine/core/tradeMarks/monitoring/action';
import helpersActionAsync from '../../../../engine/core/helpers/saga/asyncAction';

// config
import selectors from '../../../../engine/config/selectors';
import accessList from '../../../../engine/config/accessList';

// helpers
import { formName, formFields, formFieldsFilter } from './helper/form';
import { splitArrayToChunks } from '../../../../engine/_helpers/splitArrays';
import { pageLinks } from '../../../../routes';
import { useAccessList } from '../../../../ui/_hooks/useAccessList';

// styles
const Card = styled(MuiCard)(spacing);
const Button = styled(MuiButton)(spacing);
const Divider = styled(MuiDivider)(spacing);
// const HelpOutline = styled(HelpOutlineIcon)`
//   margin-left: ${(props) => props.theme.spacing(1)}px;
//   font-size: 17px;
// `;
const TypographySub = styled(Typography)`
  color: ${(props) => props.theme.palette.grey['500']};
`;

const GridFilter = styled(Grid)`
  text-align: end;
`;

const Badge = styled(MuiBadge)`
  .MuiBadge-badge {
    padding: 0 4px;
    font-size: 10px;
  }
  .MuiBadge-anchorOriginTopRightRectangle {
    right: 3px;
  }
`;
const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
`;
const Link = styled(MuiLink)`
  text-decoration: underline;
  color: #1976d2;
  display: flex;
  align-items: center;
`;

function MonitoringDetail(props) {
  const {
    handleSubmit, initialize, pendingPostMonitoring, fieldSearchFor,
    getMonitoringById, match, fieldChoice, pendingMonitoringReport,
    monitoringTabs, pendingPutMonitoring, objectStatuses, pendingDownloadFile,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { params } = match;
  const [valueTab, setValueTab] = useState(0);
  const [readyMonitoring, setReadyMonitoring] = useState(false);
  const [visibleFilterList, setVisibleFilterList] = useState(false);
  const [filterList, setFilterList] = useState(Map());
  const [pendingFilter, setPendingFilter] = useState(false);
  const accessReport = useAccessList(accessList.monitoring_report_get);

  const itemsSelect = [
    { value: 'name', name: t('By name') },
    { value: 'applicant', name: t('For the owner/the applicant') },
  ];

  const TabPanel = (propsTab) => {
    const {
      children, value, index, ...other
    } = propsTab;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box pt={2}>
            {children}
          </Box>
        )}
      </div>
    );
  };

  const totalCountTab = useCallback((nameTab) => monitoringTabs[nameTab].totalCount, [
    monitoringTabs,
  ]);

  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };

  useEffect(() => {
    if (!readyMonitoring && isEmpty(getMonitoringById) && !isEmpty(params)) {
      setReadyMonitoring(true);
      dispatch(monitoringActionsAsync.getMonitoringByIdAsync(params.hashId));
    }
  }, [
    dispatch, getMonitoringById, params,
    readyMonitoring, setReadyMonitoring,
  ]);

  useEffect(() => {
    initialize(!isEmpty(getMonitoringById) ? {
      ...getMonitoringById,
      choice: `${isEmpty(getMonitoringById.classes)}`,
      clients: getMonitoringById.client,
      searchFor: getMonitoringById.word ? 'name' : 'applicant',
    } : {
      choice: 'true',
      type: '1',
      searchFor: 'name',
    });
  }, [
    getMonitoringById,
    initialize,
  ]);

  const getDate = (value) => moment(value, 'DD.MM.YYYY');

  useEffect(() => {
    if (!isEmpty(filterList.toJS())) {
      const uniqFilterTab = filter(getMonitoringById.results, (item) => {
        const itemFilter = map(Object.keys(filterList.toJS()), (key) => {
          if (key === 'status' || key === 'active') {
            return item[key] === Math.abs(filterList.toJS()[key]);
          }
          if ((key === 'applicationDate' || key === 'registrationDate')
            && isArray(filterList.toJS()[key]) && !isEmpty(item[key])) {
            return getDate(filterList.toJS()[key][0]) <= getDate(item[key])
              && getDate(item[key]) <= getDate(filterList.toJS()[key][1]);
          }
          return includes(toLower(item[key]), toLower(filterList.toJS()[key]));
        });
        return !includes(itemFilter, false);
      });
      const resultsNational = filter(uniqFilterTab, { type: 'national' });
      const resultsMadrid = filter(uniqFilterTab, { type: 'madrid' });
      const groupNational = !isEmpty(resultsNational)
        ? splitArrayToChunks(resultsNational, 20) : [];
      const groupMadrid = !isEmpty(resultsNational) ? splitArrayToChunks(resultsMadrid, 20) : [];
      dispatch(monitoringActions.setTabs('national', {
        filtered: List(resultsNational),
        items: List(groupNational),
        totalCount: !isEmpty(resultsNational) ? size(resultsNational) : 0,
        numberPerPage: 20,
        pageSize: 0,
      }));
      dispatch(monitoringActions.setTabs('madrid', {
        filtered: List(resultsMadrid),
        items: List(groupMadrid),
        totalCount: !isEmpty(resultsMadrid) ? size(resultsMadrid) : 0,
        numberPerPage: 20,
        pageSize: 0,
      }));
    } else {
      const tabNational = filter(getMonitoringById.results, { type: 'national' });
      const tabMadrid = filter(getMonitoringById.results, { type: 'madrid' });
      const groupNational = !isEmpty(tabNational) ? splitArrayToChunks(tabNational, 20) : [];
      const groupMadrid = !isEmpty(tabMadrid) ? splitArrayToChunks(tabMadrid, 20) : [];
      dispatch(monitoringActions.setTabs('national', {
        filtered: List(tabNational),
        items: List(groupNational),
        totalCount: !isEmpty(tabNational) ? size(tabNational) : 0,
        numberPerPage: 20,
        pageSize: 0,
      }));
      dispatch(monitoringActions.setTabs('madrid', {
        filtered: List(tabMadrid),
        items: List(groupMadrid),
        totalCount: !isEmpty(tabMadrid) ? size(tabMadrid) : 0,
        numberPerPage: 20,
        pageSize: 0,
      }));
    }
    setPendingFilter(false);
  }, [filterList, dispatch]);// eslint-disable-line

  const handleFilterList = () => {
    if (visibleFilterList) {
      dispatch(change(formName, formFieldsFilter.title, null));
      dispatch(change(formName, formFieldsFilter.applicationNumber, null));
      dispatch(change(formName, formFieldsFilter.registrationNumber, null));
      dispatch(change(formName, formFieldsFilter.applicationDate, null));
      dispatch(change(formName, formFieldsFilter.registrationDate, null));
      dispatch(change(formName, formFieldsFilter.classesFilter, null));
      dispatch(change(formName, formFieldsFilter.holder, null));
      dispatch(change(formName, formFieldsFilter.status, null));
      setFilterList(Map());
    }
    setVisibleFilterList(!visibleFilterList);
  };

  const handleChangeTextInput = (name, event) => {
    setPendingFilter(true);
    setTimeout(() => {
      if (!isEmpty(event.target.value)) {
        setFilterList(merge(filterList, Map({
          [name]: event.target.value,
        })));
      } else {
        setFilterList(remove(filterList, name));
      }
    }, 1500);
  };

  const handleChangeDateInput = (name, value) => {
    setPendingFilter(true);
    setTimeout(() => {
      if (!isEmpty(value)) {
        setFilterList(merge(filterList, Map({
          [name]: value.split(' - ').length === 2 ? value.split(' - ') : value,
        })));
      } else {
        setFilterList(remove(filterList, name));
      }
    }, 1000);
  };

  const clearInput = (name) => {
    dispatch(change(formName, name, null));
    setFilterList(remove(filterList, name));
  };

  const handleMonitoringReport = () => {
    if (getMonitoringById.pdf) {
      dispatch(helpersActionAsync.getDownloadFileAsync(getMonitoringById.pdf));
      return;
    }
    dispatch(monitoringActionsAsync.getMonitoringReportAsync(params.hashId));
  };

  return (
    <>
      <Helmet title={t('Monitoring for trademarks')} />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h3" gutterBottom display="inline">
          {t('Monitoring for trademarks')}
        </Typography>
        <Link to={pageLinks.tradeMarksRoutes.monitoring.all}>
          <NavigateBeforeIcon size="small" color="primary" />
          {t('Up to the list')}
        </Link>
      </Box>
      <Divider my={6} />

      <Form onSubmit={handleSubmit((formData) => {
        const jsonFormData = formData.toJS();
        const client = jsonFormData.clients;
        delete jsonFormData.clients;
        delete jsonFormData.choice;
        delete jsonFormData.results;
        delete jsonFormData.searchFor;
        dispatch(monitoringActionsAsync[isEmpty(getMonitoringById) ? 'postMonitoringAsync' : 'putMonitoringByIdAsync']({
          ...jsonFormData,
          ...!isEmpty(client) ? {
            client: client.id,
          } : {},
          ...isEmpty(jsonFormData.classes) ? {
            classes: '1-45',
          } : {},
        }));
      })}
      >
        <CardAutocompleteClient
          formName={formName}
          disabled={!isEmpty(params)}
        />

        <Card mb={6}>
          <CardContent>
            <Typography variant="h4" paragraph>
              {t('Monitoring parameters')}
            </Typography>
            <Field
              name="searchFor"
              id="searchFor"
              label={t('Monitoring for')}
              labelId="searchFor"
              my={2}
              items={itemsSelect}
              fullWidth
              component={Select}
              disabled={!isEmpty(params)}
            />
            <TypographySub variant="subtitle1">
              {t('Classes')}
              {' '}
              {t('ICGS')}
            </TypographySub>

            <Field
              aria-label={formFields.choice}
              name={formFields.choice}
              component={RadioButton}
              row
              disabled={!isEmpty(params)}
            >
              <FormControlLabel value="true" control={<Radio />} label={t('All')} />
              <FormControlLabel value="false" control={<Radio />} label={t('Selected')} />
            </Field>

            {fieldChoice !== 'true' && (
              <Field
                id={formFields.classes}
                name={formFields.classes}
                label={t('Classes')}
                variant="standard"
                fullWidth
                component={RenderTextField}
                disabled={!isEmpty(params)}
              />
            )}

            {fieldSearchFor === 'name' && (
              <>
                <br />
                <br />
                <TypographySub variant="subtitle1">
                  {t('Request type')}
                </TypographySub>
                <Field
                  aria-label={formFields.type}
                  name={formFields.type}
                  component={RadioButton}
                  disabled={!isEmpty(params)}
                  row
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label={(
                      <Grid container alignItems="center">
                        {t('Automatic')}
                        {' '}
                        {/* <Tooltip title={t('test tooltip')} arrow placement="top">
                          <HelpOutline ml={1} fontSize="small" color="primary" />
                        </Tooltip> */}
                      </Grid>
                  )}
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label={(
                      <Grid container alignItems="center">
                        {t('Manual')}
                        {' '}
                        {/* <Tooltip title={t('test tooltip')} arrow placement="top">
                          <HelpOutline ml={1} fontSize="small" color="primary" />
                        </Tooltip> */}
                      </Grid>
                  )}
                  />
                </Field>
              </>
            )}

            {fieldSearchFor === 'name' ? (
              <Field
                id={formFields.word}
                name={formFields.word}
                label={t('Request')}
                variant="outlined"
                margin="normal"
                fullWidth
                multiline
                rows={6}
                component={RenderTextField}
                disabled={!isEmpty(params)}
              />
            ) : (
              <Field
                id="applicantsHolders"
                name="applicantsHolders"
                label={t('The owner/the applicant')}
                margin="normal"
                fullWidth
                component={RenderTextField}
                disabled={!isEmpty(params)}
              />
            )}
          </CardContent>
        </Card>

        {isEmpty(getMonitoringById) && (
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={pendingPostMonitoring || pendingPutMonitoring}
            >
              {pendingPostMonitoring || pendingPutMonitoring
                ? <CircularProgress size={25} color="inherit" />
                : t('SAVE')}
            </Button>
          </Box>
        )}
      </Form>

      {!isEmpty(getMonitoringById) && !isEmpty(getMonitoringById.results) ? (
        <>
          <Box mt={10}>
            <Grid container spacing={6}>
              <Grid container item xs={10} alignItems="center">
                <Typography variant="h4">
                  {t('Results')}
                </Typography>
                {accessReport && (
                  <Box ml={4} mr={2}>
                    <Button
                      size="small"
                      color="primary"
                      disabled={pendingDownloadFile || pendingMonitoringReport}
                      onClick={handleMonitoringReport}
                    >
                      {t(getMonitoringById.pdf ? 'Download to PDF' : 'Unload to PDF')}
                    </Button>
                  </Box>
                )}
                {(pendingMonitoringReport || pendingDownloadFile) && <CircularProgress size={20} color="primary" />}
              </Grid>
              <GridFilter item xs={2}>
                <Tooltip title={t('Filter list')} arrow>
                  <IconButton aria-label="Filter list" size="small" onClick={handleFilterList}>
                    <FilterListIcon />
                  </IconButton>
                </Tooltip>
              </GridFilter>
            </Grid>
          </Box>
          {visibleFilterList && (
            <>
              <Divider my={3} />
              <Form onSubmit={handleSubmit(() => {})}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={3}>
                    <Field
                      id={formFieldsFilter.title}
                      name={formFieldsFilter.title}
                      label={t('Title')}
                      margin="normal"
                      fullWidth
                      component={RenderTextField}
                      onChange={(e) => handleChangeTextInput(formFieldsFilter.title, e)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {!isEmpty(filterList.toJS())
                              && !isEmpty(filterList.toJS()[formFieldsFilter.title]) && (
                              <IconButton
                                onClick={() => clearInput(formFieldsFilter.title)}
                              >
                                <ClearIcon />
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Field
                      id={formFieldsFilter.registrationDate}
                      name={formFieldsFilter.registrationDate}
                      label={t('Registration date')}
                      margin="normal"
                      fullWidth
                      type="text"
                      component={ReduxDateRange}
                      onChange={(e) => handleChangeDateInput(formFieldsFilter.registrationDate, e)}
                    />
                    {!isEmpty(filterList.toJS()) && (
                      <Button
                        mt={2}
                        variant="contained"
                        color="primary"
                        onClick={handleFilterList}
                      >
                        {t('RESET FILTERS')}
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Field
                      id={formFieldsFilter.applicationNumber}
                      name={formFieldsFilter.applicationNumber}
                      label={t('Application number')}
                      margin="normal"
                      fullWidth
                      component={RenderTextField}
                      onChange={(e) => handleChangeTextInput(formFieldsFilter.applicationNumber, e)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {!isEmpty(filterList.toJS()) && !isEmpty(filterList.toJS()[
                              formFieldsFilter.applicationNumber]) && (
                              <IconButton
                                onClick={() => clearInput(formFieldsFilter.applicationNumber)}
                              >
                                <ClearIcon />
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Field
                      id={formFieldsFilter.classesFilter}
                      name={formFieldsFilter.classesFilter}
                      label={t('Classes')}
                      margin="normal"
                      fullWidth
                      component={RenderTextField}
                      onChange={(e) => handleChangeTextInput('classes', e)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {!isEmpty(filterList.toJS())
                              && !isEmpty(filterList.toJS().classes) && (
                              <IconButton
                                onClick={() => {
                                  dispatch(change(formName, formFieldsFilter.classesFilter, null));
                                  setFilterList(remove(filterList, 'classes'));
                                }}
                              >
                                <ClearIcon />
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Field
                      id={formFieldsFilter.registrationNumber}
                      name={formFieldsFilter.registrationNumber}
                      label={t('Registration number')}
                      margin="normal"
                      fullWidth
                      component={RenderTextField}
                      onChange={
                        (e) => handleChangeTextInput(formFieldsFilter.registrationNumber, e)
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {!isEmpty(filterList.toJS()) && !isEmpty(filterList.toJS()[
                              formFieldsFilter.registrationNumber]) && (
                              <IconButton
                                onClick={() => clearInput(formFieldsFilter.registrationNumber)}
                              >
                                <ClearIcon />
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Field
                      id={formFieldsFilter.holder}
                      name={formFieldsFilter.holder}
                      label={t('Owner(s)')}
                      margin="normal"
                      fullWidth
                      component={RenderTextField}
                      onChange={(e) => handleChangeTextInput(formFieldsFilter.holder, e)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {!isEmpty(filterList.toJS())
                              && !isEmpty(filterList.toJS()[formFieldsFilter.holder]) && (
                              <IconButton
                                onClick={() => clearInput(formFieldsFilter.holder)}
                              >
                                <ClearIcon />
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Field
                      id={formFieldsFilter.applicationDate}
                      name={formFieldsFilter.applicationDate}
                      label={t('Date of submission')}
                      margin="normal"
                      fullWidth
                      type="text"
                      component={ReduxDateRange}
                      onChange={(e) => handleChangeDateInput(formFieldsFilter.applicationDate, e)}
                    />
                    <Field
                      name={formFieldsFilter.status}
                      id={formFieldsFilter.status}
                      label={t('Status')}
                      labelId={formFieldsFilter.status}
                      margin="normal"
                      items={filter(objectStatuses.toJS(), (item) => item.id !== 1
                        && item.id !== 2 && item.id !== 4 && item.id !== 5 && item.id !== 6)}
                      fullWidth
                      component={Select}
                      displayEmpty
                      onChange={(e) => handleChangeTextInput(formFieldsFilter.status, e)}
                    />
                  </Grid>
                </Grid>
              </Form>
            </>
          )}

          <Divider my={3} />
          {!pendingFilter ? (
            <>
              <Tabs
                value={valueTab}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label={(
                  <Badge badgeContent={totalCountTab('national')} color="secondary">
                    {t('National procedure')}
                  </Badge>
                )}
                />
                <Tab label={(
                  <Badge badgeContent={totalCountTab('madrid')} color="secondary">
                    {t('Madrid procedure')}
                  </Badge>
                )}
                />
              </Tabs>

              <TabPanel value={valueTab} index={0}>
                <SearchCardTabs nameTab="national" entity="monitoring" disabledButtonChoose={!accessReport} />
              </TabPanel>

              <TabPanel value={valueTab} index={1}>
                <SearchCardTabs nameTab="madrid" entity="monitoring" disabledButtonChoose={!accessReport} />
              </TabPanel>
            </>
          ) : (
            <Root>
              <CircularProgress />
            </Root>
          )}
        </>
      ) : (
        <Grid container justify="center">
          {!isEmpty(getMonitoringById) && (
            <Box m={4}>
              <Typography variant="h4">
                {t('No data')}
              </Typography>
            </Box>
          )}
        </Grid>
      )}
    </>
  );
}

MonitoringDetail.displayName = 'MonitoringDetail';

MonitoringDetail.propTypes = {
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  getMonitoringById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  monitoringTabs: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingPostMonitoring: PropTypes.bool.isRequired,
  pendingPutMonitoring: PropTypes.bool.isRequired,
  pendingMonitoringReport: PropTypes.bool.isRequired,
  fieldChoice: PropTypes.string,
  fieldSearchFor: PropTypes.string,
  match: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  objectStatuses: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingDownloadFile: PropTypes.bool.isRequired,
};

MonitoringDetail.defaultProps = {
  match: {},
  fieldChoice: '',
  fieldSearchFor: '',
};

function mapStateToProps(state) {
  return {
    getMonitoringById: selectors.tradeMarksMonitoring.getMonitoringById(state),
    fieldChoice: selectors.form.getFormValues(state, formName).get(formFields.choice),
    fieldSearchFor: selectors.form.getFormValues(state, formName).get('searchFor'),
    monitoringTabs: selectors.tradeMarksMonitoring.monitoringTabs(state),
    pendingPostMonitoring: selectors.tradeMarksMonitoring.pendingPostMonitoring(state),
    pendingPutMonitoring: selectors.tradeMarksMonitoring.pendingPutMonitoring(state),
    pendingMonitoringReport: selectors.tradeMarksMonitoring.pendingMonitoringReport(state),
    objectStatuses: selectors.helpers.getObjectStatuses(state),
    pendingDownloadFile: selectors.helpers.pendingDownloadFile(state),
  };
}

export default compose(
  reduxForm({
    form: formName,
  }),
  connect(mapStateToProps, null),
)(MonitoringDetail);
