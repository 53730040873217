// Core
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import Helmet from 'react-helmet';
import {
  Field, Form, reduxForm, change,
} from 'redux-form/immutable';
import * as PropTypes from 'prop-types';
import { Link as MuiLink } from 'react-router-dom';

// ui
import {
  Typography, Divider as MuiDivider,
  CardContent, Button, Box,
  Grid, Card as MuiCard, CircularProgress,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';
import { NavigateBefore as NavigateBeforeIcon } from '@material-ui/icons';

// lodash
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';

// parts
import RenderTextField from '../../../ui/Form/RenderTextField';
import DatePickers from '../../../ui/Form/DatePickers';
import FieldClient from '../../../ui/Form/FieldsAutocomplete/FieldClient';
import PaymentDistribution from './components/PaymentDistribution';
import Loader from '../../../components/Loader';
import Select from '../../../ui/Form/Select';

// actions
import paymentsActionsAsync from '../../../engine/core/company/payments/saga/asyncAction';

// config
import selectors from '../../../engine/config/selectors';

// helpers
import { formName, formFields } from './helper/form';
import { pageLinks } from '../../../routes';

// hooks
import { useEventsAutocompleteAsync } from '../../../ui/_hooks/useEventsAutocompleteAsync';
import {
  useSetFieldsAutocomplete,
} from '../../../ui/Form/FieldsAutocomplete/_hook/useSetFieldsAutocomplete';
import accessList from '../../../engine/config/accessList';
import { useAccessList } from '../../../ui/_hooks/useAccessList';

// styles
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Link = styled(MuiLink)`
  text-decoration: underline;
  color: #1976d2;
  display: flex;
  align-items: center;
`;

function Payment(props) {
  const {
    handleSubmit, match, getPaymentDataById,
    pendingPostCreatePayment, initialize,
    pendingGetPaymentById, getPaymentById,
    selectedCompany, allCompanies,
  } = props;
  const {
    getAutocompleteLists, handleGetAsyncData,
  } = useEventsAutocompleteAsync();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const paymentDataById = getPaymentDataById.toJS();
  const paymentById = getPaymentById.toJS();
  const { params } = match;
  const [readyPaymentById, setReadyPaymentById] = useState(false);
  const [readyInitialize, setReadyInitialize] = useState(false);
  const accessPut = useAccessList(accessList.payments_put);
  const accessCompanyList = useAccessList(accessList.company_list_get);
  const accessEdit = !isEmpty(params) ? accessPut : true;

  const { getEntitiesById, isReadyOriginalToUse, isReadyToUse } = useSetFieldsAutocomplete({
    data: paymentDataById,
  });

  const handleSubmitPayment = (formData) => {
    const jsonFormData = formData.toJSON();

    if (!isEmpty(jsonFormData)) {
      const filterFormData = pickBy(jsonFormData, (value, key) => key !== 'createdAt' && key !== 'clientInput');
      dispatch(paymentsActionsAsync[!isEmpty(params)
        ? 'putPaymentByIdAsync'
        : 'postPaymentAsync']({
        ...filterFormData,
        ...filterFormData.client ? {
          client: filterFormData.client.toJS
            ? filterFormData.client.toJS().id : filterFormData.client.id,
        } : {},
      }));
    }
  };

  useEffect(() => {
    if (!isEmpty(params) && !readyPaymentById) {
      setReadyPaymentById(true);
      dispatch(paymentsActionsAsync.getPaymentByIdAsync(params.hashId));
    }
  }, [
    dispatch, params,
    readyPaymentById,
    setReadyPaymentById,
  ]);

  useEffect(() => {
    if (isEmpty(params) && !isEmpty(allCompanies.toJS())) {
      dispatch(change(formName, 'company', selectedCompany || 1));
    }
  }, [dispatch, selectedCompany, params, allCompanies]);

  useEffect(() => {
    if (!readyInitialize && !isEmpty(paymentDataById) && isReadyOriginalToUse && isReadyToUse) {
      setReadyInitialize(true);
      initialize({
        ...paymentDataById,
        ...getEntitiesById,
        ...!isEmpty(paymentDataById.company) ? {
          company: paymentDataById.company.id,
        } : {},
      });
    }
  }, [
    initialize, readyInitialize,
    setReadyInitialize, paymentDataById,
    getEntitiesById, isReadyOriginalToUse, isReadyToUse,
  ]);

  return !pendingGetPaymentById ? (
    <>
      <Helmet title={t('Payments')} />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h3" gutterBottom display="inline">
          {t(!isEmpty(paymentDataById) ? paymentDataById.label : t('Create payment'))}
        </Typography>
        <Link to={pageLinks.company.payments.all}>
          <NavigateBeforeIcon size="small" color="primary" />
          {t('Up to the list')}
        </Link>
      </Box>
      <Divider my={6} />

      <Form onSubmit={handleSubmit(handleSubmitPayment)}>
        <Card mb={6}>
          <CardContent>
            <Typography variant="h4" gutterBottom>
              {t('General information')}
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <FieldClient
                  name={formFields.client}
                  formName={formName}
                  getAutocompleteLists={getAutocompleteLists}
                  getAsyncData={handleGetAsyncData}
                  propsField={{ disabled: !accessEdit }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  id={formFields.paymentDate}
                  labelId={formFields.paymentDate}
                  name={formFields.paymentDate}
                  fullWidth
                  label={t('Date of payment')}
                  component={DatePickers}
                  disabled={!accessEdit}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Field
                  fullWidth
                  name={formFields.sum}
                  label={t('Sum')}
                  margin="normal"
                  component={RenderTextField}
                  disabled={!accessEdit}
                />
              </Grid>

              {accessCompanyList && (
                <Grid item xs={12} md={4}>
                  <Field
                    name="company"
                    id="company"
                    label={t('Legal person')}
                    labelId="company"
                    my={1}
                    items={allCompanies.toJS()}
                    fullWidth
                    component={Select}
                    disabled={!accessEdit}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <Field
                  fullWidth
                  multiline
                  rows={6}
                  name={formFields.description}
                  label={t('Appointment')}
                  margin="normal"
                  variant="outlined"
                  component={RenderTextField}
                  disabled={!accessEdit}
                />
              </Grid>
            </Grid>

            {accessEdit && (
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={pendingPostCreatePayment || paymentById.pendingPut}
              >
                {pendingPostCreatePayment || paymentById.pendingPut
                  ? <CircularProgress size={25} color="inherit" />
                  : t('SAVE')}
              </Button>
            )}
          </CardContent>
        </Card>
      </Form>

      {!isEmpty(paymentDataById) && <PaymentDistribution match={match} />}
    </>
  ) : <Loader />;
}

Payment.propTypes = {
  getPaymentDataById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  getPaymentById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingPostCreatePayment: PropTypes.bool.isRequired,
  pendingGetPaymentById: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  match: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  selectedCompany: PropTypes.string.isRequired,
  allCompanies: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

Payment.defaultProps = {
  match: {},
};

function mapStateToProps(state) {
  return {
    getPaymentDataById: selectors.paymentsTable.getPaymentDataById(state),
    getPaymentById: selectors.paymentsTable.getPaymentById(state),
    pendingGetPaymentById: selectors.paymentsTable.pendingGetPaymentById(state),
    pendingPostCreatePayment: selectors.paymentsTable.pendingPostCreatePayment(state),
    selectedCompany: selectors.helpers.selectedCompany(state),
    allCompanies: selectors.companiesTable.allCompanies(state),
  };
}

export default compose(
  reduxForm({
    form: formName,
  }),
  connect(mapStateToProps, null),
)(Payment);
