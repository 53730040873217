// Core
import { apply, put } from 'redux-saga/effects';
import { saveAs } from 'file-saver';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../config/api';

// actions
import actions from '../../action';

// helpers
import { setErrorMessage } from '../../../../../ui/_helpers/setNotificationMessage';

export function* callGetDownloadExportWorker() {
  yield put(actions.setPendingDownloadExport(true));

  const response = yield apply(api, api.files.getDownloadExport);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      saveAs(data, 'export');
    }
  }

  yield put(actions.setPendingDownloadExport(false));
}
