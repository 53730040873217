// Core
import React, { useMemo, memo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import * as PropTypes from 'prop-types';

// ui
import {
  Typography as MuiTypography,
  CardContent, Box, Button,
  Grid, Card as MuiCard, Avatar, Chip as MuiChip,
  TablePagination as MuiTablePagination,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  blue,
} from '@material-ui/core/colors';
import { spacing } from '@material-ui/system';

// lodash
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';

// actions
import searchesActions from '../../../../engine/core/tradeMarks/searches/action';
import searchesAsyncActions from '../../../../engine/core/tradeMarks/searches/saga/asyncAction';
import monitoringActions from '../../../../engine/core/tradeMarks/monitoring/action';
import monitoringAsyncActions from '../../../../engine/core/tradeMarks/monitoring/saga/asyncAction';

// config
import selectors from '../../../../engine/config/selectors';

// styles
const TablePagination = styled(MuiTablePagination)(spacing);
const Chip = styled(MuiChip)`
  ${spacing}
  
  max-width: 100%;
`;
const CardTabs = styled(MuiCard)`
  margin-top: ${(props) => props.theme.spacing(3)}px;
  &.selected {
    background-color: ${blue['50']};
  }
`;
const Typography = styled(MuiTypography)`
  white-space: normal;
  word-break: break-word;
`;
const TypographySub = styled(Typography)`
  color: ${(props) => props.theme.palette.grey['500']};
`;
const BigAvatar = styled(Avatar)`
  width: 100%;
  height: auto;
  .MuiAvatar-img {
    width: 100%;
    height: 100%;
  }
`;

const GridPreview = styled(Grid)`
  text-align: center;
  overflow: hidden;
  height: 100px;
  font-size: 11px;
`;

function SearchCardTabs(props) {
  const {
    nameTab, searchTabs, objectStatuses, disabledButtonChoose,
    monitoringTabs, entity,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const searchTab = useMemo(() => (
    entity === 'search' ? searchTabs[nameTab] : monitoringTabs[nameTab]
  ), [monitoringTabs, entity, nameTab, searchTabs]);

  const pages = searchTab.items.toJS();
  const resultsTab = pages[searchTab.pageSize];
  const { selectedBlocks } = searchTab;

  const handleSelectBlock = (id) => {
    if (id) {
      const action = entity === 'search' ? searchesActions : monitoringActions;
      dispatch(action.setTabs(nameTab, {
        selectedBlocks: includes(selectedBlocks, id)
          ? filter(selectedBlocks, (blockId) => blockId !== id)
          : [...selectedBlocks, id],
      }));
    }
  };

  const handleChangePageSize = (event, pageSize) => {
    const action = entity === 'search' ? searchesActions : monitoringActions;
    dispatch(action.setTabs(nameTab, { pageSize }));
  };

  const handleChangeRowsPerPage = (event) => {
    const action = entity === 'search' ? searchesAsyncActions : monitoringAsyncActions;
    dispatch(action.setNumberPerPageAsync(
      nameTab, parseInt(event.target.value, 10),
    ));
  };

  const getRowsPerPageOptions = useMemo(() => {
    const { totalCount } = searchTab;
    if (totalCount < 25) {
      return [20];
    }
    if (totalCount <= 50) {
      return [20, 50];
    }
    if (totalCount > 50) {
      return [20, 50, 100];
    }
    return [];
  }, [searchTab]);

  const checkResultStatus = (status, property) => {
    const foundStatusAction = find(objectStatuses.toJS(), { id: Math.abs(status) });
    const options = {
      text: '',
      style: {},
    };
    if (!isEmpty(foundStatusAction)) {
      options.text = foundStatusAction.label;
      options.style = { backgroundColor: foundStatusAction.color, color: 'white' };
    } else {
      options.text = 'status is empty';
    }
    return options[property];
  };

  return (
    <>
      {!isEmpty(resultsTab) ? map(resultsTab, (result) => (
        <CardTabs
          className={includes(selectedBlocks, result.id) ? 'selected' : ''}
          key={result.id || Math.random()}
        >
          <CardContent>
            <Grid container spacing={6}>
              <Grid container item xs={12} sm={3} md={2} justify="center" alignItems="center">
                {result.image ? (
                  <BigAvatar
                    alt="avatar"
                    variant="square"
                    src={result.image}
                  />
                ) : (
                  <GridPreview container justify="center" alignItems="center">
                    {result.title}
                  </GridPreview>
                )}
              </Grid>
              <Grid container item xs={12} sm={7} md={8}>
                <Grid container item xs={12} md={6}>
                  <Grid item xs={6}>
                    <TypographySub variant="subtitle1">
                      {t('Application')}
                    </TypographySub>
                    <Typography variant="body1" paragraph>
                      <strong>{result.applicationNumber}</strong>
                      <br />
                      {result.applicationDate}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TypographySub variant="subtitle1">
                      {t('Evidence')}
                    </TypographySub>
                    <Typography variant="body1" paragraph>
                      <strong>{result.registrationNumber}</strong>
                      <br />
                      {result.registrationDate}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container item xs={12} md={6}>
                  <Grid item xs={6}>
                    <TypographySub variant="subtitle1">
                      {t('Owner(s)')}
                    </TypographySub>
                    <Typography variant="body1" paragraph>
                      {result.holder || result.holders?.join('; ')}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TypographySub variant="subtitle1">
                      {t('ICGS')}
                    </TypographySub>
                    <Typography
                      variant="body1"
                      paragraph
                    >
                      {result.classes}
                    </Typography>
                  </Grid>
                </Grid>

              </Grid>
              <Grid item xs={12} sm={2}>
                <Grid item xs={4} sm={12}>
                  <Box mb={3} mr={1} textAlign="center">
                    <Chip
                      label={t(checkResultStatus(result.status, 'text'))}
                      pb={0.5}
                      pr={3}
                      pl={3}
                      size="small"
                      style={checkResultStatus(result.status, 'style')}
                      {...!result.status ? {
                        variant: 'outlined',
                      } : {}}
                    />
                  </Box>
                </Grid>
                <Grid item xs={4} sm={12}>
                  <Box mb={3} mr={1}>
                    <Button
                      fullWidth
                      target="_blank"
                      href={result.link}
                      size="small"
                      variant="contained"
                    >
                      {t('Detail')}
                    </Button>
                  </Box>
                </Grid>
                {!disabledButtonChoose && (
                  <Grid item xs={4} sm={12}>
                    <Box mb={3} mr={1}>
                      <Button
                        fullWidth
                        size="small"
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                          handleSelectBlock(result.id);
                        }}
                      >
                        {t(includes(selectedBlocks, result.id) ? 'Chosen' : 'Choose')}
                      </Button>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Box mt={4}>
              <Typography variant="body1" paragraph>
                {result.title}
              </Typography>
            </Box>
          </CardContent>
        </CardTabs>
      )) : (
        <Grid container justify="center">
          <Box mt={2}>
            <Typography variant="h4">
              {t('No data')}
            </Typography>
          </Box>
        </Grid>
      )}

      {Boolean(searchTab) && !isEmpty(resultsTab) && (
        <Grid container justify="flex-end">
          <TablePagination
            mt={4}
            component="div"
            count={searchTab.totalCount}
            page={searchTab.pageSize}
            onChangePage={handleChangePageSize}
            rowsPerPage={searchTab.numberPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={t('Rows per page')}
            rowsPerPageOptions={getRowsPerPageOptions}
          />
        </Grid>
      )}
    </>
  );
}

SearchCardTabs.displayName = 'SearchCardTabs';

SearchCardTabs.propTypes = {
  nameTab: PropTypes.string.isRequired,
  searchTabs: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  objectStatuses: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  disabledButtonChoose: PropTypes.bool,
  monitoringTabs: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  entity: PropTypes.string,
};

SearchCardTabs.defaultProps = {
  disabledButtonChoose: false,
  entity: 'search',
};

function mapStateToProps(state) {
  return {
    monitoringTabs: selectors.tradeMarksMonitoring.monitoringTabs(state),
    searchTabs: selectors.tradeMarksSearches.searchTabs(state),
    objectStatuses: selectors.helpers.getObjectStatuses(state),
  };
}

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.nameTab, nextProps.nameTab)
  && isEqual(prevProps.searchTabs, nextProps.searchTabs)
  && isEqual(prevProps.disabledButtonChoose, nextProps.disabledButtonChoose)
  && isEqual(prevProps.monitoringTabs, nextProps.monitoringTabs)
  && isEqual(prevProps.objectStatuses, nextProps.objectStatuses);
}

export default compose(
  connect(mapStateToProps, null),
)(memo(SearchCardTabs, areEqual));
