// Core
import { all, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetIntegrationsWorker,
  callGetIntegrationByIdWorker,
  callPutIntegrationByIdWorker,
  callPostIntegrationWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callDeleteIntegrationWorker,
  callGetAutocompleteOptionsWorker,
  callPostCheckFtpWorker,
} from './workers';
import types from '../types';

function* watchGetIntegrations() {
  yield takeEvery(asyncTypes.GET_INTEGRATIONS_ASYNC, callGetIntegrationsWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_INTEGRATIONS_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchGetIntegrationById() {
  yield takeEvery(asyncTypes.GET_INTEGRATION_BY_ID_ASYNC, callGetIntegrationByIdWorker);
}
function* watchPostIntegration() {
  yield takeEvery(asyncTypes.POST_INTEGRATION_ASYNC, callPostIntegrationWorker);
}
function* watchPutIntegrationById() {
  yield takeEvery(asyncTypes.PUT_INTEGRATION_BY_ID_ASYNC, callPutIntegrationByIdWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_INTEGRATIONS_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_INTEGRATIONS_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_INTEGRATIONS_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_INTEGRATIONS_SORTING, callSetToLocalDataWorker);
}
function* watchSetColumnWidths() {
  yield takeEvery(types.SET_INTEGRATIONS_COLUMN_WIDTHS, callSetToLocalDataWorker);
}
function* watchSetColumnOrder() {
  yield takeEvery(types.SET_INTEGRATIONS_COLUMN_ORDER, callSetToLocalDataWorker);
}
function* watchSetHiddenColumnNames() {
  yield takeEvery(types.SET_INTEGRATIONS_HIDDEN_COLUMN_NAMES, callSetToLocalDataWorker);
}
function* watchDeleteIntegration() {
  yield takeEvery(asyncTypes.DELETE_INTEGRATION_ASYNC, callDeleteIntegrationWorker);
}

function* watchGetAutocompleteOptions() {
  yield takeEvery(
    asyncTypes.GET_INTEGRATIONS_AUTOCOMPLETE_OPTIONS_ASYNC,
    callGetAutocompleteOptionsWorker,
  );
}

function* watchPostCheckFtp() {
  yield takeEvery(asyncTypes.POST_INTEGRATIONS_CHECK_FTP_ASYNC, callPostCheckFtpWorker);
}

export function* watchersIntegrations() {
  yield all([
    watchGetIntegrations(),
    watchGetInitialState(),
    watchGetIntegrationById(),
    watchPostIntegration(),
    watchPutIntegrationById(),
    watchSetCurrentPage(),
    watchSetPageSize(),
    watchSetFilters(),
    watchSetSorting(),
    watchSetColumnWidths(),
    watchSetColumnOrder(),
    watchSetHiddenColumnNames(),
    watchDeleteIntegration(),
    watchGetAutocompleteOptions(),
    watchPostCheckFtp(),
  ]);
}
