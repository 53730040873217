// core
import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import {
  Field, Form, reduxForm, formValueSelector, change,
} from 'redux-form/lib/immutable';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import { Link as MuiLink } from 'react-router-dom';
import { Map, List } from 'immutable';

// lodash
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import filter from 'lodash/filter';
import find from 'lodash/find';

// ui
import {
  Button, CardContent, Divider as MuiDivider, Grid, Box,
  Typography, Card as MuiCard, CircularProgress,
  IconButton as MuiIconButton, FormControlLabel, Radio,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import {
  Add as AddIcon,
  Close as CloseIcon,
  VpnKey as VpnKeyIcon,
  NavigateBefore as NavigateBeforeIcon,
} from '@material-ui/icons';
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { DropzoneArea as DropZoneAreaMui } from 'material-ui-dropzone';

// config
import selectors from '../../../engine/config/selectors';
import accessList from '../../../engine/config/accessList';
import { useAccessList } from '../../../ui/_hooks/useAccessList';

// helpers
import { validators } from '../../../ui/_helpers/validation';

// parts
import Checkbox from '../../../ui/Form/Checkbox';
import Select from '../../../ui/Form/Select';
import renderTextField from '../../../ui/Form/RenderTextField';
import Loader from '../../../components/Loader';
import TransferList from '../../../ui/Form/TransferList';
import RadioButton from '../../../ui/Form/RadioButton';

// routes
import { pageLinks } from '../../../routes';

// actions
import employeesActionAsync from '../../../engine/core/company/employees/saga/asyncAction';
// import uploadFileActionAsync from '../../../engine/core/uploadFile/saga/asyncAction';
import uploadFileAction from '../../../engine/core/uploadFile/action';
import employeesAction from '../../../engine/core/company/employees/action';
import permissionsGroupsAsyncAction from '../../../engine/core/permissionsGroups/saga/asyncAction';
import permissionsGroupsAction from '../../../engine/core/permissionsGroups/action';

// fields redux form
const formSelector = formValueSelector('Employee');

// styles
const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const VpnKey = styled(VpnKeyIcon)(spacing);
const IconButton = styled(MuiIconButton)(spacing);
const DropZoneArea = styled(DropZoneAreaMui)(spacing);

const TypographyAddKey = styled(Typography)`
  color: ${(props) => props.theme.palette.grey['500']};
`;
const Link = styled(MuiLink)`
  text-decoration: underline;
  color: #1976d2;
  display: flex;
  align-items: center;
`;
const TypographySub = styled(Typography)`
  margin-top: 10px;
  color: ${(props) => props.theme.palette.grey['500']};
`;

function Employee(props) {
  const {
    initialize, handleSubmit, valid, pendingEmployeeById, isUserProfile, getCskData,
    pendingPostEmployee, match, getEmployeeById, userInfo, pendingGroups,
    getKeysData, fieldPasswordKey, fieldCsk, pendingPostKeysData, uploadFile, openFieldsCreateKey,
    pendingDeleteKey, pendingPutEmployeeById, getPermissions, permissionsGroupsData,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [readyEmployee, setReadyEmployee] = useState(false);
  const [readyKeys, setReadyKeys] = useState(false);
  const [deleteKeyId, setDeleteKeyId] = useState('');
  const [displayRegNumber, setDisplayRegNumber] = useState(true);
  const [displayPasswordField, setDisplayPasswordField] = useState(false);
  const [allList, setAllList] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const { params } = match;
  const accessPermissions = useAccessList(accessList.permissions_list_get);
  const accessGroupsList = useAccessList(accessList.groups_list_get);
  const accessPut = useAccessList(accessList.employees_put);
  const accessKeyPost = useAccessList(accessList.keys_post);
  const accessKeyList = useAccessList(accessList.keys_list_get);
  const accessEdit = !isEmpty(params) ? accessPut : true;

  useEffect(() => function cleanup() {
    dispatch(employeesAction.setKeysData({ data: List() }));
    const initialState = { items: [], totalCount: 0, pending: false };
    dispatch(permissionsGroupsAction.setPermissionsGroupsData(initialState));
    dispatch(permissionsGroupsAction.setPermissionsData(initialState));
  }, [dispatch]);

  useEffect(() => {
    if (accessGroupsList) dispatch(permissionsGroupsAsyncAction.getListAsync());
    if (accessPermissions) {
      dispatch(permissionsGroupsAsyncAction.getPermissionsAsync({ limit: 1000 }));
    }
  }, [dispatch, accessPermissions, accessGroupsList]);

  useEffect(() => {
    if (!isEmpty(getPermissions.toJS()) && !isEmpty(permissionsGroupsData.toJS().items)) {
      if (!isEmpty(getEmployeeById.toJS().permissions)) {
        dispatch(change('Employee', 'all', `${getEmployeeById.toJS()?.permissions?.[0]?.all}`));
        setAllList(filter(getPermissions.toJS(),
          (item) => !find(getEmployeeById.toJS()?.permissions,
            (elem) => elem?.permission?.id === item.id)));
        setSelectedList(filter(getPermissions.toJS(),
          (item) => find(getEmployeeById.toJS()?.permissions,
            (elem) => elem?.permission?.id === item.id)));
      } else {
        dispatch(change('Employee', 'all', 'false'));
        setAllList(getPermissions.toJS());
      }
    }
  }, [getPermissions, getEmployeeById, permissionsGroupsData, dispatch]);

  const filterKeysData = useMemo(() => (
    !isEmpty(params)
      ? filter(getKeysData.toJS(), (item) => item.users?.id === Number(params.hashId))
      : []
  ), [params, getKeysData]);

  const convertCskToValues = useMemo(() => map(
    getCskData.toJS(), (csk) => ({
      name: csk.issuerCNs[0],
      value: csk.issuerCNs[0],
    }),
  ), [getCskData]);

  const handleSubmits = (formData) => {
    const json = formData.toJS();
    const valueAll = json.all || 'false';
    if (!isEmpty(json)) {
      delete json.passwordKey;
      delete json.csk;
      delete json.company;
      delete json.all;
      delete json.permissions;
      dispatch(employeesActionAsync[isEmpty(params) ? 'postEmployeeAsync' : 'putEmployeeByIdAsync']({
        ...json,
        ...!isEmpty(params) ? {
          id: params.hashId,
        } : {},
        active: json.active ? 1 : 0,
        ...!isEmpty(json.addresses?.[0]) ? {
          addresses: [{
            ...json.addresses[0],
            name: 'postal',
            country: 'ua',
          }],
        } : {},
        ...!isEmpty(selectedList) ? {
          permissions: map(selectedList, (item) => ({ id: item.id, all: valueAll, own: true })),
        } : {},
      }));
      setDisplayPasswordField(false);
    }
  };

  const handleDisplayPasswordField = () => {
    setDisplayPasswordField(true);
  };

  const handleChangeDropZone = (files) => {
    if (!isEmpty(files)) {
      // eslint-disable-next-line no-undef
      const reader = new FileReader();
      reader.onload = (event) => {
        dispatch(uploadFileAction.setUploadFile({
          file: Map({
            link: event.target.result,
            name: files[0].name,
          }),
        }));
      };

      reader.readAsDataURL(files[0]);
    }
  };

  const handleDeleteDropZone = () => {
    dispatch(uploadFileAction.setUploadFile({
      file: Map(),
    }));
  };

  const handleAddKey = () => {
    dispatch(employeesActionAsync.postKeyDataAsync({
      key: uploadFile.toJS().link,
      originalName: uploadFile.toJS().name,
      password: fieldPasswordKey,
      csk: fieldCsk,
      users: !isEmpty(params) ? params.hashId : userInfo.id,
    }));
  };

  const getTitlePage = useCallback(() => {
    if (!isEmpty(getEmployeeById.toJS()) && !isUserProfile) {
      return getEmployeeById.toJS().name;
    }

    if (isUserProfile) {
      return t('User profile');
    }

    return t('Add employee');
  }, [
    t,
    getEmployeeById,
    isUserProfile,
  ]);

  useEffect(() => {
    if (!readyEmployee && isEmpty(getEmployeeById.toJS()) && !isEmpty(params)) {
      setReadyEmployee(true);
      dispatch(employeesActionAsync.getEmployeeByIdAsync(params.hashId));
    }
  }, [
    dispatch, getEmployeeById, params,
    readyEmployee, setReadyEmployee,
  ]);

  useEffect(() => {
    if (!readyKeys && accessKeyList) {
      setReadyKeys(true);
      dispatch(employeesActionAsync.getKeysDataAsync());
    }
  }, [
    dispatch, readyKeys, setReadyKeys, accessKeyList,
  ]);

  useEffect(() => {
    initialize(!isEmpty(getEmployeeById.toJS())
      ? {
        ...getEmployeeById.toJS(),
        ...!isEmpty(getEmployeeById.toJS().addresses?.[0]) ? {
          addresses: [{
            ...getEmployeeById.toJS().addresses[0],
          }],
        } : {},
        all: 'false',
      } : {
        role: '2',
        isAttorney: false,
        active: true,
        all: 'false',
      });
    setDisplayRegNumber(getEmployeeById.toJS().isAttorney);
  }, [
    initialize,
    getEmployeeById,
    setDisplayRegNumber,
  ]);

  return (
    <>
      <Helmet title={getTitlePage()} />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h3" gutterBottom display="inline">
          {getTitlePage()}
        </Typography>
        <Link to={pageLinks.company.employees.all}>
          <NavigateBeforeIcon size="small" color="primary" />
          {t('Up to the list')}
        </Link>
      </Box>

      <Divider my={6} />

      {!pendingEmployeeById && !pendingGroups ? (
        <Form onSubmit={handleSubmit(handleSubmits)}>
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {t('Basic information')}
              </Typography>

              <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                  <Field
                    required
                    validate={validators.required}
                    name="name"
                    id="name"
                    label={t('Full name')}
                    margin="normal"
                    variant="standard"
                    my={2}
                    fullWidth
                    type="text"
                    component={renderTextField}
                    disabled={!accessEdit}
                  />

                  <Field
                    id="phone"
                    name="phone"
                    type="text"
                    label={t('Phone')}
                    variant="standard"
                    margin="normal"
                    fullWidth
                    my={2}
                    component={renderTextField}
                    disabled={!accessEdit}
                  />

                  <Field
                    name="innCode"
                    type="text"
                    id="innCode"
                    label={t('Inn')}
                    margin="normal"
                    variant="standard"
                    my={2}
                    fullWidth
                    component={renderTextField}
                    disabled={!accessEdit}
                  />

                  <Field
                    required
                    validate={validators.email}
                    id="email"
                    name="email"
                    label={t('Email')}
                    variant="standard"
                    margin="normal"
                    type="email"
                    fullWidth
                    my={2}
                    component={renderTextField}
                    disabled={!accessEdit}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="position"
                    type="text"
                    id="position"
                    label={t('Position')}
                    margin="normal"
                    variant="standard"
                    my={2}
                    fullWidth
                    component={renderTextField}
                    disabled={!accessEdit}
                  />

                  <Field
                    required
                    validate={validators.required}
                    id="isAttorney"
                    name="isAttorney"
                    type="checkbox"
                    fullWidth
                    label={t('Attorney')}
                    component={Checkbox}
                    disabled={!accessEdit}
                    onChange={(event) => {
                      setDisplayRegNumber(event.target.checked);
                    }}
                  />

                  {displayRegNumber && (
                    <Field
                      id="regNumber"
                      name="regNumber"
                      type="number"
                      label={t('Patent attorney number')}
                      variant="standard"
                      margin="normal"
                      fullWidth
                      my={2}
                      component={renderTextField}
                      disabled={!accessEdit}
                    />
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" paragraph>
                {t('Address')}
              </Typography>
              <Typography color="textSecondary">
                {t('Postal Address')}
              </Typography>
              <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                  <Field
                    id="addresses[0].city"
                    name="addresses[0].city"
                    label={t('City')}
                    variant="standard"
                    margin="normal"
                    fullWidth
                    my={2}
                    component={renderTextField}
                    disabled={!accessEdit}
                  />
                  <Field
                    id="addresses[0].address"
                    name="addresses[0].address"
                    label={t('Address')}
                    variant="standard"
                    margin="normal"
                    fullWidth
                    my={2}
                    component={renderTextField}
                    disabled={!accessEdit}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    id="addresses[0].postalIndex"
                    name="addresses[0].postalIndex"
                    label={t('Index')}
                    variant="standard"
                    margin="normal"
                    fullWidth
                    my={2}
                    component={renderTextField}
                    disabled={!accessEdit}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" paragraph>
                {t('Authorization')}
              </Typography>
              <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                  <Field
                    required
                    validate={validators.required}
                    name="username"
                    id="username"
                    label={t('Username')}
                    margin="normal"
                    variant="standard"
                    my={2}
                    fullWidth
                    type="text"
                    component={renderTextField}
                    disabled={!accessEdit}
                  />

                  {(!displayPasswordField && !isEmpty(params)) && accessEdit && (
                    <Box mt={2}>
                      <Button size="small" color="primary" onClick={handleDisplayPasswordField}>
                        {t('Change password')}
                      </Button>
                    </Box>
                  )}

                  {(displayPasswordField && !isEmpty(params)) && (
                  <Field
                    required
                    validate={validators.required}
                    name="password"
                    id="password"
                    label={t('Password')}
                    margin="normal"
                    variant="standard"
                    my={2}
                    fullWidth
                    type="password"
                    component={renderTextField}
                    disabled={!accessEdit}
                  />
                  )}

                  <Field
                    required
                    validate={validators.required}
                    id="active"
                    name="active"
                    type="checkbox"
                    label={t('Active')}
                    component={Checkbox}
                    disabled={!accessEdit}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {accessPermissions && accessEdit && (
            <Card mb={6}>
              <CardContent>
                <Typography variant="h6" paragraph>
                  {t('Access rights')}
                </Typography>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <Field
                      required
                      validate={validators.required}
                      name="group"
                      id="group"
                      label={t('Role')}
                      labelId="group"
                      my={2}
                      items={permissionsGroupsData.toJS().items}
                      fullWidth
                      component={Select}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TypographySub variant="subtitle1" gutterBottom>
                    {t('Objects visibility')}
                  </TypographySub>
                  <Field
                    aria-label="all"
                    name="all"
                    component={RadioButton}
                    row
                  >
                    <FormControlLabel value="true" control={<Radio />} label={t('All')} />
                    <FormControlLabel value="false" control={<Radio />} label={t('Own')} />
                  </Field>
                </Grid>
                <TypographySub variant="subtitle1" gutterBottom>
                  {t('Permissions')}
                </TypographySub>
                <TransferList
                  allList={allList}
                  selectedList={selectedList}
                  handleChangeAllList={setAllList}
                  handleChangeSelectedList={setSelectedList}
                />
              </CardContent>
            </Card>
          )}

          {!isEmpty(params) && (
            <Card mb={6}>
              <CardContent>
                <Typography variant="h6" paragraph>
                  {t('Electronic signature')}
                </Typography>

                <MuiList>
                  {map(filterKeysData, (key) => (
                    <ListItem key={key.id}>
                      <VpnKey mr={1} />
                      <Box mr={1}>
                        {`${key.csk} (${key.name})`}
                      </Box>
                      {deleteKeyId === key.id && pendingDeleteKey
                        ? <CircularProgress size={15} color="inherit" /> : (
                          <IconButton
                            aria-label="Delete"
                            size="small"
                            disabled={!accessEdit}
                            onClick={() => {
                              dispatch(employeesActionAsync.deleteKeyAsync(key.id));
                              setDeleteKeyId(key.id);
                            }}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        )}
                    </ListItem>
                  ))}
                </MuiList>

                {accessEdit && (
                  <Box mb={3}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        dispatch(employeesAction.setKeysData({
                          openFieldsCreateKey: !openFieldsCreateKey,
                        }));
                      }}
                    >
                      {t('ADD')}
                      <AddIcon />
                    </Button>
                  </Box>
                )}

                {openFieldsCreateKey && (
                  <>
                    <TypographyAddKey variant="subtitle1" paragraph>
                      {t('Add key')}
                    </TypographyAddKey>
                    <Grid container spacing={6}>
                      <Grid item xs={12} sm={6}>
                        <DropZoneArea
                          showFileNames
                          showPreviews={false}
                          filesLimit={1}
                          maxFileSize={21000000}
                          mt={4}
                          onDelete={handleDeleteDropZone}
                          onChange={handleChangeDropZone}
                        />
                      </Grid>

                      {!isEmpty(convertCskToValues) && (
                        <Grid item xs={12} sm={6}>
                          <Field
                            required
                            validate={validators.required}
                            name="csk"
                            id="csk"
                            label={t('AKCC key')}
                            labelId="csk"
                            my={2}
                            items={convertCskToValues}
                            fullWidth
                            component={Select}
                          />

                          <Field
                            name="passwordKey"
                            id="passwordKey"
                            label={t('Password')}
                            margin="normal"
                            variant="standard"
                            my={2}
                            fullWidth
                            type="password"
                            component={renderTextField}
                          />
                        </Grid>
                      )}
                    </Grid>
                    {accessKeyPost && (
                      <Box mt={4}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleAddKey}
                          disabled={pendingPostKeysData
                          || isEmpty(fieldPasswordKey)
                          || isEmpty(uploadFile.toJS())}
                        >
                          {pendingPostKeysData ? <CircularProgress size={25} color="inherit" /> : t('ADD')}
                        </Button>
                      </Box>
                    )}
                  </>
                )}
              </CardContent>
            </Card>
          )}

          {accessEdit && (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!valid || pendingPostEmployee || pendingPutEmployeeById}
            >
              {pendingPostEmployee || pendingPutEmployeeById
                ? <CircularProgress size={25} color="inherit" />
                : t('SAVE')}
            </Button>
          )}
        </Form>
      ) : <Loader />}
    </>
  );
}

Employee.displayName = 'Employee';

Employee.propTypes = {
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  getCskData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingDeleteKey: PropTypes.bool.isRequired,
  pendingPostEmployee: PropTypes.bool.isRequired,
  pendingEmployeeById: PropTypes.bool.isRequired,
  pendingPostKeysData: PropTypes.bool.isRequired,
  pendingPutEmployeeById: PropTypes.bool.isRequired,
  openFieldsCreateKey: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  getKeysData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  userInfo: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  match: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  uploadFile: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  getEmployeeById: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  isUserProfile: PropTypes.bool,
  fieldPasswordKey: PropTypes.string,
  fieldCsk: PropTypes.string,
  getPermissions: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  permissionsGroupsData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingGroups: PropTypes.bool.isRequired,
};

Employee.defaultProps = {
  match: {},
  getEmployeeById: {},
  isUserProfile: false,
  fieldPasswordKey: '',
  fieldCsk: '',
};

function mapStateToProps(state) {
  return {
    pendingEmployeeById: selectors.employees.pendingEmployeeById(state),
    pendingPostEmployee: selectors.employees.pendingPostEmployee(state),
    pendingPutEmployeeById: selectors.employees.pendingPutEmployeeById(state),
    openFieldsCreateKey: selectors.employees.openFieldsCreateKey(state),
    getCskData: selectors.employees.getCskData(state),
    userInfo: selectors.user.userInfo(state),
    getEmployeeById: selectors.employees.getEmployeeById(state),
    getKeysData: selectors.employees.getKeysData(state),
    fieldPasswordKey: formSelector(state, 'passwordKey'),
    fieldCsk: formSelector(state, 'csk'),
    pendingPostKeysData: selectors.employees.pendingPostKeysData(state),
    pendingDeleteKey: selectors.employees.pendingDeleteKey(state),
    uploadFile: selectors.uploadFile.uploadFile(state),
    getPermissions: selectors.permissionsGroups.getPermissions(state),
    permissionsGroupsData: selectors.permissionsGroups.permissionsGroupsData(state),
    pendingGroups: selectors.permissionsGroups.pending(state),
  };
}

export default compose(
  reduxForm({
    form: 'Employee',
  }),
  connect(mapStateToProps, null),
)(Employee);
