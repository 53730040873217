// core
import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import { Field, Form, reduxForm } from 'redux-form/lib/immutable';

// ui
import {
  TextField,
} from '@material-ui/core';

// lodash
import isEmpty from 'lodash/isEmpty';

// config
import userRoles from '../../../engine/config/userRoles';
import selectors from '../../../engine/config/selectors';

// actions
import employeesActionAsync from '../../../engine/core/company/employees/saga/asyncAction';
import employeesActions from '../../../engine/core/company/employees/action';

// parts
import Select from '../../../ui/Form/Select';
import Modal from '../../../components/Modal/Modal';
import DialogActions from '../../../components/Modal/components/DialogActions';

const renderTextField = ({
  label, input,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <TextField
    label={label}
    autoComplete="off"
    placeholder={label}
    error={touched && invalid}
    helperText={touched && error}
    {...input}
    {...custom}
  />
);

function InviteEmployeeModal(props) {
  const {
    initialize, handleSubmit,
    isModalOpenInviteEmployee,
    pendingPostInviteEmployee,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleOpenCloseModalInviteEmployee = () => {
    dispatch(employeesActions.setEmployees({
      isModalOpen: !isModalOpenInviteEmployee,
    }));
  };

  const handleSubmits = (formData) => {
    const json = formData.toJSON();
    if (!isEmpty(json)) {
      dispatch(employeesActionAsync.postInviteEmployeeAsync(json));
    }
  };

  useEffect(() => {
    initialize({
      role: '1',
    });
  }, [initialize]);

  return (
    <Modal
      title={t('Invite an employee')}
      isModalOpen={isModalOpenInviteEmployee}
      handleCloseModal={handleOpenCloseModalInviteEmployee}
      displayDialogActions={false}
    >
      <Form onSubmit={handleSubmit(handleSubmits)}>
        <Field
          name="email"
          id="email"
          label={t('Email')}
          margin="normal"
          variant="standard"
          my={2}
          fullWidth
          type="email"
          component={renderTextField}
        />

        <Field
          name="role"
          id="role"
          label={t('Role')}
          labelId="role"
          my={2}
          items={userRoles}
          fullWidth
          component={Select}
        />
        <DialogActions
          pending={pendingPostInviteEmployee}
          handleCloseModal={handleOpenCloseModalInviteEmployee}
        />
      </Form>
    </Modal>
  );
}

InviteEmployeeModal.displayName = 'InviteEmployeeModel';

InviteEmployeeModal.propTypes = {
  isModalOpenInviteEmployee: PropTypes.bool.isRequired,
  pendingPostInviteEmployee: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
};

function mapStateToProps(state) {
  return {
    isModalOpenInviteEmployee: selectors.employees.isModalOpenInviteEmployee(state),
    pendingPostInviteEmployee: selectors.employees.pendingPostEmployee(state),
  };
}

export default compose(
  reduxForm({
    form: 'InviteEmployeeForm',
  }),
  connect(mapStateToProps, null),
)(InviteEmployeeModal);
