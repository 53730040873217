/* eslint-disable */
// Core
import localforage from 'localforage';
import {
  includes, isEmpty, map, keys,
} from 'lodash';

import { setInitialPageState } from '../../ui/_helpers/setInitialPageState';

class LocalData {
  constructor() {
    this.localForage = localforage;
    this.keysObj = {
      __KEY__: '__NAME__',
    };
  }

  getItem(key) {
    return this.localForage.getItem(key).catch(console.warn);
  }

  setItem(key, data) {
    this.localForage.setItem(key, data).catch(console.warn);
  }

  setItems(data) {
    const initialPageState = setInitialPageState({});
    map(keys(data), (item) => {
      this.localForage.getItem(item, function(err, value) {
        const values = {
          ...!isEmpty(value) ? {
            ...value,
          } : {
            ...initialPageState,
          },
          ...data[item],
        };
        localforage.setItem(item, values).catch(console.warn);
      });
    });
  }

  removeItem(key) {
    this.localForage.removeItem(key).catch(console.warn);
  }

  clearStorage() {
    this.localForage.clear().catch(console.warn);
  }

  getLength() {
    return this.localForage.length().catch(console.warn);
  }

  getSettings(pathname, length) {
    const listItems = {};
    return this.localForage.iterate((value, key, index) => {// eslint-disable-line
      if (!includes(key, '/edit') && !includes(key, '/new') && !includes(key, 'currentLongMenu')
        && !includes(key, 'visibleSidebar') && !includes(key, 'signatureInfo')
        && !includes(key, 'selectedCompany') && (pathname === key || !isEmpty(value.columnOrder)
        || !isEmpty(value.columnWidths) || !isEmpty(value.hiddenColumnNames))) {
        listItems[`${key}`] = {
          columnOrder: value.columnOrder || [],
          columnWidths: value.columnWidths || [],
          hiddenColumnNames: value.hiddenColumnNames || [],
        };
      }
      if (index === length) {
        return listItems;
      }
    }).catch(console.warn);
  }
}

export default new LocalData();
