// core
import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import { push } from 'connected-react-router';

// ui
import {
  Box,
  Button, ButtonGroup, Divider as MuiDivider,
  Typography,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import { Add as AddIcon } from '@material-ui/icons';

// parts
import PaymentsTable from './PaymentsTable';

// hooks
import { usePayments } from './_hooks/usePayments';
import { useAccessList } from '../../../ui/_hooks/useAccessList';

// config
import accessList from '../../../engine/config/accessList';

// routes
import { pageLinks } from '../../../routes';

// styles
const Divider = styled(MuiDivider)(spacing);

function Payments() {
  usePayments();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hasPaymentCreate = useAccessList(accessList.payments_post);

  const handleGoToCreatePaymentPage = () => {
    dispatch(push(pageLinks.company.payments.new));
  };

  return (
    <>
      <Helmet title={t('Payments')} />
      <Typography variant="h3" gutterBottom display="inline">
        {t('Payments')}
      </Typography>
      <Divider my={6} />

      {hasPaymentCreate && (
        <Box mb={2}>
          <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
            <Button variant="contained" color="primary" fullWidth onClick={handleGoToCreatePaymentPage}>
              {t('ADD')}
              <AddIcon />
            </Button>
          </ButtonGroup>
        </Box>
      )}

      <PaymentsTable />
    </>
  );
}

Payments.displayName = 'Payments';

Payments.propTypes = {};

function mapStateToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, null),
)(Payments);
