// Core
import React, { useEffect, useState, memo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import Helmet from 'react-helmet';
import {
  Field, Form, reduxForm,
} from 'redux-form/immutable';
import * as PropTypes from 'prop-types';
import { Link as MuiLinkRouter } from 'react-router-dom';

// ui
import {
  Typography, Divider as MuiDivider, Tabs, Tab as MuiTab, Badge as MuiBadge,
  CardContent, Box, Button as MuiButton, TextField as MuiTextField,
  Grid as MuiGrid, Card as MuiCard, CircularProgress, Menu, MenuItem,
  Link as MuiLink, InputAdornment, IconButton,
} from '@material-ui/core';
import {
  Search as SearchIcon,
  Clear as ClearIcon,
  NavigateBefore as NavigateBeforeIcon,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';

// lodash
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import isObject from 'lodash/isObject';
import find from 'lodash/find';

// parts
import Loader from '../../../../components/Loader';
import RenderTextField from '../../../../ui/Form/RenderTextField';
import MktuTemplateModal from './components/MktuTemplateModal';
import FieldClient from '../../../../ui/Form/FieldsAutocomplete/FieldClient';
import FieldTradeMarksProposal from '../../../../ui/Form/FieldsAutocomplete/FieldTradeMarksProposal';
import Select from '../../../../ui/Form/Select';
import AllClasses from './components/AllClasses';
import SelectedClass from './components/SelectedClass';

// actions
import reboundsActionAsync from '../../../../engine/core/tradeMarks/rebounds/saga/asyncAction';
import reboundsAction from '../../../../engine/core/tradeMarks/rebounds/action';
import proposalAsyncActions from '../../../../engine/core/tradeMarks/proposal/saga/asyncAction';
import proposalActions from '../../../../engine/core/tradeMarks/proposal/action';
// import clientsAsyncActions from '../../../../engine/core/company/clients/saga/asyncAction';
import clientsActions from '../../../../engine/core/company/clients/action';

// hooks
import { useEventsAutocompleteAsync } from '../../../../ui/_hooks/useEventsAutocompleteAsync';

// config
import selectors from '../../../../engine/config/selectors';
import globalLangMktu from '../../../../engine/config/globalLangMktu';

// helpers
import { formName, formFields, fieldsAutocomplete } from './helper/form';
import { pageLinks } from '../../../../routes';
import accessList from '../../../../engine/config/accessList';
import { useAccessList } from '../../../../ui/_hooks/useAccessList';

// styles
const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Grid = styled(MuiGrid)(spacing);
const TextField = styled(MuiTextField)(spacing);

const Link = styled(MuiLink)`
  border-bottom: 1px dashed;
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
`;

const Badge = styled(MuiBadge)`
  .MuiBadge-badge {
    padding: 0 4px;
    font-size: 10px;
  }
  .MuiBadge-anchorOriginTopRightRectangle {
    right: 3px;
  }
`;

const Tab = styled(MuiTab)`
  min-width: 72px;
`;
const LinkRouter = styled(MuiLinkRouter)`
  text-decoration: underline;
  color: #1976d2;
  display: flex;
  align-items: center;
`;

function Rebound(props) {
  const {
    handleSubmit, initialize, pendingPostRebound,
    getReboundById, match, getSearchRebound,
    pendingPutRebound, pendingReboundById, showTitle,
    selectMktuClasses, getFileById,
    pendingGetFile, visibleTM, tmId,
    mktuClasses, langValue, isModalOpen,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { getAutocompleteLists, handleGetAsyncData } = useEventsAutocompleteAsync();
  const { params } = match;
  const [valueTab, setValueTab] = useState(visibleTM ? 0 : 1);
  const [searchValue, setSearchValue] = useState('');
  const [readyRebound, setReadyRebound] = useState(false);
  const [isInitialize, setIsInitialize] = useState(false);
  const [visibleForm, setVisibleForm] = useState(false);
  const [simpleMenu, setSimpleMenu] = useState(null);
  const pending = pendingPostRebound || pendingPutRebound;
  const accessPut = useAccessList(accessList.mktu_put);
  const accessCreateFile = useAccessList(accessList.mktu_create_files_get);
  const accessClassesList = useAccessList(accessList.mktu_classes_get);
  const accessEdit = !isEmpty(params) ? accessPut : true;

  useEffect(() => {
    if (langValue && accessClassesList) {
      dispatch(proposalAsyncActions.getClassesAsync({
        lang: langValue,
        limit: 100,
        page: 1,
      }));
    }
  }, [dispatch, langValue, accessClassesList]);

  useEffect(() => function cleanup() {
    dispatch(proposalActions.setSelectMktuClasses([]));
    dispatch(reboundsAction.setReboundPdfById({}));
    dispatch(reboundsAction.setReboundById({}));
    dispatch(reboundsAction.setSearchRebound({}));
    dispatch(proposalActions.setProposalData({
      items: [],
      totalCount: 0,
      pending: false,
    }));
    dispatch(clientsActions.setClientById({}));
  }, [dispatch]);

  useEffect(() => {
    if (!readyRebound && isEmpty(getReboundById.toJS()) && !isEmpty(params)) {
      setReadyRebound(true);
      dispatch(reboundsActionAsync.getReboundByIdWorker(params.hashId));
    }
  }, [
    dispatch, getReboundById, params,
    readyRebound, setReadyRebound,
  ]);

  const termsToArray = (terms) => map(terms, (term) => {
    if (isObject(term)) {
      return term.value;
    }
    return term;
  }).join('; ');

  useEffect(() => {
    if (!isEmpty(getReboundById.toJS()) && !isEmpty(mktuClasses.toJS())
      && isEmpty(selectMktuClasses.toJS()) && !visibleTM) {
      const templateClassIdArray = Object.keys(getReboundById.toJS().selected);
      const selectTemplateClass = map(templateClassIdArray, (templateClassId) => {
        const selectClass = find(mktuClasses.toJS(), { id: Number(templateClassId) });
        const termsArray = getReboundById.toJS().selected[templateClassId];
        return ({
          ...selectClass,
          name: termsArray.length === 1 && termsArray[0] === 'selection'
            ? 'All'
            : termsToArray(termsArray),
        });
      });
      dispatch(proposalActions.setSelectMktuClasses(selectTemplateClass));
    }
  }, [
    getReboundById, mktuClasses, visibleTM,
    selectMktuClasses, dispatch,
  ]);

  useEffect(() => {
    if (!isInitialize && !isEmpty(getReboundById.toJS()) && !isEmpty(params)) {
      initialize({
        ...getReboundById.toJS(),
        trademarks: null,
        ...!isEmpty(getReboundById.toJS().trademarks) ? {
          trademarks: getReboundById.toJS().trademarks?.[0],
        } : {},
        choice: 'true',
        lang: getReboundById.toJS().lang || 'ua',
      });
      setIsInitialize(true);
    }
  }, [
    getReboundById,
    initialize, isInitialize,
    params, setIsInitialize,
  ]);

  useEffect(() => {
    if (!isInitialize && isEmpty(params)) {
      initialize({
        choice: 'true',
        lang: 'ua',
      });
      setIsInitialize(true);
    }
  }, [
    initialize, params,
    setIsInitialize, isInitialize,
  ]);

  const handleSubmits = (formData) => {
    const json = formData.toJS();
    delete json.choice;

    if (!isEmpty(selectMktuClasses.toJS())) {
      json.selection = {};
      map(selectMktuClasses.toJS(), (selectMktuClass) => {
        json.selection[selectMktuClass.classNum] = selectMktuClass?.name;
      });
    }
    if (!isEmpty(json)) {
      dispatch(reboundsActionAsync[!isEmpty(params) ? 'putReboundByIdAsync' : 'postReboundAsync']({
        ...json,
        ...!isEmpty(json.clients) ? {
          clients: json.clients.id,
        } : {},
        ...!isEmpty(json.trademarks) ? {
          trademarks: json.trademarks.id,
        } : {},
        ...!visibleTM ? {
          reload: true,
          ...!isEmpty(tmId) ? {
            trademarks: Number(tmId.hashId),
          } : {},
        } : {},
      }));
    }
    if (!visibleTM) {
      setVisibleForm(false);
      setValueTab(1);
    }
  };

  const handleCreateTemplate = () => {
    dispatch(reboundsAction.setModalCreateOpen(true));
  };

  const handleChange = (e, value) => {
    setValueTab(value);
  };

  const handleChangeSearch = (e) => {
    setSearchValue(e.target.value);
    if (isEmpty(e.target.value)) {
      dispatch(reboundsAction.setSearchRebound({}));
    }
  };

  const handleSearch = () => {
    if (!isEmpty(searchValue)) {
      if (!isEmpty(getSearchRebound.toJS()) && !isEmpty(getSearchRebound.toJS().items)) {
        setSearchValue('');
        dispatch(reboundsAction.setSearchRebound({}));
      } else {
        dispatch(reboundsActionAsync.searchReboundAsync({
          search: searchValue,
          lang: langValue,
        }));
      }
    }
  };

  const toggleMenu = (event) => {
    if (!isEmpty(params)) {
      if (!isEmpty(getReboundById.toJS()) && isEmpty(getReboundById.toJS().links)
      && isEmpty(getFileById.toJS())) {
        dispatch(reboundsActionAsync.getReboundPdfByIdWorker(params.hashId));
      }
      setSimpleMenu(event.currentTarget);
    }
  };

  const closeMenu = () => {
    setSimpleMenu(null);
  };

  const handleChangeClick = () => {
    setVisibleForm(true);
    setValueTab(0);
  };

  return !pendingReboundById ? (
    <>
      {showTitle && (
        <>
          <Helmet title={t('Selection ICGS')} />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h3" gutterBottom display="inline">
              {t('Selection ICGS')}
            </Typography>
            <LinkRouter to={pageLinks.tradeMarksRoutes.rebounds.all}>
              <NavigateBeforeIcon size="small" color="primary" />
              {t('Up to the list')}
            </LinkRouter>
          </Box>
          <Divider my={6} />
        </>
      )}

      <Form onSubmit={handleSubmit(handleSubmits)}>
        {((!visibleTM && visibleForm) || visibleTM) && (
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {t('Basic information')}
              </Typography>

              <Grid container spacing={6}>
                <Grid item xs={12} md={4}>
                  <Field
                    id={formFields.title}
                    name={formFields.title}
                    label={t('Title')}
                    variant="standard"
                    margin="normal"
                    fullWidth
                    my={2}
                    component={RenderTextField}
                    disabled={!accessEdit}
                  />

                  {visibleTM && (
                    <FieldClient
                      name={fieldsAutocomplete.clients}
                      formName={formName}
                      getAutocompleteLists={getAutocompleteLists}
                      getAsyncData={handleGetAsyncData}
                      propsField={{ disabled: !accessEdit }}
                    />
                  )}

                  {visibleTM && (
                    <FieldTradeMarksProposal
                      name={fieldsAutocomplete.trademarks}
                      formName={formName}
                      getAutocompleteLists={getAutocompleteLists}
                      getAsyncData={handleGetAsyncData}
                      propsField={{ disabled: !accessEdit }}
                    />
                  )}

                  <Field
                    id={formFields.mktuVersion}
                    name={formFields.mktuVersion}
                    label={t('Version')}
                    variant="standard"
                    margin="normal"
                    fullWidth
                    my={2}
                    component={RenderTextField}
                    disabled={!accessEdit}
                  />

                  <Field
                    name="lang"
                    id="lang"
                    labelId="lang"
                    label={t('Language')}
                    my={1}
                    items={globalLangMktu}
                    fullWidth
                    component={Select}
                    disabled={!accessEdit}
                  />
                </Grid>
                <Grid item xs={12}>
                  {accessEdit && (
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      mr={2}
                    >
                      {pending ? <CircularProgress size={25} color="inherit" /> : t('SAVE')}
                    </Button>
                  )}

                  {visibleTM && !isEmpty(params) && accessEdit
                    ? (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleCreateTemplate}
                      >
                        {t('CREATE TEMPLATE')}
                      </Button>
                    )
                    : null}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}

        {!visibleTM && !visibleForm && accessEdit && (
          <Grid item xs={12} md={3} mb={5}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleChangeClick}
            >
              {t('CHANGE')}
            </Button>
          </Grid>
        )}

        {visibleTM && (
          <Card mb={6}>
            <CardContent>
              <Tabs
                value={valueTab}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab
                  label={(
                    <Badge color="secondary">
                      {t('Classes')}
                    </Badge>
                  )}
                />
                <Tab label={(
                  <Badge color="secondary">
                    {t('Selected')}
                  </Badge>
                )}
                />
              </Tabs>
            </CardContent>
          </Card>
        )}

        {valueTab === 0
          ? (
            <>
              <Grid item xs={12} md={3} mb={5}>
                <TextField
                  name="search"
                  id="search"
                  label={t('Search')}
                  margin="normal"
                  variant="standard"
                  type="text"
                  my={1}
                  value={searchValue}
                  fullWidth
                  onKeyDown={(event) => {
                    // enter
                    if (event.keyCode === 13) {
                      event.preventDefault();
                      handleSearch();
                    }
                  }}
                  onChange={handleChangeSearch}
                  disabled={!accessEdit}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleSearch}
                          disabled={!accessEdit}
                        >
                          {!isEmpty(getSearchRebound.toJS())
                          && !isEmpty(getSearchRebound.toJS().items)
                            ? <ClearIcon />
                            : <SearchIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <AllClasses disabled={!accessEdit} />
            </>
          )
          : null}

        {(valueTab === 1) ? (
          <>
            {accessCreateFile && (
              <Link to="/" onClick={toggleMenu} aria-owns="simple-menu" aria-haspopup="true" mb={4}>
                {t('Download list')}
              </Link>
            )}
            <Menu
              id="simple-menu"
              anchorEl={simpleMenu}
              open={Boolean(simpleMenu)}
              onClose={closeMenu}
            >
              <MenuItem>
                <Button
                  size="small"
                  color="primary"
                  disabled={pendingGetFile}
                  {
                    ...!isEmpty(getReboundById.toJS()) && !isEmpty(getReboundById.toJS().links)
                      ? {
                        href: getReboundById.toJS().links.pdf,
                        target: '_blank',
                        download: true,
                      }
                      : {}
                  }
                  {
                    ...!isEmpty(getFileById.toJS()) ? {
                      href: getFileById.toJS().pdf,
                      target: '_blank',
                      download: true,
                    } : {}
                  }
                  onClick={closeMenu}
                >
                  {pendingGetFile ? <CircularProgress size={25} color="inherit" /> : t('Download to PDF')}
                </Button>
              </MenuItem>
              <MenuItem>
                <Button
                  size="small"
                  color="primary"
                  disabled={pendingGetFile}
                  {
                    ...!isEmpty(getReboundById.toJS()) && !isEmpty(getReboundById.toJS().links)
                      ? {
                        href: getReboundById.toJS().links.docx,
                        target: '_blank',
                        download: true,
                      }
                      : {}
                  }
                  {
                    ...!isEmpty(getFileById.toJS()) ? {
                      href: getFileById.toJS().docx,
                      target: '_blank',
                      download: true,
                    } : {}
                  }
                  onClick={closeMenu}
                >
                  {pendingGetFile ? <CircularProgress size={25} color="inherit" /> : t('Download to DOCX')}
                </Button>
              </MenuItem>
            </Menu>
            {!isEmpty(selectMktuClasses.toJS()) ? (
              <Card my={3}>
                <CardContent>
                  {map(selectMktuClasses.toJS(), (mktuClass) => (
                    <SelectedClass
                      key={mktuClass.id}
                      mktuClass={mktuClass}
                      langValue={langValue}
                    />
                  ))}
                </CardContent>
              </Card>
            ) : null}
          </>
        ) : null}

        <Box mt={2}>
          {accessEdit && (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              mr={2}
            >
              {pending ? <CircularProgress size={25} color="inherit" /> : t('SAVE')}
            </Button>
          )}

          {visibleTM && !isEmpty(params) && accessEdit
            ? (
              <Button
                variant="outlined"
                color="primary"
                onClick={handleCreateTemplate}
              >
                {t('CREATE TEMPLATE')}
              </Button>
            )
            : null}
        </Box>
      </Form>

      {isModalOpen && <MktuTemplateModal match={match} />}
    </>
  ) : <Loader />;
}

Rebound.displayName = 'Rebound';

Rebound.propTypes = {
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  getReboundById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingGetFile: PropTypes.bool.isRequired,
  pendingReboundById: PropTypes.bool.isRequired,
  pendingPostRebound: PropTypes.bool.isRequired,
  pendingPutRebound: PropTypes.bool.isRequired,
  visibleTM: PropTypes.bool,
  showTitle: PropTypes.bool,
  tmId: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  match: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  selectMktuClasses: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  getSearchRebound: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  getFileById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  mktuClasses: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  langValue: PropTypes.string,
  isModalOpen: PropTypes.bool.isRequired,
};

Rebound.defaultProps = {
  match: {},
  tmId: {},
  visibleTM: true,
  showTitle: true,
  langValue: '',
};

function mapStateToProps(state) {
  return {
    getReboundById: selectors.rebounds.getReboundById(state),
    getFileById: selectors.rebounds.getReboundPdfById(state),
    pendingGetFile: selectors.rebounds.getPendingReboundPdfById(state),
    getSearchRebound: selectors.rebounds.getSearchRebound(state),
    pendingReboundById: selectors.rebounds.getPendingReboundById(state),
    pendingPostRebound: selectors.rebounds.pendingPostRebound(state),
    pendingPutRebound: selectors.rebounds.pendingPutRebound(state),
    isModalOpen: selectors.rebounds.isModalCreateOpen(state),

    selectMktuClasses: selectors.tradeMarksProposal.selectMktuClasses(state),
    mktuClasses: selectors.tradeMarksProposal.classes(state),

    langValue: selectors.form.getFormValues(state, formName).get('lang'),
  };
}

export default compose(
  reduxForm({
    form: formName,
  }),
  connect(mapStateToProps, null),
)(memo(Rebound));
