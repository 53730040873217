// Core
import { all, call, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetDocumentTemplatesWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callDeleteDocumentTemplateWorker,
  callPostDocumentTemplatesWorker,
  callGetDocumentTemplatesByIdWorker,
  callPutDocumentTemplatesByIdWorker,
  callGetAutocompleteOptionsWorker,
} from './workers';
import types from '../types';

function* watchGetDocumentTemplates() {
  yield takeEvery(asyncTypes.GET_DOCUMENT_TEMPLATES_ASYNC, callGetDocumentTemplatesWorker);
}
function* watchGetInitialState() {
  yield takeEvery(
    asyncTypes.GET_DOCUMENT_TEMPLATES_INITIAL_STATE_ASYNC,
    callGetInitialStateWorker,
  );
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_DOCUMENT_TEMPLATES_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_DOCUMENT_TEMPLATES_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_DOCUMENT_TEMPLATES_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_DOCUMENT_TEMPLATES_SORTING, callSetToLocalDataWorker);
}
function* watchSetColumnWidths() {
  yield takeEvery(types.SET_DOCUMENT_TEMPLATES_COLUMN_WIDTHS, callSetToLocalDataWorker);
}
function* watchSetColumnOrder() {
  yield takeEvery(types.SET_DOCUMENT_TEMPLATES_COLUMN_ORDER, callSetToLocalDataWorker);
}
function* watchSetHiddenColumnNames() {
  yield takeEvery(types.SET_DOCUMENT_TEMPLATES_HIDDEN_COLUMN_NAMES, callSetToLocalDataWorker);
}

function* watchPostDocumentTemplates() {
  yield takeEvery(asyncTypes.POST_DOCUMENT_TEMPLATES_ASYNC, callPostDocumentTemplatesWorker);
}

function* watchGetDocumentTemplatesById() {
  yield takeEvery(
    asyncTypes.GET_DOCUMENT_TEMPLATES_BY_ID_ASYNC,
    callGetDocumentTemplatesByIdWorker,
  );
}

function* watchDeleteDocumentTemplate() {
  yield takeEvery(asyncTypes.DELETE_DOCUMENT_TEMPLATE_ASYNC, callDeleteDocumentTemplateWorker);
}

function* watchPutDocumentTemplatesById() {
  yield takeEvery(
    asyncTypes.PUT_DOCUMENT_TEMPLATES_BY_ID_ASYNC,
    callPutDocumentTemplatesByIdWorker,
  );
}

function* watchGetAutocompleteOptions() {
  yield takeEvery(
    asyncTypes.GET_DOCUMENT_TEMPLATES_AUTOCOMPLETE_OPTIONS_ASYNC,
    callGetAutocompleteOptionsWorker,
  );
}

export function* watchersDocumentTemplatesTable() {
  yield all([
    call(watchGetDocumentTemplates),
    call(watchDeleteDocumentTemplate),
    call(watchSetCurrentPage),
    call(watchSetPageSize),
    call(watchSetFilters),
    call(watchSetSorting),
    call(watchSetColumnWidths),
    call(watchSetColumnOrder),
    call(watchSetHiddenColumnNames),
    call(watchGetInitialState),
    call(watchPostDocumentTemplates),
    call(watchGetDocumentTemplatesById),
    call(watchPutDocumentTemplatesById),
    call(watchGetAutocompleteOptions),
  ]);
}
