// core
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { Field, Form, reduxForm } from 'redux-form/lib/immutable';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import FileInputComponent from 'react-file-input-previews-base64';
import { Link as MuiLink } from 'react-router-dom';

// lodash
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import map from 'lodash/map';
import isNil from 'lodash/isNil';
import pullAt from 'lodash/pullAt';

// ui
import {
  Button as MuiButton, CardContent, Divider as MuiDivider, Grid, Box,
  Typography, Card as MuiCard, Avatar, TextField as MuiTextField, CircularProgress,
  IconButton as MuiIconButton,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import {
  CloudUpload as MuiCloudUpload, Edit,
  NavigateBefore as NavigateBeforeIcon,
  Close as CloseIcon, Add as AddIcon,
} from '@material-ui/icons';

// config
import accessList from '../../../engine/config/accessList';

// helpers
import { validators } from '../../../ui/_helpers/validation';
import { pageLinks } from '../../../routes';

// parts
import Loader from '../../../components/Loader';

// hooks
import { useAccessList } from '../../../ui/_hooks/useAccessList';

// actions
import companyActionAsync from '../../../engine/core/company/profile/saga/asyncAction';
import companyActions from '../../../engine/core/company/profile/action';
import clientsAction from '../../../engine/core/company/clients/action';
import AddClientAddressModal from '../Client/AddClientAddressModal';

// selectors
import selectors from '../../../engine/config/selectors';

// styles
const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);
const CloudUpload = styled(MuiCloudUpload)(spacing);
const IconButton = styled(MuiIconButton)(spacing);
const TypographyFlex = styled(Typography)`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;
const TextField = styled(MuiTextField)`
  height: 45px;
`;
const CenteredContent = styled.div`
  text-align: center;
`;
const BigAvatar = styled(Avatar)`
  width: 100%;
  height: auto;
  .MuiAvatar-img {
    width: 50%;
    height: 100%;
  }
`;

const AvatarDoc = styled(Avatar)`
  width: 100%;
  height: auto;
  .MuiAvatar-img {
    width: 100%;
    height: 100%;
  }
`;
const Link = styled(MuiLink)`
  text-decoration: underline;
  color: #1976d2;
  display: flex;
  align-items: center;
`;

const renderTextField = ({
  label, input,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <TextField
    label={label}
    placeholder={label}
    autoComplete="off"
    error={touched && invalid}
    helperText={touched && error}
    {...input}
    {...custom}
  />
);

function Company(props) {
  const {
    initialize, handleSubmit, pendingCompany, match,
    pendingPutCompany, companyData, pendingPostCompany,
    originalLogo, originalDocHeading, isModalOpenAddress,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [readyCompany, setReadyCompany] = useState(false);
  const [readyInitialize, setReadyInitialize] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [editAddress, setEditAddress] = useState({});
  const { params } = match;
  const accessPut = useAccessList(accessList.company_put);
  const accessCompanyEdit = !isEmpty(params) ? accessPut : true;

  const handleSubmits = (formData) => {
    const json = formData.toJSON();
    delete json.logo;
    delete json.docHeading;
    if (!isEmpty(json)) {
      dispatch(companyActionAsync[isEmpty(params) ? 'postCompanyAsync' : 'putCompanyByIdAsync']({
        ...json,
        ...companyData.logo && isObject(companyData.logo) && !isEmpty(companyData.logo) ? {
          ...companyData.logo.link !== originalLogo ? {
            logo: companyData.logo.link,
          } : {},
        } : {
          logo: '',
        },
        ...companyData.docHeading && isObject(companyData.docHeading)
          && !isEmpty(companyData.docHeading) ? {
            ...companyData.docHeading.link !== originalDocHeading ? {
              docHeading: companyData.docHeading.link,
            } : {},
          } : {
            docHeading: '',
          },
        ...json.phone ? {
          phone: [json.phone],
        } : {},
        ...!isEmpty(addresses) ? {
          addresses,
        } : {},
      }));
    }
  };

  const handleUploadLogo = (fileArr) => {
    dispatch(companyActions.setCompanyById({
      data: {
        ...companyData,
        logo: {
          ...companyData.logo,
          link: fileArr[0].base64,
        },
      },
    }));
  };

  const handleUploadDocHeading = (fileArr) => {
    dispatch(companyActions.setCompanyById({
      data: {
        ...companyData,
        docHeading: {
          ...companyData.docHeading,
          link: fileArr[0].base64,
        },
      },
    }));
  };

  const handleDeleteImg = (key) => {
    dispatch(companyActions.setCompanyById({
      data: {
        ...companyData,
        [key]: {},
      },
    }));
  };

  useEffect(() => {
    if (!readyCompany && isEmpty(companyData) && !isEmpty(params)) {
      setReadyCompany(true);
      dispatch(companyActionAsync.getCompanyByIdAsync(params.hashId));
    }
  }, [
    dispatch, companyData, params,
    readyCompany, setReadyCompany,
  ]);

  useEffect(() => function cleanup() {
    dispatch(companyActions.setCompanyById({ data: {} }));
    dispatch(companyActions.setOriginalLogo(''));
    dispatch(companyActions.setOriginalDocHeading(''));
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(companyData) && !pendingCompany && !readyInitialize) {
      initialize({
        ...companyData,
        phone: !isEmpty(companyData.phone) ? companyData.phone[0] : '',
      });
      if (!isEmpty(companyData.addresses)) setAddresses(companyData.addresses);
      setReadyInitialize(true);
    }
  }, [
    initialize, companyData, pendingCompany,
    setReadyInitialize, readyInitialize,
  ]);

  const handleAddAddress = () => {
    dispatch(clientsAction.setIsModalOpenAddress(true));
  };

  const handleSetAddresses = (element, index) => {
    const newAddresses = !isNil(index)
      ? map(addresses, (item, i) => (i === index ? element : item))
      : [...addresses, element];
    setAddresses(newAddresses);
    setEditAddress({});
    dispatch(clientsAction.setIsModalOpenAddress(false));
  };

  const handleDelete = (index) => {
    const newList = [...addresses];
    pullAt(newList, index);
    setAddresses(newList);
  };

  const handleEdit = (elem, index) => {
    setEditAddress({
      ...elem,
      index,
    });
    dispatch(clientsAction.setIsModalOpenAddress(true));
  };

  return (
    <>
      <Helmet title={t('Company')} />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h3" gutterBottom display="inline">
          {t('Company')}
        </Typography>
        <Link to={pageLinks.company.companies.all}>
          <NavigateBeforeIcon size="small" color="primary" />
          {t('Up to the list')}
        </Link>
      </Box>
      <Divider my={6} />

      {isModalOpenAddress && (
        <AddClientAddressModal
          handleSetAddresses={handleSetAddresses}
          editAddress={editAddress}
        />
      )}

      {!pendingCompany ? (
        <Form onSubmit={handleSubmit(handleSubmits)}>
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {t('Basic information')}
              </Typography>

              <Grid container spacing={6}>
                <Grid item xs={12} md={4}>
                  <Field
                    required
                    validate={validators.required}
                    name="name"
                    id="name"
                    label={t('Title')}
                    margin="normal"
                    variant="standard"
                    my={2}
                    fullWidth
                    type="text"
                    disabled={!accessCompanyEdit}
                    component={renderTextField}
                  />
                  <Field
                    id="innCode"
                    name="innCode"
                    label={t('Inn Code')}
                    variant="standard"
                    margin="normal"
                    fullWidth
                    my={2}
                    disabled={!accessCompanyEdit}
                    component={renderTextField}
                  />
                  <Field
                    required
                    validate={validators.email}
                    id="email"
                    name="email"
                    label="Email"
                    variant="standard"
                    margin="normal"
                    fullWidth
                    my={2}
                    disabled={!accessCompanyEdit}
                    component={renderTextField}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Field
                    id="director"
                    name="director"
                    label={t('Director')}
                    variant="standard"
                    margin="normal"
                    fullWidth
                    my={2}
                    disabled={!accessCompanyEdit}
                    component={renderTextField}
                  />
                  <Field
                    id="iban"
                    name="iban"
                    label="IBAN"
                    variant="standard"
                    margin="normal"
                    fullWidth
                    my={2}
                    disabled={!accessCompanyEdit}
                    component={renderTextField}
                  />

                  <Field
                    required
                    id="phone"
                    name="phone"
                    label={t('Phone')}
                    margin="normal"
                    fullWidth
                    my={2}
                    disabled={!accessCompanyEdit}
                    component={renderTextField}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <CenteredContent>
                    {!isEmpty(companyData.logo) ? (
                      <>
                        <BigAvatar
                          alt={companyData.logo.filename}
                          variant="square"
                          src={isObject(companyData.logo)
                            ? companyData.logo.link
                            : companyData.logo}
                        />
                        {accessCompanyEdit && (
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            mt={2}
                            onClick={() => handleDeleteImg('logo')}
                          >
                            {t('DELETE')}
                          </Button>
                        )}
                      </>
                    ) : (
                      <>
                        <FileInputComponent
                          name="logo"
                          imagePreview={false}
                          labelText="Select file"
                          labelStyle={{ display: 'none' }}
                          multiple
                          callbackFunction={handleUploadLogo}
                          accept="image/*"
                          buttonComponent={(
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                              disabled={!accessCompanyEdit}
                            >
                              <CloudUpload mr={2} />
                              {' '}
                              {t('UPLOAD')}
                            </Button>
                          )}
                        />
                        <Typography variant="caption" display="block" gutterBottom>
                          For best results, use an image at least 128px by 128px in .jpg
                          format
                        </Typography>
                      </>
                    )}
                  </CenteredContent>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" paragraph>
                {t('Addresses')}
              </Typography>
              {accessCompanyEdit && (
                <Box mt={2} mb={2}>
                  <Button variant="outlined" color="primary" onClick={handleAddAddress}>
                    {t('ADD')}
                    <AddIcon />
                  </Button>
                </Box>
              )}
              {map(addresses, (address, index) => (
                <TypographyFlex key={index}>
                  <Box component="span">
                    <Box
                      component="span"
                      fontWeight="fontWeightBold"
                    >
                      {(address.name === 'postal' || address.name === 'legal' || address.name === 'payment')
                        ? t(address.name) : address.name}
                    </Box>
                    <br />
                    {address?.address ? `${address?.address}, ` : ''}
                    {address?.city ? `${address?.city}, ` : ''}
                    {address?.postalIndex ? `${address?.postalIndex}, ` : ''}
                    {address?.country}
                  </Box>
                  {accessCompanyEdit && (
                    <IconButton
                      ml={4}
                      aria-label="Edit"
                      size="small"
                      onClick={() => {
                        handleEdit(address, index);
                      }}
                    >
                      <Edit fontSize="small" />
                    </IconButton>
                  )}
                  {accessCompanyEdit && (
                    <IconButton
                      aria-label="Delete"
                      size="small"
                      onClick={() => {
                        handleDelete(index);
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  )}
                </TypographyFlex>
              ))}
            </CardContent>
          </Card>

          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {t('Heading documents')}
              </Typography>

              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <CenteredContent>
                    {!isEmpty(companyData.docHeading) ? (
                      <>
                        <AvatarDoc
                          alt={companyData.docHeading?.filename}
                          variant="square"
                          src={isObject(companyData.docHeading)
                            ? companyData.docHeading.link
                            : companyData.docHeading}
                        />
                        {accessCompanyEdit && (
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            mt={2}
                            onClick={() => handleDeleteImg('docHeading')}
                          >
                            {t('DELETE')}
                          </Button>
                        )}
                      </>
                    ) : (
                      <FileInputComponent
                        name="docHeading"
                        imagePreview={false}
                        labelText="Select file"
                        labelStyle={{ display: 'none' }}
                        multiple
                        callbackFunction={handleUploadDocHeading}
                        accept="image/*"
                        buttonComponent={(
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            disabled={!accessCompanyEdit}
                          >
                            <CloudUpload mr={2} />
                            {' '}
                            {t('UPLOAD')}
                          </Button>
                        )}
                      />
                    )}
                  </CenteredContent>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {accessCompanyEdit && (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={pendingPutCompany || pendingPostCompany}
            >
              {pendingPutCompany || pendingPostCompany
                ? <CircularProgress size={25} color="inherit" /> : t('SAVE')}
            </Button>
          )}
        </Form>
      ) : <Loader />}
    </>
  );
}

Company.displayName = 'Company';

Company.propTypes = {
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  companyData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingPutCompany: PropTypes.bool.isRequired,
  pendingPostCompany: PropTypes.bool.isRequired,
  pendingCompany: PropTypes.bool.isRequired,
  isModalOpenAddress: PropTypes.bool.isRequired,
  match: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  originalLogo: PropTypes.string.isRequired,
  originalDocHeading: PropTypes.string.isRequired,
};

Company.defaultProps = {
  match: {},
};

function mapStateToProps(state) {
  return {
    companyData: selectors.companiesTable.getCompanyById(state),
    pendingPutCompany: selectors.companiesTable.pendingPutCompanyById(state),
    pendingPostCompany: selectors.companiesTable.pendingPostCompany(state),
    pendingCompany: selectors.companiesTable.pendingCompanyById(state),
    originalLogo: selectors.companiesTable.originalLogo(state),
    originalDocHeading: selectors.companiesTable.originalDocHeading(state),
    isModalOpenAddress: selectors.clients.isModalOpenAddress(state),
  };
}

export default compose(
  reduxForm({
    form: 'company',
  }),
  connect(mapStateToProps, null),
)(Company);
