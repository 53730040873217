// core
import React, { useState } from 'react';
import styled from 'styled-components';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// ui
import {
  SvgIcon as MuiSvgIcon, Fab, Menu, MenuItem,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { spacing } from '@material-ui/system';
import { ReactComponent as BellIcon } from '../../ui/_helpers/img/bell.svg';
import { ReactComponent as FileAltIcon } from '../../ui/_helpers/img/file-alt.svg';
import accessList from '../../engine/config/accessList';
import { useAccessList } from '../../ui/_hooks/useAccessList';

const MenuMui = styled(Menu)`
  .MuiMenu-paper {
    max-height: ${48 * 5.5}px; 
  }
`;
const FabMui = styled(Fab)`
  ${spacing}
  position: fixed;
  z-index: 999;
  right: ${(props) => props.theme.spacing(7)}px;
  bottom: ${(props) => props.theme.spacing(7)}px;
`;
const SvgIcon = styled(MuiSvgIcon)`
  height: 20px;
  margin-right: 5px;
`;

function AddMenu(props) {
  const {
    entity, handleNewNotification,
    buttonSizeFab, handleNewDocument,
  } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openLongMenu, setOpenLongMenu] = useState(false);
  const accessDocumentsPost = useAccessList(accessList.documents_post);
  const accessPendingActionsPost = useAccessList(accessList.pendingActions_post);

  const handleToggle = (event) => {
    const { currentTarget } = event;
    setOpenLongMenu(!openLongMenu);
    setAnchorEl(!openLongMenu ? currentTarget : null);
  };

  const onNewDocument = () => {
    setAnchorEl(null);
    setOpenLongMenu(false);
    handleNewDocument();
  };

  const onNewNotification = () => {
    setAnchorEl(null);
    setOpenLongMenu(false);
    handleNewNotification();
  };

  return (
    <>
      {(accessDocumentsPost || accessPendingActionsPost) && (
        <FabMui
          size={buttonSizeFab}
          aria-label="more-fab"
          aria-controls="add-menu-fab"
          aria-haspopup="true"
          color="secondary"
          onClick={handleToggle}
        >
          <AddIcon />
        </FabMui>
      )}

      <MenuMui
        id={entity}
        anchorEl={anchorEl}
        keepMounted
        open={openLongMenu}
        onClose={handleToggle}
      >
        {accessDocumentsPost && (
          <MenuItem onClick={onNewDocument}>
            <SvgIcon><FileAltIcon /></SvgIcon>
            {'  '}
            {t('Document')}
          </MenuItem>
        )}
        {accessPendingActionsPost && (
          <MenuItem onClick={onNewNotification}>
            <SvgIcon><BellIcon /></SvgIcon>
            {' '}
            {t('Notification')}
          </MenuItem>
        )}
      </MenuMui>
    </>
  );
}

AddMenu.propTypes = {
  entity: PropTypes.string.isRequired,
  buttonSizeFab: PropTypes.string,
  handleNewDocument: PropTypes.func.isRequired,
  handleNewNotification: PropTypes.func.isRequired,
};

AddMenu.defaultProps = {
  buttonSizeFab: 'medium',
};

function mapStateToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, null),
)(React.memo(AddMenu));
