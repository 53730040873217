// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const asyncActions = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_PAYMENT_ALLOCATIONS_ASYNC, params);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_PAYMENT_ALLOCATIONS_INITIAL_STATE_ASYNC, path);
  },
  deletePaymentAllocationsAsync(id) {
    return actionCreator(asyncTypes.DELETE_PAYMENT_ALLOCATIONS_ASYNC, id);
  },
  setSectionsAsync(data) {
    return actionCreator(asyncTypes.SET_SECTIONS_PAYMENT_ALLOCATIONS_ASYNC, data);
  },
});

export default asyncActions;
