// core
import React, {
  memo, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import {
  Form, reduxForm, change,
} from 'redux-form/immutable';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import { List, Map } from 'immutable';
import * as PropTypes from 'prop-types';

// ui
import {
  CardContent, Grid as MuiGrid, Box, Button as MuiButton,
  Typography as MuiTypography, Card as MuiCard,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';

// lodash
import {
  map, isEmpty, filter, isNil, find,
} from 'lodash';

// helpers
import { formName, formFields } from '../helper/form';
import { formatDate, getValueFormatDate } from '../../../engine/_helpers/formatterDate';
import str2bool from '../../../ui/_helpers/str2bool';

// config
import selectors from '../../../engine/config/selectors';

// ACTIONS
// proposal
import proposalAsyncActions from '../../../engine/core/utilityModels/saga/asyncAction';
import proposalActions from '../../../engine/core/utilityModels/action';

// tradeMarks
import tradeMarksActions from '../../../engine/core/tradeMarks/proposal/action';

// industrialDesigns
import industrialDesignsActions from '../../../engine/core/industrialDesigns/action';

// clients
import clientsActions from '../../../engine/core/company/clients/action';

// applicants
import applicantsActions from '../../../engine/core/applicants/action';

// employees
import employeesActions from '../../../engine/core/company/employees/action';

// uploadFile
import uploadFileAction from '../../../engine/core/uploadFile/action';

// helpers
import helpersActions from '../../../engine/core/helpers/action';

// parts
import Sign from '../../../components/Sign/Sign';
import Loader from '../../../components/Loader';
import CompareWithRegistryModal from '../../../components/CompareWithRegistryModal/CompareWithRegistryModal';
import ConfirmModal from '../../../components/ConfirmModal';
import Send from '../../../components/Send/Send';
import CardMaintainingAndCost from '../../TradeMarks/Proposal/ProposalAdd/components/CardMaintainingAndCost';
import CardPublicationAndAttributes from '../../TradeMarks/Proposal/ProposalAdd/components/CardPublicationAndAttributes';
import CardClient from '../../TradeMarks/Proposal/ProposalAdd/components/CardClient';
import CardRepresentative from '../../TradeMarks/Proposal/ProposalAdd/components/CardRepresentative';
import CardMailingAddress from '../../TradeMarks/Proposal/ProposalAdd/components/CardMailingAddress';
import CardPriority from '../../TradeMarks/Proposal/ProposalAdd/components/CardPriority';
import CardInitialDocuments from '../../TradeMarks/Proposal/ProposalAdd/components/CardInitialDocuments';
import CardFooter from '../../TradeMarks/Proposal/ProposalAdd/components/CardFooter';
import CardApplicantsOwnersAuthor from '../../TradeMarks/Proposal/ProposalAdd/components/CardApplicantsOwnersAuthor';

// hooks
import CardBasicInformation from '../../TradeMarks/Proposal/ProposalAdd/components/CardBasicInformation';
import accessList from '../../../engine/config/accessList';
import { useAccessList } from '../../../ui/_hooks/useAccessList';

// styles
const Card = styled(MuiCard)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Button = styled(MuiButton)(spacing);

function UtilityModelAdd(props) {
  const {
    handleSubmit,
    initialize,
    newApplicantsList,
    newOwnersList,
    newAuthorsList,
    signList,
    pendingPutProposal,
    pendingPostProposal,
    uploadFiles,
    match,
    pendingGetProposalById,
    applicationNumberValues,
    registrationNumberValue,
    priorityTypeValues,
    getNextNumber,
    fieldClient,
    pendingRegistryByNumber,
    isModalOpenConfirm,
    isModalOpenRegistry,
    registryByNumber,
    getProposalById,
    tagsValues,
    postalAddressValues,
    listResponsible,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [fieldValueByNumber, setFieldValueByNumber] = useState('');
  const pending = pendingPutProposal || pendingPostProposal;
  const { params } = match;
  const accessPut = useAccessList(accessList.utility_put);
  const accessGetNextNumber = useAccessList(accessList.utility_list_get);
  const accessEdit = !isEmpty(params) ? accessPut : true;

  useEffect(() => {
    if (!isNil(params.hashId)) {
      dispatch(proposalAsyncActions.getProposalByIdAsync(params.hashId));
    }
  }, [dispatch, params]);

  useEffect(() => {
    if (isEmpty(params) && accessGetNextNumber) {
      dispatch(proposalAsyncActions.getNextNumberAsync({
        field: formFields.caseNumber,
      }));
    }
  }, [dispatch, params, accessGetNextNumber]);

  useEffect(() => {
    if (!isEmpty(getNextNumber) && isEmpty(params)) {
      dispatch(change(formName, formFields.caseNumber, getNextNumber));
      dispatch(proposalActions.setNextNumber(''));
    }
  }, [dispatch, getNextNumber, params]);

  useEffect(() => function cleanup() {
    dispatch(applicantsActions.setNewApplicantsListData([]));
    dispatch(applicantsActions.setNewAuthorsListData([]));
    dispatch(applicantsActions.setNewOwnersListData([]));
    dispatch(applicantsActions.setApplicantsList(List()));
    dispatch(uploadFileAction.setAllUploadFiles([]));
    dispatch(helpersActions.setSignList([]));
    dispatch(helpersActions.setDocumentsType([]));
    dispatch(clientsActions.setClientById({ data: {} }));
    dispatch(employeesActions.setEmployeeById({ data: {} }));
    dispatch(tradeMarksActions.setCompany(Map()));
    dispatch(industrialDesignsActions.setCompany(Map()));
  }, [dispatch]);

  useEffect(() => dispatch(applicantsActions.setApplicantsList(List())), [dispatch]);

  useEffect(() => {
    initialize({
      [formFields.status]: 1,
      eApplication: true,
      data: {
        standardCharacters: 'true',
        priority: {
          type: 'noPriority',
        },
      },
    });
  }, [
    initialize,
  ]);

  const handleCompare = () => {
    if (!isEmpty(applicationNumberValues)) {
      dispatch(proposalAsyncActions.getRegistryByNumberAsync({
        applicationNumber: applicationNumberValues,
        disableInitialize: true,
      }));
      dispatch(helpersActions.setModal({ isModalOpenRegistry: true }));
    } else {
      dispatch(helpersActions.setModal({ isModalOpen: true }));
    }
  };
  const handleChangeProposal = (data) => {
    dispatch(proposalActions.setProposalDataById(data));
  };
  const handleCleanup = () => {
    dispatch(proposalActions.setRegistryByNumber(Map()));
  };

  const handleSubmits = (formData) => {
    const jsFormData = formData.toJS();

    jsFormData.data.applicants = [...newApplicantsList.toJS()];
    if (registrationNumberValue) {
      jsFormData.data.holders = [...newOwnersList.toJS()];
    } else {
      delete jsFormData.data.holders;
    }
    jsFormData.data.inventors = [...newAuthorsList.toJS()];

    if (jsFormData.yearPaid) {
      jsFormData.yearPaid = `${jsFormData.yearPaid}`;
    } else {
      delete jsFormData.yearPaid;
    }

    const documents = [];
    // eslint-disable-next-line array-callback-return
    map(uploadFiles.toJS(), (file, index) => {
      documents.push({
        type: jsFormData.documents[index].type.id,
        ...file.newFile ? {
          fileId: file.fileId,
        } : {
          docId: file.id,
        },
      });
    });
    const responsible = [];
    // eslint-disable-next-line array-callback-return
    map(listResponsible.toJS(), (item, index) => {
      const { documentClassifier } = jsFormData.responsible[index];
      responsible.push({
        employeeId: item?.id,
        documentClassifier: find(documentClassifier, { value: 'all' }) ? null : map(documentClassifier, 'id'),
      });
    });

    const priority = {
      ...jsFormData.data.priority,
      type: jsFormData.data.priority?.type === 'noPriority' ? undefined : jsFormData.data.priority?.type,
      earlyAppDate: getValueFormatDate(formatDate(jsFormData.data.priority?.earlyAppDate), 'earlyAppDate'),
      exhibitionDate: getValueFormatDate(formatDate(jsFormData.data.priority?.exhibitionDate), 'exhibitionDate'),
    };
    const publication = {
      ...jsFormData.publication,
      date: getValueFormatDate(formatDate(jsFormData.publication?.date), 'date'),
    };
    const data = {
      ...jsFormData,
      ...!isEmpty(jsFormData.tags) ? {
        tags: map(jsFormData.tags, (item) => item.id),
      } : {},
      clients: jsFormData?.clients?.id,
      jurisdictions: jsFormData?.jurisdictions?.id,
      registrationDate: getValueFormatDate(formatDate(jsFormData.registrationDate), 'registrationDate'),
      applicationDate: getValueFormatDate(formatDate(jsFormData.applicationDate), 'applicationDate'),
      renewalDateFrom: getValueFormatDate(formatDate(jsFormData.renewalDateFrom), 'renewalDateFrom'),
      renewalDateTo: getValueFormatDate(formatDate(jsFormData.renewalDateTo), 'renewalDateTo'),
      // expiryDate: getValueFormatDate(formatDate(jsFormData.expiryDate), 'expiryDate'),
      initialPublication: getValueFormatDate(formatDate(jsFormData.initialPublication), 'initialPublication'),
      publication,
      responsible,

      documents,
      data: {
        ...jsFormData.data,
        priority,
        standardCharacters: str2bool(jsFormData.standardCharacters),

        postalAddress: {
          ...jsFormData.data.postalAddress,
          fis: true,

          country: jsFormData.data?.postalAddress?.country?.abbreviation,
        },
        ...!isEmpty(jsFormData.data?.attorney) ? {
          attorney: {
            ...jsFormData.data.attorney,
            ...!isEmpty(jsFormData.data.attorney?.postalAddress) ? {
              postalAddress: {
                ...jsFormData.data.attorney.postalAddress,
                country: jsFormData.data.attorney.postalAddress?.country?.abbreviation,
              },
            } : {},
          },
        } : {},
        ...!isEmpty(jsFormData.usersId) ? {
          idAttorney: jsFormData.usersId?.id,
        } : {},
      },
    };
    delete data.person;
    delete data.applicantClients;
    delete data.applicantOwners;
    delete data.applicantAuthor;
    delete data.usersId;
    delete data.users;
    delete data.mailingAddress;
    delete data.jurisdictionsInput;
    if (params.hashId) {
      dispatch(proposalAsyncActions.putProposalAsync({ id: params.hashId, params: data }));
    } else {
      dispatch(proposalAsyncActions.postProposalAsync(data));
    }
  };
  const handleSign = () => {
    dispatch(helpersActions.setPermissionSign(true));
    handleSubmit();
  };
  return (
    <>
      {pendingGetProposalById ? (
        <Loader />
      ) : (
        <>
          {!isEmpty(params.hashId) && accessEdit && (
            <Grid item xs={12} mb={6}>
              {!isEmpty(applicationNumberValues) && (
                <Button
                  mr={4}
                  variant="outlined"
                  color="primary"
                  onClick={handleCompare}
                >
                  {t('COMPARE WITH THE REGISTRY')}
                </Button>
              )}
            </Grid>
          )}

          {isModalOpenRegistry && (
            <CompareWithRegistryModal
              pendingRegistryByNumber={pendingRegistryByNumber}
              getRegistryByNumber={registryByNumber}
              getProposalById={getProposalById}
              handleChangeProposal={handleChangeProposal}
              handleCleanup={handleCleanup}
            />
          )}

          {isModalOpenConfirm && (
            <ConfirmModal
              buttonSendText=""
              buttonCancelText="CLEARLY"
              description="You must enter an application number!"
            />
          )}

          <Form onSubmit={handleSubmit(handleSubmits)}>
            <CardBasicInformation
              pendingRegistryByNumber={pendingRegistryByNumber}
              pending={pending}
              entityName="utility"
              formFields={formFields}
              formName={formName}
              fieldValueByNumber={fieldValueByNumber}
              setFieldValueByNumber={setFieldValueByNumber}
              labelFieldTitle="Utility design name"
              disabled={!accessEdit}
            />

            <CardPublicationAndAttributes
              pending={pending}
              formFields={formFields}
              disabled={!accessEdit}
            />

            <CardMaintainingAndCost
              entity="utility"
              getProposalById={getProposalById}
              formFields={formFields}
              pending={pending}
              match={match}
              disabled={!accessEdit}
            />

            <CardClient
              name={formFields.clients}
              formName={formName}
              pending={pending}
              disabled={!accessEdit}
            />

            <CardApplicantsOwnersAuthor
              formName={formName}
              pending={pending}
              entityName="utility"
              disabled={!accessEdit}
            />

            <CardRepresentative
              formName={formName}
              getProposalById={getProposalById}
              postalAddressValues={postalAddressValues}
              formFields={formFields}
              pending={pending}
              match={match}
              disabled={!accessEdit}
            />

            <CardMailingAddress
              formFields={formFields}
              pending={pending}
              formName={formName}
              disabled={!accessEdit}
            />

            <CardPriority
              priorityType="second"
              formFields={formFields}
              formName={formName}
              priorityTypeValues={priorityTypeValues}
              pending={pending}
              disabled={!accessEdit}
            />

            <CardInitialDocuments
              formName={formName}
              pending={pending}
              fieldClient={fieldClient}
              disabled={!accessEdit}
            />

            <CardFooter
              pending={pending}
              formFields={formFields}
              formName={formName}
              tagsValues={tagsValues}
              getProposalById={getProposalById}
              match={match}
              objectType="3"
              disabled={!accessEdit}
            />

            {!isEmpty(signList.toJS()) && (
              <Card mb={6}>
                <CardContent>
                  <Typography variant="h2" gutterBottom>
                    {t('Signed')}
                  </Typography>
                  {map(signList.toJS(), (sign, index) => (
                    <Typography key={index} gutterBottom>
                      <Box
                        component="span"
                        fontWeight="fontWeightBold"
                      >
                        {sign.signer || sign.users.name}
                        ,
                        {sign.csk}
                      </Box>
                      {' '}
                      (
                      {sign.date}
                      )
                    </Typography>
                  ))}
                </CardContent>
              </Card>
            )}
          </Form>

          {accessEdit && (
            <Grid item xs={12}>
              {isEmpty(signList.toJS()) && (
                <>
                  <Button
                    disabled={isNil(params.hashId)}
                    mr={2}
                    variant="contained"
                    color="primary"
                    onClick={handleSign}
                  >
                    {t('SIGN')}
                  </Button>
                  <Sign
                    type="utility"
                    files={map(filter(uploadFiles.toJS(), 'fileId'), 'fileId')}
                    dataAllFiles={[{
                      id: params.hashId,
                      files: map(filter(uploadFiles.toJS(), 'fileId'), 'fileId'),
                    }]}
                  />
                </>
              )}

              <Send
                disabledButton={isNil(params.hashId) || isEmpty(signList.toJS())
                  || getProposalById.toJS()?.status > 2}
                type="utility"
                entityIds={[params.hashId]}
              />
            </Grid>
          )}
        </>
      )}
    </>
  );
}

UtilityModelAdd.displayName = 'UtilityModelAdd';

UtilityModelAdd.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pendingGetProposalById: PropTypes.bool.isRequired,
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  newApplicantsList: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  signList: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingPutProposal: PropTypes.bool.isRequired,
  uploadFiles: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  match: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  applicationNumberValues: PropTypes.string,
  registrationNumberValue: PropTypes.string,
  priorityTypeValues: PropTypes.string,
  isModalOpenRegistry: PropTypes.bool,
  isModalOpenConfirm: PropTypes.bool,
  newOwnersList: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  newAuthorsList: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingPostProposal: PropTypes.bool,
  pendingRegistryByNumber: PropTypes.bool,
  getNextNumber: PropTypes.string.isRequired,
  fieldClient: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  registryByNumber: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  getProposalById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  tagsValues: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  postalAddressValues: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  listResponsible: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

UtilityModelAdd.defaultProps = {
  match: {},
  fieldClient: {},
  postalAddressValues: {},
  applicationNumberValues: '',
  registrationNumberValue: '',
  priorityTypeValues: '',
  tagsValues: [],
  pendingPostProposal: false,
  pendingRegistryByNumber: false,
  isModalOpenRegistry: false,
  isModalOpenConfirm: false,
};

function mapStateToProps(state) {
  return {
    // utility
    getNextNumber: selectors.utilityModelsProposal.getNextNumber(state),
    signList: selectors.helpers.signList(state),
    pendingPutProposal: selectors.utilityModelsProposal.pendingPutProposal(state),
    pendingGetProposalById: selectors.utilityModelsProposal.pendingGetProposalById(state),
    uploadFiles: selectors.uploadFile.uploadFiles(state),
    pendingPostProposal: selectors.utilityModelsProposal.pendingPostProposal(state),
    pendingRegistryByNumber: selectors.utilityModelsProposal.getPendingRegistryByNumber(state),
    registryByNumber: selectors.utilityModelsProposal.getRegistryByNumber(state),
    getProposalById: selectors.utilityModelsProposal.getProposalById(state),

    // applicants
    newApplicantsList: selectors.applicants.getApplicantNewList(state),
    newOwnersList: selectors.applicants.getOwnersNewList(state),
    newAuthorsList: selectors.applicants.getAuthorsNewList(state),
    listResponsible: selectors.employees.listResponsible(state),

    // fields
    isColorsValues: selectors.form.getFormValues(state, formName).getIn(['data', 'isColors']),
    colorsValues: selectors.form.getFormValues(state, formName).getIn(['data', 'colors']),
    logoValues: selectors.form.getFormValues(state, formName).getIn(['data', 'logo']),
    priorityTypeValues: selectors.form.getFormValues(state, formName).getIn(['data', 'priority', 'type']),
    postalAddressValues: selectors.form.getFormValues(state, formName).getIn(['data', 'postalAddress']),
    applicationNumberValues: selectors.form.getFormValues(
      state, formName,
    ).get(formFields.applicationNumber),
    registrationNumberValue: selectors.form.getFormValues(
      state, formName,
    ).get(formFields.registrationNumber),
    fieldClient: selectors.form.getFormValues(state, formName).get(formFields.clients),
    tagsValues: selectors.form.getFormValues(state, formName).get('tags'),

    isModalOpenRegistry: selectors.helpers.isModalOpenRegistry(state),
    isModalOpenConfirm: selectors.helpers.isModalOpen(state),
  };
}

export default compose(
  reduxForm({
    form: formName,
  }),
  connect(mapStateToProps, null),
)(memo(UtilityModelAdd));
