export default Object.freeze({
  SET_PROPOSAL_DOCUMENTS_OUT_DATA: 'SET_PROPOSAL_DOCUMENTS_OUT_DATA',
  SET_PROPOSAL_DOCUMENTS_OUT_CURRENT_PAGE: 'SET_PROPOSAL_DOCUMENTS_OUT_CURRENT_PAGE',
  SET_PROPOSAL_DOCUMENTS_OUT_PAGE_SIZE: 'SET_PROPOSAL_DOCUMENTS_OUT_PAGE_SIZE',
  SET_PROPOSAL_DOCUMENTS_OUT_FILTERS: 'SET_PROPOSAL_DOCUMENTS_OUT_FILTERS',
  SET_PROPOSAL_DOCUMENTS_OUT_SORTING: 'SET_PROPOSAL_DOCUMENTS_OUT_SORTING',
  SET_PROPOSAL_DOCUMENTS_OUT_INITIAL_STATE: 'SET_PROPOSAL_DOCUMENTS_OUT_INITIAL_STATE',
  SET_PROPOSAL_DOCUMENTS_OUT_RELOAD: 'SET_PROPOSAL_DOCUMENTS_OUT_RELOAD',
  SET_PROPOSAL_DOCUMENTS_OUT_BY_ID: 'SET_PROPOSAL_DOCUMENTS_OUT_BY_ID',
  SET_PENDING_DELETE_DOCUMENTS_OUT: 'SET_PENDING_DELETE_DOCUMENTS_OUT',
  SET_DELETE_DOCUMENTS_DATA_OUT: 'SET_DELETE_DOCUMENTS_DATA_OUT',
  SET_DOCUMENTS_OUT_SELECTED_DOCUMENTS: 'SET_DOCUMENTS_OUT_SELECTED_DOCUMENTS',
  SET_DOCUMENTS_OUT_ENTITY_NAME: 'SET_DOCUMENTS_OUT_ENTITY_NAME',
});
