export default Object.freeze({
  SET_CALCULATIONS_DATA: 'SET_CALCULATIONS_DATA',
  SET_CALCULATIONS_CURRENT_PAGE: 'SET_CALCULATIONS_CURRENT_PAGE',
  SET_CALCULATIONS_PAGE_SIZE: 'SET_CALCULATIONS_PAGE_SIZE',
  SET_CALCULATIONS_FILTERS: 'SET_CALCULATIONS_FILTERS',
  SET_CALCULATIONS_SORTING: 'SET_CALCULATIONS_SORTING',
  SET_CALCULATIONS_INITIAL_STATE: 'SET_CALCULATIONS_INITIAL_STATE',
  SET_CALCULATIONS_RELOAD: 'SET_CALCULATIONS_RELOAD',
  SET_DELETE_CALCULATION: 'SET_DELETE_CALCULATION',
  SET_CALCULATIONS_COLUMN_WIDTHS: 'SET_CALCULATIONS_COLUMN_WIDTHS',
  SET_CALCULATIONS_COLUMN_ORDER: 'SET_CALCULATIONS_COLUMN_ORDER',
  SET_CALCULATIONS_HIDDEN_COLUMN_NAMES: 'SET_CALCULATIONS_HIDDEN_COLUMN_NAMES',
});
