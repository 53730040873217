// Core
import React, {
  useEffect, memo, useMemo, useCallback,
} from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// lodash
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import map from 'lodash/map';

// ui
import {
  CardContent, Button, Card, Box,
} from '@material-ui/core';

// actions
import emailAsyncAction from '../../../../engine/core/email/email/saga/asyncAction';
import helpersActions from '../../../../engine/core/helpers/action';

// parts
import EmailMessages from '../../../Email/Email/EmailMessages';
import TableEntitiesModal from '../../../../components/TableEntitiesModal/TableEntitiesModal';

// config
import selectors from '../../../../engine/config/selectors';

function ProposalEmail(props) {
  const {
    match, filters, actionId, isModalOpenTableEntities,
    reloadTable, selectedEmail, actionName,
  } = props;
  const dispatch = useDispatch();
  const { params } = match;
  const { t } = useTranslation();

  const valueFolder = useMemo(() => {
    const findFilter = find(filters, { columnName: 'folder' });
    return findFilter?.value || 'INBOX';
  }, [filters]);

  const paramsByFilter = useMemo(() => (
    JSON.parse(JSON.stringify(filters)).reduce((acc, { columnName, value }) => {
      acc[columnName] = value;
      return acc;
    }, {})
  ), [filters]);

  const getList = useCallback(() => {
    dispatch(emailAsyncAction.getListMessagesAsync({
      [actionId]: params.hashId,
      ...paramsByFilter,
      folder: valueFolder,
      ...!isEmpty(selectedEmail.toJS()) ? {
        'email.id': selectedEmail.toJS()?.id,
      } : {},
    }));
  }, [
    dispatch, actionId, params, paramsByFilter,
    selectedEmail, valueFolder,
  ]);

  useEffect(() => {
    if (reloadTable) {
      getList();
    }
  }, [reloadTable]);// eslint-disable-line

  const handleSelect = () => {
    dispatch(helpersActions.setModal({ isModalOpenTableEntities: true }));
  };

  const handleSelectLetters = (list) => {
    if (!isEmpty(list)) {
      map(list, (item) => {
        dispatch(emailAsyncAction.postEmailLetterAsync({
          messageId: item?.id,
          [actionName]: [params.hashId],
          action: 'attach',
        }));
      });
      setTimeout(() => {
        getList();
      }, 400);
    }
    dispatch(helpersActions.setModal({ isModalOpenTableEntities: false }));
  };

  return (
    <>
      <Card mb={6}>
        <CardContent>
          <Box mb={3}>
            <Button
              color="primary"
              variant="outlined"
              mb={3}
              onClick={handleSelect}
            >
              {t('SELECT')}
            </Button>
          </Box>

          <EmailMessages match={match} blockRetrievalRequest />
        </CardContent>
      </Card>
      {isModalOpenTableEntities && (
        <TableEntitiesModal
          handleSend={handleSelectLetters}
          titleModal="Select letters"
          entities="emailLetters"
          showTableExtraFilter={false}
        />
      )}
    </>
  );
}

ProposalEmail.displayName = 'ProposalEmail';

ProposalEmail.propTypes = {
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  match: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  actionId: PropTypes.string.isRequired,
  reloadTable: PropTypes.bool.isRequired,
  isModalOpenTableEntities: PropTypes.bool.isRequired,
  selectedEmail: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  actionName: PropTypes.string.isRequired,
};

ProposalEmail.defaultProps = {
  match: {},
};

function mapStateToProps(state) {
  return {
    filters: selectors.email.filters(state),
    selectedEmail: selectors.email.selectedEmail(state),
    reloadTable: selectors.email.reload(state),
    isModalOpenTableEntities: selectors.helpers.isModalOpenTableEntities(state),
  };
}

export default compose(
  connect(mapStateToProps, null),
)(memo(ProposalEmail));
