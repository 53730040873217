// Core
import React, {
  memo, useState, useRef, useEffect,
} from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// lodash
import { isEmpty } from 'lodash';

// UI
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, IconButton } from '@material-ui/core';

// Icons
import { Search as SearchIcon } from '@material-ui/icons';

// actions
import helpersActions from '../../engine/core/helpers/action';

// hooks
import { useEventsAutocompleteAsync } from '../_hooks/useEventsAutocompleteAsync';

// config
import selectors from '../../engine/config/selectors';
import { delayCallTimeout } from '../../engine/config/globalConfig';

function AutocompleteGlobalSearch(props) {
  const {
    handleChange, label, disableLabel, disableIcon, defaultValue,
    entities, helperText, isCleanup, disableClear, disabled,
  } = props;
  const {
    handleGetAsyncData,
  } = useEventsAutocompleteAsync(isCleanup);
  const dispatch = useDispatch();
  const autocompleteGroup = useSelector(selectors.helpers.autocompleteGroup);

  const [inputValue, setInputValue] = useState('');
  const [isSetDefaultValue, setIsSetDefaultValue] = useState(false);
  const [value, setValue] = useState(null);
  const timerId = useRef(0);

  const handleOnInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    if (event?.type === 'change') {
      if (timerId.current) {
        clearTimeout(timerId.current);
      }
      if (newInputValue.length >= 3) {
        timerId.current = setTimeout(() => {
          handleGetAsyncData(newInputValue, 'globalSearch', event, entities);
        }, delayCallTimeout);
      }
    }
    if (isEmpty(newInputValue)) {
      dispatch(helpersActions.setAutocompleteGroupOptions({
        items: [],
      }));
      if (disableClear) handleChange({});
    }
  };

  useEffect(() => {
    if (!isEmpty(defaultValue) && !isSetDefaultValue) {
      setValue(defaultValue);
      setIsSetDefaultValue(true);
    }
  }, [defaultValue, isSetDefaultValue]);

  const handleOnChange = (event, newInputValue) => {
    if (!isEmpty(newInputValue) && typeof newInputValue !== 'string') {
      handleChange(newInputValue);
      if (!disableClear) {
        setValue(null);
        setInputValue('');
      }
    }
  };

  return (
    <Autocomplete
      freeSolo
      options={autocompleteGroup.toJS().items}
      value={value}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        return option?.title || option?.caseNumber;
      }}
      fullWidth
      filterOptions={(options) => options}
      inputValue={inputValue}
      loading={autocompleteGroup.toJS().pendingPost}
      onChange={handleOnChange}
      onInputChange={handleOnInputChange}
      groupBy={(option) => option.groupTitle}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          {...!disableLabel ? {
            label,
          } : {}}
          autoComplete="off"
          variant="standard"
          helperText={helperText}
          placeholder={label}
          onKeyDown={(event) => {
            // enter
            if (event.keyCode === 13) {
              event.preventDefault();
            }
          }}
          InputProps={{
            ...params.InputProps,
            ...!disableIcon ? {
              startAdornment: (
                <IconButton
                  size="small"
                >
                  <SearchIcon />
                </IconButton>
              ),
            } : {},
          }}
        />
      )}
    />
  );
}

AutocompleteGlobalSearch.displayName = 'AutocompleteGlobalSearch';

AutocompleteGlobalSearch.propTypes = {
  label: PropTypes.string,
  helperText: PropTypes.string,
  disableLabel: PropTypes.bool,
  disableIcon: PropTypes.bool,
  disableClear: PropTypes.bool,
  handleChange: PropTypes.func,
  entities: PropTypes.oneOfType([
    PropTypes.array,
  ]),
  isCleanup: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([
    PropTypes.object,
  ]),
};

AutocompleteGlobalSearch.defaultProps = {
  label: '',
  helperText: '',
  disableLabel: false,
  disableIcon: false,
  disableClear: false,
  disabled: false,
  isCleanup: true,
  handleChange: () => {},
  defaultValue: {},
  entities: ['trademarks', 'utility', 'inventions', 'industrial', 'clients', 'documents'],
};

export default memo(AutocompleteGlobalSearch);
