// core
import React, { useEffect } from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import { push } from 'connected-react-router';

// ui
import {
  Button, Divider as MuiDivider,
  Typography, ButtonGroup, Box,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import {
  Add as AddIcon,
} from '@material-ui/icons';

// parts
import OrdersTable from './OrdersTable';

// config
import accessList from '../../../engine/config/accessList';

// routes
import { pageLinks } from '../../../routes';

// hooks
import { useAccessList } from '../../../ui/_hooks/useAccessList';

// hooks
import { useOrders } from './_hooks/useOrders';

// actions
import ordersActions from '../../../engine/core/company/orders/action';
import currenciesAsyncActions from '../../../engine/core/currencies/currenciesList/saga/asyncAction';
import currenciesActions from '../../../engine/core/currencies/currenciesList/action';

// styles
const Divider = styled(MuiDivider)(spacing);

function Orders() {
  useOrders();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hasOrderCreate = useAccessList(accessList.orders_create);
  const accessCurrenciesList = useAccessList(accessList.currencies_list_get);

  const handleGoToCreateOrderPage = () => {
    dispatch(push(pageLinks.company.orders.new));
  };

  useEffect(() => function cleanup() {
    const emptyData = {
      items: [],
      totalCount: 0,
      pending: false,
    };
    dispatch(ordersActions.setOrdersData(emptyData));
    dispatch(currenciesActions.setCurrenciesData(emptyData));
  }, [dispatch]);

  useEffect(() => {
    if (accessCurrenciesList) dispatch(currenciesAsyncActions.getListAsync());
  }, [dispatch, accessCurrenciesList]);

  useEffect(() => function cleanup() {
  }, [dispatch]);

  return (
    <>
      <Helmet title={t('Orders')} />
      <Typography variant="h3" gutterBottom display="inline">
        {t('Orders')}
      </Typography>
      <Divider my={6} />

      {hasOrderCreate && (
        <Box mb={2}>
          <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
            <Button variant="contained" color="primary" fullWidth onClick={handleGoToCreateOrderPage}>
              {t('ADD')}
              <AddIcon />
            </Button>
          </ButtonGroup>
        </Box>
      )}

      <OrdersTable />
    </>
  );
}

Orders.displayName = 'Orders';

Orders.propTypes = {};

function mapStateToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, null),
)(Orders);
