/* eslint-disable */
// Core
import React, { memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { List, Map } from 'immutable';

// lodash
import {
  findIndex, find, map, isEqual, isEmpty,
  isNull, filter, includes, isArray,
} from 'lodash';

// UI
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  TextField, CircularProgress, Chip,
} from '@material-ui/core';

import { delayCallTimeout } from '../../engine/config/globalConfig';

function AutocompleteEmails(props) {
  const {
    multiple, label, getAsyncData, options: getOptions, fieldValue,
    loading, getSelectOption, input, margin, defaultValue, handleChange,
    meta: {touched, invalid, error}, disableEditing, limitTags,
    meta, isGroupBy, handleClearSearch, onClickField,
    disableLabel, required, defaultOpen, disabled,
    ...rest
  } = props;

  const { t } = useTranslation();
  const options = getOptions;

  const [open, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [errorEmail, setErrorEmail] = React.useState('');
  const [value, setValue] = React.useState(multiple ? [] : null);
  const [isSetDefaultValue, setIsSetDefaultValue] = React.useState(false);
  const timerId = React.useRef(0);

  const handleOnInputChange = (event, newInputValue) => {
    if (event?.type === 'change') {
      setInputValue(newInputValue);
      if (includes(newInputValue, ',') || includes(newInputValue, ' ') || includes(newInputValue, ';')) {
        const arrayEmails = newInputValue.match(/\S+[a-z0-9]@[a-z0-9\.]+\.[a-z0-9\.]+/img);
        if (isEmpty(arrayEmails)) {
          setErrorEmail(t('Invalid email address'));
          return;
        }
        const filterEmails = filter(arrayEmails, (item) => !includes(value, item));
        setValue([...value, ...filterEmails]);
        input.onChange([...value, ...filterEmails]);
        setInputValue('');
        return;
      }
      if (timerId.current) {
        clearTimeout(timerId.current);
      }
      if (newInputValue.length >= 3 && !includes(newInputValue, '@') && getAsyncData) {
        timerId.current = setTimeout(() => {
          getAsyncData(newInputValue, input.name, event);
        }, delayCallTimeout);
      }
    }
  };

  const handleOnChange = (event, newInputValue) => {
    setValue(newInputValue);
    getSelectOption(newInputValue);
    input.onChange(newInputValue);
    handleChange(event, newInputValue);
    setInputValue('');
    setErrorEmail('');
  };
  useEffect(() => {
    if (input.value === '') {
      setValue(multiple ? [] : null);
      setIsSetDefaultValue(false);
    }
    if (!isSetDefaultValue) {
      if (List.isList(input.value) || Map.isMap(input.value) ) {
        if (!isEmpty(options)) {
          let currentOptions = multiple ? [] : null;
          if (multiple) {
            currentOptions = map(input.value.toJS(), (option) => {
              return find(options, option);
            });
          } else {
            currentOptions = options[findIndex(options, input.value.toJS())];
          }
          setValue(currentOptions);
          setIsSetDefaultValue(true);
        } else {
          setValue(input.value.toJS());
          setIsSetDefaultValue(true);
        }
      } else if (!isEmpty(input.value)) {
        setValue(multiple && !isArray(input.value) ? [input.value] : input.value);
        setIsSetDefaultValue(true);
      } else if (!isEmpty(defaultValue)) {
        setValue(defaultValue);
        setIsSetDefaultValue(true);
      }
    }
  }, [input, defaultValue]);

  const handleGetOptionSelected = (option, valueItem) => isEqual(valueItem, option);

  const handleEnter = () => {
    setOpen(false);
    if (inputValue && /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(inputValue) && !includes(value, inputValue)) {
      setValue([...value, inputValue]);
      input.onChange([...value, inputValue]);
      setInputValue('');
      setErrorEmail('');
      return;
    }
    if (inputValue && !includes(value, inputValue)) {
      setErrorEmail(t('Invalid email address'));
    }
  };

  const handleOnBlurEmail = () => {
    handleEnter();
    handleClearSearch();
  };
  const listOptions = useMemo(() => {
    const filterList = filter(value, (item) => !includes(options, item));
    return [...options, ...filterList];
  }, [options, value]);

  return (
    <Autocomplete
      options={listOptions}
      value={isNull(fieldValue) ? fieldValue : fieldValue || value}
      multiple={multiple}
      getOptionLabel={(option) => option}
      inputValue={inputValue}
      getOptionSelected={handleGetOptionSelected}
      loading={loading}
      onChange={handleOnChange}
      onInputChange={handleOnInputChange}
      disabled={disabled}
      forcePopupIcon={!isEmpty(options)}
      open={isEmpty(options) ? false : open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      {...limitTags ? { limitTags } : {}}
      onBlur={handleOnBlurEmail}
      ListboxProps={{ style: { maxHeight: '20rem' } }}
      renderTags={(items, getTagProps) => (
        map(items, (option, index) => (
          <Chip
            size="small"
            label={option}
            {...getTagProps({ index })}
          />
        ))
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          {...!disableLabel ? {
            label,
          } : {}}
          {...defaultOpen ? {
            autoFocus: true,
          } : {}}
          required={required}
          placeholder={label}
          margin={margin}
          autoComplete="off"
          error={(touched && invalid) || !!errorEmail}
          helperText={(touched && error) || errorEmail}
          onClick={onClickField}
          onKeyDown={(event) => {
            // enter
            if (event.keyCode === 13) {
              event.preventDefault();
              handleEnter();
            }
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

AutocompleteEmails.defaultProps = {
  multiple: false,
  loading: false,
  isGroupBy: false,
  required: false,
  disableLabel: false,
  disableEditing: false,
  defaultOpen: false,
  disabled: false,
  limitTags: 0,
  label: '',
  options: [],
  input: {
    onChange: () => {},
  },
  meta: {
    touched: false,
    invalid: false,
    error: false,
  },
  handleChange: () => {},
  getSelectOption: () => {},
  handleInputChange: () => {},
  handleClearSearch: () => {},
  onClickField: () => {},
};

export default memo(AutocompleteEmails);
