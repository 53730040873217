// Core
import { call, put } from 'redux-saga/effects';
import { List } from 'immutable';

// config
import { api } from '../../../../../config/api';

// actions
import employeesActions from '../../action';

export function* callGetCskDataWorker() {
  const {
    setCskData,
  } = employeesActions;
  yield put(setCskData({
    pending: true,
  }));

  const data = yield call(api.company.getCsk);
  yield put(setCskData({
    data: List(data),
  }));

  yield put(setCskData({
    pending: false,
  }));
}
