const asyncTypes = Object.freeze({
  GET_DOCUMENT_TYPES_ASYNC: 'GET_DOCUMENT_TYPES_ASYNC',
  GET_DOCUMENT_TYPES_INITIAL_STATE_ASYNC: 'GET_DOCUMENT_TYPES_INITIAL_STATE_ASYNC',
  DELETE_DOCUMENT_TYPE_ASYNC: 'DELETE_DOCUMENT_TYPE_ASYNC',
  POST_DOCUMENT_TYPE_ASYNC: 'POST_DOCUMENT_TYPE_ASYNC',
  GET_DOCUMENT_TYPE_BY_ID_ASYNC: 'GET_DOCUMENT_TYPE_BY_ID_ASYNC',
  PUT_DOCUMENT_TYPE_BY_ID_ASYNC: 'PUT_DOCUMENT_TYPE_BY_ID_ASYNC',
  GET_DOCUMENT_TYPES_AUTOCOMPLETE_OPTIONS_ASYNC: 'GET_DOCUMENT_TYPES_AUTOCOMPLETE_OPTIONS_ASYNC',
});

export default asyncTypes;
