export default Object.freeze({
  SET_EMPLOYEES_DATA: 'SET_EMPLOYEES_DATA',
  SET_EMPLOYEES_CURRENT_PAGE: 'SET_EMPLOYEES_CURRENT_PAGE',
  SET_EMPLOYEES_PAGE_SIZE: 'SET_EMPLOYEES_PAGE_SIZE',
  SET_EMPLOYEES_FILTERS: 'SET_EMPLOYEES_FILTERS',
  SET_EMPLOYEES_SORTING: 'SET_EMPLOYEES_SORTING',
  SET_EMPLOYEES_INITIAL_STATE: 'SET_EMPLOYEES_INITIAL_STATE',
  SET_EMPLOYEES_RELOAD: 'SET_EMPLOYEES_RELOAD',
  SET_EMPLOYEES_COLUMN_WIDTHS: 'SET_EMPLOYEES_COLUMN_WIDTHS',
  SET_EMPLOYEES_COLUMN_ORDER: 'SET_EMPLOYEES_COLUMN_ORDER',
  SET_EMPLOYEES_HIDDEN_COLUMN_NAMES: 'SET_EMPLOYEES_HIDDEN_COLUMN_NAMES',
});
