// Helpers
import actionCreator from '../../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const asyncActions = Object.freeze({
  getListAsyncModal(params) {
    return actionCreator(asyncTypes.GET_DEPARTMENT_DOCUMENTS_ASYNC_MODAL, params);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_DEPARTMENT_DOCUMENTS_INITIAL_STATE_ASYNC_MODAL, path);
  },
});

export default asyncActions;
