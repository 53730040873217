// core
import React, { useState } from 'react';
import styled, { withTheme } from 'styled-components';
import { useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { darken } from 'polished';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';

// lodash
import { isEmpty } from 'lodash';

// UI
import {
  Grid,
  Hidden,
  Menu,
  MenuItem,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
} from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import {
  Power,
} from 'react-feather';

// routes
import { pageLinks } from '../routes';
import i18n, { locale } from '../engine/init/i18n';

// actions
import userActionAsync from '../engine/core/user/saga/asyncAction';
import helpersActions from '../engine/core/helpers/action';

// parts
import RouterBreadcrumbs from '../ui/RouterBreadcrumbs/RouterBreadcrumbs';
import AutocompleteGlobalSearch from '../ui/Form/AutocompleteGlobalSearch';
import NotificationsDropdown from './NotificationsDropdown';
import SelectCompany from './SelectCompany';
import CheckDocuments from './CheckDocuments/CheckDocuments';

// hooks
import { useAccessList } from '../ui/_hooks/useAccessList';

// config
import accessList from '../engine/config/accessList';

// styles
const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

// const Indicator = styled(Badge)`
//   .MuiBadge-badge {
//     background: ${(props) => props.theme.header.indicator.background};
//     color: ${(props) => props.theme.palette.common.white};
//   }
// `;

const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 200px;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    display: block;
  }
`;

// const SearchIconWrapper = styled.div`
//   width: 50px;
//   height: 100%;
//   position: absolute;
//   pointer-events: none;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   svg {
//     width: 22px;
//     height: 22px;
//   }
// `;

// const Input = styled(InputBase)`
//   color: inherit;
//   width: 100%;

//   > input {
//     color: ${(props) => props.theme.header.search.color};
//     padding-top: ${(props) => props.theme.spacing(2.5)}px;
//     padding-right: ${(props) => props.theme.spacing(2.5)}px;
//     padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
//     padding-left: ${(props) => props.theme.spacing(12)}px;
//     width: 160px;
//   }
// `;

const Flag = styled.img`
  border-radius: 50%;
  width: 22px;
  height: 22px;
`;

function LanguageMenu() {
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [currentLang, setCurrentLang] = useState(i18n.language);
  const dispatch = useDispatch();
  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = (lang) => (
    () => {
      if (lang && i18n.language !== lang) {
        setCurrentLang(locale[lang]);
        dispatch(userActionAsync.changeCurrentLanguage(locale[lang]));
      }
      setAnchorMenu(null);
    });

  function checkIsMenuItemSelected(lang) {
    return lang && i18n.language === locale[lang];
  }

  return (
    <>
      <IconButton
        aria-owns={anchorMenu ? 'menu-appbar' : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
      >
        {currentLang === 'en' ? (
          <Flag src="/img/flags/us.png" alt="English" />
        ) : (
          <Flag src="/img/flags/ua.png" alt="English" />
        ) }

      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu()}
      >
        <MenuItem onClick={closeMenu(locale.en)} selected={checkIsMenuItemSelected(locale.en)}>
          English
        </MenuItem>
        <MenuItem onClick={closeMenu(locale.ua)} selected={checkIsMenuItemSelected(locale.ua)}>
          Українська
        </MenuItem>
      </Menu>
    </>
  );
}

function UserMenu() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [anchorMenu, setAnchorMenu] = useState(null);
  const accessUsersPut = useAccessList(accessList.users_put);

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleGoToProfileUser = () => {
    dispatch(push(pageLinks.profile.user));
    closeMenu();
  };
  const handleSignOut = () => {
    dispatch(userActionAsync.logOutAsync(true));
    setAnchorMenu(null);
  };

  return (
    <>
      <IconButton
        aria-owns={anchorMenu ? 'menu-appbar' : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
      >
        <Power />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        {accessUsersPut && (
          <MenuItem onClick={handleGoToProfileUser}>
            {t('Profile')}
          </MenuItem>
        )}
        <MenuItem onClick={handleSignOut}>
          {t('Sign out')}
        </MenuItem>
      </Menu>
    </>
  );
}

const Header = ({ onDrawerToggle, visibleSidebar }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const accessCheckPost = useAccessList(accessList.documents_check_post);
  const accessSearch = useAccessList(accessList.search_post_global);
  const accessCompanyList = useAccessList(accessList.company_list_get);

  const handleOnChange = (newInputValue) => {
    if (!isEmpty(newInputValue) && typeof newInputValue !== 'string') {
      switch (newInputValue.fieldName) {
        case 'trademarksId': {
          dispatch(push(pageLinks.tradeMarksRoutes.proposal.edit(newInputValue.id)));
          break;
        }
        case 'utilityId': {
          dispatch(push(pageLinks.utilityModels.edit(newInputValue.id)));
          break;
        }
        case 'inventionsId': {
          dispatch(push(pageLinks.inventions.edit(newInputValue.id)));
          break;
        }
        case 'industrialId': {
          dispatch(push(pageLinks.industrialDesigns.edit(newInputValue.id)));
          break;
        }
        case 'clientsId': {
          dispatch(push(pageLinks.company.clients.edit(newInputValue.id)));
          break;
        }
        case 'documentsId': {
          dispatch(push(pageLinks.documents.departmentDocuments.edit(newInputValue.id)));
          break;
        }
        default:
      }
      dispatch(helpersActions.setAutocompleteGroupOptions({
        items: [],
      }));
    }
  };
  return (
    <>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Hidden mdUp={visibleSidebar}>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item>
              <RouterBreadcrumbs />
            </Grid>
            {accessCheckPost && (
              <Grid item>
                <CheckDocuments iconButton />
              </Grid>
            )}

            <Grid item xs />
            {accessCompanyList && (
              <Grid item>
                <SelectCompany />
              </Grid>
            )}
            {accessSearch && (
              <Grid item>
                <Search>
                  <AutocompleteGlobalSearch
                    handleChange={handleOnChange}
                    label={t('Search')}
                    disableLabel
                  />
                </Search>
              </Grid>
            )}
            <Grid item>
              <NotificationsDropdown />
              <LanguageMenu />
              <UserMenu />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};

Header.propTypes = {
  visibleSidebar: PropTypes.bool.isRequired,
  onDrawerToggle: PropTypes.func.isRequired,
};

export default withTheme(Header);
