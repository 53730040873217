// Core
import { call, put } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';

// config
import { api } from '../../../../config/api';

// actions
import action from '../../action';

// helpers
// import { getObjectReverseFormatDate } from '../../../../_helpers/formatterDate';

export function* callGetDocumentTemplatesByIdWorker({ payload: id }) {
  const {
    setDocumentTemplatesById,
    setModalOpen, setOriginalFile,
  } = action;
  yield put(setDocumentTemplatesById({
    pending: true,
  }));

  const response = yield call(api.documentTemplates.getDocumentTemplatesById, id);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    yield put(setDocumentTemplatesById({ data }));
    if (!isEmpty(data.file)) yield put(setOriginalFile(data.file));
    yield put(setModalOpen(true));
  }

  yield put(setDocumentTemplatesById({
    pending: false,
  }));
}
