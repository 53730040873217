// core
import React, { useCallback, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';

// hooks
import { useTranslation } from 'react-i18next';

// library
import {
  isEmpty, isEqual, lowerCase, map, includes, find, uniq,
} from 'lodash';

// styles
import {
  Menu, MenuItem, Fab as MuiFab,
  ListItemIcon as MuiListItemIcon,
  ListItemText, SvgIcon,
} from '@material-ui/core';
import styled from 'styled-components';
import {
  MergeType as MergeTypeIcon, Done as DoneIcon,
  Close as CloseIcon, ToggleOn as ToggleOnIcon,
  ImportExportOutlined, FileCopy, ChromeReaderMode,
} from '@material-ui/icons';
// import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import { spacing } from '@material-ui/system';
import { ReactComponent as FileAltIcon } from '../../../_helpers/img/file-alt.svg';
import { ReactComponent as PenIcon } from '../../../_helpers/img/pen.svg';
import { ReactComponent as TrashAltIcon } from '../../../_helpers/img/trash-alt.svg';
import { ReactComponent as SignatureIcon } from '../../../_helpers/img/signature.svg';
import { ReactComponent as EnvelopeIcon } from '../../../_helpers/img/envelope.svg';

// helpers
import helpersActions from '../../../../engine/core/helpers/action';
import clientsActions from '../../../../engine/core/company/clients/action';

// Components
import TableModal from '../TableModalChange/TableModal';
import TableConfirmModal from '../TableConfirmDelete/TableConfirm';
import { listTablesName } from '../../../../engine/config/listTablesName';
import LinkTheEntityModal from '../../../../pages/Email/MailSettings/LinkTheEntityModal';
import BasicMadridCaseModal from '../../../../pages/TradeMarks/MadridCase/components/BasicMadridCaseModal';

// actions
import pendingActionsAsync from '../../../../engine/core/pendingActions/saga/asyncAction';
import emailActionAsync from '../../../../engine/core/email/email/saga/asyncAction';
import importActionAsync from '../../../../engine/core/importObjects/saga/asyncAction';
import emailActions from '../../../../engine/core/email/email/action';
import madridCaseAction from '../../../../engine/core/tradeMarks/madridCase/action';
import madridCaseAsyncAction from '../../../../engine/core/tradeMarks/madridCase/saga/asyncAction';
import tradeMarksAsyncAction from '../../../../engine/core/tradeMarks/proposal/saga/asyncAction';
import utilityModelsAsyncAction from '../../../../engine/core/utilityModels/saga/asyncAction';
import inventionsAsyncAction from '../../../../engine/core/inventions/saga/asyncAction';
import industrialAsyncAction from '../../../../engine/core/industrialDesigns/saga/asyncAction';

// config
import selectors from '../../../../engine/config/selectors';
import accessList from '../../../../engine/config/accessList';

// const LinkRecruit = styled(Link)`
//   font-size: 16px;
//   border-bottom: 1px dashed;
//   &:hover {
//     text-decoration: none;
//     cursor: pointer;
//   }
// `;

const ListItemIcon = styled(MuiListItemIcon)`
min-width: 30px;
`;

const Fab = styled(MuiFab)`
  ${spacing}
  position: fixed;
  z-index: 999;
  margin-left: -28px;
  bottom: ${(props) => props.theme.spacing(10)}px;
`;

export const formatTableName = (tableName) => {
  if (tableName.includes('_')) {
    return tableName.split('_')[0];
  }

  return tableName;
};

const ChosenCheckboxes = ({
  checkboxes,
  tableName,
  reloadTable,
  fieldsForModal,
  checkboxClose,
  createDocument,
  isModalOpenLinkEntity,
  disableMenu,
  permissions,
  accessEdit,
  accessDelete,
  isModalOpenMadrid,
  rows,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => function clear() {
    dispatch(helpersActions.setCheckboxClear());
  }, [dispatch]);

  // simpleMenu
  const [simpleMenu, setSimpleMenu] = useState(null);

  // Модалка удаление
  const [isConfirmModal, setConfirmModal] = useState(false);

  // Модалка для изменения
  const [isModal, setModal] = useState(false);

  // Блокировка MenuItem когда нет модалки, после нажатия сразу же отп. запрос.
  const [disabled, setDisabled] = useState(false);

  const fnReset = useCallback(() => {
    dispatch(reloadTable(true));
    setSimpleMenu(false);
    dispatch(helpersActions.setCheckboxClear());
    setDisabled(false);
  }, [dispatch, reloadTable]);

  useEffect(() => {
    if (checkboxClose) {
      setSimpleMenu(false);
      dispatch(helpersActions.setCheckboxClear());
      setDisabled(false);
      dispatch(helpersActions.setCheckboxClose(false));
    }
  }, [dispatch, checkboxClose]);

  const changeStatus = (status) => {
    setDisabled(true);
    map(checkboxes, (id) => {
      dispatch(pendingActionsAsync.patchPendingActionByIdAsync({
        id,
        status,
      }));
    });
    fnReset();
  };

  const createLinkEntity = () => {
    dispatch(emailActions.setIsModalOpenLinkEntity(true));
  };

  const handleSendLinkEntity = (value) => {
    map(checkboxes, (id) => {
      dispatch(emailActionAsync.postEmailLetterAsync({
        messageId: id,
        [value.entity]: [value.id],
        action: 'attach',
      }));
    });
    dispatch(emailActions.setIsModalOpenLinkEntity(false));
    setTimeout(() => {
      fnReset();
    }, 400);
  };

  // const createEmail = () => {
  //   // dispatch(emailActions.setEmailEntities({
  //   //   [`${lowerCase(tableName)}`]: checkboxes,
  //   // }));
  //   // dispatch(emailActions.setEmailById({
  //   //   isModalOpen: true,
  //   // }));
  //   setSimpleMenu(false);
  // };

  const exportEntity = () => {
    dispatch(importActionAsync.postExportAsync({
      type: tableName === 'pendingActions' ? 'pending-actions' : `${lowerCase(tableName)}`,
      ids: checkboxes,
    }));
    setSimpleMenu(false);
  };

  const changeDocuments = (value) => {
    if (value === 'sign') {
      dispatch(helpersActions.setSignIsModalOpen(true));
      return;
    }
    dispatch(helpersActions.setStartSend(true));
  };

  const mergeClients = () => {
    dispatch(clientsActions.setIsModalOpenMerge(true));
    setSimpleMenu(false);
  };

  const createMadridCase = () => {
    dispatch(madridCaseAction.setModalAddOpen(true));
  };

  const handleSetBasicTM = useCallback((trademark) => {
    dispatch(madridCaseAsyncAction.postMadridCaseAsync({
      status: 1,
      relations: uniq([trademark.id, ...checkboxes]),
    }));
    dispatch(madridCaseAction.setModalAddOpen(false));
  }, [dispatch, checkboxes]);

  const CloneEntity = () => {
    map(checkboxes, (id, index) => {
      if (tableName === listTablesName.trademarks) {
        dispatch(tradeMarksAsyncAction.putProposalCloneAsync({
          id, isList: index < checkboxes.length - 1,
        }));
      }
      if (tableName === listTablesName.industrial) {
        dispatch(industrialAsyncAction.putProposalCloneAsync({
          id, isList: index < checkboxes.length - 1,
        }));
      }
      if (tableName === listTablesName.utility) {
        dispatch(utilityModelsAsyncAction.putProposalCloneAsync({
          id, isList: index < checkboxes.length - 1,
        }));
      }
      if (tableName === listTablesName.inventions) {
        dispatch(inventionsAsyncAction.putProposalCloneAsync({
          id, isList: index < checkboxes.length - 1,
        }));
      }
    });
    dispatch(helpersActions.setCheckboxClear());
    setSimpleMenu(false);
  };

  const checkAccess = (accessName) => includes(permissions, accessName);

  const statusItems = [
    checkAccess(accessList.pendingActions_put) && {
      title: 'Activate',
      status: 1,
      icon: <ToggleOnIcon fontSize="small" />,
    },
    checkAccess(accessList.pendingActions_put) && {
      title: 'Mark done',
      status: 2,
      icon: <DoneIcon fontSize="small" />,
    },
    checkAccess(accessList.pendingActions_put) && {
      title: 'Cancel',
      status: 3,
      icon: <CloseIcon fontSize="small" />,
    },
  ];

  const documentsItems = [
    checkAccess(accessList.entity_sign_post) && {
      title: 'Sign',
      value: 'sign',
      icon: <SvgIcon><SignatureIcon fontSize="small" /></SvgIcon>,
    },
    checkAccess(accessList.entity_send_post) && {
      title: 'Send',
      value: 'send',
      icon: <SvgIcon><EnvelopeIcon fontSize="small" /></SvgIcon>,
    },
  ];

  const menuItems = () => {
    switch (tableName) {
      case listTablesName.pendingActions:
      case listTablesName.notificationsTasks:

        return map(statusItems, (item, idx) => (
          <MenuItem
            key={idx.toString()}
            disabled={disabled}
            onClick={() => changeStatus(item.status)}
          >
            <ListItemIcon>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={t(item.title)} />
          </MenuItem>
        ));

      case listTablesName.documents:
      case listTablesName.trademarksDocumentsOut:
        return map(documentsItems, (item, idx) => (
          <MenuItem
            key={idx.toString()}
            disabled={disabled}
            onClick={() => changeDocuments(item.value)}
          >
            <ListItemIcon>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={t(item.title)} />
          </MenuItem>
        ));

      case listTablesName.email:
        return (
          <MenuItem
            disabled={disabled}
            onClick={() => createLinkEntity()}
          >
            <ListItemIcon>
              <SvgIcon><EnvelopeIcon fontSize="small" /></SvgIcon>
            </ListItemIcon>
            <ListItemText primary={t('Tie the case')} />
          </MenuItem>
        );

      case listTablesName.trademarks:
      case listTablesName.industrial:
      case listTablesName.utility:
      case listTablesName.inventions:
        return (
          checkAccess(accessList.documents_post) ? (
            <MenuItem
              disabled={disabled}
              onClick={() => createDocument()}
            >
              <ListItemIcon>
                <SvgIcon fontSize="small"><FileAltIcon /></SvgIcon>
              </ListItemIcon>
              <ListItemText primary={t('Document')} />
            </MenuItem>
          ) : null
        );
      case listTablesName.clients:
        return (
          checkAccess(accessList.clients_merge_post) ? (
            <MenuItem
              disabled={disabled || checkboxes.length < 2}
              onClick={() => mergeClients()}
            >
              <ListItemIcon>
                <MergeTypeIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={t('Combine')} />
            </MenuItem>
          ) : null
        );
      default:
        return null;
    }
  };

  // const menuItemsCreateEmail = () => {
  //   switch (tableName) {
  //     case listTablesName.documents:
  //     case listTablesName.trademarks:
  //     case listTablesName.industrial:
  //     case listTablesName.utility:
  //     case listTablesName.inventions:
  //       return (
  //         <MenuItem
  //           disabled={disabled}
  //           onClick={() => createEmail()}
  //         >
  //           <ListItemIcon>
  //             <SvgIcon fontSize="small"><FileAltIcon /></SvgIcon>
  //           </ListItemIcon>
  //           <ListItemText primary={t('Create an email')} />
  //         </MenuItem>
  //       );
  //     default:
  //       return null;
  //   }
  // };

  const menuItemsExport = () => {
    switch (tableName) {
      case listTablesName.documents:
      case listTablesName.trademarks:
      case listTablesName.industrial:
      case listTablesName.utility:
      case listTablesName.inventions:
      case listTablesName.pendingActions:
        return (
          checkAccess(accessList.entity_export_post) ? (
            <MenuItem
              disabled={disabled}
              onClick={() => exportEntity()}
            >
              <ListItemIcon>
                <ImportExportOutlined fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={t('Export')} />
            </MenuItem>
          ) : null
        );
      default:
        return null;
    }
  };

  const menuItemsClone = () => {
    switch (tableName) {
      case listTablesName.trademarks:
      case listTablesName.industrial:
      case listTablesName.utility:
      case listTablesName.inventions:
        return (
          checkAccess(accessList.trademarks_clone_post) ? (
            <MenuItem
              disabled={disabled}
              onClick={() => CloneEntity()}
            >
              <ListItemIcon>
                <FileCopy fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={t('Make a copy')} />
            </MenuItem>
          ) : null
        );
      default:
        return null;
    }
  };

  const menuItemsMadridCase = () => {
    switch (tableName) {
      case listTablesName.trademarks:
        return (
          checkAccess(accessList.madrid_case_post) ? (
            <MenuItem
              disabled={disabled}
              onClick={() => createMadridCase()}
            >
              <ListItemIcon>
                <ChromeReaderMode fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={t('Madrid application')} />
            </MenuItem>
          ) : null
        );
      default:
        return null;
    }
  };

  return !isEmpty(checkboxes) && !isEmpty(tableName) && !disableMenu ? (
    <Box>
      {/* <LinkRecruit onClick={(event) => setSimpleMenu(event.currentTarget)}>
        {t('With the chosen ones')}
      </LinkRecruit> */}
      <Fab
        size="small"
        aria-label="chosen-fab"
        aria-controls="chosen-menu-fab"
        aria-haspopup="true"
        color="primary"
        variant="extended"
        onClick={(event) => setSimpleMenu(event.currentTarget)}
      >
        {t('With the chosen ones')}
      </Fab>
      {/* Simple Menu */}
      <Menu
        id="chosen-menu"
        anchorEl={simpleMenu}
        open={Boolean(simpleMenu)}
        onClose={() => setSimpleMenu(null)}
      >
        { menuItems() }
        {/* {menuItemsCreateEmail()} */}
        {menuItemsMadridCase()}
        {menuItemsExport()}
        {menuItemsClone()}

        <MenuItem
          disabled={!fieldsForModal.length || (accessEdit && !checkAccess(accessEdit))}
          onClick={() => setModal(true)}
        >
          <ListItemIcon>
            <SvgIcon fontSize="small"><PenIcon /></SvgIcon>
          </ListItemIcon>
          <ListItemText primary={t('Change')} />
        </MenuItem>
        {(!accessDelete || checkAccess(accessDelete)) && (
          <MenuItem disabled={disabled} onClick={() => setConfirmModal(true)}>
            <ListItemIcon>
              <SvgIcon fontSize="small"><TrashAltIcon /></SvgIcon>
            </ListItemIcon>
            <ListItemText primary={t('Delete')} />
          </MenuItem>
        )}

      </Menu>

      {
        isConfirmModal && (
          <TableConfirmModal
            isConfirmOpen={isConfirmModal}
            handleConfirmClose={setConfirmModal}
            checkboxes={checkboxes}
            reloadTable={reloadTable}
            tableName={tableName}
            handleSimpleMenuClose={setSimpleMenu}
          />
        )
      }

      {
        isModal && (
          <TableModal
            isModal={isModal}
            handleModalClose={setModal}
            fieldsForModal={fieldsForModal}
            tableName={tableName}
            reloadTable={reloadTable}
            checkboxes={checkboxes}
            handleSimpleMenuClose={setSimpleMenu}
          />
        )
      }

      {isModalOpenLinkEntity && (
        <LinkTheEntityModal
          handleSend={handleSendLinkEntity}
        />
      )}

      {isModalOpenMadrid && (
        <BasicMadridCaseModal
          options={map(checkboxes, (id) => find(rows, { id }) || { id })}
          handleSend={handleSetBasicTM}
        />
      )}

    </Box>
  ) : null;
};

ChosenCheckboxes.defaultProps = {
  fieldsForModal: [],
  reloadTable: () => {},
  createDocument: () => {},
  tableName: '',
  accessDelete: '',
  accessEdit: '',
  checkboxes: [],
  rows: [],
};

ChosenCheckboxes.propTypes = {
  checkboxes: PropTypes.oneOfType([
    PropTypes.array,
  ]),
  rows: PropTypes.oneOfType([
    PropTypes.array,
  ]),
  permissions: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  tableName: PropTypes.string,
  accessDelete: PropTypes.string,
  accessEdit: PropTypes.string,
  reloadTable: PropTypes.func,
  createDocument: PropTypes.func,
  fieldsForModal: PropTypes.oneOfType([PropTypes.array]),
  checkboxClose: PropTypes.bool.isRequired,
  isModalOpenLinkEntity: PropTypes.bool.isRequired,
  disableMenu: PropTypes.bool.isRequired,
  isModalOpenMadrid: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    checkboxClose: selectors.helpers.checkboxClose(state),
    isModalOpenLinkEntity: selectors.email.isModalOpenLinkEntity(state),
    isModalOpenMadrid: selectors.madridCase.isModalAddOpen(state),
    permissions: selectors.user.permissions(state),
  };
}

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.checkboxes, nextProps.checkboxes)
    && isEqual(prevProps.fieldsForModal, nextProps.fieldsForModal)
    && isEqual(prevProps.checkboxClose, nextProps.checkboxClose)
    && isEqual(prevProps.isModalOpenLinkEntity, nextProps.isModalOpenLinkEntity)
    && isEqual(prevProps.disableMenu, nextProps.disableMenu)
    && isEqual(prevProps.permissions, nextProps.permissions)
    && isEqual(prevProps.accessDelete, nextProps.accessDelete)
    && isEqual(prevProps.accessEdit, nextProps.accessEdit)
    && isEqual(prevProps.isModalOpenMadrid, nextProps.isModalOpenMadrid)
    && isEqual(prevProps.rows, nextProps.rows)
    && isEqual(prevProps.tableName, nextProps.tableName);
}

export default compose(
  connect(mapStateToProps, null),
)(React.memo(ChosenCheckboxes, areEqual));
