// Helpers
import actionCreator from '../../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const asyncActions = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_MADRID_CASE_ASYNC, params);
  },
  getMadridCaseByIdWorker(id) {
    return actionCreator(asyncTypes.GET_MADRID_CASE_BY_ID_ASYNC, id);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_MADRID_CASE_INITIAL_STATE_ASYNC, path);
  },
  postMadridCaseAsync(data) {
    return actionCreator(asyncTypes.POST_MADRID_CASE_ASYNC, data);
  },
  putMadridCaseByIdAsync(data) {
    return actionCreator(asyncTypes.PUT_MADRID_CASE_BY_ID_ASYNC, data);
  },
  deleteMadridCaseAsync() {
    return actionCreator(asyncTypes.DELETE_MADRID_CASE_ASYNC);
  },
});

export default asyncActions;
