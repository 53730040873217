// Core
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

// lodash
import { isEmpty, map } from 'lodash';

// parts
import DxTable from '../../../ui/Table/DxTable';
import ConfirmModal from '../../../components/ConfirmModal';

// actions
import actions from '../../../engine/core/tradeMarks/monitoring/action';
import asyncAction from '../../../engine/core/tradeMarks/monitoring/saga/asyncAction';
import helpersActions from '../../../engine/core/helpers/action';
import clientsAsyncActions from '../../../engine/core/company/clients/table/saga/asyncAction';

// config
import selectors from '../../../engine/config/selectors';
import { listTablesName, listEntityName } from '../../../engine/config/listTablesName';
import { useAccessList } from '../../../ui/_hooks/useAccessList';
import accessList from '../../../engine/config/accessList';

const tableName = listTablesName.trademarksMonitoring;
const entityName = listEntityName.monitoring;

function MonitoringTable(props) {
  const {
    monitoringData, totalCount, pending,
    currentPage, filters, sorting,
    pageSize, pendingDeleteMonitoring, checkboxes,
    columnWidths, columnOrder, hiddenColumnNames,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const accessClientsList = useAccessList(accessList.monitoring_list_get);

  useEffect(() => {
    if (accessClientsList) {
      dispatch(clientsAsyncActions.getListAsync({
        sortBy: 'createdAt',
        sortDir: 'asc',
        limit: 300,
        page: 1,
      }));
    }
  }, [dispatch, accessClientsList]);

  const columns = [
    { name: 'id', title: t('ID') },
    { name: 'word', title: t('Word') },
    { name: 'applicantsHolders', title: t('The owner/the applicant') },
    { name: 'classes', title: t('Classes') },
    { name: 'client', title: t('Client') },
    { name: 'lastCheckAt', title: t('Date of last check') },
    { name: 'numberTmPerDay', title: t('Number of new designations') },
    { name: 'createdAt', title: t('Created At') },
  ];

  const tableColumnExtensions = [
    { columnName: 'id', width: 50 },
    { columnName: 'word', width: '15rem' },
    { columnName: 'applicantsHolders', width: '15rem' },
    { columnName: 'classes', width: '15rem' },
    { columnName: 'client', width: '15rem' },
    { columnName: 'lastCheckAt', width: '15rem' },
    { columnName: 'numberTmPerDay', width: '15rem' },
    { columnName: 'createdAt', width: '15rem' },
  ];

  // Paging
  const onCurrentPage = (page) => dispatch(actions.setCurrentPage(page));
  const onPageSize = (size) => dispatch(actions.setPageSize(size));
  // Filtering
  const setFilters = (getFilters) => dispatch(actions.setFilters(getFilters));
  // Sorting
  const onSortingChange = (getSorting) => dispatch(actions.setSorting(getSorting));
  const onColumnWidthsChange = (getColumnWidths) => dispatch(
    actions.setColumnWidths(getColumnWidths),
  );
  const onColumnOrderChange = (getColumnOrder) => dispatch(
    actions.setColumnOrder(getColumnOrder),
  );
  const onHiddenColumnNamesChange = (getHiddenColumnNames) => dispatch(
    actions.setHiddenColumnNames(getHiddenColumnNames),
  );

  return (
    <>
      <DxTable
        name="MonitoringTable"
        isLoading={pending}
        columns={columns}
        rows={monitoringData.toJS().items}
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageSize={onPageSize}
        onCurrentPage={onCurrentPage}
        filtersAction={setFilters}
        filters={filters}
        entityName={entityName}
        // checkboxes ------------------------
        tableName={tableName}
        reloadTable={actions.setReload}
        selection={checkboxes.toJS()}
        onSelectionChange={(checkboxSelect) => {
          dispatch(helpersActions.setCheckbox({
            tableName,
            checkboxes: checkboxSelect,
          }));
        }}
        // ------------------------
        sorting={sorting}
        onSortingChange={onSortingChange}
        tableColumnExtensions={!isEmpty(columnWidths) && columnWidths.length === columns.length
          ? columnWidths : tableColumnExtensions}
        onColumnWidthsChange={onColumnWidthsChange}
        columnOrder={!isEmpty(columnOrder) ? columnOrder : map(columns, 'name')}
        onColumnOrderChange={onColumnOrderChange}
        hiddenColumnNames={hiddenColumnNames}
        onHiddenColumnNamesChange={onHiddenColumnNamesChange}
        accessDelete={accessList.monitoring_delete}
      />

      <ConfirmModal
        buttonSendText="DELETE"
        pending={pendingDeleteMonitoring}
        handleSend={() => {
          dispatch(asyncAction.deleteMonitoringAsync());
        }}
      />
    </>
  );
}

MonitoringTable.displayName = 'MonitoringTable';

MonitoringTable.propTypes = {
  monitoringData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  totalCount: PropTypes.number.isRequired,
  pending: PropTypes.bool.isRequired,
  pendingDeleteMonitoring: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  sorting: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  pageSize: PropTypes.number.isRequired,
  checkboxes: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  columnWidths: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  columnOrder: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  hiddenColumnNames: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
};

MonitoringTable.defaultProps = {};

function mapStateToProps(state) {
  return {
    monitoringData: selectors.tradeMarksMonitoring.monitoringData(state),
    pending: selectors.tradeMarksMonitoring.pending(state),
    pendingDeleteMonitoring: selectors.tradeMarksMonitoring.pendingDeleteMonitoring(state),

    // Paging
    totalCount: selectors.tradeMarksMonitoring.totalCount(state),
    currentPage: selectors.tradeMarksMonitoring.currentPage(state),
    pageSize: selectors.tradeMarksMonitoring.pageSize(state),

    // Filtering
    filters: selectors.tradeMarksMonitoring.filters(state),

    // Sorting
    sorting: selectors.tradeMarksMonitoring.sorting(state),

    // ColumnWidths
    columnWidths: selectors.tradeMarksMonitoring.columnWidths(state),
    columnOrder: selectors.tradeMarksMonitoring.columnOrder(state),
    hiddenColumnNames: selectors.tradeMarksMonitoring.hiddenColumnNames(state),

    checkboxes: selectors.helpers.getCheckboxes(state, tableName),
  };
}

function mapDispatchToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(MonitoringTable);
