// Core
import {
  useCallback, useEffect, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

// lodash
import isEmpty from 'lodash/isEmpty';

// actions
import action from '../../../engine/core/dashboard/action';
import asyncActions from '../../../engine/core/dashboard/saga/asyncAction';
import pendingActionsAsyncActions from '../../../engine/core/pendingActions/table/saga/asyncAction';
import inDocAsyncActions from '../../../engine/core/tradeMarks/proposal/documents/input/saga/asyncAction';
import outDocAsyncActions from '../../../engine/core/tradeMarks/proposal/documents/out/saga/asyncAction';

// config
import selectors from '../../../engine/config/selectors';
import accessList from '../../../engine/config/accessList';

// hook
import { useAccessList } from '../../../ui/_hooks/useAccessList';

export function useDashboard() {
  const filters = useSelector(selectors.dashboard.filters);
  const reload = useSelector(selectors.dashboard.reload);
  const path = useSelector(selectors.router.pathname);
  const userInfo = useSelector(selectors.user.userInfo);
  const accessPendingActionsList = useAccessList(accessList.pendingActions_list_get);
  const accessDashboardList = useAccessList(accessList.dashboard_list_get);
  const accessDocumentsList = useAccessList(accessList.documents_list_get);

  const dispatch = useDispatch();
  const getListAsync = useCallback(() => {
    const params = {
      ...!isEmpty(filters.toJS().entity) ? { 'filter[entity]': filters.toJS().entity } : {},
      ...filters.toJS().status ? { 'filter[status]': filters.toJS().status } : {},
      ...!isEmpty(filters.toJS().date) ? {
        'filter[date][from]': filters.toJS().date.split(' - ')?.[0],
        'filter[date][to]': filters.toJS().date.split(' - ')?.[1] || filters.toJS().date.split(' - ')?.[0],
      } : {},
    };
    if (accessPendingActionsList) {
      dispatch(pendingActionsAsyncActions.getListAsync({
        status: 1,
        'users.id': userInfo.id,
        sortBy: 'finishAt',
        sortDir: 'asc',
        limit: 100,
        page: 1,
        ...!isEmpty(filters.toJS().date) ? { finishAt: filters.toJS().date } : {},
      }));
    }
    if (accessDashboardList) {
      dispatch(asyncActions.getDashboardAsync(params));
    }
    if (accessDocumentsList) {
      dispatch(inDocAsyncActions.getListAsync({
        type: 'in',
        limit: 100,
        page: 1,
        ...!isEmpty(filters.toJS().date) ? { documentDate: filters.toJS().date } : {},
      }));
    }
    if (accessDocumentsList) {
      dispatch(outDocAsyncActions.getListAsync({
        type: 'out',
        limit: 100,
        page: 1,
        ...!isEmpty(filters.toJS().date) ? { documentDate: filters.toJS().date } : {},
      }));
    }
  }, [
    filters, dispatch, accessPendingActionsList, accessDashboardList,
    accessDocumentsList, userInfo,
  ]);

  const getInitialStateAsync = useCallback(() => {
    dispatch(asyncActions.getInitialStateAsync(path));
    dispatch(action.setPending(true));
  }, [dispatch, path]);

  const getInitialState = useRef(true);

  useEffect(() => {
    if (getInitialState.current) {
      getInitialStateAsync();
      getInitialState.current = false;
    }

    if (reload) {
      getListAsync();
    }
  }, [getListAsync, getInitialStateAsync, reload]);
}
