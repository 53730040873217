// Helpers
import actionCreator from '../../../../../_helpers/actionCreator';

// Types
import asyncTypes from './asyncTypes';

const asyncActions = Object.freeze({
  deleteTariffAsync() {
    return actionCreator(asyncTypes.DELETE_PRICES_TARIFF_ASYNC);
  },
  postTariffAsync(data) {
    return actionCreator(asyncTypes.POST_PRICES_TARIFF_ASYNC, data);
  },
  putTariffAsync(data) {
    return actionCreator(asyncTypes.PUT_PRICES_TARIFF_ASYNC, data);
  },
  getTariffID(id) {
    return actionCreator(asyncTypes.GET_PRICES_TARIFF_ID, id);
  },
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_PRICES_TARIFF_TABLE_ASYNC, params);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_PRICES_TARIFF_TABLE_INITIAL_STATE_ASYNC, path);
  },
  postTariffTableAsync(data) {
    return actionCreator(asyncTypes.POST_PRICES_TARIFF_TABLE_ASYNC, data);
  },
  getTariffTableByIdAsync(id) {
    return actionCreator(asyncTypes.GET_PRICES_TARIFF_TABLE_BY_ID_ASYNC, id);
  },
  deleteTariffTable() {
    return actionCreator(asyncTypes.DELETE_PRICES_TARIFF_TABLE);
  },
  putTariffTableByIdAsync(data) {
    return actionCreator(asyncTypes.PUT_PRICES_TARIFF_TABLE_BY_ID_ASYNC, data);
  },
});

export default asyncActions;
