// core
import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';

// ui
import {
  Box, Button, ButtonGroup,
  Divider as MuiDivider,
  Typography,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import { Add as AddIcon } from '@material-ui/icons';

// parts
import Table from './components/PaymentReceiversTable';
import AddPaymentReceiversModal from './components/AddPaymentReceiversModal';

// hooks
import { usePaymentReceivers } from './_hooks/usePaymentReceivers';
import { useAccessList } from '../../ui/_hooks/useAccessList';

// config
import accessList from '../../engine/config/accessList';

// actions
import actions from '../../engine/core/paymentReceivers/action';

// styles
const Divider = styled(MuiDivider)(spacing);

function PaymentReceivers() {
  usePaymentReceivers();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hasDocumentCreate = useAccessList(accessList.paymentReceivers_post);

  const handleGoToCreateDocumentPage = () => {
    dispatch(actions.setModalOpen(true));
  };

  return (
    <>
      <Helmet title={t('Payment Receivers')} />
      <Typography variant="h3" gutterBottom display="inline">
        {t('Payment Receivers')}
      </Typography>
      <Divider my={6} />

      {hasDocumentCreate && (
        <Box mb={2}>
          <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
            <Button variant="contained" color="primary" fullWidth onClick={handleGoToCreateDocumentPage}>
              {t('ADD')}
              <AddIcon />
            </Button>
          </ButtonGroup>
        </Box>
      )}

      <Table />

      <AddPaymentReceiversModal />
    </>
  );
}

PaymentReceivers.displayName = 'PaymentReceivers';

export default compose(
  connect(null, null),
)(PaymentReceivers);
