// Helpers
import actionCreator from '../../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const companyActionAsync = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_COMPANIES_ASYNC, params);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_COMPANIES_INITIAL_STATE_ASYNC, path);
  },
  getCompanyByIdAsync(id) {
    return actionCreator(asyncTypes.GET_COMPANY_BY_ID_ASYNC, id);
  },
  postCompanyAsync(data) {
    return actionCreator(asyncTypes.POST_COMPANY_ASYNC, data);
  },
  putCompanyByIdAsync(data) {
    return actionCreator(asyncTypes.PUT_COMPANY_BY_ID_ASYNC, data);
  },
  deleteCompanyAsync() {
    return actionCreator(asyncTypes.DELETE_COMPANY_ASYNC);
  },
  getAutocompleteOptionsAsync(params) {
    return actionCreator(asyncTypes.GET_COMPANIES_AUTOCOMPLETE_OPTIONS_ASYNC, params);
  },
  postCheckFtpAsync(data) {
    return actionCreator(asyncTypes.POST_CHECK_FTP_ASYNC, data);
  },
  getAllCompaniesAsync(data) {
    return actionCreator(asyncTypes.GET_ALL_COMPANIES_ASYNC, data);
  },
});

export default companyActionAsync;
