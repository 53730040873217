// Helpers
import actionCreator from '../../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const asyncActions = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_EMAIL_TEMPLATE_ASYNC, params);
  },
  getEmailTemplateByIdAsync(id) {
    return actionCreator(asyncTypes.GET_EMAIL_TEMPLATE_BY_ID_ASYNC, id);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_EMAIL_TEMPLATE_INITIAL_STATE_ASYNC, path);
  },
  deleteEmailTemplateAsync() {
    return actionCreator(asyncTypes.DELETE_EMAIL_TEMPLATE_ASYNC);
  },
  postEmailTemplateAsync(data) {
    return actionCreator(asyncTypes.POST_EMAIL_TEMPLATE_ASYNC, data);
  },
  putEmailTemplateByIdAsync(data) {
    return actionCreator(asyncTypes.PUT_EMAIL_TEMPLATE_BY_ID_ASYNC, data);
  },
  getAutocompleteOptionsAsync(params) {
    return actionCreator(asyncTypes.GET_EMAIL_TEMPLATE_AUTOCOMPLETE_OPTIONS_ASYNC, params);
  },
});

export default asyncActions;
