// Helpers
import actionCreator from '../../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const asyncActions = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_MONITORING_ASYNC, params);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_MONITORING_INITIAL_STATE_ASYNC, path);
  },
  deleteMonitoringAsync() {
    return actionCreator(asyncTypes.DELETE_MONITORING_ASYNC);
  },
  setNumberPerPageAsync(nameTab, count) {
    return actionCreator(asyncTypes.SET_NUMBER_PER_PAGE_ASYNC, {
      nameTab, count,
    });
  },
  getMonitoringReportAsync(id) {
    return actionCreator(asyncTypes.GET_MONITORING_REPORT_DATA_ASYNC, id);
  },
  getMonitoringByIdAsync(id) {
    return actionCreator(asyncTypes.GET_MONITORING_BY_ID_ASYNC, id);
  },
  postMonitoringAsync(searchData) {
    return actionCreator(asyncTypes.POST_MONITORING_ASYNC, searchData);
  },
  putMonitoringByIdAsync(searchData) {
    return actionCreator(asyncTypes.PUT_MONITORING_BY_ID_ASYNC, searchData);
  },
});

export default asyncActions;
