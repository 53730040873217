// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import types from './types';

const currenciesActions = Object.freeze({
  setCurrenciesData(data) {
    return actionCreator(types.SET_CURRENCIES_DATA, data);
  },
  setPendingPostCurrency(pending) {
    return actionCreator(types.SET_CURRENCY_PENDING_POST, pending);
  },
  setCurrentPage(currentPage) {
    return actionCreator(types.SET_CURRENCIES_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return actionCreator(types.SET_CURRENCIES_FILTERS, filters);
  },
  setSorting(sorting) {
    return actionCreator(types.SET_CURRENCIES_SORTING, sorting);
  },
  setColumnWidths(data) {
    return actionCreator(types.SET_CURRENCIES_COLUMN_WIDTHS, data);
  },
  setColumnOrder(data) {
    return actionCreator(types.SET_CURRENCIES_COLUMN_ORDER, data);
  },
  setHiddenColumnNames(data) {
    return actionCreator(types.SET_CURRENCIES_HIDDEN_COLUMN_NAMES, data);
  },
  setPageSize(pageSize) {
    return actionCreator(types.SET_CURRENCIES_PAGE_SIZE, pageSize);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_CURRENCIES_INITIAL_STATE, initialState);
  },
  setReload(reload) {
    return actionCreator(types.SET_CURRENCIES_RELOAD, reload);
  },
  setDeleteCurrency(data) {
    return actionCreator(types.SET_DELETE_CURRENCY, data);
  },
  setAutocompleteOptions(data) {
    return actionCreator(types.SET_CURRENCIES_AUTOCOMPLETE_OPTIONS, data);
  },
  setPendingAutocompleteOptions(pending) {
    return actionCreator(types.SET_PENDING_CURRENCIES_AUTOCOMPLETE_OPTIONS, pending);
  },
  setIsModalOpen(data) {
    return actionCreator(types.SET_CURRENCIES_IS_MODAL_OPEN, data);
  },
  setPendingPostCurrencyCalc(pending) {
    return actionCreator(types.SET_PENDING_CURRENCY_CALC, pending);
  },
  setCurrencyCalc(data) {
    return actionCreator(types.SET_CURRENCY_CALC, data);
  },
});

export default currenciesActions;
