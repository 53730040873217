// Core
import { apply, put, select } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';
import filter from 'lodash/filter';

// config
import { api } from '../../../../../../../config/api';
import selectors from '../../../../../../../config/selectors';

// actions
import actions from '../../action';
import helpersActions from '../../../../../../helpers/action';

// helpers
import {
  setErrorMessage, setSuccessMessage,
} from '../../../../../../../../ui/_helpers/setNotificationMessage';

// init
import i18n from '../../../../../../../init/i18n';

export function* callPostDeleteDocumentsInWorker() {
  const {
    setDeleteDocumentsDataIn, setProposalDocumentsInData,
    setPendingDeleteDocumentsIn,
  } = actions;
  const { setModal } = helpersActions;

  yield put(setPendingDeleteDocumentsIn(true));

  const deleteData = yield select(selectors.proposalDocumentsIn.deleteData);
  const documentsInData = yield select(selectors.proposalDocumentsIn.proposalDocumentsInData);
  const response = yield apply(api, api.trademarks.postRemoveDocuments, [deleteData.toJS()]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      yield put(setModal({
        isModalOpen: false,
      }));
      yield put(setDeleteDocumentsDataIn({}));
      yield put(setProposalDocumentsInData({
        items: filter(documentsInData.toJS().items, (item) => (
          item.id !== deleteData.toJS().params?.ids[0])),
        totalCount: documentsInData.toJS() - 1,
        pending: false,
      }));
      yield put(setSuccessMessage(i18n.t('Document remove!'), response.status.toString()));
    }
  }

  yield put(setPendingDeleteDocumentsIn(false));
}
