// core
import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import {
  Field, Form, reduxForm,
} from 'redux-form/lib/immutable';

// config
import selectors from '../../../engine/config/selectors';

// parts
import Modal from '../../../components/Modal/Modal';
import DialogActions from '../../../components/Modal/components/DialogActions';
import FieldCurrency from '../../../ui/Form/FieldsAutocomplete/FieldCurrency';
import DatePickers from '../../../ui/Form/DatePickers';
import RenderTextField from '../../../ui/Form/RenderTextField';

// actions
import asyncAction from '../../../engine/core/currencies/currencyRates/saga/asyncAction';
import actions from '../../../engine/core/currencies/currencyRates/action';

// hooks
import { useEventsAutocompleteAsync } from '../../../ui/_hooks/useEventsAutocompleteAsync';
import { getValueFormatDate, formatDate } from '../../../engine/_helpers/formatterDate';

function CurrencyModal(props) {
  const {
    handleSubmit,
    isModalOpen,
    destroy,
    pendingPost,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    getAutocompleteLists,
    handleGetAsyncData,
  } = useEventsAutocompleteAsync();

  const handleCloseModal = () => {
    dispatch(actions.setIsModalOpen(false));
  };
  useEffect(() => function cleanup() {
    destroy();
  }, [destroy]);

  const handleSubmits = (formData) => {
    const json = formData.toJS();
    dispatch(asyncAction.postCurrencyRateAsync({
      ...json,
      currency: json.currency?.id,
      date: getValueFormatDate(formatDate(json.date), 'date'),
    }));
  };

  return (
    <Modal
      title={t('Currency')}
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
      displayDialogActions={false}
    >
      <Form onSubmit={handleSubmit(handleSubmits)}>
        <FieldCurrency
          name="currency"
          getAutocompleteLists={getAutocompleteLists}
          getAsyncData={handleGetAsyncData}
          formName="CurrencyModal"
        />

        <Field
          id="date"
          labelId="date"
          name="date"
          fullWidth
          label={t('Course date')}
          component={DatePickers}
        />

        <Field
          name="rateFixed"
          id="rateFixed"
          label={t('Fixed rate')}
          margin="normal"
          variant="standard"
          fullWidth
          type="text"
          component={RenderTextField}
        />

        <Field
          name="rateReal"
          id="rateReal"
          label={t('The real course')}
          margin="normal"
          variant="standard"
          fullWidth
          type="text"
          component={RenderTextField}
        />

        <DialogActions
          pending={pendingPost}
          handleCloseModal={handleCloseModal}
        />
      </Form>
    </Modal>
  );
}

CurrencyModal.displayName = 'CurrencyModal';

CurrencyModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  pendingPost: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  destroy: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
};

CurrencyModal.defaultProps = {};

function mapStateToProps(state) {
  return {
    isModalOpen: selectors.currencyRates.isModalOpen(state),
    pendingPost: selectors.currencyRates.pendingPostCurrencyRate(state),
  };
}

export default compose(
  reduxForm({
    form: 'CurrencyModal',
  }),
  connect(mapStateToProps, null),
)(CurrencyModal);
