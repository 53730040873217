export default Object.freeze({
  SET_CHARGES_DATA: 'SET_CHARGES_DATA',
  SET_CHARGES_CURRENT_PAGE: 'SET_CHARGES_CURRENT_PAGE',
  SET_CHARGES_PAGE_SIZE: 'SET_CHARGES_PAGE_SIZE',
  SET_CHARGES_FILTERS: 'SET_CHARGES_FILTERS',
  SET_CHARGES_SORTING: 'SET_CHARGES_SORTING',
  SET_CHARGES_INITIAL_STATE: 'SET_CHARGES_INITIAL_STATE',
  SET_CHARGES_RELOAD: 'SET_CHARGES_RELOAD',
  SET_CHARGE_BY_ID: 'SET_CHARGE_BY_ID',
  SET_DELETE_CHARGE: 'SET_DELETE_CHARGE',
  SET_CHARGES_TABS: 'SET_CHARGES_TABS',
  SET_CHARGES_SELECTIONS: 'SET_CHARGES_SELECTIONS',
  SET_CHARGES_AUTOCOMPLETE_OPTIONS: 'SET_CHARGES_AUTOCOMPLETE_OPTIONS',
  SET_CHARGES_IDS: 'SET_CHARGES_IDS',
  SET_SUM_CHARGES_INPUT: 'SET_SUM_CHARGES_INPUT',
  SET_MODAL_CHARGES_TABLE: 'SET_MODAL_CHARGES_TABLE',
  SET_CHARGES_SELECTIONS_MODAL: 'SET_CHARGES_SELECTIONS_MODAL',
  SET_MODAL_CHARGES_EDIT: 'SET_MODAL_CHARGES_EDIT',
});
