// core
import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import { Field, Form, reduxForm } from 'redux-form/lib/immutable';
import styled from 'styled-components';

// lodash
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

// ui
import {
  Box,
} from '@material-ui/core';

// config
import selectors from '../../../engine/config/selectors';
import globalExecutors from '../../../engine/config/globalExecutors';

// parts
import RenderTextField from '../../../ui/Form/RenderTextField';
import Select from '../../../ui/Form/Select';
import Loader from '../../../components/Loader';
import Modal from '../../../components/Modal/Modal';
import DialogActions from '../../../components/Modal/components/DialogActions';

// actions
import actionAsync from '../../../engine/core/notifications/saga/asyncAction';
import actions from '../../../engine/core/notifications/action';
import accessList from '../../../engine/config/accessList';
import { useAccessList } from '../../../ui/_hooks/useAccessList';

// styles
const ModalPaper = styled(Box)`
  min-height: 400px;
`;

function AddEditNotificationModel(props) {
  const {
    handleSubmit, isModalOpen, pendingPostNotification,
    pendingPutNotification, getNotificationById,
    initialize, pendingNotificationById,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const accessPut = useAccessList(accessList.notifications_put);
  const accessEdit = !isEmpty(getNotificationById) ? accessPut : true;

  const handleOpenCloseModal = () => {
    dispatch(actions.setNotificationById({
      isModalOpen: false,
      data: {},
    }));
  };

  const modifyToIds = (items) => map(items.toJS ? items.toJS() : items, (item) => item.id);

  const handleSubmits = (formData) => {
    const json = formData.toJSON();
    dispatch(actionAsync[!isEmpty(getNotificationById)
      ? 'putNotificationByIdAsync'
      : 'postNotificationAsync']({
      ...json,
      ...!isEmpty(json.documentClassifier) ? {
        documentClassifier: modifyToIds(json.documentClassifier),
      } : {},
    }));
  };

  useEffect(() => function cleanup() {
    dispatch(actions.setNotificationById({
      isModalOpen: false,
      data: {},
    }));
  }, [dispatch]);

  useEffect(() => {
    initialize(!isEmpty(getNotificationById) ? getNotificationById : {});
  }, [
    getNotificationById, initialize,
  ]);

  return (
    <Modal
      title={t('Notification')}
      isModalOpen={isModalOpen}
      handleCloseModal={handleOpenCloseModal}
      displayDialogActions={false}
    >
      <ModalPaper>
        {!pendingNotificationById ? (
          <Form onSubmit={handleSubmit(handleSubmits)}>
            <Field
              name="name.ua"
              id="name.ua"
              label={t('Title')}
              margin="normal"
              variant="standard"
              my={2}
              fullWidth
              type="text"
              component={RenderTextField}
              disabled={!accessEdit}
            />

            <Field
              name="name.en"
              id="name.en"
              label={t('Title in English')}
              margin="normal"
              variant="standard"
              my={2}
              fullWidth
              type="text"
              component={RenderTextField}
              disabled={!accessEdit}
            />

            <Field
              name="text.ua"
              id="text.ua"
              label={t('Text')}
              margin="normal"
              variant="standard"
              my={2}
              fullWidth
              type="text"
              component={RenderTextField}
              disabled={!accessEdit}
            />

            <Field
              name="text.en"
              id="text.en"
              label={t('Text in English')}
              margin="normal"
              variant="standard"
              my={2}
              fullWidth
              type="text"
              component={RenderTextField}
              disabled={!accessEdit}
            />

            <Field
              name="term"
              id="term"
              label={t('Term')}
              margin="normal"
              my={2}
              fullWidth
              component={RenderTextField}
              disabled={!accessEdit}
            />

            <Field
              name="fromDate"
              id="fromDate"
              label={t('Count from the date')}
              margin="normal"
              my={2}
              fullWidth
              component={RenderTextField}
              disabled={!accessEdit}
            />

            <Field
              name="executor"
              id="executor"
              label={t('Executor')}
              labelId="executor"
              margin="normal"
              my={2}
              items={globalExecutors}
              fullWidth
              component={Select}
              disabled={!accessEdit}
            />

            <DialogActions
              pending={pendingPutNotification || pendingPostNotification}
              buttonTextSend="CONFIRM"
              handleCloseModal={handleOpenCloseModal}
              visibleButtonSend={accessEdit}
            />
          </Form>
        ) : <Loader position />}
      </ModalPaper>
    </Modal>
  );
}

AddEditNotificationModel.displayName = 'AddEditNotificationModel';

AddEditNotificationModel.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  pendingPostNotification: PropTypes.bool.isRequired,
  pendingPutNotification: PropTypes.bool.isRequired,
  pendingNotificationById: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  getNotificationById: PropTypes.oneOfType([
    PropTypes.object,
  ]),
};

AddEditNotificationModel.defaultProps = {
  getNotificationById: {},
};

function mapStateToProps(state) {
  return {
    isModalOpen: selectors.notifications.isModalOpen(state),
    pendingNotificationById: selectors.notifications.pendingNotificationById(state),
    pendingPostNotification: selectors.notifications.pendingPostNotification(state),
    pendingPutNotification: selectors.notifications.pendingPutNotification(state),
    getNotificationById: selectors.notifications.getNotificationById(state),
  };
}

export default compose(
  reduxForm({
    form: 'AddNotificationModel',
  }),
  connect(mapStateToProps, null),
)(AddEditNotificationModel);
