// Core
import { all, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetProductsWorker,
  callGetProductByIdWorker,
  callPutProductByIdWorker,
  callPostProductWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callDeleteProductWorker,
} from './workers';
import types from '../types';

function* watchGetProducts() {
  yield takeEvery(asyncTypes.GET_PRODUCTS_ASYNC, callGetProductsWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_PRODUCTS_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchGetProductById() {
  yield takeEvery(asyncTypes.GET_PRODUCT_BY_ID_ASYNC, callGetProductByIdWorker);
}
function* watchPostProduct() {
  yield takeEvery(asyncTypes.POST_PRODUCT_ASYNC, callPostProductWorker);
}
function* watchPutProductById() {
  yield takeEvery(asyncTypes.PUT_PRODUCT_BY_ID_ASYNC, callPutProductByIdWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_PRODUCTS_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_PRODUCTS_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_PRODUCTS_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_PRODUCTS_SORTING, callSetToLocalDataWorker);
}
function* watchSetColumnWidths() {
  yield takeEvery(types.SET_PRODUCTS_COLUMN_WIDTHS, callSetToLocalDataWorker);
}
function* watchSetColumnOrder() {
  yield takeEvery(types.SET_PRODUCTS_COLUMN_ORDER, callSetToLocalDataWorker);
}
function* watchSetHiddenColumnNames() {
  yield takeEvery(types.SET_PRODUCTS_HIDDEN_COLUMN_NAMES, callSetToLocalDataWorker);
}
function* watchDeleteProduct() {
  yield takeEvery(asyncTypes.DELETE_PRODUCT_ASYNC, callDeleteProductWorker);
}

export function* watchersProducts() {
  yield all([
    watchGetProducts(),
    watchGetInitialState(),
    watchGetProductById(),
    watchPostProduct(),
    watchPutProductById(),
    watchSetCurrentPage(),
    watchSetPageSize(),
    watchSetFilters(),
    watchSetSorting(),
    watchSetColumnWidths(),
    watchSetColumnOrder(),
    watchSetHiddenColumnNames(),
    watchDeleteProduct(),
  ]);
}
