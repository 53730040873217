// core
import React, {
  useEffect, useState,
} from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import {
  Field, Form, reduxForm,
} from 'redux-form/lib/immutable';

// lodash
import {
  isEmpty,
} from 'lodash';

// config
import selectors from '../../../engine/config/selectors';

// parts
import Modal from '../../../components/Modal/Modal';
import DialogActions from '../../../components/Modal/components/DialogActions';
import FieldEmployee from '../../../ui/Form/FieldsAutocomplete/FieldEmployee';
import RenderQuill from '../../../ui/Form/RenderQuill';
import LoaderWithOverlay from '../../../components/LoaderWithOverlay';

// actions
import asyncAction from '../../../engine/core/email/email/saga/asyncAction';
import actions from '../../../engine/core/email/email/action';

// hooks
import { useEventsAutocompleteAsync } from '../../../ui/_hooks/useEventsAutocompleteAsync';

const formName = 'SignatureModal';

function SignatureModal(props) {
  const {
    handleSubmit, isModalOpen, initialize, destroy,
    signatureById, pendingPost, pendingPut, pendingSignatureById,
  } = props;
  const {
    getAutocompleteLists,
    handleGetAsyncData,
  } = useEventsAutocompleteAsync();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [readyInitialize, setReadyInitialize] = useState(false);

  const handleCloseModal = () => {
    dispatch(actions.setIsModalOpenSignature(false));
  };
  useEffect(() => function cleanup() {
    destroy();
    dispatch(actions.setSignatureData({}));
  }, [destroy, dispatch]);

  const handleSubmits = (formData) => {
    const json = formData.toJS();
    dispatch(asyncAction[!isEmpty(signatureById.toJS())
      ? 'putEmailSignatureByIdAsync'
      : 'postEmailSignatureAsync']({
      ...json,
      ...!isEmpty(json.employee) ? {
        employee: json.employee.id,
      } : {},
    }));
  };

  useEffect(() => {
    if (!isEmpty(signatureById.toJS()) && !readyInitialize) {
      initialize(signatureById.toJS());
      setReadyInitialize(true);
    }
  }, [initialize, signatureById, readyInitialize]);

  return (
    <Modal
      title={t('Signature')}
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
      displayDialogActions={false}
    >
      {pendingSignatureById ? (
        <LoaderWithOverlay />
      ) : (
        <Form onSubmit={handleSubmit(handleSubmits)}>
          <Field
            name="body"
            id="body"
            labelId="body"
            label={t('Message')}
            margin="normal"
            fullWidth
            type="text"
            component={RenderQuill}
          />
          <FieldEmployee
            name="employee"
            label="Search / Select employee"
            getAutocompleteLists={getAutocompleteLists}
            getAsyncData={handleGetAsyncData}
            formName={formName}
          />

          <DialogActions
            pending={pendingPost || pendingPut}
            handleCloseModal={handleCloseModal}
          />
        </Form>
      )}
    </Modal>
  );
}

SignatureModal.displayName = 'SignatureModal';

SignatureModal.propTypes = {
  signatureById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  pendingPost: PropTypes.bool.isRequired,
  pendingPut: PropTypes.bool.isRequired,
  pendingSignatureById: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  destroy: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
};

SignatureModal.defaultProps = {};

function mapStateToProps(state) {
  return {
    signatureById: selectors.email.signatureById(state),
    isModalOpen: selectors.email.isModalOpenSignature(state),
    pendingPut: selectors.email.pendingPutSignature(state),
    pendingPost: selectors.email.pendingPostSignature(state),
    pendingSignatureById: selectors.email.pendingSignature(state),
  };
}

export default compose(
  reduxForm({
    form: formName,
  }),
  connect(mapStateToProps, null),
)(SignatureModal);
