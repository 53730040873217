// Core
import { apply, put } from 'redux-saga/effects';

// config
import { api } from '../../../../../config/api';
import { listEntityName } from '../../../../../config/listTablesName';

// actions
import actions from '../../action';

const entity = listEntityName.documentTypesCategories;

export function* callGetDocumentTypesCategoryWorker({ payload: params }) {
  yield put(actions.setDocumentTypesCategoryData({ pending: true }));
  const response = yield apply(api,
    api.documentTypesCategory.getDocumentsTypeCategory, [{ ...params, entity }]);

  let data = {
    items: [],
    totalCount: 0,
    pending: false,
  };

  if (response && response.status >= 200 && response.status < 400) {
    data = Object.assign(data, response.data);
  }

  yield put(actions.setDocumentTypesCategoryData(data));
  yield put(actions.setReload(false));
}
