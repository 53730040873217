// core
import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import { push } from 'connected-react-router';

// ui
import {
  Box,
  Button, ButtonGroup, Divider as MuiDivider,
  Typography,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import { Add as AddIcon } from '@material-ui/icons';

// parts
import SearchesTable from './SearchesTable';

// hooks
import { useSearches } from './_hooks/useSearches';
import { useAccessList } from '../../../ui/_hooks/useAccessList';

// routes
import { pageLinks } from '../../../routes';

// config
import accessList from '../../../engine/config/accessList';

// styles
const Divider = styled(MuiDivider)(spacing);

function Searches() {
  useSearches();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const accessPost = useAccessList(accessList.search_post);
  const accessQuickPost = useAccessList(accessList.quick_search_post);

  const handleGoToSearchesNew = () => {
    dispatch(push(pageLinks.tradeMarksRoutes.searches.new));
  };

  const handleGoToSearchesQuick = () => {
    dispatch(push(pageLinks.tradeMarksRoutes.searches.quick));
  };

  return (
    <>
      <Helmet title={t('Searches')} />
      <Typography variant="h3" gutterBottom display="inline">
        {t('Searches')}
      </Typography>
      <Divider my={6} />

      {(accessPost || accessQuickPost) && (
        <Box mb={2}>
          <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
            {accessPost && (
              <Button variant="contained" color="primary" fullWidth onClick={handleGoToSearchesNew}>
                {t('ADD')}
                <AddIcon />
              </Button>
            )}
            {accessQuickPost && (
              <Button variant="contained" color="primary" fullWidth onClick={handleGoToSearchesQuick}>
                {t('SEARCH')}
              </Button>
            )}
          </ButtonGroup>
        </Box>
      )}

      <SearchesTable />
    </>
  );
}

Searches.displayName = 'Searches';

Searches.propTypes = {
};

Searches.defaultProps = {
  searchTabs: {},
};

function mapStateToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, null),
)(Searches);
