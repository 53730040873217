// core
import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import {
  Field, Form, reduxForm,
} from 'redux-form/lib/immutable';
import styled from 'styled-components';

// lodash
import {
  isEmpty, includes, filter, map,
} from 'lodash';

// ui
import {
  Button as MuiButton, CircularProgress, Grid,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';

// config
import selectors from '../../../engine/config/selectors';

// parts
import RenderTextField from '../../../ui/Form/RenderTextField';
import Select from '../../../ui/Form/Select';
import Modal from '../../../components/Modal/Modal';
import DialogActions from '../../../components/Modal/components/DialogActions';
import Checkbox from '../../../ui/Form/Checkbox';

// actions
import asyncAction from '../../../engine/core/company/integration/saga/asyncAction';
import actions from '../../../engine/core/company/integration/action';
import accessList from '../../../engine/config/accessList';
import { useAccessList } from '../../../ui/_hooks/useAccessList';

// styles
const Button = styled(MuiButton)(spacing);

function IntegrationModal(props) {
  const {
    handleSubmit,
    isModalOpen,
    initialize,
    destroy,
    getIntegrationById,
    pendingPostIntegration,
    pendingPutIntegrationById,
    fieldsSetting,
    fieldUkrPatent,
    pendingPostCheckFtp,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [readyInitialize, setReadyInitialize] = useState(false);
  const [readyNewInitialize, setReadyNewInitialize] = useState(false);
  const [authorityValue, setAuthorityValue] = useState('ukrpatent');
  const [dataField, setDataField] = useState([]);
  const accessPut = useAccessList(accessList.authorityIntegration_put);
  const accessCheckFtp = useAccessList(accessList.check_ftp_post);
  const accessEdit = !isEmpty(getIntegrationById.toJS()) ? accessPut : true;

  const handleCloseModal = () => {
    dispatch(actions.setIsModalOpen(false));
  };
  const handleChangeAuthority = (e) => {
    setAuthorityValue(e.target.value);
  };
  useEffect(() => function cleanup() {
    destroy();
    dispatch(actions.setIntegrationDataById({}));
  }, [destroy, dispatch]);

  const handleSubmits = (formData) => {
    const json = formData.toJS();
    dispatch(asyncAction[!isEmpty(getIntegrationById.toJS())
      ? 'putIntegrationByIdAsync'
      : 'postIntegrationAsync']({
      ...json,
      ...!isEmpty(getIntegrationById.toJS()) ? {
        id: getIntegrationById.toJS().id,
      } : {},
      ...!isEmpty(json.data) && !isEmpty(json.data?.[authorityValue]) ? {
        data: json.data?.[authorityValue],
      } : {},
    }));
  };

  useEffect(() => {
    if (!isEmpty(getIntegrationById.toJS()) && !readyInitialize) {
      initialize({
        ...getIntegrationById.toJS(),
        data: {
          [getIntegrationById.toJS().authority]: {
            ...getIntegrationById.toJS().data,
          },
        },
      });
      setAuthorityValue(getIntegrationById.toJS().authority);
      setReadyInitialize(true);
    }
  }, [
    initialize, getIntegrationById, setReadyInitialize,
    readyInitialize, setAuthorityValue,
  ]);

  useEffect(() => {
    if (isEmpty(getIntegrationById.toJS()) && !readyNewInitialize
      && !isEmpty(fieldsSetting?.authority?.options)) {
      initialize({
        authority: 'ukrpatent',
        data: { ukrpatent: { server: '213.160.144.249' } },
        send: true,
        receive: true,
      });
      setReadyNewInitialize(true);
    }
  }, [
    initialize, getIntegrationById, fieldsSetting,
    readyNewInitialize, setReadyNewInitialize,
  ]);

  useEffect(() => {
    if (authorityValue && !isEmpty(fieldsSetting)) {
      const field = `data.${authorityValue}.`;
      setDataField(filter(Object.keys(fieldsSetting), (item) => includes(item, field)));
    }
  }, [
    authorityValue, fieldsSetting,
  ]);

  const handleCheckFtp = () => {
    dispatch(asyncAction.postCheckFtpAsync(fieldUkrPatent));
  };

  return (
    <Modal
      title={t('Integration')}
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
      displayDialogActions={false}
    >
      <Form onSubmit={handleSubmit(handleSubmits)}>
        <Field
          name="name"
          id="name"
          label={t('Name')}
          margin="normal"
          variant="standard"
          my={2}
          fullWidth
          type="text"
          component={RenderTextField}
          disabled={!accessEdit}
        />

        <Field
          name="authority"
          id="authority"
          label={t('Department')}
          labelId="authority"
          my={2}
          items={fieldsSetting?.authority?.options || []}
          fullWidth
          component={Select}
          onChange={handleChangeAuthority}
          disabled={!accessEdit}
        />
        {!isEmpty(fieldsSetting) && !isEmpty(dataField) && (
          <>
            {map(dataField, (item) => {
              const label = fieldsSetting?.[item]?.label === 'Login'
                ? 'Username'
                : fieldsSetting[item].label;
              return (
                <Field
                  key={item}
                  name={item}
                  id={item}
                  label={t(label)}
                  margin="normal"
                  variant="standard"
                  my={2}
                  fullWidth
                  type="text"
                  component={RenderTextField}
                  disabled={!accessEdit}
                />
              );
            })}
            {authorityValue === 'ukrpatent' && accessCheckFtp && (
              <Button
                mt={2}
                variant="outlined"
                color="primary"
                onClick={handleCheckFtp}
              >
                {pendingPostCheckFtp ? <CircularProgress size={25} color="inherit" /> : t('CHECK THE CONNECTIONS')}
              </Button>
            )}
          </>
        )}
        <Grid container spacing={4}>
          <Grid item xs={12} md={3}>
            <Field
              component={Checkbox}
              margin="none"
              type="checkbox"
              name="send"
              label={t('Sending')}
              disabled={!accessEdit}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Field
              component={Checkbox}
              margin="none"
              type="checkbox"
              name="receive"
              label={t('Receiving')}
              disabled={!accessEdit}
            />
          </Grid>
        </Grid>

        <DialogActions
          pending={pendingPostIntegration || pendingPutIntegrationById}
          handleCloseModal={handleCloseModal}
          visibleButtonSend={accessEdit}
        />
      </Form>
    </Modal>
  );
}

IntegrationModal.displayName = 'IntegrationModal';

IntegrationModal.propTypes = {
  getIntegrationById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  fieldsSetting: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  pendingPostIntegration: PropTypes.bool.isRequired,
  pendingPutIntegrationById: PropTypes.bool.isRequired,
  pendingPostCheckFtp: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  destroy: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  fieldUkrPatent: PropTypes.oneOfType([
    PropTypes.object,
  ]),
};

IntegrationModal.defaultProps = {
  fieldUkrPatent: {},
};

function mapStateToProps(state) {
  return {
    getIntegrationById: selectors.integration.getIntegrationById(state),
    isModalOpen: selectors.integration.isModalOpen(state),
    pendingPutIntegrationById: selectors.integration.pendingPutIntegrationById(state),
    pendingPostIntegration: selectors.integration.pendingPostIntegration(state),
    fieldsSetting: selectors.fieldsSetting.fieldsSetting(state),
    pendingPostCheckFtp: selectors.integration.pendingPostCheckFtp(state),
    fieldUkrPatent: selectors.form.getFormValues(state, 'IntegrationModal').getIn(['data', 'ukrpatent']),
  };
}

export default compose(
  reduxForm({
    form: 'IntegrationModal',
  }),
  connect(mapStateToProps, null),
)(IntegrationModal);
