export default Object.freeze({
  SET_MKTU_TEMPLATES_DATA: 'SET_MKTU_TEMPLATES_DATA',
  SET_MKTU_TEMPLATES_CURRENT_PAGE: 'SET_MKTU_TEMPLATES_CURRENT_PAGE',
  SET_MKTU_TEMPLATES_PAGE_SIZE: 'SET_MKTU_TEMPLATES_PAGE_SIZE',
  SET_MKTU_TEMPLATES_FILTERS: 'SET_MKTU_TEMPLATES_FILTERS',
  SET_MKTU_TEMPLATES_SORTING: 'SET_MKTU_TEMPLATES_SORTING',
  SET_MKTU_TEMPLATES_INITIAL_STATE: 'SET_MKTU_TEMPLATES_INITIAL_STATE',
  SET_MKTU_TEMPLATES_RELOAD: 'SET_MKTU_TEMPLATES_RELOAD',
  SET_DELETE_MKTU_TEMPLATE: 'SET_DELETE_MKTU_TEMPLATE',
  SET_MKTU_TEMPLATES_BY_ID: 'SET_MKTU_TEMPLATES_BY_ID',
  SET_MKTU_TEMPLATE_PDF_BY_ID: 'SET_MKTU_TEMPLATE_PDF_BY_ID',
  SET_PENDING_MKTU_TEMPLATE_PDF: 'SET_PENDING_MKTU_TEMPLATE_PDF',
  SET_PENDING_MKTU_TEMPLATE_BY_ID: 'SET_PENDING_MKTU_TEMPLATE_BY_ID',
  SET_MKTU_TEMPLATE_MODAL_ALL_TERMS_IS_OPEN: 'SET_MKTU_TEMPLATE_MODAL_ALL_TERMS_IS_OPEN',
  SET_MKTU_TEMPLATE_MODAL_YOUR_LIST_TERMS_IS_OPEN: 'SET_MKTU_TEMPLATE_MODAL_YOUR_LIST_TERMS_IS_OPEN',
  SET_MKTU_TEMPLATES_COLUMN_WIDTHS: 'SET_MKTU_TEMPLATES_COLUMN_WIDTHS',
  SET_MKTU_TEMPLATES_COLUMN_ORDER: 'SET_MKTU_TEMPLATES_COLUMN_ORDER',
  SET_MKTU_TEMPLATES_HIDDEN_COLUMN_NAMES: 'SET_MKTU_TEMPLATES_HIDDEN_COLUMN_NAMES',
  SET_MKTU_TEMPLATE_AUTOCOMPLETE_OPTIONS: 'SET_MKTU_TEMPLATE_AUTOCOMPLETE_OPTIONS',
  SET_PENDING_MKTU_TEMPLATE_AUTOCOMPLETE_OPTIONS: 'SET_PENDING_MKTU_TEMPLATE_AUTOCOMPLETE_OPTIONS',
});
