// Helpers
import actionCreator from '../../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const clientsActionAsync = Object.freeze({
  getClientByIdAsync(id) {
    return actionCreator(asyncTypes.GET_CLIENT_BY_ID_ASYNC, id);
  },
  getClientsAutocomplete(text) {
    return actionCreator(asyncTypes.GET_CLIENTS_AUTOCOMPLETE_ASYNC, text);
  },
  putClientByIdAsync(client) {
    return actionCreator(asyncTypes.PUT_CLIENT_BY_ID_ASYNC, client);
  },
  postClientAsync(client) {
    return actionCreator(asyncTypes.POST_CLIENT_ASYNC, client);
  },
  deleteClientAsync() {
    return actionCreator(asyncTypes.DELETE_CLIENT_ASYNC);
  },
  postMergeClientsAsync(data) {
    return actionCreator(asyncTypes.POST_MERGE_CLIENTS_ASYNC, data);
  },
  postSentCredentialsAsync(data) {
    return actionCreator(asyncTypes.POST_CLIENTS_SENT_CREDENTIALS_ASYNC, data);
  },
});

export default clientsActionAsync;
