// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const jurisdictionActionAsync = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_JURISDICTIONS_ASYNC, params);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_JURISDICTIONS_INITIAL_STATE_ASYNC, path);
  },
  getJurisdictionByIdAsync(id) {
    return actionCreator(asyncTypes.GET_JURISDICTION_BY_ID_ASYNC, id);
  },
  postJurisdictionAsync(data) {
    return actionCreator(asyncTypes.POST_JURISDICTION_ASYNC, data);
  },
  putJurisdictionByIdAsync(data) {
    return actionCreator(asyncTypes.PUT_JURISDICTION_BY_ID_ASYNC, data);
  },
  deleteJurisdictionAsync() {
    return actionCreator(asyncTypes.DELETE_JURISDICTION_ASYNC);
  },
  getAutocompleteOptionsAsync(params) {
    return actionCreator(asyncTypes.GET_JURISDICTIONS_AUTOCOMPLETE_OPTIONS_ASYNC, params);
  },
});

export default jurisdictionActionAsync;
