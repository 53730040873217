// Core
import { apply, put, select } from 'redux-saga/effects';

// lodash
import { isEmpty, has } from 'lodash';

// config
import { api } from '../../../../../config/api';
import { messageRequestSent } from '../../../../../config/globalConfig';
import selectors from '../../../../../config/selectors';

// actions
import actions from '../../action';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../../ui/_helpers/setNotificationMessage';

// init
import i18n from '../../../../../init/i18n';

export function* callGetMonitoringReportWorker({ payload: searchId }) {
  const {
    setMonitoringReportData,
    setMonitoringById,
  } = actions;
  yield put(setMonitoringReportData({ pending: true }));

  const searchTabs = yield select(selectors.tradeMarksMonitoring.monitoringTabs);
  const getMonitoringById = yield select(selectors.tradeMarksMonitoring.getMonitoringById);
  // TODO: Временно. Формирование параметов в отдельеном файле
  const response = yield apply(api, api.trademarks.getMonitoringReport, [
    searchId,
    !isEmpty(searchTabs.national.selectedBlocks)
      ? `ids[]=${searchTabs.national.selectedBlocks.join('&ids[]=')}`
      : '',
  ]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      yield put(setMonitoringById({
        data: {
          ...getMonitoringById,
          pdf: data.pdf,
        },
      }));
      yield put(setSuccessMessage(i18n.t(messageRequestSent), response.status.toString()));
    }
  }

  yield put(setMonitoringReportData({ pending: false }));
}
