// Core
import { all, call, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetMonitoringWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callDeleteMonitoringWorker,
  callGetMonitoringReportWorker,
  callSetNumberPerPageWorker,
  callGetMonitoringByIdWorker,
  callPutMonitoringByIdWorker,
  callPostMonitoringWorker,
} from './workers';
import types from '../types';

function* watchGetMonitoring() {
  yield takeEvery(asyncTypes.GET_MONITORING_ASYNC, callGetMonitoringWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_MONITORING_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_MONITORING_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_MONITORING_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_MONITORING_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_MONITORING_SORTING, callSetToLocalDataWorker);
}
function* watchSetColumnWidths() {
  yield takeEvery(types.SET_MONITORING_COLUMN_WIDTHS, callSetToLocalDataWorker);
}
function* watchSetColumnOrder() {
  yield takeEvery(types.SET_MONITORING_COLUMN_ORDER, callSetToLocalDataWorker);
}
function* watchSetHiddenColumnNames() {
  yield takeEvery(
    types.SET_MONITORING_HIDDEN_COLUMN_NAMES,
    callSetToLocalDataWorker,
  );
}
function* watchDeleteMonitoring() {
  yield takeEvery(asyncTypes.DELETE_MONITORING_ASYNC, callDeleteMonitoringWorker);
}
function* watchGetMonitoringReport() {
  yield takeEvery(asyncTypes.GET_MONITORING_REPORT_DATA_ASYNC, callGetMonitoringReportWorker);
}
function* watchSetNumberPerPage() {
  yield takeEvery(asyncTypes.SET_NUMBER_PER_PAGE_ASYNC, callSetNumberPerPageWorker);
}
function* watchGetMonitoringById() {
  yield takeEvery(asyncTypes.GET_MONITORING_BY_ID_ASYNC, callGetMonitoringByIdWorker);
}
function* watchPostMonitoring() {
  yield takeEvery(asyncTypes.POST_MONITORING_ASYNC, callPostMonitoringWorker);
}
function* watchPutMonitoringById() {
  yield takeEvery(asyncTypes.PUT_MONITORING_BY_ID_ASYNC, callPutMonitoringByIdWorker);
}

export function* watchersMonitoringTable() {
  yield all([
    call(watchGetMonitoring),
    call(watchSetCurrentPage),
    call(watchSetPageSize),
    call(watchSetFilters),
    call(watchSetSorting),
    call(watchSetColumnWidths),
    call(watchSetColumnOrder),
    call(watchSetHiddenColumnNames),
    call(watchGetInitialState),
    call(watchDeleteMonitoring),
    call(watchGetMonitoringReport),
    call(watchSetNumberPerPage),
    call(watchGetMonitoringById),
    call(watchPostMonitoring),
    call(watchPutMonitoringById),
  ]);
}
