// core
import React, { memo, useEffect } from 'react';
import { compose } from 'recompose';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty, isEqual } from 'lodash';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';

// ui
import {
  TextField as MuiTextField,
} from '@material-ui/core';

// parts
import selectors from '../../../../../../engine/config/selectors';
import proposalActions from '../../../../../../engine/core/tradeMarks/proposal/action';
import Modal from '../../../../../../components/Modal/Modal';

const TextField = styled(MuiTextField)`
  .MuiInputBase-root {
    min-height: 300px;
    align-items: start;
  }
`;

function ClassesModal(props) {
  const {
    isOpen,
    selectedMktuToModal: { selectMktuClass, mktuClass },
    mktuTemplatesById,
    editMktuClasses, pendingPostRebound, pendingPutRebound,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [value, setValue] = React.useState('');
  const handleClose = () => {
    dispatch(proposalActions.setModalIsOpen(false));
  };
  const handleSubscribe = () => {
    // eslint-disable-next-line react/prop-types
    mktuClass.name = value;
    editMktuClasses(selectMktuClass || mktuClass, value);
  };
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    const editableClass = selectMktuClass || mktuClass;
    if (!isEmpty(editableClass)) {
      if (!isEmpty(mktuTemplatesById.toJS()) && !isEmpty(selectMktuClass)) {
        setValue(editableClass.name);
      } else if (editableClass.selection) {
        setValue(editableClass.name);
      } else if (editableClass.term) {
        setValue(editableClass.term);
      } else if (!isEmpty(selectMktuClass)) {
        setValue(editableClass.name);
      } else {
        setValue('All');
      }
    }
  }, [selectMktuClass, mktuClass, mktuTemplatesById]);
  return (
    <Modal
      title={t('List of goods and services')}
      isModalOpen={isOpen}
      handleCloseModal={handleClose}
      maxWidth="lg"
      handleSend={handleSubscribe}
      pending={pendingPostRebound || pendingPutRebound}
    >
      <TextField
        id="filled-multiline-flexible"
        label={t('Terms')}
        fullWidth
        multiline
        value={value}
        variant="outlined"
        helperText={t('Insert your list separated by semicolons')}
        onChange={handleChange}
      />
    </Modal>
  );
}

ClassesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  selectedMktuToModal: PropTypes.shape({
    selectMktuClass: PropTypes.oneOfType([
      PropTypes.object,
    ]),
    mktuClass: PropTypes.oneOfType([
      PropTypes.object,
    ]),
  }).isRequired,
  mktuTemplatesById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  editMktuClasses: PropTypes.func.isRequired,
  pendingPostRebound: PropTypes.bool.isRequired,
  pendingPutRebound: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    isOpen: selectors.tradeMarksProposal.mktuModaIsOpen(state),
    pendingPostRebound: selectors.rebounds.pendingPostRebound(state),
    pendingPutRebound: selectors.rebounds.pendingPutRebound(state),
  };
}

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.isOpen, nextProps.isOpen)
  && isEqual(prevProps.mktuTemplatesById, nextProps.mktuTemplatesById)
  && isEqual(prevProps.pendingPostRebound, nextProps.pendingPostRebound)
  && isEqual(prevProps.pendingPutRebound, nextProps.pendingPutRebound)
  && isEqual(prevProps.selectedMktuToModal, nextProps.selectedMktuToModal);
}

export default compose(connect(mapStateToProps, null))(memo(ClassesModal, areEqual));
