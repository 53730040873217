// Core
import { call, put } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../../config/api';
import { messageRequestSuccess } from '../../../../../config/globalConfig';

// init
import i18n from '../../../../../init/i18n';

// actions
import action from '../../action';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../../ui/_helpers/setNotificationMessage';

export function* callPutMonitoringByIdWorker({ payload: monitoringData }) {
  const { setMonitoringById } = action;

  yield put(setMonitoringById({
    pendingPut: true,
  }));

  const response = yield call(api.trademarks.putMonitoringById, monitoringData);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      yield put(setSuccessMessage(i18n.t(messageRequestSuccess), response.status.toString()));
    }
  }

  yield put(setMonitoringById({
    pendingPut: false,
  }));
}
