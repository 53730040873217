// Core
import { call, put } from 'redux-saga/effects';
import { saveAs } from 'file-saver';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../config/api';

// helpers
import { setErrorMessage } from '../../../../../ui/_helpers/setNotificationMessage';

export function* callPostPaymentDetailsWorker({ payload }) {
  const response = yield call(api.charges.postPaymentDetails, payload);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      saveAs(data.pdf, `PaymentDetails_${payload.charge}`);
    }
  }
}
