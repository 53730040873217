// Core
import { all, call, takeEvery } from 'redux-saga/effects';

// types
import asyncTypes from './asyncTypes';
import types from '../types';

// Workers
import {
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callGetDashboardWorker,
} from './workers';

function* watchGetInitialState() {
  yield takeEvery(
    asyncTypes.GET_DASHBOARD_INITIAL_STATE_ASYNC,
    callGetInitialStateWorker,
  );
}

function* watchGetDashboard() {
  yield takeEvery(asyncTypes.GET_DASHBOARD_ASYNC, callGetDashboardWorker);
}

function* watchSetFilters() {
  yield takeEvery(types.SET_DASHBOARD_FILTERS, callSetToLocalDataWorker);
}

export function* watchersDashboard() {
  yield all([
    call(watchSetFilters),
    call(watchGetInitialState),
    call(watchGetDashboard),
  ]);
}
