// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import types from './types';

const companyActions = Object.freeze({
  setCompaniesData(data) {
    return actionCreator(types.SET_COMPANIES_DATA, data);
  },
  setCompanyById(data) {
    return actionCreator(types.SET_COMPANY_BY_ID, data);
  },
  setCurrentPage(currentPage) {
    return actionCreator(types.SET_COMPANIES_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return actionCreator(types.SET_COMPANIES_FILTERS, filters);
  },
  setSorting(sorting) {
    return actionCreator(types.SET_COMPANIES_SORTING, sorting);
  },
  setPageSize(pageSize) {
    return actionCreator(types.SET_COMPANIES_PAGE_SIZE, pageSize);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_COMPANIES_INITIAL_STATE, initialState);
  },
  setReload(reload) {
    return actionCreator(types.SET_COMPANIES_RELOAD, reload);
  },
  setDeleteCompany(data) {
    return actionCreator(types.SET_DELETE_COMPANY, data);
  },
  setAutocompleteOptions(data) {
    return actionCreator(types.SET_COMPANIES_AUTOCOMPLETE_OPTIONS, data);
  },
  setPendingAutocompleteOptions(pending) {
    return actionCreator(types.SET_PENDING_COMPANIES_AUTOCOMPLETE_OPTIONS, pending);
  },
  setPendingPostCheckFtp(data) {
    return actionCreator(types.SET_PENDING_POST_CHECK_FTP, data);
  },
  setAllCompanies(data) {
    return actionCreator(types.SET_ALL_COMPANIES, data);
  },
  setColumnWidths(data) {
    return actionCreator(types.SET_COMPANIES_COLUMN_WIDTHS, data);
  },
  setColumnOrder(data) {
    return actionCreator(types.SET_COMPANIES_COLUMN_ORDER, data);
  },
  setHiddenColumnNames(data) {
    return actionCreator(types.SET_COMPANIES_HIDDEN_COLUMN_NAMES, data);
  },
  setOriginalLogo(data) {
    return actionCreator(types.SET_COMPANY_ORIGINAL_LOGO, data);
  },
  setOriginalDocHeading(data) {
    return actionCreator(types.SET_COMPANY_ORIGINAL_DOC_HEADING, data);
  },
});

export default companyActions;
