// Core
import { apply, put } from 'redux-saga/effects';

// config
import { api } from '../../../../../config/api';
import { listEntityName } from '../../../../../config/listTablesName';

// actions
import actions from '../../action';

const entity = listEntityName.productCategory;

export function* callGetProductCategoryWorker({ payload: params }) {
  yield put(actions.setPendingProductCategoryData(true));
  const response = yield apply(api, api.productCategory.getProductCategory,
    [{ ...params, entity }]);

  if (response && response.status >= 200 && response.status < 400) {
    yield put(actions.setProductCategoryData(response.data));
  }
  yield put(actions.setPendingProductCategoryData(false));
}
