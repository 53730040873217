// core
import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import {
  Field, Form, reduxForm, change,
} from 'redux-form/lib/immutable';
import styled from 'styled-components';
import { Map } from 'immutable';
import { SketchPicker } from 'react-color';

// lodash
import {
  isEmpty,
} from 'lodash';

// ui
import {
  InputAdornment,
} from '@material-ui/core';

// config
import selectors from '../../engine/config/selectors';

// parts
import RenderTextField from '../../ui/Form/RenderTextField';
import Modal from '../Modal/Modal';
import DialogActions from '../Modal/components/DialogActions';

// actions
import asyncAction from '../../engine/core/actionTypes/saga/asyncAction';
import actions from '../../engine/core/actionTypes/action';

// styles
const Popover = styled.div`
  position: fixed;
  z-index: 2;
  top: 50%;
  left: 50%;
`;
const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

const ColorView = styled.div`
  width: 40px;
  height: 23px;
  cursor: default;
  border: 1px solid;
  padding: 1px 2px;
`;

function AddTypeActionModal(props) {
  const {
    handleSubmit,
    isModalOpen,
    initialize,
    destroy,
    getActionTypeById,
    pendingPutActionType,
    pendingPostActionType,
    fieldColor,
    defaultName,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [readyInitialize, setReadyInitialize] = useState(false);
  const [openPicker, setOpenPicker] = useState(false);

  const handleCloseModal = () => {
    dispatch(actions.setIsModalOpen(false));
  };

  useEffect(() => function cleanup() {
    destroy();
    dispatch(actions.setActionTypeById({ data: Map() }));
    dispatch(actions.setDefaultName(''));
  }, [destroy, dispatch]);

  const handleSubmits = (formData) => {
    const json = formData.toJS();
    dispatch(asyncAction[!isEmpty(getActionTypeById.toJS())
      ? 'putActionTypeByIdAsync'
      : 'postActionTypeAsync'](json));
  };

  useEffect(() => {
    if (!isEmpty(getActionTypeById.toJS()) && !readyInitialize) {
      initialize(getActionTypeById.toJS());
      setReadyInitialize(true);
    }
  }, [
    initialize, getActionTypeById, setReadyInitialize,
    readyInitialize,
  ]);

  useEffect(() => {
    if (!isEmpty(defaultName)) {
      dispatch(change('AddTypeActionModal', 'name', defaultName));
      dispatch(actions.setDefaultName(''));
    }
  }, [
    dispatch, defaultName,
  ]);

  const changeColorText = (color) => {
    dispatch(change('AddTypeActionModal', 'colors', color.hex));
    // setOpenPicker('');
  };

  return (
    <Modal
      title={t('Type')}
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
      displayDialogActions={false}
    >
      <Form onSubmit={handleSubmit(handleSubmits)}>
        <Field
          name="name"
          id="name"
          label={t('Name')}
          margin="none"
          variant="standard"
          my={2}
          fullWidth
          type="text"
          component={RenderTextField}
        />

        <Field
          id="colors"
          name="colors"
          label={t('Color')}
          margin="normal"
          my={2}
          fullWidth
          type="text"
          component={RenderTextField}
          onClick={() => setOpenPicker(true)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <ColorView style={{ backgroundColor: fieldColor }} />
              </InputAdornment>
            ),
          }}
        />
        {openPicker && (
          <Popover>
            <Cover onClick={() => setOpenPicker(false)} />
            <SketchPicker
              color={fieldColor}
              onChange={changeColorText}
            />
          </Popover>
        )}

        <DialogActions
          pending={pendingPutActionType || pendingPostActionType}
          handleCloseModal={handleCloseModal}
        />
      </Form>
    </Modal>
  );
}

AddTypeActionModal.displayName = 'AddTypeActionModal';

AddTypeActionModal.propTypes = {
  getActionTypeById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  pendingPutActionType: PropTypes.bool.isRequired,
  pendingPostActionType: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  destroy: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  fieldColor: PropTypes.string,
  defaultName: PropTypes.string.isRequired,
};

AddTypeActionModal.defaultProps = {
  fieldColor: '',
};

function mapStateToProps(state) {
  return {
    getActionTypeById: selectors.actionTypes.getActionTypeById(state),
    isModalOpen: selectors.actionTypes.isModalOpen(state),
    pendingPutActionType: selectors.actionTypes.pendingPutActionType(state),
    pendingPostActionType: selectors.actionTypes.pendingPostActionType(state),
    defaultName: selectors.actionTypes.defaultName(state),
    fieldColor: selectors.form.getFormValues(state, 'AddTypeActionModal').get('colors'),
  };
}

export default compose(
  reduxForm({
    form: 'AddTypeActionModal',
  }),
  connect(mapStateToProps, null),
)(AddTypeActionModal);
