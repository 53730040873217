// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import types from './types';

const actions = Object.freeze({
  setProposalData(company) {
    return actionCreator(types.SET_PROPOSAL_DATA, company);
  },
  setPendingGetProposalById(pending) {
    return actionCreator(types.SET_PENDING_GET_PROPOSAL_BY_ID, pending);
  },
  setProposalById(data) {
    return actionCreator(types.SET_PROPOSAL_BY_ID, data);
  },
  setProposalDataById(data) {
    return actionCreator(types.SET_PROPOSAL_DATA_BY_ID, data);
  },
  setCurrentPage(currentPage) {
    return actionCreator(types.SET_PROPOSAL_CURRENT_PAGE, currentPage);
  },
  setMktuTemplatesOptions(options) {
    return actionCreator(types.SET_MKTU_TEMPLATE_OPTIONS, options);
  },
  setPendingMktuTemplatesOptions(pending) {
    return actionCreator(types.SET_PENDING_MKTU_TEMPLATE_AUTOCOMPLETE_OPTIONS, pending);
  },
  setMktuById(elem) {
    return actionCreator(types.SET_MKTU_BY_ID_PROPOSAL, elem);
  },
  setFilters(filters) {
    return actionCreator(types.SET_PROPOSAL_FILTERS, filters);
  },
  setSorting(sorting) {
    return actionCreator(types.SET_PROPOSAL_SORTING, sorting);
  },
  setColumnWidths(data) {
    return actionCreator(types.SET_PROPOSAL_COLUMN_WIDTHS, data);
  },
  setColumnOrder(data) {
    return actionCreator(types.SET_PROPOSAL_COLUMN_ORDER, data);
  },
  setHiddenColumnNames(data) {
    return actionCreator(types.SET_PROPOSAL_HIDDEN_COLUMN_NAMES, data);
  },
  setPageSize(pageSize) {
    return actionCreator(types.SET_PROPOSAL_PAGE_SIZE, pageSize);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_PROPOSAL_INITIAL_STATE, initialState);
  },
  setReload(reload) {
    return actionCreator(types.SET_PROPOSAL_RELOAD, reload);
  },
  setAutocompleteOptions(options) {
    return actionCreator(types.SET_TRADE_MARKS_PROPOSAL_AUTOCOMPLETE_OPTIONS, options);
  },
  setPendingAutocompleteOptions(pending) {
    return actionCreator(types.SET_PENDING_TRADE_MARKS_PROPOSAL_AUTOCOMPLETE_OPTIONS, pending);
  },
  setClasses(classes) {
    return actionCreator(types.SET_PROPOSAL_CLASSES, classes);
  },
  setClassById(elem) {
    return actionCreator(types.SET_PROPOSAL_CLASS_BY_ID, elem);
  },
  setTermsMktuClasses(data) {
    return actionCreator(types.SET_PROPOSAL_TERMS_MKTU, data);
  },
  setTermsMktuClassesClear() {
    return actionCreator(types.SET_PROPOSAL_TERMS_MKTU_CLEAR);
  },
  setPendingClassById(pending) {
    return actionCreator(types.SET_PROPOSAL_PENDING_CLASS_BY_ID, pending);
  },
  setSelectClassesFromRegistry(classes) {
    return actionCreator(types.SET_PROPOSAL_CLASSES_FROM_REGISTRY, classes);
  },
  setCompany(classes) {
    return actionCreator(types.SET_PROPOSAL_COMPANY, classes);
  },
  setDeleteProposal(data) {
    return actionCreator(types.SET_DELETE_PROPOSAL, data);
  },
  setPendingPutProposal(pending) {
    return actionCreator(types.SET_PENDING_PUT_PROPOSAL, pending);
  },
  setPendingPostProposal(pending) {
    return actionCreator(types.SET_PENDING_POST_PROPOSAL, pending);
  },
  setPendingPostMktu(pending) {
    return actionCreator(types.SET_PENDING_POST_MKTU_TEMPLATE, pending);
  },

  setUploadFiles(files) {
    return actionCreator(types.SET_PROPOSAL_UPLOAD_FILES, files);
  },
  setMktuPending(pending) {
    return actionCreator(types.SET_PROPOSAL_MKTU_PENDING, pending);
  },
  setMktuList(list) {
    return actionCreator(types.SET_PROPOSAL_MKTU_LIST, list);
  },
  setModalIsOpen(isOpen) {
    return actionCreator(types.SET_PROPOSAL_MKTU_MODAL_IS_OPEN, isOpen);
  },
  setSelectMktuClasses(data) {
    return actionCreator(types.SET_PROPOSAL_SELECT_MKTU, data);
  },
  setNextTabProposal(data) {
    return actionCreator(types.SET_NEXT_TAB_PROPOSAL, data);
  },
  setCreatedImage(data) {
    return actionCreator(types.SET_CREATED_IMAGE, data);
  },
  setPendingCreatedImage(pending) {
    return actionCreator(types.SET_PENDING_CREATED_IMAGE, pending);
  },
  setPendingRegistryByNumber(pending) {
    return actionCreator(types.SET_PENDING_GET_REGISTRY_BY_NUMBER, pending);
  },
  setRegistryByNumber(data) {
    return actionCreator(types.SET_REGISTRY_BY_NUMBER, data);
  },
  setPendingFindCompany(pending) {
    return actionCreator(types.SET_PENDING_GET_FIND_COMPANY, pending);
  },
  setNextNumber(data) {
    return actionCreator(types.SET_TRADE_MARKS_NEXT_NUMBER, data);
  },
  setAllClassesModalIsOpen(isOpen) {
    return actionCreator(types.SET_ALL_CLASSES_MODAL_IS_OPEN, isOpen);
  },
  setClassesById(elem) {
    return actionCreator(types.SET_PROPOSAL_CLASSES_BY_ID, elem);
  },
  setClearClassesById(data) {
    return actionCreator(types.SET_CLEAR_PROPOSAL_CLASSES_BY_ID, data);
  },
  setPendingClassesById(pending) {
    return actionCreator(types.SET_PROPOSAL_PENDING_CLASSES_BY_ID, pending);
  },
});

export default actions;
