// core
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';

// helpers
import helpersActionsAsync from '../../../../engine/core/helpers/saga/asyncAction';
import helpersActions from '../../../../engine/core/helpers/action';

// Components
import NewConfirmModal from '../../../../components/NewConfirmModal';
import { formatTableName } from '../СhosenCheckboxes/СhosenCheckboxes';
import { listTablesName } from '../../../../engine/config/listTablesName';

const TableConfirmModal = ({
  checkboxes,
  tableName,
  reloadTable,
  isConfirmOpen,
  handleConfirmClose,
  handleSimpleMenuClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [pending, setPending] = useState(false);

  // Массовое удаление
  const handleSend = () => {
    setPending(true);

    dispatch(helpersActionsAsync.deleteMultipleAsync({
      body: {
        [formatTableName(tableName === listTablesName.notificationsTasks
          ? listTablesName.pendingActions : tableName)]: checkboxes,
      },
      callBack: () => {
        setPending(false);
        handleConfirmClose(false);
        handleSimpleMenuClose(false);
        dispatch(reloadTable(true));
        dispatch(helpersActions.setCheckboxClear());
      },
    }));
  };

  return (
    <NewConfirmModal
      handleClose={() => handleConfirmClose(false)}
      isModalOpen={isConfirmOpen}
      pending={pending}
      buttonSendText={t('Send')}
      buttonCancelText={t('Cancel')}
      description={t('Are you sure you want to delete?')}
      handleSend={handleSend}
    />
  );
};

TableConfirmModal.defaultProps = {
  handleSimpleMenuClose: () => {},
};

TableConfirmModal.propTypes = {
  checkboxes: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  tableName: PropTypes.string.isRequired,
  reloadTable: PropTypes.func.isRequired,
  isConfirmOpen: PropTypes.bool.isRequired,
  handleConfirmClose: PropTypes.func.isRequired,
  handleSimpleMenuClose: PropTypes.func,
};

export default TableConfirmModal;
