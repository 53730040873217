// Core
import { call, put } from 'redux-saga/effects';

// config
import { api } from '../../../../../config/api';

// actions
import employeesActions from '../../action';

export function* callGetResponsibleEmployeeByIdWorker({ payload: id }) {
  const {
    setResponsibleEmployeeById,
  } = employeesActions;

  const response = yield call(api.company.getEmployeeById, id);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    yield put(setResponsibleEmployeeById(data));
  }
}
