import {
  Box, ButtonGroup,
  CardContent,
  CircularProgress, Button as MuiButton,
  Card as MuiCard,
  Grid as MuiGrid,
  IconButton as MuiIconButton,
  Typography as MuiTypography,
  Tooltip,
} from '@material-ui/core';
import {
  CloudDownload as CloudDownloadIcon,
  Delete as DeleteIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from '@material-ui/icons';
import { spacing } from '@material-ui/system';
import { List, Map } from 'immutable';
import {
  find,
  isEmpty,
  isEqual,
  isObject,
  map,
  sum,
} from 'lodash';
import * as PropTypes from 'prop-types';
import React, {
  memo,
  useCallback, useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import {
  Field, change,
} from 'redux-form/immutable';
import styled from 'styled-components';
import DocViewerModal from '../../../../../components/DocViewerModal/DocViewerModal';
import DropZoneAreaModal from '../../../../../components/DropZoneAreaModal/DropZoneAreaModal';
import TableEntitiesModal from '../../../../../components/TableEntitiesModal/TableEntitiesModal';
import selectors from '../../../../../engine/config/selectors';
import depDocActions from '../../../../../engine/core/departmentDocuments/action';
import helpersActions from '../../../../../engine/core/helpers/action';
import helpersActionAsync from '../../../../../engine/core/helpers/saga/asyncAction';
import uploadFileAction from '../../../../../engine/core/uploadFile/action';
import uploadFileActionAsync from '../../../../../engine/core/uploadFile/saga/asyncAction';
import ButtonSubmit from '../../../../../ui/Button/ButtonSubmit';
import AutocompleteField from '../../../../../ui/Form/AutocompleteField';
import { validators } from '../../../../../ui/_helpers/validation';
import { formName as formNameSaved } from '../../helper/form';

// styles
const Card = styled(MuiCard)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Button = styled(MuiButton)(spacing);
const IconButton = styled(MuiIconButton)(spacing);
const Spacer = styled.div(spacing);

const fileType = 'additions';

function CardInitialDocuments(props) {
  const {
    formName, pending, fieldClient, uploadFile, uploadFileType,
    pendingPreviewFileById, isModalOpenDocViewer, uploadFiles,
    pendingDownloadFile, documentType, isModalOpenTableEntities,
    disabled, documentsForm,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [fileId, setFileId] = useState(0);
  const [dropZoneKey, setDropZoneKey] = useState(111);
  const [progressAdditions, setProgressAdditions] = useState(0);
  const [selectedDefaultType, setSelectedDefaultType] = useState(false);

  useEffect(() => {
    dispatch(helpersActionAsync.getDocumentsTypeAsync({ limit: 10000 }));
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(uploadFiles.toJS()) && !selectedDefaultType && !isEmpty(documentType.toJS())) {
      map(uploadFiles.toJS(), (file, index) => {
        if (isObject(file.type)) {
          dispatch(change(formName, `documents[${index}].type`, find(documentType.toJS(), { id: file.type.id })));
        }
      });
      setSelectedDefaultType(true);
    }
  }, [uploadFiles, dispatch, documentType, formName, selectedDefaultType]);

  useEffect(() => {
    if (!isEmpty(uploadFile.toJS()) && uploadFile.toJS().fileId && uploadFileType === fileType) {
      dispatch(uploadFileAction.setAllUploadFiles([
        ...uploadFiles.toJS(),
        {
          ...uploadFile.toJS(),
          newFile: true,
        },
      ]));
      dispatch(uploadFileAction.setUploadFile({
        file: Map(),
      }));
    }
  }, [dispatch, uploadFiles, uploadFile, uploadFileType]);

  const handleViewFile = (file) => {
    dispatch(helpersActionAsync.getPreviewFileByIdAsync(file?.fileId));
    setFileId(file.fileId);
  };
  const handleDownloadFile = (file) => {
    dispatch(helpersActionAsync.getDownloadFileAsync(file));
    setFileId(file.fileId);
  };
  const handleDeleteFile = (index) => {
    const jsUploadFiles = uploadFiles.toJS();
    const docs = [...documentsForm.toJS()];
    docs.splice(index, 1);
    dispatch(uploadFileAction.setAllUploadFiles(jsUploadFiles.filter(
      (file) => (!isEqual(file, jsUploadFiles[index])),
    )));
    dispatch(change(formName, 'documents', List(docs)));
  };
  const handleSelectDocuments = (documents) => {
    const selectUploadFile = map(documents, (document) => ({
      id: document?.id,
      size: document?.file?.size,
      filename: document?.file?.filename,
      fileId: document?.file?.fileId,
      type: document?.documentClassifier,
    }));
    dispatch(uploadFileAction.setAllUploadFiles([...uploadFiles.toJS(), ...selectUploadFile]));
    dispatch(change(
      formName,
      'documents',
      List(
        [
          ...documentsForm.toJS(),
          ...map(selectUploadFile, (el) => (
            { type: find(documentType.toJS(), { id: el.type.id }) }))],
      ),
    ));
    dispatch(helpersActions.setModal({
      isModalOpenTableEntities: false,
    }));
    dispatch(depDocActions.setDepartmentDocumentSelectionsModal(List()));
  };

  const handleChangeDropZone = useCallback((files) => {
    if (!isEmpty(files)) {
      const arrayProgress = [];
      map(files, (file, i) => {
        dispatch(uploadFileActionAsync.uploadFileAsync({
          file: {
            file: file.data,
            originalName: file.file?.name,
          },
          type: fileType,
          onUploadProgress: (event) => {
            arrayProgress[i] = Math.round((100 * event.loaded) / event.total);
            const newProgress = Math.round(sum(arrayProgress) / arrayProgress.length);
            setProgressAdditions(newProgress || 0);
            if (newProgress === 100) {
              dispatch(helpersActions.setModal({ isModalOpenDropZone: false }));
              setProgressAdditions(0);
            }
          },
        }));
        setDropZoneKey(Math.random());
      });
    }
  }, [dispatch]);

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h2" gutterBottom>
          {t('Initial documents')}
        </Typography>
        {isModalOpenDocViewer && <DocViewerModal />}

        {map(uploadFiles.toJS(), (file, index) => (
          <Grid key={`${file?.id}${file?.type?.id}${index}`} container spacing={4} alignItems="flex-end">
            <Grid item xs={12} md={5}>
              <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                {!disabled && (
                  <IconButton
                    mx={2}
                    aria-label={t('Delete')}
                    size="small"
                    onClick={() => {
                      handleDeleteFile(index);
                    }}
                  >
                    <Tooltip title={t('Delete')} placement="top-start">
                      <DeleteIcon size="small" />
                    </Tooltip>
                  </IconButton>
                )}
                <IconButton
                  aria-label="Review"
                  size="small"
                  disabled={pendingPreviewFileById}
                  onClick={() => handleViewFile(file)}
                >
                  {fileId === file?.fileId && pendingPreviewFileById
                    ? <CircularProgress size={24} color="inherit" />
                    : (
                      <Tooltip title={t('Review')} placement="top-start">
                        <RemoveRedEyeIcon size="small" />
                      </Tooltip>
                    )}
                </IconButton>
                <IconButton
                  mx={2}
                  aria-label={t('Download')}
                  size="small"
                  disabled={pendingDownloadFile}
                  onClick={() => handleDownloadFile(file)}
                >
                  {fileId === file?.fileId && pendingDownloadFile
                    ? <CircularProgress size={24} color="inherit" />
                    : (
                      <Tooltip title={t('Download')} placement="top-start">
                        <CloudDownloadIcon size="small" />
                      </Tooltip>
                    )}
                </IconButton>
                <Box component="span" fontWeight="fontWeightBold" mr={5} ml={5}>
                  {file.filename}
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={7}>
              <Field
                multiple={false}
                name={`documents[${index}].type`}
                id={`documents[${index}].type`}
                labelId={`documents[${index}].type`}
                component={AutocompleteField}
                type="text"
                label={t('Type')}
                margin="none"
                fullWidth
                options={documentType.toJS()}
                required
                validate={validators.required}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        ))}
        <Spacer mb={6} />
        <Grid container spacing={4}>

          {isModalOpenTableEntities && (
            <TableEntitiesModal
              {...!isEmpty(fieldClient) ? {
                defaultFilters: [
                  { columnName: 'clients.id', value: [fieldClient.toJS ? fieldClient.toJS()?.id : fieldClient?.id] },
                ],
              } : {}}
              handleSend={handleSelectDocuments}
            />
          )}

          <DropZoneAreaModal
            dropZoneKey={dropZoneKey}
            handleChange={handleChangeDropZone}
            progress={progressAdditions}
          />

          {!disabled && (
            <Grid item xs={12}>
              <ButtonGroup color="primary" aria-label="contained primary button group">
                <ButtonSubmit pending={pending} />
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => dispatch(helpersActions.setModal({
                    isModalOpenDropZone: true,
                  }))}
                >
                  {t('DOWNLOAD')}
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => dispatch(helpersActions.setModal({
                    isModalOpenTableEntities: true,
                  }))}
                >
                  {t('CHOSE EXISTING ONES')}
                </Button>
              </ButtonGroup>
            </Grid>
          )}
        </Grid>

      </CardContent>
    </Card>
  );
}
CardInitialDocuments.propTypes = {
  formName: PropTypes.string.isRequired,
  pending: PropTypes.bool.isRequired,
  isModalOpenDocViewer: PropTypes.bool.isRequired,
  pendingPreviewFileById: PropTypes.bool.isRequired,
  pendingDownloadFile: PropTypes.bool.isRequired,
  isModalOpenTableEntities: PropTypes.bool.isRequired,
  uploadFiles: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  documentType: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  fieldClient: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  uploadFile: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  uploadFileType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  disabled: PropTypes.bool,
  documentsForm: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

CardInitialDocuments.defaultProps = {
  uploadFileType: null,
  fieldClient: {},
  disabled: false,
};

function mapStateToProps(state) {
  return {
    isModalOpenDocViewer: selectors.helpers.isModalOpenDocViewer(state),
    pendingPreviewFileById: selectors.helpers.pendingPreviewFileById(state),
    pendingDownloadFile: selectors.helpers.pendingDownloadFile(state),
    documentType: selectors.helpers.documentsType(state),
    isModalOpenTableEntities: selectors.helpers.isModalOpenTableEntities(state),
    uploadFiles: selectors.uploadFile.uploadFiles(state),
    uploadFile: selectors.uploadFile.uploadFile(state),
    uploadFileType: selectors.uploadFile.uploadFileType(state),
    documentsForm: selectors.form.getFormValues(state, formNameSaved).get('documents'),
  };
}

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.formName, nextProps.formName)
  && isEqual(prevProps.pendingDownloadFile, nextProps.pendingDownloadFile)
  && isEqual(prevProps.pendingPreviewFileById, nextProps.pendingPreviewFileById)
  && isEqual(prevProps.isModalOpenDocViewer, nextProps.isModalOpenDocViewer)
  && isEqual(prevProps.isModalOpenTableEntities, nextProps.isModalOpenTableEntities)
  && isEqual(prevProps.uploadFiles, nextProps.uploadFiles)
  && isEqual(prevProps.documentType, nextProps.documentType)
  && isEqual(prevProps.fieldClient, nextProps.fieldClient)
  && isEqual(prevProps.uploadFile, nextProps.uploadFile)
  && isEqual(prevProps.uploadFileType, nextProps.uploadFileType)
  && isEqual(prevProps.disabled, nextProps.disabled)
  && isEqual(prevProps.documentsForm, nextProps.documentsForm)
  && isEqual(prevProps.pending, nextProps.pending);
}

export default compose(
  connect(mapStateToProps, null),
)(memo(CardInitialDocuments, areEqual));
