// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const asyncActions = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_INVENTIONS_PROPOSAL_ASYNC, params);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_INVENTIONS_PROPOSAL_INITIAL_STATE_ASYNC, path);
  },
  getProposalByIdAsync(id) {
    return actionCreator(asyncTypes.GET_INVENTIONS_PROPOSAL_BY_ID_ASYNC, id);
  },
  deleteProposalAsync() {
    return actionCreator(asyncTypes.DELETE_INVENTION_PROPOSAL_ASYNC);
  },
  getAutocompleteOptionsAsync(params) {
    return actionCreator(asyncTypes.GET_INVENTION_AUTOCOMPLETE_OPTIONS_ASYNC, params);
  },
  putProposalAsync(params) {
    return actionCreator(asyncTypes.PUT_INVENTION_PROPOSAL_ASYNC, params);
  },
  postProposalAsync(params) {
    return actionCreator(asyncTypes.POST_INVENTION_PROPOSAL_ASYNC, params);
  },
  getRegistryByNumberAsync(params) {
    return actionCreator(asyncTypes.GET_INVENTION_REGISTRY_BY_NUMBER_ASYNC, params);
  },
  getNextNumberAsync(params) {
    return actionCreator(asyncTypes.GET_INVENTIONS_PROPOSAL_NEXT_NUMBER_ASYNC, params);
  },
  putProposalCloneAsync(params) {
    return actionCreator(asyncTypes.PUT_INVENTIONS_PROPOSAL_CLONE_ASYNC, params);
  },
});

export default asyncActions;
