// Core
import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { change } from 'redux-form';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

// lodash
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';

// UI
import ChipInput from 'material-ui-chip-input';

const ChipField = styled(ChipInput)`
  margin-top: 10px;
  & > div {
    margin-top: 14px !important;
  }
`;

function ChipTextField(props) {
  const {
    label, input,
    meta: {
      touched, invalid, error, form,
    }, ...custom
  } = props;
  const {
    value, name,
  } = input;
  const dispatch = useDispatch();
  const checkArray = useCallback(() => (isArray(value) ? value : value.toJSON()), [value]);
  const getItems = useCallback(() => (isObject(value) ? checkArray() : value), [
    value, checkArray,
  ]);

  return (
    <ChipField
      {...custom}
      label={label}
      name={name}
      placeholder={label}
      error={touched && invalid}
      helperText={touched && error}
      defaultValue={!isEmpty(value) ? getItems() : []}
      onChange={(chips) => {
        dispatch(change(form, name, chips));
      }}
    />
  );
}

ChipTextField.displayName = 'ChipTextField';

ChipTextField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.array,
    ]),
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    invalid: PropTypes.bool,
    error: PropTypes.oneOfType([
      PropTypes.object,
    ]),
    form: PropTypes.string,
  }).isRequired,
  label: PropTypes.string,
};

ChipTextField.defaultProps = {
  label: '',
};

export default ChipTextField;
