export const formName = 'DepartmentDocument';

export const formFields = Object.freeze({
  file: 'file',
  additionalFiles: 'additionalFiles',
  choice: 'choice',
});

export const fieldsAutocomplete = Object.freeze({
  responseDocument: 'responseDocument',
  template: 'template',
  originalClient: 'original.clients',
  originalIndustrial: 'original.industrial',
  originalUtility: 'original.utility',
  originalTrademarks: 'original.trademarks',
  originalInventions: 'original.inventions',
  industrial: 'industrial',
  utility: 'utility',
  trademarks: 'trademarks',
  inventions: 'inventions',
  orders: 'orders',
  clients: 'clients',
});
