const asyncTypes = Object.freeze({
  GET_PAYMENTS_ASYNC: 'GET_PAYMENTS_ASYNC',
  GET_PAYMENTS_INITIAL_STATE_ASYNC: 'GET_PAYMENTS_INITIAL_STATE_ASYNC',
  DELETE_PAYMENT_ASYNC: 'DELETE_PAYMENT_ASYNC',
  POST_PAYMENTS_APPLY_ASYNC: 'POST_PAYMENTS_APPLY_ASYNC',
  GET_PAYMENT_BY_ID_ASYNC: 'GET_PAYMENT_BY_ID_ASYNC',
  POST_PAYMENT_ASYNC: 'POST_PAYMENT_ASYNC',
  PUT_PAYMENT_BY_ID_ASYNC: 'PUT_PAYMENT_BY_ID_ASYNC',
});

export default asyncTypes;
