// Core
import { apply, put } from 'redux-saga/effects';
import { saveAs } from 'file-saver';

// lodash
import has from 'lodash/has';
import pickBy from 'lodash/pickBy';

// config
import { api } from '../../../../../config/api';

// actions
import actions from '../../action';

// helpers
import { setErrorMessage } from '../../../../../../ui/_helpers/setNotificationMessage';

export function* callGetDownloadAttachmentWorker({ payload }) {
  yield put(actions.setPendingDownloadAttachment(true));

  const params = pickBy(payload, (value, key) => key !== 'fileName');
  const response = yield apply(api, api.email.getDownloadAttachment, [params]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      saveAs(data.content, data.fileName);
    }
  }

  yield put(actions.setPendingDownloadAttachment(false));
}
