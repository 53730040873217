// Core
import { all, call, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetDepartmentDocumentsWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callDeleteDepartmentDocumentWorker,
  callPostDepartmentDocumentWorker,
  callGetDepartmentDocumentByIdWorker,
  callPutDepartmentDocumentByIdWorker,
  callGetAutocompleteOptionsWorker,
  callGetNextNumberWorker,
} from './workers';
import types from '../types';

function* watchGetDepartmentDocuments() {
  yield takeEvery(asyncTypes.GET_DEPARTMENT_DOCUMENTS_ASYNC, callGetDepartmentDocumentsWorker);
}
function* watchGetInitialState() {
  yield takeEvery(
    asyncTypes.GET_DEPARTMENT_DOCUMENTS_INITIAL_STATE_ASYNC,
    callGetInitialStateWorker,
  );
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_DEPARTMENT_DOCUMENTS_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_DEPARTMENT_DOCUMENTS_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_DEPARTMENT_DOCUMENTS_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_DEPARTMENT_DOCUMENTS_SORTING, callSetToLocalDataWorker);
}
function* watchSetColumnWidths() {
  yield takeEvery(types.SET_DEPARTMENT_DOCUMENTS_COLUMN_WIDTHS, callSetToLocalDataWorker);
}
function* watchSetColumnOrder() {
  yield takeEvery(types.SET_DEPARTMENT_DOCUMENTS_COLUMN_ORDER, callSetToLocalDataWorker);
}
function* watchSetHiddenColumnNames() {
  yield takeEvery(types.SET_DEPARTMENT_DOCUMENTS_HIDDEN_COLUMN_NAMES, callSetToLocalDataWorker);
}

function* watchDeleteDepartmentDocument() {
  yield takeEvery(asyncTypes.DELETE_DEPARTMENT_DOCUMENT_ASYNC, callDeleteDepartmentDocumentWorker);
}

function* watchPostDepartmentDocument() {
  yield takeEvery(asyncTypes.POST_DEPARTMENT_DOCUMENT_ASYNC, callPostDepartmentDocumentWorker);
}

function* watchGetDepartmentDocumentById() {
  yield takeEvery(
    asyncTypes.GET_DEPARTMENT_DOCUMENT_BY_ID_ASYNC,
    callGetDepartmentDocumentByIdWorker,
  );
}

function* watchPutDepartmentDocumentById() {
  yield takeEvery(
    asyncTypes.PUT_DEPARTMENT_DOCUMENT_BY_ID_ASYNC,
    callPutDepartmentDocumentByIdWorker,
  );
}

function* watchGetAutocompleteOptions() {
  yield takeEvery(
    asyncTypes.GET_DEPARTMENT_DOCUMENTS_AUTOCOMPLETE_OPTIONS_ASYNC,
    callGetAutocompleteOptionsWorker,
  );
}

function* watchGetNextNumber() {
  yield takeEvery(asyncTypes.GET_DEPARTMENT_DOCUMENTS_NEXT_NUMBER_ASYNC, callGetNextNumberWorker);
}

export function* watchersDepartmentDocumentsTable() {
  yield all([
    call(watchGetDepartmentDocuments),
    call(watchSetCurrentPage),
    call(watchSetPageSize),
    call(watchSetFilters),
    call(watchSetSorting),
    call(watchSetColumnWidths),
    call(watchSetColumnOrder),
    call(watchSetHiddenColumnNames),
    call(watchGetInitialState),
    call(watchDeleteDepartmentDocument),
    call(watchPostDepartmentDocument),
    call(watchGetDepartmentDocumentById),
    call(watchPutDepartmentDocumentById),
    call(watchGetAutocompleteOptions),
    call(watchGetNextNumber),
  ]);
}
