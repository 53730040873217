// Core
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

// lodash
import {
  isEmpty, map, isBoolean, find,
} from 'lodash';

// actions
import actions from '../../../engine/core/currencies/currenciesList/action';
import actionAsync from '../../../engine/core/currencies/currenciesList/saga/asyncAction';
import helpersActions from '../../../engine/core/helpers/action';
import helpersAsyncAction from '../../../engine/core/helpers/saga/asyncAction';

// parts
import DxTable from '../../../ui/Table/DxTable';
import ConfirmModal from '../../../components/ConfirmModal';

// config
import selectors from '../../../engine/config/selectors';

// hooks
import { listTablesName, listEntityName } from '../../../engine/config/listTablesName';
import accessList from '../../../engine/config/accessList';

const tableName = listTablesName.currencies;
const entityName = listEntityName.currencies;

function CurrenciesTable(props) {
  const {
    currenciesData, totalCount, pending,
    currentPage, filters, sorting,
    pageSize, pendingDeleteCurrency,
    checkboxes, isModalOpen, columnWidths,
    columnOrder, hiddenColumnNames,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const columns = [
    { name: 'id', title: t('ID') },
    { name: 'name', title: t('Name') },
    { name: 'code', title: t('Code') },
    { name: 'sign', title: t('Mark') },
    { name: 'default', title: t('Base') },
  ];

  const editingStateColumnExtensionsCosts = [
    { columnName: 'id', editingEnabled: false },
    { columnName: 'name' },
    { columnName: 'code' },
    { columnName: 'sign' },
  ];

  const tableColumnExtensions = [
    { columnName: 'id', width: 50 },
    { columnName: 'name', width: '15rem' },
    { columnName: 'code', width: '8rem' },
    { columnName: 'sign', width: '8rem' },
    { columnName: 'default', width: '8rem' },
  ];

  const handleEditingChange = ({ changed }) => {
    const values = Object.values(changed)[0];
    const ids = Object.keys(changed);
    if (!isEmpty(values)) {
      const filterData = map(currenciesData.toJS().items, (item) => (
        item.id === Number(ids[0]) ? { ...item, ...values } : item));
      dispatch(actions.setCurrenciesData({
        items: filterData,
        totalCount: filterData.length,
        pending: false,
      }));
      if (isBoolean(values?.default)) {
        const findItem = find(currenciesData.toJS().items, { id: Number(ids[0]) });
        dispatch(actionAsync.putCurrencyByIdAsync({
          ...findItem,
          ...values,
        }));
        return;
      }
      dispatch(helpersAsyncAction.putMultipleAsync({
        body: {
          entityName: 'currencies',
          entityIds: ids,
          fields: values,
        },
      }));
    }
  };

  useEffect(() => function cleanup() {
    dispatch(actions.setCurrenciesData({
      items: [],
      totalCount: 0,
      pending: false,
    }));
  }, [dispatch]);

  // Paging
  const onCurrentPage = (page) => dispatch(actions.setCurrentPage(page));
  const onPageSize = (size) => dispatch(actions.setPageSize(size));
  // Filtering
  const setFilters = (getFilters) => dispatch(actions.setFilters(getFilters));

  // Sorting
  const onSortingChange = (getSorting) => dispatch(
    actions.setSorting(getSorting),
  );
  const onColumnWidthsChange = (getColumnWidths) => dispatch(
    actions.setColumnWidths(getColumnWidths),
  );
  const onColumnOrderChange = (getColumnOrder) => dispatch(
    actions.setColumnOrder(getColumnOrder),
  );
  const onHiddenColumnNamesChange = (getHiddenColumnNames) => dispatch(
    actions.setHiddenColumnNames(getHiddenColumnNames),
  );

  return (
    <>
      <DxTable
        name="Currencies"
        isLoading={pending}
        columns={columns}
        rows={currenciesData.toJS().items}
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageSize={onPageSize}
        onCurrentPage={onCurrentPage}
        filtersAction={setFilters}
        onSortingChange={onSortingChange}
        filters={filters}
        // checkboxes ------------------------
        tableName={tableName}
        reloadTable={actions.setReload}
        selection={checkboxes.toJS()}
        onSelectionChange={(checkboxSelect) => {
          dispatch(helpersActions.setCheckbox({
            tableName,
            checkboxes: checkboxSelect,
          }));
        }}
        // ------------------------
        entityName={entityName}
        editingExtensions={editingStateColumnExtensionsCosts}
        onEditingChange={handleEditingChange}
        sorting={sorting}
        isModalOpen={isModalOpen}
        tableColumnExtensions={!isEmpty(columnWidths) && columnWidths.length === columns.length
          ? columnWidths : tableColumnExtensions}
        onColumnWidthsChange={onColumnWidthsChange}
        columnOrder={!isEmpty(columnOrder) ? columnOrder : map(columns, 'name')}
        onColumnOrderChange={onColumnOrderChange}
        hiddenColumnNames={hiddenColumnNames}
        onHiddenColumnNamesChange={onHiddenColumnNamesChange}
        accessEdit={accessList.currencies_put}
        accessDelete={accessList.currencies_delete}
      />

      <ConfirmModal
        buttonSendText="DELETE"
        pending={pendingDeleteCurrency}
        handleSend={() => {
          dispatch(actionAsync.deleteCurrencyAsync());
        }}
      />
    </>
  );
}

CurrenciesTable.displayName = 'CurrenciesTable';

CurrenciesTable.propTypes = {
  currenciesData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  totalCount: PropTypes.number.isRequired,
  pending: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  sorting: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  columnWidths: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  columnOrder: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  hiddenColumnNames: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  pageSize: PropTypes.number.isRequired,
  pendingDeleteCurrency: PropTypes.bool.isRequired,
  checkboxes: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    currenciesData: selectors.currencies.currenciesData(state),
    pending: selectors.currencies.pending(state),
    pendingDeleteCurrency: selectors.currencies.pendingDeleteCurrency(state),
    isModalOpen: selectors.currencies.isModalOpen(state),

    // Paging
    totalCount: selectors.currencies.totalCount(state),
    currentPage: selectors.currencies.currentPage(state),
    pageSize: selectors.currencies.pageSize(state),

    // Filtering
    filters: selectors.currencies.filters(state),

    // Sorting
    sorting: selectors.currencies.sorting(state),

    // ColumnWidths
    columnWidths: selectors.currencies.columnWidths(state),
    columnOrder: selectors.currencies.columnOrder(state),
    hiddenColumnNames: selectors.currencies.hiddenColumnNames(state),

    checkboxes: selectors.helpers.getCheckboxes(state, tableName),
  };
}

function mapDispatchToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(CurrenciesTable);
