const asyncTypes = Object.freeze({
  GET_COMPANIES_ASYNC: 'GET_COMPANIES_ASYNC',
  GET_COMPANIES_INITIAL_STATE_ASYNC: 'GET_COMPANIES_INITIAL_STATE_ASYNC',
  GET_COMPANY_BY_ID_ASYNC: 'GET_COMPANY_BY_ID_ASYNC',
  POST_COMPANY_ASYNC: 'POST_COMPANY_ASYNC',
  PUT_COMPANY_BY_ID_ASYNC: 'PUT_COMPANY_BY_ID_ASYNC',
  DELETE_COMPANY_ASYNC: 'DELETE_COMPANY_ASYNC',
  GET_COMPANIES_AUTOCOMPLETE_OPTIONS_ASYNC: 'GET_COMPANIES_AUTOCOMPLETE_OPTIONS_ASYNC',
  POST_CHECK_FTP_ASYNC: 'POST_CHECK_FTP_ASYNC',
  GET_ALL_COMPANIES_ASYNC: 'GET_ALL_COMPANIES_ASYNC',
});

export default asyncTypes;
