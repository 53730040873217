// lodash
import pickBy from 'lodash/pickBy';

export const filterRemoveEntitiesInActionById = (orderById) => pickBy(
  orderById,
  (value, key) => key !== 'trademarks'
    && key !== 'inventions'
    && key !== 'utility'
    && key !== 'users'
    && key !== 'industrial',
);
