// Core
import React, { memo, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import * as PropTypes from 'prop-types';

// ui
import {
  Typography, Divider as MuiDivider,
  CardContent, Box, Link as MuiLink,
  FormControlLabel as MuiFormControlLabel,
  Grid, Card as MuiCard, Checkbox, Avatar as MuiAvatar,
} from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';

// lodash
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import map from 'lodash/map';
import includes from 'lodash/includes';
import without from 'lodash/without';
import replace from 'lodash/replace';
import sortBy from 'lodash/sortBy';

// parts
import Loader from '../../../../../components/Loader';
import AllTermsModal from './AllTermsModal';
import YourListTermsModal from './YourListTermsModal';

// actions
import mktuTemplatesAction from '../../../../../engine/core/tradeMarks/mktuTemplates/action';
import proposalActions from '../../../../../engine/core/tradeMarks/proposal/action';

// config
import selectors from '../../../../../engine/config/selectors';

// styles
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);

const Avatar = styled(MuiAvatar)`
  background-color: ${blue[500]};
`;
const CheckboxClass = styled(Checkbox)`
  height: calc(100% - 40px);
`;

const FormControlLabel = styled(MuiFormControlLabel)`
  opacity: 0.5;
`;
const Link = styled(MuiLink)`
margin-right: 10px;
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
`;

function AllClasses(props) {
  const {
    mktuClasses, selectMktuClasses, disabled,
    getMktuTemplateById, getSearch, pendingSearch,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedMktuToModal, setSelectedMktuToModal] = useState({});
  const [searchMktuToModal, setSearchMktuToModal] = useState({});

  const CheckboxList = (propsCheck) => {
    const {
      index, checked, onChange, item,
      originalClass,
    } = propsCheck;

    return !isEmpty(item.term) ? (
      <>
        {index < 3
          ? (
            <div key={index}>
              <MuiFormControlLabel
                control={(
                  <Checkbox
                    checked={checked}
                    onChange={(e) => {
                      onChange(e.target.checked, item.term, originalClass);
                    }}
                  />
                )}
                label={(
                  <Typography dangerouslySetInnerHTML={{ __html: item.term }} />
                )}
              />
            </div>
          ) : null}
        {index === 3
          ? (
            <div key={index}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={checked}
                    onChange={(e) => {
                      onChange(e.target.checked, item.term);
                    }}
                  />
                )}
                label={(
                  <Typography dangerouslySetInnerHTML={{ __html: item.term }} />
                )}
              />
            </div>
          ) : null}
      </>
    ) : null;
  };

  const termsToArray = (terms) => map(terms, (term) => {
    if (isObject(term)) {
      return term.value;
    }
    return term;
  }).join('; ');

  useEffect(() => {
    if (!isEmpty(getMktuTemplateById.toJS()) && !isEmpty(getMktuTemplateById.toJS().selected)
      && !isEmpty(mktuClasses.toJS()) && isEmpty(selectMktuClasses.toJS())) {
      const templateClassIdArray = Object.keys(getMktuTemplateById.toJS().selected);
      const selectTemplateClass = map(templateClassIdArray, (templateClassId) => {
        const selectClass = find(mktuClasses.toJS(), { id: Number(templateClassId) });
        const termsArray = getMktuTemplateById.toJS().selected[templateClassId];
        return ({
          ...selectClass,
          name: termsArray.length === 1 && termsArray[0] === 'selection'
            ? 'All'
            : termsToArray(termsArray),
        });
      });
      dispatch(proposalActions.setSelectMktuClasses(selectTemplateClass));
    }
  }, [
    getMktuTemplateById, mktuClasses,
    selectMktuClasses, dispatch,
  ]);

  const handleMktuClasses = (mktuClass) => {
    const findMktuClass = find(selectMktuClasses.toJS(), { id: mktuClass.id });
    if (isEmpty(findMktuClass)) {
      dispatch(proposalActions.setSelectMktuClasses([...selectMktuClasses, {
        ...mktuClass,
        name: 'All',
      }]));
      return;
    }
    dispatch(proposalActions.setSelectMktuClasses(selectMktuClasses.toJS().filter(
      (selectMktuClass) => (selectMktuClass.id !== mktuClass.id),
    )));
  };

  const handleModalAllTermsOpen = (mktuClass) => {
    setSelectedMktuToModal(mktuClass);
    setSearchMktuToModal({});
    dispatch(mktuTemplatesAction.setModalAllTermsOpen(true));
  };
  const handleModalYourListTermsOpen = (mktuClass) => {
    setSelectedMktuToModal(mktuClass);
    dispatch(mktuTemplatesAction.setModalYourListTermsOpen(true));
  };

  const handleModalTermsSearchOpen = (searchClass, originalClass) => {
    const terms = map(searchClass.items, 'term').join('; ');
    setSearchMktuToModal({
      ...originalClass,
      name: terms,
    });
    setSelectedMktuToModal({});
    dispatch(mktuTemplatesAction.setModalAllTermsOpen(true));
  };

  const handleSelectedTerms = (checked, term, originalClass) => {
    const trimTerm = replace(replace(term, '<strong>', ''), '</strong>', '');
    const findMktuClass = find(selectMktuClasses.toJS(), { id: originalClass.id });
    if (isEmpty(findMktuClass)) {
      dispatch(proposalActions.setSelectMktuClasses([
        ...selectMktuClasses,
        {
          ...originalClass,
          name: `${trimTerm}; `,
        },
      ]));
      return;
    }
    dispatch(proposalActions.setSelectMktuClasses([
      ...selectMktuClasses.toJS().filter(
        (selectClass) => (selectClass.id !== originalClass.id),
      ),
      {
        ...originalClass,
        name: checked
          ? [
            ...findMktuClass.name.split('; '),
            trimTerm,
          ].join('; ')
          : without(findMktuClass.name.split('; '), trimTerm).join('; '),
      },
    ]));
  };

  return !pendingSearch ? (
    <>
      {!isEmpty(mktuClasses.toJS()) && isEmpty(getSearch.toJS())
        ? map(sortBy(mktuClasses.toJS(), 'id'), (mktuClass) => {
          const selectMktuClass = find(selectMktuClasses.toJS(), { id: mktuClass.id });
          const isChecked = !isEmpty(selectMktuClass) && selectMktuClass.name === 'All';
          const isIndeterminate = !isEmpty(selectMktuClass) && selectMktuClass.name !== 'All';
          return (
            <Card key={mktuClass.id} mb={3}>
              <CardContent>
                <Grid container wrap="nowrap" spacing={2}>
                  <Grid item>
                    <Avatar>{mktuClass.classNum}</Avatar>
                    <CheckboxClass
                      checked={isChecked}
                      indeterminate={isIndeterminate}
                      name="Checkbox"
                      onChange={() => handleMktuClasses(mktuClass)}
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs>
                    <Typography>{mktuClass.label}</Typography>
                  </Grid>
                </Grid>
                <Divider my={2} />
                {!disabled && (
                  <Box mt={2}>
                    <Link to="/" onClick={() => handleModalAllTermsOpen(mktuClass)} mr={4}>
                      {t('Terms')}
                    </Link>
                    <Link to="/" onClick={() => handleModalYourListTermsOpen(mktuClass)}>
                      {t('Edit list')}
                    </Link>
                  </Box>
                )}
              </CardContent>
            </Card>
          );
        }) : null}

      {!isEmpty(getSearch.toJS()) && !isEmpty(getSearch.toJS().items)
        ? map(Object.keys(getSearch.toJS().items), (mktuId) => {
          const selectMktuClass = find(selectMktuClasses.toJS(), { id: Number(mktuId) });
          const isChecked = !isEmpty(selectMktuClass) && selectMktuClass.name === 'All';
          const isIndeterminate = !isEmpty(selectMktuClass) && selectMktuClass.name !== 'All';
          const searchClass = getSearch.toJS().items[mktuId];
          const originalClass = find(mktuClasses.toJS(), { id: Number(mktuId) });
          return (
            <Card key={mktuId} mb={3}>
              <CardContent>
                <Grid container wrap="nowrap" spacing={2}>
                  <Grid item>
                    <Avatar>{selectMktuClass?.classNum}</Avatar>
                    <CheckboxClass
                      checked={isChecked}
                      indeterminate={isIndeterminate}
                      name="Checkbox"
                      onChange={() => handleMktuClasses(originalClass)}
                    />
                  </Grid>
                  <Grid item xs>
                    <Typography>{searchClass.title}</Typography>
                    {!isEmpty(searchClass) && !isEmpty(searchClass.items)
                      && map(searchClass.items, (item, index) => {
                        const trimTerm = replace(replace(item.term, '<strong>', ''), '</strong>', '');
                        const isCheckedTerm = !isEmpty(selectMktuClass)
                          && (selectMktuClass.name === 'All'
                          || includes(selectMktuClass.name, trimTerm));
                        return (
                          <CheckboxList
                            key={index}
                            index={index}
                            checked={isCheckedTerm}
                            originalClass={originalClass}
                            onChange={handleSelectedTerms}
                            item={item}
                          />
                        );
                      })}
                    {!isEmpty(searchClass) && !isEmpty(searchClass.items)
                      && searchClass.items.length > 4
                      ? (
                        <Link
                          variant="subtitle2"
                          to="/"
                          onClick={() => handleModalTermsSearchOpen(searchClass, originalClass)}
                        >
                          . . .
                        </Link>
                      )
                      : null}
                  </Grid>
                </Grid>
                <Divider my={2} />
                <Box mt={2}>
                  <Link to="/" onClick={() => handleModalAllTermsOpen(originalClass)} mr={4}>
                    {t('Terms')}
                  </Link>
                  <Link to="/" onClick={() => handleModalYourListTermsOpen(originalClass)}>
                    {t('Edit list')}
                  </Link>
                </Box>
              </CardContent>
            </Card>
          );
        }) : null}

      <AllTermsModal
        mktuClass={selectedMktuToModal}
        searchMktuClass={searchMktuToModal}
      />
      <YourListTermsModal
        mktuClass={selectedMktuToModal}
      />
    </>
  ) : <Loader />;
}

AllClasses.displayName = 'AllClasses';

AllClasses.propTypes = {
  mktuClasses: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  selectMktuClasses: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  getMktuTemplateById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  getSearch: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingSearch: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};
AllClasses.defaultProps = {
  disabled: false,
};

function mapStateToProps(state) {
  return {
    mktuClasses: selectors.tradeMarksProposal.classes(state),
    selectMktuClasses: selectors.tradeMarksProposal.selectMktuClasses(state),

    getMktuTemplateById: selectors.mktuTemplates.getMktuTemplatesById(state),
    getSearch: selectors.rebounds.getSearchRebound(state),
    pendingSearch: selectors.rebounds.pendingSearchRebound(state),
  };
}

export default compose(connect(mapStateToProps, null))(memo(AllClasses));
