// Core
import React, {
  useCallback, useMemo, memo,
} from 'react';
import { connect, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

// parts
import {
  Box, Button, Card, CardContent, Typography,
} from '@material-ui/core';
import { filter, isEmpty, isEqual } from 'lodash';
import DxTable from '../../../ui/Table/DxTable';
import Send from '../../Send/Send';

// actions
import actions from '../../../engine/core/sending/action';

// config
import selectors from '../../../engine/config/selectors';
import { initialState } from '../../../engine/core/sending/reducer';
import globalStateKeys from '../../../engine/config/globalStateKeys';

import { listTablesName, listEntityName } from '../../../engine/config/listTablesName';
import helpersActions from '../../../engine/core/helpers/action';
import accessList from '../../../engine/config/accessList';

const tableName = listTablesName.outdocs;
const entityName = listEntityName.outdocs;

function TableOutdocs(props) {
  const {
    data, totalCount, pending,
    currentPage, filters, documentIdSend,
    pageSize, checkboxes, sorting,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const columns = [
    { name: 'id', title: t('ID') },
    { name: 'sendingLog', title: t('Status') },
    { name: 'sentAt', title: t('Sent') },
  ];

  // Paging
  const onCurrentPage = (page) => dispatch(actions.setCurrentPage(page));
  const onPageSize = (size) => dispatch(actions.setPageSize(size));

  // Filtering
  const setFilters = (getFilters) => dispatch(actions.setFilters(getFilters));

  // Sorting
  const onSortingChange = (getSorting) => dispatch(actions.setSorting(getSorting));

  // Reset filters
  const resetFilters = useCallback(() => {
    const dataInitialState = initialState.get(globalStateKeys.data);
    dispatch(actions.setFilters(dataInitialState.get(globalStateKeys.filters).toJS()));
    dispatch(actions.setCurrentPage(dataInitialState.get(globalStateKeys.currentPage)));
  }, [dispatch]);

  const isEmptyFilters = useMemo(() => isEmpty(filter(filters,
    (currentFilter) => !currentFilter.always)), [filters]);

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" paragraph>
          {t('OutDocs')}
        </Typography>
        <Box mb={3}>
          <Button
            mr={2}
            variant="contained"
            color="primary"
            disabled={isEmptyFilters}
            onClick={resetFilters}
          >
            {t('RESET FILTERS')}
          </Button>
        </Box>

        <DxTable
          name="Table"
          isLoading={pending}
          columns={columns}
          resetFilters={resetFilters}
          rows={data.toJS().items}
          totalCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          // checkboxes ------------------------
          tableName={tableName}
          reloadTable={actions.setReload}
          selection={checkboxes.toJS()}
          onSelectionChange={(checkboxSelect) => {
            dispatch(helpersActions.setCheckbox({
              tableName,
              checkboxes: checkboxSelect,
            }));
          }}
          // ------------------------
          onPageSize={onPageSize}
          onCurrentPage={onCurrentPage}
          filtersAction={setFilters}
          filters={filters}
          entityName={entityName}
          sorting={sorting}
          onSortingChange={onSortingChange}
          accessEdit={accessList.documents_put}
          accessDelete={accessList.documents_delete}
          disableHiddenColumn
          disableColumnOrder
          disableColumnWidth
          disableExport
        />
        <Send
          visibleButton={false}
          type="outdocs"
          entityIds={[documentIdSend]}
        />
      </CardContent>
    </Card>
  );
}

TableOutdocs.displayName = 'Table';

TableOutdocs.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  totalCount: PropTypes.number.isRequired,
  pending: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  sorting: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  pageSize: PropTypes.number.isRequired,
  checkboxes: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  documentIdSend: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

function mapStateToProps(state) {
  return {
    data: selectors.sending.sendingData(state),
    pending: selectors.sending.pending(state),

    // Paging
    totalCount: selectors.sending.totalCount(state),
    currentPage: selectors.sending.currentPage(state),
    pageSize: selectors.sending.pageSize(state),

    // Filtering
    filters: selectors.sending.filters(state),

    sorting: selectors.sending.sorting(state),

    checkboxes: selectors.helpers.getCheckboxes(state, tableName),
    documentIdSend: selectors.departmentDocuments.documentIdSend(state),
  };
}

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.checkboxes, nextProps.checkboxes)
  && isEqual(prevProps.currentPage, nextProps.currentPage)
  && isEqual(prevProps.data, nextProps.data)
  && isEqual(prevProps.filters, nextProps.filters)
  && isEqual(prevProps.pageSize, nextProps.pageSize)
  && isEqual(prevProps.pending, nextProps.pending)
  && isEqual(prevProps.sorting, nextProps.sorting)
  && isEqual(prevProps.documentIdSend, nextProps.documentIdSend)
  && isEqual(prevProps.totalCount, nextProps.totalCount);
}

export default compose(
  connect(mapStateToProps, null),
)(memo(TableOutdocs, areEqual));
