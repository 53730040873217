// Core
import { all, call, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetActionTypesWorker,
  callDeleteActionTypeWorker,
  callPostActionTypeWorker,
  callGetActionTypeByIdWorker,
  callPutActionTypeByIdWorker,
} from './workers';

function* watchGetActionTypes() {
  yield takeEvery(asyncTypes.GET_ACTION_TYPES_ASYNC, callGetActionTypesWorker);
}

function* watchDeleteActionType() {
  yield takeEvery(asyncTypes.DELETE_ACTION_TYPE_ASYNC, callDeleteActionTypeWorker);
}

function* watchPostActionType() {
  yield takeEvery(asyncTypes.POST_ACTION_TYPE_ASYNC, callPostActionTypeWorker);
}

function* watchGetActionTypeById() {
  yield takeEvery(asyncTypes.GET_ACTION_TYPE_BY_ID_ASYNC, callGetActionTypeByIdWorker);
}

function* watchPutActionTypeById() {
  yield takeEvery(asyncTypes.PUT_ACTION_TYPE_BY_ID_ASYNC, callPutActionTypeByIdWorker);
}

export function* watchersActionTypes() {
  yield all([
    call(watchGetActionTypes),
    call(watchDeleteActionType),
    call(watchPostActionType),
    call(watchGetActionTypeById),
    call(watchPutActionTypeById),
  ]);
}
