// Core
import React, { useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

// Lodash
import {
  map, isEmpty, find,
} from 'lodash';
import DxTable from '../../../ui/Table/DxTable';

// actions
import helpersActions from '../../../engine/core/helpers/action';
import ordersActions from '../../../engine/core/company/orders/action';
import ordersActionAsync from '../../../engine/core/company/orders/saga/asyncAction';

// config
import selectors from '../../../engine/config/selectors';

// parts
import ConfirmModal from '../../../components/ConfirmModal';

import { listTablesName, listEntityName } from '../../../engine/config/listTablesName';
import accessList from '../../../engine/config/accessList';

const tableName = listTablesName.orders;
const entityName = listEntityName.orders;

function OrdersTable(props) {
  const {
    ordersData, totalCount, pending, currenciesData,
    currentPage, filters, sorting,
    pageSize, pendingDeleteOrder, checkboxes,
    columnWidths, columnOrder, hiddenColumnNames,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const basePriseCode = useMemo(() => {
    const findCurrency = find(currenciesData.toJS().items, 'default');
    return findCurrency?.code || '';
  }, [currenciesData]);

  const columns = [
    { name: 'id', title: t('ID') },
    { name: 'orderNum', title: '№' },
    { name: 'clientName', title: t('Client') },
    { name: 'createdAt', title: t('Created At') },
    { name: 'totalSum', title: basePriseCode ? `${t('Amount')} (${basePriseCode})` : t('Amount') },
    { name: 'billSum', title: t('To pay') },
    { name: 'currency', title: t('Currency') },
    { name: 'payedSum', title: t('Paid amount') },
    { name: 'payed', title: t('Payed') },
  ];

  const tableColumnExtensions = [
    { columnName: 'id', width: 50 },
    { columnName: 'orderNum', width: 50 },
    { columnName: 'clientName', width: '18rem' },
    { columnName: 'createdAt', width: '8rem' },
    { columnName: 'totalSum', width: '8rem' },
    { columnName: 'billSum', width: '7rem' },
    { columnName: 'payedSum', width: '7rem' },
    { columnName: 'currency', width: '9rem' },
    { columnName: 'payed', width: '8rem' },
  ];

  // Paging
  const onCurrentPage = (page) => dispatch(ordersActions.setCurrentPage(page));
  const onPageSize = (size) => dispatch(ordersActions.setPageSize(size));
  // Filtering
  const setFilters = (getFilters) => dispatch(ordersActions.setFilters(getFilters));
  // Sorting
  const onSortingChange = (getSorting) => dispatch(ordersActions.setSorting(getSorting));
  const onColumnWidthsChange = (getColumnWidths) => dispatch(
    ordersActions.setColumnWidths(getColumnWidths),
  );
  const onColumnOrderChange = (getColumnOrder) => dispatch(
    ordersActions.setColumnOrder(getColumnOrder),
  );
  const onHiddenColumnNamesChange = (getHiddenColumnNames) => dispatch(
    ordersActions.setHiddenColumnNames(getHiddenColumnNames),
  );

  return (
    <>
      {/* <TableExtraFilter
        filters={filters}
        filtersAction={setFilters}
      /> */}
      <DxTable
        name="Orders"
        isLoading={pending}
        columns={columns}
        rows={ordersData.toJS().items}
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageSize={onPageSize}
        onCurrentPage={onCurrentPage}
        filtersAction={setFilters}
        filters={filters}
        entityName={entityName}
        tableColumnExtensions={!isEmpty(columnWidths) && columnWidths.length === columns.length
          ? columnWidths : tableColumnExtensions}
        onColumnWidthsChange={onColumnWidthsChange}
        columnOrder={!isEmpty(columnOrder) ? columnOrder : map(columns, 'name')}
        onColumnOrderChange={onColumnOrderChange}
        hiddenColumnNames={hiddenColumnNames}
        onHiddenColumnNamesChange={onHiddenColumnNamesChange}
        // checkboxes ------------------------
        tableName={tableName}
        reloadTable={ordersActions.setReload}
        selection={checkboxes.toJS()}
        onSelectionChange={(checkboxSelect) => {
          dispatch(helpersActions.setCheckbox({
            tableName,
            checkboxes: checkboxSelect,
          }));
        }}
        // ------------------------
        sorting={sorting}
        onSortingChange={onSortingChange}
        accessEdit={accessList.orders_get}
        accessDelete={accessList.orders_delete}
      />

      <ConfirmModal
        buttonSendText="DELETE"
        pending={pendingDeleteOrder}
        handleSend={() => {
          dispatch(ordersActionAsync.deleteOrderAsync());
        }}
      />
    </>
  );
}

OrdersTable.displayName = 'OrdersTable';

OrdersTable.propTypes = {
  ordersData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  totalCount: PropTypes.number.isRequired,
  pending: PropTypes.bool.isRequired,
  pendingDeleteOrder: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  sorting: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  columnWidths: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  columnOrder: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  hiddenColumnNames: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  pageSize: PropTypes.number.isRequired,
  checkboxes: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  currenciesData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

OrdersTable.defaultProps = {};

function mapStateToProps(state) {
  return {
    ordersData: selectors.ordersTable.ordersData(state),
    pending: selectors.ordersTable.pending(state),
    pendingDeleteOrder: selectors.ordersTable.pendingDeleteOrder(state),

    // Paging
    totalCount: selectors.ordersTable.totalCount(state),
    currentPage: selectors.ordersTable.currentPage(state),
    pageSize: selectors.ordersTable.pageSize(state),

    // Filtering
    filters: selectors.ordersTable.filters(state),

    // Sorting
    sorting: selectors.ordersTable.sorting(state),

    // ColumnWidths
    columnWidths: selectors.ordersTable.columnWidths(state),
    columnOrder: selectors.ordersTable.columnOrder(state),
    hiddenColumnNames: selectors.ordersTable.hiddenColumnNames(state),

    checkboxes: selectors.helpers.getCheckboxes(state, tableName),
    currenciesData: selectors.currencies.currenciesData(state),
  };
}

function mapDispatchToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(OrdersTable);
