// Core
import { call, put, select } from 'redux-saga/effects';
import { List } from 'immutable';

// lodash
import has from 'lodash/has';
import filter from 'lodash/filter';

// config
import { api } from '../../../../../config/api';
import selectors from '../../../../../config/selectors';

// init
import i18n from '../../../../../init/i18n';

// actions
import employeesActions from '../../action';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../../ui/_helpers/setNotificationMessage';

export function* callDeleteKeyWorker({ payload: keyId }) {
  const { setKeysData } = employeesActions;

  yield put(setKeysData({
    pendingDelete: true,
  }));

  const response = yield call(api.company.deleteKey, keyId);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      const keys = yield select(selectors.employees.getKeysData);
      yield put(setKeysData({
        openFieldsCreateKey: false,
        data: List(filter(keys.toJS(), (key) => key.id !== keyId)),
      }));
      yield put(setSuccessMessage(i18n.t('Key deleted!'), response.status.toString()));
    }
  }

  yield put(setKeysData({
    pendingDelete: false,
  }));
}
