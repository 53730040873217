/**
 * @props-required: data
 * @props-not-required: multiple
 * data - данные конкретной сущности с возможным наличием данных для автокомплитов
 * multiple - если Autocomplete содержит массив объектов
 * @description:
 * Есть функции getEntitiesById, setEntitiesById, fieldsAutocomplete,
 * где можно добавлять новые поля для других автокомплитов.
 * На выход getEntitiesById, isReadyToUse, isReadyOriginalToUse:
 * - getEntitiesById: сбор данных для полей автокомплита
 * - isReadyToUse: для проверки на готовность использовать getEntitiesById
 * - isReadyOriginalToUse: проверка данных из original на готовность использовать getEntitiesById
 * */
// Core
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// lodash
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import keys from 'lodash/keys';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isNumber from 'lodash/isNumber';
import pickBy from 'lodash/pickBy';

// actions Async
import clientsActionsAsync from '../../../../engine/core/company/clients/saga/asyncAction';
import employeesActionsAsync from '../../../../engine/core/company/employees/saga/asyncAction';
import tradeMarksActionsAsync from '../../../../engine/core/tradeMarks/proposal/saga/asyncAction';
import utilityModelsActionsAsync from '../../../../engine/core/utilityModels/saga/asyncAction';
import inventionsActionsAsync from '../../../../engine/core/inventions/saga/asyncAction';
import industrialDesignsActionsAsync from '../../../../engine/core/industrialDesigns/saga/asyncAction';
import documentsActionsAsync from '../../../../engine/core/departmentDocuments/saga/asyncAction';
import chargesActionsAsync from '../../../../engine/core/charges/saga/asyncAction';

// actions
import clientsActions from '../../../../engine/core/company/clients/action';
import employeesActions from '../../../../engine/core/company/employees/action';
import industrialDesignsActions from '../../../../engine/core/industrialDesigns/action';
import inventionsActions from '../../../../engine/core/inventions/action';
import utilityModelsActions from '../../../../engine/core/utilityModels/action';
import tradeMarksActions from '../../../../engine/core/tradeMarks/proposal/action';
import documentsActions from '../../../../engine/core/departmentDocuments/action';
import chargesActions from '../../../../engine/core/charges/action';

// config
import selectors from '../../../../engine/config/selectors';

// TODO: Нада бы придумать как можно уменьшить количество кода в этом хуке.
// TODO: Например разбить на подхуки
export function useSetFieldsAutocomplete({ data, multiple, cleanupEntity = true }) {
  const dispatch = useDispatch();
  const getClientById = useSelector(selectors.clients.getClientById);
  const getEmployeeById = useSelector(selectors.employees.getEmployeeById);
  const getDocumentById = useSelector(selectors.departmentDocuments.getDepartmentDocumentById);
  const getChargeById = useSelector(selectors.charges.getChargeById);
  const getTradeMarksProposalById = useSelector(selectors.tradeMarksProposal.getProposalById);
  const getInventionsProposalById = useSelector(selectors.inventionsProposal.getProposalById);
  const getIndustrialDesignsProposalById = useSelector(
    selectors.industrialDesignsProposal.getProposalById,
  );
  const getUtilityModelsProposalById = useSelector(selectors.utilityModelsProposal.getProposalById);
  const [readyProposalById, setReadyProposalById] = React.useState(false);
  const clientById = getClientById.toJS();
  const employeeById = getEmployeeById.toJS();
  const documentById = getDocumentById;
  const chargeById = getChargeById.toJS().data;
  const tradeMarksProposalById = getTradeMarksProposalById.toJS();
  const inventionsProposalById = getInventionsProposalById.toJS();
  const industrialDesignsProposal = getIndustrialDesignsProposalById.toJS();
  const utilityModelsProposal = getUtilityModelsProposalById.toJS();

  const checkOrdersFields = React.useMemo(() => {
    const originalFields = {
      ...data.original ? {
        original: {
          ...isNumber(data.original.clients) ? {
            clients: data.original.clients,
          } : {},
          ...isNumber(data.original.client) ? {
            clients: data.original.client,
          } : {},
          ...isNumber(data.original.inventions) ? {
            inventions: data.original.inventions,
          } : {},
          ...isNumber(data.original.utility) ? {
            utility: data.original.utility,
          } : {},
          ...isNumber(data.original.industrial) ? {
            industrial: data.original.industrial,
          } : {},
          ...isNumber(data.original.trademarks) ? {
            trademarks: data.original.trademarks,
          } : {},
        },
      } : {},
    };

    return !isEmpty(originalFields) && !isEmpty(originalFields.original) ? originalFields : {};
  }, [data]);

  const fieldsAutocomplete = React.useMemo(() => ({
    ...isNumber(data.clients) ? {
      clients: data.clients,
    } : {},
    ...isNumber(data.client) ? {
      client: data.client,
    } : {},
    ...isNumber(data.users) ? {
      users: data.users,
    } : {},
    ...isNumber(data.employee) ? {
      employee: data.employee,
    } : {},
    ...isNumber(data.inventions) ? {
      inventions: data.inventions,
    } : {},
    ...isNumber(data.utility) ? {
      utility: data.utility,
    } : {},
    ...isNumber(data.industrial) ? {
      industrial: data.industrial,
    } : {},
    ...isNumber(data.trademarks) ? {
      trademarks: data.trademarks,
    } : {},
    ...isNumber(data.documents) ? {
      documents: data.documents,
    } : {},
    ...isNumber(data.charges) ? {
      charges: data.charges,
    } : {},
    ...checkOrdersFields,
  }), [data, checkOrdersFields]);

  const fieldData = React.useCallback((entityData) => (multiple ? [entityData] : entityData), [
    multiple,
  ]);

  const getEntitiesById = React.useMemo(() => ({
    ...!isEmpty(tradeMarksProposalById) && isNumber(data.trademarks) ? {
      trademarks: fieldData(tradeMarksProposalById),
    } : {},
    ...!isEmpty(utilityModelsProposal) && isNumber(data.utility) ? {
      utility: fieldData(utilityModelsProposal),
    } : {},
    ...!isEmpty(inventionsProposalById) && isNumber(data.inventions) ? {
      inventions: fieldData(inventionsProposalById),
    } : {},
    ...!isEmpty(industrialDesignsProposal) && isNumber(data.industrial) ? {
      industrial: fieldData(industrialDesignsProposal),
    } : {},
    ...!isEmpty(clientById) && isNumber(data.clients) ? {
      clients: fieldData(clientById),
    } : {},
    ...!isEmpty(clientById) && isNumber(data.client) ? {
      client: fieldData(clientById),
    } : {},
    ...!isEmpty(documentById) && isNumber(data.documents) ? {
      documents: fieldData(documentById),
    } : {},
    ...!isEmpty(chargeById) && isNumber(data.charges) ? {
      charges: fieldData(chargeById),
    } : {},
    ...!isEmpty(employeeById) ? {
      ...isNumber(data.employee) ? {
        employee: fieldData(employeeById),
      } : {},
      ...isNumber(data.users) ? {
        users: fieldData(employeeById),
      } : {},
    } : {},
    // Если есть вложенные объекты. То есть на бэке приходит объект original.trademarks и т.д.
    // По необходимости добавить новые объекты, кроме original
    ...data.original ? {
      original: {
        ...!isEmpty(tradeMarksProposalById) && isNumber(data.original.trademarks) ? {
          trademarks: fieldData(tradeMarksProposalById),
        } : {},
        ...!isEmpty(industrialDesignsProposal) && isNumber(data.original.industrial) ? {
          industrial: fieldData(industrialDesignsProposal),
        } : {},
        ...!isEmpty(utilityModelsProposal) && isNumber(data.original.utility) ? {
          utility: fieldData(utilityModelsProposal),
        } : {},
        ...!isEmpty(inventionsProposalById) && isNumber(data.original.inventions) ? {
          inventions: fieldData(inventionsProposalById),
        } : {},
        ...!isEmpty(clientById) && isNumber(data.original.clients) ? {
          clients: fieldData(clientById),
        } : {},
      },
    } : {},
  }), [
    fieldData, inventionsProposalById, tradeMarksProposalById, employeeById,
    industrialDesignsProposal, utilityModelsProposal, clientById, data,
    documentById, chargeById,
  ]);

  const filterNotOriginal = (entities) => pickBy(entities, (value, key) => key !== 'original');

  const setEntitiesById = React.useCallback(() => {
    forEach(fieldsAutocomplete, (value, key) => {
      switch (key) {
        // Для вложенного объекта original
        case 'original': {
          if (!isArray(value) && isObject(value)) {
            if (isEmpty(tradeMarksProposalById) && value.trademarks) {
              dispatch(tradeMarksActionsAsync.getProposalByIdAsync(value.trademarks));
            }
            if (isEmpty(industrialDesignsProposal) && value.industrial) {
              dispatch(industrialDesignsActionsAsync.getProposalByIdAsync(value.industrial));
            }
            if (isEmpty(utilityModelsProposal) && value.utility) {
              dispatch(utilityModelsActionsAsync.getProposalByIdAsync(value.utility));
            }
            if (isEmpty(inventionsProposalById) && value.inventions) {
              dispatch(inventionsActionsAsync.getProposalByIdAsync(value.inventions));
            }
            if (isEmpty(clientById) && value.clients) {
              dispatch(clientsActionsAsync.getClientByIdAsync(value.clients));
            }
          }
          break;
        }
        case 'trademarks': {
          if (isEmpty(tradeMarksProposalById)) {
            dispatch(tradeMarksActionsAsync.getProposalByIdAsync(value));
          }
          break;
        }
        case 'utility': {
          if (isEmpty(utilityModelsProposal)) {
            dispatch(utilityModelsActionsAsync.getProposalByIdAsync(value));
          }
          break;
        }
        case 'inventions': {
          if (isEmpty(inventionsProposalById)) {
            dispatch(inventionsActionsAsync.getProposalByIdAsync(value));
          }
          break;
        }
        case 'industrial': {
          if (isEmpty(industrialDesignsProposal)) {
            dispatch(industrialDesignsActionsAsync.getProposalByIdAsync(value));
          }
          break;
        }
        case 'client':
        case 'clients': {
          if (isEmpty(clientById)) {
            dispatch(clientsActionsAsync.getClientByIdAsync(value));
          }
          break;
        }
        case 'documents': {
          if (isEmpty(documentById)) {
            dispatch(documentsActionsAsync.getDepartmentDocumentByIdAsync(value));
          }
          break;
        }
        case 'charges': {
          if (isEmpty(chargeById)) {
            dispatch(chargesActionsAsync.getChargeByIdAsync(value));
          }
          break;
        }
        case 'users':
        case 'employee': {
          if (isEmpty(employeeById)) {
            dispatch(employeesActionsAsync.getEmployeeByIdAsync(value));
          }
          break;
        }
        default:
          break;
      }
    });
  }, [
    employeeById, dispatch, fieldsAutocomplete, clientById,
    inventionsProposalById, tradeMarksProposalById, chargeById,
    industrialDesignsProposal, utilityModelsProposal, documentById,
  ]);

  React.useEffect(() => {
    if (!isEmpty(fieldsAutocomplete) && !readyProposalById) {
      setReadyProposalById(true);
      setEntitiesById();
    }
  }, [
    setEntitiesById,
    fieldsAutocomplete, dispatch,
    readyProposalById,
    setReadyProposalById,
  ]);

  React.useEffect(() => function cleanup() {
    if (cleanupEntity) {
      dispatch(employeesActions.setEmployeeById({}));
      dispatch(clientsActions.setClientById({}));
      dispatch(industrialDesignsActions.setProposalDataById({}));
      dispatch(inventionsActions.setProposalDataById({}));
      dispatch(utilityModelsActions.setProposalDataById({}));
      dispatch(tradeMarksActions.setProposalDataById({}));
      dispatch(documentsActions.setDepartmentDocumentById({}));
      dispatch(chargesActions.setChargeById({}));
    }
  }, [dispatch, cleanupEntity]);

  return {
    isReadyToUse: keys(filterNotOriginal(getEntitiesById)).length
      === keys(filterNotOriginal(fieldsAutocomplete)).length,
    // проверить наличение данных в original для использования getEntitiesById
    isReadyOriginalToUse: keys(getEntitiesById.original).length
      === keys(fieldsAutocomplete.original).length,
    getEntitiesById,
  };
}
