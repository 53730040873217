// Core
import { call, put } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../config/api';
import { messageRequestSuccess } from '../../../../config/globalConfig';

// init
import i18n from '../../../../init/i18n';

// actions
import pendingActions from '../../action';
import pendingTableActions from '../../table/action';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../ui/_helpers/setNotificationMessage';

export function* callPatchPendingActionByIdWorker({ payload: action }) {
  const { setPendingActionById, setIsModalOpen, setDisableNotifications } = pendingActions;

  yield put(setPendingActionById({ pendingPut: true }));

  const response = yield call(api.pendingActions.patchPendingActionById, action);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      yield put(pendingActions.setReload(true));
      yield put(setIsModalOpen(false));
      yield put(pendingTableActions.setReload(true));
      yield put(setDisableNotifications(false));
      yield put(setSuccessMessage(i18n.t(messageRequestSuccess), response.status.toString()));
    }
  }

  yield put(setPendingActionById({
    pendingPut: false,
  }));
}
