// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import types from './types';

const actions = Object.freeze({
  setMonitoringData(searchesData) {
    return actionCreator(types.SET_MONITORING_DATA, searchesData);
  },
  setCurrentPage(currentPage) {
    return actionCreator(types.SET_MONITORING_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return actionCreator(types.SET_MONITORING_FILTERS, filters);
  },
  setSorting(sorting) {
    return actionCreator(types.SET_MONITORING_SORTING, sorting);
  },
  setColumnWidths(data) {
    return actionCreator(types.SET_MONITORING_COLUMN_WIDTHS, data);
  },
  setColumnOrder(data) {
    return actionCreator(types.SET_MONITORING_COLUMN_ORDER, data);
  },
  setHiddenColumnNames(data) {
    return actionCreator(types.SET_MONITORING_HIDDEN_COLUMN_NAMES, data);
  },
  setPageSize(pageSize) {
    return actionCreator(types.SET_MONITORING_PAGE_SIZE, pageSize);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_MONITORING_INITIAL_STATE, initialState);
  },
  setReload(reload) {
    return actionCreator(types.SET_MONITORING_RELOAD, reload);
  },
  setDeleteMonitoring(data) {
    return actionCreator(types.SET_DELETE_MONITORING, data);
  },
  setMonitoringById(searchData) {
    return actionCreator(types.SET_MONITORING_BY_ID, searchData);
  },
  setTabs(nameTab, data) {
    return actionCreator(types.SET_TABS, {
      nameTab, data,
    });
  },
  setMonitoringReportData(reportData) {
    return actionCreator(types.SET_MONITORING_REPORT_DATA, reportData);
  },
});

export default actions;
