export default Object.freeze({
  SET_PENDING_ACTION_DATA: 'SET_PENDING_ACTION_DATA',
  SET_PENDING_ACTION_CURRENT_PAGE: 'SET_PENDING_ACTION_CURRENT_PAGE',
  SET_PENDING_ACTION_PAGE_SIZE: 'SET_PENDING_ACTION_PAGE_SIZE',
  SET_PENDING_ACTION_FILTERS: 'SET_PENDING_ACTION_FILTERS',
  SET_PENDING_ACTION_SORTING: 'SET_PENDING_ACTION_SORTING',
  SET_PENDING_ACTION_INITIAL_STATE: 'SET_PENDING_ACTION_INITIAL_STATE',
  SET_PENDING_ACTION_RELOAD: 'SET_PENDING_ACTION_RELOAD',
  SET_PENDING_ACTION_CLEAR_FILTERS: 'SET_PENDING_ACTION_CLEAR_FILTERS',
  SET_PENDING_ACTION_COLUMN_WIDTHS: 'SET_PENDING_ACTION_COLUMN_WIDTHS',
  SET_PENDING_ACTION_COLUMN_ORDER: 'SET_PENDING_ACTION_COLUMN_ORDER',
  SET_PENDING_ACTION_HIDDEN_COLUMN_NAMES: 'SET_PENDING_ACTION_HIDDEN_COLUMN_NAMES',
});
