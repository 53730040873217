// core
import React, { useMemo } from 'react';
import { Field } from 'redux-form/lib/immutable';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

// parts
import AutocompleteField from '../AutocompleteField';

// config
import selectors from '../../../engine/config/selectors';

// helpers
import { getValueFormField } from '../../_helpers/getValueFormField';
import { searchParams } from './helper/searchParams';

//
import { useMain } from './_hook/useMain';

function FieldApplicant(props) {
  const {
    data, formFieldInput, pendingDefaultList,
    autocompleteOptions, name, propsField, label,
    getAutocompleteLists, searchParamName, formField,
    getAsyncData,
  } = props;
  const { t } = useTranslation();

  const autocompleteOptionsItems = useMemo(() => autocompleteOptions.get(searchParamName), [
    autocompleteOptions, searchParamName,
  ]);

  const {
    startLoading, handleOnFocus, dataItems, checkOptions,
  } = useMain({
    formField,
    getAutocompleteLists,
    formFieldInput,
  });

  return (
    <Field
      fullWidth
      changeToStore
      name={name}
      label={t(label)}
      margin="normal"
      getAsyncData={getAsyncData}
      loading={startLoading ? (pendingDefaultList) : false}
      handleOnFocus={handleOnFocus(name, data)}
      options={checkOptions && !autocompleteOptionsItems
        ? dataItems(data)
        : autocompleteOptionsItems}
      component={AutocompleteField}
      {...propsField}
    />
  );
}

FieldApplicant.displayName = 'FieldApplicant';

FieldApplicant.propTypes = {
  autocompleteOptions: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.bool,
  ]).isRequired,
  data: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  getAutocompleteLists: PropTypes.func.isRequired,
  pendingDefaultList: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  propsField: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  formField: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array,
  ]),
  formFieldInput: PropTypes.string,
  label: PropTypes.string,
  searchParamName: PropTypes.string,
  getAsyncData: PropTypes.func,
};

FieldApplicant.defaultProps = {
  formField: {},
  propsField: {},
  formFieldInput: '',
  label: 'Applicant',
  searchParamName: searchParams.name,
  getAsyncData: () => {},
};

function mapStateToProps(state, props) {
  const { formName, name } = props;
  const formField = getValueFormField(state, formName, name);
  const formFieldInput = getValueFormField(state, formName, `${name}Input`);

  return {
    autocompleteOptions: selectors.applicants.autocompleteOptions(state),
    data: selectors.applicants.getApplicantInitialList(state),
    pendingDefaultList: selectors.applicants.pendingApplicantsAutocomplete(state),
    formField: formField(),
    formFieldInput: formFieldInput(),
  };
}

export default compose(
  connect(mapStateToProps, null),
)(FieldApplicant);
