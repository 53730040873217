// Core
import { call, put } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../../config/api';

// helpers
import { setErrorMessage } from '../../../../../../ui/_helpers/setNotificationMessage';

export function* callDeleteReboundByIdWorker({ payload: id }) {
  const response = yield call(api.trademarks.deleteRebound, id);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    }
  }
}
