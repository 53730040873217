// Helpers
import actionCreator from '../../_helpers/actionCreator';
// Types
import types from './types';

const systemMessageActions = Object.freeze({
  setSystemMessageData(data) {
    return actionCreator(types.SET_SYSTEM_MESSAGE_DATA, data);
  },
  setSystemMessageById(data) {
    return actionCreator(types.SET_SYSTEM_MESSAGE_BY_ID, data);
  },
  setSystemMessageDataById(data) {
    return actionCreator(types.SET_SYSTEM_MESSAGE_DATA_BY_ID, data);
  },
  setCurrentPage(currentPage) {
    return actionCreator(types.SET_SYSTEM_MESSAGE_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return actionCreator(types.SET_SYSTEM_MESSAGE_FILTERS, filters);
  },
  setSorting(sorting) {
    return actionCreator(types.SET_SYSTEM_MESSAGE_SORTING, sorting);
  },
  setColumnWidths(data) {
    return actionCreator(types.SET_SYSTEM_MESSAGE_COLUMN_WIDTHS, data);
  },
  setColumnOrder(data) {
    return actionCreator(types.SET_SYSTEM_MESSAGE_COLUMN_ORDER, data);
  },
  setHiddenColumnNames(data) {
    return actionCreator(types.SET_SYSTEM_MESSAGE_HIDDEN_COLUMN_NAMES, data);
  },
  setPageSize(pageSize) {
    return actionCreator(types.SET_SYSTEM_MESSAGE_PAGE_SIZE, pageSize);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_SYSTEM_MESSAGE_INITIAL_STATE, initialState);
  },
  setReload(reload) {
    return actionCreator(types.SET_SYSTEM_MESSAGE_RELOAD, reload);
  },
  setDeleteSystemMessage(data) {
    return actionCreator(types.SET_DELETE_SYSTEM_MESSAGE, data);
  },
  setIsModalOpen(data) {
    return actionCreator(types.SET_SYSTEM_MESSAGE_IS_MODAL_OPEN, data);
  },
  setSystemMessageAllList(data) {
    return actionCreator(types.SET_SYSTEM_MESSAGE_ALL_LIST, data);
  },
  setDisableReload(data) {
    return actionCreator(types.SET_SYSTEM_MESSAGE_DISABLE_RELOAD, data);
  },
  setFiltersClear() {
    return actionCreator(types.SET_SYSTEM_MESSAGE_FILTERS_CLEAR);
  },
});

export default systemMessageActions;
