// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const permissionsGroupsActionAsync = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_PERMISSIONS_GROUPS_ASYNC, params);
  },
  getPermissionsGroupsByIdAsync(id) {
    return actionCreator(asyncTypes.GET_PERMISSIONS_GROUP_BY_ID_ASYNC, id);
  },
  postPermissionsGroupsAsync(data) {
    return actionCreator(asyncTypes.POST_PERMISSIONS_GROUP_ASYNC, data);
  },
  putPermissionsGroupsByIdAsync(data) {
    return actionCreator(asyncTypes.PUT_PERMISSIONS_GROUP_BY_ID_ASYNC, data);
  },
  deletePermissionsGroupsAsync() {
    return actionCreator(asyncTypes.DELETE_PERMISSIONS_GROUP_ASYNC);
  },
  getPermissionsAsync(params) {
    return actionCreator(asyncTypes.GET_PERMISSIONS_ASYNC, params);
  },
});

export default permissionsGroupsActionAsync;
