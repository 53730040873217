export default Object.freeze({
  SET_ORDERS_DATA: 'SET_ORDERS_DATA',
  SET_ORDER_BY_ID: 'SET_ORDER_BY_ID',
  SET_ORDERS_ALL_DATA: 'SET_ORDERS_ALL_DATA',
  SET_ORDERS_CURRENT_PAGE: 'SET_ORDERS_CURRENT_PAGE',
  SET_ORDERS_PAGE_SIZE: 'SET_ORDERS_PAGE_SIZE',
  SET_ORDERS_FILTERS: 'SET_ORDERS_FILTERS',
  SET_ORDERS_SORTING: 'SET_ORDERS_SORTING',
  SET_ORDERS_INITIAL_STATE: 'SET_ORDERS_INITIAL_STATE',
  SET_ORDERS_RELOAD: 'SET_ORDERS_RELOAD',
  SET_DELETE_ORDER: 'SET_DELETE_ORDER',
  SET_ORDERS_AUTOCOMPLETE_OPTIONS: 'SET_ORDERS_AUTOCOMPLETE_OPTIONS',
  SET_PENDING_ORDERS_AUTOCOMPLETE_OPTIONS: 'SET_PENDING_ORDERS_AUTOCOMPLETE_OPTIONS',
  SET_DOCUMENTS_ORDER: 'SET_DOCUMENTS_ORDER',
  SET_ORDER_NEXT_NUMBER: 'SET_ORDER_NEXT_NUMBER',
  MERGE_ORDER_ENTITY_LIST: 'MERGE_ORDER_ENTITY_LIST',
  SET_ORDER_ENTITY_LIST: 'SET_ORDER_ENTITY_LIST',
  SET_ORDERS_COLUMN_WIDTHS: 'SET_ORDERS_COLUMN_WIDTHS',
  SET_ORDERS_COLUMN_ORDER: 'SET_ORDERS_COLUMN_ORDER',
  SET_ORDERS_HIDDEN_COLUMN_NAMES: 'SET_ORDERS_HIDDEN_COLUMN_NAMES',
});
