// Core
import { all, call, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetChargesWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callGetChargeByIdWorker,
  callPostChargeWorker,
  callPutChargeByIdWorker,
  callDeleteChargeWorker,
  callGetChargesAutocompleteWorker,
  callSetSelectionsWorker,
  callPostPaymentDetailsWorker,
} from './workers';
import types from '../types';

function* watchGetCharges() {
  yield takeEvery(asyncTypes.GET_CHARGES_ASYNC, callGetChargesWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_CHARGES_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_CHARGES_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_CHARGES_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_CHARGES_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_CHARGES_SORTING, callSetToLocalDataWorker);
}

function* watchGetChargeById() {
  yield takeEvery(asyncTypes.GET_CHARGE_BY_ID_ASYNC, callGetChargeByIdWorker);
}

function* watchPostCharge() {
  yield takeEvery(asyncTypes.POST_CHARGE_ASYNC, callPostChargeWorker);
}

function* watchPutChargeById() {
  yield takeEvery(asyncTypes.PUT_CHARGE_BY_ID_ASYNC, callPutChargeByIdWorker);
}

function* watchDeleteCharge() {
  yield takeEvery(asyncTypes.DELETE_CHARGE_ASYNC, callDeleteChargeWorker);
}

function* watchGetChargesAutocompleteWorker() {
  yield takeEvery(asyncTypes.GET_CHARGES_AUTOCOMPLETE_ASYNC, callGetChargesAutocompleteWorker);
}

function* watchSetSelectionsWorker() {
  yield takeEvery(asyncTypes.SET_SECTIONS_CHARGES_ASYNC, callSetSelectionsWorker);
}

function* watchPostPaymentDetails() {
  yield takeEvery(asyncTypes.POST_CHARGES_PAYMENT_DETAILS, callPostPaymentDetailsWorker);
}

export function* watchersChargesTable() {
  yield all([
    call(watchGetCharges),
    call(watchSetCurrentPage),
    call(watchSetPageSize),
    call(watchSetFilters),
    call(watchSetSorting),
    call(watchGetInitialState),
    call(watchGetChargeById),
    call(watchPostCharge),
    call(watchPutChargeById),
    call(watchDeleteCharge),
    call(watchSetSelectionsWorker),
    call(watchGetChargesAutocompleteWorker),
    call(watchPostPaymentDetails),
  ]);
}
