// Core
import React, { useEffect, useMemo } from 'react';
import Helmet from 'react-helmet';
import { connect, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import moment from 'moment';

// Lodash
import {
  map, isEmpty, split, includes, divide,
  ceil, filter, forEach,
} from 'lodash';

// UI
import {
  Typography as MuiTypography, Grid,
  Divider as MuiDivider,
  Card as MuiCard, Box,
  CardActions as MuiCardActions,
  CardContent as MuiCardContent,
  Checkbox as MuiCheckbox, FormControlLabel,
  Button as MuiButton,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import MuiPagination from '@material-ui/lab/Pagination';
import { Warning as WarningIcon, FiberManualRecord } from '@material-ui/icons';

// actions
import actions from '../../engine/core/systemMessage/action';
import actionsAsync from '../../engine/core/systemMessage/saga/asyncAction';
import pendingActionsAsync from '../../engine/core/pendingActions/saga/asyncAction';
import pendingActions from '../../engine/core/pendingActions/action';

// config
import selectors from '../../engine/config/selectors';
import accessList from '../../engine/config/accessList';

// hooks
import { useSystemMessage } from './_hooks/useSystemMessage';
import { useAccessList } from '../../ui/_hooks/useAccessList';

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const Checkbox = styled(MuiCheckbox)(spacing);
const Button = styled(MuiButton)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
  display: flex;
  justify-content: space-between;
`;

const CardActions = styled(MuiCardActions)`
  display: flex;
  justify-content: space-between;
`;

const Pagination = styled(MuiPagination)`
  display: flex;
  justify-content: center;
`;

const WarningError = styled(WarningIcon)`
  color: ${(props) => props.theme.palette.error.main};
`;

const Warning = styled(WarningIcon)`
  color: ${(props) => props.theme.palette.warning.light};
`;

const SuccessWarning = styled(WarningIcon)`
  color: ${(props) => props.theme.palette.success.light};
`;

const PointIcon = styled(FiberManualRecord)`
  color: ${(props) => props.theme.palette.primary.main};
`;

const ActionTitle = styled(MuiTypography)`
  width: fit-content;
  border-bottom: 1px dashed;
  cursor: pointer;
  margin-top: 5px;
`;

const format = 'DD.MM.YYYY';

function Project({ item }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const accessPendingActionsGet = useAccessList(accessList.pendingActions_get);

  const handleRead = () => {
    dispatch(actionsAsync.patchSystemMessageByIdAsync({
      id: item.id,
      isRead: true,
    }));
  };

  const createdAt = useMemo(() => {
    if (item.createdAt === moment().format(format)) return t('Today');
    if (item.createdAt === moment().add(-1, 'days').format(format)) return t('Yesterday');
    return item.createdAt;
  }, [item, t]);

  const handleOpenPendingAction = (id) => {
    if (accessPendingActionsGet) {
      dispatch(pendingActions.setIsModalOpen(true));
      dispatch(pendingActionsAsync.getPendingActionByIdAsync(id));
    }
  };

  return (
    <Card mb={3}>
      <CardContent>
        <Box display="flex">
          {item?.priority === 1 && <WarningError />}
          {item?.priority === 2 && <Warning />}
          {item?.priority === 3 && <SuccessWarning />}

          <Typography ml={4} component="p">
            {includes(item.text, 'посиланням') ? `${split(item.text, 'посиланням')[0]} посиланням` : item.text}
            {!isEmpty(item.pendingActions) && (
              map(item.pendingActions, (action) => (
                <ActionTitle key={action.id} onClick={() => handleOpenPendingAction(action.id)}>
                  {`${action?.title} - ${action?.finishAt}`}
                </ActionTitle>
              ))
            )}
          </Typography>
        </Box>

        {!item.isRead && <PointIcon />}

      </CardContent>
      <CardActions>
        {createdAt}
        {!item.isRead && (
          <FormControlLabel
            control={(
              <Checkbox
                py={0}
                mr={2}
                onChange={() => handleRead()}
                color="primary"
              />
            )}
            labelPlacement="start"
            label={t('Read')}
          />
        )}
      </CardActions>
    </Card>
  );
}

Project.propTypes = {
  item: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

function SystemMessage(props) {
  const {
    systemMessageData, totalCount, disableReload, pageSize,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useSystemMessage();

  useEffect(() => function cleanup() {
    if (disableReload) {
      dispatch(actions.setDisableReload(false));
    }
    dispatch(actions.setFiltersClear());
  }, [dispatch, disableReload]);

  // Paging
  const onCurrentPage = (page) => dispatch(actions.setCurrentPage(page));

  const count = useMemo(() => ceil(divide(totalCount, pageSize)), [totalCount, pageSize]);

  const notReadMessages = useMemo(() => filter(systemMessageData.toJS().items, ['isRead', false]), [systemMessageData]);

  const handleReadAll = () => {
    forEach(notReadMessages, (item, index) => {
      dispatch(actionsAsync.putSystemMessageByIdAsync({
        id: item.id,
        isRead: true,
        disableReload: notReadMessages.length !== index + 1,
      }));
    });
  };

  return (
    <>
      <Helmet title={t('All messages')} />
      <Typography variant="h3" gutterBottom display="inline">
        {t('All messages')}
      </Typography>

      <Divider my={6} />

      {!isEmpty(notReadMessages) && (
        <Box mb={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleReadAll}
          >
            {t('MARK ALL AS READ')}
          </Button>
        </Box>
      )}

      {!isEmpty(systemMessageData.toJS().items) && (
        <Grid container spacing={0} justify="center">
          {map(systemMessageData.toJS().items, (item) => (
            <Grid item xs={12} lg={7} xl={7} key={item.id}>
              <Project
                item={item}
              />
            </Grid>
          ))}
          {count > 1 && (
            <Grid container spacing={0} justify="center">
              <Grid item xs={12}>
                <Pagination count={count} shape="rounded" onChange={(e, page) => onCurrentPage(page - 1)} />
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
}

SystemMessage.displayName = 'SystemMessage';

SystemMessage.propTypes = {
  systemMessageData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  totalCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  disableReload: PropTypes.bool.isRequired,
};

SystemMessage.defaultProps = {};

function mapStateToProps(state) {
  return {
    systemMessageData: selectors.systemMessage.systemMessageData(state),
    totalCount: selectors.systemMessage.totalCount(state),
    pageSize: selectors.systemMessage.pageSize(state),
    disableReload: selectors.systemMessage.disableReload(state),
  };
}

function mapDispatchToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(SystemMessage);
