// core
import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import {
  Field, Form, reduxForm,
} from 'redux-form/lib/immutable';

// lodash
import { isEmpty } from 'lodash';

// config
import selectors from '../../../../../engine/config/selectors';

// parts
import Modal from '../../../../../components/Modal/Modal';
import DialogActions from '../../../../../components/Modal/components/DialogActions';
import DatePickers from '../../../../../ui/Form/DatePickers';
import Select from '../../../../../ui/Form/Select';

// actions
import asyncAction from '../../../../../engine/core/currencies/currenciesList/saga/asyncAction';
import actions from '../../../../../engine/core/currencies/currenciesList/action';
import chargesActions from '../../../../../engine/core/charges/action';
import helpersAsyncActions from '../../../../../engine/core/helpers/saga/asyncAction';

// _helpers
import { getValueFormatDate, formatDate, getObjectReverseFormatDate } from '../../../../../engine/_helpers/formatterDate';

function RecalculationModal(props) {
  const {
    handleSubmit,
    isModalOpen,
    destroy,
    pendingPost,
    editCharge,
    toCurrency,
    currencyCalc,
    initialize,
    ids,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(chargesActions.setModalChargesEdit(false));
  };
  useEffect(() => function cleanup() {
    destroy();
  }, [destroy]);

  useEffect(() => {
    if (editCharge.chargeDate) {
      initialize({
        ...getObjectReverseFormatDate(editCharge.chargeDate, 'date'),
      });
    }
  }, [
    initialize, editCharge,
  ]);

  const handleSubmits = (formData) => {
    const json = formData.toJS();
    dispatch(asyncAction.postCurrencyCalcAsync({
      ...json,
      toCurrency,
      sum: editCharge.sumBill,
      fromCurrency: editCharge.currency?.id,
      date: getValueFormatDate(formatDate(json.date), 'date'),
    }));
  };
  useEffect(() => {
    if (!isEmpty(currencyCalc.toJS())) {
      dispatch(helpersAsyncActions.putMultipleAsync({
        body: {
          entityName: 'charges',
          entityIds: ids,
          fields: {
            currency: toCurrency,
            sumBill: currencyCalc.toJS()?.price,
            rate: currencyCalc.toJS()?.rate,
          },
        },
        callBack: () => dispatch(chargesActions.setReload(true)),
      }));
      dispatch(actions.setCurrencyCalc({}));
      dispatch(chargesActions.setModalChargesEdit(false));
    }
  }, [currencyCalc, dispatch, toCurrency, ids]);

  return (
    <Modal
      title={t('Provide recalculation details')}
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
      displayDialogActions={false}
    >
      <Form onSubmit={handleSubmit(handleSubmits)}>
        <Field
          id="date"
          labelId="date"
          name="date"
          fullWidth
          label={t('Course date')}
          component={DatePickers}
        />
        <Field
          required
          name="rateType"
          id="rateType"
          label={t('Type of rate')}
          labelId="rateType"
          my={2}
          items={[
            {
              value: 'fixed',
              name: t('Fixed rate'),
            },
            {
              value: 'real',
              name: t('The real course'),
            },
          ]}
          fullWidth
          component={Select}
        />

        <DialogActions
          pending={pendingPost}
          handleCloseModal={handleCloseModal}
        />
      </Form>
    </Modal>
  );
}

RecalculationModal.displayName = 'RecalculationModal';

RecalculationModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  pendingPost: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  destroy: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  editCharge: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  toCurrency: PropTypes.number.isRequired,
  currencyCalc: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  ids: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
};

RecalculationModal.defaultProps = {};

function mapStateToProps(state) {
  return {
    isModalOpen: selectors.charges.isModalOpenChargesEdit(state),
    pendingPost: selectors.currencies.pendingPostCurrencyCalc(state),
    currencyCalc: selectors.currencies.currencyCalc(state),
  };
}

export default compose(
  reduxForm({
    form: 'RecalculationModal',
  }),
  connect(mapStateToProps, null),
)(RecalculationModal);
