// core
import React, { memo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// lodash
import { isEqual } from 'lodash';

// ui
import {
  Button,
  DialogActions, CircularProgress,
} from '@material-ui/core';

function DialogActionsComponent(props) {
  const {
    handleSend, handleCloseModal, dialogActionsChildren,
    pending, buttonTextSend, buttonTextClose, visibleButtonSend,
    disableButtonSend,
  } = props;
  const { t } = useTranslation();

  return (
    <DialogActions>
      {dialogActionsChildren || (
        <>
          {visibleButtonSend && (
            <Button
              variant="contained"
              color="primary"
              type={handleSend ? 'button' : 'submit'}
              {...handleSend ? {
                onClick: handleSend,
              } : {}}
              disabled={pending || disableButtonSend}
            >
              {pending ? <CircularProgress size={25} color="inherit" /> : t(buttonTextSend)}
            </Button>
          )}
          <Button
            onClick={handleCloseModal}
            color="primary"
            variant="outlined"
          >
            {t(buttonTextClose)}
          </Button>
        </>
      )}
    </DialogActions>
  );
}

DialogActionsComponent.propTypes = {
  handleCloseModal: PropTypes.func,
  buttonTextSend: PropTypes.string,
  buttonTextClose: PropTypes.string,
  pending: PropTypes.bool,
  visibleButtonSend: PropTypes.bool,
  disableButtonSend: PropTypes.bool,
  handleSend: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func,
  ]),
  dialogActionsChildren: PropTypes.node,
};

DialogActionsComponent.defaultProps = {
  pending: false,
  disableButtonSend: false,
  buttonTextSend: 'CONFIRM',
  buttonTextClose: 'CANCEL',
  visibleButtonSend: true,
  handleCloseModal: () => {},
  handleSend: false,
  dialogActionsChildren: '',
};

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.buttonTextClose, nextProps.buttonTextClose)
  && isEqual(prevProps.buttonTextSend, nextProps.buttonTextSend)
  && isEqual(prevProps.dialogActionsChildren, nextProps.dialogActionsChildren)
  && isEqual(prevProps.handleSend, nextProps.handleSend)
  && isEqual(prevProps.pending, nextProps.pending)
  && isEqual(prevProps.disableButtonSend, nextProps.disableButtonSend)
  && isEqual(prevProps.visibleButtonSend, nextProps.visibleButtonSend);
}

export default memo(DialogActionsComponent, areEqual);
