// Core
export const formName = 'ProposalAdd';

export const formFields = Object.freeze({
  applicationDate: 'applicationDate',
  registrationDate: 'registrationDate',
  expiryDate: 'expiryDate',
  status: 'status',
  caseNumber: 'caseNumber',
  notes: 'notes',
  clients: 'clients',
  clientInput: 'clientInput',
  order: 'order',
  payments: 'payments',
  estimate: 'estimate',
  search: 'search',
  mktu: 'mktu',
  mktuTemplates: 'mktuTemplates',
  mktuTemplatesInput: 'mktuTemplatesInput',
  title: 'title',
  isColor: 'isColor',
  picturesNumber: 'picturesNumber',
  eApplication: 'eApplication',
  boost: 'boost',
  claimNumber: 'claimNumber',
  renewalDateFrom: 'renewalDateFrom',
  renewalDateTo: 'renewalDateTo',
  yearPaid: 'yearPaid',
  claimNumberIndependent: 'claimNumberIndependent',
  pagesNumber: 'pagesNumber',
  pagesNumberPriority: 'pagesNumberPriority',
  company: 'company',
  documents: 'documents',
  description: 'description',
  internalTitle: 'internalTitle',
  keywords: 'keywords',
  tags: 'tags',
  applicationNumber: 'applicationNumber',
  registrationNumber: 'registrationNumber',
  responsiblePerson: 'responsiblePerson',
  documentsType: 'documents.type',
  documentsFile: 'documents.file',
  personInput: 'personInput',
  person: 'person',
  usersId: 'usersId',
  clientReference: 'clientReference',
  jurisdictions: 'jurisdictions',
  noteExtended: 'noteExtended',
  initialPublication: 'initialPublication',
  publicationIdentifier: 'publication.identifier',
  publicationDate: 'publication.date',
  dataIdAttorney: 'data.idAttorney',
  dataAttorneyName: 'data.attorney.name',
  dataAttorneyInnCode: 'data.attorney.innCode',
  dataAttorneyRegNumber: 'data.attorney.regNumber',
  dataAttorneyPhone: 'data.attorney.phone',
  dataAttorneyEmail: 'data.attorney.email',
  dataApplicantsFis: 'fis',
  dataAttorneyPostalAddressCountry: 'data.attorney.postalAddress.country',
  dataAttorneyPostalAddressFullName: 'data.attorney.postalAddress.fullName',
  dataAttorneyPostalAddressCity: 'data.attorney.postalAddress.city',
  dataAttorneyPostalAddressPostcode: 'data.attorney.postalAddress.postalIndex',
  dataAttorneyPostalAddressAddress: 'data.attorney.postalAddress.address',
  dataOwners: 'data.owners',
  dataAuthors: 'data.authors',
  dataApplicants: 'data.applicants',
  dataApplicantsName: 'name',
  dataApplicantsOriginalName: 'originalName',
  dataApplicantsOriginalCity: 'originalCity',
  dataApplicantsOriginalAddress: 'originalAddress',
  dataApplicantsOriginalPostalIndex: 'originalPostalIndex',
  dataApplicantsPostalIndex: 'postalIndex',
  dataApplicantsAddressCountry: 'country',
  dataApplicantsAddressCity: 'city',
  dataApplicantsAddressAddress: 'address',
  dataApplicantsAddressPostalIndex: 'postalIndex',
  dataApplicantsInnCode: 'innCode',
  dataLogo: 'data.logo',
  dataPostalAddress: 'data.postalAddress',
  dataPostalAddressCountry: 'data.postalAddress.country',
  dataPostalAddressPhone: 'data.postalAddress.phone',
  dataPostalAddressEmail: 'data.postalAddress.email',
  dataPostalAddressFullName: 'data.postalAddress.fullName',
  dataPostalAddressAddress: 'data.postalAddress.address',
  dataPostalAddressPostcode: 'data.postalAddress.postalIndex',
  dataPostalAddressCity: 'data.postalAddress.city',
  dataPostalAddressFis: 'data.postalAddress.fis',
  dataColors: 'data.colors',
  dataPriority: 'data.priority',
  dataPriorityType: 'data.priority.type',
  dataPriorityEarlyAppNumber: 'data.priority.earlyAppNumber',
  dataPriorityEarlyAppDate: 'data.priority.earlyAppDate',
  dataPriorityCountry: 'data.priority.country',
  dataPriorityExhibitionDate: 'data.priority.exhibitionDate',

  dataRelatedAppsRegs: 'data.relatedAppsRegs',

  dataRelatedAppsRegsParentNumDate: 'data.relatedAppsRegs.parentNumDate',
  dataRelatedAppsRegsParentNumDateIsCheck: 'data.relatedAppsRegs.parentNumDateIsCheck',
  dataRelatedAppsRegsVariantNumDate: 'data.relatedAppsRegs.variantNumDate',
  dataRelatedAppsRegsVariantNumDateIsCheck: 'data.relatedAppsRegs.variantNumDateIsCheck',

  dataStandardCharacters: 'data.standardCharacters',
  classificationClasses: 'classification.classes',
  classificationVersion: 'classification.version',
});
