// Helpers
import actionCreator from '../../_helpers/actionCreator';
// Types
import types from './types';

const actions = Object.freeze({
  setDocumentsType(type) {
    return actionCreator(types.SET_DOCUMENTS_TYPE, type);
  },
  setDocumentsTypePending(pending) {
    return actionCreator(types.SET_PENDING_DOCUMENTS_TYPE, pending);
  },
  setPendingSend(pending) {
    return actionCreator(types.SET_PENDING_SEND, pending);
  },
  setObjectStatuses(data) {
    return actionCreator(types.SET_OBJECT_STATUSES, data);
  },
  setMultiple(data) {
    return actionCreator(types.SET_MULTIPLE, data);
  },
  setCheckboxClear() {
    return actionCreator(types.SET_CHECKBOX_CLEAR);
  },
  setCheckbox(data) {
    return actionCreator(types.SET_CHECKBOX, data);
  },
  setAutocompleteGroupOptions(data) {
    return actionCreator(types.SET_AUTOCOMPLETE_GROUP, data);
  },
  mergeAutocompleteGroupOFilter(data) {
    return actionCreator(types.MERGE_AUTOCOMPLETE_FILTER, data);
  },
  setAutocompleteGroupFilter(data) {
    return actionCreator(types.SET_AUTOCOMPLETE_FILTER, data);
  },
  setSupportTicketsData(data) {
    return actionCreator(types.SET_SUPPORT_TICKETS_DATA, data);
  },
  setPendingPostSupportTickets(pending) {
    return actionCreator(types.SET_PENDING_POST_SUPPORT_TICKETS, pending);
  },
  setModal(modelData) {
    return actionCreator(types.SET_MODAL, modelData);
  },
  setOpenLongMenu(...data) {
    return actionCreator(types.SET_OPEN_LONG_MENU, data);
  },
  setCurrentLongMenu(...data) {
    return actionCreator(types.SET_CURRENT_LONG_MENU, data);
  },
  setCleanUpLongMenu() {
    return actionCreator(types.SET_CLEANUP_LONG_MENU);
  },
  setSignList(list) {
    return actionCreator(types.SET_SIGN_LIST, list);
  },
  setSignPending(pending) {
    return actionCreator(types.SET_SIGN_PENDING, pending);
  },
  setPermissionSign(permission) {
    return actionCreator(types.SET_PERMISSION_SIGN, permission);
  },

  setSignIsModalOpen(isOpen) {
    return actionCreator(types.SET_SIGN_MODAL_IS_OPEN, isOpen);
  },
  setVisibleSidebar(visible) {
    return actionCreator(types.SET_VISIBLE_SIDEBAR, visible);
  },
  setSignatureInfo(data) {
    return actionCreator(types.SET_SIGNATURE_INFO, data);
  },
  setSignatureInfoStateClear(data) {
    return actionCreator(types.SET_SIGNATURE_INFO_STATE_CLEAR, data);
  },
  setFilesWithBase64(data) {
    return actionCreator(types.SET_FILES_WITH_BASE_64, data);
  },
  setFilesWithBase64Clear(data) {
    return actionCreator(types.SET_FILES_WITH_BASE_64_CLEAR, data);
  },
  setImagesWithBase64(data) {
    return actionCreator(types.SET_IMAGES_WITH_BASE_64, data);
  },
  setImagesWithBase64Clear(data) {
    return actionCreator(types.SET_IMAGES_WITH_BASE_64_CLEAR, data);
  },
  setPredefinedInputs(data) {
    return actionCreator(types.SET_PREDEFINED_INPUTS, data);
  },
  setEntityApplicantModal(data) {
    return actionCreator(types.SET_ENTITY_APPLICANT_MODAL, data);
  },
  setNotPendingGetById(data) {
    return actionCreator(types.SET_NOT_PENDING_GET_BY_ID, data);
  },
  setLastObject(data) {
    return actionCreator(types.SET_LAST_OBJECT, data);
  },
  setAllNotificationList(data) {
    return actionCreator(types.SET_ALL_NOTIFICATION_LIST, data);
  },
  setIsOpenNotification(data) {
    return actionCreator(types.SET_IS_OPEN_NOTIFICATION, data);
  },
  setInitialLongMenu(data) {
    return actionCreator(types.SET_INITIAL_LONG_MENU, data);
  },
  setPendingPostRenewal(data) {
    return actionCreator(types.SET_PENDING_POST_RENEWAL, data);
  },
  setSelectedCompany(data) {
    return actionCreator(types.SET_SELECTED_COMPANY, data);
  },
  setPendingDownloadFile(pending) {
    return actionCreator(types.SET_PENDING_DOWNLOAD_FILE, pending);
  },
  setPendingDownloadExport(pending) {
    return actionCreator(types.SET_PENDING_DOWNLOAD_EXPORT, pending);
  },
  setCheckboxClose(data) {
    return actionCreator(types.SET_CHECKBOX_CLOSE, data);
  },
  setStartSend(data) {
    return actionCreator(types.SET_ON_START_SEND, data);
  },
  setPreviewFileById(data) {
    return actionCreator(types.SET_PREVIEW_FILE_BY_ID, data);
  },
  setSelectedEntities(data) {
    return actionCreator(types.SET_SELECTED_ENTITIES, data);
  },
  setTableName(name) {
    return actionCreator(types.SET_HELPERS_TABLE_NAME, name);
  },
  setSuccessDetBase64(data) {
    return actionCreator(types.SET_SUCCESS_GET_BASE_64, data);
  },
});

export default actions;
