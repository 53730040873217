// Helpers
import actionCreator from '../../_helpers/actionCreator';
// Types
import types from './types';

const actions = Object.freeze({
  setPaymentAllocationsData(data) {
    return actionCreator(types.SET_PAYMENT_ALLOCATIONS_DATA, data);
  },
  setCurrentPage(currentPage) {
    return actionCreator(types.SET_PAYMENT_ALLOCATIONS_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return actionCreator(types.SET_PAYMENT_ALLOCATIONS_FILTERS, filters);
  },
  setSorting(sorting) {
    return actionCreator(types.SET_PAYMENT_ALLOCATIONS_SORTING, sorting);
  },
  setPageSize(pageSize) {
    return actionCreator(types.SET_PAYMENT_ALLOCATIONS_PAGE_SIZE, pageSize);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_PAYMENT_ALLOCATIONS_INITIAL_STATE, initialState);
  },
  setReload(reload) {
    return actionCreator(types.SET_PAYMENT_ALLOCATIONS_RELOAD, reload);
  },
  setDeletePaymentAllocations(data) {
    return actionCreator(types.SET_DELETE_PAYMENT_ALLOCATIONS, data);
  },
  setSelections(data) {
    return actionCreator(types.SET_PAYMENT_ALLOCATIONS_SELECTIONS, data);
  },
});

export default actions;
