// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import types from './types';

const actions = Object.freeze({
  setSearchesData(searchesData) {
    return actionCreator(types.SET_SEARCHES_DATA, searchesData);
  },
  setCurrentPage(currentPage) {
    return actionCreator(types.SET_SEARCHES_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return actionCreator(types.SET_SEARCHES_FILTERS, filters);
  },
  setSorting(sorting) {
    return actionCreator(types.SET_SEARCHES_SORTING, sorting);
  },
  setColumnWidths(data) {
    return actionCreator(types.SET_SEARCHES_COLUMN_WIDTHS, data);
  },
  setColumnOrder(data) {
    return actionCreator(types.SET_SEARCHES_COLUMN_ORDER, data);
  },
  setHiddenColumnNames(data) {
    return actionCreator(types.SET_SEARCHES_HIDDEN_COLUMN_NAMES, data);
  },
  setDeleteSearch(data) {
    return actionCreator(types.SET_DELETE_SEARCH, data);
  },
  setPageSize(pageSize) {
    return actionCreator(types.SET_SEARCHES_PAGE_SIZE, pageSize);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_SEARCHES_INITIAL_STATE, initialState);
  },
  setReload(reload) {
    return actionCreator(types.SET_SEARCHES_RELOAD, reload);
  },
  setSearchById(searchData) {
    return actionCreator(types.SET_SEARCH_BY_ID, searchData);
  },
  setTabs(nameTab, data) {
    return actionCreator(types.SET_TABS, {
      nameTab, data,
    });
  },
  setSearchReportData(reportData) {
    return actionCreator(types.SET_SEARCH_REPORT_DATA, reportData);
  },
  setQuickSearchData(data) {
    return actionCreator(types.SET_QUICK, data);
  },
  setSelectedClasses(data) {
    return actionCreator(types.SET_SEARCH_SELECTED_CLASSES, data);
  },
  mergeSearchEntityList(data) {
    return actionCreator(types.MERGE_SEARCH_ENTITY_LIST, data);
  },
  setSearchEntityList(data) {
    return actionCreator(types.SET_SEARCH_ENTITY_LIST, data);
  },
});

export default actions;
