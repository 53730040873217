// core
import React, { memo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import styled from 'styled-components';

// lodash
import { isEqual } from 'lodash';

// ui
import {
  Button,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import { DropzoneAreaBase as DropZoneAreaMui } from 'material-ui-dropzone';

// config
import selectors from '../../engine/config/selectors';

// parts
import Modal from '../Modal/Modal';
import DialogActions from '../Modal/components/DialogActions';
import ProgressBar from '../ProgressBar';

// actions
import helpersActions from '../../engine/core/helpers/action';

// styles
const DropZoneArea = styled(DropZoneAreaMui)(spacing);

function DropZoneAreaModal(props) {
  const {
    dropzoneText, dropZoneKey, isModalOpenDropZone,
    handleChange, filesLimit, progress,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(helpersActions.setModal({ isModalOpenDropZone: false }));
  };

  return (
    <Modal
      title={t('Download')}
      isModalOpen={isModalOpenDropZone}
      handleCloseModal={handleCloseModal}
      displayDialogActions={false}
    >
      {progress ? (
        <ProgressBar value={progress} />
      ) : (
        <DropZoneArea
          dropzoneText={t(dropzoneText)}
          key={dropZoneKey}
          showPreviewsInDropzone={false}
          showAlerts={false}
          clearOnUnmount
          filesLimit={filesLimit}
          maxFileSize={21000000}
          mt={4}
          onAdd={handleChange}
        />
      )}

      <DialogActions
        dialogActionsChildren={(
          <>
            <Button
              variant="outlined"
              onClick={handleCloseModal}
              color="primary"
            >
              {t('CANCEL')}
            </Button>
          </>
        )}
      />
    </Modal>
  );
}

DropZoneAreaModal.propTypes = {
  isModalOpenDropZone: PropTypes.bool.isRequired,
  dropZoneKey: PropTypes.number.isRequired,
  filesLimit: PropTypes.number,
  dropzoneText: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  progress: PropTypes.number,
};

DropZoneAreaModal.defaultProps = {
  dropzoneText: 'Drag and drop a file here or click',
  filesLimit: 20,
  progress: 0,
};

function mapStateToProps(state) {
  return {
    isModalOpenDropZone: selectors.helpers.isModalOpenDropZone(state),
  };
}

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.dropZoneKey, nextProps.dropZoneKey)
  && isEqual(prevProps.dropzoneText, nextProps.dropzoneText)
  && isEqual(prevProps.filesLimit, nextProps.filesLimit)
  && isEqual(prevProps.progress, nextProps.progress)
  && isEqual(prevProps.isModalOpenDropZone, nextProps.isModalOpenDropZone);
}

export default compose(
  connect(mapStateToProps, null),
)(memo(DropZoneAreaModal, areEqual));
