// Core
import React, { useEffect, useState, Fragment } from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import Helmet from 'react-helmet';

// UI
import {
  Typography, Divider as MuiDivider,
  List, Card as MuiCard, Button, Collapse,
  ListItem as MuiListItem, IconButton,
  ListItemText as MuiListItemText, Box, ButtonGroup,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';
import * as PropTypes from 'prop-types';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {
  Delete, Add as AddIcon, Edit as EditIcon,
} from '@material-ui/icons';

// lodash
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';

// actions
import worksActionsAsync from '../../../engine/core/prices/works/saga/asyncAction';
import worksActions from '../../../engine/core/prices/works/action';
import helpersActions from '../../../engine/core/helpers/action';

// parts
import ConfirmModal from '../../../components/ConfirmModal';
import Loader from '../../../components/Loader';
import AddWorksModal from './components/AddWorksModal';

// config
import selectors from '../../../engine/config/selectors';
import accessList from '../../../engine/config/accessList';
import { useAccessList } from '../../../ui/_hooks/useAccessList';

// styles
const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const ListItem = styled(MuiListItem)`
  ${spacing}
  &:hover {
    background-color: ${(props) => props.theme.palette.grey[100]};
    cursor: pointer;
  }
`;
const ListItemText = styled(MuiListItemText)(spacing);
function Works(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    worksData, pendingGetWorks, deleteNested: { item, costId, itemCosts },
  } = props;

  const [openWorks, setOpenWorks] = useState({});
  const [isNested, setIsNested] = useState(false);
  const [isEditWork, setIsEditWork] = useState(false);
  const accessPost = useAccessList(accessList.works_post);
  const accessPut = useAccessList(accessList.works_put);
  const accessGet = useAccessList(accessList.works_get);
  const accessDelete = useAccessList(accessList.works_delete);

  // function for opening nested list
  const handleClick = (param) => {
    setOpenWorks((prevState) => ({ ...prevState, [param]: !prevState[param] }));
  };
  // creating data for updating costs and making request
  let costs = {};
  const handleDeleteNested = () => {
    function CostsId() {
      const arr = map(filter(itemCosts, (elem) => elem.id !== costId), 'id');
      costs = {
        name: item.name,
        costs: arr,
      };
      return costs;
    }
    CostsId(itemCosts);
    dispatch(worksActionsAsync.putWorkAsync({ id: item.id, costs }));
  };
  // select modal for list and nested list
  const toggleModal = () => (
    isNested ? handleDeleteNested()
      : dispatch(worksActionsAsync.deleteWorkAsync())
  );
  // open modal add works
  const openAddWorksModal = () => {
    dispatch(worksActions.setWorksData({
      isModalOpenAddWork: true,
    }));
  };
  useEffect(() => {
    dispatch(worksActionsAsync.getWorksAsync());
  }, [dispatch]);

  const EditComponent = (id) => (
    <IconButton
      color="inherit"
      onClick={(e) => {
        e.stopPropagation();
        openAddWorksModal();
        dispatch(worksActionsAsync.getWorkByIdAsync(id));
        setIsEditWork(true);
      }}
    >
      <EditIcon fontSize="small" />
    </IconButton>
  );
  return (
    <>
      <Helmet title={t('Works')} />
      <Typography variant="h3" gutterBottom display="inline">
        {t('Works')}
      </Typography>
      <Divider my={6} />

      {accessPost && (
        <Box mb={2}>
          <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                openAddWorksModal();
              }}
            >
              {t('ADD')}
              <AddIcon />
            </Button>
          </ButtonGroup>
        </Box>
      )}

      <Card mb={6}>
        <List
          component="nav"
        >
          {isEmpty(worksData.toJS().items) && (
            <ListItem>
              <ListItemText primary={t('No data')} pl={0} />
            </ListItem>
          )}
          {map(worksData.toJS().items, (work) => (
            <Fragment key={work.id}>
              <ListItem
                onClick={() => handleClick(work.name)}
              >
                {accessGet && <EditComponent id={work.id} />}
                {accessDelete && (
                  <IconButton
                    color="inherit"
                    aria-label="delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsNested(false);
                      dispatch(helpersActions.setModal({
                        isModalOpen: true,
                      }));
                      dispatch(worksActions.setDeleteWork({
                        id: work.id,
                      }));
                    }}
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                )}

                <ListItemText
                  primary={t(`${work.name}`)}
                  pl={0}
                />
                {openWorks[work.name] ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={openWorks[work.name]}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {map(work.costs, (cost) => (
                    <ListItem key={cost.id} pl={12}>
                      {accessPut && (
                        <IconButton
                          color="inherit"
                          aria-label="delete"
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsNested(true);
                            dispatch(worksActions.setPutWork({
                              item: work,
                              costId: cost.id,
                              itemCosts: work.costs,
                            }));
                            dispatch(helpersActions.setModal({
                              isModalOpen: true,
                            }));
                          }}
                        >
                          <Delete fontSize="small" />
                        </IconButton>
                      )}
                      <ListItemText>
                        <ListItemText primary={t(`${cost.name}`)} />
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </Fragment>
          ))}
        </List>
      </Card>
      <ConfirmModal
        buttonSendText="DELETE"
        handleSend={() => toggleModal()}
      />
      {pendingGetWorks && <Loader position />}
      <AddWorksModal
        isEditWork={isEditWork}
        handleSend={() => setIsEditWork(false)}
      />
    </>
  );
}

Works.displayName = 'Works';

Works.propTypes = {
  worksData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  deleteNested: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingGetWorks: PropTypes.bool.isRequired,
};

Works.defaultProps = {};

function mapStateToProps(state) {
  return {
    worksData: selectors.pricesWorks.getWorksData(state),
    deleteNested: selectors.pricesWorks.workIdDeleteNested(state).toJS(),
    pendingGetWorks: selectors.pricesWorks.pendingGetWorks(state),
  };
}

function mapDispatchToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Works);
