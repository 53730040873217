const asyncTypes = Object.freeze({
  SIGN_IN_ASYNC: 'SIGN_IN_ASYNC',
  GET_USER_INFO_ASYNC: 'GET_USER_INFO_ASYNC',
  AUTH_CLEAN_UP: 'AUTH_CLEAN_UP',
  LOG_OUT_ASYNC: 'LOG_OUT_ASYNC',
  RESET_PASSWORD_ASYNC: 'RESET_PASSWORD_ASYNC',
  SET_USER_LANGUAGE_ASYNC: 'SET_USER_LANGUAGE_ASYNC',
});

export default asyncTypes;
