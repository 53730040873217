// Core
import { apply, select, put } from 'redux-saga/effects';
import isNil from 'lodash/isNil';

// Engine
import types from '../../types';
import actions from '../../action';
import selectors from '../../../../../../../config/selectors';
import localData from '../../../../../../../config/localData';

export function* callSetToLocalDataWorker({ type, payload }) {
  const pathname = yield select(selectors.router.pathname);
  const data = {
    currentPage: yield select(selectors.proposalDocumentsOut.currentPage),
    filters: (yield select(selectors.proposalDocumentsOut.filters)),
    pageSize: yield select(selectors.proposalDocumentsOut.pageSize),
    totalCount: yield select(selectors.proposalDocumentsOut.totalCount),
    sorting: (yield select(selectors.proposalDocumentsOut.sorting)),
  };

  let currentData;
  yield put(actions.setReload(true));
  switch (type) {
    case types.SET_PROPOSAL_DOCUMENTS_OUT_CURRENT_PAGE: {
      currentData = { currentPage: payload };
      break;
    }
    case types.SET_PROPOSAL_DOCUMENTS_OUT_PAGE_SIZE: {
      currentData = { pageSize: payload };
      break;
    }
    case types.SET_PROPOSAL_DOCUMENTS_OUT_FILTERS: {
      const { currentPage } = data;
      const filters = payload.filter(({ value }) => !isNil(value));
      if (currentPage) {
        yield put(actions.setCurrentPage(0));
      }
      currentData = { filters };
      break;
    }
    case types.SET_PROPOSAL_DOCUMENTS_OUT_SORTING: {
      currentData = { sorting: payload };
      break;
    }
    default: {
      break;
    }
  }

  data.currentPage = yield select(selectors.proposalDocumentsOut.currentPage);
  yield apply(localData, localData.setItem, [`out${pathname}`, { ...data, ...currentData }]);
}
