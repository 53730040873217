// core
import React, {
  memo, useCallback, useMemo,
} from 'react';
import { spacing } from '@material-ui/system';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import { Field } from 'redux-form/immutable';

// lodash
import {
  isEqual, isEmpty,
} from 'lodash';

// UI
import {
  CardContent, Grid as MuiGrid, Tooltip,
  Typography as MuiTypography, Card as MuiCard,
  IconButton as MuiIconButton,
  CircularProgress, InputAdornment,
} from '@material-ui/core';

// icon
import {
  Search as SearchIcon, Link as LinkIcon,
} from '@material-ui/icons';

// actions
import tradeMarksAsyncActions from '../../../../../engine/core/tradeMarks/proposal/saga/asyncAction';
import industrialAsyncActions from '../../../../../engine/core/industrialDesigns/saga/asyncAction';
import inventionsAsyncActions from '../../../../../engine/core/inventions/saga/asyncAction';
import utilityModelsAsyncActions from '../../../../../engine/core/utilityModels/saga/asyncAction';

// config
import selectors from '../../../../../engine/config/selectors';

// parts
import RenderTextField from '../../../../../ui/Form/RenderTextField';
import Select from '../../../../../ui/Form/Select';
import ButtonSubmit from '../../../../../ui/Button/ButtonSubmit';
import DatePickers from '../../../../../ui/Form/DatePickers';
import FieldJurisdiction from '../../../../../ui/Form/FieldsAutocomplete/FieldJurisdiction';
import ChipTextField from '../../../../../ui/Form/ChipTextField';

// hooks
import { useEventsAutocompleteAsync } from '../../../../../ui/_hooks/useEventsAutocompleteAsync';

// helpers
import { validators } from '../../../../../ui/_helpers/validation';

// styles
const Card = styled(MuiCard)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);
const IconButton = styled(MuiIconButton)(spacing);

function CardBasicInformation(props) {
  const {
    formName, pending, formFields, objectStatuses,
    pendingRegistryByNumber, entityName, setFieldValueByNumber,
    fieldClient, fieldCaseNumber, formValues, fieldCompany,
    applicationNumberValues, registrationNumberValue,
    fieldValueByNumber, labelFieldTitle, disabled,
  } = props;
  const {
    getAutocompleteLists,
  } = useEventsAutocompleteAsync();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const asyncActions = useMemo(() => {
    switch (entityName) {
      case 'trademarks': {
        return tradeMarksAsyncActions;
      }
      case 'industrial': {
        return industrialAsyncActions;
      }
      case 'inventions': {
        return inventionsAsyncActions;
      }
      default:
        return utilityModelsAsyncActions;
    }
  }, [entityName]);

  const getRegistryByNumber = useCallback((number, name) => {
    if (!isEmpty(number)) {
      setFieldValueByNumber(name);
      dispatch(asyncActions.getRegistryByNumberAsync({
        [name]: number,
        saveFields: {
          caseNumber: fieldCaseNumber,
          company: fieldCompany,
          ...!isEmpty(fieldClient) ? {
            clients: fieldClient,
          } : {},
          eApplication: formValues.toJS().eApplication,
          boost: formValues.toJS().boost,
          ...entityName === 'industrial' ? {
            picturesNumber: formValues.toJS().picturesNumber,
            isColor: formValues.toJS().isColor,
          } : {},
        },
      }));
    }
  }, [
    dispatch, setFieldValueByNumber, fieldCaseNumber, asyncActions,
    fieldCompany, fieldClient, formValues, entityName,
  ]);

  const renderBtnSearchRegistryByNumber = useCallback((value, name) => (
    <IconButton
      disabled={!value}
      onClick={() => {
        getRegistryByNumber(value, name);
      }}
    >
      {fieldValueByNumber === name && pendingRegistryByNumber ? <CircularProgress size={20} color="inherit" /> : <SearchIcon />}
    </IconButton>
  ), [fieldValueByNumber, getRegistryByNumber, pendingRegistryByNumber]);

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h2" gutterBottom>
          {t('Basic information')}
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} md={3}>
            <Field
              name={formFields.status}
              id={formFields.status}
              label={t('Status')}
              labelId={formFields.status}
              my={1}
              items={objectStatuses}
              fullWidth
              component={Select}
              disabled={disabled}
            />
            <Field
              required
              validate={validators.required}
              name={formFields.internalTitle}
              id={formFields.internalTitle}
              label={t('Internal name')}
              margin="normal"
              variant="standard"
              my={1}
              fullWidth
              type="text"
              component={RenderTextField}
              disabled={disabled}
            />

            {entityName === 'trademarks' && (
              <FieldJurisdiction
                name={formFields.jurisdictions}
                label="Jurisdiction"
                getAutocompleteLists={getAutocompleteLists}
                formName={formName}
                propsField={{ disabled }}
              />
            )}
          </Grid>

          <Grid item xs={12} md={3}>
            <Field
              name={formFields.caseNumber}
              id={formFields.caseNumber}
              label={t('Case number')}
              margin="normal"
              variant="standard"
              my={1}
              fullWidth
              type="text"
              component={RenderTextField}
              disabled={disabled}
            />
            <Field
              name={formFields.applicationDate}
              id={formFields.applicationDate}
              labelId={formFields.applicationDate}
              label={t('Date of submission')}
              margin="normal"
              variant="standard"
              my={1}
              fullWidth
              type="text"
              formatDate="dd.MM.yyyy"
              component={DatePickers}
              disabled={disabled}
            />

            {entityName === 'trademarks' && (
              <Field
                name={formFields.clientReference}
                id={formFields.clientReference}
                label={t('Client\'s case number')}
                margin="normal"
                variant="standard"
                my={1}
                fullWidth
                type="text"
                component={RenderTextField}
                disabled={disabled}
              />
            )}
          </Grid>

          <Grid item xs={12} md={3}>
            <Field
              name={formFields.applicationNumber}
              id={formFields.applicationNumber}
              label={t('Application number')}
              margin="normal"
              variant="standard"
              my={1}
              fullWidth
              type="text"
              component={RenderTextField}
              disabled={disabled}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {applicationNumberValues ? (
                      <Tooltip
                        title={t('Find in the registry')}
                        arrow
                      >
                        {renderBtnSearchRegistryByNumber(applicationNumberValues, 'applicationNumber')}
                      </Tooltip>
                    ) : renderBtnSearchRegistryByNumber(applicationNumberValues, 'applicationNumber')}
                  </InputAdornment>
                ),
              }}
            />

            <Field
              name={formFields.registrationDate}
              id={formFields.registrationDate}
              labelId={formFields.registrationDate}
              label={t('Registration date')}
              margin="normal"
              variant="standard"
              my={1}
              fullWidth
              type="text"
              formatDate="dd.MM.yyyy"
              component={DatePickers}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Field
              name={formFields.registrationNumber}
              id={formFields.registrationNumber}
              label={t('Registration number')}
              margin="normal"
              variant="standard"
              my={1}
              fullWidth
              type="text"
              component={RenderTextField}
              disabled={disabled}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {registrationNumberValue ? (
                      <Tooltip
                        title={t('Find in the registry')}
                        arrow
                      >
                        {renderBtnSearchRegistryByNumber(registrationNumberValue, 'registrationNumber')}
                      </Tooltip>
                    ) : renderBtnSearchRegistryByNumber(registrationNumberValue, 'registrationNumber')}
                  </InputAdornment>
                ),
              }}
            />

            {entityName === 'trademarks' && (
              <Field
                name={formFields.expiryDate}
                id={formFields.expiryDate}
                labelId={formFields.expiryDate}
                label={t('End date')}
                margin="normal"
                variant="standard"
                my={1}
                fullWidth
                type="text"
                formatDate="dd.MM.yyyy"
                component={DatePickers}
                disabled={disabled}
              />
            )}

            {entityName === 'industrial' && (
              <Field
                name={formFields.isColor}
                id={formFields.isColor}
                label={t('Image in color')}
                labelId={formFields.isColor}
                my={1}
                items={[
                  { value: 'true', name: t('Yes') },
                  { value: 'false', name: t('No') },
                ]}
                fullWidth
                component={Select}
                disabled={disabled}
              />
            )}

            {entityName === 'inventions' && (
              <Field
                name={formFields.ipc}
                id={formFields.ipc}
                label={t('ICP')}
                margin="normal"
                fullWidth
                my={2}
                component={ChipTextField}
                disabled={disabled}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title={t('Choose')}
                        arrow
                      >
                        <IconButton
                          component="a"
                          target="_blank"
                          href="https://base.uipv.org/mpk2009/index.html"
                        >
                          <LinkIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            )}

            {entityName === 'utility' && (
              <Field
                name={formFields.dataIpc}
                id={formFields.dataIpc}
                label={t('ICP')}
                margin="normal"
                variant="standard"
                my={1}
                fullWidth
                type="text"
                component={RenderTextField}
                disabled={disabled}
              />
            )}
          </Grid>

          {entityName !== 'trademarks' && (
            <Grid item xs={12}>
              <Field
                name={formFields.title}
                id={formFields.title}
                label={t(labelFieldTitle)}
                margin="normal"
                variant="outlined"
                fullWidth
                multiline
                component={RenderTextField}
                disabled={disabled}
                rows={2}
              />
            </Grid>
          )}

          {entityName === 'industrial' && (
            <>
              <Grid item xs={12} md={3}>
                <Field
                  name={formFields.classificationClasses}
                  id={formFields.classification}
                  label={t('Class LOC')}
                  margin="normal"
                  fullWidth
                  my={2}
                  component={ChipTextField}
                  disabled={disabled}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          title={t('Choose')}
                          arrow
                        >
                          <IconButton
                            component="a"
                            target="_blank"
                            href="https://base.uipv.org/locarno/"
                          >
                            <LinkIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
                <FieldJurisdiction
                  name={formFields.jurisdictions}
                  label="Jurisdiction"
                  getAutocompleteLists={getAutocompleteLists}
                  formName={formName}
                  propsField={{ disabled }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Field
                  name={formFields.classificationVersion}
                  id={formFields.classificationVersion}
                  label={t('LOC version')}
                  margin="normal"
                  fullWidth
                  component={RenderTextField}
                  disabled={disabled}
                />
                <Field
                  name={formFields.clientReference}
                  id={formFields.clientReference}
                  label={t('Client\'s case number')}
                  margin="normal"
                  variant="standard"
                  my={1}
                  fullWidth
                  type="text"
                  component={RenderTextField}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Field
                  name={formFields.picturesNumber}
                  id={formFields.picturesNumber}
                  label={t('Number of images')}
                  disabled
                  margin="normal"
                  fullWidth
                  component={RenderTextField}
                />
              </Grid>
            </>
          )}
          {(entityName === 'inventions' || entityName === 'utility') && (
            <>
              <Grid item xs={12} md={3} pt={0}>
                <FieldJurisdiction
                  name={formFields.jurisdictions}
                  label="Jurisdiction"
                  getAutocompleteLists={getAutocompleteLists}
                  formName={formName}
                  disabled={disabled}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <Field
                  name={formFields.clientReference}
                  id={formFields.clientReference}
                  label={t('Client\'s case number')}
                  margin="normal"
                  variant="standard"
                  my={1}
                  fullWidth
                  type="text"
                  component={RenderTextField}
                  disabled={disabled}
                />
              </Grid>
            </>
          )}
          {!disabled && (
            <Grid item xs={12}>
              <ButtonSubmit pending={pending} />
            </Grid>
          )}

        </Grid>
      </CardContent>
    </Card>
  );
}
CardBasicInformation.propTypes = {
  formName: PropTypes.string.isRequired,
  pending: PropTypes.bool.isRequired,
  formFields: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingRegistryByNumber: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  entityName: PropTypes.string.isRequired,
  fieldValueByNumber: PropTypes.string.isRequired,
  setFieldValueByNumber: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  labelFieldTitle: PropTypes.string,

  objectStatuses: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  fieldClient: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  fieldCaseNumber: PropTypes.string,
  applicationNumberValues: PropTypes.string,
  registrationNumberValue: PropTypes.string,
  formValues: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  fieldCompany: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

CardBasicInformation.defaultProps = {
  fieldClient: {},
  formValues: {},
  fieldCaseNumber: '',
  fieldCompany: '',
  applicationNumberValues: '',
  registrationNumberValue: '',
  labelFieldTitle: '',
  disabled: false,
};

function mapStateToProps(state) {
  return {
    objectStatuses: selectors.helpers.getObjectStatuses(state),
    formValues: selectors.form.getFormValues(state, 'ProposalAdd'),
    fieldClient: selectors.form.getFormValues(state, 'ProposalAdd').get('clients'),
    fieldCaseNumber: selectors.form.getFormValues(state, 'ProposalAdd').get('caseNumber'),
    fieldCompany: selectors.form.getFormValues(state, 'ProposalAdd').get('company'),
    applicationNumberValues: selectors.form.getFormValues(
      state, 'ProposalAdd',
    ).get('applicationNumber'),
    registrationNumberValue: selectors.form.getFormValues(
      state, 'ProposalAdd',
    ).get('registrationNumber'),
  };
}

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.formName, nextProps.formName)
  && isEqual(prevProps.formFields, nextProps.formFields)
  && isEqual(prevProps.pendingRegistryByNumber, nextProps.pendingRegistryByNumber)
  && isEqual(prevProps.entityName, nextProps.entityName)
  && isEqual(prevProps.fieldValueByNumber, nextProps.fieldValueByNumber)
  && isEqual(prevProps.labelFieldTitle, nextProps.labelFieldTitle)
  && isEqual(prevProps.objectStatuses, nextProps.objectStatuses)
  && isEqual(prevProps.formValues, nextProps.formValues)
  && isEqual(prevProps.fieldCaseNumber, nextProps.fieldCaseNumber)
  && isEqual(prevProps.fieldClient, nextProps.fieldClient)
  && isEqual(prevProps.disabled, nextProps.disabled)
  && isEqual(prevProps.fieldCompany, nextProps.fieldCompany)
  && isEqual(prevProps.applicationNumberValues, nextProps.applicationNumberValues)
  && isEqual(prevProps.registrationNumberValue, nextProps.registrationNumberValue)
  && isEqual(prevProps.pending, nextProps.pending);
}

export default compose(
  connect(mapStateToProps, null),
)(memo(CardBasicInformation, areEqual));
