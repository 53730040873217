// Core
import { apply, put } from 'redux-saga/effects';

// config
import { api } from '../../../../../config/api';
import { getObjectReverseFormatDate } from '../../../../../_helpers/formatterDate';

// actions
import actions from '../../action';

export function* callGetMadridCaseByIdWorker({ payload: id }) {
  yield put(actions.setMadridCaseById({
    pending: true,
  }));
  const response = yield apply(api, api.madridCase.getMadridCaseById, [id]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    yield put(actions.setMadridCaseDataById({
      ...data,
      ...getObjectReverseFormatDate(data.publicationDate, 'publicationDate'),
    }));
  }

  yield put(actions.setMadridCaseById({
    pending: false,
  }));
}
