// Helpers
import actionCreator from '../../_helpers/actionCreator';
// Types
import types from './types';

const actions = Object.freeze({
  setPendingImportObjects(objects) {
    return actionCreator(types.SET_PENDING_IMPORT_OBJECTS, objects);
  },
  setPendingImportExcel(objects) {
    return actionCreator(types.SET_PENDING_POST_IMPORT_EXCEL, objects);
  },
  setSpecialDocuments(objects) {
    return actionCreator(types.SET_SPECIAL_DOCUMENTS, objects);
  },
  setSelectedObjects(objects) {
    return actionCreator(types.SET_IMPORT_SELECTED_OBJECTS, objects);
  },
  mergeSelectedObjects(data) {
    return actionCreator(types.MERGE_IMPORT_SELECTED_OBJECTS, data);
  },
  setPendingSpecialDocuments(pending) {
    return actionCreator(types.SET_PENDING_SPECIAL_DOCUMENTS, pending);
  },
  setIsModalOpen(data) {
    return actionCreator(types.SET_IMPORT_IS_MODAL_OPEN, data);
  },
  setEntityList(objects) {
    return actionCreator(types.SET_IMPORT_ENTITY_LIST, objects);
  },
  mergeEntityList(data) {
    return actionCreator(types.MERGE_IMPORT_ENTITY_LIST, data);
  },
  setPendingPostExport(objects) {
    return actionCreator(types.SET_PENDING_POST_EXPORT, objects);
  },
  setIsModalOpenObject(data) {
    return actionCreator(types.SET_IMPORT_IS_MODAL_OPEN_OBJECT, data);
  },
  setEntityListNumber(objects) {
    return actionCreator(types.SET_IMPORT_ENTITY_LIST_NUMBER, objects);
  },
  mergeEntityListNumber(data) {
    return actionCreator(types.MERGE_IMPORT_ENTITY_LIST_NUMBER, data);
  },
});

export default actions;
