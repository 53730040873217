// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const productsActionAsync = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_PRODUCTS_ASYNC, params);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_PRODUCTS_INITIAL_STATE_ASYNC, path);
  },
  getProductByIdAsync(id) {
    return actionCreator(asyncTypes.GET_PRODUCT_BY_ID_ASYNC, id);
  },
  postProductAsync(data) {
    return actionCreator(asyncTypes.POST_PRODUCT_ASYNC, data);
  },
  putProductByIdAsync(data) {
    return actionCreator(asyncTypes.PUT_PRODUCT_BY_ID_ASYNC, data);
  },
  deleteProductAsync() {
    return actionCreator(asyncTypes.DELETE_PRODUCT_ASYNC);
  },
});

export default productsActionAsync;
