// Core
import { apply, put } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../config/api';
import actions from '../../action';

// actions

export function* callGetFieldsEntityWorker({ payload: name }) {
  const response = yield apply(api, api.fieldsSetting.getFieldsEntity, [name]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(actions.setFieldsEntity({}));
    } else {
      yield put(actions.setFieldsEntity(data.items));
    }
  }
}
