export default Object.freeze({
  SET_EMPLOYEE_BY_ID: 'SET_EMPLOYEE_BY_ID',
  SET_EMPLOYEES: 'SET_EMPLOYEES',
  SET_EMPLOYEES_AUTOCOMPLETE_OPTIONS: 'SET_EMPLOYEES_AUTOCOMPLETE_OPTIONS',
  SET_PENDING_EMPLOYEES_AUTOCOMPLETE_OPTIONS: 'SET_PENDING_EMPLOYEES_AUTOCOMPLETE_OPTIONS',
  SET_KEYS_DATA: 'SET_KEYS_DATA',
  SET_CSK_DATA: 'SET_CSK_DATA',
  SET_DELETE_EMPLOYEE: 'SET_DELETE_EMPLOYEE',
  SET_RESPONSIBLE_EMPLOYEE_BY_ID: 'SET_RESPONSIBLE_EMPLOYEE_BY_ID',
  SET_LIST_RESPONSIBLE: 'SET_LIST_RESPONSIBLE',
});
