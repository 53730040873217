import React, { Suspense } from 'react';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import Helmet from 'react-helmet';

import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { StylesProvider } from '@material-ui/styles';
import { ThemeProvider } from 'styled-components';

import maTheme from './theme';
import Routes from './routes/Routes';
import Loader from './components/Loader';

import withClearCache from './ClearCache';

function MainApp() {
  return (
    <>
      <Suspense fallback={<Loader />}>
        <Helmet
          titleTemplate="%s | lexberry"
          defaultTitle="Material App - React Admin & Dashboard Template"
        />
        <StylesProvider injectFirst>
          <MuiThemeProvider theme={maTheme[0]}>
            <ThemeProvider theme={maTheme[0]}>
              <ReactNotification />
              <Routes />
            </ThemeProvider>
          </MuiThemeProvider>
        </StylesProvider>
      </Suspense>
    </>
  );
}

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

export default App;
