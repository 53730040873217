// core
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import * as PropTypes from 'prop-types';

// parts
import ActionsTable from './ActionsTable';

// hooks
import { useActions } from './_hooks/useActions';

function ActionsList(props) {
  const { blockRetrievalRequest } = props;
  useActions(blockRetrievalRequest);

  return <ActionsTable blockRetrievalRequest={blockRetrievalRequest} />;
}

ActionsList.displayName = 'ActionsList';

ActionsList.propTypes = {
  blockRetrievalRequest: PropTypes.bool,
};

ActionsList.defaultProps = {
  blockRetrievalRequest: true,
};

function mapStateToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, null),
)(ActionsList);
