import React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';

// UI
import {
  Grid as MuiGrid,
  CircularProgress,
} from '@material-ui/core';

// styles
const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  top: 0;
  background: #8080802b;
`;

const Grid = styled(MuiGrid)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 1;
  z-index: 1;
`;

function LoaderWithOverlay(props) {
  const {
    position,
  } = props;

  const renderPositionLoader = () => (
    <Grid
      container
      spacing={0}
      alignItems="center"
      justify="center"
    >
      <CircularProgress m={2} color="secondary" />
    </Grid>
  );

  return position ? renderPositionLoader() : (
    <Root>
      <CircularProgress m={2} color="secondary" />
    </Root>
  );
}

LoaderWithOverlay.displayName = 'LoaderWithOverlay';

LoaderWithOverlay.propTypes = {
  position: PropTypes.bool,
};

LoaderWithOverlay.defaultProps = {
  position: false,
};

export default LoaderWithOverlay;
