// Core
import { apply, put, select } from 'redux-saga/effects';
import { Map } from 'immutable';

// lodash
import { isEmpty, has } from 'lodash';

// config
import { api } from '../../../../../config/api';
import { messageRequestSent } from '../../../../../config/globalConfig';
import selectors from '../../../../../config/selectors';

// actions
import actions from '../../action';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../../ui/_helpers/setNotificationMessage';

// init
import i18n from '../../../../../init/i18n';

export function* callGetSearchReportWorker({ payload: searchId }) {
  const {
    setSearchReportData,
    setSearchById,
  } = actions;
  yield put(setSearchReportData({ pending: true }));

  const searchTabs = yield select(selectors.tradeMarksSearches.searchTabs);
  const getSearchById = yield select(selectors.tradeMarksSearches.getSearchById);

  // TODO: Временно. Формирование параметов в отдельеном файле
  const response = yield apply(api, api.trademarks.getSearchReport, [
    searchId,
    !isEmpty(searchTabs.national.selectedBlocks)
      ? `ids[]=${searchTabs.national.selectedBlocks.join('&ids[]=')}`
      : '',
  ]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      yield put(setSearchById({
        data: Map({
          ...getSearchById.toJS(),
          pdf: data.pdf,
        }),
      }));
      yield put(setSuccessMessage(i18n.t(messageRequestSent), response.status.toString()));
    }
  }

  yield put(setSearchReportData({ pending: false }));
}
