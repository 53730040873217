// core
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Field, Form, reduxForm, change,
} from 'redux-form/lib/immutable';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import { Map } from 'immutable';

// lodash
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import uniqBy from 'lodash/uniqBy';
import filter from 'lodash/filter';

// ui
import {
  Button, Box, Chip as MuiChip,
  Typography, CircularProgress, Grid,
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { spacing } from '@material-ui/system';

// helpers
import { validators } from '../../../ui/_helpers/validation';
import { fieldsAutocomplete, formName } from './helper/form';
import { filterRemoveEntitiesInActionById } from './helper/filterEntitiesInActionById';
import { formatDate, getValueFormatDate } from '../../../engine/_helpers/formatterDate';

// parts
import Modal from '../../../components/Modal/Modal';
import DatePickers from '../../../ui/Form/DatePickers';
import Select from '../../../ui/Form/Select';
import AutocompleteGlobalSearch from '../../../ui/Form/AutocompleteGlobalSearch';
import DialogActions from '../../../components/Modal/components/DialogActions';
import renderTextField from '../../../ui/Form/RenderTextField';
import ChipEntity from '../../../ui/Chip/ChipEntity';

// actions
import pendingActionsAsync from '../../../engine/core/pendingActions/saga/asyncAction';
import pendingActions from '../../../engine/core/pendingActions/action';
import helpersActions from '../../../engine/core/helpers/action';
import actionTypeAsyncAction from '../../../engine/core/actionTypes/saga/asyncAction';
import actionTypeActions from '../../../engine/core/actionTypes/action';

// config
import selectors from '../../../engine/config/selectors';
import globalStatusesAction from '../../../engine/config/globalStatusesAction';
import accessList from '../../../engine/config/accessList';
import { useAccessList } from '../../../ui/_hooks/useAccessList';
import { useEventsAutocompleteAsync } from '../../../ui/_hooks/useEventsAutocompleteAsync';
import FieldActionTypes from '../../../ui/Form/FieldsAutocomplete/FieldActionTypes';

const TypographySub = styled(Typography)`
  color: ${(props) => props.theme.palette.grey['500']};
`;
// const FieldSet = styled.fieldset`
//   border: none;
//   margin: 0;
//   padding: 0;
// `;
const Chip = styled(MuiChip)(spacing);
const Accordion = styled(MuiAccordion)`
  margin-bottom: 20px;
  margin-top: 10px;
  box-shadow: none;
  width: 100%;
  :before {
    height: 0;
  }
`;
const AccordionDetails = styled(MuiAccordionDetails)`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;
const AccordionDetailsList = styled(MuiAccordionDetails)`
  display: flex;
  flex-direction: column;
`;
const TypographyItem = styled(Typography)`
  color: ${(props) => props.theme.palette.common.black};
  text-decoration: none;
  border-bottom: 1px dashed;
  cursor: pointer;
  width: fit-content;
  margin-bottom: 10px;
`;

function ActionModal(props) {
  const {
    initialize, handleSubmit, pendingPostPendingAction,
    pendingPutPendingActionById, getPendingActionById,
    entityList, isModalOpenAction, fieldRemindersType,
    fieldRemindersPeriod, typesValues, newActionType,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    getAutocompleteLists,
  } = useEventsAutocompleteAsync();
  const [readyInitialize, setReadyInitialize] = useState(false);
  const [listDate, setListDate] = useState([]);
  const accessPut = useAccessList(accessList.pendingActions_put);
  const accessEdit = !isEmpty(getPendingActionById) ? accessPut : true;

  const handleCloseModal = () => {
    dispatch(pendingActions.setIsModalOpen(false));
  };

  const handleSubmits = (formData) => {
    const json = formData.toJS();

    const getIDSValues = () => ({
      [fieldsAutocomplete.users]: map(entityList.toJS()[fieldsAutocomplete.users], 'id') || null,
      [fieldsAutocomplete.inventions]: entityList.toJS()[fieldsAutocomplete.inventions]?.id || null,
      [fieldsAutocomplete.trademarks]: entityList.toJS()[fieldsAutocomplete.trademarks]?.id || null,
      [fieldsAutocomplete.utility]: entityList.toJS()[fieldsAutocomplete.utility]?.id || null,
      [fieldsAutocomplete.industrial]: entityList.toJS()[fieldsAutocomplete.industrial]?.id || null,
      [fieldsAutocomplete.documents]: entityList.toJS()[fieldsAutocomplete.documents]?.id || null,
      [fieldsAutocomplete.charges]: entityList.toJS()[fieldsAutocomplete.charges]?.id || null,
      ...!isEmpty(json.reminders) ? {
        reminders: {
          type: json.reminders?.type,
          ...json.reminders?.type === 'regular' ? {
            startDate: getValueFormatDate(formatDate(json.reminders?.startDate), 'startDate'),
            period: {
              [json.reminders?.period]: json.reminders?.period === 'everyNumDay'
                ? json.reminders?.days
                : true,
            },
          } : {
            dates: map(listDate, (date) => formatDate(date, 'YYYY-MM-DD')),
          },
        },
      } : {},
    });
    if (!isEmpty(json)) {
      if (!isEmpty(getPendingActionById)) {
        dispatch(pendingActionsAsync.putPendingActionByIdAsync({
          title: json.title,
          id: getPendingActionById.id,
          status: Number(json.status),
          finishAt: getValueFormatDate(formatDate(json.end), 'finishAt'),
          ...getIDSValues(),
          ...!isEmpty(json.type) ? {
            type: json.type?.id,
          } : {},
        }));
      } else {
        dispatch(pendingActionsAsync.postPendingActionAsync({
          title: json.title,
          status: Number(json.status),
          finishAt: getValueFormatDate(formatDate(json.end), 'finishAt'),
          ...getIDSValues(),
          ...!isEmpty(json.type) ? {
            type: json.type?.id,
          } : {},
        }));
      }
    }
  };

  const handleEditAction = (id) => {
    dispatch(pendingActions.setPendingActionById({ data: {} }));
    dispatch(pendingActionsAsync.getPendingActionByIdAsync(id));
    setTimeout(() => {
      setReadyInitialize(false);
    });
  };

  useEffect(() => function cleanup() {
    dispatch(helpersActions.setAutocompleteGroupOptions({ items: [] }));
    dispatch(pendingActions.setPendingActionEntityList({}));
    dispatch(pendingActions.setPendingActionById({ data: {} }));
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(getPendingActionById) && !readyInitialize) {
      setReadyInitialize(true);
      initialize({
        ...filterRemoveEntitiesInActionById(getPendingActionById),
      });
      const data = getPendingActionById;
      dispatch(pendingActions.setPendingActionEntityList({
        ...data.trademarks ? { trademarks: data.trademarks } : {},
        ...data.utility ? { utility: data.utility } : {},
        ...data.inventions ? { inventions: data.inventions } : {},
        ...data.industrial ? { industrial: data.industrial } : {},
        ...data.documents ? { documents: data.documents } : {},
        ...data.users ? { users: data.users } : {},
        ...data.charges ? { charges: data.charges } : {},
      }));
    }
  }, [
    getPendingActionById, readyInitialize,
    initialize, setReadyInitialize, dispatch,
  ]);

  useEffect(() => {
    if (isEmpty(getPendingActionById)) {
      initialize({
        status: '1',
        title: '',
        end: new Date(),
      });
    }
  }, [
    getPendingActionById, initialize,
  ]);

  const handleChangeEntity = (item) => {
    if (!isEmpty(item)) {
      dispatch(pendingActions.mergePendingActionEntityList({
        entityName: item.entity,
        items: item.entity === 'users'
          ? uniqBy([
            ...!isEmpty(entityList.toJS()) && !isEmpty(entityList.toJS().users) ? [
              ...entityList.toJS().users,
            ] : [],
            item,
          ], 'id')
          : item,
      }));
    }
  };

  const handleDeleteEntity = (entityName, id) => {
    if (entityName && id) {
      dispatch(pendingActions.mergePendingActionEntityList({
        entityName,
        items: entityName === 'users'
          ? [
            ...!isEmpty(entityList.toJS()) && !isEmpty(entityList.toJS()[entityName]) ? [
              ...filter(entityList.toJS()[entityName], (item) => item.id !== id),
            ] : [],
          ]
          : {},
      }));
    }
  };

  const handleDeleteDate = (index) => {
    const duplicateListDate = [...listDate];
    duplicateListDate.splice(index, 1);
    setListDate(duplicateListDate);
  };

  const handleAddDate = (date) => {
    if (date) {
      setListDate([...listDate, date]);
      setTimeout(() => dispatch(change(formName, 'reminders.dates', null)));
    }
  };

  useEffect(() => {
    if (!isEmpty(newActionType.toJS())) {
      dispatch(change(formName, 'type', ''));
      setTimeout(() => {
        dispatch(change(formName, 'type', newActionType.toJS()));
      });
      dispatch(actionTypeActions.setNewActionType({}));
    }
  }, [dispatch, typesValues, newActionType]);

  const handleCreateNew = useCallback((value) => {
    dispatch(actionTypeActions.setIsModalOpen(true));
    if (value) dispatch(actionTypeActions.setDefaultName(value));
  }, [dispatch]);

  const handleEdit = useCallback((tag) => {
    if (tag) {
      dispatch(actionTypeActions.setActionTypeById({ data: Map(tag) }));
      dispatch(actionTypeActions.setIsModalOpen(true));
    }
  }, [dispatch]);

  const handleDelete = useCallback((tag) => {
    if (tag && tag.id) dispatch(actionTypeAsyncAction.deleteActionTypeAsync(tag.id));
  }, [dispatch]);

  return (
    <Modal
      title={isEmpty(getPendingActionById) ? t('Create action') : getPendingActionById.title}
      isModalOpen={isModalOpenAction}
      handleCloseModal={handleCloseModal}
      displayDialogActions={false}
    >
      <Form onSubmit={handleSubmit(handleSubmits)}>
        <Grid container spacing={6}>
          <Field
            required
            validate={validators.required}
            name={fieldsAutocomplete.title}
            id={fieldsAutocomplete.title}
            label={t('Title')}
            margin="normal"
            variant="outlined"
            my={2}
            fullWidth
            type="text"
            multiline
            rows={2}
            disabled={!accessEdit}
            component={renderTextField}
          />

          <Field
            required
            validate={validators.required}
            name={fieldsAutocomplete.status}
            id={fieldsAutocomplete.status}
            label={t('Status')}
            labelId="role"
            my={2}
            items={globalStatusesAction}
            fullWidth
            disabled={!accessEdit}
            component={Select}
          />

          <Field
            form={null}
            id={fieldsAutocomplete.end}
            labelId={fieldsAutocomplete.end}
            name={fieldsAutocomplete.end}
            fullWidth
            my={2}
            disabled={!accessEdit}
            label={t('Finish At')}
            component={DatePickers}
          />
          <Box mt={2} width="100%">
            <FieldActionTypes
              name="type"
              getAutocompleteLists={getAutocompleteLists}
              formName={formName}
              handleCreateNew={handleCreateNew}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          </Box>
          <Box mt={3} width="100%">
            <AutocompleteGlobalSearch
              label={t('Add connections')}
              handleChange={handleChangeEntity}
              helperText={t('Enter the name of the customer, № case, № application, № registration or name')}
              disableIcon
              entities={
                ['trademarks', 'utility', 'inventions', 'industrial', 'documents', 'users', 'charges']
              }
              isCleanup={!isEmpty(getPendingActionById)}
              disabled={!accessEdit}
            />
          </Box>
          <Grid item xs={12}>
            {!isEmpty(entityList.toJS()) && (
              map(Object.keys(entityList.toJS()), (entity, index) => {
                if (!isEmpty(entityList.toJS()[entity])) {
                  const item = entityList.toJS()[entity];
                  return (
                    <Grid key={index}>
                      <TypographySub variant="subtitle1" gutterBottom>
                        {t(entity)}
                      </TypographySub>
                      {entity === 'users' ? (
                        map(entityList.toJS()[entity], (elem) => (
                          <ChipEntity
                            key={elem.id}
                            id={elem.id}
                            entity={entity}
                            label={elem.label || elem.title || elem.id}
                            handleDelete={handleDeleteEntity}
                            disabled={!accessEdit}
                          />
                        ))
                      ) : (
                        <ChipEntity
                          id={item.id}
                          entity={entity}
                          label={item.label || item.title || item.id}
                          handleDelete={handleDeleteEntity}
                          disabled={!accessEdit}
                        />
                      )}
                    </Grid>
                  );
                }
                return null;
              })
            )}
          </Grid>
          {(!isEmpty(getPendingActionById.reminders)
          || !isEmpty(getPendingActionById.remindersParent)) && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">{t('List of related reminders')}</Typography>
              </AccordionSummary>
              <AccordionDetailsList>
                {map(getPendingActionById.reminders
                || getPendingActionById.remindersParent, (item) => (
                  <TypographyItem variant="subtitle2" key={item.id} onClick={() => handleEditAction(item.id)}>
                    {`${item.title} ${item.finishAt}`}
                  </TypographyItem>
                ))}
              </AccordionDetailsList>
            </Accordion>
          )}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">{t(!isEmpty(getPendingActionById) ? 'Create reminders' : 'Reminders')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Field
                name="reminders.type"
                id="reminders.type"
                label={t('Reminder type')}
                labelId="reminders.type"
                margin="none"
                my={2}
                items={[
                  { value: 'exactDates', name: 'One-time' },
                  { value: 'regular', name: 'Regular' },
                ]}
                fullWidth
                disabled={!accessEdit}
                component={Select}
              />
              {!isEmpty(fieldRemindersType) && (
                <Grid container>
                  {fieldRemindersType === 'exactDates' ? (
                    <>
                      <Field
                        name="reminders.dates"
                        id="reminders.dates"
                        label={t('Days')}
                        labelId="reminders.dates"
                        margin="normal"
                        my={2}
                        fullWidth
                        disabled={!accessEdit}
                        component={DatePickers}
                        onChange={handleAddDate}
                      />
                      <Box>
                        {map(listDate, (date, i) => (
                          <Chip
                            key={i}
                            style={{ maxWidth: '100%' }}
                            color="primary"
                            label={formatDate(date, 'DD.MM.YYYY')}
                            onDelete={() => handleDeleteDate(i)}
                            m={1}
                          />
                        ))}
                      </Box>
                    </>
                  ) : (
                    <>
                      <Field
                        name="reminders.period"
                        id="reminders.period"
                        label={t('Periodicity')}
                        labelId="reminders.period"
                        my={2}
                        items={[
                          { value: 'everyDay', name: t('Every day') },
                          { value: 'everyWeek', name: t('Every week') },
                          { value: 'everyMonth', name: t('Every month') },
                          { value: 'everyNumDay', name: t('Every n day') },
                        ]}
                        fullWidth
                        disabled={!accessEdit}
                        component={Select}
                      />
                      {fieldRemindersPeriod === 'everyNumDay' && (
                        <Field
                          name="reminders.days"
                          id="reminders.days"
                          label={t('Days')}
                          margin="normal"
                          my={2}
                          fullWidth
                          type="text"
                          disabled={!accessEdit}
                          component={renderTextField}
                        />
                      )}
                      <Field
                        name="reminders.startDate"
                        id="reminders.startDate"
                        label={t('Start date')}
                        labelId="reminders.startDate"
                        margin="normal"
                        my={2}
                        fullWidth
                        disabled={!accessEdit}
                        component={DatePickers}
                      />
                    </>
                  )}
                </Grid>
              )}
            </AccordionDetails>
          </Accordion>
        </Grid>

        <DialogActions
          dialogActionsChildren={(
            <>
              {accessEdit && (
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={pendingPutPendingActionById || pendingPostPendingAction}
                >
                  {pendingPutPendingActionById || pendingPostPendingAction
                    ? <CircularProgress size={25} color="inherit" />
                    : t('CONFIRM')}
                </Button>
              )}
              <Button
                variant="outlined"
                onClick={handleCloseModal}
                color="primary"
              >
                {t('CANCEL')}
              </Button>
            </>
          )}
        />
      </Form>
    </Modal>
  );
}

ActionModal.displayName = 'ActionModal';

ActionModal.propTypes = {
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  getPendingActionById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingPutPendingActionById: PropTypes.bool.isRequired,
  pendingPostPendingAction: PropTypes.bool.isRequired,
  isModalOpenAction: PropTypes.bool.isRequired,
  fieldRemindersType: PropTypes.string,
  fieldRemindersPeriod: PropTypes.string,
  entityList: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  typesValues: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  newActionType: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

ActionModal.defaultProps = {
  fieldRemindersType: '',
  fieldRemindersPeriod: '',
  typesValues: {},
};

function mapStateToProps(state) {
  return {
    getPendingActionById: selectors.pendingActions.getPendingActionById(state),
    pendingPutPendingActionById: selectors.pendingActions.pendingPutPendingActionById(state),
    pendingPostPendingAction: selectors.pendingActions.pendingPostPendingAction(state),
    entityList: selectors.pendingActions.entityList(state),
    isModalOpenAction: selectors.pendingActions.isModalOpenAction(state),
    fieldRemindersType: selectors.form.getFormValues(state, formName).get('reminders')?.get('type'),
    fieldRemindersPeriod: selectors.form.getFormValues(state, formName).get('reminders')?.get('period'),
    typesValues: selectors.form.getFormValues(state, formName).get('type'),
    newActionType: selectors.actionTypes.newActionType(state),
  };
}

export default compose(
  reduxForm({
    form: formName,
  }),
  connect(mapStateToProps, null),
)(ActionModal);
