// Core
import { all, call, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetReceivingWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callDeleteReceivingWorker,
  callPostCheckDocumentsWorker,
  callGetDownloadFileWorker,
} from './workers';
import types from '../types';

function* watchGet() {
  yield takeEvery(asyncTypes.GET_RECEIVING_ASYNC, callGetReceivingWorker);
}
function* watchGetInitialState() {
  yield takeEvery(
    asyncTypes.GET_RECEIVING_INITIAL_STATE_ASYNC,
    callGetInitialStateWorker,
  );
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_RECEIVING_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_RECEIVING_PAGE_SIZE, callSetToLocalDataWorker);
}

function* watchDelete() {
  yield takeEvery(asyncTypes.DELETE_RECEIVING, callDeleteReceivingWorker);
}

function* watchSetSorting() {
  yield takeEvery(types.SET_RECEIVING_SORTING, callSetToLocalDataWorker);
}

function* watchSetFilters() {
  yield takeEvery(types.SET_RECEIVING_FILTERS, callSetToLocalDataWorker);
}
function* watchSetColumnWidths() {
  yield takeEvery(types.SET_RECEIVING_COLUMN_WIDTHS, callSetToLocalDataWorker);
}
function* watchSetColumnOrder() {
  yield takeEvery(types.SET_RECEIVING_COLUMN_ORDER, callSetToLocalDataWorker);
}
function* watchSetHiddenColumnNames() {
  yield takeEvery(types.SET_RECEIVING_HIDDEN_COLUMN_NAMES, callSetToLocalDataWorker);
}

function* watchPostCheckDocumentsAsync() {
  yield takeEvery(asyncTypes.POST_CHECK_DOCUMENTS_ASYNC, callPostCheckDocumentsWorker);
}
function* watchGetDownloadInDocsAsync() {
  yield takeEvery(asyncTypes.GET_DOWNLOAD_FILE_IN_DOCS_ASYNC, callGetDownloadFileWorker);
}

export function* watchersReceivingTable() {
  yield all([
    call(watchGet),
    call(watchSetCurrentPage),
    call(watchSetPageSize),
    call(watchGetInitialState),
    call(watchDelete),
    call(watchSetFilters),
    call(watchSetSorting),
    call(watchPostCheckDocumentsAsync),
    call(watchGetDownloadInDocsAsync),
    call(watchSetColumnWidths),
    call(watchSetColumnOrder),
    call(watchSetHiddenColumnNames),
  ]);
}
