// Core
import React, { useEffect, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// lodash
import {
  isEmpty, filter, find, map,
} from 'lodash';

// ui
import {
  Select, MenuItem, Grid as MuiGrid,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';

// actions
import actions from '../../../engine/core/email/email/action';
import actionAsync from '../../../engine/core/email/email/saga/asyncAction';
import helpersActions from '../../../engine/core/helpers/action';

// parts
import DxTable from '../../../ui/Table/DxTable';

// config
import selectors from '../../../engine/config/selectors';
import accessList from '../../../engine/config/accessList';

// hooks
import { listTablesName, listEntityName } from '../../../engine/config/listTablesName';

// styles
const Grid = styled(MuiGrid)(spacing);

const tableName = listTablesName.email;
const entityName = listEntityName.emailLetters;

function EmailMessagesTable(props) {
  const {
    emailMessageData, pending, totalCount, pageSize,
    currentPage, filters, selectedEmail,
    checkboxes, isModalOpen, listEmail, sorting,
    columnWidths, columnOrder, hiddenColumnNames,
    blockRetrievalRequest,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Filtering
  const handleFolder = (e) => {
    dispatch(actions.setFilters([
      ...filter(filters, (item) => item.columnName !== 'folder' && item.columnName !== 'senderEmail'),
      { columnName: 'folder', value: e.target.value, type: '=' },
    ]));
    dispatch(actions.setCurrentPage(0));
  };
  const valueFolder = useMemo(() => {
    const findFilter = find(filters, { columnName: 'folder' });
    return findFilter?.value || 'INBOX';
  }, [filters]);

  useEffect(() => {
    if (!isEmpty(listEmail.toJS()) && isEmpty(selectedEmail.toJS()) && !blockRetrievalRequest) {
      dispatch(actions.setSelectedEmail(listEmail.toJS()[0]));
    }
  }, [dispatch, listEmail, selectedEmail, blockRetrievalRequest]);

  const handleEmail = (e) => {
    const findEmail = find(listEmail.toJS(), { id: e.target.value });
    dispatch(actions.setSelectedEmail(findEmail));
    dispatch(actions.setCurrentPage(0));
  };
  const valueEmail = useMemo(() => (
    selectedEmail.toJS()?.id || !blockRetrievalRequest ? listEmail.toJS()?.[0]?.id : ''
  ), [selectedEmail, listEmail, blockRetrievalRequest]);

  const columns = [
    { name: 'id', title: t('ID') },
    {
      name: 'senderEmail',
      title: valueFolder === 'SENT' ? t('Whom') : t('From whom'),
      folder: valueFolder,
    },
    { name: 'entity', title: t('Case') },
    { name: 'subject', title: t('Subject') },
    { name: 'snippet', title: t('Message') },
    { name: 'date', title: t('Date') },
  ];

  const tableColumnExtensions = [
    { columnName: 'id', width: 50 },
    { columnName: 'senderEmail', width: '10rem' },
    { columnName: 'entity', width: '10rem' },
    { columnName: 'subject', width: '20rem' },
    { columnName: 'snippet', width: '30rem' },
    { columnName: 'date', width: '10rem' },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isEmpty(selectedEmail.toJS()) && !blockRetrievalRequest) {
        dispatch(actionAsync.getCheckNewAsync(selectedEmail.toJS()?.id));
      }
    }, 30000);
    return () => clearInterval(interval);
  }, [dispatch, selectedEmail, blockRetrievalRequest]);

  // const handleScroll = useCallback(() => {
  //   if (document.body.scrollHeight === document.documentElement.scrollTop + window.innerHeight) {
  //     dispatch(actions.setCurrentPage(nextPageMessage - 1));
  //     dispatch(actions.setReload(true));
  //   }
  // }, [dispatch, nextPageMessage]);

  // useEffect(() => {
  //   if (nextPageMessage) {
  //     window.addEventListener('scroll', handleScroll);
  //     return () => window.removeEventListener('scroll', handleScroll);
  //   }
  //   return null;
  // }, [nextPageMessage, handleScroll]);

  // Paging
  const onCurrentPage = (page) => dispatch(actions.setCurrentPage(page));
  const onPageSize = (size) => dispatch(actions.setPageSize(size));
  // Filtering
  const setFilters = (getFilters) => dispatch(actions.setFilters(getFilters));
  // Sorting
  const onSortingChange = (getSorting) => dispatch(actions.setSorting(getSorting));

  const onColumnWidthsChange = (getColumnWidths) => dispatch(
    actions.setColumnWidths(getColumnWidths),
  );
  const onColumnOrderChange = (getColumnOrder) => dispatch(
    actions.setColumnOrder(getColumnOrder),
  );
  const onHiddenColumnNamesChange = (getHiddenColumnNames) => dispatch(
    actions.setHiddenColumnNames(getHiddenColumnNames),
  );

  return (
    <>
      <Grid container spacing={4} mb={3}>
        {!isEmpty(listEmail.toJS()) && (
          <Grid item xs={12} md={3}>
            <Select
              labelId="filterEmail"
              fullWidth
              value={valueEmail}
              onChange={handleEmail}
              disabled={pending}
            >
              {map(listEmail.toJS(), (item) => (
                <MenuItem key={item.id} value={item.id}>{item.email}</MenuItem>
              ))}
            </Select>
          </Grid>
        )}
        <Grid item xs={12} md={3}>
          <Select
            labelId="filter"
            fullWidth
            value={valueFolder}
            onChange={handleFolder}
            disabled={pending}
          >
            <MenuItem value="INBOX">{t('Inbox')}</MenuItem>
            <MenuItem value="SENT">{t('The Sent')}</MenuItem>
            <MenuItem value="TRASH">{t('Deleted')}</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <DxTable
        name="EmailMessages"
        isLoading={pending}
        columns={columns}
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageSize={onPageSize}
        onCurrentPage={onCurrentPage}
        rows={emailMessageData.toJS().items}
        entityName={entityName}
        // checkboxes ------------------------
        tableName={!blockRetrievalRequest ? tableName : 'CardEmail'}
        reloadTable={actions.setReload}
        selection={checkboxes.toJS()}
        onSelectionChange={(checkboxSelect) => {
          dispatch(helpersActions.setCheckbox({
            tableName,
            checkboxes: checkboxSelect,
          }));
        }}
        // ------------------------
        filters={filters}
        filtersAction={setFilters}
        sorting={sorting}
        onSortingChange={onSortingChange}
        tableColumnExtensions={!isEmpty(columnWidths) && columnWidths.length === columns.length
          ? columnWidths : tableColumnExtensions}
        onColumnWidthsChange={onColumnWidthsChange}
        columnOrder={!isEmpty(columnOrder) ? columnOrder : map(columns, 'name')}
        onColumnOrderChange={onColumnOrderChange}
        hiddenColumnNames={hiddenColumnNames}
        onHiddenColumnNamesChange={onHiddenColumnNamesChange}
        isModalOpen={isModalOpen}
        accessEdit="disabledEdit"
        accessDelete={accessList.emailLetters_delete}
        disableExport
      />
    </>
  );
}

EmailMessagesTable.displayName = 'EmailMessagesTable';

EmailMessagesTable.propTypes = {
  emailMessageData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pending: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  sorting: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  columnWidths: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  columnOrder: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  hiddenColumnNames: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  checkboxes: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  listEmail: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  selectedEmail: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  blockRetrievalRequest: PropTypes.bool,
};

EmailMessagesTable.defaultProps = {
  blockRetrievalRequest: false,
};

function mapStateToProps(state) {
  return {
    emailMessageData: selectors.email.emailMessageData(state),
    pending: selectors.email.pending(state),
    isModalOpen: selectors.email.isModalOpenMessage(state),
    listEmail: selectors.email.listEmail(state),
    selectedEmail: selectors.email.selectedEmail(state),

    // Paging
    totalCount: selectors.email.totalCount(state),
    pageSize: selectors.email.pageSize(state),
    currentPage: selectors.email.currentPage(state),

    // Filtering
    filters: selectors.email.filters(state),

    // Sorting
    sorting: selectors.email.sorting(state),

    // ColumnWidths
    columnWidths: selectors.email.columnWidths(state),
    columnOrder: selectors.email.columnOrder(state),
    hiddenColumnNames: selectors.email.hiddenColumnNames(state),

    checkboxes: selectors.helpers.getCheckboxes(state, tableName),
  };
}

function mapDispatchToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(EmailMessagesTable);
