// lodash
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import map from 'lodash/map';
import groupBy from 'lodash/groupBy';
import isArray from 'lodash/isArray';
import find from 'lodash/find';
import filter from 'lodash/filter';

export const getFieldsSearchGroup = (filterList) => {
  const groupByFieldName = map(groupBy(filterList, 'fieldName'), (value) => ({
    [value[0].fieldName]: value,
  }));
  return reduce(groupByFieldName, (result, value) => ({
    ...result, ...value,
  }), {});
};

export const convertToFilterDataSearchGroup = (options) => {
  const {
    filters, formValues,
    fieldNameSearchGroup,
  } = options;

  const fieldSearchGroup = formValues.toJS()[fieldNameSearchGroup];
  const filterFieldSearchGroup = fieldSearchGroup
    ? filter(filters, (item) => !find(fieldSearchGroup, {
      fieldName: item.columnName,
    }))
    : filters;

  const foundFilter = find(
    filterFieldSearchGroup,
    (filterItem) => filterItem.columnName === fieldNameSearchGroup,
  );
  const fieldsSearchGroup = foundFilter ? getFieldsSearchGroup(foundFilter.value) : {};
  const checkValueSearchGroup = !isEmpty(fieldsSearchGroup) && isArray(foundFilter.value);

  return [
    ...filterFieldSearchGroup,
    ...checkValueSearchGroup ? map(fieldsSearchGroup, (value, columnName) => ({
      columnName, value,
    })) : [],
  ];
};
