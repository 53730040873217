// core
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import * as PropTypes from 'prop-types';

// lodash
import isEqual from 'lodash/isEqual';

// ui
import {
  Box,
  Button,
  ButtonGroup,
  Typography,
} from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';

// parts
import Table from './components/TariffTable';
import TariffModal from './components/TariffModal';

// hooks
import { useTable } from './_hooks/useTable';

// actions
import actions from '../../../../../engine/core/prices/tariffs/tariff/action';
import NewConfirmModal from '../../../../../components/NewConfirmModal';
import selectors from '../../../../../engine/config/selectors';
import accessList from '../../../../../engine/config/accessList';
import { useAccessList } from '../../../../../ui/_hooks/useAccessList';

function TariffTable({
  id, isModalOpen, isModalOpenAdd, accessEdit,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const accessPost = useAccessList(accessList.costsComponents_post);
  const accessCostsComponentsList = useAccessList(accessList.costsComponents_list_get);

  useTable(id, 'costId');

  const handleChange = () => {
    if (id) {
      dispatch(actions.setModalOpen(true));
      return;
    }
    dispatch(actions.setConfirmModalOpen(true));
  };

  return (
    <>
      <Box mb={5}>
        <Typography variant="h6" gutterBottom display="inline">
          {t('Cost Components')}
        </Typography>
      </Box>

      {accessEdit && accessPost && (
        <Box mb={5}>
          <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
            <Button
              variant={id ? 'contained' : 'outlined'}
              color="primary"
              fullWidth
              onClick={handleChange}
            >
              {t('ADD')}
              <AddIcon />
            </Button>
          </ButtonGroup>
        </Box>
      )}

      <NewConfirmModal
        handleClose={() => dispatch(actions.setConfirmModalOpen(false))}
        isModalOpen={isModalOpen}
        buttonSendText=""
        buttonCancelText="CLEARLY"
        description="Save the tariff first"
      />

      {accessCostsComponentsList && <Table id={id} accessEdit={accessEdit} />}

      {isModalOpenAdd && <TariffModal />}
    </>
  );
}

TariffTable.displayName = 'TariffTable';

TariffTable.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  isModalOpenAdd: PropTypes.bool.isRequired,
  id: PropTypes.string,
  accessEdit: PropTypes.bool,
};

TariffTable.defaultProps = {
  id: '',
  accessEdit: true,
};

const mapStateToProps = (state) => ({
  isModalOpen: selectors.pricesTariff.isConfirmModalOpen(state),
  isModalOpenAdd: selectors.pricesTariff.isModalOpen(state),
});

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.id, nextProps.id)
    && isEqual(prevProps.isModalOpen, nextProps.isModalOpen)
    && isEqual(prevProps.isModalOpenAdd, nextProps.isModalOpenAdd);
}

export default compose(
  connect(mapStateToProps, null),
)(React.memo(TariffTable, areEqual));
