// Instruments
import { types } from './types';
import actionCreator from '../../_helpers/actionCreator';

const applicantsActions = Object.freeze({
  setApplicantsList(data) {
    return actionCreator(types.SET_APPLICANTS_LIST, data);
  },
  setApplicantsData(data) {
    return actionCreator(types.SET_APPLICANTS_DATA, data);
  },
  setCurrentPage(currentPage) {
    return actionCreator(types.SET_APPLICANTS_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return actionCreator(types.SET_APPLICANTS_FILTERS, filters);
  },
  setSorting(sorting) {
    return actionCreator(types.SET_APPLICANTS_SORTING, sorting);
  },
  setColumnWidths(data) {
    return actionCreator(types.SET_APPLICANTS_COLUMN_WIDTHS, data);
  },
  setColumnOrder(data) {
    return actionCreator(types.SET_APPLICANTS_COLUMN_ORDER, data);
  },
  setHiddenColumnNames(data) {
    return actionCreator(types.SET_APPLICANTS_HIDDEN_COLUMN_NAMES, data);
  },
  setPageSize(pageSize) {
    return actionCreator(types.SET_APPLICANTS_PAGE_SIZE, pageSize);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_APPLICANTS_INITIAL_STATE, initialState);
  },
  setReload(reload) {
    return actionCreator(types.SET_APPLICANTS_RELOAD, reload);
  },
  setApplicantsModalIsOpen(isOpen) {
    return actionCreator(types.SET_APPLICANTS_MODAL_IS_OPEN, isOpen);
  },
  setNewApplicantsListData(data) {
    return actionCreator(types.SET_NEW_APPLICANTS_LIST, data);
  },
  mergeNewApplicantsListData(data) {
    return actionCreator(types.MERGE_NEW_APPLICANTS_LIST, data);
  },
  setAutocompleteOptions(applicantsData) {
    return actionCreator(types.SET_APPLICANTS_AUTOCOMPLETE_OPTIONS, applicantsData);
  },
  setApplicantsInitialList(data) {
    return actionCreator(types.SET_INITIAL_APPLICANTS_LIST, data);
  },
  setPendingAutocompleteOptions(pending) {
    return actionCreator(types.SET_PENDING_APPLICANTS_AUTOCOMPLETE_OPTIONS, pending);
  },
  setNewApplicant(data) {
    return actionCreator(types.SET_NEW_APPLICANT, data);
  },
  setPendingGetApplicantById(pending) {
    return actionCreator(types.SET_PENDING_GET_APPLICANT_BY_ID, pending);
  },
  setApplicantById(data) {
    return actionCreator(types.SET_APPLICANT_BY_ID, data);
  },
  setDeleteApplicant(data) {
    return actionCreator(types.SET_DELETE_APPLICANT, data);
  },
  mergeNewOwnersListData(data) {
    return actionCreator(types.MERGE_APPLICANT_NEW_OWNERS_LIST, data);
  },
  setNewOwnersListData(data) {
    return actionCreator(types.SET_APPLICANT_NEW_OWNERS_LIST, data);
  },
  mergeNewAuthorsListData(data) {
    return actionCreator(types.MERGE_APPLICANT_NEW_AUTHORS_LIST, data);
  },
  setNewAuthorsListData(data) {
    return actionCreator(types.SET_APPLICANT_NEW_AUTHORS_LIST, data);
  },
});

export default applicantsActions;
