// Helpers
import actionCreator from '../../../../../_helpers/actionCreator';

// Types
import types from './types';

const actions = Object.freeze({
  setProposalDocumentsInById(data) {
    return actionCreator(types.SET_PROPOSAL_DOCUMENTS_IN_BY_ID, data);
  },
  setProposalDocumentsInData(data) {
    return actionCreator(types.SET_PROPOSAL_DOCUMENTS_IN_DATA, data);
  },
  setCurrentPage(currentPage) {
    return actionCreator(types.SET_PROPOSAL_DOCUMENTS_IN_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return actionCreator(types.SET_PROPOSAL_DOCUMENTS_IN_FILTERS, filters);
  },
  setSorting(sorting) {
    return actionCreator(types.SET_PROPOSAL_DOCUMENTS_IN_SORTING, sorting);
  },
  setPageSize(pageSize) {
    return actionCreator(types.SET_PROPOSAL_DOCUMENTS_IN_PAGE_SIZE, pageSize);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_PROPOSAL_DOCUMENTS_IN_INITIAL_STATE, initialState);
  },
  setReload(reload) {
    return actionCreator(types.SET_PROPOSAL_DOCUMENTS_IN_RELOAD, reload);
  },
  setPendingDeleteDocumentsIn(data) {
    return actionCreator(types.SET_PENDING_DELETE_DOCUMENTS_IN, data);
  },
  setDeleteDocumentsDataIn(data) {
    return actionCreator(types.SET_DELETE_DOCUMENTS_DATA_IN, data);
  },
  setDocumentsInEntity(data) {
    return actionCreator(types.SET_DOCUMENTS_IN_ENTITY, data);
  },
});

export default actions;
