// core
import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { compose } from 'recompose';
import {
  Field, change,
} from 'redux-form/immutable';

// ui
import { spacing } from '@material-ui/system';
import {
  Grid, Typography,
} from '@material-ui/core';

// lodash
import {
  find, isEmpty, isNil, isObject, map, sortBy,
  isEqual, forEach, includes, filter,
} from 'lodash';

// actions
import reboundsActionAsync from '../../../../../../engine/core/tradeMarks/rebounds/saga/asyncAction';
import proposalAsyncActions from '../../../../../../engine/core/tradeMarks/proposal/saga/asyncAction';
import mktuTemplatesAsyncActions from '../../../../../../engine/core/tradeMarks/mktuTemplates/saga/asyncAction';
import mktuTemplatesActions from '../../../../../../engine/core/tradeMarks/mktuTemplates/action';
import proposalActions from '../../../../../../engine/core/tradeMarks/proposal/action';
import reboundsAction from '../../../../../../engine/core/tradeMarks/rebounds/action';

// parts
import ButtonMenus from '../../../../../../ui/ButtonMenus/ButtonMenus';
import ClassesModal from './ClassesModal';
import FieldMktuTemplates from '../../../../../../ui/Form/FieldsAutocomplete/FieldMktuTemplates';
import FieldMktu from '../../../../../../ui/Form/FieldsAutocomplete/FieldMktu';
import AllTermsModal from '../../../../Rebounds/Rebound/components/AllTermsModal';
import Select from '../../../../../../ui/Form/Select';

// config
import globalLangMktu from '../../../../../../engine/config/globalLangMktu';
import selectors from '../../../../../../engine/config/selectors';
import accessList from '../../../../../../engine/config/accessList';

// helper
import { formFields, formName } from '../../../helper/form';

// hooks
import { useEventsAutocompleteAsync } from '../../../../../../ui/_hooks/useEventsAutocompleteAsync';
import { useAccessList } from '../../../../../../ui/_hooks/useAccessList';

// const correspondingClass = {
//   1: [2, 3, 5, 17, 19],
//   2: [3, 17],
//   3: [5, 21],
// };

// styles
const Spacer = styled.div(spacing);
// const ColorLegend = styled(Box)`
// width: 40px;
// height: 20px;
// display: inline-block;
//   margin-right: ${(props) => props.theme.spacing(2)}px;
// `;

function Classes(props) {
  const {
    mktuClasses, mktuTemplatesById, getProposalById, disabled,
    reboundById, match, mktuById, langMktuValue, classesById,
    selectMktuClasses, mktuValue, allClassesModalIsOpen,
  } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectedMktuToModal, setSelectedMktuToModal] = useState({});
  const [mktuToModal, setMktuToModal] = useState({});
  const [searchMktuToModal, setSearchMktuToModal] = useState({});
  const [optionMktuTemplates, setOptionMktuTemplates] = useState(null);
  const [flagClasses, setFlagClasses] = useState(0);
  const [firstLangClasses, setFirstLangClasses] = useState([]);
  const [readyInitialize, setReadyInitialize] = useState(false);
  const [optionRebounds, setOptionRebounds] = useState(null);
  const accessClassesList = useAccessList(accessList.mktu_classes_get);
  const { params } = match;

  useEffect(() => {
    if (!isNil(mktuValue)) {
      dispatch(reboundsActionAsync.getReboundByIdWorker(mktuValue));
    }
  }, [dispatch, mktuValue]);
  useEffect(() => {
    if (!isEmpty(reboundById.toJS()) && !langMktuValue) {
      dispatch(change(formName, 'langMktu', reboundById.toJS().lang || 'ua'));
    }
  }, [reboundById, langMktuValue, dispatch]);
  const {
    getAutocompleteLists,
    handleGetAsyncData,
  } = useEventsAutocompleteAsync();

  const termsToArray = (terms) => map(terms, (term) => {
    if (isObject(term)) {
      return term.value;
    }
    return term;
  }).join('; ');
  useEffect(() => {
    if (langMktuValue && accessClassesList) {
      dispatch(proposalAsyncActions.getClassesAsync({
        'lang:typeRequest:=': langMktuValue,
        limit: 100,
        page: 1,
      }));
    }
  }, [dispatch, langMktuValue, accessClassesList]);

  const handleChangeLang = (e) => {
    if (!isEmpty(selectMktuClasses.toJS())) {
      dispatch(proposalActions.setPendingClassesById(true));
      forEach(selectMktuClasses.toJS(), (item) => {
        dispatch(proposalAsyncActions.getClassByIdAsync({
          id: item.classNum,
          lang: langMktuValue,
          multiClass: true,
        }));
      });
      dispatch(reboundsAction.setReboundById({
        ...reboundById.toJS(),
        lang: e.target.value,
      }));
      setFlagClasses(1);
    }
  };

  useEffect(() => {
    if (!isEmpty(selectMktuClasses.toJS()) && !isEmpty(classesById.toJS()) && flagClasses === 1
      && classesById.size === selectMktuClasses.toJS().length) {
      const selectedClasses = map(selectMktuClasses.toJS(), (item) => ({
        ...item,
        name: item.name.split('; '),
      }));
      const selectedClassesNum = map(selectedClasses, (item) => ({
        ...item,
        name: map(item.name, (itemName) => {
          const elem = classesById.toJS()[item.classNum];
          return find(Object.keys(elem), (key) => elem[key] === itemName);
        }),
      }));
      dispatch(proposalActions.setClearClassesById());
      forEach(selectMktuClasses.toJS(), (item) => {
        dispatch(proposalAsyncActions.getClassByIdAsync({
          id: item.classNum,
          lang: langMktuValue,
          multiClass: true,
        }));
      });
      setFirstLangClasses(selectedClassesNum);
      setFlagClasses(2);
    }
  }, [
    classesById, selectMktuClasses, flagClasses, setFirstLangClasses,
    dispatch, langMktuValue,
  ]);

  const saveMktu = useCallback((selectedMktu) => {
    const selection = {};
    map(selectedMktu, (item) => {
      selection[item.classNum] = item?.name;
    });
    dispatch(reboundsActionAsync[!isEmpty(reboundById.toJS()) ? 'putReboundByIdAsync' : 'postReboundAsync']({
      ...reboundById.toJS(),
      ...!isEmpty(params) ? {
        trademarks: params.hashId,
      } : {},
      ...!isEmpty(getProposalById.toJS()) && !isEmpty(params) && isEmpty(reboundById.toJS()) ? {
        title: getProposalById.toJS().internalTitle || getProposalById.toJS().title,
      } : {},
      ...!isEmpty(reboundById.toJS().clients) && isObject(reboundById.toJS().clients) ? {
        clients: reboundById.toJS().clients.id,
      } : {},
      selection,
      reload: true,
      lang: langMktuValue,
    }));
    dispatch(proposalActions.setSelectMktuClasses(selectedMktu));
  }, [
    dispatch, reboundById, getProposalById, params, langMktuValue,
  ]);

  useEffect(() => {
    if (!isEmpty(selectMktuClasses.toJS()) && !isEmpty(classesById.toJS()) && flagClasses === 2
      && classesById.size === selectMktuClasses.toJS().length && !isEmpty(firstLangClasses)) {
      const selectedClassesNum = map(firstLangClasses, (item) => {
        const findClass = find(mktuClasses.toJS(), { classNum: item.classNum });
        const checkHeader = includes(item.name, undefined);
        const filterName = filter(item.name, (el) => el);
        return ({
          ...findClass,
          name: [
            ...checkHeader ? findClass.label.split('; ') : [],
            ...map(filterName, (itemNum) => {
              const elem = classesById.toJS()[item.classNum];
              return elem?.[itemNum];
            }),
          ].join('; '),
        });
      });
      saveMktu(selectedClassesNum);
      dispatch(proposalActions.setClearClassesById());
      dispatch(proposalActions.setPendingClassesById(false));
      setFlagClasses(1);
      setFirstLangClasses([]);
    }
  }, [
    classesById, selectMktuClasses, flagClasses, firstLangClasses,
    mktuClasses, dispatch, saveMktu,
  ]);

  useEffect(() => {
    if (!isEmpty(mktuTemplatesById.toJS())) {
      const templateClassNumArray = Object.keys(mktuTemplatesById.toJS().selected);
      const selectTemplateClassNum = map(templateClassNumArray, (templateClassNum) => {
        const selectClassNum = find(mktuClasses.toJS(), { classNum: templateClassNum });
        selectClassNum.name = termsToArray(mktuTemplatesById.toJS().selected[templateClassNum]);
        return selectClassNum;
      });
      saveMktu(selectTemplateClassNum);
      dispatch(mktuTemplatesActions.setMktuTemplatesById({}));
    }
  }, [mktuTemplatesById, dispatch, saveMktu, mktuClasses]);

  useEffect(() => {
    if (!isEmpty(mktuById.toJS())) {
      const templateClassNumArray = Object.keys(mktuById.toJS().selected);
      const selectTemplateClassNum = map(templateClassNumArray, (templateClassNum) => {
        const selectClassNum = find(mktuClasses.toJS(), { classNum: templateClassNum });
        selectClassNum.name = termsToArray(mktuById.toJS().selected[templateClassNum]);
        return selectClassNum;
      });
      saveMktu(selectTemplateClassNum);
      dispatch(proposalActions.setMktuById({}));
    }
  }, [mktuById, dispatch, saveMktu, mktuClasses]);

  useEffect(() => {
    if (!isEmpty(reboundById.toJS()) && !isEmpty(mktuClasses.toJS())
      && !isEmpty(params) && !readyInitialize) {
      const templateClassNumArray = Object.keys(reboundById.toJS().selected);
      const selectTemplateClassNum = map(templateClassNumArray, (templateClassNum) => {
        const selectClassNum = find(mktuClasses.toJS(), { classNum: templateClassNum });
        if (!isEmpty(selectClassNum)) {
          selectClassNum.selection = true;
          selectClassNum.name = termsToArray(reboundById.toJS().selected[templateClassNum]);
          return selectClassNum;
        }
        return false;
      });
      if (selectTemplateClassNum) {
        dispatch(proposalActions.setSelectMktuClasses(selectTemplateClassNum));
        setReadyInitialize(true);
      }
    }
    if (!isEmpty(reboundById.toJS()) && isEmpty(params) && isEmpty(selectMktuClasses.toJS())
      && !isEmpty(mktuClasses.toJS()) && !readyInitialize) {
      dispatch(reboundsAction.setReboundById({}));
      setReadyInitialize(true);
    }
  }, [
    reboundById, setReadyInitialize, readyInitialize, mktuClasses,
    dispatch, params, selectMktuClasses,
  ]);

  const handleDeleteMktuClasses = (mktuClass) => {
    const selectedMktu = selectMktuClasses.toJS().filter(
      (selectMktuClass) => (selectMktuClass.classNum !== mktuClass.classNum),
    );
    const selection = {};
    map(selectedMktu, (item) => {
      selection[item.classNum] = item?.name;
    });
    // if (!isEmpty(selection)) {
    dispatch(reboundsActionAsync.putReboundByIdAsync({
      ...reboundById.toJS(),
      ...!isEmpty(params) ? {
        trademarks: params.hashId,
      } : {},
      ...!isEmpty(reboundById.toJS().clients) && isObject(reboundById.toJS().clients) ? {
        clients: reboundById.toJS().clients.id,
      } : {},
      selection,
      reload: true,
    }));
    // } else {
    //   dispatch(reboundsAction.setReboundById({}));
    // }
    dispatch(proposalActions.setSelectMktuClasses(selectedMktu));
  };
  const handleEditMktuClasses = useCallback((mktuClass, value) => {
    const classInSelected = find(selectMktuClasses.toJS(), { classNum: mktuClass.classNum });
    const selectedMktu = isNil(classInSelected)
      ? [...selectMktuClasses.toJS(), { ...mktuClass, name: value }]
      : map(selectMktuClasses.toJS(), (item) => {
        if (item.classNum === classInSelected.classNum) {
          return ({ ...item, name: value });
        }
        return item;
      });
    saveMktu(selectedMktu);
  }, [
    selectMktuClasses, saveMktu,
  ]);

  const handleMktuGetSelectOption = useCallback((option) => {
    setOptionMktuTemplates(option);
    if (!isEmpty(option)) {
      dispatch(mktuTemplatesAsyncActions.getMktuTemplateByIdWorker(option?.id));
      setOptionRebounds(null);
    }
  }, [dispatch]);
  const handleReboundsGetSelectOption = useCallback((option) => {
    setOptionRebounds(option);
    if (!isEmpty(option)) {
      dispatch(proposalAsyncActions.getMktuByIdAsync(option?.id));
      setOptionMktuTemplates(null);
    }
  }, [dispatch]);

  const handleSelectedMktuClass = (selectMktuClass, mktuClass) => {
    setSelectedMktuToModal({ selectMktuClass, mktuClass });
    dispatch(proposalActions.setModalIsOpen(true));
  };
  const handleModalAllTermsOpen = (mktuClass) => {
    setMktuToModal(mktuClass);
    setSearchMktuToModal({});
    dispatch(reboundsAction.setModalAllTermsOpen(true));
  };

  return (
    <>
      <Typography variant="h2" gutterBottom>
        {t('ICGS')}
      </Typography>
      <Grid container spacing={4}>

        <Grid item xs={12} md={4}>
          <Field
            name="langMktu"
            id="langMktu"
            labelId="langMktu"
            label={t('Language')}
            my={1}
            items={globalLangMktu}
            fullWidth
            component={Select}
            onChange={handleChangeLang}
            disabled={disabled}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FieldMktuTemplates
            name={formFields.mktuTemplates}
            label="Choose from templates"
            getAutocompleteLists={getAutocompleteLists}
            getAsyncData={handleGetAsyncData}
            formName={formName}
            propsField={{
              fieldValue: optionMktuTemplates,
              getSelectOption: handleMktuGetSelectOption,
              disabled,
            }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FieldMktu
            name={formFields.caseNumberRebounds}
            label="Choose from another case"
            getAutocompleteLists={getAutocompleteLists}
            getAsyncData={handleGetAsyncData}
            formName={formName}
            propsField={{
              fieldValue: optionRebounds,
              getSelectOption: handleReboundsGetSelectOption,
              disabled,
            }}
          />
        </Grid>
      </Grid>
      <Spacer mb={6} />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            {map(sortBy(mktuClasses.toJS(), 'id'), (mktuClass, index) => {
              const selectMktuClass = find(selectMktuClasses.toJS(),
                { classNum: mktuClass.classNum });
              // const correspondingBool = map(selectMktuClasses.toJS(),
              //   (value) => includes(correspondingClass[value.classNum],
              //     Number(mktuClass.classNum)));
              // const isCorresponding = includes(correspondingBool, true);
              const color = !isNil(selectMktuClass) ? { color: 'primary' } : { color: 'default' };
              // isCorresponding ? {
              //   style: {
              //     background: 'rgba(33, 150, 243, 0.3)',
              //     color: '#fff',
              //   },
              // } ;
              return (
                <Grid key={index} item>

                  <ButtonMenus
                    toolTipText={mktuClass.name}
                    color={color}
                    key={index}
                    id={index}
                    label={mktuClass.classNum}
                    disabled={disabled}
                    items={[
                      {
                        label: 'Select all',
                        handler: () => {
                          handleEditMktuClasses(mktuClass, 'All');
                        },
                      },
                      {
                        label: 'Terms',
                        handler: () => {
                          handleModalAllTermsOpen(mktuClass);
                        },
                      },
                      {
                        label: 'Edit list',
                        handler: () => {
                          handleSelectedMktuClass(selectMktuClass, mktuClass);
                        },
                      },
                      {
                        label: 'Delete',
                        handler: () => {
                          handleDeleteMktuClasses(mktuClass);
                        },
                      },
                    ]}
                  />
                </Grid>
              );
            })}
            {!allClassesModalIsOpen && (
              <>
                <ClassesModal
                  editMktuClasses={handleEditMktuClasses}
                  mktuTemplatesById={mktuTemplatesById}
                  selectedMktuToModal={selectedMktuToModal}
                />
                <AllTermsModal
                  editMktuClasses={handleEditMktuClasses}
                  mktuClass={mktuToModal}
                  searchMktuClass={searchMktuToModal}
                  langMktuValue={langMktuValue}
                />
              </>
            )}

          </Grid>
        </Grid>
        {/* <Grid item xs={12} container alignItems="center" justify="space-between">
          <div>
            <Box mr={2} display="inline-flex" flexDirection="row"
              alignItems="center" component="span">
              <ColorLegend
                style={{ background: '#1976d2' }}
                component="span"
              />
              {' '}
              -
              {t('Selected classes')}
            </Box>
            <Box display="inline-flex" flexDirection="row" alignItems="center" component="span">
              <ColorLegend
                style={{ background: 'rgba(33, 150, 243, 0.3)' }}
                component="span"
              />
              {' '}
              -
              {t('Corresponding classes')}
            </Box>
          </div>
        </Grid> */}
      </Grid>
    </>
  );
}
Classes.defaultProps = {
  mktuValue: null,
  match: {},
  langMktuValue: '',
  disabled: false,
};
Classes.propTypes = {
  mktuClasses: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,

  mktuTemplatesById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  mktuById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  reboundById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  selectMktuClasses: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  mktuValue: PropTypes.number,
  langMktuValue: PropTypes.string,
  allClassesModalIsOpen: PropTypes.bool.isRequired,
  match: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  getProposalById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  classesById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  disabled: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    mktuTemplatesById: selectors.mktuTemplates.getMktuTemplatesById(state),
    mktuById: selectors.tradeMarksProposal.mktuById(state),
    getProposalById: selectors.tradeMarksProposal.getProposalById(state),
    mktuClasses: selectors.tradeMarksProposal.classes(state),
    classesById: selectors.tradeMarksProposal.classesById(state),
    reboundById: selectors.rebounds.getReboundById(state),
    selectMktuClasses: selectors.tradeMarksProposal.selectMktuClasses(state),
    mktuValue: selectors.form.getFormValues(state, formName).get(formFields.mktu),
    langMktuValue: selectors.form.getFormValues(state, formName).get('langMktu'),
    allClassesModalIsOpen: selectors.tradeMarksProposal.allClassesModalIsOpen(state),
  };
}

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.allClassesModalIsOpen, nextProps.allClassesModalIsOpen)
  && isEqual(prevProps.getProposalById, nextProps.getProposalById)
  && isEqual(prevProps.match, nextProps.match)
  && isEqual(prevProps.mktuById, nextProps.mktuById)
  && isEqual(prevProps.mktuClasses, nextProps.mktuClasses)
  && isEqual(prevProps.mktuTemplatesById, nextProps.mktuTemplatesById)
  && isEqual(prevProps.mktuValue, nextProps.mktuValue)
  && isEqual(prevProps.reboundById, nextProps.reboundById)
  && isEqual(prevProps.langMktuValue, nextProps.langMktuValue)
  && isEqual(prevProps.classesById, nextProps.classesById)
  && isEqual(prevProps.disabled, nextProps.disabled)
  && isEqual(prevProps.selectMktuClasses, nextProps.selectMktuClasses);
}

export default compose(connect(mapStateToProps, null))(memo(Classes, areEqual));
