// Core
import { apply, put } from 'redux-saga/effects';

// config
import { api } from '../../../../../config/api';
import { listEntityName } from '../../../../../config/listTablesName';

// actions
import actions from '../../action';

const entity = listEntityName.emailLetters;

export function* callGetEmailLettersWorker({ payload: params }) {
  yield put(actions.setPendingLetters(true));

  const response = yield apply(api, api.emailLetters.getEmailLetters, [{ ...params, entity }]);

  let data = {
    items: [],
  };

  if (response && response.status >= 200 && response.status < 400) {
    data = Object.assign(data, response.data);
  }

  yield put(actions.setEmailLetters(data.items));
  yield put(actions.setEmailLettersTotalCount(response?.data?.totalCount || 0));
  yield put(actions.setPendingLetters(false));
}
