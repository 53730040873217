export default Object.freeze({
  SET_SENDING_DATA: 'SET_SENDING_DATA',
  SET_SENDING_CURRENT_PAGE: 'SET_SENDING_CURRENT_PAGE',
  SET_SENDING_PAGE_SIZE: 'SET_SENDING_PAGE_SIZE',
  SET_SENDING_FILTERS: 'SET_SENDING_FILTERS',
  SET_SENDING_INITIAL_STATE: 'SET_SENDING_INITIAL_STATE',
  SET_SENDING_RELOAD: 'SET_SENDING_RELOAD',
  SET_SENDING_SORTING: 'SET_SENDING_SORTING',
  SET_SENDING_COLUMN_WIDTHS: 'SET_SENDING_COLUMN_WIDTHS',
  SET_SENDING_COLUMN_ORDER: 'SET_SENDING_COLUMN_ORDER',
  SET_SENDING_HIDDEN_COLUMN_NAMES: 'SET_SENDING_HIDDEN_COLUMN_NAMES',
});
