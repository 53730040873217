// Core
import { apply, select, put } from 'redux-saga/effects';
import isNil from 'lodash/isNil';

// Engine
import localData from '../../../../config/localData';
import selectors from '../../../../config/selectors';
import types from '../../types';
import actions from '../../action';

export function* callSetToLocalDataWorker({ type, payload }) {
  const pathname = yield select(selectors.router.pathname);
  const data = {
    filters: (yield select(selectors.pendingActions.filters)),
  };

  let currentData;
  yield put(actions.setReload(true));
  switch (type) {
    case types.SET_PENDING_ACTION_CALENDAR_FILTERS: {
      const filters = payload.filter(({ value }) => !isNil(value));
      currentData = { filters };
      break;
    }
    default: {
      break;
    }
  }
  yield apply(localData, localData.setItem, [pathname, { ...data, ...currentData }]);
}
