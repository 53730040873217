// Helpers
import actionCreator from '../../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const asyncActions = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_ORDERS_ASYNC, params);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_ORDERS_INITIAL_STATE_ASYNC, path);
  },
  getOrderByIdAsync(id) {
    return actionCreator(asyncTypes.GET_ORDER_BY_ID_ASYNC, id);
  },
  postOrderAsync(order) {
    return actionCreator(asyncTypes.POST_ORDER_ASYNC, order);
  },
  postInvoiceAsync(invoice) {
    return actionCreator(asyncTypes.POST_INVOICE_ASYNC, invoice);
  },
  putOrderByIdAsync(order) {
    return actionCreator(asyncTypes.PUT_ORDER_BY_ID_ASYNC, order);
  },
  deleteOrderAsync() {
    return actionCreator(asyncTypes.DELETE_ORDER_ASYNC);
  },
  getAutocompleteOptionsAsync(params) {
    return actionCreator(asyncTypes.GET_ORDERS_AUTOCOMPLETE_OPTIONS_ASYNC, params);
  },
  getNextNumberAsync(params) {
    return actionCreator(asyncTypes.GET_ORDER_NEXT_NUMBER_ASYNC, params);
  },
});

export default asyncActions;
