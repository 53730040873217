const asyncTypes = Object.freeze({
  GET_PERMISSIONS_GROUPS_ASYNC: 'GET_PERMISSIONS_GROUPS_ASYNC',
  GET_PERMISSIONS_GROUP_BY_ID_ASYNC: 'GET_PERMISSIONS_GROUP_BY_ID_ASYNC',
  POST_PERMISSIONS_GROUP_ASYNC: 'POST_PERMISSIONS_GROUP_ASYNC',
  PUT_PERMISSIONS_GROUP_BY_ID_ASYNC: 'PUT_PERMISSIONS_GROUP_BY_ID_ASYNC',
  DELETE_PERMISSIONS_GROUP_ASYNC: 'DELETE_PERMISSIONS_GROUP_ASYNC',
  GET_PERMISSIONS_ASYNC: 'GET_PERMISSIONS_ASYNC',
});

export default asyncTypes;
