// Core
import { call, put } from 'redux-saga/effects';
import { Map } from 'immutable';

// config
import { api } from '../../../../../config/api';

// actions
import actions from '../../action';

// helpers
import { getObjectReverseFormatDate } from '../../../../../_helpers/formatterDate';

export function* callGetPaymentByIdWorker({ payload: id }) {
  const {
    setPaymentById,
  } = actions;
  yield put(setPaymentById({
    pending: true,
  }));

  const response = yield call(api.company.getPaymentById, id);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    yield put(setPaymentById({
      data: Map({
        ...data,
        ...getObjectReverseFormatDate(data.paymentDate, 'paymentDate'),
      }),
    }));
  }

  yield put(setPaymentById({
    pending: false,
  }));
}
