export default Object.freeze({
  SET_CLIENT_BY_ID: 'SET_CLIENT_BY_ID',
  SET_CLIENT_DATA: 'SET_CLIENT_DATA',
  SET_DELETE_CLIENT: 'SET_DELETE_CLIENT',
  SET_CLIENT_AUTOCOMPLETE_OPTIONS: 'SET_CLIENT_AUTOCOMPLETE_OPTIONS',
  SET_PENDING_CLIENT_AUTOCOMPLETE_OPTIONS: 'SET_PENDING_CLIENT_AUTOCOMPLETE_OPTIONS',
  SET_PENDING_POST_MERGE_CLIENTS: 'SET_PENDING_POST_MERGE_CLIENTS',
  SET_IS_MODAL_OPEN_MERGE_CLIENTS: 'SET_IS_MODAL_OPEN_MERGE_CLIENTS',
  SET_IS_MODAL_OPEN_ADDRESS_CLIENTS: 'SET_IS_MODAL_OPEN_ADDRESS_CLIENTS',
  SET_PENDING_CLIENTS_SENT_CREDENTIALS: 'SET_PENDING_CLIENTS_SENT_CREDENTIALS',
});
