// Core
import { all, call, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetWorksWorker,
  callDeleteWorkWorker,
  callPutWorkWorker,
  callGetWorkByIdWorker,
  callPostWorkWorker,
} from './workers';

function* watchGetWorks() {
  yield takeEvery(asyncTypes.GET_PRICES_WORKS_ASYNC, callGetWorksWorker);
}
function* watchDeleteWork() {
  yield takeEvery(asyncTypes.DELETE_PRICES_WORK_ASYNC, callDeleteWorkWorker);
}
function* watchPutWorkWorker() {
  yield takeEvery(asyncTypes.PUT_PRICES_WORK_ASYNC, callPutWorkWorker);
}
function* watchGetWorkByIdWorker() {
  yield takeEvery(asyncTypes.GET_PRICES_WORK_BY_ID_ASYNC, callGetWorkByIdWorker);
}
function* watchPostWorkWorker() {
  yield takeEvery(asyncTypes.POST_PRICES_WORK_ASYNC, callPostWorkWorker);
}

export function* watchersPricesWorks() {
  yield all([
    call(watchGetWorks),
    call(watchDeleteWork),
    call(watchPutWorkWorker),
    call(watchGetWorkByIdWorker),
    call(watchPostWorkWorker),
  ]);
}
