// core
import React, {
  memo, useEffect, useState,
} from 'react';
import {
  change,
} from 'redux-form/immutable';
import * as PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';

// lodash
import { isEmpty, isEqual, pullAt } from 'lodash';

// actions
import helpersActions from '../../../../../engine/core/helpers/action';
import applicantsActions from '../../../../../engine/core/applicants/action';
import applicantsAsyncActions from '../../../../../engine/core/applicants/saga/asyncAction';

// parts
import CardApplicants from './CardApplicants';
import AddApplicantModal from '../../../../../components/AddApplicantModal/AddApplicantModal';

// config
import selectors from '../../../../../engine/config/selectors';

function CardApplicantsOwnersAuthor(props) {
  const {
    fieldClient, formName, registrationNumberValue, newApplicantsList,
    pending, entityApplicantModal, newOwnersList, newAuthorsList,
    newApplicant, isModalOpenAddApplicant, entityName, disabled,
  } = props;
  const dispatch = useDispatch();
  const [applicantIndex, setApplicantIndex] = useState(0);
  const [editApplicant, setEditApplicant] = useState({});

  useEffect(() => {
    if (!isEmpty(newApplicant.toJS()) && entityApplicantModal) {
      switch (entityApplicantModal) {
        case 'applicant': {
          dispatch(applicantsActions.mergeNewApplicantsListData([newApplicant.toJS()]));
          break;
        }
        case 'owner': {
          dispatch(applicantsActions.mergeNewOwnersListData([newApplicant.toJS()]));
          break;
        }
        case 'author': {
          dispatch(applicantsActions.mergeNewAuthorsListData([newApplicant.toJS()]));
          break;
        }
        default: {
          break;
        }
      }
      dispatch(helpersActions.setEntityApplicantModal(''));
      dispatch(applicantsActions.setNewApplicant({}));
    }
  }, [newApplicant, entityApplicantModal, dispatch]);

  const handleDeleteApplicant = (index) => {
    const newApplicantsListDelete = [...newApplicantsList.toJS()];
    pullAt(newApplicantsListDelete, index);
    dispatch(applicantsActions.setNewApplicantsListData(newApplicantsListDelete));
    dispatch(change(formName, 'mailingAddress', ''));
  };
  const handleDeleteOwners = (index) => {
    const newOwnersListDelete = [...newOwnersList.toJS()];
    pullAt(newOwnersListDelete, index);
    dispatch(applicantsActions.setNewOwnersListData(newOwnersListDelete));
  };
  const handleDeleteAuthors = (index) => {
    const newAuthorsListDelete = [...newAuthorsList.toJS()];
    pullAt(newAuthorsListDelete, index);
    dispatch(applicantsActions.setNewAuthorsListData(newAuthorsListDelete));
  };

  const handleEditApplicant = (item, index, entity) => {
    setEditApplicant(item);
    setApplicantIndex(index);
    dispatch(helpersActions.setEntityApplicantModal(entity));
    dispatch(helpersActions.setModal({ isModalOpenAddApplicant: true }));
  };

  const handleSendEditApplicant = (item) => {
    dispatch(helpersActions.setModal({ isModalOpenAddApplicant: false }));
    dispatch(helpersActions.setEntityApplicantModal(''));
    setEditApplicant({});
    setApplicantIndex(0);
    if (entityApplicantModal === 'owner') {
      const newOwnersListEdit = [...newOwnersList.toJS()];
      pullAt(newOwnersListEdit, applicantIndex);
      dispatch(applicantsActions.setNewOwnersListData([...newOwnersListEdit, item]));
      return;
    }
    if (entityApplicantModal === 'author') {
      const newAuthorsListEdit = [...newAuthorsList.toJS()];
      pullAt(newAuthorsListEdit, applicantIndex);
      dispatch(applicantsActions.setNewAuthorsListData([...newAuthorsListEdit, item]));
      return;
    }
    const newApplicantsListEdit = [...newApplicantsList.toJS()];
    pullAt(newApplicantsListEdit, applicantIndex);
    dispatch(applicantsActions.setNewApplicantsListData([...newApplicantsListEdit, item]));
    dispatch(change(formName, 'mailingAddress', ''));
  };

  const handleCloseApplicantModal = () => {
    setEditApplicant({});
    setApplicantIndex(0);
    dispatch(helpersActions.setEntityApplicantModal(''));
  };

  const handleApplicantGetSelectOption = (elem) => {
    if (isEmpty(elem)) return;
    setTimeout(() => {
      dispatch(change(formName, 'applicantClients', ''));
    });
    if (isEmpty(elem.address) && !elem.name && elem.id) {
      dispatch(helpersActions.setEntityApplicantModal('applicant'));
      dispatch(applicantsAsyncActions.getApplicantByIdAsync({ id: elem.id }));
      return;
    }
    dispatch(applicantsActions.mergeNewApplicantsListData([elem]));
    dispatch(change(formName, 'mailingAddress', ''));
  };

  const handleOwnerGetSelectOption = (elem) => {
    if (isEmpty(elem)) return;
    setTimeout(() => {
      dispatch(change(formName, 'applicantOwners', ''));
    });
    if (isEmpty(elem.address) && !elem.name && elem.id) {
      dispatch(helpersActions.setEntityApplicantModal('owner'));
      dispatch(applicantsAsyncActions.getApplicantByIdAsync({ id: elem.id }));
      return;
    }
    dispatch(applicantsActions.mergeNewOwnersListData([elem]));
  };

  const handleAuthorGetSelectOption = (elem) => {
    if (isEmpty(elem)) return;
    setTimeout(() => {
      dispatch(change(formName, 'applicantAuthors', ''));
    });
    if (isEmpty(elem.address) && !elem.name && elem.id) {
      dispatch(helpersActions.setEntityApplicantModal('author'));
      dispatch(applicantsAsyncActions.getApplicantByIdAsync({ id: elem.id }));
      return;
    }
    dispatch(applicantsActions.mergeNewAuthorsListData([elem]));
  };

  return (
    <>
      <CardApplicants
        fieldClient={fieldClient}
        formName={formName}
        fieldName="applicantClients"
        titleHeader="Applicants"
        pending={pending}
        name="applicant"
        list={newApplicantsList.toJS()}
        handleDelete={handleDeleteApplicant}
        handleEdit={handleEditApplicant}
        handleSelectOption={handleApplicantGetSelectOption}
        textButton="NEW APPLICANT"
        disabled={disabled}
      />

      {isModalOpenAddApplicant && (
        <AddApplicantModal
          editApplicant={editApplicant}
          client={fieldClient}
          handleSend={handleSendEditApplicant}
          handleClose={handleCloseApplicantModal}
          isProposal
        />
      )}

      {registrationNumberValue && (
        <CardApplicants
          fieldClient={fieldClient}
          formName={formName}
          fieldName="applicantOwners"
          titleHeader="Owners"
          pending={pending}
          name="owner"
          list={newOwnersList.toJS()}
          handleDelete={handleDeleteOwners}
          handleEdit={handleEditApplicant}
          handleSelectOption={handleOwnerGetSelectOption}
          textButton="NEW OWNER"
          disabled={disabled}
        />
      )}

      {entityName !== 'trademarks' && (
        <CardApplicants
          fieldClient={fieldClient}
          formName={formName}
          fieldName="applicantAuthors"
          titleHeader={entityName === 'industrial' ? 'Authors' : 'Inventors'}
          pending={pending}
          name="author"
          list={newAuthorsList.toJS()}
          handleDelete={handleDeleteAuthors}
          handleEdit={handleEditApplicant}
          handleSelectOption={handleAuthorGetSelectOption}
          textButton={entityName === 'industrial' ? 'NEW AUTHOR' : 'NEW INVENTOR'}
          disabled={disabled}
        />
      )}
    </>
  );
}
CardApplicantsOwnersAuthor.propTypes = {
  formName: PropTypes.string.isRequired,
  fieldClient: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  pending: PropTypes.bool.isRequired,
  newApplicant: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  entityApplicantModal: PropTypes.string.isRequired,
  newOwnersList: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  newApplicantsList: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  newAuthorsList: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  registrationNumberValue: PropTypes.string,
  isModalOpenAddApplicant: PropTypes.bool.isRequired,
  entityName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

CardApplicantsOwnersAuthor.defaultProps = {
  fieldClient: {},
  registrationNumberValue: '',
  disabled: false,
};

function mapStateToProps(state) {
  return {
    newApplicant: selectors.applicants.getNewApplicant(state),
    entityApplicantModal: selectors.helpers.entityApplicantModal(state),
    newApplicantsList: selectors.applicants.getApplicantNewList(state),
    newOwnersList: selectors.applicants.getOwnersNewList(state),
    newAuthorsList: selectors.applicants.getAuthorsNewList(state),
    fieldClient: selectors.form.getFormValues(state, 'ProposalAdd').get('clients'),
    registrationNumberValue: selectors.form.getFormValues(
      state, 'ProposalAdd',
    ).get('registrationNumber'),
    isModalOpenAddApplicant: selectors.helpers.isModalOpenAddApplicant(state),
  };
}

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.fieldClient, nextProps.fieldClient)
  && isEqual(prevProps.isModalOpenAddApplicant, nextProps.isModalOpenAddApplicant)
  && isEqual(prevProps.formName, nextProps.formName)
  && isEqual(prevProps.newOwnersList, nextProps.newOwnersList)
  && isEqual(prevProps.pending, nextProps.pending)
  && isEqual(prevProps.newApplicant, nextProps.newApplicant)
  && isEqual(prevProps.entityApplicantModal, nextProps.entityApplicantModal)
  && isEqual(prevProps.newOwnersList, nextProps.newOwnersList)
  && isEqual(prevProps.newAuthorsList, nextProps.newAuthorsList)
  && isEqual(prevProps.registrationNumberValue, nextProps.registrationNumberValue)
  && isEqual(prevProps.entityName, nextProps.entityName)
  && isEqual(prevProps.disabled, nextProps.disabled)
  && isEqual(prevProps.newApplicantsList, nextProps.newApplicantsList);
}

export default compose(
  connect(mapStateToProps, null),
)(memo(CardApplicantsOwnersAuthor, areEqual));
