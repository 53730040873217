// Helpers
import actionCreator from '../../_helpers/actionCreator';
// Types
import types from './types';

const actions = Object.freeze({
  setFilters(data) {
    return actionCreator(types.SET_DASHBOARD_FILTERS, data);
  },
  setReload(data) {
    return actionCreator(types.SET_DASHBOARD_RELOAD, data);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_DASHBOARD_INITIAL_STATE, initialState);
  },
  setIsOpenFilters(data) {
    return actionCreator(types.SET_DASHBOARD_IS_OPEN_FILTERS, data);
  },
  setPending(data) {
    return actionCreator(types.SET_DASHBOARD_PENDING, data);
  },
  setActionInCalendar(data) {
    return actionCreator(types.SET_DASHBOARD_ACTION_IN_CALENDAR, data);
  },
  setObjects(data) {
    return actionCreator(types.SET_DASHBOARD_OBJECTS, data);
  },
  setObjectsByClient(data) {
    return actionCreator(types.SET_DASHBOARD_OBJECTS_BY_CLIENT, data);
  },
  setObjectsByCountries(data) {
    return actionCreator(types.SET_DASHBOARD_OBJECTS_BY_COUNTRIES, data);
  },
  setObjectsByStatus(data) {
    return actionCreator(types.SET_DASHBOARD_OBJECTS_BY_STATUS, data);
  },
  setObjectsByCountriesAndStatus(data) {
    return actionCreator(types.SET_DASHBOARD_OBJECTS_BY_COUNTRIES_STATUS, data);
  },
});

export default actions;
