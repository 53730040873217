export const fieldsAutocomplete = Object.freeze({
  responseDocument: 'responseDocument',
  template: 'template',
  originalClient: 'original.client',
  originalIndustrial: 'original.industrial',
  originalUtility: 'original.utility',
  originalTrademarks: 'original.trademarks',
  originalInventions: 'original.inventions',
  industrial: 'industrial',
  utility: 'utility',
  trademarks: 'trademarks',
  inventions: 'inventions',
  orders: 'orders',
  clients: 'clients',
});
