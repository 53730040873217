// core
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import moment from 'moment';
import { push } from 'connected-react-router';

import './jvectormap.css';
import '@fullcalendar/daygrid/main.css';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

// lodash
import {
  map, max, min, round, sumBy, find,
  reduce, toUpper, filter, keys, values,
} from 'lodash';

// ui
import {
  Grid, Card as MuiCard,
  Typography, CardHeader, IconButton, CardContent as MuiCardContent,
} from '@material-ui/core';
// import { spacing } from '@material-ui/system';
import {
  green, red, orange, blue, brown, lime, teal, yellow, amber, indigo,
} from '@material-ui/core/colors';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import {
  Chart as ChartJS, ArcElement, Tooltip, Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from 'chart.js';
import { Doughnut, Bar } from 'react-chartjs-2';

import { VectorMap } from 'react-jvectormap';

// actions
import actions from '../../engine/core/dashboard/action';
import pendingActions from '../../engine/core/pendingActions/table/action';
import pendingAction from '../../engine/core/pendingActions/action';
import clientsAsyncActions from '../../engine/core/company/clients/table/saga/asyncAction';
import clientsActions from '../../engine/core/company/clients/table/action';
import documentsOutAction from '../../engine/core/tradeMarks/proposal/documents/out/action';
import documentsInAction from '../../engine/core/tradeMarks/proposal/documents/input/action';

// selectors
import selectors from '../../engine/config/selectors';

// routes
import { pageLinks } from '../../routes';
import accessList from '../../engine/config/accessList';
import { useAccessList } from '../../ui/_hooks/useAccessList';
import { countryOptions } from '../../ui/_helpers/country';

// parts
import DxVirtualTable from './DxVirtualTable';
import DashboardFilters from './DashboardFilters';
import { useDashboard } from './_hooks/useDashboard';
import Loader from '../../components/Loader';
import DocViewerModal from '../../components/DocViewerModal/DocViewerModal';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ArcElement,
  Tooltip,
  Legend,
);

// styles
const FullCalendarWrapper = styled.div``;

const Card = styled(MuiCard)`
  height: 400px;
  margin-bottom: 24px;
`;

const CardContent = styled(MuiCardContent)`
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(2)}px;
  }
`;

const CardContentTable = styled(MuiCardContent)`
  padding: 0;
  height: 344px;
  &:last-child {
    padding-bottom: 0;
  }
`;

const CardHeaderMap = styled(CardHeader)`
  padding-bottom: 0;
`;

const ChartWrapper = styled.div`
  height: 324px;
  position: relative;
`;

const BarWrapper = styled.div`
  height: 324px;
  width: 100%;
`;

const DoughnutInner = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -50px;
  text-align: center;
  z-index: 0;
`;

const MapContainer = styled.div`
  height: 344px;
`;

function Dashboard(props) {
  const {
    pendingActionsData, clientsData, pendingTableActions, countriesAndStatus,
    pendingDashboard, numberActionInCalendar, numberObjects, numberObjectsByClient,
    numberObjectsByCountries, numberObjectsByStatus, objectStatuses,
    documentsInData, pendingDocIn, pendingDocOut, documentsOutData, isModalOpenDocViewer,
    pendingPreviewFileById,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useDashboard();
  const accessClientsList = useAccessList(accessList.clients_list_get);
  const accessStatusesList = useAccessList(accessList.entity_statuses_get);

  useEffect(() => function cleanup() {
    dispatch(actions.setObjects({}));
    dispatch(actions.setObjectsByCountriesAndStatus({}));
    dispatch(actions.setActionInCalendar([]));
    dispatch(actions.setObjectsByClient([]));
    dispatch(actions.setObjectsByCountries([]));
    dispatch(actions.setObjectsByStatus([]));
    const emptyData = { items: [], pending: false, totalCount: 0 };
    dispatch(clientsActions.setClientsData(emptyData));
    dispatch(pendingActions.setPendingActionsData(emptyData));
    dispatch(documentsOutAction.setProposalDocumentsOutData(emptyData));
    dispatch(documentsInAction.setProposalDocumentsInData(emptyData));
  }, [dispatch]);

  useEffect(() => {
    if (accessClientsList) {
      dispatch(clientsAsyncActions.getListAsync({ limit: 100, page: 1 }));
    }
  }, [dispatch, accessClientsList]);

  const dataCalendar = useMemo(() => {
    const thisMonth = filter(numberActionInCalendar.toJS(), (item) => moment(item.date).isSame(moment(), 'month'));
    const nextMonth = filter(numberActionInCalendar.toJS(), (item) => moment(item.date).isSame(moment().add(1, 'month').format(), 'month'));
    return { thisMonth, nextMonth };
  }, [numberActionInCalendar]);

  const getBackgroundColor = (value, array) => {
    const colors = [blue[50], blue[100], blue[200], blue[300], blue[400],
      blue[500], blue[600], blue[700], blue[800], blue[900]];
    const maxArray = max(map(array, 'count'));
    const minArray = min(map(array, 'count'));
    const getIndex = round(((value - minArray) * 9) / (maxArray - minArray));
    return ({
      backgroundColor: colors[getIndex || 0],
      borderColor: colors[getIndex || 0],
    });
  };

  const getThisMonth = map(dataCalendar.thisMonth, (item) => ({
    start: item.date,
    end: item.date,
    display: 'background',
    value: item.count,
    ...getBackgroundColor(item.count, dataCalendar.thisMonth),
  }));

  const getNextMonth = map(dataCalendar.nextMonth, (item) => ({
    start: item.date,
    end: item.date,
    display: 'background',
    value: item.count,
    ...getBackgroundColor(item.count, dataCalendar.nextMonth),
  }));

  const onClickCalendar = (value, array) => {
    if (find(array, (elem) => elem.start === value.dateStr)) {
      dispatch(push(pageLinks.actions.table));
      dispatch(pendingAction.setDisableReload(true));
      dispatch(pendingActions.setFilters([
        {
          columnName: 'finishAt',
          value: {
            endDate: value.date,
            startDate: value.date,
            key: 'selection',
          },
        },
      ]));
    }
  };

  const dataNumberObjects = useMemo(() => {
    const data = {
      labels: map(numberObjects.toJS().entity, (item) => t(item)),
      datasets: [
        {
          data: numberObjects.toJS().values || [],
          backgroundColor: [blue[500], red[500], orange[500], green[500]],
          borderWidth: 3,
        },
      ],
    };
    const sum = sumBy(numberObjects.toJS().values || [], (item) => Number(item));
    return { data, sum };
  }, [numberObjects, t]);

  const dataNumberObjectsByStatus = useMemo(() => {
    const data = {
      labels: map(numberObjectsByStatus.toJS(), (item) => {
        const findElem = find(objectStatuses.toJS(), { id: Number(item.status) });
        return t(findElem?.name);
      }),
      datasets: [
        {
          data: map(numberObjectsByStatus.toJS(), 'count'),
          backgroundColor: [
            blue[500], red[500], orange[500], green[500], brown[500],
            lime[500], teal[500], yellow[500], amber[500], indigo[500],
          ],
          borderWidth: 2,
        },
      ],
    };
    const sum = sumBy(numberObjectsByStatus.toJS() || [], (item) => Number(item.count));
    return { data, sum };
  }, [numberObjectsByStatus, t, objectStatuses]);

  const dataNumberObjectsByClient = useMemo(() => {
    const data = {
      labels: map(numberObjectsByClient.toJS(), (item) => {
        const findElem = find(clientsData.toJS().items, { id: Number(item.id) });
        return findElem?.name || item.id;
      }),
      datasets: [
        {
          data: map(numberObjectsByClient.toJS(), 'count'),
          backgroundColor: [
            blue[500], red[500], orange[500], green[500], brown[500],
            lime[500], teal[500], yellow[500], amber[500], indigo[500],
          ],
          borderWidth: 2,
        },
      ],
    };
    const sum = sumBy(numberObjectsByClient.toJS() || [], (item) => Number(item.count));
    return { data, sum };
  }, [numberObjectsByClient, clientsData]);

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
        align: 'start',
        maxHeight: 110,
        labels: { boxWidth: 25 },
      },
    },
    cutout: 80,
  };

  const columnsActions = [
    { name: 'id', title: t('ID') },
    { name: 'title', title: t('Title'), customField: 'titleAction' },
    { name: 'status', title: t('Status'), customField: 'statusAction' },
    { name: 'finishAt', title: t('Finish At'), customField: 'finishAtAction' },
  ];

  const columnsDocs = [
    { name: 'view', title: t('File') },
    { name: 'titleDocDashboard', title: t('Title') },
    { name: 'documentClassifier', title: t('Classification') },
    { name: 'inNumber', title: t('In number') },
    { name: 'outNumber', title: t('Out number') },
    { name: 'documentDate', title: t('Date') },
  ];

  const tableColumnExtensions = [
    { columnName: 'id', width: 50 },
  ];
  const tableColumnExtensionsDoc = [
    { columnName: 'view', width: 60 },
  ];

  const dataCountriesAndStatus = useMemo(() => {
    const arrayColors = [blue[900], blue[800], blue[700], blue[600], blue[500],
      blue[400], blue[300], blue[200], blue[100], blue[50]];
    const data = {
      labels: map(keys(countriesAndStatus.toJS()), (item) => {
        const findElem = find(countryOptions, { abbreviation: toUpper(item) });
        return findElem?.label || toUpper(item);
      }),
      datasets: map(objectStatuses.toJS(), (status, i) => {
        const getValues = map(values(countriesAndStatus.toJS()), (item) => item[status.id] || 0);

        return ({
          label: t(status.name),
          backgroundColor: arrayColors[i],
          data: getValues,
          barPercentage: 0.4,
          categoryPercentage: 0.5,
        });
      }),
    };
    return data;
  }, [countriesAndStatus, t, objectStatuses]);

  const optionsBar = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        // display: false,
        labels: { boxWidth: 20 },
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
        stacked: true,
        ticks: {
          stepSize: 20,
          fontColor: blue[600],
        },
      },
      x: {
        stacked: true,
        grid: {
          color: 'transparent',
        },
        ticks: {
          fontColor: blue[600],
        },
      },
    },
  };
  const valuesForMap = useMemo(() => (
    reduce(numberObjectsByCountries.toJS(), (acc, item) => ({
      ...acc,
      ...Number(item.count) ? { [toUpper(item.code)]: item.count } : {},
    }), {})
  ), [numberObjectsByCountries]);

  const optionsMap = {
    map: 'world_mill',
    backgroundColor: 'transparent',
    regionStyle: {
      initial: {
        fill: '#e4e4e4',
        'fill-opacity': 0.9,
        stroke: 'none',
        'stroke-width': 0,
        'stroke-opacity': 0,
      },
    },
    containerStyle: {
      width: '100%',
      height: '100%',
    },
    zoomOnScroll: false,
    regionsSelectable: false,
    onRegionTipShow: (e, el, code) => el.html(`${el.html()} - ${valuesForMap[code] || 0}`),
    series: {
      regions: [
        {
          values: valuesForMap,
          scale: [blue[100], blue[900]],
          normalizeFunction: 'polynomial',
        },
      ],
    },
  };

  return (
    <>
      <Helmet title={t('Dashboard')} />

      <DashboardFilters />

      {isModalOpenDocViewer && <DocViewerModal />}
      <Grid container spacing={6}>
        <Grid item xs={12} lg={4}>
          <Card mb={6}>
            <CardHeader title={t('Number of objects')} />

            <CardContent>
              <ChartWrapper>
                {pendingDashboard ? (
                  <Loader position />
                ) : (
                  <>
                    <DoughnutInner variant="h4">
                      <Typography variant="h4">{dataNumberObjects.sum}</Typography>
                      <Typography variant="h6">{t('the total number of')}</Typography>
                    </DoughnutInner>
                    <Doughnut data={dataNumberObjects.data} options={options} />
                  </>
                )}
              </ChartWrapper>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} lg={8}>
          <Card mb={6}>
            <CardHeader title={t('My deadlines')} />
            <CardContentTable>
              <DxVirtualTable
                columns={columnsActions}
                rows={pendingActionsData.toJS().items}
                tableColumnExtensions={tableColumnExtensions}
                isLoading={pendingTableActions}
              />
            </CardContentTable>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12} lg={8}>
          <Card mb={6}>
            <CardHeader title={t('Input documents')} />
            <CardContentTable>
              <DxVirtualTable
                columns={columnsDocs}
                rows={documentsInData.toJS().items}
                tableColumnExtensions={tableColumnExtensionsDoc}
                isLoading={pendingDocIn || pendingPreviewFileById}
              />
            </CardContentTable>
          </Card>
        </Grid>

        <Grid item xs={12} lg={4}>
          <Card mb={6}>
            <CardContent>
              <FullCalendarWrapper>
                <FullCalendar
                  initialView="dayGridMonth"
                  events={getThisMonth}
                  // showNonCurrentDates={false}
                  editable
                  locale="uk"
                  firstDay={1}
                  headerToolbar={{
                    left: '',
                    center: 'title',
                    right: '',
                  }}
                  plugins={[dayGridPlugin, interactionPlugin]}
                  dateClick={(event) => onClickCalendar(event, getThisMonth)}
                />
              </FullCalendarWrapper>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12} lg={8}>
          <Card mb={6}>
            <CardHeader title={t('Output documents')} />
            <CardContentTable>
              <DxVirtualTable
                columns={columnsDocs}
                rows={documentsOutData.toJS().items}
                tableColumnExtensions={tableColumnExtensionsDoc}
                isLoading={pendingDocOut || pendingPreviewFileById}
              />
            </CardContentTable>
          </Card>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Card mb={6}>
            <CardContent>
              <FullCalendarWrapper>
                <FullCalendar
                  initialView="dayGridMonth"
                  initialDate={moment().add(1, 'month').format('YYYY-MM-DD')}
                  events={getNextMonth}
                  // showNonCurrentDates={false}
                  editable
                  locale="uk"
                  firstDay={1}
                  headerToolbar={{
                    left: '',
                    center: 'title',
                    right: '',
                  }}
                  plugins={[dayGridPlugin]}
                />
              </FullCalendarWrapper>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {accessStatusesList && (
        <Grid container spacing={6}>
          <Grid item xs={12} lg={8}>
            <Card mb={6}>
              <CardHeader title={t('Status of objects in different countries')} />
              <CardContent>
                <BarWrapper>
                  <Bar data={dataCountriesAndStatus} options={optionsBar} />
                </BarWrapper>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Card mb={6}>
              <CardHeader
                action={<IconButton aria-label="settings"><MoreVertIcon /></IconButton>}
                title={t('Number of objects by status')}
              />

              <CardContent>
                <ChartWrapper>
                  {pendingDashboard ? (
                    <Loader position />
                  ) : (
                    <>
                      <DoughnutInner variant="h4">
                        <Typography variant="h4">{dataNumberObjectsByStatus.sum}</Typography>
                        {/* <Typography variant="h6">{t('the total number of')}</Typography> */}
                      </DoughnutInner>
                      <Doughnut data={dataNumberObjectsByStatus.data} options={options} />
                    </>
                  )}
                </ChartWrapper>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}

      <Grid container spacing={6}>
        {accessClientsList && (
          <Grid item xs={12} lg={4}>
            <Card mb={6}>
              <CardHeader title={t('Customers with the largest number of objects')} />

              <CardContent>
                <ChartWrapper>
                  {pendingDashboard ? (
                    <Loader position />
                  ) : (
                    <>
                      <DoughnutInner variant="h4" style={{ top: '40%' }}>
                        <Typography variant="h4">{dataNumberObjectsByClient.sum}</Typography>
                        {/* <Typography variant="h6">{t('the total number of')}</Typography> */}
                      </DoughnutInner>
                      <Doughnut data={dataNumberObjectsByClient.data} options={options} />
                    </>
                  )}
                </ChartWrapper>
              </CardContent>
            </Card>
          </Grid>
        )}
        <Grid item xs={12} lg={8}>
          <Card mb={4}>
            <CardHeaderMap title={t('Map')} />

            <CardContent>
              <MapContainer>
                <VectorMap {...optionsMap} />
              </MapContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

Dashboard.displayName = 'Dashboard';

Dashboard.propTypes = {
  pendingActionsData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  clientsData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  numberActionInCalendar: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  numberObjects: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  numberObjectsByClient: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  numberObjectsByCountries: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  numberObjectsByStatus: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  countriesAndStatus: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingDashboard: PropTypes.bool.isRequired,
  pendingTableActions: PropTypes.bool.isRequired,
  objectStatuses: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingDocIn: PropTypes.bool.isRequired,
  documentsInData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingDocOut: PropTypes.bool.isRequired,
  documentsOutData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  isModalOpenDocViewer: PropTypes.bool.isRequired,
  pendingPreviewFileById: PropTypes.bool.isRequired,
};

Dashboard.defaultProps = {};

function mapStateToProps(state) {
  return {
    pendingActionsData: selectors.pendingActionsTable.pendingActionsData(state),
    pendingTableActions: selectors.pendingActionsTable.pending(state),
    objectStatuses: selectors.helpers.getObjectStatuses(state),
    clientsData: selectors.clientsTable.clientsData(state),
    pendingDashboard: selectors.dashboard.pending(state),
    numberActionInCalendar: selectors.dashboard.numberActionInCalendar(state),
    numberObjects: selectors.dashboard.numberObjects(state),
    numberObjectsByClient: selectors.dashboard.numberObjectsByClient(state),
    numberObjectsByCountries: selectors.dashboard.numberObjectsByCountries(state),
    numberObjectsByStatus: selectors.dashboard.numberObjectsByStatus(state),
    countriesAndStatus: selectors.dashboard.numberObjectsByCountriesAndStatus(state),
    documentsInData: selectors.proposalDocumentsIn.proposalDocumentsInData(state),
    pendingDocIn: selectors.proposalDocumentsIn.pending(state),
    documentsOutData: selectors.proposalDocumentsOut.proposalDocumentsOutData(state),
    pendingDocOut: selectors.proposalDocumentsOut.pending(state),
    isModalOpenDocViewer: selectors.helpers.isModalOpenDocViewer(state),
    pendingPreviewFileById: selectors.helpers.pendingPreviewFileById(state),
  };
}

export default compose(
  connect(mapStateToProps, null),
)(Dashboard);
