// Core
import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { connect, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// Lodash
import {
  map, isEmpty,
} from 'lodash';

// UI
import {
  Typography,
  Divider as MuiDivider,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';

// parts
import DxTable from '../../../../ui/Table/DxTable';
import ConfirmModal from '../../../../components/ConfirmModal';
// import TableExtraFilter from '../../../../ui/Table/components/TableExtraFilter/TableExtraFilter';

// actions
import pendingTableActions from '../../../../engine/core/pendingActions/table/action';
import pendingActionsAsync from '../../../../engine/core/pendingActions/saga/asyncAction';
import helpersActions from '../../../../engine/core/helpers/action';
import pendingActions from '../../../../engine/core/pendingActions/action';
import helpersAsyncActions from '../../../../engine/core/helpers/saga/asyncAction';
import employeesTableAsyncActions from '../../../../engine/core/company/employees/table/saga/asyncAction';
import employeesTableActions from '../../../../engine/core/company/employees/table/action';

// config
import selectors from '../../../../engine/config/selectors';

// hooks
import { useNotificationsTasks } from './_hooks/useNotificationsTasks';

import { listTablesName, listEntityName } from '../../../../engine/config/listTablesName';
import { useAccessList } from '../../../../ui/_hooks/useAccessList';
import accessList from '../../../../engine/config/accessList';

const Divider = styled(MuiDivider)(spacing);

const tableName = listTablesName.notificationsTasks;
const entityName = listEntityName.pendingActions;

function NotificationsTasks(props) {
  const {
    pendingActionsData, totalCount, pending, pathname,
    currentPage, filters, sorting, disableReload, isModalOpen,
    pageSize, pendingDeletePendingAction, checkboxes,
    columnWidths, columnOrder, hiddenColumnNames,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useNotificationsTasks();
  const accessEmployeesList = useAccessList(accessList.employees_list_get);

  const columns = [
    { name: 'id', title: t('ID') },
    { name: 'title', title: t('Title'), customField: 'titleAction' },
    { name: 'status', title: t('Status'), customField: 'statusAction' },
    { name: 'type.id', title: t('Type'), customField: 'typeAction' },
    { name: 'users', title: t('Person'), customField: 'usersAction' },
    { name: 'finishAt', title: t('Finish At') },
  ];

  const editingStateColumnExtensionsCosts = [
    { columnName: 'id', editingEnabled: false },
    { columnName: 'title', editingEnabled: false },
    { columnName: 'status' },
    { columnName: 'type.id' },
    { columnName: 'users' },
    { columnName: 'finishAt', editingEnabled: false },
  ];

  const tableColumnExtensions = [
    { columnName: 'id', width: 50 },
    { columnName: 'title', width: '35rem' },
    { columnName: 'status', width: '10rem' },
    { columnName: 'type.id', width: '10rem' },
    { columnName: 'users', width: '35rem' },
    { columnName: 'finishAt', width: '10rem' },
  ];

  useEffect(() => function cleanup() {
    if (disableReload) {
      dispatch(pendingActions.setDisableReload(false));
      dispatch(pendingTableActions.setClearFilters(pathname));
      dispatch(employeesTableActions.setEmployeesData({
        items: [],
        totalCount: 0,
        pending: false,
      }));
    }
  }, [dispatch, disableReload, pathname]);

  // Paging
  const onCurrentPage = (page) => dispatch(pendingTableActions.setCurrentPage(page));
  const onPageSize = (size) => dispatch(pendingTableActions.setPageSize(size));
  // Filtering
  const setFilters = (getFilters) => dispatch(pendingTableActions.setFilters(getFilters));
  // Sorting
  const onSortingChange = (getSorting) => dispatch(pendingTableActions.setSorting(getSorting));
  const onColumnWidthsChange = (getColumnWidths) => dispatch(
    pendingTableActions.setColumnWidths(getColumnWidths),
  );
  const onColumnOrderChange = (getColumnOrder) => dispatch(
    pendingTableActions.setColumnOrder(getColumnOrder),
  );
  const onHiddenColumnNamesChange = (getHiddenColumnNames) => dispatch(
    pendingTableActions.setHiddenColumnNames(getHiddenColumnNames),
  );

  useEffect(() => {
    if (accessEmployeesList) dispatch(employeesTableAsyncActions.getListAsync());
  }, [dispatch, accessEmployeesList]);

  const handleEditingChange = ({ changed }) => {
    const values = Object.values(changed)[0];
    const ids = Object.keys(changed);
    if (!isEmpty(values)) {
      dispatch(pendingTableActions.setPendingActionsData({ pending: true }));
      dispatch(helpersAsyncActions.putMultipleAsync({
        body: {
          entityName: 'pendingActions',
          entityIds: ids,
          fields: values,
        },
        callBack: () => {
          dispatch(pendingTableActions.setReload(true));
        },
      }));
    }
  };

  return (
    <>
      <Helmet title={t('View all')} />
      <Typography variant="h3" gutterBottom display="inline">
        {t('View all')}
      </Typography>

      <Divider my={6} />

      {/* <TableExtraFilter
        filters={filters}
        filtersAction={setFilters}
      /> */}
      <DxTable
        name="NotificationsTasks"
        isLoading={pending}
        columns={columns}
        rows={pendingActionsData.toJS().items}
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageSize={onPageSize}
        onCurrentPage={onCurrentPage}
        filtersAction={setFilters}
        filters={filters}
        // checkboxes ------------------------
        tableName={tableName}
        reloadTable={pendingTableActions.setReload}
        selection={checkboxes.toJS()}
        onSelectionChange={(checkboxSelect) => {
          dispatch(helpersActions.setCheckbox({
            tableName,
            checkboxes: checkboxSelect,
          }));
        }}
        // ------------------------
        entityName={entityName}
        sorting={sorting}
        onSortingChange={onSortingChange}
        isModalOpen={isModalOpen}
        editingExtensions={editingStateColumnExtensionsCosts}
        onEditingChange={handleEditingChange}
        tableColumnExtensions={!isEmpty(columnWidths) && columnWidths.length === columns.length
          ? columnWidths : tableColumnExtensions}
        onColumnWidthsChange={onColumnWidthsChange}
        columnOrder={!isEmpty(columnOrder) ? columnOrder : map(columns, 'name')}
        onColumnOrderChange={onColumnOrderChange}
        hiddenColumnNames={hiddenColumnNames}
        onHiddenColumnNamesChange={onHiddenColumnNamesChange}
        accessEdit={accessList.pendingActions_put}
        accessDelete={accessList.pendingActions_delete}
      />

      <ConfirmModal
        buttonSendText="DELETE"
        pending={pendingDeletePendingAction}
        handleSend={() => {
          dispatch(pendingActionsAsync.deletePendingActionAsync());
        }}
      />
    </>
  );
}

NotificationsTasks.displayName = 'NotificationsTasks';

NotificationsTasks.propTypes = {
  pendingActionsData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  totalCount: PropTypes.number.isRequired,
  pending: PropTypes.bool.isRequired,
  pendingDeletePendingAction: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  sorting: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  pageSize: PropTypes.number.isRequired,
  checkboxes: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  disableReload: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  pathname: PropTypes.string.isRequired,
  columnWidths: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  columnOrder: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  hiddenColumnNames: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
};

NotificationsTasks.defaultProps = {};

function mapStateToProps(state) {
  return {
    pendingActionsData: selectors.pendingActionsTable.pendingActionsData(state),
    pending: selectors.pendingActionsTable.pending(state),
    pendingDeletePendingAction: selectors.pendingActions.pendingDeletePendingAction(state),

    // Paging
    totalCount: selectors.pendingActionsTable.totalCount(state),
    currentPage: selectors.pendingActionsTable.currentPage(state),
    pageSize: selectors.pendingActionsTable.pageSize(state),

    // Filtering
    filters: selectors.pendingActionsTable.filters(state),

    // Sorting
    sorting: selectors.pendingActionsTable.sorting(state),

    // ColumnWidths
    columnWidths: selectors.pendingActionsTable.columnWidths(state),
    columnOrder: selectors.pendingActionsTable.columnOrder(state),
    hiddenColumnNames: selectors.pendingActionsTable.hiddenColumnNames(state),

    checkboxes: selectors.helpers.getCheckboxes(state, tableName),
    disableReload: selectors.pendingActions.disableReload(state),
    isModalOpen: selectors.pendingActions.isModalOpenAction(state),
    pathname: selectors.router.pathname(state),
  };
}

function mapDispatchToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(NotificationsTasks);
