// Core
import {
  all, call, takeEvery,
} from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetProposalWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callGetAutocompleteOptionsWorker,
  callGetRegistryByNumberWorker,
  callGetClassesWorker,
  callGetClassByIdWorker,
  callGetFindCompanyWorker,
  callPostProposalWorker,
  callPostMktuTemplateWorker,
  callGetMktuByIdWorker,
  callDeleteProposalWorker,
  callGetProposalByIdWorker,
  callPutProposalWorker,
  callPostCreateImageWorker,
  callPostAddDocumentsWorker,
  callGetNextNumberWorker,
  callPutProposalCloneByIdWorker,
} from './workers';
import types from '../types';

function* watchGetProposalCompany() {
  yield takeEvery(asyncTypes.GET_PROPOSAL_ASYNC, callGetProposalWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_PROPOSAL_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchGetAutocompleteOptions() {
  yield takeEvery(
    asyncTypes.GET_TRADE_MARKS_PROPOSAL_AUTOCOMPLETE_OPTIONS_ASYNC,
    callGetAutocompleteOptionsWorker,
  );
}
function* watchRegistryByNumberWorker() {
  yield takeEvery(asyncTypes.GET_REGISTRY_BY_NUMBER_ASYNC, callGetRegistryByNumberWorker);
}
function* watchGetClassesWorker() {
  yield takeEvery(asyncTypes.GET_GET_CLASSES_ASYNC, callGetClassesWorker);
}
function* watchGetClassByIdWorker() {
  yield takeEvery(asyncTypes.GET_CLASS_BY_ID_ASYNC, callGetClassByIdWorker);
}
function* watchGetFindCompanyWorker() {
  yield takeEvery(asyncTypes.GET_FIND_COMPANY_ASYNC, callGetFindCompanyWorker);
}
function* watchGetMktuWorkerById() {
  yield takeEvery(
    asyncTypes.GET_MKTU_BY_ID_PROPOSAL_ASYNC,
    callGetMktuByIdWorker,
  );
}
function* watchPostMktuTemplateWorker() {
  yield takeEvery(asyncTypes.POST_MKTU_TEMPLATE_ASYNC, callPostMktuTemplateWorker);
}
function* watchPostProposalWorker() {
  yield takeEvery(asyncTypes.POST_PROPOSAL_ASYNC, callPostProposalWorker);
}
function* watchDeleteProposalWorker() {
  yield takeEvery(asyncTypes.DELETE_PROPOSAL_ASYNC, callDeleteProposalWorker);
}
function* watchGetProposalByIdWorker() {
  yield takeEvery(asyncTypes.GET_TRADEMARKS_PROPOSAL_BY_ID_ASYNC, callGetProposalByIdWorker);
}
function* watchPutProposalWorker() {
  yield takeEvery(asyncTypes.PUT_PROPOSAL_ASYNC, callPutProposalWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_PROPOSAL_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_PROPOSAL_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_PROPOSAL_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_PROPOSAL_SORTING, callSetToLocalDataWorker);
}
function* watchSetColumnWidths() {
  yield takeEvery(types.SET_PROPOSAL_COLUMN_WIDTHS, callSetToLocalDataWorker);
}
function* watchSetColumnOrder() {
  yield takeEvery(types.SET_PROPOSAL_COLUMN_ORDER, callSetToLocalDataWorker);
}
function* watchSetHiddenColumnNames() {
  yield takeEvery(
    types.SET_PROPOSAL_HIDDEN_COLUMN_NAMES,
    callSetToLocalDataWorker,
  );
}
function* watchPostCreateImageWorker() {
  yield takeEvery(asyncTypes.POST_CREATE_IMAGE_ASYNC, callPostCreateImageWorker);
}
function* watchPostAddDocumentsWorker() {
  yield takeEvery(asyncTypes.POST_ADD_DOCUMENTS_ASYNC, callPostAddDocumentsWorker);
}

function* watchGetNextNumber() {
  yield takeEvery(asyncTypes.GET_TRADEMARKS_PROPOSAL_NEXT_NUMBER_ASYNC, callGetNextNumberWorker);
}

function* watchPutProposalCloneById() {
  yield takeEvery(asyncTypes.PUT_TRADEMARKS_PROPOSAL_CLONE_ASYNC, callPutProposalCloneByIdWorker);
}

export function* watchersProposal() {
  yield all([
    call(watchGetProposalCompany),
    call(watchSetCurrentPage),
    call(watchSetPageSize),
    call(watchSetFilters),
    call(watchSetSorting),
    call(watchSetColumnWidths),
    call(watchSetColumnOrder),
    call(watchSetHiddenColumnNames),
    call(watchGetInitialState),
    call(watchGetAutocompleteOptions),
    call(watchGetClassesWorker),
    call(watchGetClassByIdWorker),
    call(watchRegistryByNumberWorker),
    call(watchGetFindCompanyWorker),
    call(watchPostProposalWorker),
    call(watchGetMktuWorkerById),
    call(watchDeleteProposalWorker),
    call(watchPostMktuTemplateWorker),
    call(watchGetProposalByIdWorker),
    call(watchPutProposalWorker),
    call(watchPostCreateImageWorker),
    call(watchPostAddDocumentsWorker),
    call(watchGetNextNumber),
    call(watchPutProposalCloneById),
  ]);
}
