/**
 * Use to split arrays
 * @parameters array, size
 * array - any array
 * size - count of elements in a chunk
 * */

export const splitArrayToChunks = (array, size) => {
  const splitArray = [];
  const len = Math.ceil(array.length / size);
  for (let index = 0; index < len; index += 1) {
    splitArray[index] = array.slice((index * size), (index * size) + size);
  }
  return splitArray;
};
