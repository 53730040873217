// Core
import { all, call, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetTagsWorker,
  callDeleteTagWorker,
  callPostTagWorker,
  callGetTagByIdWorker,
  callPutTagByIdWorker,
} from './workers';

function* watchGetTags() {
  yield takeEvery(asyncTypes.GET_TAGS_ASYNC, callGetTagsWorker);
}

function* watchDeleteTag() {
  yield takeEvery(asyncTypes.DELETE_TAG_ASYNC, callDeleteTagWorker);
}

function* watchPostTag() {
  yield takeEvery(asyncTypes.POST_TAG_ASYNC, callPostTagWorker);
}

function* watchGetTagById() {
  yield takeEvery(asyncTypes.GET_TAG_BY_ID_ASYNC, callGetTagByIdWorker);
}

function* watchPutTagById() {
  yield takeEvery(asyncTypes.PUT_TAG_BY_ID_ASYNC, callPutTagByIdWorker);
}

export function* watchersTags() {
  yield all([
    call(watchGetTags),
    call(watchDeleteTag),
    call(watchPostTag),
    call(watchGetTagById),
    call(watchPutTagById),
  ]);
}
