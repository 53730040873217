// Core
import { call, put } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../config/api';
import { messageDataUpdated } from '../../../../config/globalConfig';

// actions
import action from '../../action';
import pendingActionsAction from '../../../pendingActions/action';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../ui/_helpers/setNotificationMessage';

// init
import i18n from '../../../../init/i18n';

export function* callPutMultipleWorker({ payload }) {
  const {
    setMultiple,
  } = action;
  const { body, callBack } = payload;
  yield put(setMultiple({
    pendingPut: true,
  }));

  const response = yield call(api.multiple.putMultiple, body);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      yield put(setSuccessMessage(i18n.t(messageDataUpdated), response.status.toString()));
      if (body.entityName === 'pendingActions') {
        yield put(pendingActionsAction.setDisableNotifications(false));
      }
      if (callBack) {
        callBack(body);
      }
    }
  }

  yield put(setMultiple({
    pendingPut: false,
  }));
}
