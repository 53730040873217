// Core
import { Map, List } from 'immutable';

// Instruments
import types from './types';
import globalStateKeys from '../../config/globalStateKeys';

export const initialState = Map({
  filters: Map(),
  reload: false,
  isOpenFilters: false,
  numberActionInCalendar: List(),
  numberObjects: Map(),
  numberObjectsByClient: List(),
  numberObjectsByCountries: List(),
  numberObjectsByStatus: List(),
  pending: false,
  numberObjectsByCountriesAndStatus: Map(),
});

export const stateKeys = Object.freeze({
  controller: 'dashboard',
  isOpenFilters: 'isOpenFilters',
  numberActionInCalendar: 'numberActionInCalendar',
  numberObjects: 'numberObjects',
  numberObjectsByClient: 'numberObjectsByClient',
  numberObjectsByCountries: 'numberObjectsByCountries',
  numberObjectsByStatus: 'numberObjectsByStatus',
  numberObjectsByCountriesAndStatus: 'numberObjectsByCountriesAndStatus',
});

export const dashboardReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_DASHBOARD_FILTERS: {
      return state.setIn([globalStateKeys.filters], Map(payload));
    }
    case types.SET_DASHBOARD_INITIAL_STATE: {
      const { filters = {} } = payload;
      const reload = true;
      return state
        .setIn([globalStateKeys.filters], Map(filters))
        .setIn([globalStateKeys.reload], reload);
    }
    case types.SET_DASHBOARD_RELOAD: {
      return state.setIn([globalStateKeys.reload], payload);
    }
    case types.SET_DASHBOARD_IS_OPEN_FILTERS: {
      return state.setIn([stateKeys.isOpenFilters], payload);
    }
    case types.SET_DASHBOARD_PENDING: {
      return state.setIn([globalStateKeys.pending], payload);
    }
    case types.SET_DASHBOARD_ACTION_IN_CALENDAR: {
      return state.setIn([stateKeys.numberActionInCalendar], List(payload));
    }
    case types.SET_DASHBOARD_OBJECTS: {
      return state.setIn([stateKeys.numberObjects], Map(payload));
    }
    case types.SET_DASHBOARD_OBJECTS_BY_CLIENT: {
      return state.setIn([stateKeys.numberObjectsByClient], List(payload));
    }
    case types.SET_DASHBOARD_OBJECTS_BY_COUNTRIES: {
      return state.setIn([stateKeys.numberObjectsByCountries], List(payload));
    }
    case types.SET_DASHBOARD_OBJECTS_BY_STATUS: {
      return state.setIn([stateKeys.numberObjectsByStatus], List(payload));
    }
    case types.SET_DASHBOARD_OBJECTS_BY_COUNTRIES_STATUS: {
      return state.setIn([stateKeys.numberObjectsByCountriesAndStatus], Map(payload));
    }
    default:
      return state;
  }
};
