// Helpers
import actionCreator from '../../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const currencyRateActionAsync = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_CURRENCY_RATES_ASYNC, params);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_CURRENCY_RATES_INITIAL_STATE_ASYNC, path);
  },
  postCurrencyRateAsync(data) {
    return actionCreator(asyncTypes.POST_CURRENCY_RATE_ASYNC, data);
  },
  putCurrencyRateByIdAsync(data) {
    return actionCreator(asyncTypes.PUT_CURRENCY_RATE_BY_ID_ASYNC, data);
  },
  deleteCurrencyRateAsync() {
    return actionCreator(asyncTypes.DELETE_CURRENCY_RATE_ASYNC);
  },
  getAutocompleteOptionsAsync(params) {
    return actionCreator(asyncTypes.GET_CURRENCY_RATES_AUTOCOMPLETE_OPTIONS_ASYNC, params);
  },
});

export default currencyRateActionAsync;
