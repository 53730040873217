// Core
import { all, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetTaxesWorker,
  callGetTaxByIdWorker,
  callPutTaxByIdWorker,
  callPostTaxWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callDeleteTaxWorker,
} from './workers';
import types from '../types';

function* watchGetTaxes() {
  yield takeEvery(asyncTypes.GET_TAXES_ASYNC, callGetTaxesWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_TAXES_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchGetTaxById() {
  yield takeEvery(asyncTypes.GET_TAX_BY_ID_ASYNC, callGetTaxByIdWorker);
}
function* watchPostTax() {
  yield takeEvery(asyncTypes.POST_TAX_ASYNC, callPostTaxWorker);
}
function* watchPutTaxById() {
  yield takeEvery(asyncTypes.PUT_TAX_BY_ID_ASYNC, callPutTaxByIdWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_TAXES_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_TAXES_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_TAXES_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_TAXES_SORTING, callSetToLocalDataWorker);
}
function* watchSetColumnWidths() {
  yield takeEvery(types.SET_TAXES_COLUMN_WIDTHS, callSetToLocalDataWorker);
}
function* watchSetColumnOrder() {
  yield takeEvery(types.SET_TAXES_COLUMN_ORDER, callSetToLocalDataWorker);
}
function* watchSetHiddenColumnNames() {
  yield takeEvery(types.SET_TAXES_HIDDEN_COLUMN_NAMES, callSetToLocalDataWorker);
}
function* watchDeleteTax() {
  yield takeEvery(asyncTypes.DELETE_TAX_ASYNC, callDeleteTaxWorker);
}

export function* watchersTaxes() {
  yield all([
    watchGetTaxes(),
    watchGetInitialState(),
    watchGetTaxById(),
    watchPostTax(),
    watchPutTaxById(),
    watchSetCurrentPage(),
    watchSetPageSize(),
    watchSetFilters(),
    watchSetSorting(),
    watchSetColumnWidths(),
    watchSetColumnOrder(),
    watchSetHiddenColumnNames(),
    watchDeleteTax(),
  ]);
}
