// Core
import { all, call, takeEvery } from 'redux-saga/effects';
// Types
import asyncTypes from './asyncTypes';
// Workers
import { callGetFieldsSettingsWorker, callGetFieldsEntityWorker } from './workers';

function* watchGetFieldsSettingsWorker() {
  yield takeEvery(asyncTypes.GET_FIELDS_SETTINGS_ASYNC, callGetFieldsSettingsWorker);
}
function* watchGetFieldsEntityWorker() {
  yield takeEvery(asyncTypes.GET_FIELDS_ENTITY_ASYNC, callGetFieldsEntityWorker);
}

export function* watchersFieldsSetting() {
  yield all([
    call(watchGetFieldsSettingsWorker),
    call(watchGetFieldsEntityWorker),
  ]);
}
