// Core
import {
  required, email, length, confirmation,
  format, addValidator, numericality,
} from 'redux-form-validators';

const passwordRegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[-\w!@#$%^&*()"№;:?_+|~=`{}'[\]<>,.\\/]{8,}$/;

const editPasswordValidator = addValidator({
  defaultMessage: "Обов'язково латинські букви різного регістра + цифри",
  validator: (options, value) => (
    value && passwordRegExp.test(value)
  ),
});

const emptyFieldValidator = addValidator({
  defaultMessage: 'Поле не заповнено',
  validator: (options, value) => value.length,
});

const selectValidator = addValidator({
  defaultMessage: "Це обов'язкове поле",
  validator: (options, value) => (
    value.toJS ? value.size > 0 : value.length > 0
  ),
});

const notRequiredPhoneValidator = addValidator({
  defaultMessage: 'Невалідний номер телефону',
  validator: (options, value) => value && !length(options)(value),
});

const notRequiredEmailValidator = addValidator({
  defaultMessage: 'Недійсна email адреса',
  validator: (options, value) => value && !email(options)(value),
});

export const validators = Object.freeze({
  required: required({ msg: "Це обов'язкове поле" }),
  email: email({ msg: 'Недійсна email адреса' }),
  onlyNumber: numericality({ msg: 'Введіть лише цифри' }),
  empty: emptyFieldValidator({ msg: 'Поле не заповнено' }),
  maxLength10: length({ max: 10, msg: 'Ліміт 10 символів' }),
  maxLength50: length({ max: 50, msg: 'Ліміт 50 символів' }),
  maxLength150: length({ max: 150, msg: 'Ліміт 150 символів' }),
  minLength8: length({ min: 8, msg: 'Мінімум 8 символів' }),
  phone: length({ min: 12, max: 12, msg: 'Невалідний номер телефону' }),
  password: format({ with: passwordRegExp, msg: "Обов'язково латинські букви різного регістра + цифри" }),
  confirmField: (field) => confirmation({ field, msg: 'Паролі повинні співпадати' }),
  editPasswordValidator: editPasswordValidator(),
  selectValidator: selectValidator(),
  notRequiredPhone: notRequiredPhoneValidator({ min: 12, max: 12, msg: 'Невалідний номер телефону' }),
  notRequiredEmail: notRequiredEmailValidator({ msg: 'Недійсна email адреса' }),
});
