// Core
import { apply, put, select } from 'redux-saga/effects';
import { initialize, change } from 'redux-form/lib/immutable';

// lodash
import {
  isNil, isArray, isEmpty,
  has, values, find, map,
} from 'lodash';

// config
import { api } from '../../../../../config/api';
import selectors from '../../../../../config/selectors';

// helpers
import { setErrorMessage } from '../../../../../../ui/_helpers/setNotificationMessage';
import { formFields } from '../../../../../../pages/TradeMarks/Proposal/helper/form';
import { countryOptions } from '../../../../../../ui/_helpers/country';
import { getObjectReverseFormatDate } from '../../../../../_helpers/formatterDate';

// actions
import actions from '../../action';
import helpersActions from '../../../../helpers/action';
import uploadFileActions from '../../../../uploadFile/action';
import actionsApplicants from '../../../../applicants/action';
import employeesAsyncActions from '../../../../company/employees/saga/asyncAction';

export function* callGetProposalByIdWorker({ payload: params }) {
  const permissionSign = yield select(selectors.helpers.notPendingGetById);
  const documentType = yield select(selectors.helpers.documentsType);

  if (!permissionSign) yield put(actions.setPendingGetProposalById(true));

  const response = yield apply(api, api.trademarks.getProposalById, [params]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data: responseData } = response;
    if (has(responseData, 'error')) {
      yield put(setErrorMessage(responseData.error, response.status.toString()));
    } else {
      const {
        data, title, signs = [], status, documents, company, publication, mktu,
      } = responseData;
      const {
        postalAddress, additionalApplicationData, logo, applicants,
        holders, standardCharacters, priority, idAttorney, attorney,
      } = data;
      const formData = {
        ...responseData,
        data: {
          ...data,
          standardCharacters: `${standardCharacters}`,
          postalAddress: {
            ...postalAddress,
            country: find(countryOptions, { abbreviation: postalAddress?.country }),
          },
          additionalApplicationData,
          logo,
          priority: {
            ...priority,
            type: !isNil(priority?.type) ? priority?.type : 'noPriority',
          },
          attorney: {
            ...attorney,
            ...!isEmpty(attorney?.postalAddress) ? {
              postalAddress: {
                ...attorney.postalAddress,
                country: find(countryOptions, { abbreviation: attorney.postalAddress?.country }),
              },
            } : {},
          },
        },
        publication: {
          ...publication,
          ...getObjectReverseFormatDate(responseData.publication?.date, 'date'),
        },
        [formFields.title]: title,
        [formFields.status]: status,
        ...getObjectReverseFormatDate(responseData.applicationDate, formFields.applicationDate),
        ...getObjectReverseFormatDate(responseData.expiryDate, formFields.expiryDate),
        ...getObjectReverseFormatDate(responseData.registrationDate, formFields.registrationDate),
        ...getObjectReverseFormatDate(
          responseData.initialPublication,
          formFields.initialPublication,
        ),
        ...!isEmpty(company) ? {
          company: company.id,
        } : {},
        ...!isEmpty(values(documents)) && !isEmpty(documentType.toJS()) ? {
          documents: map(values(documents), (file) => ({
            type: find(documentType.toJS(), { id: file.type.id }) || file.type,
          })),
        } : {
          documents: [],
        },
      };
      delete formData.data.idAttorney;
      yield put(initialize('ProposalAdd', formData, formFields));
      if (!mktu) {
        yield put(change('ProposalAdd', 'langMktu', 'ua'));
      }
      yield put(uploadFileActions.setAllUploadFiles(values(documents)));
      if (isArray(holders)) {
        yield put(actionsApplicants.setNewOwnersListData(holders));
      }
      yield put(helpersActions.setSignList(signs));
      yield put(actionsApplicants.setNewApplicantsListData(applicants));
      if (idAttorney) {
        yield put(employeesAsyncActions.getResponsibleEmployeeByIdAsync(idAttorney));
      }
      yield put(actions.setProposalDataById(formData));
    }
  }
  yield put(helpersActions.setNotPendingGetById(false));
  yield put(actions.setPendingGetProposalById(false));
}
