export default Object.freeze({
  SET_INTEGRATIONS_DATA: 'SET_INTEGRATIONS_DATA',
  SET_INTEGRATION_BY_ID: 'SET_INTEGRATION_BY_ID',
  SET_INTEGRATIONS_RELOAD: 'SET_INTEGRATIONS_RELOAD',
  SET_INTEGRATIONS_CURRENT_PAGE: 'SET_INTEGRATIONS_CURRENT_PAGE',
  SET_INTEGRATIONS_FILTERS: 'SET_INTEGRATIONS_FILTERS',
  SET_INTEGRATIONS_SORTING: 'SET_INTEGRATIONS_SORTING',
  SET_INTEGRATIONS_PAGE_SIZE: 'SET_INTEGRATIONS_PAGE_SIZE',
  SET_INTEGRATIONS_INITIAL_STATE: 'SET_INTEGRATIONS_INITIAL_STATE',
  SET_DELETE_INTEGRATION: 'SET_DELETE_INTEGRATION',
  SET_INTEGRATIONS_AUTOCOMPLETE_OPTIONS: 'SET_INTEGRATIONS_AUTOCOMPLETE_OPTIONS',
  SET_PENDING_INTEGRATIONS_AUTOCOMPLETE_OPTIONS: 'SET_PENDING_INTEGRATIONS_AUTOCOMPLETE_OPTIONS',
  SET_PENDING_INTEGRATIONS_POST_CHECK_FTP: 'SET_INTEGRATIONS_PENDING_POST_CHECK_FTP',
  SET_INTEGRATIONS_IS_MODAL_OPEN: 'SET_INTEGRATIONS_IS_MODAL_OPEN',
  SET_INTEGRATION_DATA_BY_ID: 'SET_INTEGRATION_DATA_BY_ID',
  SET_INTEGRATIONS_COLUMN_WIDTHS: 'SET_INTEGRATIONS_COLUMN_WIDTHS',
  SET_INTEGRATIONS_COLUMN_ORDER: 'SET_INTEGRATIONS_COLUMN_ORDER',
  SET_INTEGRATIONS_HIDDEN_COLUMN_NAMES: 'SET_INTEGRATIONS_HIDDEN_COLUMN_NAMES',
});
