// Core
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

// Lodash
import {
  map, isEmpty, isEqual,
} from 'lodash';

// parts
import DxTable from '../../../ui/Table/DxTable';
import ConfirmModal from '../../../components/ConfirmModal';
import AddEditDocumentTypeModel from './AddEditDocumentTypeModel';

// actions
import actions from '../../../engine/core/documentTypes/action';
import helpersActions from '../../../engine/core/helpers/action';
import asyncAction from '../../../engine/core/documentTypes/saga/asyncAction';

// config
import selectors from '../../../engine/config/selectors';

import { listTablesName, listEntityName } from '../../../engine/config/listTablesName';

// hooks
import { useDocumentTypes } from './_hooks/useDocumentTypes';
import accessList from '../../../engine/config/accessList';

const tableName = listTablesName.documentTypes;
const entityName = listEntityName.documentTypes;

function DocumentTypesTable(props) {
  const {
    documentTypesData, totalCount, pending,
    currentPage, filters, sorting,
    pageSize, pendingDeleteDocumentType, checkboxes,
    isModalOpen, columnWidths, columnOrder, hiddenColumnNames,
  } = props;
  useDocumentTypes();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const columns = [
    { name: 'id', title: t('ID') },
    { name: 'idDocType', title: t('Document Id') },
    { name: 'name', title: t('Title') },
    { name: 'shortName', title: t('Short name') },
    { name: 'category', title: t('Category') },
    { name: 'type', title: t('Type') },
    { name: 'objectType', title: t('Object type') },
  ];

  const tableColumnExtensions = [
    { columnName: 'id', width: 50 },
    { columnName: 'idDocType', width: '10rem' },
    { columnName: 'name', width: '40rem' },
    { columnName: 'shortName', width: '10rem' },
    { columnName: 'category', width: '10rem' },
    { columnName: 'type', width: '10rem' },
    { columnName: 'objectType', width: '15rem' },
  ];

  // Paging
  const onCurrentPage = (page) => dispatch(actions.setCurrentPage(page));
  const onPageSize = (size) => dispatch(actions.setPageSize(size));
  // Filtering
  const setFilters = (getFilters) => dispatch(actions.setFilters(getFilters));
  // Sorting
  const onSortingChange = (getSorting) => dispatch(actions.setSorting(getSorting));
  const onColumnWidthsChange = (getColumnWidths) => dispatch(
    actions.setColumnWidths(getColumnWidths),
  );
  const onColumnOrderChange = (getColumnOrder) => dispatch(
    actions.setColumnOrder(getColumnOrder),
  );
  const onHiddenColumnNamesChange = (getHiddenColumnNames) => dispatch(
    actions.setHiddenColumnNames(getHiddenColumnNames),
  );

  return (
    <>
      <DxTable
        name="DocumentTypesTable"
        isLoading={pending}
        columns={columns}
        rows={documentTypesData.toJS().items}
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageSize={onPageSize}
        onCurrentPage={onCurrentPage}
        filtersAction={setFilters}
        filters={filters}
        entityName={entityName}
        tableColumnExtensions={!isEmpty(columnWidths) && columnWidths.length === columns.length
          ? columnWidths : tableColumnExtensions}
        onColumnWidthsChange={onColumnWidthsChange}
        columnOrder={!isEmpty(columnOrder) ? columnOrder : map(columns, 'name')}
        onColumnOrderChange={onColumnOrderChange}
        hiddenColumnNames={hiddenColumnNames}
        onHiddenColumnNamesChange={onHiddenColumnNamesChange}
        sorting={sorting}
        onSortingChange={onSortingChange}
        // checkboxes ------------------------
        tableName={tableName}
        reloadTable={actions.setReload}
        selection={checkboxes.toJS()}
        onSelectionChange={(checkboxSelect) => {
          dispatch(helpersActions.setCheckbox({
            tableName,
            checkboxes: checkboxSelect,
          }));
        }}
        // ------------------------
        isModalOpen={isModalOpen}
        accessEdit={accessList.documentClassifier_put}
        accessDelete={accessList.documentClassifier_delete}
      />

      <ConfirmModal
        buttonSendText="DELETE"
        pending={pendingDeleteDocumentType}
        handleSend={() => {
          dispatch(asyncAction.deleteDocumentTypeAsync());
        }}
      />

      {isModalOpen && <AddEditDocumentTypeModel />}
    </>
  );
}

DocumentTypesTable.displayName = 'DocumentTypesTable';

DocumentTypesTable.propTypes = {
  documentTypesData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  pending: PropTypes.bool.isRequired,
  pendingDeleteDocumentType: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  sorting: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  pageSize: PropTypes.number.isRequired,
  checkboxes: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  columnWidths: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  columnOrder: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  hiddenColumnNames: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
};

DocumentTypesTable.defaultProps = {
};

function mapStateToProps(state) {
  return {
    isModalOpen: selectors.documentTypes.isModalOpen(state),
    documentTypesData: selectors.documentTypes.documentTypesData(state),
    pending: selectors.documentTypes.pending(state),
    pendingDeleteDocumentType: selectors.documentTypes.pendingDeleteDocumentType(state),
    // Paging
    totalCount: selectors.documentTypes.totalCount(state),
    currentPage: selectors.documentTypes.currentPage(state),
    pageSize: selectors.documentTypes.pageSize(state),

    // Filtering
    filters: selectors.documentTypes.filters(state),

    // Sorting
    sorting: selectors.documentTypes.sorting(state),

    // ColumnWidths
    columnWidths: selectors.documentTypes.columnWidths(state),
    columnOrder: selectors.documentTypes.columnOrder(state),
    hiddenColumnNames: selectors.documentTypes.hiddenColumnNames(state),

    checkboxes: selectors.helpers.getCheckboxes(state, tableName),
  };
}

function mapDispatchToProps() {
  return {};
}

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.isModalOpen, nextProps.isModalOpen)
    && isEqual(prevProps.documentTypesData, nextProps.documentTypesData)
    && isEqual(prevProps.pendingDeleteDocumentType, nextProps.pendingDeleteDocumentType)
    && isEqual(prevProps.totalCount, nextProps.totalCount)
    && isEqual(prevProps.pending, nextProps.pending)
    && isEqual(prevProps.currentPage, nextProps.currentPage)
    && isEqual(prevProps.pageSize, nextProps.pageSize)
    && isEqual(prevProps.filters, nextProps.filters)
    && isEqual(prevProps.sorting, nextProps.sorting)
    && isEqual(prevProps.checkboxes, nextProps.checkboxes);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(React.memo(DocumentTypesTable, areEqual));
