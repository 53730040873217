// Core
import { apply, put } from 'redux-saga/effects';

// lodash
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

// config
import { api } from '../../../../config/api';
import { listEntityName } from '../../../../config/listTablesName';

// actions
import actions from '../../action';

const entity = listEntityName.documentTypes;

export function* callGetDocumentTypesWorker({ payload: params }) {
  yield put(actions.setDocumentTypesData({ pending: true }));
  const response = yield apply(api, api.documentTypes.getDocumentsType, [{ entity, ...params }]);

  let data = {
    items: [],
    totalCount: 0,
    pending: false,
  };

  if (response && response.status >= 200 && response.status < 400) {
    response.data.items = !isEmpty(response.data.items) ? map(response.data.items, (doc) => ({
      ...doc,
      ...doc.objectType ? {
        objectType: `${doc.objectType}`,
      } : {},
    })) : [];
    data = Object.assign(data, response.data);
  }
  yield put(actions.setDocumentTypesData(data));
  yield put(actions.setReload(false));
}
