// core
import React, {
  useState,
} from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';

// ui
import {
  Grid as MuiGrid, Select, MenuItem,
} from '@material-ui/core';

// config
import selectors from '../../../engine/config/selectors';

// parts
import Modal from '../../../components/Modal/Modal';
import SimpleTable from './SimpleTable';

// actions
import importActions from '../../../engine/core/importObjects/action';
// styles
const Grid = styled(MuiGrid)(spacing);

function TableObjectsModal(props) {
  const {
    isModalOpen, specialDocuments, selectedObjects,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState('trademarks');

  const handleCloseModal = () => {
    dispatch(importActions.setIsModalOpen(false));
  };

  const handleConfirm = () => {
    dispatch(importActions.setEntityList(selectedObjects.toJS()));
    dispatch(importActions.setIsModalOpen(false));
  };

  const handleChange = (e) => {
    setSelected(e.target.value);
  };

  const handleSelect = (checkboxSelect) => {
    dispatch(importActions.mergeSelectedObjects({
      entityName: selected,
      items: checkboxSelect,
    }));
  };

  const columns = [
    { name: 'title', title: t('Title') },
    { name: 'applicants', title: t('Applicant') },
    { name: 'applicationDate', title: t('Application date') },
    { name: 'applicationNumber', title: t('Application number') },
    { name: 'registrationNumber', title: t('Registration number') },
  ];

  const tableColumnExtensions = [
    { columnName: 'applicationDate', width: '6rem' },
    { columnName: 'applicationNumber', width: '6rem' },
    { columnName: 'registrationNumber', width: '7rem' },
  ];

  return (
    <Modal
      title={t('Objects SPEZ-1')}
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
      maxWidth="lg"
      handleSend={handleConfirm}
    >
      <Grid item xs={12} md={4} mb={4}>
        <Select
          labelId="selectObject"
          fullWidth
          value={selected}
          onChange={handleChange}
        >
          <MenuItem value="trademarks">{t('trademarks')}</MenuItem>
          <MenuItem value="utility">{t('utility')}</MenuItem>
          <MenuItem value="inventions">{t('inventions')}</MenuItem>
        </Select>
      </Grid>

      <SimpleTable
        name="SelectObject"
        columns={columns}
        rows={specialDocuments.toJS()?.[selected] || []}
        selection={selectedObjects.toJS()?.[selected] || []}
        onSelectionChange={handleSelect}
        tableColumnExtensions={tableColumnExtensions}
      />
    </Modal>
  );
}

TableObjectsModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  specialDocuments: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  selectedObjects: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

TableObjectsModal.defaultProps = {
};

function mapStateToProps(state) {
  return {
    isModalOpen: selectors.importObjects.isModalOpen(state),
    specialDocuments: selectors.importObjects.specialDocuments(state),
    selectedObjects: selectors.importObjects.selectedObjects(state),
  };
}

export default compose(
  connect(mapStateToProps, null),
)(TableObjectsModal);
