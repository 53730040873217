// Core
import { call, put } from 'redux-saga/effects';
// import isArray from 'lodash/isArray';

// config
import { api } from '../../../../config/api';

// actions
import action from '../../action';

// helpers
// import { getObjectReverseFormatDate } from '../../../../_helpers/formatterDate';

export function* callGetPaymentReceiversByIdWorker({ payload: id }) {
  const {
    setPaymentReceiversById,
    setModalOpen,
  } = action;
  yield put(setPaymentReceiversById({
    pending: true,
  }));

  const response = yield call(api.paymentReceivers.getPaymentReceiversById, id);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    yield put(setPaymentReceiversById({ data }));
    yield put(setModalOpen(true));
  }

  yield put(setPaymentReceiversById({
    pending: false,
  }));
}
