export default Object.freeze({
  SET_PAYMENT_ALLOCATIONS_DATA: 'SET_PAYMENT_ALLOCATIONS_DATA',
  SET_PAYMENT_ALLOCATIONS_CURRENT_PAGE: 'SET_PAYMENT_ALLOCATIONS_CURRENT_PAGE',
  SET_PAYMENT_ALLOCATIONS_PAGE_SIZE: 'SET_PAYMENT_ALLOCATIONS_PAGE_SIZE',
  SET_PAYMENT_ALLOCATIONS_FILTERS: 'SET_PAYMENT_ALLOCATIONS_FILTERS',
  SET_PAYMENT_ALLOCATIONS_SORTING: 'SET_PAYMENT_ALLOCATIONS_SORTING',
  SET_PAYMENT_ALLOCATIONS_INITIAL_STATE: 'SET_PAYMENT_ALLOCATIONS_INITIAL_STATE',
  SET_PAYMENT_ALLOCATIONS_RELOAD: 'SET_PAYMENT_ALLOCATIONS_RELOAD',
  SET_DELETE_PAYMENT_ALLOCATIONS: 'SET_DELETE_PAYMENT_ALLOCATIONS',
  SET_PAYMENT_ALLOCATIONS_SELECTIONS: 'SET_PAYMENT_ALLOCATIONS_SELECTIONS',
});
