const asyncTypes = Object.freeze({
  GET_MKTU_TEMPLATES_ASYNC: 'GET_MKTU_TEMPLATES_ASYNC',
  GET_MKTU_TEMPLATE_BY_ID_ASYNC: 'GET_MKTU_TEMPLATE_BY_ID_ASYNC',
  GET_MKTU_TEMPLATE_PDF_BY_ID_ASYNC: 'GET_MKTU_TEMPLATE_PDF_BY_ID_ASYNC',
  GET_MKTU_TEMPLATES_INITIAL_STATE_ASYNC: 'GET_MKTU_TEMPLATES_INITIAL_STATE_ASYNC',
  POST_MKTU_TEMPLATES_ASYNC: 'POST_MKTU_TEMPLATES_ASYNC',
  PUT_MKTU_TEMPLATE_BY_ID_ASYNC: 'PUT_MKTU_TEMPLATE_BY_ID_ASYNC',
  DELETE_MKTU_TEMPLATE_ASYNC: 'DELETE_MKTU_TEMPLATE_ASYNC',
  GET_MKTU_TEMPLATES_AUTOCOMPLETE_OPTIONS_ASYNC: 'GET_MKTU_TEMPLATES_AUTOCOMPLETE_OPTIONS_ASYNC',
});

export default asyncTypes;
