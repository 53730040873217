// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import types from './types';

const actions = Object.freeze({
  setTariffsData(searchesData) {
    return actionCreator(types.SET_PRICES_TARIFFS_DATA, searchesData);
  },
  setCurrentPage(currentPage) {
    return actionCreator(types.SET_PRICES_TARIFFS_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return actionCreator(types.SET_PRICES_TARIFFS_FILTERS, filters);
  },
  setSorting(sorting) {
    return actionCreator(types.SET_PRICES_TARIFFS_SORTING, sorting);
  },
  setColumnWidths(data) {
    return actionCreator(types.SET_PRICES_TARIFFS_COLUMN_WIDTHS, data);
  },
  setColumnOrder(data) {
    return actionCreator(types.SET_PRICES_TARIFFS_COLUMN_ORDER, data);
  },
  setHiddenColumnNames(data) {
    return actionCreator(types.SET_PRICES_TARIFFS_HIDDEN_COLUMN_NAMES, data);
  },
  setPageSize(pageSize) {
    return actionCreator(types.SET_PRICES_TARIFFS_PAGE_SIZE, pageSize);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_PRICES_TARIFFS_INITIAL_STATE, initialState);
  },
  setReload(reload) {
    return actionCreator(types.SET_PRICES_TARIFFS_RELOAD, reload);
  },
  setDeleteTariff(data) {
    return actionCreator(types.SET_DELETE_PRICES_TARIFF, data);
  },
  setAutocompleteOptions(options) {
    return actionCreator(types.SET_PRICES_TARIFFS_AUTOCOMPLETE_OPTIONS, options);
  },
  setTariffsTypes(typesData) {
    return actionCreator(types.SET_PRICES_TARIFFS_TYPES, typesData);
  },
});

export default actions;
