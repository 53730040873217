// Core
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import * as PropTypes from 'prop-types';

// ui
import {
  Typography, CardContent, Box, Button,
  CircularProgress, Card as MuiCard,
  Menu, Link, MenuItem, ButtonGroup,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';

// lodash
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';

// parts
import DistributionTable from './DistributionTable';
import ChargesModal from './ChargesModal/ChargesModal';

// actions
import paymentAllocationsActionsAsync from '../../../../engine/core/paymentAllocations/saga/asyncAction';
import paymentAllocationsActions from '../../../../engine/core/paymentAllocations/action';
import chargesActions from '../../../../engine/core/charges/action';
import paymentsActionsAsync from '../../../../engine/core/company/payments/saga/asyncAction';
import paymentsActions from '../../../../engine/core/company/payments/action';

// config
import selectors from '../../../../engine/config/selectors';

// helpers
import { formName, formFields } from '../helper/form';
import accessList from '../../../../engine/config/accessList';
import { useAccessList } from '../../../../ui/_hooks/useAccessList';

// styles
const Card = styled(MuiCard)(spacing);

const LinkChosen = styled(Link)`
  border-bottom: 1px dashed;
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
`;

function PaymentDistribution(props) {
  const {
    selections, match, filters, sumChargesInput,
    isModalOpenChargesTable, fieldClient, getPaymentDataById,
    selectionsModal, pendingPostPaymentsApply,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [simpleMenu, setSimpleMenu] = useState(null);
  const getSelections = selections.toJS();
  const getSumChargesInput = sumChargesInput.toJS();
  const { params } = match;
  const paymentById = getPaymentDataById.toJS();
  const accessPut = useAccessList(accessList.payments_put);
  const accessEdit = !isEmpty(params) ? accessPut : true;

  const toggleMenu = (event) => {
    setSimpleMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setSimpleMenu(null);
  };

  const handleDeletePaymentAllocations = () => {
    forEach(getSelections.all, (id) => {
      dispatch(paymentAllocationsActionsAsync.deletePaymentAllocationsAsync(id));
    });
    closeMenu();
  };

  const handleDistributeManually = () => {
    dispatch(chargesActions.setChargeById({
      alwaysFilters: {
        payed: 'false',
      },
    }));
    dispatch(chargesActions.setModalChargesTable(true));
  };

  const handleSendChargesModal = () => {
    const readyData = map(selectionsModal.toJS(), (data) => {
      const valueSumInput = getSumChargesInput[data.id];

      return {
        id: parseInt(params.hashId, 10),
        charge: data.id,
        type: 'manual',
        sum: valueSumInput ? parseInt(valueSumInput, 10) : data.toPay,
      };
    });

    const filterData = filter(readyData, (data) => data.sum);

    if (!isEmpty(filterData)) {
      dispatch(paymentsActionsAsync.postPaymentsApplyAsync({
        hashId: params.hashId,
        data: filterData,
      }));
    }
  };

  const handleDistributeAutomatically = () => {
    dispatch(paymentsActionsAsync.postPaymentsApplyAsync({
      hashId: params.hashId,
      data: [{
        id: params.hashId,
        type: 'auto',
      }],
    }));
  };

  useEffect(() => {
    if (!isEmpty(params)) {
      dispatch(paymentAllocationsActions.setFilters([
        { columnName: 'payments.id', value: params.hashId },
      ]));
    }
  }, [
    dispatch, params,
  ]);

  useEffect(() => () => {
    dispatch(paymentsActions.setPaymentDataById({}));
  }, [dispatch]);

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h4" gutterBottom>
          {t('Payment distribution')}
        </Typography>
        <br />

        {!isEmpty(paymentById) && paymentById.unappliedSum && paymentById.sum && (
          <>
            <Typography variant="inherit" gutterBottom>
              {t('Distributed')}
              {' '}
              <strong>
                {parseInt(paymentById.sum, 10) - parseInt(paymentById.unappliedSum, 10)}
                {' '}
                {t('UAH')}
              </strong>
              {t('from')}
              <strong>
                {parseInt(paymentById.sum, 10)}
                {' '}
                {t('UAH')}
              </strong>
            </Typography>
            <br />
            <Typography variant="inherit" gutterBottom>
              {t('Undistributed amount')}
              <strong>
                {paymentById.unappliedSum}
                {t('UAH')}
              </strong>
            </Typography>
          </>
        )}

        {accessEdit && (
          <Box mt={3} mb={3}>
            <ButtonGroup color="primary" aria-label="extra filter buttons group">
              <Button
                mr={3}
                variant="outlined"
                color="primary"
                disabled={pendingPostPaymentsApply}
                onClick={handleDistributeAutomatically}
              >
                {pendingPostPaymentsApply ? <CircularProgress size={25} color="inherit" /> : t('DISTRIBUTE AUTOMATICALLY')}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                disabled={false}
                onClick={handleDistributeManually}
              >
                {t('DISTRIBUTE MANUALLY')}
              </Button>
            </ButtonGroup>
          </Box>
        )}

        {!isEmpty(params) && !isEmpty(filters) ? (
          <DistributionTable />
        ) : <Box mt={3} mb={3}>{t('No data')}</Box>}

        {Boolean(getSelections.chosen) && accessEdit && (
          <>
            <Typography>
              {`${t('Chosen')}: ${getSelections.chosen} ${t('in the amount of')} ${getSelections.sum} ${t('UAH')}`}
            </Typography>
            <LinkChosen to="/" onClick={toggleMenu} aria-owns="simple-menu" aria-haspopup="true">
              {t('With the chosen ones')}
            </LinkChosen>
            <Menu
              id="simple-menu"
              anchorEl={simpleMenu}
              open={Boolean(simpleMenu)}
              onClose={closeMenu}
            >
              <MenuItem onClick={handleDeletePaymentAllocations}>
                {t('Delete')}
              </MenuItem>
            </Menu>
          </>
        )}

        {isModalOpenChargesTable && (
          <ChargesModal
            {...!isEmpty(fieldClient) ? {
              defaultFilters: [
                { columnName: 'clients.id', value: [fieldClient.id || fieldClient] },
              ],
            } : {}}
            handleSend={handleSendChargesModal}
          />
        )}
      </CardContent>
    </Card>
  );
}

PaymentDistribution.propTypes = {
  getPaymentDataById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  isModalOpenChargesTable: PropTypes.bool.isRequired,
  pendingPostPaymentsApply: PropTypes.bool.isRequired,
  selections: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  sumChargesInput: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  selectionsModal: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  match: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  fieldClient: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array,
  ]),
};

PaymentDistribution.defaultProps = {
  match: {},
  fieldClient: {},
};

function mapStateToProps(state) {
  return {
    selections: selectors.paymentAllocations.selections(state),
    selectionsModal: selectors.charges.selectionsModal(state),
    filters: selectors.paymentAllocations.filters(state),
    isModalOpenChargesTable: selectors.charges.isModalOpenChargesTable(state),
    sumChargesInput: selectors.charges.sumChargesInput(state),
    pendingPostPaymentsApply: selectors.paymentsTable.pendingPostPaymentsApply(state),
    getPaymentDataById: selectors.paymentsTable.getPaymentDataById(state),
    fieldClient: selectors.form.getFormValues(state, formName).toJS()[formFields.client],
  };
}

export default compose(
  connect(mapStateToProps, null),
)(PaymentDistribution);
