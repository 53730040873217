// Core
import { all, call, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetMadridCasesWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callDeleteMadridCaseWorker,
  callGetMadridCaseByIdWorker,
  callPostMadridCaseWorker,
  callPutMadridCaseByIdWorker,
} from './workers';
import types from '../types';

function* watchGetMadridCase() {
  yield takeEvery(asyncTypes.GET_MADRID_CASE_ASYNC, callGetMadridCasesWorker);
}
function* watchGetMadridCaseByIdAsync() {
  yield takeEvery(asyncTypes.GET_MADRID_CASE_BY_ID_ASYNC, callGetMadridCaseByIdWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_MADRID_CASE_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_MADRID_CASE_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_MADRID_CASE_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_MADRID_CASE_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_MADRID_CASE_SORTING, callSetToLocalDataWorker);
}
function* watchSetColumnWidths() {
  yield takeEvery(types.SET_MADRID_CASE_COLUMN_WIDTHS, callSetToLocalDataWorker);
}
function* watchSetColumnOrder() {
  yield takeEvery(types.SET_MADRID_CASE_COLUMN_ORDER, callSetToLocalDataWorker);
}
function* watchSetHiddenColumnNames() {
  yield takeEvery(
    types.SET_MADRID_CASE_HIDDEN_COLUMN_NAMES,
    callSetToLocalDataWorker,
  );
}

function* watchPostMadridCase() {
  yield takeEvery(asyncTypes.POST_MADRID_CASE_ASYNC, callPostMadridCaseWorker);
}

function* watchPutMadridCaseById() {
  yield takeEvery(asyncTypes.PUT_MADRID_CASE_BY_ID_ASYNC, callPutMadridCaseByIdWorker);
}

function* watchDeleteMadridCase() {
  yield takeEvery(asyncTypes.DELETE_MADRID_CASE_ASYNC, callDeleteMadridCaseWorker);
}

export function* watchersMadridCase() {
  yield all([
    call(watchGetMadridCase),
    call(watchSetCurrentPage),
    call(watchSetPageSize),
    call(watchSetFilters),
    call(watchSetSorting),
    call(watchSetColumnWidths),
    call(watchSetColumnOrder),
    call(watchSetHiddenColumnNames),
    call(watchGetInitialState),
    call(watchPostMadridCase),
    call(watchPutMadridCaseById),
    call(watchDeleteMadridCase),
    call(watchGetMadridCaseByIdAsync),
  ]);
}
