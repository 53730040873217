// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import types from './types';

const actions = Object.freeze({
  setOrdersData(ordersData) {
    return actionCreator(types.SET_ORDERS_DATA, ordersData);
  },
  setOrdersAllData(ordersData) {
    return actionCreator(types.SET_ORDERS_ALL_DATA, ordersData);
  },
  setOrderById(order) {
    return actionCreator(types.SET_ORDER_BY_ID, order);
  },
  setCurrentPage(currentPage) {
    return actionCreator(types.SET_ORDERS_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return actionCreator(types.SET_ORDERS_FILTERS, filters);
  },
  setSorting(sorting) {
    return actionCreator(types.SET_ORDERS_SORTING, sorting);
  },
  setPageSize(pageSize) {
    return actionCreator(types.SET_ORDERS_PAGE_SIZE, pageSize);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_ORDERS_INITIAL_STATE, initialState);
  },
  setReload(reload) {
    return actionCreator(types.SET_ORDERS_RELOAD, reload);
  },
  setDeleteOrder(data) {
    return actionCreator(types.SET_DELETE_ORDER, data);
  },
  setDocumentsOrder(documents) {
    return actionCreator(types.SET_DOCUMENTS_ORDER, documents);
  },
  setAutocompleteOptions(ordersData) {
    return actionCreator(types.SET_ORDERS_AUTOCOMPLETE_OPTIONS, ordersData);
  },
  setPendingAutocompleteOptions(pending) {
    return actionCreator(types.SET_PENDING_ORDERS_AUTOCOMPLETE_OPTIONS, pending);
  },
  setOrderNextNumber(data) {
    return actionCreator(types.SET_ORDER_NEXT_NUMBER, data);
  },
  mergeOrderEntityList(data) {
    return actionCreator(types.MERGE_ORDER_ENTITY_LIST, data);
  },
  setOrderEntityList(data) {
    return actionCreator(types.SET_ORDER_ENTITY_LIST, data);
  },
  setColumnWidths(data) {
    return actionCreator(types.SET_ORDERS_COLUMN_WIDTHS, data);
  },
  setColumnOrder(data) {
    return actionCreator(types.SET_ORDERS_COLUMN_ORDER, data);
  },
  setHiddenColumnNames(data) {
    return actionCreator(types.SET_ORDERS_HIDDEN_COLUMN_NAMES, data);
  },
});

export default actions;
