// Core
import { call, put } from 'redux-saga/effects';
// import { push } from 'connected-react-router';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../config/api';
import { messageRequestSuccess } from '../../../../config/globalConfig';

// routes
// import { pageLinks } from '../../../../../routes';

// actions
import actions from '../../action';
import helpersActions from '../../../helpers/action';

// init
import i18n from '../../../../init/i18n';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../ui/_helpers/setNotificationMessage';

export function* callPostChargeWorker({ payload }) {
  const { setChargeById, setReload, setChargesIds } = actions;
  const { setModal } = helpersActions;

  yield put(setChargeById({
    pendingPost: true,
  }));

  const response = yield call(api.charges.postCharge, payload);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      yield put(setSuccessMessage(i18n.t(messageRequestSuccess), response.status.toString()));
      yield put(setModal({
        isModalOpenAddCharges: false,
      }));
      yield put(setChargesIds(data.charges));
      yield put(setReload(true));
    }
  }

  yield put(setChargeById({
    pendingPost: false,
  }));
}
