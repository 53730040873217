// Core
import React, {
  memo, useEffect, useState, useMemo,
} from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import * as PropTypes from 'prop-types';

// ui
import {
  Typography,
  Grid as MuiGrid,
  Avatar as MuiAvatar,
} from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';

// lodash
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

// actions
import proposalAsyncActions from '../../../../../engine/core/tradeMarks/proposal/saga/asyncAction';

// config
import selectors from '../../../../../engine/config/selectors';

// styles
const Grid = styled(MuiGrid)(spacing);

const Avatar = styled(MuiAvatar)`
  margin-right: ${(props) => props.theme.spacing(2)}px;
  background-color: ${blue[500]};
`;

function SelectedClass(props) {
  const {
    mktuClass, termMktuClasses, langValue,
  } = props;
  const dispatch = useDispatch();
  const [readyGetTerms, setReadyGetTerms] = useState(false);

  useEffect(() => {
    if (!readyGetTerms && !isEmpty(mktuClass) && (mktuClass.name === 'All' || mktuClass.name === 'selection')
      && (isEmpty(termMktuClasses.toJS()) || isEmpty(termMktuClasses.toJS()[mktuClass.id]))) {
      setReadyGetTerms(true);
      dispatch(proposalAsyncActions.getClassByIdAsync({
        id: mktuClass.classNum,
        lang: langValue,
      }));
    }
  }, [
    dispatch, mktuClass, termMktuClasses, readyGetTerms, setReadyGetTerms, langValue,
  ]);

  const getName = useMemo(() => {
    const termName = !isEmpty(termMktuClasses.toJS())
    && !isEmpty(termMktuClasses.toJS()[mktuClass.id])
      ? `${mktuClass.label}; ${termMktuClasses.toJS()[mktuClass.id]}`
      : null;
    return mktuClass.name !== 'All' ? mktuClass.name : termName;
  }, [mktuClass, termMktuClasses]);

  return (
    <Grid mb={5} container wrap="nowrap" spacing={2}>
      <Grid item>
        <Avatar>{mktuClass.classNum}</Avatar>
      </Grid>
      <Grid item xs>
        <Typography>
          {getName}
        </Typography>
      </Grid>
    </Grid>
  );
}

SelectedClass.displayName = 'SelectedClass';

SelectedClass.propTypes = {
  mktuClass: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  termMktuClasses: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  langValue: PropTypes.string.isRequired,
};

SelectedClass.defaultProps = {
  mktuClass: {},
};

function mapStateToProps(state) {
  return {
    termMktuClasses: selectors.tradeMarksProposal.termMktuClasses(state),
  };
}

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.mktuClass, nextProps.mktuClass)
  && isEqual(prevProps.langValue, nextProps.langValue)
  && isEqual(prevProps.termMktuClasses, nextProps.termMktuClasses);
}

export default compose(connect(mapStateToProps, null))(memo(SelectedClass, areEqual));
