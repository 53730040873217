// Helpers
import actionCreator from '../../_helpers/actionCreator';
// Types
import types from './types';

const actions = Object.freeze({
  setWorksData(searchesData) {
    return actionCreator(types.SET_WORKS_DATA, searchesData);
  },
  setCurrentPage(currentPage) {
    return actionCreator(types.SET_WORKS_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return actionCreator(types.SET_WORKS_FILTERS, filters);
  },
  setSorting(sorting) {
    return actionCreator(types.SET_WORKS_SORTING, sorting);
  },
  setPageSize(pageSize) {
    return actionCreator(types.SET_WORKS_PAGE_SIZE, pageSize);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_WORKS_INITIAL_STATE, initialState);
  },
  setReload(reload) {
    return actionCreator(types.SET_WORKS_RELOAD, reload);
  },
  setDeleteWord(data) {
    return actionCreator(types.SET_DELETE_WORK, data);
  },
  setAutocompleteOptions(options) {
    return actionCreator(types.SET_WORKS_AUTOCOMPLETE_OPTIONS, options);
  },
});

export default actions;
