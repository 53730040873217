// Core
import { call, put } from 'redux-saga/effects';
// import { push } from 'connected-react-router';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../../config/api';
import { messageRequestSuccess } from '../../../../../config/globalConfig';

// init
import i18n from '../../../../../init/i18n';

// actions
import actions from '../../action';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../../ui/_helpers/setNotificationMessage';

export function* callPostIntegrationWorker({ payload: integration }) {
  const { setIntegrationById, setIsModalOpen, setReload } = actions;

  yield put(setIntegrationById({
    pendingPost: true,
  }));
  const response = yield call(api.integration.postIntegration, integration);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      yield put(setSuccessMessage(i18n.t(messageRequestSuccess), response.status.toString()));
      yield put(setIsModalOpen(false));
      yield put(setReload(true));
    }
  }

  yield put(setIntegrationById({
    pendingPost: false,
  }));
}
