// Core
import {
  useCallback, useEffect, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

// lodash
import { isEmpty } from 'lodash';

// Engine
import asyncActions from '../../../../engine/core/paymentAllocations/saga/asyncAction';
import selectors from '../../../../engine/config/selectors';
import { listEntityName } from '../../../../engine/config/listTablesName';

// Hook
import { useParamsRequest } from '../../../../ui/_hooks/useParamsRequest';

const entityName = listEntityName.paymentAllocation;

export function useDistribution(data = {}) {
  const currentPage = useSelector(selectors.paymentAllocations.currentPage);
  const limit = useSelector(selectors.paymentAllocations.pageSize);
  const filters = useSelector(selectors.paymentAllocations.filters);
  const sorting = useSelector(selectors.paymentAllocations.sorting);
  const reload = useSelector(selectors.paymentAllocations.reload);
  const routeKey = useSelector(selectors.router.key);
  const path = useSelector(selectors.router.pathname);
  const fieldsEntity = useSelector(selectors.fieldsSetting.fieldsEntity);
  const dispatch = useDispatch();
  const { orderBy, paramsByFilter } = useParamsRequest({ filters, sorting, entityName });

  const getListAsync = useCallback(() => {
    const params = {
      ...paramsByFilter,
      ...orderBy,
      limit,
      page: currentPage + 1,
    };
    dispatch(asyncActions.getListAsync(params));
  }, [paramsByFilter, currentPage, orderBy, limit, dispatch]);

  const getInitialStateAsync = useCallback(() => {
    dispatch(asyncActions.getInitialStateAsync(path));
  }, [dispatch, path]);

  const getInitialState = useRef(true);

  useEffect(() => {
    if (getInitialState.current && !data.stopInitialState) {
      getInitialStateAsync();
      getInitialState.current = false;
    }
    if (reload && !isEmpty(fieldsEntity)) {
      getListAsync();
    }
  }, [getListAsync, getInitialStateAsync, reload, routeKey]);// eslint-disable-line
}
