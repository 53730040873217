export const searchParams = Object.freeze({
  ordersId: 'ordersId',
  caseNumber: 'caseNumber',
  inNumber: 'inNumber',
  outNumber: 'outNumber',
  name: 'name',
  text: 'text',
  title: 'title',
  applicationNumber: 'applicationNumber',
  registrationNumber: 'registrationNumber',
});
