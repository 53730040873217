// Helpers
import actionCreator from '../../_helpers/actionCreator';
// Types
import types from './types';

const loggingActions = Object.freeze({
  setLoggingData(data) {
    return actionCreator(types.SET_LOGGING_DATA, data);
  },
  setLoggingById(data) {
    return actionCreator(types.SET_LOGGING_BY_ID, data);
  },
  setLoggingDataById(data) {
    return actionCreator(types.SET_LOGGING_DATA_BY_ID, data);
  },
  setCurrentPage(currentPage) {
    return actionCreator(types.SET_LOGGING_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return actionCreator(types.SET_LOGGING_FILTERS, filters);
  },
  setSorting(sorting) {
    return actionCreator(types.SET_LOGGING_SORTING, sorting);
  },
  setColumnWidths(data) {
    return actionCreator(types.SET_LOGGING_COLUMN_WIDTHS, data);
  },
  setColumnOrder(data) {
    return actionCreator(types.SET_LOGGING_COLUMN_ORDER, data);
  },
  setHiddenColumnNames(data) {
    return actionCreator(types.SET_LOGGING_HIDDEN_COLUMN_NAMES, data);
  },
  setPageSize(pageSize) {
    return actionCreator(types.SET_LOGGING_PAGE_SIZE, pageSize);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_LOGGING_INITIAL_STATE, initialState);
  },
  setReload(reload) {
    return actionCreator(types.SET_LOGGING_RELOAD, reload);
  },
  setDeleteLogging(data) {
    return actionCreator(types.SET_DELETE_LOGGING, data);
  },
  setIsModalOpen(data) {
    return actionCreator(types.SET_LOGGING_IS_MODAL_OPEN, data);
  },
});

export default loggingActions;
