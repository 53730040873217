const asyncTypes = Object.freeze({
  GET_APPLICANTS_LIST_ASYNC: 'GET_APPLICANTS_LIST_ASYNC',
  POST_APPLICANT_ASYNC: 'POST_APPLICANT_ASYNC',
  PUT_APPLICANT_ASYNC: 'PUT_APPLICANT_ASYNC',
  DELETE_APPLICANT_ASYNC: 'DELETE_APPLICANT_ASYNC',
  GET_APPLICANT_AUTOCOMPLETE_ASYNC: 'GET_APPLICANT_AUTOCOMPLETE_ASYNC',
  GET_APPLICANTS_INITIAL_LIST_ASYNC: 'GET_APPLICANTS_INITIAL_LIST_ASYNC',
  GET_APPLICANT_BY_ID_ASYNC: 'GET_APPLICANT_BY_ID_ASYNC',
  GET_APPLICANTS_INITIAL_STATE_ASYNC: 'GET_APPLICANTS_INITIAL_STATE_ASYNC',
});

export default asyncTypes;
