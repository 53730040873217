const asyncTypes = Object.freeze({
  GET_ORDERS_ASYNC: 'GET_ORDERS_ASYNC',
  GET_ORDER_BY_ID_ASYNC: 'GET_ORDER_BY_ID_ASYNC',
  PUT_ORDER_BY_ID_ASYNC: 'PUT_ORDER_BY_ID_ASYNC',
  POST_ORDER_ASYNC: 'POST_ORDER_ASYNC',
  POST_INVOICE_ASYNC: 'POST_INVOICE_ASYNC',
  GET_ORDERS_INITIAL_STATE_ASYNC: 'GET_ORDERS_INITIAL_STATE_ASYNC',
  DELETE_ORDER_ASYNC: 'DELETE_ORDER_ASYNC',
  GET_ORDERS_AUTOCOMPLETE_OPTIONS_ASYNC: 'GET_ORDERS_AUTOCOMPLETE_OPTIONS_ASYNC',
  GET_ORDER_NEXT_NUMBER_ASYNC: 'GET_ORDER_NEXT_NUMBER_ASYNC',
});

export default asyncTypes;
