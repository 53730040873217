// Core
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import Helmet from 'react-helmet';
import {
  Field, Form, reduxForm,
} from 'redux-form/immutable';
import * as PropTypes from 'prop-types';
import { Link as MuiLinkRouter } from 'react-router-dom';

// ui
import {
  Typography, Divider as MuiDivider, Tabs, Tab as MuiTab, Badge as MuiBadge,
  CardContent, Box, Button as MuiButton, TextField as MuiTextField,
  Grid as MuiGrid, Card as MuiCard, CircularProgress, Menu, MenuItem,
  Link as MuiLink, InputAdornment, IconButton,
} from '@material-ui/core';
import {
  Search as SearchIcon,
  Clear as ClearIcon,
  NavigateBefore as NavigateBeforeIcon,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';

// lodash
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

// parts
import Loader from '../../../../components/Loader';
import RenderTextField from '../../../../ui/Form/RenderTextField';
import AllClasses from './components/AllClasses';
import SelectedClass from './components/SelectedClass';

// actions
import mktuTemplatesActionAsync from '../../../../engine/core/tradeMarks/mktuTemplates/saga/asyncAction';
import mktuTemplatesAction from '../../../../engine/core/tradeMarks/mktuTemplates/action';
import reboundsActionAsync from '../../../../engine/core/tradeMarks/rebounds/saga/asyncAction';
import reboundsAction from '../../../../engine/core/tradeMarks/rebounds/action';
import proposalAsyncActions from '../../../../engine/core/tradeMarks/proposal/saga/asyncAction';
import proposalActions from '../../../../engine/core/tradeMarks/proposal/action';

// config
import selectors from '../../../../engine/config/selectors';

// helpers
import { formName, formFields } from './helper/form';
import { pageLinks } from '../../../../routes';
import accessList from '../../../../engine/config/accessList';
import { useAccessList } from '../../../../ui/_hooks/useAccessList';

// styles
const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Grid = styled(MuiGrid)(spacing);
const TextField = styled(MuiTextField)(spacing);

const Link = styled(MuiLink)`
  border-bottom: 1px dashed;
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
`;

const Badge = styled(MuiBadge)`
  .MuiBadge-badge {
    padding: 0 4px;
    font-size: 10px;
  }
  .MuiBadge-anchorOriginTopRightRectangle {
    right: 3px;
  }
`;

const Tab = styled(MuiTab)`
  min-width: 72px;
`;
const LinkRouter = styled(MuiLinkRouter)`
  text-decoration: underline;
  color: #1976d2;
  display: flex;
  align-items: center;
`;

function MktuTemplate(props) {
  const {
    handleSubmit, initialize, pendingPostMktuTemplate,
    getMktuTemplateById, match, getSearch,
    pendingPutMktuTemplate, pendingMktuTemplateById,
    selectMktuClasses, getFileById, pendingGetFile,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { params } = match;
  const [valueTab, setValueTab] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [readyMktuTemplate, setReadyMktuTemplate] = useState(false);
  const [simpleMenu, setSimpleMenu] = useState(null);
  const pending = pendingPostMktuTemplate || pendingPutMktuTemplate;
  const accessPut = useAccessList(accessList.mktuTemplate_put);
  const accessCreateFile = useAccessList(accessList.mktuTemplate_create_files_get);
  const accessClassesList = useAccessList(accessList.mktu_classes_list_get);
  const accessEdit = !isEmpty(params) ? accessPut : true;

  useEffect(() => {
    if (accessClassesList) {
      dispatch(proposalAsyncActions.getClassesAsync({
        lang: 'ua',
        limit: 100,
        page: 1,
      }));
    }
  }, [dispatch, accessClassesList]);

  useEffect(() => function cleanup() {
    dispatch(proposalActions.setSelectMktuClasses([]));
    dispatch(mktuTemplatesAction.setMktuTemplatePdfById({}));
    dispatch(reboundsAction.setSearchRebound({}));
  }, [dispatch]);

  useEffect(() => {
    if (!readyMktuTemplate && isEmpty(getMktuTemplateById.toJS()) && !isEmpty(params)) {
      setReadyMktuTemplate(true);
      dispatch(mktuTemplatesActionAsync.getMktuTemplateByIdWorker(params.hashId));
    }
  }, [
    dispatch, getMktuTemplateById, params,
    readyMktuTemplate, setReadyMktuTemplate,
  ]);

  useEffect(() => {
    initialize(!isEmpty(getMktuTemplateById.toJS()) && !isEmpty(params) ? {
      ...getMktuTemplateById.toJS(),
      choice: 'true',
    } : {
      choice: 'true',
    });
  }, [
    getMktuTemplateById,
    initialize, params,
  ]);

  const handleSubmits = (formData) => {
    const json = formData.toJS();
    delete json.choice;

    if (!isEmpty(selectMktuClasses.toJS())) {
      json.selection = {};
      map(selectMktuClasses.toJS(), (selectMktuClass) => {
        json.selection[selectMktuClass.classNum] = selectMktuClass?.name;
      });
    }
    if (!isEmpty(json)) {
      dispatch(mktuTemplatesActionAsync[!isEmpty(params)
        ? 'putMktuTemplateByIdAsync' : 'postMktuTemplatesAsync']({
        ...json,
      }));
    }
  };

  const handleChange = (e, value) => {
    setValueTab(value);
  };

  const handleChangeSearch = (e) => {
    setSearchValue(e.target.value);
    if (isEmpty(e.target.value)) {
      dispatch(reboundsAction.setSearchRebound({}));
    }
  };

  const handleSearch = () => {
    if (!isEmpty(searchValue)) {
      if (!isEmpty(getSearch.toJS()) && !isEmpty(getSearch.toJS().items)) {
        setSearchValue('');
        dispatch(reboundsAction.setSearchRebound({}));
        return;
      }
      dispatch(reboundsActionAsync.searchReboundAsync({
        search: searchValue,
        lang: 'ua',
      }));
    }
  };

  const toggleMenu = (event) => {
    if (!isEmpty(params)) {
      if (!isEmpty(getMktuTemplateById.toJS()) && isEmpty(getMktuTemplateById.toJS().links)
      && isEmpty(getFileById.toJS())) {
        dispatch(mktuTemplatesActionAsync.getMktuTemplatePdfByIdWorker(params.hashId));
      }
      setSimpleMenu(event.currentTarget);
    }
  };

  const closeMenu = () => {
    setSimpleMenu(null);
  };

  return !pendingMktuTemplateById ? (
    <>
      <Helmet title={t('Selection template of ICGS')} />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h3" gutterBottom display="inline">
          {t('Selection template of ICGS')}
        </Typography>
        <LinkRouter to={pageLinks.tradeMarksRoutes.mktuTemplates.all}>
          <NavigateBeforeIcon size="small" color="primary" />
          {t('Up to the list')}
        </LinkRouter>
      </Box>
      <Divider my={6} />

      <Form onSubmit={handleSubmit(handleSubmits)}>
        <Card mb={6}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              {t('Basic information')}
            </Typography>

            <Grid container spacing={6}>
              <Grid item xs={12} md={4}>
                <Field
                  id={formFields.title}
                  name={formFields.title}
                  label={t('Title')}
                  variant="standard"
                  margin="normal"
                  fullWidth
                  my={2}
                  component={RenderTextField}
                  disabled={!accessEdit}
                />
              </Grid>
              {accessEdit && (
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    mr={2}
                  >
                    {pending ? <CircularProgress size={25} color="inherit" /> : t('SAVE')}
                  </Button>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>

        <Card mb={6}>
          <CardContent>
            <Tabs
              value={valueTab}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label={(
                <Badge color="secondary">
                  {t('Classes')}
                </Badge>
              )}
              />
              <Tab label={(
                <Badge color="secondary">
                  {t('Selected')}
                </Badge>
              )}
              />
            </Tabs>
          </CardContent>
        </Card>

        {valueTab === 0
          ? (
            <>
              <Grid item xs={12} md={3} mb={5}>
                <TextField
                  name="search"
                  id="search"
                  label={t('Search')}
                  margin="normal"
                  variant="standard"
                  type="text"
                  my={1}
                  value={searchValue}
                  fullWidth
                  onKeyDown={(event) => {
                    // enter
                    if (event.keyCode === 13) {
                      event.preventDefault();
                      handleSearch();
                    }
                  }}
                  onChange={handleChangeSearch}
                  disabled={!accessEdit}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleSearch}
                          disabled={!accessEdit}
                        >
                          {!isEmpty(getSearch.toJS()) && !isEmpty(getSearch.toJS().items)
                            ? <ClearIcon />
                            : <SearchIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <AllClasses disabled={!accessEdit} />
            </>
          )
          : null}

        {valueTab === 1 ? (
          <>
            {accessCreateFile && (
              <Link to="/" onClick={toggleMenu} aria-owns="simple-menu" aria-haspopup="true" mb={4}>
                {t('Download list')}
              </Link>
            )}
            <Menu
              id="simple-menu"
              anchorEl={simpleMenu}
              open={Boolean(simpleMenu)}
              onClose={closeMenu}
            >
              <MenuItem>
                <Button
                  size="small"
                  color="primary"
                  disabled={pendingGetFile}
                  {
                    ...!isEmpty(getMktuTemplateById.toJS())
                      && !isEmpty(getMktuTemplateById.toJS().links) ? {
                        href: getMktuTemplateById.toJS().links.pdf,
                        target: '_blank',
                        download: true,
                      } : {}
                  }
                  {
                    ...!isEmpty(getFileById.toJS()) ? {
                      href: getFileById.toJS().pdf,
                      target: '_blank',
                      download: true,
                    } : {}
                  }
                  onClick={closeMenu}
                >
                  {pendingGetFile ? <CircularProgress size={25} color="inherit" /> : t('Download to PDF')}
                </Button>
              </MenuItem>
              <MenuItem>
                <Button
                  size="small"
                  color="primary"
                  disabled={pendingGetFile}
                  {
                    ...!isEmpty(getMktuTemplateById.toJS())
                      && !isEmpty(getMktuTemplateById.toJS().links) ? {
                        href: getMktuTemplateById.toJS().links.docx,
                        target: '_blank',
                        download: true,
                      } : {}
                  }
                  {
                    ...!isEmpty(getFileById.toJS()) ? {
                      href: getFileById.toJS().docx,
                      target: '_blank',
                      download: true,
                    } : {}
                  }
                  onClick={closeMenu}
                >
                  {pendingGetFile ? <CircularProgress size={25} color="inherit" /> : t('Download to DOCX')}
                </Button>
              </MenuItem>
            </Menu>
            {!isEmpty(selectMktuClasses.toJS()) ? (
              <Card my={3}>
                <CardContent>
                  {map(selectMktuClasses.toJS(), (mktuClass) => (
                    <SelectedClass
                      key={mktuClass.id}
                      mktuClass={mktuClass}
                    />
                  ))}
                </CardContent>
              </Card>
            ) : null}
          </>
        ) : null}

        {accessEdit && (
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              mr={2}
            >
              {pending ? <CircularProgress size={25} color="inherit" /> : t('SAVE')}
            </Button>
          </Box>
        )}
      </Form>

    </>
  ) : <Loader />;
}

MktuTemplate.displayName = 'MktuTemplate';

MktuTemplate.propTypes = {
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  getMktuTemplateById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingGetFile: PropTypes.bool.isRequired,
  pendingMktuTemplateById: PropTypes.bool.isRequired,
  pendingPostMktuTemplate: PropTypes.bool.isRequired,
  pendingPutMktuTemplate: PropTypes.bool.isRequired,
  match: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  selectMktuClasses: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  getSearch: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  getFileById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

MktuTemplate.defaultProps = {
  match: {},
};

function mapStateToProps(state) {
  return {
    getMktuTemplateById: selectors.mktuTemplates.getMktuTemplatesById(state),
    getFileById: selectors.mktuTemplates.getMktuTemplatePdfById(state),
    pendingGetFile: selectors.mktuTemplates.getPendingMktuTemplatePdfById(state),
    getSearch: selectors.rebounds.getSearchRebound(state),
    pendingMktuTemplateById: selectors.mktuTemplates.getPendingMktuTemplateById(state),
    pendingPostMktuTemplate: selectors.mktuTemplates.pendingPostMktuTemplate(state),
    pendingPutMktuTemplate: selectors.mktuTemplates.pendingPutMktuTemplate(state),

    selectMktuClasses: selectors.tradeMarksProposal.selectMktuClasses(state),

  };
}

export default compose(
  reduxForm({
    form: formName,
  }),
  connect(mapStateToProps, null),
)(MktuTemplate);
