// Core
import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

// lodash
import get from 'lodash/get';

// UI
import {
  TextField, InputAdornment, IconButton,
} from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ClearIcon from '@material-ui/icons/Clear';

// parts
import DataRangeModal from './DataRangeModal';

// helpers
import { formatDate, isSame } from '../_helpers/moment';

function ReduxDateRange(props) {
  const {
    label, input, defaultFormat,
    meta: { touched, invalid, error },
    disabled,
    ...rest
  } = props;
  const [checked, setChecked] = useState(false);
  const { t } = useTranslation();
  const computedValue = (filters) => {
    const endDate = get(filters, 'endDate', new Date());
    const startDate = get(filters, 'startDate', new Date());
    const formattedStartDate = formatDate(startDate, defaultFormat);
    const formattedEndDate = formatDate(endDate, defaultFormat);
    switch (true) {
      case !filters:
      case get(filters, 'value') === null: {
        return '';
      }
      case isSame(formattedStartDate, formattedEndDate): {
        return formatDate(startDate, defaultFormat);
      }
      default: {
        return `${formatDate(startDate, defaultFormat)} - ${formatDate(endDate, defaultFormat)}`;
      }
    }
  };

  const handleDataRangeToggle = () => {
    if (!checked && input.value) return;
    setChecked(!checked);
  };
  const onDateRangeChange = (ranges) => {
    if (ranges && ranges?.endDate !== ranges?.startDate) setChecked(false);
    input.onChange(computedValue(ranges));
  };
  const clearField = () => {
    onDateRangeChange(null);
  };
  moment.defaultFormat = defaultFormat;

  const selectDateStart = input.value ? moment(input.value.split(' - ')[0], moment.defaultFormat).toDate() : new Date();
  const selectDateEnd = input.value ? moment(input.value.split(' - ')[1], moment.defaultFormat).toDate() : new Date();

  return (
    <>
      <TextField
        label={label}
        placeholder={label}
        autoComplete="off"
        onClick={handleDataRangeToggle}
        error={touched && invalid}
        helperText={touched && error}
        // value={value}
        {...input}
        {...rest}
        InputProps={{
          // value,
          readOnly: true,
          startAdornment: (
            <InputAdornment position="end">
              <span>
                <DateRangeIcon />
              </span>
            </InputAdornment>
          ),
          endAdornment: (input.value !== '') && (
            <InputAdornment position="end">
              <IconButton aria-label="Clear filter" onClick={clearField} component="span">
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <DataRangeModal
        disabled={disabled}
        title={t('Filter by date')}
        open={checked}
        handleDataRangeToggle={handleDataRangeToggle}
        onDateRangeChange={onDateRangeChange}
        startDate={selectDateStart}
        endDate={selectDateEnd}
      />

    </>
  );
}

ReduxDateRange.propTypes = {
  label: PropTypes.string.isRequired,
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.array,
      PropTypes.object,
    ]),
  }).isRequired,
  meta: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  defaultFormat: PropTypes.string,
};

ReduxDateRange.defaultProps = {
  type: 'text',
  disabled: false,
  defaultFormat: 'DD.MM.YYYY',
};

export default ReduxDateRange;
