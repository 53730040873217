// Core
import { call, put } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';

// config
import { api } from '../../../../../config/api';

// actions
import reboundActions from '../../action';

// helpers
import { setErrorMessage } from '../../../../../../ui/_helpers/setNotificationMessage';

export function* callSearchReboundWorker({ payload: search }) {
  const { setPendingSearchRebound } = reboundActions;

  yield put(setPendingSearchRebound(true));

  const response = yield call(api.trademarks.postSearchRebound, search);

  if (response && response.status >= 200 && response.status < 400) {
    yield put(setPendingSearchRebound(false));
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
      return;
    }
    if (!isEmpty(data) && !isEmpty(data.items) && isArray(data.items)) {
      yield put(reboundActions.setSearchRebound(data.items[0]));
      return;
    }
    yield put(reboundActions.setSearchRebound({}));
  }

  yield put(setPendingSearchRebound(false));
}
