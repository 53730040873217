// core
import React, {
  memo, useCallback, useEffect, useRef, useState,
} from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { compose } from 'recompose';
import { connect, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';

// ui
import {
  Button as MuiButton,
  Card as MuiCard, CardContent, Grid, Typography, CircularProgress,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import { DropzoneAreaBase as DropZoneAreaMui } from 'material-ui-dropzone';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';

// icon
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';

// lodash
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import find from 'lodash/find';

// helpers
import { formFields } from '../../helper/form';

// actions
import uploadFileActionAsync from '../../../../engine/core/uploadFile/saga/asyncAction';
import helpersActions from '../../../../engine/core/helpers/action';

// parts
import Modal from '../../../../components/Modal/Modal';
import selectors from '../../../../engine/config/selectors';

// styles
const Card = styled(MuiCard)(spacing);
const Button = styled(MuiButton)(spacing);
const Drawer = styled.div`
  display: inline-block;
  max-width: 200px;
  margin: 10px;
  cursor: pointer;
`;
const VariantImage = styled.img`
  width: 200px;
  height: 200px;
  object-fit: contain;
  background: #F7F9FC;
  display: inline-block;
  pointer-events: none;`;

const VariantImageProgress = styled.div`
  width: 200px;
  height: 200px;
  object-fit: contain;
  background: #F7F9FC;
  display: flex;
  pointer-events: none;
  max-width: 200px;
  margin: 10px;
  align-items: center;
  justify-content: center;`;
const Spacer = styled.div(spacing);

const fileType = 'dragImage';

const SortableItem = SortableElement((props) => {
  const {
    value, setImageRemove, variantIndex, imageIndex,
    setImageName, img, disabledEdit,
  } = props;
  const [name, setName] = useState(value.name);
  const handleChange = (e) => {
    setName(e.target.value);
    setImageName(variantIndex, imageIndex, e.target.value);
  };
  return (
    <Drawer>
      <VariantImage src={img?.base64 || value.link} />
      <TextField
        id={formFields.dataApplicantsName}
        value={name}
        autoComplete="off"
        margin="normal"
        variant="standard"
        onChange={handleChange}
        fullWidth
        type="text"
        disabled={disabledEdit}
      />
      {!disabledEdit && (
        <IconButton
          onClick={() => {
            setImageRemove(variantIndex, imageIndex);
          }}
          aria-label="delete"
          size="small"
        >
          <DeleteIcon />
        </IconButton>
      )}
    </Drawer>
  );
});
const SortableList = SortableContainer(({
  items, collection, setImageRemove, setImageName,
  imagesWithBase64, disabledEdit,
}) => (
  <div>
    {map(items, (value, index) => {
      const findImg = find(imagesWithBase64.toJS(), { fileId: value.fileId });
      return (
        <SortableItem
          setImageRemove={setImageRemove}
          setImageName={setImageName}
          collection={collection}
          variantIndex={collection}
          key={`item-${value.fileId}`}
          imageIndex={index}
          index={index}
          value={value}
          img={findImg || {}}
          disabledEdit={disabledEdit}
        />
      );
    })}
  </div>
));

const DropZoneArea = styled(DropZoneAreaMui)(spacing);

function DragImages(props) {
  const {
    addImageAction, addVariantAction, setImageMove, setImageVariantRemove,
    setImageRemove, setImageName, uploadFile, children,
    items, uploadFileType, imagesWithBase64, disabled,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [variantIndex, setVariantIndex] = useState(null);
  const [loadImage, setLoadImage] = useState(false);
  const [dropZoneKey, setDropZoneKey] = useState(Math.random());
  const imageCount = useRef(0);

  const onSortEnd = ({
    oldIndex, newIndex, collection,
  }) => {
    const imagesAfterMove = arrayMove(items.toJS()[collection], oldIndex, newIndex);
    const imagesWithNewNumber = map(imagesAfterMove, (image, index) => {
      // eslint-disable-next-line no-param-reassign
      image.number = index;
      return image;
    });
    setImageMove({
      variantIndex: collection,
      data: imagesWithNewNumber,
    });
  };
  const handleAddImage = (index) => {
    setVariantIndex(index);
    setModalIsOpen(!modalIsOpen);
  };
  const handleModalIsOpen = () => {
    setModalIsOpen(!modalIsOpen);
  };

  const handleChangeDropZone = useCallback((files) => {
    if (!isEmpty(files)) {
      setLoadImage(true);
      map(files, (file) => {
        dispatch(uploadFileActionAsync.uploadFileAsync({
          file: {
            file: file.data,
            originalName: file.file?.name,
          },
          type: fileType,
          callBack: (isSuccess = true) => {
            if (isSuccess) {
              imageCount.current += 1;
              if (files.length === imageCount.current) {
                setLoadImage(false);
                imageCount.current = 0;
              }
            } else {
              setLoadImage(false);
              imageCount.current = 0;
            }
          },
        }));
        setDropZoneKey(Math.random());
      });
      setModalIsOpen(false);
    }
  }, [dispatch]);
  useEffect(() => {
    if (!isEmpty(uploadFile.toJS()) && uploadFileType === fileType && !isNil(variantIndex)) {
      dispatch(helpersActions.setImagesWithBase64([uploadFile.toJS()]));
      addImageAction({
        variantIndex,
        data: {
          name: t('General view'),
          number: items.toJS()[variantIndex]?.length,
          link: uploadFile.toJS().base64,
          fileId: uploadFile.toJS().fileId,
        },
      });
    }
  }, [uploadFile]);// eslint-disable-line
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h2" gutterBottom>
          {t('Images')}
        </Typography>
        <Spacer mb={6} />

        {map(items.toJS(), (item, index) => (
          <Grid key={index} container item xs={12} alignItems="center">
            <Typography variant="h6" gutterBottom>
              {t('Variant')}
              {' '}
              {index + 1}
            </Typography>

            {!disabled && (
              <Button
                ml={4}
                color="primary"
                onClick={() => {
                  setImageVariantRemove(index);
                }}
              >
                {t('Remove variant')}
                <DeleteIcon />
              </Button>
            )}

            {!disabled && (
              <Button
                ml={4}
                color="primary"
                onClick={() => {
                  handleAddImage(index);
                }}
              >
                {t('Add image')}
                <AddIcon />
              </Button>
            )}
            <Grid key={index} container item xs={12}>
              <SortableList
                disabledEdit={disabled}
                distance={2}
                setImageRemove={setImageRemove}
                setImageName={setImageName}
                collection={index}
                axis="xy"
                items={item}
                imagesWithBase64={imagesWithBase64}
                onSortEnd={onSortEnd}
              />
              { (variantIndex === index) && loadImage && (
              <VariantImageProgress>
                <CircularProgress />
              </VariantImageProgress>
              )}

            </Grid>
            <Spacer mb={8} />
          </Grid>
        ))}

        {!disabled && (
          <Grid item xs={12} md={3}>
            <Button mr={2} mb={4} variant="outlined" color="primary" onClick={addVariantAction}>
              {t('Add variant')}
              <AddIcon />
            </Button>
          </Grid>
        )}
        {children}
      </CardContent>

      <Modal
        title={t('Add images')}
        isModalOpen={modalIsOpen}
        handleCloseModal={handleModalIsOpen}
        displayDialogActions={false}
      >
        <DropZoneArea
          dropzoneText={t('Drag and drop a file here or click')}
          showPreviewsInDropzone={false}
          showAlerts={false}
          clearOnUnmount
          filesLimit={20}
          mt={4}
          maxFileSize={21000000}
          key={dropZoneKey}
          onAdd={handleChangeDropZone}
        />
        <Spacer mb={6} />
      </Modal>
    </Card>
  );
}

DragImages.propTypes = {
  items: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  addImageAction: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  uploadFileType: PropTypes.string,
  addVariantAction: PropTypes.func.isRequired,
  setImageMove: PropTypes.func.isRequired,
  setImageVariantRemove: PropTypes.func.isRequired,
  setImageRemove: PropTypes.func.isRequired,
  setImageName: PropTypes.func.isRequired,
  uploadFile: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  imagesWithBase64: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  disabled: PropTypes.bool,
};

DragImages.defaultProps = {
  uploadFileType: null,
  disabled: false,
};

function mapStateToProps(state) {
  return {
    uploadFile: selectors.uploadFile.uploadFile(state),
    uploadFileType: selectors.uploadFile.uploadFileType(state),
    imagesWithBase64: selectors.helpers.imagesWithBase64(state),
  };
}

export default compose(
  connect(mapStateToProps, null),
)(memo(DragImages));
