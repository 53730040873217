// Instruments
import { types } from './types';

export const uiActions = Object.freeze({
  setNotificationMessage: (notificationMessage) => ({
    type: types.SET_NOTIFICATION_MESSAGE,
    payload: notificationMessage,
  }),
  setInitialApplicationState: () => ({
    type: types.SET_INITIAL_APPLICATION_STATE,
  }),
});
