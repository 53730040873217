export default Object.freeze({
  SET_SYSTEM_MESSAGE_DATA: 'SET_SYSTEM_MESSAGE_DATA',
  SET_SYSTEM_MESSAGE_BY_ID: 'SET_SYSTEM_MESSAGE_BY_ID',
  SET_SYSTEM_MESSAGE_RELOAD: 'SET_SYSTEM_MESSAGE_RELOAD',
  SET_SYSTEM_MESSAGE_CURRENT_PAGE: 'SET_SYSTEM_MESSAGE_CURRENT_PAGE',
  SET_SYSTEM_MESSAGE_FILTERS: 'SET_SYSTEM_MESSAGE_FILTERS',
  SET_SYSTEM_MESSAGE_SORTING: 'SET_SYSTEM_MESSAGE_SORTING',
  SET_SYSTEM_MESSAGE_PAGE_SIZE: 'SET_SYSTEM_MESSAGE_PAGE_SIZE',
  SET_SYSTEM_MESSAGE_INITIAL_STATE: 'SET_SYSTEM_MESSAGE_INITIAL_STATE',
  SET_DELETE_SYSTEM_MESSAGE: 'SET_DELETE_SYSTEM_MESSAGE',
  SET_SYSTEM_MESSAGE_IS_MODAL_OPEN: 'SET_SYSTEM_MESSAGE_IS_MODAL_OPEN',
  SET_SYSTEM_MESSAGE_DATA_BY_ID: 'SET_SYSTEM_MESSAGE_DATA_BY_ID',
  SET_SYSTEM_MESSAGE_COLUMN_WIDTHS: 'SET_SYSTEM_MESSAGE_COLUMN_WIDTHS',
  SET_SYSTEM_MESSAGE_COLUMN_ORDER: 'SET_SYSTEM_MESSAGE_COLUMN_ORDER',
  SET_SYSTEM_MESSAGE_HIDDEN_COLUMN_NAMES: 'SET_SYSTEM_MESSAGE_HIDDEN_COLUMN_NAMES',
  SET_SYSTEM_MESSAGE_ALL_LIST: 'SET_SYSTEM_MESSAGE_ALL_LIST',
  SET_SYSTEM_MESSAGE_DISABLE_RELOAD: 'SET_SYSTEM_MESSAGE_DISABLE_RELOAD',
  SET_SYSTEM_MESSAGE_FILTERS_CLEAR: 'SET_SYSTEM_MESSAGE_FILTERS_CLEAR',
});
