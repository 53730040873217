// Core
import { Map, List } from 'immutable';

// Instruments
import types from './types';

// config
import globalStateKeys from '../../../config/globalStateKeys';

const initialState = Map({
  data: Map({
    items: List(),
    pending: false,
    totalCount: 0,
    isModalOpenAddWork: false,
  }),
  delete: Map({
    pendingDelete: false,
    id: '',
  }),
  deleteNested: Map({
    pendingDeleteNested: false,
    item: '',
    costId: '',
    itemCosts: '',
  }),
  itemByHashId: {
    pending: false,
    id: '',
    idWorkData: {},
  },
});

export const stateKeys = Object.freeze({
  controller: 'pricesWorks',
  isModalOpenAddWork: 'isModalOpenAddWork',
  idWorkData: 'idWorkData',
});

export const pricesWorksReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_PRICES_WORKS_LIST: {
      return state.mergeIn([globalStateKeys.data], payload);
    }
    case types.SET_DELETE_PRICES_WORK: {
      return state.mergeIn([globalStateKeys.delete], payload);
    }
    case types.SET_PUT_PRICES_WORK: {
      return state.mergeIn([globalStateKeys.deleteNested], payload);
    }
    case types.SET_PRICES_WORK_BY_ID: {
      return state.mergeIn([globalStateKeys.itemByHashId], payload);
    }
    default:
      return state;
  }
};
