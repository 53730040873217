// Core
import { call, put, select } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';

// config
import { api } from '../../../../config/api';
import { messageRequestSuccess } from '../../../../config/globalConfig';
import selectors from '../../../../config/selectors';

// init
import i18n from '../../../../init/i18n';

// actions
import actions from '../../action';
import actionAsync from '../asyncAction';
import helpersActions from '../../../helpers/action';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../ui/_helpers/setNotificationMessage';
import { formatDate, getValueFormatDate } from '../../../../_helpers/formatterDate';

export function* callPutDepartmentDocumentByIdWorker({ payload: document }) {
  const { setDepartmentDocumentById, setAdditionalDocuments } = actions;
  const { getDepartmentDocumentByIdAsync } = actionAsync;

  yield put(setDepartmentDocumentById({
    pendingPut: true,
  }));

  const permissionSign = yield select(selectors.helpers.permissionSign);

  const filterDocumentData = pickBy(document, (value, key) => key !== 'params');

  const response = yield call(api.departmentDocuments.putDepartmentDocumentById, {
    ...filterDocumentData,
    ...!isEmpty(document.receivingDate) ? {
      receivingDate: getValueFormatDate(formatDate(document.receivingDate), 'receivingDate'),
    } : {},
  });

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      yield put(getDepartmentDocumentByIdAsync(data.id || document.id));
      yield put(setSuccessMessage(i18n.t(messageRequestSuccess), response.status.toString()));
      yield put(setAdditionalDocuments([]));
      if (permissionSign) {
        yield put(helpersActions.setSignIsModalOpen(true));
      }
    }
  }

  yield put(setDepartmentDocumentById({
    pendingPut: false,
  }));
  yield put(helpersActions.setPermissionSign(false));
}
