// Core
import { all, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetCurrencyRatesWorker,
  callPutCurrencyRateByIdWorker,
  callPostCurrencyRateWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callDeleteCurrencyRateWorker,
  callGetAutocompleteOptionsWorker,
} from './workers';
import types from '../types';

function* watchGetCurrencyRates() {
  yield takeEvery(asyncTypes.GET_CURRENCY_RATES_ASYNC, callGetCurrencyRatesWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_CURRENCY_RATES_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchPostCurrencyRate() {
  yield takeEvery(asyncTypes.POST_CURRENCY_RATE_ASYNC, callPostCurrencyRateWorker);
}
function* watchPutCurrencyRateById() {
  yield takeEvery(asyncTypes.PUT_CURRENCY_RATE_BY_ID_ASYNC, callPutCurrencyRateByIdWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_CURRENCY_RATES_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_CURRENCY_RATES_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_CURRENCY_RATES_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_CURRENCY_RATES_SORTING, callSetToLocalDataWorker);
}
function* watchSetColumnWidths() {
  yield takeEvery(types.SET_CURRENCY_RATES_COLUMN_WIDTHS, callSetToLocalDataWorker);
}
function* watchSetColumnOrder() {
  yield takeEvery(types.SET_CURRENCY_RATES_COLUMN_ORDER, callSetToLocalDataWorker);
}
function* watchSetHiddenColumnNames() {
  yield takeEvery(types.SET_CURRENCY_RATES_HIDDEN_COLUMN_NAMES, callSetToLocalDataWorker);
}
function* watchDeleteCurrencyRate() {
  yield takeEvery(asyncTypes.DELETE_CURRENCY_RATE_ASYNC, callDeleteCurrencyRateWorker);
}

function* watchGetAutocompleteOptions() {
  yield takeEvery(
    asyncTypes.GET_CURRENCY_RATES_AUTOCOMPLETE_OPTIONS_ASYNC,
    callGetAutocompleteOptionsWorker,
  );
}

export function* watchersCurrencyRates() {
  yield all([
    watchGetCurrencyRates(),
    watchGetInitialState(),
    watchPostCurrencyRate(),
    watchPutCurrencyRateById(),
    watchSetCurrentPage(),
    watchSetPageSize(),
    watchSetFilters(),
    watchSetSorting(),
    watchSetColumnWidths(),
    watchSetColumnOrder(),
    watchSetHiddenColumnNames(),
    watchDeleteCurrencyRate(),
    watchGetAutocompleteOptions(),
  ]);
}
