// Core
import React from 'react';
import styled from 'styled-components';
import { Link as MuiLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';

// lodash
import {
  get, map, isObject, isString, isNumber, isArray,
  isInteger, isEmpty, find, last, isEqual, filter,
  isBoolean, includes, floor,
} from 'lodash';

// UI
import {
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import {
  Avatar as MuiAvatar, TextField, SvgIcon as MuiSvgIcon,
  Grid, Typography as MuiTypography, Chip as MuiChip,
  Tooltip as MuiTooltip, Box, Checkbox as MuiCheckbox, IconButton,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import { withStyles } from '@material-ui/core/styles';
import {
  Edit as MuiEditIcon, PlaylistAdd as PlaylistAddIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from '@material-ui/icons';
import { ReactComponent as RabbitFastIcon } from '../../../_helpers/img/rabbit-fast.svg';
import { ReactComponent as TurtleIcon } from '../../../_helpers/img/turtle.svg';
import { ReactComponent as TagsIcon } from '../../../_helpers/img/tags.svg';

// config
import selectors from '../../../../engine/config/selectors';
import globalObjectTypes from '../../../../engine/config/globalObjectTypes';
import globalExecutors from '../../../../engine/config/globalExecutors';
import { borderColorNotStatusChip } from '../../../../engine/config/globalConfig';
import { ReactComponent as CheckIcon } from '../../../_helpers/img/check.svg';
import globalStatusesAction from '../../../../engine/config/globalStatusesAction';
import i18n from '../../../../engine/init/i18n';
import accessList from '../../../../engine/config/accessList';

// actions
import chargesActions from '../../../../engine/core/charges/action';
import docTemplateAsyncActions from '../../../../engine/core/documentTemplates/saga/asyncAction';
import documentTypesActions from '../../../../engine/core/documentTypes/action';
import documentTypesAsyncAction from '../../../../engine/core/documentTypes/saga/asyncAction';
import documentTypesCategoryActions from '../../../../engine/core/documentTypes/category/action';
import documentTypesCategoryAsyncAction from '../../../../engine/core/documentTypes/category/saga/asyncAction';
import paymentReceiversAsyncActions from '../../../../engine/core/paymentReceivers/saga/asyncAction';
import notificationsActions from '../../../../engine/core/notifications/action';
import notificationsAsyncAction from '../../../../engine/core/notifications/saga/asyncAction';
import applicantsActionAsync from '../../../../engine/core/applicants/saga/asyncAction';
import pendingActionsAsync from '../../../../engine/core/pendingActions/saga/asyncAction';
import pendingActions from '../../../../engine/core/pendingActions/action';
import integrationActions from '../../../../engine/core/company/integration/action';
import integrationActionAsync from '../../../../engine/core/company/integration/saga/asyncAction';
import fieldsSettingsActionAsync from '../../../../engine/core/fieldsSettings/saga/asyncAction';
import helpersActionAsync from '../../../../engine/core/helpers/saga/asyncAction';
import helpersAction from '../../../../engine/core/helpers/action';
import jurisdictionsActions from '../../../../engine/core/jurisdictions/action';
import jurisdictionsActionAsync from '../../../../engine/core/jurisdictions/saga/asyncAction';
import loggingActions from '../../../../engine/core/logging/action';
import loggingActionAsync from '../../../../engine/core/logging/saga/asyncAction';
import emailActionAsync from '../../../../engine/core/email/email/saga/asyncAction';
import emailActions from '../../../../engine/core/email/email/action';
import categoryActions from '../../../../engine/core/prices/category/action';
import categoryActionAsync from '../../../../engine/core/prices/category/saga/asyncAction';

// routes
import { pageLinks } from '../../../../routes';

// styles
const EditIcon = styled(MuiEditIcon)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Tooltip = styled(MuiTooltip)(spacing);
const Checkbox = styled(MuiCheckbox)(spacing);
const Chip = styled(MuiChip)`
  ${spacing};

  font-size: 0.7rem;
`;
export const Link = styled(MuiLink)`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const Avatar = styled(MuiAvatar)`
  width: 70%;
  height: auto;
  .MuiAvatar-img {
    width: 100%;
    height: 100%;
  }
`;

const Uppercase = styled.span`
  text-transform: uppercase;
`;

const TextLine = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const SvgIcon = styled(MuiSvgIcon)`
  color: #4caf50;
`;

const TypographyDate = styled(MuiTypography)`
  border-bottom: 1px dashed;
`;
const ObjectTitle = styled(MuiTypography)`
  text-transform: lowercase;
  &::first-letter {
    text-transform: uppercase;
  }
`;
const TypographyNote = styled(MuiTypography)`
  border-bottom: 1px dashed;
  cursor: pointer;
  width: fit-content;
`;
const TypographyLink = styled(MuiTypography)`
  color: #1976d2;
  text-decoration: underline;
  cursor: pointer;
`;
const TableCellCursor = styled(VirtualTable.Cell)`
  cursor: pointer;
`;
const NowrapText = styled.span`
  white-space: nowrap;
`;

export const LinkId = styled(MuiLink)`
  text-decoration: underline;
  color: #1976d2;
`;
const LinkMadrid = styled(MuiLink)`
  text-decoration: underline;
  color: ${(props) => props.theme.palette.common.black};
`;
export const LinkClient = styled(MuiLink)`
  color: ${(props) => props.theme.palette.common.black};
  text-decoration: none;
  border-bottom: 1px dashed;
  cursor: pointer;
`;
export const LinkTitle = styled(MuiLink)`
  color: ${(props) => props.theme.palette.common.black};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
const TooltipObj = withStyles(() => ({
  tooltip: {
    whiteSpace: 'pre-line',
    maxWidth: 'none',
  },
}))(MuiTooltip);

function TableCell(props) {
  const { row, column, value } = props;
  const { t } = useTranslation();
  const objectStatuses = useSelector(selectors.helpers.getObjectStatuses);
  const sumChargesInput = useSelector(selectors.charges.sumChargesInput);
  const clientsData = useSelector(selectors.clientsTable.clientsData);
  const path = useSelector(selectors.router.pathname);
  const userInfo = useSelector(selectors.user.userInfo);
  const hash = useSelector(selectors.router.hash);
  const dispatch = useDispatch();

  switch (column.name) {
    case 'id': {
      const checkPath = (link) => {
        switch (link) {
          case pageLinks.tradeMarksRoutes.proposal.all: {
            return pageLinks.tradeMarksRoutes.proposal.edit(value);
          }
          case pageLinks.industrialDesigns.all: {
            return pageLinks.industrialDesigns.edit(value);
          }
          case pageLinks.utilityModels.all: {
            return pageLinks.utilityModels.edit(value);
          }
          case pageLinks.inventions.all: {
            return pageLinks.inventions.edit(value);
          }
          case '/trade-marks/rebounds': {
            return pageLinks.tradeMarksRoutes.rebounds.edit(value);
          }
          case '/trade-marks/mktu-template': {
            return pageLinks.tradeMarksRoutes.mktuTemplates.edit(value);
          }
          case '/trade-marks/searches': {
            return pageLinks.tradeMarksRoutes.searches.edit(value);
          }
          case '/trade-marks/monitoring': {
            return pageLinks.tradeMarksRoutes.monitoring.edit(value);
          }
          case '/documents': {
            return pageLinks.documents.departmentDocuments.edit(value);
          }
          case '/employees': {
            return pageLinks.company.employees.edit(value);
          }
          case '/clients': {
            return pageLinks.company.clients.edit(value);
          }
          case '/orders': {
            return pageLinks.company.orders.edit(value);
          }
          case '/payments': {
            return pageLinks.company.payments.edit(value);
          }
          case '/companies': {
            return pageLinks.company.companies.edit(value);
          }
          case '/prices/tariffs': {
            return pageLinks.prices.tariffs.edit(value);
          }
          case pageLinks.taxes.all: {
            return pageLinks.taxes.edit(value);
          }
          case pageLinks.products.all: {
            return pageLinks.products.edit(value);
          }
          case pageLinks.tradeMarksRoutes.madridCases.all: {
            return pageLinks.tradeMarksRoutes.madridCases.edit(value);
          }
          default: {
            switch (hash) {
              // case '#docs':{
              //   return pageLinks.documents.departmentDocuments.edit(value)
              // }
              case '#finance': {
                if (row.charges) return pageLinks.company.orders.edit(value);
                return '';
              }
              default: {
                return '';
              }
            }
          }
        }
      };
      const accessName = (link) => {
        if (hash === '#docs') return accessList.documents_get;
        if (hash === '#finance') return accessList.orders_get;
        switch (link) {
          case pageLinks.tradeMarksRoutes.proposal.all: return accessList.trademarks_get;
          case pageLinks.industrialDesigns.all: return accessList.industrial_get;
          case pageLinks.utilityModels.all: return accessList.utility_get;
          case pageLinks.inventions.all: return accessList.inventions_get;
          case pageLinks.tradeMarksRoutes.madridCases.all: return accessList.madrid_case_get;
          case '/trade-marks/rebounds': return accessList.mktu_get;
          case '/trade-marks/mktu-template': return accessList.mktuTemplate_get;
          case '/trade-marks/searches': return accessList.search_get;
          case '/trade-marks/monitoring': return accessList.monitoring_get;
          case '/documents': return accessList.documents_get;
          case '/employees': return accessList.employees_get;
          case '/clients': return accessList.clients_get;
          case '/orders': return accessList.orders_get;
          case '/payments': return accessList.payments_get;
          case '/companies': return accessList.company_get;
          case '/prices/tariffs': return accessList.costs_get;
          case pageLinks.taxes.all: return accessList.taxes_get;
          case pageLinks.products.all: return accessList.products_get;
          case '/logging': return accessList.logging_get;
          case '/jurisdictions': return accessList.jurisdictions_get;
          case '/email': return accessList.email_get;
          case '/applicants': return accessList.applicants_get;
          case '/integrations': return accessList.authorityIntegration_get;
          case '/payment-receivers': return accessList.paymentReceivers_get;
          case '/document-types': return accessList.documentClassifier_get;
          case '/notifications': return accessList.notifications_get;
          case '/pending-actions-table': return accessList.pendingActions_get;
          case '/': return row.status ? accessList.pendingActions_get : accessList.false;
          case '/prices/categories': return accessList.costsCategories_get;
          case '/documents/templates': return accessList.documentsTemplate_get;
          default: {
            return '';
          }
        }
      };
      const checkModal = (link) => {
        if (hash === '#docs') return true;
        switch (link) {
          case '/logging':
          case '/jurisdictions':
          case '/email':
          case '/applicants':
          case '/integrations':
          case '/payment-receivers':
          case '/document-types':
          case '/notifications':
          case '/pending-actions-table':
          case '/':
          case '/prices/categories':
          case '/documents/templates': {
            return !column.disabledLink;
          }
          default: {
            return false;
          }
        }
      };
      const checkEntityName = () => {
        if (includes(path, 'trade-marks')) return 'trademarks';
        if (includes(path, 'industrial-designs')) return 'industrial';
        if (includes(path, 'inventions')) return 'inventions';
        if (includes(path, 'utility-models')) return 'utility';
        return '';
      };
      const openModal = () => {
        if (hash === '#docs' && checkEntityName()) {
          dispatch(helpersAction.setLastObject({ entityId: last(path.split('/')), entityName: checkEntityName() }));
          dispatch(push(pageLinks.documents.departmentDocuments.edit(value)));
          return;
        }
        switch (path) {
          case '/applicants': {
            dispatch(applicantsActionAsync.getApplicantByIdAsync({
              id: value,
              isModal: true,
            }));
            break;
          }
          case '/':
          case '/pending-actions-table': {
            dispatch(pendingActions.setIsModalOpen(true));
            dispatch(pendingActionsAsync.getPendingActionByIdAsync(value));
            break;
          }
          case '/prices/categories': {
            dispatch(categoryActions.setIsModalOpen(true));
            dispatch(categoryActionAsync.getCategoryByIdAsync(value));
            break;
          }
          case '/documents/templates': {
            dispatch(docTemplateAsyncActions.getDocumentTemplatesByIdAsync(value));
            break;
          }
          case '/document-types': {
            if (row?.documentClassifier || row?.index) {
              dispatch(
                documentTypesCategoryAsyncAction.getDocumentTypeCategoryByIdAsync(value),
              );
              dispatch(
                documentTypesCategoryActions.setDocumentTypeCategoryById({ isModalOpen: true }),
              );
              break;
            }
            dispatch(documentTypesAsyncAction.getDocumentTypeByIdAsync(value));
            dispatch(documentTypesActions.setDocumentTypeById({
              data: row,
              isModalOpen: true,
            }));
            break;
          }
          case '/payment-receivers': {
            dispatch(paymentReceiversAsyncActions.getPaymentReceiversByIdAsync(value));
            break;
          }
          case '/notifications': {
            dispatch(notificationsAsyncAction.getNotificationByIdAsync(value));
            dispatch(notificationsActions.setNotificationById({
              // data: row,
              isModalOpen: true,
            }));
            break;
          }
          case '/integrations': {
            dispatch(fieldsSettingsActionAsync.getFieldsSettingsAsync({
              entity: 'AuthorityIntegration',
            }));
            dispatch(integrationActions.setIsModalOpen(true));
            dispatch(integrationActionAsync.getIntegrationByIdAsync(value));
            break;
          }
          case '/jurisdictions': {
            dispatch(jurisdictionsActions.setIsModalOpen(true));
            dispatch(jurisdictionsActionAsync.getJurisdictionByIdAsync(value));
            break;
          }
          case '/logging': {
            dispatch(loggingActions.setIsModalOpen(true));
            dispatch(loggingActionAsync.getLoggingByIdAsync(value));
            break;
          }
          case '/email': {
            dispatch(emailActions.setIsModalOpenMessage(true));
            dispatch(emailActionAsync.getThreadsMessagesAsync(value));
            break;
          }
          default: {
            break;
          }
        }
      };
      if (column.customField === 'tmID') {
        return (
          <VirtualTable.Cell>
            <LinkId
              to={pageLinks.tradeMarksRoutes.proposal.edit(value)}
            >
              {value}
            </LinkId>
          </VirtualTable.Cell>
        );
      }
      return (
        ((checkModal(path) || checkPath(path)) && includes(userInfo.permissions, accessName(path)))
          ? (
            <VirtualTable.Cell>
              {checkPath(path) ? (
                <LinkId
                  to={checkPath(path)}
                >
                  {value}
                </LinkId>
              ) : (
                <TypographyLink
                  component="span"
                  onClick={openModal}
                >
                  {value}
                </TypographyLink>
              )}
            </VirtualTable.Cell>
          ) : (
            <VirtualTable.Cell {...props} />
          )
      );
    }
    case 'title': {
      const checkLink = (rowAction) => {
        if (rowAction.trademarks) {
          return pageLinks.tradeMarksRoutes.proposal.edit(isObject(rowAction.trademarks)
            ? rowAction.trademarks.id : rowAction.trademarks);
        }
        if (rowAction.inventions) {
          return pageLinks.inventions.edit(isObject(rowAction.inventions)
            ? rowAction.inventions.id : rowAction.inventions);
        }
        if (rowAction.utility) {
          return pageLinks.utilityModels.edit(isObject(rowAction.utility)
            ? rowAction.utility.id : rowAction.utility);
        }
        if (rowAction.industrial) {
          return pageLinks.industrialDesigns.edit(isObject(rowAction.industrial)
            ? rowAction.industrial.id : rowAction.industrial);
        }
        return '';
      };
      const titleAction = (entity) => (
        `${!isEmpty(entity?.caseNumber)
          ? `${t('Case')}: ${entity.caseNumber} \n` : ''}${!isEmpty(entity?.applicationNumber)
          ? `${t('Application')}: ${entity.applicationNumber} \n` : ''}${!isEmpty(entity?.title)
          ? `${t('Title')}: ${entity.title} \n` : ''}`
      );
      return (
        (column.customField === 'titleLogo' || column.customField === 'titleObject'
        || column.customField === 'ProposalDocuments' || column.customField === 'titleAction') ? (
          <VirtualTable.Cell>
            {column.customField === 'titleLogo' && (
              <Grid container alignItems="center" justify="center">
                {row.logo ? (
                  <Avatar
                    alt="avatar"
                    variant="square"
                    src={row.logo}
                  />
                ) : (
                  <Typography align="center" component="small" variant="inherit">
                    {row.title || row.name || row.label}
                  </Typography>
                )}
              </Grid>
            )}
            {column.customField === 'titleObject' && (
              <ObjectTitle>
                {value}
              </ObjectTitle>
            )}
            {column.customField === 'ProposalDocuments' && (
              <LinkTitle to={pageLinks.documents.departmentDocuments.edit(row.id)}>
                {value}
              </LinkTitle>
            )}
            {column.customField === 'titleAction' && (
              <TooltipObj
                title={titleAction(row.trademarks || row.inventions
                  || row.utility || row.industrial)}
                placement="top-start"
              >
                {checkLink(row) ? (
                  <LinkClient to={checkLink(row)}>
                    {value}
                  </LinkClient>
                ) : (
                  <TypographyNote>
                    {value}
                  </TypographyNote>
                )}
              </TooltipObj>
            )}
          </VirtualTable.Cell>
          ) : (
            <VirtualTable.Cell {...props} />
          )
      );
    }
    case 'firstImage': {
      return (
        <VirtualTable.Cell>
          <Grid container alignItems="center" justify="center">
            {value && (
              <Avatar
                alt="avatar"
                variant="square"
                src={value}
              />
            )}
          </Grid>
        </VirtualTable.Cell>
      );
    }
    case 'documents': {
      const id = get(value, ['id'], '');
      const title = get(value, ['title'], '');

      return (
        <VirtualTable.Cell>
          <Tooltip title={title} placement="top-start">
            <LinkTitle to={pageLinks.documents.departmentDocuments.edit(id)}>
              {id}
            </LinkTitle>
          </Tooltip>
        </VirtualTable.Cell>
      );
    }
    case 'industrial':
    case 'inventions':
    case 'utility':
    case 'trademarks': {
      const appNum = get(value, ['applicationNumber'], null);
      const caseNum = get(value, ['caseNumber'], null);
      const regNum = get(value, ['registrationNumber'], null);
      const interTitle = get(value, ['internalTitle'], null);
      const title = get(value, ['title'], null);
      const id = get(value, ['id'], null);
      const idTMArray = isArray(value) && isInteger(value[0]) ? value[0] : null;
      const idTMNumber = isInteger(value) ? value : null;
      const idTM = idTMArray || idTMNumber;
      // const foundTM = find(tradeMarksData.toJS().items, { id: idTM });
      // const labelTM = !isEmpty(foundTM)
      //   ? foundTM.registrationNumber || foundTM.applicationNumber
      // || foundTM.caseNumber || foundTM.id
      //   : null;

      const message = (caseNumber, applicationNumber, registrationNumber, internalTitle) => (
        `${!isEmpty(caseNumber) ? `${t('Case')}: ${caseNumber} \n` : ''}${!isEmpty(applicationNumber)
          ? `${t('Application')}: ${applicationNumber} \n` : ''} ${!isEmpty(registrationNumber)
          ? `${t('Registration')}: ${registrationNumber} \n` : ''} ${!isEmpty(internalTitle)
          ? `${t('Title')}: ${internalTitle} \n` : ''}`
      );

      const checkName = (name, objId) => {
        switch (name) {
          case 'trademarks': {
            return pageLinks.tradeMarksRoutes.proposal.edit(objId);
          }
          case 'inventions': {
            return pageLinks.inventions.edit(objId);
          }
          case 'utility': {
            return pageLinks.utilityModels.edit(objId);
          }
          case 'industrial': {
            return pageLinks.industrialDesigns.edit(objId);
          }
          default: {
            return '';
          }
        }
      };

      return (
        <VirtualTable.Cell>
          {column.customField === 'documents' ? (
            isArray(value) && !isEmpty(value) && (
              map(value, (item, index) => (
                <TooltipObj
                  title={message(item.caseNumber, item.applicationNumber,
                    item.registrationNumber, item.label)}
                  placement="top-start"
                  key={item.id}
                >
                  <LinkTitle
                    to={checkName(column.name, item.id)}
                  >
                    {item.caseNumber || item.id}
                    {index + 1 === value.length ? '' : ', '}
                  </LinkTitle>
                </TooltipObj>
              ))
            )
          ) : (
            <TooltipObj title={message(caseNum, appNum, regNum, interTitle || title)} placement="top-start">
              <LinkTitle
                to={checkName(column.name, id || idTM)}
              >
                {caseNum || id || idTM}
              </LinkTitle>
            </TooltipObj>
          )}
        </VirtualTable.Cell>
      );
    }
    case 'client':
    case 'clients': {
      const foundClient = find(clientsData.toJS().items, { id: value });
      const name = get(foundClient, ['name'], null);
      const description = get(row, 'description', '');
      const nameClientsObj = get(value, 'name', '');

      return (
        column.customField ? (
          <VirtualTable.Cell>
            {column.customField === 'paymentsTable' && (
              <Tooltip title={description} placement="top-start">
                <LinkClient
                  to={pageLinks.company.clients.edit(value)}
                >
                  {name || value}
                </LinkClient>
              </Tooltip>
            )}
            {column.customField === 'objectTable' && (
              <MuiTypography>
                <Tooltip title={nameClientsObj} placement="top-start">
                  <TextLine>{nameClientsObj}</TextLine>
                </Tooltip>
              </MuiTypography>
            )}
          </VirtualTable.Cell>
        ) : (
          <VirtualTable.Cell>
            <LinkTitle
              to={pageLinks.company.clients.edit(value)}
            >
              {name || nameClientsObj || value}
            </LinkTitle>
          </VirtualTable.Cell>
        )
      );
    }
    case 'clientsApplicant': {
      const name = get(row, ['clients', 'name'], '');
      const id = get(row, ['clients', 'id'], null);
      return (
        <VirtualTable.Cell>
          <LinkClient
            to={pageLinks.company.clients.edit(id)}
          >
            {name}
          </LinkClient>
        </VirtualTable.Cell>
      );
    }
    case 'clientName': {
      const name = get(row, ['clients', 'name'], '');
      const industrial = get(row, 'industrial', {});
      const inventions = get(row, 'inventions', {});
      const trademarks = get(row, 'trademarks', {});
      const utility = get(row, 'utility', {});
      const charges = map(get(row, 'charges', []), (item) => `${item.name} - ${item.sum}`).join('\n');
      const arrayObj = [trademarks, industrial, utility, inventions];
      const filterArray = filter(arrayObj, (item) => !isEmpty(item)
        && (item.internalTitle || item.caseNumber));
      const titleObj = map(filterArray, (item) => `${item.internalTitle} (${item.caseNumber})`).join(',');
      const title = `${!isEmpty(titleObj) ? `${titleObj}:\n` : ''}${charges}`;
      return (
        <VirtualTable.Cell>
          <TooltipObj title={title} placement="top-start">
            <TypographyDate component="span">
              {name}
            </TypographyDate>
          </TooltipObj>
        </VirtualTable.Cell>
      );
    }
    case 'orders':
    case 'link': {
      return (
        <VirtualTable.Cell>
          {Boolean(row.link) && isString(row.link) && (
            <a href={row.link} target="_self" rel="noopener noreferrer" download>
              {row.link}
            </a>
          )}
          {Boolean(row.link) && isObject(row.link) && (
            <a href={row.link.link} target="_self" rel="noopener noreferrer" download>
              {row.link.filename}
            </a>
          )}
          {Boolean(row.charge) && isObject(row.charge) && row.charge.orders && (
            <Link
              to={pageLinks.company.orders.edit(row.charge.orders)}
              aria-owns="simple-menu"
              aria-haspopup="true"
            >
              {row.charge.orders}
            </Link>
          )}
          {Boolean(column.customField === 'chargesField' && row.orders) && (
            <Link
              to={pageLinks.company.orders.edit(row.orders)}
              aria-owns="simple-menu"
              aria-haspopup="true"
            >
              {row.orders}
            </Link>
          )}
        </VirtualTable.Cell>
      );
    }
    case 'status': {
      if (column.customField === 'statusAction') {
        const foundStatusAction = find(globalStatusesAction, { value: value?.toString() });
        const getStyle = (color) => ({
          color: 'white',
          backgroundColor: color,
          borderColor: borderColorNotStatusChip,
        });
        return (
          <VirtualTable.Cell {...props}>
            {!isEmpty(foundStatusAction) && (
              <Grid container alignItems="center" justify="center">
                <Chip
                  pb={0.5}
                  size="small"
                  variant="outlined"
                  label={foundStatusAction.name}
                  style={getStyle(foundStatusAction.color)}
                />
              </Grid>
            )}
          </VirtualTable.Cell>
        );
      }
      const foundStatusNumber = find(objectStatuses.toJS(), { id: Math.abs(value) });
      const getStyle = (statusData) => ({
        ...statusData ? {
          color: statusData === '#e0e0e0' ? 'black' : 'white',
          backgroundColor: statusData,
        } : {
          color: 'black',
          borderColor: borderColorNotStatusChip,
        },
      });
      return (
        <VirtualTable.Cell>
          {foundStatusNumber && (
            <Grid container alignItems="center" justify="center">
              <Chip
                pb={0.5}
                size="small"
                label={foundStatusNumber.label}
                {...(isNumber(value) && !foundStatusNumber.color) || !value ? {
                  variant: 'outlined',
                } : {}}
                style={isNumber(value) && foundStatusNumber.color
                  ? getStyle(foundStatusNumber.color) : getStyle()}
              />
            </Grid>
          )}
        </VirtualTable.Cell>
      );
    }
    case 'payed':
    case 'success': {
      const getStyle = (statusData) => ({
        ...statusData ? {
          color: 'white',
          backgroundColor: statusData,
        } : {
          backgroundColor: statusData,
        },
      });

      return (
        <VirtualTable.Cell>
          <Grid container alignItems="center" justify="center">
            <Chip
              pb={0.5}
              pr={3}
              pl={3}
              size="small"
              label={value ? t('Yes') : t('No')}
              style={value ? getStyle('#4caf50') : getStyle('#fc4b4b')}
            />
          </Grid>
        </VirtualTable.Cell>
      );
    }
    case 'name': {
      return (
        (column.customField === 'nameJurisdictions' ? (
          <VirtualTable.Cell>
            <Grid container alignItems="center">
              {row.icon && (
                <img
                  style={{ width: 20, marginRight: 5 }}
                  src={row.icon}
                  alt={t('Image')}
                />
              )}
              <span>{value}</span>
            </Grid>
          </VirtualTable.Cell>
        ) : (
          <VirtualTable.Cell {...props}>
            {isObject(value) && (
              <span>{value[i18n.language] || ''}</span>
            )}
            {(Boolean(row.charge) && isObject(row.charge) && Boolean(row.charge.name)) && (
              <span>{row.charge.name}</span>
            )}
            {Boolean(row.name) && !isObject(value) && (
              <span>{row.name}</span>
            )}
          </VirtualTable.Cell>
        ))
      );
    }
    case 'jurisdictions': {
      if (column.customField === 'productTable' && isArray(value)) {
        const title = map(value, 'name').join(', ');
        return (
          <VirtualTable.Cell>
            <span>{title}</span>
          </VirtualTable.Cell>
        );
      }
      return (
        <VirtualTable.Cell>
          {isObject(value) && !isEmpty(value) && (
            <Grid container alignItems="center">
              {value.icon && (
                <img
                  style={{ width: 20, marginRight: 5 }}
                  src={value.icon}
                  alt={t('Image')}
                />
              )}
              <span>
                {value.name}
                <Uppercase>{` (${value.code})`}</Uppercase>
              </span>
            </Grid>
          )}
        </VirtualTable.Cell>
      );
    }
    case 'category':
    case 'holders':
    case 'applicants': {
      if (!isEmpty(value) && isArray(value)) {
        const title = map(value, 'name').join(', ');
        return (
          <VirtualTable.Cell {...props}>
            <Tooltip title={title} placement="top-start">
              <TextLine>{title}</TextLine>
            </Tooltip>
          </VirtualTable.Cell>
        );
      }
      if (column.customField === 'productTable' && isObject(value)) {
        return (
          <VirtualTable.Cell>
            <span>{value?.name}</span>
          </VirtualTable.Cell>
        );
      }
      return <VirtualTable.Cell {...props} />;
    }
    case 'sendingLog': {
      const sendingArray = value || [];
      const status = get(last(sendingArray), ['status'], '');
      const message = get(last(sendingArray), ['message'], '');

      const getStyle = (statusData) => (
        statusData === 'accepted' ? {
          color: 'white',
          backgroundColor: '#4caf50',
        } : {
          color: 'white',
          backgroundColor: '#fc4b4b',
        }
      );

      return (
        <VirtualTable.Cell>
          {!isEmpty(status) && (
            <Tooltip title={message} placement="top-start">
              <Chip
                pb={0.5}
                pr={3}
                pl={3}
                size="small"
                label={t(status)}
                style={getStyle(status)}
              />
            </Tooltip>
          )}
        </VirtualTable.Cell>
      );
    }
    case 'renewal': {
      const dateTo = get(value, 'dateTo', '');
      const color = get(value, 'color', '');

      return (
        <VirtualTable.Cell>
          {!isEmpty(dateTo) && (
            <Chip
              pb={0.5}
              pr={3}
              pl={3}
              size="small"
              label={t(dateTo)}
              style={{ color: color ? 'white' : 'black', backgroundColor: color }}
            />
          )}
        </VirtualTable.Cell>
      );
    }
    case 'senderEmail': {
      const getValue = column.folder === 'INBOX' ? value : map(row.recipients, 'email').join(', ');
      const handleClick = () => {
        dispatch(emailActions.setIsModalOpenMessage(true));
        dispatch(emailActionAsync.getThreadsMessagesAsync(row.id));
      };
      return (
        <TableCellCursor {...props} onClick={handleClick}>
          <span>{getValue}</span>
        </TableCellCursor>
      );
    }
    case 'subject': {
      const handleClick = () => {
        dispatch(emailActions.setIsModalOpenMessage(true));
        dispatch(emailActionAsync.getThreadsMessagesAsync(row.id));
      };
      return (
        <TableCellCursor {...props} onClick={handleClick}>
          <NowrapText style={{ fontWeight: row.isRead ? 'inherit' : 'bold' }}>{value}</NowrapText>
        </TableCellCursor>
      );
    }
    case 'snippet': {
      const handleClick = () => {
        dispatch(emailActions.setIsModalOpenMessage(true));
        dispatch(emailActionAsync.getThreadsMessagesAsync(row.id));
      };
      return (
        <TableCellCursor {...props} onClick={handleClick}>
          <NowrapText style={{ fontWeight: row.isRead ? 'inherit' : '700', color: row.isRead ? 'black' : '#828282' }}>
            {value}
          </NowrapText>
        </TableCellCursor>
      );
    }
    case 'entity': {
      const getEntity = row?.industrial || row?.trademarks || row?.utility || row?.inventions;
      return (
        <VirtualTable.Cell {...props}>
          {isArray(getEntity) && !isEmpty(getEntity) ? (
            map(getEntity, (item) => item?.caseNumber || item?.applicationNumber
            || item?.registrationNumber || item?.internalTitle || item?.id || '').join(', ')
          ) : (
            ''
          )}
        </VirtualTable.Cell>
      );
    }

    case 'tags': {
      return (
        <VirtualTable.Cell {...props}>
          {isArray(value) && !isEmpty(value) ? (
            map(value, (item, index) => (
              <Chip
                pb={0.5}
                key={index}
                mr={1}
                mb={1}
                size="small"
                label={item.name}
                style={{
                  color: item.colorTxt ? item.colorTxt : 'black',
                  backgroundColor: item?.colorBg ? item.colorBg : 'white',
                  borderRadius: 0,
                }}
              />
            ))
          ) : (
            <Box textAlign="center">
              <MuiSvgIcon color="action">
                <TagsIcon />
              </MuiSvgIcon>
            </Box>
          )}
        </VirtualTable.Cell>
      );
    }
    case 'fis': {
      return (
        <VirtualTable.Cell>
          {isBoolean(value) && (
            <Chip
              pb={0.5}
              pr={3}
              pl={3}
              size="small"
              label={value ? t('Individual person') : t('Legal person')}
              style={{ color: 'white', backgroundColor: value ? '#0066ff' : '#ff9900' }}
            />
          )}
        </VirtualTable.Cell>
      );
    }
    case 'boost': {
      if (isBoolean(value)) {
        return (
          <VirtualTable.Cell style={{ textAlign: 'center' }}>
            <MuiSvgIcon style={{ color: value ? '#fc4b4b' : '#4caf50' }}>
              {value ? <RabbitFastIcon /> : <TurtleIcon />}
            </MuiSvgIcon>
          </VirtualTable.Cell>
        );
      }
      return <VirtualTable.Cell {...props} />;
    }
    case 'signs': {
      if (!isEmpty(value) && isArray(value)) {
        const title = map(value, (item) => `${item.date} ${item.users?.name} (${item.csk})`).join('\n');
        return (
          <VirtualTable.Cell>
            <Tooltip title={title} placement="top-start">
              <SvgIcon>
                <CheckIcon />
              </SvgIcon>
            </Tooltip>
          </VirtualTable.Cell>
        );
      }
      return <VirtualTable.Cell {...props} />;
    }
    case 'lastDocuments': {
      const file = get(value, ['file'], {});
      const name = get(value, ['documentClassifier', 'name'], '');
      const receivingDate = get(value, ['receivingDate'], '');

      const handleClick = () => {
        if (!isEmpty(file)) {
          dispatch(helpersActionAsync.getDownloadFileAsync(file));
        }
      };
      return (
        <VirtualTable.Cell>
          <Tooltip title={name} placement="top-start">
            <TypographyNote onClick={handleClick}>
              {receivingDate}
            </TypographyNote>
          </Tooltip>
        </VirtualTable.Cell>
      );
    }
    case 'pendingActions': {
      const title = get(row, 'nextAction.title', '');
      const createdAt = get(row, 'nextAction.finishAt', '');
      return (
        <VirtualTable.Cell>
          <Tooltip title={title} placement="top-start">
            <TypographyNote>{createdAt}</TypographyNote>
          </Tooltip>
        </VirtualTable.Cell>
      );
    }
    case 'nextAction': {
      const title = get(value, 'title', '');
      const createdAt = get(value, 'finishAt', '');
      return (
        <VirtualTable.Cell>
          <Tooltip title={title} placement="top-start">
            <TypographyNote>{createdAt}</TypographyNote>
          </Tooltip>
        </VirtualTable.Cell>
      );
    }
    case 'view': {
      const handleReview = () => {
        if (row?.file?.fileId) {
          dispatch(helpersActionAsync.getPreviewFileByIdAsync(row.file.fileId));
        }
      };
      return (
        <VirtualTable.Cell>
          <Tooltip title={t('Review')} placement="top-start">
            <IconButton
              aria-label="view"
              aria-controls="view"
              onClick={handleReview}
              size="small"
            >
              <RemoveRedEyeIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </VirtualTable.Cell>
      );
    }
    case 'titleDocDashboard': {
      return (
        <VirtualTable.Cell>
          <LinkId
            to={pageLinks.documents.departmentDocuments.edit(row.id)}
          >
            {row.title}
          </LinkId>
        </VirtualTable.Cell>
      );
    }
    case 'sumBill': {
      return (
        <VirtualTable.Cell {...props}>
          <span>{value ? floor(value, 2) : value}</span>
        </VirtualTable.Cell>
      );
    }
    case 'entityName':
    case 'action': {
      return (
        <VirtualTable.Cell {...props}>
          <span>{t(value)}</span>
        </VirtualTable.Cell>
      );
    }
    case 'rateType': {
      return (
        <VirtualTable.Cell {...props}>
          {value === 'fixed' && (
            <span>{t('Fixed rate')}</span>
          )}
          {value === 'real' && (
            <span>{t('The real course')}</span>
          )}
        </VirtualTable.Cell>
      );
    }
    case 'currency':
    case 'currency.code': {
      return (
        <VirtualTable.Cell {...props}>
          <span>{row?.currency?.code || ''}</span>
        </VirtualTable.Cell>
      );
    }
    case 'includeToBill':
    case 'default': {
      return (
        <VirtualTable.Cell {...props}>
          <Checkbox
            checked={value || false}
            color="primary"
            py={0}
          />
        </VirtualTable.Cell>
      );
    }
    case 'lastCheckAt': {
      const label = get(row, 'label', '');
      const lastCheckAt = get(row, 'lastCheckAt.date', null);
      return (
        <VirtualTable.Cell>
          <Tooltip title={label} placement="top-start">
            <span>{lastCheckAt || value}</span>
          </Tooltip>
        </VirtualTable.Cell>
      );
    }
    case 'costscategories': {
      const name = get(row, 'category.name', '');

      return (
        <VirtualTable.Cell>
          <span>{name}</span>
        </VirtualTable.Cell>
      );
    }
    case 'query.classes': {
      if (isArray(row?.query?.classes)) {
        return (
          <VirtualTable.Cell>
            <span>{row?.query?.classes.join(',')}</span>
          </VirtualTable.Cell>
        );
      }
      return (
        <VirtualTable.Cell>
          <span>{row?.query?.classes || ''}</span>
        </VirtualTable.Cell>
      );
    }
    case 'query.keywords': {
      return (
        <VirtualTable.Cell>
          <span>{row?.query?.keywords || ''}</span>
        </VirtualTable.Cell>
      );
    }
    case 'classes': {
      if (isArray(value)) {
        return (
          <VirtualTable.Cell>
            <span>{value.join(',')}</span>
          </VirtualTable.Cell>
        );
      }
      return <VirtualTable.Cell {...props} />;
    }
    case 'recipients': {
      if (isArray(value)) {
        return (
          <VirtualTable.Cell>
            <span>{map(value, 'email').join(', ')}</span>
          </VirtualTable.Cell>
        );
      }
      return <VirtualTable.Cell {...props} />;
    }
    case 'users': {
      if (isArray(value)) {
        return (
          <VirtualTable.Cell {...props}>
            <span>{map(value, 'label').join(', ')}</span>
          </VirtualTable.Cell>
        );
      }
      if (!isEmpty(value) && isObject(value)) {
        return (
          <VirtualTable.Cell {...props}>
            <span>{value?.name}</span>
          </VirtualTable.Cell>
        );
      }
      return <VirtualTable.Cell {...props} />;
    }
    case 'madridCases': {
      if (isArray(value)) {
        return (
          <VirtualTable.Cell {...props}>
            <LinkMadrid to={pageLinks.tradeMarksRoutes.madridCases.edit(value[0]?.id)}>
              {value[0]?.regNumber || value[0]?.id}
            </LinkMadrid>
          </VirtualTable.Cell>
        );
      }
      if (!isEmpty(value) && isObject(value)) {
        return (
          <VirtualTable.Cell {...props}>
            <LinkMadrid to={pageLinks.tradeMarksRoutes.madridCases.edit(value?.id)}>
              {value?.regNumber || value?.id}
            </LinkMadrid>
          </VirtualTable.Cell>
        );
      }
      return <VirtualTable.Cell {...props} />;
    }
    case 'objectType': {
      const foundObjectType = find(globalObjectTypes, { value });
      return (
        <VirtualTable.Cell>
          {!isEmpty(foundObjectType) && (
            <span>{foundObjectType.name}</span>
          )}
        </VirtualTable.Cell>
      );
    }
    case 'group': {
      return (
        <VirtualTable.Cell>
          <span>{value?.title}</span>
        </VirtualTable.Cell>
      );
    }
    case 'executor': {
      const foundExecutor = find(globalExecutors, { value });
      return (
        <VirtualTable.Cell>
          {!isEmpty(foundExecutor) && (
            <span>{foundExecutor.name}</span>
          )}
        </VirtualTable.Cell>
      );
    }
    case 'type.id': {
      if (column.customField === 'typeAction') {
        return (
          <VirtualTable.Cell {...props}>
            {!isEmpty(row.type) && (
              <Grid container alignItems="center" justify="center">
                <Chip
                  pb={0.5}
                  pr={3}
                  pl={3}
                  size="small"
                  label={t(row.type?.name)}
                  style={{
                    background: row.type?.colors,
                    color: '#ffffff',
                    borderColor: '#ffffff',
                  }}
                />
              </Grid>
            )}
          </VirtualTable.Cell>
        );
      }
      return <VirtualTable.Cell {...props} />;
    }
    case 'type': {
      if (typeof value === 'number') {
        return (
          <VirtualTable.Cell>
            {value === 1 && t('Fixed')}
            {value === 2 && t('Quantitative')}
            {value === 3 && t('Hourly')}
          </VirtualTable.Cell>
        );
      }

      return (
        <VirtualTable.Cell>
          {Boolean(value) && isString(value) && (
            <Grid container alignItems="center" justify="center">
              <Chip
                pb={0.5}
                pr={3}
                pl={3}
                size="small"
                {...value === 'in' ? {
                  variant: 'outlined',
                } : {}}
                label={value === 'in' ? t('Incoming') : t('Outgoing')}
                style={{ borderColor: borderColorNotStatusChip }}
              />
            </Grid>
          )}
          {Boolean(value) && isNumber(value) && (
            <span>{value}</span>
          )}
          {Boolean(value) && isObject(value) && Boolean(value.title) && (
            <span>{value.title}</span>
          )}
          {(Boolean(row.charge) && isObject(row.charge) && Boolean(row.charge.type)) && (
            <span>{row.charge.type}</span>
          )}
        </VirtualTable.Cell>
      );
    }
    case 'sum': {
      const getSumChargesInput = sumChargesInput.toJS();
      const handleSumChargesInput = (id) => (event) => {
        dispatch(chargesActions.setSumChargesInput({
          ...getSumChargesInput,
          [id]: event.target.value,
        }));
      };
      return (
        <>
          {(column.customField === 'sumChargesInput' && isNumber(value) && isNumber(row.toPay)) && (
            <VirtualTable.Cell>
              <TextField
                fullWidth
                id={`${row.id}`}
                name={`${row.id}`}
                type="number"
                defaultValue={!isEmpty(getSumChargesInput) && getSumChargesInput[row.id]
                  ? getSumChargesInput[row.id]
                  : row.toPay}
                onChange={handleSumChargesInput(row.id)}
              />
            </VirtualTable.Cell>
          )}
          {isEmpty(column.customField) && <VirtualTable.Cell {...props} />}
        </>
      );
    }
    case 'documentClassifier': {
      return (
        <VirtualTable.Cell>
          <span>{value?.name}</span>
        </VirtualTable.Cell>
      );
    }
    case 'notes': {
      return (
        value ? (
          <VirtualTable.Cell {...props}>
            <Box display="flex" alignItems="center">
              <span>{value}</span>
              <EditIcon fontSize="small" color="action" ml={1} />
            </Box>
          </VirtualTable.Cell>
        ) : (
          <VirtualTable.Cell {...props} style={{ textAlign: 'center' }}>
            <PlaylistAddIcon fontSize="small" color="action" />
          </VirtualTable.Cell>
        )
      );
    }
    default: {
      return <VirtualTable.Cell {...props} />;
    }
  }
}

TableCell.displayName = 'TableCell';

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.row, nextProps.row)
  && isEqual(prevProps.column, nextProps.column)
  && isEqual(prevProps.value, nextProps.value);
}

export default React.memo(TableCell, areEqual);
