export default Object.freeze({
  SET_TAXES_DATA: 'SET_TAXES_DATA',
  SET_TAX_BY_ID: 'SET_TAX_BY_ID',
  SET_TAXES_RELOAD: 'SET_TAXES_RELOAD',
  SET_TAXES_CURRENT_PAGE: 'SET_TAXES_CURRENT_PAGE',
  SET_TAXES_FILTERS: 'SET_TAXES_FILTERS',
  SET_TAXES_SORTING: 'SET_TAXES_SORTING',
  SET_TAXES_PAGE_SIZE: 'SET_TAXES_PAGE_SIZE',
  SET_TAXES_INITIAL_STATE: 'SET_TAXES_INITIAL_STATE',
  SET_DELETE_TAXES: 'SET_DELETE_TAXES',
  SET_TAXES_IS_MODAL_OPEN: 'SET_TAXES_IS_MODAL_OPEN',
  SET_TAX_DATA_BY_ID: 'SET_TAX_DATA_BY_ID',
  SET_TAXES_COLUMN_WIDTHS: 'SET_TAXES_COLUMN_WIDTHS',
  SET_TAXES_COLUMN_ORDER: 'SET_TAXES_COLUMN_ORDER',
  SET_TAXES_HIDDEN_COLUMN_NAMES: 'SET_TAXES_HIDDEN_COLUMN_NAMES',
});
