// core
import React, { useEffect, memo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import { change } from 'redux-form/lib/immutable';
import styled from 'styled-components';

// lodash
import {
  isEmpty, isNil, find, map, includes,
} from 'lodash';

// ui
import {
  Grid as MuiGrid, Typography as MuiTypography, Divider as MuiDivider,
  Tooltip, IconButton as MuiIconButton, Box,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import {
  SwapHoriz as SwapHorizIcon,
} from '@material-ui/icons';
import {
  yellow, grey,
} from '@material-ui/core/colors';

// config
import selectors from '../../engine/config/selectors';

// parts
import Modal from '../Modal/Modal';
import LoaderWithOverlay from '../LoaderWithOverlay';

// actions
import actionsApplicants from '../../engine/core/applicants/action';
import helpersActions from '../../engine/core/helpers/action';

const Typography = styled(MuiTypography)`
  white-space: pre-line;
`;

const TypographyTitle = styled(MuiTypography)`
  margin-left: 25px;
  color: ${grey['500']};
`;
const TypographyTitleRegistry = styled(MuiTypography)`
  color: ${grey['500']};
`;
const TypographyRegistry = styled(MuiTypography)`
  white-space: pre-line;
  padding-right: 25px;
`;

const IconButton = styled(MuiIconButton)`
  padding: 0;
  margin-right: 5px;
  height: 20px;
  width: 20px;
  & ~ .different {
    background-color: ${yellow['500']};
  }
`;
const Grid = styled(MuiGrid)`
  padding: 4px 8px 0 8px;
`;
const DivMktu = styled.div`
  display: inline;
  &.different {
    background-color: ${yellow['500']};
  }
`;
const ListMktu = styled.div`
  padding-right: 25px;
`;

const Divider = styled(MuiDivider)(spacing);

const fieldBasicInformation = [
  { field: 'status', title: 'Status' },
  { field: 'keywords', fieldReg: 'internalTitle', title: 'Keywords' },
  { field: 'caseNumber', title: 'Case number' },
  { field: 'applicationDate', title: 'Date of submission' },
  { field: 'applicationNumber', title: 'Application number' },
  { field: 'registrationDate', title: 'Registration date' },
  { field: 'registrationNumber', title: 'Registration number' },
  { field: 'expiryDate', title: 'End date' },
];

function RowApplication(props) {
  const {
    field, title, value, handleChange, valueRegistry,
  } = props;
  const { t } = useTranslation();
  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <TypographyTitle variant="body2">{t(title)}</TypographyTitle>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Tooltip
            title={t('Change')}
            size="small"
            arrow
          >
            <IconButton onClick={() => (value !== valueRegistry) && handleChange(field)}>
              <SwapHorizIcon />
            </IconButton>
          </Tooltip>
          <Typography
            variant="body2"
            className={value !== valueRegistry && field !== 'logo'
              ? 'different'
              : ''}
          >
            {field === 'logo' && !isEmpty(value) ? (
              <img
                style={{ height: 150, width: '100%', objectFit: 'contain' }}
                src={value}
                alt={t('Logo')}
              />
            ) : value}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <TypographyTitleRegistry variant="body2">{t(title)}</TypographyTitleRegistry>
        <TypographyRegistry variant="body2">
          {field === 'logo' && !isEmpty(valueRegistry) ? (
            <img
              style={{ height: 150, width: '100%', objectFit: 'contain' }}
              src={valueRegistry}
              alt={t('Logo')}
            />
          ) : valueRegistry}
        </TypographyRegistry>
      </Grid>
    </Grid>
  );
}

RowApplication.propTypes = {
  field: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  valueRegistry: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
};

RowApplication.defaultProps = {
  valueRegistry: '',
  value: '',
};

function RowMktu(props) {
  const {
    selectMktuClass, mktuClassRegistry, handleChange,
  } = props;
  const { t } = useTranslation();
  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <TypographyTitle variant="body2">
          {selectMktuClass?.classNum || mktuClassRegistry?.classNum}
        </TypographyTitle>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Tooltip
            title={t('Change')}
            size="small"
            arrow
          >
            <IconButton
              onClick={() => (!isEmpty(mktuClassRegistry)) && handleChange(mktuClassRegistry)}
            >
              <SwapHorizIcon />
            </IconButton>
          </Tooltip>
          <div>
            {!isEmpty(selectMktuClass) && (
              map(selectMktuClass.name.split('; '), (mktu, index) => (
                <DivMktu
                  key={index}
                  className={(!isEmpty(mktuClassRegistry)
                    && includes(mktuClassRegistry.name, mktu))
                    ? ''
                    : 'different'}
                >
                  {mktu}
                  {'; '}
                </DivMktu>
              ))
            )}
          </div>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <TypographyTitleRegistry variant="body2">
          {selectMktuClass?.classNum || mktuClassRegistry?.classNum}
        </TypographyTitleRegistry>
        <ListMktu>
          {!isEmpty(mktuClassRegistry) && (
            map(mktuClassRegistry.name.split('; '), (mktu, index) => (
              <DivMktu
                key={index}
                className={(!isEmpty(selectMktuClass) && includes(selectMktuClass.name, mktu))
                  ? ''
                  : 'different'}
              >
                {mktu}
                {'; '}
              </DivMktu>
            ))
          )}
        </ListMktu>
      </Grid>
    </Grid>
  );
}

RowMktu.propTypes = {
  selectMktuClass: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  mktuClassRegistry: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  handleChange: PropTypes.func.isRequired,
};

RowMktu.defaultProps = {
  selectMktuClass: {},
  mktuClassRegistry: {},
};

function CompareWithRegistryModal(props) {
  const {
    isModalOpen, pendingRegistryByNumber, handleChangeMktu, handleCleanup,
    getRegistryByNumber, getProposalById, handleChangeProposal,
    objectStatuses, selectMktuClasses, mktuClasses,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => function cleanup() {
    handleCleanup();
  }, []);// eslint-disable-line

  const handleCloseModal = () => {
    dispatch(helpersActions.setModal({ isModalOpenRegistry: false }));
  };

  const handleChange = (field) => {
    handleChangeProposal({
      ...getProposalById.toJS(),
      [field]: getRegistryByNumber.toJS()[field],
    });
    dispatch(change('ProposalAdd', field, getRegistryByNumber.toJS()[field]));
  };

  const handleChangeKeywords = () => {
    handleChangeProposal({
      ...getProposalById.toJS(),
      keywords: getRegistryByNumber.toJS().internalTitle,
    });
    dispatch(change('ProposalAdd', 'keywords', getRegistryByNumber.toJS().internalTitle));
  };

  const handleChangeApplicants = () => {
    dispatch(actionsApplicants.setNewApplicantsListData(
      getRegistryByNumber.toJS().data.applicants,
    ));
    handleChangeProposal({
      ...getProposalById.toJS(),
      data: {
        ...getProposalById.toJS().data,
        applicants: getRegistryByNumber.toJS().data.applicants,
      },
    });
  };

  const handleChangeHolders = () => {
    dispatch(actionsApplicants.setNewOwnersListData(getRegistryByNumber.toJS().data.holders));
    handleChangeProposal({
      ...getProposalById.toJS(),
      data: {
        ...getProposalById.toJS().data,
        holders: getRegistryByNumber.toJS().data.holders,
      },
    });
  };

  const handleChangeAuthorsList = () => {
    dispatch(actionsApplicants.setNewAuthorsListData(getRegistryByNumber.toJS().data.authors));
    handleChangeProposal({
      ...getProposalById.toJS(),
      data: {
        ...getProposalById.toJS().data,
        authors: getRegistryByNumber.toJS().data.authors,
      },
    });
  };

  const handleChangeInventors = () => {
    dispatch(actionsApplicants.setNewAuthorsListData(getRegistryByNumber.toJS().data.inventors));
    handleChangeProposal({
      ...getProposalById.toJS(),
      data: {
        ...getProposalById.toJS().data,
        inventors: getRegistryByNumber.toJS().data.inventors,
      },
    });
  };

  const handleChangeAttorney = () => {
    handleChangeProposal({
      ...getProposalById.toJS(),
      data: {
        ...getProposalById.toJS().data,
        attorney: {
          ...getProposalById.toJS().data?.attorney,
          name: getRegistryByNumber.toJS().data.attorney.name,
        },
      },
    });
    dispatch(change('ProposalAdd', 'data.attorney.name',
      getRegistryByNumber.toJS().data.attorney.name));
  };

  const handleChangePostalAddress = () => {
    handleChangeProposal({
      ...getProposalById.toJS(),
      data: {
        ...getProposalById.toJS().data,
        postalAddress: getRegistryByNumber.toJS().data.postalAddress,
      },
    });
    dispatch(change('ProposalAdd', 'data.postalAddress',
      getRegistryByNumber.toJS().data.postalAddress));
  };

  const handleChangeLogo = () => {
    handleChangeProposal({
      ...getProposalById.toJS(),
      data: {
        ...getProposalById.toJS().data,
        logo: getRegistryByNumber.toJS().data.logo,
      },
    });
    dispatch(change('ProposalAdd', 'data.logo', getRegistryByNumber.toJS().data.logo));
    setTimeout(() => dispatch(change('ProposalAdd', 'data.logo', null)));
  };

  const handleChangeMktuClasses = (mktu) => {
    handleChangeMktu([
      ...selectMktuClasses.toJS().filter((mktuClass) => (mktuClass.classNum !== mktu.classNum)),
      mktu,
    ]);
  };

  const getStatus = (statusId) => {
    if (!isNil(statusId) && !isEmpty(objectStatuses.toJS())) {
      const foundStatus = find(objectStatuses.toJS(), { id: statusId });
      return t(foundStatus?.name);
    }
    return '';
  };

  const getApplicantValue = (applicants) => {
    if (!isEmpty(applicants)) {
      const arrayValue = map(applicants, (applicant) => {
        const name = !isNil(applicant.name) ? `${applicant.name},\n` : '';
        const country = !isNil(applicant.address?.country) ? `${applicant.address?.country}, ` : '';
        const city = !isNil(applicant.address?.city) ? `${applicant.address?.city}, ` : '';
        const address = !isNil(applicant.address?.address) ? `${applicant.address?.address}, ` : '';
        const postalIndex = !isNil(applicant.address?.postalIndex) ? `${applicant.address?.postalIndex}, ` : '';
        return `${name}${country}${city}${address}${postalIndex}`;
      });
      return arrayValue.join('\n');
    }
    return '';
  };

  const getPostalAddressValue = (postalAddress) => {
    if (!isEmpty(postalAddress)) {
      const fullName = !isNil(postalAddress.fullName) ? `${postalAddress.fullName},\n` : '';
      const country = !isNil(postalAddress.country?.label) ? `${postalAddress.country?.label}, ` : '';
      const city = !isNil(postalAddress.city) ? `${postalAddress.city}, ` : '';
      const address = !isNil(postalAddress.address) ? `${postalAddress.address}, ` : '';
      const postalIndex = !isNil(postalAddress.postalIndex) ? `${postalAddress.postalIndex}, ` : '';
      return `${fullName}${country}${city}${address}${postalIndex}`;
    }
    return '';
  };

  return (
    <Modal
      title={t('Comparison with the register')}
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
      displayDialogActions={false}
      maxWidth="md"
    >
      {pendingRegistryByNumber ? (
        <LoaderWithOverlay />
      ) : (
        <>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                {t('Application data')}
              </Typography>
              <Divider my={2} />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                {t('Data from the register')}
              </Typography>
              <Divider my={2} />
            </Grid>
          </Grid>

          {!isEmpty(getProposalById.toJS()) && !isEmpty(getRegistryByNumber.toJS()) && (
            <>
              {map(fieldBasicInformation, (item, index) => (
                !isNil(getRegistryByNumber.toJS()[item.field]) && (
                  <RowApplication
                    key={index}
                    field={item.field}
                    title={item.title}
                    value={item.field === 'status'
                      ? getStatus(getProposalById.toJS()[item.field])
                      : getProposalById.toJS()[item.field]}
                    valueRegistry={item.field === 'status'
                      ? getStatus(getRegistryByNumber.toJS()[item.field])
                      : getRegistryByNumber.toJS()[
                        !isEmpty(item.fieldReg) ? item.fieldReg : item.field
                      ]}
                    handleChange={!isEmpty(item.fieldReg) ? handleChangeKeywords : handleChange}
                  />
                )
              ))}
              {!isEmpty(getRegistryByNumber.toJS().data?.applicants) && (
                <RowApplication
                  field="applicants"
                  title="Applicants"
                  value={getApplicantValue(getProposalById.toJS().data?.applicants)}
                  valueRegistry={getApplicantValue(getRegistryByNumber.toJS().data?.applicants)}
                  handleChange={handleChangeApplicants}
                />
              )}
              {!isEmpty(getRegistryByNumber.toJS().data?.holders) && (
                <RowApplication
                  field="holders"
                  title="Owners"
                  value={getApplicantValue(getProposalById.toJS().data?.holders)}
                  valueRegistry={getApplicantValue(getRegistryByNumber.toJS().data?.holders)}
                  handleChange={handleChangeHolders}
                />
              )}
              {!isEmpty(getRegistryByNumber.toJS().data?.authors) && (
                <RowApplication
                  field="authors"
                  title="Authors"
                  value={getApplicantValue(getProposalById.toJS().data?.authors)}
                  valueRegistry={getApplicantValue(getRegistryByNumber.toJS().data?.authors)}
                  handleChange={handleChangeAuthorsList}
                />
              )}
              {!isEmpty(getRegistryByNumber.toJS().data?.inventors) && (
                <RowApplication
                  field="inventors"
                  title="Inventors"
                  value={getApplicantValue(getProposalById.toJS().data?.inventors)}
                  valueRegistry={getApplicantValue(getRegistryByNumber.toJS().data?.inventors)}
                  handleChange={handleChangeInventors}
                />
              )}
              {!isEmpty(getRegistryByNumber.toJS().data?.attorney?.name) && (
                <RowApplication
                  field="attorney"
                  title="Representative"
                  value={getProposalById.toJS().data?.attorney?.name}
                  valueRegistry={getRegistryByNumber.toJS().data?.attorney?.name}
                  handleChange={handleChangeAttorney}
                />
              )}
              {!isEmpty(getRegistryByNumber.toJS().data?.postalAddress) && (
                <RowApplication
                  field="postalAddress"
                  title="Mailing address"
                  value={getPostalAddressValue(getProposalById.toJS().data?.postalAddress)}
                  valueRegistry={getPostalAddressValue(
                    getRegistryByNumber.toJS().data?.postalAddress,
                  )}
                  handleChange={handleChangePostalAddress}
                />
              )}
              {!isEmpty(getRegistryByNumber.toJS().data?.logo) && (
                <RowApplication
                  field="logo"
                  title="Image"
                  value={getProposalById.toJS().data?.logo}
                  valueRegistry={getRegistryByNumber.toJS().data?.logo}
                  handleChange={handleChangeLogo}
                />
              )}
              {!isEmpty(mktuClasses.toJS()) && (!isEmpty(selectMktuClasses.toJS())
                || !isEmpty(getRegistryByNumber.toJS().mktu)) && (
                <>
                  <Grid container>
                    <Grid item xs={12} md={6}>
                      <Typography variant="body1">
                        {t('ICGS')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="body1">
                        {t('ICGS')}
                      </Typography>
                    </Grid>
                  </Grid>
                  {map(mktuClasses.toJS(), (item, index) => {
                    const selectMktuClass = find(selectMktuClasses.toJS(),
                      { classNum: item.classNum });
                    const mktuClassRegistry = find(getRegistryByNumber.toJS().mktu,
                      { classNum: item.classNum });
                    if (!isEmpty(selectMktuClass) || !isEmpty(mktuClassRegistry)) {
                      return (
                        <RowMktu
                          key={index}
                          selectMktuClass={selectMktuClass}
                          mktuClassRegistry={mktuClassRegistry}
                          handleChange={handleChangeMktuClasses}
                        />
                      );
                    }
                    return null;
                  })}
                </>
              )}
            </>
          )}
        </>
      )}
    </Modal>
  );
}

CompareWithRegistryModal.displayName = 'CompareWithRegistryModal';

CompareWithRegistryModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  getRegistryByNumber: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  getProposalById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingRegistryByNumber: PropTypes.bool.isRequired,
  selectMktuClasses: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  mktuClasses: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,

  objectStatuses: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  handleChangeProposal: PropTypes.func.isRequired,
  handleCleanup: PropTypes.func.isRequired,
  handleChangeMktu: PropTypes.func,
};

CompareWithRegistryModal.defaultProps = {
  handleChangeMktu: () => {},
};

function mapStateToProps(state) {
  return {
    isModalOpen: selectors.helpers.isModalOpenRegistry(state),

    selectMktuClasses: selectors.tradeMarksProposal.selectMktuClasses(state),
    mktuClasses: selectors.tradeMarksProposal.classes(state),

    objectStatuses: selectors.helpers.getObjectStatuses(state),
  };
}

export default compose(
  connect(mapStateToProps, null),
)(memo(CompareWithRegistryModal));
