// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const asyncActions = Object.freeze({
  getDocumentsTypeAsync(params) {
    return actionCreator(asyncTypes.GET_DOCUMENTS_TYPE_ASYNC, params);
  },
  postAutocompleteGroupOptionsAsync(data) {
    return actionCreator(asyncTypes.POST_AUTOCOMPLETE_GROUP_ASYNC, data);
  },
  deleteMultipleAsync(data) {
    return actionCreator(asyncTypes.DELETE_MULTIPLE_ASYNC, data);
  },
  putMultipleAsync(data) {
    return actionCreator(asyncTypes.PUT_MULTIPLE_ASYNC, data);
  },
  getObjectStatusesAsync() {
    return actionCreator(asyncTypes.GET_OBJECT_STATUSES_ASYNC);
  },
  postSendAsync(params) {
    return actionCreator(asyncTypes.POST_SEND_ASYNC, params);
  },
  postSignAsync(params) {
    return actionCreator(asyncTypes.POST_SIGN_ASYNC, params);
  },
  postCreatePacketAsync(params) {
    return actionCreator(asyncTypes.POST_CREATE_PACKET_ASYNC, params);
  },
  getFilesBase64Async(path) {
    return actionCreator(asyncTypes.GET_FILES_BASE_64_ASYNC, path);
  },
  getImagesBase64Async(path) {
    return actionCreator(asyncTypes.GET_IMAGES_BASE_64_ASYNC, path);
  },
  postSupportTicketsAsync(params) {
    return actionCreator(asyncTypes.POST_SUPPORT_TICKETS_ASYNC, params);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_INITIAL_VISIBLE_SIDEBAR_ASYNC, path);
  },
  postCalcPatentsRenewalAsync(params) {
    return actionCreator(asyncTypes.POST_CALC_PATENTS_RENEWAL_ASYNC, params);
  },
  getDownloadFileAsync(path) {
    return actionCreator(asyncTypes.GET_DOWNLOAD_FILE_ASYNC, path);
  },
  getDownloadExportAsync() {
    return actionCreator(asyncTypes.GET_DOWNLOAD_EXPORT_ASYNC);
  },
  getPreviewFileByIdAsync(id) {
    return actionCreator(asyncTypes.GET_PREVIEW_FILE_BY_ID_ASYNC, id);
  },
  setSaveFrontendSetting() {
    return actionCreator(asyncTypes.SET_SAVE_FRONTEND_SETTING);
  },
});

export default asyncActions;
