// Core
export const formName = 'Search';

export const formFields = Object.freeze({
  clients: 'clients',
  choice: 'choice',
  type: 'type',
  classes: 'classes',
  word: 'word',
  conclusion: 'conclusion',
});

export const formFieldsFilter = Object.freeze({
  title: 'title',
  applicationNumber: 'applicationNumber',
  registrationNumber: 'registrationNumber',
  applicationDate: 'applicationDate',
  registrationDate: 'registrationDate',
  classesFilter: 'classesFilter',
  holder: 'holder',
  active: 'active',
  status: 'status',
});
