// core
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

// lodash
import map from 'lodash/map';

// ui
import {
  Menu, MenuItem, IconButton,
} from '@material-ui/core';
import { AddBox } from '@material-ui/icons';

// actions
import pendingActions from '../../engine/core/pendingActions/action';

// routes
import { pageLinks } from '../../routes';

// hooks
import { useAccessList } from '../../ui/_hooks/useAccessList';

// config
import accessList from '../../engine/config/accessList';

function MenuCreateEntity() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const pendingActionsPost = useAccessList(accessList.pendingActions_post);
  const trademarksPost = useAccessList(accessList.trademarks_post);
  const industrialPost = useAccessList(accessList.industrial_post);
  const inventionsPost = useAccessList(accessList.inventions_post);
  const utilityPost = useAccessList(accessList.utility_post);
  const documentsPost = useAccessList(accessList.documents_post);
  const paymentsPost = useAccessList(accessList.payments_post);
  const clientsPost = useAccessList(accessList.clients_post);
  const list = [
    ...pendingActionsPost ? [{ title: t('Notification'), entity: 'notification' }] : [],
    ...trademarksPost ? [{ title: t('the Trademark'), entity: pageLinks.tradeMarksRoutes.proposal.new }] : [],
    ...industrialPost ? [{ title: t('the Industrial Design'), entity: pageLinks.industrialDesigns.new }] : [],
    ...inventionsPost ? [{ title: t('the Invention'), entity: pageLinks.inventions.new }] : [],
    ...utilityPost ? [{ title: t('the Utility Model'), entity: pageLinks.utilityModels.new }] : [],
    ...documentsPost ? [{ title: t('Document'), entity: pageLinks.documents.departmentDocuments.new }] : [],
    ...paymentsPost ? [{ title: t('the Payment'), entity: pageLinks.company.payments.new }] : [],
    ...clientsPost ? [{ title: t('the Client'), entity: pageLinks.company.clients.new }] : [],
  ];

  const checkAccess = pendingActionsPost || trademarksPost || industrialPost
  || inventionsPost || utilityPost || documentsPost
  || paymentsPost || clientsPost;

  const handleToggle = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCreate = (value) => {
    setAnchorEl(null);
    if (value === 'notification') {
      dispatch(pendingActions.setIsModalOpen(true));
      return;
    }
    dispatch(push(value));
  };

  return (
    checkAccess
      ? (
        <>
          <IconButton
            aria-label="more"
            aria-controls="create-menu"
            aria-haspopup="true"
            onClick={handleToggle}
            style={{ color: '#4caf50' }}
          >
            <AddBox fontSize="small" />
          </IconButton>
          <Menu
            id="create-entity-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            {map(list, (item, index) => (
              <MenuItem
                key={index}
                onClick={() => handleCreate(item.entity)}
              >
                {item.title}
              </MenuItem>
            ))}
          </Menu>
        </>
      ) : null
  );
}

export default MenuCreateEntity;
