// Core
import { apply, put } from 'redux-saga/effects';

// lodash
import { isString } from 'lodash';

// config
import { api } from '../../../../config/api';
import { listEntityName } from '../../../../config/listTablesName';

// actions
import actions from '../../action';

const entity = listEntityName.notifications;

export function* callGetNotificationsWorker({ payload: params }) {
  yield put(actions.setNotificationsData({ pending: true }));
  const response = yield apply(api, api.notifications.getNotifications, [{ ...params, entity }]);

  let data = {
    items: [],
    totalCount: 0,
    pending: false,
  };

  if (response && response.status >= 200 && response.status < 400) {
    data = Object.assign(data, {
      ...response.data,
      totalCount: isString(response.data.totalCount)
        ? Number(response.data.totalCount)
        : response.data.totalCount,
    });
  }
  yield put(actions.setNotificationsData(data));
  yield put(actions.setReload(false));
}
