// Core
import { List, Map } from 'immutable';

// Instruments
import { isNil } from 'lodash';
import types from './types';
import { pageSizes } from '../../../config/globalConfig';
import globalStateKeys from '../../../config/globalStateKeys';

export const initialState = Map({
  data: Map({
    items: List(),
    currentPage: 0,
    filters: List([{ columnName: 'folder', value: 'INBOX' }]),
    pending: false,
    sorting: List([{ columnName: 'date', direction: 'desc' }]),
    pageSize: pageSizes[0],
    reload: false,
    totalCount: 0,
    isModalOpen: false,
    selectedEmail: Map(),
    columnWidths: List(),
    columnOrder: List(),
    hiddenColumnNames: List(),
  }),
  pendingPostSend: false,
  pendingDownloadAttachment: false,
  threadsMessages: List(),
  totalCountThreadsMessages: 0,
  nextPageThreadsMessages: 0,
  pendingThreadsMessages: false,
  emailProvider: '',
  pendingEmailProvider: false,
  email: Map({
    items: List(),
    pending: false,
    data: Map(),
    pendingById: false,
    pendingPost: false,
    pendingPut: false,
    isModalOpen: false,
    pendingDelete: false,
  }),
  signatures: Map({
    items: List(),
    pending: false,
    data: Map(),
    pendingById: false,
    pendingPost: false,
    pendingPut: false,
    isModalOpen: false,
    pendingDelete: false,
  }),
  emailLetters: Map({
    items: List(),
    pending: false,
    data: Map(),
    filters: List([{ columnName: 'folder', value: 'INBOX' }]),
    currentPage: 0,
    pageSize: pageSizes[0],
    totalCount: 0,
    reload: false,
    pendingById: false,
    pendingPost: false,
    isModalOpen: false,
    pendingDelete: false,
  }),
  isModalOpenLinkEntity: false,
  previewText: '',
});

export const stateKeys = Object.freeze({
  controller: 'email',
  pendingPostSend: 'pendingPostSend',
  pendingDownloadAttachment: 'pendingDownloadAttachment',
  pendingById: 'pendingById',
  email: 'email',
  signatures: 'signatures',
  emailLetters: 'emailLetters',
  emailMessages: 'emailMessages',
  isModalOpenLinkEntity: 'isModalOpenLinkEntity',
  threadsMessages: 'threadsMessages',
  pendingThreadsMessages: 'pendingThreadsMessages',
  selectedEmail: 'selectedEmail',
  totalCountThreadsMessages: 'totalCountThreadsMessages',
  nextPageThreadsMessages: 'nextPageThreadsMessages',
  emailProvider: 'emailProvider',
  pendingEmailProvider: 'pendingEmailProvider',
  previewText: 'previewText',
  pendingPostPreview: 'pendingPostPreview',
});

export const emailReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_EMAIL_MESSAGES_DATA: {
      const {
        items, pending, totalCount,
      } = payload;
      if (pending) {
        return state.setIn([globalStateKeys.data, globalStateKeys.pending], pending);
      }
      return state
        .setIn([globalStateKeys.data, globalStateKeys.pending], pending)
        .setIn([globalStateKeys.data, globalStateKeys.items], List(items))
        .setIn([globalStateKeys.data, globalStateKeys.totalCount], Number(totalCount));
    }
    case types.SET_EMAIL_MESSAGES_RELOAD: {
      return state.setIn([globalStateKeys.data, globalStateKeys.reload], payload);
    }
    case types.SET_EMAIL_MESSAGES_CURRENT_PAGE: {
      return state.setIn([globalStateKeys.data, globalStateKeys.currentPage], payload);
    }
    case types.SET_EMAIL_MESSAGES_IS_MODAL_OPEN: {
      return state.setIn([globalStateKeys.data, globalStateKeys.isModalOpen], payload);
    }
    case types.SET_EMAIL_MESSAGES_FILTERS: {
      const filters = payload.filter((item) => !isNil(item.value));
      return state.setIn([globalStateKeys.data, globalStateKeys.filters], List(filters));
    }
    case types.SET_EMAIL_MESSAGES_PAGE_SIZE: {
      return state.setIn([globalStateKeys.data, globalStateKeys.pageSize], payload);
    }
    case types.SET_EMAIL_MESSAGES_SORTING: {
      return state.setIn([globalStateKeys.data, globalStateKeys.sorting], List(payload));
    }
    case types.SET_EMAIL_MESSAGES_COLUMN_WIDTHS: {
      return state.setIn([globalStateKeys.data, globalStateKeys.columnWidths], List(payload));
    }
    case types.SET_EMAIL_MESSAGES_COLUMN_ORDER: {
      return state.setIn([globalStateKeys.data, globalStateKeys.columnOrder], List(payload));
    }
    case types.SET_EMAIL_MESSAGES_HIDDEN_COLUMN_NAMES: {
      return state.setIn([globalStateKeys.data, globalStateKeys.hiddenColumnNames], List(payload));
    }
    case types.SET_EMAIL_THREADS_MESSAGES: {
      return state.setIn([stateKeys.threadsMessages], List(payload));
    }
    case types.MERGE_EMAIL_THREADS_MESSAGES: {
      return state.mergeIn([stateKeys.threadsMessages], List(payload));
    }
    case types.SET_EMAIL_TOTAL_COUNT_THREADS_MESSAGES: {
      return state.setIn([stateKeys.totalCountThreadsMessages], payload);
    }
    case types.SET_EMAIL_NEXT_PAGE_THREADS_MESSAGES: {
      return state.setIn([stateKeys.pageThreadsMessages], payload);
    }
    case types.SET_PENDING_EMAIL_THREADS_MESSAGES: {
      return state.setIn([stateKeys.pendingThreadsMessages], payload);
    }
    case types.SET_EMAIL_PROVIDER: {
      return state.setIn([stateKeys.emailProvider], payload);
    }
    case types.SET_PENDING_EMAIL_PROVIDER: {
      return state.setIn([stateKeys.pendingEmailProvider], payload);
    }
    case types.SET_SELECTED_EMAIL: {
      return state
        .setIn([globalStateKeys.data, stateKeys.selectedEmail], Map(payload))
        .setIn([globalStateKeys.data, globalStateKeys.reload], true);
    }
    case types.SET_EMAIL_MESSAGES_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, sorting = [],
        columnWidths, columnOrder, hiddenColumnNames,
      } = payload;
      const reload = true;
      const currentSorting = sorting.length
        ? sorting
        : initialState.getIn([globalStateKeys.data, globalStateKeys.sorting]);
      return state
        .setIn([globalStateKeys.data, globalStateKeys.currentPage], currentPage)
        .setIn([globalStateKeys.data, globalStateKeys.totalCount], totalCount)
        .setIn([globalStateKeys.data, globalStateKeys.filters], List(filters))
        .setIn([globalStateKeys.data, globalStateKeys.pageSize], pageSize)
        .setIn([globalStateKeys.data, globalStateKeys.columnWidths], List(columnWidths))
        .setIn([globalStateKeys.data, globalStateKeys.columnOrder], List(columnOrder))
        .setIn([globalStateKeys.data, globalStateKeys.hiddenColumnNames], List(hiddenColumnNames))
        .setIn([globalStateKeys.data, globalStateKeys.sorting], List(currentSorting))
        .setIn([globalStateKeys.data, globalStateKeys.reload], reload);
    }
    case types.SET_PENDING_POST_SEND_EMAIL: {
      return state.setIn([stateKeys.pendingPostSend], payload);
    }
    case types.SET_PREVIEW_TEXT_EMAIL: {
      return state.setIn([stateKeys.previewText], payload);
    }
    case types.SET_PENDING_POST_PREVIEW_EMAIL: {
      return state.setIn([stateKeys.pendingPostPreview], payload);
    }
    case types.SET_PENDING_DOWNLOAD_ATTACHMENT: {
      return state.setIn([stateKeys.pendingDownloadAttachment], payload);
    }
    case types.SET_EMAIL_SIGNATURES_LIST: {
      return state.setIn([stateKeys.signatures, globalStateKeys.items], List(payload));
    }
    case types.SET_EMAIL_SIGNATURES_IS_MODAL_OPEN: {
      return state.setIn([stateKeys.signatures, globalStateKeys.isModalOpen], payload);
    }
    case types.SET_EMAIL_SIGNATURES_DATA: {
      return state.setIn([stateKeys.signatures, globalStateKeys.data], Map(payload));
    }
    case types.SET_EMAIL_SIGNATURES_PENDING: {
      return state.setIn([stateKeys.signatures, globalStateKeys.pending], payload);
    }
    case types.SET_EMAIL_SIGNATURES_PENDING_BY_ID: {
      return state.setIn([stateKeys.signatures, stateKeys.pendingById], payload);
    }
    case types.SET_EMAIL_SIGNATURES_PENDING_PUT: {
      return state.setIn([stateKeys.signatures, globalStateKeys.pendingPut], payload);
    }
    case types.SET_EMAIL_SIGNATURES_PENDING_POST: {
      return state.setIn([stateKeys.signatures, globalStateKeys.pendingPost], payload);
    }
    case types.SET_EMAIL_SIGNATURES_PENDING_DELETE: {
      return state.setIn([stateKeys.signatures, globalStateKeys.pendingDelete], payload);
    }
    case types.SET_EMAIL_LIST: {
      return state.setIn([stateKeys.email, globalStateKeys.items], List(payload));
    }
    case types.SET_EMAIL_IS_MODAL_OPEN: {
      return state.setIn([stateKeys.email, globalStateKeys.isModalOpen], payload);
    }
    case types.SET_EMAIL_DATA: {
      return state.setIn([stateKeys.email, globalStateKeys.data], Map(payload));
    }
    case types.SET_EMAIL_PENDING: {
      return state.setIn([stateKeys.email, globalStateKeys.pending], payload);
    }
    case types.SET_EMAIL_PENDING_PUT: {
      return state.setIn([stateKeys.email, globalStateKeys.pendingPut], payload);
    }
    case types.SET_EMAIL_PENDING_POST: {
      return state.setIn([stateKeys.email, globalStateKeys.pendingPost], payload);
    }
    case types.SET_EMAIL_PENDING_BY_ID: {
      return state.setIn([stateKeys.email, stateKeys.pendingById], payload);
    }
    case types.SET_EMAIL_PENDING_DELETE: {
      return state.setIn([stateKeys.email, globalStateKeys.pendingDelete], payload);
    }
    case types.SET_EMAIL_LETTERS_LIST: {
      return state.setIn([stateKeys.emailLetters, globalStateKeys.items], List(payload));
    }
    case types.SET_EMAIL_LETTERS_FILTERS: {
      const filters = payload.filter((item) => !isNil(item.value));
      return state.setIn([stateKeys.emailLetters, globalStateKeys.filters], List(filters));
    }
    case types.SET_EMAIL_LETTERS_CURRENT_PAGE: {
      return state.setIn([stateKeys.emailLetters, globalStateKeys.currentPage], payload);
    }
    case types.SET_EMAIL_LETTERS_TOTAL_COUNT: {
      return state.setIn([stateKeys.emailLetters, globalStateKeys.totalCount], payload);
    }
    case types.SET_EMAIL_LETTERS_PAGE_SIZE: {
      return state.setIn([stateKeys.emailLetters, globalStateKeys.pageSize], payload);
    }
    case types.SET_EMAIL_LETTERS_RELOAD: {
      return state.setIn([stateKeys.emailLetters, globalStateKeys.reload], payload);
    }
    case types.SET_EMAIL_LETTERS_IS_MODAL_OPEN: {
      return state.setIn([stateKeys.emailLetters, globalStateKeys.isModalOpen], payload);
    }
    case types.SET_EMAIL_LETTERS_DATA: {
      return state.setIn([stateKeys.emailLetters, globalStateKeys.data], Map(payload));
    }
    case types.SET_EMAIL_LETTERS_PENDING: {
      return state.setIn([stateKeys.emailLetters, globalStateKeys.pending], payload);
    }
    case types.SET_EMAIL_LETTERS_PENDING_POST: {
      return state.setIn([stateKeys.emailLetters, globalStateKeys.pendingPost], payload);
    }
    case types.SET_EMAIL_LETTERS_PENDING_BY_ID: {
      return state.setIn([stateKeys.emailLetters, stateKeys.pendingById], payload);
    }
    case types.SET_EMAIL_LETTERS_PENDING_DELETE: {
      return state.setIn([stateKeys.emailLetters, globalStateKeys.pendingDelete], payload);
    }
    case types.SET_IS_MODAL_OPEN_LINK_ENTITY: {
      return state.setIn([stateKeys.isModalOpenLinkEntity], payload);
    }
    default:
      return state;
  }
};
