// Core
import { all, call, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetProposalDocumentsOutWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callPostDeleteDocumentsOutWorker,
} from './workers';
import types from '../types';

function* watchGetProposalDocumentsOut() {
  yield takeEvery(asyncTypes.GET_PROPOSAL_DOCUMENTS_OUT_ASYNC, callGetProposalDocumentsOutWorker);
}
function* watchGetInitialState() {
  yield takeEvery(
    asyncTypes.GET_PROPOSAL_DOCUMENTS_OUT_INITIAL_STATE_ASYNC,
    callGetInitialStateWorker,
  );
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_PROPOSAL_DOCUMENTS_OUT_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_PROPOSAL_DOCUMENTS_OUT_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_PROPOSAL_DOCUMENTS_OUT_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_PROPOSAL_DOCUMENTS_OUT_SORTING, callSetToLocalDataWorker);
}
function* watchPostDeleteDocumentsOutWorker() {
  yield takeEvery(asyncTypes.POST_DELETE_DOCUMENTS_OUT_ASYNC, callPostDeleteDocumentsOutWorker);
}

export function* watchersProposalDocumentsOutTable() {
  yield all([
    call(watchGetProposalDocumentsOut),
    call(watchSetCurrentPage),
    call(watchSetPageSize),
    call(watchSetFilters),
    call(watchSetSorting),
    call(watchGetInitialState),
    call(watchPostDeleteDocumentsOutWorker),
  ]);
}
