// Core
import { call, put } from 'redux-saga/effects';

// config
import { api } from '../../../../../config/api';

// actions
import ordersActions from '../../action';

export function* callGetOrderByIdWorker({ payload: id }) {
  const {
    setOrderById,
  } = ordersActions;
  yield put(setOrderById({
    pending: true,
  }));

  const response = yield call(api.company.getOrderById, id);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    yield put(setOrderById({ data }));
  }

  yield put(setOrderById({
    pending: false,
  }));
}
