// Core
import { all, call, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetReboundsWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callDeleteReboundWorker,
  callGetReboundByIdWorker,
  callGetReboundPdfByIdWorker,
  callPostReboundWorker,
  callSearchReboundWorker,
  callPutReboundByIdWorker,
  callGetReboundsAutocompleteWorker,
  callDeleteReboundByIdWorker,
} from './workers';
import types from '../types';

function* watchGetRebounds() {
  yield takeEvery(asyncTypes.GET_REBOUNDS_ASYNC, callGetReboundsWorker);
}
function* watchGetReboundsAutocompleteWorker() {
  yield takeEvery(asyncTypes.GET_REBOUNDS_AUTOCOMPLETE_ASYNC,
    callGetReboundsAutocompleteWorker);
}
function* watchGetReboundByIdAsync() {
  yield takeEvery(asyncTypes.GET_REBOUND_BY_ID_ASYNC, callGetReboundByIdWorker);
}
function* watchGetReboundPdfByIdAsync() {
  yield takeEvery(asyncTypes.GET_REBOUND_PDF_BY_ID_ASYNC, callGetReboundPdfByIdWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_REBOUNDS_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_REBOUNDS_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_REBOUNDS_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_REBOUNDS_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_REBOUNDS_SORTING, callSetToLocalDataWorker);
}
function* watchSetColumnWidths() {
  yield takeEvery(types.SET_REBOUNDS_COLUMN_WIDTHS, callSetToLocalDataWorker);
}
function* watchSetColumnOrder() {
  yield takeEvery(types.SET_REBOUNDS_COLUMN_ORDER, callSetToLocalDataWorker);
}
function* watchSetHiddenColumnNames() {
  yield takeEvery(
    types.SET_REBOUNDS_HIDDEN_COLUMN_NAMES,
    callSetToLocalDataWorker,
  );
}

function* watchPostRebound() {
  yield takeEvery(asyncTypes.POST_REBOUND_ASYNC, callPostReboundWorker);
}

function* watchSearchRebound() {
  yield takeEvery(asyncTypes.SEARCH_REBOUND_ASYNC, callSearchReboundWorker);
}

function* watchPutReboundById() {
  yield takeEvery(asyncTypes.PUT_REBOUND_BY_ID_ASYNC, callPutReboundByIdWorker);
}

function* watchDeleteRebound() {
  yield takeEvery(asyncTypes.DELETE_REBOUND_ASYNC, callDeleteReboundWorker);
}

function* watchDeleteReboundById() {
  yield takeEvery(asyncTypes.DELETE_REBOUND_BY_ID_ASYNC, callDeleteReboundByIdWorker);
}

export function* watchersReboundsTable() {
  yield all([
    call(watchGetRebounds),
    call(watchSetCurrentPage),
    call(watchSetPageSize),
    call(watchSetFilters),
    call(watchSetSorting),
    call(watchSetColumnWidths),
    call(watchSetColumnOrder),
    call(watchSetHiddenColumnNames),
    call(watchGetInitialState),
    call(watchPostRebound),
    call(watchSearchRebound),
    call(watchPutReboundById),
    call(watchDeleteRebound),
    call(watchGetReboundByIdAsync),
    call(watchGetReboundPdfByIdAsync),
    call(watchGetReboundsAutocompleteWorker),
    call(watchDeleteReboundById),
  ]);
}
