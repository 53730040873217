// core
import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import { spacing } from '@material-ui/system';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import { Field, change } from 'redux-form/immutable';

// lodash
import { isEqual, isEmpty } from 'lodash';

// UI
import {
  CardContent, Grid as MuiGrid,
  Typography as MuiTypography, Card as MuiCard,
  ButtonGroup, Button as MuiButton,
} from '@material-ui/core';

// action
import employeesAsyncActions from '../../../../../engine/core/company/employees/saga/asyncAction';

// parts
import FieldEmployee from '../../../../../ui/Form/FieldsAutocomplete/FieldEmployee';
import RenderTextField from '../../../../../ui/Form/RenderTextField';
import ButtonSubmit from '../../../../../ui/Button/ButtonSubmit';

// config
import selectors from '../../../../../engine/config/selectors';

// hooks
import { useEventsAutocompleteAsync } from '../../../../../ui/_hooks/useEventsAutocompleteAsync';

// styles
const Card = styled(MuiCard)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Button = styled(MuiButton)(spacing);
const TypographySub = styled(MuiTypography)`
  color: ${(props) => props.theme.palette.grey['500']};
`;

function CardRepresentative(props) {
  const {
    formName, employeeById, formFields, pending,
    getProposalById, match, postalAddressValues, disabled,
  } = props;
  const {
    getAutocompleteLists,
    handleGetAsyncData,
  } = useEventsAutocompleteAsync();
  const [showAddressAttorney, setShowAddressAttorney] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { params } = match;

  useEffect(() => {
    if (!isEmpty(employeeById.toJS())) {
      dispatch(change(formName, formFields.dataAttorneyName, employeeById.toJS().name));
      dispatch(change(formName, formFields.dataIdAttorney, employeeById.toJS().id));
      dispatch(change(formName, formFields.dataAttorneyPhone, employeeById.toJS().phone));
      dispatch(change(formName, formFields.dataAttorneyEmail, employeeById.toJS().email));
      dispatch(change(formName, formFields.dataAttorneyRegNumber, employeeById.toJS().regNumber));
    }
  }, [dispatch, employeeById, formFields, formName]);

  const handleEmployeeGetSelectOption = useCallback((elem) => {
    if (!isEmpty(elem)) {
      dispatch(employeesAsyncActions.getEmployeeByIdAsync(elem?.id));
      dispatch(change(formName, formFields.dataAttorneyPostalAddressCity, ''));
      dispatch(change(formName, formFields.dataAttorneyPostalAddressPostcode, ''));
      dispatch(change(formName, formFields.dataAttorneyPostalAddressAddress, ''));
      setShowAddressAttorney(false);
    }
  }, [dispatch, formFields, formName]);

  const handleShowAddressAttorney = () => {
    setShowAddressAttorney(true);
    if (!isEmpty(employeeById.toJS()) && !isEmpty(employeeById.toJS().addresses?.[0])) {
      const employeeAddress = employeeById.toJS().addresses[0];
      dispatch(change(formName, formFields.dataAttorneyPostalAddressCity, employeeAddress?.city));
      dispatch(change(formName, formFields.dataAttorneyPostalAddressPostcode,
        employeeAddress?.postalIndex));
      dispatch(change(formName, formFields.dataAttorneyPostalAddressAddress,
        employeeAddress?.address));
      return;
    }
    const addressValue = postalAddressValues.toJS
      ? postalAddressValues.toJS()
      : postalAddressValues;
    dispatch(change(formName, formFields.dataAttorneyPostalAddressCity, addressValue?.city));
    dispatch(change(formName, formFields.dataAttorneyPostalAddressPostcode,
      addressValue?.postalIndex));
    dispatch(change(formName, formFields.dataAttorneyPostalAddressAddress,
      addressValue?.address));
  };
  useEffect(() => {
    if (!isEmpty(params) && !isEmpty(getProposalById.toJS()?.data?.attorney?.postalAddress)) {
      setShowAddressAttorney(true);
    }
  }, [getProposalById, params]);

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h2" gutterBottom>
          {t('Representative')}
        </Typography>

        <Grid container spacing={4}>
          <Grid container item xs={12}>
            <Grid item xs={12} md={4}>
              <FieldEmployee
                name={formFields.person}
                label="Search / Select employee"
                getAutocompleteLists={getAutocompleteLists}
                getAsyncData={handleGetAsyncData}
                formName={formName}
                staticOption={{ label: t('Without a representative'), id: Math.random() }}
                propsField={{
                  getSelectOption: handleEmployeeGetSelectOption,
                  disabled,
                }}
              />
            </Grid>

          </Grid>
          <Grid item xs={12} md={3}>
            <Field
              name={formFields.dataAttorneyName}
              id={formFields.dataAttorneyName}
              label={t('Full name')}
              margin="normal"
              variant="standard"
              fullWidth
              type="text"
              component={RenderTextField}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Field
              name={formFields.dataAttorneyRegNumber}
              id={formFields.dataAttorneyRegNumber}
              label={t('№ Patent Attorney')}
              margin="normal"
              variant="standard"
              fullWidth
              type="text"
              component={RenderTextField}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Field
              name={formFields.dataAttorneyEmail}
              id={formFields.dataAttorneyEmail}
              label={t('Email')}
              margin="normal"
              variant="standard"
              fullWidth
              type="text"
              component={RenderTextField}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Field
              name={formFields.dataAttorneyPhone}
              id={formFields.dataAttorneyPhone}
              label={t('Phone')}
              margin="normal"
              variant="standard"
              fullWidth
              type="text"
              component={RenderTextField}
              disabled={disabled}
            />
          </Grid>
          {showAddressAttorney && (
            <Grid item xs={12}>
              <TypographySub variant="subtitle1">
                {t('Address')}
              </TypographySub>
              <Grid container item xs={12} spacing={4}>
                <Grid item xs={12} md={6}>
                  <Field
                    name={formFields.dataAttorneyPostalAddressCity}
                    id={formFields.dataAttorneyPostalAddressCity}
                    label={t('City')}
                    margin="normal"
                    variant="standard"
                    my={1}
                    fullWidth
                    type="text"
                    component={RenderTextField}
                    disabled={disabled}
                  />
                  <Field
                    name={formFields.dataAttorneyPostalAddressPostcode}
                    id={formFields.dataAttorneyPostalAddressPostcode}
                    label={t('Postal code')}
                    margin="normal"
                    variant="standard"
                    my={1}
                    fullWidth
                    type="text"
                    component={RenderTextField}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name={formFields.dataAttorneyPostalAddressAddress}
                    id={formFields.dataAttorneyPostalAddressAddress}
                    label={t('Address')}
                    margin="normal"
                    variant="standard"
                    my={1}
                    fullWidth
                    type="text"
                    component={RenderTextField}
                    disabled={disabled}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {!disabled && (
            <Grid item xs={12}>
              <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                <ButtonSubmit pending={pending} />
                {!showAddressAttorney && (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleShowAddressAttorney}
                  >
                    {t('ADD ADDRESS')}
                  </Button>
                )}
              </ButtonGroup>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
CardRepresentative.propTypes = {
  formName: PropTypes.string.isRequired,
  formFields: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pending: PropTypes.bool.isRequired,
  employeeById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  getProposalById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  match: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  postalAddressValues: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  disabled: PropTypes.bool,
};

CardRepresentative.defaultProps = {
  match: {},
  disabled: false,
};

function mapStateToProps(state) {
  return {
    // employees
    employeeById: selectors.employees.getEmployeeById(state),
  };
}

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.postalAddressValues, nextProps.postalAddressValues)
  && isEqual(prevProps.formFields, nextProps.formFields)
  && isEqual(prevProps.formName, nextProps.formName)
  && isEqual(prevProps.employeeById, nextProps.employeeById)
  && isEqual(prevProps.getProposalById, nextProps.getProposalById)
  && isEqual(prevProps.match, nextProps.match)
  && isEqual(prevProps.disabled, nextProps.disabled)
  && isEqual(prevProps.pending, nextProps.pending);
}
export default compose(
  connect(mapStateToProps, null),
)(memo(CardRepresentative, areEqual));
