/* eslint-disable */
// Core
import React, { memo } from 'react';

// UI
import {
  TextField,
} from '@material-ui/core';


function RenderTextField(props) {
  const {
    label, input, endAdornment, placeholder,
    meta: {touched, invalid, error},
    ...rest
  } = props;
  return (
    <TextField
      label={label}
      placeholder={placeholder}
      error={touched && invalid}
      autoComplete="off"
      helperText={touched && error}
      {...input}
      {...rest}
    />
  );
}

RenderTextField.defaultProps = {
  type: 'text'
}
export default memo(RenderTextField);
