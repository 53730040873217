// Core
import React, { useEffect, useState, memo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { push } from 'connected-react-router';

// UI
import {
  List, Card as MuiCard,
  ListItem as MuiListItem,
  ListItemIcon as MuiListItemIcon,
  ListItemText as MuiListItemText,
  Collapse,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import {
  SelectAll, FindInPageOutlined,
  PostAdd, Apps,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';

// lodash
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

// actions
import tmCalculationsAsyncActions from '../../../../engine/core/tradeMarks/calculations/saga/asyncAction';
import tmReboundsAsyncActions from '../../../../engine/core/tradeMarks/rebounds/saga/asyncAction';
import tmSearchesAsyncActions from '../../../../engine/core/tradeMarks/searches/saga/asyncAction';

// parts
import Loader from '../../../../components/Loader';

// config
import selectors from '../../../../engine/config/selectors';

// routes
import { pageLinks } from '../../../../routes';
import { useAccessList } from '../../../../ui/_hooks/useAccessList';
import accessList from '../../../../engine/config/accessList';

// styles
const ListItemIcon = styled(MuiListItemIcon)(spacing);
const Card = styled(MuiCard)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const ListItem = styled(MuiListItem)(spacing);

function ProposalOther(props) {
  const {
    match, reboundsData, calculationsData, searchesData,
    pendingSearches, pendingCalculations, pendingRebounds,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { params } = match;
  const [readySubList, setReadySubList] = useState(false);
  const [openCalculations, setOpenCalculations] = useState(false);
  const [openSelection, setOpenSelection] = useState(false);
  const [openSearches, setOpenSearches] = useState(false);
  const accessEstimatesList = useAccessList(accessList.estimates_list_get);
  const accessMktuList = useAccessList(accessList.mktu_list_get);
  const accessSearchList = useAccessList(accessList.search_list_get);

  const calculations = calculationsData.toJS().items;
  const rebounds = reboundsData.toJS().items;
  const searches = searchesData.toJS().items;

  const pendingItems = pendingSearches && pendingCalculations && pendingRebounds;

  const renderArrowsExpand = (list, open) => {
    if (!isEmpty(list)) {
      return open ? <ExpandLessIcon /> : <ExpandMoreIcon />;
    }

    return '';
  };

  const getLabelItem = (item) => item.label || item.title || '';

  useEffect(() => {
    if (!isEmpty(params.hashId) && !readySubList) {
      const filterParams = {
        'trademarks.id': params.hashId,
      };
      setReadySubList(true);
      if (accessEstimatesList) dispatch(tmCalculationsAsyncActions.getListAsync(filterParams));
      if (accessMktuList) dispatch(tmReboundsAsyncActions.getListAsync(filterParams));
      if (accessSearchList) dispatch(tmSearchesAsyncActions.getListAsync(filterParams));
    }
  }, [
    dispatch, params, accessMktuList,
    readySubList, accessEstimatesList,
    setReadySubList, accessSearchList,
  ]);

  return (
    <Card mb={6}>
      <List
        component="nav"
      >
        {isEmpty(params.hashId) && (
          <ListItem>
            <ListItemText primary={t('No data')} pl={0} />
          </ListItem>
        )}
        {!isEmpty(params.hashId) && pendingItems && <Loader />}
        {!isEmpty(params.hashId) && !pendingItems && (
          <>
            <ListItem
              button
              onClick={() => {
                setOpenCalculations(!openCalculations);
              }}
            >
              <ListItemIcon>
                <Apps />
              </ListItemIcon>

              <ListItemText primary={t('Calculations')} pl={0} />
              {renderArrowsExpand(calculations, openCalculations)}
            </ListItem>
            {!isEmpty(calculations) && (
            <Collapse in={openCalculations} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {map(calculations, (item) => (
                  <ListItem
                    key={item.id}
                    button
                    ml={4}
                    onClick={() => {
                      if (pageLinks.tradeMarksRoutes.calculations.edit) {
                        dispatch(push(pageLinks.tradeMarksRoutes.calculations.edit(item.id)));
                      }
                    }}
                  >
                    <ListItemIcon>
                      <PostAdd />
                    </ListItemIcon>
                    <ListItemText inset primary={getLabelItem(item)} pl={0} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
            )}

            <ListItem
              button
              onClick={() => {
                setOpenSelection(!openSelection);
              }}
            >
              <ListItemIcon>
                <Apps />
              </ListItemIcon>

              <ListItemText primary={t('Selection of ICGS')} pl={0} />
              {renderArrowsExpand(rebounds, openSelection)}
            </ListItem>
            {!isEmpty(rebounds) && (
            <Collapse in={openSelection} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {map(rebounds, (item) => (
                  <ListItem
                    key={item.id}
                    button
                    ml={4}
                    onClick={() => {
                      if (pageLinks.tradeMarksRoutes.rebounds.edit) {
                        dispatch(push(pageLinks.tradeMarksRoutes.rebounds.edit(item.id)));
                      }
                    }}
                  >
                    <ListItemIcon>
                      <SelectAll />
                    </ListItemIcon>
                    <ListItemText inset primary={getLabelItem(item)} pl={0} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
            )}

            <ListItem
              button
              onClick={() => {
                setOpenSearches(!openSearches);
              }}
            >
              <ListItemIcon>
                <Apps />
              </ListItemIcon>

              <ListItemText primary={t('Searches')} pl={0} />
              {renderArrowsExpand(searches, openSearches)}
            </ListItem>
            {!isEmpty(searches) && (
            <Collapse in={openSearches} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {map(searches, (item) => (
                  <ListItem
                    key={item.id}
                    button
                    ml={4}
                    onClick={() => {
                      if (pageLinks.tradeMarksRoutes.searches.edit) {
                        dispatch(push(pageLinks.tradeMarksRoutes.searches.edit(item.id)));
                      }
                    }}
                  >
                    <ListItemIcon>
                      <FindInPageOutlined />
                    </ListItemIcon>
                    <ListItemText inset primary={getLabelItem(item)} pl={0} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
            )}
          </>
        )}
      </List>
    </Card>
  );
}

ProposalOther.displayName = 'ProposalOther';

ProposalOther.propTypes = {
  reboundsData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  calculationsData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  searchesData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingSearches: PropTypes.bool.isRequired,
  pendingCalculations: PropTypes.bool.isRequired,
  pendingRebounds: PropTypes.bool.isRequired,
  match: PropTypes.oneOfType([
    PropTypes.object,
  ]),
};

ProposalOther.defaultProps = {
  match: {},
};

function mapStateToProps(state) {
  return {
    reboundsData: selectors.reboundsTable.reboundsData(state),
    calculationsData: selectors.calculationsTable.calculationsData(state),
    searchesData: selectors.tradeMarksSearches.searchesData(state),
    pendingSearches: selectors.tradeMarksSearches.pending(state),
    pendingRebounds: selectors.reboundsTable.pending(state),
    pendingCalculations: selectors.calculationsTable.pending(state),
  };
}

function mapDispatchToProps() {
  return {};
}

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.calculationsData, nextProps.calculationsData)
  && isEqual(prevProps.match, nextProps.match)
  && isEqual(prevProps.pendingCalculations, nextProps.pendingCalculations)
  && isEqual(prevProps.pendingRebounds, nextProps.pendingRebounds)
  && isEqual(prevProps.pendingSearches, nextProps.pendingSearches)
  && isEqual(prevProps.reboundsData, nextProps.reboundsData)
  && isEqual(prevProps.searchesData, nextProps.searchesData);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(memo(ProposalOther, areEqual));
