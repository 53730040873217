// Core
import { apply, put } from 'redux-saga/effects';

// lodash
import { map } from 'lodash';

// config
import { api } from '../../../../config/api';
import { listEntityName } from '../../../../config/listTablesName';

// actions
import actions from '../../action';

const entity = listEntityName.documentTypes;

export function* callGetDocumentsTypeWorker({ payload: params }) {
  yield put(actions.setDocumentsTypePending(true));

  const response = yield apply(api, api.documentTypes.getDocumentsType, [{ entity, ...params }]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data: { items } } = response;
    const modifyItems = map(items, (item) => ({
      ...item,
      label: item.name,
    }));
    yield put(actions.setDocumentsType(modifyItems));
  }
  yield put(actions.setDocumentsTypePending(false));
}
