// Helpers
import actionCreator from '../../_helpers/actionCreator';

// Types
import types from './types';

const actions = Object.freeze({
  setReceivingById(data) {
    return actionCreator(types.SET_RECEIVING_BY_ID, data);
  },
  setReceivingData(data) {
    return actionCreator(types.SET_RECEIVING_DATA, data);
  },
  setCurrentPage(currentPage) {
    return actionCreator(types.SET_RECEIVING_CURRENT_PAGE, currentPage);
  },
  setDeleteReceiving(data) {
    return actionCreator(types.SET_DELETE_RECEIVING, data);
  },
  setPageSize(pageSize) {
    return actionCreator(types.SET_RECEIVING_PAGE_SIZE, pageSize);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_RECEIVING_INITIAL_STATE, initialState);
  },
  setReload(reload) {
    return actionCreator(types.SET_RECEIVING_RELOAD, reload);
  },
  setFilters(data) {
    return actionCreator(types.SET_RECEIVING_FILTERS, data);
  },
  setModalOpen(modalOpen) {
    return actionCreator(types.SET_RECEIVING_MODAL_OPEN, modalOpen);
  },
  setSorting(sorting) {
    return actionCreator(types.SET_RECEIVING_SORTING, sorting);
  },
  setPendingPostCheckDocuments(pending) {
    return actionCreator(types.SET_PENDING_POST_CHECK_DOCUMENTS, pending);
  },
  setColumnWidths(data) {
    return actionCreator(types.SET_RECEIVING_COLUMN_WIDTHS, data);
  },
  setColumnOrder(data) {
    return actionCreator(types.SET_RECEIVING_COLUMN_ORDER, data);
  },
  setHiddenColumnNames(data) {
    return actionCreator(types.SET_RECEIVING_HIDDEN_COLUMN_NAMES, data);
  },
});

export default actions;
