const asyncTypes = Object.freeze({
  GET_DEPARTMENT_DOCUMENTS_ASYNC: 'GET_DEPARTMENT_DOCUMENTS_ASYNC',
  GET_DEPARTMENT_DOCUMENTS_INITIAL_STATE_ASYNC: 'GET_DEPARTMENT_DOCUMENTS_INITIAL_STATE_ASYNC',
  DELETE_DEPARTMENT_DOCUMENT_ASYNC: 'DELETE_DEPARTMENT_DOCUMENT_ASYNC',
  POST_DEPARTMENT_DOCUMENT_ASYNC: 'POST_DEPARTMENT_DOCUMENT_ASYNC',
  GET_DEPARTMENT_DOCUMENT_BY_ID_ASYNC: 'GET_DEPARTMENT_DOCUMENT_BY_ID_ASYNC',
  PUT_DEPARTMENT_DOCUMENT_BY_ID_ASYNC: 'PUT_DEPARTMENT_DOCUMENT_BY_ID_ASYNC',
  GET_DEPARTMENT_DOCUMENTS_AUTOCOMPLETE_OPTIONS_ASYNC: 'GET_DEPARTMENT_DOCUMENTS_AUTOCOMPLETE_OPTIONS_ASYNC',
  GET_DEPARTMENT_DOCUMENTS_NEXT_NUMBER_ASYNC: 'GET_DEPARTMENT_DOCUMENTS_NEXT_NUMBER_ASYNC',
});

export default asyncTypes;
