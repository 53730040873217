// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import types from './types';

const actions = Object.freeze({
  setDepartmentDocumentsDataModal(data) {
    return actionCreator(types.SET_DEPARTMENT_DOCUMENTS_DATA_MODAL, data);
  },
  setCurrentPage(currentPage) {
    return actionCreator(types.SET_DEPARTMENT_DOCUMENTS_CURRENT_PAGE_MODAL, currentPage);
  },
  setFilters(filters) {
    return actionCreator(types.SET_DEPARTMENT_DOCUMENTS_FILTERS_MODAL, filters);
  },
  setPageSize(pageSize) {
    return actionCreator(types.SET_DEPARTMENT_DOCUMENTS_PAGE_SIZE_MODAL, pageSize);
  },
  setReload(reload) {
    return actionCreator(types.SET_DEPARTMENT_DOCUMENTS_RELOAD_MODAL, reload);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_DEPARTMENT_DOCUMENTS_INITIAL_STATE_MODAL, initialState);
  },
});

export default actions;
