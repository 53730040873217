// Core
import { call, put } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../../config/api';
import { messageDataUpdated } from '../../../../../config/globalConfig';

// init
import i18n from '../../../../../init/i18n';

// actions
import actions from '../../action';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../../ui/_helpers/setNotificationMessage';

export function* callPutCurrencyByIdWorker({ payload: currency }) {
  const { setIsModalOpen, setReload } = actions;

  const response = yield call(api.currencies.putCurrencyById, currency);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      yield put(setSuccessMessage(i18n.t(messageDataUpdated), response.status.toString()));
      yield put(setIsModalOpen(false));
      yield put(setReload(true));
    }
  }
}
