// Core
import { call, put } from 'redux-saga/effects';

// config
import { api } from '../../../../config/api';

// helpers
import { getObjectReverseFormatDate } from '../../../../_helpers/formatterDate';

// actions
import pendingActions from '../../action';

export function* callGetPendingActionByIdWorker({ payload: id }) {
  const { setPendingActionById } = pendingActions;
  yield put(setPendingActionById({ pending: true }));

  const response = yield call(api.pendingActions.getPendingActionById, id);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    const {
      id: actionId,
      title,
      status,
      label,
      finishAt,
      createdAt,
      users,
      inventions,
      trademarks,
      utility,
      industrial,
      documents,
      charges,
      reminders,
      remindersParent,
      type,
    } = data;
    yield put(setPendingActionById({
      data: {
        id: actionId,
        title,
        label,
        createdAt,
        inventions,
        users,
        trademarks,
        utility,
        industrial,
        documents,
        charges,
        reminders,
        remindersParent,
        type,
        ...status ? {
          status: `${status}`,
        } : {},
        ...getObjectReverseFormatDate(finishAt, 'start'),
        ...getObjectReverseFormatDate(finishAt, 'end'),
      },
    }));
  }

  yield put(setPendingActionById({
    pending: false,
  }));
}
