// core
import React, { memo } from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';

// lodash
import { isEqual } from 'lodash';

// ui
import { Divider as MuiDivider, Typography } from '@material-ui/core';
import { spacing } from '@material-ui/system';

// parts
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import TableOutdocs from './components/TableOutdocs';
import TableIndocs from './components/TableIndocs';

// hooks
import { useTableOutdocs } from './_hooks/useTableOutdocs';
import { useTableIndocs } from './_hooks/useTableIndocs';

// styles
const Divider = styled(MuiDivider)(spacing);

function ProposalDispatchLog(props) {
  const { match, actionId, showTitle } = props;
  const id = match.params?.hashId;

  useTableOutdocs(id, actionId);
  useTableIndocs(id, actionId);

  const { t } = useTranslation();

  if (!id) {
    return 'No Data';
  }

  return (
    <>
      {showTitle && (
        <>
          <Helmet title={t('Dispatch Log')} />
          <Typography variant="h3" gutterBottom display="inline">
            {t('Dispatch Log')}
          </Typography>
          <Divider my={6} />
        </>
      )}

      <Box mb={10}>
        <TableOutdocs />
      </Box>

      <TableIndocs />
    </>
  );
}

ProposalDispatchLog.propTypes = {
  match: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  actionId: PropTypes.string.isRequired,
  showTitle: PropTypes.bool,
};

ProposalDispatchLog.defaultProps = {
  showTitle: true,
};

ProposalDispatchLog.displayName = 'ProposalDispatchLog';

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.showTitle, nextProps.showTitle)
  && isEqual(prevProps.actionId, nextProps.actionId)
  && isEqual(prevProps.match, nextProps.match);
}

export default memo(ProposalDispatchLog, areEqual);
