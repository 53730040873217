// core
import React from 'react';
import { Link as RouterLink, Route } from 'react-router-dom';

// lodash
// import find from 'lodash/find';
import map from 'lodash/map';

// ui
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

// routes
import { sidebarRoutes as routes } from '../../routes/index';

// parts
import MenuCreateEntity from '../../components/MenuCreateEntity/MenuCreateEntity';

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

export default function RouterBreadcrumbs() {
  return (
    <Route>
      {({ location }) => {
        const pathnames = location.pathname.split('/').filter((x) => x);
        return (
          <Breadcrumbs aria-label="breadcrumb">
            <MenuCreateEntity />
            {map(pathnames, (value, index) => {
              const last = index === pathnames.length - 1;
              const to = `/${pathnames.slice(0, index + 1).join('/')}`;
              map(routes, (category) => {
                if (category.children) {
                  // const currentRout = find(category.children, {path: to})
                  return last ? (
                    <Typography color="textPrimary" key={to}>
                      {/* {currentRout.id} */}
                    </Typography>
                  ) : (
                    <LinkRouter color="inherit" to={to} key={to}>
                      {/* {currentRout.id} */}
                    </LinkRouter>
                  );
                }
                return last ? (
                  <Typography color="textPrimary" key={to}>
                    {category.path[to]}
                  </Typography>
                ) : (
                  <LinkRouter color="inherit" to={to} key={to}>
                    {category.path[to]}
                  </LinkRouter>
                );
              });
            })}
          </Breadcrumbs>
        );
      }}
    </Route>
  );
}
