// core
import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import { Field, Form, reduxForm } from 'redux-form/lib/immutable';

// lodash
import {
  filter,
  includes,
  isEmpty, map,
} from 'lodash';

// config
import selectors from '../../../../engine/config/selectors';

// parts
import RenderTextField from '../../../../ui/Form/RenderTextField';
import Select from '../../../../ui/Form/Select';
import DialogActions from '../../../../components/Modal/components/DialogActions';
import Modal from '../../../../components/Modal/Modal';
import AutocompleteField from '../../../../ui/Form/AutocompleteField';

// actions
import categoryActionAsync from '../../../../engine/core/prices/category/saga/asyncAction';
import categoryActions from '../../../../engine/core/prices/category/action';
import taxesAsyncActions from '../../../../engine/core/taxes/saga/asyncAction';
import taxesActions from '../../../../engine/core/taxes/action';
import accessList from '../../../../engine/config/accessList';
import { useAccessList } from '../../../../ui/_hooks/useAccessList';

function AddCategoryModal(props) {
  const {
    handleSubmit, getCategoryById, taxesData,
    isModalOpen, pendingPutCategory,
    pendingPostCategory, initialize, destroy,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [readyInitialize, setReadyInitialize] = useState(false);
  const accessPut = useAccessList(accessList.costsCategories_put);
  const accessTagsList = useAccessList(accessList.tags_list_get);
  const accessEdit = !isEmpty(getCategoryById.toJS()) ? accessPut : true;

  const handleCloseModal = () => {
    dispatch(categoryActions.setIsModalOpen(false));
  };

  useEffect(() => {
    if (accessTagsList) dispatch(taxesAsyncActions.getListAsync());
  }, [dispatch, accessTagsList]);

  useEffect(() => function cleanup() {
    destroy();
    dispatch(categoryActions.setCategoryDataById({}));
    dispatch(taxesActions.setTaxesData({
      items: [],
      totalCount: 0,
      pending: false,
    }));
  }, [destroy, dispatch]);

  useEffect(() => {
    if (!isEmpty(getCategoryById.toJS()) && !isEmpty(taxesData.toJS().items) && !readyInitialize) {
      const taxesId = map(getCategoryById.toJS().taxes, 'id');
      initialize({
        ...getCategoryById.toJS(),
        ...!isEmpty(getCategoryById.toJS().taxes) ? {
          taxes: filter(taxesData.toJS().items, (tax) => includes(taxesId, tax.id)),
        } : {},
      });
      setReadyInitialize(true);
    }
  }, [
    initialize, getCategoryById, readyInitialize, taxesData,
  ]);

  const handleSubmits = (formData) => {
    const json = formData.toJS();
    dispatch(categoryActionAsync[!isEmpty(getCategoryById.toJS())
      ? 'putCategoryAsync'
      : 'postCategoryAsync']({
      ...getCategoryById.toJS(),
      ...json,
      ...!isEmpty(json.taxes) ? {
        taxes: map(json.taxes, 'id'),
      } : {},
    }));
  };

  return (
    <Modal
      title={t('Add category')}
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
      displayDialogActions={false}
    >
      <Form onSubmit={handleSubmit(handleSubmits)}>
        <Field
          name="name"
          id="name"
          label={t('Name category')}
          margin="normal"
          variant="standard"
          my={2}
          fullWidth
          type="text"
          component={RenderTextField}
          disabled={!accessEdit}
        />

        <Field
          name="rateType"
          id="rateType"
          label={t('Type of rate')}
          labelId="rateType"
          my={2}
          items={[
            {
              value: 'fixed',
              name: t('Fixed rate'),
            },
            {
              value: 'real',
              name: t('The real course'),
            },
          ]}
          fullWidth
          component={Select}
          disabled={!accessEdit}
        />

        <Field
          name="taxes"
          id="taxes"
          label={t('Taxes')}
          labelId="taxes"
          margin="normal"
          options={taxesData.toJS().items}
          my={2}
          fullWidth
          multiple
          component={AutocompleteField}
          disabled={!accessEdit}
        />

        <DialogActions
          pending={pendingPostCategory || pendingPutCategory}
          handleCloseModal={handleCloseModal}
          visibleButtonSend={accessEdit}
        />
      </Form>
    </Modal>
  );
}

AddCategoryModal.displayName = 'AddCategoryModal';

AddCategoryModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  pendingPostCategory: PropTypes.bool.isRequired,
  pendingPutCategory: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  destroy: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  getCategoryById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  taxesData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

function mapStateToProps(state) {
  return {
    isModalOpen: selectors.pricesCategory.isModalOpen(state),
    pendingPostCategory: selectors.pricesCategory.pendingPostCategory(state),
    pendingPutCategory: selectors.pricesCategory.pendingPutCategory(state),
    getCategoryById: selectors.pricesCategory.getCategoryById(state),
    taxesData: selectors.taxes.taxesData(state),
  };
}

export default compose(
  reduxForm({
    form: 'AddCategory',
  }),
  connect(mapStateToProps, null),
)(AddCategoryModal);
