// core
import React, { useEffect } from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';

// ui
import {
  Box, Button,
  Divider as MuiDivider,
  Typography,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import { Add as AddIcon } from '@material-ui/icons';

// parts
import DocumentTemplatesTable from './components/DocumentTemplatesTable';
import AddDocumentModal from './components/AddDocumentModal';

// hooks
import { useDocumentTemplates } from './_hooks/useDocumentTemplates';
import { useAccessList } from '../../ui/_hooks/useAccessList';

// config
import accessList from '../../engine/config/accessList';
import selectors from '../../engine/config/selectors';

// actions
import actions from '../../engine/core/documentTemplates/action';

// styles
const Divider = styled(MuiDivider)(spacing);

function DocumentTemplates(props) {
  const { isModalOpen } = props;
  useDocumentTemplates();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const accessPost = useAccessList(accessList.documentsTemplate_post);

  const handleGoToCreateDocumentPage = () => {
    dispatch(actions.setModalOpen(true));
  };

  useEffect(() => function cleanup() {
    dispatch(actions.setDocumentTemplatesData({
      items: [],
      pending: false,
      totalCount: 0,
    }));
  }, [dispatch]);

  return (
    <>
      <Helmet title={t('Document templates')} />
      <Typography variant="h3" gutterBottom display="inline">
        {t('Document templates')}
      </Typography>
      <Divider my={6} />

      {accessPost && (
        <Box mb={2}>
          <Button variant="contained" color="primary" onClick={handleGoToCreateDocumentPage}>
            {t('ADD')}
            <AddIcon />
          </Button>
        </Box>
      )}

      <DocumentTemplatesTable />

      {isModalOpen && <AddDocumentModal />}
    </>
  );
}

DocumentTemplates.displayName = 'DocumentTemplates';

DocumentTemplates.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    isModalOpen: selectors.documentTemplates.isModalOpen(state),
  };
}

export default compose(
  connect(mapStateToProps, null),
)(DocumentTemplates);
