/* eslint-disable */
// Core
import React, { memo, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { List, Map } from 'immutable';

// lodash
import {
  findIndex, find, map, isEqual, isEmpty,
  isNull, filter, includes,
} from 'lodash';

// UI
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  TextField, CircularProgress, Chip,
  Typography as MuiTypography,
  IconButton as MuiIconButton, Box as MuiBox,
} from '@material-ui/core';

// Icons
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  AddCircleOutline as AddCircleOutlineIcon,
} from '@material-ui/icons';
import { delayCallTimeout } from '../../engine/config/globalConfig';

const TypographyLink = styled(MuiTypography)`
  color: #1976d2;
  margin-left: 5px;
`;

const IconButton = styled(MuiIconButton)`
  z-index: 190;
`;

const Box = styled(MuiBox)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

function AutocompleteField(props) {
  const {
    multiple, label, getAsyncData, options: getOptions, fieldValue,
    loading, getSelectOption, input, margin, defaultValue, handleChange,
    meta: {touched, invalid, error}, handleCreateNew, translate,
    handleDeleteTag, handleEditTag, disableEditing, limitTags,
    handleOnFocus, handleInputChange, meta, isGroupBy, disableEditIcon,
    disableLabel, required, defaultOpen, handleOnBlur, disabled,
    ...rest
  } = props;

  const { t } = useTranslation();
  const options = filter(getOptions,(option) => option.label || option.title || option.name || option.id);

  const [inputValue, setInputValue] = React.useState('');
  const [value, setValue] = React.useState(multiple ? [] : null);
  const [isSetDefaultValue, setIsSetDefaultValue] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const timerId = React.useRef(0);
  const handleOnInputChange = (event, newInputValue) => {
    if (!disableEditIcon || !isEdit) setInputValue(newInputValue);
    if (event?.type === 'change') {
      if (timerId.current) {
        clearTimeout(timerId.current);
      }
      if (newInputValue.length >= 3 && getAsyncData) {
        timerId.current = setTimeout(() => {
          getAsyncData(newInputValue, input.name, event);
        }, delayCallTimeout);
      }
    }

    if (handleInputChange) {
      handleInputChange(newInputValue);
    }
  };

  const handleOnChange = (event, newInputValue) => {
    if ((find(newInputValue, { id: 'add' }) || newInputValue?.id === 'add') && !isEdit) {
      handleCreateNew(inputValue);
      setIsEdit(false);
      event.preventDefault();
      return;
    }
    if (!isEdit) {
      setValue(newInputValue);
      getSelectOption(newInputValue);
      input.onChange(newInputValue);
      handleChange(event, newInputValue);
    }
    setIsEdit(false);
  };
  useEffect(() => {
    if (input.value === '') {
      setValue(multiple ? [] : null);
      setIsSetDefaultValue(false);
    }
    if (!isSetDefaultValue) {
      if (List.isList(input.value) || Map.isMap(input.value) ) {
        if (!isEmpty(options)) {
          let currentOptions = multiple ? [] : null;
          if (multiple) {
            currentOptions = map(input.value.toJS(), (option) => {
              return find(options, option);
            });
          } else {
            currentOptions = options[findIndex(options, input.value.toJS())];
          }
          setValue(currentOptions);
          setIsSetDefaultValue(true);
        } else {
          setValue(input.value.toJS());
          setIsSetDefaultValue(true);
        }
      } else if (!isEmpty(input.value)) {
        setValue(input.value);
        setIsSetDefaultValue(true);

      } else if (!isEmpty(defaultValue)) {
        setValue(defaultValue);
        setIsSetDefaultValue(true);
      }
    }
  }, [input, defaultValue]);

  const handleGetOptionSelected = (option, valueItem) => isEqual(valueItem, option);

  const handlerGetOptionLabel = (option) => (option?.label || option?.title || option?.name || String(option?.id));

  return (
    <Autocomplete
      options={options}
      value={isNull(fieldValue) ? fieldValue : fieldValue || value}
      multiple={multiple}
      disableCloseOnSelect={multiple}
      getOptionLabel={handlerGetOptionLabel}
      inputValue={inputValue}
      getOptionSelected={handleGetOptionSelected}
      loading={loading}
      onChange={handleOnChange}
      onInputChange={handleOnInputChange}
      onFocus={handleOnFocus}
      disabled={disabled}
      {...limitTags ? { limitTags } : {}}
      {...isGroupBy ? {
        groupBy: (option) => option.groupTitle,
      } : {}}
      onBlur={handleOnBlur}
      ListboxProps={{ style: { maxHeight: '20rem' } }}
      filterOptions={(items, params) => {
        const filtered = filter(items, (item) => includes(item.name, params.inputValue));
        return [
          ...!disableEditing ? [{
            id: 'add',
            label: `${t('Add')}${params.inputValue ? ` "${params.inputValue}"` : ''}`,
          }] : [],
          ...filtered,
        ];
      }}
      renderOption={(option) => (
        option.id === 'add' ? (
          <>
            <AddCircleOutlineIcon size="small" style={{ color: '#1976d2' }} />
            <TypographyLink component="span">
              {option.label || option.name || option.id}
            </TypographyLink>
          </>
        ) : (
          <Box>
            {(translate ? t(option?.label) : option?.label) || option.title || option.name || option.id}
            {(!disableEditing || disableEditIcon) && (
              <MuiBox
                onClickCapture={(event) => {
                  setIsEdit(true);
                  event.preventDefault();
                }}
              >
                {!disableEditIcon && (
                  <IconButton size="small" onClick={() => handleEditTag(option)}>
                    <EditIcon size="small" style={{ height: 16, width: 16 }} />
                  </IconButton>
                )}
                <IconButton size="small" onClick={() => handleDeleteTag(option)}>
                  <DeleteIcon size="small" style={{ height: 16, width: 16 }} />
                </IconButton>
              </MuiBox>
            )}
          </Box>
        )
      )}
      renderTags={(items, getTagProps) => (
        map(items, (option, index) => (
          <Chip
            size="small"
            label={translate ? t(option?.label) : option?.label}
            {...getTagProps({ index })}
            style={{
              color: option?.colorTxt ? option.colorTxt : 'black',
              backgroundColor: option?.colorBg || option?.colors ? option.colorBg || option?.colors : 'white',
              borderRadius: 0,
              fontSize: '0.7rem',
            }}
          />
        ))
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          {...!disableLabel ? {
            label,
          } : {}}
          {...defaultOpen ? {
            autoFocus: true,
          } : {}}
          required={required}
          placeholder={label}
          margin={margin}
          autoComplete="off"
          error={touched && invalid}
          helperText={touched && error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

AutocompleteField.defaultProps = {
  multiple: false,
  loading: false,
  isGroupBy: false,
  required: false,
  disableLabel: false,
  disableEditing: false,
  defaultOpen: false,
  disabled: false,
  disableEditIcon: false,
  translate: false,
  limitTags: 0,
  label: '',
  options: [],
  input: {
    onChange: () => {},
  },
  meta: {
    touched: false,
    invalid: false,
    error: false,
  },
  handleChange: () => {},
  getSelectOption: () => {},
  handleOnFocus: () => {},
  handleInputChange: () => {},
  handleCreateNew: () => {},
  handleEditTag: () => {},
  handleDeleteTag: () => {},
  handleOnBlur: () => {},
};

export default memo(AutocompleteField);
