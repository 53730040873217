// Core
import { apply, put } from 'redux-saga/effects';

// config
import { api } from '../../../../../config/api';

// actions
import actions from '../../action';

export function* callGetEmailsWorker({ payload: params }) {
  yield put(actions.setPendingEmails(true));

  const response = yield apply(api, api.email.getEmail, [params]);

  let data = {
    items: [],
  };

  if (response && response.status >= 200 && response.status < 400) {
    data = Object.assign(data, response.data);
  }

  yield put(actions.setEmails(data.items));
  yield put(actions.setPendingEmails(false));
}
