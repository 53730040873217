// core
import React, { useEffect, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import {
  Field, Form, reduxForm,
} from 'redux-form/lib/immutable';

// lodash
import { includes, filter, without } from 'lodash';

// config
import selectors from '../../../engine/config/selectors';

// parts
import Select from '../../../ui/Form/Select';
import Modal from '../../../components/Modal/Modal';
import DialogActions from '../../../components/Modal/components/DialogActions';

// actions
import asyncAction from '../../../engine/core/company/clients/saga/asyncAction';
import actions from '../../../engine/core/company/clients/action';

import { listTablesName } from '../../../engine/config/listTablesName';

const tableName = listTablesName.clients;

function MergeClientsModal(props) {
  const {
    handleSubmit,
    isModalOpenMerge,
    destroy,
    pendingMergeClients,
    clientsData,
    checkboxes,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(actions.setIsModalOpenMerge(false));
  };
  useEffect(() => function cleanup() {
    destroy();
  }, [destroy]);

  const handleSubmits = (formData) => {
    const json = formData.toJS();
    dispatch(asyncAction.postMergeClientsAsync({
      merge: without(checkboxes.toJS(), Number(json.main)),
      main: json.main,
    }));
  };

  const filterClients = useMemo(() => (
    filter(clientsData.toJS().items, (item) => includes(checkboxes.toJS(), item.id))
  ), [clientsData, checkboxes]);

  return (
    <Modal
      title={t('Client association')}
      isModalOpen={isModalOpenMerge}
      handleCloseModal={handleCloseModal}
      displayDialogActions={false}
    >
      <Form onSubmit={handleSubmit(handleSubmits)}>
        <Field
          name="main"
          id="main"
          labelId="main"
          label={t('Master record')}
          items={filterClients}
          fullWidth
          component={Select}
          md={5}
        />

        <DialogActions
          pending={pendingMergeClients}
          handleCloseModal={handleCloseModal}
        />
      </Form>
    </Modal>
  );
}

MergeClientsModal.displayName = 'MergeClientsModal';

MergeClientsModal.propTypes = {
  isModalOpenMerge: PropTypes.bool.isRequired,
  pendingMergeClients: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  destroy: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  clientsData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  checkboxes: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

MergeClientsModal.defaultProps = {};

function mapStateToProps(state) {
  return {
    isModalOpenMerge: selectors.clients.isModalOpenMerge(state),
    pendingMergeClients: selectors.clients.pendingMergeClients(state),
    clientsData: selectors.clientsTable.clientsData(state),
    checkboxes: selectors.helpers.getCheckboxes(state, tableName),
  };
}

export default compose(
  reduxForm({
    form: 'MergeClientsModal',
  }),
  connect(mapStateToProps, null),
)(MergeClientsModal);
