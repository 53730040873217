// Core
import { all, call, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetCalculationsWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callDeleteCalculationWorker,
} from './workers';
import types from '../types';

function* watchGetCalculations() {
  yield takeEvery(asyncTypes.GET_CALCULATIONS_ASYNC, callGetCalculationsWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_CALCULATIONS_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_CALCULATIONS_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_CALCULATIONS_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_CALCULATIONS_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_CALCULATIONS_SORTING, callSetToLocalDataWorker);
}
function* watchSetColumnWidths() {
  yield takeEvery(types.SET_CALCULATIONS_COLUMN_WIDTHS, callSetToLocalDataWorker);
}
function* watchSetColumnOrder() {
  yield takeEvery(types.SET_CALCULATIONS_COLUMN_ORDER, callSetToLocalDataWorker);
}
function* watchSetHiddenColumnNames() {
  yield takeEvery(
    types.SET_CALCULATIONS_HIDDEN_COLUMN_NAMES,
    callSetToLocalDataWorker,
  );
}

function* watchDeleteCalculation() {
  yield takeEvery(asyncTypes.DELETE_CALCULATION_ASYNC, callDeleteCalculationWorker);
}

export function* watchersCalculationsTable() {
  yield all([
    call(watchGetCalculations),
    call(watchSetCurrentPage),
    call(watchSetPageSize),
    call(watchSetFilters),
    call(watchSetSorting),
    call(watchSetColumnWidths),
    call(watchSetColumnOrder),
    call(watchSetHiddenColumnNames),
    call(watchGetInitialState),
    call(watchDeleteCalculation),
  ]);
}
