// Core
import { Map, List } from 'immutable';

// Instruments
import types from './types';
import globalStateKeys from '../../config/globalStateKeys';

const initialState = Map({
  pendingPost: false,
  pendingSpecialDocuments: false,
  specialDocuments: Map(),
  selectedObjects: Map(),
  isModalOpen: false,
  entityList: Map({
    trademarks: List(),
    utility: List(),
    inventions: List(),
  }),
  pendingPostExcel: false,
  pendingPostExport: false,
  isModalOpenObject: false,
  entityListNumber: Map({
    trademarks: List(),
    utility: List(),
    inventions: List(),
  }),
});

export const stateKeys = Object.freeze({
  controller: 'importObjects',
  pendingSpecialDocuments: 'pendingSpecialDocuments',
  specialDocuments: 'specialDocuments',
  selectedObjects: 'selectedObjects',
  pendingPostExcel: 'pendingPostExcel',
  pendingPostExport: 'pendingPostExport',
  isModalOpenObject: 'isModalOpenObject',
  entityListNumber: 'entityListNumber',
});

export const importObjectsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_PENDING_IMPORT_OBJECTS: {
      return state.setIn([globalStateKeys.pendingPost], payload);
    }
    case types.SET_PENDING_POST_IMPORT_EXCEL: {
      return state.setIn([stateKeys.pendingPostExcel], payload);
    }
    case types.SET_SPECIAL_DOCUMENTS: {
      return state.setIn([stateKeys.specialDocuments], Map(payload));
    }
    case types.SET_IMPORT_SELECTED_OBJECTS: {
      return state.setIn([stateKeys.selectedObjects], Map(payload));
    }
    case types.MERGE_IMPORT_SELECTED_OBJECTS: {
      const { entityName, items } = payload;
      return state.mergeIn([stateKeys.selectedObjects], Map({ [entityName]: List(items) }));
    }
    case types.MERGE_IMPORT_ENTITY_LIST: {
      const { entityName, items } = payload;
      return state.mergeIn([globalStateKeys.entityList], Map({ [entityName]: List(items) }));
    }
    case types.SET_IMPORT_ENTITY_LIST: {
      return state.setIn([globalStateKeys.entityList], Map(payload));
    }
    case types.SET_PENDING_SPECIAL_DOCUMENTS: {
      return state.setIn([stateKeys.pendingSpecialDocuments], payload);
    }
    case types.SET_IMPORT_IS_MODAL_OPEN: {
      return state.setIn([globalStateKeys.isModalOpen], payload);
    }
    case types.SET_PENDING_POST_EXPORT: {
      return state.setIn([stateKeys.pendingPostExport], payload);
    }
    case types.SET_IMPORT_IS_MODAL_OPEN_OBJECT: {
      return state.setIn([stateKeys.isModalOpenObject], payload);
    }
    case types.SET_IMPORT_ENTITY_LIST_NUMBER: {
      return state.setIn([stateKeys.entityListNumber], Map(payload));
    }
    case types.MERGE_IMPORT_ENTITY_LIST_NUMBER: {
      const { entityName, items } = payload;
      return state.mergeIn([stateKeys.entityListNumber], Map({ [entityName]: List(items) }));
    }
    default:
      return state;
  }
};
