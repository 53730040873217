// Core
import React from 'react';
import { Trans } from 'react-i18next';

export default [
  { name: <Trans>Ukrainian</Trans>, value: 'ua' },
  { name: <Trans>English</Trans>, value: 'en' },
  { name: <Trans>Russian</Trans>, value: 'ru' },
  { name: <Trans>French</Trans>, value: 'fr' },
];
