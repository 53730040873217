// core
import React, { useEffect, useState, memo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import { Field, Form, reduxForm } from 'redux-form/lib/immutable';
import styled from 'styled-components';

// lodash
import isEmpty from 'lodash/isEmpty';

// ui
import {
  Box, ListItem as MuiListItem, List as MuiList,
  IconButton as MuiIconButton, Grid,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import {
  Close as CloseIcon,
  Description as MuiDescription,
} from '@material-ui/icons';
import { DropzoneAreaBase } from 'material-ui-dropzone';

// config
import selectors from '../../engine/config/selectors';

// helpers
import { validators } from '../../ui/_helpers/validation';

// parts
import RenderTextField from '../../ui/Form/RenderTextField';
import Select from '../../ui/Form/Select';
import Modal from '../Modal/Modal';
import DialogActions from '../Modal/components/DialogActions';

// actions
import employeesActionAsync from '../../engine/core/company/employees/saga/asyncAction';
import helpersActionAsync from '../../engine/core/helpers/saga/asyncAction';
import helpersActions from '../../engine/core/helpers/action';

// styles
const Description = styled(MuiDescription)(spacing);
const IconButton = styled(MuiIconButton)(spacing);

const List = styled(MuiList)`
  display: flex;
`;

const ListItem = styled(MuiListItem)`
  width: auto;
  padding-left: 0;
`;

function SupportModal(props) {
  const {
    handleSubmit, userInfo, getEmployeeById,
    isModalOpen, supportTicketsData,
    pendingPost, initialize,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [uploadFile, setUploadFile] = useState({});

  useEffect(() => {
    if (isEmpty(supportTicketsData.toJS()) && !isEmpty(userInfo)) {
      dispatch(employeesActionAsync.getEmployeeByIdAsync(userInfo.id));
    }
  }, [userInfo, dispatch, supportTicketsData]);

  const handleCloseModal = () => {
    dispatch(helpersActions.setModal({ isModalOpenSupport: false }));
  };

  const handleSubmits = (formData) => {
    const json = formData.toJSON();
    dispatch(helpersActionAsync.postSupportTicketsAsync({
      ...json,
      ...!isEmpty(uploadFile) ? {
        attachment: uploadFile.base64,
      } : {},
    }));
  };

  const handleUploadFiles = (files) => {
    if (!isEmpty(files) && files[0]?.data) {
      setUploadFile({
        filename: files[0].file?.name,
        base64: files[0].data,
      });
    }
  };

  const handleDeleteFile = () => {
    setUploadFile({});
  };

  useEffect(() => {
    if (!isEmpty(getEmployeeById.toJS()) || !isEmpty(supportTicketsData.toJS())) {
      initialize({
        name: supportTicketsData.toJS().name || getEmployeeById.toJS().name,
        phone: supportTicketsData.toJS().phone || getEmployeeById.toJS().phone,
        email: supportTicketsData.toJS().email || getEmployeeById.toJS().email,
      });
    }
  }, [
    initialize, getEmployeeById, supportTicketsData,
  ]);

  return (
    <Modal
      title={t('Support')}
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
      displayDialogActions={false}
    >
      <Form onSubmit={handleSubmit(handleSubmits)}>
        <Field
          required
          validate={validators.required}
          name="name"
          id="name"
          label={t('Name')}
          variant="standard"
          fullWidth
          type="text"
          component={RenderTextField}
        />
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Field
              required
              validate={validators.email}
              id="email"
              name="email"
              label={t('Email')}
              variant="standard"
              margin="normal"
              fullWidth
              component={RenderTextField}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Field
              required
              id="phone"
              name="phone"
              label={t('Phone')}
              margin="normal"
              fullWidth
              component={RenderTextField}
            />
          </Grid>
        </Grid>

        <Field
          required
          name="subject"
          id="subject"
          label={t('Subject')}
          labelId="subject"
          margin="none"
          items={[
            {
              value: 'Report a problem',
              name: t('Report a problem'),
            },
            {
              value: 'Suggest improvements',
              name: t('Suggest improvements'),
            },
            {
              value: 'Other',
              name: t('Other'),
            },
          ]}
          fullWidth
          component={Select}
        />

        {!isEmpty(uploadFile) && (
        <List>
          <ListItem>
            <Description mr={1} />
            <Box mr={1}>
              {uploadFile.filename}
            </Box>
            <IconButton
              aria-label="Delete"
              size="small"
              onClick={handleDeleteFile}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </ListItem>
        </List>
        )}

        {isEmpty(uploadFile) && (
          <Box mt={2} mb={2}>
            <DropzoneAreaBase
              dropzoneText={t('Drag and drop a file here or click')}
              showPreviewsInDropzone={false}
              showAlerts={false}
              clearOnUnmount
              maxFileSize={21000000}
              filesLimit={1}
              onAdd={(files) => {
                handleUploadFiles(files);
              }}
            />
          </Box>
        )}
        <Field
          required
          validate={validators.required}
          multiline
          name="message"
          id="message"
          label={t('Message')}
          margin="normal"
          variant="outlined"
          fullWidth
          component={RenderTextField}
          rows={3}
        />
        <DialogActions
          pending={pendingPost}
          handleCloseModal={handleCloseModal}
        />
      </Form>
    </Modal>
  );
}

SupportModal.displayName = 'SupportModal';

SupportModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  supportTicketsData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  userInfo: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  getEmployeeById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingPost: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
};

function mapStateToProps(state) {
  return {
    isModalOpen: selectors.helpers.isModalOpenSupport(state),
    supportTicketsData: selectors.helpers.supportTicketsData(state),
    pendingPost: selectors.helpers.pendingPostSupportTickets(state),
    userInfo: selectors.user.userInfo(state),
    getEmployeeById: selectors.employees.getEmployeeById(state),
  };
}

export default compose(
  reduxForm({
    form: 'SupportModal',
  }),
  connect(mapStateToProps, null),
)(memo(SupportModal));
