// core
import React from 'react';
import { Field } from 'redux-form/lib/immutable';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

// parts
import AutocompleteField from '../AutocompleteField';

// config
import selectors from '../../../engine/config/selectors';

// helpers
import { getValueFormField } from '../../_helpers/getValueFormField';
// import { searchParams } from './helper/searchParams';

//
import { useMain } from './_hook/useMain';

function FieldCurrency(props) {
  const {
    data, formFieldInput, pendingDefaultList,
    name, propsField, label,
    getAutocompleteLists,
    formField,
  } = props;
  const { t } = useTranslation();

  const {
    startLoading, handleOnFocus, dataItems,
  } = useMain({
    formField,
    getAutocompleteLists,
    formFieldInput,
  });

  return (
    <Field
      fullWidth
      changeToStore
      name={name}
      label={t(label)}
      margin="normal"
      loading={startLoading ? (pendingDefaultList) : false}
      handleOnFocus={handleOnFocus(name, data)}
      options={dataItems(data)}
      component={AutocompleteField}
      {...propsField}
    />
  );
}

FieldCurrency.displayName = 'FieldCurrency';

FieldCurrency.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  getAutocompleteLists: PropTypes.func.isRequired,
  pendingDefaultList: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  propsField: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  formField: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array,
  ]),
  formFieldInput: PropTypes.string,
  label: PropTypes.string,
};

FieldCurrency.defaultProps = {
  formField: {},
  propsField: {},
  formFieldInput: '',
  label: 'Currency',
};

function mapStateToProps(state, props) {
  const { formName, name } = props;
  const formField = getValueFormField(state, formName, name);
  const formFieldInput = getValueFormField(state, formName, `${name}Input`);

  return {
    data: selectors.currencies.currenciesData(state),
    pendingDefaultList: selectors.currencies.pendingAutocompleteOptions(state),
    formField: formField(),
    formFieldInput: formFieldInput(),
  };
}

export default compose(
  connect(mapStateToProps, null),
)(FieldCurrency);
