// Core
import React, { useEffect, useState, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';
import * as PropTypes from 'prop-types';
import {
  Field, Form, reduxForm, change,
} from 'redux-form/immutable';
import FileInputComponent from 'react-file-input-previews-base64';
import XLSX from 'xlsx';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

// lodash
import map from 'lodash/map';
import without from 'lodash/without';
import reduce from 'lodash/reduce';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';

// UI
import {
  CardContent, Box, Button, Typography, IconButton as MuiIconButton,
  Grid as MuiGrid, CircularProgress, Card as MuiCard, Box as MuiBox,
} from '@material-ui/core';

// icon
import {
  Close as CloseIcon,
} from '@material-ui/icons';

// parts
import RenderTextField from '../../ui/Form/RenderTextField';
import Select from '../../ui/Form/Select';

// config
import selectors from '../../engine/config/selectors';

// actions
import importAsyncAction from '../../engine/core/importObjects/saga/asyncAction';
import fieldsSettingsActionAsync from '../../engine/core/fieldsSettings/saga/asyncAction';
import actions from '../../engine/core/fieldsSettings/action';
import { fieldNames } from './helper/fieldNames';

// styles
const Grid = styled(MuiGrid)(spacing);
const Card = styled(MuiCard)(spacing);
const BoxDroppable = styled(MuiBox)`
  border-radius: 4px;
  border: 1px solid;
  border-color: ${(props) => props.theme.palette.grey['500']};
  margin-bottom: 10px;
  min-height: 92px;
`;
const BoxDraggable = styled(MuiBox)`
  background-color: #1976d2;
  width: fit-content;
  padding: 8px; 
  margin-right: 8px;
  color: white;
`;
const BoxContainer = styled(MuiBox)`
  display: flex;
  flex-wrap: wrap;
`;
const TitleDroppable = styled.div`
  background-color: ${(props) => props.theme.palette.grey['200']};
  border-bottom: 2px solid;
  border-color: ${(props) => props.theme.palette.grey['500']};
  font-weight: 500;
  margin-bottom: 8px;
  padding: 8px;
`;
const IconButton = styled(MuiIconButton)`
  padding: 0;
  padding-left: 5px;
  color: white;
`;

const formName = 'ImportFromExcel';

function ImportFromExcel(props) {
  const {
    handleSubmit, pendingPostExcel, fieldsSetting,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [fileBase64, setFileBase64] = useState('');
  const [listItem, setListItem] = useState([]);
  const [loaderFile, setLoaderFile] = useState(false);

  useEffect(() => function cleanup() {
    dispatch(actions.setFieldsSetting({}));
  }, [dispatch]);

  const handleSubmits = (formData) => {
    const jsFormData = formData.toJS();
    delete jsFormData.file;
    dispatch(importAsyncAction.postImportExcelAsync({
      ...jsFormData,
      fileBase64,
      objectType: jsFormData.objectType?.toLowerCase(),
      mappings: reduce(listItem, (acc, item) => ({
        ...acc,
        ...item.value ? { [item.value]: item.name } : {},
      }), {}),
    }));
  };

  const itemObjectTypes = [
    { name: t('the Trademark'), value: 'Trademarks' },
    { name: t('the Utility Model'), value: 'Utility' },
    { name: t('the Industrial Design'), value: 'Industrial' },
    { name: t('the Invention'), value: 'Inventions' },
    { name: t('the Client'), value: 'Clients' },
    { name: t('the Applicant'), value: 'Applicants' },
    { name: t('the Payment'), value: 'Payments' },
  ];

  const handleObjectType = (e) => {
    dispatch(fieldsSettingsActionAsync.getFieldsSettingsAsync({ entity: e.target.value }));
    setListItem(map(listItem, (item) => ({ ...item, value: '' })));
  };

  const handleUploadFile = (fileBase) => {
    setLoaderFile(true);
    if (fileBase && fileBase.name && includes(fileBase.name, 'xls')) {
      setFileBase64(fileBase.base64);
      dispatch(change(formName, 'file', fileBase?.file?.name || ''));

      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = (e) => {
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, {
          header: 1,
        });
        setListItem(reduce(data[0], (acc, item) => ([
          ...acc,
          { name: item, value: '' },
        ]), []));
      };
      if (rABS) reader.readAsBinaryString(fileBase.file);
      else reader.readAsArrayBuffer(fileBase.file);
    } else {
      setFileBase64('');
      setListItem([]);
    }
    setLoaderFile(false);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    if (result.destination?.droppableId === result.source?.droppableId) return;
    const newList = map(listItem, (item) => {
      if (item.name === result.destination?.droppableId) {
        return { ...item, value: result.draggableId };
      }
      if (item.name === result.source?.droppableId && result.source?.droppableId !== 'basic') {
        return { ...item, value: '' };
      }
      return item;
    });
    setListItem(newList);
  };

  const handleDeleteItem = (name) => {
    setListItem(map(listItem, (item) => (item.name === name
      ? { ...item, value: '' }
      : item
    )));
  };

  const getItemSetting = useMemo(() => (
    without(Object.keys(fieldsSetting), ...map(listItem, 'value'))
  ), [fieldsSetting, listItem]);

  const getItemsFindBy = useMemo(() => map(Object.keys(fieldsSetting), (item) => ({
    name: fieldNames[item] || item,
    value: item,
  })), [fieldsSetting]);

  return (
    <Form onSubmit={handleSubmit(handleSubmits)}>
      <Card mb={6}>
        <CardContent>
          <Typography variant="h4" gutterBottom>
            {t('Settings')}
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Field
                name="objectType"
                id="objectType"
                label={t('What we import')}
                labelId="objectType"
                fullWidth
                my={2}
                items={itemObjectTypes}
                component={Select}
                onChange={handleObjectType}
              />
              <Field
                name="update"
                id="update"
                label={t('Update existing data')}
                labelId="update"
                component={Select}
                items={[
                  { name: t('Yes'), value: 'true' },
                  { name: t('No'), value: 'false' },
                ]}
                margin="normal"
                fullWidth
              />
              <Field
                name="findBy"
                id="findBy"
                label={t('Search for existing data')}
                labelId="findBy"
                component={Select}
                items={getItemsFindBy}
                margin="normal"
                fullWidth
              />
              <FileInputComponent
                name="logo"
                imagePreview={false}
                labelStyle={{ display: 'none' }}
                parentStyle={{ display: 'flex', marginTop: 14, alignItems: 'flex-end' }}
                multiple={false}
                callbackFunction={handleUploadFile}
                accept="file"
                textBoxVisible
                textFieldComponent={(
                  <Field
                    name="file"
                    label={t('File')}
                    component={RenderTextField}
                    margin="none"
                    fullWidth
                    required
                  />
                )}
                buttonComponent={(
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                  >
                    {loaderFile
                      ? <CircularProgress size={25} color="inherit" />
                      : t('Upload')}
                    {/* {t('Upload')} */}
                  </Button>
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card mb={6}>
        <CardContent>
          <Typography variant="h4" gutterBottom>
            {t('Data')}
          </Typography>
          {!isEmpty(listItem) && !isEmpty(fieldsSetting) && (
            <DragDropContext onDragEnd={onDragEnd}>
              <Grid container spacing={4}>
                <Grid item xs={12} my={2}>
                  <Droppable droppableId="basic" isDropDisabled>
                    {(drop) => (
                      <BoxContainer ref={drop.innerRef} {...drop.droppableProps}>
                        {map(getItemSetting, (item, index) => (
                          <Draggable draggableId={item} index={index} key={item}>
                            {(provided) => (
                              <BoxDraggable
                                my={1}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                {fieldNames[item] || item}
                              </BoxDraggable>
                            )}
                          </Draggable>
                        ))}
                        {drop.placeholder}
                      </BoxContainer>
                    )}
                  </Droppable>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                {map(listItem, (item, index) => (
                  <Droppable droppableId={item.name} key={index} isDropDisabled={!!item.value}>
                    {(drop) => (
                      <Grid item xs={12} md={2}>
                        <BoxDroppable ref={drop.innerRef} {...drop.droppableProps}>
                          <TitleDroppable variant="subtitle1" gutterBottom>
                            {item.name}
                          </TitleDroppable>
                          {item.value && (
                            <Draggable draggableId={item.value} index={0}>
                              {(provided) => (
                                <BoxDraggable
                                  ml={2}
                                  mb={2}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  {fieldNames[item.value] || item.value}
                                  <IconButton
                                    aria-label="Delete"
                                    size="small"
                                    onClick={() => {
                                      handleDeleteItem(item.name);
                                    }}
                                  >
                                    <CloseIcon fontSize="small" />
                                  </IconButton>
                                </BoxDraggable>
                              )}
                            </Draggable>
                          )}
                          {drop.placeholder}
                        </BoxDroppable>
                      </Grid>
                    )}
                  </Droppable>
                ))}
              </Grid>
            </DragDropContext>
          )}
        </CardContent>
      </Card>

      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
        >
          {pendingPostExcel
            ? <CircularProgress size={25} color="inherit" />
            : t('SAVE')}
        </Button>
      </Box>
    </Form>
  );
}

ImportFromExcel.displayName = 'ImportFromExcel';

ImportFromExcel.propTypes = {
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  pendingPostExcel: PropTypes.bool.isRequired,
  fieldsSetting: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

ImportFromExcel.defaultProps = {};

function mapStateToProps(state) {
  return {
    fieldsSetting: selectors.fieldsSetting.fieldsSetting(state),
    pendingPostExcel: selectors.importObjects.pendingPostExcel(state),
  };
}

function mapDispatchToProps() {
  return {};
}

export default compose(
  reduxForm({
    form: formName,
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(ImportFromExcel);
