// core
import React, { useEffect, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import styled from 'styled-components';

// lodash
import {
  isEmpty, isEqual, isObject, keys,
  mergeWith, map, isBoolean, isArray,
} from 'lodash';

// ui
import {
  Grid as MuiGrid, Typography as MuiTypography,
  Divider as MuiDivider,
  // Tooltip,
  Box,
} from '@material-ui/core';
import {
  grey,
} from '@material-ui/core/colors';
import { spacing } from '@material-ui/system';

// config
import selectors from '../../engine/config/selectors';

// parts
import Modal from '../../components/Modal/Modal';
import LoaderWithOverlay from '../../components/LoaderWithOverlay';

// actions
import actions from '../../engine/core/logging/action';
import { fieldNames } from '../ImportObjects/helper/fieldNames';
import { formatDateDotSeparate } from '../../engine/_helpers/formatterDate';

// style
const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)`
  white-space: pre-line;
  overflow: hidden;
`;
const TypographyTitle = styled(MuiTypography)`
  color: ${grey['500']};
`;
const Grid = styled(MuiGrid)`
  padding: 4px 8px 0 8px;
`;

function LogModal(props) {
  const {
    isModalOpen,
    getLogById,
    pendingLogById,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(actions.setIsModalOpen(false));
  };
  const getListDiff = useMemo(() => {
    const listDiff = [];
    if (!isEmpty(getLogById.toJS()?.target)) {
      const object = getLogById.toJS()?.source;
      const source = getLogById.toJS()?.target;
      mergeWith(getLogById.toJS()?.source || {},
        getLogById.toJS()?.target, (objectValue, sourceValue, key) => {
          if ((isArray(objectValue) || isArray(sourceValue))
          && (isEmpty(objectValue) && isEmpty(sourceValue))) {
            return;
          }
          if (!isEqual(objectValue, sourceValue)
          && ((objectValue && isEqual(object?.[key], objectValue))
          || (sourceValue && isEqual(source?.[key], sourceValue)))) {
            listDiff.push({ key, source: objectValue, target: sourceValue });
          }
        });
    }
    return listDiff;
  }, [
    getLogById,
  ]);

  useEffect(() => function cleanup() {
    dispatch(actions.setLoggingDataById({}));
  }, [dispatch]);

  const getValue = (value, key) => {
    if (isArray(value) && !isEmpty(value)) {
      if (key === 'classes' || key === 'documents' || key === 'tags') return value.join(',');
      return value[0]?.name || value[0]?.id || key;
    }
    if (isObject(value)) {
      if (key === 'selected') return keys(value)?.join(',');
      return value?.name || value?.fullName || value?.date
      || value?.originCountry || value?.id || key;
    }
    if (isBoolean(value)) {
      return value ? t('Yes') : t('No');
    }
    if (key === 'documentDate' || key === 'receivingDate' || key === 'createdAt' || key === 'initialPublication'
    || key === 'applicationDate' || key === 'registrationDate' || key === 'expiryDate') {
      return formatDateDotSeparate(value);
    }
    return value;
  };

  return (
    <Modal
      title={t('Action')}
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
      displayDialogActions={false}
      maxWidth="md"
    >
      {pendingLogById ? (
        <LoaderWithOverlay />
      ) : (
        <>
          {!isEmpty(getListDiff) ? (
            <>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" gutterBottom>
                    {t('Previous values')}
                  </Typography>
                  <Divider my={2} />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography variant="h6" gutterBottom>
                    {t('New values')}
                  </Typography>
                  <Divider my={2} />
                </Grid>
              </Grid>
              {map(getListDiff, (item) => (
                (getLogById.toJS()?.source?.[item.key]
                || getLogById.toJS()?.target?.[item.key]) && (
                  <Grid container key={item.key}>
                    <Grid item xs={12} md={6}>
                      <TypographyTitle variant="body2">{fieldNames[item.key] || item.key}</TypographyTitle>
                      <Box display="flex" flexDirection="row" alignItems="center">
                        <Typography
                          variant="body2"
                        >
                          {getValue(item.source, item.key)}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TypographyTitle variant="body2">{fieldNames[item.key] || item.key}</TypographyTitle>
                      <Typography variant="body2">
                        {getValue(item.target, item.key)}
                      </Typography>
                    </Grid>
                  </Grid>
                )
              ))}
            </>
          ) : (
            <Grid container>
              <Typography variant="h6" gutterBottom>
                {t('There are no changes')}
              </Typography>
            </Grid>
          )}
        </>
      )}
    </Modal>
  );
}

LogModal.displayName = 'LogModal';

LogModal.propTypes = {
  getLogById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  pendingLogById: PropTypes.bool.isRequired,
};

LogModal.defaultProps = {};

function mapStateToProps(state) {
  return {
    getLogById: selectors.logging.getLogById(state),
    isModalOpen: selectors.logging.isModalOpen(state),
    pendingLogById: selectors.logging.pendingLogById(state),
  };
}

export default compose(
  connect(mapStateToProps, null),
)(LogModal);
