// Core
import { apply, put } from 'redux-saga/effects';

// config
import { api } from '../../../../../config/api';

// actions
import actions from '../../action';

export function* callGetReboundByIdWorker({ payload: id }) {
  yield put(actions.setPendingReboundById({
    pending: true,
  }));
  const response = yield apply(api, api.trademarks.getReboundById, [id]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    yield put(actions.setReboundById(data));
  }

  yield put(actions.setPendingReboundById({
    pending: false,
  }));
}
