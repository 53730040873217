export default Object.freeze({
  SET_DOCUMENT_TEMPLATES_DATA: 'SET_DOCUMENT_TEMPLATES_DATA',
  SET_DOCUMENT_TEMPLATES_CURRENT_PAGE: 'SET_DOCUMENT_TEMPLATES_CURRENT_PAGE',
  SET_DOCUMENT_TEMPLATES_PAGE_SIZE: 'SET_DOCUMENT_TEMPLATES_PAGE_SIZE',
  SET_DOCUMENT_TEMPLATES_FILTERS: 'SET_DOCUMENT_TEMPLATES_FILTERS',
  SET_DOCUMENT_TEMPLATES_SORTING: 'SET_DOCUMENT_TEMPLATES_SORTING',
  SET_DOCUMENT_TEMPLATES_INITIAL_STATE: 'SET_DOCUMENT_TEMPLATES_INITIAL_STATE',
  SET_DOCUMENT_TEMPLATES_RELOAD: 'SET_DOCUMENT_TEMPLATES_RELOAD',
  SET_DELETE_DOCUMENT_TEMPLATE: 'SET_DELETE_DOCUMENT_TEMPLATE',
  SET_DOCUMENT_TEMPLATES_MODAL_OPEN: 'SET_DOCUMENT_TEMPLATES_MODAL_OPEN',
  SET_DOCUMENT_TEMPLATES_BY_ID: 'SET_DOCUMENT_TEMPLATES_BY_ID',
  SET_DOCUMENT_TEMPLATES_AUTOCOMPLETE_OPTIONS: 'SET_DOCUMENT_TEMPLATES_AUTOCOMPLETE_OPTIONS',
  SET_PENDING_DOCUMENT_TEMPLATES_AUTOCOMPLETE_OPTIONS: 'SET_PENDING_DOCUMENT_TEMPLATES_AUTOCOMPLETE_OPTIONS',
  SET_DOCUMENT_TEMPLATES_COLUMN_WIDTHS: 'SET_DOCUMENT_TEMPLATES_COLUMN_WIDTHS',
  SET_DOCUMENT_TEMPLATES_COLUMN_ORDER: 'SET_DOCUMENT_TEMPLATES_COLUMN_ORDER',
  SET_DOCUMENT_TEMPLATES_HIDDEN_COLUMN_NAMES: 'SET_DOCUMENT_TEMPLATES_HIDDEN_COLUMN_NAMES',
  SET_DOCUMENT_TEMPLATES_ORIGINAL_FILE: 'SET_DOCUMENT_TEMPLATES_ORIGINAL_FILE',
});
