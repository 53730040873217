/* eslint-disable */
// core
import React, {
  memo, useEffect, useMemo, useState,
} from 'react';
import {
  reduxForm, Field, Form, change,
} from 'redux-form/immutable';
import { compose } from 'recompose';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import FileInputComponent from 'react-file-input-previews-base64';
/* istanbul ignore next */
import { EndUser, EndUserConstants, EndUserKeyMedia } from 'euscp';

import styled from 'styled-components';

// ui
import {
  CircularProgress as MuiCircularProgress, Dialog, DialogTitle, Grid, IconButton,
  Tabs, Tab as MuiTab, Badge as MuiBadge, FormControlLabel, Radio,
  Button as MuiButton, DialogContent as MuiDialogContent, DialogActions,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { spacing } from '@material-ui/system';

// icon
import { Close, CloudUpload as MuiCloudUpload, CloudDownload } from '@material-ui/icons';

// lodash
import map from 'lodash/map';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import find from 'lodash/find';

// actions
import helpersActionAsync from '../../engine/core/helpers/saga/asyncAction';
import actions from '../../engine/core/helpers/action';
import employeesActionAsync from '../../engine/core/company/employees/saga/asyncAction';

// config
import selectors from '../../engine/config/selectors';

// parts
import AutocompleteField from '../../ui/Form/AutocompleteField';
import RenderTextField from '../../ui/Form/RenderTextField';
import RememberCheckbox from '../../pages/Auth/components/RememberCheckbox';
import Select from '../../ui/Form/Select';
import RadioButton from '../../ui/Form/RadioButton';
import LoaderWithOverlay from '../LoaderWithOverlay';

// Helpers
import { validators } from '../../ui/_helpers/validation';
import { dataURLtoFile, base64toUint8 } from './helper/formatterFile';
import { euSettings } from './helper';
import { setErrorMessage } from '../../ui/_helpers/setNotificationMessage';
import { euSignCodes } from './helper/eusignCodes';
import accessList from '../../engine/config/accessList';
import { useAccessList } from '../../ui/_hooks/useAccessList';

// style
const Button = styled(MuiButton)(spacing);
const DialogContent = styled(MuiDialogContent)(spacing);
const CloudUpload = styled(MuiCloudUpload)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);

const Badge = styled(MuiBadge)`
  .MuiBadge-badge {
    padding: 0 4px;
    font-size: 10px;
  }
  .MuiBadge-anchorOriginTopRightRectangle {
    right: 3px;
  }
`;

const Tab = styled(MuiTab)`
  min-width: 72px;
`;

const formName = 'Signature';

// Бібліотека для роботи з файловими ключами, що не потребує
// встановлення додатково ПЗ
const euSignFile = new EndUser(null, EndUserConstants?.EndUserLibraryType?.JS);

// Бібліотека для роботи з аппаратними носіями, що потребує
// встановлення додатково ПЗ бібліотек web-підпису, web-розширення для браузера
const euSignKeyMedia = new EndUser(null, EndUserConstants?.EndUserLibraryType?.SW);

function Sign(props) {
  const {
    keysOptions, pending, type, isModalOpen, reloadDocumentsOut,
    handleSubmit, valid, files, signatureInfo, filesWithBase64, destroy,
    fieldKey, fieldTypeKey, dataAllFiles,
  } = props;
  const [selectOption, setSelectOption] = useState(null);
  const [valueTab, setValueTab] = useState(0);
  const [uploadFile, setUploadFile] = useState({});
  const [jsonData, setJsonData] = useState({});
  const [readyInitialFile, setReadyInitialFile] = useState(false);
  const [isKeys, setIsKeys] = useState(false);
  const [accessSubmit, setAccessSubmit] = useState(false);
  const [pendingUpload, setPendingUpload] = useState(false);
  const [readyInitialize, setReadyInitialize] = useState(false);
  const [signatureKeys, setSignatureKeys] = useState([]);
  const [keyMedias, setKeyMedias] = useState([]);
  const [loaderMedia, setLoaderMedia] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [alertUrl, setAlertUrl] = useState('');
  const [euSign, setEuSign] = useState(euSignFile);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const accessKeysList = useAccessList(accessList.keys_list_get);

  useEffect(() => {
    dispatch(helpersActionAsync.getInitialStateAsync({ url: 'signatureInfo' }));
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(signatureInfo.toJS()) && !readyInitialFile && fieldTypeKey === 'file') {
      setUploadFile({
        name: signatureInfo.toJS().name,
        base64: signatureInfo.toJS().base64,
        data: base64toUint8(signatureInfo.toJS().base64),
        file: dataURLtoFile(signatureInfo.toJS().base64, signatureInfo.toJS().name),
      });
      if (!isEmpty(signatureInfo.toJS().keys)) setSignatureKeys(signatureInfo.toJS().keys);
      if (!isNil(signatureInfo.toJS().key)) {
        dispatch(change(formName, 'key', signatureInfo.toJS().key));
      }
      dispatch(change(formName, 'file', signatureInfo.toJS().name));
      dispatch(change(formName, 'password', signatureInfo.toJS().password));
      dispatch(change(formName, 'rememberMe', true));
      setReadyInitialFile(true);
    }
  }, [
    setReadyInitialFile, readyInitialFile, fieldTypeKey,
    signatureInfo, setUploadFile, dispatch,
  ]);

  useEffect(() => {
    if (readyInitialFile && fieldTypeKey !== 'file') {
      setReadyInitialFile(false);
    }
  }, [
    setReadyInitialFile, readyInitialFile, fieldTypeKey,
  ]);

  useEffect(() => {
    if (isEmpty(keysOptions.toJS()) && !isKeys && accessKeysList) {
      dispatch(employeesActionAsync.getKeysDataAsync());
      setIsKeys(true);
    }
  }, [keysOptions, dispatch, isKeys, setIsKeys, accessKeysList]);

  useEffect(() => function cleanup() {
    dispatch(actions.setSignIsModalOpen(false));
    dispatch(actions.setFilesWithBase64Clear());
    dispatch(actions.setSignatureInfoStateClear());
  }, [dispatch]);

  const convertKeysOptions = useMemo(() => map(
    keysOptions.toJS(), (key) => ({
      ...key,
      label: `${key?.users?.name === null ? t('Untitled') : key?.users?.name} (${key?.csk})`,
    }),
  ), [keysOptions, t]);

  const handleGetSelectOption = (option) => {
    setSelectOption(option);
  };

  const handleCloseModal = () => {
    dispatch(actions.setSignIsModalOpen(false));
    dispatch(actions.setSignPending(false));
    dispatch(actions.setFilesWithBase64Clear());
    setUploadFile({});
    setValueTab(0);
    setJsonData({});
    setReadyInitialFile(false);
    setAccessSubmit(false);
    setSignatureKeys([]);
    setKeyMedias([]);
    setReadyInitialize(false);
    setLoaderMedia(false);
    setAlertText('');
    setAlertUrl('');
    setEuSign(euSignFile);
    destroy();
  };

  const handleSendData = () => {
    forEach(dataAllFiles, (entity) => {
      dispatch(helpersActionAsync.postSignAsync({
        key: selectOption.id,
        [type]: entity.id,
        reloadDocumentsOut,
      }));
    });
  };

  const handleChange = (e, value) => {
    setValueTab(value);
  };

  const getFileExtension = (filename) => {
    return filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename;
  };

  const getPrivateKeys = async (file) => {
    try {
      const keys = await euSign.GetJKSPrivateKeys(file?.data);
      const keysArray = [];
      keys.forEach(key => {
        keysArray.push({
          ...key,
          value: key.alias,
          name: key.alias,
        });
      });
      setSignatureKeys(keysArray);
      setPendingUpload(false);
      if (keysArray.length === 1) {
        dispatch(change(formName, 'key', keysArray[0].value));
      }
    } catch (error) {
      dispatch(setErrorMessage(euSignCodes[error.code]));
      setPendingUpload(false);
    }
  };

  const readFile = (file) => {
    return new Promise(function(resolve, reject) {
      const reader = new FileReader();
      reader.onloadend  = function(evt) {
        if (evt.target.readyState != FileReader.DONE)
          return;

        resolve({
          ...file,
          "data": new Uint8Array(evt.target.result),
        });
      };
      reader.readAsArrayBuffer(file.file);
    });
  }

  const handleUploadFile = async (fileBase) => {
    const newFile = await readFile(fileBase);
    setUploadFile(newFile);

    dispatch(change(formName, 'file', fileBase?.file?.name || ''));
    dispatch(change(formName, 'key', ''));
    if (getFileExtension(fileBase?.file?.name) === "jks") {
      setPendingUpload(true);
      getPrivateKeys(newFile);
    } else {
      setSignatureKeys([]);
    }
  };

  const initialize = async (euSignInitial) => {
    if (euSignInitial == euSignFile) {
      const result = await euSignInitial.IsInitialized();
      if (result) {
        return;
      }
      return await euSignInitial.Initialize(euSettings);
    }
    // Перевірка чи встановлені необхідні модулі для роботи криптографічної бібліотеки
    const result = await euSignInitial.GetLibraryInfo();
    if (!result.supported) {
      setAlertText(t('Web signature library is not supported in your browser or OS'));
      return;
    }

    if (!result.loaded) {
      // Бібліотека встановлена, але потребує оновлення
      if (result.isNativeLibraryNeedUpdate) {
        setAlertText(t('The web signature library needs updating. Please install the update and refresh the page'));
        setAlertUrl(result.nativeLibraryInstallURL);
        return;
      }

      // Якщо браузер підтримує web-розширення рекомендується встановлювати web-розширення
      // Увага! Встановлення web-розширень ОБОВ'ЯЗКОВЕ для ОС Linux та ОС Windows Server
      if (result.isWebExtensionSupported && !result.isWebExtensionInstalled) {
        setAlertText(t('The web signature library requires a web extension. Please install a web extension and refresh the page'));
        setAlertUrl(result.webExtensionInstallURL);
        return;
      }

      // Бібліотека (нативні модулі) не встановлені
      setAlertText(t('The web signature library needs to be installed. Please install the library and refresh the page'));
      setAlertUrl(result.nativeLibraryInstallURL);
      return;
    }

    const resultMedia = await euSignInitial.IsInitialized();
    if (resultMedia) {
      return;
    }
    return await euSignInitial.Initialize(euSettings);
  };

  const submitKeyAndPassword = async (file, data) => {
    if (euSign == euSignFile) {
      if (file.name.endsWith(".jks")) {
        const jksKey = find(signatureKeys, { value: data.key || '' });
        return await euSign.ReadPrivateKeyBinary(jksKey.privateKey, data.password, null, null);
      }
      return await euSign.ReadPrivateKeyBinary(file.data, data.password, null, null);
    }
    const selectedKM = find(keyMedias, { device: data.mediaName });
    const keyMedia = new EndUserKeyMedia(selectedKM || {});
    keyMedia.password = data.password;
    return await euSign.ReadPrivateKey(keyMedia, null, null);
  };

  const signatureDocuments = async (data, filesData) => {
    try {
      await euSign.SetRuntimeParameter(EndUserConstants?.EU_SIGN_TYPE_PARAMETER, EndUserConstants?.EU_SIGN_TYPE_CADES_X_LONG);
      await submitKeyAndPassword(uploadFile, data);
      async function signAllFile(item) {
        const arrayFiles = [];
        async function signFile(fileIdOriginal) {
          const findFile = find(filesData, { fileId: fileIdOriginal });
          const signData = await euSign.SignData(base64toUint8(findFile.base64), true);
          arrayFiles.push({
            id: findFile.fileId,
            contentSigned: 'data:application/pkcs7-signature;base64,' + signData,
          });
        }
        await Promise.all(map(item.files, signFile));
        dispatch(helpersActionAsync.postCreatePacketAsync({
          [type]: item.id,
          files: arrayFiles,
          reloadDocumentsOut,
        }));
      }
      await Promise.all(map(dataAllFiles, signAllFile));
      if (data.rememberMe) {
        dispatch(actions.setSignatureInfo({
          name: uploadFile.name,
          base64: uploadFile.base64,
          password: data.password,
          ...!isEmpty(signatureKeys) ? {
            keys: signatureKeys,
            key: fieldKey,
          } : {},
        }));
      } else {
        dispatch(actions.setSignatureInfo({}));
      }
    } catch (error) {
      dispatch(setErrorMessage(euSignCodes[error.code || error.errorCode]));
      dispatch(actions.setSignPending(false));
    }
  };

  useEffect(() => {
    if (accessSubmit && !isEmpty(filesWithBase64.toJS()) && !isEmpty(jsonData)
      && filesWithBase64.toJS().length === files.length) {
      dispatch(helpersActionAsync.getInitialStateAsync({ url: 'signatureInfo' }));
      setAccessSubmit(false);
      signatureDocuments(jsonData, filesWithBase64.toJS());
    }
  }, [filesWithBase64, accessSubmit, files, jsonData, dispatch]);

  const handleSubmits = (formData) => {
    const jsonFormData = formData.toJSON();
    if (!accessSubmit && !isEmpty(jsonFormData.password) && !isEmpty(files)
      && (!isEmpty(uploadFile) || !isEmpty(jsonFormData.mediaName))) {
      dispatch(actions.setSignPending(true));
      forEach(files, (id) => {
        dispatch(helpersActionAsync.getFilesBase64Async({ fileId: id }));
      });
      setJsonData(jsonFormData);
      setAccessSubmit(true);
    }
  };

  const handleChangeTypeKey = async (typeKey) => {
    setLoaderMedia(true);
    setAlertText('');
    setAlertUrl('');
    dispatch(change(formName, 'password', ''));
    try {
      if (typeKey === 'hardware') {
        dispatch(change(formName, 'mediaName', ''));
        setEuSign(euSignKeyMedia);
        await initialize(euSignKeyMedia);
        const keysMedia = await euSignKeyMedia.GetKeyMedias();
        const arrayMedia = map(keysMedia, (item) => (
          {
            ...item,
            value: item.device,
            name: item.visibleName,
          }
        ));
        setKeyMedias(arrayMedia);
      } else {
        dispatch(change(formName, 'key', ''));
        dispatch(change(formName, 'file', ''));
        setEuSign(euSignFile);
        await initialize(euSignFile);
      }
    } catch (error) {
      if (error?.code || error?.errorCode) {
        dispatch(setErrorMessage(euSignCodes[error.code || error.errorCode]));
      }
    }
    setLoaderMedia(false);
  };

  useEffect(() => {
    if (isModalOpen && !readyInitialize) {
      dispatch(change(formName, 'typeKey', 'file'));
      handleChangeTypeKey('file');
      setReadyInitialize(true);
    }
  }, [
    dispatch, isModalOpen, readyInitialize,
    setReadyInitialize, handleChangeTypeKey,
  ]);

  return (
    <Dialog
      fullWidth
      open={isModalOpen}
      onClose={handleCloseModal}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
    >
      <DialogTitle id="form-dialog-title">
        <Grid container>
          <Grid item xs={6} container alignItems="center">
            {t('Select DEK')}
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <IconButton aria-label="close" onClick={handleCloseModal}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Tabs
          value={valueTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label={(
            <Badge color="secondary">
              {t('Local CEP')}
            </Badge>
          )}
          />
          <Tab label={(
            <Badge color="secondary">
              {t('Saved CEP')}
            </Badge>
          )}
          />
        </Tabs>
        {valueTab === 0 && (
          <Form onSubmit={handleSubmit(handleSubmits)}>
            <Field name="typeKey" row component={RadioButton} onChange={(e) => handleChangeTypeKey(e.target.value)}>
              <FormControlLabel
                value="file"
                control={<Radio />}
                label={t('File key')}
              />
              <FormControlLabel
                value="hardware"
                control={<Radio />}
                label={t('Hardware key')}
              />
            </Field>
            {fieldTypeKey === 'file' && (
              <>
                <FileInputComponent
                  name="logo"
                  imagePreview={false}
                  labelStyle={{ display: 'none' }}
                  parentStyle={{ display: 'flex', marginTop: 14, alignItems: 'flex-end' }}
                  multiple={false}
                  callbackFunction={handleUploadFile}
                  accept="file"
                  textBoxVisible
                  textFieldComponent={(
                    <Field
                      name="file"
                      label={t('Electronic signature')}
                      component={RenderTextField}
                      margin="none"
                      fullWidth
                      required
                    />
                  )}
                  buttonComponent={(
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      {pendingUpload
                        ? <CircularProgress size={25} mr={2} color="inherit" />
                        : <>
                          <CloudUpload mr={2} />
                          {' UPLOAD'}
                        </>}
                    </Button>
                  )}
                />
                {!isEmpty(signatureKeys) && (
                  <Field
                    name="key"
                    component={Select}
                    labelId="key"
                    id="key"
                    required
                    fullWidth
                    label={t('Choose a signature')}
                    margin="normal"
                    items={signatureKeys}
                    validate={validators.required}
                  />
                )}
                <Field
                  name="password"
                  component={RenderTextField}
                  type="password"
                  required
                  fullWidth
                  label={t('Password')}
                  margin="normal"
                  validate={validators.required}
                />
                <Field
                  component={RememberCheckbox}
                  label={t('Remember')}
                  name="rememberMe"
                  type="checkbox"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  mb={2}
                  disabled={isEmpty(uploadFile) || !valid || pending}
                >
                  {pending ? <CircularProgress size={25} color="inherit" /> : t('Sign')}
                </Button>
              </>
            )}
            {fieldTypeKey === 'hardware' && !alertText && (
              <>
                <Field
                  name="mediaName"
                  component={Select}
                  labelId="mediaName"
                  id="mediaName"
                  required
                  fullWidth
                  label={t('Media name')}
                  margin="normal"
                  items={keyMedias}
                  validate={validators.required}
                />
                <Field
                  name="password"
                  component={RenderTextField}
                  type="password"
                  required
                  fullWidth
                  label={t('Password')}
                  margin="normal"
                  validate={validators.required}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  mb={2}
                  mt={2}
                  disabled={!valid || pending}
                >
                  {pending ? <CircularProgress size={25} color="inherit" /> : t('Sign')}
                </Button>
              </>
            )}
            {fieldTypeKey === 'hardware' && alertText && (
              <Alert
                severity="error"
                {...alertUrl && {
                  action: (
                    <IconButton
                      aria-label="close"
                      target="_blank"
                      href={alertUrl}
                    >
                      <CloudDownload color="error" />
                    </IconButton>
                  ),
                }}
              >
                {alertText}
              </Alert>
            )}
            {loaderMedia && <LoaderWithOverlay />}
          </Form>
        )}
        {valueTab === 1 && (
          <>
            <AutocompleteField
              multiple={false}
              getSelectOption={handleGetSelectOption}
              label={t('Select')}
              margin="none"
              options={convertKeysOptions}
            />
            <Button
              disabled={isNil(selectOption) || pending}
              fullWidth
              variant="contained"
              color="primary"
              my={4}
              onClick={handleSendData}
            >
              {pending
                ? <CircularProgress size={25} color="inherit" />
                : t('Sign')}
            </Button>
          </>
        )}

      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseModal}
          color="primary"
        >
          {t('Cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Sign.propTypes = {
  keysOptions: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pending: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  reloadDocumentsOut: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  files: PropTypes.oneOfType([
    PropTypes.array,
  ]),
  dataAllFiles: PropTypes.oneOfType([
    PropTypes.array,
  ]),
  signatureInfo: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  filesWithBase64: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  destroy: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  fieldKey: PropTypes.string,
  fieldTypeKey: PropTypes.string,
};
Sign.defaultProps = {
  files: [],
  dataAllFiles: [],
  reloadDocumentsOut: false,
  fieldKey: '',
  fieldTypeKey: '',
};

function mapStateToProps(state) {
  return {
    keysOptions: selectors.employees.getKeysData(state),
    pending: selectors.helpers.signPending(state),
    isModalOpen: selectors.helpers.signIsModalOpen(state),
    signatureInfo: selectors.helpers.signatureInfo(state),
    filesWithBase64: selectors.helpers.filesWithBase64(state),
    fieldKey: selectors.form.getFormValues(state, formName).get('key'),
    fieldTypeKey: selectors.form.getFormValues(state, formName).get('typeKey'),
  };
}

function mapDispatchToProps() {
  return {};
}

export default compose(
  reduxForm({
    form: formName,
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(memo(Sign));
