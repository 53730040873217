// Helpers
import actionCreator from '../../_helpers/actionCreator';
// Types
import types from './types';

const actions = Object.freeze({
  setUploadFile(fileData) {
    return actionCreator(types.SET_UPLOAD_FILE, fileData);
  },
  setAllUploadFiles(files) {
    return actionCreator(types.SET_ALL_UPLOAD_FILES, files);
  },
});

export default actions;
