// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const asyncActions = Object.freeze({
  postImportObjectsAsync(data) {
    return actionCreator(asyncTypes.POST_IMPORT_OBJECTS_ASYNC, data);
  },
  postSpezDocumentsAsync(data) {
    return actionCreator(asyncTypes.POST_IMPORT_SPEZ_DOCUMENTS, data);
  },
  postImportExcelAsync(data) {
    return actionCreator(asyncTypes.POST_IMPORT_EXCEL_ASYNC, data);
  },
  postExportAsync(data) {
    return actionCreator(asyncTypes.POST_EXPORT_ASYNC, data);
  },
});

export default asyncActions;
