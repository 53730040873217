// Core
import { apply, put } from 'redux-saga/effects';

// lodash
import { has } from 'lodash';

// config
import { api } from '../../../../../config/api';

// actions
import actions from '../../action';

export function* callGetMktuByIdWorker({ payload: id }) {
  const response = yield apply(api, api.trademarks.getReboundById, [id]);
  if (response && response.status >= 200 && response.status < 400 && !has(response.data, 'error')) {
    yield put(actions.setMktuById(response.data));
  }
}
