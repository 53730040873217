// Core
import {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

// lodash
import isEmpty from 'lodash/isEmpty';

// actions
import documentsAsyncActions from '../../../engine/core/departmentDocuments/documentsModal/saga/asyncAction';
import documentTemplatesAsyncActions from '../../../engine/core/documentTemplates/saga/asyncAction';
import trademarksAsyncActions from '../../../engine/core/tradeMarks/proposal/saga/asyncAction';
import industrialAsyncActions from '../../../engine/core/industrialDesigns/saga/asyncAction';
import inventionsAsyncActions from '../../../engine/core/inventions/saga/asyncAction';
import utilityAsyncActions from '../../../engine/core/utilityModels/saga/asyncAction';
import emailAsyncActions from '../../../engine/core/email/email/saga/asyncAction';
import emailActions from '../../../engine/core/email/email/action';

// config
import selectors from '../../../engine/config/selectors';
import { listEntityName } from '../../../engine/config/listTablesName';

// Hook
import { useParamsRequest } from '../../../ui/_hooks/useParamsRequest';

export function useTableEntitiesModal(data = {}) {
  const { stopInitialState, entities } = data;

  const getEntities = useMemo(() => {
    switch (entities) {
      case 'documents': return 'departmentDocumentsModal';
      case 'documentTemplates': return 'documentTemplates';
      case 'trademarks': return 'tradeMarksProposal';
      case 'inventions': return 'inventionsProposal';
      case 'industrial': return 'industrialDesignsProposal';
      case 'utility': return 'utilityModelsProposal';
      default: return 'departmentDocumentsModal';
    }
  }, [entities]);

  const entityName = useMemo(() => {
    switch (entities) {
      case 'documents': return listEntityName.documents;
      case 'documentTemplates': return listEntityName.documentsTemplate;
      case 'trademarks': return listEntityName.trademarks;
      case 'inventions': return listEntityName.integrations;
      case 'industrial': return listEntityName.industrial;
      case 'utility': return listEntityName.utility;
      case 'emailLetters': return listEntityName.emailLetters;
      default: return listEntityName.documents;
    }
  }, [entities]);

  const currentPage = useSelector(entities === 'emailLetters' ? selectors.email.emailLettersCurrentPage : selectors?.[getEntities]?.currentPage);
  const limit = useSelector(entities === 'emailLetters' ? selectors.email.emailLettersPageSize : selectors?.[getEntities]?.pageSize);
  const filters = useSelector(entities === 'emailLetters' ? selectors.email.emailLettersFilters : selectors?.[getEntities]?.filters);
  const reload = useSelector(entities === 'emailLetters' ? selectors.email.emailLettersReload : selectors?.[getEntities]?.reload);
  const path = useSelector(selectors.router.pathname);
  const fieldsEntity = useSelector(selectors.fieldsSetting.fieldsEntity);
  const dispatch = useDispatch();
  const { paramsByFilter } = useParamsRequest({ filters, entityName });

  const getListAsync = useCallback(() => {
    const params = {
      ...paramsByFilter,
      limit,
      page: currentPage + 1,
    };
    if (entities === 'documents') dispatch(documentsAsyncActions.getListAsyncModal(params));
    if (entities === 'documentTemplates') dispatch(documentTemplatesAsyncActions.getListAsync(params));
    if (entities === 'trademarks') dispatch(trademarksAsyncActions.getListAsync(params));
    if (entities === 'inventions') dispatch(inventionsAsyncActions.getListAsync(params));
    if (entities === 'industrial') dispatch(industrialAsyncActions.getListAsync(params));
    if (entities === 'utility') dispatch(utilityAsyncActions.getListAsync(params));
    if (entities === 'emailLetters') dispatch(emailAsyncActions.getEmailLettersAsync(params));
  }, [paramsByFilter, currentPage, limit, dispatch, entities]);

  const getInitialStateAsync = useCallback(() => {
    if (entities === 'documents') dispatch(documentsAsyncActions.getInitialStateAsync(path));
    if (entities === 'documentTemplates') dispatch(documentTemplatesAsyncActions.getInitialStateAsync(path));
    if (entities === 'trademarks') dispatch(trademarksAsyncActions.getInitialStateAsync(path));
    if (entities === 'inventions') dispatch(inventionsAsyncActions.getInitialStateAsync(path));
    if (entities === 'industrial') dispatch(industrialAsyncActions.getInitialStateAsync(path));
    if (entities === 'utility') dispatch(utilityAsyncActions.getInitialStateAsync(path));
    if (entities === 'emailLetters') dispatch(emailActions.setEmailLettersReload(true));
  }, [dispatch, path, entities]);

  const getInitialState = useRef(true);

  useEffect(() => {
    if (getInitialState.current && !stopInitialState) {
      getInitialStateAsync();
      getInitialState.current = false;
    }

    if (reload && !isEmpty(fieldsEntity.toJS())) {
      getListAsync();
    }
  }, [getListAsync, getInitialStateAsync, reload, stopInitialState, fieldsEntity]);
}
