// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const actionsAsync = Object.freeze({
  uploadFileAsync(id) {
    return actionCreator(asyncTypes.UPLOAD_FILE_ASYNC, id);
  },
});

export default actionsAsync;
