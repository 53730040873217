export const listTablesName = Object.freeze({
  outdocs: 'OutDocs',
  indocs: 'InDocs',
  trademarks: 'Trademarks',
  trademarksEstimates: 'Estimates',
  trademarksMktu: 'Mktu',
  trademarksSearch: 'Search',
  trademarksMonitoring: 'Monitoring',
  trademarksMktuTemplate: 'MktuTemplate',
  trademarksDocumentsIn: 'Documents_In',
  trademarksDocumentsOut: 'Documents_Out',
  industrial: 'Industrial',
  utility: 'Utility',
  inventions: 'Inventions',
  documentsTemplate: 'DocumentsTemplate',
  documents: 'Documents',
  employees: 'Users_employees',
  clients: 'Users_clients',
  orders: 'Orders',
  payments: 'Payments',
  tariffs: 'Costs',
  tariff: 'CostsComponents',
  documentTypes: 'DocumentClassifier',
  documentTypesCategories: 'DocumentClassifierCategory',
  notifications: 'Notifications',
  paymentReceivers: 'PaymentReceivers',
  pendingActions: 'pendingActions',
  charges: 'Charges',
  applicants: 'Applicants',
  companies: 'Companies',
  integrations: 'Integrations',
  jurisdictions: 'Jurisdictions',
  logging: 'Logging',
  currencies: 'Currencies',
  currencyRates: 'CurrencyRates',
  categories: 'Categories',
  taxes: 'Taxes',
  emailTemplates: 'EmailTemplates',
  email: 'Email',
  notificationsTasks: 'NotificationsTasks',
  systemMessage: 'SystemMessage',
  products: 'Products',
  madridCase: 'MadridCase',
});

export const listEntityName = Object.freeze({
  pendingActions: 'pendingactions',
  outdocs: 'outdocs',
  indocs: 'indocs',
  trademarks: 'trademarks',
  industrial: 'industrial',
  utility: 'utility',
  inventions: 'inventions',
  trademarksEstimates: 'estimates',
  mktu: 'mktu',
  mktuTemplate: 'mktutemplate',
  search: 'search',
  monitoring: 'monitoring',
  systemMessage: 'systemmessage',
  documents: 'documents',
  documentsTemplate: 'documentstemplate',
  company: 'company',
  clients: 'users',
  employees: 'users',
  applicants: 'applicants',
  orders: 'orders',
  payments: 'payments',
  integrations: 'authorityintegration',
  emailTemplates: 'emailtemplate',
  email: 'email',
  tariffs: 'costs',
  works: 'works',
  tariff: 'costscomponents',
  documentTypes: 'documentclassifier',
  documentTypesCategories: 'documentclassifiercategory',
  categories: 'costscategories',
  currencies: 'currencies',
  currencyRates: 'currencyrates',
  taxes: 'taxes',
  notifications: 'notifications',
  paymentReceivers: 'paymentreceivers',
  jurisdictions: 'jurisdictions',
  logging: 'logging',
  charges: 'charges',
  paymentAllocation: 'paymentallocation',
  classes: 'classes',
  tags: 'tags',
  usersFilters: 'usersfilters',
  emailLetters: 'emailletters',
  products: 'products',
  productCategory: 'productcategory',
  madridCase: 'madridcase',
});
