export const types = Object.freeze({
  SET_APPLICANTS_LIST: 'SET_APPLICANTS_LIST',
  SET_APPLICANTS_DATA: 'SET_APPLICANTS_DATA',
  SET_NEW_APPLICANTS_LIST: 'SET_NEW_APPLICANTS_LIST',
  MERGE_NEW_APPLICANTS_LIST: 'MERGE_NEW_APPLICANTS_LIST',
  SET_APPLICANTS_AUTOCOMPLETE_OPTIONS: 'SET_APPLICANTS_AUTOCOMPLETE_OPTIONS',
  SET_INITIAL_APPLICANTS_LIST: 'SET_INITIAL_APPLICANTS_LIST',
  SET_PENDING_APPLICANTS_AUTOCOMPLETE_OPTIONS: 'SET_PENDING_APPLICANTS_AUTOCOMPLETE_OPTIONS',
  SET_NEW_APPLICANT: 'SET_NEW_APPLICANT',
  SET_APPLICANT_BY_ID: 'SET_APPLICANT_BY_ID',
  SET_PENDING_GET_APPLICANT_BY_ID: 'SET_PENDING_GET_APPLICANT_BY_ID',
  SET_APPLICANTS_RELOAD: 'SET_APPLICANTS_RELOAD',
  SET_APPLICANTS_CURRENT_PAGE: 'SET_APPLICANTS_CURRENT_PAGE',
  SET_APPLICANTS_FILTERS: 'SET_APPLICANTS_FILTERS',
  SET_APPLICANTS_SORTING: 'SET_APPLICANTS_SORTING',
  SET_APPLICANTS_PAGE_SIZE: 'SET_APPLICANTS_PAGE_SIZE',
  SET_APPLICANTS_INITIAL_STATE: 'SET_APPLICANTS_INITIAL_STATE',
  SET_APPLICANTS_MODAL_IS_OPEN: 'SET_APPLICANTS_MODAL_IS_OPEN',
  SET_APPLICANTS_COLUMN_WIDTHS: 'SET_APPLICANTS_COLUMN_WIDTHS',
  SET_APPLICANTS_COLUMN_ORDER: 'SET_APPLICANTS_COLUMN_ORDER',
  SET_APPLICANTS_HIDDEN_COLUMN_NAMES: 'SET_APPLICANTS_HIDDEN_COLUMN_NAMES',
  SET_DELETE_APPLICANT: 'SET_DELETE_APPLICANT',
  MERGE_APPLICANT_NEW_OWNERS_LIST: 'MERGE_APPLICANT_NEW_OWNERS_LIST',
  SET_APPLICANT_NEW_OWNERS_LIST: 'SET_APPLICANT_NEW_OWNERS_LIST',
  MERGE_APPLICANT_NEW_AUTHORS_LIST: 'MERGE_APPLICANT_NEW_AUTHORS_LIST',
  SET_APPLICANT_NEW_AUTHORS_LIST: 'SET_APPLICANT_NEW_AUTHORS_LIST',
});
