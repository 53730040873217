// Core
import { call, put } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../../config/api';
import { messageRequestSent } from '../../../../../config/globalConfig';

// init
import i18n from '../../../../../init/i18n';

// actions
import action from '../../action';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../../ui/_helpers/setNotificationMessage';

export function* callPostEmailSendWorker({ payload }) {
  const { setPendingPostEmailSend, setIsModalOpenMessage } = action;

  yield put(setPendingPostEmailSend(true));

  const response = yield call(api.email.postEmailSend, payload);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      yield put(setIsModalOpenMessage(false));
      yield put(setSuccessMessage(i18n.t(messageRequestSent), response.status.toString()));
    }
  }

  yield put(setPendingPostEmailSend(false));
}
