// core
import React, {
  useEffect, useState,
} from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import {
  Field, Form, formValueSelector, reduxForm, change,
} from 'redux-form/immutable';

// lodash
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

// ui
import {
  Button, Grid, InputAdornment, Tooltip,
  CircularProgress, IconButton,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';

// config
import selectors from '../../engine/config/selectors';

// parts
import Modal from '../Modal/Modal';
import DialogActions from '../Modal/components/DialogActions';
import Select from '../../ui/Form/Select';
import renderTextField from '../../ui/Form/RenderTextField';
import Checkbox from '../../ui/Form/Checkbox';
import AutocompleteField from '../../ui/Form/AutocompleteField';

// actions
import helpersActions from '../../engine/core/helpers/action';
import proposalAsyncActions from '../../engine/core/tradeMarks/proposal/saga/asyncAction';
import proposalActions from '../../engine/core/tradeMarks/proposal/action';
import clientsActionAsync from '../../engine/core/company/clients/saga/asyncAction';

// helpers
import { formName } from './helpers/form';
import { validators } from '../../ui/_helpers/validation';
import { countryOptions } from '../../ui/_helpers/country';

const formSelector = formValueSelector(formName);

function AddClientModal(props) {
  const {
    handleSubmit, companyData, fieldInnCode, isModalOpenAddClient,
    destroy, pendingPostClient, pendingPostApplicant, companyPending,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [readyInitialize, setReadyInitialize] = useState(false);
  const [notFoundCode, setNotFoundCode] = useState(false);

  const handleCloseModal = () => {
    destroy();
    dispatch(helpersActions.setModal({
      isModalOpenAddClient: false,
    }));
  };

  const getRegistryByNumber = (number) => {
    dispatch(proposalAsyncActions.getFindCompanyAsync({ code: number }));
  };

  const handleSubmits = (formData) => {
    const json = formData.toJSON();
    const address = {
      ...json.postalAddress.toJSON(),
      country: json.postalAddress.toJSON()?.country?.abbreviation,
      name: 'postal',
    };
    delete json.postalAddress;
    dispatch(clientsActionAsync.postClientAsync({
      ...json,
      addresses: [address],
      isModal: true,
    }));
  };

  useEffect(() => {
    if (!isEmpty(companyData.toJS())) {
      if (!isEmpty(companyData.toJS().error)) {
        setNotFoundCode(true);
      } else {
        const parseCompanyData = companyData.toJS();
        const street = !isNil(parseCompanyData?.streettype) && !isNil(parseCompanyData?.street) ? `${parseCompanyData?.streettype || ''} ${parseCompanyData?.street},` : '';
        const building = !isNil(parseCompanyData?.building) ? `будинок ${parseCompanyData?.building},` : '';
        const room = !isNil(parseCompanyData?.roomtype) && !isNil(parseCompanyData?.room) ? `${parseCompanyData?.roomtype} ${parseCompanyData?.room}` : '';
        const obl = !isNil(parseCompanyData?.obl) ? `${parseCompanyData?.obl} область,` : '';
        const region = !isNil(parseCompanyData?.region) ? `${parseCompanyData?.region} район,` : '';
        const city = !isNil(parseCompanyData?.citytype) && !isNil(parseCompanyData?.cityname)
          ? `${parseCompanyData?.citytype || ''} ${parseCompanyData?.cityname || ''}` : '';

        dispatch(change(formName, 'name', parseCompanyData.name || ''));
        dispatch(change(formName, 'typeOfOwnership', parseCompanyData.fis ? '2' : '1'));
        dispatch(change(formName, 'postalAddress.city', `${obl}${obl ? ' ' : ''}${region}${region ? ' ' : ''}${city}` || ''));
        dispatch(change(formName, 'postalAddress.address', `${street}${street ? ' ' : ''}${building}${building ? ' ' : ''}${room}` || ''));
        dispatch(change(formName, 'postalAddress.postalIndex', `${parseCompanyData?.index}` || ''));
        dispatch(change(formName, 'postalAddress.country', { label: 'Ukraine', abbreviation: 'UA' }));
        setNotFoundCode(false);
      }
      dispatch(proposalActions.setCompany({}));
    }
  }, [dispatch, companyData]);

  useEffect(() => {
    if (!readyInitialize) {
      setReadyInitialize(true);
      dispatch(change(formName, 'postalAddress.country', { label: 'Ukraine', abbreviation: 'UA' }));
      dispatch(change(formName, 'typeOfOwnership', '3'));
    }
  }, [
    dispatch, readyInitialize, setReadyInitialize,
  ]);

  const handleChangeInnCode = () => {
    if (notFoundCode) {
      setNotFoundCode(false);
    }
  };

  const submitForm = (event) => {
    handleSubmit();
    event.preventDefault();
  };

  return (
    <Modal
      title={t('Creating a client')}
      isModalOpen={isModalOpenAddClient}
      handleCloseModal={handleCloseModal}
      displayDialogActions={false}
    >
      <Form onSubmit={handleSubmit(handleSubmits)}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Field
              name="innCode"
              id="innCode"
              label={t('USREOU')}
              margin="normal"
              variant="standard"
              fullWidth
              type="text"
              component={renderTextField}
              {...notFoundCode ? {
                helperText: t('Not found'),
              } : {}}
              onChange={handleChangeInnCode}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {fieldInnCode ? (
                      <Tooltip
                        title={t('Find in the national register')}
                        arrow
                      >
                        <IconButton
                          onClick={() => {
                            getRegistryByNumber(fieldInnCode);
                          }}
                        >
                          {companyPending ? <CircularProgress size={20} color="inherit" /> : <SearchIcon />}
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <IconButton disabled>
                        <SearchIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />

            <Field
              required
              validate={validators.required}
              name="name"
              id="name"
              label={t('Full name/Name')}
              margin="normal"
              variant="standard"
              my={2}
              fullWidth
              type="text"
              component={renderTextField}
            />

            <Field
              id="phone"
              name="phone"
              label={t('Phone')}
              margin="normal"
              fullWidth
              my={2}
              component={renderTextField}
            />

            <Field
              id="postalAddress.city"
              name="postalAddress.city"
              label={t('City')}
              variant="standard"
              margin="normal"
              fullWidth
              my={2}
              component={renderTextField}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Field
              required
              validate={validators.required}
              name="typeOfOwnership"
              id="typeOfOwnership"
              label={t('Type of ownership')}
              labelId="typeOfOwnership"
              my={2}
              items={[
                {
                  value: '1',
                  name: t('Legal person'),
                },
                {
                  value: '2',
                  name: t('FOP'),
                },
                {
                  value: '3',
                  name: t('Individual person'),
                },
              ]}
              fullWidth
              component={Select}
            />

            <Field
              id="email"
              name="email"
              label="Email"
              variant="standard"
              margin="normal"
              fullWidth
              my={2}
              component={renderTextField}
            />

            <Field
              multiple={false}
              name="postalAddress.country"
              id="postalAddress.country"
              component={AutocompleteField}
              type="text"
              label={t('Country')}
              margin="normal"
              fullWidth
              options={countryOptions}
            />

            <Field
              id="postalAddress.postalIndex"
              name="postalAddress.postalIndex"
              label={t('Index')}
              variant="standard"
              margin="normal"
              fullWidth
              my={2}
              component={renderTextField}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              id="postalAddress.address"
              name="postalAddress.address"
              label={t('Address')}
              variant="standard"
              margin="normal"
              fullWidth
              my={2}
              component={renderTextField}
            />
            <Field
              id="createApplicant"
              name="createApplicant"
              component={Checkbox}
              margin="none"
              type="checkbox"
              label={t('Create an applicant')}
            />
          </Grid>
        </Grid>

        <DialogActions
          dialogActionsChildren={(
            <>
              <Button
                variant="contained"
                color="primary"
                disabled={pendingPostClient || pendingPostApplicant}
                onClick={submitForm}
              >
                {pendingPostClient || pendingPostApplicant
                  ? <CircularProgress size={25} color="inherit" />
                  : t('CONFIRM')}
              </Button>
              <Button
                variant="outlined"
                onClick={handleCloseModal}
                color="primary"
              >
                {t('CANCEL')}
              </Button>
            </>
          )}
        />
      </Form>
    </Modal>
  );
}

AddClientModal.propTypes = {
  destroy: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  isModalOpenAddClient: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  fieldInnCode: PropTypes.string,
  companyData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingPostClient: PropTypes.bool.isRequired,
  pendingPostApplicant: PropTypes.bool.isRequired,
  companyPending: PropTypes.bool.isRequired,
};

AddClientModal.defaultProps = {
  fieldInnCode: '',
};

function mapStateToProps(state) {
  return {
    isModalOpenAddClient: selectors.helpers.isModalOpenAddClient(state),
    pendingPostClient: selectors.clients.pendingPostClient(state),
    pendingPostApplicant: selectors.applicants.pendingPostApplicant(state),
    companyData: selectors.tradeMarksProposal.company(state),
    companyPending: selectors.tradeMarksProposal.companyPending(state),
    fieldInnCode: formSelector(state, 'innCode'),

  };
}

export default compose(
  reduxForm({
    form: formName,
  }),
  connect(mapStateToProps, null),
)(AddClientModal);
