// Core
import { apply, put } from 'redux-saga/effects';
import { api } from '../../../../../../../config/api';
import { listEntityName } from '../../../../../../../config/listTablesName';

// actions
import actions from '../../action';

const entity = listEntityName.documents;

export function* callGetProposalDocumentsInWorker({ payload: params }) {
  yield put(actions.setProposalDocumentsInData({ pending: true }));
  const response = yield apply(api,
    api.departmentDocuments.getDepartmentDocuments, [{ ...params, entity }]);

  let data = {
    items: [],
    totalCount: 0,
    pending: false,
  };

  if (response && response.status >= 200 && response.status < 400) {
    data = Object.assign(data, response.data);
  }
  yield put(actions.setProposalDocumentsInData(data));
  yield put(actions.setReload(false));
}
