// Helpers
import actionCreator from '../../_helpers/actionCreator';
// Types
import types from './types';

const actions = Object.freeze({
  setChargesData(chargesData) {
    return actionCreator(types.SET_CHARGES_DATA, chargesData);
  },
  setCurrentPage(currentPage) {
    return actionCreator(types.SET_CHARGES_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return actionCreator(types.SET_CHARGES_FILTERS, filters);
  },
  setSorting(sorting) {
    return actionCreator(types.SET_CHARGES_SORTING, sorting);
  },
  setDeleteCharge(data) {
    return actionCreator(types.SET_DELETE_CHARGE, data);
  },
  setPageSize(pageSize) {
    return actionCreator(types.SET_CHARGES_PAGE_SIZE, pageSize);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_CHARGES_INITIAL_STATE, initialState);
  },
  setReload(reload) {
    return actionCreator(types.SET_CHARGES_RELOAD, reload);
  },
  setChargeById(chargeData) {
    return actionCreator(types.SET_CHARGE_BY_ID, chargeData);
  },
  setAutocompleteOptions(data) {
    return actionCreator(types.SET_CHARGES_AUTOCOMPLETE_OPTIONS, data);
  },
  setTabs(data) {
    return actionCreator(types.SET_CHARGES_TABS, data);
  },
  setSelections(data) {
    return actionCreator(types.SET_CHARGES_SELECTIONS, data);
  },
  setChargesIds(ids) {
    return actionCreator(types.SET_CHARGES_IDS, ids);
  },
  setModalChargesTable(open) {
    return actionCreator(types.SET_MODAL_CHARGES_TABLE, open);
  },
  setSumChargesInput(data) {
    return actionCreator(types.SET_SUM_CHARGES_INPUT, data);
  },
  setChargesSelectionsModal(list) {
    return actionCreator(types.SET_CHARGES_SELECTIONS_MODAL, list);
  },
  setModalChargesEdit(open) {
    return actionCreator(types.SET_MODAL_CHARGES_EDIT, open);
  },
});

export default actions;
