// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const asyncActions = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_WORKS_ASYNC, params);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_WORKS_INITIAL_STATE_ASYNC, path);
  },
  deleteWorkAsync() {
    return actionCreator(asyncTypes.DELETE_WORK_ASYNC);
  },
  getAutocompleteOptionsAsync(params) {
    return actionCreator(asyncTypes.GET_WORKS_AUTOCOMPLETE_OPTIONS_ASYNC, params);
  },
});

export default asyncActions;
