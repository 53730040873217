// Helpers
import actionCreator from '../../_helpers/actionCreator';
// Types
import types from './types';

const actions = Object.freeze({
  setNotificationById(data) {
    return actionCreator(types.SET_NOTIFICATION_BY_ID, data);
  },
  setNotificationsData(data) {
    return actionCreator(types.SET_NOTIFICATIONS_DATA, data);
  },
  setCurrentPage(currentPage) {
    return actionCreator(types.SET_NOTIFICATIONS_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return actionCreator(types.SET_NOTIFICATIONS_FILTERS, filters);
  },
  setSorting(sorting) {
    return actionCreator(types.SET_NOTIFICATIONS_SORTING, sorting);
  },
  setColumnWidths(data) {
    return actionCreator(types.SET_NOTIFICATIONS_COLUMN_WIDTHS, data);
  },
  setColumnOrder(data) {
    return actionCreator(types.SET_NOTIFICATIONS_COLUMN_ORDER, data);
  },
  setHiddenColumnNames(data) {
    return actionCreator(types.SET_NOTIFICATIONS_HIDDEN_COLUMN_NAMES, data);
  },
  setPageSize(pageSize) {
    return actionCreator(types.SET_NOTIFICATIONS_PAGE_SIZE, pageSize);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_NOTIFICATIONS_INITIAL_STATE, initialState);
  },
  setReload(reload) {
    return actionCreator(types.SET_NOTIFICATIONS_RELOAD, reload);
  },
  setDeleteNotification(data) {
    return actionCreator(types.SET_DELETE_NOTIFICATION, data);
  },
  setAutocompleteOptions(options) {
    return actionCreator(types.SET_NOTIFICATIONS_AUTOCOMPLETE_OPTIONS, options);
  },
  setPendingAutocompleteOptions(pending) {
    return actionCreator(types.SET_PENDING_NOTIFICATIONS_AUTOCOMPLETE_OPTIONS, pending);
  },
});

export default actions;
