// Core
import { apply, put } from 'redux-saga/effects';

// config
import { api } from '../../../../../config/api';

// actions
import actions from '../../action';

export function* callGetCheckNewWorker({ payload: id }) {
  const response = yield apply(api, api.email.getCheckNew, [id]);

  if (response && response.status >= 200 && response.status < 400 && response?.data?.success) {
    yield put(actions.setCurrentPage(0));
    yield put(actions.setReload(false));
  }
}
