// core
import React from 'react';
import styled from 'styled-components';
import { compose } from 'recompose';
import { connect, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { List } from 'immutable';

// lodash
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import values from 'lodash/values';
import keys from 'lodash/keys';
import isEqual from 'lodash/isEqual';

// ui
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { spacing } from '@material-ui/system';

// actions
import helpersAction from '../../engine/core/helpers/action';

// config
import selectors from '../../engine/config/selectors';

const MenuMui = styled(Menu)`
  .MuiMenu-paper {
    max-height: ${48 * 5.5}px; 
  }
`;
const IconButtonMui = styled(IconButton)(spacing);
const FabMui = styled(Fab)`
  ${spacing}
  position: fixed;
  z-index: 999;
  right: ${(props) => props.theme.spacing(7)}px;
  bottom: ${(props) => props.theme.spacing(25)}px;
`;
const NewFab = styled(Fab)`
  ${spacing}
  position: fixed;
  z-index: 999;
  right: ${(props) => props.theme.spacing(7)}px;
  bottom: ${(props) => props.theme.spacing(7)}px;
`;

function LongMenu(props) {
  const {
    items, entity, currentLongMenu,
    openLongMenu, displayButtonFab,
    buttonSize, buttonSizeFab, isNewEntity,
    displayButton, initialLongMenu,
    isCleanUpLongMenu, handleChange,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const list = List(items).toJS();
  const [currentValueLongMenu] = values(currentLongMenu.toJS());
  const [currentKeyLongMenu] = keys(currentLongMenu.toJS());

  const handleToggle = (event) => {
    const { currentTarget } = event;
    dispatch(helpersAction.setOpenLongMenu(entity, !openLongMenu));
    setAnchorEl(!openLongMenu ? currentTarget : null);
  };

  const setCurrentLongMenu = React.useCallback((index, value) => {
    handleChange(value.hash);
    dispatch(helpersAction.setCurrentLongMenu(entity, index, value));
  }, [dispatch, entity, handleChange]);

  const handleSelected = (index) => () => {
    setCurrentLongMenu(index, list[index]);
    dispatch(helpersAction.setOpenLongMenu(entity, !openLongMenu));
  };

  React.useEffect(() => {
    if (initialLongMenu && currentKeyLongMenu && !isEmpty(currentValueLongMenu)) {
      setCurrentLongMenu(Number(currentKeyLongMenu), currentValueLongMenu);
      dispatch(helpersAction.setInitialLongMenu(false));
    }
  }, [
    dispatch, currentValueLongMenu, currentKeyLongMenu,
    initialLongMenu, setCurrentLongMenu,
  ]);

  React.useEffect(() => function cleanup() {
    if (isCleanUpLongMenu) {
      dispatch(helpersAction.setCleanUpLongMenu());
    }
  }, [dispatch, isCleanUpLongMenu]);

  return (
    <>
      {displayButton && (
        <IconButtonMui
          ml={2}
          mr={2}
          size={buttonSize}
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <MoreVertIcon />
        </IconButtonMui>
      )}

      {displayButtonFab && (
        isNewEntity ? (
          <NewFab
            size={buttonSizeFab}
            aria-label="more-fab"
            aria-controls="long-menu-fab"
            aria-haspopup="true"
            color="secondary"
            onClick={handleToggle}
          >
            <MoreVertIcon />
          </NewFab>
        ) : (
          <FabMui
            size={buttonSizeFab}
            aria-label="more-fab"
            aria-controls="long-menu-fab"
            aria-haspopup="true"
            color="secondary"
            onClick={handleToggle}
          >
            <MoreVertIcon />
          </FabMui>
        )
      )}

      <MenuMui
        id={entity}
        anchorEl={anchorEl}
        keepMounted
        open={openLongMenu}
        onClose={handleToggle}
      >
        {!isEmpty(list) && map(list, (item, index) => (
          <MenuItem
            key={index}
            selected={Boolean(currentLongMenu.toJS()[index])}
            onClick={handleSelected(index)}
          >
            {item.name}
          </MenuItem>
        ))}
      </MenuMui>
    </>
  );
}

LongMenu.propTypes = {
  items: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  entity: PropTypes.string.isRequired,
  currentLongMenu: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  openLongMenu: PropTypes.bool.isRequired,
  displayButtonFab: PropTypes.bool,
  displayButton: PropTypes.bool,
  buttonSize: PropTypes.string,
  buttonSizeFab: PropTypes.string,
  isCleanUpLongMenu: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  initialLongMenu: PropTypes.bool.isRequired,
  isNewEntity: PropTypes.bool,
};

LongMenu.defaultProps = {
  displayButtonFab: true,
  displayButton: true,
  buttonSize: 'small',
  buttonSizeFab: 'medium',
  isCleanUpLongMenu: true,
  isNewEntity: false,
};

function mapStateToProps(state, { entity }) {
  return {
    currentLongMenu: selectors.helpers.currentLongMenu(state, entity),
    openLongMenu: selectors.helpers.openLongMenu(state, entity),
    initialLongMenu: selectors.helpers.initialLongMenu(state),
  };
}

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.items, nextProps.items)
    && isEqual(prevProps.entity, nextProps.entity)
    && isEqual(prevProps.currentLongMenu, nextProps.currentLongMenu)
    && isEqual(prevProps.openLongMenu, nextProps.openLongMenu)
    && isEqual(prevProps.displayButtonFab, nextProps.displayButtonFab)
    && isEqual(prevProps.buttonSize, nextProps.buttonSize)
    && isEqual(prevProps.displayButton, nextProps.displayButton)
    && isEqual(prevProps.isCleanUpLongMenu, nextProps.isCleanUpLongMenu)
    && isEqual(prevProps.buttonSizeFab, nextProps.buttonSizeFab)
    && isEqual(prevProps.isNewEntity, nextProps.isNewEntity)
    && isEqual(prevProps.initialLongMenu, nextProps.initialLongMenu);
}

export default compose(
  connect(mapStateToProps, null),
)(React.memo(LongMenu, areEqual));
