// Core
import { all, call, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callGetDepartmentDocumentsModalWorker,
} from './workers';
import types from '../types';

function* watchGetDepartmentDocuments() {
  yield takeEvery(
    asyncTypes.GET_DEPARTMENT_DOCUMENTS_ASYNC_MODAL,
    callGetDepartmentDocumentsModalWorker,
  );
}
function* watchGetInitialState() {
  yield takeEvery(
    asyncTypes.GET_DEPARTMENT_DOCUMENTS_INITIAL_STATE_ASYNC_MODAL,
    callGetInitialStateWorker,
  );
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_DEPARTMENT_DOCUMENTS_CURRENT_PAGE_MODAL, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_DEPARTMENT_DOCUMENTS_PAGE_SIZE_MODAL, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_DEPARTMENT_DOCUMENTS_FILTERS_MODAL, callSetToLocalDataWorker);
}

export function* watchersDepartmentDocumentsModalTable() {
  yield all([
    call(watchGetDepartmentDocuments),
    call(watchSetCurrentPage),
    call(watchSetPageSize),
    call(watchSetFilters),
    call(watchGetInitialState),
  ]);
}
