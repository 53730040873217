// Core
import { createStore, applyMiddleware, compose } from 'redux';

// Instruments
import { rootReducer } from './rootReducer';
import {
  sagaMiddleWare, dev, middleware, history,
} from './midlware';

import { rootSaga } from './rootSaga';

const devtools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__; // eslint-disable-line
const composeEnhancers = dev && devtools ? devtools : compose;

const store = createStore(
  rootReducer(history),
  composeEnhancers(applyMiddleware(...middleware)),
);

sagaMiddleWare.run(rootSaga);

export { store };
