const asyncTypes = Object.freeze({
  GET_DOCUMENT_TYPES_CATEGORY_ASYNC: 'GET_DOCUMENT_TYPES_CATEGORY_ASYNC',
  GET_DOCUMENT_TYPES_CATEGORY_INITIAL_STATE_ASYNC: 'GET_DOCUMENT_TYPES_CATEGORY_INITIAL_STATE_ASYNC',
  DELETE_DOCUMENT_TYPE_CATEGORY_ASYNC: 'DELETE_DOCUMENT_TYPE_CATEGORY_ASYNC',
  POST_DOCUMENT_TYPE_CATEGORY_ASYNC: 'POST_DOCUMENT_TYPE_CATEGORY_ASYNC',
  GET_DOCUMENT_TYPE_CATEGORY_BY_ID_ASYNC: 'GET_DOCUMENT_TYPE_CATEGORY_BY_ID_ASYNC',
  PUT_DOCUMENT_TYPE_CATEGORY_BY_ID_ASYNC: 'PUT_DOCUMENT_TYPE_CATEGORY_BY_ID_ASYNC',
  GET_DOCUMENT_TYPES_CATEGORY_AUTOCOMPLETE_OPTIONS_ASYNC: 'GET_DOCUMENT_TYPES_CATEGORY_AUTOCOMPLETE_OPTIONS_ASYNC',
});

export default asyncTypes;
