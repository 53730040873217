export default Object.freeze({
  SET_DOCUMENT_TYPES_CATEGORY_DATA: 'SET_DOCUMENT_TYPES_CATEGORY_DATA',
  SET_DOCUMENT_TYPES_CATEGORY_CURRENT_PAGE: 'SET_DOCUMENT_TYPES_CATEGORY_CURRENT_PAGE',
  SET_DOCUMENT_TYPES_CATEGORY_PAGE_SIZE: 'SET_DOCUMENT_TYPES_CATEGORY_PAGE_SIZE',
  SET_DOCUMENT_TYPES_CATEGORY_FILTERS: 'SET_DOCUMENT_TYPES_CATEGORY_FILTERS',
  SET_DOCUMENT_TYPES_CATEGORY_SORTING: 'SET_DOCUMENT_TYPES_CATEGORY_SORTING',
  SET_DOCUMENT_TYPES_CATEGORY_INITIAL_STATE: 'SET_DOCUMENT_TYPES_CATEGORY_INITIAL_STATE',
  SET_DOCUMENT_TYPES_CATEGORY_RELOAD: 'SET_DOCUMENT_TYPES_CATEGORY_RELOAD',
  SET_DELETE_DOCUMENT_TYPE_CATEGORY: 'SET_PENDING_DELETE_DOCUMENT_TYPE_CATEGORY',
  SET_DOCUMENT_TYPE_CATEGORY_BY_ID: 'SET_DOCUMENT_TYPE_CATEGORY_BY_ID',
  SET_DOCUMENT_TYPES_CATEGORY_AUTOCOMPLETE_OPTIONS: 'SET_DOCUMENT_TYPES_CATEGORY_AUTOCOMPLETE_OPTIONS',
  SET_DOCUMENT_TYPES_CATEGORY_COLUMN_WIDTHS: 'SET_DOCUMENT_TYPES_CATEGORY_COLUMN_WIDTHS',
  SET_DOCUMENT_TYPES_CATEGORY_COLUMN_ORDER: 'SET_DOCUMENT_TYPES_CATEGORY_COLUMN_ORDER',
  SET_DOCUMENT_TYPES_CATEGORY_HIDDEN_COLUMN_NAMES: 'SET_DOCUMENT_TYPES_CATEGORY_HIDDEN_COLUMN_NAMES',
});
