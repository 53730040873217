export default Object.freeze({
  SET_INDUSTRIAL_PROPOSAL_DATA_BY_ID: 'SET_INDUSTRIAL_PROPOSAL_DATA_BY_ID',
  SET_INDUSTRIAL_DESIGNS_PROPOSAL_DATA: 'SET_INDUSTRIAL_DESIGNS_PROPOSAL_DATA',
  SET_INDUSTRIAL_DESIGNS_PROPOSAL_CURRENT_PAGE: 'SET_INDUSTRIAL_DESIGNS_PROPOSAL_CURRENT_PAGE',
  SET_INDUSTRIAL_DESIGNS_PROPOSAL_PAGE_SIZE: 'SET_INDUSTRIAL_DESIGNS_PROPOSAL_PAGE_SIZE',
  SET_INDUSTRIAL_DESIGNS_PROPOSAL_FILTERS: 'SET_INDUSTRIAL_DESIGNS_PROPOSAL_FILTERS',
  SET_INDUSTRIAL_DESIGNS_PROPOSAL_SORTING: 'SET_INDUSTRIAL_DESIGNS_PROPOSAL_SORTING',
  SET_INDUSTRIAL_DESIGNS_PROPOSAL_INITIAL_STATE: 'SET_INDUSTRIAL_DESIGNS_PROPOSAL_INITIAL_STATE',
  SET_INDUSTRIAL_DESIGNS_PROPOSAL_RELOAD: 'SET_INDUSTRIAL_DESIGNS_PROPOSAL_RELOAD',
  SET_DELETE_INDUSTRIAL_DESIGN_PROPOSAL: 'SET_DELETE_INDUSTRIAL_DESIGN_PROPOSAL',
  SET_INDUSTRIAL_PROPOSAL_AUTOCOMPLETE_OPTIONS: 'SET_INDUSTRIAL_PROPOSAL_AUTOCOMPLETE_OPTIONS',
  SET_INDUSTRIAL_DESIGNS_PROPOSAL_UPLOAD_FILES: 'SET_INDUSTRIAL_DESIGNS_PROPOSAL_UPLOAD_FILES',
  SET_INDUSTRIAL_DESIGNS_PROPOSAL_ADD_APPLICANTS_FORM_SHOW: 'SET_INDUSTRIAL_DESIGNS_PROPOSAL_ADD_APPLICANTS_FORM_SHOW',
  SET_INDUSTRIAL_DESIGNS_PROPOSAL_SIGN_LIST: 'SET_INDUSTRIAL_DESIGNS_PROPOSAL_SIGN_LIST',
  SET_INDUSTRIAL_DESIGNS_PROPOSAL_SIGN_MODAL_IS_OPEN: 'SET_INDUSTRIAL_DESIGNS_PROPOSAL_SIGN_MODAL_IS_OPEN',
  SET_INDUSTRIAL_DESIGNS_PROPOSAL_SIGN_PENDING: 'SET_INDUSTRIAL_DESIGNS_PROPOSAL_SIGN_PENDING',
  SET_INDUSTRIAL_DESIGNS_PENDING_PUT_PROPOSAL: 'SET_INDUSTRIAL_DESIGNS_PENDING_PUT_PROPOSAL',
  SET_INDUSTRIAL_DESIGNS_AUTHORS_FORM_SHOW: 'SET_INDUSTRIAL_DESIGNS_AUTHORS_FORM_SHOW',
  SET_INDUSTRIAL_DESIGNS_OWNERS_FORM_SHOW: 'SET_INDUSTRIAL_DESIGNS_OWNERS_FORM_SHOW',
  SET_INDUSTRIAL_DESIGNS_NEW_AUTHORS_LIST: 'SET_INDUSTRIAL_DESIGNS_NEW_AUTHORS_LIST',
  SET_PENDING_INDUSTRIAL_PROPOSAL_AUTOCOMPLETE_OPTIONS: 'SET_PENDING_INDUSTRIAL_PROPOSAL_AUTOCOMPLETE_OPTIONS',
  SET_INDUSTRIAL_DESIGNS_NEW_OWNERS_LIST: 'SET_INDUSTRIAL_DESIGNS_NEW_OWNERS_LIST',
  MERGE_INDUSTRIAL_DESIGNS_NEW_OWNERS_LIST: 'MERGE_INDUSTRIAL_DESIGNS_NEW_OWNERS_LIST',
  MERGE_INDUSTRIAL_DESIGNS_NEW_AUTHORS_LIST: 'MERGE_INDUSTRIAL_DESIGNS_NEW_AUTHORS_LIST',
  SET_INDUSTRIAL_DESIGNS_IMAGE: 'SET_INDUSTRIAL_DESIGNS_IMAGE',
  SET_INDUSTRIAL_DESIGNS_IMAGES_VARIANT_LIST: 'SET_INDUSTRIAL_DESIGNS_IMAGES_VARIANT_LIST',
  SET_INDUSTRIAL_DESIGNS_NEW_IMAGES_VARIANT_LIST: 'SET_INDUSTRIAL_DESIGNS_NEW_IMAGES_VARIANT_LIST',
  SET_INDUSTRIAL_DESIGNS_IMAGES_MOVE: 'SET_INDUSTRIAL_DESIGNS_IMAGES_MOVE',
  SET_INDUSTRIAL_DESIGNS_IMAGES_VARIANT_REMOVE: 'SET_INDUSTRIAL_DESIGNS_IMAGES_VARIANT_REMOVE',
  SET_INDUSTRIAL_DESIGNS_IMAGES_REMOVE: 'SET_INDUSTRIAL_DESIGNS_IMAGES_REMOVE',
  SET_INDUSTRIAL_DESIGNS_IMAGES_NAME: 'SET_INDUSTRIAL_DESIGNS_IMAGES_NAME',
  SET_INDUSTRIAL_DESIGNS_PENDING_POST_PROPOSAL: 'SET_INDUSTRIAL_DESIGNS_PENDING_POST_PROPOSAL',
  SET_PENDING_GET_SET_INDUSTRIAL_DESIGNS_PROPOSAL_BY_ID: 'SET_PENDING_GET_SET_INDUSTRIAL_DESIGNS_PROPOSAL_BY_ID',
  SET_INDUSTRIAL_DESIGNS_IMAGES_CLEAR: 'SET_INDUSTRIAL_DESIGNS_IMAGES_CLEAR',
  SET_INDUSTRIAL_DESIGNS_PROPOSAL_COMPANY: 'SET_INDUSTRIAL_DESIGNS_PROPOSAL_COMPANY',
  SET_INDUSTRIAL_DESIGNS_PENDING_GET_FIND_COMPANY: 'SET_INDUSTRIAL_DESIGNS_PENDING_GET_FIND_COMPANY',
  SET_INDUSTRIAL_DESIGNS_PENDING_GET_REGISTRY_BY_NUMBER: 'SET_INDUSTRIAL_DESIGNS_PENDING_GET_REGISTRY_BY_NUMBER',
  SET_INDUSTRIAL_DESIGNS_REGISTRY_BY_NUMBER: 'SET_INDUSTRIAL_DESIGNS_REGISTRY_BY_NUMBER',
  SET_INDUSTRIAL_DESIGNS_NEXT_NUMBER: 'SET_INDUSTRIAL_DESIGNS_NEXT_NUMBER',
  SET_INDUSTRIAL_DESIGNS_PROPOSAL_COLUMN_WIDTHS: 'SET_INDUSTRIAL_DESIGNS_PROPOSAL_COLUMN_WIDTHS',
  SET_INDUSTRIAL_DESIGNS_PROPOSAL_COLUMN_ORDER: 'SET_INDUSTRIAL_DESIGNS_PROPOSAL_COLUMN_ORDER',
  SET_INDUSTRIAL_DESIGNS_PROPOSAL_HIDDEN_COLUMN_NAMES: 'SET_INDUSTRIAL_DESIGNS_PROPOSAL_HIDDEN_COLUMN_NAMES',
});
