// Core
import React, { useState, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import {
  Field, Form, reduxForm, formValueSelector,
} from 'redux-form/immutable';
import * as PropTypes from 'prop-types';

// ui
import {
  Typography, CardContent, Box, Button,
  Grid, CircularProgress, Card as MuiCard,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';

// lodash
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import filter from 'lodash/filter';

// parts
import RenderTextField from '../../ui/Form/RenderTextField';
import FieldEmployee from '../../ui/Form/FieldsAutocomplete/FieldEmployee';
import FieldClient from '../../ui/Form/FieldsAutocomplete/FieldClient';

// actions
import importObjectsAsyncActions from '../../engine/core/importObjects/saga/asyncAction';

// config
import selectors from '../../engine/config/selectors';

// helpers
import { formName, formFields } from './helper/form';
import { useEventsAutocompleteAsync } from '../../ui/_hooks/useEventsAutocompleteAsync';

// styles
const Card = styled(MuiCard)(spacing);

// fields redux form
const formSelector = formValueSelector(formName);

const caseGenerateNumbers = {
  tradeMarks: 'tradeMarks',
  industrialDesigns: 'industrialDesigns',
  utilityModels: 'utilityModels',
  inventions: 'inventions',
  typeApp: 'application',
  typeReg: 'registration',
};

function ImportObjects(props) {
  const {
    fieldTradeMarksApplicationNumber, handleSubmit,
    fieldTradeMarksRegistrationNumber, fieldIndustrialDesignsApplicationNumber,
    fieldIndustrialDesignsRegistrationNumber, fieldUtilityModelsApplicationNumber,
    fieldUtilityModelsRegistrationNumber, fieldInventionsApplicationNumber,
    fieldInventionsRegistrationNumber, clientById, valid,
    pendingPostImportObjects, personById,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [pendingType, setPendingType] = useState(null);
  const {
    inventions, typeApp, typeReg, tradeMarks,
    industrialDesigns, utilityModels,
  } = caseGenerateNumbers;

  const {
    getAutocompleteLists,
    handleGetSelectOption,
    handleGetAsyncData,
  } = useEventsAutocompleteAsync();
  const validForm = useMemo(() => valid && (!isEmpty(fieldTradeMarksApplicationNumber)
    || !isEmpty(fieldTradeMarksRegistrationNumber)
    || !isEmpty(fieldIndustrialDesignsApplicationNumber)
    || !isEmpty(fieldIndustrialDesignsRegistrationNumber)
    || !isEmpty(fieldInventionsApplicationNumber)
    || !isEmpty(fieldInventionsRegistrationNumber)
    || !isEmpty(fieldUtilityModelsApplicationNumber)
    || !isEmpty(fieldUtilityModelsRegistrationNumber)), [
    valid, fieldTradeMarksApplicationNumber, fieldTradeMarksRegistrationNumber,
    fieldIndustrialDesignsApplicationNumber, fieldIndustrialDesignsRegistrationNumber,
    fieldInventionsApplicationNumber, fieldInventionsRegistrationNumber,
    fieldUtilityModelsApplicationNumber, fieldUtilityModelsRegistrationNumber,
  ]);

  const convertToObject = (numbers, type) => (!isEmpty(numbers)
    ? map(filter(numbers.split(','), (number) => !isEmpty(number)), (number) => ({
      type, number: number.replace(/\s+/g, ''),
    })) : []);

  const convertToCurrentBlock = (data) => {
    const { fieldApplication, fieldRegistration, key } = data;
    return !isEmpty(fieldApplication) || !isEmpty(fieldRegistration) ? {
      [key]: [
        ...convertToObject(fieldApplication, typeApp),
        ...convertToObject(fieldRegistration, typeReg),
      ],
    } : {};
  };

  const generateNumbers = (key) => {
    switch (key) {
      case tradeMarks:
        return convertToCurrentBlock({
          key: 'trademarks',
          fieldApplication: fieldTradeMarksApplicationNumber,
          fieldRegistration: fieldTradeMarksRegistrationNumber,
        });
      case industrialDesigns:
        return convertToCurrentBlock({
          key: 'industrial',
          fieldApplication: fieldIndustrialDesignsApplicationNumber,
          fieldRegistration: fieldIndustrialDesignsRegistrationNumber,
        });
      case utilityModels:
        return convertToCurrentBlock({
          key: 'utility',
          fieldApplication: fieldUtilityModelsApplicationNumber,
          fieldRegistration: fieldUtilityModelsRegistrationNumber,
        });
      case inventions:
        return convertToCurrentBlock({
          key: 'inventions',
          fieldApplication: fieldInventionsApplicationNumber,
          fieldRegistration: fieldInventionsRegistrationNumber,
        });
      default:
        return {};
    }
  };

  const sendImportObjects = (key) => {
    const client = clientById.toJS().id;
    setPendingType(key);
    dispatch(importObjectsAsyncActions.postImportObjectsAsync({
      client,
      employee: personById.toJS().id,
      ...key ? generateNumbers(key) : {
        ...generateNumbers(tradeMarks),
        ...generateNumbers(industrialDesigns),
        ...generateNumbers(utilityModels),
        ...generateNumbers(inventions),
      },
    }));
  };

  const handleSendNumbersBlock = (key) => {
    switch (key) {
      case tradeMarks: {
        sendImportObjects(tradeMarks);
        break;
      }
      case industrialDesigns: {
        sendImportObjects(industrialDesigns);
        break;
      }
      case utilityModels: {
        sendImportObjects(utilityModels);
        break;
      }
      case inventions: {
        sendImportObjects(inventions);
        break;
      }
      default: {
        sendImportObjects(null);
      }
    }
  };

  const handleClientsGetSelectOption = (elem) => {
    handleGetSelectOption(elem, 'clients');
  };
  const handlePersonGetSelectOption = (elem) => {
    handleGetSelectOption(elem, 'person');
  };
  return (
    <>
      {/* <Helmet title={t('Import objects')} />
      <Typography variant="h3" gutterBottom display="inline">
        {t('Import objects')}
      </Typography>
      <Divider my={6} /> */}

      <Form onSubmit={handleSubmit(() => {
        handleSendNumbersBlock();
      })}
      >
        <Card mb={6}>
          <CardContent>
            <Typography variant="h4" gutterBottom>
              {t('Connections')}
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <FieldClient
                  name="clients"
                  getAutocompleteLists={getAutocompleteLists}
                  formName={formName}
                  getAsyncData={handleGetAsyncData}
                  propsField={{
                    getSelectOption: handleClientsGetSelectOption,
                  }}
                />
                {!isEmpty(clientById.toJS()) && (
                  <Grid container spacing={4} item xs={12} alignItems="center">
                    <Grid item xs={12} container direction="column">
                      <Typography variant="subtitle2">
                        {clientById.toJS()?.name}
                      </Typography>
                      {!isEmpty(clientById.toJS().addresses)
                        && !isEmpty(clientById.toJS().addresses[0]) && (
                        <Typography variant="body2" gutterBottom>
                          {t('Street')}
                          {': '}
                          {clientById.toJS().addresses[0]?.address}
                          <br />
                          {t('Country')}
                          {': '}
                          {clientById.toJS().addresses[0]?.country}
                          <br />
                          {t('City')}
                          {': '}
                          {clientById.toJS().addresses[0]?.city}
                        </Typography>
                      )}
                      {!isEmpty(clientById.toJS().phone) && (
                        <Typography variant="body2" gutterBottom>
                          {t('Phone')}
                          {': '}
                          {clientById.toJS()?.phone}
                        </Typography>
                      )}
                      {!isEmpty(clientById.toJS().email) && (
                        <Typography variant="body2" gutterBottom>
                          {t('Email')}
                          {': '}
                          {clientById.toJS()?.email}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                <FieldEmployee
                  name="person"
                  label="Person"
                  getAutocompleteLists={getAutocompleteLists}
                  getAsyncData={handleGetAsyncData}
                  formName={formName}
                  propsField={{
                    getSelectOption: handlePersonGetSelectOption,
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card mb={6}>
          <CardContent>
            <Typography variant="h4" gutterBottom>
              {t('Trade marks')}
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Field
                  fullWidth
                  multiline
                  rows={6}
                  name={formFields.tradeMarksApplicationNumber}
                  label={`${t('Application number')} (${t('Comma separated')})`}
                  margin="normal"
                  variant="outlined"
                  component={RenderTextField}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  fullWidth
                  multiline
                  rows={6}
                  name={formFields.tradeMarksRegistrationNumber}
                  label={`${t('Registration number')} (${t('Comma separated')})`}
                  margin="normal"
                  variant="outlined"
                  component={RenderTextField}
                />
              </Grid>
            </Grid>

            <Button
              variant="contained"
              color="primary"
              disabled={
                (isEmpty(fieldTradeMarksRegistrationNumber)
                  && isEmpty(fieldTradeMarksApplicationNumber))
                || (pendingType === tradeMarks && pendingPostImportObjects)
              }
              onClick={() => {
                handleSendNumbersBlock(tradeMarks);
              }}
            >
              {(pendingType === tradeMarks && pendingPostImportObjects)
                ? <CircularProgress size={25} color="inherit" />
                : t('IMPORT')}
            </Button>
          </CardContent>
        </Card>

        <Card mb={6}>
          <CardContent>
            <Typography variant="h4" gutterBottom>
              {t('Industrial designs')}
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Field
                  fullWidth
                  multiline
                  rows={6}
                  name={formFields.industrialDesignsApplicationNumber}
                  label={`${t('Application number')} (${t('Comma separated')})`}
                  margin="normal"
                  variant="outlined"
                  component={RenderTextField}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  fullWidth
                  multiline
                  rows={6}
                  name={formFields.industrialDesignsRegistrationNumber}
                  label={`${t('Registration number')} (${t('Comma separated')})`}
                  margin="normal"
                  variant="outlined"
                  component={RenderTextField}
                />
              </Grid>
            </Grid>

            <Button
              variant="contained"
              color="primary"
              disabled={
                (isEmpty(fieldIndustrialDesignsRegistrationNumber)
                  && isEmpty(fieldIndustrialDesignsApplicationNumber))
                || (pendingType === industrialDesigns && pendingPostImportObjects)
              }
              onClick={() => {
                handleSendNumbersBlock(industrialDesigns);
              }}
            >
              {(pendingType === industrialDesigns && pendingPostImportObjects)
                ? <CircularProgress size={25} color="inherit" />
                : t('IMPORT')}
            </Button>
          </CardContent>
        </Card>

        <Card mb={6}>
          <CardContent>
            <Typography variant="h4" gutterBottom>
              {t('Utility models')}
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Field
                  fullWidth
                  multiline
                  rows={6}
                  name={formFields.utilityModelsApplicationNumber}
                  label={`${t('Application number')} (${t('Comma separated')})`}
                  margin="normal"
                  variant="outlined"
                  component={RenderTextField}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  fullWidth
                  multiline
                  rows={6}
                  name={formFields.utilityModelsRegistrationNumber}
                  label={`${t('Registration number')} (${t('Comma separated')})`}
                  margin="normal"
                  variant="outlined"
                  component={RenderTextField}
                />
              </Grid>
            </Grid>

            <Button
              variant="contained"
              color="primary"
              disabled={
                (isEmpty(fieldUtilityModelsRegistrationNumber)
                  && isEmpty(fieldUtilityModelsApplicationNumber))
                || (pendingType === utilityModels && pendingPostImportObjects)
              }
              onClick={() => {
                handleSendNumbersBlock(utilityModels);
              }}
            >
              {(pendingType === utilityModels && pendingPostImportObjects)
                ? <CircularProgress size={25} color="inherit" />
                : t('IMPORT')}
            </Button>
          </CardContent>
        </Card>

        <Card mb={6}>
          <CardContent>
            <Typography variant="h4" gutterBottom>
              {t('Inventions')}
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Field
                  fullWidth
                  multiline
                  rows={6}
                  name={formFields.inventionsApplicationNumber}
                  label={`${t('Application number')} (${t('Comma separated')})`}
                  margin="normal"
                  variant="outlined"
                  component={RenderTextField}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  fullWidth
                  multiline
                  rows={6}
                  name={formFields.inventionsRegistrationNumber}
                  label={`${t('Registration number')} (${t('Comma separated')})`}
                  margin="normal"
                  variant="outlined"
                  component={RenderTextField}
                />
              </Grid>
            </Grid>

            <Button
              variant="contained"
              color="primary"
              disabled={
                (isEmpty(fieldInventionsRegistrationNumber)
                  && isEmpty(fieldInventionsApplicationNumber))
                || (pendingType === inventions && pendingPostImportObjects)
              }
              onClick={() => {
                handleSendNumbersBlock(inventions);
              }}
            >
              {(pendingType === inventions && pendingPostImportObjects)
                ? <CircularProgress size={25} color="inherit" />
                : t('IMPORT')}
            </Button>
          </CardContent>
        </Card>

        <Box mt={2}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!validForm || (!pendingType && pendingPostImportObjects)}
          >
            {(!pendingType && pendingPostImportObjects)
              ? <CircularProgress size={25} color="inherit" />
              : t('IMPORT ALL')}
          </Button>
        </Box>
      </Form>
    </>
  );
}

ImportObjects.displayName = 'ImportObjects';

ImportObjects.propTypes = {
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  valid: PropTypes.bool.isRequired,
  clientById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  personById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingPostImportObjects: PropTypes.bool.isRequired,
  fieldTradeMarksApplicationNumber: PropTypes.string,
  fieldTradeMarksRegistrationNumber: PropTypes.string,
  fieldIndustrialDesignsApplicationNumber: PropTypes.string,
  fieldIndustrialDesignsRegistrationNumber: PropTypes.string,
  fieldUtilityModelsApplicationNumber: PropTypes.string,
  fieldUtilityModelsRegistrationNumber: PropTypes.string,
  fieldInventionsApplicationNumber: PropTypes.string,
  fieldInventionsRegistrationNumber: PropTypes.string,
};

ImportObjects.defaultProps = {
  fieldTradeMarksApplicationNumber: '',
  fieldTradeMarksRegistrationNumber: '',
  fieldIndustrialDesignsApplicationNumber: '',
  fieldIndustrialDesignsRegistrationNumber: '',
  fieldUtilityModelsApplicationNumber: '',
  fieldUtilityModelsRegistrationNumber: '',
  fieldInventionsApplicationNumber: '',
  fieldInventionsRegistrationNumber: '',
};

function mapStateToProps(state) {
  return {
    clientsAutocompleteOptions: selectors.clients.autocompleteOptions(state),
    clientById: selectors.clients.getClientById(state),
    personById: selectors.employees.getEmployeeById(state),
    pendingClientById: selectors.clients.pendingClientById(state),
    pendingPostImportObjects: selectors.importObjects.pendingPostImportObjects(state),
    fieldTradeMarksApplicationNumber: formSelector(state, formFields.tradeMarksApplicationNumber),
    fieldTradeMarksRegistrationNumber: formSelector(state, formFields.tradeMarksRegistrationNumber),
    fieldIndustrialDesignsApplicationNumber: formSelector(
      state, formFields.industrialDesignsApplicationNumber,
    ),
    fieldIndustrialDesignsRegistrationNumber: formSelector(
      state, formFields.industrialDesignsRegistrationNumber,
    ),
    fieldUtilityModelsApplicationNumber: formSelector(
      state, formFields.utilityModelsApplicationNumber,
    ),
    fieldUtilityModelsRegistrationNumber: formSelector(
      state, formFields.utilityModelsRegistrationNumber,
    ),
    fieldInventionsApplicationNumber: formSelector(state, formFields.inventionsApplicationNumber),
    fieldInventionsRegistrationNumber: formSelector(state, formFields.inventionsRegistrationNumber),
  };
}

export default compose(
  reduxForm({
    form: formName,
  }),
  connect(mapStateToProps, null),
)(ImportObjects);
