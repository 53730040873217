// Core
import { call, put, select } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../config/api';
import selectors from '../../../../config/selectors';

// init
import i18n from '../../../../init/i18n';

// actions
import actions from '../../action';
import asyncAction from '../asyncAction';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../ui/_helpers/setNotificationMessage';

export function* callDeletePermissionsGroupWorker() {
  const { setDeletePermissionsGroup } = actions;
  const { getListAsync } = asyncAction;

  yield put(setDeletePermissionsGroup({
    pendingDelete: true,
  }));

  const idDelete = yield select(
    selectors.permissionsGroups.permissionsGroupIdDelete,
  );
  const response = yield call(api.permissionsGroups.deletePermissionsGroup, idDelete);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      yield put(setDeletePermissionsGroup({
        id: '',
      }));
      yield put(setSuccessMessage(i18n.t('Group deleted!'), response.status.toString()));
      yield put(getListAsync());
    }
  }

  yield put(setDeletePermissionsGroup({
    pendingDelete: false,
  }));
}
