// Core
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

// lodash
import { isEmpty, map } from 'lodash';

// actions
import actions from '../../engine/core/logging/action';
import actionAsync from '../../engine/core/logging/saga/asyncAction';
import helpersActions from '../../engine/core/helpers/action';

// parts
import DxTable from '../../ui/Table/DxTable';
import ConfirmModal from '../../components/ConfirmModal';

// config
import selectors from '../../engine/config/selectors';

// hooks
import { listTablesName, listEntityName } from '../../engine/config/listTablesName';
import accessList from '../../engine/config/accessList';

const tableName = listTablesName.logging;
const entityName = listEntityName.logging;

function LoggingTable(props) {
  const {
    loggingData, totalCount, pending,
    currentPage, filters, sorting,
    pageSize, pendingDeleteLog,
    checkboxes, isModalOpen, columnWidths,
    columnOrder, hiddenColumnNames,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const columns = [
    { name: 'id', title: t('ID') },
    { name: 'action', title: t('Action') },
    { name: 'entityName', title: t('The name of the entity') },
    { name: 'entityId', title: t('Id essence') },
    { name: 'users', title: t('Person') },
  ];

  const tableColumnExtensions = [
    { columnName: 'id', width: 50 },
    { columnName: 'action', width: '20rem' },
    { columnName: 'entityName', width: '20rem' },
    { columnName: 'entityId', width: '20rem' },
    { columnName: 'users', width: '20rem' },
  ];

  useEffect(() => function cleanup() {
    dispatch(actions.setLoggingData({
      items: [],
      totalCount: 0,
      pending: false,
    }));
  }, [dispatch]);

  // Paging
  const onCurrentPage = (page) => dispatch(actions.setCurrentPage(page));
  const onPageSize = (size) => dispatch(actions.setPageSize(size));
  // Filtering
  const setFilters = (getFilters) => dispatch(actions.setFilters(getFilters));

  // Sorting
  const onSortingChange = (getSorting) => dispatch(
    actions.setSorting(getSorting),
  );
  const onColumnWidthsChange = (getColumnWidths) => dispatch(
    actions.setColumnWidths(getColumnWidths),
  );
  const onColumnOrderChange = (getColumnOrder) => dispatch(
    actions.setColumnOrder(getColumnOrder),
  );
  const onHiddenColumnNamesChange = (getHiddenColumnNames) => dispatch(
    actions.setHiddenColumnNames(getHiddenColumnNames),
  );

  return (
    <>
      <DxTable
        name="Logging"
        isLoading={pending}
        columns={columns}
        rows={loggingData.toJS().items}
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageSize={onPageSize}
        onCurrentPage={onCurrentPage}
        filtersAction={setFilters}
        onSortingChange={onSortingChange}
        filters={filters}
        // checkboxes ------------------------
        tableName={tableName}
        reloadTable={actions.setReload}
        selection={checkboxes.toJS()}
        onSelectionChange={(checkboxSelect) => {
          dispatch(helpersActions.setCheckbox({
            tableName,
            checkboxes: checkboxSelect,
          }));
        }}
        // ------------------------
        entityName={entityName}
        sorting={sorting}
        isModalOpen={isModalOpen}
        tableColumnExtensions={!isEmpty(columnWidths) && columnWidths.length === columns.length
          ? columnWidths : tableColumnExtensions}
        onColumnWidthsChange={onColumnWidthsChange}
        columnOrder={!isEmpty(columnOrder) ? columnOrder : map(columns, 'name')}
        onColumnOrderChange={onColumnOrderChange}
        hiddenColumnNames={hiddenColumnNames}
        onHiddenColumnNamesChange={onHiddenColumnNamesChange}
        editComponentWidth={60}
        accessDelete={accessList.logging_delete}
      />

      <ConfirmModal
        buttonSendText="DELETE"
        pending={pendingDeleteLog}
        handleSend={() => {
          dispatch(actionAsync.deleteLoggingAsync());
        }}
      />
    </>
  );
}

LoggingTable.displayName = 'LoggingTable';

LoggingTable.propTypes = {
  loggingData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  totalCount: PropTypes.number.isRequired,
  pending: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  sorting: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  columnWidths: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  columnOrder: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  hiddenColumnNames: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  pageSize: PropTypes.number.isRequired,
  pendingDeleteLog: PropTypes.bool.isRequired,
  checkboxes: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    loggingData: selectors.logging.loggingData(state),
    pending: selectors.logging.pending(state),
    pendingDeleteLog: selectors.logging.pendingDeleteLog(state),
    isModalOpen: selectors.logging.isModalOpen(state),

    // Paging
    totalCount: selectors.logging.totalCount(state),
    currentPage: selectors.logging.currentPage(state),
    pageSize: selectors.logging.pageSize(state),

    // Filtering
    filters: selectors.logging.filters(state),

    // Sorting
    sorting: selectors.logging.sorting(state),

    // ColumnWidths
    columnWidths: selectors.logging.columnWidths(state),
    columnOrder: selectors.logging.columnOrder(state),
    hiddenColumnNames: selectors.logging.hiddenColumnNames(state),

    checkboxes: selectors.helpers.getCheckboxes(state, tableName),
  };
}

function mapDispatchToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(LoggingTable);
