// Core
import React from 'react';
import * as PropTypes from 'prop-types';

// UI
import CheckboxComponent from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

function Checkbox(props) {
  const {
    fullWidth, input,
    label, margin, disabled,
    classNameFormControl,
  } = props;
  const { checked } = input;

  return (
    <FormControl
      fullWidth={fullWidth}
      margin={margin}
      className={classNameFormControl}
      disabled={disabled}
    >
      <FormControlLabel
        control={(
          <CheckboxComponent
            checked={checked}
            onChange={input.onChange}
            color="primary"
          />
          )}
        label={label}
      />
    </FormControl>
  );
}

Checkbox.displayName = 'Checkbox';

Checkbox.propTypes = {
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  margin: PropTypes.oneOf(['none', 'dense', 'normal']),
  classNameFormControl: PropTypes.string,
  input: PropTypes.shape({
    checked: PropTypes.oneOfType([
      PropTypes.bool,
    ]),
    onChange: PropTypes.func,
    name: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
    ]),
  }),
};

Checkbox.defaultProps = {
  fullWidth: true,
  label: '',
  margin: 'normal',
  classNameFormControl: '',
  input: {
    checked: false,
    onChange: () => {},
    name: '',
  },
  disabled: false,
};

export default Checkbox;
