// Core
import { call, put } from 'redux-saga/effects';

// config
import { api } from '../../../../../config/api';

// actions
import action from '../../action';

export function* callGetDocumentTypeCategoryByIdWorker({ payload: id }) {
  const {
    setDocumentTypeCategoryById,
  } = action;
  yield put(setDocumentTypeCategoryById({
    pending: true,
  }));

  const response = yield call(api.documentTypesCategory.getDocumentTypeCategoryById, id);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    yield put(setDocumentTypeCategoryById({ data }));
  }

  yield put(setDocumentTypeCategoryById({
    pending: false,
  }));
}
