// core
import React, { memo, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';

// lodash
import { isEqual, replace } from 'lodash';

// config
import selectors from '../../engine/config/selectors';

// parts
import Modal from '../Modal/Modal';

// actions
import helpersActions from '../../engine/core/helpers/action';

function DocViewerModal(props) {
  const {
    isModalOpenDocViewer, previewFileById, url,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(helpersActions.setModal({ isModalOpenDocViewer: false }));
  };

  useEffect(() => function cleanup() {
    dispatch(helpersActions.setPreviewFileById({
      file: '',
      pending: false,
    }));
  }, [dispatch]);

  return (
    <Modal
      title={t('Document')}
      isModalOpen={isModalOpenDocViewer}
      handleCloseModal={handleCloseModal}
      displayDialogActions={false}
      maxWidth="md"
    >
      <DocViewer
        documents={[
          {
            uri: url || replace(
              previewFileById,
              'application/octet-stream',
              'application/pdf',
            ),
          },
        ]}
        pluginRenderers={DocViewerRenderers}
        config={{
          header: {
            disableHeader: true,
            disableFileName: true,
            retainURLParams: false,
          },
        }}
      />
    </Modal>
  );
}

DocViewerModal.propTypes = {
  isModalOpenDocViewer: PropTypes.bool.isRequired,
  previewFileById: PropTypes.string.isRequired,
  url: PropTypes.string,
};

DocViewerModal.defaultProps = {
  url: '',
};

function mapStateToProps(state) {
  return {
    isModalOpenDocViewer: selectors.helpers.isModalOpenDocViewer(state),
    previewFileById: selectors.helpers.previewFileById(state),
  };
}

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.isModalOpenDropZone, nextProps.isModalOpenDropZone)
    && isEqual(prevProps.url, nextProps.url)
    && isEqual(prevProps.previewFileById, nextProps.previewFileById);
}

export default compose(
  connect(mapStateToProps, null),
)(memo(DocViewerModal, areEqual));
