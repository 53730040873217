// core
import React, {
  memo, useEffect, useState, useMemo, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { List, Map } from 'immutable';
import { push } from 'connected-react-router';
import { Link as MuiLink } from 'react-router-dom';

// ui
import {
  Divider as MuiDivider, Typography, Box,
} from '@material-ui/core';
import { isEmpty, isNil } from 'lodash';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';
import {
  NavigateBefore as NavigateBeforeIcon,
} from '@material-ui/icons';

// engine
import selectors from '../../../engine/config/selectors';

// parts
import ProposalDocuments from '../../TradeMarks/Proposal/ProposalDocuments/ProposalDocuments';
import ProposalCalendar from '../../../components/ProposalCalendar/ProposalCalendar';
import ProposalDispatchLog from '../../../components/ProposalDispathLog/ProposalDispatchLog';
import ProposalPayments from '../../TradeMarks/Proposal/ProposalPayments/ProposalPayments';
import LongMenu from '../../../components/LongMenu/LongMenu';
import InventionsAdd from '../InventionAdd/InventionsAdd';
import AddMenu from '../../../components/AddMenu/AddMenu';
import FabScrollTop from '../../../components/FabScrollTop/FabScrollTop';
import ProposalEmail from '../../TradeMarks/Proposal/ProposalEmail/ProposalEmail';

// action
import proposalActions from '../../../engine/core/inventions/action';
import proposalAsyncActions from '../../../engine/core/inventions/saga/asyncAction';
import helpersAsyncAction from '../../../engine/core/helpers/saga/asyncAction';
import clientsActions from '../../../engine/core/company/clients/action';
import helpersActions from '../../../engine/core/helpers/action';
import applicantsActions from '../../../engine/core/applicants/action';
import employeesActions from '../../../engine/core/company/employees/action';
import industrialDesignsActions from '../../../engine/core/industrialDesigns/action';
import tradeMarksActions from '../../../engine/core/tradeMarks/proposal/action';
import depDocActions from '../../../engine/core/departmentDocuments/action';
import pendingActions from '../../../engine/core/pendingActions/action';
import uploadFileAction from '../../../engine/core/uploadFile/action';

// hooks
// import { useCurrentLongMenu } from '../../../components/LongMenu/_hooks/useCurrentLongMenu';

// routes
import { pageLinks } from '../../../routes';
import { useAccessList } from '../../../ui/_hooks/useAccessList';
import accessList from '../../../engine/config/accessList';

const TabPanelRoot = styled.div`
  flex: 1;
`;
const Link = styled(MuiLink)`
  text-decoration: underline;
  color: #1976d2;
  display: flex;
  align-items: center;
`;
function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <TabPanelRoot
      role="tabpanel"
      hidden={value !== index}
      id={`inventions-tabpanel-${index}`}
      aria-labelledby={`inventions-tab-${index}`}
      {...other}
    >
      {value === index && (
      <>{children}</>
      )}
    </TabPanelRoot>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Divider = styled(MuiDivider)(spacing);

const entityLongMenu = 'inventions';

function InventionsTab(props) {
  const { match, location } = props;
  const { params } = match;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [title, setTitle] = useState('New');
  const [isInitial, setInitial] = useState(false);
  const [isRequest, setIsRequest] = useState(false);
  const getProposalById = useSelector(selectors.inventionsProposal.getProposalById);
  // const isCurrentLongMenu = useCurrentLongMenu(
  //   `${entityLongMenu}${!isNil(params.hashId) ? params.hashId : ''}`,
  // );
  const accessDocumentsList = useAccessList(accessList.documents_list_get);
  const accessChargesList = useAccessList(accessList.charges_list_get);
  const accessPendingActionsList = useAccessList(accessList.pendingActions_list_get);
  const accessInDocsList = useAccessList(accessList.inDocs_list_get);
  const accessMktuList = useAccessList(accessList.mktu_list_get);
  const accessEmailLettersList = useAccessList(accessList.emailLetters_list_get);

  const checkTab = useCallback((tabName) => tabName === location.hash, [
    location,
  ]);

  const handleChangeTab = useCallback((tabName) => {
    dispatch(push(pageLinks.inventions.edit(params.hashId, tabName)));
    setInitial(true);
  }, [dispatch, params]);

  const getTitleTab = useMemo(() => {
    if (checkTab('')) { return t('Application'); }
    if (checkTab('#docs')) { return t('Documents'); }
    if (checkTab('#finance')) { return t('Payments'); }
    if (checkTab('#calendar')) { return t('Calendar'); }
    if (checkTab('#email')) { return t('Email'); }
    if (checkTab('#packets')) { return t('Dispatch Log'); }
    if (checkTab('#misc')) { return t('Other'); }
    return '';
  }, [checkTab, t]);

  useEffect(() => {
    if (!isNil(params.hashId) && !isRequest && checkTab('#docs') && isEmpty(getProposalById.toJS())) {
      dispatch(proposalAsyncActions.getProposalByIdAsync(params.hashId));
      setIsRequest(true);
    }
  }, [dispatch, params, setIsRequest, isRequest, getProposalById, checkTab]);

  useEffect(() => {
    if (!isNil(params.hashId)) {
      const titleProposal = getProposalById.toJS().caseNumber || params.hashId;
      setTitle(`${titleProposal} - ${getTitleTab}`);
    } else {
      setTitle(t('New'));
    }
  }, [params.hashId, getTitleTab, getProposalById, t]);

  const clients = useMemo(() => {
    if (getProposalById.get('clients')) return { clients: getProposalById.get('clients')?.id };
    return {};
  }, [getProposalById]);

  useEffect(() => {
    if (!isNil(params.hashId) && !location.hash && !isInitial) {
      dispatch(helpersAsyncAction.getInitialStateAsync({
        url: 'currentLongMenu',
        entity: `${entityLongMenu}${params.hashId}`,
      }));
      setInitial(true);
    }
  }, [params, dispatch, location, setInitial, isInitial]);

  useEffect(() => function cleanup() {
    dispatch(proposalActions.setProposalDataById({}));
    dispatch(applicantsActions.setNewApplicantsListData([]));
    dispatch(applicantsActions.setNewAuthorsListData([]));
    dispatch(applicantsActions.setNewOwnersListData([]));
    dispatch(applicantsActions.setApplicantsList(List()));
    dispatch(uploadFileAction.setAllUploadFiles([]));
    dispatch(helpersActions.setSignList([]));
    dispatch(employeesActions.setEmployeeById({ data: {} }));
    dispatch(clientsActions.setClientById({ data: {} }));
    dispatch(tradeMarksActions.setCompany(Map()));
    dispatch(industrialDesignsActions.setCompany(Map()));
  }, [dispatch]);

  const handleNewDocument = useCallback(() => {
    dispatch(helpersActions.setLastObject({ entityId: params.hashId, entityName: entityLongMenu }));
    dispatch(depDocActions.setDepartmentDocumentEntityList({
      [entityLongMenu]: [getProposalById.toJS()],
      ...getProposalById.toJS().clients ? { clients: [getProposalById.toJS().clients] } : {},
    }));
    dispatch(push(pageLinks.documents.departmentDocuments.new));
  }, [dispatch, getProposalById, params]);

  const handleNewNotification = useCallback(() => {
    dispatch(pendingActions.setPendingActionEntityList({
      [entityLongMenu]: getProposalById.toJS(),
    }));
    dispatch(pendingActions.setIsModalOpen(true));
  }, [dispatch, getProposalById]);

  return (
    <>
      <Helmet title={t('Application')} />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h3" gutterBottom display="inline">
          {title}
          {!isEmpty(params) && (
            <LongMenu
              handleChange={handleChangeTab}
              entity={`${entityLongMenu}${!isNil(params.hashId) ? params.hashId : ''}`}
              isNewEntity={isNil(params.hashId)}
              items={[
                { name: t('Application'), hash: '' },
                ...accessDocumentsList ? [{ name: t('Documents'), hash: 'docs' }] : [],
                ...accessChargesList ? [{ name: t('Payments'), hash: 'finance' }] : [],
                ...accessPendingActionsList ? [{ name: t('Calendar'), hash: 'calendar' }] : [],
                ...accessEmailLettersList ? [{ name: t('Email'), hash: 'email' }] : [],
                ...accessInDocsList ? [{ name: t('Dispatch Log'), hash: 'packets' }] : [],
                ...accessMktuList ? [{ name: t('Other'), hash: 'misc' }] : [],
              ]}
            />
          )}
          {!isEmpty(params) && (
            <AddMenu
              entity={entityLongMenu}
              handleNewDocument={handleNewDocument}
              handleNewNotification={handleNewNotification}
            />
          )}
          <FabScrollTop isNewEntity={isNil(params.hashId)} />
        </Typography>
        <Link to={pageLinks.inventions.all}>
          <NavigateBeforeIcon size="small" color="primary" />
          {t('Up to the list')}
        </Link>
      </Box>

      <Divider mb={6} />

      {(checkTab('') || isEmpty(params)) && <InventionsAdd match={match} />}
      {checkTab('#docs') && accessDocumentsList && (
        <ProposalDocuments
          match={match}
          name={entityLongMenu}
          proposal={getProposalById}
        />
      )}
      {checkTab('#finance') && accessChargesList && (
        <ProposalPayments
          match={match}
          clients={clients}
          entityName={entityLongMenu}
          defaultFilters={[
            { columnName: 'inventions.id', value: params.hashId },
          ]}
        />
      )}
      {checkTab('#calendar') && accessPendingActionsList && (
        <ProposalCalendar
          match={match}
          actionId="inventions.id"
          actionName={entityLongMenu}
        />
      )}
      {checkTab('#email') && accessEmailLettersList && (
        <ProposalEmail
          match={match}
          actionId="inventions.id"
          actionName="inventions"
        />
      )}
      {checkTab('#packets') && accessInDocsList && (
        <ProposalDispatchLog
          match={match}
          showTitle={false}
          actionId="inventions.id"
        />
      )}
      {checkTab('#misc') && accessMktuList && (
        <>Other</>
      )}
    </>
  );
}
InventionsTab.propTypes = {
  match: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  location: PropTypes.oneOfType([
    PropTypes.object,
  ]),
};
InventionsTab.defaultProps = {
  match: {},
  location: {},
};
export default memo(InventionsTab);
