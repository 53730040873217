const asyncTypes = Object.freeze({
  GET_NOTIFICATIONS_ASYNC: 'GET_NOTIFICATIONS_ASYNC',
  GET_NOTIFICATIONS_INITIAL_STATE_ASYNC: 'GET_NOTIFICATIONS_INITIAL_STATE_ASYNC',
  DELETE_NOTIFICATION_ASYNC: 'DELETE_NOTIFICATION_ASYNC',
  POST_NOTIFICATION_ASYNC: 'POST_NOTIFICATION_ASYNC',
  GET_NOTIFICATION_BY_ID_ASYNC: 'GET_NOTIFICATION_BY_ID_ASYNC',
  PUT_NOTIFICATION_BY_ID_ASYNC: 'PUT_NOTIFICATION_BY_ID_ASYNC',
  GET_NOTIFICATIONS_AUTOCOMPLETE_OPTIONS_ASYNC: 'GET_NOTIFICATIONS_AUTOCOMPLETE_OPTIONS_ASYNC',
});

export default asyncTypes;
