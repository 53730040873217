// Core
import React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

// UI
import MuiChip from '@material-ui/core/Chip';
import { spacing } from '@material-ui/system';

// actions
import pendingActions from '../../engine/core/pendingActions/action';

// routes
import { pageLinks } from '../../routes';

// styles
const Chip = styled(MuiChip)(spacing);

function ChipEntity(props) {
  const {
    color, handleDelete, label, entity,
    id, disabled,
  } = props;
  const dispatch = useDispatch();

  const handleClick = () => {
    if (entity && id) {
      dispatch(pendingActions.setIsModalOpen(false));
      switch (entity) {
        case 'trademarks': {
          dispatch(push(pageLinks.tradeMarksRoutes.proposal.edit(id)));
          break;
        }
        case 'utility': {
          dispatch(push(pageLinks.utilityModels.edit(id)));
          break;
        }
        case 'inventions': {
          dispatch(push(pageLinks.inventions.edit(id)));
          break;
        }
        case 'industrial': {
          dispatch(push(pageLinks.industrialDesigns.edit(id)));
          break;
        }
        case 'clients': {
          dispatch(push(pageLinks.company.clients.edit(id)));
          break;
        }
        case 'orders': {
          dispatch(push(pageLinks.company.orders.edit(id)));
          break;
        }
        case 'documents': {
          dispatch(push(pageLinks.documents.departmentDocuments.edit(id)));
          break;
        }
        case 'users': {
          dispatch(push(pageLinks.company.employees.edit(id)));
          break;
        }
        default: {
          break;
        }
      }
    }
  };

  return (
    <Chip
      style={{ maxWidth: '100%' }}
      color={color}
      label={label}
      onDelete={() => handleDelete(entity, id)}
      disabled={disabled}
      m={1}
      onClick={handleClick}
    />
  );
}

ChipEntity.displayName = 'ChipEntity';

ChipEntity.defaultProps = {
  color: 'primary',
  handleDelete: () => {},
  label: '',
  entity: '',
  id: '',
  disabled: false,
};

ChipEntity.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  handleDelete: PropTypes.func,
  label: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  entity: PropTypes.string,
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

export default ChipEntity;
