// Core
import { apply, put } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../config/api';

// actions
import helpersActions from '../../../helpers/action';
import {
  setErrorMessage, setSuccessMessage,
} from '../../../../../ui/_helpers/setNotificationMessage';
import i18n from '../../../../init/i18n';
import { messageRequestSent } from '../../../../config/globalConfig';

export function* callPostOutDocsSendWorker({ payload: params }) {
  const response = yield apply(api, api.sending.postOutDocsSend, [params]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      yield put(setSuccessMessage(i18n.t(messageRequestSent), response.status.toString()));
      yield put(helpersActions.setModal({ isModalOpenSend: false }));
    }
  }
}
