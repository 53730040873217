// Core
import { all, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetEmployeeByIdWorker,
  callPutEmployeeByIdWorker,
  callPostEmployeeWorker,
  callPostInviteEmployeeWorker,
  callGetEmployeeAutocompleteWorker,
  callGetKeysDataWorker,
  callPostKeyDataWorker,
  callGetCskDataWorker,
  callDeleteKeyWorker,
  callDeleteEmployeeWorker,
  callGetResponsibleEmployeeByIdWorker,
} from './workers';

function* watchGetEmployeeById() {
  yield takeEvery(asyncTypes.GET_EMPLOYEE_BY_ID_ASYNC, callGetEmployeeByIdWorker);
}

function* watchGetResponsibleEmployeeById() {
  yield takeEvery(asyncTypes.GET_RESPONSIBLE_EMPLOYEE_BY_ID_ASYNC,
    callGetResponsibleEmployeeByIdWorker);
}

function* watchPutEmployeeById() {
  yield takeEvery(asyncTypes.PUT_EMPLOYEE_BY_ID_ASYNC, callPutEmployeeByIdWorker);
}

function* watchPostEmployee() {
  yield takeEvery(asyncTypes.POST_EMPLOYEE_ASYNC, callPostEmployeeWorker);
}

function* watchPostInviteEmployee() {
  yield takeEvery(asyncTypes.POST_INVITE_EMPLOYEE_ASYNC, callPostInviteEmployeeWorker);
}

function* watchEmployeeAutocomplete() {
  yield takeEvery(asyncTypes.GET_EMPLOYEE_AUTOCOMPLETE_ASYNC, callGetEmployeeAutocompleteWorker);
}

function* watchGetKeysData() {
  yield takeEvery(asyncTypes.GET_KEYS_DATA_ASYNC, callGetKeysDataWorker);
}

function* watchPostKeyData() {
  yield takeEvery(asyncTypes.POST_KEY_DATA_ASYNC, callPostKeyDataWorker);
}

function* watchCskData() {
  yield takeEvery(asyncTypes.CSK_DATA_ASYNC, callGetCskDataWorker);
}

function* watchDeleteKey() {
  yield takeEvery(asyncTypes.DELETE_KEY_ASYNC, callDeleteKeyWorker);
}

function* watchDeleteEmployee() {
  yield takeEvery(asyncTypes.DELETE_EMPLOYEE_ASYNC, callDeleteEmployeeWorker);
}

export function* watchersEmployees() {
  yield all([
    watchGetEmployeeById(),
    watchPutEmployeeById(),
    watchPostEmployee(),
    watchPostInviteEmployee(),
    watchEmployeeAutocomplete(),
    watchGetKeysData(),
    watchPostKeyData(),
    watchCskData(),
    watchDeleteKey(),
    watchDeleteEmployee(),
    watchGetResponsibleEmployeeById(),
  ]);
}
