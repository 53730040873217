// Core
import { List, Map } from 'immutable';

// Instruments
import { isNil } from 'lodash';
import types from './types';
import { pageSizes } from '../../../config/globalConfig';
import globalStateKeys from '../../../config/globalStateKeys';

export const initialState = Map({
  data: Map({
    items: List(),
    currentPage: 0,
    filters: List(),
    pending: false,
    sorting: List([{ columnName: 'createdAt', direction: 'asc' }]),
    totalCount: 0,
    pageSize: pageSizes[0],
    reload: false,
    columnWidths: List(),
    columnOrder: List(),
    hiddenColumnNames: List(),
  }),
  itemByHashId: {
    data: {},
    pendingPut: false,
    isModalOpen: false,
    pendingPost: false,
    pending: false,
  },
  autocompleteOptions: Map({
    items: Map(),
  }),
  delete: Map({
    pendingDelete: false,
    id: '',
  }),
});

export const stateKeys = Object.freeze({
  controller: 'documentTypesCategory',
});

export const documentTypesCategoryReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_DOCUMENT_TYPES_CATEGORY_DATA: {
      const { items, totalCount, pending } = payload;
      if (pending) {
        return state.setIn([globalStateKeys.data, globalStateKeys.pending], pending);
      }
      return state
        .setIn([globalStateKeys.data, globalStateKeys.pending], pending)
        .setIn([globalStateKeys.data, globalStateKeys.items], List(items))
        .setIn([globalStateKeys.data, globalStateKeys.totalCount], totalCount);
    }
    case types.SET_DOCUMENT_TYPES_CATEGORY_RELOAD: {
      return state.setIn([globalStateKeys.data, globalStateKeys.reload], payload);
    }
    case types.SET_DOCUMENT_TYPES_CATEGORY_CURRENT_PAGE: {
      return state.setIn([globalStateKeys.data, globalStateKeys.currentPage], payload);
    }
    case types.SET_DOCUMENT_TYPES_CATEGORY_FILTERS: {
      const filters = payload.filter((item) => !isNil(item.value));
      return state.setIn([globalStateKeys.data, globalStateKeys.filters], List(filters));
    }
    case types.SET_DOCUMENT_TYPES_CATEGORY_SORTING: {
      return state.setIn([globalStateKeys.data, globalStateKeys.sorting], List(payload));
    }
    case types.SET_DOCUMENT_TYPES_CATEGORY_COLUMN_WIDTHS: {
      return state.setIn([globalStateKeys.data, globalStateKeys.columnWidths], List(payload));
    }
    case types.SET_DOCUMENT_TYPES_CATEGORY_COLUMN_ORDER: {
      return state.setIn([globalStateKeys.data, globalStateKeys.columnOrder], List(payload));
    }
    case types.SET_DOCUMENT_TYPES_CATEGORY_HIDDEN_COLUMN_NAMES: {
      return state.setIn([globalStateKeys.data, globalStateKeys.hiddenColumnNames], List(payload));
    }
    case types.SET_DOCUMENT_TYPES_CATEGORY_PAGE_SIZE: {
      return state.setIn([globalStateKeys.data, globalStateKeys.pageSize], payload);
    }
    case types.SET_DOCUMENT_TYPES_CATEGORY_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, sorting = [],
        columnWidths, columnOrder, hiddenColumnNames,
      } = payload;
      const reload = true;
      const currentSorting = sorting.length
        ? sorting
        : initialState.getIn([globalStateKeys.data, globalStateKeys.sorting]);
      return state
        .setIn([globalStateKeys.data, globalStateKeys.currentPage], currentPage)
        .setIn([globalStateKeys.data, globalStateKeys.filters], List(filters))
        .setIn([globalStateKeys.data, globalStateKeys.pageSize], pageSize)
        .setIn([globalStateKeys.data, globalStateKeys.totalCount], totalCount)
        .setIn([globalStateKeys.data, globalStateKeys.reload], reload)
        .setIn([globalStateKeys.data, globalStateKeys.columnWidths], List(columnWidths))
        .setIn([globalStateKeys.data, globalStateKeys.columnOrder], List(columnOrder))
        .setIn([globalStateKeys.data, globalStateKeys.hiddenColumnNames], List(hiddenColumnNames))
        .setIn([globalStateKeys.data, globalStateKeys.sorting], List(currentSorting));
    }
    case types.SET_DELETE_DOCUMENT_TYPE_CATEGORY: {
      return state.mergeIn([globalStateKeys.delete], payload);
    }
    case types.SET_DOCUMENT_TYPE_CATEGORY_BY_ID: {
      return state.mergeIn([globalStateKeys.itemByHashId], payload);
    }
    case types.SET_DOCUMENT_TYPES_CATEGORY_AUTOCOMPLETE_OPTIONS: {
      return state.mergeIn([
        globalStateKeys.autocompleteOptions,
        globalStateKeys.items,
      ], Map(payload));
    }
    default:
      return state;
  }
};
