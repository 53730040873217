// Helpers
import actionCreator from '../../../../_helpers/actionCreator';
// Types
import types from './types';

const actions = Object.freeze({
  setClientsData(company) {
    return actionCreator(types.SET_CLIENTS_DATA, company);
  },
  setCurrentPage(currentPage) {
    return actionCreator(types.SET_CLIENTS_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return actionCreator(types.SET_CLIENTS_FILTERS, filters);
  },
  setSorting(sorting) {
    return actionCreator(types.SET_CLIENTS_SORTING, sorting);
  },
  setPageSize(pageSize) {
    return actionCreator(types.SET_CLIENTS_PAGE_SIZE, pageSize);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_CLIENTS_INITIAL_STATE, initialState);
  },
  setReload(reload) {
    return actionCreator(types.SET_CLIENTS_RELOAD, reload);
  },
  setColumnWidths(data) {
    return actionCreator(types.SET_CLIENTS_COLUMN_WIDTHS, data);
  },
  setColumnOrder(data) {
    return actionCreator(types.SET_CLIENTS_COLUMN_ORDER, data);
  },
  setHiddenColumnNames(data) {
    return actionCreator(types.SET_CLIENTS_HIDDEN_COLUMN_NAMES, data);
  },
});

export default actions;
