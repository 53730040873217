// Core
import { apply, put } from 'redux-saga/effects';

// config
import { api } from '../../../../config/api';
import { listEntityName } from '../../../../config/listTablesName';

// actions
import actions from '../../action';

const entity = listEntityName.usersFilters;

export function* callGetUsersFiltersWorker({ payload: params }) {
  yield put(actions.setUsersFiltersData({ pending: true }));
  const response = yield apply(api, api.usersFilters.getUsersFilters, [{ ...params, entity }]);

  let dataInit = {
    items: [],
    totalCount: 0,
    pending: false,
  };

  if (response && response.status >= 200 && response.status < 400) {
    dataInit = Object.assign(dataInit, response.data);
  }
  yield put(actions.setUsersFiltersData(dataInit));
}
