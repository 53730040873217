// Core
import { call, put } from 'redux-saga/effects';

// config
import { api } from '../../../../../../config/api';

// actions
import actions from '../../action';

export function* callGetTariffIDWorker({ payload: id }) {
  const { setEditItem, setPending } = actions;
  yield put(setPending(true));
  const response = yield call(api.prices.getTariffID, id);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    yield put(setEditItem(data));
    yield put(setPending(false));
  }
}
