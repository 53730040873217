// core
import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import styled from 'styled-components';

// lodash
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import difference from 'lodash/difference';
import find from 'lodash/find';

// ui
import {
  Box,
} from '@material-ui/core';

// config
import selectors from '../../../../../engine/config/selectors';
// import globalStateKeys from '../../../../../engine/config/globalStateKeys';
import { listEntityName } from '../../../../../engine/config/listTablesName';

// parts
import DxTable from '../../../../../ui/Table/DxTable';
// import TableExtraFilter from
// '../../../../../ui/Table/components/TableExtraFilter/TableExtraFilter';

// actions
import chargesActions from '../../../../../engine/core/charges/action';

// reducers
// import { initialState } from '../../../../../engine/core/charges/reducer';

// hooks
import { useCharges } from './_hooks/useCharges';

const entityName = listEntityName.charges;

// styles
const BoxContainerTable = styled(Box)`
  overflow-y: scroll;
  height: 40vh;
  min-height: 40vh;
`;
// const BoxContainerExtraFilter = styled(Box)`
//   .MuiCardContent-root > .MuiPaper-root {
//     box-shadow: none;
//   }
// `;

function ChargesTable(props) {
  const {
    filters, pending, totalCount, currentPage, pageSize,
    chargesData, selectionsModal,
    isDefaultFilters,
  } = props;
  useCharges(isDefaultFilters ? {
    stopInitialState: true,
  } : {});
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selections = selectionsModal.toJS();

  const columns = [
    { name: 'name', title: t('Title') },
    { name: 'order', title: t('Order') },
    {
      name: 'type',
      title: t('Type'),
      options: [
        { name: t('Service'), value: '1' },
        { name: t('Gathering'), value: '2' },
      ],
    },
    { name: 'toPay', title: t('To pay') },
    { name: 'sum', title: t('Sum'), customField: 'sumChargesInput' },
  ];

  // Paging
  const onCurrentPage = (page) => dispatch(chargesActions.setCurrentPage(page));
  const onPageSize = (size) => dispatch(chargesActions.setPageSize(size));
  // Filtering
  const setFilters = (getFilters) => dispatch(chargesActions.setFilters(getFilters));

  // Reset filters
  // const resetFilters = useCallback(() => {
  //   const dataInitialState = initialState.get(globalStateKeys.data);
  //   dispatch(chargesActions.setFilters(dataInitialState.get(globalStateKeys.filters).toJS()));
  //   dispatch(chargesActions.setSorting(dataInitialState.get(globalStateKeys.sorting).toJS()));
  //   dispatch(chargesActions.setCurrentPage(dataInitialState.get(globalStateKeys.currentPage)));
  // }, [dispatch]);

  const onSelectionChange = (selected) => {
    const idBySelections = map(selections, (selection) => selection.id);
    const newSelectedCharges = difference(selected, idBySelections);
    if (isEmpty(newSelectedCharges)) {
      const removeDocuments = difference(idBySelections, selected);
      dispatch(chargesActions.setChargesSelectionsModal(
        selections.filter((selection) => selection.id !== removeDocuments[0]),
      ));
      return;
    }
    const selectedCharges = map(newSelectedCharges, (select) => (
      find(chargesData.toJS().items, { id: select })
    ));
    dispatch(chargesActions.setChargesSelectionsModal(
      [...selections, ...selectedCharges],
    ));
  };

  return (
    <>
      {/* <BoxContainerExtraFilter>
        <TableExtraFilter
          filters={filters}
          filtersAction={setFilters}
          resetFilters={resetFilters}
        />
      </BoxContainerExtraFilter> */}
      <BoxContainerTable>
        <DxTable
          name="Charges table"
          columns={columns}
          rows={chargesData.toJS().items}
          isLoading={pending}
          // Paging
          totalCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageSize={onPageSize}
          onCurrentPage={onCurrentPage}
          entityName={entityName}
          // filters { columnName: 'payed', value: 'false' }
          filtersAction={setFilters}
          filters={filters}
          selection={map(selections, (selection) => selection.id)}
          onSelectionChange={onSelectionChange}
          disableHiddenColumn
          disableColumnOrder
          disableColumnWidth
          disableExport
          disableTableScroll
        />
      </BoxContainerTable>
    </>
  );
}

ChargesTable.propTypes = {
  chargesData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  selectionsModal: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pending: PropTypes.bool.isRequired,
  // filters
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  // page
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  isDefaultFilters: PropTypes.bool,
};

ChargesTable.defaultProps = {
  isDefaultFilters: false,
};

function mapStateToProps(state) {
  return {
    chargesData: selectors.charges.chargesData(state),
    pending: selectors.charges.pending(state),
    selectionsModal: selectors.charges.selectionsModal(state),
    // Filtering
    filters: selectors.charges.filters(state),
    // Paging
    totalCount: selectors.charges.totalCount(state),
    currentPage: selectors.charges.currentPage(state),
    pageSize: selectors.charges.pageSize(state),
  };
}

export default compose(
  connect(mapStateToProps, null),
)(ChargesTable);
