// Core
import { call, put } from 'redux-saga/effects';
import { List } from 'immutable';

// lodash
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

// init
import i18n from '../../../../init/i18n';

// config
import { api } from '../../../../config/api';

// actions
import action from '../../action';

export function* callGetObjectStatusesWorker() {
  const {
    setObjectStatuses,
  } = action;
  yield put(setObjectStatuses({
    pending: true,
  }));

  const response = yield call(api.objectStatuses);

  if (response && response.status >= 200 && response.status < 400) {
    const { items } = response.data;
    const sortItems = map(items, (item) => ({
      ...item,
      name: i18n.t(item.name),
      label: i18n.t(item.label),
      value: `${item.id}`,
    }));
    yield put(setObjectStatuses({
      items: List(sortBy(sortItems, 'id')),
    }));
  }

  yield put(setObjectStatuses({
    pending: false,
  }));
}
