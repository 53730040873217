export default Object.freeze({
  SET_SEARCHES_DATA: 'SET_SEARCHES_DATA',
  SET_SEARCHES_CURRENT_PAGE: 'SET_SEARCHES_CURRENT_PAGE',
  SET_SEARCHES_PAGE_SIZE: 'SET_SEARCHES_PAGE_SIZE',
  SET_SEARCHES_FILTERS: 'SET_SEARCHES_FILTERS',
  SET_SEARCHES_SORTING: 'SET_SEARCHES_SORTING',
  SET_SEARCHES_INITIAL_STATE: 'SET_SEARCHES_INITIAL_STATE',
  SET_SEARCHES_RELOAD: 'SET_SEARCHES_RELOAD',
  SET_SEARCH_BY_ID: 'SET_SEARCH_BY_ID',
  SET_TABS: 'SET_TABS',
  SET_QUICK: 'SET_QUICK',
  SET_SEARCH_REPORT_DATA: 'SET_SEARCH_REPORT_DATA',
  SET_DELETE_SEARCH: 'SET_DELETE_SEARCH',
  SET_SEARCH_SELECTED_CLASSES: 'SET_SEARCH_SELECTED_CLASSES',
  MERGE_SEARCH_ENTITY_LIST: 'MERGE_SEARCH_ENTITY_LIST',
  SET_SEARCH_ENTITY_LIST: 'SET_SEARCH_ENTITY_LIST',
  SET_SEARCHES_COLUMN_WIDTHS: 'SET_SEARCHES_COLUMN_WIDTHS',
  SET_SEARCHES_COLUMN_ORDER: 'SET_SEARCHES_COLUMN_ORDER',
  SET_SEARCHES_HIDDEN_COLUMN_NAMES: 'SET_SEARCHES_HIDDEN_COLUMN_NAMES',
});
