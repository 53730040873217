// Core
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

// lodash
import { isEmpty, map } from 'lodash';

// actions
import actions from '../../../../engine/core/prices/category/action';
import actionAsync from '../../../../engine/core/prices/category/saga/asyncAction';
import helpersActions from '../../../../engine/core/helpers/action';
import helpersAsyncAction from '../../../../engine/core/helpers/saga/asyncAction';

// parts
import DxTable from '../../../../ui/Table/DxTable';
import ConfirmModal from '../../../../components/ConfirmModal';

// config
import selectors from '../../../../engine/config/selectors';

// hooks
import { listTablesName, listEntityName } from '../../../../engine/config/listTablesName';
import accessList from '../../../../engine/config/accessList';

const tableName = listTablesName.categories;
const entityName = listEntityName.categories;

function CategoriesTable(props) {
  const {
    categoriesData, totalCount, pending,
    currentPage, filters, sorting,
    pageSize, pendingDeleteCategory,
    checkboxes, isModalOpen, columnWidths,
    columnOrder, hiddenColumnNames,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const columns = [
    { name: 'id', title: t('ID') },
    { name: 'name', title: t('Name') },
    { name: 'rateType', title: t('Type of rate') },
  ];
  const editingStateColumnExtensionsCosts = [
    { columnName: 'id', editingEnabled: false },
    { columnName: 'name' },
    { columnName: 'rateType' },
  ];

  const tableColumnExtensions = [
    { columnName: 'id', width: 50 },
    { columnName: 'name', width: '40rem' },
    { columnName: 'rateType', width: '30rem' },
  ];

  const handleEditingChange = ({ changed }) => {
    const values = Object.values(changed)[0];
    const ids = Object.keys(changed);
    if (!isEmpty(values)) {
      const filterData = map(categoriesData.toJS().items, (item) => (
        item.id === Number(ids[0]) ? { ...item, ...values } : item));
      dispatch(actions.setCategoriesData({
        items: filterData,
        totalCount: filterData.length,
        pending: false,
      }));
      dispatch(helpersAsyncAction.putMultipleAsync({
        body: {
          entityName: 'costsCategories',
          entityIds: ids,
          fields: values,
        },
      }));
    }
  };

  useEffect(() => function cleanup() {
    dispatch(actions.setCategoriesData({
      items: [],
      totalCount: 0,
      pending: false,
    }));
  }, [dispatch]);

  // Paging
  const onCurrentPage = (page) => dispatch(actions.setCurrentPage(page));
  const onPageSize = (size) => dispatch(actions.setPageSize(size));
  // Filtering
  const setFilters = (getFilters) => dispatch(actions.setFilters(getFilters));

  // Sorting
  const onSortingChange = (getSorting) => dispatch(
    actions.setSorting(getSorting),
  );
  const onColumnWidthsChange = (getColumnWidths) => dispatch(
    actions.setColumnWidths(getColumnWidths),
  );
  const onColumnOrderChange = (getColumnOrder) => dispatch(
    actions.setColumnOrder(getColumnOrder),
  );
  const onHiddenColumnNamesChange = (getHiddenColumnNames) => dispatch(
    actions.setHiddenColumnNames(getHiddenColumnNames),
  );

  return (
    <>
      <DxTable
        name="Categories"
        isLoading={pending}
        columns={columns}
        rows={categoriesData.toJS().items}
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageSize={onPageSize}
        onCurrentPage={onCurrentPage}
        filtersAction={setFilters}
        onSortingChange={onSortingChange}
        filters={filters}
        // checkboxes ------------------------
        tableName={tableName}
        reloadTable={actions.setReload}
        selection={checkboxes.toJS()}
        onSelectionChange={(checkboxSelect) => {
          dispatch(helpersActions.setCheckbox({
            tableName,
            checkboxes: checkboxSelect,
          }));
        }}
        // ------------------------
        entityName={entityName}
        editingExtensions={editingStateColumnExtensionsCosts}
        onEditingChange={handleEditingChange}
        sorting={sorting}
        isModalOpen={isModalOpen}
        tableColumnExtensions={!isEmpty(columnWidths) && columnWidths.length === columns.length
          ? columnWidths : tableColumnExtensions}
        onColumnWidthsChange={onColumnWidthsChange}
        columnOrder={!isEmpty(columnOrder) ? columnOrder : map(columns, 'name')}
        onColumnOrderChange={onColumnOrderChange}
        hiddenColumnNames={hiddenColumnNames}
        onHiddenColumnNamesChange={onHiddenColumnNamesChange}
        accessEdit={accessList.costsCategories_put}
        accessDelete={accessList.costsCategories_delete}
      />

      <ConfirmModal
        buttonSendText="DELETE"
        pending={pendingDeleteCategory}
        handleSend={() => {
          dispatch(actionAsync.deleteCategoryAsync());
        }}
      />
    </>
  );
}

CategoriesTable.displayName = 'CategoriesTable';

CategoriesTable.propTypes = {
  categoriesData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  totalCount: PropTypes.number.isRequired,
  pending: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  sorting: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  columnWidths: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  columnOrder: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  hiddenColumnNames: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  pageSize: PropTypes.number.isRequired,
  pendingDeleteCategory: PropTypes.bool.isRequired,
  checkboxes: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    categoriesData: selectors.pricesCategory.categoriesData(state),
    pending: selectors.pricesCategory.pending(state),
    pendingDeleteCategory: selectors.pricesCategory.pendingDeleteCategory(state),
    isModalOpen: selectors.pricesCategory.isModalOpen(state),

    // Paging
    totalCount: selectors.pricesCategory.totalCount(state),
    currentPage: selectors.pricesCategory.currentPage(state),
    pageSize: selectors.pricesCategory.pageSize(state),

    // Filtering
    filters: selectors.pricesCategory.filters(state),

    // Sorting
    sorting: selectors.pricesCategory.sorting(state),

    // ColumnWidths
    columnWidths: selectors.pricesCategory.columnWidths(state),
    columnOrder: selectors.pricesCategory.columnOrder(state),
    hiddenColumnNames: selectors.pricesCategory.hiddenColumnNames(state),

    checkboxes: selectors.helpers.getCheckboxes(state, tableName),
  };
}

function mapDispatchToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(CategoriesTable);
