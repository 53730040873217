// Core
import {
  useCallback, useEffect, useRef, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

// lodash
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import find from 'lodash/find';

// actions
import asyncActions from '../../../../engine/core/email/email/saga/asyncAction';

// config
import selectors from '../../../../engine/config/selectors';
import { listEntityName } from '../../../../engine/config/listTablesName';

// Hook
import { useParamsRequest } from '../../../../ui/_hooks/useParamsRequest';

const entityName = listEntityName.emailLetters;

export function useEmailMessages(blockRetrievalRequest) {
  const currentPage = useSelector(selectors.email.currentPage);
  const limit = useSelector(selectors.email.pageSize);
  const filters = useSelector(selectors.email.filters);
  const sorting = useSelector(selectors.email.sorting);
  const reload = useSelector(selectors.email.reload);
  const routeKey = useSelector(selectors.router.key);
  const path = useSelector(selectors.router.pathname);
  const selectedEmail = useSelector(selectors.email.selectedEmail);
  const fieldsEntity = useSelector(selectors.fieldsSetting.fieldsEntity);
  const dispatch = useDispatch();
  const filterChanged = useMemo(() => {
    if (find(filters, (item) => item.columnName === 'folder' && item.value === 'SENT')) {
      return map(filters, (item) => (item.columnName === 'senderEmail'
        ? { ...item, columnName: 'recipients.email' } : item));
    }
    return filters;
  }, [filters]);

  const {
    orderBy, paramsByFilter,
  } = useParamsRequest({ filters: filterChanged, sorting, entityName });

  const getListAsync = useCallback(() => {
    const params = {
      ...paramsByFilter,
      'email.id:typeRequest:=': selectedEmail.toJS()?.id,
      ...orderBy,
      limit,
      page: currentPage + 1,
    };
    dispatch(asyncActions.getListMessagesAsync(params));
  }, [paramsByFilter, currentPage, limit, dispatch, selectedEmail, orderBy]);

  const getInitialStateAsync = useCallback(() => {
    dispatch(asyncActions.getMessagesInitialStateAsync(path));
  }, [dispatch, path]);

  const getInitialState = useRef(true);

  useEffect(() => {
    if (getInitialState.current) {
      getInitialStateAsync();
      getInitialState.current = false;
    }
    if (reload && !isEmpty(selectedEmail.toJS())
      && !isEmpty(fieldsEntity.toJS()) && !blockRetrievalRequest) {
      getListAsync();
    }
  }, [
    getListAsync, selectedEmail, getInitialStateAsync,
    reload, routeKey, fieldsEntity, blockRetrievalRequest,
  ]);
}
