import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  Grid,
  Hidden,
  List,
  ListItemText,
  ListItem as MuiListItem,
} from '@material-ui/core';

// actions
import helpersActions from '../engine/core/helpers/action';

// config
import selectors from '../engine/config/selectors';

// parts
import SupportModal from './SupportModal/SupportModal';

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  background: ${(props) => props.theme.palette.common.white};
  position: relative;
`;

const ListItem = styled(MuiListItem)`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)}px;
  padding-right: ${(props) => props.theme.spacing(2)}px;

  &,
  &:hover,
  &:active {
    background: ${(props) => props.theme.palette.common.white};
    color: #000;
  }
`;

function Footer() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isModalOpenSupport = useSelector(selectors.helpers.isModalOpenSupport);
  return (
    <Wrapper>
      {isModalOpenSupport && <SupportModal />}

      <Grid container spacing={0}>
        <Hidden smDown>
          <Grid container item xs={12} md={6}>
            <List>
              <ListItem
                button
                onClick={() => dispatch(helpersActions.setModal({ isModalOpenSupport: true }))}
              >
                <ListItemText primary={t('Support')} />
              </ListItem>
              <ListItem button component="a" href="https://help.lexberry.pro" target="_blank">
                <ListItemText primary={t('Documentation')} />
              </ListItem>
              <ListItem button>
                <ListItemText primary={t('Terms of use')} />
              </ListItem>
              <ListItem button>
                <ListItemText primary={t('Privacy policy')} />
              </ListItem>
            </List>
          </Grid>
        </Hidden>
        <Grid container item xs={12} md={6} justify="flex-end">
          <List>
            <ListItem>
              <ListItemText primary={`© ${new Date().getFullYear()} - lexberry`} />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Footer;
