// Core
import React, { memo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';

// lodash
import { isEmpty, isEqual } from 'lodash';

// UI
import {
  Card as MuiCard, Typography,
  CardContent,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';

// parts
import DxTable from '../../../../../ui/Table/DxTable';
import ConfirmModal from '../../../../../components/ConfirmModal';
import DocViewerModal from '../../../../../components/DocViewerModal/DocViewerModal';

// hooks
import { useInputDocument } from '../_hooks/useInputDocument';

// actions
import actions from '../../../../../engine/core/tradeMarks/proposal/documents/input/action';
import asyncActions from '../../../../../engine/core/tradeMarks/proposal/documents/input/saga/asyncAction';
import helpersActions from '../../../../../engine/core/helpers/action';

// config
import selectors from '../../../../../engine/config/selectors';

import { listTablesName, listEntityName } from '../../../../../engine/config/listTablesName';
import accessList from '../../../../../engine/config/accessList';

// styles
const Card = styled(MuiCard)(spacing);

const tableName = listTablesName.trademarksDocumentsIn;
const entityName = listEntityName.documents;

function InputDocumentTable(props) {
  const {
    documentsData, match, totalCount,
    pending, currentPage, filters, deleteData,
    sorting, pageSize, name, pendingDelete, checkboxes,
    isModalOpenDocViewer, pendingPreviewFileById,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { params } = match;
  useInputDocument(params.hashId, name);

  const columnsTableIn = [
    { name: 'id', title: t('ID') },
    { name: 'title', title: t('Title'), customField: 'ProposalDocuments' },
    { name: 'documentClassifier', title: t('Classification') },
    { name: 'inNumber', title: t('In number') },
    { name: 'outNumber', title: t('Out number') },
    { name: 'documentDate', title: t('Date') },
    { name: 'receivingDate', title: t('Received At') },
    { name: 'nextAction', title: t('Action up to') },
  ];

  // Paging
  const onCurrentPage = (page) => dispatch(actions.setCurrentPage(page));
  const onPageSize = (size) => dispatch(actions.setPageSize(size));
  // Filtering
  const setFilters = (getFilters) => dispatch(actions.setFilters(getFilters));
  // Sorting
  const onSortingChange = (getSorting) => dispatch(
    actions.setSorting(getSorting),
  );

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h2" gutterBottom>
          {t('Input documents')}
        </Typography>
        <DxTable
          name="ProposalDocumentsTableIn"
          isLoading={pending || pendingPreviewFileById}
          columns={columnsTableIn}
          rows={documentsData.toJS().items}
          // Paging
          totalCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageSize={onPageSize}
          onCurrentPage={onCurrentPage}
          // Filters
          filtersAction={setFilters}
          filters={filters}
          entityName={entityName}
          // Sorting
          sorting={sorting}
          onSortingChange={onSortingChange}
          // checkboxes ------------------------
          tableName={tableName}
          reloadTable={actions.setReload}
          selection={checkboxes.toJS()}
          onSelectionChange={(checkboxSelect) => {
            dispatch(helpersActions.setCheckbox({
              tableName,
              checkboxes: checkboxSelect,
            }));
          }}
          editComponentWidth={60}
          // ------------------------
          isModalOpen={isModalOpenDocViewer}
          disableHiddenColumn
          disableColumnOrder
          disableColumnWidth
          disableExport
          accessEdit={accessList.documents_put}
          accessDelete={accessList.documents_delete}
        />

        {isModalOpenDocViewer && <DocViewerModal />}

        {!isEmpty(deleteData.toJS()) && (
          <ConfirmModal
            buttonSendText="REMOVE"
            description="Are you sure you want to remove document?"
            pending={pendingDelete}
            handleSend={() => {
              dispatch(asyncActions.postDeleteDocumentsInAsync());
            }}
            handleCloseModal={() => dispatch(actions.setDeleteDocumentsDataIn({}))}
          />
        )}
      </CardContent>
    </Card>
  );
}

InputDocumentTable.displayName = 'ProposalDocuments';

InputDocumentTable.propTypes = {
  documentsData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  match: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  name: PropTypes.string,
  totalCount: PropTypes.number.isRequired,
  pending: PropTypes.bool.isRequired,
  pendingDelete: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  sorting: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  pageSize: PropTypes.number.isRequired,
  checkboxes: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  deleteData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  isModalOpenDocViewer: PropTypes.bool.isRequired,
  pendingPreviewFileById: PropTypes.bool.isRequired,
};

InputDocumentTable.defaultProps = {
  match: {},
  name: '',
};

function mapStateToProps(state) {
  return {
    documentsData: selectors.proposalDocumentsIn.proposalDocumentsInData(state),
    pendingDelete: selectors.proposalDocumentsIn.pendingDelete(state),
    deleteData: selectors.proposalDocumentsIn.deleteData(state),
    pending: selectors.proposalDocumentsIn.pending(state),
    // Paging
    totalCount: selectors.proposalDocumentsIn.totalCount(state),
    currentPage: selectors.proposalDocumentsIn.currentPage(state),
    pageSize: selectors.proposalDocumentsIn.pageSize(state),
    // Filtering
    filters: selectors.proposalDocumentsIn.filters(state),
    // Sorting
    sorting: selectors.proposalDocumentsIn.sorting(state),

    checkboxes: selectors.helpers.getCheckboxes(state, tableName),
    isModalOpenDocViewer: selectors.helpers.isModalOpenDocViewer(state),
    pendingPreviewFileById: selectors.helpers.pendingPreviewFileById(state),
  };
}

function mapDispatchToProps() {
  return {};
}

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.checkboxes, nextProps.checkboxes)
  && isEqual(prevProps.currentPage, nextProps.currentPage)
  && isEqual(prevProps.deleteData, nextProps.deleteData)
  && isEqual(prevProps.documentsData, nextProps.documentsData)
  && isEqual(prevProps.filters, nextProps.filters)
  && isEqual(prevProps.match, nextProps.match)
  && isEqual(prevProps.name, nextProps.name)
  && isEqual(prevProps.pageSize, nextProps.pageSize)
  && isEqual(prevProps.pending, nextProps.pending)
  && isEqual(prevProps.pendingDelete, nextProps.pendingDelete)
  && isEqual(prevProps.sorting, nextProps.sorting)
  && isEqual(prevProps.isModalOpenDocViewer, nextProps.isModalOpenDocViewer)
  && isEqual(prevProps.pendingPreviewFileById, nextProps.pendingPreviewFileById)
  && isEqual(prevProps.totalCount, nextProps.totalCount);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(memo(InputDocumentTable, areEqual));
