// Core
import React, { memo } from 'react';
import * as PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
// import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';

// lodash
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';

import {
  TableEditColumn,
} from '@devexpress/dx-react-grid-material-ui';

// ui
import {
  SvgIcon,
} from '@material-ui/core';
import {
  Delete, Edit as EditIcon, CloudDownload as CloudDownloadIcon,
  Reply as ReplyIcon, RemoveRedEye as RemoveRedEyeIcon, FileCopy,
  Done as DoneIcon, ArrowForward as ArrowForwardIcon, Description,
  ChromeReaderMode,
} from '@material-ui/icons';
import { ReactComponent as BellIcon } from '../../../_helpers/img/bell.svg';
import { ReactComponent as FileAltIcon } from '../../../_helpers/img/file-alt.svg';
import { ReactComponent as SignatureIcon } from '../../../_helpers/img/signature.svg';
import { ReactComponent as EnvelopeIcon } from '../../../_helpers/img/envelope.svg';
import { ReactComponent as TimesIcon } from '../../../_helpers/img/times.svg';

// actions
import tradeMarksActions from '../../../../engine/core/tradeMarks/proposal/action';
import tradeMarksAsyncAction from '../../../../engine/core/tradeMarks/proposal/saga/asyncAction';
import utilityModelsAsyncAction from '../../../../engine/core/utilityModels/saga/asyncAction';
import inventionsAsyncAction from '../../../../engine/core/inventions/saga/asyncAction';
import industrialAsyncAction from '../../../../engine/core/industrialDesigns/saga/asyncAction';
import utilityModelsActions from '../../../../engine/core/utilityModels/action';
import inventionsActions from '../../../../engine/core/inventions/action';
import industrialActions from '../../../../engine/core/industrialDesigns/action';
import helpersActions from '../../../../engine/core/helpers/action';
import depDocActions from '../../../../engine/core/departmentDocuments/action';
import pendingActions from '../../../../engine/core/pendingActions/action';
import helpersActionAsync from '../../../../engine/core/helpers/saga/asyncAction';
import pendingActionsAsync from '../../../../engine/core/pendingActions/saga/asyncAction';
import receivingActions from '../../../../engine/core/receiving/action';
import documentTemplatesActions from '../../../../engine/core/documentTemplates/action';
import documentTemplatesAsyncActions from '../../../../engine/core/documentTemplates/saga/asyncAction';
import companiesActions from '../../../../engine/core/company/profile/action';
import employeesActions from '../../../../engine/core/company/employees/action';
import paymentsActions from '../../../../engine/core/company/payments/action';
import ordersActions from '../../../../engine/core/company/orders/action';
import integrationActions from '../../../../engine/core/company/integration/action';
import applicantsActions from '../../../../engine/core/applicants/action';
import applicantsActionAsync from '../../../../engine/core/applicants/saga/asyncAction';
import clientsActions from '../../../../engine/core/company/clients/action';
import integrationActionAsync from '../../../../engine/core/company/integration/saga/asyncAction';
import fieldsSettingsActionAsync from '../../../../engine/core/fieldsSettings/saga/asyncAction';
import tmCalculationsActions from '../../../../engine/core/tradeMarks/calculations/action';
import mktuTemplatesActions from '../../../../engine/core/tradeMarks/mktuTemplates/action';
import tmMonitoringActions from '../../../../engine/core/tradeMarks/monitoring/action';
import madridCaseActions from '../../../../engine/core/tradeMarks/madridCase/action';
import madridCaseAsyncAction from '../../../../engine/core/tradeMarks/madridCase/saga/asyncAction';
import reboundsActions from '../../../../engine/core/tradeMarks/rebounds/action';
import searchesActions from '../../../../engine/core/tradeMarks/searches/action';
import jurisdictionsActions from '../../../../engine/core/jurisdictions/action';
import jurisdictionsActionAsync from '../../../../engine/core/jurisdictions/saga/asyncAction';
import pricesCategoryActions from '../../../../engine/core/prices/category/action';
import pricesCategoryActionAsync from '../../../../engine/core/prices/category/saga/asyncAction';
import notificationsActions from '../../../../engine/core/notifications/action';
import notificationsAsyncAction from '../../../../engine/core/notifications/saga/asyncAction';
import paymentReceiversActions from '../../../../engine/core/paymentReceivers/action';
import paymentReceiversAsyncActions from '../../../../engine/core/paymentReceivers/saga/asyncAction';
import documentTypesActions from '../../../../engine/core/documentTypes/action';
import documentTypesAsyncAction from '../../../../engine/core/documentTypes/saga/asyncAction';
import documentTypesCategoryActions from '../../../../engine/core/documentTypes/category/action';
import documentTypesCategoryAsyncAction from '../../../../engine/core/documentTypes/category/saga/asyncAction';
import tariffsActions from '../../../../engine/core/prices/tariffs/action';
import currenciesActions from '../../../../engine/core/currencies/currenciesList/action';
import currencyRatesActions from '../../../../engine/core/currencies/currencyRates/action';
import taxesActions from '../../../../engine/core/taxes/action';
import documentsOutActions from '../../../../engine/core/tradeMarks/proposal/documents/out/action';
import documentsInActions from '../../../../engine/core/tradeMarks/proposal/documents/input/action';
import loggingActions from '../../../../engine/core/logging/action';
import loggingActionAsync from '../../../../engine/core/logging/saga/asyncAction';
import chargesActionAsync from '../../../../engine/core/charges/saga/asyncAction';

// config
import selectors from '../../../../engine/config/selectors';
import { listTablesName } from '../../../../engine/config/listTablesName';
import accessList from '../../../../engine/config/accessList';

// routes
import { pageLinks } from '../../../../routes';

// parts
import IconMenuTable from '../../../../components/IconMenuTable/IconMenuTable';

function ActionsCell(props) {
  const {
    row,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const getTableName = useSelector(selectors.helpers.getTableName);
  const entityName = useSelector(selectors.proposalDocumentsOut.entityName);
  const entityId = useSelector(selectors.proposalDocumentsOut.entityId);
  const entityDocIn = useSelector(selectors.proposalDocumentsIn.entityDocIn);

  const permissions = useSelector(selectors.user.permissions);

  const checkAccess = (accessName) => includes(permissions, accessName);

  switch (getTableName) {
    case listTablesName.trademarks: {
      const DeleteComponent = (args) => {
        dispatch(helpersActions.setModal({ isModalOpen: true }));
        dispatch(tradeMarksActions.setDeleteProposal({ id: args.row.id }));
      };
      const EditComponent = (args) => {
        dispatch(push(pageLinks.tradeMarksRoutes.proposal.edit(args.row.id)));
      };
      const AddDocument = (args) => {
        dispatch(helpersActions.setLastObject({ entityId: args.row.id, entityName: 'trademarks' }));
        dispatch(depDocActions.setDepartmentDocumentEntityList({
          trademarks: [args.row],
          ...args.row.clients ? { clients: [args.row.clients] } : {},
        }));
        dispatch(push(pageLinks.documents.departmentDocuments.new));
      };
      const AddNotification = () => {
        dispatch(pendingActions.setPendingActionEntityList({ trademarks: row }));
        dispatch(pendingActions.setIsModalOpen(true));
      };
      const CopyComponent = (args) => {
        dispatch(tradeMarksAsyncAction.putProposalCloneAsync({ id: args.row.id }));
      };
      const CreateMadridCase = (args) => {
        dispatch(madridCaseAsyncAction.postMadridCaseAsync({
          status: 1,
          relations: [args.row.id],
        }));
      };
      const GoToRegister = (args) => {
        window.open(args.row.linkToUkrPatent);
      };
      const menuItems = [
        checkAccess(accessList.trademarks_get) && {
          title: t('Edit'),
          icon: <EditIcon fontSize="small" />,
          handleClick: (args) => EditComponent(args),
        },
        checkAccess(accessList.documents_post) && {
          title: t('Document'),
          icon: <SvgIcon fontSize="small"><FileAltIcon /></SvgIcon>,
          handleClick: (args) => AddDocument(args),
        },
        checkAccess(accessList.pendingActions_post) && {
          title: t('Notification'),
          icon: <SvgIcon fontSize="small"><BellIcon /></SvgIcon>,
          handleClick: (args) => AddNotification(args),
        },
        checkAccess(accessList.trademarks_clone_post) && {
          title: t('Make a copy'),
          icon: <FileCopy fontSize="small" />,
          handleClick: (args) => CopyComponent(args),
        },
        checkAccess(accessList.madrid_case_post) && {
          title: t('Madrid application'),
          icon: <ChromeReaderMode fontSize="small" />,
          handleClick: (args) => CreateMadridCase(args),
        },
        !isEmpty(props.row.linkToUkrPatent) && {
          title: t('Register'),
          icon: <ReplyIcon fontSize="small" />,
          handleClick: (args) => GoToRegister(args),
        },
        checkAccess(accessList.trademarks_delete) && {
          title: t('Delete'),
          icon: <Delete fontSize="small" />,
          handleClick: (args) => DeleteComponent(args),
        },
      ];
      return (
        <TableEditColumn.Cell style={{ padding: 0 }}>
          <IconMenuTable
            menuItems={menuItems}
            tableName={getTableName}
            actionsProps={props}
          />
        </TableEditColumn.Cell>
      );
    }
    case listTablesName.utility: {
      const DeleteComponent = (args) => {
        dispatch(helpersActions.setModal({
          isModalOpen: true,
        }));
        dispatch(utilityModelsActions.setDeleteProposal({
          id: args.row.id,
        }));
      };
      const EditComponent = (args) => {
        dispatch(push(pageLinks.utilityModels.edit(args.row.id)));
      };
      const AddDocument = (args) => {
        dispatch(helpersActions.setLastObject({ entityId: args.row.id, entityName: 'utility' }));
        dispatch(depDocActions.setDepartmentDocumentEntityList({
          utility: [args.row],
          ...args.row.clients ? { clients: [args.row.clients] } : {},
        }));
        dispatch(push(pageLinks.documents.departmentDocuments.new));
      };
      const AddNotification = (args) => {
        dispatch(pendingActions.setPendingActionEntityList({
          utility: args.row,
        }));
        dispatch(pendingActions.setIsModalOpen(true));
      };
      const CopyComponent = (args) => {
        dispatch(utilityModelsAsyncAction.putProposalCloneAsync({ id: args.row.id }));
      };
      const menuItems = [
        checkAccess(accessList.utility_get) && {
          title: t('Edit'),
          icon: <EditIcon fontSize="small" />,
          handleClick: (args) => EditComponent(args),
        },
        checkAccess(accessList.documents_post) && {
          title: t('Document'),
          icon: <SvgIcon fontSize="small"><FileAltIcon /></SvgIcon>,
          handleClick: (args) => AddDocument(args),
        },
        checkAccess(accessList.pendingActions_post) && {
          title: t('Notification'),
          icon: <SvgIcon fontSize="small"><BellIcon /></SvgIcon>,
          handleClick: (args) => AddNotification(args),
        },
        checkAccess(accessList.utility_clone_put) && {
          title: t('Make a copy'),
          icon: <FileCopy fontSize="small" />,
          handleClick: (args) => CopyComponent(args),
        },
        checkAccess(accessList.utility_delete) && {
          title: t('Delete'),
          icon: <Delete fontSize="small" />,
          handleClick: (args) => DeleteComponent(args),
        },
      ];
      return (
        <TableEditColumn.Cell style={{ padding: 0 }}>
          <IconMenuTable
            menuItems={menuItems}
            tableName={getTableName}
            actionsProps={props}
          />
        </TableEditColumn.Cell>
      );
    }
    case listTablesName.industrial: {
      const DeleteComponent = (args) => {
        dispatch(helpersActions.setModal({ isModalOpen: true }));
        dispatch(industrialActions.setDeleteProposal({ id: args.row.id }));
      };
      const EditComponent = (args) => {
        dispatch(push(pageLinks.industrialDesigns.edit(args.row.id)));
      };
      const AddDocument = (args) => {
        dispatch(helpersActions.setLastObject({ entityId: args.row.id, entityName: 'industrial' }));
        dispatch(depDocActions.setDepartmentDocumentEntityList({
          industrial: [args.row],
          ...args.row.clients ? { clients: [args.row.clients] } : {},
        }));
        dispatch(push(pageLinks.documents.departmentDocuments.new));
      };
      const AddNotification = (args) => {
        dispatch(pendingActions.setPendingActionEntityList({
          industrial: args.row,
        }));
        dispatch(pendingActions.setIsModalOpen(true));
      };
      const CopyComponent = (args) => {
        dispatch(industrialAsyncAction.putProposalCloneAsync({ id: args.row.id }));
      };
      const menuItems = [
        checkAccess(accessList.industrial_get) && {
          title: t('Edit'),
          icon: <EditIcon fontSize="small" />,
          handleClick: (args) => EditComponent(args),
        },
        checkAccess(accessList.documents_post) && {
          title: t('Document'),
          icon: <SvgIcon fontSize="small"><FileAltIcon /></SvgIcon>,
          handleClick: (args) => AddDocument(args),
        },
        checkAccess(accessList.pendingActions_post) && {
          title: t('Notification'),
          icon: <SvgIcon fontSize="small"><BellIcon /></SvgIcon>,
          handleClick: (args) => AddNotification(args),
        },
        checkAccess(accessList.industrial_clone_post) && {
          title: t('Make a copy'),
          icon: <FileCopy fontSize="small" />,
          handleClick: (args) => CopyComponent(args),
        },
        checkAccess(accessList.industrial_delete) && {
          title: t('Delete'),
          icon: <Delete fontSize="small" />,
          handleClick: (args) => DeleteComponent(args),
        },
      ];
      return (
        <TableEditColumn.Cell style={{ padding: 0 }}>
          <IconMenuTable
            menuItems={menuItems}
            tableName={getTableName}
            actionsProps={props}
          />
        </TableEditColumn.Cell>
      );
    }
    case listTablesName.inventions: {
      const DeleteComponent = (args) => {
        dispatch(helpersActions.setModal({ isModalOpen: true }));
        dispatch(inventionsActions.setDeleteProposal({ id: args.row.id }));
      };
      const EditComponent = (args) => {
        dispatch(push(pageLinks.inventions.edit(args.row.id)));
      };
      const AddDocument = (args) => {
        dispatch(helpersActions.setLastObject({ entityId: args.row.id, entityName: 'inventions' }));
        dispatch(depDocActions.setDepartmentDocumentEntityList({
          inventions: [args.row],
          ...args.row.clients ? { clients: [args.row.clients] } : {},
        }));
        dispatch(push(pageLinks.documents.departmentDocuments.new));
      };
      const AddNotification = (args) => {
        dispatch(pendingActions.setPendingActionEntityList({
          inventions: args.row,
        }));
        dispatch(pendingActions.setIsModalOpen(true));
      };
      const CopyComponent = (args) => {
        dispatch(inventionsAsyncAction.putProposalCloneAsync({ id: args.row.id }));
      };
      const menuItems = [
        checkAccess(accessList.inventions_get) && {
          title: t('Edit'),
          icon: <EditIcon fontSize="small" />,
          handleClick: (args) => EditComponent(args),
        },
        checkAccess(accessList.documents_post) && {
          title: t('Document'),
          icon: <SvgIcon fontSize="small"><FileAltIcon /></SvgIcon>,
          handleClick: (args) => AddDocument(args),
        },
        checkAccess(accessList.pendingActions_post) && {
          title: t('Notification'),
          icon: <SvgIcon fontSize="small"><BellIcon /></SvgIcon>,
          handleClick: (args) => AddNotification(args),
        },
        checkAccess(accessList.inventions_clone_post) && {
          title: t('Make a copy'),
          icon: <FileCopy fontSize="small" />,
          handleClick: (args) => CopyComponent(args),
        },
        checkAccess(accessList.inventions_delete) && {
          title: t('Delete'),
          icon: <Delete fontSize="small" />,
          handleClick: (args) => DeleteComponent(args),
        },
      ];
      return (
        <TableEditColumn.Cell style={{ padding: 0 }}>
          <IconMenuTable
            menuItems={menuItems}
            tableName={getTableName}
            actionsProps={props}
          />
        </TableEditColumn.Cell>
      );
    }
    case listTablesName.documents: {
      const EditComponent = (args) => {
        dispatch(push(pageLinks.documents.departmentDocuments.edit(args.row.id)));
      };
      const DeleteComponent = (args) => {
        dispatch(helpersActions.setModal({ isModalOpen: true }));
        dispatch(depDocActions.setDeleteDepartmentDocument({ id: args.row.id }));
      };
      const ReplyComponent = (args) => {
        dispatch(helpersActions.setPredefinedInputs({
          entity: 'documents',
          values: { responseDocument: args.row },
        }));
        dispatch(push(pageLinks.documents.departmentDocuments.new));
        dispatch(depDocActions.setDepartmentDocumentEntityList({
          ...args.row.trademarks ? { trademarks: args.row.trademarks } : {},
          ...args.row.utility ? { utility: args.row.utility } : {},
          ...args.row.inventions ? { inventions: args.row.inventions } : {},
          ...args.row.industrial ? { industrial: args.row.industrial } : {},
        }));
      };
      const ReviewDocument = (args) => {
        if (args.row?.file?.fileId) {
          dispatch(helpersActionAsync.getPreviewFileByIdAsync(args.row.file.fileId));
        }
      };

      const handleSend = (getRow) => {
        if (isEmpty(getRow.outDocs)) {
          dispatch(depDocActions.setDocumentIdSend(getRow.id));
          dispatch(helpersActions.setStartSend(true));
        }
      };
      const handleOpenModal = (getRow) => {
        dispatch(depDocActions.setFileSign([{
          id: getRow.id,
          files: [
            getRow?.file?.fileId,
            ...!isEmpty(getRow?.additionalFiles) ? map(getRow.additionalFiles, 'fileId') : [],
          ],
        }]));
        dispatch(helpersActions.setSignIsModalOpen(true));
      };
      const SignatureComponent = (args) => {
        // eslint-disable-next-line
        !isEmpty(args.row.signs) ? handleSend(args.row) : handleOpenModal(args.row);
      };
      const menuItems = [
        checkAccess(accessList.documents_get) && {
          title: t('Edit'),
          icon: <EditIcon fontSize="small" />,
          handleClick: (args) => EditComponent(args),
        },
        checkAccess(accessList.documents_post) && {
          title: t('Reply'),
          icon: <ReplyIcon fontSize="small" />,
          checkParams: { key: 'type', value: 'in' },
          handleClick: (args) => ReplyComponent(args),
        },
        checkAccess(accessList.entity_sign_post) && {
          titleSend: t('Send'),
          title: t('Sign'),
          checkParams: { key: 'type', value: 'out' },
          iconSend: <SvgIcon><EnvelopeIcon fontSize="small" /></SvgIcon>,
          icon: <SvgIcon><SignatureIcon fontSize="small" /></SvgIcon>,
          handleClick: (args) => SignatureComponent(args),
        },
        {
          title: t('Review'),
          icon: <RemoveRedEyeIcon fontSize="small" />,
          handleClick: (args) => ReviewDocument(args),
          review: true,
        },
        checkAccess(accessList.files_download_get) && {
          title: t('Download'),
          icon: <CloudDownloadIcon fontSize="small" />,
          download: true,
        },
        checkAccess(accessList.documents_delete) && {
          title: t('Delete'),
          icon: <Delete fontSize="small" />,
          handleClick: (args) => DeleteComponent(args),
        },
      ];
      return (
        <TableEditColumn.Cell style={{ padding: 0 }}>
          <IconMenuTable
            menuItems={menuItems}
            tableName={getTableName}
            actionsProps={props}
          />
        </TableEditColumn.Cell>
      );
    }
    case listTablesName.outdocs: {
      const SendingComponent = (args) => {
        dispatch(depDocActions.setDocumentIdSend(args.row.id));
        dispatch(helpersActions.setStartSend(true));
      };
      const menuItems = [
        checkAccess(accessList.outDocs_download_get) && {
          title: t('Download'),
          icon: <CloudDownloadIcon fontSize="small" />,
          download: true,
          outDocs: true,
        },
        checkAccess(accessList.outDocs_send) && {
          title: t('Send'),
          icon: <SvgIcon><EnvelopeIcon fontSize="small" /></SvgIcon>,
          handleClick: (args) => SendingComponent(args),
        },
      ];
      return (
        <TableEditColumn.Cell style={{ padding: 0 }}>
          <IconMenuTable
            menuItems={menuItems}
            tableName={getTableName}
            actionsProps={props}
          />
        </TableEditColumn.Cell>
      );
    }
    case listTablesName.trademarksDocumentsOut:
    case listTablesName.trademarksDocumentsIn: {
      if (row.type === 'out') {
        const DeleteComponent = (args) => {
          dispatch(helpersActions.setModal({
            isModalOpen: true,
          }));
          dispatch(documentsOutActions.setDeleteDocumentsDataOut({
            id: Number(entityId),
            params: {
              ids: [args.row.id],
            },
          }));
        };
        const EditComponent = (args) => {
          dispatch(helpersActions.setLastObject({ entityId, entityName }));
          dispatch(push(pageLinks.documents.departmentDocuments.edit(args.row.id)));
        };
        const ReviewDocument = (args) => {
          if (args.row?.file?.fileId) {
            dispatch(helpersActionAsync.getPreviewFileByIdAsync(args.row.file.fileId));
          }
        };
        const handleSend = (getRow) => {
          if (isEmpty(getRow.outDocs)) {
            dispatch(depDocActions.setDocumentIdSend(getRow.id));
            dispatch(helpersActions.setStartSend(true));
          }
        };
        const handleOpenModal = (getRow) => {
          dispatch(depDocActions.setFileSign([{
            id: getRow.id,
            files: [
              getRow?.file?.fileId,
              ...!isEmpty(getRow?.additionalFiles) ? map(getRow.additionalFiles, 'fileId') : [],
            ],
          }]));
          dispatch(helpersActions.setSignIsModalOpen(true));
        };
        const SignatureComponent = (args) => {
          // eslint-disable-next-line
          !isEmpty(args.row.signs) ? handleSend(args.row) : handleOpenModal(args.row);
        };
        const menuItems = [
          checkAccess(accessList.files_download_get) && {
            title: t('Download'),
            icon: <CloudDownloadIcon fontSize="small" />,
            download: true,
          },
          {
            title: t('Review'),
            icon: <RemoveRedEyeIcon fontSize="small" />,
            handleClick: (args) => ReviewDocument(args),
            review: true,
          },
          checkAccess(accessList.documents_get) && {
            title: t('Edit'),
            icon: <EditIcon fontSize="small" />,
            handleClick: (args) => EditComponent(args),
          },
          checkAccess(accessList.entity_sign_post) && {
            titleSend: t('Send'),
            title: t('Sign'),
            iconSend: <SvgIcon><EnvelopeIcon fontSize="small" /></SvgIcon>,
            icon: <SvgIcon><SignatureIcon fontSize="small" /></SvgIcon>,
            handleClick: (args) => SignatureComponent(args),
          },
          checkAccess(accessList.documents_delete) && {
            title: t('Delete'),
            icon: <SvgIcon><TimesIcon /></SvgIcon>,
            handleClick: (args) => DeleteComponent(args),
          },
        ];
        return (
          <TableEditColumn.Cell style={{ padding: 0 }}>
            <IconMenuTable
              menuItems={menuItems}
              tableName={getTableName}
              actionsProps={props}
            />
          </TableEditColumn.Cell>
        );
      }
      const DeleteComponent = (args) => {
        dispatch(helpersActions.setModal({
          isModalOpen: true,
        }));
        dispatch(documentsInActions.setDeleteDocumentsDataOut({
          id: Number(entityId),
          params: {
            ids: [args.row.id],
          },
        }));
      };
      const EditComponent = (args) => {
        dispatch(helpersActions.setLastObject({ entityId, entityName }));
        dispatch(push(pageLinks.documents.departmentDocuments.edit(args.row.id)));
      };
      const ReviewDocument = (args) => {
        if (args.row?.file?.fileId) {
          dispatch(helpersActionAsync.getPreviewFileByIdAsync(args.row.file.fileId));
        }
      };
      const ReplyComponent = (args) => {
        dispatch(helpersActions.setPredefinedInputs({
          entity: 'documents',
          values: {
            responseDocument: args.row,
          },
        }));
        dispatch(helpersActions.setLastObject({ entityId, entityName }));
        dispatch(depDocActions.setDepartmentDocumentEntityList({
          [entityName]: [entityDocIn],
        }));
        dispatch(push(pageLinks.documents.departmentDocuments.new));
      };
      const menuItems = [
        checkAccess(accessList.files_download_get) && {
          title: t('Download'),
          icon: <CloudDownloadIcon fontSize="small" />,
          download: true,
        },
        {
          title: t('Review'),
          icon: <RemoveRedEyeIcon fontSize="small" />,
          handleClick: (args) => ReviewDocument(args),
          review: true,
        },
        checkAccess(accessList.documents_get) && {
          title: t('Edit'),
          icon: <EditIcon fontSize="small" />,
          handleClick: (args) => EditComponent(args),
        },
        checkAccess(accessList.documents_post) && {
          title: t('Reply'),
          icon: <ReplyIcon fontSize="small" />,
          handleClick: (args) => ReplyComponent(args),
        },
        checkAccess(accessList.documents_delete) && {
          title: t('Delete'),
          icon: <SvgIcon><TimesIcon /></SvgIcon>,
          handleClick: (args) => DeleteComponent(args),
        },
      ];
      return (
        <TableEditColumn.Cell style={{ padding: 0 }}>
          <IconMenuTable
            menuItems={menuItems}
            tableName={getTableName}
            actionsProps={props}
          />
        </TableEditColumn.Cell>
      );
    }
    case listTablesName.logging: {
      const HandleReview = (args) => {
        if (args.row) {
          dispatch(loggingActions.setIsModalOpen(true));
          dispatch(loggingActionAsync.getLoggingByIdAsync(args.row.id));
        }
      };
      const handleRevert = (args) => {
        dispatch(loggingActionAsync.putLoggingByIdAsync(args.row.id));
      };
      const menuItems = [
        checkAccess(accessList.logging_get) && {
          title: t('Review'),
          icon: <RemoveRedEyeIcon fontSize="small" />,
          handleClick: (args) => HandleReview(args),
          review: true,
        },
        checkAccess(accessList.logging_revert_put) && {
          title: t('Revert'),
          icon: <RemoveRedEyeIcon fontSize="small" />,
          handleClick: (args) => handleRevert(args),
          revert: true,
        },
      ];
      return (
        <TableEditColumn.Cell style={{ padding: 0 }}>
          <IconMenuTable
            menuItems={menuItems}
            tableName={getTableName}
            actionsProps={props}
          />
        </TableEditColumn.Cell>
      );
    }
    case 'documentsModal': {
      const menuItems = [
        checkAccess(accessList.files_download_get) && {
          title: t('Download'),
          icon: <CloudDownloadIcon fontSize="small" />,
          download: true,
        },
      ];
      return (
        <TableEditColumn.Cell style={{ padding: 0 }}>
          <IconMenuTable
            menuItems={menuItems}
            tableName={getTableName}
            actionsProps={props}
          />
        </TableEditColumn.Cell>
      );
    }
    case 'CostsOrder':
    case 'DocumentsOrder': {
      if (row.type === 'out' || row.type === 'in') {
        const ReviewDocument = (args) => {
          if (args.row?.file?.fileId) {
            dispatch(helpersActionAsync.getPreviewFileByIdAsync(args.row.file.fileId));
          }
        };
        const menuItems = [
          {
            title: t('Review'),
            icon: <RemoveRedEyeIcon fontSize="small" />,
            handleClick: (args) => ReviewDocument(args),
            review: true,
          },
          checkAccess(accessList.files_download_get) && {
            title: t('Download'),
            icon: <CloudDownloadIcon fontSize="small" />,
            download: true,
          },
        ];
        return (
          <TableEditColumn.Cell style={{ padding: 0 }}>
            <IconMenuTable
              menuItems={menuItems}
              tableName={getTableName}
              actionsProps={props}
            />
          </TableEditColumn.Cell>
        );
      }
      return <TableEditColumn.Cell {...props} style={{ padding: 0 }} />;
    }
    case listTablesName.pendingActions: {
      const DeleteComponent = (args) => {
        dispatch(helpersActions.setModal({
          isModalOpen: true,
        }));
        dispatch(pendingActions.setDeletePendingAction({
          id: args.row.id,
        }));
      };

      const EditComponent = (args) => {
        dispatch(pendingActions.setIsModalOpen(true));
        dispatch(pendingActionsAsync.getPendingActionByIdAsync(args.row.id));
      };

      const ToCaseComponent = (args) => {
        if (args.row.inventions?.id) {
          dispatch(push(pageLinks.inventions.edit(args.row.inventions.id)));
        }
        if (args.row.industrial?.id) {
          dispatch(push(pageLinks.industrialDesigns.edit(args.row.industrial.id)));
        }
        if (args.row.trademarks?.id) {
          dispatch(push(pageLinks.tradeMarksRoutes.proposal.edit(args.row.trademarks.id)));
        }
        if (args.row.utility?.id) {
          dispatch(push(pageLinks.utilityModels.edit(args.row.utility.id)));
        }
      };

      const DoneComponent = (args) => {
        dispatch(pendingActionsAsync.patchPendingActionByIdAsync({
          id: args.row.id,
          status: 2,
        }));
      };
      const menuItems = [
        checkAccess(accessList.pendingActions_get) && {
          title: t('Edit'),
          icon: <EditIcon fontSize="small" />,
          handleClick: (args) => EditComponent(args),
        },
        checkAccess(accessList.trademarks_get) && {
          title: t('Get to case'),
          icon: <ArrowForwardIcon fontSize="small" />,
          handleClick: (args) => ToCaseComponent(args),
        },
        checkAccess(accessList.pendingActions_put) && {
          title: t('the Done'),
          icon: <DoneIcon fontSize="small" />,
          handleClick: (args) => DoneComponent(args),
        },
        checkAccess(accessList.pendingActions_delete) && {
          title: t('Delete'),
          icon: <Delete fontSize="small" />,
          handleClick: (args) => DeleteComponent(args),
        },
      ];
      return (
        <TableEditColumn.Cell style={{ padding: 0 }}>
          <IconMenuTable
            menuItems={menuItems}
            tableName={getTableName}
            actionsProps={props}
          />
        </TableEditColumn.Cell>
      );
    }
    case listTablesName.notificationsTasks: {
      const DeleteComponent = (args) => {
        dispatch(helpersActions.setModal({ isModalOpen: true }));
        dispatch(pendingActions.setDeletePendingAction({ id: args.row.id }));
      };
      const EditComponent = (args) => {
        dispatch(pendingActions.setIsModalOpen(true));
        dispatch(pendingActionsAsync.getPendingActionByIdAsync(args.row.id));
      };
      const menuItems = [
        checkAccess(accessList.pendingActions_get) && {
          title: t('Edit'),
          icon: <EditIcon fontSize="small" />,
          handleClick: (args) => EditComponent(args),
        },
        checkAccess(accessList.pendingActions_delete) && {
          title: t('Delete'),
          icon: <Delete fontSize="small" />,
          handleClick: (args) => DeleteComponent(args),
        },
      ];
      return (
        <TableEditColumn.Cell style={{ padding: 0 }}>
          <IconMenuTable
            menuItems={menuItems}
            tableName={getTableName}
            actionsProps={props}
          />
        </TableEditColumn.Cell>
      );
    }
    case listTablesName.indocs: {
      const DeleteComponent = (args) => {
        dispatch(helpersActions.setModal({ isModalOpen: true }));
        dispatch(receivingActions.setDeleteReceiving({
          id: {
            /* eslint quote-props: ["error", "consistent"] */
            'ids': [args.row.id],
          },
        }));
      };
      const ReviewDocument = (args) => {
        if (args.row?.files?.[0]) {
          dispatch(helpersActionAsync.getPreviewFileByIdAsync(args.row.files?.[0]));
        }
      };
      const menuItems = [
        checkAccess(accessList.inDocs_download_get) && {
          title: t('Download'),
          icon: <CloudDownloadIcon fontSize="small" />,
          download: true,
          inDocs: true,
        },
        {
          title: t('Review'),
          icon: <RemoveRedEyeIcon fontSize="small" />,
          handleClick: (args) => ReviewDocument(args),
          review: true,
        },
        checkAccess(accessList.inDocs_delete) && {
          title: t('Delete'),
          icon: <Delete fontSize="small" />,
          handleClick: (args) => DeleteComponent(args),
        },
      ];
      return (
        <TableEditColumn.Cell style={{ padding: 0 }}>
          <IconMenuTable
            menuItems={menuItems}
            tableName={getTableName}
            actionsProps={props}
          />
        </TableEditColumn.Cell>
      );
    }
    case listTablesName.documentsTemplate: {
      const EditComponent = (args) => {
        dispatch(documentTemplatesAsyncActions.getDocumentTemplatesByIdAsync(args.row.id));
      };
      const DeleteComponent = (args) => {
        dispatch(helpersActions.setModal({
          isModalOpen: true,
        }));
        dispatch(documentTemplatesActions.setDeleteDocumentTemplate({
          id: args.row.id,
        }));
      };
      const menuItems = [
        checkAccess(accessList.documentsTemplate_get) && {
          title: t('Edit'),
          icon: <EditIcon fontSize="small" />,
          handleClick: (args) => EditComponent(args),
        },
        checkAccess(accessList.files_download_get) && {
          title: t('Download'),
          icon: <CloudDownloadIcon fontSize="small" />,
          download: true,
        },
        checkAccess(accessList.documentsTemplate_delete) && {
          title: t('Delete'),
          icon: <Delete fontSize="small" />,
          handleClick: (args) => DeleteComponent(args),
        },
      ];
      return (
        <TableEditColumn.Cell style={{ padding: 0 }}>
          <IconMenuTable
            menuItems={menuItems}
            tableName={getTableName}
            actionsProps={props}
          />
        </TableEditColumn.Cell>
      );
    }
    case listTablesName.companies: {
      const DeleteComponent = (args) => {
        dispatch(helpersActions.setModal({ isModalOpen: true }));
        dispatch(companiesActions.setDeleteCompany({ id: args.row.id }));
      };
      const EditComponent = (args) => {
        dispatch(push(pageLinks.company.companies.edit(args.row.id)));
      };
      const menuItems = [
        checkAccess(accessList.company_get) && {
          title: t('Edit'),
          icon: <EditIcon fontSize="small" />,
          handleClick: (args) => EditComponent(args),
        },
        checkAccess(accessList.company_delete) && {
          title: t('Delete'),
          icon: <Delete fontSize="small" />,
          handleClick: (args) => DeleteComponent(args),
        },
      ];
      return (
        <TableEditColumn.Cell style={{ padding: 0 }}>
          <IconMenuTable
            menuItems={menuItems}
            tableName={getTableName}
            actionsProps={props}
          />
        </TableEditColumn.Cell>
      );
    }
    case listTablesName.employees: {
      const DeleteComponent = (args) => {
        dispatch(helpersActions.setModal({ isModalOpen: true }));
        dispatch(employeesActions.setDeleteEmployee({ id: args.row.id }));
      };
      const EditComponent = (args) => {
        dispatch(push(pageLinks.company.employees.edit(args.row.id)));
      };
      const menuItems = [
        checkAccess(accessList.employees_get) && {
          title: t('Edit'),
          icon: <EditIcon fontSize="small" />,
          handleClick: (args) => EditComponent(args),
        },
        checkAccess(accessList.employees_delete) && {
          title: t('Delete'),
          icon: <Delete fontSize="small" />,
          handleClick: (args) => DeleteComponent(args),
        },
      ];
      return (
        <TableEditColumn.Cell style={{ padding: 0 }}>
          <IconMenuTable
            menuItems={menuItems}
            tableName={getTableName}
            actionsProps={props}
          />
        </TableEditColumn.Cell>
      );
    }
    case listTablesName.clients: {
      const DeleteComponent = (args) => {
        dispatch(helpersActions.setModal({
          isModalOpen: true,
        }));
        dispatch(clientsActions.setDeleteClient({
          id: args.row.id,
        }));
      };
      const EditComponent = (args) => {
        dispatch(push(pageLinks.company.clients.edit(args.row.id)));
      };
      const menuItems = [
        checkAccess(accessList.clients_get) && {
          title: t('Edit'),
          icon: <EditIcon fontSize="small" />,
          handleClick: (args) => EditComponent(args),
        },
        checkAccess(accessList.clients_delete) && {
          title: t('Delete'),
          icon: <Delete fontSize="small" />,
          handleClick: (args) => DeleteComponent(args),
        },
      ];
      return (
        <TableEditColumn.Cell style={{ padding: 0 }}>
          <IconMenuTable
            menuItems={menuItems}
            tableName={getTableName}
            actionsProps={props}
          />
        </TableEditColumn.Cell>
      );
    }
    case listTablesName.applicants: {
      const DeleteComponent = (args) => {
        dispatch(helpersActions.setModal({
          isModalOpen: true,
        }));
        dispatch(applicantsActions.setDeleteApplicant({ id: args.row.id }));
      };

      const EditComponent = (args) => {
        dispatch(applicantsActionAsync.getApplicantByIdAsync({ id: args.row.id, isModal: true }));
      };
      const menuItems = [
        checkAccess(accessList.applicants_get) && {
          title: t('Edit'),
          icon: <EditIcon fontSize="small" />,
          handleClick: (args) => EditComponent(args),
        },
        checkAccess(accessList.applicants_delete) && {
          title: t('Delete'),
          icon: <Delete fontSize="small" />,
          handleClick: (args) => DeleteComponent(args),
        },
      ];
      return (
        <TableEditColumn.Cell style={{ padding: 0 }}>
          <IconMenuTable
            menuItems={menuItems}
            tableName={getTableName}
            actionsProps={props}
          />
        </TableEditColumn.Cell>
      );
    }
    case listTablesName.integrations: {
      const DeleteComponent = (args) => {
        dispatch(helpersActions.setModal({
          isModalOpen: true,
        }));
        dispatch(integrationActions.setDeleteIntegration({ id: args.row.id }));
      };
      const EditComponent = (args) => {
        dispatch(fieldsSettingsActionAsync.getFieldsSettingsAsync({
          entity: 'AuthorityIntegration',
        }));
        dispatch(integrationActions.setIsModalOpen(true));
        dispatch(integrationActionAsync.getIntegrationByIdAsync(args.row.id));
      };
      const menuItems = [
        checkAccess(accessList.authorityIntegration_get) && {
          title: t('Edit'),
          icon: <EditIcon fontSize="small" />,
          handleClick: (args) => EditComponent(args),
        },
        checkAccess(accessList.authorityIntegration_delete) && {
          title: t('Delete'),
          icon: <Delete fontSize="small" />,
          handleClick: (args) => DeleteComponent(args),
        },
      ];
      return (
        <TableEditColumn.Cell style={{ padding: 0 }}>
          <IconMenuTable
            menuItems={menuItems}
            tableName={getTableName}
            actionsProps={props}
          />
        </TableEditColumn.Cell>
      );
    }
    case listTablesName.orders: {
      if (row.charges || row.billSumTaxes || row.orderNum) {
        const DeleteComponent = (args) => {
          dispatch(helpersActions.setModal({
            isModalOpen: true,
          }));
          dispatch(ordersActions.setDeleteOrder({
            id: args.row.id,
          }));
        };
        const EditComponent = (args) => {
          dispatch(push(pageLinks.company.orders.edit(args.row.id)));
        };
        const menuItems = [
          checkAccess(accessList.orders_edit) && {
            title: t('Edit'),
            icon: <EditIcon fontSize="small" />,
            handleClick: (args) => EditComponent(args),
          },
          checkAccess(accessList.orders_delete) && {
            title: t('Delete'),
            icon: <Delete fontSize="small" />,
            handleClick: (args) => DeleteComponent(args),
          },
        ];
        return (
          <TableEditColumn.Cell style={{ padding: 0 }}>
            <IconMenuTable
              menuItems={menuItems}
              tableName={getTableName}
              actionsProps={props}
            />
          </TableEditColumn.Cell>
        );
      }
      if (row.costId && row.type === 2) {
        const DetailsComponent = (args) => {
          dispatch(chargesActionAsync.postPaymentDetailsAsync({
            costs: args.row.costId,
            charge: args.row.id,
            type: 'pdf',
            clients: args.row.clients,
          }));
        };
        const menuItems = [
          {
            title: t('Details'),
            icon: <Description fontSize="small" />,
            handleClick: (args) => DetailsComponent(args),
          },
        ];
        return (
          <TableEditColumn.Cell style={{ padding: 0 }}>
            <IconMenuTable
              menuItems={menuItems}
              tableName={getTableName}
              actionsProps={props}
            />
          </TableEditColumn.Cell>
        );
      }
      return <TableEditColumn.Cell {...props} style={{ padding: 0 }} />;
    }
    case listTablesName.payments: {
      const DeleteComponent = (args) => {
        dispatch(helpersActions.setModal({ isModalOpen: true }));
        dispatch(paymentsActions.setDeletePayment({ id: args.row.id }));
      };
      const EditComponent = (args) => {
        dispatch(push(pageLinks.company.payments.edit(args.row.id)));
      };
      const menuItems = [
        checkAccess(accessList.payments_get) && {
          title: t('Edit'),
          icon: <EditIcon fontSize="small" />,
          handleClick: (args) => EditComponent(args),
        },
        checkAccess(accessList.payments_delete) && {
          title: t('Delete'),
          icon: <Delete fontSize="small" />,
          handleClick: (args) => DeleteComponent(args),
        },
      ];
      return (
        <TableEditColumn.Cell style={{ padding: 0 }}>
          <IconMenuTable
            menuItems={menuItems}
            tableName={getTableName}
            actionsProps={props}
          />
        </TableEditColumn.Cell>
      );
    }
    case listTablesName.trademarksEstimates: {
      const DeleteComponent = (args) => {
        dispatch(helpersActions.setModal({
          isModalOpen: true,
        }));
        dispatch(tmCalculationsActions.setDeleteCalculation({
          id: args.row.id,
        }));
      };
      const menuItems = [
        checkAccess(accessList.estimates_delete) && {
          title: t('Delete'),
          icon: <Delete fontSize="small" />,
          handleClick: (args) => DeleteComponent(args),
        },
      ];
      return (
        <TableEditColumn.Cell style={{ padding: 0 }}>
          <IconMenuTable
            menuItems={menuItems}
            tableName={getTableName}
            actionsProps={props}
          />
        </TableEditColumn.Cell>
      );
    }
    case listTablesName.trademarksMktuTemplate: {
      const DeleteComponent = (args) => {
        dispatch(helpersActions.setModal({
          isModalOpen: true,
        }));
        dispatch(mktuTemplatesActions.setDeleteMktuTemplate({
          id: args.row.id,
        }));
      };
      const EditComponent = (args) => {
        dispatch(push(pageLinks.tradeMarksRoutes.mktuTemplates.edit(args.row.id)));
      };
      const menuItems = [
        checkAccess(accessList.mktuTemplate_get) && {
          title: t('Edit'),
          icon: <EditIcon fontSize="small" />,
          handleClick: (args) => EditComponent(args),
        },
        checkAccess(accessList.mktuTemplate_delete) && {
          title: t('Delete'),
          icon: <Delete fontSize="small" />,
          handleClick: (args) => DeleteComponent(args),
        },
      ];
      return (
        <TableEditColumn.Cell style={{ padding: 0 }}>
          <IconMenuTable
            menuItems={menuItems}
            tableName={getTableName}
            actionsProps={props}
          />
        </TableEditColumn.Cell>
      );
    }
    case listTablesName.trademarksMonitoring: {
      const DeleteComponent = (args) => {
        dispatch(helpersActions.setModal({
          isModalOpen: true,
        }));
        dispatch(tmMonitoringActions.setDeleteMonitoring({
          id: args.row.id,
        }));
      };
      const EditComponent = (args) => {
        dispatch(push(pageLinks.tradeMarksRoutes.monitoring.edit(args.row.id)));
      };
      const menuItems = [
        checkAccess(accessList.monitoring_get) && {
          title: t('Edit'),
          icon: <EditIcon fontSize="small" />,
          handleClick: (args) => EditComponent(args),
        },
        checkAccess(accessList.files_download_get) && {
          title: t('Download PDF'),
          icon: <CloudDownloadIcon fontSize="small" />,
          download: true,
        },
        checkAccess(accessList.monitoring_delete) && {
          title: t('Delete'),
          icon: <Delete fontSize="small" />,
          handleClick: (args) => DeleteComponent(args),
        },
      ];
      return (
        <TableEditColumn.Cell style={{ padding: 0 }}>
          <IconMenuTable
            menuItems={menuItems}
            tableName={getTableName}
            actionsProps={props}
          />
        </TableEditColumn.Cell>
      );
    }
    case listTablesName.trademarksMktu: {
      const DeleteComponent = (args) => {
        dispatch(helpersActions.setModal({
          isModalOpen: true,
        }));
        dispatch(reboundsActions.setDeleteRebound({
          id: args.row.id,
        }));
      };
      const EditComponent = (args) => {
        dispatch(push(pageLinks.tradeMarksRoutes.rebounds.edit(args.row.id)));
      };
      const menuItems = [
        checkAccess(accessList.mktu_get) && {
          title: t('Edit'),
          icon: <EditIcon fontSize="small" />,
          handleClick: (args) => EditComponent(args),
        },
        checkAccess(accessList.mktu_delete) && {
          title: t('Delete'),
          icon: <Delete fontSize="small" />,
          handleClick: (args) => DeleteComponent(args),
        },
      ];
      return (
        <TableEditColumn.Cell style={{ padding: 0 }}>
          <IconMenuTable
            menuItems={menuItems}
            tableName={getTableName}
            actionsProps={props}
          />
        </TableEditColumn.Cell>
      );
    }
    case listTablesName.trademarksSearch: {
      const DeleteComponent = (args) => {
        dispatch(helpersActions.setModal({
          isModalOpen: true,
        }));
        dispatch(searchesActions.setDeleteSearch({ id: args.row.id }));
      };
      const EditComponent = (args) => {
        dispatch(push(pageLinks.tradeMarksRoutes.searches.edit(args.row.id)));
      };
      const menuItems = [
        checkAccess(accessList.search_get) && {
          title: t('Edit'),
          icon: <EditIcon fontSize="small" />,
          handleClick: (args) => EditComponent(args),
        },
        checkAccess(accessList.search_delete) && {
          title: t('Delete'),
          icon: <Delete fontSize="small" />,
          handleClick: (args) => DeleteComponent(args),
        },
      ];
      return (
        <TableEditColumn.Cell style={{ padding: 0 }}>
          <IconMenuTable
            menuItems={menuItems}
            tableName={getTableName}
            actionsProps={props}
          />
        </TableEditColumn.Cell>
      );
    }
    case listTablesName.madridCase: {
      const DeleteComponent = (args) => {
        dispatch(helpersActions.setModal({
          isModalOpen: true,
        }));
        dispatch(madridCaseActions.setDeleteMadridCase({ id: args.row.id }));
      };
      const EditComponent = (args) => {
        dispatch(push(pageLinks.tradeMarksRoutes.madridCases.edit(args.row.id)));
      };
      const menuItems = [
        checkAccess(accessList.search_get) && {
          title: t('Edit'),
          icon: <EditIcon fontSize="small" />,
          handleClick: (args) => EditComponent(args),
        },
        checkAccess(accessList.search_delete) && {
          title: t('Delete'),
          icon: <Delete fontSize="small" />,
          handleClick: (args) => DeleteComponent(args),
        },
      ];
      return (
        <TableEditColumn.Cell style={{ padding: 0 }}>
          <IconMenuTable
            menuItems={menuItems}
            tableName={getTableName}
            actionsProps={props}
          />
        </TableEditColumn.Cell>
      );
    }
    case listTablesName.jurisdictions: {
      const DeleteComponent = (args) => {
        dispatch(helpersActions.setModal({
          isModalOpen: true,
        }));
        dispatch(jurisdictionsActions.setDeleteJurisdiction({ id: args.row.id }));
      };
      const EditComponent = (args) => {
        dispatch(jurisdictionsActions.setIsModalOpen(true));
        dispatch(jurisdictionsActionAsync.getJurisdictionByIdAsync(args.row.id));
      };
      const menuItems = [
        checkAccess(accessList.jurisdictions_get) && {
          title: t('Edit'),
          icon: <EditIcon fontSize="small" />,
          handleClick: (args) => EditComponent(args),
        },
        checkAccess(accessList.jurisdictions_delete) && {
          title: t('Delete'),
          icon: <Delete fontSize="small" />,
          handleClick: (args) => DeleteComponent(args),
        },
      ];
      return (
        <TableEditColumn.Cell style={{ padding: 0 }}>
          <IconMenuTable
            menuItems={menuItems}
            tableName={getTableName}
            actionsProps={props}
          />
        </TableEditColumn.Cell>
      );
    }
    case listTablesName.categories: {
      const DeleteComponent = (args) => {
        dispatch(helpersActions.setModal({
          isModalOpen: true,
        }));
        dispatch(pricesCategoryActions.setDeleteCategory({ id: args.row.id }));
      };
      const EditComponent = (args) => {
        dispatch(pricesCategoryActions.setIsModalOpen(true));
        dispatch(pricesCategoryActionAsync.getCategoryByIdAsync(args.row.id));
      };
      const menuItems = [
        checkAccess(accessList.costsCategories_get) && {
          title: t('Edit'),
          icon: <EditIcon fontSize="small" />,
          handleClick: (args) => EditComponent(args),
        },
        checkAccess(accessList.costsCategories_delete) && {
          title: t('Delete'),
          icon: <Delete fontSize="small" />,
          handleClick: (args) => DeleteComponent(args),
        },
      ];
      return (
        <TableEditColumn.Cell style={{ padding: 0 }}>
          <IconMenuTable
            menuItems={menuItems}
            tableName={getTableName}
            actionsProps={props}
          />
        </TableEditColumn.Cell>
      );
    }
    case listTablesName.notifications: {
      const EditComponent = (args) => {
        dispatch(notificationsAsyncAction.getNotificationByIdAsync(args.row.id));
        dispatch(notificationsActions.setNotificationById({
          // data: args.row,
          isModalOpen: true,
        }));
      };
      const DeleteComponent = (args) => {
        dispatch(helpersActions.setModal({
          isModalOpen: true,
        }));
        dispatch(notificationsActions.setDeleteNotification({
          id: args.row.id,
        }));
      };
      const menuItems = [
        checkAccess(accessList.notifications_get) && {
          title: t('Edit'),
          icon: <EditIcon fontSize="small" />,
          handleClick: (args) => EditComponent(args),
        },
        checkAccess(accessList.notifications_delete) && {
          title: t('Delete'),
          icon: <Delete fontSize="small" />,
          handleClick: (args) => DeleteComponent(args),
        },
      ];
      return (
        <TableEditColumn.Cell style={{ padding: 0 }}>
          <IconMenuTable
            menuItems={menuItems}
            tableName={getTableName}
            actionsProps={props}
          />
        </TableEditColumn.Cell>
      );
    }
    case listTablesName.paymentReceivers: {
      const EditComponent = (args) => {
        dispatch(paymentReceiversAsyncActions.getPaymentReceiversByIdAsync(args.row.id));
      };
      const DeleteComponent = (args) => {
        dispatch(helpersActions.setModal({
          isModalOpen: true,
        }));
        dispatch(paymentReceiversActions.setDeletePaymentReceivers({
          id: args.row.id,
        }));
      };
      const menuItems = [
        checkAccess(accessList.entity_sign_post) && {
          title: t('Edit'),
          icon: <EditIcon fontSize="small" />,
          handleClick: (args) => EditComponent(args),
        },
        checkAccess(accessList.entity_sign_post) && {
          title: t('Delete'),
          icon: <Delete fontSize="small" />,
          handleClick: (args) => DeleteComponent(args),
        },
      ];
      return (
        <TableEditColumn.Cell style={{ padding: 0 }}>
          <IconMenuTable
            menuItems={menuItems}
            tableName={getTableName}
            actionsProps={props}
          />
        </TableEditColumn.Cell>
      );
    }
    case listTablesName.documentTypesCategories:
    case listTablesName.documentTypes: {
      const EditComponent = (args) => {
        if (args.row?.documentClassifier || args.row?.index) {
          dispatch(documentTypesCategoryAsyncAction.getDocumentTypeCategoryByIdAsync(args.row.id));
          dispatch(documentTypesCategoryActions.setDocumentTypeCategoryById({ isModalOpen: true }));
          return;
        }
        dispatch(documentTypesAsyncAction.getDocumentTypeByIdAsync(args.row.id));
        dispatch(documentTypesActions.setDocumentTypeById({
          data: args.row,
          isModalOpen: true,
        }));
      };
      const DeleteComponent = (args) => {
        dispatch(helpersActions.setModal({
          isModalOpen: true,
        }));
        if (args.row?.documentClassifier || args.row?.documentClassifier) {
          dispatch(documentTypesCategoryActions.setDeleteDocumentTypeCategory({ id: args.row.id }));
          return;
        }
        dispatch(documentTypesActions.setDeleteDocumentType({ id: args.row.id }));
      };
      const menuItems = [
        checkAccess(accessList.documentClassifier_get) && {
          title: t('Edit'),
          icon: <EditIcon fontSize="small" />,
          handleClick: (args) => EditComponent(args),
        },
        checkAccess(accessList.documentClassifier_delete) && {
          title: t('Delete'),
          icon: <Delete fontSize="small" />,
          handleClick: (args) => DeleteComponent(args),
        },
      ];
      return (
        <TableEditColumn.Cell style={{ padding: 0 }}>
          <IconMenuTable
            menuItems={menuItems}
            tableName={getTableName}
            actionsProps={props}
          />
        </TableEditColumn.Cell>
      );
    }
    case listTablesName.taxes: {
      const DeleteComponent = (args) => {
        dispatch(helpersActions.setModal({
          isModalOpen: true,
        }));
        dispatch(taxesActions.setDeleteTax({ id: args.row.id }));
      };
      const EditComponent = (args) => {
        dispatch(push(pageLinks.taxes.edit(args.row.id)));
      };
      const menuItems = [
        checkAccess(accessList.taxes_get) && {
          title: t('Edit'),
          icon: <EditIcon fontSize="small" />,
          handleClick: (args) => EditComponent(args),
        },
        checkAccess(accessList.taxes_delete) && {
          title: t('Delete'),
          icon: <Delete fontSize="small" />,
          handleClick: (args) => DeleteComponent(args),
        },
      ];
      return (
        <TableEditColumn.Cell style={{ padding: 0 }}>
          <IconMenuTable
            menuItems={menuItems}
            tableName={getTableName}
            actionsProps={props}
          />
        </TableEditColumn.Cell>
      );
    }
    case listTablesName.currencies: {
      const DeleteComponent = (args) => {
        dispatch(helpersActions.setModal({
          isModalOpen: true,
        }));
        dispatch(currenciesActions.setDeleteCurrency({ id: args.row.id }));
      };
      const menuItems = [
        checkAccess(accessList.currencies_delete) && {
          title: t('Delete'),
          icon: <Delete fontSize="small" />,
          handleClick: (args) => DeleteComponent(args),
        },
      ];
      return (
        <TableEditColumn.Cell style={{ padding: 0 }}>
          <IconMenuTable
            menuItems={menuItems}
            tableName={getTableName}
            actionsProps={props}
          />
        </TableEditColumn.Cell>
      );
    }
    case listTablesName.currencyRates: {
      const DeleteComponent = (args) => {
        dispatch(helpersActions.setModal({
          isModalOpen: true,
        }));
        dispatch(currencyRatesActions.setDeleteCurrencyRate({ id: args.row.id }));
      };
      const menuItems = [
        checkAccess(accessList.currencyRates_delete) && {
          title: t('Delete'),
          icon: <Delete fontSize="small" />,
          handleClick: (args) => DeleteComponent(args),
        },
      ];
      return (
        <TableEditColumn.Cell style={{ padding: 0 }}>
          <IconMenuTable
            menuItems={menuItems}
            tableName={getTableName}
            actionsProps={props}
          />
        </TableEditColumn.Cell>
      );
    }
    case listTablesName.tariffs: {
      const DeleteComponent = (args) => {
        dispatch(helpersActions.setModal({
          isModalOpen: true,
        }));
        dispatch(tariffsActions.setDeleteTariff({
          id: args.row.id,
        }));
      };
      const EditComponent = (args) => {
        dispatch(push(pageLinks.prices.tariffs.edit(args.row.id)));
      };
      const menuItems = [
        checkAccess(accessList.costs_get) && {
          title: t('Edit'),
          icon: <EditIcon fontSize="small" />,
          handleClick: (args) => EditComponent(args),
        },
        checkAccess(accessList.costs_delete) && {
          title: t('Delete'),
          icon: <Delete fontSize="small" />,
          handleClick: (args) => DeleteComponent(args),
        },
      ];
      return (
        <TableEditColumn.Cell style={{ padding: 0 }}>
          <IconMenuTable
            menuItems={menuItems}
            tableName={getTableName}
            actionsProps={props}
          />
        </TableEditColumn.Cell>
      );
    }
    default: {
      return <TableEditColumn.Cell {...props} style={{ padding: 0 }} />;
    }
  }
}

ActionsCell.propTypes = {
  row: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.row, nextProps.row);
}

export default memo(ActionsCell, areEqual);
