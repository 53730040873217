/**
 * Use to redux-form/form or just list
 * @props-required: data, defaultExpanded
 * @props-not-required: handleExpanded, searchOptions, defaultCollapseIcon, defaultExpandIcon
 * @example for JSX:
   <RecursiveTreeView
     data={data}
     defaultExpanded={['root', '3']}
     handleExpanded={(ids) => {
     }}
     searchOptions={{
        query: 'Child - 4',
        searchBy: 'name',
      }}
   />
 * @example for defaultExpanded prop:
 * It's an item id (['root', '7']) if need to default open
 * @example for searchOptions prop:
    searchOptions={{
      query: 'text input',
      searchBy: 'name',
    }}
 * @example for data prop:
   const data = {
    id: 'root',
    name: 'Parent',
    children: [
      {
        id: '1',
        name: 'test',
        renderName: (
          <div>
            <span>test</span>
          </div>
        ),
      },
      {
        id: '2',
        name: 'Child - 2',
        handleClick: () => {},
      },
      {
        id: '3',
        name: 'Child - 3',
        children: [
          {
            id: '4',
            checkbox: {
             label: 'label',
             name: 'name',
            },
            name: 'Child - 4',
          },
        ],
      },
    ],
  };
 * */
// core
import React, {
  useMemo, useCallback, useState, useEffect,
} from 'react';
import * as PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';

// lodash
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';

// ui
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';

// parts
import Checkbox from '../../ui/Form/Checkbox';

// helpers
import { searchByProperty } from './helper/searchByProperty';

// TODO: перенести в helpers
const flattenItems = (items, key) => items.reduce((flattenedItems, item) => {
  flattenedItems.push(item);
  if (isArray(item[key])) {
    // eslint-disable-next-line no-param-reassign
    flattenedItems = flattenedItems.concat(flattenItems(item[key], key));
  }
  return flattenedItems;
}, []);

function RecursiveTreeView(props) {
  const {
    data, defaultCollapseIcon, defaultExpandIcon,
    defaultExpanded, searchOptions, handleExpanded,
  } = props;
  const [valueExpanded, setValueExpanded] = useState(defaultExpanded);
  const [controlledExpanded, setControlledExpanded] = useState(false);

  // фильтрация на наличие children, если пустой массив (data.checkbox: []) - не выводить сам пункт
  // TODO: вынести в hook
  const checkChildren = useCallback((item) => (isArray(item.children) && !isEmpty(item.children))
    || !isArray(item.children), []);
  const filterChildren = useCallback((nodes) => filter(
    nodes.children,
    (item) => checkChildren(item),
  ), [checkChildren]);
  const filterData = useMemo(() => (!isEmpty(data.children) ? filterChildren(data) : data), [
    data, filterChildren,
  ]);

  // search
  // TODO: вынести в hook
  const checkSearchOptions = useCallback((option) => {
    const checkOption = () => (option ? searchOptions[option] : searchOptions);
    return !isEmpty(searchOptions) ? checkOption() : null;
  }, [
    searchOptions,
  ]);
  const searchItems = useMemo(() => {
    const { searchBy } = searchOptions;
    const { query } = searchOptions;

    if (searchBy && query && !isEmpty(filterData) && isArray(filterData)) {
      const filteredData = map(searchByProperty(filterData, query, searchBy), (item) => (
        !isEmpty(item.children) && isArray(item.children)
          ? {
            ...item,
            children: searchByProperty(item.children, query, searchBy),
          }
          : item));
      return filteredData;
    }
    return [];
  }, [
    filterData,
    searchOptions,
  ]);

  // TODO: вынести в hook
  const filterChildrenBySearch = useMemo(() => (checkSearchOptions()
    ? filter(flattenItems(searchItems, 'children'), (item) => !isEmpty(item.children))
    : []), [checkSearchOptions, searchItems]);

  // TODO: вынести в hook
  const expanded = useMemo(() => {
    const controlled = checkSearchOptions('query') && controlledExpanded;
    const isNotControlled = !isEmpty(valueExpanded) ? valueExpanded : [];

    return (controlled && !isEmpty(searchItems)) || (controlled && isEmpty(searchItems)) ? [
      ...!isEmpty(searchItems) ? map(filterChildrenBySearch, (item) => item.id) : [],
    ] : isNotControlled;
  }, [
    valueExpanded, searchItems, controlledExpanded,
    checkSearchOptions, filterChildrenBySearch,
  ]);

  // TODO: Выделить в отдельный файл компонент
  const renderTree = (nodes, index) => {
    const renderName = nodes.renderName || nodes.name;

    return (
      <TreeItem
        key={index}
        {...nodes.handleClick ? {
          onClick: nodes.handleClick,
        } : {}}
        nodeId={nodes.id}
        label={!isEmpty(nodes.checkbox) && nodes.checkbox.label && nodes.checkbox.name ? (
          <Field
            component={Checkbox}
            margin="none"
            type="checkbox"
            {...nodes.checkbox}
          />
        ) : renderName}
      >
        {isArray(nodes.children) ? map(
          nodes.children,
          (node, childIndex) => renderTree(node, childIndex),
        ) : null}
      </TreeItem>
    );
  };

  useEffect(() => {
    if (!checkSearchOptions('query') && !controlledExpanded) {
      setControlledExpanded(true);
    }
  }, [
    checkSearchOptions,
    controlledExpanded,
    setControlledExpanded,
  ]);

  const dataTree = useMemo(() => {
    const controlled = checkSearchOptions('query');
    return controlled ? searchItems : filterData;
  }, [searchItems, filterData, checkSearchOptions]);

  return (
    <TreeView
      defaultCollapseIcon={defaultCollapseIcon}
      defaultExpandIcon={defaultExpandIcon}
      onNodeToggle={(event, nodeIds) => {
        setValueExpanded(nodeIds);
        setControlledExpanded(false);
        if (handleExpanded) {
          handleExpanded(nodeIds);
        }
        if (!checkSearchOptions('query')) {
          setTimeout(() => {
            setControlledExpanded(true);
          }, 500);
        }
      }}
      expanded={expanded}
    >
      {isArray(dataTree) ? map(
        dataTree,
        (node, childIndex) => renderTree(node, childIndex),
      ) : null}
    </TreeView>
  );
}

RecursiveTreeView.displayName = 'RecursiveTreeView';

RecursiveTreeView.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  defaultCollapseIcon: PropTypes.node,
  defaultExpandIcon: PropTypes.node,
  defaultExpanded: PropTypes.oneOfType([
    PropTypes.array,
  ]),
  searchOptions: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  handleExpanded: PropTypes.func,
};

RecursiveTreeView.defaultProps = {
  handleExpanded: () => {},
  defaultCollapseIcon: <ExpandMoreIcon />,
  defaultExpandIcon: <ChevronRightIcon />,
  defaultExpanded: [],
  searchOptions: {
    searchBy: '',
    query: '',
  },
};

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.data, nextProps.data)
  && isEqual(prevProps.defaultExpanded, nextProps.defaultExpanded)
  && isEqual(prevProps.searchOptions, nextProps.searchOptions);
}

export default React.memo(RecursiveTreeView, areEqual);
