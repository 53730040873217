// Core
import { put, select } from 'redux-saga/effects';

// lodash
import isEmpty from 'lodash/isEmpty';

// Engine
import localData from '../../../../../config/localData';
import { setInitialPageState } from '../../../../../../ui/_helpers/setInitialPageState';
import selectors from '../../../../../config/selectors';

// action
import actions from '../../action';

export function* callGetInitialStateWorker({ payload: url }) {
  const lists = yield localData.getItem(url);
  const initialPageState = setInitialPageState(lists || {});
  const disableReload = yield select(selectors.pendingActions.disableReload);

  if (!disableReload) {
    const userInfo = yield select(selectors.user.userInfo);
    yield put(actions.setInitialState({
      ...initialPageState,
      ...isEmpty(initialPageState.filters) && !isEmpty(userInfo) ? {
        filters: [
          { columnName: 'status', value: 1 },
          { columnName: 'users', value: userInfo.id },
        ],
      } : {},
    }));
  }
}
