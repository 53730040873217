// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const usersFiltersActionAsync = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_USERS_FILTERS_ASYNC, params);
  },
  postUsersFilterAsync(id) {
    return actionCreator(asyncTypes.POST_USERS_FILTER_ASYNC, id);
  },
  deleteUsersFilterAsync() {
    return actionCreator(asyncTypes.DELETE_USERS_FILTER_ASYNC);
  },
});

export default usersFiltersActionAsync;
