// core
import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import {
  Field, Form as MuiForm, reduxForm, change,
} from 'redux-form/immutable';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import { List, Map } from 'immutable';
import * as PropTypes from 'prop-types';

// ui
import {
  FormGroup, CardContent, Grid as MuiGrid, Box, Radio,
  Typography as MuiTypography, Card as MuiCard,
  Checkbox as MuiCheckbox, FormControlLabel, Tooltip, ButtonGroup,
  Button as MuiButton, Link as MuiLink,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';

// lodash
import {
  map, isEmpty, find,
  isNil, filter,
} from 'lodash';

// helpers
import { formName, formFields } from '../helper/form';
import {
  formatDate,
  getValueFormatDate,
} from '../../../../engine/_helpers/formatterDate';
import str2bool from '../../../../ui/_helpers/str2bool';

// config
import selectors from '../../../../engine/config/selectors';

// ACTIONS
// proposal
import proposalAsyncActions from '../../../../engine/core/tradeMarks/proposal/saga/asyncAction';
import proposalActions from '../../../../engine/core/tradeMarks/proposal/action';

// rebounds
import reboundsAction from '../../../../engine/core/tradeMarks/rebounds/action';
import reboundsActionAsync from '../../../../engine/core/tradeMarks/rebounds/saga/asyncAction';

// industrialDesigns
import industrialDesignsActions from '../../../../engine/core/industrialDesigns/action';

// clients
import clientsActions from '../../../../engine/core/company/clients/action';

// applicants
import applicantsActions from '../../../../engine/core/applicants/action';

// uploadFile
import uploadFileAction from '../../../../engine/core/uploadFile/action';

// employees
import employeesActions from '../../../../engine/core/company/employees/action';

// helpers
import helpersActions from '../../../../engine/core/helpers/action';

// parts
import RenderTextField from '../../../../ui/Form/RenderTextField';
import RadioButton from '../../../../ui/Form/RadioButton';
import Checkbox from '../../../../ui/Form/Checkbox';
import Sign from '../../../../components/Sign/Sign';
import RenderCropper from './components/RenderCropper';
import Loader from '../../../../components/Loader';
import Classes from './components/Classes/Classes';
import LoaderWithOverlay from '../../../../components/LoaderWithOverlay';
import CompareWithRegistryModal from '../../../../components/CompareWithRegistryModal/CompareWithRegistryModal';
import ConfirmModal from '../../../../components/ConfirmModal';
import Send from '../../../../components/Send/Send';
import AllClassesModal from './components/Classes/AllClassesModal';
import CardApplicantsOwnersAuthor from './components/CardApplicantsOwnersAuthor';
import CardRepresentative from './components/CardRepresentative';
import CardMailingAddress from './components/CardMailingAddress';
import CardPublicationAndAttributes from './components/CardPublicationAndAttributes';
import CardClient from './components/CardClient';
import CardPriority from './components/CardPriority';
import CardInitialDocuments from './components/CardInitialDocuments';
import CardFooter from './components/CardFooter';
import ButtonSubmit from '../../../../ui/Button/ButtonSubmit';
import CardBasicInformation from './components/CardBasicInformation';
import accessList from '../../../../engine/config/accessList';
import { useAccessList } from '../../../../ui/_hooks/useAccessList';

// styles
const Card = styled(MuiCard)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Button = styled(MuiButton)(spacing);
const Form = styled(MuiForm)`
  position: relative;
`;
const Link = styled(MuiLink)`
  cursor: pointer;
  border-bottom: 1px dashed;
  &:hover {
    text-decoration: none;
  }
`;
const MuiFormControlLabel = styled(FormControlLabel)`
  .MuiTypography-root {
    border-bottom: 1px dashed; 
  } 
`;
const FieldTextArea = styled(Field)`
.MuiInputBase-input {
  height: 5rem; 
} 
`;
const FieldTextAreaName = styled(Field)`
.MuiInputBase-input {
  height: 4rem; 
} 
`;

function ProposalAdd(props) {
  const {
    handleSubmit,
    initialize,
    newApplicantsList,
    signList,
    pendingPutProposal,
    uploadFiles,
    match,
    pendingGetProposalById,
    pendingRegistryByNumber,
    isColorsValues,
    applicationNumberValues,
    registrationNumberValue,
    logoValues,
    priorityTypeValues,
    isModalOpenRegistry,
    isModalOpenConfirm,
    fieldClient,
    getNextNumber,
    fieldTitle,
    createdImage,
    pendingCreatedImage,
    newOwnersList,
    registryByNumber,
    getProposalById,
    allClassesModalIsOpen,
    reboundById,
    tagsValues,
    postalAddressValues,
    pendingGetClassesById,
    listResponsible,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [fieldValueByNumber, setFieldValueByNumber] = useState('');
  const [isColorChecked, setIsColorChecked] = useState(false);
  const [isRequest, setIsRequest] = useState(false);
  const [cropImg, setCropImg] = useState(undefined);
  const pending = pendingPutProposal;
  const { params } = match;
  const accessTrademarksPut = useAccessList(accessList.trademarks_put);
  const accessGetNextNumber = useAccessList(accessList.trademarks_next_number_get);
  const accessPut = !isEmpty(params) ? accessTrademarksPut : true;

  useEffect(() => {
    if (!isNil(params.hashId) && !isRequest) {
      dispatch(proposalAsyncActions.getProposalByIdAsync(params.hashId));
      setIsRequest(true);
    }
  }, [dispatch, params, setIsRequest, isRequest]);

  useEffect(() => {
    if (isEmpty(params) && accessGetNextNumber) {
      dispatch(proposalAsyncActions.getNextNumberAsync({
        field: formFields.caseNumber,
      }));
    }
  }, [dispatch, params, accessGetNextNumber]);

  useEffect(() => {
    if (!isEmpty(getNextNumber) && isEmpty(params)) {
      dispatch(change(formName, formFields.caseNumber, getNextNumber));
      dispatch(proposalActions.setNextNumber(''));
    }
  }, [dispatch, getNextNumber, params]);

  useEffect(() => {
    if (!isNil(isColorsValues)) {
      setIsColorChecked(!isColorsValues);
    }
  }, [isColorsValues]);

  const cleanupState = useCallback(() => {
    dispatch(reboundsAction.setReboundById({}));
    dispatch(applicantsActions.setNewApplicantsListData([]));
    dispatch(applicantsActions.setNewOwnersListData([]));
    dispatch(proposalActions.setRegistryByNumber(Map()));
    dispatch(applicantsActions.setApplicantsList(List()));
    dispatch(proposalActions.setSelectMktuClasses([]));
    dispatch(uploadFileAction.setAllUploadFiles([]));
    dispatch(helpersActions.setSignList([]));
    dispatch(helpersActions.setDocumentsType([]));
    dispatch(proposalActions.setCreatedImage({}));
    dispatch(employeesActions.setEmployeeById({ data: {} }));
    dispatch(clientsActions.setClientById({ data: {} }));
    dispatch(proposalActions.setCompany(Map()));
    dispatch(proposalActions.setClasses([]));
    dispatch(industrialDesignsActions.setCompany(Map()));
    dispatch(proposalActions.setTermsMktuClassesClear());
    setCropImg(undefined);
  }, [
    dispatch,
  ]);

  useEffect(() => function cleanup() {
    cleanupState();
  }, [cleanupState]);

  useEffect(() => dispatch(applicantsActions.setApplicantsList(List())), [dispatch]);

  useEffect(() => {
    if ((isEmpty(params) && isEmpty(fieldValueByNumber))
      || (isEmpty(params) && !isEmpty(getProposalById.toJS()) && isEmpty(fieldValueByNumber))) {
      initialize({
        [formFields.status]: 1,
        eApplication: true,
        data: {
          standardCharacters: 'true',
          priority: {
            type: 'noPriority',
          },
          logo: null,
          additionalApplicationData: {
            markCollective: false,
            mark3d: false,
          },
        },
        langMktu: 'ua',
      });
      dispatch(proposalActions.setProposalDataById({}));
      cleanupState();
    }
  }, [
    initialize, params, getProposalById, dispatch, cleanupState,
    fieldValueByNumber,
  ]);

  const handleColorChecked = () => {
    setIsColorChecked(!isColorChecked);
  };

  const handleGetCropImg = (crop) => {
    setCropImg(crop);
  };
  const handleCreateImg = (title) => {
    dispatch(proposalAsyncActions.postCreateImageAsync({ title }));
  };

  const handleSubmits = (formData) => {
    const jsFormData = formData.toJS();
    jsFormData.data.applicants = [...newApplicantsList.toJS()];
    if (registrationNumberValue) {
      jsFormData.data.holders = [...newOwnersList.toJS()];
    } else {
      delete jsFormData.data.holders;
    }
    if (typeof cropImg === 'string' && cropImg !== logoValues) {
      jsFormData.data.logo = cropImg;
    } else if (!isNil(cropImg) && typeof cropImg !== 'string'
      && cropImg.getCroppedCanvas().toDataURL() !== logoValues) {
      jsFormData.data.logo = cropImg.getCroppedCanvas().toDataURL();
    } else if (isNil(cropImg) && logoValues) {
      jsFormData.data.logo = '';
    } else {
      delete jsFormData.data.logo;
    }
    if (!isEmpty(reboundById.toJS())) {
      jsFormData.mktu = {
        selectionId: reboundById.toJS()?.id,
      };
    }
    const documents = [];
    // eslint-disable-next-line array-callback-return
    map(uploadFiles.toJS(), (file, index) => {
      documents.push({
        type: jsFormData.documents[index].type.id,
        ...file.newFile ? {
          fileId: file.fileId,
        } : {
          docId: file.id,
        },
      });
    });
    const responsible = [];
    // eslint-disable-next-line array-callback-return
    map(listResponsible.toJS(), (item, index) => {
      const { documentClassifier } = jsFormData.responsible[index];
      responsible.push({
        employeeId: item?.id,
        documentClassifier: find(documentClassifier, { value: 'all' }) ? null : map(documentClassifier, 'id'),
      });
    });
    const priority = {
      ...jsFormData.data.priority,
      type: jsFormData.data.priority?.type === 'noPriority' ? undefined : jsFormData.data.priority?.type,
      earlyAppDate: getValueFormatDate(formatDate(jsFormData.data.priority?.earlyAppDate), 'earlyAppDate'),
      exhibitionDate: getValueFormatDate(formatDate(jsFormData.data.priority?.exhibitionDate), 'exhibitionDate'),
    };
    const publication = {
      ...jsFormData.publication,
      date: getValueFormatDate(formatDate(jsFormData.publication?.date), 'date'),
    };

    const data = {
      ...jsFormData,
      ...!isEmpty(jsFormData.tags) ? {
        tags: map(jsFormData.tags, (item) => item.id),
      } : {},
      clients: jsFormData?.clients?.id,
      jurisdictions: jsFormData?.jurisdictions?.id,
      registrationDate: getValueFormatDate(formatDate(jsFormData.registrationDate), 'registrationDate'),
      applicationDate: getValueFormatDate(formatDate(jsFormData.applicationDate), 'applicationDate'),
      expiryDate: getValueFormatDate(formatDate(jsFormData.expiryDate), 'expiryDate'),
      initialPublication: getValueFormatDate(formatDate(jsFormData.initialPublication), 'initialPublication'),
      documents,
      responsible,
      publication,
      isColor: !isColorChecked,
      data: {
        ...jsFormData.data,
        priority,
        standardCharacters: str2bool(jsFormData.data.standardCharacters),
        postalAddress: {
          ...jsFormData.data.postalAddress,
          fis: true,
          country: jsFormData.data?.postalAddress?.country?.abbreviation,
        },
        ...!isEmpty(jsFormData.data?.attorney) ? {
          attorney: {
            ...jsFormData.data.attorney,
            ...!isEmpty(jsFormData.data.attorney?.postalAddress) ? {
              postalAddress: {
                ...jsFormData.data.attorney.postalAddress,
                country: jsFormData.data.attorney.postalAddress?.country?.abbreviation,
              },
            } : {},
          },
        } : {},
        ...!isEmpty(jsFormData.usersId) ? {
          idAttorney: jsFormData.usersId?.id,
        } : {},
      },
    };
    delete data.person;
    delete data.mktuTemplates;
    delete data.caseNumberRebounds;
    delete data.clientsInput;
    delete data.applicantClients;
    delete data.applicantOwners;
    delete data.usersId;
    delete data.users;
    delete data.mailingAddress;
    delete data.jurisdictionsInput;
    if (params.hashId) {
      dispatch(proposalAsyncActions.putProposalAsync({ id: params.hashId, params: data }));
    } else {
      dispatch(proposalAsyncActions.postProposalAsync(data));
    }
  };
  const handleSign = () => {
    dispatch(helpersActions.setPermissionSign(true));
    handleSubmit();
  };
  const handleCompare = () => {
    if (!isEmpty(applicationNumberValues)) {
      dispatch(proposalAsyncActions.getRegistryByNumberAsync({
        applicationNumber: applicationNumberValues,
        disableInitialize: true,
      }));
      dispatch(helpersActions.setModal({ isModalOpenRegistry: true }));
    } else {
      dispatch(helpersActions.setModal({ isModalOpen: true }));
    }
  };
  const handleChangeProposal = (data) => {
    dispatch(proposalActions.setProposalDataById(data));
  };
  const handleChangeMktu = useCallback((selectedMktu) => {
    const selection = {};
    map(selectedMktu, (item) => {
      selection[item.classNum] = item?.name;
    });
    dispatch(reboundsActionAsync[!isEmpty(reboundById.toJS()) ? 'putReboundByIdAsync' : 'postReboundAsync']({
      ...reboundById.toJS(),
      ...!isEmpty(params) ? {
        trademarks: params.hashId,
      } : {},
      ...!isEmpty(getProposalById.toJS()) && !isEmpty(params) && isEmpty(reboundById.toJS()) ? {
        title: getProposalById.toJS().internalTitle || getProposalById.toJS().title,
      } : {},
      selection,
      reload: true,
    }));
    dispatch(proposalActions.setSelectMktuClasses(selectedMktu));
  }, [
    dispatch, reboundById, getProposalById, params,
  ]);
  const handleCleanup = () => {
    dispatch(proposalActions.setRegistryByNumber(Map()));
  };
  return (
    <>
      {pendingGetProposalById ? (
        <Loader />
      ) : (
        <>
          {!isEmpty(params.hashId) && accessPut && (
            <Grid item xs={12} mb={6}>
              {!isEmpty(applicationNumberValues) && (
                <Button
                  mr={4}
                  variant="outlined"
                  color="primary"
                  onClick={handleCompare}
                >
                  {t('COMPARE WITH THE REGISTRY')}
                </Button>
              )}
            </Grid>
          )}

          {isModalOpenRegistry && (
            <CompareWithRegistryModal
              pendingRegistryByNumber={pendingRegistryByNumber}
              getRegistryByNumber={registryByNumber}
              getProposalById={getProposalById}
              handleChangeProposal={handleChangeProposal}
              handleChangeMktu={handleChangeMktu}
              handleCleanup={handleCleanup}
            />
          )}

          {isModalOpenConfirm && (
            <ConfirmModal
              buttonSendText=""
              buttonCancelText="CLEARLY"
              description="You must enter an application number!"
            />
          )}

          <Form onSubmit={handleSubmit(handleSubmits)}>
            {(pendingRegistryByNumber || pendingGetClassesById) && (
              <LoaderWithOverlay />
            ) }

            <CardBasicInformation
              pendingRegistryByNumber={pendingRegistryByNumber}
              pending={pending}
              entityName="trademarks"
              formFields={formFields}
              formName={formName}
              fieldValueByNumber={fieldValueByNumber}
              setFieldValueByNumber={setFieldValueByNumber}
              disabled={!accessPut}
            />

            <CardPublicationAndAttributes
              pending={pending}
              formFields={formFields}
              disabled={!accessPut}
            />

            <CardClient
              name={formFields.clients}
              formName={formName}
              pending={pending}
              disabled={!accessPut}
            />

            <CardApplicantsOwnersAuthor
              formName={formName}
              pending={pending}
              entityName="trademarks"
              disabled={!accessPut}
            />

            <CardRepresentative
              formName={formName}
              getProposalById={getProposalById}
              postalAddressValues={postalAddressValues}
              formFields={formFields}
              pending={pending}
              match={match}
              disabled={!accessPut}
            />

            <CardMailingAddress
              formFields={formFields}
              pending={pending}
              formName={formName}
              disabled={!accessPut}
            />

            <Card mb={6}>
              <CardContent>
                <Typography variant="h2" gutterBottom>
                  {t('Image')}
                </Typography>

                <Grid container spacing={7}>
                  <Grid item xs={12} md={8}>
                    <Field
                      name={formFields.dataStandardCharacters}
                      row
                      component={RadioButton}
                      disabled={!accessPut}
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label={t('Standard characters')}
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label={t('Not standard characters')}
                      />
                    </Field>
                    <FieldTextAreaName
                      name={formFields.title}
                      id={formFields.title}
                      label={t('Verbal part of the sign')}
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      multiline
                      component={RenderTextField}
                      rows={4}
                      disabled={!accessPut}
                    />
                    {accessPut && (
                      <Grid item mt={1}>
                        <Link
                          to="/"
                          onClick={() => !isEmpty(fieldTitle) && handleCreateImg(fieldTitle)}
                          aria-haspopup="true"
                        >
                          {t('Create an image from the verbal part')}
                        </Link>
                      </Grid>
                    )}
                    <Grid item mt={4}>
                      <FieldTextArea
                        name={formFields.description}
                        id={formFields.description}
                        label={t('Description of TM')}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        multiline
                        component={RenderTextField}
                        rows={5}
                        disabled={!accessPut}
                      />
                    </Grid>
                    <Grid item mt={2}>
                      <FieldTextArea
                        name={formFields.dataColors}
                        id={formFields.dataColors}
                        label={t('Color scheme')}
                        margin="normal"
                        fullWidth
                        type="text"
                        variant="outlined"
                        multiline
                        component={RenderTextField}
                        rows={5}
                        disabled={!accessPut}
                      />
                    </Grid>
                    <Grid item mt={2}>
                      <Tooltip
                        title={t('The image will be discolored automatically after saving')}
                        arrow
                        placement="top"
                      >
                        <MuiFormControlLabel
                          control={(
                            <MuiCheckbox
                              checked={isColorChecked}
                              onChange={handleColorChecked}
                              name="isColor"
                            />
                          )}
                          label={t('No color assignment')}
                          disabled={!accessPut}
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <RenderCropper
                      handleGetCropImg={handleGetCropImg}
                      haveImage={!isEmpty(params)
                        || (isEmpty(params) && !isEmpty(fieldValueByNumber)) ? logoValues : ''}
                      fieldTitle={fieldTitle}
                      handleCreateImg={handleCreateImg}
                      createdImage={createdImage}
                      pending={pendingCreatedImage}
                      clearCreatedImage={() => dispatch(proposalActions.setCreatedImage({}))}
                      disabled={!accessPut}
                    />
                  </Grid>
                  {accessPut && (
                    <Grid item xs={12}>
                      <ButtonSubmit pending={pending} />
                    </Grid>
                  )}

                </Grid>
              </CardContent>
            </Card>

            <Card mb={6}>
              <CardContent>
                <Classes match={match} disabled={!accessPut} />
                {allClassesModalIsOpen && (
                  <AllClassesModal
                    tmId={params}
                  />
                )}
                {accessPut && (
                  <Grid item xs={12} mt={5}>
                    <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                      <ButtonSubmit pending={pending} />
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          dispatch(proposalActions.setAllClassesModalIsOpen(true));
                        }}
                      >
                        {t('ICGS EDITOR')}
                      </Button>
                    </ButtonGroup>
                  </Grid>
                )}
              </CardContent>
            </Card>

            <CardPriority
              priorityType="first"
              formFields={formFields}
              formName={formName}
              priorityTypeValues={priorityTypeValues}
              pending={pending}
              disabled={!accessPut}
            />

            <CardInitialDocuments
              formName={formName}
              pending={pending}
              fieldClient={fieldClient}
              disabled={!accessPut}
            />

            <Card mb={6}>
              <CardContent>
                <Typography variant="h2" gutterBottom>
                  {t('More information')}
                </Typography>

                <Grid container spacing={4}>
                  <Grid item xs={12} md={4}>
                    <FormGroup row>
                      <Field
                        name={formFields.dataAdditionalApplicationDataMark3d}
                        id={formFields.dataAdditionalApplicationDataMark3d}
                        type="checkbox"
                        fullWidth={false}
                        label={t('Volume sign')}
                        component={Checkbox}
                        disabled={!accessPut}
                      />
                      <Field
                        name={formFields.dataAdditionalApplicationDataMarkCollective}
                        id={formFields.dataAdditionalApplicationDataMarkCollective}
                        type="checkbox"
                        fullWidth={false}
                        label={t('Collective sign')}
                        component={Checkbox}
                        disabled={!accessPut}
                      />
                    </FormGroup>

                    <Field
                      name={formFields.dataAdditionalApplicationDataOriginCountry}
                      id={formFields.dataAdditionalApplicationDataOriginCountry}
                      label={t('Registration in the country of origin')}
                      margin="none"
                      variant="standard"
                      my={1}
                      fullWidth
                      type="text"
                      component={RenderTextField}
                      disabled={!accessPut}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Field
                      name={formFields.dataAdditionalApplicationDataLinkedApplications}
                      id={formFields.dataAdditionalApplicationDataLinkedApplications}
                      label={t('Number and date of other applications related to this in the legal view')}
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      multiline
                      component={RenderTextField}
                      rows={4}
                      disabled={!accessPut}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Field
                      name={formFields.dataAdditionalApplicationDataLinkedRegistrations}
                      id={formFields.dataAdditionalApplicationDataLinkedRegistrations}
                      label={t('Number and date of other registrations related to this in the legal view')}
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      multiline
                      component={RenderTextField}
                      rows={4}
                      disabled={!accessPut}
                    />
                  </Grid>
                  {accessPut && (
                    <Grid item xs={12}>
                      <ButtonSubmit pending={pending} />
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>

            <CardFooter
              pending={pending}
              formFields={formFields}
              formName={formName}
              tagsValues={tagsValues}
              getProposalById={getProposalById}
              match={match}
              objectType="1"
              disabled={!accessPut}
            />

            {!isEmpty(signList.toJS()) && (
              <Card mb={6}>
                <CardContent>
                  <Typography variant="h2" gutterBottom>
                    {t('Signed')}
                  </Typography>
                  {map(signList.toJS(), (sign, index) => (
                    <Typography key={index} gutterBottom>
                      <Box
                        component="span"
                        fontWeight="fontWeightBold"
                      >
                        {sign.signer || sign.users.name}
                        ,
                        {sign.csk}
                      </Box>
                      {' '}
                      (
                      {sign.date}
                      )
                    </Typography>
                  ))}
                </CardContent>
              </Card>
            )}

          </Form>

          {accessPut && (
            <Grid item xs={12}>
              {isEmpty(signList.toJS()) && (
                <>
                  <Button
                    disabled={isNil(params.hashId)}
                    mr={2}
                    variant="contained"
                    color="primary"
                    onClick={handleSign}
                  >
                    {t('SIGN')}
                  </Button>
                  <Sign
                    type="trademarks"
                    files={map(filter(uploadFiles.toJS(), 'fileId'), 'fileId')}
                    dataAllFiles={[{
                      id: params.hashId,
                      files: map(filter(uploadFiles.toJS(), 'fileId'), 'fileId'),
                    }]}
                  />
                </>
              )}

              <Send
                disabledButton={isNil(params.hashId) || isEmpty(signList.toJS())
                  || getProposalById.toJS()?.status > 2}
                type="trademarks"
                entityIds={[params.hashId]}
              />
            </Grid>
          )}
        </>
      )}
    </>
  );
}

ProposalAdd.displayName = 'ProposalAdd';

ProposalAdd.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pendingGetProposalById: PropTypes.bool.isRequired,
  pendingRegistryByNumber: PropTypes.bool.isRequired,
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  newApplicantsList: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  signList: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingPutProposal: PropTypes.bool.isRequired,
  pendingCreatedImage: PropTypes.bool.isRequired,
  uploadFiles: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  match: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  createdImage: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  fieldClient: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  isColorsValues: PropTypes.bool,
  fieldTitle: PropTypes.string,
  applicationNumberValues: PropTypes.string,
  registrationNumberValue: PropTypes.string,
  logoValues: PropTypes.string,
  priorityTypeValues: PropTypes.string,
  isModalOpenConfirm: PropTypes.bool,
  isModalOpenRegistry: PropTypes.bool,
  newOwnersList: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  registryByNumber: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  getProposalById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  getNextNumber: PropTypes.string.isRequired,
  tagsValues: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  allClassesModalIsOpen: PropTypes.bool.isRequired,
  reboundById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  postalAddressValues: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  pendingGetClassesById: PropTypes.bool.isRequired,
  listResponsible: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

ProposalAdd.defaultProps = {
  match: {},
  fieldClient: {},
  postalAddressValues: {},
  fieldTitle: '',
  applicationNumberValues: '',
  registrationNumberValue: '',
  priorityTypeValues: '',
  logoValues: '',
  tagsValues: [],
  isColorsValues: false,
  isModalOpenRegistry: false,
  isModalOpenConfirm: false,
};

function mapStateToProps(state) {
  return {
    // tradeMarksProposal
    signList: selectors.helpers.signList(state),
    pendingPutProposal: selectors.tradeMarksProposal.pendingPutProposal(state),
    pendingGetProposalById: selectors.tradeMarksProposal.pendingGetProposalById(state),
    pendingRegistryByNumber: selectors.tradeMarksProposal.getPendingRegistryByNumber(state),
    registryByNumber: selectors.tradeMarksProposal.getRegistryByNumber(state),
    getProposalById: selectors.tradeMarksProposal.getProposalById(state),
    uploadFiles: selectors.uploadFile.uploadFiles(state),
    createdImage: selectors.tradeMarksProposal.createdImage(state),
    pendingCreatedImage: selectors.tradeMarksProposal.pendingCreatedImage(state),
    getNextNumber: selectors.tradeMarksProposal.getNextNumber(state),
    pendingGetClassesById: selectors.tradeMarksProposal.pendingGetClassesById(state),
    allClassesModalIsOpen: selectors.tradeMarksProposal.allClassesModalIsOpen(state),
    reboundById: selectors.rebounds.getReboundById(state),

    // applicants
    newApplicantsList: selectors.applicants.getApplicantNewList(state),
    newOwnersList: selectors.applicants.getOwnersNewList(state),
    listResponsible: selectors.employees.listResponsible(state),

    isColorsValues: selectors.form.getFormValues(state, formName).get('isColor'),
    logoValues: selectors.form.getFormValues(state, formName).getIn(['data', 'logo']),
    priorityTypeValues: selectors.form.getFormValues(state, formName).getIn(['data', 'priority', 'type']),
    postalAddressValues: selectors.form.getFormValues(state, formName).getIn(['data', 'postalAddress']),
    applicationNumberValues: selectors.form.getFormValues(
      state, formName,
    ).get(formFields.applicationNumber),
    registrationNumberValue: selectors.form.getFormValues(
      state, formName,
    ).get(formFields.registrationNumber),
    fieldClient: selectors.form.getFormValues(state, formName).get(formFields.clients),
    fieldTitle: selectors.form.getFormValues(state, formName).get(formFields.title),
    tagsValues: selectors.form.getFormValues(state, formName).get('tags'),
    isModalOpenRegistry: selectors.helpers.isModalOpenRegistry(state),
    isModalOpenConfirm: selectors.helpers.isModalOpen(state),
    objectStatuses: selectors.helpers.getObjectStatuses(state),
  };
}

export default compose(
  reduxForm({
    form: formName,
  }),
  connect(mapStateToProps, null),
)(memo(ProposalAdd));
