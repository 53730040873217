// core
import React, {
  memo, useEffect, useState, useMemo, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { List, Map } from 'immutable';
import { push } from 'connected-react-router';
import { Link as MuiLink } from 'react-router-dom';

// ui
import {
  Divider as MuiDivider, Typography, Grid, Box,
} from '@material-ui/core';
import { isNil, isEmpty, isEqual } from 'lodash';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';
import {
  NavigateBefore as NavigateBeforeIcon,
} from '@material-ui/icons';

// engine
import selectors from '../../../../engine/config/selectors';

// parts
import ProposalPayments from '../ProposalPayments/ProposalPayments';
import ProposalOther from '../ProposalOther/ProposalOther';
import ProposalAdd from '../ProposalAdd/ProposalAdd';
import ProposalDocuments from '../ProposalDocuments/ProposalDocuments';
import Rebound from '../../Rebounds/Rebound/Rebound';
import LongMenu from '../../../../components/LongMenu/LongMenu';
import ProposalCalendar from '../../../../components/ProposalCalendar/ProposalCalendar';
import ProposalDispatchLog from '../../../../components/ProposalDispathLog/ProposalDispatchLog';
import AddMenu from '../../../../components/AddMenu/AddMenu';
import FabScrollTop from '../../../../components/FabScrollTop/FabScrollTop';
import ProposalEmail from '../ProposalEmail/ProposalEmail';

// action
import reboundsAction from '../../../../engine/core/tradeMarks/rebounds/action';
import proposalAsyncActions from '../../../../engine/core/tradeMarks/proposal/saga/asyncAction';
import proposalActions from '../../../../engine/core/tradeMarks/proposal/action';
import helpersAsyncAction from '../../../../engine/core/helpers/saga/asyncAction';
import clientsActions from '../../../../engine/core/company/clients/action';
import helpersActions from '../../../../engine/core/helpers/action';
import applicantsActions from '../../../../engine/core/applicants/action';
import employeesActions from '../../../../engine/core/company/employees/action';
import industrialDesignsActions from '../../../../engine/core/industrialDesigns/action';
import depDocActions from '../../../../engine/core/departmentDocuments/action';
import pendingActions from '../../../../engine/core/pendingActions/action';
import uploadFileAction from '../../../../engine/core/uploadFile/action';

// hooks
// import { useCurrentLongMenu } from '../../../../components/LongMenu/_hooks/useCurrentLongMenu';

// routes
import { pageLinks } from '../../../../routes';
import { useAccessList } from '../../../../ui/_hooks/useAccessList';
import accessList from '../../../../engine/config/accessList';

const TabPanelRoot = styled.div`
  flex: 1;
`;
const Link = styled(MuiLink)`
  text-decoration: underline;
  color: #1976d2;
  display: flex;
  align-items: center;
`;
function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <TabPanelRoot
      role="tabpanel"
      hidden={value !== index}
      id={`trademark-tabpanel-${index}`}
      aria-labelledby={`trademark-tab-${index}`}
      {...other}
    >
      {value === index && (
      <>{children}</>
      )}
    </TabPanelRoot>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Divider = styled(MuiDivider)(spacing);

const entityLongMenu = 'trademarks';

function ProposalTab(props) {
  const {
    match, reboundById, location, getProposalById,
  } = props;
  const { params } = match;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [title, setTitle] = useState(t('New'));
  const [isInitial, setInitial] = useState(false);
  const [isRequest, setIsRequest] = useState(false);
  const accessMktu = useAccessList(accessList.mktu_get);
  const accessDocumentsList = useAccessList(accessList.documents_list_get);
  const accessChargesList = useAccessList(accessList.charges_list_get);
  const accessPendingActionsList = useAccessList(accessList.pendingActions_list_get);
  const accessInDocsList = useAccessList(accessList.inDocs_list_get);
  const accessMktuList = useAccessList(accessList.mktu_list_get);
  const accessEmailLettersList = useAccessList(accessList.emailLetters_list_get);

  const checkTab = useCallback((tabName) => tabName === location.hash, [
    location,
  ]);

  const handleChangeTab = useCallback((tabName) => {
    if (!isNil(params.hashId)) {
      dispatch(push(pageLinks.tradeMarksRoutes.proposal.edit(params.hashId, tabName)));
      setInitial(true);
    } else {
      dispatch(push(pageLinks.tradeMarksRoutes.proposal.newTab(tabName)));
    }
  }, [dispatch, params]);

  const getTitleTab = useMemo(() => {
    if (checkTab('')) { return t('Application'); }
    if (checkTab('#classes')) { return t('ICGS'); }
    if (checkTab('#docs')) { return t('Documents'); }
    if (checkTab('#finance')) { return t('Payments'); }
    if (checkTab('#calendar')) { return t('Calendar'); }
    if (checkTab('#email')) { return t('Email'); }
    if (checkTab('#packets')) { return t('Dispatch Log'); }
    if (checkTab('#misc')) { return t('Other'); }
    return '';
  }, [t, checkTab]);

  useEffect(() => {
    if (!isNil(params.hashId) && !isRequest && checkTab('#docs') && isEmpty(getProposalById.toJS())) {
      dispatch(proposalAsyncActions.getProposalByIdAsync(params.hashId));
      setIsRequest(true);
    }
  }, [dispatch, params, setIsRequest, isRequest, getProposalById, checkTab]);

  useEffect(() => {
    if (!isNil(params.hashId)) {
      const titleProposal = getProposalById.toJS().caseNumber || params.hashId;
      setTitle(`${titleProposal} - ${getTitleTab}`);
    } else {
      setTitle(`${t('New')} - ${getTitleTab}`);
    }
  }, [params.hashId, getTitleTab, getProposalById, t]);

  const clients = useMemo(() => {
    if (getProposalById.get('clients')) return { clients: getProposalById.get('clients')?.id };
    return {};
  }, [getProposalById]);

  useEffect(() => {
    if (!isNil(params.hashId) && !location.hash && !isInitial) {
      dispatch(helpersAsyncAction.getInitialStateAsync({
        url: 'currentLongMenu',
        entity: `${entityLongMenu}${params.hashId}`,
      }));
      setInitial(true);
    }
  }, [params, dispatch, location, setInitial, isInitial]);

  useEffect(() => function cleanup() {
    dispatch(applicantsActions.setNewApplicantsListData([]));
    dispatch(applicantsActions.setNewOwnersListData([]));
    dispatch(proposalActions.setRegistryByNumber(Map()));
    dispatch(applicantsActions.setApplicantsList(List()));
    dispatch(proposalActions.setSelectMktuClasses([]));
    dispatch(uploadFileAction.setAllUploadFiles([]));
    dispatch(helpersActions.setSignList([]));
    dispatch(helpersActions.setDocumentsType([]));
    dispatch(proposalActions.setCreatedImage({}));
    dispatch(employeesActions.setEmployeeById({ data: {} }));
    dispatch(clientsActions.setClientById({ data: {} }));
    dispatch(proposalActions.setCompany(Map()));
    dispatch(proposalActions.setClasses([]));
    dispatch(industrialDesignsActions.setCompany(Map()));
    dispatch(reboundsAction.setReboundById({}));
    dispatch(proposalActions.setProposalDataById({}));
  }, [dispatch]);

  const handleNewDocument = useCallback(() => {
    dispatch(helpersActions.setLastObject({ entityId: params.hashId, entityName: entityLongMenu }));
    dispatch(depDocActions.setDepartmentDocumentEntityList({
      [entityLongMenu]: [getProposalById.toJS()],
      ...getProposalById.toJS().clients ? { clients: [getProposalById.toJS().clients] } : {},
    }));
    dispatch(push(pageLinks.documents.departmentDocuments.new));
  }, [dispatch, getProposalById, params]);

  const handleNewNotification = useCallback(() => {
    dispatch(pendingActions.setPendingActionEntityList({
      [entityLongMenu]: getProposalById.toJS(),
    }));
    dispatch(pendingActions.setIsModalOpen(true));
  }, [dispatch, getProposalById]);

  return (
    <>
      <Helmet title={t('Application')} />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h3" gutterBottom display="inline">
          <Grid container alignItems="center">
            {title}
            <LongMenu
              handleChange={handleChangeTab}
              entity={`${entityLongMenu}${!isNil(params.hashId) ? params.hashId : ''}`}
              isNewEntity={isNil(params.hashId)}
              items={[
                { name: t('Application'), hash: '' },
                ...accessMktu ? [{ name: t('ICGS'), hash: 'classes' }] : [],
                ...!isEmpty(params) ? [
                  ...accessDocumentsList ? [{ name: t('Documents'), hash: 'docs' }] : [],
                  ...accessChargesList ? [{ name: t('Payments'), hash: 'finance' }] : [],
                  ...accessPendingActionsList ? [{ name: t('Calendar'), hash: 'calendar' }] : [],
                  ...accessEmailLettersList ? [{ name: t('Email'), hash: 'email' }] : [],
                  ...accessInDocsList ? [{ name: t('Dispatch Log'), hash: 'packets' }] : [],
                  ...accessMktuList ? [{ name: t('Other'), hash: 'misc' }] : [],
                ] : [],
              ]}
            />
            {!isEmpty(params) && (
              <AddMenu
                entity={entityLongMenu}
                handleNewDocument={handleNewDocument}
                handleNewNotification={handleNewNotification}
              />
            )}
            <FabScrollTop isNewEntity={isNil(params.hashId)} />
          </Grid>
        </Typography>
        <Link to={pageLinks.tradeMarksRoutes.proposal.all}>
          <NavigateBeforeIcon size="small" color="primary" />
          {t('Up to the list')}
        </Link>
      </Box>
      <Divider mb={6} />

      {checkTab('') && <ProposalAdd match={match} />}
      {checkTab('#classes') && accessMktu && (
        <Rebound
          tmId={params}
          visibleTM={false}
          showTitle={false}
          match={!isEmpty(reboundById.toJS()) && !isEmpty(params.hashId)
            ? {
              ...match,
              params: {
                hashId: reboundById.toJS().id,
              },
            }
            : {
              ...match,
              params: {},
            }}
        />
      )}
      {checkTab('#docs') && accessDocumentsList && <ProposalDocuments match={match} name="trademarks" proposal={getProposalById} />}
      {checkTab('#finance') && accessChargesList && (
        <ProposalPayments
          match={match}
          clients={clients}
          entityName="trademarks"
          defaultFilters={[
            { columnName: 'trademarks.id', value: params.hashId },
          ]}
        />
      )}
      {checkTab('#calendar') && accessPendingActionsList && (
        <ProposalCalendar
          match={match}
          actionId="trademarks.id"
          actionName="trademarks"
        />
      )}
      {checkTab('#email') && accessEmailLettersList && (
        <ProposalEmail
          match={match}
          actionId="trademarks.id"
          actionName="trademarks"
        />
      )}
      {checkTab('#packets') && accessInDocsList && (
        <ProposalDispatchLog
          match={match}
          showTitle={false}
          actionId="trademarks.id"
        />
      )}
      {checkTab('#misc') && accessMktuList && <ProposalOther match={match} />}
    </>
  );
}
ProposalTab.propTypes = {
  match: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  location: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  reboundById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  getProposalById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};
ProposalTab.defaultProps = {
  match: {},
  location: {},
};
function mapStateToProps(state) {
  return {
    reboundById: selectors.rebounds.getReboundById(state),
    getProposalById: selectors.tradeMarksProposal.getProposalById(state),
  };
}

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.location, nextProps.location)
  && isEqual(prevProps.match, nextProps.match)
  && isEqual(prevProps.getProposalById, nextProps.getProposalById)
  && isEqual(prevProps.reboundById, nextProps.reboundById);
}

export default compose(
  connect(mapStateToProps, null),
)(memo(ProposalTab, areEqual));
