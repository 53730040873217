// Core
import React from 'react';
import { Trans } from 'react-i18next';

export default [
  { name: <Trans>General</Trans>, value: '1' },
  { name: <Trans>Payment of the fee</Trans>, value: '2' },
  { name: <Trans>Response to the request</Trans>, value: '3' },
  { name: <Trans>Reminder</Trans>, value: '4' },
];
