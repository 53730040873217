import React, { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import { compose } from 'recompose';
import { Redirect } from 'react-router-dom';

// lodash
import isEmpty from 'lodash/isEmpty';

// UI
import { spacing } from '@material-ui/system';
import {
  Hidden,
  CssBaseline,
  Paper as MuiPaper,
  withWidth,
} from '@material-ui/core';
import { isWidthUp } from '@material-ui/core/withWidth';

// parts
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Loader from '../components/Loader';
import ActionModal from '../pages/ActionsPage/components/ActionModal';
import AddTagsModal from '../components/AddTagsModal/AddTagsModal';

// actions
import helpersActions from '../engine/core/helpers/action';

// config
import selectors from '../engine/config/selectors';
import { routersLink } from '../routes';
import AddTypeActionModal from '../components/AddTypeActionModal/AddTypeActionModal';

const drawerWidth = 260;

const GlobalStyle = createGlobalStyle`
  html {
    scroll-padding-top: 80px;
  }

  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.body.background};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
  justify-content: center;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
  
  &.close {
    width: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.body.background};
  display: flex;
  flex-direction: column;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Dashboard = (props) => {
  const {
    children, width, pending, visibleSidebar, isModalOpenAction,
    isModalOpenTags, isModalOpenActionTypes,
  } = props;
  const dispatch = useDispatch();
  const userInfo = useSelector(selectors.user.userInfo);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    if (!isWidthUp('md', width)) setMobileOpen(!mobileOpen);
    if (isWidthUp('md', width)) dispatch(helpersActions.setVisibleSidebar(!visibleSidebar));
  };

  if (isEmpty(userInfo)) {
    return <Redirect to={routersLink.users.signIn} />;
  }

  return (
    <Root>
      {!pending ? (
        <>
          <CssBaseline />
          <GlobalStyle />
          <Drawer className={visibleSidebar ? '' : 'close'}>
            <Hidden mdUp implementation="js">
              <Sidebar
                // routes={routes}
                PaperProps={{ style: { width: drawerWidth } }}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
              />
            </Hidden>
            <Hidden smDown implementation="css">
              <Sidebar
                // routes={routes}
                PaperProps={{ style: { width: visibleSidebar ? drawerWidth : 0 } }}
                onClose={handleDrawerToggle}
              />
            </Hidden>
          </Drawer>
          <AppContent>
            <Header visibleSidebar={visibleSidebar} onDrawerToggle={handleDrawerToggle} />
            <MainContent p={isWidthUp('lg', width) ? 10 : 5}>
              {children}
            </MainContent>
            <Footer />

            {isModalOpenAction && (
              <ActionModal />
            )}
            {isModalOpenTags && (
              <AddTagsModal />
            )}
            {isModalOpenActionTypes && (
              <AddTypeActionModal />
            )}
          </AppContent>
        </>
      ) : (
        <Loader />
      )}
    </Root>
  );
};

Dashboard.propTypes = {
  pending: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  visibleSidebar: PropTypes.bool.isRequired,
  isModalOpenAction: PropTypes.bool.isRequired,
  isModalOpenTags: PropTypes.bool.isRequired,
  isModalOpenActionTypes: PropTypes.bool.isRequired,
  width: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    pending: selectors.user.pending(state),
    visibleSidebar: selectors.helpers.visibleSidebar(state),
    isModalOpenAction: selectors.pendingActions.isModalOpenAction(state),
    isModalOpenTags: selectors.tags.isModalOpen(state),
    isModalOpenActionTypes: selectors.actionTypes.isModalOpen(state),
  };
}
export default compose(connect(mapStateToProps, null), withWidth())(Dashboard);
