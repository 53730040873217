// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const asyncActions = Object.freeze({
  getApplicantsListAsync(params) {
    return actionCreator(asyncTypes.GET_APPLICANTS_LIST_ASYNC, params);
  },
  putApplicantAsync(data) {
    return actionCreator(asyncTypes.PUT_APPLICANT_ASYNC, data);
  },
  postApplicantAsync(data) {
    return actionCreator(asyncTypes.POST_APPLICANT_ASYNC, data);
  },
  deleteApplicantAsync() {
    return actionCreator(asyncTypes.DELETE_APPLICANT_ASYNC);
  },
  getApplicantAutocomplete(text) {
    return actionCreator(asyncTypes.GET_APPLICANT_AUTOCOMPLETE_ASYNC, text);
  },
  getApplicantsInitialListAsync(params) {
    return actionCreator(asyncTypes.GET_APPLICANTS_INITIAL_LIST_ASYNC, params);
  },
  getInitialStateAsync(params) {
    return actionCreator(asyncTypes.GET_APPLICANTS_INITIAL_STATE_ASYNC, params);
  },
  getApplicantByIdAsync(params) {
    return actionCreator(asyncTypes.GET_APPLICANT_BY_ID_ASYNC, params);
  },
});

export default asyncActions;
