export default Object.freeze({
  SET_PROPOSAL_DATA: 'SET_PROPOSAL_DATA',
  SET_PROPOSAL_CURRENT_PAGE: 'SET_PROPOSAL_CURRENT_PAGE',
  SET_PROPOSAL_PAGE_SIZE: 'SET_PROPOSAL_PAGE_SIZE',
  SET_PROPOSAL_FILTERS: 'SET_PROPOSAL_FILTERS',
  SET_PROPOSAL_BY_ID: 'SET_PROPOSAL_BY_ID',
  SET_PROPOSAL_DATA_BY_ID: 'SET_PROPOSAL_DATA_BY_ID',
  SET_PROPOSAL_SORTING: 'SET_PROPOSAL_SORTING',
  SET_PROPOSAL_INITIAL_STATE: 'SET_PROPOSAL_INITIAL_STATE',
  SET_PROPOSAL_RELOAD: 'SET_PROPOSAL_RELOAD',
  SET_TRADE_MARKS_PROPOSAL_AUTOCOMPLETE_OPTIONS: 'SET_TRADE_MARKS_PROPOSAL_AUTOCOMPLETE_OPTIONS',
  SET_PENDING_TRADE_MARKS_PROPOSAL_AUTOCOMPLETE_OPTIONS: 'SET_PENDING_TRADE_MARKS_PROPOSAL_AUTOCOMPLETE_OPTIONS',
  SET_PROPOSAL_CLASSES: 'SET_PROPOSAL_CLASSES',
  SET_PROPOSAL_COMPANY: 'SET_PROPOSAL_COMPANY',
  SET_MKTU_TEMPLATE_OPTIONS: 'SET_MKTU_TEMPLATE_OPTIONS',
  SET_PENDING_MKTU_TEMPLATE_AUTOCOMPLETE_OPTIONS: 'SET_PENDING_MKTU_TEMPLATE_AUTOCOMPLETE_OPTIONS',
  SET_MKTU_TEMPLATE_BY_ID: 'SET_MKTU_TEMPLATE_BY_ID',
  SET_DELETE_PROPOSAL: 'SET_DELETE_PROPOSAL',
  SET_PROPOSAL_CLASSES_FROM_REGISTRY: 'SET_PROPOSAL_CLASSES_FROM_REGISTRY',
  SET_REGISTRY_BY_NUMBER: 'SET_REGISTRY_BY_NUMBER',
  SET_PENDING_PUT_PROPOSAL: 'SET_PENDING_PUT_PROPOSAL',
  SET_PENDING_POST_MKTU_TEMPLATE: 'SET_PENDING_POST_MKTU_TEMPLATE',
  SET_PROPOSAL_CLASS_BY_ID: 'SET_PROPOSAL_CLASS_BY_ID',
  SET_PROPOSAL_TERMS_MKTU: 'SET_PROPOSAL_TERMS_MKTU',
  SET_PROPOSAL_TERMS_MKTU_CLEAR: 'SET_PROPOSAL_TERMS_MKTU_CLEAR',
  SET_PROPOSAL_PENDING_CLASS_BY_ID: 'SET_PROPOSAL_PENDING_CLASS_BY_ID',
  SET_PROPOSAL_SIGN_PENDING: 'SET_PROPOSAL_SIGN_PENDING',
  SET_PROPOSAL_SIGN_MODAL_IS_OPEN: 'SET_PROPOSAL_SIGN_MODAL_IS_OPEN',
  SET_PROPOSAL_UPLOAD_FILES: 'SET_PROPOSAL_UPLOAD_FILES',
  SET_PROPOSAL_MKTU_PENDING: 'SET_PROPOSAL_MKTU_PENDING',
  SET_PROPOSAL_MKTU_LIST: 'SET_PROPOSAL_MKTU_LIST',
  SET_PENDING_GET_PROPOSAL_BY_ID: 'SET_PENDING_GET_PROPOSAL_BY_ID',
  SET_PROPOSAL_MKTU_MODAL_IS_OPEN: 'SET_PROPOSAL_MKTU_MODAL_IS_OPEN',
  SET_PROPOSAL_SELECT_MKTU: 'SET_PROPOSAL_SELECT_MKTU',
  SET_PENDING_POST_PROPOSAL: 'SET_PENDING_POST_PROPOSAL',
  SET_NEXT_TAB_PROPOSAL: 'SET_NEXT_TAB_PROPOSAL',
  SET_CREATED_IMAGE: 'SET_CREATED_IMAGE',
  SET_PENDING_CREATED_IMAGE: 'SET_PENDING_CREATED_IMAGE',
  SET_PENDING_GET_REGISTRY_BY_NUMBER: 'SET_PENDING_GET_REGISTRY_BY_NUMBER',
  SET_PENDING_GET_FIND_COMPANY: 'SET_PENDING_GET_FIND_COMPANY',
  SET_TRADE_MARKS_NEXT_NUMBER: 'SET_TRADE_MARKS_NEXT_NUMBER',
  SET_ALL_CLASSES_MODAL_IS_OPEN: 'SET_ALL_CLASSES_MODAL_IS_OPEN',
  SET_PROPOSAL_COLUMN_WIDTHS: 'SET_PROPOSAL_COLUMN_WIDTHS',
  SET_PROPOSAL_COLUMN_ORDER: 'SET_PROPOSAL_COLUMN_ORDER',
  SET_PROPOSAL_HIDDEN_COLUMN_NAMES: 'SET_PROPOSAL_HIDDEN_COLUMN_NAMES',
  SET_MKTU_BY_ID_PROPOSAL: 'SET_MKTU_BY_ID_PROPOSAL',
  SET_PROPOSAL_CLASSES_BY_ID: 'SET_PROPOSAL_CLASSES_BY_ID',
  SET_CLEAR_PROPOSAL_CLASSES_BY_ID: 'SET_CLEAR_PROPOSAL_CLASSES_BY_ID',
  SET_PROPOSAL_PENDING_CLASSES_BY_ID: 'SET_PROPOSAL_PENDING_CLASSES_BY_ID',
});
