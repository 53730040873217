// Core
import { all, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetSystemMessageWorker,
  callGetSystemMessageByIdWorker,
  callPutSystemMessageByIdWorker,
  callPostSystemMessageWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callDeleteSystemMessageWorker,
  callGetSystemMessageAllListWorker,
  callPatchSystemMessageByIdWorker,
} from './workers';
import types from '../types';

function* watchGetSystemMessage() {
  yield takeEvery(asyncTypes.GET_SYSTEM_MESSAGE_ASYNC, callGetSystemMessageWorker);
}
function* watchGetSystemMessageAllList() {
  yield takeEvery(asyncTypes.GET_SYSTEM_MESSAGE_ALL_LIST_ASYNC, callGetSystemMessageAllListWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_SYSTEM_MESSAGE_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchGetSystemMessageById() {
  yield takeEvery(asyncTypes.GET_SYSTEM_MESSAGE_BY_ID_ASYNC, callGetSystemMessageByIdWorker);
}
function* watchPostSystemMessage() {
  yield takeEvery(asyncTypes.POST_SYSTEM_MESSAGE_ASYNC, callPostSystemMessageWorker);
}
function* watchPutSystemMessageById() {
  yield takeEvery(asyncTypes.PUT_SYSTEM_MESSAGE_BY_ID_ASYNC, callPutSystemMessageByIdWorker);
}
function* watchPatchSystemMessageById() {
  yield takeEvery(asyncTypes.PATCH_SYSTEM_MESSAGE_BY_ID_ASYNC, callPatchSystemMessageByIdWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_SYSTEM_MESSAGE_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_SYSTEM_MESSAGE_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_SYSTEM_MESSAGE_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_SYSTEM_MESSAGE_SORTING, callSetToLocalDataWorker);
}
function* watchSetColumnWidths() {
  yield takeEvery(types.SET_SYSTEM_MESSAGE_COLUMN_WIDTHS, callSetToLocalDataWorker);
}
function* watchSetColumnOrder() {
  yield takeEvery(types.SET_SYSTEM_MESSAGE_COLUMN_ORDER, callSetToLocalDataWorker);
}
function* watchSetHiddenColumnNames() {
  yield takeEvery(types.SET_SYSTEM_MESSAGE_HIDDEN_COLUMN_NAMES, callSetToLocalDataWorker);
}
function* watchDeleteSystemMessage() {
  yield takeEvery(asyncTypes.DELETE_SYSTEM_MESSAGE_ASYNC, callDeleteSystemMessageWorker);
}

export function* watchersSystemMessage() {
  yield all([
    watchGetSystemMessage(),
    watchGetInitialState(),
    watchGetSystemMessageById(),
    watchPostSystemMessage(),
    watchPutSystemMessageById(),
    watchSetCurrentPage(),
    watchSetPageSize(),
    watchSetFilters(),
    watchSetSorting(),
    watchSetColumnWidths(),
    watchSetColumnOrder(),
    watchSetHiddenColumnNames(),
    watchDeleteSystemMessage(),
    watchGetSystemMessageAllList(),
    watchPatchSystemMessageById(),
  ]);
}
