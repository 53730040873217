// core
import React, {
  memo,
} from 'react';
import { spacing } from '@material-ui/system';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';

// lodash
import { isEqual } from 'lodash';

// UI
import {
  CardContent, Grid as MuiGrid,
  Typography as MuiTypography, Card as MuiCard,
} from '@material-ui/core';

// parts
import RenderTextField from '../../../../../ui/Form/RenderTextField';
import DatePickers from '../../../../../ui/Form/DatePickers';
import Checkbox from '../../../../../ui/Form/Checkbox';
import ButtonSubmit from '../../../../../ui/Button/ButtonSubmit';

// styles
const Card = styled(MuiCard)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);

function CardPublicationAndAttributes(props) {
  const {
    formFields, pending, disabled,
  } = props;
  const { t } = useTranslation();

  return (
    <>
      <Card mb={6}>
        <CardContent>
          <Typography variant="h2" gutterBottom>
            {t('Publication')}
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={3}>
              <Field
                name={formFields.initialPublication}
                id={formFields.initialPublication}
                labelId={formFields.initialPublication}
                label={t('Publication of the application (441)')}
                margin="normal"
                variant="standard"
                my={1}
                fullWidth
                type="text"
                formatDate="dd.MM.yyyy"
                component={DatePickers}
                disabled={disabled}
              />

            </Grid>
            <Grid item xs={12} md={3}>
              <Field
                name={formFields.publicationIdentifier}
                id={formFields.publicationIdentifier}
                label={t('Bulletin')}
                margin="normal"
                variant="standard"
                my={1}
                fullWidth
                type="text"
                component={RenderTextField}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Field
                name={formFields.publicationDate}
                id={formFields.publicationDate}
                labelId={formFields.publicationDate}
                label={t('Date of publication')}
                margin="normal"
                variant="standard"
                my={1}
                fullWidth
                type="text"
                formatDate="dd.MM.yyyy"
                component={DatePickers}
                disabled={disabled}
              />
            </Grid>
            {!disabled && (
              <Grid item xs={12}>
                <ButtonSubmit pending={pending} />
              </Grid>
            )}

          </Grid>
        </CardContent>
      </Card>
      <Card mb={6}>
        <CardContent>
          <Typography variant="h2" gutterBottom>
            {t('Attributes')}
          </Typography>

          <Grid container spacing={4}>
            <Grid item xs={12} md={3}>
              <Field
                component={Checkbox}
                margin="none"
                type="checkbox"
                name={formFields.eApplication}
                label={t('Electronic application')}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Field
                component={Checkbox}
                margin="none"
                type="checkbox"
                name={formFields.boost}
                label={t('Expedited application')}
                disabled={disabled}
              />
            </Grid>
            {!disabled && (
              <Grid item xs={12}>
                <ButtonSubmit pending={pending} />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
CardPublicationAndAttributes.propTypes = {
  formFields: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pending: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

CardPublicationAndAttributes.defaultProps = {
  disabled: false,
};

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.formFields, nextProps.formFields)
  && isEqual(prevProps.disabled, nextProps.disabled)
  && isEqual(prevProps.pending, nextProps.pending);
}
export default memo(CardPublicationAndAttributes, areEqual);
