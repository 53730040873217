// Core
import React, {
  useEffect, useState, useCallback, useMemo, memo,
} from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import Helmet from 'react-helmet';
import {
  Field, Form, reduxForm, change,
} from 'redux-form/immutable';
import * as PropTypes from 'prop-types';
import {
  List, Map, merge, remove,
} from 'immutable';
import moment from 'moment';
import { Link as MuiLink } from 'react-router-dom';

// ui
import {
  Typography, Divider as MuiDivider, Tabs, Tab, Badge as MuiBadge,
  CardContent, Box, Button as MuiButton, FormControlLabel, IconButton,
  Grid, Card as MuiCard, Radio, Tooltip, CircularProgress, InputAdornment,
  Link as LinkMui,
} from '@material-ui/core';
import {
  // HelpOutline as HelpOutlineIcon,
  FilterList as FilterListIcon,
  Clear as ClearIcon,
  NavigateBefore as NavigateBeforeIcon,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';

// lodash
import {
  isEmpty, filter, includes, map, range,
  size, toLower, isArray, pickBy, without,
} from 'lodash';

// parts
import RadioButton from '../../../../ui/Form/RadioButton';
import RenderTextField from '../../../../ui/Form/RenderTextField';
import SearchCardTabs from './SearchCardTabs';
import Select from '../../../../ui/Form/Select';
import ReduxDateRange from '../../../../ui/DateRange/ReduxDateRange';
import Loader from '../../../../components/Loader';
import AutocompleteGlobalSearch from '../../../../ui/Form/AutocompleteGlobalSearch';
import ChipEntity from '../../../../ui/Chip/ChipEntity';

// actions
import searchesActionsAsync from '../../../../engine/core/tradeMarks/searches/saga/asyncAction';
import searchesActions from '../../../../engine/core/tradeMarks/searches/action';
import helpersActionAsync from '../../../../engine/core/helpers/saga/asyncAction';

// config
import selectors from '../../../../engine/config/selectors';
import accessList from '../../../../engine/config/accessList';

// helpers
import { formName, formFields, formFieldsFilter } from './helper/form';
import { splitArrayToChunks } from '../../../../engine/_helpers/splitArrays';
import { pageLinks } from '../../../../routes';

// hooks
import { useSetFieldsAutocomplete } from '../../../../ui/Form/FieldsAutocomplete/_hook/useSetFieldsAutocomplete';
import { useAccessList } from '../../../../ui/_hooks/useAccessList';

// styles
const Card = styled(MuiCard)(spacing);
const Button = styled(MuiButton)(spacing);
const Divider = styled(MuiDivider)(spacing);
// const HelpOutline = styled(HelpOutlineIcon)`
//   margin-left: ${(props) => props.theme.spacing(1)}px;
//   font-size: 17px;
// `;
const TypographySub = styled(Typography)`
  color: ${(props) => props.theme.palette.grey['500']};
`;

const Badge = styled(MuiBadge)`
  .MuiBadge-badge {
    padding: 0 4px;
    font-size: 10px;
  }
  .MuiBadge-anchorOriginTopRightRectangle {
    right: 3px;
  }
`;

const GridFilter = styled(Grid)`
  text-align: end;
`;
const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
`;
const Link = styled(MuiLink)`
  text-decoration: underline;
  color: #1976d2;
  display: flex;
  align-items: center;
`;
const LinkCleanUp = styled(LinkMui)`
  margin-left: 6px;
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
`;
const EmptyDiv = styled.div`
  height: 20px;
`;

function Search(props) {
  const {
    handleSubmit, initialize, pendingPostSearch, pendingGetSearchById,
    getSearchById: searchByIdData, match, fieldChoice, pendingSearchReport,
    searchTabs, pendingPutSearch, pathname, quickSearchItems, entityList,
    pendingPostQuickSearch, quickSearchData, objectStatuses, selectedClasses,
    pendingDownloadFile,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const getSearchById = searchByIdData.toJS();
  const { params } = match;
  const [valueTab, setValueTab] = useState(0);
  const [readySearch, setReadySearch] = useState(false);
  const [readyInitialize, setReadyInitialize] = useState(false);
  const [visibleFilterList, setVisibleFilterList] = useState(false);
  const [filterList, setFilterList] = useState(Map());
  const [pendingFilter, setPendingFilter] = useState(false);
  const isPathnameQuick = (/quick/g).test(pathname);
  const accessPut = useAccessList(accessList.search_put);
  const accessReport = useAccessList(accessList.search_report_get);
  const accessEdit = !isEmpty(params) ? accessPut : true;

  const { getEntitiesById, isReadyToUse } = useSetFieldsAutocomplete({
    initialize, data: getSearchById,
  });

  const TabPanel = (propsTab) => {
    const {
      children, value, index, ...other
    } = propsTab;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box pt={2}>
            {children}
          </Box>
        )}
      </div>
    );
  };

  const totalCountTab = useCallback((nameTab) => searchTabs[nameTab].totalCount, [
    searchTabs,
  ]);

  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };

  const getObjDateRange = (date, property) => (date ? {
    [property]: {
      from: date.split(' - ')[0],
      to: date.split(' - ')[1] || date.split(' - ')[0],
    },
  } : {});

  const getStringDateRange = (date, property) => (!isEmpty(date) ? {
    [property]: `${date?.from} - ${date?.to}`,
  } : {});

  const handleSubmitSend = (formData) => {
    const jsonFormData = formData.toJS();
    const filterFormData = pickBy(jsonFormData, (value, key) => key !== 'choice' && key !== 'results');
    delete jsonFormData.clients;
    if (isPathnameQuick) {
      dispatch(searchesActionsAsync.postQuickSearch({
        ...filterFormData,
        query: {
          ...filterFormData?.query,
          classes: fieldChoice !== 'true' ? `${selectedClasses.toJS().join(',')}` : '1-45',
          ...getObjDateRange(filterFormData?.query?.applicationDate, 'applicationDate'),
          ...getObjDateRange(filterFormData?.query?.registrationDate, 'registrationDate'),
          ...getObjDateRange(filterFormData?.query?.expiryDate, 'expiryDate'),
          ...getObjDateRange(filterFormData?.query?.prolongationDate, 'prolongationDate'),
          ...getObjDateRange(filterFormData?.query?.publicationDate, 'publicationDate'),
          ...getObjDateRange(filterFormData?.query?.initialPublication, 'initialPublication'),
        },
      }));
    } else {
      dispatch(searchesActionsAsync[isEmpty(getSearchById) ? 'postSearchAsync' : 'putSearchByIdAsync']({
        ...filterFormData,
        ...!isEmpty(getSearchById) && getSearchById.id ? {
          id: getSearchById.id,
        } : {},
        ...!isEmpty(entityList.toJS()?.clients) ? {
          clients: entityList.toJS().clients.id,
        } : {},
        ...!isEmpty(entityList.toJS()?.trademarks) ? {
          trademarks: entityList.toJS().trademarks.id,
        } : {},
        query: {
          ...filterFormData?.query,
          classes: fieldChoice !== 'true' ? `${selectedClasses.toJS().join(',')}` : '1-45',
          ...getObjDateRange(filterFormData?.query?.applicationDate, 'applicationDate'),
          ...getObjDateRange(filterFormData?.query?.registrationDate, 'registrationDate'),
          ...getObjDateRange(filterFormData?.query?.expiryDate, 'expiryDate'),
          ...getObjDateRange(filterFormData?.query?.prolongationDate, 'prolongationDate'),
          ...getObjDateRange(filterFormData?.query?.publicationDate, 'publicationDate'),
          ...getObjDateRange(filterFormData?.query?.initialPublication, 'initialPublication'),
        },
      }));
    }
  };

  const handleSearchReport = () => {
    if (getSearchById.pdf) {
      dispatch(helpersActionAsync.getDownloadFileAsync(getSearchById.pdf));
      return;
    }
    dispatch(searchesActionsAsync.getSearchReportAsync(params.hashId));
  };

  const buttonSendText = useMemo(() => {
    if (!isEmpty(getSearchById)) {
      return 'SAVE';
    }
    if (isPathnameQuick) {
      return 'SEARCH';
    }
    return 'SAVE';
  }, [
    getSearchById, isPathnameQuick,
  ]);

  const titleText = useMemo(() => {
    if (!isEmpty(params) && !isEmpty(getSearchById)) {
      return getSearchById.label || getSearchById.name;
    }
    if (isPathnameQuick) {
      return t('Quick search for trademarks');
    }
    if (isEmpty(params)) {
      return t('Search for trademarks');
    }
    return '';
  }, [
    params, t, getSearchById, isPathnameQuick,
  ]);

  useEffect(() => {
    if (!readySearch && isEmpty(getSearchById) && !isEmpty(params)) {
      setReadySearch(true);
      dispatch(searchesActionsAsync.getSearchByIdAsync(params.hashId));
    }
  }, [
    dispatch, getSearchById, params,
    readySearch, setReadySearch,
  ]);

  useEffect(() => {
    if (!readyInitialize && !isEmpty(params) && !isEmpty(getSearchById) && isReadyToUse) {
      setReadyInitialize(true);
      initialize({
        ...pickBy(getSearchById, (value, key) => key !== 'trademarks' && key !== 'clients'),
        choice: `${getSearchById?.query?.classes === '1-45'}`,
        query: {
          ...getSearchById?.query,
          ...getStringDateRange(getSearchById?.query?.applicationDate, 'applicationDate'),
          ...getStringDateRange(getSearchById?.query?.registrationDate, 'registrationDate'),
          ...getStringDateRange(getSearchById?.query?.expiryDate, 'expiryDate'),
          ...getStringDateRange(getSearchById?.query?.prolongationDate, 'prolongationDate'),
          ...getStringDateRange(getSearchById?.query?.publicationDate, 'publicationDate'),
          ...getStringDateRange(getSearchById?.query?.initialPublication, 'initialPublication'),
        },
      });
      dispatch(searchesActions.setSearchEntityList(getEntitiesById));
      if (getSearchById?.query?.classes && getSearchById?.query?.classes !== '1-45') {
        dispatch(searchesActions.setSelectedClasses(getSearchById.query.classes.split(',')));
      }
    }
  }, [
    getSearchById, initialize, params, isReadyToUse,
    setReadyInitialize, readyInitialize, dispatch, getEntitiesById,
  ]);

  useEffect(() => {
    if (!readyInitialize && isEmpty(params)) {
      setReadyInitialize(true);
      initialize({
        choice: 'true',
        // type: '1',
      });
    }
  }, [
    initialize, params,
    setReadyInitialize, readyInitialize,
  ]);

  useEffect(() => function cleanup() {
    const emptyData = {
      totalCount: 0,
      pageSize: 0,
      items: List(),
      numberPerPage: 20,
      filtered: List(),
      selectedBlocks: [],
    };
    dispatch(searchesActions.setQuickSearchData({
      isEmpty: false,
      items: List(),
    }));
    dispatch(searchesActions.setSelectedClasses([]));
    dispatch(searchesActions.setTabs('national', emptyData));
    dispatch(searchesActions.setTabs('madrid', emptyData));
    dispatch(searchesActions.setSearchById({
      data: Map(),
    }));
    dispatch(searchesActions.setSearchEntityList({}));
  }, [dispatch]);

  const getDate = (value) => moment(value, 'DD.MM.YYYY');

  useEffect(() => {
    const arrayItem = getSearchById.results || quickSearchItems.toJS();
    if (!isEmpty(filterList.toJS())) {
      const uniqFilterTab = filter(arrayItem, (item) => {
        const itemFilter = map(Object.keys(filterList.toJS()), (key) => {
          if (key === 'status' || key === 'active') {
            return item[key] === Math.abs(filterList.toJS()[key]);
          }
          if ((key === 'applicationDate' || key === 'registrationDate')
            && isArray(filterList.toJS()[key]) && !isEmpty(item[key])) {
            return getDate(filterList.toJS()[key][0]) <= getDate(item[key])
              && getDate(item[key]) <= getDate(filterList.toJS()[key][1]);
          }
          return includes(toLower(item[key]), toLower(filterList.toJS()[key]));
        });
        return !includes(itemFilter, false);
      });
      const resultsNational = filter(uniqFilterTab, { type: 'national' });
      const resultsMadrid = filter(uniqFilterTab, { type: 'madrid' });
      const groupNational = !isEmpty(resultsNational)
        ? splitArrayToChunks(resultsNational, 20) : [];
      const groupMadrid = !isEmpty(resultsNational) ? splitArrayToChunks(resultsMadrid, 20) : [];
      dispatch(searchesActions.setTabs('national', {
        filtered: List(resultsNational),
        items: List(groupNational),
        totalCount: !isEmpty(resultsNational) ? size(resultsNational) : 0,
        numberPerPage: 20,
        pageSize: 0,
      }));
      dispatch(searchesActions.setTabs('madrid', {
        filtered: List(resultsMadrid),
        items: List(groupMadrid),
        totalCount: !isEmpty(resultsMadrid) ? size(resultsMadrid) : 0,
        numberPerPage: 20,
        pageSize: 0,
      }));
    } else {
      const tabNational = filter(arrayItem, { type: 'national' });
      const tabMadrid = filter(arrayItem, { type: 'madrid' });
      const groupNational = !isEmpty(tabNational) ? splitArrayToChunks(tabNational, 20) : [];
      const groupMadrid = !isEmpty(tabMadrid) ? splitArrayToChunks(tabMadrid, 20) : [];
      dispatch(searchesActions.setTabs('national', {
        filtered: List(tabNational),
        items: List(groupNational),
        totalCount: !isEmpty(tabNational) ? size(tabNational) : 0,
        numberPerPage: 20,
        pageSize: 0,
      }));
      dispatch(searchesActions.setTabs('madrid', {
        filtered: List(tabMadrid),
        items: List(groupMadrid),
        totalCount: !isEmpty(tabMadrid) ? size(tabMadrid) : 0,
        numberPerPage: 20,
        pageSize: 0,
      }));
    }
    setPendingFilter(false);
  }, [filterList, dispatch]);// eslint-disable-line

  const handleFilterList = () => {
    if (visibleFilterList) {
      dispatch(change(formName, formFieldsFilter.title, null));
      dispatch(change(formName, formFieldsFilter.applicationNumber, null));
      dispatch(change(formName, formFieldsFilter.registrationNumber, null));
      dispatch(change(formName, formFieldsFilter.applicationDate, null));
      dispatch(change(formName, formFieldsFilter.registrationDate, null));
      dispatch(change(formName, formFieldsFilter.classesFilter, null));
      dispatch(change(formName, formFieldsFilter.holder, null));
      dispatch(change(formName, formFieldsFilter.status, null));
      setFilterList(Map());
    }
    setVisibleFilterList(!visibleFilterList);
  };

  const handleChangeTextInput = (name, event) => {
    setPendingFilter(true);
    setTimeout(() => {
      if (!isEmpty(event.target.value)) {
        setFilterList(merge(filterList, Map({
          [name]: event.target.value,
        })));
      } else {
        setFilterList(remove(filterList, name));
      }
    }, 1500);
  };

  const handleChangeDateInput = (name, value) => {
    setPendingFilter(true);
    setTimeout(() => {
      if (!isEmpty(value)) {
        setFilterList(merge(filterList, Map({
          [name]: value.split(' - ').length === 2 ? value.split(' - ') : value,
        })));
      } else {
        setFilterList(remove(filterList, name));
      }
    }, 1000);
  };

  const clearInput = (name) => {
    dispatch(change(formName, name, null));
    setFilterList(remove(filterList, name));
  };

  const handleSelectedClass = (id) => {
    if (includes(selectedClasses.toJS(), id)) {
      dispatch(searchesActions.setSelectedClasses(without(selectedClasses.toJS(), id)));
      return;
    }
    dispatch(searchesActions.setSelectedClasses([...selectedClasses.toJS(), id]));
  };

  const handleCleanUpSelectedClass = () => {
    dispatch(searchesActions.setSelectedClasses([]));
  };

  const handleChangeEntity = (item) => {
    if (!isEmpty(item)) {
      dispatch(searchesActions.mergeSearchEntityList({
        entityName: item.entity,
        items: item,
      }));
    }
  };

  const handleDeleteEntity = (entityName, id) => {
    if (entityName && id) {
      dispatch(searchesActions.mergeSearchEntityList({
        entityName,
        items: {},
      }));
    }
  };

  return !pendingGetSearchById ? (
    <>
      <Helmet title={titleText} />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h3" gutterBottom display="inline">
          {titleText}
        </Typography>
        <Link to={pageLinks.tradeMarksRoutes.searches.all}>
          <NavigateBeforeIcon size="small" color="primary" />
          {t('Up to the list')}
        </Link>
      </Box>
      <Divider my={6} />

      <Form onSubmit={handleSubmit(handleSubmitSend)}>
        {!isPathnameQuick && (
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" paragraph>
                {t('Connections')}
              </Typography>
              <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                  <AutocompleteGlobalSearch
                    label={t('Add connections')}
                    handleChange={handleChangeEntity}
                    helperText={t('Enter the name of the customer, № case, № application, № registration or name')}
                    disableIcon
                    disabled={!accessEdit}
                    entities={
                      ['trademarks', 'clients']
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  {!isEmpty(entityList.toJS()) && (
                    map(Object.keys(entityList.toJS()), (entity, index) => {
                      if (!isEmpty(entityList.toJS()[entity])) {
                        const item = entityList.toJS()[entity];
                        return (
                          <Grid key={index}>
                            <TypographySub variant="subtitle1" gutterBottom>
                              {t(entity)}
                            </TypographySub>
                            <ChipEntity
                              id={item.id}
                              entity={entity}
                              label={item.label || item.title || item.id}
                              handleDelete={handleDeleteEntity}
                              disabled={!accessEdit}
                            />
                          </Grid>
                        );
                      }
                      return null;
                    })
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}

        <Card mb={6}>
          <CardContent>
            <Typography variant="h4" paragraph>
              {t('Parameters')}
            </Typography>

            <TypographySub variant="subtitle1">
              {t('Classes')}
              {' '}
              {t('ICGS')}
            </TypographySub>

            <Field
              aria-label={formFields.choice}
              name={formFields.choice}
              component={RadioButton}
              row
              disabled={!accessEdit}
            >
              <FormControlLabel value="true" control={<Radio />} label={t('All')} />
              <FormControlLabel value="false" control={<Radio />} label={t('Selected')} />
            </Field>

            {(fieldChoice !== 'true') && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {map(range(1, 46), (id) => {
                    const color = includes(selectedClasses.toJS(), `${id}`)
                      ? { color: 'primary' }
                      : { color: 'default' };
                    return (
                      <Button
                        key={id}
                        m={1}
                        {...color}
                        variant="contained"
                        aria-controls={id}
                        aria-haspopup="true"
                        onClick={() => handleSelectedClass(`${id}`)}
                        disabled={!accessEdit}
                      >
                        {id}
                      </Button>
                    );
                  })}
                </Grid>
                {!isEmpty(selectedClasses.toJS()) && accessEdit ? (
                  <Grid item xs={12}>
                    <LinkCleanUp to="/" onClick={handleCleanUpSelectedClass}>
                      {t('Clean up')}
                    </LinkCleanUp>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <EmptyDiv />
                  </Grid>
                )}
              </Grid>
            )}

            <br />
            {/* <TypographySub variant="subtitle1">
              {t('Request type')}
            </TypographySub>

            <Field
              aria-label={formFields.type}
              name={formFields.type}
              component={RadioButton}
              row
              disabled={!accessEdit}
            >
              <FormControlLabel
                value="1"
                control={<Radio />}
                label={(
                  <Grid container alignItems="center">
                    {t('Automatic')}
                    {' '}
                    <Tooltip title={t('test tooltip')} arrow placement="top">
                      <HelpOutline ml={1} fontSize="small" color="primary" />
                    </Tooltip>
                  </Grid>
              )}
              />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label={(
                  <Grid container alignItems="center">
                    {t('Manual')}
                    {' '}
                    <Tooltip title={t('test tooltip')} arrow placement="top">
                      <HelpOutline ml={1} fontSize="small" color="primary" />
                    </Tooltip>
                  </Grid>
              )}
              />
            </Field> */}

            <Field
              id={formFields.keywords}
              name={formFields.keywords}
              label={t('Request')}
              variant="standard"
              margin="normal"
              fullWidth
              component={RenderTextField}
              disabled={!accessEdit}
            />
            <Grid container spacing={4}>
              <Grid item xs={12} md={3}>
                <Field
                  name={formFields.goodsServices}
                  id={formFields.goodsServices}
                  label={t('Mktu terms')}
                  margin="normal"
                  variant="standard"
                  my={1}
                  fullWidth
                  type="text"
                  component={RenderTextField}
                  disabled={!accessEdit}
                />
                <Field
                  name={formFields.registrationDate}
                  id={formFields.registrationDate}
                  label={t('Registration date')}
                  margin="normal"
                  variant="standard"
                  my={1}
                  fullWidth
                  type="text"
                  defaultFormat="YYYY-MM-DD"
                  component={ReduxDateRange}
                  disabled={!accessEdit}
                />

                <Field
                  name={formFields.registrationStatus}
                  id={formFields.registrationStatus}
                  label={t('Trademark registration status')}
                  margin="normal"
                  variant="standard"
                  my={1}
                  fullWidth
                  type="text"
                  component={RenderTextField}
                  disabled={!accessEdit}
                />
                <Field
                  name={formFields.holders}
                  id={formFields.holders}
                  label={t('Holders')}
                  margin="normal"
                  variant="standard"
                  my={1}
                  fullWidth
                  type="text"
                  component={RenderTextField}
                  disabled={!accessEdit}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <Field
                  name={formFields.applicationNumber}
                  id={formFields.applicationNumber}
                  label={t('Application number')}
                  margin="normal"
                  variant="standard"
                  my={1}
                  fullWidth
                  type="text"
                  component={RenderTextField}
                  disabled={!accessEdit}
                />
                <Field
                  name={formFields.expiryDate}
                  id={formFields.expiryDate}
                  label={t('End date')}
                  margin="normal"
                  variant="standard"
                  my={1}
                  fullWidth
                  type="text"
                  defaultFormat="YYYY-MM-DD"
                  component={ReduxDateRange}
                  disabled={!accessEdit}
                />
                <Field
                  name={formFields.publicationDate}
                  id={formFields.publicationDate}
                  label={t('Date of publication of the certificate')}
                  margin="normal"
                  variant="standard"
                  my={1}
                  fullWidth
                  type="text"
                  defaultFormat="YYYY-MM-DD"
                  component={ReduxDateRange}
                  disabled={!accessEdit}
                />
                <Field
                  name={formFields.representative}
                  id={formFields.representative}
                  label={t('Representative data')}
                  margin="normal"
                  variant="standard"
                  my={1}
                  fullWidth
                  type="text"
                  component={RenderTextField}
                  disabled={!accessEdit}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <Field
                  name={formFields.applicationDate}
                  id={formFields.applicationDate}
                  label={t('Date of submission')}
                  margin="normal"
                  variant="standard"
                  my={1}
                  fullWidth
                  type="text"
                  defaultFormat="YYYY-MM-DD"
                  component={ReduxDateRange}
                  disabled={!accessEdit}
                />
                <Field
                  name={formFields.prolongationDate}
                  id={formFields.prolongationDate}
                  label={t('Trademark renewal date')}
                  margin="normal"
                  variant="standard"
                  my={1}
                  fullWidth
                  type="text"
                  defaultFormat="YYYY-MM-DD"
                  component={ReduxDateRange}
                  disabled={!accessEdit}
                />
                <Field
                  name={formFields.publicationBulletin}
                  id={formFields.publicationBulletin}
                  label={t('Bulletin number')}
                  margin="normal"
                  variant="standard"
                  my={1}
                  fullWidth
                  type="text"
                  component={RenderTextField}
                  disabled={!accessEdit}
                />
                <Field
                  name={formFields.correspondence}
                  id={formFields.correspondence}
                  label={t('Address for exchanging mail')}
                  margin="normal"
                  variant="standard"
                  my={1}
                  fullWidth
                  type="text"
                  component={RenderTextField}
                  disabled={!accessEdit}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <Field
                  name={formFields.registrationNumber}
                  id={formFields.registrationNumber}
                  label={t('Registration number')}
                  margin="normal"
                  variant="standard"
                  my={1}
                  fullWidth
                  type="text"
                  component={RenderTextField}
                  disabled={!accessEdit}
                />

                <Field
                  name={formFields.applicationStatus}
                  id={formFields.applicationStatus}
                  label={t('Trademark application status')}
                  margin="normal"
                  variant="standard"
                  my={1}
                  fullWidth
                  type="text"
                  component={RenderTextField}
                  disabled={!accessEdit}
                />
                <Field
                  name={formFields.applicants}
                  id={formFields.applicants}
                  label={t('Applicants')}
                  margin="normal"
                  variant="standard"
                  my={1}
                  fullWidth
                  type="text"
                  component={RenderTextField}
                  disabled={!accessEdit}
                />
                <Field
                  name={formFields.initialPublication}
                  id={formFields.initialPublication}
                  label={t('Date of publication of the application')}
                  margin="normal"
                  variant="standard"
                  my={1}
                  fullWidth
                  type="text"
                  defaultFormat="YYYY-MM-DD"
                  component={ReduxDateRange}
                  disabled={!accessEdit}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {!isPathnameQuick && (
          <Card>
            <CardContent>
              <Typography variant="h4" paragraph>
                {t('Conclusion')}
              </Typography>
              <Field
                fullWidth
                multiline
                rows={6}
                name={formFields.conclusion}
                label={t('Conclusion')}
                margin="normal"
                variant="outlined"
                component={RenderTextField}
                disabled={!accessEdit}
              />
            </CardContent>
          </Card>
        )}

        {accessEdit && (
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={pendingPostSearch || pendingPutSearch || pendingPostQuickSearch}
            >
              {pendingPostSearch || pendingPutSearch || pendingPostQuickSearch
                ? <CircularProgress size={25} color="inherit" />
                : t(buttonSendText)}
            </Button>
          </Box>
        )}
      </Form>

      {(!isEmpty(getSearchById) && !isEmpty(getSearchById.results))
      || (!isEmpty(quickSearchItems.toJS()) && isPathnameQuick)
        ? (
          <>
            <Box mt={10}>
              <Grid container spacing={6}>
                <Grid container item xs={10} alignItems="center">
                  <Typography variant="h4">
                    {t('Results')}
                  </Typography>
                  {!isPathnameQuick && accessReport && (
                    <Box ml={4} mr={2}>
                      <Button
                        size="small"
                        color="primary"
                        disabled={pendingSearchReport || pendingDownloadFile}
                        onClick={handleSearchReport}
                      >
                        {t(getSearchById.pdf ? 'Download to PDF' : 'Unload to PDF')}
                      </Button>
                    </Box>
                  )}
                  {(pendingSearchReport || pendingDownloadFile) && <CircularProgress size={20} color="primary" />}
                </Grid>

                <GridFilter item xs={2}>
                  <Tooltip title={t('Filter list')} arrow>
                    <IconButton aria-label="Filter list" size="small" onClick={handleFilterList}>
                      <FilterListIcon />
                    </IconButton>
                  </Tooltip>
                </GridFilter>
              </Grid>
            </Box>
            {visibleFilterList && (
              <>
                <Divider my={3} />
                <Form onSubmit={handleSubmit(() => {})}>
                  <Grid container spacing={6}>
                    <Grid item xs={12} md={3}>
                      <Field
                        id={formFieldsFilter.title}
                        name={formFieldsFilter.title}
                        label={t('Title')}
                        margin="normal"
                        fullWidth
                        component={RenderTextField}
                        onChange={(e) => handleChangeTextInput(formFieldsFilter.title, e)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {!isEmpty(filterList.toJS())
                                && !isEmpty(filterList.toJS()[formFieldsFilter.title]) && (
                                <IconButton
                                  onClick={() => clearInput(formFieldsFilter.title)}
                                >
                                  <ClearIcon />
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Field
                        id={formFieldsFilter.registrationDate}
                        name={formFieldsFilter.registrationDate}
                        label={t('Registration date')}
                        margin="normal"
                        fullWidth
                        type="text"
                        component={ReduxDateRange}
                        onChange={(e) => handleChangeDateInput(
                          formFieldsFilter.registrationDate, e,
                        )}
                      />
                      {!isEmpty(filterList.toJS()) && (
                        <Button
                          mt={2}
                          variant="contained"
                          color="primary"
                          onClick={handleFilterList}
                        >
                          {t('RESET FILTERS')}
                        </Button>
                      )}
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Field
                        id={formFieldsFilter.applicationNumber}
                        name={formFieldsFilter.applicationNumber}
                        label={t('Application number')}
                        margin="normal"
                        fullWidth
                        component={RenderTextField}
                        onChange={(e) => handleChangeTextInput(
                          formFieldsFilter.applicationNumber, e,
                        )}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {!isEmpty(filterList.toJS())
                                && !isEmpty(filterList.toJS()[
                                  formFieldsFilter.applicationNumber
                                ]) && (
                                <IconButton
                                  onClick={() => clearInput(formFieldsFilter.applicationNumber)}
                                >
                                  <ClearIcon />
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Field
                        id={formFieldsFilter.classesFilter}
                        name={formFieldsFilter.classesFilter}
                        label={t('Classes')}
                        margin="normal"
                        fullWidth
                        component={RenderTextField}
                        onChange={(e) => handleChangeTextInput('classes', e)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {!isEmpty(filterList.toJS())
                                && !isEmpty(filterList.toJS().classes) && (
                                <IconButton
                                  onClick={() => {
                                    dispatch(change(formName,
                                      formFieldsFilter.classesFilter, null));
                                    setFilterList(remove(filterList, 'classes'));
                                  }}
                                >
                                  <ClearIcon />
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Field
                        id={formFieldsFilter.registrationNumber}
                        name={formFieldsFilter.registrationNumber}
                        label={t('Registration number')}
                        margin="normal"
                        fullWidth
                        component={RenderTextField}
                        onChange={
                          (e) => handleChangeTextInput(formFieldsFilter.registrationNumber, e)
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {!isEmpty(filterList.toJS())
                                && !isEmpty(filterList.toJS()[
                                  formFieldsFilter.registrationNumber
                                ]) && (
                                <IconButton
                                  onClick={() => clearInput(formFieldsFilter.registrationNumber)}
                                >
                                  <ClearIcon />
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Field
                        id={formFieldsFilter.holder}
                        name={formFieldsFilter.holder}
                        label={t('Owner(s)')}
                        margin="normal"
                        fullWidth
                        component={RenderTextField}
                        onChange={(e) => handleChangeTextInput(formFieldsFilter.holder, e)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {!isEmpty(filterList.toJS())
                                && !isEmpty(filterList.toJS()[formFieldsFilter.holder]) && (
                                <IconButton
                                  onClick={() => clearInput(formFieldsFilter.holder)}
                                >
                                  <ClearIcon />
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Field
                        id={formFieldsFilter.applicationDate}
                        name={formFieldsFilter.applicationDate}
                        label={t('Date of submission')}
                        margin="normal"
                        fullWidth
                        type="text"
                        component={ReduxDateRange}
                        onChange={(e) => handleChangeDateInput(formFieldsFilter.applicationDate, e)}
                      />
                      <Field
                        name={formFieldsFilter.status}
                        id={formFieldsFilter.status}
                        label={t('Status')}
                        labelId={formFieldsFilter.status}
                        margin="normal"
                        items={filter(objectStatuses.toJS(), (item) => item.id !== 1
                          && item.id !== 2 && item.id !== 4 && item.id !== 5 && item.id !== 6)}
                        displayEmpty
                        fullWidth
                        component={Select}
                        onChange={(e) => handleChangeTextInput(formFieldsFilter.status, e)}
                      />
                    </Grid>
                  </Grid>
                </Form>
              </>
            )}

            <Divider my={3} />
            {!pendingFilter ? (
              <>
                <Tabs
                  value={valueTab}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <Tab label={(
                    <Badge badgeContent={totalCountTab('national')} color="secondary">
                      {t('National procedure')}
                    </Badge>
                  )}
                  />
                  <Tab label={(
                    <Badge badgeContent={totalCountTab('madrid')} color="secondary">
                      {t('Madrid procedure')}
                    </Badge>
                  )}
                  />
                </Tabs>

                <TabPanel value={valueTab} index={0}>
                  <SearchCardTabs nameTab="national" disabledButtonChoose={isPathnameQuick || !accessReport} />
                </TabPanel>

                <TabPanel value={valueTab} index={1}>
                  <SearchCardTabs nameTab="madrid" disabledButtonChoose={isPathnameQuick || !accessReport} />
                </TabPanel>
              </>
            ) : (
              <Root>
                <CircularProgress />
              </Root>
            )}
          </>
        ) : (
          <Grid container justify="center">
            {(!isEmpty(getSearchById) || (isPathnameQuick && quickSearchData.toJS().isEmpty)) && (
              <Box m={4}>
                <Typography variant="h4">
                  {t('No data')}
                </Typography>
              </Box>
            )}
          </Grid>
        )}
    </>
  ) : <Loader />;
}

Search.displayName = 'Search';

Search.propTypes = {
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  getSearchById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  quickSearchData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  quickSearchItems: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  searchTabs: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pathname: PropTypes.string.isRequired,
  pendingPostQuickSearch: PropTypes.bool.isRequired,
  pendingPostSearch: PropTypes.bool.isRequired,
  pendingPutSearch: PropTypes.bool.isRequired,
  pendingSearchReport: PropTypes.bool.isRequired,
  pendingGetSearchById: PropTypes.bool.isRequired,
  fieldChoice: PropTypes.string,
  match: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  objectStatuses: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  selectedClasses: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  entityList: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingDownloadFile: PropTypes.bool.isRequired,
};

Search.defaultProps = {
  match: {},
  fieldChoice: '',
};

function mapStateToProps(state) {
  return {
    pathname: selectors.router.pathname(state),
    getSearchById: selectors.tradeMarksSearches.getSearchById(state),
    quickSearchData: selectors.tradeMarksSearches.quickSearchData(state),
    quickSearchItems: selectors.tradeMarksSearches.quickSearchItems(state),
    pendingPostQuickSearch: selectors.tradeMarksSearches.pendingPostQuickSearch(state),
    searchTabs: selectors.tradeMarksSearches.searchTabs(state),
    pendingGetSearchById: selectors.tradeMarksSearches.pendingGetSearchById(state),
    pendingPostSearch: selectors.tradeMarksSearches.pendingPostSearch(state),
    pendingPutSearch: selectors.tradeMarksSearches.pendingPutSearch(state),
    pendingSearchReport: selectors.tradeMarksSearches.pendingSearchReport(state),
    fieldChoice: selectors.form.getFormValues(state, formName).get(formFields.choice),
    objectStatuses: selectors.helpers.getObjectStatuses(state),
    selectedClasses: selectors.tradeMarksSearches.selectedClasses(state),
    entityList: selectors.tradeMarksSearches.entityList(state),
    pendingDownloadFile: selectors.helpers.pendingDownloadFile(state),
  };
}

export default compose(
  reduxForm({
    form: formName,
  }),
  connect(mapStateToProps, null),
)(memo(Search));
