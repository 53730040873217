// core
import React, {
  memo, useCallback, useEffect, useState, useMemo,
} from 'react';
import { spacing } from '@material-ui/system';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import { Field, change } from 'redux-form/immutable';
import { Map, List } from 'immutable';
import { Link as MuiLink } from 'react-router-dom';

// lodash
import {
  isEqual, isEmpty, map, isArray, find,
  filter, pullAt,
  forEach,
} from 'lodash';

// UI
import {
  CardContent, Grid as MuiGrid, Tooltip,
  Typography as MuiTypography, Card as MuiCard,
  IconButton as MuiIconButton, Box,
} from '@material-ui/core';

// icon
import {
  Delete as DeleteIcon,
} from '@material-ui/icons';

// actions
import tagsAction from '../../../../../engine/core/tags/action';
import tagsAsyncAction from '../../../../../engine/core/tags/saga/asyncAction';
import employeesActions from '../../../../../engine/core/company/employees/action';
import employeesTableActions from '../../../../../engine/core/company/employees/table/action';
import employeesAsyncActions from '../../../../../engine/core/company/employees/table/saga/asyncAction';
import docTypesAsyncActions from '../../../../../engine/core/documentTypes/saga/asyncAction';
import docTypesActions from '../../../../../engine/core/documentTypes/action';
import jurisdictionsActionsAsync from '../../../../../engine/core/jurisdictions/saga/asyncAction';
import tradeMarksActions from '../../../../../engine/core/tradeMarks/proposal/action';
import tradeMarksAsyncActions from '../../../../../engine/core/tradeMarks/proposal/saga/asyncAction';

// config
import selectors from '../../../../../engine/config/selectors';

// parts
import FieldEmployee from '../../../../../ui/Form/FieldsAutocomplete/FieldEmployee';
import RenderQuill from '../../../../../ui/Form/RenderQuill';
import RenderTextField from '../../../../../ui/Form/RenderTextField';
import FieldTags from '../../../../../ui/Form/FieldsAutocomplete/FieldTags';
import Select from '../../../../../ui/Form/Select';
import AutocompleteField from '../../../../../ui/Form/AutocompleteField';
import ButtonSubmit from '../../../../../ui/Button/ButtonSubmit';

// hooks
import { useEventsAutocompleteAsync } from '../../../../../ui/_hooks/useEventsAutocompleteAsync';
import { pageLinks } from '../../../../../routes';
import { useAccessList } from '../../../../../ui/_hooks/useAccessList';
import accessList from '../../../../../engine/config/accessList';
import DxTable from '../../../../../ui/Table/DxTable';

// helpers
// import { validators } from '../../../../../ui/_helpers/validation';

// styles
const Card = styled(MuiCard)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);
const IconButton = styled(MuiIconButton)(spacing);
const TypographySub = styled(MuiTypography)`
  color: ${(props) => props.theme.palette.grey['500']};
`;
const LinkMadrid = styled(MuiLink)`
  text-decoration: underline;
`;

function CardFooter(props) {
  const {
    formName, pending, formFields, allCompanies, jurisdictionsData,
    newTag, tagsValues, responsibleEmployeeById, fieldResponsible,
    match, getProposalById, selectedCompany, employeesData, proposalData,
    listResponsible, objectType, documentTypesData, disabled,
  } = props;
  const {
    getAutocompleteLists,
    handleGetAsyncData,
  } = useEventsAutocompleteAsync();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isListEmployees, setIsListEmployees] = useState(false);
  const [isInit, setIsInit] = useState(false);
  const [isList, setIsList] = useState(false);
  const [listTrademarks, setListTrademarks] = useState([]);
  const { params } = match;
  const itemAll = { value: 'all', label: t('All') };
  const accessTrademarksListGet = useAccessList(accessList.trademarks_list_get);
  const accessCompanyList = useAccessList(accessList.company_list_get);

  useEffect(() => function cleanup() {
    dispatch(employeesActions.setListResponsible([]));
    dispatch(docTypesActions.setDocumentTypesData({
      items: [],
      totalCount: 0,
      pending: false,
    }));
    const emptyData = { items: [], totalCount: 0, pending: false };
    dispatch(tradeMarksActions.setProposalData(emptyData));
  }, [dispatch]);

  useEffect(() => {
    if (objectType) {
      dispatch(docTypesAsyncActions.getListAsync({
        'objectType:typeRequest:isNull': objectType,
        'objectType:typeRequest:=': objectType,
        connector: ['or', 'or'],
        'type:typeRequest:=': 'in',
        limit: 10000,
      }));
    }
  }, [
    objectType, dispatch,
  ]);

  useEffect(() => {
    if (isEmpty(params)) {
      dispatch(jurisdictionsActionsAsync.getListAsync());
    }
  }, [dispatch, params]);

  useEffect(() => {
    if (isEmpty(params) && !isEmpty(jurisdictionsData.toJS().items)) {
      const jurisdiction = find(jurisdictionsData.toJS().items, (item) => item.code === 'ua' || item.code === 'UA');
      dispatch(change(formName, formFields.jurisdictions, jurisdiction));
    }
  }, [jurisdictionsData, dispatch, params, formFields, formName]);

  useEffect(() => {
    if (!isEmpty(getProposalById.toJS())
      && !isEmpty(getProposalById.toJS().responsible) && !isListEmployees) {
      dispatch(employeesAsyncActions.getListAsync());
      setIsListEmployees(true);
    }
  }, [getProposalById, dispatch, isListEmployees]);

  useEffect(() => {
    if (!isEmpty(getProposalById.toJS()) && !isEmpty(documentTypesData.toJS().items)
    && !isEmpty(getProposalById.toJS().responsible)) {
      forEach(getProposalById.toJS().responsible, (item, index) => {
        const documentClassifier = isArray(item.documentClassifier)
          ? map(item.documentClassifier, (id) => find(documentTypesData.toJS().items, { id }))
          : [itemAll];
        dispatch(change(formName, `responsible[${index}].documentClassifier`, documentClassifier));
      });
    }
  }, [dispatch, getProposalById, formName, documentTypesData, pending]);// eslint-disable-line

  useEffect(() => {
    if (!isEmpty(getProposalById.toJS()) && !isEmpty(getProposalById.toJS().responsible)
      && !isEmpty(employeesData.toJS().items) && !isInit) {
      const list = map(getProposalById.toJS().responsible, (item) => {
        const employee = find(employeesData.toJS().items, { id: item.employeeId });
        return employee;
      });
      dispatch(employeesActions.setListResponsible(list || []));
      dispatch(employeesTableActions.setEmployeesData({
        items: [],
        totalCount: 0,
        pending: false,
      }));
      setIsInit(true);
    }
  }, [employeesData, dispatch, getProposalById, formName, isInit]);

  useEffect(() => {
    if (isEmpty(params) && !isEmpty(allCompanies.toJS()) && isEmpty(getProposalById.toJS())) {
      dispatch(change(formName, formFields.company, selectedCompany || 1));
    }
  }, [
    dispatch, selectedCompany, params, allCompanies,
    getProposalById, formName, formFields,
  ]);

  useEffect(() => {
    if (!isEmpty(responsibleEmployeeById.toJS())) {
      dispatch(change(formName, formFields.usersId, responsibleEmployeeById.toJS()));
      dispatch(employeesActions.setResponsibleEmployeeById({}));
    }
  }, [dispatch, responsibleEmployeeById, formName, formFields]);

  const handleCreateNewTag = useCallback((value) => {
    dispatch(tagsAction.setIsModalOpen(true));
    if (value) dispatch(tagsAction.setDefaultName(value));
  }, [dispatch]);

  const handleEditTag = useCallback((tag) => {
    if (tag) {
      dispatch(tagsAction.setTagById({ data: Map(tag) }));
      dispatch(tagsAction.setIsModalOpen(true));
    }
  }, [dispatch]);

  const handleDeleteTag = useCallback((tag) => {
    if (tag && tag.id) dispatch(tagsAsyncAction.deleteTagAsync(tag.id));
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(newTag.toJS())) {
      dispatch(change(formName, formFields.tags, ''));
      setTimeout(() => {
        dispatch(change(formName, formFields.tags, [
          ...tagsValues.toJS ? tagsValues.toJS() : tagsValues,
          newTag.toJS(),
        ]));
      });
      dispatch(tagsAction.setNewTag({}));
    }
  }, [dispatch, tagsValues, newTag, formName, formFields]);

  const handleUsersGetSelectOption = (elem) => {
    if (!isEmpty(elem)) {
      dispatch(employeesActions.setListResponsible([
        ...listResponsible.toJS(),
        elem,
      ]));
      setTimeout(() => {
        dispatch(change(formName, 'users', ''));
        dispatch(change(formName, `responsible[${listResponsible.size}].documentClassifier`, [itemAll]));
      });
    }
  };
  const handleDeleteUsers = (index) => {
    const list = [...listResponsible.toJS()];
    pullAt(list, index);
    const responsible = [...fieldResponsible.toJS ? fieldResponsible.toJS() : fieldResponsible];
    pullAt(responsible, index);
    dispatch(employeesActions.setListResponsible(list));
    dispatch(change(formName, 'responsible', List([])));
    setTimeout(() => {
      dispatch(change(formName, 'responsible', List(responsible)));
    }, 20);
  };
  const handleChange = (values, index) => {
    const findItemAll = find(values, { value: 'all' });
    if (findItemAll || isEmpty(values)) {
      const filterItems = values[0]?.value === 'all' && values.length > 1 ? filter(values, 'id') : [itemAll];
      setTimeout(() => {
        dispatch(change(formName, `responsible[${index}].documentClassifier`, null));
        dispatch(change(formName, `responsible[${index}].documentClassifier`, filterItems));
      });
    }
  };

  const getMadridCase = useMemo(() => {
    const madridCase = isArray(getProposalById.toJS().madridCases)
      ? getProposalById.toJS().madridCases[0] : getProposalById.toJS().madridCases;
    return madridCase || {};
  }, [getProposalById]);

  useEffect(() => {
    if (!isList && !isEmpty(getMadridCase?.relations) && objectType === '1'
      && !isEmpty(params) && !isEmpty(proposalData.toJS().items)) {
      setIsList(true);
      const arrayTM = map(getMadridCase.relations || [],
        (id) => find(proposalData.toJS().items, { id }) || { id });
      setListTrademarks(arrayTM);
      const emptyData = { items: [], totalCount: 0, pending: false };
      dispatch(tradeMarksActions.setProposalData(emptyData));
    }
  }, [dispatch, getMadridCase, isList, params, proposalData, objectType]);

  useEffect(() => {
    if (!isEmpty(params) && accessTrademarksListGet && objectType === '1') {
      dispatch(tradeMarksAsyncActions.getListAsync({ limit: 100, page: 1 }));
    }
  }, [dispatch, params, accessTrademarksListGet, objectType]);

  const columnsTrademarks = [
    { name: 'id', title: t('ID') },
    { name: 'caseNumber', title: t('Case number') },
    { name: 'jurisdictions', title: t('Jurisdiction') },
    { name: 'madridType', title: t('Type') },
    { name: 'status', title: t('Status') },
    { name: 'lastDocuments', title: t('Input document') },
    { name: 'pendingActions', title: t('Action up to') },
  ];

  return (
    <>
      <Card mb={6}>
        <CardContent>
          <Typography variant="h2" gutterBottom>
            {t('Responsible persons')}
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <TypographySub variant="subtitle1">
                {t('Responsible for the case')}
              </TypographySub>
              <FieldEmployee
                name={formFields.usersId}
                label="Search / Select employee"
                getAutocompleteLists={getAutocompleteLists}
                getAsyncData={handleGetAsyncData}
                formName={formName}
                propsField={{ disabled }}
              />
              <TypographySub variant="subtitle1">
                {t('Responsible for documents')}
              </TypographySub>
              <FieldEmployee
                name="users"
                label="Search / Select employee"
                getAutocompleteLists={getAutocompleteLists}
                getAsyncData={handleGetAsyncData}
                formName={formName}
                propsField={{
                  getSelectOption: handleUsersGetSelectOption,
                  disabled,
                }}
              />
            </Grid>
            {map(listResponsible.toJS(), (employee, index) => (
              <Grid key={index} container alignItems="flex-end">
                <Grid item xs={12} md={4}>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    {!disabled && (
                      <IconButton
                        mx={2}
                        aria-label={t('Delete')}
                        size="small"
                        onClick={() => {
                          handleDeleteUsers(index);
                        }}
                      >
                        <Tooltip title={t('Delete')} placement="top-start">
                          <DeleteIcon size="small" />
                        </Tooltip>
                      </IconButton>
                    )}
                    <Box component="span" fontWeight="fontWeightBold" mr={5} ml={5}>
                      {employee?.name || employee?.label}
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} md={8}>
                  <Field
                    multiple
                    name={`responsible[${index}].documentClassifier`}
                    id={`responsible[${index}].documentClassifier`}
                    labelId={`responsible[${index}].documentClassifier`}
                    component={AutocompleteField}
                    type="text"
                    label={t('Type')}
                    margin="none"
                    fullWidth
                    options={[
                      itemAll,
                      ...documentTypesData.toJS().items,
                    ]}
                    onChange={(e) => handleChange(e, index)}
                    disabled={disabled}
                  />
                </Grid>
              </Grid>
            ))}
            {!disabled && (
              <Grid item xs={12}>
                <ButtonSubmit pending={pending} />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>

      {accessCompanyList && (
        <Card mb={6}>
          <CardContent>
            <Typography variant="h2" gutterBottom>
              {t('Legal person')}
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Field
                  name={formFields.company}
                  id={formFields.company}
                  label={t('Legal person')}
                  labelId={formFields.company}
                  my={1}
                  items={allCompanies.toJS()}
                  fullWidth
                  component={Select}
                  disabled={disabled}
                />
              </Grid>
              {!disabled && (
                <Grid item xs={12}>
                  <ButtonSubmit pending={pending} />
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      )}

      <Card mb={6}>
        <CardContent>
          <Typography variant="h2" gutterBottom>
            {t('Tags')}
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <FieldTags
                name={formFields.tags}
                getAutocompleteLists={getAutocompleteLists}
                formName={formName}
                handleCreateNewTag={handleCreateNewTag}
                handleEditTag={handleEditTag}
                handleDeleteTag={handleDeleteTag}
                propsField={{
                  multiple: true,
                  disabled,
                }}
              />
            </Grid>
            {!disabled && (
              <Grid item xs={12}>
                <ButtonSubmit pending={pending} />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>

      {objectType === '1' && !isEmpty(getMadridCase) && (
        <Card mb={6}>
          <CardContent>
            <Typography variant="h2" gutterBottom>
              {t('Madrid')}
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <LinkMadrid
                  to={pageLinks.tradeMarksRoutes.madridCases.edit(getMadridCase.id)}
                >
                  {`${getMadridCase.regNumber || getMadridCase.id}: ${getMadridCase.publicationDate}`}
                </LinkMadrid>
              </Grid>
              <Grid item xs={12}>
                <DxTable
                  name="TrademarksMadridCase"
                  columns={columnsTrademarks}
                  rows={listTrademarks}
                  isLoading={false}
                  disablePaging
                  disableHiddenColumn
                  disableColumnOrder
                  disableColumnWidth
                  disableExport
                  disableMenu
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}

      <Card mb={6}>
        <CardContent>
          <Typography variant="h2" gutterBottom>
            {t('Note')}
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Field
                name={formFields.notes}
                id={formFields.notes}
                label={t('Note')}
                margin="normal"
                fullWidth
                type="text"
                variant="outlined"
                multiline
                component={RenderTextField}
                rows={2}
                inputProps={{ maxLength: 250 }}
                disabled={disabled}
              />
              <Field
                name={formFields.noteExtended}
                id={formFields.noteExtended}
                labelId={formFields.noteExtended}
                label={t('Extended note')}
                margin="normal"
                fullWidth
                type="text"
                component={RenderQuill}
                disabled={disabled}
              />
            </Grid>
            {!disabled && (
              <Grid item xs={12}>
                <ButtonSubmit pending={pending} />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
CardFooter.propTypes = {
  formName: PropTypes.string.isRequired,
  pending: PropTypes.bool.isRequired,
  allCompanies: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  formFields: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  tagsValues: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  newTag: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  responsibleEmployeeById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  match: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  getProposalById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  listResponsible: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  selectedCompany: PropTypes.string.isRequired,
  objectType: PropTypes.string.isRequired,
  documentTypesData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  employeesData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  jurisdictionsData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  fieldResponsible: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  disabled: PropTypes.bool,
  proposalData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

CardFooter.defaultProps = {
  fieldResponsible: {},
  disabled: false,
};

function mapStateToProps(state) {
  return {
    allCompanies: selectors.companiesTable.allCompanies(state),
    newTag: selectors.tags.newTag(state),
    responsibleEmployeeById: selectors.employees.responsibleEmployeeById(state),
    listResponsible: selectors.employees.listResponsible(state),
    selectedCompany: selectors.helpers.selectedCompany(state),
    documentTypesData: selectors.documentTypes.documentTypesData(state),
    employeesData: selectors.employeesTable.employeesData(state),
    jurisdictionsData: selectors.jurisdiction.jurisdictionsData(state),
    fieldResponsible: selectors.form.getFormValues(state, 'ProposalAdd').get('responsible'),
    proposalData: selectors.tradeMarksProposal.proposalData(state),
  };
}

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.formName, nextProps.formName)
  && isEqual(prevProps.formFields, nextProps.formFields)
  && isEqual(prevProps.allCompanies, nextProps.allCompanies)
  && isEqual(prevProps.tagsValues, nextProps.tagsValues)
  && isEqual(prevProps.newTag, nextProps.newTag)
  && isEqual(prevProps.jurisdictionsData, nextProps.jurisdictionsData)
  && isEqual(prevProps.responsibleEmployeeById, nextProps.responsibleEmployeeById)
  && isEqual(prevProps.getProposalById, nextProps.getProposalById)
  && isEqual(prevProps.selectedCompany, nextProps.selectedCompany)
  && isEqual(prevProps.listResponsible, nextProps.listResponsible)
  && isEqual(prevProps.entityName, nextProps.entityName)
  && isEqual(prevProps.employeesData, nextProps.employeesData)
  && isEqual(prevProps.documentTypesData, nextProps.documentTypesData)
  && isEqual(prevProps.match, nextProps.match)
  && isEqual(prevProps.fieldResponsible, nextProps.fieldResponsible)
  && isEqual(prevProps.disabled, nextProps.disabled)
  && isEqual(prevProps.proposalData, nextProps.proposalData)
  && isEqual(prevProps.pending, nextProps.pending);
}

export default compose(
  connect(mapStateToProps, null),
)(memo(CardFooter, areEqual));
