// core
import React, {
  useEffect, useState, useCallback,
} from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import {
  Field, Form, reduxForm, change,
} from 'redux-form/lib/immutable';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import { Link as MuiLink } from 'react-router-dom';

// lodash
import {
  isNil, filter, isEmpty, map, pullAt, omit,
  find,
} from 'lodash';

// ui
import {
  Button, CardContent, Divider as MuiDivider, Grid, Box as MuiBox,
  Typography, Card as MuiCard, CircularProgress, ButtonGroup,
  IconButton as MuiIconButton, Tooltip, InputAdornment,
  FormControlLabel, Radio,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import {
  Add as AddIcon, Edit, Search as SearchIcon,
  Close as CloseIcon, NavigateBefore as NavigateBeforeIcon,
} from '@material-ui/icons';

// helpers
import { validators } from '../../../ui/_helpers/validation';
import { getPostalAddress } from '../../../ui/_helpers/getPostalAddress';

// parts
import Loader from '../../../components/Loader';
import Select from '../../../ui/Form/Select';
import renderTextField from '../../../ui/Form/RenderTextField';
import ConfirmModal from '../../../components/ConfirmModal';
import AddApplicantModal from '../../../components/AddApplicantModal/AddApplicantModal';
import AddClientAddressModal from './AddClientAddressModal';
import TransferList from '../../../ui/Form/TransferList';
import RadioButton from '../../../ui/Form/RadioButton';

// actions
import clientsActionAsync from '../../../engine/core/company/clients/saga/asyncAction';
import clientsAction from '../../../engine/core/company/clients/action';
import applicantsAsyncActions from '../../../engine/core/applicants/saga/asyncAction';
import applicantsActions from '../../../engine/core/applicants/action';
import helpersActions from '../../../engine/core/helpers/action';
import proposalAsyncActions from '../../../engine/core/tradeMarks/proposal/saga/asyncAction';
import proposalActions from '../../../engine/core/tradeMarks/proposal/action';
import currenciesAsyncAction from '../../../engine/core/currencies/currenciesList/saga/asyncAction';
import currenciesActions from '../../../engine/core/currencies/currenciesList/action';
import permissionsGroupsAsyncAction from '../../../engine/core/permissionsGroups/saga/asyncAction';
import permissionsGroupsAction from '../../../engine/core/permissionsGroups/action';

// config
import selectors from '../../../engine/config/selectors';
import { pageLinks } from '../../../routes';
import accessList from '../../../engine/config/accessList';
import { useAccessList } from '../../../ui/_hooks/useAccessList';

// styles
const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const IconButton = styled(MuiIconButton)(spacing);
const Box = styled(MuiBox)(spacing);
const Link = styled(MuiLink)`
  text-decoration: underline;
  color: #1976d2;
  display: flex;
  align-items: center;
`;
const formName = 'client';
const TypographySub = styled(Typography)`
margin-top: 10px;
  color: ${(props) => props.theme.palette.grey['500']};
`;

function Client(props) {
  const {
    initialize, handleSubmit, pendingClientById, isUserProfile,
    pendingPutClientById, getClientById, match, applicants,
    pendingDeleteApplicant, isModalOpenAddApplicant, isModalOpenAddress,
    pendingPostClient, reloadApplicants, isModalOpenConfirm,
    companyData, companyPending, fieldInnCode, currenciesData,
    getPermissions, permissionsGroupsData, typeGenerate, passwordValue,
    pendingSentCredentials,
    // pendingGroups,
  } = props;
  const defaultContactPerson = {
    fullName: '',
    email: '',
    phone: '',
    id: Math.random(),
  };
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [readyClient, setReadyClient] = useState(false);
  const [readyApplicants, setReadyApplicants] = useState(false);
  const [contactPersons, setContactPersons] = useState([defaultContactPerson]);
  const [deleteApplicantId, setDeleteApplicantId] = useState('');
  const [editAddress, setEditAddress] = useState({});
  const [addresses, setAddresses] = useState([]);
  const [notFoundCode, setNotFoundCode] = useState(false);
  const [allList, setAllList] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const { params } = match;
  const accessPermissions = useAccessList(accessList.permissions_list_get);
  const accessPut = useAccessList(accessList.clients_put);
  const accessSentCredentials = useAccessList(accessList.clients_sent_credentials_post);
  const accessApplicantsGet = useAccessList(accessList.applicants_get);
  const accessApplicantsGetList = useAccessList(accessList.applicants_list_get);
  const accessApplicantsPost = useAccessList(accessList.applicants_post);
  const accessApplicantsDelete = useAccessList(accessList.applicants_delete);
  const accessFindCompany = useAccessList(accessList.company_helpers_find_get);
  const accessCurrenciesGetList = useAccessList(accessList.currencies_list_get);
  const accessGroupsList = useAccessList(accessList.groups_list_get);
  const accessEdit = !isEmpty(params) ? accessPut : true;

  const itemsGenerate = [
    { value: '1', name: t('Generate a password') },
    { value: '2', name: t('Enter the password') },
  ];

  const handleSubmits = (formData) => {
    const json = formData.toJS();
    const valueAll = json.all || 'false';
    if (!isEmpty(json)) {
      delete json.company;
      delete json.addresses;
      delete json.all;
      delete json.password;
      delete json.typeGenerate;
      delete json.permissions;
      dispatch(clientsActionAsync[isEmpty(params) ? 'postClientAsync' : 'putClientByIdAsync']({
        ...json,
        ...!isEmpty(json.currency) ? {
          currency: json.currency.id,
        } : {},
        files: null,
        ...!isEmpty(addresses) ? {
          addresses,
        } : {},
        ...!isEmpty(selectedList) ? {
          permissions: map(selectedList, (item) => ({ id: item.id, all: valueAll, own: true })),
        } : {},
      }));
    }
  };

  const handleAddContact = () => {
    setContactPersons([
      ...contactPersons,
      defaultContactPerson,
    ]);
  };

  const handleAddApplicant = () => {
    if (!isEmpty(params.hashId)) {
      dispatch(helpersActions.setModal({ isModalOpenAddApplicant: true }));
    } else {
      dispatch(helpersActions.setModal({ isModalOpen: true }));
    }
  };

  const handleDeleteContact = (id) => {
    setContactPersons(filter(contactPersons, (person) => person.id !== id));
  };

  const handleEditApplicant = (applicantId) => {
    dispatch(applicantsAsyncActions.getApplicantByIdAsync({ id: applicantId, isModal: true }));
  };

  const handleDeleteApplicant = (applicantId) => {
    dispatch(applicantsActions.setDeleteApplicant({ id: applicantId }));
    dispatch(applicantsAsyncActions.deleteApplicantAsync());
    setDeleteApplicantId(applicantId);
  };

  const getTitlePage = useCallback(() => {
    if (!isEmpty(getClientById.toJS()) && !isUserProfile) {
      return getClientById.name;
    }

    if (isUserProfile) {
      return t('User profile');
    }

    return t('Add client');
  }, [
    t,
    getClientById,
    isUserProfile,
  ]);

  const handleChangeInnCode = () => {
    if (notFoundCode) {
      setNotFoundCode(false);
    }
  };

  const getRegistryByNumber = (number) => {
    dispatch(proposalAsyncActions.getFindCompanyAsync({ code: number }));
  };

  const handleAddAddress = () => {
    dispatch(clientsAction.setIsModalOpenAddress(true));
  };

  const handleSetAddresses = (element, index) => {
    const newAddresses = !isNil(index)
      ? map(addresses, (item, i) => (i === index ? element : item))
      : [...addresses, element];
    setAddresses(newAddresses);
    setEditAddress({});
    dispatch(clientsAction.setIsModalOpenAddress(false));
  };

  const handleDelete = (index) => {
    const newList = [...addresses];
    pullAt(newList, index);
    setAddresses(newList);
  };

  const handleEdit = (elem, index) => {
    setEditAddress({
      ...elem,
      index,
    });
    dispatch(clientsAction.setIsModalOpenAddress(true));
  };

  const createApplicantFromClient = () => {
    dispatch(applicantsAsyncActions.postApplicantAsync({
      address: omit(getPostalAddress(addresses), 'recipient'),
      fis: getClientById.toJS().typeOfOwnership === '3',
      name: getClientById.toJS().name,
      innCode: getClientById.toJS().innCode,
      clients: params.hashId,
      isTable: true,
    }));
  };

  useEffect(() => {
    if (!isEmpty(editAddress) && !isModalOpenAddress) {
      setEditAddress({});
    }
  }, [isModalOpenAddress, editAddress]);

  useEffect(() => {
    if (!isEmpty(companyData.toJS())) {
      if (!isEmpty(companyData.toJS().error)) {
        setNotFoundCode(true);
      } else {
        const parseCompanyData = companyData.toJS();
        const street = !isNil(parseCompanyData?.streettype) && !isNil(parseCompanyData?.street) ? `${parseCompanyData?.streettype || ''} ${parseCompanyData?.street},` : '';
        const building = !isNil(parseCompanyData?.building) ? `будинок ${parseCompanyData?.building},` : '';
        const room = !isNil(parseCompanyData?.roomtype) && !isNil(parseCompanyData?.room) ? `${parseCompanyData?.roomtype} ${parseCompanyData?.room}` : '';
        const obl = !isNil(parseCompanyData?.obl) ? `${parseCompanyData?.obl} область,` : '';
        const region = !isNil(parseCompanyData?.region) ? `${parseCompanyData?.region} район,` : '';
        const city = !isNil(parseCompanyData?.citytype) && !isNil(parseCompanyData?.cityname)
          ? `${parseCompanyData?.citytype || ''} ${parseCompanyData?.cityname || ''}` : '';

        dispatch(change(formName, 'name', parseCompanyData.name || ''));
        dispatch(change(formName, 'typeOfOwnership', parseCompanyData.fis ? '2' : '1'));
        setAddresses([
          ...addresses,
          {
            name: 'postal',
            city: `${obl}${obl ? ' ' : ''}${region}${region ? ' ' : ''}${city}` || '',
            address: `${street}${street ? ' ' : ''}${building}${building ? ' ' : ''}${room}` || '',
            postalIndex: `${parseCompanyData?.index}` || '',
            country: 'UA',
          },
        ]);
        setNotFoundCode(false);
      }
      dispatch(proposalActions.setCompany({}));
    }
  }, [dispatch, companyData, addresses]);

  useEffect(() => {
    if (!readyClient && isEmpty(getClientById.toJS()) && !isEmpty(params)) {
      setReadyClient(true);
      dispatch(clientsActionAsync.getClientByIdAsync(params.hashId));
    }
  }, [
    dispatch, getClientById, params,
    readyClient, setReadyClient,
  ]);

  useEffect(() => {
    if (accessCurrenciesGetList) dispatch(currenciesAsyncAction.getListAsync());
    if (accessGroupsList) dispatch(permissionsGroupsAsyncAction.getListAsync());
    if (accessPermissions) {
      dispatch(permissionsGroupsAsyncAction.getPermissionsAsync({ limit: 1000 }));
    }
  }, [dispatch, accessCurrenciesGetList, accessPermissions, accessGroupsList]);

  useEffect(() => {// eslint-disable-line
    return function cleanup() {
      dispatch(clientsAction.setClientById({ data: {} }));
      dispatch(applicantsActions.setApplicantsList([]));
      dispatch(currenciesActions.setCurrenciesData({
        items: [],
        totalCount: 0,
        pending: false,
      }));
      const initialState = { items: [], totalCount: 0, pending: false };
      dispatch(permissionsGroupsAction.setPermissionsGroupsData(initialState));
      dispatch(permissionsGroupsAction.setPermissionsData(initialState));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(getPermissions.toJS()) && !isEmpty(permissionsGroupsData.toJS().items)) {
      if (!isEmpty(getClientById.toJS().permissions)) {
        dispatch(change('client', 'all', `${getClientById.toJS()?.permissions?.[0]?.all}`));
        setAllList(filter(getPermissions.toJS(), (item) => !find(getClientById.toJS()?.permissions,
          (elem) => elem?.permission?.id === item.id)));
        setSelectedList(filter(getPermissions.toJS(),
          (item) => find(getClientById.toJS()?.permissions,
            (elem) => elem?.permission?.id === item.id)));
      } else {
        dispatch(change('client', 'all', 'false'));
        setAllList(getPermissions.toJS());
      }
    }
  }, [getPermissions, getClientById, permissionsGroupsData, dispatch]);

  const handleSendPassword = () => {
    dispatch(clientsActionAsync.postSentCredentialsAsync({
      id: params.hashId,
      ...passwordValue ? {
        password: passwordValue,
      } : {},
    }));
  };

  useEffect(() => {
    if (!readyApplicants || reloadApplicants) {
      setReadyApplicants(true);
      dispatch(applicantsActions.setApplicantsData({
        reload: false,
      }));
      if (!isEmpty(params) && accessApplicantsGetList) {
        dispatch(applicantsAsyncActions.getApplicantsListAsync({
          'clients.id': params.hashId,
        }));
      }
    }
  }, [
    params, dispatch, readyApplicants,
    setReadyApplicants,
    reloadApplicants, accessApplicantsGetList,
  ]);

  useEffect(() => {
    if (!isEmpty(getClientById.toJS()) && !pendingClientById) {
      initialize({
        ...getClientById.toJS(),
        fis: 'true',
        all: 'false',
        typeGenerate: '1',
      });
      if (!isEmpty(getClientById.toJS().addresses)) setAddresses(getClientById.toJS().addresses);
    }
  }, [
    initialize,
    getClientById,
    pendingClientById,
  ]);

  useEffect(() => {
    if (!isEmpty(params)) initialize({ typeGenerate: '1', all: 'false' });
  }, [initialize, params]);

  useEffect(() => {
    if (!isEmpty(getClientById.toJS()) && !isEmpty(getClientById.toJS().contactPersons)) {
      setContactPersons(getClientById.toJS().contactPersons);
    }
  }, [contactPersons, setContactPersons, getClientById]);

  return !pendingClientById ? (
    <>
      <Helmet title={t('Profile')} />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h3" gutterBottom display="inline">
          {getTitlePage()}
        </Typography>
        <Link to={pageLinks.company.clients.all}>
          <NavigateBeforeIcon size="small" color="primary" />
          {t('Up to the list')}
        </Link>
      </Box>
      <Divider my={6} />

      {isModalOpenAddress && (
        <AddClientAddressModal
          handleSetAddresses={handleSetAddresses}
          editAddress={editAddress}
        />
      )}

      <Form onSubmit={handleSubmit(handleSubmits)}>
        <Card mb={6}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              {t('Basic information')}
            </Typography>

            <Grid container spacing={6}>
              <Grid item xs={12} md={6}>
                <Field
                  name="typeOfOwnership"
                  id="typeOfOwnership"
                  label={t('Type of ownership')}
                  labelId="typeOfOwnership"
                  my={2}
                  items={[
                    {
                      value: '1',
                      name: t('Legal person'),
                    },
                    {
                      value: '2',
                      name: t('FOP'),
                    },
                    {
                      value: '3',
                      name: t('Individual person'),
                    },
                  ]}
                  fullWidth
                  component={Select}
                  disabled={!accessEdit}
                />

                <Field
                  required
                  validate={validators.required}
                  name="name"
                  id="name"
                  label={t('Full name/Name')}
                  margin="normal"
                  variant="standard"
                  my={2}
                  fullWidth
                  type="text"
                  component={renderTextField}
                  disabled={!accessEdit}
                />

                <Field
                  id="iban"
                  name="iban"
                  label="IBAN"
                  variant="standard"
                  margin="normal"
                  fullWidth
                  my={2}
                  component={renderTextField}
                  disabled={!accessEdit}
                />

                <Field
                  name="currency"
                  id="currency"
                  label={t('Currency')}
                  labelId="currency"
                  my={2}
                  items={currenciesData.toJS().items}
                  fullWidth
                  component={Select}
                  disabled={!accessEdit}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Field
                  name="innCode"
                  id="innCode"
                  label={t('Inn Code')}
                  margin="normal"
                  variant="standard"
                  fullWidth
                  type="text"
                  my={2}
                  component={renderTextField}
                  {...notFoundCode ? {
                    helperText: t('Not found'),
                  } : {}}
                  onChange={handleChangeInnCode}
                  disabled={!accessEdit}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {fieldInnCode && accessFindCompany ? (
                          <Tooltip
                            title={t('Find in the national register')}
                            arrow
                          >
                            <IconButton
                              onClick={() => getRegistryByNumber(fieldInnCode)}
                            >
                              {companyPending ? <CircularProgress size={20} color="inherit" /> : <SearchIcon />}
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <IconButton disabled>
                            <SearchIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />

                <Field
                  id="email"
                  name="email"
                  label="Email"
                  variant="standard"
                  margin="normal"
                  fullWidth
                  my={2}
                  component={renderTextField}
                  disabled={!accessEdit}
                />
                <Field
                  id="phone"
                  name="phone"
                  label={t('Phone')}
                  margin="normal"
                  fullWidth
                  my={2}
                  component={renderTextField}
                  disabled={!accessEdit}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card mb={6}>
          <CardContent>
            <Typography variant="h6" paragraph>
              {t('Addresses')}
            </Typography>
            {accessEdit && (
              <Box mt={2} mb={2}>
                <Button variant="outlined" color="primary" onClick={handleAddAddress}>
                  {t('ADD')}
                  <AddIcon />
                </Button>
              </Box>
            )}
            {map(addresses, (address, index) => (
              <Box display="flex" flexDirection="row" alignItems="center" key={index} mb={3}>
                <Box component="span">
                  <Box
                    component="span"
                    fontWeight="fontWeightBold"
                  >
                    {(address.name === 'postal' || address.name === 'legal' || address.name === 'payment')
                      ? t(address.name) : address.name}
                  </Box>
                  <br />
                  {address?.address ? `${address?.address}, ` : ''}
                  {address?.city ? `${address?.city}, ` : ''}
                  {address?.postalIndex ? `${address?.postalIndex}, ` : ''}
                  {address?.country}
                </Box>
                {accessEdit && (
                  <IconButton
                    ml={4}
                    aria-label="Edit"
                    size="small"
                    onClick={() => {
                      handleEdit(address, index);
                    }}
                  >
                    <Edit fontSize="small" />
                  </IconButton>
                )}
                {accessEdit && (
                  <IconButton
                    aria-label="Delete"
                    size="small"
                    onClick={() => {
                      handleDelete(index);
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                )}
              </Box>
            ))}
          </CardContent>
        </Card>

        <Card mb={6}>
          <CardContent>
            <Typography variant="h6" paragraph>
              {t('Contact persons')}
            </Typography>
            {map(contactPersons, (currentContact, index) => (
              <Grid container spacing={6} key={index}>
                <Grid item xs={4}>
                  <Field
                    name={`contactPerson[${index}].name`}
                    id={`contactPerson[${index}].name`}
                    label={t('Full name')}
                    margin="none"
                    variant="standard"
                    my={2}
                    fullWidth
                    type="text"
                    component={renderTextField}
                    disabled={!accessEdit}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    validate={validators.notRequiredEmail}
                    name={`contactPerson[${index}].email`}
                    id={`contactPerson[${index}].email`}
                    label={t('Email')}
                    margin="none"
                    variant="standard"
                    my={2}
                    fullWidth
                    type="email"
                    component={renderTextField}
                    disabled={!accessEdit}
                  />
                </Grid>
                <Grid item xs={contactPersons.length > 1 ? 3 : 4}>
                  <Field
                    name={`contactPerson[${index}].phone`}
                    id={`contactPerson[${index}].phone`}
                    label={t('Phone')}
                    margin="none"
                    variant="standard"
                    my={2}
                    fullWidth
                    type="text"
                    component={renderTextField}
                    disabled={!accessEdit}
                  />
                </Grid>
                {contactPersons.length > 1 && accessEdit && (
                  <Grid item xs={1} container alignItems="center" justify="center">
                    <IconButton
                      mx={2}
                      aria-label="Delete"
                      onClick={() => handleDeleteContact(currentContact.id)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            ))}

            {accessEdit && (
              <Box mt={2}>
                <Button variant="outlined" color="primary" onClick={handleAddContact}>
                  {t('ADD')}
                  <AddIcon />
                </Button>
              </Box>
            )}
          </CardContent>
        </Card>

        {!isEmpty(params) && (
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" paragraph>
                {t('Authorization')}
              </Typography>
              <Grid container spacing={6}>
                <Grid item xs={12} md={3}>
                  <Field
                    name="typeGenerate"
                    id="typeGenerate"
                    label={t('Type')}
                    labelId="typeGenerate"
                    variant="standard"
                    my={2}
                    fullWidth
                    type="text"
                    component={Select}
                    items={itemsGenerate}
                    disabled={!accessEdit}
                  />

                  {typeGenerate === '2' && (
                    <Field
                      name="password"
                      id="password"
                      label={t('Password')}
                      margin="normal"
                      variant="standard"
                      my={2}
                      fullWidth
                      type="password"
                      component={renderTextField}
                      disabled={!accessEdit}
                    />
                  )}

                  {accessEdit && accessSentCredentials && (
                    <Box mt={4}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleSendPassword}
                        disabled={pendingSentCredentials}
                      >
                        {pendingSentCredentials
                          ? <CircularProgress size={25} color="inherit" />
                          : t('SEND PASSWORD')}
                      </Button>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}

        {accessPermissions && accessEdit && (
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" paragraph>
                {t('Access rights')}
              </Typography>
              <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                  <Field
                    required
                    validate={validators.required}
                    name="group"
                    id="group"
                    label={t('Role')}
                    labelId="group"
                    my={2}
                    items={permissionsGroupsData.toJS().items}
                    fullWidth
                    component={Select}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TypographySub variant="subtitle1" gutterBottom>
                  {t('Objects visibility')}
                </TypographySub>
                <Field
                  aria-label="all"
                  name="all"
                  component={RadioButton}
                  row
                >
                  <FormControlLabel value="true" control={<Radio />} label={t('All')} />
                  <FormControlLabel value="false" control={<Radio />} label={t('Own')} />
                </Field>
              </Grid>
              <TypographySub variant="subtitle1" gutterBottom>
                {t('Permissions')}
              </TypographySub>
              <TransferList
                allList={allList}
                selectedList={selectedList}
                handleChangeAllList={setAllList}
                handleChangeSelectedList={setSelectedList}
              />
            </CardContent>
          </Card>
        )}
        <Card mb={6} raised>
          <CardContent>
            <Typography variant="h6" paragraph>
              {t('Notes')}
            </Typography>
            <Field
              multiline
              name="notes"
              id="notes"
              margin="normal"
              variant="outlined"
              my={2}
              fullWidth
              component={renderTextField}
              rows={6}
              disabled={!accessEdit}
            />
          </CardContent>
        </Card>

        <Card mb={6} raised>
          <CardContent>
            <Typography variant="h6" paragraph>
              {t('Applicants')}
            </Typography>
            <Grid item xs={12}>
              {map(applicants, (applicant, index) => (
                <Box display="flex" flexDirection="row" alignItems="center" key={index} mb={3}>
                  <Box component="span">
                    <Box
                      component="span"
                      fontWeight="fontWeightBold"
                    >
                      {applicant?.name}
                    </Box>
                    <br />
                    {applicant?.address?.address ? `${applicant?.address?.address}, ` : ''}
                    {applicant?.address?.city ? `${applicant?.address?.city}, ` : ''}
                    {applicant?.address?.postalIndex ? `${applicant?.address?.postalIndex}, ` : ''}
                    {applicant?.address?.country}
                  </Box>
                  {accessEdit && accessApplicantsGet && (
                    <IconButton
                      ml={4}
                      aria-label="Edit"
                      size="small"
                      onClick={() => {
                        handleEditApplicant(applicant.id);
                      }}
                    >
                      <Edit fontSize="small" />
                    </IconButton>
                  )}
                  {deleteApplicantId === applicant.id && pendingDeleteApplicant
                    ? <CircularProgress size={15} color="inherit" />
                    : (
                      <IconButton
                        aria-label="Delete"
                        size="small"
                        onClick={() => {
                          handleDeleteApplicant(applicant.id);
                        }}
                        disabled={!accessEdit || !accessApplicantsDelete}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    )}
                </Box>
              ))}
            </Grid>

            {accessEdit && (
              <Box>
                <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddApplicant}
                  >
                    {t('ADD')}
                  </Button>
                  {!isEmpty(params) && accessApplicantsPost && (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={createApplicantFromClient}
                    >
                      {t('FROM THE CLIENT')}
                    </Button>
                  )}
                </ButtonGroup>
              </Box>
            )}

            {isModalOpenAddApplicant && (
              <AddApplicantModal
                client={getClientById.toJS()}
                visibleClient={false}
                isTable
              />
            )}

            {isModalOpenConfirm && (
              <ConfirmModal
                buttonSendText=""
                buttonCancelText="CLEARLY"
                description="Save the client first"
              />
            )}
          </CardContent>
        </Card>

        {accessEdit && (
          <Box mt={3}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={pendingPutClientById || pendingPostClient}
            >
              {pendingPutClientById || pendingPostClient
                ? <CircularProgress size={25} color="inherit" />
                : t('SAVE')}
            </Button>
          </Box>
        )}
      </Form>
    </>
  ) : <Loader />;
}

Client.displayName = 'Client';

Client.propTypes = {
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  getClientById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  applicants: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  reloadApplicants: PropTypes.bool.isRequired,
  pendingPutClientById: PropTypes.bool.isRequired,
  pendingDeleteApplicant: PropTypes.bool.isRequired,
  pendingClientById: PropTypes.bool.isRequired,
  pendingPostClient: PropTypes.bool.isRequired,
  isModalOpenConfirm: PropTypes.bool.isRequired,
  isModalOpenAddApplicant: PropTypes.bool.isRequired,
  match: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  isUserProfile: PropTypes.bool,
  companyData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  companyPending: PropTypes.bool.isRequired,
  pendingSentCredentials: PropTypes.bool.isRequired,
  fieldInnCode: PropTypes.string,
  typeGenerate: PropTypes.string,
  passwordValue: PropTypes.string,
  currenciesData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  isModalOpenAddress: PropTypes.bool.isRequired,
  // pendingGroups: PropTypes.bool.isRequired,
  getPermissions: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  permissionsGroupsData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

Client.defaultProps = {
  match: {},
  isUserProfile: false,
  fieldInnCode: '',
  typeGenerate: '',
  passwordValue: '',
};

function mapStateToProps(state) {
  return {
    getClientById: selectors.clients.getClientById(state),
    pendingPutClientById: selectors.clients.pendingPutClientById(state),
    pendingClientById: selectors.clients.pendingClientById(state),
    pendingPostClient: selectors.clients.pendingPostClient(state),
    applicants: selectors.applicants.list(state).toJS(),
    reloadApplicants: selectors.applicants.reloadApplicants(state),
    pendingDeleteApplicant: selectors.applicants.pendingDeleteApplicant(state),
    isModalOpenConfirm: selectors.helpers.isModalOpen(state),
    isModalOpenAddApplicant: selectors.helpers.isModalOpenAddApplicant(state),
    isModalOpenAddress: selectors.clients.isModalOpenAddress(state),
    companyData: selectors.tradeMarksProposal.company(state),
    companyPending: selectors.tradeMarksProposal.companyPending(state),
    fieldInnCode: selectors.form.getFormValues(state, formName).get('innCode'),
    typeGenerate: selectors.form.getFormValues(state, formName).get('typeGenerate'),
    passwordValue: selectors.form.getFormValues(state, formName).get('password'),
    currenciesData: selectors.currencies.currenciesData(state),
    getPermissions: selectors.permissionsGroups.getPermissions(state),
    // pendingGroups: selectors.permissionsGroups.pending(state),
    permissionsGroupsData: selectors.permissionsGroups.permissionsGroupsData(state),
    pendingSentCredentials: selectors.clients.pendingSentCredentials(state),
  };
}

export default compose(
  reduxForm({
    form: 'client',
  }),
  connect(mapStateToProps, null),
)(Client);
