// Helpers
import actionCreator from '../../../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const employeesActionAsync = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_EMPLOYEES_ASYNC, params);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_EMPLOYEES_INITIAL_STATE_ASYNC, path);
  },
});

export default employeesActionAsync;
