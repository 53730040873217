// Core
import { useSelector } from 'react-redux';

// lodash
import { includes } from 'lodash';

// Selectors
import selectors from '../../engine/config/selectors';

export function useAccessList(accessName) {
  const userInfo = useSelector(selectors.user.userInfo);
  const { permissions } = userInfo;
  return permissions ? includes(permissions, accessName) : false;
}
