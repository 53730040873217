// core
import React, {
  useEffect, useState, useMemo,
} from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import {
  Field, Form, reduxForm, change,
} from 'redux-form/lib/immutable';
import styled from 'styled-components';

// lodash
import {
  isEmpty, map, includes, filter,
} from 'lodash';

// ui
import {
  Grid as MuiGrid, Typography as MuiTypography,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';

// config
import selectors from '../../../engine/config/selectors';

// parts
import RenderTextField from '../../../ui/Form/RenderTextField';
import Modal from '../../../components/Modal/Modal';
import DialogActions from '../../../components/Modal/components/DialogActions';
import Select from '../../../ui/Form/Select';
import RenderQuill from '../../../ui/Form/RenderQuill';
import LoaderWithOverlay from '../../../components/LoaderWithOverlay';

// actions
import asyncAction from '../../../engine/core/email/emailTemplate/saga/asyncAction';
import actions from '../../../engine/core/email/emailTemplate/action';
import fieldsSettingsActionAsync from '../../../engine/core/fieldsSettings/saga/asyncAction';
import fieldsSettingsActions from '../../../engine/core/fieldsSettings/action';

// helpers
import { fieldNames } from '../../ImportObjects/helper/fieldNames';
// hooks
import { listEntityName } from '../../../engine/config/listTablesName';

// style
const Grid = styled(MuiGrid)(spacing);
const TypographySub = styled(MuiTypography)`
  color: ${(props) => props.theme.palette.grey['500']};
  margin-top: 10px;
`;

const formName = 'EmailTemplateModal';

const accessKeys = [
  'status', 'caseNumber', 'applicationDate', 'registrationDate',
  'applicationNumber', 'registrationNumber', 'title', 'internalTitle',
  'clientReference', 'dataIpc', 'ipc', 'id', 'expiryDate',
  'typeOfOwnership', 'name', 'iban', 'innCode', 'email', 'inNumber',
  'phone', 'type', 'documentDate', 'outNumber', 'receivingDate',
];

function EmailTemplateModal(props) {
  const {
    handleSubmit, isModalOpen, initialize, destroy,
    getEmailTemplateById, pendingPost, pendingPut,
    fieldsEntity, bodyValues, essenceValues,
    pendingEmailTemplateById,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [readyInitialize, setReadyInitialize] = useState(false);

  const handleCloseModal = () => {
    dispatch(actions.setEmailTemplateById({ isModalOpen: false }));
  };
  useEffect(() => function cleanup() {
    destroy();
    dispatch(actions.setEmailTemplateDataById({}));
    dispatch(fieldsSettingsActions.setFieldsEntity({}));
  }, [destroy, dispatch]);

  const handleSubmits = (formData) => {
    const json = formData.toJS();
    delete json.essence;
    delete json.field;
    dispatch(asyncAction[!isEmpty(getEmailTemplateById.toJS())
      ? 'putEmailTemplateByIdAsync'
      : 'postEmailTemplateAsync']({
      ...json,
      ...!isEmpty(getEmailTemplateById.toJS()) ? {
        id: getEmailTemplateById.toJS().id,
      } : {},
    }));
  };

  useEffect(() => {
    if (!isEmpty(getEmailTemplateById.toJS()) && !readyInitialize) {
      initialize(getEmailTemplateById.toJS());
      setReadyInitialize(true);
    }
  }, [
    initialize, getEmailTemplateById, setReadyInitialize,
    readyInitialize,
  ]);

  const itemObjectTypes = [
    { name: t('the Trademark'), value: listEntityName.trademarks },
    { name: t('the Utility Model'), value: listEntityName.utility },
    { name: t('the Industrial Design'), value: listEntityName.industrial },
    { name: t('the Invention'), value: listEntityName.inventions },
    { name: t('the Client'), value: listEntityName.clients },
    { name: t('Document'), value: listEntityName.documents },
  ];

  const handleObjectType = (e) => {
    dispatch(fieldsSettingsActionAsync.getFieldsEntityAsync(e.target.value));
    dispatch(change(formName, 'field', ''));
  };
  const getItemsFindBy = useMemo(() => {
    const subStrFields = map(Object.keys(fieldsEntity.toJS()), (item) => item.substring(item.indexOf('.') + 1));
    const filterFields = filter(subStrFields, (item) => includes(accessKeys, item));
    return map(filterFields, (item) => ({
      name: fieldNames[item] || item,
      value: item,
    }));
  }, [fieldsEntity]);

  const handleFieldChange = (e) => {
    if (!isEmpty(essenceValues) && !isEmpty(e.target.value)) {
      const placeholder = `[${essenceValues}.${e.target.value}]`;
      const index = bodyValues.lastIndexOf('</');
      dispatch(change(formName, 'body', `${bodyValues.slice(0, index)}${placeholder}${bodyValues.slice(index)}`));
      dispatch(fieldsSettingsActions.setFieldsSetting({}));
      dispatch(change(formName, 'essence', ''));
      setTimeout(() => dispatch(change(formName, 'field', '')));
    }
  };

  return (
    <Modal
      title={t('Letter template')}
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
      displayDialogActions={false}
    >
      {pendingEmailTemplateById ? (
        <LoaderWithOverlay />
      ) : (
        <Form onSubmit={handleSubmit(handleSubmits)}>
          {/* <Field
            name="senderEmail"
            id="senderEmail"
            label={t('Sender email')}
            margin="normal"
            variant="standard"
            my={2}
            fullWidth
            type="text"
            component={RenderTextField}
          />
          <Field
            name="senderName"
            id="senderName"
            label={t('Sender name')}
            margin="normal"
            variant="standard"
            my={2}
            fullWidth
            type="text"
            component={RenderTextField}
          /> */}
          <Field
            name="name"
            id="name"
            label={t('Name')}
            margin="normal"
            variant="standard"
            my={2}
            fullWidth
            type="text"
            component={RenderTextField}
          />
          <Field
            name="subject"
            id="subject"
            label={t('Subject')}
            margin="normal"
            variant="standard"
            my={2}
            fullWidth
            type="text"
            component={RenderTextField}
          />
          <TypographySub variant="subtitle1" gutterBottom>
            {t('Dynamic fields')}
          </TypographySub>
          <Grid container spacing={4} mb={3}>
            <Grid item xs={12} md={6}>
              <Field
                name="essence"
                id="essence"
                label={t('Essence')}
                labelId="essence"
                margin="none"
                fullWidth
                my={2}
                items={itemObjectTypes}
                component={Select}
                onChange={handleObjectType}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                name="field"
                id="field"
                label={t('Field')}
                labelId="field"
                component={Select}
                items={getItemsFindBy}
                onChange={handleFieldChange}
                margin="none"
                fullWidth
              />
            </Grid>
          </Grid>

          <Field
            name="body"
            id="body"
            labelId="body"
            label={t('Message')}
            margin="normal"
            fullWidth
            type="text"
            component={RenderQuill}
          />

          <DialogActions
            pending={pendingPost || pendingPut}
            handleCloseModal={handleCloseModal}
          />
        </Form>
      )}
    </Modal>
  );
}

EmailTemplateModal.displayName = 'EmailTemplateModal';

EmailTemplateModal.propTypes = {
  getEmailTemplateById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  pendingPost: PropTypes.bool.isRequired,
  pendingPut: PropTypes.bool.isRequired,
  pendingEmailTemplateById: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  destroy: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  fieldsEntity: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  bodyValues: PropTypes.string,
  essenceValues: PropTypes.string,
};

EmailTemplateModal.defaultProps = {
  bodyValues: '',
  essenceValues: '',
};

function mapStateToProps(state) {
  return {
    getEmailTemplateById: selectors.emailTemplate.getEmailTemplateById(state),
    isModalOpen: selectors.emailTemplate.isModalOpen(state),
    pendingPut: selectors.emailTemplate.pendingPutEmailTemplate(state),
    pendingPost: selectors.emailTemplate.pendingPostEmailTemplate(state),
    pendingEmailTemplateById: selectors.emailTemplate.pendingEmailTemplateById(state),
    fieldsEntity: selectors.fieldsSetting.fieldsEntity(state),
    bodyValues: selectors.form.getFormValues(state, formName).get('body'),
    essenceValues: selectors.form.getFormValues(state, formName).get('essence'),
  };
}

export default compose(
  reduxForm({
    form: formName,
  }),
  connect(mapStateToProps, null),
)(EmailTemplateModal);
