// Helpers
import actionCreator from '../../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const asyncActions = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_PRICES_TARIFFS_ASYNC, params);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_PRICES_TARIFFS_INITIAL_STATE_ASYNC, path);
  },
  deleteTariffAsync() {
    return actionCreator(asyncTypes.SET_DELETE_PRICES_TARIFF_ASYNC);
  },
  getAutocompleteOptionsAsync(params) {
    return actionCreator(asyncTypes.GET_PRICES_TARIFFS_AUTOCOMPLETE_OPTIONS_ASYNC, params);
  },
  setTariffsTypesAsync(params) {
    return actionCreator(asyncTypes.SET_PRICES_TARIFFS_TYPES_ASYNC, params);
  },
});

export default asyncActions;
