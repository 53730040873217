// Core
import { apply, put } from 'redux-saga/effects';

// lodash
import { has, keys } from 'lodash';

// config
import { api } from '../../../../../config/api';
import { listEntityName } from '../../../../../config/listTablesName';

// actions
import actions from '../../action';

const entity = listEntityName.mktu;

export function* callGetReboundsAutocompleteWorker({ payload: params }) {
  const { setReboundsOptions, setPendingReboundsOptions } = actions;

  yield put(setPendingReboundsOptions(true));

  const name = keys(params)[0];
  const response = yield apply(api, api.trademarks.getReboundsAutocomplete,
    [{ ...params, entity }]);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setReboundsOptions({ [name]: [] }));
    } else {
      const { items } = data;
      yield put(setReboundsOptions({ [name]: items }));
    }
  }

  yield put(setPendingReboundsOptions(false));
}
