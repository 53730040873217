// Core
import React from 'react';
import * as PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

function RememberCheckbox(props) {
  const { input, label } = props;

  return (
    <FormControlLabel
      control={<Checkbox {...input} value="remember" color="primary" />}
      label={label}
    />
  );
}

RememberCheckbox.displayName = 'RememberCheckbox';

RememberCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  input: PropTypes.object.isRequired,
};

RememberCheckbox.defaultProps = {
};

export default RememberCheckbox;
