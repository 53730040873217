const asyncTypes = Object.freeze({
  GET_REBOUNDS_ASYNC: 'GET_REBOUNDS_ASYNC',
  GET_REBOUND_BY_ID_ASYNC: 'GET_REBOUND_BY_ID_ASYNC',
  GET_REBOUND_PDF_BY_ID_ASYNC: 'GET_REBOUND_PDF_BY_ID_ASYNC',
  GET_REBOUNDS_INITIAL_STATE_ASYNC: 'GET_REBOUNDS_INITIAL_STATE_ASYNC',
  GET_REBOUNDS_AUTOCOMPLETE_ASYNC: 'GET_REBOUNDS_AUTOCOMPLETE_ASYNC',
  POST_REBOUND_ASYNC: 'POST_REBOUND_ASYNC',
  SEARCH_REBOUND_ASYNC: 'SEARCH_REBOUND_ASYNC',
  PUT_REBOUND_BY_ID_ASYNC: 'PUT_REBOUND_BY_ID_ASYNC',
  DELETE_REBOUND_ASYNC: 'DELETE_REBOUND_ASYNC',
  DELETE_REBOUND_BY_ID_ASYNC: 'DELETE_REBOUND_BY_ID_ASYNC',
});

export default asyncTypes;
