// Core
import { apply, put } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../config/api';
import { messageRequestSent } from '../../../../config/globalConfig';

// init
import i18n from '../../../../init/i18n';

// actions
import actions from '../../action';
import inventionsAsyncAction from '../../../inventions/saga/asyncAction';
import utilityModelsAsyncAction from '../../../utilityModels/saga/asyncAction';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../ui/_helpers/setNotificationMessage';

export function* callPostCalcPatentsRenewalWorker({ payload: params }) {
  yield put(actions.setPendingPostRenewal(true));

  const response = yield apply(api, api.patent.postCalcPatentsRenewal, [params]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      if (params.inventions) {
        yield put(inventionsAsyncAction.getProposalByIdAsync(params.inventions[0]));
      }
      if (params.utility) {
        yield put(utilityModelsAsyncAction.getProposalByIdAsync(params.utility[0]));
      }
      yield put(setSuccessMessage(i18n.t(messageRequestSent), response.status.toString()));
    }
  }
  yield put(actions.setPendingPostRenewal(false));
}
