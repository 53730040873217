// Core
import { apply, put } from 'redux-saga/effects';

// config
import { api } from '../../../../config/api';

// actions
import actions from '../../action';

export function* callGetWorksWorker({ payload: params }) {
  yield put(actions.setWorksData({ pending: true }));
  const response = yield apply(api, api.works.getWorks, [params]);

  let data = {
    items: [],
    totalCount: 0,
    pending: false,
  };

  if (response && response.status >= 200 && response.status < 400) {
    data = Object.assign(data, response.data);
  }

  yield put(actions.setWorksData(data));
  yield put(actions.setReload(false));
}
