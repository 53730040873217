// Core
import { apply, put } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';
// import isEmpty from 'lodash/isEmpty';

// config
import { listEntityName } from '../../../../config/listTablesName';
import { api } from '../../../../config/api';

// actions
import action from '../../action';
import pendingActions from '../../../pendingActions/action';

const entity = listEntityName.systemMessage;

export function* callGetSystemMessageAllListWorker({ payload: params }) {
  const {
    setSystemMessageAllList,
  } = action;

  const response = yield apply(api, api.systemMessage.getSystemMessage, [{ ...params, entity }]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (!has(data, 'error')) {
      yield put(setSystemMessageAllList(data.items));
      yield put(pendingActions.setDisableNotifications(true));
    }
  }
}
