// Core
import React, { memo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import * as PropTypes from 'prop-types';

// ui
import {
  Typography, CardContent,
  Grid, Card as MuiCard,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';

// lodash
import { isEmpty, isEqual } from 'lodash';

// parts
import FieldClient from '../ui/Form/FieldsAutocomplete/FieldClient';

// config
import selectors from '../engine/config/selectors';

// hooks
import { useEventsAutocompleteAsync } from '../ui/_hooks/useEventsAutocompleteAsync';

// styles
const Card = styled(MuiCard)(spacing);

const fieldNameClient = 'clients';

function CardAutocompleteClient(props) {
  const {
    clientById, fieldClient, formName, disabled,
  } = props;
  const { t } = useTranslation();
  const {
    getAutocompleteLists,
    handleGetSelectOption,
    handleGetAsyncData,
  } = useEventsAutocompleteAsync();
  const handleClientsGetSelectOption = (elem) => {
    handleGetSelectOption(elem, fieldNameClient);
  };
  const client = clientById.toJS();
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h4" gutterBottom>
          {t('Client')}
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <FieldClient
              name={fieldNameClient}
              getAutocompleteLists={getAutocompleteLists}
              formName={formName}
              getAsyncData={handleGetAsyncData}
              propsField={{
                getSelectOption: handleClientsGetSelectOption,
                disabled,
              }}
            />
          </Grid>
        </Grid>

        {!isEmpty(client) && !isEmpty(fieldClient) && (
          <Grid container spacing={4} item xs={12} alignItems="center">
            <Grid item xs={12} container direction="column">
              <Typography variant="subtitle2">
                {client?.name}
              </Typography>
              {!isEmpty(client.addresses) && !isEmpty(client.addresses[0]) && (
                <Typography variant="body2" gutterBottom>
                  {t('Street')}
                  {': '}
                  {client.addresses[0]?.address}
                  <br />
                  {t('Country')}
                  {': '}
                  {client.addresses[0]?.country?.label}
                  <br />
                  {t('City')}
                  {': '}
                  {client.addresses[0]?.city}
                </Typography>
              )}
              {!isEmpty(client.phone) && (
                <Typography variant="body2" gutterBottom>
                  {t('Phone')}
                  {': '}
                  {client?.phone}
                </Typography>
              )}
              {!isEmpty(client.email) && (
                <Typography variant="body2" gutterBottom>
                  {t('Email')}
                  {': '}
                  {client?.email}
                </Typography>
              )}
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
}

CardAutocompleteClient.displayName = 'CardAutocompleteClient';

CardAutocompleteClient.propTypes = {
  clientById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  formName: PropTypes.string.isRequired,
  fieldClient: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array,
  ]),
  disabled: PropTypes.bool,
};

CardAutocompleteClient.defaultProps = {
  fieldClient: {},
  disabled: false,
};

function mapStateToProps(state, { formName, propsField }) {
  const getFormValues = selectors.form.getFormValues(state, formName).toJS();

  return {
    clientById: selectors.clients.getClientById(state),
    fieldClient: getFormValues[propsField && propsField.name ? propsField.name : fieldNameClient],
  };
}

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.clientById, nextProps.clientById)
  && isEqual(prevProps.fieldClient, nextProps.fieldClient)
  && isEqual(prevProps.disabled, nextProps.disabled)
  && isEqual(prevProps.formName, nextProps.formName);
}

export default compose(
  connect(mapStateToProps, null),
)(memo(CardAutocompleteClient, areEqual));
