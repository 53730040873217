// Helpers
import actionCreator from '../../_helpers/actionCreator';
// Types
import types from './types';

const actions = Object.freeze({
  setDepartmentDocumentSelectionsModal(list) {
    return actionCreator(types.SET_DEPARTMENT_DOCUMENT_SELECTIONS_MODAL, list);
  },
  setDepartmentDocumentById(data) {
    return actionCreator(types.SET_DEPARTMENT_DOCUMENT_BY_ID, data);
  },
  setDepartmentDocumentsData(data) {
    return actionCreator(types.SET_DEPARTMENT_DOCUMENTS_DATA, data);
  },
  setCurrentPage(currentPage) {
    return actionCreator(types.SET_DEPARTMENT_DOCUMENTS_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return actionCreator(types.SET_DEPARTMENT_DOCUMENTS_FILTERS, filters);
  },
  setSorting(sorting) {
    return actionCreator(types.SET_DEPARTMENT_DOCUMENTS_SORTING, sorting);
  },
  setPageSize(pageSize) {
    return actionCreator(types.SET_DEPARTMENT_DOCUMENTS_PAGE_SIZE, pageSize);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_DEPARTMENT_DOCUMENTS_INITIAL_STATE, initialState);
  },
  setReload(reload) {
    return actionCreator(types.SET_DEPARTMENT_DOCUMENTS_RELOAD, reload);
  },
  setDeleteDepartmentDocument(data) {
    return actionCreator(types.SET_DELETE_DEPARTMENT_DOCUMENT, data);
  },
  setAutocompleteOptions(options) {
    return actionCreator(types.SET_DEPARTMENT_DOCUMENTS_AUTOCOMPLETE_OPTIONS, options);
  },
  setPendingAutocompleteOptions(pending) {
    return actionCreator(types.SET_PENDING_DEPARTMENT_DOCUMENTS_AUTOCOMPLETE_OPTIONS, pending);
  },
  setDepartmentDocumentNextNumber(data) {
    return actionCreator(types.SET_DEPARTMENT_DOCUMENTS_NEXT_NUMBER, data);
  },
  mergeDepartmentDocumentEntityList(data) {
    return actionCreator(types.MERGE_DEPARTMENT_DOCUMENTS_ENTITY_LIST, data);
  },
  setDepartmentDocumentEntityList(data) {
    return actionCreator(types.SET_DEPARTMENT_DOCUMENTS_ENTITY_LIST, data);
  },
  setAdditionalDocuments(data) {
    return actionCreator(types.SET_DEPARTMENT_DOCUMENTS_ADDITIONAL_DOCUMENTS, data);
  },
  setSelectedDocuments(data) {
    return actionCreator(types.SET_DEPARTMENT_DOCUMENTS_SELECTED_DOCUMENTS, data);
  },
  setColumnWidths(data) {
    return actionCreator(types.SET_DEPARTMENT_DOCUMENTS_COLUMN_WIDTHS, data);
  },
  setColumnOrder(data) {
    return actionCreator(types.SET_DEPARTMENT_DOCUMENTS_COLUMN_ORDER, data);
  },
  setHiddenColumnNames(data) {
    return actionCreator(types.SET_DEPARTMENT_DOCUMENTS_HIDDEN_COLUMN_NAMES, data);
  },
  setFileSign(data) {
    return actionCreator(types.SET_DEPARTMENT_DOCUMENTS_FILE_SIGN, data);
  },
  setDocumentIdSend(data) {
    return actionCreator(types.SET_DEPARTMENT_DOCUMENTS_ID_SEND, data);
  },
});

export default actions;
