// Core
import { call, put } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../config/api';

// actions
import actions from '../../action';

// helpers
import { setErrorMessage } from '../../../../../ui/_helpers/setNotificationMessage';

export function* callGetPreviewFileByIdWorker({ payload: id }) {
  yield put(actions.setPreviewFileById({ pending: true }));

  const response = yield call(api.files.getPreviewFileById, id);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      yield put(actions.setPreviewFileById(data));
      yield put(actions.setModal({ isModalOpenDocViewer: true }));
    }
  }

  yield put(actions.setPreviewFileById({ pending: false }));
}
