// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const asyncActions = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_DEPARTMENT_DOCUMENTS_ASYNC, params);
  },
  getDepartmentDocumentByIdAsync(id) {
    return actionCreator(asyncTypes.GET_DEPARTMENT_DOCUMENT_BY_ID_ASYNC, id);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_DEPARTMENT_DOCUMENTS_INITIAL_STATE_ASYNC, path);
  },
  deleteDepartmentDocumentAsync() {
    return actionCreator(asyncTypes.DELETE_DEPARTMENT_DOCUMENT_ASYNC);
  },
  postDepartmentDocumentAsync(data) {
    return actionCreator(asyncTypes.POST_DEPARTMENT_DOCUMENT_ASYNC, data);
  },
  putDepartmentDocumentByIdAsync(data) {
    return actionCreator(asyncTypes.PUT_DEPARTMENT_DOCUMENT_BY_ID_ASYNC, data);
  },
  getAutocompleteOptionsAsync(params) {
    return actionCreator(asyncTypes.GET_DEPARTMENT_DOCUMENTS_AUTOCOMPLETE_OPTIONS_ASYNC, params);
  },
  getNextNumberAsync(params) {
    return actionCreator(asyncTypes.GET_DEPARTMENT_DOCUMENTS_NEXT_NUMBER_ASYNC, params);
  },
});

export default asyncActions;
