// Helpers
import actionCreator from '../../_helpers/actionCreator';
// Types
import types from './types';

const usersFiltersActions = Object.freeze({
  setUsersFiltersData(data) {
    return actionCreator(types.SET_USERS_FILTERS_DATA, data);
  },
  setDeleteUsersFilter(data) {
    return actionCreator(types.SET_DELETE_USERS_FILTER, data);
  },
  setIsModalOpen(data) {
    return actionCreator(types.SET_USERS_FILTERS_IS_MODAL_OPEN, data);
  },
  setPendingPost(data) {
    return actionCreator(types.SET_USERS_FILTERS_PENDING_POST, data);
  },
});

export default usersFiltersActions;
