// Core
import { call, put } from 'redux-saga/effects';

// config
import { api } from '../../../../config/api';

// actions
import action from '../../action';

export function* callGetDocumentTypeByIdWorker({ payload: id }) {
  const {
    setDocumentTypeById,
  } = action;
  yield put(setDocumentTypeById({
    pending: true,
  }));

  const response = yield call(api.documentTypes.getDocumentTypeById, id);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    yield put(setDocumentTypeById({
      data: {
        ...data,
        name: data.label || '',
        daysAnswer: `${data.daysAnswer || ''}`,
      },
    }));
  }

  yield put(setDocumentTypeById({
    pending: false,
  }));
}
