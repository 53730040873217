// Core
import { all, call, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetDocumentTypesCategoryWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callDeleteDocumentTypeCategoryWorker,
  callPostDocumentTypeCategoryWorker,
  callGetDocumentTypeCategoryByIdWorker,
  callPutDocumentTypeCategoryByIdWorker,
  callGetAutocompleteOptionsWorker,
} from './workers';
import types from '../types';

function* watchGetDocumentTypesCategory() {
  yield takeEvery(asyncTypes.GET_DOCUMENT_TYPES_CATEGORY_ASYNC, callGetDocumentTypesCategoryWorker);
}
function* watchGetInitialState() {
  yield takeEvery(
    asyncTypes.GET_DOCUMENT_TYPES_CATEGORY_INITIAL_STATE_ASYNC,
    callGetInitialStateWorker,
  );
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_DOCUMENT_TYPES_CATEGORY_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_DOCUMENT_TYPES_CATEGORY_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_DOCUMENT_TYPES_CATEGORY_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_DOCUMENT_TYPES_CATEGORY_SORTING, callSetToLocalDataWorker);
}
function* watchSetColumnWidths() {
  yield takeEvery(types.SET_DOCUMENT_TYPES_CATEGORY_COLUMN_WIDTHS, callSetToLocalDataWorker);
}
function* watchSetColumnOrder() {
  yield takeEvery(types.SET_DOCUMENT_TYPES_CATEGORY_COLUMN_ORDER, callSetToLocalDataWorker);
}
function* watchSetHiddenColumnNames() {
  yield takeEvery(types.SET_DOCUMENT_TYPES_CATEGORY_HIDDEN_COLUMN_NAMES, callSetToLocalDataWorker);
}

function* watchDeleteDocumentTypeCategory() {
  yield takeEvery(
    asyncTypes.DELETE_DOCUMENT_TYPE_CATEGORY_ASYNC,
    callDeleteDocumentTypeCategoryWorker,
  );
}

function* watchPostDocumentTypeCategory() {
  yield takeEvery(asyncTypes.POST_DOCUMENT_TYPE_CATEGORY_ASYNC, callPostDocumentTypeCategoryWorker);
}

function* watchGetDocumentTypeCategoryById() {
  yield takeEvery(
    asyncTypes.GET_DOCUMENT_TYPE_CATEGORY_BY_ID_ASYNC,
    callGetDocumentTypeCategoryByIdWorker,
  );
}

function* watchPutDocumentTypeCategoryById() {
  yield takeEvery(
    asyncTypes.PUT_DOCUMENT_TYPE_CATEGORY_BY_ID_ASYNC,
    callPutDocumentTypeCategoryByIdWorker,
  );
}

function* watchGetAutocompleteOptions() {
  yield takeEvery(
    asyncTypes.GET_DOCUMENT_TYPES_CATEGORY_AUTOCOMPLETE_OPTIONS_ASYNC,
    callGetAutocompleteOptionsWorker,
  );
}

export function* watchersDocumentTypesCategoryTable() {
  yield all([
    call(watchSetCurrentPage),
    call(watchSetPageSize),
    call(watchSetFilters),
    call(watchSetSorting),
    call(watchGetInitialState),
    call(watchGetAutocompleteOptions),
    call(watchGetDocumentTypesCategory),
    call(watchDeleteDocumentTypeCategory),
    call(watchPostDocumentTypeCategory),
    call(watchGetDocumentTypeCategoryById),
    call(watchPutDocumentTypeCategoryById),
    call(watchSetColumnWidths),
    call(watchSetColumnOrder),
    call(watchSetHiddenColumnNames),
  ]);
}
