// core
import React, { memo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import { Field, Form, reduxForm } from 'redux-form/lib/immutable';

// config
import selectors from '../../../../../engine/config/selectors';

// helpers
import { validators } from '../../../../_helpers/validation';

// parts
import RenderTextField from '../../../../Form/RenderTextField';
import Modal from '../../../../../components/Modal/Modal';
import DialogActions from '../../../../../components/Modal/components/DialogActions';

// actions
import usersFiltersActions from '../../../../../engine/core/usersFilters/action';
import usersFiltersAsyncActions from '../../../../../engine/core/usersFilters/saga/asyncAction';

function SaveFiltersModal(props) {
  const {
    handleSubmit, filters, isModalOpen,
    pendingPost, destroy, pathname,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    destroy();
    dispatch(usersFiltersActions.setIsModalOpen(false));
  };

  const handleSubmits = (formData) => {
    const json = formData.toJS();
    dispatch(usersFiltersAsyncActions.postUsersFilterAsync({
      name: json.name,
      location: pathname,
      filters,
    }));
  };

  return (
    <Modal
      title={t('Save filter')}
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
      displayDialogActions={false}
    >
      <Form onSubmit={handleSubmit(handleSubmits)}>
        <Field
          required
          validate={validators.required}
          name="name"
          id="name"
          label={t('Name')}
          variant="standard"
          fullWidth
          type="text"
          component={RenderTextField}
        />
        <DialogActions
          pending={pendingPost}
          handleCloseModal={handleCloseModal}
        />
      </Form>
    </Modal>
  );
}

SaveFiltersModal.displayName = 'SaveFiltersModal';

SaveFiltersModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  pendingPost: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  destroy: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  pathname: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    isModalOpen: selectors.usersFilters.isModalOpen(state),
    pendingPost: selectors.usersFilters.pendingPost(state),
    pathname: selectors.router.pathname(state),
  };
}

export default compose(
  reduxForm({
    form: 'SaveFiltersModal',
  }),
  connect(mapStateToProps, null),
)(memo(SaveFiltersModal));
