const asyncTypes = Object.freeze({
  GET_INDUSTRIAL_DESIGNS_PROPOSAL_ASYNC: 'GET_INDUSTRIAL_DESIGNS_PROPOSAL_ASYNC',
  GET_INDUSTRIAL_DESIGNS_PROPOSAL_INITIAL_STATE_ASYNC: 'GET_INDUSTRIAL_DESIGNS_PROPOSAL_INITIAL_STATE_ASYNC',
  DELETE_INDUSTRIAL_DESIGN_PROPOSAL_ASYNC: 'DELETE_INDUSTRIAL_DESIGN_PROPOSAL_ASYNC',
  GET_INDUSTRIAL_AUTOCOMPLETE_OPTIONS_ASYNC: 'GET_INDUSTRIAL_AUTOCOMPLETE_OPTIONS_ASYNC',
  GET_INDUSTRIAL_DESIGNS_PROPOSAL_BY_ID_ASYNC: 'GET_INDUSTRIAL_DESIGNS_PROPOSAL_BY_ID_ASYNC',
  PUT_INDUSTRIAL_DESIGNS_PROPOSAL_ASYNC: 'PUT_INDUSTRIAL_DESIGNS_PROPOSAL_ASYNC',
  POST_INDUSTRIAL_DESIGNS_PROPOSAL_ASYNC: 'POST_INDUSTRIAL_DESIGNS_PROPOSAL_ASYNC',
  GET_INDUSTRIAL_DESIGNS_FIND_COMPANY_ASYNC: 'GET_INDUSTRIAL_DESIGNS_FIND_COMPANY_ASYNC',
  GET_INDUSTRIAL_REGISTRY_BY_NUMBER_ASYNC: 'GET_INDUSTRIAL_REGISTRY_BY_NUMBER_ASYNC',
  GET_INDUSTRIAL_DESIGNS_PROPOSAL_NEXT_NUMBER_ASYNC: 'GET_INDUSTRIAL_DESIGNS_PROPOSAL_NEXT_NUMBER_ASYNC',
  PUT_INDUSTRIAL_DESIGNS_PROPOSAL_CLONE_ASYNC: 'PUT_INDUSTRIAL_DESIGNS_PROPOSAL_CLONE_ASYNC',
});

export default asyncTypes;
