// Helpers
import actionCreator from '../../_helpers/actionCreator';
// Types
import types from './types';

const actions = Object.freeze({
  setActionTypeById(data) {
    return actionCreator(types.SET_ACTION_TYPES_BY_ID, data);
  },
  setPendingActionTypesData(pending) {
    return actionCreator(types.SET_PENDING_ACTION_TYPES_DATA, pending);
  },
  setActionTypesData(data) {
    return actionCreator(types.SET_ACTION_TYPES_DATA, data);
  },
  setPendingDeleteActionType(data) {
    return actionCreator(types.SET_PENDING_DELETE_ACTION_TYPE, data);
  },
  setIsModalOpen(data) {
    return actionCreator(types.SET_ACTION_TYPES_IS_MODAL_OPEN, data);
  },
  setDefaultName(name) {
    return actionCreator(types.SET_ACTION_TYPE_DEFAULT_NAME, name);
  },
  setNewActionType(name) {
    return actionCreator(types.SET_NEW_ACTION_TYPE, name);
  },
});

export default actions;
