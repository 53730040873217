// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const asyncActions = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_INDUSTRIAL_DESIGNS_PROPOSAL_ASYNC, params);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_INDUSTRIAL_DESIGNS_PROPOSAL_INITIAL_STATE_ASYNC, path);
  },
  deleteProposalAsync() {
    return actionCreator(asyncTypes.DELETE_INDUSTRIAL_DESIGN_PROPOSAL_ASYNC);
  },
  getAutocompleteOptionsAsync(params) {
    return actionCreator(asyncTypes.GET_INDUSTRIAL_AUTOCOMPLETE_OPTIONS_ASYNC, params);
  },
  getProposalByIdAsync(id) {
    return actionCreator(asyncTypes.GET_INDUSTRIAL_DESIGNS_PROPOSAL_BY_ID_ASYNC, id);
  },
  putProposalAsync(params) {
    return actionCreator(asyncTypes.PUT_INDUSTRIAL_DESIGNS_PROPOSAL_ASYNC, params);
  },
  postProposalAsync(params) {
    return actionCreator(asyncTypes.POST_INDUSTRIAL_DESIGNS_PROPOSAL_ASYNC, params);
  },
  getFindCompanyAsync(params) {
    return actionCreator(asyncTypes.GET_INDUSTRIAL_DESIGNS_FIND_COMPANY_ASYNC, params);
  },
  getRegistryByNumberAsync(params) {
    return actionCreator(asyncTypes.GET_INDUSTRIAL_REGISTRY_BY_NUMBER_ASYNC, params);
  },
  getNextNumberAsync(params) {
    return actionCreator(asyncTypes.GET_INDUSTRIAL_DESIGNS_PROPOSAL_NEXT_NUMBER_ASYNC, params);
  },
  putProposalCloneAsync(params) {
    return actionCreator(asyncTypes.PUT_INDUSTRIAL_DESIGNS_PROPOSAL_CLONE_ASYNC, params);
  },
});

export default asyncActions;
