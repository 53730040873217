const asyncTypes = Object.freeze({
  GET_EMAIL_MESSAGES_ASYNC: 'GET_EMAIL_MESSAGES_ASYNC',
  GET_EMAIL_MESSAGES_INITIAL_STATE_ASYNC: 'GET_EMAIL_MESSAGES_INITIAL_STATE_ASYNC',
  GET_EMAIL_THREADS_MESSAGES_ASYNC: 'GET_EMAIL_THREADS_MESSAGES_ASYNC',
  GET_EMAIL_CHECK_NEW_ASYNC: 'GET_EMAIL_CHECK_NEW_ASYNC',

  POST_EMAIL_SEND_ASYNC: 'POST_EMAIL_SEND_ASYNC',
  POST_EMAIL_PREVIEW_ASYNC: 'POST_EMAIL_PREVIEW_ASYNC',
  GET_EMAIL_DOWNLOAD_ATTACHMENT_ASYNC: 'GET_EMAIL_DOWNLOAD_ATTACHMENT_ASYNC',

  GET_EMAIL_SIGNATURES_ASYNC: 'GET_EMAIL_SIGNATURES_ASYNC',
  DELETE_EMAIL_SIGNATURES_ASYNC: 'DELETE_EMAIL_SIGNATURES_ASYNC',
  POST_EMAIL_SIGNATURES_ASYNC: 'POST_EMAIL_SIGNATURES_ASYNC',
  GET_EMAIL_SIGNATURES_BY_ID_ASYNC: 'GET_EMAIL_SIGNATURES_BY_ID_ASYNC',
  PUT_EMAIL_SIGNATURES_BY_ID_ASYNC: 'PUT_EMAIL_SIGNATURES_BY_ID_ASYNC',

  GET_EMAIL_ASYNC: 'GET_EMAIL_ASYNC',
  DELETE_EMAIL_ASYNC: 'DELETE_EMAIL_ASYNC',
  POST_EMAIL_ASYNC: 'POST_EMAIL_ASYNC',
  GET_EMAIL_BY_ID_ASYNC: 'GET_EMAIL_BY_ID_ASYNC',
  PUT_EMAIL_BY_ID_ASYNC: 'PUT_EMAIL_BY_ID_ASYNC',
  GET_CHECK_EMAIL_ASYNC: 'GET_CHECK_EMAIL_ASYNC',

  GET_EMAIL_LETTERS_ASYNC: 'GET_EMAIL_LETTERS_ASYNC',
  DELETE_EMAIL_LETTERS_ASYNC: 'DELETE_EMAIL_LETTERS_ASYNC',
  POST_EMAIL_LETTERS_ASYNC: 'POST_EMAIL_LETTERS_ASYNC',
  GET_EMAIL_LETTERS_BY_ID_ASYNC: 'GET_EMAIL_LETTERS_BY_ID_ASYNC',

});

export default asyncTypes;
