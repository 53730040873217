export default Object.freeze({
  SET_JURISDICTIONS_DATA: 'SET_JURISDICTIONS_DATA',
  SET_JURISDICTION_BY_ID: 'SET_JURISDICTION_BY_ID',
  SET_JURISDICTIONS_RELOAD: 'SET_JURISDICTIONS_RELOAD',
  SET_JURISDICTIONS_CURRENT_PAGE: 'SET_JURISDICTIONS_CURRENT_PAGE',
  SET_JURISDICTIONS_FILTERS: 'SET_JURISDICTIONS_FILTERS',
  SET_JURISDICTIONS_SORTING: 'SET_JURISDICTIONS_SORTING',
  SET_JURISDICTIONS_PAGE_SIZE: 'SET_JURISDICTIONS_PAGE_SIZE',
  SET_JURISDICTIONS_INITIAL_STATE: 'SET_JURISDICTIONS_INITIAL_STATE',
  SET_DELETE_JURISDICTION: 'SET_DELETE_JURISDICTION',
  SET_JURISDICTIONS_AUTOCOMPLETE_OPTIONS: 'SET_JURISDICTIONS_AUTOCOMPLETE_OPTIONS',
  SET_PENDING_JURISDICTIONS_AUTOCOMPLETE_OPTIONS: 'SET_PENDING_JURISDICTIONS_AUTOCOMPLETE_OPTIONS',
  SET_JURISDICTIONS_IS_MODAL_OPEN: 'SET_JURISDICTIONS_IS_MODAL_OPEN',
  SET_JURISDICTION_DATA_BY_ID: 'SET_JURISDICTION_DATA_BY_ID',
  SET_JURISDICTIONS_COLUMN_WIDTHS: 'SET_JURISDICTIONS_COLUMN_WIDTHS',
  SET_JURISDICTIONS_COLUMN_ORDER: 'SET_JURISDICTIONS_COLUMN_ORDER',
  SET_JURISDICTIONS_HIDDEN_COLUMN_NAMES: 'SET_JURISDICTIONS_HIDDEN_COLUMN_NAMES',
});
