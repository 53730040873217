// Core
import React from 'react';
import { Trans } from 'react-i18next';

export const fieldNames = Object.freeze({
  amount: <Trans>Employee</Trans>,
  client: <Trans>Client</Trans>,
  finalAt: <Trans>Finish At</Trans>,
  orders: <Trans>Orders</Trans>,
  status: <Trans>Status</Trans>,
  title: <Trans>Name</Trans>,
  applicationNumber: <Trans>Application number</Trans>,
  caseNumber: <Trans>Case number</Trans>,
  'data.applicants.address': <Trans>Address applicant</Trans>,
  'data.applicants.country': <Trans>Country applicant</Trans>,
  'data.applicants.fis': <Trans>Individual person</Trans>,
  'data.applicants.innCode': <Trans>InnCode applicant</Trans>,
  'data.applicants.name': <Trans>Name applicant</Trans>,
  'data.idAttorney': <Trans>Representative</Trans>,
  'data.postalAddress.address': <Trans>Address</Trans>,
  'data.postalAddress.country': <Trans>Country</Trans>,
  'data.postalAddress.email': <Trans>Email postal address</Trans>,
  'data.postalAddress.filters': <Trans>Filters</Trans>,
  'data.postalAddress.fullName': <Trans>Recipient</Trans>,
  'data.postalAddress.innCode': <Trans>Inn Code</Trans>,
  'data.postalAddress.phone': <Trans>Phone postal address</Trans>,
  description: <Trans>Description</Trans>,
  email: <Trans>Email</Trans>,
  files: <Trans>Files</Trans>,
  images: <Trans>Images</Trans>,
  'images.data': <Trans>Data images</Trans>,
  'images.name': <Trans>Name images</Trans>,
  'images.number': <Trans>Number images</Trans>,
  notes: <Trans>Notes</Trans>,
  payments: <Trans>Payments</Trans>,
  phone: <Trans>Phone</Trans>,
  registrationNumber: <Trans>Registration number</Trans>,
  'data.colors': <Trans>Colors</Trans>,
  'data.logo': <Trans>Logo</Trans>,
  'data.postalAddress.fis': <Trans>Individual person</Trans>,
  'data.standardCharacters': <Trans>Standard characters</Trans>,
  estimate: <Trans>Estimate</Trans>,
  mktu: <Trans>ICGS</Trans>,
  search: <Trans>Search</Trans>,
  blueprints: <Trans>Blueprints</Trans>,
  formula: <Trans>Formula</Trans>,
  classes: <Trans>Classes</Trans>,
  file: <Trans>File</Trans>,
  industrial: <Trans>the Industrial Design</Trans>,
  inventions: <Trans>the Invention</Trans>,
  responseDocument: <Trans>Response document</Trans>,
  trademarks: <Trans>the Trademark</Trans>,
  type: <Trans>Type</Trans>,
  utility: <Trans>the Utility Model</Trans>,
  internalTitle: <Trans>Internal name</Trans>,
  documentClassifier: <Trans>Document classifier</Trans>,
  documentDate: <Trans>Document date</Trans>,
  receivingDate: <Trans>Received At</Trans>,
  company: <Trans>Company</Trans>,
  name: <Trans>Name</Trans>,
  applicationDate: <Trans>Date of submission</Trans>,
  createdAt: <Trans>Created At</Trans>,
  tags: <Trans>Tags</Trans>,
  registrationDate: <Trans>Registration date</Trans>,
  documents: <Trans>Documents</Trans>,
  boost: <Trans>Expedited application</Trans>,
  eApplication: <Trans>Electronic application</Trans>,
  jurisdictions: <Trans>Jurisdiction</Trans>,
  expiryDate: <Trans>End date</Trans>,
  initialPublication: <Trans>Publication of the application (441)</Trans>,
  clients: <Trans>Client</Trans>,
  applicants: <Trans>Applicants</Trans>,
  attorney: <Trans>Attorney</Trans>,
  correspondence: <Trans>Recipients</Trans>,
  additionalApplicationData: <Trans>Registration in the country of origin</Trans>,
  holders: <Trans>Owners</Trans>,
  clientReference: <Trans>Client&apos;s case number</Trans>,
  innCode: <Trans>Inn Code</Trans>,
  typeOfOwnership: <Trans>Type of ownership</Trans>,
  iban: <Trans>IBAN</Trans>,
  inNumber: <Trans>In number</Trans>,
  outNumber: <Trans>Out number</Trans>,
});
