// Core
import { apply } from 'redux-saga/effects';

// Engine
import localData from '../../../../config/localData';

// types
import types from '../../types';

export function* callSetToLocalDataWorker({ type, payload }) {
  switch (type) {
    case types.SET_VISIBLE_SIDEBAR: {
      yield apply(localData, localData.setItem, ['visibleSidebar', payload]);
      break;
    }
    case types.SET_CURRENT_LONG_MENU: {
      yield apply(localData, localData.setItem, ['currentLongMenu', payload]);
      break;
    }
    case types.SET_SIGNATURE_INFO: {
      yield apply(localData, localData.setItem, ['signatureInfo', payload]);
      break;
    }
    case types.SET_SELECTED_COMPANY: {
      yield apply(localData, localData.setItem, ['selectedCompany', payload]);
      break;
    }
    default: {
      break;
    }
  }
}
