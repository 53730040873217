// core
import moment from 'moment';
import { isNil } from 'lodash';

const reverseDate = (date) => date.split('.').reverse().join('-');

export const formatDate = (date) => {
  if (isNil(date)) {
    return date;
  }
  return moment(date).format('YYYY-MM-DD');
};
export const formatDateDotSeparate = (date) => {
  if (isNil(date)) {
    return date;
  }
  return moment(date).format('DD.MM.YYYY');
};
export const getValueFormatDate = (date) => (date ? reverseDate(date) : undefined);
export const getObjectReverseFormatDate = (date, property) => (date ? {
  [property]: reverseDate(date),
} : {});
