// Core
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

// Lodash
import {
  map, isEmpty,
} from 'lodash';

// actions
import helpersActions from '../../../engine/core/helpers/action';
import paymentsActions from '../../../engine/core/company/payments/action';
import paymentsActionAsync from '../../../engine/core/company/payments/saga/asyncAction';
import clientsAsyncActions from '../../../engine/core/company/clients/table/saga/asyncAction';

// parts
import DxTable from '../../../ui/Table/DxTable';
import ConfirmModal from '../../../components/ConfirmModal';

// config
import selectors from '../../../engine/config/selectors';
import { listTablesName, listEntityName } from '../../../engine/config/listTablesName';
import { useAccessList } from '../../../ui/_hooks/useAccessList';
import accessList from '../../../engine/config/accessList';

const tableName = listTablesName.payments;
const entityName = listEntityName.payments;

function PaymentsTable(props) {
  const {
    paymentsData, totalCount, pending,
    currentPage, filters, sorting,
    pageSize, pendingDeletePayment, checkboxes,
    columnWidths, columnOrder, hiddenColumnNames,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const accessClientsList = useAccessList(accessList.clients_list_get);

  useEffect(() => {
    if (accessClientsList) {
      dispatch(clientsAsyncActions.getListAsync({
        sortBy: 'createdAt',
        sortDir: 'asc',
        limit: 300,
        page: 1,
      }));
    }
  }, [dispatch, accessClientsList]);

  useEffect(() => function cleanup() {
    dispatch(paymentsActions.setPaymentsData({
      items: [],
      totalCount: 0,
      pending: false,
    }));
  }, [dispatch]);

  const columns = [
    { name: 'id', title: t('ID') },
    { name: 'client', title: t('Client'), customField: 'paymentsTable' },
    { name: 'sum', title: t('Amount') },
    { name: 'unappliedSum', title: t('Remainder') },
    { name: 'paymentDate', title: t('Date') },
  ];

  const tableColumnExtensions = [
    { columnName: 'id', width: 50 },
    { columnName: 'client', width: '40rem' },
    { columnName: 'sum', width: '10rem' },
    { columnName: 'unappliedSum', width: '10rem' },
    { columnName: 'paymentDate', width: '10rem' },
  ];

  // Paging
  const onCurrentPage = (page) => dispatch(paymentsActions.setCurrentPage(page));
  const onPageSize = (size) => dispatch(paymentsActions.setPageSize(size));
  // Filtering
  const setFilters = (getFilters) => dispatch(paymentsActions.setFilters(getFilters));
  // Sorting
  const onSortingChange = (getSorting) => dispatch(paymentsActions.setSorting(getSorting));
  const onColumnWidthsChange = (getColumnWidths) => dispatch(
    paymentsActions.setColumnWidths(getColumnWidths),
  );
  const onColumnOrderChange = (getColumnOrder) => dispatch(
    paymentsActions.setColumnOrder(getColumnOrder),
  );
  const onHiddenColumnNamesChange = (getHiddenColumnNames) => dispatch(
    paymentsActions.setHiddenColumnNames(getHiddenColumnNames),
  );

  return (
    <>
      <DxTable
        name="Payments"
        isLoading={pending}
        columns={columns}
        rows={paymentsData.toJS().items}
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageSize={onPageSize}
        onCurrentPage={onCurrentPage}
        filtersAction={setFilters}
        filters={filters}
        entityName={entityName}
        tableColumnExtensions={!isEmpty(columnWidths) && columnWidths.length === columns.length
          ? columnWidths : tableColumnExtensions}
        onColumnWidthsChange={onColumnWidthsChange}
        columnOrder={!isEmpty(columnOrder) ? columnOrder : map(columns, 'name')}
        onColumnOrderChange={onColumnOrderChange}
        hiddenColumnNames={hiddenColumnNames}
        onHiddenColumnNamesChange={onHiddenColumnNamesChange}
        // checkboxes ------------------------
        tableName={tableName}
        reloadTable={paymentsActions.setReload}
        selection={checkboxes.toJS()}
        onSelectionChange={(checkboxSelect) => {
          dispatch(helpersActions.setCheckbox({
            tableName,
            checkboxes: checkboxSelect,
          }));
        }}
        // ------------------------
        sorting={sorting}
        onSortingChange={onSortingChange}
        accessEdit={accessList.payments_put}
        accessDelete={accessList.payments_delete}
      />

      <ConfirmModal
        buttonSendText="DELETE"
        pending={pendingDeletePayment}
        handleSend={() => {
          dispatch(paymentsActionAsync.deletePaymentAsync());
        }}
      />
    </>
  );
}

PaymentsTable.propTypes = {
  paymentsData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  totalCount: PropTypes.number.isRequired,
  pending: PropTypes.bool.isRequired,
  pendingDeletePayment: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  sorting: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  columnWidths: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  columnOrder: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  hiddenColumnNames: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  pageSize: PropTypes.number.isRequired,
  checkboxes: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

function mapStateToProps(state) {
  return {
    paymentsData: selectors.paymentsTable.paymentsData(state),
    pending: selectors.paymentsTable.pending(state),
    pendingDeletePayment: selectors.paymentsTable.pendingDeletePayment(state),

    // Paging
    totalCount: selectors.paymentsTable.totalCount(state),
    currentPage: selectors.paymentsTable.currentPage(state),
    pageSize: selectors.paymentsTable.pageSize(state),

    // Filtering
    filters: selectors.paymentsTable.filters(state),

    // Sorting
    sorting: selectors.paymentsTable.sorting(state),

    // ColumnWidths
    columnWidths: selectors.paymentsTable.columnWidths(state),
    columnOrder: selectors.paymentsTable.columnOrder(state),
    hiddenColumnNames: selectors.paymentsTable.hiddenColumnNames(state),

    checkboxes: selectors.helpers.getCheckboxes(state, tableName),
  };
}

function mapDispatchToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(PaymentsTable);
