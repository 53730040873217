// core
import React from 'react';
import { Field } from 'redux-form/lib/immutable';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

// parts
import AutocompleteChips from '../AutocompleteChips';

// config
import selectors from '../../../engine/config/selectors';

// helpers
import { getValueFormField } from '../../_helpers/getValueFormField';

// hook
import { useMain } from './_hook/useMain';

function FieldActionTypes(props) {
  const {
    data, formFieldInput, pendingDefaultList, handleEdit,
    name, propsField, label, handleCreateNew,
    getAutocompleteLists, formField, handleDelete,
  } = props;
  const {
    startLoading, handleOnFocus, dataItems,
  } = useMain({
    formField,
    getAutocompleteLists,
    formFieldInput,
  });
  const { t } = useTranslation();

  return (
    <Field
      fullWidth
      changeToStore
      translate
      name={name}
      label={t(label)}
      margin="normal"
      loading={startLoading ? pendingDefaultList : false}
      handleOnFocus={handleOnFocus(name, data)}
      options={dataItems(data)}
      handleCreateNew={handleCreateNew}
      handleEditTag={handleEdit}
      handleDeleteTag={handleDelete}
      component={AutocompleteChips}
      {...propsField}
    />
  );
}

FieldActionTypes.displayName = 'FieldActionTypes';

FieldActionTypes.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  getAutocompleteLists: PropTypes.func.isRequired,
  handleCreateNew: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  pendingDefaultList: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  propsField: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  formField: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array,
  ]),
  formFieldInput: PropTypes.string,
  label: PropTypes.string,
};

FieldActionTypes.defaultProps = {
  formField: {},
  propsField: {},
  formFieldInput: '',
  label: 'Type',
};

function mapStateToProps(state, props) {
  const { formName, name } = props;
  const formField = getValueFormField(state, formName, name);
  const formFieldInput = getValueFormField(state, formName, `${name}Input`);

  return {
    data: selectors.actionTypes.actionTypesData(state),
    pendingDefaultList: selectors.actionTypes.pendingData(state),
    formField: formField(),
    formFieldInput: formFieldInput(),
  };
}

export default compose(
  connect(mapStateToProps, null),
)(FieldActionTypes);
