// Core
import { apply, put } from 'redux-saga/effects';

// lodash
import { has } from 'lodash';

// config
import { api } from '../../../../../config/api';

// actions
import actions from '../../action';

export function* callGetAllCompaniesWorker({ payload: params }) {
  const response = yield apply(api, api.company.getCompanies, [params]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(actions.setAllCompanies([]));
    } else {
      yield put(actions.setAllCompanies(data.items));
    }
  }
}
