// Core
import { call, put } from 'redux-saga/effects';

// config
import { api } from '../../../../../config/api';

// actions
import action from '../../action';

export function* callGetEmailByIdWorker({ payload: id }) {
  const {
    setEmailData, setPendingByIdEmail,
  } = action;
  yield put(setPendingByIdEmail(true));

  const response = yield call(api.email.getEmailById, id);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    yield put(setEmailData(data));
  }

  yield put(setPendingByIdEmail(false));
}
