// Core
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { compose } from 'recompose';
import {
  reduxForm, Form, Field, change,
} from 'redux-form/immutable';
import * as PropTypes from 'prop-types';

// ui
import {
  Typography as MuiTypography, IconButton,
  CardContent, CircularProgress, Grid,
  Card as MuiCard, Box, Button as MuiButton,
  FormControlLabel, Radio,
} from '@material-ui/core';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';

// icon
import {
  Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon,
} from '@material-ui/icons';

// lodash
import {
  isEmpty, map, includes, filter, find,
} from 'lodash';

// parts
import Loader from '../../components/Loader';
import RenderTextField from '../../ui/Form/RenderTextField';
import TransferList from '../../ui/Form/TransferList';

// actions
import permissionsGroupsAsyncAction from '../../engine/core/permissionsGroups/saga/asyncAction';
import permissionsGroupsAction from '../../engine/core/permissionsGroups/action';

// config
import selectors from '../../engine/config/selectors';
import RadioButton from '../../ui/Form/RadioButton';
import accessList from '../../engine/config/accessList';
import { useAccessList } from '../../ui/_hooks/useAccessList';

// styles
const Card = styled(MuiCard)(spacing);
const Button = styled(MuiButton)(spacing);
const TypographyFlex = styled(MuiTypography)`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;
const TypographySub = styled(MuiTypography)`
  color: ${(props) => props.theme.palette.grey['500']};
`;

const formName = 'RolesSettings';

function RolesSettings(props) {
  const {
    permissionsGroupsData, pendingPermissionsGroups,
    pendingPermissions, getPermissions, pendingDelete,
    pendingPost, pendingPut, handleSubmit, destroy, openForm,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [deleteId, setDeleteId] = useState(false);
  const [allList, setAllList] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [editGroup, setEditGroup] = useState({});
  const accessGroupsDelete = useAccessList(accessList.groups_delete);
  const accessGroupsPost = useAccessList(accessList.groups_post);
  const accessGroupsPut = useAccessList(accessList.groups_put);

  useEffect(() => function cleanup() {
    const initialState = { items: [], totalCount: 0, pending: false };
    dispatch(permissionsGroupsAction.setPermissionsGroupsData(initialState));
    dispatch(permissionsGroupsAction.setPermissionsData(initialState));
    dispatch(permissionsGroupsAction.setIsModalOpen(false));
  }, [dispatch]);

  useEffect(() => {
    dispatch(permissionsGroupsAsyncAction.getListAsync());
    dispatch(permissionsGroupsAsyncAction.getPermissionsAsync({ limit: 1000 }));
  }, [dispatch]);

  useEffect(() => {
    if (!openForm) {
      destroy();
      setAllList([]);
      setSelectedList([]);
      setEditGroup({});
    }
  }, [destroy, openForm]);

  const handleDeleteGroup = (group) => {
    dispatch(permissionsGroupsAction.setDeletePermissionsGroup({ id: group.id }));
    dispatch(permissionsGroupsAsyncAction.deletePermissionsGroupsAsync());
    setDeleteId(group.id);
  };

  const handleSubmits = (formData) => {
    const json = formData.toJSON();
    dispatch(permissionsGroupsAsyncAction[!isEmpty(editGroup)
      ? 'putPermissionsGroupsByIdAsync'
      : 'postPermissionsGroupsAsync']({
      ...!isEmpty(editGroup) ? {
        id: editGroup.id,
      } : {},
      title: json.title,
      permissions: [
        ...map(selectedList, (item) => ({
          id: item.id, all: json.all === 'true', own: true,
        })),
        {
          id: find(getPermissions.toJS(), (item) => item.route === 'api_v1_entity_fields_list_get')?.id,
          all: true,
          own: true,
        },
      ],
    }));
  };
  const handleEditGroup = (group) => {
    setEditGroup(group);
    setAllList(filter(getPermissions.toJS(), (item) => !includes(item.groups, group?.id)));
    setSelectedList(filter(getPermissions.toJS(), (item) => includes(item.groups, group?.id)));
    dispatch(change(formName, 'title', group.title));
    dispatch(change(formName, 'all', `${group.permissions?.[0]?.all || false}`));
    dispatch(permissionsGroupsAction.setIsModalOpen(true));
  };

  const handleCloseForm = () => {
    dispatch(permissionsGroupsAction.setIsModalOpen(false));
  };

  const handleOpenForm = () => {
    setAllList(getPermissions.toJS());
    dispatch(change(formName, 'all', 'true'));
    dispatch(permissionsGroupsAction.setIsModalOpen(true));
  };

  return (
    <>
      {(!pendingPermissionsGroups && !pendingPermissions) ? (
        <Card mb={6}>
          <CardContent>
            {openForm ? (
              <Form onSubmit={handleSubmit(handleSubmits)}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={4}>
                    <Field
                      name="title"
                      id="title"
                      label={t('Title')}
                      margin="normal"
                      variant="standard"
                      my={2}
                      fullWidth
                      type="text"
                      component={RenderTextField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TypographySub variant="subtitle1" gutterBottom>
                      {t('Objects visibility')}
                    </TypographySub>
                    <Field
                      aria-label="all"
                      name="all"
                      component={RadioButton}
                      row
                    >
                      <FormControlLabel value="true" control={<Radio />} label={t('All')} />
                      <FormControlLabel value="false" control={<Radio />} label={t('Own')} />
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <TransferList
                      allList={allList}
                      selectedList={selectedList}
                      handleChangeAllList={setAllList}
                      handleChangeSelectedList={setSelectedList}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={pendingPost || pendingPut}
                    >
                      {(pendingPost || pendingPut) ? <CircularProgress size={25} color="inherit" /> : t('CONFIRM')}
                    </Button>

                    <Button
                      onClick={handleCloseForm}
                      color="primary"
                      variant="outlined"
                      ml={2}
                    >
                      {t('CANCEL')}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            ) : (
              <Grid container spacing={6}>
                {!isEmpty(permissionsGroupsData.toJS().items) && (
                  <Grid item xs={12}>
                    {map(permissionsGroupsData.toJS().items, (group, index) => (
                      <TypographyFlex key={index}>
                        <Box component="span">
                          {group?.title}
                        </Box>
                        {accessGroupsPut && (
                          <IconButton
                            ml={4}
                            aria-label="Edit"
                            size="small"
                            onClick={() => handleEditGroup(group)}
                            disabled={pendingDelete}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        )}
                        {accessGroupsDelete && (
                          <IconButton
                            aria-label="Delete"
                            size="small"
                            onClick={() => handleDeleteGroup(group)}
                            disabled={pendingDelete}
                          >
                            {pendingDelete && deleteId === group.id
                              ? <CircularProgress size={20} color="inherit" />
                              : <DeleteIcon fontSize="small" />}
                          </IconButton>
                        )}
                      </TypographyFlex>
                    ))}
                  </Grid>
                )}
                {accessGroupsPost && (
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleOpenForm}
                      disabled={openForm}
                    >
                      {t('ADD ROLE')}
                      <AddIcon />
                    </Button>
                  </Grid>
                )}
              </Grid>
            )}
          </CardContent>
        </Card>
      ) : <Loader />}
    </>
  );
}

RolesSettings.displayName = 'RolesSettings';

RolesSettings.propTypes = {
  permissionsGroupsData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingPermissionsGroups: PropTypes.bool.isRequired,
  pendingPermissions: PropTypes.bool.isRequired,
  pendingDelete: PropTypes.bool.isRequired,
  pendingPost: PropTypes.bool.isRequired,
  pendingPut: PropTypes.bool.isRequired,
  openForm: PropTypes.bool.isRequired,
  getPermissions: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  destroy: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
};

RolesSettings.defaultProps = {};

function mapStateToProps(state) {
  return {
    permissionsGroupsData: selectors.permissionsGroups.permissionsGroupsData(state),
    pendingPermissionsGroups: selectors.permissionsGroups.pending(state),
    getPermissions: selectors.permissionsGroups.getPermissions(state),
    pendingPermissions: selectors.permissionsGroups.pendingPermissions(state),
    pendingDelete: selectors.permissionsGroups.pendingDelete(state),
    pendingPost: selectors.permissionsGroups.pendingPostPermissionsGroup(state),
    pendingPut: selectors.permissionsGroups.pendingPutPermissionsGroup(state),
    openForm: selectors.permissionsGroups.isModalOpen(state),
  };
}

export default compose(
  reduxForm({
    form: formName,
  }),
  connect(mapStateToProps, null),
)(RolesSettings);
