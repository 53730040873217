// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import types from './types';

const currencyRatesActions = Object.freeze({
  setCurrencyRatesData(data) {
    return actionCreator(types.SET_CURRENCY_RATES_DATA, data);
  },
  setCurrencyRatePendingPost(data) {
    return actionCreator(types.SET_CURRENCY_RATE_PENDING_POST, data);
  },
  setCurrentPage(currentPage) {
    return actionCreator(types.SET_CURRENCY_RATES_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return actionCreator(types.SET_CURRENCY_RATES_FILTERS, filters);
  },
  setSorting(sorting) {
    return actionCreator(types.SET_CURRENCY_RATES_SORTING, sorting);
  },
  setColumnWidths(data) {
    return actionCreator(types.SET_CURRENCY_RATES_COLUMN_WIDTHS, data);
  },
  setColumnOrder(data) {
    return actionCreator(types.SET_CURRENCY_RATES_COLUMN_ORDER, data);
  },
  setHiddenColumnNames(data) {
    return actionCreator(types.SET_CURRENCY_RATES_HIDDEN_COLUMN_NAMES, data);
  },
  setPageSize(pageSize) {
    return actionCreator(types.SET_CURRENCY_RATES_PAGE_SIZE, pageSize);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_CURRENCY_RATES_INITIAL_STATE, initialState);
  },
  setReload(reload) {
    return actionCreator(types.SET_CURRENCY_RATES_RELOAD, reload);
  },
  setDeleteCurrencyRate(data) {
    return actionCreator(types.SET_DELETE_CURRENCY_RATE, data);
  },
  setAutocompleteOptions(data) {
    return actionCreator(types.SET_CURRENCY_RATES_AUTOCOMPLETE_OPTIONS, data);
  },
  setPendingAutocompleteOptions(pending) {
    return actionCreator(types.SET_PENDING_CURRENCY_RATES_AUTOCOMPLETE_OPTIONS, pending);
  },
  setIsModalOpen(data) {
    return actionCreator(types.SET_CURRENCY_RATES_IS_MODAL_OPEN, data);
  },
});

export default currencyRatesActions;
