export default Object.freeze({
  SET_REBOUNDS_DATA: 'SET_REBOUNDS_DATA',
  SET_REBOUNDS_AUTOCOMPLETE_OPTIONS: 'SET_REBOUNDS_AUTOCOMPLETE_OPTIONS',
  SET_PENDING_REBOUNDS_AUTOCOMPLETE_OPTIONS: 'SET_PENDING_REBOUNDS_AUTOCOMPLETE_OPTIONS',
  SET_REBOUNDS_CURRENT_PAGE: 'SET_REBOUNDS_CURRENT_PAGE',
  SET_REBOUNDS_PAGE_SIZE: 'SET_REBOUNDS_PAGE_SIZE',
  SET_REBOUNDS_FILTERS: 'SET_REBOUNDS_FILTERS',
  SET_REBOUNDS_SORTING: 'SET_REBOUNDS_SORTING',
  SET_REBOUNDS_INITIAL_STATE: 'SET_REBOUNDS_INITIAL_STATE',
  SET_REBOUNDS_RELOAD: 'SET_REBOUNDS_RELOAD',
  SET_DELETE_REBOUND: 'SET_DELETE_REBOUND',
  SET_REBOUND_BY_ID: 'SET_REBOUND_BY_ID',
  SET_REBOUND_PDF_BY_ID: 'SET_REBOUND_PDF_BY_ID',
  SET_PENDING_REBOUND_PDF: 'SET_PENDING_REBOUND_PDF',
  SET_PENDING_REBOUND_BY_ID: 'SET_PENDING_REBOUND_BY_ID',
  SET_REBOUND_MODAL_CREATE_IS_OPEN: 'SET_REBOUND_MODAL_CREATE_IS_OPEN',
  SET_REBOUND_MODAL_ALL_TERMS_IS_OPEN: 'SET_REBOUND_MODAL_ALL_TERMS_IS_OPEN',
  SET_REBOUND_MODAL_YOUR_LIST_TERMS_IS_OPEN: 'SET_REBOUND_MODAL_YOUR_LIST_TERMS_IS_OPEN',
  SET_SEARCH_REBOUND: 'SET_SEARCH_REBOUND',
  SET_PENDING_SEARCH_REBOUND: 'SET_PENDING_SEARCH_REBOUND',
  SET_REBOUNDS_COLUMN_WIDTHS: 'SET_REBOUNDS_COLUMN_WIDTHS',
  SET_REBOUNDS_COLUMN_ORDER: 'SET_REBOUNDS_COLUMN_ORDER',
  SET_REBOUNDS_HIDDEN_COLUMN_NAMES: 'SET_REBOUNDS_HIDDEN_COLUMN_NAMES',
});
