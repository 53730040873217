// Core
import { apply, put } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../../config/api';

// actions
import { setErrorMessage, setSuccessMessage } from '../../../../../../ui/_helpers/setNotificationMessage';
import i18n from '../../../../../init/i18n';

// actions
import companyActions from '../../action';

export function* callPostCheckFtpWorker({ payload: params }) {
  const { setPendingPostCheckFtp } = companyActions;

  yield put(setPendingPostCheckFtp(true));

  const response = yield apply(api, api.patent.postCheckFtp, [params]);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      yield put(setSuccessMessage(i18n.t('Connection successful!'), response.status.toString()));
    }
  }
  yield put(setPendingPostCheckFtp(false));
}
