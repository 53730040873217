// core
import React, {
  useEffect, useState,
} from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import {
  Field, Form, reduxForm,
} from 'redux-form/immutable';

// lodash
import { isEmpty, find } from 'lodash';

// config
import selectors from '../../../engine/config/selectors';

// parts
import Modal from '../../../components/Modal/Modal';
import DialogActions from '../../../components/Modal/components/DialogActions';
import Select from '../../../ui/Form/Select';
import renderTextField from '../../../ui/Form/RenderTextField';
import AutocompleteField from '../../../ui/Form/AutocompleteField';

// actions
import clientsAction from '../../../engine/core/company/clients/action';

// helpers
import { countryOptions } from '../../../ui/_helpers/country';

const formName = 'AddClientAddressModal';

function AddClientAddressModal(props) {
  const {
    handleSubmit, nameValue, isModalOpenAddress, destroy,
    initialize, handleSetAddresses, editAddress,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isInitialize, setIsInitialize] = useState(false);

  const handleCloseModal = () => {
    destroy();
    dispatch(clientsAction.setIsModalOpenAddress(false));
  };

  const handleSubmits = (formData) => {
    const json = formData.toJS();
    if (json.name === 'custom' && json.customName) {
      json.name = json.customName;
      delete json.customName;
    }
    const { index } = json;
    delete json.index;
    const data = {
      ...json,
      country: json?.country?.abbreviation,
    };
    handleSetAddresses(data, index);
  };
  useEffect(() => {
    if (!isEmpty(editAddress) && !isInitialize) {
      initialize({
        ...editAddress,
        ...!(editAddress.name === 'postal' || editAddress.name === 'legal' || editAddress.name === 'payment')
          ? { name: 'custom', customName: editAddress.name } : {},
        ...editAddress?.country ? {
          country: find(countryOptions, { abbreviation: editAddress?.country }),
        } : {},
      });
      setIsInitialize(true);
    }
  }, [initialize, editAddress, isInitialize, setIsInitialize]);

  useEffect(() => {
    if (isEmpty(editAddress) && !isInitialize) {
      initialize({
        country: { label: 'Ukraine', abbreviation: 'UA' },
      });
      setIsInitialize(true);
    }
  }, [initialize, editAddress, isInitialize, setIsInitialize]);

  return (
    <Modal
      title={t('Address')}
      isModalOpen={isModalOpenAddress}
      handleCloseModal={handleCloseModal}
      displayDialogActions={false}
    >
      <Form onSubmit={handleSubmit(handleSubmits)}>
        <Field
          name="name"
          id="name"
          label={t('Name address')}
          labelId="name"
          my={2}
          items={[
            {
              value: 'postal',
              name: t('Postal'),
            },
            {
              value: 'legal',
              name: t('Legal'),
            },
            {
              value: 'payment',
              name: t('Pay'),
            },
            {
              value: 'custom',
              name: t('Other'),
            },
          ]}
          fullWidth
          component={Select}
        />
        {nameValue === 'custom' && (
          <Field
            id="customName"
            name="customName"
            label={t('Address')}
            variant="standard"
            margin="normal"
            fullWidth
            my={2}
            component={renderTextField}
          />
        )}
        <Field
          name="recipient"
          id="recipient"
          label={t('Recipient')}
          margin="normal"
          variant="standard"
          my={2}
          fullWidth
          type="text"
          component={renderTextField}
        />
        <Field
          name="country"
          id="country"
          label={t('Country')}
          margin="normal"
          variant="standard"
          my={2}
          fullWidth
          type="text"
          component={AutocompleteField}
          options={countryOptions}
        />
        <Field
          id="city"
          name="city"
          label={t('City')}
          variant="standard"
          margin="normal"
          fullWidth
          my={2}
          component={renderTextField}
        />
        <Field
          id="address"
          name="address"
          label={t('Address')}
          variant="standard"
          margin="normal"
          fullWidth
          my={2}
          component={renderTextField}
        />
        <Field
          id="postalIndex"
          name="postalIndex"
          label={t('Index')}
          variant="standard"
          margin="normal"
          fullWidth
          my={2}
          component={renderTextField}
        />

        <DialogActions
          handleCloseModal={handleCloseModal}
        />
      </Form>
    </Modal>
  );
}

AddClientAddressModal.propTypes = {
  destroy: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  isModalOpenAddress: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  nameValue: PropTypes.string,
  handleSetAddresses: PropTypes.func.isRequired,
  editAddress: PropTypes.oneOfType([
    PropTypes.object,
  ]),
};

AddClientAddressModal.defaultProps = {
  nameValue: '',
  editAddress: {},
};

function mapStateToProps(state) {
  return {
    isModalOpenAddress: selectors.clients.isModalOpenAddress(state),

    nameValue: selectors.form.getFormValues(state, formName).get('name'),
  };
}

export default compose(
  reduxForm({
    form: formName,
  }),
  connect(mapStateToProps, null),
)(AddClientAddressModal);
