export default Object.freeze({
  SET_DOCUMENT_TYPES_DATA: 'SET_DOCUMENT_TYPES_DATA',
  SET_DOCUMENT_TYPES_CURRENT_PAGE: 'SET_DOCUMENT_TYPES_CURRENT_PAGE',
  SET_DOCUMENT_TYPES_PAGE_SIZE: 'SET_DOCUMENT_TYPES_PAGE_SIZE',
  SET_DOCUMENT_TYPES_FILTERS: 'SET_DOCUMENT_TYPES_FILTERS',
  SET_DOCUMENT_TYPES_SORTING: 'SET_DOCUMENT_TYPES_SORTING',
  SET_DOCUMENT_TYPES_INITIAL_STATE: 'SET_DOCUMENT_TYPES_INITIAL_STATE',
  SET_DOCUMENT_TYPES_RELOAD: 'SET_DOCUMENT_TYPES_RELOAD',
  SET_DELETE_DOCUMENT_TYPE_DOCUMENT: 'SET_DELETE_DOCUMENT_TYPE_DOCUMENT',
  SET_DOCUMENT_TYPE_BY_ID: 'SET_DOCUMENT_TYPE_BY_ID',
  SET_DOCUMENT_TYPES_AUTOCOMPLETE_OPTIONS: 'SET_DOCUMENT_TYPES_AUTOCOMPLETE_OPTIONS',
  SET_DOCUMENT_TYPES_COLUMN_WIDTHS: 'SET_DOCUMENT_TYPES_COLUMN_WIDTHS',
  SET_DOCUMENT_TYPES_COLUMN_ORDER: 'SET_DOCUMENT_TYPES_COLUMN_ORDER',
  SET_DOCUMENT_TYPES_HIDDEN_COLUMN_NAMES: 'SET_DOCUMENT_TYPES_HIDDEN_COLUMN_NAMES',
});
