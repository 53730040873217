// Core
import { all, call, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetProposalWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callDeleteProposalWorker,
  callGetAutocompleteOptionsWorker,
  callGetProposalByIdWorker,
  callPutProposalWorker,
  callPostProposalWorker,
  callGetRegistryByNumberWorker,
  callGetNextNumberWorker,
  callPutProposalCloneByIdWorker,
} from './workers';
import types from '../types';

function* watchGetProposal() {
  yield takeEvery(asyncTypes.GET_INVENTIONS_PROPOSAL_ASYNC, callGetProposalWorker);
}
function* watchGetInitialState() {
  yield takeEvery(
    asyncTypes.GET_INVENTIONS_PROPOSAL_INITIAL_STATE_ASYNC,
    callGetInitialStateWorker,
  );
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_INVENTIONS_PROPOSAL_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_INVENTIONS_PROPOSAL_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_INVENTIONS_PROPOSAL_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_INVENTIONS_PROPOSAL_SORTING, callSetToLocalDataWorker);
}
function* watchSetColumnWidths() {
  yield takeEvery(types.SET_INVENTIONS_PROPOSAL_COLUMN_WIDTHS, callSetToLocalDataWorker);
}
function* watchSetColumnOrder() {
  yield takeEvery(types.SET_INVENTIONS_PROPOSAL_COLUMN_ORDER, callSetToLocalDataWorker);
}
function* watchSetHiddenColumnNames() {
  yield takeEvery(
    types.SET_INVENTIONS_PROPOSAL_HIDDEN_COLUMN_NAMES,
    callSetToLocalDataWorker,
  );
}

function* watchDeleteProposal() {
  yield takeEvery(asyncTypes.DELETE_INVENTION_PROPOSAL_ASYNC, callDeleteProposalWorker);
}

function* watchGetAutocompleteOptions() {
  yield takeEvery(
    asyncTypes.GET_INVENTION_AUTOCOMPLETE_OPTIONS_ASYNC,
    callGetAutocompleteOptionsWorker,
  );
}

function* watchGetProposalById() {
  yield takeEvery(
    asyncTypes.GET_INVENTIONS_PROPOSAL_BY_ID_ASYNC,
    callGetProposalByIdWorker,
  );
}
function* watchPutProposalWorker() {
  yield takeEvery(asyncTypes.PUT_INVENTION_PROPOSAL_ASYNC, callPutProposalWorker);
}
function* watchPostProposalWorker() {
  yield takeEvery(asyncTypes.POST_INVENTION_PROPOSAL_ASYNC, callPostProposalWorker);
}
function* watchRegistryByNumberWorker() {
  yield takeEvery(
    asyncTypes.GET_INVENTION_REGISTRY_BY_NUMBER_ASYNC,
    callGetRegistryByNumberWorker,
  );
}
function* watchGetNextNumber() {
  yield takeEvery(
    asyncTypes.GET_INVENTIONS_PROPOSAL_NEXT_NUMBER_ASYNC,
    callGetNextNumberWorker,
  );
}

function* watchPutProposalCloneById() {
  yield takeEvery(
    asyncTypes.PUT_INVENTIONS_PROPOSAL_CLONE_ASYNC,
    callPutProposalCloneByIdWorker,
  );
}
export function* watchersInventionsProposal() {
  yield all([
    call(watchGetProposal),
    call(watchGetProposalById),
    call(watchSetCurrentPage),
    call(watchSetPageSize),
    call(watchSetFilters),
    call(watchSetSorting),
    call(watchSetColumnWidths),
    call(watchSetColumnOrder),
    call(watchSetHiddenColumnNames),
    call(watchGetInitialState),
    call(watchDeleteProposal),
    call(watchGetAutocompleteOptions),
    call(watchPutProposalWorker),
    call(watchPostProposalWorker),
    call(watchRegistryByNumberWorker),
    call(watchGetNextNumber),
    call(watchPutProposalCloneById),
  ]);
}
