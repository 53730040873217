// Helpers
import actionCreator from '../../_helpers/actionCreator';
// Types
import types from './types';

const actions = Object.freeze({
  setPendingGetProposalById(pending) {
    return actionCreator(types.SET_PENDING_GET_SET_INDUSTRIAL_DESIGNS_PROPOSAL_BY_ID, pending);
  },
  setProposalData(searchesData) {
    return actionCreator(types.SET_INDUSTRIAL_DESIGNS_PROPOSAL_DATA, searchesData);
  },
  setCurrentPage(currentPage) {
    return actionCreator(types.SET_INDUSTRIAL_DESIGNS_PROPOSAL_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return actionCreator(types.SET_INDUSTRIAL_DESIGNS_PROPOSAL_FILTERS, filters);
  },
  setSorting(sorting) {
    return actionCreator(types.SET_INDUSTRIAL_DESIGNS_PROPOSAL_SORTING, sorting);
  },
  setColumnWidths(data) {
    return actionCreator(types.SET_INDUSTRIAL_DESIGNS_PROPOSAL_COLUMN_WIDTHS, data);
  },
  setColumnOrder(data) {
    return actionCreator(types.SET_INDUSTRIAL_DESIGNS_PROPOSAL_COLUMN_ORDER, data);
  },
  setHiddenColumnNames(data) {
    return actionCreator(types.SET_INDUSTRIAL_DESIGNS_PROPOSAL_HIDDEN_COLUMN_NAMES, data);
  },
  setPageSize(pageSize) {
    return actionCreator(types.SET_INDUSTRIAL_DESIGNS_PROPOSAL_PAGE_SIZE, pageSize);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_INDUSTRIAL_DESIGNS_PROPOSAL_INITIAL_STATE, initialState);
  },
  setReload(reload) {
    return actionCreator(types.SET_INDUSTRIAL_DESIGNS_PROPOSAL_RELOAD, reload);
  },
  setDeleteProposal(data) {
    return actionCreator(types.SET_DELETE_INDUSTRIAL_DESIGN_PROPOSAL, data);
  },
  setAutocompleteOptions(options) {
    return actionCreator(types.SET_INDUSTRIAL_PROPOSAL_AUTOCOMPLETE_OPTIONS, options);
  },
  setProposalDataById(data) {
    return actionCreator(types.SET_INDUSTRIAL_PROPOSAL_DATA_BY_ID, data);
  },
  setPendingAutocompleteOptions(pending) {
    return actionCreator(types.SET_PENDING_INDUSTRIAL_PROPOSAL_AUTOCOMPLETE_OPTIONS, pending);
  },
  setUploadFiles(payload) {
    return actionCreator(types.SET_INDUSTRIAL_DESIGNS_PROPOSAL_UPLOAD_FILES, payload);
  },
  setAddApplicantsFormShow(isOpen) {
    return actionCreator(types.SET_INDUSTRIAL_DESIGNS_PROPOSAL_ADD_APPLICANTS_FORM_SHOW, isOpen);
  },
  setAddAuthorsFormShow(isOpen) {
    return actionCreator(types.SET_INDUSTRIAL_DESIGNS_AUTHORS_FORM_SHOW, isOpen);
  },
  setAddOwnersFormShow(isOpen) {
    return actionCreator(types.SET_INDUSTRIAL_DESIGNS_OWNERS_FORM_SHOW, isOpen);
  },
  mergeAddAuthorsFormShow(isOpen) {
    return actionCreator(types.MERGE_INDUSTRIAL_DESIGNS_NEW_AUTHORS_LIST, isOpen);
  },
  mergeAddOwnersFormShow(isOpen) {
    return actionCreator(types.MERGE_INDUSTRIAL_DESIGNS_NEW_OWNERS_LIST, isOpen);
  },
  setNewAuthorsListData(data) {
    return actionCreator(types.SET_INDUSTRIAL_DESIGNS_NEW_AUTHORS_LIST, data);
  },
  setNewOwnersListData(data) {
    return actionCreator(types.SET_INDUSTRIAL_DESIGNS_NEW_OWNERS_LIST, data);
  },
  setCompany(classes) {
    return actionCreator(types.SET_INDUSTRIAL_DESIGNS_PROPOSAL_COMPANY, classes);
  },
  setPendingFindCompany(pending) {
    return actionCreator(types.SET_INDUSTRIAL_DESIGNS_PENDING_GET_FIND_COMPANY, pending);
  },
  setSignList(list) {
    return actionCreator(types.SET_INDUSTRIAL_DESIGNS_PROPOSAL_SIGN_LIST, list);
  },
  setSignPending(pending) {
    return actionCreator(types.SET_INDUSTRIAL_DESIGNS_PROPOSAL_SIGN_PENDING, pending);
  },
  setSignIsModalOpen(isOpen) {
    return actionCreator(types.SET_INDUSTRIAL_DESIGNS_PROPOSAL_SIGN_MODAL_IS_OPEN, isOpen);
  },
  setPendingPutProposal(payload) {
    return actionCreator(types.SET_INDUSTRIAL_DESIGNS_PENDING_PUT_PROPOSAL, payload);
  },
  setImage(payload) {
    return actionCreator(types.SET_INDUSTRIAL_DESIGNS_IMAGE, payload);
  },
  setImageVariant(payload) {
    return actionCreator(types.SET_INDUSTRIAL_DESIGNS_IMAGES_VARIANT_LIST, payload);
  },
  setNewImageVariant(payload) {
    return actionCreator(types.SET_INDUSTRIAL_DESIGNS_NEW_IMAGES_VARIANT_LIST, payload);
  },
  setImageMove(payload) {
    return actionCreator(types.SET_INDUSTRIAL_DESIGNS_IMAGES_MOVE, payload);
  },
  setImageVariantRemove(index) {
    return actionCreator(types.SET_INDUSTRIAL_DESIGNS_IMAGES_VARIANT_REMOVE, index);
  },
  setImageRemove(payload) {
    return actionCreator(types.SET_INDUSTRIAL_DESIGNS_IMAGES_REMOVE, payload);
  },
  setImageClear() {
    return actionCreator(types.SET_INDUSTRIAL_DESIGNS_IMAGES_CLEAR);
  },
  setImageName(payload) {
    return actionCreator(types.SET_INDUSTRIAL_DESIGNS_IMAGES_NAME, payload);
  },
  setPendingPostProposal(pending) {
    return actionCreator(types.SET_INDUSTRIAL_DESIGNS_PENDING_POST_PROPOSAL, pending);
  },
  setPendingRegistryByNumber(pending) {
    return actionCreator(types.SET_INDUSTRIAL_DESIGNS_PENDING_GET_REGISTRY_BY_NUMBER, pending);
  },
  setRegistryByNumber(data) {
    return actionCreator(types.SET_INDUSTRIAL_DESIGNS_REGISTRY_BY_NUMBER, data);
  },
  setNextNumber(data) {
    return actionCreator(types.SET_INDUSTRIAL_DESIGNS_NEXT_NUMBER, data);
  },
});

export default actions;
