// Core
import React from 'react';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// parts
import Loader from '../../../../components/Loader';

function NoDataCell(props) {
  const {
    isLoading, message, colSpan,
  } = props;
  const { t } = useTranslation();
  return (
    <VirtualTable.NoDataCell
      colSpan={colSpan}
      getMessage={() => (isLoading ? <Loader /> : (message || t('No data')))}
    />
  );
}

NoDataCell.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  message: PropTypes.string,
  colSpan: PropTypes.number.isRequired,
};

NoDataCell.defaultProps = {
  message: '',
};

export default NoDataCell;
