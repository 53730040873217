// core
import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import {
  Field, Form, reduxForm, change,
} from 'redux-form/lib/immutable';
import { Map } from 'immutable';

// lodash
import {
  isEmpty,
} from 'lodash';

// config
import selectors from '../../../engine/config/selectors';
import accessList from '../../../engine/config/accessList';

// parts
import RenderTextField from '../../../ui/Form/RenderTextField';
import DialogActions from '../../../components/Modal/components/DialogActions';
import Modal from '../../../components/Modal/Modal';

// actions
import asyncAction from '../../../engine/core/products/productCategory/saga/asyncAction';
import actions from '../../../engine/core/products/productCategory/action';

import { useAccessList } from '../../../ui/_hooks/useAccessList';

function AddProductCategoryModal(props) {
  const {
    handleSubmit,
    isModalOpen,
    initialize,
    destroy,
    getProductCategoryById,
    pendingPut,
    pendingPost,
    defaultName,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [readyInitialize, setReadyInitialize] = useState(false);
  const accessPut = useAccessList(accessList.productCategory_put);
  const accessEdit = !isEmpty(getProductCategoryById) ? accessPut : true;

  const handleCloseModal = () => {
    dispatch(actions.setIsModalOpen(false));
  };

  useEffect(() => function cleanup() {
    destroy();
    dispatch(actions.setProductCategoryById({ data: Map() }));
    dispatch(actions.setDefaultName(''));
  }, [destroy, dispatch]);

  const handleSubmits = (formData) => {
    const json = formData.toJS();
    dispatch(asyncAction[!isEmpty(getProductCategoryById.toJS())
      ? 'putProductCategoryByIdAsync'
      : 'postProductCategoryAsync'](json));
  };

  useEffect(() => {
    if (!isEmpty(getProductCategoryById.toJS()) && !readyInitialize) {
      initialize(getProductCategoryById.toJS());
      setReadyInitialize(true);
    }
  }, [initialize, getProductCategoryById, readyInitialize]);

  useEffect(() => {
    if (!isEmpty(defaultName)) {
      dispatch(change('AddProductCategoryModal', 'name', defaultName));
      dispatch(actions.setDefaultName(''));
    }
  }, [
    dispatch, defaultName,
  ]);

  return (
    <Modal
      title={t('Category')}
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
      displayDialogActions={false}
    >
      <Form onSubmit={handleSubmit(handleSubmits)}>
        <Field
          name="name"
          id="name"
          label={t('Name')}
          margin="none"
          variant="standard"
          my={2}
          fullWidth
          type="text"
          component={RenderTextField}
          disabled={!accessEdit}
        />

        <DialogActions
          pending={pendingPut || pendingPost}
          handleCloseModal={handleCloseModal}
          visibleButtonSend={accessEdit}
        />
      </Form>
    </Modal>
  );
}

AddProductCategoryModal.displayName = 'AddProductCategoryModal';

AddProductCategoryModal.propTypes = {
  getProductCategoryById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  pendingPut: PropTypes.bool.isRequired,
  pendingPost: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  destroy: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  defaultName: PropTypes.string.isRequired,
};

AddProductCategoryModal.defaultProps = {};

function mapStateToProps(state) {
  return {
    getProductCategoryById: selectors.productCategory.getProductCategoryById(state),
    isModalOpen: selectors.productCategory.isModalOpen(state),
    pendingPut: selectors.productCategory.pendingPutProductCategory(state),
    pendingPost: selectors.productCategory.pendingPostProductCategory(state),
    defaultName: selectors.productCategory.defaultName(state),
  };
}

export default compose(
  reduxForm({
    form: 'AddProductCategoryModal',
  }),
  connect(mapStateToProps, null),
)(AddProductCategoryModal);
