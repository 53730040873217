// Core
import { all, call, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetEmailMessagesWorker,
  callPostEmailSendWorker,
  callPostEmailPreviewWorker,
  callGetDownloadAttachmentWorker,
  callGetThreadsMessagesWorker,
  callGetCheckNewWorker,

  callSetToLocalDataWorker,
  callGetInitialStateWorker,

  callGetEmailsWorker,
  callPostEmailWorker,
  callPutEmailByIdWorker,
  callGetEmailByIdWorker,
  callDeleteEmailWorker,
  callGetCheckEmailWorker,

  callGetEmailSignaturesWorker,
  callDeleteEmailSignatureWorker,
  callPostEmailSignatureWorker,
  callGetEmailSignatureByIdWorker,
  callPutEmailSignatureByIdWorker,

  callGetEmailLettersWorker,
  callGetEmailLetterByIdWorker,
  callPostEmailLetterWorker,
  callDeleteEmailLetterWorker,
} from './workers';
import types from '../types';

function* watchGetEmail() {
  yield takeEvery(asyncTypes.GET_EMAIL_ASYNC, callGetEmailsWorker);
}
function* watchDeleteEmail() {
  yield takeEvery(asyncTypes.DELETE_EMAIL_ASYNC, callDeleteEmailWorker);
}
function* watchPostEmail() {
  yield takeEvery(asyncTypes.POST_EMAIL_ASYNC, callPostEmailWorker);
}
function* watchGetEmailById() {
  yield takeEvery(asyncTypes.GET_EMAIL_BY_ID_ASYNC, callGetEmailByIdWorker);
}
function* watchPutEmailById() {
  yield takeEvery(asyncTypes.PUT_EMAIL_BY_ID_ASYNC, callPutEmailByIdWorker);
}

function* watchGetEmailMessages() {
  yield takeEvery(asyncTypes.GET_EMAIL_MESSAGES_ASYNC, callGetEmailMessagesWorker);
}
function* watchPostEmailSend() {
  yield takeEvery(asyncTypes.POST_EMAIL_SEND_ASYNC, callPostEmailSendWorker);
}
function* watchPostEmailPreview() {
  yield takeEvery(asyncTypes.POST_EMAIL_PREVIEW_ASYNC, callPostEmailPreviewWorker);
}
function* watchGetDownloadAttachment() {
  yield takeEvery(asyncTypes.GET_EMAIL_DOWNLOAD_ATTACHMENT_ASYNC, callGetDownloadAttachmentWorker);
}
function* watchGetThreadsMessages() {
  yield takeEvery(asyncTypes.GET_EMAIL_THREADS_MESSAGES_ASYNC, callGetThreadsMessagesWorker);
}
function* watchGetCheckNew() {
  yield takeEvery(asyncTypes.GET_EMAIL_CHECK_NEW_ASYNC, callGetCheckNewWorker);
}
function* watchGetCheckEmail() {
  yield takeEvery(asyncTypes.GET_CHECK_EMAIL_ASYNC, callGetCheckEmailWorker);
}

function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_EMAIL_MESSAGES_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_EMAIL_MESSAGES_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_EMAIL_MESSAGES_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_EMAIL_MESSAGES_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_EMAIL_MESSAGES_SORTING, callSetToLocalDataWorker);
}
function* watchSetColumnWidths() {
  yield takeEvery(types.SET_EMAIL_MESSAGES_COLUMN_WIDTHS, callSetToLocalDataWorker);
}
function* watchSetColumnOrder() {
  yield takeEvery(types.SET_EMAIL_MESSAGES_COLUMN_ORDER, callSetToLocalDataWorker);
}
function* watchSetHiddenColumnNames() {
  yield takeEvery(types.SET_EMAIL_MESSAGES_HIDDEN_COLUMN_NAMES, callSetToLocalDataWorker);
}

function* watchGetEmailSignatures() {
  yield takeEvery(asyncTypes.GET_EMAIL_SIGNATURES_ASYNC, callGetEmailSignaturesWorker);
}
function* watchDeleteEmailSignature() {
  yield takeEvery(asyncTypes.DELETE_EMAIL_SIGNATURES_ASYNC, callDeleteEmailSignatureWorker);
}
function* watchPostEmailSignature() {
  yield takeEvery(asyncTypes.POST_EMAIL_SIGNATURES_ASYNC, callPostEmailSignatureWorker);
}
function* watchGetEmailSignatureById() {
  yield takeEvery(asyncTypes.GET_EMAIL_SIGNATURES_BY_ID_ASYNC, callGetEmailSignatureByIdWorker);
}
function* watchPutEmailSignatureById() {
  yield takeEvery(asyncTypes.PUT_EMAIL_SIGNATURES_BY_ID_ASYNC, callPutEmailSignatureByIdWorker);
}

function* watchGetEmailLetters() {
  yield takeEvery(asyncTypes.GET_EMAIL_LETTERS_ASYNC, callGetEmailLettersWorker);
}
function* watchDeleteEmailLetter() {
  yield takeEvery(asyncTypes.DELETE_EMAIL_LETTERS_ASYNC, callDeleteEmailLetterWorker);
}
function* watchPostEmailLetter() {
  yield takeEvery(asyncTypes.POST_EMAIL_LETTERS_ASYNC, callPostEmailLetterWorker);
}
function* watchGetEmailLetterById() {
  yield takeEvery(asyncTypes.GET_EMAIL_LETTERS_BY_ID_ASYNC, callGetEmailLetterByIdWorker);
}

export function* watchersEmailTable() {
  yield all([
    call(watchSetCurrentPage),
    call(watchSetPageSize),
    call(watchSetFilters),
    call(watchGetInitialState),
    call(watchSetSorting),
    call(watchSetColumnWidths),
    call(watchSetColumnOrder),
    call(watchSetHiddenColumnNames),

    call(watchPostEmailSend),
    call(watchPostEmailPreview),
    call(watchGetEmailMessages),
    call(watchGetDownloadAttachment),
    call(watchGetThreadsMessages),
    call(watchGetCheckNew),

    call(watchGetEmail),
    call(watchDeleteEmail),
    call(watchPostEmail),
    call(watchGetEmailById),
    call(watchPutEmailById),
    call(watchGetCheckEmail),

    call(watchGetEmailSignatures),
    call(watchDeleteEmailSignature),
    call(watchPostEmailSignature),
    call(watchGetEmailSignatureById),
    call(watchPutEmailSignatureById),

    call(watchGetEmailLetters),
    call(watchDeleteEmailLetter),
    call(watchPostEmailLetter),
    call(watchGetEmailLetterById),
  ]);
}
