// Core
export const formName = 'Search';

export const formFields = Object.freeze({
  client: 'client',
  choice: 'choice',
  type: 'type',
  classes: 'query.classes',
  keywords: 'query.keywords',
  conclusion: 'conclusion',
  goodsServices: 'query.goodsServices',
  applicationNumber: 'query.applicationNumber',
  applicationDate: 'query.applicationDate',
  registrationNumber: 'query.registrationNumber',
  registrationDate: 'query.registrationDate',
  expiryDate: 'query.expiryDate',
  prolongationDate: 'query.prolongationDate',
  applicationStatus: 'query.applicationStatus',
  registrationStatus: 'query.registrationStatus',
  publicationDate: 'query.publicationDate',
  publicationBulletin: 'query.publicationBulletin',
  applicants: 'query.applicants',
  holders: 'query.holders',
  representative: 'query.representative',
  correspondence: 'query.correspondence',
  initialPublication: 'query.initialPublication',
});

export const formFieldsFilter = Object.freeze({
  title: 'title',
  applicationNumber: 'applicationNumber',
  registrationNumber: 'registrationNumber',
  applicationDate: 'applicationDate',
  registrationDate: 'registrationDate',
  classesFilter: 'classesFilter',
  holder: 'holder',
  active: 'active',
  status: 'status',
});
