// Core
import React, { useState } from 'react';
import Helmet from 'react-helmet';

// ui
import {
  Typography, Divider as MuiDivider, Tabs as MuiTabs, Tab,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';

// parts
import BasicSettings from './BasicSettings';
import RolesSettings from './RolesSettings';
import accessList from '../../engine/config/accessList';
import { useAccessList } from '../../ui/_hooks/useAccessList';

// styles
const Divider = styled(MuiDivider)(spacing);
const Tabs = styled(MuiTabs)(spacing);

function Settings() {
  const { t } = useTranslation();
  const [valueTab, setValueTab] = useState(0);
  const accessPermissions = useAccessList(accessList.permissions_list_get);

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  return (
    <>
      <Helmet title={t('Settings')} />
      <Typography variant="h3" gutterBottom display="inline">
        {t('Settings')}
      </Typography>

      <Divider my={6} />

      <Tabs
        mb={5}
        value={valueTab}
        onChange={handleChangeTab}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label={t('Basic')} />
        {accessPermissions && <Tab label={t('Roles')} />}
      </Tabs>

      {valueTab === 0 && (<BasicSettings />)}
      {valueTab === 1 && (<RolesSettings />)}
    </>
  );
}

Settings.displayName = 'Settings';

export default Settings;
