// Core
import { call, put } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../config/api';

// init
import i18n from '../../../../init/i18n';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../ui/_helpers/setNotificationMessage';

// actions
import actions from '../../action';

export function* callPostImportObjectsWorker({ payload: objects }) {
  const { setPendingImportObjects } = actions;

  yield put(setPendingImportObjects(true));

  const response = yield call(api.importObjects.postImportObjects, objects);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      yield put(setSuccessMessage(i18n.t('Successfully queued for processing')));
    }
  }

  yield put(setPendingImportObjects(false));
}
