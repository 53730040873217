// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const asyncActions = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_SENDING_ASYNC, params);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_SENDING_INITIAL_STATE_ASYNC, path);
  },
  postOutDocsSendAsync(id) {
    return actionCreator(asyncTypes.POST_OUT_DOCS_SEND_ASYNC, id);
  },
  getDownloadOutDocsAsync(path) {
    return actionCreator(asyncTypes.GET_DOWNLOAD_FILE_OUT_DOCS_ASYNC, path);
  },
});

export default asyncActions;
