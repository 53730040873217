// Core
import { apply, put } from 'redux-saga/effects';
import { initialize } from 'redux-form/immutable';

// lodash
import {
  pickBy, find, has, isEmpty,
  values, isArray,
} from 'lodash';

// config
import { api } from '../../../../config/api';
import { formFields } from '../../../../../pages/UtilityModels/helper/form';

// actions
import actionsApplicants from '../../../applicants/action';
import actions from '../../action';
import uploadFileActions from '../../../uploadFile/action';

import { countryOptions } from '../../../../../ui/_helpers/country';
import { setErrorMessage } from '../../../../../ui/_helpers/setNotificationMessage';
import { getObjectReverseFormatDate } from '../../../../_helpers/formatterDate';

export function* callGetRegistryByNumberWorker({ payload: params }) {
  yield put(actions.setPendingRegistryByNumber(true));

  const filterParams = pickBy(params, (value, key) => key !== 'disableInitialize' && key !== 'saveFields');
  const response = yield apply(api, api.utilityModels.getFromRegistry, [filterParams]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data: responseData } = response;
    if (has(responseData, 'error')) {
      yield put(setErrorMessage(responseData.error, response.status.toString()));
    } else {
      const {
        data, title, status, documents, claimNumber, publication,
      } = responseData;
      const {
        postalAddress, additionalApplicationData, inventors,
        applicants, attorney, standardCharacters, holders,
      } = data;
      const formData = {
        ...responseData,
        ...claimNumber ? {
          claimNumber: `${claimNumber}`,
        } : {},
        data: {
          ...data,
          standardCharacters: `${standardCharacters}`,
          ...!isEmpty(postalAddress) ? {
            postalAddress: {
              ...postalAddress,
              country: find(countryOptions, { abbreviation: postalAddress?.country }),
            },
          } : {},
          additionalApplicationData,
          ...!isEmpty(attorney) && isArray(attorney) ? {
            attorney: attorney[0],
          } : {},
        },
        publication: {
          ...publication,
          ...getObjectReverseFormatDate(responseData.publication?.date, 'date'),
        },
        [formFields.title]: title,
        [formFields.status]: status,
        ...getObjectReverseFormatDate(responseData.applicationDate, formFields.applicationDate),
        ...getObjectReverseFormatDate(responseData.expiryDate, formFields.expiryDate),
        ...getObjectReverseFormatDate(responseData.registrationDate, formFields.registrationDate),
        ...getObjectReverseFormatDate(responseData.initialPublication,
          formFields.initialPublication),
        ...!isEmpty(params.saveFields) ? {
          ...params.saveFields,
        } : {},

      };
      delete formData.documents;

      if (!params.disableInitialize) {
        yield put(actions.setProposalDataById(formData));
        yield put(initialize('ProposalAdd', formData, formFields));
        yield put(uploadFileActions.setAllUploadFiles(values(documents)));
        yield put(actionsApplicants.setNewAuthorsListData(inventors));
        if (isArray(holders)) {
          yield put(actionsApplicants.setNewOwnersListData(holders));
        }
        yield put(actionsApplicants.setNewApplicantsListData(applicants));
      } else {
        yield put(actions.setRegistryByNumber(formData));
      }
    }

    yield put(actions.setPendingRegistryByNumber(false));
  }
}
