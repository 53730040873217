// Core
import { List, Map } from 'immutable';

// Instruments
import { isNil } from 'lodash';
import types from './types';
import { pageSizes } from '../../../config/globalConfig';
import globalStateKeys from '../../../config/globalStateKeys';

export const initialState = Map({
  itemByHashId: {
    data: {},
    pending: false,
    pendingPut: false,
    pendingPost: false,
  },
  data: Map({
    items: List(),
    currentPage: 0,
    filters: List(),
    pending: false,
    sorting: List([{ columnName: 'createdAt', direction: 'desc' }]),
    totalCount: 0,
    pageSize: pageSizes[0],
    reload: false,
    columnWidths: List(),
    columnOrder: List(),
    hiddenColumnNames: List(),
  }),
  documentsOrder: List(),
  autocompleteOptions: Map({
    items: Map(),
    pending: false,
  }),
  delete: Map({
    pendingDelete: false,
    id: '',
  }),
  getNextNumber: '',
  entityList: Map(),
});

export const stateKeys = Object.freeze({
  controller: 'orders',
  documentsOrder: 'documentsOrder',
  getNextNumber: 'getNextNumber',
});

export const ordersReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_ORDERS_DATA: {
      const { items, totalCount, pending } = payload;
      if (pending) {
        return state.setIn([globalStateKeys.data, globalStateKeys.pending], pending);
      }
      return state
        .setIn([globalStateKeys.data, globalStateKeys.pending], pending)
        .setIn([globalStateKeys.data, globalStateKeys.items], List(items))
        .setIn([globalStateKeys.data, globalStateKeys.totalCount], totalCount);
    }
    case types.SET_ORDERS_ALL_DATA: {
      return state.mergeIn([
        globalStateKeys.data,
      ], Map(payload));
    }
    case types.SET_ORDER_BY_ID: {
      return state.mergeIn([globalStateKeys.itemByHashId], payload);
    }
    case types.SET_ORDERS_RELOAD: {
      return state.setIn([globalStateKeys.data, globalStateKeys.reload], payload);
    }
    case types.SET_ORDERS_CURRENT_PAGE: {
      return state.setIn([globalStateKeys.data, globalStateKeys.currentPage], payload);
    }
    case types.SET_ORDERS_FILTERS: {
      const filters = payload.filter((item) => !isNil(item.value));
      return state.setIn([globalStateKeys.data, globalStateKeys.filters], List(filters));
    }
    case types.SET_ORDERS_SORTING: {
      return state.setIn([globalStateKeys.data, globalStateKeys.sorting], List(payload));
    }
    case types.SET_ORDERS_COLUMN_WIDTHS: {
      return state.setIn([globalStateKeys.data, globalStateKeys.columnWidths], List(payload));
    }
    case types.SET_ORDERS_COLUMN_ORDER: {
      return state.setIn([globalStateKeys.data, globalStateKeys.columnOrder], List(payload));
    }
    case types.SET_ORDERS_HIDDEN_COLUMN_NAMES: {
      return state.setIn([globalStateKeys.data, globalStateKeys.hiddenColumnNames], List(payload));
    }
    case types.SET_DOCUMENTS_ORDER: {
      return state.setIn([stateKeys.documentsOrder], List(payload));
    }
    case types.SET_ORDERS_PAGE_SIZE: {
      return state.setIn([globalStateKeys.data, globalStateKeys.pageSize], payload);
    }
    case types.SET_ORDERS_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, sorting = [],
        columnWidths, columnOrder, hiddenColumnNames,
      } = payload;
      const reload = true;
      const currentSorting = sorting.length
        ? sorting
        : initialState.getIn([globalStateKeys.data, globalStateKeys.sorting]);
      return state
        .setIn([globalStateKeys.data, globalStateKeys.currentPage], currentPage)
        .setIn([globalStateKeys.data, globalStateKeys.filters], List(filters))
        .setIn([globalStateKeys.data, globalStateKeys.pageSize], pageSize)
        .setIn([globalStateKeys.data, globalStateKeys.totalCount], totalCount)
        .setIn([globalStateKeys.data, globalStateKeys.reload], reload)
        .setIn([globalStateKeys.data, globalStateKeys.columnWidths], List(columnWidths))
        .setIn([globalStateKeys.data, globalStateKeys.columnOrder], List(columnOrder))
        .setIn([globalStateKeys.data, globalStateKeys.hiddenColumnNames], List(hiddenColumnNames))
        .setIn([globalStateKeys.data, globalStateKeys.sorting], List(currentSorting));
    }
    case types.SET_DELETE_ORDER: {
      return state.mergeIn([globalStateKeys.delete], payload);
    }
    case types.SET_ORDERS_AUTOCOMPLETE_OPTIONS: {
      return state.mergeIn([
        globalStateKeys.autocompleteOptions,
        globalStateKeys.items,
      ], Map(payload));
    }
    case types.SET_PENDING_ORDERS_AUTOCOMPLETE_OPTIONS: {
      return state.setIn([
        globalStateKeys.autocompleteOptions,
        globalStateKeys.pending,
      ], payload);
    }
    case types.SET_ORDER_NEXT_NUMBER: {
      return state.setIn([stateKeys.getNextNumber], payload);
    }
    case types.MERGE_ORDER_ENTITY_LIST: {
      const { entityName, items } = payload;
      return state.mergeIn([globalStateKeys.entityList], Map({ [entityName]: Map(items) }));
    }
    case types.SET_ORDER_ENTITY_LIST: {
      return state.setIn([globalStateKeys.entityList], Map(payload));
    }
    default:
      return state;
  }
};
