// Core
import { call, put, select } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../config/api';
import selectors from '../../../../config/selectors';

// init
import i18n from '../../../../init/i18n';

// actions
import actions from '../../action';
import helpersActions from '../../../helpers/action';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../ui/_helpers/setNotificationMessage';

export function* callDeleteReceivingWorker() {
  const { setDeleteReceiving, setReload } = actions;
  const { setModal } = helpersActions;

  yield put(setDeleteReceiving({ pendingDelete: true }));

  const IdDelete = yield select(selectors.receiving.deleteID);

  const response = yield call(api.receiving.deleteReceiving, IdDelete);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      yield put(setModal({ isModalOpen: false }));
      yield put(setDeleteReceiving({ id: '' }));
      yield put(setReload(true));
      yield put(setSuccessMessage(i18n.t('Document deleted!'), response.status.toString()));
    }
  }

  yield put(setDeleteReceiving({
    pendingDelete: false,
  }));
}
