// Core
import { call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';

// lodash
import has from 'lodash/has';
import pickBy from 'lodash/pickBy';

// config
import { api } from '../../../../../config/api';
import { messageRequestSuccess } from '../../../../../config/globalConfig';

// init
import i18n from '../../../../../init/i18n';

// actions
import ordersActions from '../../action';
import helpersActions from '../../../../helpers/action';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../../ui/_helpers/setNotificationMessage';

// routes
import { pageLinks } from '../../../../../../routes';

export function* callPostOrderWorker({ payload: order }) {
  const { setOrdersAllData, setReload } = ordersActions;

  yield put(setOrdersAllData({
    pendingPost: true,
  }));

  const filterOrderData = pickBy(order, (value, key) => key !== 'stay'
    && key !== 'reload' && key !== 'params');
  const response = yield call(api.company.postOrder, filterOrderData);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      yield put(setSuccessMessage(i18n.t(messageRequestSuccess), response.status.toString()));
      if (!order.stay) {
        yield put(push(pageLinks.company.orders.edit(data.id)));
      }
      if (order.params) {
        yield put(helpersActions.setLastObject(order.params));
      }
      if (order.reload) {
        yield put(setReload(true));
      }
    }
  }

  yield put(setOrdersAllData({
    pendingPost: false,
  }));
}
