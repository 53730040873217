// Core
/* eslint-disable */
import React from 'react';
import { PagingState } from '@devexpress/dx-react-grid';
import * as PropTypes from 'prop-types';

// lodash
import isEqual from 'lodash/isEqual';

function DxPagingState(props) {
  const {
    currentPage, pageSize, onPageSize, onCurrentPage,
  } = props;

  function setPageSize(size) {
    onPageSize(size);
    onCurrentPage(0);
  }

  return (
    <PagingState
      currentPage={currentPage}
      onCurrentPageChange={onCurrentPage}
      pageSize={pageSize}
      onPageSizeChange={setPageSize}
    />
  );
}

DxPagingState.propTypes = {
  onPageSize: PropTypes.func,
  onCurrentPage: PropTypes.func,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
};

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.currentPage, nextProps.currentPage)
    && isEqual(prevProps.pageSize, nextProps.pageSize)
    && isEqual(prevProps.totalCount, nextProps.totalCount);
}

export default React.memo(DxPagingState, areEqual);
