// Core
import { apply, put } from 'redux-saga/effects';

// lodash
import {
  pickBy,
} from 'lodash';

// config
import { api } from '../../../../../config/api';
import { listEntityName } from '../../../../../config/listTablesName';

// actions
import actions from '../../action';

const entity = listEntityName.classes;

export function* callGetClassByIdWorker({ payload: params }) {
  yield put(actions.setPendingClassById(true));

  const filterParams = pickBy(params, (value, key) => key !== 'multiClass');

  const response = yield apply(api, api.trademarks.getClassById, [{ ...filterParams, entity }]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (params.multiClass) {
      yield put(actions.setClassesById({ [params.id]: data.items }));
    } else {
      yield put(actions.setClassById(data.items));
      yield put(actions.setTermsMktuClasses({
        [params.id]: Object.values(data.items).join('; '),
      }));
    }
  }

  yield put(actions.setPendingClassById(false));
}
