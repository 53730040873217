export const delayCallTimeout = 750;

/**
 * Use to default avatar
 * @constant {String}
 * */
export const defaultAvatar = '/img/avatars/avatar-1.jpg';

/**
 * Use to statuses Chip where absent status
 * @constant {String}
 * */
export const borderColorNotStatusChip = 'rgb(224, 224, 224)';

/**
 * Use to fields search global by api POST /api/v1/search
 * @constant {String}
 * */
export const markFieldSearchGroup = 'GroupSearch';

/**
 * Use to set message about request success
 * @constant {String}
 * */
export const messageDataUpdated = 'Data updated!';
export const messageRequestSent = 'Data sent!';
export const messageRequestSuccess = 'Successfully saved!';
export const messageRequestDeleted = 'Data deleted!';
export const messageFileUploaded = 'File uploaded!';
export const messageSaved = 'Saved!';

/**
 * Use for displaying page sizes in the tables
 * @constant {Number[]}
 * */
export const pageSizes = [20, 50, 100];
