// Core
export const formName = 'Rebound';

export const formFields = Object.freeze({
  title: 'title',
  choice: 'choice',
  classes: 'classes',
  mktuVersion: 'mktuVersion',
});

export const fieldsAutocomplete = Object.freeze({
  trademarks: 'trademarks',
  client: 'client',
  clients: 'clients',
});
