export default Object.freeze({
  SET_DEPARTMENT_DOCUMENTS_DATA: 'SET_DEPARTMENT_DOCUMENTS_DATA',
  SET_DEPARTMENT_DOCUMENTS_CURRENT_PAGE: 'SET_DEPARTMENT_DOCUMENTS_CURRENT_PAGE',
  SET_DEPARTMENT_DOCUMENTS_PAGE_SIZE: 'SET_DEPARTMENT_DOCUMENTS_PAGE_SIZE',
  SET_DEPARTMENT_DOCUMENTS_FILTERS: 'SET_DEPARTMENT_DOCUMENTS_FILTERS',
  SET_DEPARTMENT_DOCUMENTS_SORTING: 'SET_DEPARTMENT_DOCUMENTS_SORTING',
  SET_DEPARTMENT_DOCUMENTS_INITIAL_STATE: 'SET_DEPARTMENT_DOCUMENTS_INITIAL_STATE',
  SET_DEPARTMENT_DOCUMENTS_RELOAD: 'SET_DEPARTMENT_DOCUMENTS_RELOAD',
  SET_DELETE_DEPARTMENT_DOCUMENT: 'SET_DELETE_DEPARTMENT_DOCUMENT',
  SET_DEPARTMENT_DOCUMENT_BY_ID: 'SET_DEPARTMENT_DOCUMENT_BY_ID',
  SET_DEPARTMENT_DOCUMENT_SELECTIONS_MODAL: 'SET_DEPARTMENT_DOCUMENT_SELECTIONS_MODAL',
  SET_DEPARTMENT_DOCUMENTS_AUTOCOMPLETE_OPTIONS: 'SET_DEPARTMENT_DOCUMENTS_AUTOCOMPLETE_OPTIONS',
  SET_PENDING_DEPARTMENT_DOCUMENTS_AUTOCOMPLETE_OPTIONS: 'SET_PENDING_DEPARTMENT_DOCUMENTS_AUTOCOMPLETE_OPTIONS',
  SET_DEPARTMENT_DOCUMENTS_NEXT_NUMBER: 'SET_DEPARTMENT_DOCUMENTS_NEXT_NUMBER',
  MERGE_DEPARTMENT_DOCUMENTS_ENTITY_LIST: 'MERGE_DEPARTMENT_DOCUMENTS_ENTITY_LIST',
  SET_DEPARTMENT_DOCUMENTS_ENTITY_LIST: 'SET_DEPARTMENT_DOCUMENTS_ENTITY_LIST',
  SET_DEPARTMENT_DOCUMENTS_ADDITIONAL_DOCUMENTS: 'SET_DEPARTMENT_DOCUMENTS_ADDITIONAL_DOCUMENTS',
  SET_DEPARTMENT_DOCUMENTS_SELECTED_DOCUMENTS: 'SET_DEPARTMENT_DOCUMENTS_SELECTED_DOCUMENTS',
  SET_DEPARTMENT_DOCUMENTS_COLUMN_WIDTHS: 'SET_DEPARTMENT_DOCUMENTS_COLUMN_WIDTHS',
  SET_DEPARTMENT_DOCUMENTS_COLUMN_ORDER: 'SET_DEPARTMENT_DOCUMENTS_COLUMN_ORDER',
  SET_DEPARTMENT_DOCUMENTS_HIDDEN_COLUMN_NAMES: 'SET_DEPARTMENT_DOCUMENTS_HIDDEN_COLUMN_NAMES',
  SET_DEPARTMENT_DOCUMENTS_FILE_SIGN: 'SET_DEPARTMENT_DOCUMENTS_FILE_SIGN',
  SET_DEPARTMENT_DOCUMENTS_ID_SEND: 'SET_DEPARTMENT_DOCUMENTS_ID_SEND',
});
