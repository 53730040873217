// Core
import { call, put } from 'redux-saga/effects';
import { List } from 'immutable';

// lodash
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import filter from 'lodash/filter';

// helpers
import { splitArrayToChunks } from '../../../../../_helpers/splitArrays';

// config
import { api } from '../../../../../config/api';

// actions
import actions from '../../action';

export function* callGetMonitoringByIdWorker({ payload: id }) {
  const {
    setMonitoringById,
    setTabs,
  } = actions;
  yield put(setMonitoringById({
    pending: true,
  }));

  const response = yield call(api.trademarks.getMonitoringById, id);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    const typeNational = 'national';
    const typeMadrid = 'madrid';
    const resultsNational = filter(data.results, { type: typeNational });
    const resultsMadrid = filter(data.results, { type: typeMadrid });
    const groupNational = !isEmpty(resultsNational) ? splitArrayToChunks(resultsNational, 20) : [];
    const groupMadrid = !isEmpty(resultsNational) ? splitArrayToChunks(resultsMadrid, 20) : [];

    yield put(setMonitoringById({
      data,
    }));

    yield put(setTabs(typeNational, {
      filtered: List(resultsNational),
      items: List(groupNational),
      totalCount: !isEmpty(resultsNational) ? size(resultsNational) : 0,
    }));

    yield put(setTabs(typeMadrid, {
      filtered: List(resultsMadrid),
      items: List(groupMadrid),
      totalCount: !isEmpty(resultsMadrid) ? size(resultsMadrid) : 0,
    }));
  }

  yield put(setMonitoringById({
    pending: false,
  }));
}
