/* eslint-disable */
// Core
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

// Lodash
import {
  includes, isEmpty, isEqual, last, without, find,
  map, get, keys,
} from 'lodash';

// UI
import {
  TextField, TableCell as MuiTableCell, Select as MuiSelect,
  MenuItem,
} from '@material-ui/core';

// parts
import RenderDateRange from '../../../DateRange/RenderDateRange';
import AutocompleteField from '../../../Form/AutocompleteFieldFilter';
import AutocompleteJurisdiction from '../../../Form/AutocompleteJurisdiction';
import AutocompleteChips from '../../../Form/AutocompleteChips';

// config
import globalObjectTypes from '../../../../engine/config/globalObjectTypes';
import globalDocumentType from '../../../../engine/config/globalDocumentType';
import globalExecutors from '../../../../engine/config/globalExecutors';
import selectors from '../../../../engine/config/selectors';
import { useDefferCall } from '../../../_hooks/useDefferCall';

// action
import employeesAsyncActions from '../../../../engine/core/company/employees/saga/asyncAction';
import clientsActionAsync from '../../../../engine/core/company/clients/saga/asyncAction';
import documentTypesTableActionsAsync from '../../../../engine/core/documentTypes/saga/asyncAction';
import tradeMarksActionAsync from '../../../../engine/core/tradeMarks/proposal/saga/asyncAction';
import industrialActionAsync from '../../../../engine/core/industrialDesigns/saga/asyncAction';
import inventionsActionAsync from '../../../../engine/core/inventions/saga/asyncAction';
import utilityActionAsync from '../../../../engine/core/utilityModels/saga/asyncAction';
import jurisdictionsActionAsync from '../../../../engine/core/jurisdictions/saga/asyncAction';

// hooks
import { useEventsAutocompleteAsync } from '../../../_hooks/useEventsAutocompleteAsync';
import globalStatusesAction from '../../../../engine/config/globalStatusesAction';
import accessList from '../../../../engine/config/accessList';
import { listEntityName } from '../../../../engine/config/listTablesName';
import globalPendingActionType from '../../../../engine/config/globalPendingActionType';

// styles
const TableCell = styled(MuiTableCell)`
   width: 100%;
   padding: 0px 4px`;
const Select = styled(MuiSelect)`
   width: 100%`;

const FilterCell = (props) => {
  const { filter, column, filteringEnabled } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userInfo = useSelector(selectors.user.userInfo);
  const categoriesData = useSelector(selectors.pricesCategory.categoriesData);
  const objectStatuses = useSelector(selectors.helpers.getObjectStatuses);
  const documentTypesCategoryData = useSelector(
    selectors.documentTypesCategory.documentTypesCategoryData,
  );
  const docTypCategoryAutocomplete = useSelector(
    selectors.documentTypesCategory.autocompleteOptions,
  );
  const documentTypesData = useSelector(selectors.documentTypes.documentTypesData);
  const getDocumentTypeById = useSelector(selectors.documentTypes.getDocumentTypeById);
  const documentTypesAutocomplete = useSelector(selectors.documentTypes.autocompleteOptions);
  const employeesData = useSelector(selectors.employeesTable.employeesData);
  const employeesAutocomplete = useSelector(selectors.employees.autocompleteOptions);
  const employeesById = useSelector(selectors.employees.getEmployeeById);
  const tradeMarksData = useSelector(selectors.tradeMarksProposal.proposalData);
  const tradeMarksAutocompleteOptions = useSelector(
    selectors.tradeMarksProposal.autocompleteOptions,
  );
  const getTradeMarksById = useSelector(selectors.tradeMarksProposal.getProposalById);
  const industrialData = useSelector(selectors.industrialDesignsProposal.proposalData);
  const industrialAutocompleteOptions = useSelector(
    selectors.industrialDesignsProposal.autocompleteOptions,
  );
  const getIndustrialById = useSelector(selectors.industrialDesignsProposal.getProposalById);
  const inventionsData = useSelector(selectors.inventionsProposal.proposalData);
  const inventionsAutocompleteOptions = useSelector(
    selectors.inventionsProposal.autocompleteOptions,
  );
  const getInventionsById = useSelector(selectors.inventionsProposal.getProposalById);
  const utilityData = useSelector(selectors.utilityModelsProposal.proposalData);
  const utilityAutocompleteOptions = useSelector(
    selectors.utilityModelsProposal.autocompleteOptions,
  );
  const getUtilityById = useSelector(selectors.utilityModelsProposal.getProposalById);
  const clientsData = useSelector(selectors.clientsTable.clientsData);
  const clientsAutocompleteOptions = useSelector(selectors.clients.autocompleteOptions);
  const getClientById = useSelector(selectors.clients.getClientById);
  const jurisdictionsData = useSelector(selectors.jurisdiction.jurisdictionsData);
  const getJurisdictionById = useSelector(selectors.jurisdiction.getJurisdictionById);
  const tagsData = useSelector(selectors.tags.tagsData);
  const actionTypesData = useSelector(selectors.actionTypes.actionTypesData);

  const autocompleteFilter = useSelector(selectors.helpers.autocompleteFilter);
  const {
    handleGetAsyncData, getAutocompleteLists,
  } = useEventsAutocompleteAsync();
  const onSelectFilterChange = (filterValue) => {
    const { onFilter } = props;
    onFilter({ columnName: column.name, value: filterValue, type: 'like' });
  };
  const onSelectDateFilterChange = (filterValue) => {
    const { onFilter } = props;
    onFilter({ columnName: column.name, value: filterValue });
  };
  const getValue = (defaultValue = undefined) => get(filter, 'value', defaultValue);

  switch (column.name) {
    case 'status': {
      const { onFilter, colSpan } = props;
      const handleFilter = (e) => {
        const arrayStatus = last(e.target.value) ? without(e.target.value, '') : [];
        onFilter(!isEmpty(arrayStatus) ? { value: arrayStatus } : null);
      };
      return (
        <TableCell colSpan={colSpan}>
          {column.customField === 'statusAction' ? (
            <Select
              disabled={!filteringEnabled}
              value={filter ? filter.value : ''}
              displayEmpty
              onChange={(e) => onFilter(e.target.value ? { value: e.target.value } : null)}
            >
              <MenuItem value="">{t('All')}</MenuItem>
              {map(globalStatusesAction, (option, index) => (
                <MenuItem key={index} value={option.value}>{option.name}</MenuItem>
              ))}
            </Select>
          ) : (
            <Select
              disabled={!filteringEnabled}
              value={!isEmpty(filter) ? filter.value : ['']}
              multiple
              onChange={handleFilter}
              displayEmpty
            >
              <MenuItem value="">{t('All')}</MenuItem>
              {map(objectStatuses.toJS(), (option, index) => (
                <MenuItem key={index} value={option.value}>{t(option.name)}</MenuItem>
              ))}
            </Select>
          )}
        </TableCell>
      );
    }

    case 'trademarks': {
      if (column.customField === 'Autocomplete' || column.customField === 'documents') {
        const { onFilter, colSpan } = props;
        const [inputValue, setInputValue] = useState('');
        const [getTrademarks, setGetTrademarks] = useState(false);
        useEffect(() => {
          if (!isEmpty(filter) && !getTrademarks) {
            setGetTrademarks(true);
            dispatch(tradeMarksActionAsync.getProposalByIdAsync(filter.value));
          }
        }, [filter, getTrademarks, setGetTrademarks, dispatch]);
        const autocompleteList = autocompleteFilter.get(column.name) && autocompleteFilter.get(column.name).toJS
          ? autocompleteFilter.get(column.name).toJS() : [];

        return (
          <TableCell colSpan={colSpan}>
            <AutocompleteField
              disabled={!filteringEnabled}
              fullWidth
              input={{
                name: 'trademarks',
                onChange: () => {},
              }}
              {...!filter ? {
                fieldValue: null,
              } : {}}
              disableLabel
              defaultValue={getTradeMarksById.toJS()}
              label={t('Filter')}
              options={isEmpty(inputValue)
                ? tradeMarksData.toJS().items
                : autocompleteList}
              handleOnFocus={() => isEmpty(tradeMarksData.toJS().items) && getAutocompleteLists(['trademarks'])}
              getAsyncData={(val, name) => handleGetAsyncData(val, 'tmFilterSearch')}
              handleChange={(e, newValue) => onFilter(newValue ? { value: newValue.id } : null)}
              handleInputChange={(value) => setInputValue(value)}
            />
          </TableCell>
        );
      }
      const { colSpan } = props;
      const { value, setValue } = useDefferCall(onSelectFilterChange, getValue(''));
      const onChange = (e) => {
        setValue(e.target.value);
      };
      return (
        <TableCell colSpan={colSpan}>
          {filteringEnabled && (
            <TextField
              fullWidth
              placeholder={t('Filter')}
              onChange={onChange}
              value={value}
            />
          )}
        </TableCell>
      );
    }

    case 'industrial': {
      if (column.customField === 'documents') {
        const { onFilter, colSpan } = props;
        const [inputValue, setInputValue] = useState('');
        const [getIndustrial, setGetIndustrial] = useState(false);
        useEffect(() => {
          if (!isEmpty(filter) && !getIndustrial) {
            setGetIndustrial(true);
            dispatch(industrialActionAsync.getProposalByIdAsync(filter.value));
          }
        }, [filter, getIndustrial, setGetIndustrial, dispatch]);

        const autocompleteList = autocompleteFilter.get(column.name) && autocompleteFilter.get(column.name).toJS
          ? autocompleteFilter.get(column.name).toJS() : [];
        return (
          <TableCell colSpan={colSpan}>
            <AutocompleteField
              disabled={!filteringEnabled}
              fullWidth
              input={{
                name: 'industrial',
                onChange: () => {},
              }}
              {...!filter ? {
                fieldValue: null,
              } : {}}
              disableLabel
              defaultValue={getIndustrialById.toJS()}
              label={t('Filter')}
              options={isEmpty(inputValue)
                ? industrialData.toJS().items
                : autocompleteList}
              handleOnFocus={() => isEmpty(industrialData.toJS().items) && getAutocompleteLists(['industrial'])}
              getAsyncData={(val, name) => handleGetAsyncData(val, 'industrialFilterSearch')}
              handleChange={(e, newValue) => onFilter(newValue ? { value: newValue.id } : null)}
              handleInputChange={(value) => setInputValue(value)}
            />
          </TableCell>
        );
      }
      const { colSpan } = props;
      const { value, setValue } = useDefferCall(onSelectFilterChange, getValue(''));
      const onChange = (e) => {
        setValue(e.target.value);
      };
      return (
        <TableCell colSpan={colSpan}>
          {filteringEnabled && (
            <TextField
              fullWidth
              placeholder={t('Filter')}
              onChange={onChange}
              value={value}
            />
          )}
        </TableCell>
      );
    }

    case 'inventions': {
      if (column.customField === 'documents') {
        const { onFilter, colSpan } = props;
        const [inputValue, setInputValue] = useState('');
        const [getInventions, setGetInventions] = useState(false);
        useEffect(() => {
          if (!isEmpty(filter) && !getInventions) {
            setGetInventions(true);
            dispatch(inventionsActionAsync.getProposalByIdAsync(filter.value));
          }
        }, [filter, getInventions, setGetInventions, dispatch]);
        const autocompleteList = autocompleteFilter.get(column.name) && autocompleteFilter.get(column.name).toJS
          ? autocompleteFilter.get(column.name).toJS() : [];

        return (
          <TableCell colSpan={colSpan}>
            <AutocompleteField
              disabled={!filteringEnabled}
              fullWidth
              input={{
                name: 'inventions',
                onChange: () => {},
              }}
              {...!filter ? {
                fieldValue: null,
              } : {}}
              disableLabel
              defaultValue={getInventionsById.toJS()}
              label={t('Filter')}
              options={isEmpty(inputValue)
                ? inventionsData.toJS().items
                : autocompleteList}
              handleOnFocus={() => isEmpty(inventionsData.toJS().items) && getAutocompleteLists(['inventions'])}
              getAsyncData={(val, name) => handleGetAsyncData(val, 'inventionsFilterSearch')}
              handleChange={(e, newValue) => onFilter(newValue ? { value: newValue.id } : null)}
              handleInputChange={(value) => setInputValue(value)}
            />
          </TableCell>
        );
      }
      const { colSpan } = props;
      const { value, setValue } = useDefferCall(onSelectFilterChange, getValue(''));
      const onChange = (e) => {
        setValue(e.target.value);
      };
      return (
        <TableCell colSpan={colSpan}>
          {filteringEnabled && (
            <TextField
              fullWidth
              placeholder={t('Filter')}
              onChange={onChange}
              value={value}
            />
          )}
        </TableCell>
      );
    }

    case 'utility': {
      if (column.customField === 'documents') {
        const { onFilter, colSpan } = props;
        const [inputValue, setInputValue] = useState('');
        const [getUtility, setGetUtility] = useState(false);
        useEffect(() => {
          if (!isEmpty(filter) && !getUtility) {
            setGetUtility(true);
            dispatch(utilityActionAsync.getProposalByIdAsync(filter.value));
          }
        }, [filter, getUtility, setGetUtility, dispatch]);
        const autocompleteList = autocompleteFilter.get(column.name) && autocompleteFilter.get(column.name).toJS
          ? autocompleteFilter.get(column.name).toJS() : [];

        return (
          <TableCell colSpan={colSpan}>
            <AutocompleteField
              disabled={!filteringEnabled}
              fullWidth
              input={{
                name: 'utility',
                onChange: () => {},
              }}
              {...!filter ? {
                fieldValue: null,
              } : {}}
              disableLabel
              defaultValue={getUtilityById.toJS()}
              label={t('Filter')}
              options={isEmpty(inputValue)
                ? utilityData.toJS().items
                : autocompleteList}
              handleOnFocus={() => isEmpty(utilityData.toJS().items) && getAutocompleteLists(['utility'])}
              getAsyncData={(val, name) => handleGetAsyncData(val, 'utilityFilterSearch')}
              handleChange={(e, newValue) => onFilter(newValue ? { value: newValue.id } : null)}
              handleInputChange={(value) => setInputValue(value)}
            />
          </TableCell>
        );
      }
      const { colSpan } = props;
      const { value, setValue } = useDefferCall(onSelectFilterChange, getValue(''));
      const onChange = (e) => {
        setValue(e.target.value);
      };
      return (
        <TableCell colSpan={colSpan}>
          {filteringEnabled && (
            <TextField
              fullWidth
              placeholder={t('Filter')}
              onChange={onChange}
              value={value}
            />
          )}
        </TableCell>
      );
    }
    case 'jurisdictions': {
      const { onFilter, colSpan } = props;
      const [getInventions, setGetInventions] = useState(false);
      useEffect(() => {
        if (!isEmpty(filter) && !getInventions) {
          setGetInventions(true);
          dispatch(jurisdictionsActionAsync.getJurisdictionByIdAsync(filter.value));
        }
      }, [filter, getInventions, setGetInventions, dispatch]);

      return (
        <TableCell colSpan={colSpan}>
          <AutocompleteJurisdiction
            disabled={!filteringEnabled}
            fullWidth
            input={{
              name: 'jurisdictions',
              onChange: () => {},
            }}
            {...!filter ? {
              fieldValue: null,
            } : {}}
            disableLabel
            defaultValue={getJurisdictionById.toJS()}
            label={t('Filter')}
            options={jurisdictionsData.toJS().items}
            handleOnFocus={() => isEmpty(jurisdictionsData.toJS().items) && (
              setGetInventions(true),
              getAutocompleteLists(['jurisdictions'])
            )}
            handleChange={(e, newValue) => onFilter(newValue ? { value: newValue.id } : null)}
          />
        </TableCell>
      );
    }
    case 'tags': {
      const { onFilter, colSpan } = props;
      const [getInventions, setGetInventions] = useState(false);
      const [defaultValue, setDefaultValue] = useState([]);
      useEffect(() => {
        if (!isEmpty(filter) && !getInventions && !isEmpty(tagsData.toJS().items)) {
          setGetInventions(true);
          setDefaultValue(tagsData.toJS().items.filter((item) => includes(filter.value, item.id)));
        }
      }, [filter, getInventions, setGetInventions, tagsData]);
      const handleChange = (e, newValue) => {
        onFilter(!isEmpty(newValue) ? { value: map(newValue, 'id'), type: 'in' } : null);
      };

      return (
        <TableCell colSpan={colSpan}>
          <AutocompleteChips
            disabled={!filteringEnabled}
            multiple
            fullWidth
            input={{
              name: 'tags',
              onChange: () => {},
            }}
            {...!filter ? {
              fieldValue: [],
            } : {}}
            disableLabel
            defaultValue={defaultValue}
            label={t('Tags')}
            options={tagsData.toJS().items}
            handleOnFocus={() => isEmpty(tagsData.toJS().items) && (
              setGetInventions(true),
              getAutocompleteLists(['tags'])
            )}
            handleChange={handleChange}
            disableEditing
            limitTags={1}
          />
        </TableCell>
      );
    }

    case 'clientsApplicant':
    case 'client':
    case 'clients': {
      const { onFilter, colSpan } = props;
      // const defaultValue = (!isEmpty(filter) && !isEmpty(clientsData.toJS().items))
      //   ? find(clientsData.toJS().items, { id === filter.value })
      //   : null;
      const [inputValue, setInputValue] = useState('');
      const [getClients, setGetClients] = useState(false);
      useEffect(() => {
        if (!isEmpty(filter) && !getClients) {
          setGetClients(true);
          dispatch(clientsActionAsync.getClientByIdAsync(filter.value));
        }
      }, [filter, getClients, setGetClients, dispatch]);

      return (
        <TableCell colSpan={colSpan}>
          <AutocompleteField
            disabled={!filteringEnabled}
            fullWidth
            input={{
              name: 'clients',
              onChange: () => {},
            }}
            {...!filter ? {
              fieldValue: null,
            } : {}}
            disableLabel
            defaultValue={getClientById.toJS()}
            label={t('Filter')}
            options={isEmpty(inputValue)
              ? clientsData.toJS().items
              : clientsAutocompleteOptions.get('name')}
            handleOnFocus={() => isEmpty(clientsData.toJS().items) && getAutocompleteLists(['clients'])}
            getAsyncData={handleGetAsyncData}
            handleChange={(e, newValue) => onFilter(newValue ? { value: newValue.id } : null)}
            handleInputChange={(value) => setInputValue(value)}
          />
        </TableCell>
      );
    }

    case 'documentClassifier': {
      const { onFilter, colSpan } = props;
      const documentTypesItems = documentTypesData.toJS().items;
      const [inputValue, setInputValue] = useState('');
      const [getDocumentClassifier, setGetDocumentClassifier] = useState(false);
      useEffect(() => {
        if (!isEmpty(filter) && !getDocumentClassifier) {
          setGetDocumentClassifier(true);
          dispatch(documentTypesTableActionsAsync.getDocumentTypeByIdAsync(filter.value));
        }
      }, [filter, getDocumentClassifier, setGetDocumentClassifier, dispatch]);

      return (
        <TableCell colSpan={colSpan}>
          <AutocompleteField
            disabled={!filteringEnabled}
            fullWidth
            input={{
              name: 'documentClassifier',
              onChange: () => {},
            }}
            {...!filter ? {
              fieldValue: null,
            } : {}}
            disableLabel
            defaultValue={getDocumentTypeById}
            label={t('Filter')}
            options={isEmpty(inputValue)
              ? documentTypesItems
              : documentTypesAutocomplete.get('name')}
            handleOnFocus={() => isEmpty(documentTypesItems) && getAutocompleteLists(['documentClassifier'])}
            getAsyncData={handleGetAsyncData}
            handleChange={(e, newValue) => onFilter(newValue ? { value: newValue.id } : null)}
            handleInputChange={(value) => setInputValue(value)}
          />
        </TableCell>
      );
    }

    case 'category': {
      const { onFilter, colSpan } = props;
      const { items } = documentTypesCategoryData.toJS();
      const [inputValue, setInputValue] = useState('');
      const defaultValue = (!isEmpty(filter) && items.length)
        ? find(items, { id: filter.value })
        : null;

      return (
        <TableCell colSpan={colSpan}>
          <AutocompleteField
            disabled={!filteringEnabled}
            fullWidth
            input={{
              name: 'category',
              onChange: () => {}
            }}
            {...!filter ? {
              fieldValue: null,
            } : {}}
            disableLabel
            defaultValue={defaultValue}
            label={t('Filter')}
            options={isEmpty(inputValue)
              ? items
              : docTypCategoryAutocomplete.get('name')}
            handleOnFocus={() => isEmpty(items) && getAutocompleteLists(['category'])}
            getAsyncData={handleGetAsyncData}
            handleChange={(e, newValue) => onFilter(newValue ? { value: newValue.id } : null)}
            handleInputChange={(value) => setInputValue(value)}
          />
        </TableCell>
      );
    }

    case 'users': {
      const { onFilter, colSpan } = props;
      const employeesItems = employeesData.toJS().items;
      const [inputValue, setInputValue] = useState('');
      const [getUser, setGetUser] = useState(false);
      useEffect(() => {
        if (!isEmpty(filter) && !getUser && includes(userInfo?.permissions, accessList.employees_get)) {
          setGetUser(true);
          dispatch(employeesAsyncActions.getEmployeeByIdAsync(filter.value));
        }
      }, [filter, getUser, setGetUser, dispatch, userInfo]);

      return (
        <TableCell colSpan={colSpan}>
          {filteringEnabled && (
            <AutocompleteField
              disabled={!filteringEnabled}
              fullWidth
              input={{
                name: 'users',
                onChange: () => {},
              }}
              {...!filter ? {
                fieldValue: null,
              } : {}}
              disableLabel
              defaultValue={employeesById.toJS()}
              label={t('Person')}
              options={isEmpty(inputValue)
                ? employeesItems
                : employeesAutocomplete.get('name')}
              handleOnFocus={() => isEmpty(employeesItems) && getAutocompleteLists(['users'])}
              getAsyncData={handleGetAsyncData}
              handleChange={(e, newValue) => onFilter(newValue ? { value: newValue.id } : null)}
              handleInputChange={(value) => setInputValue(value)}
            />
          )}
        </TableCell>
      );
    }
    case 'costscategories': {
      const { onFilter, colSpan } = props;
      return (
        <TableCell colSpan={colSpan}>
          <Select
            disabled={!filteringEnabled}
            value={filter ? filter.value : ''}
            displayEmpty
            onChange={(e) => onFilter(e.target.value ? { value: e.target.value } : null)}
          >
            <MenuItem value="">{t('All')}</MenuItem>
            {map(categoriesData.toJS().items, (option) => (
              <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
            ))}
          </Select>
        </TableCell>
      );
    }
    case 'rateType': {
      const { onFilter, colSpan } = props;
      return (
        <TableCell colSpan={colSpan}>
          <Select
            disabled={!filteringEnabled}
            value={filter ? filter.value : ''}
            displayEmpty
            onChange={(e) => onFilter(e.target.value ? { value: e.target.value } : null)}
          >
            <MenuItem value="">{t('All')}</MenuItem>
            <MenuItem value="fixed">{t('Fixed rate')}</MenuItem>
            <MenuItem value="real">{t('The real course')}</MenuItem>
          </Select>
        </TableCell>
      );
    }
    case 'entityName': {
      const { onFilter, colSpan } = props;
      return (
        <TableCell colSpan={colSpan}>
          <Select
            disabled={!filteringEnabled}
            value={filter ? filter.value : ''}
            displayEmpty
            onChange={(e) => onFilter(e.target.value ? { value: e.target.value } : null)}
          >
            <MenuItem value="">{t('All')}</MenuItem>
            {map(keys(listEntityName), (option) => (
              <MenuItem key={option} value={option}>{t(option)}</MenuItem>
            ))}
          </Select>
        </TableCell>
      );
    }
    case 'executor': {
      const { onFilter, colSpan } = props;
      return (
        <TableCell colSpan={colSpan}>
          <Select
            disabled={!filteringEnabled}
            value={filter ? filter.value : ''}
            displayEmpty
            onChange={(e) => onFilter(e.target.value ? { value: e.target.value } : null)}
          >
            <MenuItem value="">{t('All')}</MenuItem>
            {map(globalExecutors, (option) => (
              <MenuItem key={option.value} value={option.value}>{option.name}</MenuItem>
            ))}
          </Select>
        </TableCell>
      );
    }
    case 'type.id': {
      const { onFilter, colSpan } = props;
      const [getInventions, setGetInventions] = useState(false);
      const [defaultValue, setDefaultValue] = useState([]);
      useEffect(() => {
        if (!isEmpty(filter) && !getInventions && !isEmpty(actionTypesData.toJS().items)) {
          setGetInventions(true);
          setDefaultValue(actionTypesData.toJS().items.filter((item) => includes(filter.value, item.id)));
        }
      }, [filter, getInventions, setGetInventions, actionTypesData]);
      const handleChange = (e, newValue) => {
        onFilter(!isEmpty(newValue) ? { value: map(newValue, 'id'), type: 'in' } : null);
      };

      return (
        <TableCell colSpan={colSpan}>
          <AutocompleteChips
            disabled={!filteringEnabled}
            multiple
            fullWidth
            translate
            input={{
              name: 'type',
              onChange: () => {},
            }}
            {...!filter ? {
              fieldValue: [],
            } : {}}
            disableLabel
            defaultValue={defaultValue}
            label={t('Type')}
            options={actionTypesData.toJS().items}
            handleOnFocus={() => isEmpty(actionTypesData.toJS().items) && (
              setGetInventions(true),
              getAutocompleteLists(['type'])
            )}
            handleChange={handleChange}
            disableEditing
            limitTags={1}
          />
        </TableCell>
      );
    }

    case 'payed':
    case 'success': {
      const { onFilter, colSpan } = props;
      return (
        <TableCell colSpan={colSpan}>
          <Select
            disabled={!filteringEnabled}
            displayEmpty
            value={filter ? filter.value : ''}
            onChange={(e) => onFilter(e.target.value ? { value: e.target.value } : null)}
          >
            <MenuItem value="">{t('All')}</MenuItem>
            <MenuItem value="true">{t('Yes')}</MenuItem>
            <MenuItem value="false">{t('No')}</MenuItem>
          </Select>
        </TableCell>
      );
    }
    case 'firstImage': {
      const { colSpan } = props;
      return <TableCell colSpan={colSpan} />
    }

    case 'boost': {
      const { onFilter, colSpan } = props;
      return (
        <TableCell colSpan={colSpan}>
          <Select
            disabled={!filteringEnabled}
            displayEmpty
            value={filter ? filter.value : ''}
            onChange={(e) => onFilter(e.target.value ? { value: e.target.value } : null)}
          >
            <MenuItem value="">{t('All')}</MenuItem>
            <MenuItem value="1">{t('Urgently')}</MenuItem>
            <MenuItem value="0">{t('Usual')}</MenuItem>
          </Select>
        </TableCell>
      );
    }

    case 'type': {
      const { onFilter, colSpan } = props;
      return (
        <TableCell colSpan={colSpan}>
          <Select
            disabled={!filteringEnabled}
            value={filter ? filter.value : ''}
            onChange={(e) => onFilter(e.target.value ? { value: e.target.value } : null)}
            displayEmpty
          >
            <MenuItem value="">{t('All')}</MenuItem>
            {map(column.options || globalDocumentType, (option) => (
              <MenuItem
                key={option.value}
                value={option.value}
              >
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </TableCell>
      );
    }

    case 'objectType': {
      const { onFilter, colSpan } = props;
      return (
        <TableCell colSpan={colSpan}>
          <Select
            displayEmpty
            disabled={!filteringEnabled}
            value={filter ? filter.value : ''}
            onChange={(e) => onFilter(e.target.value ? { value: e.target.value } : null)}
          >
            <MenuItem value="">{t('All')}</MenuItem>
            {map(globalObjectTypes, (option, index) => (
              <MenuItem key={index} value={option.value}>{option.name}</MenuItem>
            ))}
          </Select>
        </TableCell>
      );
    }
    case 'receivedAt':
    case 'sendAt':
    case 'createdAt':
    case 'expiryDate':
    case 'applicationDate':
    case 'registrationDate':
    case 'paymentDate':
    case 'documentDate':
    case 'sendDate':
    case 'dateSent':
    case 'pendingActions':
    case 'nextAction':
    case 'renewal':
    case 'lastDocuments':
    case 'lastCheckAt':
    case 'date':
    case 'finishAt': {
      const { colSpan } = props;
      return (
        <TableCell colSpan={colSpan}>
          <RenderDateRange
            disabled={!filteringEnabled}
            filters={filter}
            onChange={onSelectDateFilterChange}
          />
        </TableCell>
      );
    }

    default: {
      const { colSpan } = props;
      const { value, setValue } = useDefferCall(onSelectFilterChange, getValue(''));
      const onChange = (e) => {
        setValue(e.target.value);
      };
      return (
        <TableCell
          colSpan={colSpan}
        >
          {filteringEnabled && (
            <TextField
              fullWidth
              placeholder={t('Filter')}
              onChange={onChange}
              value={value}
            />
          )}
        </TableCell>
      );
    }
  }
};

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.filter, nextProps.filter)
  && isEqual(prevProps.column, nextProps.column)
  && isEqual(prevProps.filteringEnabled, nextProps.filteringEnabled);
}

export default React.memo(FilterCell, areEqual);
