// Core
import { call, put, select } from 'redux-saga/effects';

// lodash
import filter from 'lodash/filter';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

// config
import { api } from '../../../../config/api';
import selectors from '../../../../config/selectors';
import { listEntityName } from '../../../../config/listTablesName';

// actions
import actions from '../../action';

const entity = listEntityName.charges;

export function* callGetChargesWorker({ payload: params }) {
  const {
    setChargesData,
    setTabs,
    setReload,
  } = actions;
  yield put(setChargesData({ pending: true }));

  const getChargeById = yield select(selectors.charges.getChargeById);
  const { alwaysFilters } = getChargeById.toJS();
  const response = yield call(api.charges.getCharges, {
    ...params, entity, ...!isEmpty(alwaysFilters) ? alwaysFilters : {},
  });

  let data = {
    items: [],
    totalCount: 0,
    pending: false,
  };

  const filterWorkId = (items, isCategory) => filter(items, (item) => (isCategory
    ? item.workId
    : !item.workId));
  const groupByWorkId = (items) => {
    const itemsNotWorkId = filterWorkId(items, false);
    const itemsWorkId = filterWorkId(items, true);

    return [
      ...!isEmpty(itemsWorkId) ? map(groupBy(itemsWorkId, 'workId'), (value) => ({
        ...value[0],
        name: value[0].workName,
        children: value,
      })) : [],
      ...!isEmpty(itemsNotWorkId) ? [{
        name: '',
        id: 0,
        children: itemsNotWorkId,
      }] : [],
    ];
  };

  if (response && response.status >= 200 && response.status < 400) {
    data = Object.assign(data, response.data);
    const { items } = data;

    yield put(setTabs({
      all: items,
      fees: items,
      services: items,
      works: groupByWorkId(items),
    }));
  }

  yield put(setChargesData(data));
  yield put(setReload(false));
}
