// Core
import { useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';

// lodash
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';

// immutable
// import {List, Map} from 'immutable';

// config
// import {markFieldSearchGroup} from '../../engine/config/globalConfig';

// actions async
import clientsAsyncActions from '../../engine/core/company/clients/saga/asyncAction';
import employeesAsyncActions from '../../engine/core/company/employees/saga/asyncAction';
import applicantsAsyncActions from '../../engine/core/applicants/saga/asyncAction';
import clientsTableActionAsync from '../../engine/core/company/clients/table/saga/asyncAction';
import employeesTableAsyncActions from '../../engine/core/company/employees/table/saga/asyncAction';
import tradeMarksProposalTableAsyncActions from '../../engine/core/tradeMarks/proposal/saga/asyncAction';
import tradeMarksReboundsAsyncActions from '../../engine/core/tradeMarks/rebounds/saga/asyncAction';
import industrialProposalTableAsyncActions from '../../engine/core/industrialDesigns/saga/asyncAction';
import utilityModelsProposalTableAsyncActions from '../../engine/core/utilityModels/saga/asyncAction';
import inventionsProposalTableAsyncActions from '../../engine/core/inventions/saga/asyncAction';
import departmentDocumentsTableAsyncActions from '../../engine/core/departmentDocuments/saga/asyncAction';
import documentTemplatesTableAsyncActions from '../../engine/core/documentTemplates/saga/asyncAction';
import ordersTableAsyncActions from '../../engine/core/company/orders/saga/asyncAction';
import documentTypeAsyncActions from '../../engine/core/documentTypes/saga/asyncAction';
import documentTypeCategoryAsyncActions from '../../engine/core/documentTypes/category/saga/asyncAction';
import tagsAsyncActions from '../../engine/core/tags/saga/asyncAction';
import actionTypesAsyncActions from '../../engine/core/actionTypes/saga/asyncAction';
import productCategoryAsyncActions from '../../engine/core/products/productCategory/saga/asyncAction';
import helpersActionsAsync from '../../engine/core/helpers/saga/asyncAction';
import chargesActionsAsync from '../../engine/core/charges/saga/asyncAction';
import notificationsActionsAsync from '../../engine/core/notifications/saga/asyncAction';
import jurisdictionsActionsAsync from '../../engine/core/jurisdictions/saga/asyncAction';
import currenciesActionsAsync from '../../engine/core/currencies/currenciesList/saga/asyncAction';
// import emailActionsAsync from '../../engine/core/email/email/saga/asyncAction';
import mktuTemplatesAsyncActions from '../../engine/core/tradeMarks/mktuTemplates/saga/asyncAction';

// actions
import applicantsActions from '../../engine/core/applicants/action';
import tradeMarksProposalTableActions from '../../engine/core/tradeMarks/proposal/action';
import inventionsProposalTableActions from '../../engine/core/inventions/action';
import industrialDesignsProposalTableActions from '../../engine/core/industrialDesigns/action';
import utilityModelsProposalTableActions from '../../engine/core/utilityModels/action';
import clientsTableActions from '../../engine/core/company/clients/table/action';
import employeesTableActions from '../../engine/core/company/employees/table/action';
import employeesActions from '../../engine/core/company/employees/action';
// import departmentDocumentsTableActions from '../../engine/core/departmentDocuments/action';
import documentTypesTableActions from '../../engine/core/documentTypes/action';
import documentTypeCategoryActions from '../../engine/core/documentTypes/category/action';
import tagsActions from '../../engine/core/tags/action';
import actionTypesActions from '../../engine/core/actionTypes/action';
import productCategoryActions from '../../engine/core/products/productCategory/action';
import notificationsActions from '../../engine/core/notifications/action';
import clientsActions from '../../engine/core/company/clients/action';
import jurisdictionsActions from '../../engine/core/jurisdictions/action';
// import emailActions from '../../engine/core/email/email/action';
import mktuTemplatesActions from '../../engine/core/tradeMarks/mktuTemplates/action';
import { useAccessList } from './useAccessList';
import accessList from '../../engine/config/accessList';

export function useEventsAutocompleteAsync(clean = true) {
  const dispatch = useDispatch();
  const accessClientsList = useAccessList(accessList.clients_list_get);
  const accessApplicantsList = useAccessList(accessList.applicants_list_get);
  const accessCurrenciesList = useAccessList(accessList.currencies_list_get);
  const accessEmployeesList = useAccessList(accessList.employees_list_get);
  const accessDocTypeList = useAccessList(accessList.documentClassifier_list_get);
  const accessTagsList = useAccessList(accessList.tags_list_get);
  const accessCostsCategoriesList = useAccessList(accessList.costsCategories_list_get);
  const accessDocTypeCategoryList = useAccessList(accessList.documentClassifierCategory_list_get);
  const accessTrademarksList = useAccessList(accessList.trademarks_list_get);
  const accessInventionsList = useAccessList(accessList.inventions_list_get);
  const accessIndustrialList = useAccessList(accessList.industrial_list_get);
  const accessUtilityList = useAccessList(accessList.utility_list_get);
  const accessOrdersList = useAccessList(accessList.orders_list_get);
  const accessDocumentsList = useAccessList(accessList.documents_list_get);
  const accessChargesList = useAccessList(accessList.charges_list_get);
  const accessDocumentsTemplateList = useAccessList(accessList.documentsTemplate_list_get);
  const accessNotificationsList = useAccessList(accessList.notifications_list_get);
  const accessJurisdictionsList = useAccessList(accessList.jurisdictions_list_get);
  const accessMktuList = useAccessList(accessList.mktu_list_get);
  const accessMktuTemplateList = useAccessList(accessList.mktuTemplate_list_get);
  const accessSearch = useAccessList(accessList.search_post_global);

  const accessClientsGet = useAccessList(accessList.clients_get);
  const accessEmployeesGet = useAccessList(accessList.employees_get);

  useEffect(() => {
    const emptyData = {
      items: [],
      totalCount: 0,
      pending: false,
    };
    if (clean) {
      return function cleanup() {
        dispatch(tradeMarksProposalTableActions.setProposalData(emptyData));
        dispatch(tradeMarksProposalTableActions.setMktuList([]));
        dispatch(inventionsProposalTableActions.setProposalData(emptyData));
        dispatch(industrialDesignsProposalTableActions.setProposalData(emptyData));
        dispatch(utilityModelsProposalTableActions.setProposalData(emptyData));
        dispatch(clientsTableActions.setClientsData(emptyData));
        dispatch(employeesTableActions.setEmployeesData(emptyData));
        // dispatch(departmentDocumentsTableActions.setDepartmentDocumentsData(emptyData));
        dispatch(documentTypesTableActions.setDocumentTypesData(emptyData));
        dispatch(documentTypeCategoryActions.setDocumentTypesCategoryData(emptyData));
        dispatch(tagsActions.setTagsData({ items: [] }));
        dispatch(actionTypesActions.setActionTypesData({ items: [] }));
        dispatch(productCategoryActions.setProductCategoryData({ items: [] }));
        dispatch(notificationsActions.setNotificationsData(emptyData));
        dispatch(applicantsActions.setApplicantsInitialList({ items: [] }));
        dispatch(clientsActions.setClientById({ data: {} }));
        dispatch(employeesActions.setEmployeeById({ data: {} }));
        dispatch(jurisdictionsActions.setJurisdictionsData(emptyData));
        // dispatch(emailActions.setRecipientsEmailData({ items: [], pending: false }));
        dispatch(mktuTemplatesActions.setMktuTemplatesData(emptyData));
      };
    }
    return null;
  }, [dispatch, clean]);

  // параметр additionalData может содержать всё что угодно, любые данные
  const handleGetAsyncData = useCallback((value, name, additionalData, entities = []) => {
    switch (name) {
      case 'clientsName':
      case 'clientsId':
      case 'client':
      case 'clients': {
        if (accessClientsList) {
          dispatch(clientsAsyncActions.getClientsAutocomplete({ name: value }));
        }
        break;
      }
      case 'attorneyId':
      case 'attorneyName':
      case 'attorney': {
        if (accessEmployeesList) {
          dispatch(employeesAsyncActions.getEmployeeAutocomplete({ name: value, isAttorney: 1 }));
        }
        break;
      }
      case 'applicantId':
      case 'applicantsName':
      case 'applicantClients':
      case 'applicantOwners':
      case 'applicantAuthors':
      case 'applicant': {
        if (accessApplicantsList) {
          dispatch(applicantsAsyncActions.getApplicantAutocomplete({ name: value }));
        }
        break;
      }
      case 'users':
      case 'usersId':
      case 'personId':
      case 'employee':
      case 'person': {
        if (accessEmployeesList) {
          const checkAdditionalData = (additionalData && !additionalData.target)
            ? additionalData : null;
          dispatch(employeesAsyncActions.getEmployeeAutocomplete(checkAdditionalData
            || { name: value }));
        }
        break;
      }
      case 'mktuTemplates': {
        if (accessMktuTemplateList) {
          dispatch(mktuTemplatesAsyncActions.getAutocompleteOptionsAsync({
            name: value,
          }));
        }
        break;
      }
      case 'caseNumberRebounds': {
        if (accessMktuList) {
          dispatch(tradeMarksReboundsAsyncActions.getReboundsAutocompleteAsync({
            tmCaseNumber: value,
            tmName: value,
            tmApplicationNumber: value,
            tmRegistrationNumber: value,
            tmInternalTitle: value,
            title: value,
          }));
        }
        break;
      }
      case 'documentClassifier': {
        if (accessDocTypeList) {
          dispatch(documentTypeAsyncActions.getAutocompleteOptionsAsync({ name: value }));
        }
        break;
      }
      case 'category': {
        if (accessDocTypeCategoryList) {
          dispatch(documentTypeCategoryAsyncActions.getAutocompleteOptionsAsync({ name: value }));
        }
        break;
      }
      case 'trademarks': {
        if (additionalData && accessTrademarksList) {
          dispatch(tradeMarksProposalTableAsyncActions.getAutocompleteOptionsAsync(additionalData));
        }
        break;
      }
      case 'industrial': {
        if (additionalData && accessIndustrialList) {
          dispatch(industrialProposalTableAsyncActions.getAutocompleteOptionsAsync(additionalData));
        }
        break;
      }
      case 'utility': {
        if (additionalData && accessUtilityList) {
          dispatch(utilityModelsProposalTableAsyncActions.getAutocompleteOptionsAsync(
            additionalData,
          ));
        }
        break;
      }
      case 'orders': {
        if (additionalData && accessOrdersList) {
          dispatch(ordersTableAsyncActions.getAutocompleteOptionsAsync(additionalData));
        }
        break;
      }
      case 'jurisdiction': {
        if (additionalData && accessJurisdictionsList) {
          dispatch(jurisdictionsActionsAsync.getAutocompleteOptionsAsync({ name: value }));
        }
        break;
      }
      case 'currency': {
        if (additionalData && accessCurrenciesList) {
          dispatch(currenciesActionsAsync.getAutocompleteOptionsAsync({ name: value }));
        }
        break;
      }
      case 'inventions': {
        if (additionalData && accessInventionsList) {
          dispatch(inventionsProposalTableAsyncActions.getAutocompleteOptionsAsync(additionalData));
        }
        break;
      }
      case 'documents':
      case 'responseDocument': {
        if (additionalData && accessDocumentsList) {
          dispatch(departmentDocumentsTableAsyncActions.getAutocompleteOptionsAsync(
            additionalData,
          ));
        }
        break;
      }
      case 'charges': {
        if (additionalData && accessChargesList) {
          dispatch(chargesActionsAsync.getChargesAutocomplete(additionalData));
        }
        break;
      }
      // case 'recipient': {
      //   if (additionalData) {
      //     dispatch(emailActionsAsync.getRecipientsAutocompleteOptionsAsync(additionalData));
      //   }
      //   break;
      // }
      case 'template': {
        if (additionalData && accessDocumentsTemplateList) {
          dispatch(documentTemplatesTableAsyncActions.getAutocompleteOptionsAsync(additionalData));
        }
        break;
      }
      case 'notifications': {
        if (additionalData && accessNotificationsList) {
          dispatch(notificationsActionsAsync.getAutocompleteOptionsAsync(additionalData));
        }
        break;
      }
      // Для глобального поиска по апи POST /api/v1/search
      case 'caseNumberGroup': {
        if (accessSearch) {
          dispatch(helpersActionsAsync.postAutocompleteGroupOptionsAsync({
            value,
            entities: ['trademarks', 'utility', 'inventions', 'industrial'],
            fields: ['title', 'caseNumber', 'applicationNumber', 'registrationNumber'],
            returnFields: ['title', 'caseNumber', 'applicationNumber', 'registrationNumber'],
          }));
        }
        break;
      }
      case 'globalSearch': {
        if (accessSearch) {
          dispatch(helpersActionsAsync.postAutocompleteGroupOptionsAsync({
            value,
            entities,
            fields: ['title', 'caseNumber', 'applicationNumber', 'registrationNumber',
              'internalTitle', 'name', 'email', 'phone', 'inNumber', 'outNumber', 'orderNum'],
            returnFields: ['title', 'caseNumber', 'applicationNumber', 'registrationNumber',
              'internalTitle', 'name', 'email', 'phone', 'inNumber', 'outNumber', 'orderNum'],
            groupByEntities: true,
          }));
        }
        break;
      }
      case 'tmFilterSearch': {
        if (accessSearch) {
          dispatch(helpersActionsAsync.postAutocompleteGroupOptionsAsync({
            value,
            entities: ['trademarks'],
            fields: ['title', 'caseNumber', 'applicationNumber', 'registrationNumber'],
            returnFields: ['title', 'caseNumber', 'applicationNumber', 'registrationNumber'],
            isFilter: true,
          }));
        }
        break;
      }
      case 'industrialFilterSearch': {
        if (accessSearch) {
          dispatch(helpersActionsAsync.postAutocompleteGroupOptionsAsync({
            value,
            entities: ['industrial'],
            fields: ['title', 'caseNumber', 'applicationNumber', 'registrationNumber'],
            returnFields: ['title', 'caseNumber', 'applicationNumber', 'registrationNumber'],
            isFilter: true,
          }));
        }
        break;
      }
      case 'inventionsFilterSearch': {
        if (accessSearch) {
          dispatch(helpersActionsAsync.postAutocompleteGroupOptionsAsync({
            value,
            entities: ['inventions'],
            fields: ['title', 'caseNumber', 'applicationNumber', 'registrationNumber'],
            returnFields: ['title', 'caseNumber', 'applicationNumber', 'registrationNumber'],
            isFilter: true,
          }));
        }
        break;
      }
      case 'utilityFilterSearch': {
        if (accessSearch) {
          dispatch(helpersActionsAsync.postAutocompleteGroupOptionsAsync({
            value,
            entities: ['utility'],
            fields: ['title', 'caseNumber', 'applicationNumber', 'registrationNumber'],
            returnFields: ['title', 'caseNumber', 'applicationNumber', 'registrationNumber'],
            isFilter: true,
          }));
        }
        break;
      }
      case 'cc':
      case 'bcc':
      case 'to': {
        if (accessSearch) {
          dispatch(helpersActionsAsync.postAutocompleteGroupOptionsAsync({
            value,
            entities: ['users'],
            fields: ['name', 'email'],
            returnFields: ['name', 'email'],
            isEmail: true,
          }));
        }
        break;
      }
      default:
    }
  }, [
    dispatch, accessClientsList, accessApplicantsList, accessChargesList,
    accessCurrenciesList, accessDocTypeCategoryList, accessDocTypeList, accessDocumentsList,
    accessDocumentsTemplateList, accessEmployeesList, accessIndustrialList, accessInventionsList,
    accessJurisdictionsList, accessMktuList, accessMktuTemplateList, accessNotificationsList,
    accessOrdersList, accessTrademarksList, accessUtilityList, accessSearch,
  ]);

  const getAutocompleteLists = useCallback((fieldsName, params) => {
    const paramsForAutocompleteList = {
      sortBy: 'createdAt',
      sortDir: 'asc',
      limit: 50,
      page: 0,
    };
    if (isArray(fieldsName) && !isEmpty(fieldsName)) {
      forEach(fieldsName, (name) => {
        switch (name) {
          case 'client':
          case 'clients': {
            if (accessClientsList) {
              dispatch(clientsTableActionAsync.getListAsync(paramsForAutocompleteList));
            }
            break;
          }
          case 'applicant': {
            if (accessApplicantsList) {
              dispatch(applicantsAsyncActions.getApplicantsListAsync(paramsForAutocompleteList));
            }
            break;
          }
          case 'currency': {
            if (accessCurrenciesList) {
              dispatch(currenciesActionsAsync.getListAsync(paramsForAutocompleteList));
            }
            break;
          }
          case 'applicantOwners':
          case 'applicantAuthors':
          case 'applicantClients': {
            if (accessApplicantsList) {
              dispatch(applicantsAsyncActions.getApplicantsInitialListAsync(params
                || paramsForAutocompleteList));
            }
            break;
          }
          case 'users':
          case 'employee':
          case 'usersId': {
            if (accessEmployeesList) {
              dispatch(employeesTableAsyncActions.getListAsync(
                paramsForAutocompleteList,
              ));
            }
            break;
          }
          case 'person': {
            if (accessEmployeesList) {
              dispatch(employeesTableAsyncActions.getListAsync({
                isAttorney: 1,
                ...paramsForAutocompleteList,
              }));
            }
            break;
          }
          case 'attorneyName':
          case 'attorneyId': {
            if (accessEmployeesList) {
              dispatch(employeesAsyncActions.getEmployeeAutocomplete({
                isAttorney: 1,
                ...paramsForAutocompleteList,
              }));
            }
            break;
          }
          case 'termFromDocument': {
            if (accessDocTypeList) {
              dispatch(documentTypeAsyncActions.getListAsync());
            }
            break;
          }
          case 'tags': {
            if (accessTagsList) {
              dispatch(tagsAsyncActions.getListAsync());
            }
            break;
          }
          case 'type': {
            dispatch(actionTypesAsyncActions.getListAsync());
            break;
          }
          case 'productCategory': {
            if (accessCostsCategoriesList) {
              dispatch(productCategoryAsyncActions.getListAsync());
            }
            break;
          }
          case 'category': {
            if (accessDocTypeCategoryList) {
              dispatch(documentTypeCategoryAsyncActions.getListAsync(paramsForAutocompleteList));
            }
            break;
          }
          case 'documentClassifier': {
            if (accessDocTypeList) {
              dispatch(documentTypeAsyncActions.getListAsync(paramsForAutocompleteList));
            }
            break;
          }
          // case 'recipient': {
          //   dispatch(emailActionsAsync.getListRecipientsAsync(paramsForAutocompleteList));
          //   break;
          // }
          case 'trademarks': {
            if (accessTrademarksList) {
              dispatch(tradeMarksProposalTableAsyncActions.getListAsync(paramsForAutocompleteList));
            }
            break;
          }

          case 'industrial': {
            if (accessIndustrialList) {
              dispatch(industrialProposalTableAsyncActions.getListAsync(paramsForAutocompleteList));
            }
            break;
          }
          case 'utility': {
            if (accessUtilityList) {
              dispatch(
                utilityModelsProposalTableAsyncActions.getListAsync(paramsForAutocompleteList),
              );
            }
            break;
          }
          case 'inventions': {
            if (accessInventionsList) {
              dispatch(
                inventionsProposalTableAsyncActions.getListAsync(paramsForAutocompleteList),
              );
            }
            break;
          }
          case 'orders': {
            if (accessOrdersList) {
              dispatch(ordersTableAsyncActions.getListAsync(paramsForAutocompleteList));
            }
            break;
          }
          case 'documents':
          case 'responseDocument': {
            if (accessDocumentsList) {
              dispatch(
                departmentDocumentsTableAsyncActions.getListAsync(params
                  || paramsForAutocompleteList),
              );
            }
            break;
          }
          case 'charges': {
            if (accessChargesList) {
              dispatch(chargesActionsAsync.getListAsync(paramsForAutocompleteList));
            }
            break;
          }
          case 'template': {
            if (accessDocumentsTemplateList) {
              dispatch(
                documentTemplatesTableAsyncActions.getListAsync(paramsForAutocompleteList),
              );
            }
            break;
          }
          case 'notifications': {
            if (accessNotificationsList) {
              dispatch(notificationsActionsAsync.getListAsync({
                limit: 50,
                page: 0,
              }));
            }
            break;
          }
          case 'jurisdictions': {
            if (accessJurisdictionsList) {
              dispatch(jurisdictionsActionsAsync.getListAsync());
            }
            break;
          }
          case 'caseNumberRebounds': {
            if (accessMktuList) {
              dispatch(
                tradeMarksReboundsAsyncActions.getListAsync(paramsForAutocompleteList),
              );
            }
            break;
          }
          case 'mktuTemplates': {
            if (accessMktuTemplateList) {
              dispatch(
                mktuTemplatesAsyncActions.getListAsync(paramsForAutocompleteList),
              );
            }
            break;
          }
          default:
        }
      });
    }
  }, [
    dispatch, accessClientsList, accessApplicantsList, accessChargesList, accessCostsCategoriesList,
    accessCurrenciesList, accessDocTypeCategoryList, accessDocTypeList, accessDocumentsList,
    accessDocumentsTemplateList, accessEmployeesList, accessIndustrialList, accessInventionsList,
    accessJurisdictionsList, accessMktuList, accessMktuTemplateList, accessNotificationsList,
    accessOrdersList, accessTagsList, accessTrademarksList, accessUtilityList,
  ]);

  const handleGetSelectOption = useCallback((elem, name) => {
    switch (name) {
      case 'clients': {
        if (elem?.id && accessClientsGet) {
          dispatch(clientsAsyncActions.getClientByIdAsync(elem?.id));
        }
        break;
      }
      case 'person': {
        if (elem?.id && accessEmployeesGet) {
          dispatch(employeesAsyncActions.getEmployeeByIdAsync(elem?.id));
        }
        break;
      }
      default:
    }
  }, [dispatch, accessEmployeesGet, accessClientsGet]);

  return {
    handleGetAsyncData,
    getAutocompleteLists,
    handleGetSelectOption,
  };
}
