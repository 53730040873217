// core
import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import {
  Field, Form, reduxForm,
} from 'redux-form/lib/immutable';

// config
import selectors from '../../../../engine/config/selectors';

// parts
import AutocompleteField from '../../../../ui/Form/AutocompleteField';
import Modal from '../../../../components/Modal/Modal';
import DialogActions from '../../../../components/Modal/components/DialogActions';

// actions
import actions from '../../../../engine/core/tradeMarks/madridCase/action';

function BasicMadridCaseModal(props) {
  const {
    handleSubmit, isModalOpen, destroy,
    options, handleSend, handleClose,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(actions.setModalAddOpen(false));
    handleClose();
  };
  useEffect(() => function cleanup() {
    destroy();
  }, [destroy]);

  const handleSubmits = (formData) => {
    const json = formData.toJS();
    handleSend(json?.case);
  };

  return (
    <Modal
      title={t('Choose a base trademark')}
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
      displayDialogActions={false}
    >
      <Form onSubmit={handleSubmit(handleSubmits)}>
        <Field
          multiple={false}
          name="case"
          id="case"
          component={AutocompleteField}
          type="text"
          label={t('Case')}
          margin="dense"
          required
          fullWidth
          options={options}
          md={5}
        />

        <DialogActions
          // pending={pendingPost}
          handleCloseModal={handleCloseModal}
        />
      </Form>
    </Modal>
  );
}

BasicMadridCaseModal.displayName = 'BasicMadridCaseModal';

BasicMadridCaseModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  destroy: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  options: PropTypes.oneOfType([
    PropTypes.array,
  ]),
  handleSend: PropTypes.oneOfType([
    PropTypes.func,
  ]),
  handleClose: PropTypes.func,
};

BasicMadridCaseModal.defaultProps = {
  options: [],
  handleSend: () => {},
  handleClose: () => {},
};

function mapStateToProps(state) {
  return {
    isModalOpen: selectors.madridCase.isModalAddOpen(state),
  };
}

export default compose(
  reduxForm({
    form: 'BasicMadridCaseModal',
  }),
  connect(mapStateToProps, null),
)(BasicMadridCaseModal);
