// Core
import { all, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetJurisdictionsWorker,
  callGetJurisdictionByIdWorker,
  callPutJurisdictionByIdWorker,
  callPostJurisdictionWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callDeleteJurisdictionWorker,
  callGetAutocompleteOptionsWorker,
} from './workers';
import types from '../types';

function* watchGetJurisdictions() {
  yield takeEvery(asyncTypes.GET_JURISDICTIONS_ASYNC, callGetJurisdictionsWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_JURISDICTIONS_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchGetJurisdictionById() {
  yield takeEvery(asyncTypes.GET_JURISDICTION_BY_ID_ASYNC, callGetJurisdictionByIdWorker);
}
function* watchPostJurisdiction() {
  yield takeEvery(asyncTypes.POST_JURISDICTION_ASYNC, callPostJurisdictionWorker);
}
function* watchPutJurisdictionById() {
  yield takeEvery(asyncTypes.PUT_JURISDICTION_BY_ID_ASYNC, callPutJurisdictionByIdWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_JURISDICTIONS_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_JURISDICTIONS_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_JURISDICTIONS_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_JURISDICTIONS_SORTING, callSetToLocalDataWorker);
}
function* watchSetColumnWidths() {
  yield takeEvery(types.SET_JURISDICTIONS_COLUMN_WIDTHS, callSetToLocalDataWorker);
}
function* watchSetColumnOrder() {
  yield takeEvery(types.SET_JURISDICTIONS_COLUMN_ORDER, callSetToLocalDataWorker);
}
function* watchSetHiddenColumnNames() {
  yield takeEvery(types.SET_JURISDICTIONS_HIDDEN_COLUMN_NAMES, callSetToLocalDataWorker);
}
function* watchDeleteJurisdiction() {
  yield takeEvery(asyncTypes.DELETE_JURISDICTION_ASYNC, callDeleteJurisdictionWorker);
}

function* watchGetAutocompleteOptions() {
  yield takeEvery(
    asyncTypes.GET_JURISDICTIONS_AUTOCOMPLETE_OPTIONS_ASYNC,
    callGetAutocompleteOptionsWorker,
  );
}

export function* watchersJurisdictions() {
  yield all([
    watchGetJurisdictions(),
    watchGetInitialState(),
    watchGetJurisdictionById(),
    watchPostJurisdiction(),
    watchPutJurisdictionById(),
    watchSetCurrentPage(),
    watchSetPageSize(),
    watchSetFilters(),
    watchSetSorting(),
    watchSetColumnWidths(),
    watchSetColumnOrder(),
    watchSetHiddenColumnNames(),
    watchDeleteJurisdiction(),
    watchGetAutocompleteOptions(),
  ]);
}
