// Core
import { apply, select, put } from 'redux-saga/effects';
import isNil from 'lodash/isNil';

// Engine
import localData from '../../../../../config/localData';
import selectors from '../../../../../config/selectors';
import types from '../../types';
import actions from '../../action';
import helpersActionsAsync from '../../../../helpers/saga/asyncAction';

export function* callSetToLocalDataWorker({ type, payload }) {
  const pathname = yield select(selectors.router.pathname);
  const data = {
    currentPage: yield select(selectors.mktuTemplatesTable.currentPage),
    filters: (yield select(selectors.mktuTemplatesTable.filters)),
    pageSize: yield select(selectors.mktuTemplatesTable.pageSize),
    totalCount: yield select(selectors.mktuTemplatesTable.totalCount),
    sorting: (yield select(selectors.mktuTemplatesTable.sorting)),
    columnWidths: (yield select(selectors.mktuTemplatesTable.columnWidths)),
    columnOrder: (yield select(selectors.mktuTemplatesTable.columnOrder)),
    hiddenColumnNames: (yield select(selectors.mktuTemplatesTable.hiddenColumnNames)),
  };

  let currentData;
  yield put(actions.setReload(true));
  switch (type) {
    case types.SET_MKTU_TEMPLATES_CURRENT_PAGE: {
      currentData = { currentPage: payload };
      break;
    }
    case types.SET_MKTU_TEMPLATES_PAGE_SIZE: {
      currentData = { pageSize: payload };
      break;
    }
    case types.SET_MKTU_TEMPLATES_FILTERS: {
      const { currentPage } = data;
      const filters = payload.filter(({ value }) => !isNil(value));
      if (currentPage) {
        yield put(actions.setCurrentPage(0));
      }
      currentData = { filters };
      break;
    }
    case types.SET_MKTU_TEMPLATES_SORTING: {
      currentData = { sorting: payload };
      break;
    }
    case types.SET_MKTU_TEMPLATES_COLUMN_WIDTHS: {
      currentData = { columnWidths: payload };
      break;
    }
    case types.SET_MKTU_TEMPLATES_COLUMN_ORDER: {
      currentData = { columnOrder: payload };
      break;
    }
    case types.SET_MKTU_TEMPLATES_HIDDEN_COLUMN_NAMES: {
      currentData = { hiddenColumnNames: payload };
      break;
    }
    default: {
      break;
    }
  }

  data.currentPage = yield select(selectors.mktuTemplatesTable.currentPage);
  yield apply(localData, localData.setItem, [pathname, { ...data, ...currentData }]);

  switch (type) {
    case types.SET_MKTU_TEMPLATES_COLUMN_WIDTHS:
    case types.SET_MKTU_TEMPLATES_COLUMN_ORDER:
    case types.SET_MKTU_TEMPLATES_HIDDEN_COLUMN_NAMES: {
      yield put(helpersActionsAsync.setSaveFrontendSetting());
      break;
    }
    default: {
      break;
    }
  }
}
