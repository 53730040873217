// core
import React, {
  memo, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import {
  Field, Form, reduxForm, change,
} from 'redux-form/immutable';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import { List, Map } from 'immutable';
import * as PropTypes from 'prop-types';

// ui
import {
  CardContent, Grid as MuiGrid, Button as MuiButton,
  Typography as MuiTypography, Card as MuiCard, Box,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';

// lodash
import {
  isEmpty, isNil, filter, forEach, concat, map, find,
} from 'lodash';

// helpers
import { formName, formFields } from '../helper/form';
import { formatDate, getValueFormatDate } from '../../../engine/_helpers/formatterDate';
import str2bool from '../../../ui/_helpers/str2bool';

// config
import selectors from '../../../engine/config/selectors';

// ACTIONS
import proposalAsyncActions from '../../../engine/core/industrialDesigns/saga/asyncAction';
import proposalActions from '../../../engine/core/industrialDesigns/action';
import tradeMarksActions from '../../../engine/core/tradeMarks/proposal/action';
import clientsActions from '../../../engine/core/company/clients/action';
import applicantsActions from '../../../engine/core/applicants/action';
import reboundsAction from '../../../engine/core/tradeMarks/rebounds/action';
import employeesActions from '../../../engine/core/company/employees/action';
import uploadFileAction from '../../../engine/core/uploadFile/action';
import helpersActionAsync from '../../../engine/core/helpers/saga/asyncAction';
import helpersActions from '../../../engine/core/helpers/action';

// parts
import RenderTextField from '../../../ui/Form/RenderTextField';
import Checkbox from '../../../ui/Form/Checkbox';
import Sign from '../../../components/Sign/Sign';
import Loader from '../../../components/Loader';
import DragImages from './components/DragImages';
import CompareWithRegistryModal from '../../../components/CompareWithRegistryModal/CompareWithRegistryModal';
import ConfirmModal from '../../../components/ConfirmModal';
import Send from '../../../components/Send/Send';
import CardPublicationAndAttributes from '../../TradeMarks/Proposal/ProposalAdd/components/CardPublicationAndAttributes';
import CardClient from '../../TradeMarks/Proposal/ProposalAdd/components/CardClient';
import CardFooter from '../../TradeMarks/Proposal/ProposalAdd/components/CardFooter';
import CardInitialDocuments from '../../TradeMarks/Proposal/ProposalAdd/components/CardInitialDocuments';
import CardPriority from '../../TradeMarks/Proposal/ProposalAdd/components/CardPriority';
import CardMailingAddress from '../../TradeMarks/Proposal/ProposalAdd/components/CardMailingAddress';
import CardRepresentative from '../../TradeMarks/Proposal/ProposalAdd/components/CardRepresentative';
import CardApplicantsOwnersAuthor from '../../TradeMarks/Proposal/ProposalAdd/components/CardApplicantsOwnersAuthor';
import ButtonSubmit from '../../../ui/Button/ButtonSubmit';
import CardBasicInformation from '../../TradeMarks/Proposal/ProposalAdd/components/CardBasicInformation';
import CardMaintainingAndCost from '../../TradeMarks/Proposal/ProposalAdd/components/CardMaintainingAndCost';
import accessList from '../../../engine/config/accessList';
import { useAccessList } from '../../../ui/_hooks/useAccessList';

// styles
const Card = styled(MuiCard)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Button = styled(MuiButton)(spacing);

function IndustrialDesignAdd(props) {
  const {
    handleSubmit,
    initialize,
    newApplicantsList,
    newOwnersList,
    newAuthorsList,
    signList,
    pendingPutProposal,
    pendingPostProposal,
    uploadFiles,
    match,
    pendingGetProposalById,
    applicationNumberValues,
    registrationNumberValue,
    parentNumDateIsCheck,
    variantNumDateIsCheck,
    priorityTypeValues,
    imagesVariant,
    getNextNumber,
    fieldClient,
    pendingRegistryByNumber,
    isModalOpenConfirm,
    isModalOpenRegistry,
    registryByNumber,
    getProposalById,
    tagsValues,
    postalAddressValues,
    listResponsible,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [fieldValueByNumber, setFieldValueByNumber] = useState('');
  const [isBase64, setIsBase64] = useState(false);
  const pending = pendingPutProposal || pendingPostProposal;
  const { params } = match;
  const accessPut = useAccessList(accessList.inventions_put);
  const accessGetBase64 = useAccessList(accessList.files_helpers_base64_get);
  const accessGetNextNumber = useAccessList(accessList.industrial_next_number_get);
  const accessEdit = !isEmpty(params) ? accessPut : true;

  useEffect(() => {
    if (!isEmpty(imagesVariant.toJS()) && !isBase64 && !isNil(params.hashId) && accessGetBase64) {
      forEach(concat(...imagesVariant.toJS()), (image) => {
        dispatch(helpersActionAsync.getImagesBase64Async(image));
      });
      setIsBase64(true);
    }
  }, [dispatch, imagesVariant, setIsBase64, isBase64, params, accessGetBase64]);
  useEffect(() => {
    if (!isNil(params.hashId)) {
      dispatch(proposalAsyncActions.getProposalByIdAsync(params.hashId));
    }
  }, [dispatch, params]);

  useEffect(() => {
    if (isEmpty(params) && accessGetNextNumber) {
      dispatch(proposalAsyncActions.getNextNumberAsync({
        field: formFields.caseNumber,
      }));
    }
  }, [dispatch, params, accessGetNextNumber]);

  useEffect(() => {
    if (!isEmpty(getNextNumber) && isEmpty(params)) {
      dispatch(change(formName, formFields.caseNumber, getNextNumber));
      dispatch(proposalActions.setNextNumber(''));
    }
  }, [dispatch, getNextNumber, params]);

  useEffect(() => function cleanup() {
    dispatch(applicantsActions.setNewApplicantsListData([]));
    dispatch(applicantsActions.setNewAuthorsListData([]));
    dispatch(applicantsActions.setNewOwnersListData([]));
    dispatch(applicantsActions.setApplicantsList(List()));
    dispatch(uploadFileAction.setAllUploadFiles([]));
    dispatch(helpersActions.setSignList([]));
    dispatch(helpersActions.setDocumentsType([]));
    dispatch(reboundsAction.setReboundById({}));
    dispatch(clientsActions.setClientById({ data: {} }));
    dispatch(proposalActions.setImageClear());
    dispatch(employeesActions.setEmployeeById({ data: {} }));
    dispatch(proposalActions.setCompany(Map()));
    dispatch(tradeMarksActions.setCompany(Map()));
    dispatch(helpersActions.setImagesWithBase64Clear());
  }, [dispatch]);

  useEffect(() => {
    dispatch(applicantsActions.setApplicantsList(List()));
  }, [dispatch]);

  useEffect(() => {
    initialize({
      [formFields.status]: 1,
      [formFields.isColor]: 'true',
      [formFields.eApplication]: true,
      data: {
        relatedAppsRegs: {
          parentNumDateIsCheck: false,
          variantNumDateIsCheck: false,
        },
        standardCharacters: 'true',
        priority: { type: 'noPriority' },
      },
    });
  }, [
    initialize,
  ]);

  const handleCompare = () => {
    if (!isEmpty(applicationNumberValues)) {
      dispatch(proposalAsyncActions.getRegistryByNumberAsync({
        applicationNumber: applicationNumberValues,
        disableInitialize: true,
      }));
      dispatch(helpersActions.setModal({ isModalOpenRegistry: true }));
    } else {
      dispatch(helpersActions.setModal({ isModalOpen: true }));
    }
  };
  const handleChangeProposal = (data) => {
    dispatch(proposalActions.setProposalDataById(data));
  };
  const handleCleanup = () => {
    dispatch(proposalActions.setRegistryByNumber(Map()));
  };
  const addImageAction = (image) => {
    dispatch(proposalActions.setImage(image));
  };
  const setImageMove = (images) => {
    dispatch(proposalActions.setImageMove(images));
  };
  const addVariantAction = () => (dispatch(proposalActions.setImageVariant([[]])));
  const setImageVariantRemove = (index) => (dispatch(
    proposalActions.setImageVariantRemove({ variantIndex: index }),
  ));
  const setImageRemove = (variantIndex, imageIndex) => {
    dispatch(proposalActions.setImageRemove({
      variantIndex,
      imageIndex,
    }));
  };
  const setImageName = (variantIndex, imageIndex, name) => (dispatch(proposalActions.setImageName({
    variantIndex,
    imageIndex,
    name,
  })));

  const handleSubmits = (formData) => {
    const jsFormData = formData.toJS();

    jsFormData.data.applicants = [...newApplicantsList.toJS()];
    if (registrationNumberValue) {
      jsFormData.data.holders = [...newOwnersList.toJS()];
    } else {
      delete jsFormData.data.holders;
    }
    jsFormData.data.authors = [...newAuthorsList.toJS()];

    const documents = [];
    // eslint-disable-next-line array-callback-return
    map(uploadFiles.toJS(), (file, index) => {
      documents.push({
        type: jsFormData.documents[index].type.id,
        ...file.newFile ? {
          fileId: file.fileId,
        } : {
          docId: file.id,
        },
      });
    });
    const responsible = [];
    // eslint-disable-next-line array-callback-return
    map(listResponsible.toJS(), (item, index) => {
      const { documentClassifier } = jsFormData.responsible[index];
      responsible.push({
        employeeId: item.id,
        documentClassifier: find(documentClassifier, { value: 'all' }) ? null : map(documentClassifier, 'id'),
      });
    });

    const priority = {
      ...jsFormData.data.priority,
      type: jsFormData.data.priority?.type === 'noPriority' ? undefined : jsFormData.data.priority?.type,
      earlyAppDate: getValueFormatDate(formatDate(jsFormData.data.priority?.earlyAppDate), 'earlyAppDate'),
      exhibitionDate: getValueFormatDate(formatDate(jsFormData.data.priority?.exhibitionDate), 'exhibitionDate'),
    };
    const relatedAppsRegs = {
      parentNumDate: jsFormData.data.relatedAppsRegs?.parentNumDateIsCheck
        ? jsFormData.data.relatedAppsRegs.parentNumDate : undefined,
      variantNumDate: jsFormData.data.relatedAppsRegs?.variantNumDateIsCheck
        ? jsFormData.data.relatedAppsRegs.variantNumDate : undefined,
    };
    const publication = {
      ...jsFormData.publication,
      date: getValueFormatDate(formatDate(jsFormData.publication?.date), 'date'),
    };
    const data = {
      ...jsFormData,
      ...!isEmpty(jsFormData.tags) ? {
        tags: map(jsFormData.tags, (item) => item.id),
      } : {},
      images: imagesVariant.toJS(),
      clients: jsFormData?.clients?.id,
      jurisdictions: jsFormData?.jurisdictions?.id,
      registrationDate: getValueFormatDate(formatDate(jsFormData.registrationDate), 'registrationDate'),
      applicationDate: getValueFormatDate(formatDate(jsFormData.applicationDate), 'applicationDate'),
      renewalDateFrom: getValueFormatDate(formatDate(jsFormData.renewalDateFrom), 'renewalDateFrom'),
      renewalDateTo: getValueFormatDate(formatDate(jsFormData.renewalDateTo), 'renewalDateTo'),
      // expiryDate: getValueFormatDate(formatDate(jsFormData.expiryDate), 'expiryDate'),
      initialPublication: getValueFormatDate(formatDate(jsFormData.initialPublication), 'initialPublication'),
      publication,
      isColor: str2bool(jsFormData.isColor),
      documents,
      responsible,
      data: {
        ...jsFormData.data,
        relatedAppsRegs,
        priority,
        standardCharacters: str2bool(jsFormData.standardCharacters),

        postalAddress: {
          ...jsFormData.data.postalAddress,
          fis: true,

          country: jsFormData.data?.postalAddress?.country?.abbreviation,
        },
        ...!isEmpty(jsFormData.data?.attorney) ? {
          attorney: {
            ...jsFormData.data.attorney,
            ...!isEmpty(jsFormData.data.attorney?.postalAddress) ? {
              postalAddress: {
                ...jsFormData.data.attorney.postalAddress,
                country: jsFormData.data.attorney.postalAddress?.country?.abbreviation,
              },
            } : {},
          },
        } : {},
        ...!isEmpty(jsFormData.usersId) ? {
          idAttorney: jsFormData.usersId?.id,
        } : {},
      },
    };
    delete data.person;
    delete data.applicantClients;
    delete data.applicantOwners;
    delete data.applicantAuthor;
    delete data.usersId;
    delete data.users;
    delete data.mailingAddress;
    delete data.jurisdictionsInput;
    if (params.hashId) {
      dispatch(proposalAsyncActions.putProposalAsync({ id: params.hashId, params: data }));
    } else {
      dispatch(proposalAsyncActions.postProposalAsync(data));
    }
  };
  const handleSign = () => {
    dispatch(helpersActions.setPermissionSign(true));
    handleSubmit();
  };
  return (
    <>
      {pendingGetProposalById ? (
        <Loader />
      ) : (
        <>
          {!isEmpty(params.hashId) && accessEdit && (
            <Grid item xs={12} mb={6}>
              {!isEmpty(applicationNumberValues) && (
                <Button
                  mr={4}
                  variant="outlined"
                  color="primary"
                  onClick={handleCompare}
                >
                  {t('COMPARE WITH THE REGISTRY')}
                </Button>
              )}
            </Grid>
          )}

          {isModalOpenRegistry && (
            <CompareWithRegistryModal
              pendingRegistryByNumber={pendingRegistryByNumber}
              getRegistryByNumber={registryByNumber}
              getProposalById={getProposalById}
              handleChangeProposal={handleChangeProposal}
              handleCleanup={handleCleanup}
            />
          )}

          {isModalOpenConfirm && (
            <ConfirmModal
              buttonSendText=""
              buttonCancelText="CLEARLY"
              description="You must enter an application number!"
            />
          )}

          <Form onSubmit={handleSubmit(handleSubmits)}>
            <CardBasicInformation
              pendingRegistryByNumber={pendingRegistryByNumber}
              pending={pending}
              entityName="industrial"
              formFields={formFields}
              formName={formName}
              fieldValueByNumber={fieldValueByNumber}
              setFieldValueByNumber={setFieldValueByNumber}
              labelFieldTitle="Industrial design name"
              disabled={!accessEdit}
            />

            <CardPublicationAndAttributes
              pending={pending}
              formFields={formFields}
              disabled={!accessEdit}
            />

            <DragImages
              addImageAction={addImageAction}
              items={imagesVariant}
              addVariantAction={addVariantAction}
              setImageMove={setImageMove}
              setImageVariantRemove={setImageVariantRemove}
              setImageRemove={setImageRemove}
              setImageName={setImageName}
              disabled={!accessEdit}
            >
              {accessEdit && (
                <Grid item xs={12}>
                  <ButtonSubmit pending={pending} />
                </Grid>
              )}
            </DragImages>

            <CardMaintainingAndCost
              entity="industrial"
              getProposalById={getProposalById}
              formFields={formFields}
              pending={pending}
              match={match}
              disabled={!accessEdit}
            />

            <CardClient
              name={formFields.clients}
              formName={formName}
              pending={pending}
              disabled={!accessEdit}
            />

            <CardApplicantsOwnersAuthor
              formName={formName}
              pending={pending}
              entityName="industrial"
              disabled={!accessEdit}
            />

            <CardRepresentative
              formName={formName}
              getProposalById={getProposalById}
              postalAddressValues={postalAddressValues}
              formFields={formFields}
              pending={pending}
              match={match}
              disabled={!accessEdit}
            />

            <CardMailingAddress
              formFields={formFields}
              pending={pending}
              formName={formName}
              disabled={!accessEdit}
            />

            <CardPriority
              priorityType="first"
              formFields={formFields}
              formName={formName}
              priorityTypeValues={priorityTypeValues}
              pending={pending}
              disabled={!accessEdit}
            />

            <CardInitialDocuments
              formName={formName}
              pending={pending}
              fieldClient={fieldClient}
              disabled={!accessEdit}
            />

            <Card mb={6}>
              <CardContent>
                <Typography variant="h2" gutterBottom>
                  {t('Related objects')}
                </Typography>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={8}>

                    <Field
                      component={Checkbox}
                      margin="none"
                      type="checkbox"
                      name={formFields.dataRelatedAppsRegsParentNumDateIsCheck}
                      label={t('Submission to the institution of the preliminary application from which this application is selected')}
                      disabled={!accessEdit}
                    />
                  </Grid>
                  {parentNumDateIsCheck && (
                    <Grid item xs={12} md={6}>
                      <Field
                        name={formFields.dataRelatedAppsRegsParentNumDate}
                        id={formFields.dataRelatedAppsRegsParentNumDate}
                        label={t('Numbers and dates of related applications')}
                        margin="none"
                        variant="standard"
                        my={1}
                        fullWidth
                        type="text"
                        component={RenderTextField}
                        disabled={!accessEdit}
                      />
                    </Grid>

                  )}

                  <Grid item xs={12} md={8}>
                    <Field
                      component={Checkbox}
                      margin="none"
                      type="checkbox"
                      name={formFields.dataRelatedAppsRegsVariantNumDateIsCheck}
                      label={t('Submission to the institution of an application or registration of an industrial design which is a variant of this design')}
                      disabled={!accessEdit}
                    />

                  </Grid>
                  {variantNumDateIsCheck
                  && (
                  <Grid item xs={12} md={6}>
                    <Field
                      name={formFields.dataRelatedAppsRegsVariantNumDate}
                      id={formFields.dataRelatedAppsRegsVariantNumDate}
                      label={t('Numbers and dates of related certificates')}
                      margin="none"
                      variant="standard"
                      my={1}
                      fullWidth
                      type="text"
                      component={RenderTextField}
                      disabled={!accessEdit}
                    />
                  </Grid>
                  )}

                  {accessEdit && (
                    <Grid item xs={12}>
                      <ButtonSubmit pending={pending} />
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>

            <CardFooter
              pending={pending}
              formFields={formFields}
              formName={formName}
              tagsValues={tagsValues}
              getProposalById={getProposalById}
              match={match}
              objectType="2"
              disabled={!accessEdit}
            />

            {!isEmpty(signList.toJS()) && (
              <Card mb={6}>
                <CardContent>
                  <Typography variant="h2" gutterBottom>
                    {t('Signed')}
                  </Typography>
                  {map(signList.toJS(), (sign, index) => (
                    <Typography key={index} gutterBottom>
                      <Box
                        component="span"
                        fontWeight="fontWeightBold"
                      >
                        {sign.signer || sign.users.name}
                        ,
                        {sign.csk}
                      </Box>
                      {' '}
                      (
                      {sign.date}
                      )
                    </Typography>
                  ))}
                </CardContent>
              </Card>
            )}

          </Form>

          {accessEdit && (
            <Grid item xs={12}>
              {isEmpty(signList.toJS()) && (
                <>
                  <Button
                    disabled={isNil(params.hashId)}
                    mr={2}
                    variant="contained"
                    color="primary"
                    onClick={handleSign}
                  >
                    {t('SIGN')}
                  </Button>
                  <Sign
                    type="industrial"
                    files={map(filter(uploadFiles.toJS(), 'fileId'), 'fileId')}
                    dataAllFiles={[{
                      id: params.hashId,
                      files: map(filter(uploadFiles.toJS(), 'fileId'), 'fileId'),
                    }]}
                  />
                </>
              )}

              <Send
                disabledButton={isNil(params.hashId) || isEmpty(signList.toJS())
                  || getProposalById.toJS()?.status > 2}
                type="industrial"
                entityIds={[params.hashId]}
              />
            </Grid>
          )}
        </>
      )}
    </>
  );
}

IndustrialDesignAdd.displayName = 'IndustrialDesignAdd';

IndustrialDesignAdd.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pendingGetProposalById: PropTypes.bool.isRequired,
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  newApplicantsList: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  signList: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingPutProposal: PropTypes.bool.isRequired,
  uploadFiles: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  match: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  applicationNumberValues: PropTypes.string,
  registrationNumberValue: PropTypes.string,
  priorityTypeValues: PropTypes.string,
  newOwnersList: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  newAuthorsList: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingPostProposal: PropTypes.bool,
  parentNumDateIsCheck: PropTypes.bool,
  variantNumDateIsCheck: PropTypes.bool,
  pendingRegistryByNumber: PropTypes.bool,
  isModalOpenConfirm: PropTypes.bool,
  isModalOpenRegistry: PropTypes.bool,
  imagesVariant: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  getNextNumber: PropTypes.string.isRequired,
  fieldClient: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  registryByNumber: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  getProposalById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  tagsValues: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  postalAddressValues: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  listResponsible: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

IndustrialDesignAdd.defaultProps = {
  match: {},
  fieldClient: {},
  postalAddressValues: {},
  applicationNumberValues: '',
  registrationNumberValue: '',
  priorityTypeValues: '',
  tagsValues: [],
  isModalOpenRegistry: false,
  isModalOpenConfirm: false,
  parentNumDateIsCheck: false,
  pendingPostProposal: false,
  pendingRegistryByNumber: false,
  variantNumDateIsCheck: false,
};

function mapStateToProps(state) {
  return {
    // industrialDesignsProposal
    getNextNumber: selectors.industrialDesignsProposal.getNextNumber(state),
    signList: selectors.helpers.signList(state),
    pendingPutProposal: selectors.industrialDesignsProposal.pendingPutProposal(state),
    pendingGetProposalById: selectors.industrialDesignsProposal.pendingGetProposalById(state),
    uploadFiles: selectors.uploadFile.uploadFiles(state),
    imagesVariant: selectors.industrialDesignsProposal.getImagesVariant(state),
    pendingPostProposal: selectors.industrialDesignsProposal.pendingPostProposal(state),
    pendingRegistryByNumber: selectors.industrialDesignsProposal.getPendingRegistryByNumber(state),
    registryByNumber: selectors.industrialDesignsProposal.getRegistryByNumber(state),
    getProposalById: selectors.industrialDesignsProposal.getProposalById(state),

    // applicants
    newApplicantsList: selectors.applicants.getApplicantNewList(state),
    newOwnersList: selectors.applicants.getOwnersNewList(state),
    newAuthorsList: selectors.applicants.getAuthorsNewList(state),
    listResponsible: selectors.employees.listResponsible(state),

    // fields
    parentNumDateIsCheck: selectors.form.getFormValues(state, formName).getIn(['data', 'relatedAppsRegs', 'parentNumDateIsCheck']),
    variantNumDateIsCheck: selectors.form.getFormValues(state, formName).getIn(['data', 'relatedAppsRegs', 'variantNumDateIsCheck']),
    priorityTypeValues: selectors.form.getFormValues(state, formName).getIn(['data', 'priority', 'type']),
    postalAddressValues: selectors.form.getFormValues(state, formName).getIn(['data', 'postalAddress']),
    applicationNumberValues: selectors.form.getFormValues(
      state, formName,
    ).get(formFields.applicationNumber),
    registrationNumberValue: selectors.form.getFormValues(
      state, formName,
    ).get(formFields.registrationNumber),
    fieldClient: selectors.form.getFormValues(state, formName).get(formFields.clients),
    tagsValues: selectors.form.getFormValues(state, formName).get('tags'),

    isModalOpenRegistry: selectors.helpers.isModalOpenRegistry(state),
    isModalOpenConfirm: selectors.helpers.isModalOpen(state),
  };
}

function mapDispatchToProps() {
  return {};
}

export default compose(
  reduxForm({
    form: formName,
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(memo(IndustrialDesignAdd));
