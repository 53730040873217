import React from 'react';
import { Trans } from 'react-i18next';
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  startOfYear,
  endOfYear,
  addYears,
  addWeeks,
  isSameDay,
  differenceInCalendarDays,
} from 'date-fns';

// lodash
import map from 'lodash/map';

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addWeeks(new Date(), -1)),
  endOfLastWeek: endOfWeek(addWeeks(new Date(), -1)),
  startOfNextWeek: startOfWeek(addWeeks(new Date(), 1)),
  endOfNextWeek: endOfWeek(addWeeks(new Date(), 1)),

  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfTomorrow: startOfDay(addDays(new Date(), 1)),
  endOfTomorrow: endOfDay(addDays(new Date(), 1)),

  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfNextMonth: startOfMonth(addMonths(new Date(), 1)),
  endOfNextMonth: endOfMonth(addMonths(new Date(), 1)),

  startOfYear: startOfYear(new Date()),
  endOfYear: endOfYear(new Date()),
  startOfLastYear: startOfYear(addYears(new Date(), -1)),
  endOfLastYear: endOfYear(addYears(new Date(), -1)),
  startOfNextYear: startOfYear(addYears(new Date(), 1)),
  endOfNextYear: endOfYear(addYears(new Date(), 1)),
};

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate)
      && isSameDay(range.endDate, definedRange.endDate)
    );
  },
};

export function dataRangeStaticRanges(ranges) {
  return map(ranges, (range) => ({ ...staticRangeHandler, ...range }));
}

export const defaultStaticRanges = dataRangeStaticRanges([
  {
    label: <Trans>Today</Trans>,
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: <Trans>Yesterday</Trans>,
    range: () => ({
      startDate: defineds.startOfYesterday,
      endDate: defineds.endOfYesterday,
    }),
  },
  {
    label: <Trans>Tomorrow</Trans>,
    range: () => ({
      startDate: defineds.startOfTomorrow,
      endDate: defineds.endOfTomorrow,
    }),
  },
  {
    label: <Trans>This week</Trans>,
    range: () => ({
      startDate: defineds.startOfWeek,
      endDate: defineds.endOfWeek,
    }),
  },
  {
    label: <Trans>Last week</Trans>,
    range: () => ({
      startDate: defineds.startOfLastWeek,
      endDate: defineds.endOfLastWeek,
    }),
  },
  {
    label: <Trans>Next week</Trans>,
    range: () => ({
      startDate: defineds.startOfNextWeek,
      endDate: defineds.endOfNextWeek,
    }),
  },
  {
    label: <Trans>This month</Trans>,
    range: () => ({
      startDate: defineds.startOfMonth,
      endDate: defineds.endOfMonth,
    }),
  },
  {
    label: <Trans>Last month</Trans>,
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth,
    }),
  },
  {
    label: <Trans>Next month</Trans>,
    range: () => ({
      startDate: defineds.startOfNextMonth,
      endDate: defineds.endOfNextMonth,
    }),
  },
  {
    label: <Trans>This year</Trans>,
    range: () => ({
      startDate: defineds.startOfYear,
      endDate: defineds.endOfYear,
    }),
  },
  {
    label: <Trans>Last year</Trans>,
    range: () => ({
      startDate: defineds.startOfLastYear,
      endDate: defineds.endOfLastYear,
    }),
  },
  {
    label: <Trans>Next year</Trans>,
    range: () => ({
      startDate: defineds.startOfNextYear,
      endDate: defineds.endOfNextYear,
    }),
  },
]);

export const defaultInputRanges = [
  {
    label: <Trans>days until today</Trans>,
    range(value) {
      return {
        startDate: addDays(defineds.startOfToday, (Math.max(Number(value), 1) - 1) * -1),
        endDate: defineds.endOfToday,
      };
    },
    getCurrentValue(range) {
      if (!isSameDay(range.endDate, defineds.endOfToday)) {
        return '-';
      }
      if (!range.startDate) {
        return '∞';
      }
      return differenceInCalendarDays(defineds.endOfToday, range.startDate) + 1;
    },
  },
  {
    label: <Trans>days from today</Trans>,
    range(value) {
      const today = new Date();
      return {
        startDate: today,
        endDate: addDays(today, Math.max(Number(value), 1) - 1),
      };
    },
    getCurrentValue(range) {
      if (!isSameDay(range.startDate, defineds.startOfToday)) {
        return '-';
      }
      if (!range.endDate) {
        return '∞';
      }
      return differenceInCalendarDays(range.endDate, defineds.startOfToday) + 1;
    },
  },
];
