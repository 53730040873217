// Core
import { List, Map } from 'immutable';

// Instruments
import { isNil } from 'lodash';
import types from './types';
import { pageSizes } from '../../../config/globalConfig';
import globalStateKeys from '../../../config/globalStateKeys';

const initialState = Map({
  data: Map({
    items: List(),
    currentPage: 0,
    filters: List(),
    pending: false,
    sorting: List([]),
    totalCount: 0,
    pageSize: pageSizes[0],
    reload: false,
    columnWidths: List(),
    columnOrder: List(),
    hiddenColumnNames: List(),
  }),
  autocompleteOptions: Map({
    items: Map(),
    pending: false,
  }),
  delete: Map({
    pendingDelete: false,
    id: '',
  }),
  pendingPost: false,
  isModalOpen: false,
  pendingPostCurrencyCalc: false,
  currencyCalc: Map(),
});

export const stateKeys = Object.freeze({
  controller: 'currencies',
  pendingPostCurrencyCalc: 'pendingPostCurrencyCalc',
  currencyCalc: 'currencyCalc',
});

export const currenciesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_CURRENCIES_DATA: {
      const { items, totalCount, pending } = payload;
      if (pending) {
        return state.setIn([globalStateKeys.data, globalStateKeys.pending], pending);
      }
      return state
        .setIn([globalStateKeys.data, globalStateKeys.pending], pending)
        .setIn([globalStateKeys.data, globalStateKeys.items], List(items))
        .setIn([globalStateKeys.data, globalStateKeys.totalCount], Number(totalCount));
    }
    case types.SET_CURRENCY_PENDING_POST: {
      return state.setIn([globalStateKeys.pendingPost], payload);
    }
    case types.SET_CURRENCIES_RELOAD: {
      return state.setIn([globalStateKeys.data, globalStateKeys.reload], payload);
    }
    case types.SET_CURRENCIES_CURRENT_PAGE: {
      return state.setIn([globalStateKeys.data, globalStateKeys.currentPage], payload);
    }
    case types.SET_CURRENCIES_FILTERS: {
      const filters = payload.filter((item) => !isNil(item.value));
      return state.setIn([globalStateKeys.data, globalStateKeys.filters], List(filters));
    }
    case types.SET_CURRENCIES_SORTING: {
      return state.setIn([globalStateKeys.data, globalStateKeys.sorting], List(payload));
    }
    case types.SET_CURRENCIES_COLUMN_WIDTHS: {
      return state.setIn([globalStateKeys.data, globalStateKeys.columnWidths], List(payload));
    }
    case types.SET_CURRENCIES_COLUMN_ORDER: {
      return state.setIn([globalStateKeys.data, globalStateKeys.columnOrder], List(payload));
    }
    case types.SET_CURRENCIES_HIDDEN_COLUMN_NAMES: {
      return state.setIn([globalStateKeys.data, globalStateKeys.hiddenColumnNames], List(payload));
    }
    case types.SET_CURRENCIES_PAGE_SIZE: {
      return state.setIn([globalStateKeys.data, globalStateKeys.pageSize], payload);
    }
    case types.SET_CURRENCIES_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, sorting = [],
        columnWidths, columnOrder, hiddenColumnNames,
      } = payload;
      const reload = true;
      const currentSorting = sorting.length
        ? sorting
        : initialState.getIn([globalStateKeys.data, globalStateKeys.sorting]);
      return state
        .setIn([globalStateKeys.data, globalStateKeys.currentPage], currentPage)
        .setIn([globalStateKeys.data, globalStateKeys.filters], List(filters))
        .setIn([globalStateKeys.data, globalStateKeys.pageSize], pageSize)
        .setIn([globalStateKeys.data, globalStateKeys.totalCount], totalCount)
        .setIn([globalStateKeys.data, globalStateKeys.reload], reload)
        .setIn([globalStateKeys.data, globalStateKeys.columnWidths], List(columnWidths))
        .setIn([globalStateKeys.data, globalStateKeys.columnOrder], List(columnOrder))
        .setIn([globalStateKeys.data, globalStateKeys.hiddenColumnNames], List(hiddenColumnNames))
        .setIn([globalStateKeys.data, globalStateKeys.sorting], List(currentSorting));
    }
    case types.SET_DELETE_CURRENCY: {
      return state.mergeIn([globalStateKeys.delete], payload);
    }
    case types.SET_CURRENCIES_AUTOCOMPLETE_OPTIONS: {
      return state.mergeIn([
        globalStateKeys.autocompleteOptions,
        globalStateKeys.items,
      ], Map(payload));
    }
    case types.SET_PENDING_CURRENCIES_AUTOCOMPLETE_OPTIONS: {
      return state.setIn([
        globalStateKeys.autocompleteOptions,
        globalStateKeys.pending,
      ], payload);
    }
    case types.SET_CURRENCIES_IS_MODAL_OPEN: {
      return state.setIn([globalStateKeys.isModalOpen], payload);
    }
    case types.SET_PENDING_CURRENCY_CALC: {
      return state.setIn([stateKeys.pendingPostCurrencyCalc], payload);
    }
    case types.SET_CURRENCY_CALC: {
      return state.setIn([stateKeys.currencyCalc], Map(payload));
    }
    default:
      return state;
  }
};
