// Core
import { apply, select, put } from 'redux-saga/effects';

// Engine
import isNil from 'lodash/isNil';
import localData from '../../../../../../config/localData';
import selectors from '../../../../../../config/selectors';
import types from '../../types';
import actions from '../../action';

export function* callSetToLocalDataWorker({ type, payload }) {
  const pathname = yield select(selectors.router.pathname);
  const data = {
    currentPage: yield select(selectors.pricesTariff.currentPage),
    pageSize: yield select(selectors.pricesTariff.pageSize),
    totalCount: yield select(selectors.pricesTariff.totalCount),
    sorting: yield select(selectors.pricesTariff.sorting),
    filters: yield select(selectors.pricesTariff.filters),
  };

  let currentData;
  yield put(actions.setReload(true));
  switch (type) {
    case types.SET_PRICES_TARIFF_TABLE_CURRENT_PAGE: {
      currentData = { currentPage: payload };
      break;
    }
    case types.SET_PRICES_TARIFF_TABLE_PAGE_SIZE: {
      currentData = { pageSize: payload };
      break;
    }
    case types.SET_TARIFF_TABLE_FILTER: {
      const { currentPage } = data;
      const filters = payload.filter(({ value }) => !isNil(value));
      if (currentPage) {
        yield put(actions.setCurrentPage(0));
      }
      currentData = { filters };
      break;
    }
    case types.SET_TARIFF_TABLE_SORTING: {
      currentData = { sorting: payload };
      break;
    }
    default: {
      break;
    }
  }

  data.currentPage = yield select(selectors.pricesTariff.currentPage);
  yield apply(localData, localData.setItem, [pathname, { ...data, ...currentData }]);
}
