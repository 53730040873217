// core
import React, {
  useEffect, useState, useMemo, memo,
} from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';

// lodash
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

// config
import selectors from '../../engine/config/selectors';
import accessList from '../../engine/config/accessList';

// parts
import Client from '../Company/Client/Client';
import Employee from '../Company/Employee/Employee';

// actions
import clientsActions from '../../engine/core/company/clients/action';
import employeesActions from '../../engine/core/company/employees/action';

// hooks
import { useAccessList } from '../../ui/_hooks/useAccessList';

function User(props) {
  const {
    getUserInfo,
  } = props;
  const dispatch = useDispatch();
  const accessProfileClient = useAccessList(accessList.profileClient);
  const [readyClearData, setReadyClearData] = useState(false);
  const clearData = {
    data: {},
  };

  const getUserId = useMemo(() => (!isEmpty(getUserInfo) ? {
    match: {
      params: { hashId: getUserInfo.id },
    },
  } : {}), [getUserInfo]);

  useEffect(() => () => {
    if (!readyClearData) {
      setReadyClearData(true);
      if (accessProfileClient) {
        dispatch(clientsActions.setClientById(clearData));
      } else {
        dispatch(employeesActions.setEmployeeById(clearData));
      }
    }
  });

  return accessProfileClient
    ? <Client {...getUserId} isUserProfile />
    : <Employee {...getUserId} isUserProfile />;
}

User.displayName = 'User';

User.propTypes = {
  getUserInfo: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.getUserInfo, nextProps.getUserInfo);
}

function mapStateToProps(state) {
  return {
    getUserInfo: selectors.user.userInfo(state),
  };
}

export default compose(
  connect(mapStateToProps, null),
)(memo(User, areEqual));
