// core
import React from 'react';
import { Field } from 'redux-form/lib/immutable';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

// parts
import selectors from '../../../../../engine/config/selectors';

// helpers
import { fields } from '../helper/form';
import Select from '../../../../../ui/Form/Select';

function PaymentReceivers(props) {
  const { data, disabled } = props;
  const { t } = useTranslation();

  return (
    <Field
      displayEmpty
      textEmpty={t('Not selected')}
      name={fields.paymentReceivers}
      id={fields.paymentReceivers}
      label={t('Payment Receivers')}
      margin="normal"
      labelId="role"
      items={data.toJS().items}
      variant="standard"
      my={2}
      fullWidth
      type="text"
      component={Select}
      disabled={disabled}
    />
  );
}

PaymentReceivers.displayName = 'PaymentReceivers';

PaymentReceivers.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  disabled: PropTypes.bool,
};

PaymentReceivers.defaultProps = {
  disabled: false,
};

function mapStateToProps(state) {
  return {
    data: selectors.paymentReceivers.paymentReceiversData(state),
  };
}

export default compose(
  connect(mapStateToProps, null),
)(PaymentReceivers);
