// Core
import { all, call, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetCategoriesWorker,
  callPutCategoryWorker,
  callDeleteCategoryWorker,
  callPostCategoryWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callGetCategoryByIdWorker,
} from './workers';
import types from '../types';

function* watchGetCategories() {
  yield takeEvery(asyncTypes.GET_PRICES_CATEGORIES_ASYNC, callGetCategoriesWorker);
}
function* watchPutCategory() {
  yield takeEvery(asyncTypes.PUT_PRICES_CATEGORY_BY_ID_ASYNC, callPutCategoryWorker);
}
function* watchDeleteCategory() {
  yield takeEvery(asyncTypes.DELETE_PRICES_CATEGORY_ASYNC, callDeleteCategoryWorker);
}
function* watchPostCategory() {
  yield takeEvery(asyncTypes.POST_PRICES_CATEGORY_ASYNC, callPostCategoryWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_PRICES_CATEGORIES_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchGetCategoryById() {
  yield takeEvery(asyncTypes.GET_PRICES_CATEGORY_BY_ID_ASYNC, callGetCategoryByIdWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_PRICES_CATEGORIES_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_PRICES_CATEGORIES_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_PRICES_CATEGORIES_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_PRICES_CATEGORIES_SORTING, callSetToLocalDataWorker);
}
function* watchSetColumnWidths() {
  yield takeEvery(types.SET_PRICES_CATEGORIES_COLUMN_WIDTHS, callSetToLocalDataWorker);
}
function* watchSetColumnOrder() {
  yield takeEvery(types.SET_PRICES_CATEGORIES_COLUMN_ORDER, callSetToLocalDataWorker);
}
function* watchSetHiddenColumnNames() {
  yield takeEvery(types.SET_PRICES_CATEGORIES_HIDDEN_COLUMN_NAMES, callSetToLocalDataWorker);
}

export function* watchersPricesCategories() {
  yield all([
    call(watchGetCategories),
    call(watchPutCategory),
    call(watchDeleteCategory),
    call(watchPostCategory),
    call(watchGetInitialState),
    call(watchSetCurrentPage),
    call(watchSetPageSize),
    call(watchSetFilters),
    call(watchSetSorting),
    call(watchSetColumnWidths),
    call(watchSetColumnOrder),
    call(watchSetHiddenColumnNames),
    call(watchGetCategoryById),
  ]);
}
