// Core
import { combineReducers } from 'redux-immutablejs';
import { reducer as formReducer } from 'redux-form/immutable';
import { connectRouter } from 'connected-react-router/immutable';

// Reducer
import { userReducer as user } from '../core/user/reducers';
import { employeesReducer as employees } from '../core/company/employees/reducer';
import { uploadFileReducer as uploadFile } from '../core/uploadFile/reducer';
import { employeesReducer as employeesTable } from '../core/company/employees/table/reducer';
import { paymentsReducer as payments } from '../core/company/payments/reducer';
import { paymentAllocationsReducer as paymentAllocations } from '../core/paymentAllocations/reducer';
import { ordersReducer as orders } from '../core/company/orders/reducer';
import { integrationReducer as integration } from '../core/company/integration/reducer';
import { currenciesReducer as currencies } from '../core/currencies/currenciesList/reducer';
import { currencyRatesReducer as currencyRates } from '../core/currencies/currencyRates/reducer';
import { chargesReducer as charges } from '../core/charges/reducer';
import { importObjectsReducer as importObjects } from '../core/importObjects/reducer';
import { pendingActionsReducer as pendingActions } from '../core/pendingActions/reducer';
import { pendingActionsTableReducer as pendingActionsTable } from '../core/pendingActions/table/reducer';
import { companyReducer as companies } from '../core/company/profile/reducer';
import { worksReducer as works } from '../core/works/reducer';
import { pricesCategoryReducer as pricesCategory } from '../core/prices/category/reducer';
import { emailReducer as email } from '../core/email/email/reducer';
import { emailTemplateReducer as emailTemplate } from '../core/email/emailTemplate/reducer';
import { pricesTariffsReducer as pricesTariffs } from '../core/prices/tariffs/reducer';
import { pricesTariffReducer as pricesTariff } from '../core/prices/tariffs/tariff/reducer';
import { pricesWorksReducer as pricesWorks } from '../core/prices/works/reducer';
import { documentTypesReducer as documentTypes } from '../core/documentTypes/reducer';
import { documentTypesCategoryReducer as documentTypesCategory } from '../core/documentTypes/category/reducer';
import { tagsReducer as tags } from '../core/tags/reducer';
import { notificationsReducer as notifications } from '../core/notifications/reducer';
import { jurisdictionsReducer as jurisdiction } from '../core/jurisdictions/reducer';
import { systemMessageReducer as systemMessage } from '../core/systemMessage/reducer';
import { loggingReducer as logging } from '../core/logging/reducer';
import { usersFiltersReducer as usersFilters } from '../core/usersFilters/reducer';
import { taxesReducer as taxes } from '../core/taxes/reducer';
import { clientsReducer as clients } from '../core/company/clients/reducer';
import { clientsReducer as clientsTable } from '../core/company/clients/table/reducer';
import { departmentDocumentsReducer as departmentDocuments } from '../core/departmentDocuments/reducer';
import { departmentDocumentsModalReducer as departmentDocumentsModal } from '../core/departmentDocuments/documentsModal/reducer';
import { documentTemplatesReducer as documentTemplates } from '../core/documentTemplates/reducer';
import { proposalDocumentsOutReducer as proposalDocumentsOut } from '../core/tradeMarks/proposal/documents/out/reducer';
import { proposalDocumentsInReducer as proposalDocumentsIn } from '../core/tradeMarks/proposal/documents/input/reducer';
import { tradeMarksProposalReducer as tradeMarksProposal } from '../core/tradeMarks/proposal/reducer';
import { madridCaseReducer as madridCase } from '../core/tradeMarks/madridCase/reducer';
import { tradeMarksSearchesReducer as tradeMarksSearches } from '../core/tradeMarks/searches/reducer';
import { tradeMarksReboundsReducer as tradeMarksRebounds } from '../core/tradeMarks/rebounds/reducer';
import { tradeMarksMktuTemplatesReducer as tradeMarksMktuTemplates } from '../core/tradeMarks/mktuTemplates/reducer';
import { tradeMarksMonitoringReducer as tradeMarksMonitoring } from '../core/tradeMarks/monitoring/reducer';
import { tradeMarksCalculationsReducer as tradeMarksCalculations } from '../core/tradeMarks/calculations/reducer';
import { utilityModelsProposalReducer as utilityModelsProposal } from '../core/utilityModels/reducer';
import { inventionsProposalReducer as inventionsProposal } from '../core/inventions/reducer';
import { industrialDesignsProposalReducer as industrialDesignsProposal } from '../core/industrialDesigns/reducer';
import { fieldsSettingReducer as fieldsSetting } from '../core/fieldsSettings/reducer';
import { paymentReceiversReducer as paymentReceivers } from '../core/paymentReceivers/reducer';
import { applicantsReducer as applicants } from '../core/applicants/reducer';
import { receivingReducer as receiving } from '../core/receiving/reducer';
import { sendingReducer as sending } from '../core/sending/reducer';
import { settingsReducer as settings } from '../core/settings/reducer';
import { permissionsGroupsReducer as permissionsGroups } from '../core/permissionsGroups/reducer';
import { helpersReducer as helpers } from '../core/helpers/reducer';
import { productsReducer as products } from '../core/products/reducer';
import { productCategoryReducer as productCategory } from '../core/products/productCategory/reducer';
import { dashboardReducer as dashboard } from '../core/dashboard/reducer';
import { actionTypesReducer as actionTypes } from '../core/actionTypes/reducer';

const rootReducer = (history) => combineReducers({
  form: formReducer,
  router: connectRouter(history),
  user,
  pendingActions,
  sending,
  settings,
  uploadFile,
  pendingActionsTable,
  employees,
  pricesTariff,
  payments,
  receiving,
  orders,
  integration,
  currencies,
  currencyRates,
  jurisdiction,
  logging,
  taxes,
  charges,
  employeesTable,
  companies,
  clients,
  works,
  paymentAllocations,
  pricesCategory,
  pricesTariffs,
  pricesWorks,
  documentTypes,
  documentTypesCategory,
  emailTemplate,
  email,
  tags,
  notifications,
  importObjects,
  clientsTable,
  departmentDocuments,
  departmentDocumentsModal,
  documentTemplates,
  proposalDocumentsOut,
  proposalDocumentsIn,
  utilityModelsProposal,
  inventionsProposal,
  tradeMarksCalculations,
  tradeMarksProposal,
  tradeMarksSearches,
  tradeMarksRebounds,
  tradeMarksMktuTemplates,
  tradeMarksMonitoring,
  industrialDesignsProposal,
  fieldsSetting,
  paymentReceivers,
  applicants,
  helpers,
  systemMessage,
  usersFilters,
  permissionsGroups,
  products,
  productCategory,
  dashboard,
  madridCase,
  actionTypes,
});

export { rootReducer };
