// Core
import { all, call } from 'redux-saga/effects';

// Instruments
import { watchersUser } from '../core/user/saga/watchers';
import { watchersPendingActions } from '../core/pendingActions/saga/watchers';
import { watchersUploadFile } from '../core/uploadFile/saga/watchers';
import { watchersPendingActionsTable } from '../core/pendingActions/table/saga/watchers';
import { watchersDepartmentDocumentsTable } from '../core/departmentDocuments/saga/watchers';
import { watchersDepartmentDocumentsModalTable } from '../core/departmentDocuments/documentsModal/saga/watchers';
import { watchersDocumentTemplatesTable } from '../core/documentTemplates/saga/watchers';
import { watchersProposalDocumentsOutTable } from '../core/tradeMarks/proposal/documents/out/saga/watchers';
import { watchersProposalDocumentsInTable } from '../core/tradeMarks/proposal/documents/input/saga/watchers';
import { watchersEmployees } from '../core/company/employees/saga/watchers';
import { watchersIntegrations } from '../core/company/integration/saga/watchers';
import { watchersCurrencies } from '../core/currencies/currenciesList/saga/watchers';
import { watchersCurrencyRates } from '../core/currencies/currencyRates/saga/watchers';
import { watchersChargesTable } from '../core/charges/saga/watchers';
import { watchersImportObjects } from '../core/importObjects/saga/watchers';
import { watchersEmployeesTable } from '../core/company/employees/table/saga/watchers';
import { watchersClientsTable } from '../core/company/clients/table/saga/watchers';
import { watchersPaymentsTable } from '../core/company/payments/saga/watchers';
import { watchersPaymentAllocations } from '../core/paymentAllocations/saga/watchers';
import { watchersOrdersTable } from '../core/company/orders/saga/watchers';
import { watchersUI } from '../core/ui/saga/watchers';
import { watchersWorks } from '../core/works/saga/watchers';
import { watchersPricesCategories } from '../core/prices/category/saga/watchers';
import { watchersEmailTable } from '../core/email/email/saga/watchers';
import { watchersEmailTemplateTable } from '../core/email/emailTemplate/saga/watchers';
import { watchersPricesTariffs } from '../core/prices/tariffs/saga/watchers';
import { watchersPricesWorks } from '../core/prices/works/saga/watchers';
import { watchersDocumentTypesTable } from '../core/documentTypes/saga/watchers';
import { watchersDocumentTypesCategoryTable } from '../core/documentTypes/category/saga/watchers';
import { watchersTags } from '../core/tags/saga/watchers';
import { watchersNotificationsTable } from '../core/notifications/saga/watchers';
import { watchersJurisdictions } from '../core/jurisdictions/saga/watchers';
import { watchersSystemMessage } from '../core/systemMessage/saga/watchers';
import { watchersLogging } from '../core/logging/saga/watchers';
import { watchersUsersFilters } from '../core/usersFilters/saga/watchers';
import { watchersTaxes } from '../core/taxes/saga/watchers';
import { watchersCompany } from '../core/company/profile/saga/watchers';
import { watchersClients } from '../core/company/clients/saga/watchers';
import { watchersProposal } from '../core/tradeMarks/proposal/saga/watchers';
import { watchersMadridCase } from '../core/tradeMarks/madridCase/saga/watchers';
import { watchersReboundsTable } from '../core/tradeMarks/rebounds/saga/watchers';
import { watchersMktuTemplatesTable } from '../core/tradeMarks/mktuTemplates/saga/watchers';
import { watchersMonitoringTable } from '../core/tradeMarks/monitoring/saga/watchers';
import { watchersSearchesTable } from '../core/tradeMarks/searches/saga/watchers';
import { watchersCalculationsTable } from '../core/tradeMarks/calculations/saga/watchers';
import { watchersInventionsProposal } from '../core/inventions/saga/watchers';
import { watchersUtilityModelsProposal } from '../core/utilityModels/saga/watchers';
import { watchersIndustrialDesignsProposal } from '../core/industrialDesigns/saga/watchers';
import { watchersFieldsSetting } from '../core/fieldsSettings/saga/watchers';
import { watchersApplicants } from '../core/applicants/saga/watchers';
import { watchersHelpers } from '../core/helpers/saga/watchers';
import { watchersSendingTable } from '../core/sending/saga/watchers';
import { watchersSettingsTable } from '../core/settings/saga/watchers';
import { watchersReceivingTable } from '../core/receiving/saga/watchers';
import { watchersPaymentReceiversTable } from '../core/paymentReceivers/saga/watchers';
import { watchersTariffTable } from '../core/prices/tariffs/tariff/saga/watchers';
import { watchersPermissionsGroups } from '../core/permissionsGroups/saga/watchers';
import { watchersProducts } from '../core/products/saga/watchers';
import { watchersProductCategory } from '../core/products/productCategory/saga/watchers';
import { watchersDashboard } from '../core/dashboard/saga/watchers';
import { watchersActionTypes } from '../core/actionTypes/saga/watchers';

export function* rootSaga() {
  yield all([
    call(watchersUser),
    call(watchersPendingActions),
    call(watchersUploadFile),
    call(watchersDepartmentDocumentsTable),
    call(watchersDepartmentDocumentsModalTable),
    call(watchersDocumentTemplatesTable),
    call(watchersPaymentReceiversTable),
    call(watchersReceivingTable),
    call(watchersProposalDocumentsOutTable),
    call(watchersProposalDocumentsInTable),
    call(watchersPendingActionsTable),
    call(watchersEmployees),
    call(watchersIntegrations),
    call(watchersCurrencies),
    call(watchersCurrencyRates),
    call(watchersTariffTable),
    call(watchersChargesTable),
    call(watchersEmployeesTable),
    call(watchersSendingTable),
    call(watchersSettingsTable),
    call(watchersUI),
    call(watchersPricesCategories),
    call(watchersEmailTable),
    call(watchersEmailTemplateTable),
    call(watchersPricesTariffs),
    call(watchersPricesWorks),
    call(watchersDocumentTypesTable),
    call(watchersDocumentTypesCategoryTable),
    call(watchersTags),
    call(watchersNotificationsTable),
    call(watchersJurisdictions),
    call(watchersLogging),
    call(watchersTaxes),
    call(watchersImportObjects),
    call(watchersCompany),
    call(watchersClients),
    call(watchersWorks),
    call(watchersClientsTable),
    call(watchersPaymentsTable),
    call(watchersPaymentAllocations),
    call(watchersOrdersTable),
    call(watchersProposal),
    call(watchersReboundsTable),
    call(watchersMktuTemplatesTable),
    call(watchersCalculationsTable),
    call(watchersMonitoringTable),
    call(watchersSearchesTable),
    call(watchersUtilityModelsProposal),
    call(watchersInventionsProposal),
    call(watchersIndustrialDesignsProposal),
    call(watchersFieldsSetting),
    call(watchersApplicants),
    call(watchersHelpers),
    call(watchersSystemMessage),
    call(watchersUsersFilters),
    call(watchersPermissionsGroups),
    call(watchersProducts),
    call(watchersProductCategory),
    call(watchersDashboard),
    call(watchersMadridCase),
    call(watchersActionTypes),
  ]);
}
