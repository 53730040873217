// core
import React, { memo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// lodash
import { map } from 'lodash';

// ui
import {
  Tooltip, MenuItem, Menu, Button,
} from '@material-ui/core';

function ButtonMenus(props) {
  const {
    id, label, items, color, toolTipText, disabled,
  } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <Tooltip m={6} title={toolTipText} arrow>
        <Button
          {...color}
          variant="contained"
          aria-controls={label}
          aria-haspopup="true"
          {...!disabled ? {
            onClick: handleClick,
          } : {}}
        >
          {label}
        </Button>
      </Tooltip>
      <Menu
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {map(items, (item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              handleClose();
              item?.handler();
            }}
          >
            {t(item?.label)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

ButtonMenus.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  items: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  color: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  toolTipText: PropTypes.string,
};

ButtonMenus.defaultProps = {
  toolTipText: '',
};

export default memo(ButtonMenus);
