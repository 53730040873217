/* eslint-disable */
export const currenciesOptions = [
	{ "code":"UAH", "label":"Гривня (UAH)", "name":"Гривня", "sign":"₴", },
	{ "code":"USD", "label":"Долар США (USD)", "name":"Долар США", "sign":"$", },
	{ "code":"EUR", "label":"Євро (EUR)", "name":"Євро", "sign":"€", },
    { "code":"AFN", "label":"Афгані (AFN)", "name":"Афгані", "sign":"؋", },
	{ "code":"ALL", "label":"Лек (ALL)", "name":"Лек", "sign":"L", },
	{ "code":"DZD", "label":"Алжирський динар (DZD)", "name":"Алжирський динар", "sign":".د.ج", },
	{ "code":"AOA", "label":"Кванза (AOA)", "name":"Кванза", "sign":"Kz", },
	{ "code":"XCD", "label":"Східнокарибський долар (XCD)", "name":"Східнокарибський долар", "sign":"$", },
	{ "code":"ARS", "label":"Аргентинський песо (ARS)", "name":"Аргентинський песо", "sign":"$", },
	{ "code":"AMD", "label":"Вірменський драм (AMD)", "name":"Вірменський драм", "sign":"դր.", },
	{ "code":"AWG", "label":"Арубський ґульден (AWG)", "name":"Арубський ґульден", "sign":"ƒ", },
	{ "code":"AUD", "label":"Австралійський долар (AUD)", "name":"Австралійський долар", "sign":"$", },
	{ "code":"AZN", "label":"Азербайджанський манат (AZN)", "name":"Азербайджанський манат", "sign":"₼", },
	{ "code":"BSD", "label":"Багамський долар (BSD)", "name":"Багамський долар", "sign":"$", },
	{ "code":"BHD", "label":"Бахрейнський динар (BHD)", "name":"Бахрейнський динар", "sign":".د.ب", },
	{ "code":"BDT", "label":"Така (BDT)", "name":"Така", "sign":"৳", },
	{ "code":"BBD", "label":"Барбадоський долар (BBD)", "name":"Барбадоський долар", "sign":"$", },
	{ "code":"BYN", "label":"Білоруський рубль (BYN)", "name":"Білоруський рубль", "sign":"p.", },
	{ "code":"BZD", "label":"Белізький долар (BZD)", "name":"Белізький долар", "sign":"BZ$", },
	{ "code":"XOF", "label":"КФА франк BCEAO (XOF)", "name":"КФА франк BCEAO", "sign":"CFA", },
	{ "code":"BMD", "label":"Бермудський долар (BMD)", "name":"Бермудський долар", "sign":"$", },
	{ "code":"INR", "label":"Індійська рупія (INR)", "name":"Індійська рупія", "sign":"₹", },
	{ "code":"BTN", "label":"Нгултрум (BTN)", "name":"Нгултрум", "sign":"Nu.", },
	{ "code":"BOB", "label":"Болівіано (BOB)", "name":"Болівіано", "sign":"Bs.", },
	{ "code":"BOV", "label":"Мвдол (BOV)", "name":"Мвдол", "sign":"Bov", },
	{ "code":"BAM", "label":"Конвертована марка (BAM)", "name":"Конвертована марка", "sign":"KM", },
	{ "code":"BWP", "label":"Пула (BWP)", "name":"Пула", "sign":"P", },
	{ "code":"NOK", "label":"Норвезька крона (NOK)", "name":"Норвезька крона", "sign":"kr", },
	{ "code":"BRL", "label":"Бразильський ріал (BRL)", "name":"Бразильський ріал", "sign":"R$", },
	{ "code":"BND", "label":"Брунейський долар (BND)", "name":"Брунейський долар", "sign":"$", },
	{ "code":"BGN", "label":"Болгарський лев (BGN)", "name":"Болгарський лев", "sign":"лв", },
	{ "code":"BIF", "label":"Бурундійський франк (BIF)", "name":"Бурундійський франк", "sign":"FBu", },
	{ "code":"CVE", "label":"Ескудо Кабо-Верде (CVE)", "name":"Ескудо Кабо-Верде", "sign":"esc", },
	{ "code":"KHR", "label":"Рієл (KHR)", "name":"Рієл", "sign":"៛", },
	{ "code":"XAF", "label":"КФА франк BEAC (XAF)", "name":"КФА франк BEAC", "sign":"FCFA", },
	{ "code":"CAD", "label":"Канадський долар (CAD)", "name":"Канадський долар", "sign":"$", },
	{ "code":"KYD", "label":"Долар Кайманових островів (KYD)", "name":"Долар Кайманових островів", "sign":"$", },
	{ "code":"CLP", "label":"Чилійський песо (CLP)", "name":"Чилійський песо", "sign":"$", },
	{ "code":"CNY", "label":"Юань Женьміньбі (CNY)", "name":"Юань Женьміньбі", "sign":"元", },
	{ "code":"COP", "label":"Колумбійський песо (COP)", "name":"Колумбійський песо", "sign":"$", },
	{ "code":"COU", "label":"Унiдад де валор рiал (COU)", "name":"Унiдад де валор рiал", "sign":"COU", },
	{ "code":"KMF", "label":"Коморський франк (KMF)", "name":"Коморський франк", "sign":"CF", },
	{ "code":"CDF", "label":"Конґолезький франк (CDF)", "name":"Конґолезький франк", "sign":"FC", },
	{ "code":"NZD", "label":"Новозеландський долар (NZD)", "name":"Новозеландський долар", "sign":"$", },
	{ "code":"CRC", "label":"Костариканський колон (CRC)", "name":"Костариканський колон", "sign":"₡", },
	{ "code":"HRK", "label":"Хорватська куна (HRK)", "name":"Хорватська куна", "sign":"kn", },
	{ "code":"CUP", "label":"Кубинський песо (CUP)", "name":"Кубинський песо", "sign":"$MN", },
	{ "code":"ANG", "label":"Нідерландський Антильський Гульден (ANG)", "name":"Нідерландський Антильський Гульден", "sign":"ƒ", },
	{ "code":"CZK", "label":"Чеська крона (CZK)", "name":"Чеська крона", "sign":"Kč", },
	{ "code":"DKK", "label":"Данська крона (DKK)", "name":"Данська крона", "sign":"kr", },
	{ "code":"DJF", "label":"Джибутійський франк (DJF)", "name":"Джибутійський франк", "sign":"Fdj", },
	{ "code":"DOP", "label":"Домініканський песо (DOP)", "name":"Домініканський песо", "sign":"RD$", },
	{ "code":"EGP", "label":"Єгипетський фунт (EGP)", "name":"Єгипетський фунт", "sign":"£", },
	{ "code":"SVC", "label":"Сальвадорський колон (SVC)", "name":"Сальвадорський колон", "sign":"$", },
	{ "code":"ERN", "label":"Накфа (ERN)", "name":"Накфа", "sign":"Nkf", },
	{ "code":"SZL", "label":"Ліланджені (SZL)", "name":"Ліланджені", "sign":"L", },
	{ "code":"ETB", "label":"Ефіопський бір (ETB)", "name":"Ефіопський бір", "sign":"Br", },
	{ "code":"FKP", "label":"Фунт Фолклендських островів (FKP)", "name":"Фунт Фолклендських островів", "sign":"£", },
	{ "code":"FJD", "label":"Фіджійський долар (FJD)", "name":"Фіджійський долар", "sign":"$", },
	{ "code":"XPF", "label":"КФП франк (XPF)", "name":"КФП франк", "sign":"₣", },
	{ "code":"GMD", "label":"Даласі (GMD)", "name":"Даласі", "sign":"D", },
	{ "code":"GEL", "label":"Ларі (GEL)", "name":"Ларі", "sign":"GEL", },
	{ "code":"GHS", "label":"Седі (GHS)", "name":"Седі", "sign":"GH₵", },
	{ "code":"GIP", "label":"Гібралтарський фунт (GIP)", "name":"Гібралтарський фунт", "sign":"£", },
	{ "code":"GTQ", "label":"Кетсал (GTQ)", "name":"Кетсал", "sign":"Q", },
	{ "code":"GBP", "label":"Фунт стерлінгів (GBP)", "name":"Фунт стерлінгів", "sign":"£", },
	{ "code":"GNF", "label":"Ґвінейський франк (GNF)", "name":"Ґвінейський франк", "sign":"GFr", },
	{ "code":"GYD", "label":"Ґаянський долар (GYD)", "name":"Ґаянський долар", "sign":"$", },
	{ "code":"HTG", "label":"Гурд (HTG)", "name":"Гурд", "sign":"G", },
	{ "code":"HNL", "label":"Лемпіра (HNL)", "name":"Лемпіра", "sign":"L", },
	{ "code":"HKD", "label":"Долар Гонконґу (HKD)", "name":"Долар Гонконґу", "sign":"$", },
	{ "code":"HUF", "label":"Форинт (HUF)", "name":"Форинт", "sign":"Ft", },
	{ "code":"ISK", "label":"Ісландська крона (ISK)", "name":"Ісландська крона", "sign":"kr", },
	{ "code":"IDR", "label":"Рупія (IDR)", "name":"Рупія", "sign":"Rp", },
	{ "code":"XDR", "label":"СДР (Спеціальні права запозичення) (XDR)", "name":"СДР (Спеціальні права запозичення)", "sign":"SDR", },
	{ "code":"IRR", "label":"Іранський ріал (IRR)", "name":"Іранський ріал", "sign":"﷼", },
	{ "code":"IQD", "label":"Іракський динар (IQD)", "name":"Іракський динар", "sign":".د.ع", },
	{ "code":"ILS", "label":"Новий ізраїльський шекель (ILS)", "name":"Новий ізраїльський шекель", "sign":"₪", },
	{ "code":"JMD", "label":"Ямайський долар (JMD)", "name":"Ямайський долар", "sign":"J$", },
	{ "code":"JPY", "label":"Єна (JPY)", "name":"Єна", "sign":"¥", },
	{ "code":"JOD", "label":"Йорданський динар (JOD)", "name":"Йорданський динар", "sign":".د.إ", },
	{ "code":"KZT", "label":"Тенге (KZT)", "name":"Тенге", "sign":"₸", },
	{ "code":"KES", "label":"Кенійський шилінг (KES)", "name":"Кенійський шилінг", "sign":"KSh", },
	{ "code":"KPW", "label":"Північнокорейський вон (KPW)", "name":"Північнокорейський вон", "sign":"₩", },
	{ "code":"KRW", "label":"Вон (KRW)", "name":"Вон", "sign":"₩", },
	{ "code":"KWD", "label":"Кувейтський динар (KWD)", "name":"Кувейтський динар", "sign":".د.ك", },
	{ "code":"KGS", "label":"Сом (KGS)", "name":"Сом", "sign":"сом", },
	{ "code":"LAK", "label":"Кіп (LAK)", "name":"Кіп", "sign":"₭", },
	{ "code":"LBP", "label":"Ліванський фунт (LBP)", "name":"Ліванський фунт", "sign":"£", },
	{ "code":"LSL", "label":"Лоті (LSL)", "name":"Лоті", "sign":"LSL", },
	{ "code":"ZAR", "label":"Ренд (ZAR)", "name":"Ренд", "sign":"R", },
	{ "code":"LRD", "label":"Ліберійський долар (LRD)", "name":"Ліберійський долар", "sign":"$", },
	{ "code":"LYD", "label":"Лівійський динар (LYD)", "name":"Лівійський динар", "sign":".د.ل", },
	{ "code":"CHF", "label":"Швейцарський франк (CHF)", "name":"Швейцарський франк", "sign":"fr.", },
	{ "code":"MOP", "label":"Патака (MOP)", "name":"Патака", "sign":"MOP$", },
	{ "code":"MKD", "label":"Денар (MKD)", "name":"Денар", "sign":"ден", },
	{ "code":"MGA", "label":"Малаґасійський аріарі (MGA)", "name":"Малаґасійський аріарі", "sign":"Ar", },
	{ "code":"MWK", "label":"Малавійська квача (MWK)", "name":"Малавійська квача", "sign":"MK", },
	{ "code":"MYR", "label":"Малайзійський рінгіт (MYR)", "name":"Малайзійський рінгіт", "sign":"RM", },
	{ "code":"MVR", "label":"Руфія (MVR)", "name":"Руфія", "sign":"MVR", },
	{ "code":"MRU", "label":"Мавританська угія (MRU)", "name":"Мавританська угія", "sign":"", },
	{ "code":"MUR", "label":"Маврикійська рупія (MUR)", "name":"Маврикійська рупія", "sign":"₨", },
	{ "code":"MXN", "label":"Мексиканський песо (MXN)", "name":"Мексиканський песо", "sign":"$", },
	{ "code":"MDL", "label":"Молдовський лей (MDL)", "name":"Молдовський лей", "sign":"lei", },
	{ "code":"MNT", "label":"Тугрик (MNT)", "name":"Тугрик", "sign":"₮", },
	{ "code":"MAD", "label":"Марокканський дірхем (MAD)", "name":"Марокканський дірхем", "sign":".د.م", },
	{ "code":"MZN", "label":"Метікал (MZN)", "name":"Метікал", "sign":"MT", },
	{ "code":"MMK", "label":"К'ят (MMK)", "name":"К'ят", "sign":"K", },
	{ "code":"NAD", "label":"Намібійський долар (NAD)", "name":"Намібійський долар", "sign":"$", },
	{ "code":"NPR", "label":"Непальська рупія (NPR)", "name":"Непальська рупія", "sign":"₨", },
	{ "code":"NIO", "label":"Кордоба Оро (NIO)", "name":"Кордоба Оро", "sign":"C$", },
	{ "code":"NGN", "label":"Наїра (NGN)", "name":"Наїра", "sign":"₦", },
	{ "code":"OMR", "label":"Оманський ріал (OMR)", "name":"Оманський ріал", "sign":"﷼", },
	{ "code":"PKR", "label":"Пакистанська рупія (PKR)", "name":"Пакистанська рупія", "sign":"₨", },
	{ "code":"PAB", "label":"Бальбоа (PAB)", "name":"Бальбоа", "sign":"B/.", },
	{ "code":"PGK", "label":"Кіна (PGK)", "name":"Кіна", "sign":"K", },
	{ "code":"PYG", "label":"Гуарані (PYG)", "name":"Гуарані", "sign":"Gs", },
	{ "code":"PEN", "label":"Новий сол (PEN)", "name":"Новий сол", "sign":"S/", },
	{ "code":"PHP", "label":"Філіппінський песо (PHP)", "name":"Філіппінський песо", "sign":"₱", },
	{ "code":"PLN", "label":"Злотий (PLN)", "name":"Злотий", "sign":"zł", },
	{ "code":"QAR", "label":"Катарський ріал (QAR)", "name":"Катарський ріал", "sign":"﷼", },
	{ "code":"RON", "label":"Нова румунська лея (RON)", "name":"Нова румунська лея", "sign":"lei", },
	{ "code":"RUB", "label":"Російський рубль (RUB)", "name":"Російський рубль", "sign":"₽", },
	{ "code":"RWF", "label":"Руандійський франк (RWF)", "name":"Руандійський франк", "sign":"R₣", },
	{ "code":"SHP", "label":"Фунт Святої Єлени (SHP)", "name":"Фунт Святої Єлени", "sign":"£", },
	{ "code":"WST", "label":"Тала (WST)", "name":"Тала", "sign":"WS$", },
	{ "code":"STN", "label":"Добра (STN)", "name":"Добра", "sign":"", },
	{ "code":"SAR", "label":"Саудівський ріал (SAR)", "name":"Саудівський ріал", "sign":"﷼", },
	{ "code":"RSD", "label":"Сербський динар (RSD)", "name":"Сербський динар", "sign":"Дин.", },
	{ "code":"SCR", "label":"Сейшельська рупія (SCR)", "name":"Сейшельська рупія", "sign":"₨", },
	{ "code":"SLL", "label":"Леоне (SLL)", "name":"Леоне", "sign":"Le", },
	{ "code":"SGD", "label":"Синґапурський долар (SGD)", "name":"Синґапурський долар", "sign":"$", },
	{ "code":"SBD", "label":"Долар Соломонових островів (SBD)", "name":"Долар Соломонових островів", "sign":"$", },
	{ "code":"SOS", "label":"Сомалійський шилінг (SOS)", "name":"Сомалійський шилінг", "sign":"S", },
	{ "code":"LKR", "label":"Рупія Шрі-Ланки (LKR)", "name":"Рупія Шрі-Ланки", "sign":"₨", },
	{ "code":"SDG", "label":"Суданський фунт (SDG)", "name":"Суданський фунт", "sign":"SDG", },
	{ "code":"SRD", "label":"Сурінамський долар (SRD)", "name":"Сурінамський долар", "sign":"$", },
	{ "code":"SEK", "label":"Шведська крона (SEK)", "name":"Шведська крона", "sign":"kr", },
	{ "code":"CHE", "label":"WIR Euro (CHE)", "name":"WIR Euro", "sign":"CHE", },
	{ "code":"CHW", "label":"WIR Franc (CHW)", "name":"WIR Franc", "sign":"CHW", },
	{ "code":"SYP", "label":"Сирійський фунт (SYP)", "name":"Сирійський фунт", "sign":"£", },
	{ "code":"TWD", "label":"Новий тайванський долар (TWD)", "name":"Новий тайванський долар", "sign":"NT$", },
	{ "code":"TJS", "label":"Сомоні (TJS)", "name":"Сомоні", "sign":"SM", },
	{ "code":"TZS", "label":"Танзанійський шилінг (TZS)", "name":"Танзанійський шилінг", "sign":"TSh", },
	{ "code":"THB", "label":"Бат (THB)", "name":"Бат", "sign":"฿", },
	{ "code":"TOP", "label":"Паанґа (TOP)", "name":"Паанґа", "sign":"T$", },
	{ "code":"TTD", "label":"Долар Тринідаду і Тобаго (TTD)", "name":"Долар Тринідаду і Тобаго", "sign":"TT$", },
	{ "code":"TND", "label":"Туніський динар (TND)", "name":"Туніський динар", "sign":".د.ت", },
	{ "code":"TRY", "label":"Нова турецька ліра (TRY)", "name":"Нова турецька ліра", "sign":"₺", },
	{ "code":"TMT", "label":"Turkmenistan New Manat (TMT)", "name":"Turkmenistan New Manat", "sign":"T", },
	{ "code":"UGX", "label":"Уґандійський шилінг (UGX)", "name":"Уґандійський шилінг", "sign":"USh", },
	{ "code":"AED", "label":"Дірхем ОАЕ (AED)", "name":"Дірхем ОАЕ", "sign":".د.إ", },
	{ "code":"UYU", "label":"Уруґвайський песо (UYU)", "name":"Уруґвайський песо", "sign":"$U", },
	{ "code":"UYW", "label":"Unidad Previsional (UYW)", "name":"Unidad Previsional", "sign":"", },
	{ "code":"UZS", "label":"Узбецький сум (UZS)", "name":"Узбецький сум", "sign":"so’m", },
	{ "code":"VUV", "label":"Вату (VUV)", "name":"Вату", "sign":"VT", },
	{ "code":"VES", "label":"Bolívar Soberano (VES)", "name":"Bolívar Soberano", "sign":"Bs", },
	{ "code":"VND", "label":"Донг (VND)", "name":"Донг", "sign":"₫", },
	{ "code":"YER", "label":"Єменський ріал (YER)", "name":"Єменський ріал", "sign":"﷼", },
	{ "code":"ZMW", "label":"Zambian Kwacha (ZMW)", "name":"Zambian Kwacha", "sign":"K", },
	{ "code":"ZWL", "label":"Zimbabwe Dollar (ZWL)", "name":"Zimbabwe Dollar", "sign":"Z$", },
	{ "code":"XAU", "label":"Gold (XAU)", "name":"Gold", "sign":"", },
	{ "code":"XPD", "label":"Palladium (XPD)", "name":"Palladium", "sign":"", },
	{ "code":"XPT", "label":"Platinum (XPT)", "name":"Platinum", "sign":"", },
	{ "code":"XAG", "label":"Silver (XAG)", "name":"Silver", "sign":"", },
	{ "code":"AFA", "label":"Afghani (AFA)", "name":"Afghani", "sign":"", },
	{ "code":"FIM", "label":"Markka (FIM)", "name":"Markka", "sign":"", },
	{ "code":"ADP", "label":"Andorran Peseta (ADP)", "name":"Andorran Peseta", "sign":"", },
	{ "code":"ESP", "label":"Spanish Peseta (ESP)", "name":"Spanish Peseta", "sign":"", },
	{ "code":"FRF", "label":"French Franc (FRF)", "name":"French Franc", "sign":"", },
	{ "code":"AOK", "label":"Kwanza (AOK)", "name":"Kwanza", "sign":"", },
	{ "code":"AOR", "label":"Kwanza Reajustado (AOR)", "name":"Kwanza Reajustado", "sign":"", },
	{ "code":"RUR", "label":"Russian Ruble (RUR)", "name":"Russian Ruble", "sign":"₽", },
	{ "code":"ATS", "label":"Schilling (ATS)", "name":"Schilling", "sign":"", },
	{ "code":"AYM", "label":"Azerbaijan Manat (AYM)", "name":"Azerbaijan Manat", "sign":"", },
	{ "code":"AZM", "label":"Azerbaijanian Manat (AZM)", "name":"Azerbaijanian Manat", "sign":"", },
	{ "code":"BYB", "label":"Belarusian Ruble (BYB)", "name":"Belarusian Ruble", "sign":"", },
	{ "code":"BYR", "label":"Білоруський рубль (BYR)", "name":"Білоруський рубль", "sign":"p.", },
	{ "code":"BEC", "label":"Convertible Franc (BEC)", "name":"Convertible Franc", "sign":"", },
	{ "code":"BEF", "label":"Belgian Franc (BEF)", "name":"Belgian Franc", "sign":"", },
	{ "code":"BEL", "label":"Financial Franc (BEL)", "name":"Financial Franc", "sign":"", },
	{ "code":"BAD", "label":"Dinar (BAD)", "name":"Dinar", "sign":"", },
	{ "code":"BRB", "label":"Cruzeiro (BRB)", "name":"Cruzeiro", "sign":"", },
	{ "code":"BRR", "label":"Cruzeiro Real (BRR)", "name":"Cruzeiro Real", "sign":"", },
	{ "code":"BGJ", "label":"Lev A/52 (BGJ)", "name":"Lev A/52", "sign":"", },
	{ "code":"CYP", "label":"Cyprus Pound (CYP)", "name":"Cyprus Pound", "sign":"", },
	{ "code":"CSK", "label":"Koruna (CSK)", "name":"Koruna", "sign":"", },
	{ "code":"ECS", "label":"Sucre (ECS)", "name":"Sucre", "sign":"", },
	{ "code":"GQE", "label":"Ekwele (GQE)", "name":"Ekwele", "sign":"", },
	{ "code":"EEK", "label":"Естонська крона (EEK)", "name":"Естонська крона", "sign":"kr", },
	{ "code":"XEU", "label":"European Currency Unit  (E.C.U) (XEU)", "name":"European Currency Unit  (E.C.U)", "sign":"", },
	{ "code":"GEK", "label":"Georgian Coupon (GEK)", "name":"Georgian Coupon", "sign":"", },
	{ "code":"DDM", "label":"Mark der DDR (DDM)", "name":"Mark der DDR", "sign":"", },
	{ "code":"DEM", "label":"Deutsche Mark (DEM)", "name":"Deutsche Mark", "sign":"", },
	{ "code":"GHC", "label":"Cedi (GHC)", "name":"Cedi", "sign":"¢", },
	{ "code":"GHP", "label":"Ghana Cedi (GHP)", "name":"Ghana Cedi", "sign":"", },
	{ "code":"GRD", "label":"Drachma (GRD)", "name":"Drachma", "sign":"", },
	{ "code":"GWE", "label":"Guinea Escudo (GWE)", "name":"Guinea Escudo", "sign":"", },
	{ "code":"ITL", "label":"Italian Lira (ITL)", "name":"Italian Lira", "sign":"", },
	{ "code":"IEP", "label":"Irish Pound (IEP)", "name":"Irish Pound", "sign":"", },
	{ "code":"LVL", "label":"Латвійський лат (LVL)", "name":"Латвійський лат", "sign":"Ls", },
	{ "code":"ZAL", "label":"Financial Rand (ZAL)", "name":"Financial Rand", "sign":"", },
	{ "code":"LTL", "label":"Литовський літ (LTL)", "name":"Литовський літ", "sign":"Lt", },
	{ "code":"LUC", "label":"Luxembourg Convertible Franc (LUC)", "name":"Luxembourg Convertible Franc", "sign":"", },
	{ "code":"LUF", "label":"Luxembourg Franc (LUF)", "name":"Luxembourg Franc", "sign":"", },
	{ "code":"LUL", "label":"Luxembourg Financial Franc (LUL)", "name":"Luxembourg Financial Franc", "sign":"", },
	{ "code":"MGF", "label":"Malagasy Franc (MGF)", "name":"Malagasy Franc", "sign":"", },
	{ "code":"MLF", "label":"Mali Franc (MLF)", "name":"Mali Franc", "sign":"", },
	{ "code":"MTL", "label":"Maltese Lira (MTL)", "name":"Maltese Lira", "sign":"", },
	{ "code":"MRO", "label":"Агвія (MRO)", "name":"Агвія", "sign":"ouguiya", },
	{ "code":"MZE", "label":"Mozambique Escudo (MZE)", "name":"Mozambique Escudo", "sign":"", },
	{ "code":"NLG", "label":"Netherlands Guilder (NLG)", "name":"Netherlands Guilder", "sign":"", },
	{ "code":"PLZ", "label":"Zloty (PLZ)", "name":"Zloty", "sign":"", },
	{ "code":"PTE", "label":"Portuguese Escudo (PTE)", "name":"Portuguese Escudo", "sign":"", },
	{ "code":"ROK", "label":"Leu A/52 (ROK)", "name":"Leu A/52", "sign":"", },
	{ "code":"STD", "label":"Добра (STD)", "name":"Добра", "sign":"Db", },
	{ "code":"CSD", "label":"Serbian Dinar (CSD)", "name":"Serbian Dinar", "sign":"", },
	{ "code":"SKK", "label":"Словацька крона (SKK)", "name":"Словацька крона", "sign":"", },
	{ "code":"SIT", "label":"Tolar (SIT)", "name":"Tolar", "sign":"", },
	{ "code":"RHD", "label":"Rhodesian Dollar (RHD)", "name":"Rhodesian Dollar", "sign":"", },
	{ "code":"ESA", "label":"Spanish Peseta (ESA)", "name":"Spanish Peseta", "sign":"", },
	{ "code":"SDD", "label":"Sudanese Dinar (SDD)", "name":"Sudanese Dinar", "sign":"", },
	{ "code":"SRG", "label":"Surinam Guilder (SRG)", "name":"Surinam Guilder", "sign":"", },
	{ "code":"TJR", "label":"Tajik Ruble (TJR)", "name":"Tajik Ruble", "sign":"", },
	{ "code":"TPE", "label":"Timor Escudo (TPE)", "name":"Timor Escudo", "sign":"", },
	{ "code":"TRL", "label":"Old Turkish Lira (TRL)", "name":"Old Turkish Lira", "sign":"₤", },
	{ "code":"TMM", "label":"Туркменський манат (TMM)", "name":"Туркменський манат", "sign":"", },
	{ "code":"UAK", "label":"Karbovanet (UAK)", "name":"Karbovanet", "sign":"", },
	{ "code":"USS", "label":"US Dollar  (Same day) (USS)", "name":"US Dollar  (Same day)", "sign":"", },
	{ "code":"VEB", "label":"Bolivar (VEB)", "name":"Bolivar", "sign":"", },
	{ "code":"VEF", "label":"Болівар (VEF)", "name":"Болівар", "sign":"Bs", },
	{ "code":"YDD", "label":"Yemeni Dinar (YDD)", "name":"Yemeni Dinar", "sign":"", },
	{ "code":"YUD", "label":"New Yugoslavian Dinar (YUD)", "name":"New Yugoslavian Dinar", "sign":"", },
	{ "code":"ZRN", "label":"New Zaire (ZRN)", "name":"New Zaire", "sign":"", },
	{ "code":"ZMK", "label":"Замбійська квача (ZMK)", "name":"Замбійська квача", "sign":"", },
	{ "code":"ZWN", "label":"Zimbabwe Dollar  (new) (ZWN)", "name":"Zimbabwe Dollar  (new)", "sign":"", },
	{ "code":"ZWR", "label":"Zimbabwe Dollar (ZWR)", "name":"Zimbabwe Dollar", "sign":"", },
];