// Core
import React, { useCallback, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';

// lodash
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import forEach from 'lodash/forEach';

// UI
import Button from '@material-ui/core/Button';
import { Add as AddIcon } from '@material-ui/icons';

// actions
import actions from '../../../engine/core/tradeMarks/proposal/action';
import asyncAction from '../../../engine/core/tradeMarks/proposal/saga/asyncAction';
import helpersActions from '../../../engine/core/helpers/action';
import depDocActions from '../../../engine/core/departmentDocuments/action';
import helpersAsyncActions from '../../../engine/core/helpers/saga/asyncAction';
import tagsActionAsync from '../../../engine/core/tags/saga/asyncAction';
import tagsActions from '../../../engine/core/tags/action';

// reducers
import { initialState } from '../../../engine/core/tradeMarks/proposal/reducer';

// config
import accessList from '../../../engine/config/accessList';
import selectors from '../../../engine/config/selectors';
import globalStateKeys from '../../../engine/config/globalStateKeys';
import { listTablesName, listEntityName } from '../../../engine/config/listTablesName';

// Parts
import DxTable from '../../../ui/Table/DxTable';
import TableExtraFilter from '../../../ui/Table/components/TableExtraFilter/TableExtraFilter';
import ConfirmModal from '../../../components/ConfirmModal';

// routes
import { pageLinks } from '../../../routes';

// hooks
import { useAccessList } from '../../../ui/_hooks/useAccessList';

const tableName = listTablesName.trademarks;
const entityName = listEntityName.trademarks;

function ProposalTable(props) {
  const {
    proposalData, totalCount, pending, successDetBase64,
    currentPage, filters, sorting, pageSize,
    pendingDeleteProposal, checkboxes, isModalOpenAction,
    columnWidths, columnOrder, hiddenColumnNames, isModalOpenAddTag,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const accessPost = useAccessList(accessList.trademarks_post);
  const accessTagsList = useAccessList(accessList.tags_list_get);

  const fieldsForModal = [
    {
      name: 'status',
      type: 'select',
      label: t('Status'),
    },
    {
      name: 'client',
      type: 'autocompleteClient',
      label: t('Customer Search / Selection'),
    },
    {
      name: 'person',
      type: 'autocompleteEmployee',
      label: t('Search / Select employee'),
    },
    {
      name: 'jurisdictions',
      type: 'autocompleteJurisdictions',
      label: t('Jurisdiction'),
    },
    {
      name: 'company',
      type: 'selectCompany',
      label: t('Legal person'),
    },
    {
      name: 'boost',
      type: 'checkbox',
      label: t('Expedited application'),
    },
    {
      name: 'eApplication',
      type: 'checkbox',
      label: t('Electronic application'),
    },
  ];

  const columns = [
    { name: 'id', title: t('ID') },
    { name: 'caseNumber', title: t('Case number') },
    { name: 'title', title: t('Image'), customField: 'titleLogo' },
    { name: 'classes', title: t('Classes') },
    { name: 'clients', title: t('Client'), customField: 'objectTable' },
    { name: 'jurisdictions', title: t('Jurisdiction') },
    { name: 'applicationNumber', title: t('Application number') },
    { name: 'applicationDate', title: t('Application date') },
    { name: 'registrationNumber', title: t('Registration number') },
    { name: 'registrationDate', title: t('Registration date') },
    { name: 'status', title: t('Status') },
    { name: 'boost', title: t('Term') },
    { name: 'madridCases', title: t('Madrid') },
    { name: 'expiryDate', title: t('Expiration date') },
    { name: 'tags', title: t('Tags'), customField: 'tagsObject' },
    { name: 'notes', title: t('Note') },
    { name: 'pendingActions', title: t('Action up to') },
    { name: 'lastDocuments', title: t('Input document') },
    { name: 'createdAt', title: t('Created At') },
    { name: 'applicants', title: t('Applicants') },
    { name: 'holders', title: t('Owners') },
  ];

  const editingStateColumnExtensionsCosts = [
    { columnName: 'id', editingEnabled: false },
    { columnName: 'applicationDate', editingEnabled: false },
    { columnName: 'registrationDate', editingEnabled: false },
    { columnName: 'clients', editingEnabled: false },
    { columnName: 'caseNumber', editingEnabled: false },
    { columnName: 'title', editingEnabled: false },
    { columnName: 'status', editingEnabled: false },
    { columnName: 'madridCases', editingEnabled: false },
    { columnName: 'classes', editingEnabled: false },
    { columnName: 'pendingActions', editingEnabled: false },
    { columnName: 'applicationNumber', editingEnabled: false },
    { columnName: 'registrationNumber', editingEnabled: false },
    { columnName: 'expiryDate', editingEnabled: false },
    { columnName: 'lastDocuments', editingEnabled: false },
    { columnName: 'boost', editingEnabled: false },
    { columnName: 'createdAt', editingEnabled: false },
    { columnName: 'jurisdictions', editingEnabled: false },
    { columnName: 'applicants', editingEnabled: false },
    { columnName: 'holders', editingEnabled: false },
    { columnName: 'tags' },
    { columnName: 'notes' },
  ];

  const tableColumnExtensions = [
    { columnName: 'id', width: 50 },
    { columnName: 'applicationDate', width: '9rem' },
    { columnName: 'registrationDate', width: '9rem' },
    { columnName: 'caseNumber', width: '9rem' },
    { columnName: 'createdAt', width: '9rem' },
    { columnName: 'classes', width: '9rem' },
    { columnName: 'registrationNumber', width: '7rem' },
    { columnName: 'applicationNumber', width: '7rem' },
    { columnName: 'expiryDate', width: '8rem' },
    { columnName: 'lastDocuments', width: '8rem' },
    { columnName: 'pendingActions', width: '8rem' },
    { columnName: 'title', width: '15rem' },
    { columnName: 'status', width: '8rem' },
    { columnName: 'boost', width: '5rem' },
    { columnName: 'madridCases', width: '6rem' },
    { columnName: 'tags', width: '10rem' },
    { columnName: 'notes', width: '15rem' },
    { columnName: 'clients', width: '8rem' },
    { columnName: 'jurisdictions', width: '10rem' },
    { columnName: 'applicants', width: '10rem' },
    { columnName: 'holders', width: '10rem' },
  ];

  const handleEditingChange = ({ changed }) => {
    const values = Object.values(changed)[0];
    const ids = Object.keys(changed);
    if (!isEmpty(values)) {
      const filterData = map(proposalData.toJS().items, (item) => (
        item.id === Number(ids[0]) ? { ...item, ...values } : item));
      dispatch(actions.setProposalData({
        items: filterData,
        totalCount: filterData.length,
        pending: false,
      }));
      dispatch(helpersAsyncActions.putMultipleAsync({
        body: {
          entityName: 'trademarks',
          entityIds: ids,
          fields: values.tags ? {
            tags: map(values.tags, (item) => item.id),
          } : values,
        },
      }));
    }
  };

  useEffect(() => {
    if (accessTagsList) dispatch(tagsActionAsync.getListAsync());
  }, [dispatch, accessTagsList]);

  useEffect(() => {
    if (!isEmpty(proposalData.toJS().items) && successDetBase64) {
      forEach(proposalData.toJS().items, (item) => {
        if (item.logo) {
          dispatch(helpersAsyncActions.getImagesBase64Async({
            id: item.id,
            fileId: item.logo,
          }));
        }
      });
      dispatch(helpersActions.setSuccessDetBase64(false));
    }
  }, [dispatch, proposalData, successDetBase64]);

  useEffect(() => function cleanup() {
    dispatch(tagsActions.setTagsData({ items: [] }));
    dispatch(helpersActions.setImagesWithBase64Clear());
    const emptyData = { items: [], totalCount: 0, pending: false };
    dispatch(actions.setProposalData(emptyData));
  }, [dispatch]);

  // Paging
  const onCurrentPage = (page) => dispatch(actions.setCurrentPage(page));
  const onPageSize = (size) => dispatch(actions.setPageSize(size));
  // Filtering
  const setFilters = (getFilter) => dispatch(actions.setFilters(getFilter));
  // Sorting
  const onSortingChange = (sort) => dispatch(actions.setSorting(sort));
  const onColumnWidthsChange = (getColumnWidths) => dispatch(
    actions.setColumnWidths(getColumnWidths),
  );
  const onColumnOrderChange = (getColumnOrder) => dispatch(
    actions.setColumnOrder(getColumnOrder),
  );
  const onHiddenColumnNamesChange = (getHiddenColumnNames) => dispatch(
    actions.setHiddenColumnNames(getHiddenColumnNames),
  );
  // Reset filters
  const resetFilters = useCallback(() => {
    const dataInitialState = initialState.get(globalStateKeys.data);
    dispatch(actions.setFilters(dataInitialState.get(globalStateKeys.filters).toJS()));
    dispatch(actions.setSorting(dataInitialState.get(globalStateKeys.sorting).toJS()));
    dispatch(actions.setCurrentPage(dataInitialState.get(globalStateKeys.currentPage)));
  }, [dispatch]);

  const createDocument = useCallback(() => {
    const selectedEntity = filter(
      proposalData.toJS().items, (item) => includes(checkboxes.toJS(), item.id),
    );
    dispatch(depDocActions.setDepartmentDocumentEntityList({ trademarks: selectedEntity }));
    dispatch(push(pageLinks.documents.departmentDocuments.new));
  }, [proposalData, checkboxes, dispatch]);

  return (
    <>
      <TableExtraFilter
        filters={filters}
        filtersAction={setFilters}
        resetFilters={resetFilters}
      >
        {accessPost && (
          <Button
            mr={2}
            variant="contained"
            component={Link}
            to={pageLinks.tradeMarksRoutes.proposal.new}
            color="primary"
          >
            {t('ADD')}
            <AddIcon />
          </Button>
        )}
      </TableExtraFilter>
      <DxTable
        name="Trademarks"
        isLoading={pending}
        columns={columns}
        rows={proposalData.toJS().items}
        // Paging
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageSize={onPageSize}
        onCurrentPage={onCurrentPage}
        // Filters
        filtersAction={setFilters}
        filters={filters}
        // checkboxes ------------------------
        fieldsForModal={fieldsForModal}
        entityName={entityName}
        tableName={tableName}
        reloadTable={actions.setReload}
        createDocument={createDocument}
        selection={checkboxes.toJS()}
        onSelectionChange={(checkboxSelect) => {
          dispatch(helpersActions.setCheckbox({
            tableName,
            checkboxes: checkboxSelect,
          }));
        }}
        // ------------------------
        // Sorting
        sorting={sorting}
        onSortingChange={onSortingChange}
        // Edit/Delete
        isModalOpen={isModalOpenAction || isModalOpenAddTag}
        editingExtensions={editingStateColumnExtensionsCosts}
        onEditingChange={handleEditingChange}
        tableColumnExtensions={!isEmpty(columnWidths) && columnWidths.length === columns.length
          ? columnWidths : tableColumnExtensions}
        onColumnWidthsChange={onColumnWidthsChange}
        columnOrder={!isEmpty(columnOrder) ? columnOrder : map(columns, 'name')}
        onColumnOrderChange={onColumnOrderChange}
        hiddenColumnNames={hiddenColumnNames}
        onHiddenColumnNamesChange={onHiddenColumnNamesChange}
        accessEdit={accessList.trademarks_put}
        accessDelete={accessList.trademarks_delete}
      />

      <ConfirmModal
        buttonSendText="DELETE"
        pending={pendingDeleteProposal}
        handleSend={() => {
          dispatch(asyncAction.deleteProposalAsync());
        }}
      />
    </>
  );
}

Error.displayName = 'ProposalTable';

ProposalTable.propTypes = {
  totalCount: PropTypes.number.isRequired,
  pending: PropTypes.bool.isRequired,
  pendingDeleteProposal: PropTypes.bool.isRequired,
  isModalOpenAction: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  sorting: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  columnWidths: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  columnOrder: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  hiddenColumnNames: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  pageSize: PropTypes.number.isRequired,
  proposalData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  checkboxes: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  isModalOpenAddTag: PropTypes.bool.isRequired,
  successDetBase64: PropTypes.bool.isRequired,
};

ProposalTable.defaultProps = {
};

function mapStateToProps(state) {
  return {
    proposalData: selectors.tradeMarksProposal.proposalData(state),
    pending: selectors.tradeMarksProposal.pending(state),
    pendingDeleteProposal: selectors.tradeMarksProposal.pendingDeleteProposal(state),
    // Paging
    totalCount: selectors.tradeMarksProposal.totalCount(state),
    currentPage: selectors.tradeMarksProposal.currentPage(state),
    pageSize: selectors.tradeMarksProposal.pageSize(state),
    // Filtering
    filters: selectors.tradeMarksProposal.filters(state),
    // Sorting
    sorting: selectors.tradeMarksProposal.sorting(state),

    // ColumnWidths
    columnWidths: selectors.tradeMarksProposal.columnWidths(state),
    columnOrder: selectors.tradeMarksProposal.columnOrder(state),
    hiddenColumnNames: selectors.tradeMarksProposal.hiddenColumnNames(state),

    checkboxes: selectors.helpers.getCheckboxes(state, tableName),
    isModalOpenAction: selectors.pendingActions.isModalOpenAction(state),
    isModalOpenAddTag: selectors.tags.isModalOpen(state),

    successDetBase64: selectors.helpers.successDetBase64(state),
  };
}

function mapDispatchToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(ProposalTable);
