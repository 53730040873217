// Core
import React, { useEffect, useRef, useState } from 'react';

// Engine
import { delayCallTimeout } from '../../engine/config/globalConfig';

export function useDefferCall(setFilter, initValue) {
  const [value, setValue] = useState(initValue);
  const timerId = React.useRef(0);
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (timerId.current) {
      clearTimeout(timerId.current);
    }
    if (value !== initValue) {
      timerId.current = setTimeout(() => {
        setFilter(value || null);
      }, delayCallTimeout);
    }
  }, [initValue, setFilter, value]);
  useEffect(() => {
    setValue(initValue);
  }, [initValue]);

  return { value, setValue };
}
