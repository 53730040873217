// Core
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import { Field, reduxForm, change } from 'redux-form/immutable';
import * as PropTypes from 'prop-types';
import { List } from 'immutable';

// ui
import {
  Typography as MuiTypography,
  CardContent, TextField, Grid, Card as MuiCard,
  IconButton as MuiIconButton, Box, Tooltip, Button,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';

// icon
import {
  Delete as DeleteIcon,
} from '@material-ui/icons';

// lodash
import {
  isEmpty, find, groupBy, map, isArray,
  reduce, pullAt, filter, forEach,
} from 'lodash';

// parts
import Loader from '../../components/Loader';
import AutocompleteField from '../../ui/Form/AutocompleteField';
import FieldEmployee from '../../ui/Form/FieldsAutocomplete/FieldEmployee';

// actions
import actions from '../../engine/core/settings/action';
import asyncActions from '../../engine/core/settings/saga/asyncAction';
import employeesTableActions from '../../engine/core/company/employees/table/action';
import employeesAsyncActions from '../../engine/core/company/employees/table/saga/asyncAction';
import employeesActions from '../../engine/core/company/employees/action';
import docTypesAsyncActions from '../../engine/core/documentTypes/saga/asyncAction';
import docTypesActions from '../../engine/core/documentTypes/action';

// config
import selectors from '../../engine/config/selectors';

// hooks
import { useEventsAutocompleteAsync } from '../../ui/_hooks/useEventsAutocompleteAsync';
import accessList from '../../engine/config/accessList';
import { useAccessList } from '../../ui/_hooks/useAccessList';

// styles
const Card = styled(MuiCard)(spacing);
const Typography = styled(MuiTypography)(spacing);
const IconButton = styled(MuiIconButton)(spacing);
const TypographySub = styled(MuiTypography)`
  color: ${(props) => props.theme.palette.grey['500']};
`;

const formName = 'BasicSettings';

function BasicSettings(props) {
  const {
    pendingSettings, settingsData, employeesData,
    documentTypesData, responsibleList, fieldResponsible,
  } = props;
  const {
    getAutocompleteLists,
    handleGetAsyncData,
  } = useEventsAutocompleteAsync();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isSetting, setIsSetting] = useState(false);
  const [isListEmployees, setIsListEmployees] = useState(false);
  const [isInit, setIsInit] = useState(false);
  const [changedSetting, setChangedSetting] = useState({});
  const itemAll = { value: 'all', label: t('All') };
  const accessEdit = useAccessList(accessList.settings_put);
  const accessEmployeesList = useAccessList(accessList.employees_list_get);
  const accessDocTypeList = useAccessList(accessList.documentClassifier_list_get);

  useEffect(() => function cleanup() {
    dispatch(employeesActions.setListResponsible([]));
    dispatch(docTypesActions.setDocumentTypesData({
      items: [],
      totalCount: 0,
      pending: false,
    }));
    dispatch(actions.setSettingsResponsibleList([]));
  }, [dispatch]);

  useEffect(() => {
    if (!isSetting) {
      dispatch(asyncActions.getSettingsAsync());
      setIsSetting(true);
    }
  }, [dispatch, isSetting, setIsSetting]);
  useEffect(() => {
    if (!isListEmployees) {
      if (accessEmployeesList) dispatch(employeesAsyncActions.getListAsync());
      if (accessDocTypeList) dispatch(docTypesAsyncActions.getListAsync({ type: 'in', limit: 10000 }));
      setIsListEmployees(true);
    }
  }, [dispatch, isListEmployees, accessEmployeesList, accessDocTypeList]);

  useEffect(() => {
    if (!isEmpty(settingsData.toJS().items) && !isEmpty(employeesData.toJS().items) && !isInit) {
      const { defaultResponsible } = groupBy(settingsData.toJS().items, 'group');
      if (!isEmpty(defaultResponsible)) {
        const list = reduce(defaultResponsible, (acc, item) => {
          const values = !isEmpty(item.settingValue) && isArray(item.settingValue)
            ? map(item.settingValue,
              (elem) => find(employeesData.toJS().items, { id: elem.employeeId }))
            : [];
          acc[item.settingName] = values;
          return acc;
        }, {});
        dispatch(actions.setSettingsResponsibleList(list || []));
        dispatch(employeesTableActions.setEmployeesData({
          items: [],
          totalCount: 0,
          pending: false,
        }));
        setIsInit(true);
      }
    }
  }, [employeesData, dispatch, settingsData, isInit]);

  useEffect(() => {
    if (!isEmpty(settingsData.toJS().items) && !isEmpty(documentTypesData.toJS().items)) {
      const { defaultResponsible } = groupBy(settingsData.toJS().items, 'group');
      if (!isEmpty(defaultResponsible)) {
        map(defaultResponsible, (item) => {
          if (!isEmpty(item.settingValue) && isArray(item.settingValue)) {
            forEach(item.settingValue, (elem, index) => {
              const documentClassifier = isArray(elem.documentClassifier)
                ? filter(map(elem.documentClassifier, (id) => find(documentTypesData.toJS().items,
                  { id: Number(id) })), (el) => !isEmpty(el))
                : [itemAll];
              dispatch(change(formName, `responsible.${item.settingName}[${index}].documentClassifier`, documentClassifier));
            });
          }
        });
      }
    }
  }, [dispatch, settingsData, formName, documentTypesData]);// eslint-disable-line

  const handleEditSetting = () => {
    if (!isEmpty(changedSetting)) {
      dispatch(asyncActions.putSettingAsync(changedSetting));
      setChangedSetting({});
    }
  };

  const handleChangeSetting = (setting, value) => {
    setChangedSetting({
      ...setting,
      settingValue: value,
    });
  };

  const handleUsersGetSelectOption = (name, elem) => {
    if (!isEmpty(elem)) {
      dispatch(actions.setSettingsResponsibleList({
        ...responsibleList.toJS(),
        [name]: [
          ...responsibleList.toJS()[name] || [],
          elem,
        ],
      }));
      setTimeout(() => {
        dispatch(change(formName, 'users', ''));
        const { length = 0 } = responsibleList.toJS()[name];
        dispatch(change(formName, `responsible.${name}[${length}].documentClassifier`, [itemAll]));
      });
    }
  };

  const handleDeleteUsers = (name, index) => {
    const list = [...responsibleList.toJS()[name]];
    pullAt(list, index);
    const responsible = fieldResponsible.toJS ? fieldResponsible.toJS() : fieldResponsible;
    const listDocClassifier = [...responsible[name]];
    pullAt(listDocClassifier, index);
    dispatch(actions.setSettingsResponsibleList({
      ...responsibleList.toJS(),
      [name]: list,
    }));
    dispatch(change(formName, `responsible.${name}`, List([])));
    setTimeout(() => {
      dispatch(change(formName, `responsible.${name}`, List(listDocClassifier)));
    }, 20);
  };

  const handleChange = (name, values, index) => {
    const findItemAll = find(values, { value: 'all' });
    if (findItemAll || isEmpty(values)) {
      const filterItems = values[0]?.value === 'all' && values.length > 1 ? filter(values, 'id') : [itemAll];
      setTimeout(() => {
        dispatch(change(formName, `responsible.${name}[${index}].documentClassifier`, null));
        dispatch(change(formName, `responsible.${name}[${index}].documentClassifier`, filterItems));
      });
    }
  };

  const handleSave = (name) => {
    const findItem = find(settingsData.toJS().items, { settingName: name });
    const responsible = fieldResponsible.toJS ? fieldResponsible.toJS() : fieldResponsible;
    dispatch(asyncActions.putSettingAsync({
      ...findItem,
      settingValue: [
        ...map(responsibleList.toJS()[name], (item, index) => {
          const { documentClassifier } = responsible?.[name]?.[index];
          return ({
            employeeId: item.id,
            documentClassifier: find(documentClassifier, { value: 'all' }) ? null : map(documentClassifier, 'id'),
          });
        }),
      ],
    }));
  };

  const getObjectType = (name) => {
    switch (name) {
      case 'trademarks': return '1';
      case 'industrial': return '2';
      default: return '3';
    }
  };

  return (
    <>
      {!pendingSettings ? (
        !isEmpty(settingsData.toJS().items) && (
          map(Object.keys(groupBy(settingsData.toJS().items, 'group')), (groupName) => {
            const group = groupBy(settingsData.toJS().items, 'group')[groupName];
            if (groupName === 'defaultResponsible') {
              return (
                <Card mb={6} key={groupName}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {t(groupName)}
                    </Typography>
                    <Grid container spacing={6}>
                      {map(group, (setting) => (
                        <React.Fragment key={setting.id}>
                          <Grid item xs={12} md={7}>
                            <TypographySub variant="subtitle1">
                              {t(setting.settingName)}
                            </TypographySub>
                            <FieldEmployee
                              name="users"
                              label="Search / Select employee"
                              getAutocompleteLists={getAutocompleteLists}
                              getAsyncData={handleGetAsyncData}
                              formName={formName}
                              propsField={{
                                getSelectOption:
                                (elem) => handleUsersGetSelectOption(setting.settingName, elem),
                                disabled: !accessEdit,
                              }}
                            />
                          </Grid>
                          {!isEmpty(responsibleList.toJS())
                          && !isEmpty(responsibleList.toJS()[setting.settingName])
                          && map(responsibleList.toJS()[setting.settingName], (employee, index) => (
                            <Grid key={index} container alignItems="flex-end">
                              <Grid item xs={12} md={4}>
                                <Box display="flex" flexDirection="row" alignItems="center">
                                  {accessEdit && (
                                    <IconButton
                                      mx={2}
                                      aria-label={t('Delete')}
                                      size="small"
                                      onClick={() => {
                                        handleDeleteUsers(setting.settingName, index);
                                      }}
                                    >
                                      <Tooltip title={t('Delete')} placement="top-start">
                                        <DeleteIcon size="small" />
                                      </Tooltip>
                                    </IconButton>
                                  )}
                                  <Box component="span" fontWeight="fontWeightBold" mr={5} ml={5}>
                                    {employee?.name || employee?.label}
                                  </Box>
                                </Box>
                              </Grid>

                              <Grid item xs={12} md={8}>
                                <Field
                                  multiple
                                  name={`responsible.${setting.settingName}[${index}].documentClassifier`}
                                  id={`responsible.${setting.settingName}[${index}].documentClassifier`}
                                  labelId={`responsible.${setting.settingName}[${index}].documentClassifier`}
                                  component={AutocompleteField}
                                  type="text"
                                  label={t('Type')}
                                  margin="none"
                                  fullWidth
                                  options={[
                                    itemAll,
                                    ...filter(documentTypesData.toJS().items,
                                      { objectType: getObjectType(setting.settingName) }),
                                  ]}
                                  onChange={(e) => handleChange(setting.settingName, e, index)}
                                  disabled={!accessEdit}
                                />
                              </Grid>
                            </Grid>
                          ))}
                          {accessEdit && (
                            <Grid item xs={12}>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleSave(setting.settingName)}
                              >
                                {t('SAVE')}
                              </Button>
                            </Grid>
                          )}
                        </React.Fragment>
                      ))}
                    </Grid>
                  </CardContent>
                </Card>
              );
            }
            return (
              <Card mb={6} key={groupName}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {t(groupName)}
                  </Typography>
                  <Grid container spacing={6}>
                    {map(group, (setting) => (
                      <Grid item xs={12} md={3} key={setting.id}>
                        <TextField
                          onKeyDown={(event) => {
                            // enter
                            if (event.keyCode === 13 && accessEdit) {
                              handleEditSetting();
                            }
                          }}
                          name={setting.settingName}
                          id={setting.settingName}
                          label={t(setting.settingName)}
                          margin="normal"
                          variant="standard"
                          my={2}
                          fullWidth
                          type="text"
                          onChange={(event) => handleChangeSetting(setting, event.target.value)}
                          defaultValue={setting.settingValue}
                          onBlur={handleEditSetting}
                          disabled={!accessEdit}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
              </Card>
            );
          })
        )
      ) : <Loader />}
    </>
  );
}

BasicSettings.displayName = 'BasicSettings';

BasicSettings.propTypes = {
  settingsData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingSettings: PropTypes.bool.isRequired,
  employeesData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  documentTypesData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  responsibleList: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  fieldResponsible: PropTypes.oneOfType([
    PropTypes.object,
  ]),
};

BasicSettings.defaultProps = {
  fieldResponsible: {},
};

function mapStateToProps(state) {
  return {
    settingsData: selectors.settings.settingsData(state),
    pendingSettings: selectors.settings.pending(state),
    responsibleList: selectors.settings.responsibleList(state),
    employeesData: selectors.employeesTable.employeesData(state),
    documentTypesData: selectors.documentTypes.documentTypesData(state),
    fieldResponsible: selectors.form.getFormValues(state, formName).get('responsible'),
  };
}

export default compose(
  reduxForm({
    form: formName,
  }),
  connect(mapStateToProps, null),
)(BasicSettings);
