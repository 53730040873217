// Core
import moment from 'moment';
import 'moment/locale/uk';
import 'moment/locale/ru';

export function formatDate(date, format = 'DD.MM.YYYY HH:mm', locale = 'uk') {
  return moment(date).locale(locale).format(format);
}

export function formatDateByFilter(startDate, endDate, format = 'YYYY-MM-DD-HH:mm:ss', locale = 'uk') {
  return ({
    start: moment(startDate).locale(locale).startOf('day').format(format),
    end: moment(endDate).locale(locale).endOf('day').format(format),
  });
}

export function isSame(start, end) {
  return moment(start).isSame(end);
}
