// Core
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

// Lodash
import {
  map, isEmpty,
} from 'lodash';

// actions
import helpersActions from '../../../engine/core/helpers/action';
import companiesActions from '../../../engine/core/company/profile/action';
import companiesActionAsync from '../../../engine/core/company/profile/saga/asyncAction';

// config
import selectors from '../../../engine/config/selectors';

// parts
import ConfirmModal from '../../../components/ConfirmModal';
import DxTable from '../../../ui/Table/DxTable';

// hooks

import { listTablesName, listEntityName } from '../../../engine/config/listTablesName';
import accessList from '../../../engine/config/accessList';

const tableName = listTablesName.companies;
const entityName = listEntityName.company;

function CompaniesTable(props) {
  const {
    companiesData, totalCount, pending,
    currentPage, filters, sorting,
    pageSize, pendingDeleteCompany, checkboxes,
    columnWidths, columnOrder, hiddenColumnNames,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const columns = [
    { name: 'id', title: t('ID') },
    { name: 'name', title: t('Title') },
    { name: 'innCode', title: t('Inn Code') },
    { name: 'director', title: t('Director') },
  ];

  const tableColumnExtensions = [
    { columnName: 'id', width: 50 },
    { columnName: 'name', width: '40rem' },
    { columnName: 'director', width: '40rem' },
    { columnName: 'innCode', width: '10rem' },
  ];

  // Paging
  const onCurrentPage = (page) => dispatch(companiesActions.setCurrentPage(page));
  const onPageSize = (size) => dispatch(companiesActions.setPageSize(size));
  // Filtering
  const setFilters = (getFilters) => dispatch(companiesActions.setFilters(getFilters));
  // Sorting
  const onSortingChange = (getSorting) => dispatch(companiesActions.setSorting(getSorting));
  const onColumnWidthsChange = (getColumnWidths) => dispatch(
    companiesActions.setColumnWidths(getColumnWidths),
  );
  const onColumnOrderChange = (getColumnOrder) => dispatch(
    companiesActions.setColumnOrder(getColumnOrder),
  );
  const onHiddenColumnNamesChange = (getHiddenColumnNames) => dispatch(
    companiesActions.setHiddenColumnNames(getHiddenColumnNames),
  );

  return (
    <>
      <DxTable
        name="Companies"
        isLoading={pending}
        columns={columns}
        rows={companiesData.toJS().items}
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageSize={onPageSize}
        onCurrentPage={onCurrentPage}
        filtersAction={setFilters}
        filters={filters}
        entityName={entityName}
        tableColumnExtensions={!isEmpty(columnWidths) && columnWidths.length === columns.length
          ? columnWidths : tableColumnExtensions}
        onColumnWidthsChange={onColumnWidthsChange}
        columnOrder={!isEmpty(columnOrder) ? columnOrder : map(columns, 'name')}
        onColumnOrderChange={onColumnOrderChange}
        hiddenColumnNames={hiddenColumnNames}
        onHiddenColumnNamesChange={onHiddenColumnNamesChange}
        // checkboxes ------------------------
        tableName={tableName}
        reloadTable={companiesActions.setReload}
        selection={checkboxes.toJS()}
        onSelectionChange={(checkboxSelect) => {
          dispatch(helpersActions.setCheckbox({
            tableName,
            checkboxes: checkboxSelect,
          }));
        }}
        // ------------------------
        sorting={sorting}
        onSortingChange={onSortingChange}
        accessEdit={accessList.company_put}
        accessDelete={accessList.company_delete}
      />

      <ConfirmModal
        buttonSendText="DELETE"
        pending={pendingDeleteCompany}
        handleSend={() => {
          dispatch(companiesActionAsync.deleteCompanyAsync());
        }}
      />
    </>
  );
}

CompaniesTable.displayName = 'CompaniesTable';

CompaniesTable.propTypes = {
  companiesData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  totalCount: PropTypes.number.isRequired,
  pending: PropTypes.bool.isRequired,
  pendingDeleteCompany: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  sorting: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  columnWidths: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  columnOrder: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  hiddenColumnNames: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  pageSize: PropTypes.number.isRequired,
  checkboxes: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

CompaniesTable.defaultProps = {};

function mapStateToProps(state) {
  return {
    companiesData: selectors.companiesTable.companiesData(state),
    pending: selectors.companiesTable.pending(state),
    pendingDeleteCompany: selectors.companiesTable.pendingDeleteCompany(state),

    // Paging
    totalCount: selectors.companiesTable.totalCount(state),
    currentPage: selectors.companiesTable.currentPage(state),
    pageSize: selectors.companiesTable.pageSize(state),

    // Filtering
    filters: selectors.companiesTable.filters(state),

    // Sorting
    sorting: selectors.companiesTable.sorting(state),

    // ColumnWidths
    columnWidths: selectors.companiesTable.columnWidths(state),
    columnOrder: selectors.companiesTable.columnOrder(state),
    hiddenColumnNames: selectors.companiesTable.hiddenColumnNames(state),

    checkboxes: selectors.helpers.getCheckboxes(state, tableName),
  };
}

function mapDispatchToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(CompaniesTable);
