// Core
import { all, call, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetProposalDocumentsInWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callPostDeleteDocumentsInWorker,
} from './workers';
import types from '../types';

function* watchGetProposalDocumentsIn() {
  yield takeEvery(asyncTypes.GET_PROPOSAL_DOCUMENTS_IN_ASYNC, callGetProposalDocumentsInWorker);
}
function* watchGetInitialState() {
  yield takeEvery(
    asyncTypes.GET_PROPOSAL_DOCUMENTS_IN_INITIAL_STATE_ASYNC,
    callGetInitialStateWorker,
  );
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_PROPOSAL_DOCUMENTS_IN_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_PROPOSAL_DOCUMENTS_IN_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_PROPOSAL_DOCUMENTS_IN_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_PROPOSAL_DOCUMENTS_IN_SORTING, callSetToLocalDataWorker);
}
function* watchPostDeleteDocumentsInWorker() {
  yield takeEvery(asyncTypes.POST_DELETE_DOCUMENTS_IN_ASYNC, callPostDeleteDocumentsInWorker);
}

export function* watchersProposalDocumentsInTable() {
  yield all([
    call(watchGetProposalDocumentsIn),
    call(watchSetCurrentPage),
    call(watchSetPageSize),
    call(watchSetFilters),
    call(watchSetSorting),
    call(watchGetInitialState),
    call(watchPostDeleteDocumentsInWorker),
  ]);
}
