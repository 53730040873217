// Core
import { apply, put } from 'redux-saga/effects';

// config
import { api } from '../../../../config/api';

// actions
import actions from '../../action';

export function* callGetSettingsWorker({ payload: params }) {
  const {
    setSettingsData,
  } = actions;

  yield put(setSettingsData({ pending: true }));

  const response = yield apply(api, api.settings.getSettings, [params]);

  if (response && response.status >= 200 && response.status < 400) {
    yield put(setSettingsData(response.data));
  }

  yield put(setSettingsData({ pending: false }));
}
