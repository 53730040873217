// Core
import { call, put } from 'redux-saga/effects';

// config
import { api } from '../../../../../config/api';

// actions
import employeesActions from '../../action';

export function* callGetEmployeeByIdWorker({ payload: id }) {
  const {
    setEmployeeById,
  } = employeesActions;
  yield put(setEmployeeById({
    pending: true,
  }));

  const response = yield call(api.company.getEmployeeById, id);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    yield put(setEmployeeById({
      data: {
        ...data,
        active: Boolean(data.active),
      },
    }));
  }

  yield put(setEmployeeById({
    pending: false,
  }));
}
