export const formName = 'order';

export const fieldsAutocomplete = Object.freeze({
  originalClient: 'original.clients',
  originalIndustrial: 'original.industrial',
  originalUtility: 'original.utility',
  originalTrademarks: 'original.trademarks',
  originalInventions: 'original.inventions',
  industrial: 'industrial',
  utility: 'utility',
  trademarks: 'trademarks',
  inventions: 'inventions',
  clients: 'clients',
});
