// Helpers
import actionCreator from '../../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const actionsAsync = Object.freeze({
  getWorksAsync(params) {
    return actionCreator(asyncTypes.GET_PRICES_WORKS_ASYNC, params);
  },
  deleteWorkAsync() {
    return actionCreator(asyncTypes.DELETE_PRICES_WORK_ASYNC);
  },
  putWorkAsync(params) {
    return actionCreator(asyncTypes.PUT_PRICES_WORK_ASYNC, params);
  },
  getWorkByIdAsync(params) {
    return actionCreator(asyncTypes.GET_PRICES_WORK_BY_ID_ASYNC, params);
  },
  postWorkAsync(params) {
    return actionCreator(asyncTypes.POST_PRICES_WORK_ASYNC, params);
  },
});

export default actionsAsync;
