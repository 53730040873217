// Core
import { call, put } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../config/api';

// init
import i18n from '../../../../init/i18n';

// actions
import actions from '../../action';
import asyncAction from '../asyncAction';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../ui/_helpers/setNotificationMessage';

export function* callDeleteTagWorker({ payload: id }) {
  const { setPendingDeleteTag } = actions;

  yield put(setPendingDeleteTag(true));

  const response = yield call(api.tags.deleteTag, id);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      yield put(asyncAction.getListAsync());
      yield put(setSuccessMessage(i18n.t('Tag deleted!'), response.status.toString()));
    }
  }

  yield put(setPendingDeleteTag(false));
}
