export default Object.freeze({
  SET_PAYMENTS_DATA: 'SET_PAYMENTS_DATA',
  SET_PAYMENTS_CURRENT_PAGE: 'SET_PAYMENTS_CURRENT_PAGE',
  SET_PAYMENTS_PAGE_SIZE: 'SET_PAYMENTS_PAGE_SIZE',
  SET_PAYMENTS_FILTERS: 'SET_PAYMENTS_FILTERS',
  SET_PAYMENTS_SORTING: 'SET_PAYMENTS_SORTING',
  SET_PAYMENTS_INITIAL_STATE: 'SET_PAYMENTS_INITIAL_STATE',
  SET_PAYMENTS_RELOAD: 'SET_PAYMENTS_RELOAD',
  SET_DELETE_PAYMENT: 'SET_DELETE_PAYMENT',
  SET_PAYMENTS_APPLY: 'SET_PAYMENTS_APPLY',
  SET_PAYMENT_BY_ID: 'SET_PAYMENT_BY_ID',
  SET_CREATE_PAYMENT: 'SET_CREATE_PAYMENT',
  SET_PAYMENT_DATA_BY_ID: 'SET_PAYMENT_DATA_BY_ID',
  SET_PAYMENTS_COLUMN_WIDTHS: 'SET_PAYMENTS_COLUMN_WIDTHS',
  SET_PAYMENTS_COLUMN_ORDER: 'SET_PAYMENTS_COLUMN_ORDER',
  SET_PAYMENTS_HIDDEN_COLUMN_NAMES: 'SET_PAYMENTS_HIDDEN_COLUMN_NAMES',
});
