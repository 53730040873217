// Core
import { getIn, List, Map } from 'immutable';

// keys
import { stateKeys as companyKeys } from '../core/company/profile/reducer';
import { stateKeys as integrationKeys } from '../core/company/integration/reducer';
import { stateKeys as currenciesKeys } from '../core/currencies/currenciesList/reducer';
import { stateKeys as currencyRatesKeys } from '../core/currencies/currencyRates/reducer';
import { stateKeys as importObjectsKeys } from '../core/importObjects/reducer';
import { stateKeys as uploadFileKeys } from '../core/uploadFile/reducer';
import { stateKeys as pricesCategoryKeys } from '../core/prices/category/reducer';
import { stateKeys as emailKeys } from '../core/email/email/reducer';
import { stateKeys as emailTemplateKeys } from '../core/email/emailTemplate/reducer';
import { stateKeys as pricesTariffsKeys } from '../core/prices/tariffs/reducer';
import { stateKeys as pricesWorksKeys } from '../core/prices/works/reducer';
import { stateKeys as worksKeys } from '../core/works/reducer';
import { stateKeys as jurisdictionKeys } from '../core/jurisdictions/reducer';
import { stateKeys as systemMessageKeys } from '../core/systemMessage/reducer';
import { stateKeys as loggingKeys } from '../core/logging/reducer';
import { stateKeys as usersFiltersKeys } from '../core/usersFilters/reducer';
import { stateKeys as taxesKeys } from '../core/taxes/reducer';
import { stateKeys as documentTypesKeys } from '../core/documentTypes/reducer';
import { stateKeys as documentTypesCategoryKeys } from '../core/documentTypes/category/reducer';
import { stateKeys as tagsKeys } from '../core/tags/reducer';
import { stateKeys as actionTypesKeys } from '../core/actionTypes/reducer';
import { stateKeys as notificationsKeys } from '../core/notifications/reducer';
import { stateKeys as departmentDocumentsKeys } from '../core/departmentDocuments/reducer';
import { stateKeys as departmentDocumentsModalKeys } from '../core/departmentDocuments/documentsModal/reducer';
import { stateKeys as documentTemplatesKeys } from '../core/documentTemplates/reducer';
import { stateKeys as proposalDocumentsOutKeys } from '../core/tradeMarks/proposal/documents/out/reducer';
import { stateKeys as proposalDocumentsInKeys } from '../core/tradeMarks/proposal/documents/input/reducer';
import { stateKeys as tradeMarksProposalKeys } from '../core/tradeMarks/proposal/reducer';
import { stateKeys as madridCaseKeys } from '../core/tradeMarks/madridCase/reducer';
import { stateKeys as tradeMarksReboundsKeys } from '../core/tradeMarks/rebounds/reducer';
import { stateKeys as tradeMarksMktuTemplatesKeys } from '../core/tradeMarks/mktuTemplates/reducer';
import { stateKeys as tradeMarksSearchesKeys } from '../core/tradeMarks/searches/reducer';
import { stateKeys as tradeMarksCalculationsKeys } from '../core/tradeMarks/calculations/reducer';
import { stateKeys as tradeMarksMonitoringKeys } from '../core/tradeMarks/monitoring/reducer';
import { stateKeys as industrialDesignsMonitoringKeys } from '../core/industrialDesigns/reducer';
import { stateKeys as inventionsProposalKeys } from '../core/inventions/reducer';
import { stateKeys as utilityModelsProposalKeys } from '../core/utilityModels/reducer';
import { stateKeys as pendingActionsKeys } from '../core/pendingActions/reducer';
import { stateKeys as pendingActionsTableKeys } from '../core/pendingActions/table/reducer';
import { stateKeys as employeesKeys } from '../core/company/employees/reducer';
import { stateKeys as employeesTableKeys } from '../core/company/employees/table/reducer';
import { stateKeys as clientsKeys } from '../core/company/clients/reducer';
import { stateKeys as clientsTableKeys } from '../core/company/clients/table/reducer';
import { stateKeys as paymentsTableKeys } from '../core/company/payments/reducer';
import { stateKeys as paymentAllocationsKeys } from '../core/paymentAllocations/reducer';
import { stateKeys as ordersTableKeys } from '../core/company/orders/reducer';
import { stateKeys as chargesKeys } from '../core/charges/reducer';
import { stateKeys as fieldsSettingKeys } from '../core/fieldsSettings/reducer';
import { stateKeys as helpersKeys } from '../core/helpers/reducer';
import { stateKeys as applicantsKeys } from '../core/applicants/reducer';
import { stateKeys as paymentReceiversKeys } from '../core/paymentReceivers/reducer';
import { stateKeys as sendingTableKeys } from '../core/sending/reducer';
import { stateKeys as settingsTableKeys } from '../core/settings/reducer';
import { stateKeys as pricesTariffKeys } from '../core/prices/tariffs/tariff/reducer';
import { stateKeys as receivingKeys } from '../core/receiving/reducer';
import { stateKeys as permissionsGroupsKeys } from '../core/permissionsGroups/reducer';
import { stateKeys as productsKeys } from '../core/products/reducer';
import { stateKeys as productCategoryKeys } from '../core/products/productCategory/reducer';
import { stateKeys as dashboardKeys } from '../core/dashboard/reducer';
import globalStateKeys from './globalStateKeys';

// Data
import { pageSizes } from './globalConfig';

const defaultPageSize = pageSizes[0];

const selectorsTable = (propertyData, keys) => Object.freeze({
  [propertyData]: (state) => getIn(state, [
    keys.controller,
    globalStateKeys.data,
  ], Map()),
  pending: (state) => getIn(state, [
    keys.controller,
    globalStateKeys.data,
    globalStateKeys.pending,
  ], false),
  // Paging
  totalCount: (state) => getIn(state, [
    keys.controller,
    globalStateKeys.data,
    globalStateKeys.totalCount,
  ], 0),
  currentPage: (state) => getIn(state, [
    keys.controller,
    globalStateKeys.data,
    globalStateKeys.currentPage,
  ], 0),
  pageSize: (state) => getIn(state, [
    keys.controller,
    globalStateKeys.data,
    globalStateKeys.pageSize,
  ], defaultPageSize),
  filters: (state) => getIn(state, [
    keys.controller,
    globalStateKeys.data,
    globalStateKeys.filters,
  ], List()).toJS(),
  sorting: (state) => getIn(state, [
    keys.controller,
    globalStateKeys.data,
    globalStateKeys.sorting,
  ], List()).toJS(),
  reload: (state) => getIn(state, [
    keys.controller,
    globalStateKeys.data,
    globalStateKeys.reload,
  ], false),
  columnWidths: (state) => getIn(state, [
    keys.controller,
    globalStateKeys.data,
    globalStateKeys.columnWidths,
  ], List()).toJS(),
  columnOrder: (state) => getIn(state, [
    keys.controller,
    globalStateKeys.data,
    globalStateKeys.columnOrder,
  ], List()).toJS(),
  hiddenColumnNames: (state) => getIn(state, [
    keys.controller,
    globalStateKeys.data,
    globalStateKeys.hiddenColumnNames,
  ], List()).toJS(),
});

const user = Object.freeze({
  userRoles: (state) => getIn(state, ['user', 'userInfo', 'roles'], []),
  permissions: (state) => getIn(state, ['user', 'userInfo', 'permissions'], []),
  userInfo: (state) => getIn(state, [
    'user',
    'userInfo',
  ], {}),
  pending: (state) => getIn(state, [
    'user',
    globalStateKeys.pending,
  ], false),
  pendingResetPassword: (state) => getIn(state, [
    'user',
    'pendingResetPassword',
  ], false),
});
const form = Object.freeze({
  getFormValues: (state, formName) => getIn(state, ['form', formName, 'values'], Map()),
});

const importObjects = Object.freeze({
  pendingPostImportObjects: (state) => getIn(state, [
    importObjectsKeys.controller,
    globalStateKeys.pendingPost,
  ], false),
  pendingSpecialDocuments: (state) => getIn(state, [
    importObjectsKeys.controller,
    importObjectsKeys.pendingSpecialDocuments,
  ], false),
  pendingPostExcel: (state) => getIn(state, [
    importObjectsKeys.controller,
    importObjectsKeys.pendingPostExcel,
  ], false),
  specialDocuments: (state) => getIn(state, [
    importObjectsKeys.controller,
    importObjectsKeys.specialDocuments,
  ], Map()),
  selectedObjects: (state) => getIn(state, [
    importObjectsKeys.controller,
    importObjectsKeys.selectedObjects,
  ], Map()),
  entityList: (state) => getIn(state, [
    importObjectsKeys.controller,
    globalStateKeys.entityList,
  ], Map()),
  isModalOpen: (state) => getIn(state, [
    importObjectsKeys.controller,
    globalStateKeys.isModalOpen,
  ], false),
  isModalOpenObject: (state) => getIn(state, [
    importObjectsKeys.controller,
    importObjectsKeys.isModalOpenObject,
  ], false),
  entityListNumber: (state) => getIn(state, [
    importObjectsKeys.controller,
    importObjectsKeys.entityListNumber,
  ], Map()),
});

const uploadFile = Object.freeze({
  uploadFile: (state) => getIn(state, [
    uploadFileKeys.controller,
    globalStateKeys.data,
    uploadFileKeys.file,
  ], Map()),
  uploadFileType: (state) => getIn(state, [
    uploadFileKeys.controller,
    globalStateKeys.data,
    globalStateKeys.type,
  ], null),
  pendingPostUploadFile: (state) => getIn(state, [
    uploadFileKeys.controller,
    globalStateKeys.data,
    globalStateKeys.pendingPost,
  ], false),
  uploadFiles: (state) => getIn(state, [
    uploadFileKeys.controller,
    globalStateKeys.uploadFiles,
  ], List()),
});

const pendingActions = Object.freeze({
  ...selectorsTable('pendingActionData', pendingActionsKeys),
  getPendingActions: (state) => getIn(state, [
    pendingActionsKeys.controller,
    globalStateKeys.items,
    globalStateKeys.data,
  ], List()),
  isModalOpenAddAction: (state) => getIn(state, [
    pendingActionsKeys.controller,
    globalStateKeys.items,
    globalStateKeys.isModalOpen,
  ], false),
  pendingPendingActions: (state) => getIn(state, [
    pendingActionsKeys.controller,
    globalStateKeys.items,
    globalStateKeys.pending,
  ], false),
  getPendingActionById: (state) => getIn(state, [
    pendingActionsKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.data,
  ], {}),
  pendingPendingActionById: (state) => getIn(state, [
    pendingActionsKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pending,
  ], false),
  pendingPutPendingActionById: (state) => getIn(state, [
    pendingActionsKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPut,
  ], false),
  pendingPostPendingAction: (state) => getIn(state, [
    pendingActionsKeys.controller,
    globalStateKeys.items,
    globalStateKeys.pendingPost,
  ], false),
  isGoToListPendingActionsPage: (state) => getIn(state, [
    pendingActionsKeys.controller,
    globalStateKeys.items,
    globalStateKeys.goToListPage,
  ], false),
  pendingDeletePendingAction: (state) => getIn(state, [
    pendingActionsKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
  pendingActionIdDelete: (state) => getIn(state, [
    pendingActionsKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),
  pendingActionsAllList: (state) => getIn(state, [
    pendingActionsKeys.controller,
    pendingActionsKeys.pendingActionsAllList,
  ], List()),
  disableReload: (state) => getIn(state, [
    pendingActionsKeys.controller,
    pendingActionsKeys.disableReload,
  ], false),
  entityList: (state) => getIn(state, [
    pendingActionsKeys.controller,
    globalStateKeys.entityList,
  ], Map()),
  isModalOpenAction: (state) => getIn(state, [
    pendingActionsKeys.controller,
    pendingActionsKeys.isModalOpenAction,
  ], false),
  disableNotifications: (state) => getIn(state, [
    pendingActionsKeys.controller,
    pendingActionsKeys.disableNotifications,
  ], false),
});

const proposalDocumentsOut = Object.freeze({
  ...selectorsTable('proposalDocumentsOutData', proposalDocumentsOutKeys),
  pendingDelete: (state) => getIn(state, [
    proposalDocumentsOutKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pending,
  ], false),
  deleteData: (state) => getIn(state, [
    proposalDocumentsOutKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.data,
  ], Map()),
  selectedDocuments: (state) => getIn(state, [
    proposalDocumentsOutKeys.controller,
    proposalDocumentsOutKeys.selectedDocuments,
  ], List()),
  entityName: (state) => getIn(state, [
    proposalDocumentsOutKeys.controller,
    proposalDocumentsOutKeys.entity,
    globalStateKeys.name,
  ], ''),
  entityId: (state) => getIn(state, [
    proposalDocumentsOutKeys.controller,
    proposalDocumentsOutKeys.entity,
    globalStateKeys.id,
  ], ''),
});

const proposalDocumentsIn = Object.freeze({
  ...selectorsTable('proposalDocumentsInData', proposalDocumentsInKeys),
  pendingDelete: (state) => getIn(state, [
    proposalDocumentsInKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pending,
  ], false),
  deleteData: (state) => getIn(state, [
    proposalDocumentsInKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.data,
  ], Map()),
  entityDocIn: (state) => getIn(state, [
    proposalDocumentsInKeys.controller,
    proposalDocumentsInKeys.entity,
  ], Map()),
});

const pendingActionsTable = selectorsTable('pendingActionsData', pendingActionsTableKeys);

const receiving = Object.freeze({
  ...selectorsTable('receivingData', receivingKeys),
  pendingDelete: (state) => getIn(state, [
    receivingKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
  deleteID: (state) => getIn(state, [
    receivingKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),
  pendingPostCheckDocuments: (state) => getIn(state, [
    receivingKeys.controller,
    receivingKeys.pendingPostCheckDocuments,
  ], false),
});

const departmentDocumentsModal = selectorsTable('departmentDocumentsModalData', departmentDocumentsModalKeys);

const departmentDocuments = Object.freeze({
  ...selectorsTable('departmentDocumentsData', departmentDocumentsKeys),
  getDepartmentDocumentById: (state) => getIn(state, [
    departmentDocumentsKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.data,
  ], {}),
  pendingDeleteDepartmentDocument: (state) => getIn(state, [
    departmentDocumentsKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
  pendingDepartmentDocument: (state) => getIn(state, [
    departmentDocumentsKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pending,
  ], false),
  pendingPostDepartmentDocument: (state) => getIn(state, [
    departmentDocumentsKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPost,
  ], false),
  pendingPutDepartmentDocumentById: (state) => getIn(state, [
    departmentDocumentsKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPut,
  ], false),
  pendingDepartmentDocumentById: (state) => getIn(state, [
    departmentDocumentsKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pending,
  ], false),
  pendingAutocompleteOptions: (state) => getIn(state, [
    departmentDocumentsKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.pending,
  ], false),
  departmentDocumentIdDelete: (state) => getIn(state, [
    departmentDocumentsKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),
  autocompleteOptions: (state) => getIn(state, [
    departmentDocumentsKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.items,
  ], List()),
  selectionsModal: (state) => getIn(state, [
    departmentDocumentsKeys.controller,
    globalStateKeys.selectionsModal,
  ], List()),
  getNextNumber: (state) => getIn(state, [
    departmentDocumentsKeys.controller,
    departmentDocumentsKeys.getNextNumber,
  ], ''),
  entityList: (state) => getIn(state, [
    departmentDocumentsKeys.controller,
    departmentDocumentsKeys.entityList,
  ], Map()),
  additionalDocuments: (state) => getIn(state, [
    departmentDocumentsKeys.controller,
    departmentDocumentsKeys.additionalDocuments,
  ], List()),
  selectedDocuments: (state) => getIn(state, [
    departmentDocumentsKeys.controller,
    departmentDocumentsKeys.selectedDocuments,
  ], List()),
  fileSign: (state) => getIn(state, [
    departmentDocumentsKeys.controller,
    departmentDocumentsKeys.fileSign,
  ], List()),
  documentIdSend: (state) => getIn(state, [
    departmentDocumentsKeys.controller,
    departmentDocumentsKeys.documentIdSend,
  ], ''),
});

const charges = Object.freeze({
  ...selectorsTable('chargesData', chargesKeys),
  getChargeById: (state) => getIn(state, [
    chargesKeys.controller,
    globalStateKeys.itemByHashId,
  ], Map()),
  pendingDeleteCharge: (state) => getIn(state, [
    chargesKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
  pendingCharge: (state) => getIn(state, [
    chargesKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pending,
  ], false),
  pendingPostCharge: (state) => getIn(state, [
    chargesKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPost,
  ], false),
  pendingPutChargeById: (state) => getIn(state, [
    chargesKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPut,
  ], false),
  pendingChargeById: (state) => getIn(state, [
    chargesKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pending,
  ], false),
  chargeIdDelete: (state) => getIn(state, [
    chargesKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),
  getChargesIds: (state) => getIn(state, [
    chargesKeys.controller,
    chargesKeys.chargesIds,
  ], List()),
  tabs: (state) => getIn(state, [
    chargesKeys.controller,
    globalStateKeys.tabs,
  ], Map()),
  sumChargesInput: (state) => getIn(state, [
    chargesKeys.controller,
    chargesKeys.sumChargesInput,
  ], Map()),
  isModalOpenChargesTable: (state) => getIn(state, [
    chargesKeys.controller,
    chargesKeys.isModalOpenChargesTable,
  ], false),
  isModalOpenChargesEdit: (state) => getIn(state, [
    chargesKeys.controller,
    chargesKeys.isModalOpenChargesEdit,
  ], false),
  selections: (state) => getIn(state, [
    chargesKeys.controller,
    globalStateKeys.selections,
  ], Map()),
  selectionsModal: (state) => getIn(state, [
    chargesKeys.controller,
    globalStateKeys.selectionsModal,
  ], List()),
  autocompleteOptions: (state) => getIn(state, [
    chargesKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.items,
  ], List()),
});

const documentTemplates = Object.freeze({
  ...selectorsTable('documentTemplatesData', documentTemplatesKeys),
  getDocumentTemplatesById: (state) => getIn(state, [
    documentTemplatesKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.data,
  ], {}),
  pendingDeleteDocumentTemplate: (state) => getIn(state, [
    documentTemplatesKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
  pendingDocumentTemplates: (state) => getIn(state, [
    documentTemplatesKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pending,
  ], false),
  isModalOpen: (state) => getIn(state, [
    documentTemplatesKeys.controller,
    globalStateKeys.data,
    documentTemplatesKeys.isModalOpen,
  ], false),
  pendingPostDocumentTemplates: (state) => getIn(state, [
    documentTemplatesKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPost,
  ], false),
  pendingPutDocumentTemplatesById: (state) => getIn(state, [
    documentTemplatesKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPut,
  ], false),
  pendingDocumentTemplatesById: (state) => getIn(state, [
    documentTemplatesKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pending,
  ], false),
  documentTemplateIdDelete: (state) => getIn(state, [
    documentTemplatesKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),
  autocompleteOptions: (state) => getIn(state, [
    documentTemplatesKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.items,
  ], List()),
  pendingAutocompleteOptions: (state) => getIn(state, [
    documentTemplatesKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.pending,
  ], false),
  originalFile: (state) => getIn(state, [
    documentTemplatesKeys.controller,
    documentTemplatesKeys.originalFile,
  ], Map()),
});

const sending = Object.freeze({
  ...selectorsTable('sendingData', sendingTableKeys),
});

const settings = Object.freeze({
  ...selectorsTable('settingsData', settingsTableKeys),
  responsibleList: (state) => getIn(state, [
    settingsTableKeys.controller,
    settingsTableKeys.responsibleList,
  ], Map()),
});

const paymentReceivers = Object.freeze({
  ...selectorsTable('paymentReceiversData', paymentReceiversKeys),
  getPaymentReceiversById: (state) => getIn(state, [
    paymentReceiversKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.data,
  ], {}),
  pendingPaymentReceivers: (state) => getIn(state, [
    paymentReceiversKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pending,
  ], false),
  isModalOpen: (state) => getIn(state, [
    paymentReceiversKeys.controller,
    globalStateKeys.data,
    globalStateKeys.isModalOpen,
  ], false),
  paymentReceiversIdDelete: (state) => getIn(state, [
    paymentReceiversKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),
  pendingPostPaymentReceivers: (state) => getIn(state, [
    paymentReceiversKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPost,
  ], false),
  pendingPutPaymentReceiversById: (state) => getIn(state, [
    paymentReceiversKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPut,
  ], false),
  pendingPaymentReceiversById: (state) => getIn(state, [
    paymentReceiversKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pending,
  ], false),
  pendingDeletePaymentReceivers: (state) => getIn(state, [
    paymentReceiversKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
});

const pricesTariff = Object.freeze({
  ...selectorsTable('pricesTariffData', pricesTariffKeys),
  // Tariff ===============================
  goToPageTariff: (state) => getIn(state, [
    pricesTariffKeys.controller,
    globalStateKeys.item,
    globalStateKeys.goToListPage,
  ], false),
  pendingTariff: (state) => getIn(state, [
    pricesTariffKeys.controller,
    globalStateKeys.item,
    globalStateKeys.pending,
  ], false),
  pendingTariffPut: (state) => getIn(state, [
    pricesTariffKeys.controller,
    globalStateKeys.item,
    globalStateKeys.pendingPut,
  ], false),
  pendingTariffPost: (state) => getIn(state, [
    pricesTariffKeys.controller,
    globalStateKeys.item,
    globalStateKeys.pendingPost,
  ], false),
  getEditItem: (state) => getIn(state, [
    pricesTariffKeys.controller,
    globalStateKeys.item,
    globalStateKeys.item,
  ], {}),
  getHashID: (state) => getIn(state, [
    pricesTariffKeys.controller,
    globalStateKeys.item,
    globalStateKeys.id,
  ], ''),
  isConfirmModalOpen: (state) => getIn(state, [
    pricesTariffKeys.controller,
    globalStateKeys.modal,
    globalStateKeys.isModalOpen,
  ], false),

  // Tariff Table===============================
  getTariffTableById: (state) => getIn(state, [
    pricesTariffKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.data,
  ], {}),
  pendingTariffTable: (state) => getIn(state, [
    pricesTariffKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pending,
  ], false),
  isModalOpen: (state) => getIn(state, [
    pricesTariffKeys.controller,
    globalStateKeys.data,
    globalStateKeys.isModalOpen,
  ], false),
  getTariffTableByID: (state) => getIn(state, [
    pricesTariffKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),
  pendingPostTariffTable: (state) => getIn(state, [
    pricesTariffKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPost,
  ], false),
  pendingPutTariffTable: (state) => getIn(state, [
    pricesTariffKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPut,
  ], false),
  pendingTariffTableById: (state) => getIn(state, [
    pricesTariffKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pending,
  ], false),
  pendingDeleteTariffTable: (state) => getIn(state, [
    pricesTariffKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
});

const reboundsTable = Object.freeze({
  ...selectorsTable('reboundsData', tradeMarksReboundsKeys),
  pendingDeleteRebound: (state) => getIn(state, [
    tradeMarksReboundsKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
  reboundIdDelete: (state) => getIn(state, [
    tradeMarksReboundsKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),

});

const rebounds = Object.freeze({
  autocompleteOptions: (state) => getIn(state, [
    tradeMarksReboundsKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.items,
  ], List()),
  pendingReboundAutocompleteOptions: (state) => getIn(state, [
    tradeMarksReboundsKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.pending,
  ], false),
  getReboundById: (state) => getIn(state, [
    tradeMarksReboundsKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.data,
  ], Map()),
  getReboundPdfById: (state) => getIn(state, [
    tradeMarksReboundsKeys.controller,
    tradeMarksReboundsKeys.pdf,
    tradeMarksReboundsKeys.links,
  ], Map()),
  getPendingReboundPdfById: (state) => getIn(state, [
    tradeMarksReboundsKeys.controller,
    tradeMarksReboundsKeys.pdf,
    globalStateKeys.pending,
  ], false),
  getPendingReboundById: (state) => getIn(state, [
    tradeMarksReboundsKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pending,
  ], false),
  pendingPostRebound: (state) => getIn(state, [
    tradeMarksReboundsKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPost,
  ], false),
  pendingPutRebound: (state) => getIn(state, [
    tradeMarksReboundsKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPut,
  ], false),
  getSearchRebound: (state) => getIn(state, [
    tradeMarksReboundsKeys.controller,
    tradeMarksReboundsKeys.searchTerms,
    globalStateKeys.data,
  ], Map()),
  pendingSearchRebound: (state) => getIn(state, [
    tradeMarksReboundsKeys.controller,
    tradeMarksReboundsKeys.searchTerms,
    globalStateKeys.pending,
  ], false),
  isModalCreateOpen: (state) => getIn(state, [
    tradeMarksReboundsKeys.controller,
    tradeMarksReboundsKeys.isModalCreateOpen,
  ], false),
  isModalAllTermsOpen: (state) => getIn(state, [
    tradeMarksReboundsKeys.controller,
    tradeMarksReboundsKeys.isModalAllTermsOpen,
  ], false),
  isModalYourListTermsOpen: (state) => getIn(state, [
    tradeMarksReboundsKeys.controller,
    tradeMarksReboundsKeys.isModalYourListTermsOpen,
  ], false),
});

const madridCase = Object.freeze({
  ...selectorsTable('madridCaseData', madridCaseKeys),
  pendingDeleteMadridCase: (state) => getIn(state, [
    madridCaseKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
  idDelete: (state) => getIn(state, [
    madridCaseKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),
  getMadridCaseById: (state) => getIn(state, [
    madridCaseKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.data,
  ], Map()),
  getPendingMadridCaseById: (state) => getIn(state, [
    madridCaseKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pending,
  ], false),
  pendingPostMadridCase: (state) => getIn(state, [
    madridCaseKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPost,
  ], false),
  pendingPutMadridCase: (state) => getIn(state, [
    madridCaseKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPut,
  ], false),
  isModalAddOpen: (state) => getIn(state, [
    madridCaseKeys.controller,
    madridCaseKeys.isModalAddOpen,
  ], false),
});

const mktuTemplatesTable = Object.freeze({
  ...selectorsTable('mktuTemplatesData', tradeMarksMktuTemplatesKeys),
  pendingDeleteMktuTemplate: (state) => getIn(state, [
    tradeMarksMktuTemplatesKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
  mktuTemplateIdDelete: (state) => getIn(state, [
    tradeMarksMktuTemplatesKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),

});

const mktuTemplates = Object.freeze({
  getMktuTemplatesById: (state) => getIn(state, [
    tradeMarksMktuTemplatesKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.data,
  ], Map()),
  getMktuTemplatePdfById: (state) => getIn(state, [
    tradeMarksMktuTemplatesKeys.controller,
    tradeMarksMktuTemplatesKeys.pdf,
    tradeMarksMktuTemplatesKeys.links,
  ], Map()),
  getPendingMktuTemplatePdfById: (state) => getIn(state, [
    tradeMarksMktuTemplatesKeys.controller,
    tradeMarksMktuTemplatesKeys.pdf,
    globalStateKeys.pending,
  ], false),
  getPendingMktuTemplateById: (state) => getIn(state, [
    tradeMarksMktuTemplatesKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pending,
  ], false),
  pendingPostMktuTemplate: (state) => getIn(state, [
    tradeMarksMktuTemplatesKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPost,
  ], false),
  pendingPutMktuTemplate: (state) => getIn(state, [
    tradeMarksMktuTemplatesKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPut,
  ], false),
  isModalAllTermsOpen: (state) => getIn(state, [
    tradeMarksMktuTemplatesKeys.controller,
    tradeMarksMktuTemplatesKeys.isModalAllTermsOpen,
  ], false),
  isModalYourListTermsOpen: (state) => getIn(state, [
    tradeMarksMktuTemplatesKeys.controller,
    tradeMarksMktuTemplatesKeys.isModalYourListTermsOpen,
  ], false),
  autocompleteOptions: (state) => getIn(state, [
    tradeMarksMktuTemplatesKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.items,
  ], Map()),
  pendingAutocompleteOptions: (state) => getIn(state, [
    tradeMarksMktuTemplatesKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.pending,
  ], false),
});
const employeesTable = selectorsTable('employeesData', employeesTableKeys);

const employees = Object.freeze({
  getEmployeeById: (state) => getIn(state, [
    employeesKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.data,
  ], Map()),
  responsibleEmployeeById: (state) => getIn(state, [
    employeesKeys.controller,
    employeesKeys.responsibleEmployeeById,
  ], Map()),
  getCskData: (state) => getIn(state, [
    employeesKeys.controller,
    employeesKeys.csk,
    globalStateKeys.data,
  ], List()),
  getKeysData: (state) => getIn(state, [
    employeesKeys.controller,
    employeesKeys.keys,
    globalStateKeys.data,
  ], List()),
  openFieldsCreateKey: (state) => getIn(state, [
    employeesKeys.controller,
    employeesKeys.keys,
    employeesKeys.openFieldsCreateKey,
  ], false),
  pendingKeysData: (state) => getIn(state, [
    employeesKeys.controller,
    employeesKeys.keys,
    globalStateKeys.pending,
  ], false),
  pendingDeleteKey: (state) => getIn(state, [
    employeesKeys.controller,
    employeesKeys.keys,
    globalStateKeys.pendingDelete,
  ], false),
  pendingPostKeysData: (state) => getIn(state, [
    employeesKeys.controller,
    employeesKeys.keys,
    globalStateKeys.pendingPost,
  ], false),
  pendingEmployeeById: (state) => getIn(state, [
    employeesKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pending,
  ], false),
  pendingPutEmployeeById: (state) => getIn(state, [
    employeesKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPut,
  ], false),
  pendingDeleteEmployee: (state) => getIn(state, [
    employeesKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
  employeeIdDelete: (state) => getIn(state, [
    employeesKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),
  pendingPostEmployee: (state) => getIn(state, [
    employeesKeys.controller,
    globalStateKeys.items,
    globalStateKeys.pendingPost,
  ], false),
  isModalOpenInviteEmployee: (state) => getIn(state, [
    employeesKeys.controller,
    globalStateKeys.items,
    globalStateKeys.isModalOpen,
  ], false),
  isGoToListEmployeesPage: (state) => getIn(state, [
    employeesKeys.controller,
    globalStateKeys.items,
    globalStateKeys.goToListPage,
  ], false),
  autocompleteOptions: (state) => getIn(state, [
    employeesKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.items,
  ], List()),
  pendingAutocompleteOptions: (state) => getIn(state, [
    employeesKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.pending,
  ], false),
  listResponsible: (state) => getIn(state, [
    employeesKeys.controller,
    employeesKeys.listResponsible,
  ], List()),
});

const ordersTable = Object.freeze({
  ...selectorsTable('ordersData', ordersTableKeys),
  pendingDeleteOrder: (state) => getIn(state, [
    ordersTableKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
  getOrderById: (state) => getIn(state, [
    ordersTableKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.data,
  ], {}),
  pendingOrderById: (state) => getIn(state, [
    ordersTableKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pending,
  ], false),
  pendingPutOrderById: (state) => getIn(state, [
    ordersTableKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPut,
  ], false),
  pendingPostOrder: (state) => getIn(state, [
    ordersTableKeys.controller,
    globalStateKeys.data,
    globalStateKeys.pendingPost,
  ], false),
  orderIdDelete: (state) => getIn(state, [
    ordersTableKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),
  documentsOrder: (state) => getIn(state, [
    ordersTableKeys.controller,
    ordersTableKeys.documentsOrder,
  ], List()),
  autocompleteOptions: (state) => getIn(state, [
    ordersTableKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.items,
  ], List()),
  pendingAutocompleteOptions: (state) => getIn(state, [
    ordersTableKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.pending,
  ], false),
  getNextNumber: (state) => getIn(state, [
    ordersTableKeys.controller,
    ordersTableKeys.getNextNumber,
  ], ''),
  entityList: (state) => getIn(state, [
    ordersTableKeys.controller,
    globalStateKeys.entityList,
  ], Map()),
});

const companiesTable = Object.freeze({
  ...selectorsTable('companiesData', companyKeys),
  pendingDeleteCompany: (state) => getIn(state, [
    companyKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
  getCompanyById: (state) => getIn(state, [
    companyKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.data,
  ], Map()),
  pendingCompanyById: (state) => getIn(state, [
    companyKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pending,
  ], false),
  pendingPutCompanyById: (state) => getIn(state, [
    companyKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPut,
  ], false),
  pendingPostCompany: (state) => getIn(state, [
    companyKeys.controller,
    globalStateKeys.data,
    globalStateKeys.pendingPost,
  ], false),
  companyIdDelete: (state) => getIn(state, [
    companyKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),
  autocompleteOptions: (state) => getIn(state, [
    companyKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.items,
  ], List()),
  pendingAutocompleteOptions: (state) => getIn(state, [
    companyKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.pending,
  ], false),
  pendingPostCheckFtp: (state) => getIn(state, [
    companyKeys.controller,
    companyKeys.pendingPostCheckFtp,
  ], false),
  allCompanies: (state) => getIn(state, [
    companyKeys.controller,
    companyKeys.allCompanies,
  ], List()),
  originalLogo: (state) => getIn(state, [
    companyKeys.controller,
    companyKeys.originalLogo,
  ], ''),
  originalDocHeading: (state) => getIn(state, [
    companyKeys.controller,
    companyKeys.originalDocHeading,
  ], ''),
});

const integration = Object.freeze({
  ...selectorsTable('integrationsData', integrationKeys),
  pendingDeleteIntegration: (state) => getIn(state, [
    integrationKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
  getIntegrationById: (state) => getIn(state, [
    integrationKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.data,
  ], Map()),
  pendingIntegrationById: (state) => getIn(state, [
    integrationKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pending,
  ], false),
  pendingPutIntegrationById: (state) => getIn(state, [
    integrationKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPut,
  ], false),
  pendingPostIntegration: (state) => getIn(state, [
    integrationKeys.controller,
    globalStateKeys.data,
    globalStateKeys.pendingPost,
  ], false),
  integrationIdDelete: (state) => getIn(state, [
    integrationKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),
  autocompleteOptions: (state) => getIn(state, [
    integrationKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.items,
  ], List()),
  pendingAutocompleteOptions: (state) => getIn(state, [
    integrationKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.pending,
  ], false),
  pendingPostCheckFtp: (state) => getIn(state, [
    integrationKeys.controller,
    integrationKeys.pendingPostCheckFtp,
  ], false),
  isModalOpen: (state) => getIn(state, [
    integrationKeys.controller,
    globalStateKeys.isModalOpen,
  ], false),
});

const currencies = Object.freeze({
  ...selectorsTable('currenciesData', currenciesKeys),
  pendingDeleteCurrency: (state) => getIn(state, [
    currenciesKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
  pendingPostCurrency: (state) => getIn(state, [
    currenciesKeys.controller,
    globalStateKeys.pendingPost,
  ], false),
  currencyIdDelete: (state) => getIn(state, [
    currenciesKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),
  autocompleteOptions: (state) => getIn(state, [
    currenciesKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.items,
  ], List()),
  pendingAutocompleteOptions: (state) => getIn(state, [
    currenciesKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.pending,
  ], false),
  isModalOpen: (state) => getIn(state, [
    currenciesKeys.controller,
    globalStateKeys.isModalOpen,
  ], false),
  pendingPostCurrencyCalc: (state) => getIn(state, [
    currenciesKeys.controller,
    currenciesKeys.pendingPostCurrencyCalc,
  ], false),
  currencyCalc: (state) => getIn(state, [
    currenciesKeys.controller,
    currenciesKeys.currencyCalc,
  ], Map()),
});

const currencyRates = Object.freeze({
  ...selectorsTable('currencyRatesData', currencyRatesKeys),
  pendingDeleteCurrencyRate: (state) => getIn(state, [
    currencyRatesKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
  pendingPostCurrencyRate: (state) => getIn(state, [
    currencyRatesKeys.controller,
    globalStateKeys.pendingPost,
  ], false),
  currencyRateIdDelete: (state) => getIn(state, [
    currencyRatesKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),
  autocompleteOptions: (state) => getIn(state, [
    currencyRatesKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.items,
  ], List()),
  pendingAutocompleteOptions: (state) => getIn(state, [
    currencyRatesKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.pending,
  ], false),
  isModalOpen: (state) => getIn(state, [
    currencyRatesKeys.controller,
    globalStateKeys.isModalOpen,
  ], false),
});

const jurisdiction = Object.freeze({
  ...selectorsTable('jurisdictionsData', jurisdictionKeys),
  pendingDeleteJurisdiction: (state) => getIn(state, [
    jurisdictionKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
  getJurisdictionById: (state) => getIn(state, [
    jurisdictionKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.data,
  ], Map()),
  pendingJurisdictionById: (state) => getIn(state, [
    jurisdictionKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pending,
  ], false),
  pendingPutJurisdictionById: (state) => getIn(state, [
    jurisdictionKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPut,
  ], false),
  pendingPostJurisdiction: (state) => getIn(state, [
    jurisdictionKeys.controller,
    globalStateKeys.data,
    globalStateKeys.pendingPost,
  ], false),
  jurisdictionIdDelete: (state) => getIn(state, [
    jurisdictionKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),
  autocompleteOptions: (state) => getIn(state, [
    jurisdictionKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.items,
  ], List()),
  pendingAutocompleteOptions: (state) => getIn(state, [
    jurisdictionKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.pending,
  ], false),
  isModalOpen: (state) => getIn(state, [
    jurisdictionKeys.controller,
    globalStateKeys.isModalOpen,
  ], false),
});

const products = Object.freeze({
  ...selectorsTable('productsData', productsKeys),
  pendingDeleteProduct: (state) => getIn(state, [
    productsKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
  getProductById: (state) => getIn(state, [
    productsKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.data,
  ], Map()),
  pendingProductById: (state) => getIn(state, [
    productsKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pending,
  ], false),
  pendingPutProductById: (state) => getIn(state, [
    productsKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPut,
  ], false),
  pendingPostProduct: (state) => getIn(state, [
    productsKeys.controller,
    globalStateKeys.data,
    globalStateKeys.pendingPost,
  ], false),
  productIdDelete: (state) => getIn(state, [
    productsKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),
  isModalOpen: (state) => getIn(state, [
    productsKeys.controller,
    globalStateKeys.isModalOpen,
  ], false),
});

const productCategory = Object.freeze({
  productCategoryData: (state) => getIn(state, [
    productCategoryKeys.controller,
    globalStateKeys.data,
  ], Map()),
  pendingData: (state) => getIn(state, [
    productCategoryKeys.controller,
    globalStateKeys.pending,
  ], false),
  getProductCategoryById: (state) => getIn(state, [
    productCategoryKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.data,
  ], Map()),
  isModalOpen: (state) => getIn(state, [
    productCategoryKeys.controller,
    globalStateKeys.isModalOpen,
  ], false),
  pendingPostProductCategory: (state) => getIn(state, [
    productCategoryKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPost,
  ], false),
  pendingPutProductCategory: (state) => getIn(state, [
    productCategoryKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPut,
  ], false),
  pendingProductCategoryById: (state) => getIn(state, [
    productCategoryKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pending,
  ], false),
  pendingDeleteProductCategory: (state) => getIn(state, [
    productCategoryKeys.controller,
    globalStateKeys.pendingDelete,
  ], false),
  defaultName: (state) => getIn(state, [
    productCategoryKeys.controller,
    productCategoryKeys.defaultName,
  ], ''),
  newProductCategory: (state) => getIn(state, [
    productCategoryKeys.controller,
    productCategoryKeys.newTag,
  ], Map()),
});

const systemMessage = Object.freeze({
  ...selectorsTable('systemMessageData', systemMessageKeys),
  pendingDeleteSystemMessage: (state) => getIn(state, [
    systemMessageKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
  getSystemMessageById: (state) => getIn(state, [
    systemMessageKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.data,
  ], Map()),
  pendingSystemMessageById: (state) => getIn(state, [
    systemMessageKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pending,
  ], false),
  pendingPutSystemMessageById: (state) => getIn(state, [
    systemMessageKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPut,
  ], false),
  pendingPostSystemMessage: (state) => getIn(state, [
    systemMessageKeys.controller,
    globalStateKeys.data,
    globalStateKeys.pendingPost,
  ], false),
  systemMessageIdDelete: (state) => getIn(state, [
    systemMessageKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),
  isModalOpen: (state) => getIn(state, [
    systemMessageKeys.controller,
    globalStateKeys.isModalOpen,
  ], false),
  systemMessageAllList: (state) => getIn(state, [
    systemMessageKeys.controller,
    systemMessageKeys.systemMessageAllList,
  ], List()),
  disableReload: (state) => getIn(state, [
    systemMessageKeys.controller,
    systemMessageKeys.disableReload,
  ], false),
});

const logging = Object.freeze({
  ...selectorsTable('loggingData', loggingKeys),
  pendingDeleteLog: (state) => getIn(state, [
    loggingKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
  getLogById: (state) => getIn(state, [
    loggingKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.data,
  ], Map()),
  pendingLogById: (state) => getIn(state, [
    loggingKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pending,
  ], false),
  pendingPutLogById: (state) => getIn(state, [
    loggingKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPut,
  ], false),
  logIdDelete: (state) => getIn(state, [
    loggingKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),
  isModalOpen: (state) => getIn(state, [
    loggingKeys.controller,
    globalStateKeys.isModalOpen,
  ], false),
});

const taxes = Object.freeze({
  ...selectorsTable('taxesData', taxesKeys),
  pendingDeleteTax: (state) => getIn(state, [
    taxesKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
  taxIdDelete: (state) => getIn(state, [
    taxesKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),
  getTaxById: (state) => getIn(state, [
    taxesKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.data,
  ], Map()),
  pendingTaxById: (state) => getIn(state, [
    taxesKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pending,
  ], false),
  pendingPutTaxById: (state) => getIn(state, [
    taxesKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPut,
  ], false),
  pendingPostTax: (state) => getIn(state, [
    taxesKeys.controller,
    globalStateKeys.data,
    globalStateKeys.pendingPost,
  ], false),
  isModalOpen: (state) => getIn(state, [
    taxesKeys.controller,
    globalStateKeys.isModalOpen,
  ], false),
});

const clients = Object.freeze({
  getClientById: (state) => getIn(state, [
    clientsKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.data,
  ], Map()),
  pendingClientById: (state) => getIn(state, [
    clientsKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pending,
  ], false),
  pendingPutClientById: (state) => getIn(state, [
    clientsKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPut,
  ], false),
  pendingPostClient: (state) => getIn(state, [
    clientsKeys.controller,
    globalStateKeys.data,
    globalStateKeys.pendingPost,
  ], false),
  pendingDeleteClient: (state) => getIn(state, [
    clientsKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
  clientIdDelete: (state) => getIn(state, [
    clientsKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),
  autocompleteOptions: (state) => getIn(state, [
    clientsKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.items,
  ], List()),
  pendingAutocompleteOptions: (state) => getIn(state, [
    clientsKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.pending,
  ], false),
  pendingMergeClients: (state) => getIn(state, [
    clientsKeys.controller,
    clientsKeys.pendingMergeClients,
  ], false),
  isModalOpenMerge: (state) => getIn(state, [
    clientsKeys.controller,
    clientsKeys.isModalOpenMerge,
  ], false),
  isModalOpenAddress: (state) => getIn(state, [
    clientsKeys.controller,
    clientsKeys.isModalOpenAddress,
  ], false),
  pendingSentCredentials: (state) => getIn(state, [
    clientsKeys.controller,
    clientsKeys.pendingSentCredentials,
  ], false),
});

const clientsTable = selectorsTable('clientsData', clientsTableKeys);
const calculationsTable = Object.freeze({
  ...selectorsTable('calculationsData', tradeMarksCalculationsKeys),
  pendingDeleteCalculation: (state) => getIn(state, [
    tradeMarksCalculationsKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
  calculationIdDelete: (state) => getIn(state, [
    tradeMarksCalculationsKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),
});
const paymentsTable = Object.freeze({
  ...selectorsTable('paymentsData', paymentsTableKeys),
  pendingDeletePayment: (state) => getIn(state, [
    paymentsTableKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
  paymentIdDelete: (state) => getIn(state, [
    paymentsTableKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),
  pendingPostPaymentsApply: (state) => getIn(state, [
    paymentsTableKeys.controller,
    paymentsTableKeys.paymentsApply,
    globalStateKeys.pendingPost,
  ], false),
  pendingPostCreatePayment: (state) => getIn(state, [
    paymentsTableKeys.controller,
    paymentsTableKeys.createPayment,
    globalStateKeys.pendingPost,
  ], false),
  getPaymentById: (state) => getIn(state, [
    paymentsTableKeys.controller,
    globalStateKeys.itemByHashId,
  ], Map()),
  getPaymentDataById: (state) => getIn(state, [
    paymentsTableKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.data,
  ], Map()),
  pendingGetPaymentById: (state) => getIn(state, [
    paymentsTableKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pending,
  ], false),
});

const paymentAllocations = Object.freeze({
  ...selectorsTable('paymentAllocationsData', paymentAllocationsKeys),
  pendingDeletePaymentAllocations: (state) => getIn(state, [
    paymentAllocationsKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
  paymentAllocationsIdDelete: (state) => getIn(state, [
    paymentAllocationsKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),
  selections: (state) => getIn(state, [
    paymentAllocationsKeys.controller,
    globalStateKeys.selections,
  ], Map()),
});

const industrialDesignsProposal = Object.freeze({
  ...selectorsTable('proposalData', industrialDesignsMonitoringKeys),
  pendingDeleteProposal: (state) => getIn(state, [
    industrialDesignsMonitoringKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
  getProposalById: (state) => getIn(state, [
    industrialDesignsMonitoringKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.data,
  ], Map()),
  proposalIdDelete: (state) => getIn(state, [
    industrialDesignsMonitoringKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),
  autocompleteOptions: (state) => getIn(state, [
    industrialDesignsMonitoringKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.items,
  ], List()),
  pendingAutocompleteOptions: (state) => getIn(state, [
    industrialDesignsMonitoringKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.pending,
  ], false),
  pendingGetProposalById: (state) => getIn(state, [
    industrialDesignsMonitoringKeys.controller,
    industrialDesignsMonitoringKeys.pendingGetProposalById,
  ], false),
  uploadFiles: (state) => getIn(state, [
    industrialDesignsMonitoringKeys.controller,
    industrialDesignsMonitoringKeys.uploadFiles,
  ], List()),
  registrySelectClasses: (state) => getIn(state, [
    industrialDesignsMonitoringKeys.controller,
    industrialDesignsMonitoringKeys.registrySelectClasses,
  ], List()),
  selectMktuClasses: (state) => getIn(state, [
    industrialDesignsMonitoringKeys.controller,
    industrialDesignsMonitoringKeys.selectMktuClasses,
  ], List()),
  pendingPutProposal: (state) => getIn(state, [
    industrialDesignsMonitoringKeys.controller,
    industrialDesignsMonitoringKeys.pendingPutProposal,
  ], false),
  getImagesVariant: (state) => getIn(state, [
    industrialDesignsMonitoringKeys.controller,
    industrialDesignsMonitoringKeys.imagesVariant,
    globalStateKeys.items,
  ], List()),
  pendingPostProposal: (state) => getIn(state, [
    industrialDesignsMonitoringKeys.controller,
    industrialDesignsMonitoringKeys.pendingPostProposal,
  ], false),
  company: (state) => getIn(state, [
    industrialDesignsMonitoringKeys.controller,
    industrialDesignsMonitoringKeys.company,
    globalStateKeys.item,
  ], Map()),
  companyPending: (state) => getIn(state, [
    industrialDesignsMonitoringKeys.controller,
    industrialDesignsMonitoringKeys.company,
    globalStateKeys.pending,
  ], false),
  getPendingRegistryByNumber: (state) => getIn(state, [
    industrialDesignsMonitoringKeys.controller,
    industrialDesignsMonitoringKeys.pendingGetRegistryByNumber,
  ], false),
  getRegistryByNumber: (state) => getIn(state, [
    industrialDesignsMonitoringKeys.controller,
    industrialDesignsMonitoringKeys.registryByNumber,
  ], Map()),
  getNextNumber: (state) => getIn(state, [
    industrialDesignsMonitoringKeys.controller,
    globalStateKeys.getNextNumber,
  ], ''),
});

const inventionsProposal = Object.freeze({
  ...selectorsTable('proposalData', inventionsProposalKeys),
  autocompleteOptions: (state) => getIn(state, [
    inventionsProposalKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.items,
  ], List()),
  getProposalById: (state) => getIn(state, [
    inventionsProposalKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.data,
  ], Map()),
  pendingAutocompleteOptions: (state) => getIn(state, [
    inventionsProposalKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.pending,
  ], false),
  pendingDeleteProposal: (state) => getIn(state, [
    inventionsProposalKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
  proposalIdDelete: (state) => getIn(state, [
    inventionsProposalKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),
  pendingPutProposal: (state) => getIn(state, [
    inventionsProposalKeys.controller,
    inventionsProposalKeys.pendingPutProposal,
  ], false),
  pendingGetProposalById: (state) => getIn(state, [
    inventionsProposalKeys.controller,
    inventionsProposalKeys.pendingGetProposalById,
  ], false),
  uploadFiles: (state) => getIn(state, [
    inventionsProposalKeys.controller,
    inventionsProposalKeys.uploadFiles,
  ], List()),
  pendingPostProposal: (state) => getIn(state, [
    inventionsProposalKeys.controller,
    inventionsProposalKeys.pendingPostProposal,
  ], false),
  getPendingRegistryByNumber: (state) => getIn(state, [
    inventionsProposalKeys.controller,
    inventionsProposalKeys.pendingGetRegistryByNumber,
  ], false),
  getRegistryByNumber: (state) => getIn(state, [
    inventionsProposalKeys.controller,
    inventionsProposalKeys.registryByNumber,
  ], Map()),
  getNextNumber: (state) => getIn(state, [
    inventionsProposalKeys.controller,
    globalStateKeys.getNextNumber,
  ], ''),
});

const documentTypes = Object.freeze({
  ...selectorsTable('documentTypesData', documentTypesKeys),
  autocompleteOptions: (state) => getIn(state, [
    documentTypesKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.items,
  ], List()),
  getDocumentTypeById: (state) => getIn(state, [
    documentTypesKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.data,
  ], {}),
  documentTypeIdDelete: (state) => getIn(state, [
    documentTypesKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),
  isModalOpen: (state) => getIn(state, [
    documentTypesKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.isModalOpen,
  ], false),
  pendingPostDocumentType: (state) => getIn(state, [
    documentTypesKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPost,
  ], false),
  pendingPutDocumentType: (state) => getIn(state, [
    documentTypesKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPut,
  ], false),
  pendingDocumentTypeById: (state) => getIn(state, [
    documentTypesKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pending,
  ], false),
  pendingDeleteDocumentType: (state) => getIn(state, [
    documentTypesKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
});

const documentTypesCategory = Object.freeze({
  ...selectorsTable('documentTypesCategoryData', documentTypesCategoryKeys),
  autocompleteOptions: (state) => getIn(state, [
    documentTypesCategoryKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.items,
  ], List()),
  getDocumentTypeCategoryById: (state) => getIn(state, [
    documentTypesCategoryKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.data,
  ], Map()),
  isModalOpen: (state) => getIn(state, [
    documentTypesCategoryKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.isModalOpen,
  ], false),
  pendingPostDocumentTypeCategory: (state) => getIn(state, [
    documentTypesCategoryKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPost,
  ], false),
  pendingPutDocumentTypeCategory: (state) => getIn(state, [
    documentTypesCategoryKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPut,
  ], false),
  pendingDocumentTypeCategoryById: (state) => getIn(state, [
    documentTypesCategoryKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pending,
  ], false),
  documentTypeCategoryIdDelete: (state) => getIn(state, [
    documentTypesCategoryKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),
  pendingDeleteDocumentTypeCategory: (state) => getIn(state, [
    documentTypesCategoryKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
});

const email = Object.freeze({
  ...selectorsTable('emailMessageData', emailKeys),
  isModalOpenMessage: (state) => getIn(state, [
    emailKeys.controller,
    globalStateKeys.data,
    globalStateKeys.isModalOpen,
  ], false),
  nextPageMessage: (state) => getIn(state, [
    emailKeys.controller,
    globalStateKeys.data,
    emailKeys.nextPage,
  ], false),
  threadsMessages: (state) => getIn(state, [
    emailKeys.controller,
    emailKeys.threadsMessages,
  ], List()),
  pendingThreadsMessages: (state) => getIn(state, [
    emailKeys.controller,
    emailKeys.pendingThreadsMessages,
  ], false),
  totalCountThreadsMessages: (state) => getIn(state, [
    emailKeys.controller,
    emailKeys.totalCountThreadsMessages,
  ], 0),
  selectedEmail: (state) => getIn(state, [
    emailKeys.controller,
    globalStateKeys.data,
    emailKeys.selectedEmail,
  ], Map()),

  pendingPostSend: (state) => getIn(state, [
    emailKeys.controller,
    emailKeys.pendingPostSend,
  ], false),
  previewText: (state) => getIn(state, [
    emailKeys.controller,
    emailKeys.previewText,
  ], ''),
  pendingPostPreview: (state) => getIn(state, [
    emailKeys.controller,
    emailKeys.pendingPostPreview,
  ], false),
  pendingDownloadAttachment: (state) => getIn(state, [
    emailKeys.controller,
    emailKeys.pendingDownloadAttachment,
  ], false),
  isModalOpenLinkEntity: (state) => getIn(state, [
    emailKeys.controller,
    emailKeys.isModalOpenLinkEntity,
  ], false),

  listSignatures: (state) => getIn(state, [
    emailKeys.controller,
    emailKeys.signatures,
    globalStateKeys.items,
  ], List()),
  isModalOpenSignature: (state) => getIn(state, [
    emailKeys.controller,
    emailKeys.signatures,
    globalStateKeys.isModalOpen,
  ], false),
  signatureById: (state) => getIn(state, [
    emailKeys.controller,
    emailKeys.signatures,
    globalStateKeys.data,
  ], Map()),
  pendingSignature: (state) => getIn(state, [
    emailKeys.controller,
    emailKeys.signatures,
    globalStateKeys.pending,
  ], false),
  pendingSignatureById: (state) => getIn(state, [
    emailKeys.controller,
    emailKeys.signatures,
    emailKeys.pendingById,
  ], false),
  pendingPostSignature: (state) => getIn(state, [
    emailKeys.controller,
    emailKeys.signatures,
    globalStateKeys.pendingPost,
  ], false),
  pendingPutSignature: (state) => getIn(state, [
    emailKeys.controller,
    emailKeys.signatures,
    globalStateKeys.pendingPut,
  ], false),
  pendingDeleteSignature: (state) => getIn(state, [
    emailKeys.controller,
    emailKeys.signatures,
    globalStateKeys.pendingDelete,
  ], false),

  listEmail: (state) => getIn(state, [
    emailKeys.controller,
    emailKeys.email,
    globalStateKeys.items,
  ], List()),
  isModalOpenEmail: (state) => getIn(state, [
    emailKeys.controller,
    emailKeys.email,
    globalStateKeys.isModalOpen,
  ], false),
  emailById: (state) => getIn(state, [
    emailKeys.controller,
    emailKeys.email,
    globalStateKeys.data,
  ], Map()),
  pendingEmailById: (state) => getIn(state, [
    emailKeys.controller,
    emailKeys.email,
    emailKeys.pendingById,
  ], false),
  pendingEmail: (state) => getIn(state, [
    emailKeys.controller,
    emailKeys.email,
    globalStateKeys.pending,
  ], false),
  pendingPostEmail: (state) => getIn(state, [
    emailKeys.controller,
    emailKeys.email,
    globalStateKeys.pendingPost,
  ], false),
  pendingPutEmail: (state) => getIn(state, [
    emailKeys.controller,
    emailKeys.email,
    globalStateKeys.pendingPut,
  ], false),
  pendingDeleteEmail: (state) => getIn(state, [
    emailKeys.controller,
    emailKeys.email,
    globalStateKeys.pendingDelete,
  ], false),
  emailProvider: (state) => getIn(state, [
    emailKeys.controller,
    emailKeys.emailProvider,
  ], ''),
  pendingEmailProvider: (state) => getIn(state, [
    emailKeys.controller,
    emailKeys.pendingEmailProvider,
  ], false),

  listLetters: (state) => getIn(state, [
    emailKeys.controller,
    emailKeys.emailLetters,
    globalStateKeys.items,
  ], List()),
  emailLettersFilters: (state) => getIn(state, [
    emailKeys.controller,
    emailKeys.emailLetters,
    globalStateKeys.filters,
  ], List()).toJS(),
  emailLettersTotalCount: (state) => getIn(state, [
    emailKeys.controller,
    emailKeys.emailLetters,
    globalStateKeys.totalCount,
  ], 0),
  emailLettersCurrentPage: (state) => getIn(state, [
    emailKeys.controller,
    emailKeys.emailLetters,
    globalStateKeys.currentPage,
  ], 0),
  emailLettersPageSize: (state) => getIn(state, [
    emailKeys.controller,
    emailKeys.emailLetters,
    globalStateKeys.pageSize,
  ], defaultPageSize),
  emailLettersReload: (state) => getIn(state, [
    emailKeys.controller,
    emailKeys.emailLetters,
    globalStateKeys.reload,
  ], false),
  letterById: (state) => getIn(state, [
    emailKeys.controller,
    emailKeys.emailLetters,
    globalStateKeys.data,
  ], Map()),
  pendingLetterById: (state) => getIn(state, [
    emailKeys.controller,
    emailKeys.emailLetters,
    emailKeys.pendingById,
  ], false),
  pendingEmailLetters: (state) => getIn(state, [
    emailKeys.controller,
    emailKeys.emailLetters,
    globalStateKeys.pending,
  ], false),
  pendingPostLetter: (state) => getIn(state, [
    emailKeys.controller,
    emailKeys.emailLetters,
    globalStateKeys.pendingPost,
  ], false),
  pendingDeleteLetter: (state) => getIn(state, [
    emailKeys.controller,
    emailKeys.emailLetters,
    globalStateKeys.pendingDelete,
  ], false),
});

const emailTemplate = Object.freeze({
  ...selectorsTable('emailTemplateData', emailTemplateKeys),
  autocompleteOptions: (state) => getIn(state, [
    emailTemplateKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.items,
  ], List()),
  getEmailTemplateById: (state) => getIn(state, [
    emailTemplateKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.data,
  ], Map()),
  isModalOpen: (state) => getIn(state, [
    emailTemplateKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.isModalOpen,
  ], false),
  pendingPostEmailTemplate: (state) => getIn(state, [
    emailTemplateKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPost,
  ], false),
  pendingPutEmailTemplate: (state) => getIn(state, [
    emailTemplateKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPut,
  ], false),
  pendingEmailTemplateById: (state) => getIn(state, [
    emailTemplateKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pending,
  ], false),
  emailTemplateIdDelete: (state) => getIn(state, [
    emailTemplateKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),
  pendingDeleteEmailTemplate: (state) => getIn(state, [
    emailTemplateKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
});

const tags = Object.freeze({
  tagsData: (state) => getIn(state, [
    tagsKeys.controller,
    globalStateKeys.data,
  ], Map()),
  pendingData: (state) => getIn(state, [
    tagsKeys.controller,
    globalStateKeys.pending,
  ], false),
  getTagById: (state) => getIn(state, [
    tagsKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.data,
  ], Map()),
  isModalOpen: (state) => getIn(state, [
    tagsKeys.controller,
    globalStateKeys.isModalOpen,
  ], false),
  pendingPostTag: (state) => getIn(state, [
    tagsKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPost,
  ], false),
  pendingPutTag: (state) => getIn(state, [
    tagsKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPut,
  ], false),
  pendingTagById: (state) => getIn(state, [
    tagsKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pending,
  ], false),
  pendingDeleteTag: (state) => getIn(state, [
    tagsKeys.controller,
    globalStateKeys.pendingDelete,
  ], false),
  defaultName: (state) => getIn(state, [
    tagsKeys.controller,
    tagsKeys.defaultName,
  ], ''),
  newTag: (state) => getIn(state, [
    tagsKeys.controller,
    tagsKeys.newTag,
  ], Map()),
});

const actionTypes = Object.freeze({
  actionTypesData: (state) => getIn(state, [
    actionTypesKeys.controller,
    globalStateKeys.data,
  ], Map()),
  pendingData: (state) => getIn(state, [
    actionTypesKeys.controller,
    globalStateKeys.pending,
  ], false),
  getActionTypeById: (state) => getIn(state, [
    actionTypesKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.data,
  ], Map()),
  isModalOpen: (state) => getIn(state, [
    actionTypesKeys.controller,
    globalStateKeys.isModalOpen,
  ], false),
  pendingPostActionType: (state) => getIn(state, [
    actionTypesKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPost,
  ], false),
  pendingPutActionType: (state) => getIn(state, [
    actionTypesKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPut,
  ], false),
  pendingActionTypeById: (state) => getIn(state, [
    actionTypesKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pending,
  ], false),
  pendingDeleteActionType: (state) => getIn(state, [
    actionTypesKeys.controller,
    globalStateKeys.pendingDelete,
  ], false),
  defaultName: (state) => getIn(state, [
    actionTypesKeys.controller,
    actionTypesKeys.defaultName,
  ], ''),
  newActionType: (state) => getIn(state, [
    actionTypesKeys.controller,
    actionTypesKeys.newActionType,
  ], Map()),
});

const notifications = Object.freeze({
  ...selectorsTable('notificationsData', notificationsKeys),
  autocompleteOptions: (state) => getIn(state, [
    notificationsKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.items,
  ], List()),
  getNotificationById: (state) => getIn(state, [
    notificationsKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.data,
  ], {}),
  notificationIdDelete: (state) => getIn(state, [
    notificationsKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),
  isModalOpen: (state) => getIn(state, [
    notificationsKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.isModalOpen,
  ], false),
  pendingPostNotification: (state) => getIn(state, [
    notificationsKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPost,
  ], false),
  pendingPutNotification: (state) => getIn(state, [
    notificationsKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPut,
  ], false),
  pendingNotificationById: (state) => getIn(state, [
    notificationsKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pending,
  ], false),
  pendingDeleteNotification: (state) => getIn(state, [
    notificationsKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
  pendingAutocompleteOptions: (state) => getIn(state, [
    notificationsKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.pending,
  ], false),
});

const utilityModelsProposal = Object.freeze({
  ...selectorsTable('proposalData', utilityModelsProposalKeys),
  pendingDeleteProposal: (state) => getIn(state, [
    utilityModelsProposalKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
  getProposalById: (state) => getIn(state, [
    utilityModelsProposalKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.data,
  ], Map()),
  proposalIdDelete: (state) => getIn(state, [
    utilityModelsProposalKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),
  autocompleteOptions: (state) => getIn(state, [
    utilityModelsProposalKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.items,
  ], List()),
  pendingAutocompleteOptions: (state) => getIn(state, [
    utilityModelsProposalKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.pending,
  ], false),
  pendingGetProposalById: (state) => getIn(state, [
    utilityModelsProposalKeys.controller,
    utilityModelsProposalKeys.pendingGetProposalById,
  ], false),
  uploadFiles: (state) => getIn(state, [
    utilityModelsProposalKeys.controller,
    utilityModelsProposalKeys.uploadFiles,
  ], List()),
  registrySelectClasses: (state) => getIn(state, [
    utilityModelsProposalKeys.controller,
    utilityModelsProposalKeys.registrySelectClasses,
  ], List()),
  selectMktuClasses: (state) => getIn(state, [
    utilityModelsProposalKeys.controller,
    utilityModelsProposalKeys.selectMktuClasses,
  ], List()),
  pendingPutProposal: (state) => getIn(state, [
    utilityModelsProposalKeys.controller,
    utilityModelsProposalKeys.pendingPutProposal,
  ], false),
  getImagesVariant: (state) => getIn(state, [
    utilityModelsProposalKeys.controller,
    utilityModelsProposalKeys.imagesVariant,
    globalStateKeys.items,
  ], List()),
  pendingPostProposal: (state) => getIn(state, [
    utilityModelsProposalKeys.controller,
    utilityModelsProposalKeys.pendingPostProposal,
  ], false),
  getPendingRegistryByNumber: (state) => getIn(state, [
    utilityModelsProposalKeys.controller,
    utilityModelsProposalKeys.pendingGetRegistryByNumber,
  ], false),
  getRegistryByNumber: (state) => getIn(state, [
    utilityModelsProposalKeys.controller,
    utilityModelsProposalKeys.registryByNumber,
  ], Map()),
  getNextNumber: (state) => getIn(state, [
    utilityModelsProposalKeys.controller,
    globalStateKeys.getNextNumber,
  ], ''),
});

const tradeMarksSearches = Object.freeze({
  ...selectorsTable('searchesData', tradeMarksSearchesKeys),
  autocompleteOptions: (state) => getIn(state, [
    tradeMarksSearchesKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.items,
  ], List()),
  getSearchById: (state) => getIn(state, [
    tradeMarksSearchesKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.data,
  ], Map()),
  pendingGetSearchById: (state) => getIn(state, [
    tradeMarksSearchesKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pending,
  ], false),
  searchTabs: (state) => getIn(state, [
    tradeMarksSearchesKeys.controller,
    globalStateKeys.tabs,
  ], {}),
  pendingPostSearch: (state) => getIn(state, [
    tradeMarksSearchesKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPost,
  ], false),
  pendingPutSearch: (state) => getIn(state, [
    tradeMarksSearchesKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPut,
  ], false),
  pendingDeleteSearch: (state) => getIn(state, [
    tradeMarksSearchesKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
  searchIdDelete: (state) => getIn(state, [
    tradeMarksSearchesKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),
  isModalOpenCreateConclusion: (state) => getIn(state, [
    tradeMarksSearchesKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.isModalOpen,
  ], false),
  searchReport: (state) => getIn(state, [
    tradeMarksSearchesKeys.controller,
    globalStateKeys.report,
    globalStateKeys.data,
  ], {}),
  pendingSearchReport: (state) => getIn(state, [
    tradeMarksSearchesKeys.controller,
    globalStateKeys.report,
    globalStateKeys.pending,
  ], false),
  quickSearchData: (state) => getIn(state, [
    tradeMarksSearchesKeys.controller,
    tradeMarksSearchesKeys.quick,
  ], Map()),
  pendingPostQuickSearch: (state) => getIn(state, [
    tradeMarksSearchesKeys.controller,
    tradeMarksSearchesKeys.quick,
    globalStateKeys.pendingPost,
  ], false),
  quickSearchItems: (state) => getIn(state, [
    tradeMarksSearchesKeys.controller,
    tradeMarksSearchesKeys.quick,
    globalStateKeys.items,
  ], List()),
  selectedClasses: (state) => getIn(state, [
    tradeMarksSearchesKeys.controller,
    tradeMarksSearchesKeys.selectedClasses,
  ], List()),
  entityList: (state) => getIn(state, [
    tradeMarksSearchesKeys.controller,
    tradeMarksSearchesKeys.entityList,
  ], Map()),
});

const tradeMarksMonitoring = Object.freeze({
  ...selectorsTable('monitoringData', tradeMarksMonitoringKeys),
  autocompleteOptions: (state) => getIn(state, [
    tradeMarksMonitoringKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.items,
  ], List()),
  getMonitoringById: (state) => getIn(state, [
    tradeMarksMonitoringKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.data,
  ], {}),
  pendingDeleteMonitoring: (state) => getIn(state, [
    tradeMarksMonitoringKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
  monitoringIdDelete: (state) => getIn(state, [
    tradeMarksMonitoringKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),
  monitoringTabs: (state) => getIn(state, [
    tradeMarksMonitoringKeys.controller,
    globalStateKeys.tabs,
  ], {}),
  pendingMonitoringReport: (state) => getIn(state, [
    tradeMarksMonitoringKeys.controller,
    globalStateKeys.report,
    globalStateKeys.pending,
  ], false),
  pendingPostMonitoring: (state) => getIn(state, [
    tradeMarksMonitoringKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPost,
  ], false),
  pendingPutMonitoring: (state) => getIn(state, [
    tradeMarksMonitoringKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPut,
  ], false),
});

const tradeMarksProposal = Object.freeze({
  ...selectorsTable('proposalData', tradeMarksProposalKeys),
  getProposalById: (state) => getIn(state, [
    tradeMarksProposalKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.data,
  ], Map()),
  autocompleteOptions: (state) => getIn(state, [
    tradeMarksProposalKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.items,
  ], List()),
  pendingAutocompleteOptions: (state) => getIn(state, [
    tradeMarksProposalKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.pending,
  ], false),
  mktuItems: (state) => getIn(state, [
    tradeMarksProposalKeys.controller,
    tradeMarksProposalKeys.mktu,
    globalStateKeys.items,
  ], List()),
  pendingMktuItems: (state) => getIn(state, [
    tradeMarksProposalKeys.controller,
    tradeMarksProposalKeys.mktu,
    globalStateKeys.pending,
  ], false),
  selectMktuClasses: (state) => getIn(state, [
    tradeMarksProposalKeys.controller,
    tradeMarksProposalKeys.selectMktuClasses,
  ], List()),
  mktuById: (state) => getIn(state, [
    tradeMarksProposalKeys.controller,
    tradeMarksProposalKeys.mktuById,
  ], Map()),
  createdImage: (state) => getIn(state, [
    tradeMarksProposalKeys.controller,
    tradeMarksProposalKeys.createdImage,
  ], Map()),
  pendingCreatedImage: (state) => getIn(state, [
    tradeMarksProposalKeys.controller,
    tradeMarksProposalKeys.pendingCreatedImage,
  ], false),
  classes: (state) => getIn(state, [
    tradeMarksProposalKeys.controller,
    globalStateKeys.classes,
  ], List()),
  classById: (state) => getIn(state, [
    tradeMarksProposalKeys.controller,
    tradeMarksProposalKeys.classById,
    globalStateKeys.items,
  ], Map()),
  termMktuClasses: (state) => getIn(state, [
    tradeMarksProposalKeys.controller,
    tradeMarksProposalKeys.termMktuClasses,
  ], Map()),
  pendingGetClassById: (state) => getIn(state, [
    tradeMarksProposalKeys.controller,
    tradeMarksProposalKeys.classById,
    globalStateKeys.pending,
  ], false),
  registrySelectClasses: (state) => getIn(state, [
    tradeMarksProposalKeys.controller,
    tradeMarksProposalKeys.registrySelectClasses,
  ], List()),
  company: (state) => getIn(state, [
    tradeMarksProposalKeys.controller,
    tradeMarksProposalKeys.company,
    globalStateKeys.item,
  ], Map()),
  companyPending: (state) => getIn(state, [
    tradeMarksProposalKeys.controller,
    tradeMarksProposalKeys.company,
    globalStateKeys.pending,
  ], false),
  pendingDeleteProposal: (state) => getIn(state, [
    tradeMarksProposalKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
  proposalIdDelete: (state) => getIn(state, [
    tradeMarksProposalKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),
  pendingPutProposal: (state) => getIn(state, [
    tradeMarksProposalKeys.controller,
    tradeMarksProposalKeys.pendingPutProposal,
  ], false),
  nextTabProposal: (state) => getIn(state, [
    tradeMarksProposalKeys.controller,
    tradeMarksProposalKeys.nextTab,
  ], false),
  pendingGetProposalById: (state) => getIn(state, [
    tradeMarksProposalKeys.controller,
    tradeMarksProposalKeys.pendingGetProposalById,
  ], false),
  pendingPostMktu: (state) => getIn(state, [
    tradeMarksProposalKeys.controller,
    tradeMarksProposalKeys.pendingPostMktu,
  ], false),

  uploadFiles: (state) => getIn(state, [
    tradeMarksProposalKeys.controller,
    tradeMarksProposalKeys.uploadFiles,
  ], List()),
  mktuModaIsOpen: (state) => getIn(state, [
    tradeMarksProposalKeys.controller,
    tradeMarksProposalKeys.mktuModaIsOpen,
  ], false),
  getPendingRegistryByNumber: (state) => getIn(state, [
    tradeMarksProposalKeys.controller,
    tradeMarksProposalKeys.pendingGetRegistryByNumber,
  ], false),
  getRegistryByNumber: (state) => getIn(state, [
    tradeMarksProposalKeys.controller,
    tradeMarksProposalKeys.registryByNumber,
  ], Map()),
  getNextNumber: (state) => getIn(state, [
    tradeMarksProposalKeys.controller,
    globalStateKeys.getNextNumber,
  ], ''),
  allClassesModalIsOpen: (state) => getIn(state, [
    tradeMarksProposalKeys.controller,
    tradeMarksProposalKeys.allClassesModalIsOpen,
  ], false),
  classesById: (state) => getIn(state, [
    tradeMarksProposalKeys.controller,
    tradeMarksProposalKeys.classesById,
    globalStateKeys.items,
  ], Map()),
  pendingGetClassesById: (state) => getIn(state, [
    tradeMarksProposalKeys.controller,
    tradeMarksProposalKeys.classesById,
    globalStateKeys.pending,
  ], false),
});
const fieldsSetting = Object.freeze({
  fieldsSetting: (state) => getIn(state, [
    fieldsSettingKeys.controller,
    globalStateKeys.data,
  ], Map()).toJS(),
  fieldsEntity: (state) => getIn(state, [
    fieldsSettingKeys.controller,
    fieldsSettingKeys.fieldsEntity,
  ], Map()),
});
const helpers = Object.freeze({
  documentsType: (state) => getIn(state, [
    helpersKeys.controller,
    helpersKeys.documentsType,
  ], List()),
  pendingDocumentsType: (state) => getIn(state, [
    helpersKeys.controller,
    helpersKeys.pendingDocumentsType,
  ], false),
  isModalOpen: (state) => getIn(state, [
    helpersKeys.controller,
    globalStateKeys.modal,
    globalStateKeys.isModalOpen,
  ], false),
  isModalOpenAddCharges: (state) => getIn(state, [
    helpersKeys.controller,
    globalStateKeys.modal,
    helpersKeys.isModalOpenAddCharges,
  ], false),
  isModalOpenAddClient: (state) => getIn(state, [
    helpersKeys.controller,
    globalStateKeys.modal,
    helpersKeys.isModalOpenAddClient,
  ], false),
  isModalOpenAddApplicant: (state) => getIn(state, [
    helpersKeys.controller,
    globalStateKeys.modal,
    helpersKeys.isModalOpenAddApplicant,
  ], false),
  isModalOpenDropZone: (state) => getIn(state, [
    helpersKeys.controller,
    globalStateKeys.modal,
    helpersKeys.isModalOpenDropZone,
  ], false),
  entityApplicantModal: (state) => getIn(state, [
    helpersKeys.controller,
    helpersKeys.entityApplicantModal,
  ], ''),
  isModalOpenDocuments: (state) => getIn(state, [
    helpersKeys.controller,
    globalStateKeys.modal,
    helpersKeys.isModalOpenDocuments,
  ], false),
  isModalOpenDocumentTemplates: (state) => getIn(state, [
    helpersKeys.controller,
    globalStateKeys.modal,
    helpersKeys.isModalOpenDocumentTemplates,
  ], false),
  isModalOpenSupport: (state) => getIn(state, [
    helpersKeys.controller,
    globalStateKeys.modal,
    helpersKeys.isModalOpenSupport,
  ], false),
  isModalOpenRegistry: (state) => getIn(state, [
    helpersKeys.controller,
    globalStateKeys.modal,
    helpersKeys.isModalOpenRegistry,
  ], false),
  isModalOpenSend: (state) => getIn(state, [
    helpersKeys.controller,
    globalStateKeys.modal,
    helpersKeys.isModalOpenSend,
  ], false),
  isModalOpenCheckDocuments: (state) => getIn(state, [
    helpersKeys.controller,
    globalStateKeys.modal,
    helpersKeys.isModalOpenCheckDocuments,
  ], false),
  isModalOpenDocViewer: (state) => getIn(state, [
    helpersKeys.controller,
    globalStateKeys.modal,
    helpersKeys.isModalOpenDocViewer,
  ], false),
  isModalOpenTableEntities: (state) => getIn(state, [
    helpersKeys.controller,
    globalStateKeys.modal,
    helpersKeys.isModalOpenTableEntities,
  ], false),
  supportTicketsData: (state) => getIn(state, [
    helpersKeys.controller,
    helpersKeys.supportTickets,
    globalStateKeys.data,
  ], Map()),
  pendingPostSupportTickets: (state) => getIn(state, [
    helpersKeys.controller,
    helpersKeys.supportTickets,
    globalStateKeys.pendingPost,
  ], false),
  getObjectStatuses: (state) => getIn(state, [
    helpersKeys.controller,
    helpersKeys.objectStatuses,
    globalStateKeys.items,
  ], List()),
  autocompleteGroup: (state) => getIn(state, [
    helpersKeys.controller,
    helpersKeys.autocompleteGroup,
  ], Map()),
  autocompleteFilter: (state) => getIn(state, [
    helpersKeys.controller,
    helpersKeys.autocompleteFilter,
  ], Map()),
  pendingAutocompleteGroup: (state) => getIn(state, [
    helpersKeys.controller,
    helpersKeys.autocompleteGroup,
    globalStateKeys.pendingPost,
  ], false),
  multiple: (state) => getIn(state, [
    helpersKeys.controller,
    globalStateKeys.multiple,
  ], Map()),
  getCheckboxes: (state, tableName) => getIn(state, [
    helpersKeys.controller,
    'checkboxes',
    tableName,
  ], List()),
  openLongMenu: (state, entity) => getIn(state, [
    helpersKeys.controller,
    helpersKeys.longMenu,
    entity,
    globalStateKeys.isOpen,
  ], false),
  currentLongMenu: (state, entity) => getIn(state, [
    helpersKeys.controller,
    helpersKeys.longMenu,
    entity,
    globalStateKeys.items,
  ], Map()),
  pendingSend: (state) => getIn(state, [
    helpersKeys.controller,
    helpersKeys.pendingSend,
  ], false),
  signPending: (state) => getIn(state, [
    helpersKeys.controller,
    globalStateKeys.sign,
    globalStateKeys.pending,
  ], false),
  signList: (state) => getIn(state, [
    helpersKeys.controller,
    globalStateKeys.sign,
    globalStateKeys.items,
  ], List()),
  signIsModalOpen: (state) => getIn(state, [
    helpersKeys.controller,
    globalStateKeys.sign,
    globalStateKeys.isModalOpen,
  ], false),
  permissionSign: (state) => getIn(state, [
    helpersKeys.controller,
    globalStateKeys.sign,
    helpersKeys.permissionSign,
  ], false),
  visibleSidebar: (state) => getIn(state, [
    helpersKeys.controller,
    helpersKeys.visibleSidebar,
  ], true),
  signatureInfo: (state) => getIn(state, [
    helpersKeys.controller,
    helpersKeys.signatureInfo,
  ], Map()),
  filesWithBase64: (state) => getIn(state, [
    helpersKeys.controller,
    helpersKeys.filesWithBase64,
  ], List()),
  imagesWithBase64: (state) => getIn(state, [
    helpersKeys.controller,
    helpersKeys.imagesWithBase64,
  ], List()),
  predefinedInputs: (state) => getIn(state, [
    helpersKeys.controller,
    helpersKeys.predefinedInputs,
  ], Map()),
  notPendingGetById: (state) => getIn(state, [
    helpersKeys.controller,
    helpersKeys.notPendingGetById,
  ], false),
  lastObject: (state) => getIn(state, [
    helpersKeys.controller,
    helpersKeys.lastObject,
  ], Map()),
  allNotificationList: (state) => getIn(state, [
    helpersKeys.controller,
    helpersKeys.allNotificationList,
  ], List()),
  initialLongMenu: (state) => getIn(state, [
    helpersKeys.controller,
    helpersKeys.initialLongMenu,
  ], false),
  isOpenNotification: (state) => getIn(state, [
    helpersKeys.controller,
    helpersKeys.isOpenNotification,
  ], false),
  pendingPostRenewal: (state) => getIn(state, [
    helpersKeys.controller,
    helpersKeys.pendingPostRenewal,
  ], false),
  selectedCompany: (state) => getIn(state, [
    helpersKeys.controller,
    helpersKeys.selectedCompany,
  ], ''),
  selectedEntities: (state) => getIn(state, [
    helpersKeys.controller,
    helpersKeys.selectedEntities,
  ], List()),
  pendingDownloadFile: (state) => getIn(state, [
    helpersKeys.controller,
    helpersKeys.pendingDownloadFile,
  ], false),
  pendingDownloadExport: (state) => getIn(state, [
    helpersKeys.controller,
    helpersKeys.pendingDownloadExport,
  ], false),
  checkboxClose: (state) => getIn(state, [
    helpersKeys.controller,
    helpersKeys.checkboxClose,
  ], false),
  startSend: (state) => getIn(state, [
    helpersKeys.controller,
    helpersKeys.startSend,
  ], false),
  previewFileById: (state) => getIn(state, [
    helpersKeys.controller,
    helpersKeys.previewFileById,
    helpersKeys.file,
  ], ''),
  pendingPreviewFileById: (state) => getIn(state, [
    helpersKeys.controller,
    helpersKeys.previewFileById,
    globalStateKeys.pending,
  ], false),
  getTableName: (state) => getIn(state, [
    helpersKeys.controller,
    helpersKeys.tableName,
  ], ''),
  successDetBase64: (state) => getIn(state, [
    helpersKeys.controller,
    helpersKeys.successDetBase64,
  ], false),
});

const pricesCategory = Object.freeze({
  ...selectorsTable('categoriesData', pricesCategoryKeys),
  isModalOpen: (state) => getIn(state, [
    pricesCategoryKeys.controller,
    globalStateKeys.isModalOpen,
  ], false),
  getCategoryById: (state) => getIn(state, [
    pricesCategoryKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.data,
  ], Map()),
  pendingDeleteCategory: (state) => getIn(state, [
    pricesCategoryKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
  categoryIdDelete: (state) => getIn(state, [
    pricesCategoryKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),
  pendingPostCategory: (state) => getIn(state, [
    pricesCategoryKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPost,
  ], false),
  pendingPutCategory: (state) => getIn(state, [
    pricesCategoryKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPut,
  ], false),
});

const works = Object.freeze({
  ...selectorsTable('worksData', worksKeys),
  pendingDeleteWork: (state) => getIn(state, [
    worksKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
  workIdDelete: (state) => getIn(state, [
    worksKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),
});

const pricesTariffs = Object.freeze({
  ...selectorsTable('tariffsData', pricesTariffsKeys),
  pendingDeleteTariff: (state) => getIn(state, [
    pricesTariffsKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
  tariffIdDelete: (state) => getIn(state, [
    pricesTariffsKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),
  tariffIdTypes: (state) => getIn(state, [
    pricesTariffsKeys.controller,
    globalStateKeys.types,
  ], Map()),
});

const pricesWorks = Object.freeze({
  getWorksData: (state) => getIn(state, [
    pricesWorksKeys.controller,
    globalStateKeys.data,
  ], Map()),
  pendingGetWorks: (state) => getIn(state, [
    pricesWorksKeys.controller,
    globalStateKeys.data,
    globalStateKeys.pending,
  ], false),
  workIdDelete: (state) => getIn(state, [
    pricesWorksKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),
  pendingDelete: (state) => getIn(state, [
    pricesWorksKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
  workIdDeleteNested: (state) => getIn(state, [
    pricesWorksKeys.controller,
    globalStateKeys.deleteNested,
  ], Map()),
  pendingDeleteNested: (state) => getIn(state, [
    pricesWorksKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
  isModalOpenAddWork: (state) => getIn(state, [
    pricesWorksKeys.controller,
    globalStateKeys.data,
    pricesWorksKeys.isModalOpenAddWork,
  ], false),
  getWorkById: (state) => getIn(state, [
    pricesWorksKeys.controller,
    globalStateKeys.itemByHashId,
    pricesWorksKeys.idWorkData,
  ], {}),
});

const permissionsGroups = Object.freeze({
  ...selectorsTable('permissionsGroupsData', permissionsGroupsKeys),
  permissionsGroupIdDelete: (state) => getIn(state, [
    permissionsGroupsKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),
  pendingDelete: (state) => getIn(state, [
    permissionsGroupsKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
  getPermissionsGroupById: (state) => getIn(state, [
    permissionsGroupsKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.data,
  ], Map()),
  pendingPostPermissionsGroup: (state) => getIn(state, [
    permissionsGroupsKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPost,
  ], false),
  pendingPutPermissionsGroup: (state) => getIn(state, [
    permissionsGroupsKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pendingPut,
  ], false),
  isModalOpen: (state) => getIn(state, [
    permissionsGroupsKeys.controller,
    globalStateKeys.isModalOpen,
  ], false),
  getPermissions: (state) => getIn(state, [
    permissionsGroupsKeys.controller,
    permissionsGroupsKeys.permissions,
    globalStateKeys.items,
  ], List()),
  pendingPermissions: (state) => getIn(state, [
    permissionsGroupsKeys.controller,
    permissionsGroupsKeys.permissions,
    globalStateKeys.pending,
  ], false),
});

const applicants = Object.freeze({
  ...selectorsTable('applicantsData', applicantsKeys),
  openFormAddApplicant: (state) => getIn(state, [
    applicantsKeys.controller,
    globalStateKeys.data,
    applicantsKeys.openFormAddApplicant,
  ], false),
  reloadApplicants: (state) => getIn(state, [
    applicantsKeys.controller,
    globalStateKeys.data,
    globalStateKeys.reload,
  ], false),
  list: (state) => getIn(state, [applicantsKeys.controller, globalStateKeys.items], List()),
  getApplicantId: (state) => getIn(state, [
    applicantsKeys.controller,
    globalStateKeys.data,
    applicantsKeys.applicantId,
  ], ''),
  getApplicantNewList: (state) => getIn(state, [
    applicantsKeys.controller,
    applicantsKeys.newApplicantsList,
  ], List()),
  getNewApplicant: (state) => getIn(state, [
    applicantsKeys.controller,
    applicantsKeys.newApplicant,
  ], Map()),
  getOwnersNewList: (state) => getIn(state, [
    applicantsKeys.controller,
    applicantsKeys.newOwnersList,
  ], List()),
  getAuthorsNewList: (state) => getIn(state, [
    applicantsKeys.controller,
    applicantsKeys.newAuthorsList,
  ], List()),
  getApplicantById: (state) => getIn(state, [
    applicantsKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.data,
  ], Map()),
  pendingApplicantById: (state) => getIn(state, [
    applicantsKeys.controller,
    globalStateKeys.itemByHashId,
    globalStateKeys.pending,
  ], false),
  pendingApplicants: (state) => getIn(state, [
    applicantsKeys.controller,
    globalStateKeys.data,
    globalStateKeys.pending,
  ], false),
  isModalOpen: (state) => getIn(state, [
    applicantsKeys.controller,
    globalStateKeys.data,
    globalStateKeys.isModalOpen,
  ], false),
  getApplicantInitialList: (state) => getIn(state, [
    applicantsKeys.controller,
    applicantsKeys.initialList,
  ], Map()),
  pendingApplicantsAutocomplete: (state) => getIn(state, [
    applicantsKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.pending,
  ], false),
  pendingPutApplicant: (state) => getIn(state, [
    applicantsKeys.controller,
    globalStateKeys.data,
    globalStateKeys.pendingPut,
  ], false),
  pendingPostApplicant: (state) => getIn(state, [
    applicantsKeys.controller,
    globalStateKeys.data,
    globalStateKeys.pendingPost,
  ], false),
  applicantIdDelete: (state) => getIn(state, [
    applicantsKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),
  pendingDeleteApplicant: (state) => getIn(state, [
    applicantsKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
  autocompleteOptions: (state) => getIn(state, [
    applicantsKeys.controller,
    globalStateKeys.autocompleteOptions,
    globalStateKeys.items,
  ], List()),
});

const dashboard = Object.freeze({
  filters: (state) => getIn(state, [
    dashboardKeys.controller,
    globalStateKeys.filters,
  ], Map()),
  reload: (state) => getIn(state, [
    dashboardKeys.controller,
    globalStateKeys.reload,
  ], false),
  pending: (state) => getIn(state, [
    dashboardKeys.controller,
    globalStateKeys.pending,
  ], false),
  isOpenFilters: (state) => getIn(state, [
    dashboardKeys.controller,
    dashboardKeys.isOpenFilters,
  ], false),
  numberObjects: (state) => getIn(state, [
    dashboardKeys.controller,
    dashboardKeys.numberObjects,
  ], Map()),
  numberActionInCalendar: (state) => getIn(state, [
    dashboardKeys.controller,
    dashboardKeys.numberActionInCalendar,
  ], List()),
  numberObjectsByClient: (state) => getIn(state, [
    dashboardKeys.controller,
    dashboardKeys.numberObjectsByClient,
  ], List()),
  numberObjectsByCountries: (state) => getIn(state, [
    dashboardKeys.controller,
    dashboardKeys.numberObjectsByCountries,
  ], List()),
  numberObjectsByStatus: (state) => getIn(state, [
    dashboardKeys.controller,
    dashboardKeys.numberObjectsByStatus,
  ], List()),
  numberObjectsByCountriesAndStatus: (state) => getIn(state, [
    dashboardKeys.controller,
    dashboardKeys.numberObjectsByCountriesAndStatus,
  ], Map()),
});

const usersFilters = Object.freeze({
  ...selectorsTable('usersFiltersData', usersFiltersKeys),
  pendingDelete: (state) => getIn(state, [
    usersFiltersKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.pendingDelete,
  ], false),
  idDelete: (state) => getIn(state, [
    usersFiltersKeys.controller,
    globalStateKeys.delete,
    globalStateKeys.id,
  ], ''),
  pendingPost: (state) => getIn(state, [
    usersFiltersKeys.controller,
    globalStateKeys.pendingPost,
  ], false),
  isModalOpen: (state) => getIn(state, [
    usersFiltersKeys.controller,
    globalStateKeys.isModalOpen,
  ], false),
});

const router = Object.freeze({
  key: (state) => getIn(state, ['router', 'location', 'key'], ''),
  pathname: (state) => getIn(state, ['router', 'location', 'pathname'], ''),
  hash: (state) => getIn(state, ['router', 'location', 'hash'], ''),
  query: (state) => getIn(state, ['router', 'location', 'query'], {}),
});

export default Object.freeze({
  user,
  form,
  employees,
  uploadFile,
  employeesTable,
  pricesCategory,
  pricesTariffs,
  pricesWorks,
  documentTypes,
  documentTypesCategory,
  tags,
  email,
  emailTemplate,
  currencies,
  currencyRates,
  jurisdiction,
  notifications,
  receiving,
  works,
  integration,
  charges,
  paymentAllocations,
  departmentDocuments,
  departmentDocumentsModal,
  documentTemplates,
  proposalDocumentsOut,
  proposalDocumentsIn,
  importObjects,
  sending,
  settings,
  pendingActions,
  pendingActionsTable,
  utilityModelsProposal,
  inventionsProposal,
  industrialDesignsProposal,
  calculationsTable,
  tradeMarksSearches,
  tradeMarksMonitoring,
  reboundsTable,
  rebounds,
  mktuTemplatesTable,
  mktuTemplates,
  companiesTable,
  clients,
  clientsTable,
  paymentsTable,
  ordersTable,
  tradeMarksProposal,
  pricesTariff,
  router,
  fieldsSetting,
  paymentReceivers,
  applicants,
  helpers,
  taxes,
  logging,
  systemMessage,
  usersFilters,
  permissionsGroups,
  products,
  productCategory,
  dashboard,
  madridCase,
  actionTypes,
});
