// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const taxesActionAsync = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_TAXES_ASYNC, params);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_TAXES_INITIAL_STATE_ASYNC, path);
  },
  getTaxByIdAsync(id) {
    return actionCreator(asyncTypes.GET_TAX_BY_ID_ASYNC, id);
  },
  postTaxAsync(data) {
    return actionCreator(asyncTypes.POST_TAX_ASYNC, data);
  },
  putTaxByIdAsync(data) {
    return actionCreator(asyncTypes.PUT_TAX_BY_ID_ASYNC, data);
  },
  deleteTaxAsync(id) {
    return actionCreator(asyncTypes.DELETE_TAX_ASYNC, id);
  },
});

export default taxesActionAsync;
