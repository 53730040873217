// core
import React, {
  memo,
} from 'react';
import { spacing } from '@material-ui/system';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// lodash
import { isEmpty, isEqual, map } from 'lodash';

// UI
import {
  CardContent, Grid as MuiGrid,
  Typography as MuiTypography, Card as MuiCard,
  Box, ButtonGroup, Button as MuiButton,
  IconButton as MuiIconButton,
} from '@material-ui/core';

// icon
import {
  Edit as EditIcon,
  Close as CloseIcon,
} from '@material-ui/icons';

// actions
import helpersActions from '../../../../../engine/core/helpers/action';

// parts
import FieldApplicant from '../../../../../ui/Form/FieldsAutocomplete/FieldApplicant';
import ButtonSubmit from '../../../../../ui/Button/ButtonSubmit';

// hooks
import { useEventsAutocompleteAsync } from '../../../../../ui/_hooks/useEventsAutocompleteAsync';

// styles
const Card = styled(MuiCard)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Button = styled(MuiButton)(spacing);
const IconButton = styled(MuiIconButton)(spacing);
const TypographyFlex = styled(MuiTypography)`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;
const TypographySub = styled(MuiTypography)`
  color: ${(props) => props.theme.palette.grey['500']};
`;

function CardApplicants(props) {
  const {
    fieldClient, formName, fieldName, titleHeader,
    handleDelete, handleEdit, pending, disabled,
    list, name, handleSelectOption, textButton,
  } = props;
  const {
    getAutocompleteLists,
    handleGetAsyncData,
  } = useEventsAutocompleteAsync();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleAutocompleteLists = (fieldsName) => {
    if (!isEmpty(fieldClient)) {
      getAutocompleteLists(fieldsName, {
        'clients.id': fieldClient.toJS ? fieldClient.toJS().id : fieldClient.id,
        getList: true,
      });
      return;
    }
    getAutocompleteLists(fieldsName);
  };

  const handleAddApplicantModal = (entity) => {
    dispatch(helpersActions.setEntityApplicantModal(entity));
    dispatch(helpersActions.setModal({ isModalOpenAddApplicant: true }));
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h2" gutterBottom>
          {t(titleHeader)}
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <FieldApplicant
              name={fieldName}
              label="Add from existing"
              getAutocompleteLists={handleAutocompleteLists}
              getAsyncData={handleGetAsyncData}
              formName={formName}
              propsField={{
                getSelectOption: handleSelectOption,
                disabled,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            {!isEmpty(list) && (
              <>
                <TypographySub variant="subtitle1" gutterBottom>
                  {t('Selected')}
                </TypographySub>
                {map(list, (applicant, index) => (
                  <TypographyFlex key={index}>
                    <Box component="span">
                      <Box
                        component="span"
                        fontWeight="fontWeightBold"
                      >
                        {applicant?.name}
                        {applicant?.originalAddress?.name ? ` (${applicant?.originalAddress?.name})` : ''}
                      </Box>
                      <br />
                      {applicant?.address?.address ? `${applicant?.address?.address}, ` : ''}
                      {applicant?.address?.city ? `${applicant?.address?.city}, ` : ''}
                      {applicant?.address?.postalIndex ? `${applicant?.address?.postalIndex}, ` : ''}
                      {applicant?.address?.country}
                      {applicant?.originalAddress?.address ? ` (${applicant?.originalAddress?.address})` : ''}
                    </Box>
                    {!disabled && (
                      <IconButton
                        ml={4}
                        aria-label="Edit"
                        size="small"
                        onClick={() => {
                          handleEdit(applicant, index, name);
                        }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    )}
                    {!disabled && (
                      <IconButton
                        aria-label="Delete"
                        size="small"
                        onClick={() => {
                          handleDelete(index);
                        }}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    )}
                  </TypographyFlex>
                ))}
              </>
            )}
          </Grid>

          {!disabled && (
            <Grid item xs={12}>
              <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                <ButtonSubmit pending={pending} />
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handleAddApplicantModal(name)}
                >
                  {t(textButton)}
                </Button>
              </ButtonGroup>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
CardApplicants.propTypes = {
  titleHeader: PropTypes.string,
  fieldName: PropTypes.string,
  textButton: PropTypes.string,
  formName: PropTypes.string.isRequired,
  fieldClient: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  pending: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  list: PropTypes.oneOfType([
    PropTypes.array,
  ]),
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleSelectOption: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

CardApplicants.defaultProps = {
  titleHeader: 'Applicants',
  fieldName: 'applicantClients',
  textButton: 'NEW APPLICANT',
  list: [],
  fieldClient: {},
};

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.fieldClient, nextProps.fieldClient)
  && isEqual(prevProps.fieldName, nextProps.fieldName)
  && isEqual(prevProps.formName, nextProps.formName)
  && isEqual(prevProps.name, nextProps.name)
  && isEqual(prevProps.list, nextProps.list)
  && isEqual(prevProps.pending, nextProps.pending)
  && isEqual(prevProps.textButton, nextProps.textButton)
  && isEqual(prevProps.disabled, nextProps.disabled)
  && isEqual(prevProps.titleHeader, nextProps.titleHeader);
}

export default memo(CardApplicants, areEqual);
