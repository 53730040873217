// core
import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';

// lodash
import isEmpty from 'lodash/isEmpty';

// config
import selectors from '../../../../../engine/config/selectors';

// parts
import Modal from '../../../../../components/Modal/Modal';
import ChargesTable from './ChargesTable';

// actions
import helpersActions from '../../../../../engine/core/helpers/action';
import chargesActions from '../../../../../engine/core/charges/action';

function ChargesModal(props) {
  const {
    isModalOpenChargesTable, filters,
    handleSend, selectionsModal,
    pendingPostPaymentsApply,
    defaultFilters,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selections = selectionsModal.toJS();
  const [readyDefaultFilters, setReadyDefaultFilters] = useState(false);

  const handleCloseModal = () => {
    dispatch(chargesActions.setChargeById({
      alwaysFilters: {},
    }));
    dispatch(chargesActions.setSumChargesInput({}));
    dispatch(chargesActions.setFilters([]));
    dispatch(chargesActions.setSorting([]));
    dispatch(chargesActions.setModalChargesTable(false));
    dispatch(helpersActions.setAutocompleteGroupOptions({
      items: [],
    }));
    dispatch(chargesActions.setChargesSelectionsModal([]));
    dispatch(chargesActions.setChargesData({
      items: [],
      totalCount: 0,
      pending: false,
    }));
  };

  const handleSendSelections = () => {
    handleSend(selections);
  };

  useEffect(() => {
    if (!isEmpty(defaultFilters) && !readyDefaultFilters) {
      setReadyDefaultFilters(true);
      dispatch(chargesActions.setFilters(defaultFilters));
    }
  }, [
    dispatch, setReadyDefaultFilters,
    defaultFilters, readyDefaultFilters,
  ]);

  return (
    <Modal
      dialogProps={{
        maxWidth: 'md',
      }}
      title={t('Select charges')}
      isModalOpen={isModalOpenChargesTable}
      handleCloseModal={handleCloseModal}
      handleSend={handleSendSelections}
      pending={pendingPostPaymentsApply}
    >
      {((!isEmpty(defaultFilters) && !isEmpty(filters))
        || isEmpty(defaultFilters)
        || isEmpty(filters)
        || (isEmpty(defaultFilters) && !isEmpty(filters)))
      && <ChargesTable isDefaultFilters={!isEmpty(defaultFilters)} />}
    </Modal>
  );
}

ChargesModal.propTypes = {
  handleSend: PropTypes.func.isRequired,
  pendingPostPaymentsApply: PropTypes.bool.isRequired,
  selectionsModal: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  isModalOpenChargesTable: PropTypes.bool.isRequired,
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  defaultFilters: PropTypes.oneOfType([
    PropTypes.array,
  ]),
};

ChargesModal.defaultProps = {
  defaultFilters: [],
};

function mapStateToProps(state) {
  return {
    isModalOpenChargesTable: selectors.charges.isModalOpenChargesTable(state),
    filters: selectors.charges.filters(state),
    selectionsModal: selectors.charges.selectionsModal(state),
    pendingPostPaymentsApply: selectors.paymentsTable.pendingPostPaymentsApply(state),
  };
}

export default compose(
  connect(mapStateToProps, null),
)(ChargesModal);
