// core
import React, { useMemo } from 'react';
import { Field } from 'redux-form/lib/immutable';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Map } from 'immutable';

// lodash
import isEmpty from 'lodash/isEmpty';
import concat from 'lodash/concat';

// parts
import AutocompleteField from '../AutocompleteField';

// config
import selectors from '../../../engine/config/selectors';

// helpers
import { getValueFormField } from '../../_helpers/getValueFormField';
import { searchParams } from './helper/searchParams';

// hooks
import { useMain } from './_hook/useMain';

function FieldEmployee(props) {
  const {
    data, staticOption, formFieldInput, pendingDefaultList,
    autocompleteOptions, name, propsField, label,
    pendingAutocompleteOptions, getAutocompleteLists,
    searchParamName, getAsyncData, formField,
  } = props;
  const { t } = useTranslation();
  const autocompleteOptionsItems = useMemo(() => autocompleteOptions.get(searchParamName), [
    autocompleteOptions, searchParamName,
  ]);
  const {
    startLoading, handleOnFocus, dataItems, checkOptions,
  } = useMain({
    formField,
    getAutocompleteLists,
    formFieldInput,
  });
  const dataOptions = isEmpty(staticOption) ? data : Map({
    ...data.toJS(),
    items: concat(staticOption, data.toJS().items),
  });
  return (
    <Field
      fullWidth
      changeToStore
      name={name}
      label={t(label)}
      margin="normal"
      getAsyncData={getAsyncData}
      loading={startLoading ? (pendingDefaultList || pendingAutocompleteOptions) : false}
      handleOnFocus={handleOnFocus(name, data)}
      options={checkOptions ? dataItems(dataOptions) : autocompleteOptionsItems}
      component={AutocompleteField}
      {...propsField}
    />
  );
}

FieldEmployee.displayName = 'FieldEmployee';

FieldEmployee.propTypes = {
  autocompleteOptions: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.bool,
  ]).isRequired,
  data: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  getAutocompleteLists: PropTypes.func.isRequired,
  pendingDefaultList: PropTypes.bool.isRequired,
  pendingAutocompleteOptions: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  propsField: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  staticOption: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  formField: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array,
  ]),
  formFieldInput: PropTypes.string,
  label: PropTypes.string,
  searchParamName: PropTypes.string,
  getAsyncData: PropTypes.func,
};

FieldEmployee.defaultProps = {
  formField: {},
  staticOption: {},
  propsField: {},
  formFieldInput: '',
  label: 'Employee',
  getAsyncData: () => {},
  searchParamName: searchParams.name,
};

function mapStateToProps(state, props) {
  const { formName, name } = props;
  const formField = getValueFormField(state, formName, name);
  const formFieldInput = getValueFormField(state, formName, `${name}Input`);

  return {
    autocompleteOptions: selectors.employees.autocompleteOptions(state),
    data: selectors.employeesTable.employeesData(state),
    pendingAutocompleteOptions: selectors.employees.pendingAutocompleteOptions(state),
    pendingDefaultList: selectors.employeesTable.pending(state),
    formField: formField(),
    formFieldInput: formFieldInput(),
  };
}

export default compose(
  connect(mapStateToProps, null),
)(FieldEmployee);
