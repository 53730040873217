// Core
import { call, put } from 'redux-saga/effects';

// config
import { api } from '../../../../../config/api';
import { listEntityName } from '../../../../../config/listTablesName';

// actions
import action from '../../action';

const entity = listEntityName.categories;

export function* callGetCategoriesWorker({ payload: params }) {
  const {
    setCategoriesData, setReload,
  } = action;
  yield put(setCategoriesData({
    pending: true,
  }));

  const response = yield call(api.prices.getCategories, { ...params, entity });

  let dataInit = {
    items: [],
    totalCount: 0,
    pending: false,
  };

  if (response && response.status >= 200 && response.status < 400) {
    dataInit = Object.assign(dataInit, response.data);
  }

  yield put(setCategoriesData(dataInit));
  yield put(setReload(false));
}
