// Core
import React from 'react';
import {
  reduxForm, Field, Form,
} from 'redux-form/immutable';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import { spacing } from '@material-ui/system';
import styled from 'styled-components';
import { push } from 'connected-react-router';

// UI
import {
  Avatar,
  Button as MuiButton,
  Paper,
  Typography, CircularProgress,
} from '@material-ui/core';

// parts
import RememberCheckbox from './components/RememberCheckbox';
import { routersLink } from '../../routes';
import selectors from '../../engine/config/selectors';
import RenderTextField from '../../ui/Form/RenderTextField';

// Engine
import userActionAsync from '../../engine/core/user/saga/asyncAction';

// Helpers
import { validators } from '../../ui/_helpers/validation';

// Style
const Button = styled(MuiButton)(spacing);

const Wrapper = styled(Paper)`
    padding: ${(props) => props.theme.spacing(6)}px;
    ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)}px;
    }`;

const BigAvatar = styled(Avatar)`
    width: 272px;
    height: 68px;
    text-align: center;
    margin: 0 auto;
    ${(props) => props.theme.breakpoints.up('md')} {
    margin: 0 auto ${(props) => props.theme.spacing(5)}px;
    width: 320px;
    height: 80px;
    }`;

function SignIn(props) {
  const { handleSubmit, pending, valid } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleSubmits = (formData) => {
    const jsonFormData = formData.toJSON();
    dispatch(userActionAsync.signInAsync(jsonFormData));
  };

  const handleClick = () => {
    dispatch(push(routersLink.users.resetPassword));
  };

  return (
    <Wrapper>
      <Helmet title={t('Sign in')} />
      <BigAvatar variant="square" alt="Lucy" src="/img/lexberryLogo.png" />
      <Typography component="h2" variant="body1" align="center">
        {t('Sign in to your account to continue')}
      </Typography>
      <Form onSubmit={handleSubmit(handleSubmits)}>
        <Field
          name="username"
          component={RenderTextField}
          label={t('User name')}
          margin="normal"
          fullWidth
          required
          validate={validators.required}
        />
        <Field
          name="password"
          component={RenderTextField}
          type="password"
          autoComplete="current-password"
          required
          fullWidth
          label={t('Password')}
          margin="normal"
          validate={validators.required}
        />
        <Field
          component={RememberCheckbox}
          label={t('Remember me')}
          name="rememberMe"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          name="submit"
          mb={2}
          disabled={!valid || pending}
        >
          {pending ? <CircularProgress size={25} color="inherit" /> : t('Sign in')}
        </Button>

        <Button
          onClick={handleClick}
          fullWidth
          color="primary"
        >
          {t('Forgot password')}
        </Button>
      </Form>
    </Wrapper>
  );
}

function mapStateToProps(state) {
  return {
    pending: selectors.user.pending(state),
  };
}

SignIn.propTypes = {
  pending: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default compose(
  connect(mapStateToProps, null),
  reduxForm({
    form: 'signIn',
  }),
)(SignIn);
