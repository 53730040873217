// Core
import { apply, put } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';
import pickBy from 'lodash/pickBy';

// config
import { api } from '../../../../config/api';

// actions
import actions from '../../action';
import docOutActions from '../../../tradeMarks/proposal/documents/out/action';
import depDocActions from '../../../departmentDocuments/action';

// helpers
import { setErrorMessage } from '../../../../../ui/_helpers/setNotificationMessage';

export function* callPostCreatePacketWorker({ payload: params }) {
  yield put(actions.setSignPending(true));

  const filterParams = pickBy(params, (value, key) => key !== 'reloadDocumentsOut');
  const response = yield apply(api, api.sign.postCreatePacket, [filterParams]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      yield put(actions.setSignList([data]));
      yield put(actions.setSignIsModalOpen(false));
      if (params.reloadDocumentsOut) {
        yield put(docOutActions.setReload(true));
        yield put(depDocActions.setReload(true));
        yield put(actions.setCheckboxClose(true));
      }
    }
  }
  yield put(actions.setSignPending(false));
}
