// Core
import isPlainObject from 'lodash/isPlainObject';

// Engine
import { uiActions } from '../../engine/core/ui/action';

const notificationTypes = Object.freeze({
  error: Object.freeze({
    defaultTitle: 'Error',
    type: 'danger',
  }),
  success: Object.freeze({
    defaultTitle: 'Success',
    type: 'success',
  }),
  warning: Object.freeze({
    defaultTitle: 'Error',
    type: 'warning',
  }),
});

function setNotificationMessage(config) {
  const {
    message, title, type, dismissable = true, duration = 10000, closeButton, clickDismiss, reload,
  } = config;

  return uiActions.setNotificationMessage({
    duration,
    message,
    title,
    type,
    dismissable,
    closeButton,
    clickDismiss,
    reload,
  });
}

function convertMessagesArrayToString(errors) {
  if (isPlainObject(errors)) {
    return errors.message;
  }
  return errors.reduce((acc, currentValue) => `${acc}${currentValue.message}\n`, '');
}

export function setSuccessMessage(message,
  title = notificationTypes.success.defaultTitle, dismissable) {
  return setNotificationMessage({
    duration: 3000,
    dismissable,
    message,
    title,
    type: notificationTypes.success.type,
  });
}

export function setErrorMessage(errors, title = notificationTypes.error.defaultTitle, dismissable) {
  const message = typeof errors === 'object' ? convertMessagesArrayToString(errors) : errors;
  return setNotificationMessage({
    dismissable,
    message,
    title,
    type: notificationTypes.error.type,
  });
}

export function setWarningMessage(warnings,
  title = notificationTypes.warning.defaultTitle, dismissable) {
  const message = Array.isArray(warnings) ? convertMessagesArrayToString(warnings) : warnings;
  return setNotificationMessage({
    dismissable,
    message,
    title,
    type: notificationTypes.warning.type,
  });
}
