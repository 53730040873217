// core
import React, { memo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import { Field, Form, reduxForm } from 'redux-form/lib/immutable';

// lodash
import isEmpty from 'lodash/isEmpty';

// config
import selectors from '../../../../../engine/config/selectors';

// parts
import RenderTextField from '../../../../../ui/Form/RenderTextField';
import Modal from '../../../../../components/Modal/Modal';
import DialogActions from '../../../../../components/Modal/components/DialogActions';

// actions
import reboundsAction from '../../../../../engine/core/tradeMarks/rebounds/action';
import proposalAsyncActions from '../../../../../engine/core/tradeMarks/proposal/saga/asyncAction';

function MktuTemplateModal(props) {
  const {
    handleSubmit,
    isModalOpen,
    pendingPostMktu,
    match,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { params } = match;

  const handleCloseModal = () => dispatch(reboundsAction.setModalCreateOpen(false));

  const handleSubmits = (formData) => {
    const jsonData = formData.toJSON();
    if (!isEmpty(params) && !isEmpty(jsonData)) {
      dispatch(proposalAsyncActions.postMktuTemplateAsync({
        ...jsonData,
        mktuId: params.hashId,
      }));
    }
  };

  return (
    <Modal
      title={t('Create template')}
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
      displayDialogActions={false}
    >
      <Form onSubmit={handleSubmit(handleSubmits)}>
        <Field
          id="title"
          name="title"
          label={t('Title')}
          variant="standard"
          margin="normal"
          fullWidth
          my={2}
          component={RenderTextField}
        />

        <DialogActions
          pending={pendingPostMktu}
          handleCloseModal={handleCloseModal}
        />
      </Form>
    </Modal>
  );
}

MktuTemplateModal.displayName = 'MktuTemplateModal';

MktuTemplateModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  pendingPostMktu: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  match: PropTypes.oneOfType([
    PropTypes.object,
  ]),
};

MktuTemplateModal.defaultProps = {
  match: {},
};

function mapStateToProps(state) {
  return {
    isModalOpen: selectors.rebounds.isModalCreateOpen(state),
    pendingPostMktu: selectors.tradeMarksProposal.pendingPostMktu(state),
  };
}

export default compose(
  reduxForm({
    form: 'MktuTemplateModal',
  }),
  connect(mapStateToProps, null),
)(memo(MktuTemplateModal));
