// core
import React, { memo } from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// ui
import {
  CircularProgress,
  Button as MuiButton,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';

const Button = styled(MuiButton)(spacing);

function ButtonSubmit(props) {
  const { pending, label } = props;
  const { t } = useTranslation();
  return (
    <Button
      variant="contained"
      color="primary"
      type="submit"
    >
      {pending ? <CircularProgress size={25} color="inherit" /> : t(label)}
    </Button>
  );
}

ButtonSubmit.displayName = 'ButtonSubmit';

ButtonSubmit.defaultProps = {
  pending: false,
  label: 'SAVE',
};

ButtonSubmit.propTypes = {
  label: PropTypes.string,
  pending: PropTypes.bool,
};

export default memo(ButtonSubmit);
