// Helpers
import actionCreator from '../../../../_helpers/actionCreator';

// Types
import types from './types';

const actions = Object.freeze({
  setGotToPageTariffs(payload) {
    return actionCreator(types.SET_GO_TO_PAGE_TARIFFS, payload);
  },
  setEditItem(data) {
    return actionCreator(types.SET_PRICES_TARIFF_EDIT_ITEM, data);
  },
  setPending(payload) {
    return actionCreator(types.SET_PENDING, payload);
  },
  setConfirmModalOpen(payload) {
    return actionCreator(types.SET_CONFIRM_MODAL_OPEN, payload);
  },
  setHashID(payload) {
    return actionCreator(types.SET_PRICES_TARIFF_HASH_ID, payload);
  },
  setPendingPut(payload) {
    return actionCreator(types.SET_PENDING_PUT, payload);
  },
  setPendingPost(payload) {
    return actionCreator(types.SET_PENDING_POST, payload);
  },
  setDeleteTariff(data) {
    return actionCreator(types.SET_DELETE_PRICES_TARIFF, data);
  },
  setTariffTableById(data) {
    return actionCreator(types.SET_PRICES_TARIFF_TABLE_BY_ID, data);
  },
  setTariffTableData(data) {
    return actionCreator(types.SET_PRICES_TARIFF_TABLE_DATA, data);
  },
  setCurrentPage(currentPage) {
    return actionCreator(types.SET_PRICES_TARIFF_TABLE_CURRENT_PAGE, currentPage);
  },
  setDeleteTariffTable(data) {
    return actionCreator(types.SET_DELETE_PRICES_TARIFF_TABLE, data);
  },
  setPageSize(pageSize) {
    return actionCreator(types.SET_PRICES_TARIFF_TABLE_PAGE_SIZE, pageSize);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_PRICES_TARIFF_TABLE_INITIAL_STATE, initialState);
  },
  setReload(reload) {
    return actionCreator(types.SET_PRICES_TARIFF_TABLE_RELOAD, reload);
  },
  setModalOpen(modalOpen) {
    return actionCreator(types.SET_PRICES_TARIFF_TABLE_MODAL_OPEN, modalOpen);
  },
  setSorting(sorting) {
    return actionCreator(types.SET_TARIFF_TABLE_SORTING, sorting);
  },
  setFilters(filters) {
    return actionCreator(types.SET_TARIFF_TABLE_FILTER, filters);
  },
});

export default actions;
