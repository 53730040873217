export default Object.freeze({
  SET_MODAL: 'SET_MODAL',
  SET_MULTIPLE: 'SET_MULTIPLE',
  SET_AUTOCOMPLETE_GROUP: 'SET_AUTOCOMPLETE_GROUP',
  SET_OBJECT_STATUSES: 'SET_OBJECT_STATUSES',
  SET_DOCUMENTS_DATA: 'SET_DOCUMENTS_DATA',
  SET_DOCUMENTS_CURRENT_PAGE: 'SET_DOCUMENTS_CURRENT_PAGE',
  SET_DOCUMENTS_PAGE_SIZE: 'SET_DOCUMENTS_PAGE_SIZE',
  SET_DOCUMENTS_FILTERS: 'SET_DOCUMENTS_FILTERS',
  SET_DOCUMENTS_SORTING: 'SET_DOCUMENTS_SORTING',
  SET_DOCUMENTS_INITIAL_STATE: 'SET_DOCUMENTS_INITIAL_STATE',
  SET_DOCUMENTS_RELOAD: 'SET_DOCUMENTS_RELOAD',
  SET_DOCUMENTS_TYPE: 'SET_DOCUMENTS_TYPE',
  SET_PENDING_DOCUMENTS_TYPE: 'SET_PENDING_DOCUMENTS_TYPE',
  SET_CHECKBOX: 'SET_CHECKBOX',
  SET_CHECKBOX_CLEAR: 'SET_CHECKBOX_CLEAR',
  SET_CURRENT_LONG_MENU: 'SET_CURRENT_LONG_MENU',
  SET_OPEN_LONG_MENU: 'SET_OPEN_LONG_MENU',
  SET_CLEANUP_LONG_MENU: 'SET_CLEANUP_LONG_MENU',
  SET_SIGN_LIST: 'SET_SIGN_LIST',
  SET_SIGN_PENDING: 'SET_SIGN_PENDING',
  SET_SIGN_MODAL_IS_OPEN: 'SET_SIGN_MODAL_IS_OPEN',
  SET_SUPPORT_TICKETS_DATA: 'SET_SUPPORT_TICKETS_DATA',
  SET_PENDING_POST_SUPPORT_TICKETS: 'SET_PENDING_POST_SUPPORT_TICKETS',
  SET_VISIBLE_SIDEBAR: 'SET_VISIBLE_SIDEBAR',
  SET_SIGNATURE_INFO: 'SET_SIGNATURE_INFO',
  SET_SIGNATURE_INFO_STATE_CLEAR: 'SET_SIGNATURE_INFO_STATE_CLEAR',
  SET_FILES_WITH_BASE_64: 'SET_FILES_WITH_BASE_64',
  SET_FILES_WITH_BASE_64_CLEAR: 'SET_FILES_WITH_BASE_64_CLEAR',
  SET_IMAGES_WITH_BASE_64: 'SET_IMAGES_WITH_BASE_64',
  SET_IMAGES_WITH_BASE_64_CLEAR: 'SET_IMAGES_WITH_BASE_64_CLEAR',
  SET_PREDEFINED_INPUTS: 'SET_PREDEFINED_INPUTS',
  SET_PERMISSION_SIGN: 'SET_PERMISSION_SIGN',
  SET_ENTITY_APPLICANT_MODAL: 'SET_ENTITY_APPLICANT_MODAL',
  SET_NOT_PENDING_GET_BY_ID: 'SET_NOT_PENDING_GET_BY_ID',
  SET_LAST_OBJECT: 'SET_LAST_OBJECT',
  SET_ALL_NOTIFICATION_LIST: 'SET_ALL_NOTIFICATION_LIST',
  SET_INITIAL_LONG_MENU: 'SET_INITIAL_LONG_MENU',
  SET_PENDING_POST_RENEWAL: 'SET_PENDING_POST_RENEWAL',
  SET_SELECTED_COMPANY: 'SET_SELECTED_COMPANY',
  SET_PENDING_SEND: 'SET_PENDING_SEND',
  SET_PENDING_DOWNLOAD_FILE: 'SET_PENDING_DOWNLOAD_FILE',
  SET_IS_OPEN_NOTIFICATION: 'SET_IS_OPEN_NOTIFICATION',
  SET_CHECKBOX_CLOSE: 'SET_CHECKBOX_CLOSE',
  SET_ON_START_SEND: 'SET_ON_START_SEND',
  SET_PREVIEW_FILE_BY_ID: 'SET_PREVIEW_FILE_BY_ID',
  SET_SELECTED_ENTITIES: 'SET_SELECTED_ENTITIES',
  SET_HELPERS_TABLE_NAME: 'SET_HELPERS_TABLE_NAME',
  SET_PENDING_DOWNLOAD_EXPORT: 'SET_PENDING_DOWNLOAD_EXPORT',
  MERGE_AUTOCOMPLETE_FILTER: 'MERGE_AUTOCOMPLETE_FILTER',
  SET_AUTOCOMPLETE_FILTER: 'SET_AUTOCOMPLETE_FILTER',
  SET_SUCCESS_GET_BASE_64: 'SET_SUCCESS_GET_BASE_64',
});
