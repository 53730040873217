// Core
import { call, put } from 'redux-saga/effects';

// config
import { api } from '../../../../../config/api';

// actions
import action from '../../action';

export function* callGetWorkByIdWorker({ payload }) {
  const {
    setGetWorkById,
  } = action;
  yield put(setGetWorkById({
    pending: true,
  }));

  const response = yield call(api.works.getWorkById, payload.id);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    yield put(setGetWorkById({
      idWorkData: {
        ...data,
        data,
      },
    }));
  }

  yield put(setGetWorkById({
    pending: false,
  }));
}
