// core
import React from 'react';
import styled from 'styled-components';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

// ui
import {
  Fab,
} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { spacing } from '@material-ui/system';

const FabMui = styled(Fab)`
  ${spacing}
  position: fixed;
  z-index: 999;
  right: ${(props) => props.theme.spacing(7)}px;
  bottom: ${(props) => props.theme.spacing(43)}px;
`;
const NewFab = styled(Fab)`
  ${spacing}
  position: fixed;
  z-index: 999;
  right: ${(props) => props.theme.spacing(7)}px;
  bottom: ${(props) => props.theme.spacing(25)}px;
`;

function FabScrollTop(props) {
  const {
    buttonSizeFab, isNewEntity,
  } = props;

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      {isNewEntity ? (
        <NewFab
          size={buttonSizeFab}
          aria-label="fab"
          aria-controls="fab"
          aria-haspopup="true"
          color="secondary"
          onClick={handleClick}
        >
          <ArrowUpwardIcon />
        </NewFab>
      ) : (
        <FabMui
          size={buttonSizeFab}
          aria-label="fab"
          aria-controls="fab"
          aria-haspopup="true"
          color="secondary"
          onClick={handleClick}
        >
          <ArrowUpwardIcon />
        </FabMui>
      )}
    </>
  );
}

FabScrollTop.propTypes = {
  buttonSizeFab: PropTypes.string,
  isNewEntity: PropTypes.bool,
};

FabScrollTop.defaultProps = {
  buttonSizeFab: 'medium',
  isNewEntity: false,
};

function mapStateToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, null),
)(React.memo(FabScrollTop));
