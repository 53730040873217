// core
import React, {
  useCallback, useEffect, useRef, useState, memo,
} from 'react';
import { spacing } from '@material-ui/system';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import 'cropperjs/dist/cropper.css';// eslint-disable-line

// lodash
import { isEmpty, isNil, isEqual } from 'lodash';

// UI
import {
  Grid as MuiGrid, IconButton as MuiIconButton,
  SvgIcon, Tooltip, CircularProgress,
} from '@material-ui/core';
import { DropzoneAreaBase as DropZoneAreaMui } from 'material-ui-dropzone';

// parts
import { Cropper } from 'react-cropper';

// icon
import {
  RotateLeft as RotateLeftIcon,
  Edit as EditIcon, Check as CheckIcon,
  AddCircleOutline as AddCircleOutlineIcon, RemoveCircleOutline as RemoveCircleOutlineIcon,
  RotateRight as RotateRightIcon,
} from '@material-ui/icons';
import DefaultCrop from '../../../../../ui/_helpers/img/defaultCrop.png';
import { ReactComponent as TimesIcon } from '../../../../../ui/_helpers/img/times.svg';
import { ReactComponent as ImageIcon } from '../../../../../ui/_helpers/img/image.svg';

// helpers
import checkIsUrl from '../../../../../ui/_helpers/checkIsUrl';

const DropZoneArea = styled(DropZoneAreaMui)(spacing);
const Grid = styled(MuiGrid)(spacing);
const IconButton = styled(MuiIconButton)(spacing);
const Spacer = styled.div(spacing);
const Container = styled.div`
  .MuiDropzoneArea-root {
    min-height: 174px;
  }
`;

const heightImage = 282;

function RenderCropper(props) {
  const {
    handleGetCropImg, haveImage, fieldTitle,
    handleCreateImg, createdImage, pending,
    clearCreatedImage, disabled,
  } = props;
  const [cropper, setCropper] = useState();
  const [image, setImage] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const imageRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => function cleanup() {
    setImage(null);
  }, [setImage]);

  const onChange = useCallback((files) => {
    if (!isEmpty(files) && files[0]?.data) {
      setImage(files[0].data);
      handleGetCropImg(files[0].data);
    }
  }, [handleGetCropImg]);

  const deleteImage = useCallback(() => {
    setImage(null);
    handleGetCropImg(null);
  }, [handleGetCropImg]);

  const handleCreateImage = useCallback((title) => {
    handleCreateImg(title);
  }, [handleCreateImg]);

  const handleEditImage = () => {
    setIsEditable(!isEditable);
  };
  const handleConfirmСhanges = () => {
    setImage(cropper.getCroppedCanvas().toDataURL());
    setIsEditable(!isEditable);
  };

  useEffect(() => {
    if (!isNil(haveImage)) {
      setIsEditable(false);
      handleGetCropImg(haveImage);
      if (checkIsUrl(haveImage)) {
        setImage(null);
      } else {
        setImage(haveImage);
      }
    }
  }, [haveImage]);// eslint-disable-line

  useEffect(() => {
    if (!isEmpty(createdImage.toJS()) && !isEmpty(createdImage.toJS().image)) {
      setImage(createdImage.toJS().image);
      handleGetCropImg(createdImage.toJS().image);
      clearCreatedImage();
    }
  }, [createdImage, handleGetCropImg]);// eslint-disable-line

  const handleRotateToLeft = () => {
    cropper.rotate(-5);
  };
  const handleRotateToRight = () => {
    cropper.rotate(5);
  };
  const handleZoomPlus = () => {
    cropper.zoom(0.1);
  };
  const handleZoomMinus = () => {
    cropper.zoom(-0.1);
  };

  return (
    <div>
      <>
        {isEditable ? (
          <>
            {!disabled && (
              <Grid container alignItems="center" justify="center" mb={7}>
                <Tooltip title={t('Turn left')} arrow placement="top-start">
                  <span>
                    <IconButton size="small" mr={2} color="primary" disabled={isNil(image)} onClick={handleRotateToLeft}>
                      <RotateLeftIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title={t('Turn right')} arrow placement="top-start">
                  <span>
                    <IconButton size="small" mr={2} color="primary" disabled={isNil(image)} onClick={handleRotateToRight}>
                      <RotateRightIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title={t('Zoom in')} arrow placement="top-start">
                  <span>
                    <IconButton size="small" mr={2} color="primary" disabled={isNil(image)} onClick={handleZoomPlus}>
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title={t('Reduce')} arrow placement="top-start">
                  <span>
                    <IconButton size="small" mr={2} color="primary" disabled={isNil(image)} onClick={handleZoomMinus}>
                      <RemoveCircleOutlineIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title={t('Confirm changes')} arrow placement="top-start">
                  <span>
                    <IconButton size="small" mr={2} color="primary" disabled={isNil(image)} onClick={handleConfirmСhanges}>
                      <CheckIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
            )}

            {isNil(image) ? (
              <img style={{ height: heightImage, width: '100%', objectFit: 'cover' }} src={DefaultCrop} alt="" />
            ) : (
              <>
                <Cropper
                  style={{ height: heightImage, width: '100%', marginBottom: '33px' }}
                  guides
                  src={image}
                  ref={imageRef}
                  checkCrossOrigin={false}
                  checkOrientation // https://github.com/fengyuanchen/cropperjs/issues/671
                  onInitialized={(instance) => {
                    setCropper(instance);
                    handleGetCropImg(instance);
                  }}
                />
              </>
            )}
          </>
        ) : (
          <>
            {!disabled && (
              <Grid container alignItems="center" justify="center" mb={7}>
                <Tooltip title={t('Edit image')} arrow placement="top-start">
                  <span>
                    <IconButton
                      color="primary"
                      size="small"
                      mr={2}
                      onClick={handleEditImage}
                      disabled={isEmpty(image)}
                    >
                      <EditIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title={t('Delete image')} arrow placement="top-start">
                  <span>
                    <IconButton
                      color="primary"
                      size="small"
                      mr={2}
                      onClick={deleteImage}
                      disabled={isEmpty(image)}
                    >
                      <SvgIcon>
                        <TimesIcon />
                      </SvgIcon>
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title={t('Create an image from the verbal part')} arrow placement="top-start">
                  <span>
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={() => handleCreateImage(fieldTitle)}
                      disabled={isEmpty(fieldTitle)}
                    >
                      {pending
                        ? <CircularProgress size={25} color="primary" />
                        : <SvgIcon viewBox="0 0 24 22"><ImageIcon /></SvgIcon>}
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
            )}

            <img
              style={{ height: heightImage, width: '100%', objectFit: image ? 'contain' : 'cover' }}
              src={image || DefaultCrop}
              alt={t('Logo')}
            />
          </>
        )}
        <Spacer mb={7} />

        {!disabled && (
          <Container>
            <DropZoneArea
              dropzoneText={t('Drag and drop a file here or click')}
              showPreviewsInDropzone={false}
              showAlerts={false}
              clearOnUnmount
              maxFileSize={21000000}
              filesLimit={1}
              mt={4}
              onAdd={onChange}
            />
          </Container>
        )}
      </>
    </div>
  );
}
RenderCropper.propTypes = {
  pending: PropTypes.bool.isRequired,
  haveImage: PropTypes.string,
  fieldTitle: PropTypes.string.isRequired,
  createdImage: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  handleCreateImg: PropTypes.func.isRequired,
  handleGetCropImg: PropTypes.func.isRequired,
  clearCreatedImage: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

RenderCropper.defaultProps = {
  haveImage: '',
  disabled: false,
};

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.createdImage, nextProps.createdImage)
  && isEqual(prevProps.fieldTitle, nextProps.fieldTitle)
  && isEqual(prevProps.haveImage, nextProps.haveImage)
  && isEqual(prevProps.disabled, nextProps.disabled)
  && isEqual(prevProps.pending, nextProps.pending);
}

export default memo(RenderCropper, areEqual);
