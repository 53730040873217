export default Object.freeze({
  documents_next_number_get: 'api_v1_documents_next_number_get',
  documents_check_post: 'api_v1_documents_check_post',
  documents_list_get: 'api_v1_documents_list_get',
  documents_get: 'api_v1_documents_get',
  documents_post: 'api_v1_documents_post',
  documents_put: 'api_v1_documents_put',
  documents_delete: 'api_v1_documents_delete',
  documents_spez_post: 'api_v1_documents_spez_post',
  trademarks_from_registry_get: 'api_v1_trademarks_from_registry_get',
  trademarks_next_number_get: 'api_v1_trademarks_next_number_get',
  trademarks_list_get: 'api_v1_trademarks_list_get',
  trademarks_get: 'api_v1_trademarks_get',
  trademarks_registry_post: 'api_v1_trademarks_registry_post',
  trademarks_post: 'api_v1_trademarks_post',
  trademarks_put: 'api_v1_trademarks_put',
  trademarks_add_documents_post: 'api_v1_trademarks_add_documents_post',
  trademarks_remove_documents_post: 'api_v1_trademarks_remove_documents_post',
  trademarks_create_image_post: 'api_v1_trademarks_create_image_post',
  trademarks_delete: 'api_v1_trademarks_delete',
  trademarks_clone_post: 'api_v1_trademarks_clone_post',
  industrial_from_registry_get: 'api_v1_industrial_from_registry_get',
  industrial_next_number_get: 'api_v1_industrial_next_number_get',
  industrial_list_get: 'api_v1_industrial_list_get',
  industrial_get: 'api_v1_industrial_get',
  industrial_registry_post: 'api_v1_industrial_registry_post',
  industrial_post: 'api_v1_industrial_post',
  industrial_put: 'api_v1_industrial_put',
  industrial_add_documents_post: 'api_v1_industrial_add_documents_post',
  industrial_remove_documents_post: 'api_v1_industrial_remove_documents_post',
  industrial_delete: 'api_v1_industrial_delete',
  industrial_clone_post: 'api_v1_industrial_clone_post',
  invention_from_registry_get: 'api_v1_invention_from_registry_get',
  utility_from_registry_get: 'api_v1_utility_from_registry_get',
  utility_next_number_get: 'api_v1_utility_next_number_get',
  utility_list_get: 'api_v1_utility_list_get',
  utility_get: 'api_v1_utility_get',
  utility_registry_post: 'api_v1_utility_registry_post',
  utility_post: 'api_v1_utility_post',
  utility_put: 'api_v1_utility_put',
  utility_add_documents_post: 'api_v1_utility_add_documents_post',
  utility_remove_documents_post: 'api_v1_utility_remove_documents_post',
  utility_clone_put: 'api_v1_utility_clone_put',
  utility_delete: 'api_v1_utility_delete',
  support_tickets_post: 'api_v1_support_tickets_post',
  quick_search_post: 'api_v1_quick_search_post',
  entity_statuses_get: 'api_v1_entity_statuses_get',
  check_ftp_post: 'api_v1_check_ftp_post',
  calc_patents_renewal_post: 'api_v1_calc_patents_renewal_post',
  search_post_global: 'api_v1_search_post_global',
  email_preview_post: 'api_v1_email_preview_post',
  entity_update: 'api_v1_entity_update',
  parser_address_post: 'api_v1_parser_address_post',
  inventions_next_number_get: 'api_v1_inventions_next_number_get',
  inventions_list_get: 'api_v1_inventions_list_get',
  inventions_get: 'api_v1_inventions_get',
  inventions_registry_post: 'api_v1_inventions_registry_post',
  inventions_post: 'api_v1_inventions_post',
  inventions_put: 'api_v1_inventions_put',
  inventions_add_documents_post: 'api_v1_inventions_add_documents_post',
  inventions_remove_documents_post: 'api_v1_inventions_remove_documents_post',
  inventions_applications_delete: 'api_v1_inventions_applications_delete',
  inventions_clone_post: 'api_v1_inventions_clone_post',
  inventions_delete: 'api_v1_inventions_delete',
  orders_next_number_get: 'api_v1_orders_next_number_get',
  orders_list_get: 'api_v1_orders_list_get',
  orders_get: 'api_v1_orders_get',
  orders_create: 'api_v1_orders_create',
  orders_edit: 'api_v1_orders_edit',
  orders_delete: 'api_v1_orders_delete',
  orders_invoice_create_post: 'api_v1_orders_invoice_create_post',
  mktu_list_get: 'api_v1_mktu_list_get',
  mktu_classes_list_get: 'api_v1_mktu_classes_list_get',
  mktu_classes_get: 'api_v1_mktu_classes_get',
  mktu_get: 'api_v1_mktu_get',
  mktu_search_post: 'api_v1_mktu_search_post',
  mktu_post: 'api_v1_mktu_post',
  mktu_put: 'api_v1_mktu_put',
  mktu_create_files_get: 'api_v1_mktu_create_files_get',
  mktu_delete: 'api_v1_mktu_delete',
  estimates_list_get: 'api_v1_estimates_list_get',
  estimates_get: 'api_v1_estimates_get',
  estimates_post: 'api_v1_estimates_post',
  estimates_put: 'api_v1_estimates_put',
  estimates_calculate_post: 'api_v1_estimates_calculate_post',
  estimates_delete: 'api_v1_estimates_delete',
  search_list_get: 'api_v1_search_list_get',
  search_report_get: 'api_v1_search_report_get',
  search_get: 'api_v1_search_get',
  search_post: 'api_v1_search_post',
  search_put: 'api_v1_search_put',
  search_delete: 'api_v1_search_delete',
  monitoring_list_get: 'api_v1_monitoring_list_get',
  monitoring_get: 'api_v1_monitoring_get',
  monitoring_post: 'api_v1_monitoring_post',
  monitoring_report_get: 'api_v1_monitoring_report_get',
  monitoring_delete: 'api_v1_monitoring_delete',
  mktuTemplate_list_get: 'api_v1_mktuTemplate_list_get',
  mktuTemplate_get: 'api_v1_mktuTemplate_get',
  mktuTemplate_create_files_get: 'api_v1_mktuTemplate_create_files_get',
  mktuTemplate_post: 'api_v1_mktuTemplate_post',
  mktuTemplate_put: 'api_v1_mktuTemplate_put',
  mktuTemplate_delete: 'api_v1_mktuTemplate_delete',
  applicants_list_get: 'api_v1_applicants_list_get',
  applicants_get: 'api_v1_applicants_get',
  applicants_post: 'api_v1_applicants_post',
  applicants_put: 'api_v1_applicants_put',
  applicants_delete: 'api_v1_applicants_delete',
  productCategory_list_get: 'api_v1_productCategory_list_get',
  productCategory_get: 'api_v1_productCategory_get',
  productCategory_post: 'api_v1_productCategory_post',
  productCategory_put: 'api_v1_productCategory_put',
  productCategory_delete: 'api_v1_productCategory_delete',
  products_list_get: 'api_v1_products_list_get',
  products_get: 'api_v1_products_get',
  products_post: 'api_v1_products_post',
  products_put: 'api_v1_products_put',
  products_delete: 'api_v1_products_delete',
  authorityIntegration_list_get: 'api_v1_authorityIntegration_list_get',
  authorityIntegration_get: 'api_v1_authorityIntegration_get',
  authorityIntegration_post: 'api_v1_authorityIntegration_post',
  authorityIntegration_put: 'api_v1_authorityIntegration_put',
  authorityIntegration_delete: 'api_v1_authorityIntegration_delete',
  charges_list_get: 'api_v1_charges_list_get',
  charges_get: 'api_v1_charges_get',
  charges_post: 'api_v1_charges_post',
  charges_put: 'api_v1_charges_put',
  charges_delete: 'api_v1_charges_delete',
  payments_requisites_get: 'api_v1_payments_requisites_get',
  payments_list_get: 'api_v1_payments_list_get',
  payments_get: 'api_v1_payments_get',
  payments_post: 'api_v1_payments_post',
  payments_put: 'api_v1_payments_put',
  payments_import_post: 'api_v1_payments_import_post',
  payments_apply_post: 'api_v1_payments_apply_post',
  payments_delete: 'api_v1_payments_delete',
  payments_renew_post: 'api_v1_payments_renew_post',
  clients_list_get: 'api_v1_clients_list_get',
  clients_get: 'api_v1_clients_get',
  clients_post: 'api_v1_clients_post',
  clients_put: 'api_v1_clients_put',
  clients_sent_credentials_post: 'api_v1_clients_sent_credentials_post',
  clients_merge_post: 'api_v1_clients_merge_post',
  clients_delete: 'api_v1_clients_delete',
  company_list_get: 'api_v1_company_list_get',
  company_get: 'api_v1_company_get',
  company_post: 'api_v1_company_post',
  company_put: 'api_v1_company_put',
  company_delete: 'api_v1_company_delete',
  company_helpers_find_get: 'api_v1_company_helpers_find_get',
  costsCategories_list_get: 'api_v1_costsCategories_list_get',
  costsCategories_get: 'api_v1_costsCategories_get',
  costsCategories_post: 'api_v1_costsCategories_post',
  costsCategories_put: 'api_v1_costsCategories_put',
  costsCategories_delete: 'api_v1_costsCategories_delete',
  costs_list_get: 'api_v1_costs_list_get',
  costs_get: 'api_v1_costs_get',
  costs_post: 'api_v1_costs_post',
  costs_put: 'api_v1_costs_put',
  costs_delete: 'api_v1_costs_delete',
  costsComponents_list_get: 'api_v1_costsComponents_list_get',
  costsComponents_get: 'api_v1_costsComponents_get',
  costsComponents_post: 'api_v1_costsComponents_post',
  costsComponents_put: 'api_v1_costsComponents_put',
  costsComponents_delete: 'api_v1_costsComponents_delete',
  crmIntegration_list_get: 'api_v1_crmIntegration_list_get',
  crmIntegration_get: 'api_v1_crmIntegration_get',
  crmIntegration_fields_get: 'api_v1_crmIntegration_fields_get',
  crmIntegration_post: 'api_v1_crmIntegration_post',
  crmIntegration_put: 'api_v1_crmIntegration_put',
  crmIntegration_delete: 'api_v1_crmIntegration_delete',
  currencies_list_get: 'api_v1_currencies_list_get',
  currencies_get: 'api_v1_currencies_get',
  currencies_post: 'api_v1_currencies_post',
  currencies_put: 'api_v1_currencies_put',
  currencies_calc_post: 'api_v1_currencies_calc_post',
  currencies_delete: 'api_v1_currencies_delete',
  currencyRates_list_get: 'api_v1_currencyRates_list_get',
  currencyRates_get: 'api_v1_currencyRates_get',
  currencyRates_post: 'api_v1_currencyRates_post',
  currencyRates_put: 'api_v1_currencyRates_put',
  currencyRates_delete: 'api_v1_currencyRates_delete',
  documentClassifier_list_get: 'api_v1_documentClassifier_list_get',
  documentClassifier_get: 'api_v1_documentClassifier_get',
  documentClassifier_post: 'api_v1_documentClassifier_post',
  documentClassifier_put: 'api_v1_documentClassifier_put',
  documentClassifier_delete: 'api_v1_documentClassifier_delete',
  documentClassifierCategory_list_get: 'api_v1_documentClassifierCategory_list_get',
  documentClassifierCategory_get: 'api_v1_documentClassifierCategory_get',
  documentClassifierCategory_post: 'api_v1_documentClassifierCategory_post',
  documentClassifierCategory_put: 'api_v1_documentClassifierCategory_put',
  documentClassifierCategory_delete: 'api_v1_documentClassifierCategory_delete',
  documentsTemplate_list_get: 'api_v1_documentsTemplate_list_get',
  documentsTemplate_get: 'api_v1_documentsTemplate_get',
  documentsTemplate_post: 'api_v1_documentsTemplate_post',
  documentsTemplate_put: 'api_v1_documentsTemplate_put',
  documentsTemplate_delete: 'api_v1_documentsTemplate_delete',
  email_list_get: 'api_v1_email_list_get',
  email_get: 'api_v1_email_get',
  email_post: 'api_v1_email_post',
  email_put: 'api_v1_email_put',
  email_send_post: 'api_v1_email_send_post',
  email_check_new_post: 'api_v1_email_check_new_post',
  email_check_host_post: 'api_v1_email_check_host_post',
  email_delete: 'api_v1_email_delete',
  emailTemplate_list_get: 'api_v1_emailTemplate_list_get',
  emailTemplate_get: 'api_v1_emailTemplate_get',
  emailTemplate_post: 'api_v1_emailTemplate_post',
  emailTemplate_put: 'api_v1_emailTemplate_put',
  emailTemplate_delete: 'api_v1_emailTemplate_delete',
  emailLetters_list_get: 'api_v1_emailLetters_list_get',
  emailLetters_get: 'api_v1_emailLetters_get',
  emailLetters_thread_get: 'api_v1_emailLetters_thread_get',
  emailLetters_attachment_get: 'api_v1_emailLetters_attachment_get',
  emailLetters_post: 'api_v1_emailLetters_post',
  emailLetters_delete: 'api_v1_emailLetters_delete',
  emailSignature_list_get: 'api_v1_emailSignature_list_get',
  emailSignature_get: 'api_v1_emailSignature_get',
  emailSignature_post: 'api_v1_emailSignature_post',
  emailSignature_put: 'api_v1_emailSignature_put',
  emailSignature_delete: 'api_v1_emailSignature_delete',
  employees_list_get: 'api_v1_employees_list_get',
  employees_get: 'api_v1_employees_get',
  employees_post: 'api_v1_employees_post',
  employees_put: 'api_v1_employees_put',
  employees_invite_post: 'api_v1_employees_invite_post',
  employees_delete: 'api_v1_employees_delete',
  entity_import_post: 'api_v1_entity_import_post',
  entity_fields_list_get: 'api_v1_entity_fields_list_get',
  entity_fields_all_list_get: 'api_v1_entity_fields_all_list_get',
  entity_remove: 'api_v1_entity_remove',
  entity_import_excel_post: 'api_v1_entity_import_excel_post',
  entity_export_post: 'api_v1_entity_export_post',
  entity_download_export_get: 'api_v1_entity_download_export_get',
  entity_send_post: 'api_v1_entity_send_post',
  entity_sign_post: 'api_v1_entity_sign_post',
  entity_create_packet_post: 'api_v1_entity_create_packet_post',
  files_list_get: 'api_v1_files_list_get',
  files_get: 'api_v1_files_get',
  files_post: 'api_v1_files_post',
  files_delete: 'api_v1_files_delete',
  files_download_get: 'api_v1_files_download_get',
  files_helpers_base64_get: 'api_v1_files_helpers_base64_get',
  outDocs_list_get: 'api_v1_outDocs_list_get',
  outDocs_download_get: 'api_v1_outDocs_download_get',
  outDocs_send: 'api_v1_outDocs_send',
  inDocs_list_get: 'api_v1_inDocs_list_get',
  inDocs_delete: 'api_v1_inDocs_delete',
  inDocs_download_get: 'api_v1_inDocs_download_get',
  jurisdictions_list_get: 'api_v1_jurisdictions_list_get',
  jurisdictions_get: 'api_v1_jurisdictions_get',
  jurisdictions_post: 'api_v1_jurisdictions_post',
  jurisdictions_put: 'api_v1_jurisdictions_put',
  jurisdictions_delete: 'api_v1_jurisdictions_delete',
  keys_list_get: 'api_v1_keys_list_get',
  keys_get: 'api_v1_keys_get',
  keys_post: 'api_v1_keys_post',
  keys_put: 'api_v1_keys_put',
  keys_delete: 'api_v1_keys_delete',
  logging_list_get: 'api_v1_logging_list_get',
  logging_get: 'api_v1_logging_get',
  logging_revert_put: 'api_v1_logging_revert_put',
  logging_delete: 'api_v1_logging_delete',
  notifications_list_get: 'api_v1_notifications_list_get',
  notifications_get: 'api_v1_notifications_get',
  notifications_post: 'api_v1_notifications_post',
  notifications_put: 'api_v1_notifications_put',
  notifications_delete: 'api_v1_notifications_delete',
  paymentReceivers_list_get: 'api_v1_paymentReceivers_list_get',
  paymentReceivers_get: 'api_v1_paymentReceivers_get',
  paymentReceivers_post: 'api_v1_paymentReceivers_post',
  paymentReceivers_put: 'api_v1_paymentReceivers_put',
  paymentReceivers_delete: 'api_v1_paymentReceivers_delete',
  paymentAllocation_list_get: 'api_v1_paymentAllocation_list_get',
  paymentAllocation_delete: 'api_v1_paymentAllocation_delete',
  pendingActions_list_get: 'api_v1_pendingActions_list_get',
  pendingActions_get: 'api_v1_pendingActions_get',
  pendingActions_post: 'api_v1_pendingActions_post',
  pendingActions_put: 'api_v1_pendingActions_put',
  pendingActions_delete: 'api_v1_pendingActions_delete',
  pendingActions_delete_by_document_post: 'api_v1_pendingActions_delete_by_document_post',
  groups_list_get: 'api_v1_groups_list_get',
  groups_get: 'api_v1_groups_get',
  groups_post: 'api_v1_groups_post',
  groups_put: 'api_v1_groups_put',
  groups_delete: 'api_v1_groups_delete',
  permissions_list_get: 'api_v1_permissions_list_get',
  settings_list_get: 'api_v1_settings_list_get',
  settings_get: 'api_v1_settings_get',
  settings_post: 'api_v1_settings_post',
  settings_put: 'api_v1_settings_put',
  settings_delete: 'api_v1_settings_delete',
  systemMessage_list_get: 'api_v1_systemMessage_list_get',
  systemMessage_get: 'api_v1_systemMessage_get',
  systemMessage_post: 'api_v1_systemMessage_post',
  systemMessage_put: 'api_v1_systemMessage_put',
  systemMessage_delete: 'api_v1_systemMessage_delete',
  tags_list_get: 'api_v1_tags_list_get',
  tags_get: 'api_v1_tags_get',
  tags_post: 'api_v1_tags_post',
  tags_put: 'api_v1_tags_put',
  tags_delete: 'api_v1_tags_delete',
  taxes_list_get: 'api_v1_taxes_list_get',
  taxes_get: 'api_v1_taxes_get',
  taxes_post: 'api_v1_taxes_post',
  taxes_put: 'api_v1_taxes_put',
  taxes_delete: 'api_v1_taxes_delete',
  termTemplate_get: 'api_v1_termTemplate_get',
  usersFilters_list_get: 'api_v1_usersFilters_list_get',
  usersFilters_post: 'api_v1_usersFilters_post',
  usersFilters_delete: 'api_v1_usersFilters_delete',
  users_put: 'api_v1_users_put',
  works_list_get: 'api_v1_works_list_get',
  works_get: 'api_v1_works_get',
  works_post: 'api_v1_works_post',
  works_put: 'api_v1_works_put',
  works_delete: 'api_v1_works_delete',
  dashboard_list_get: 'api_v1_dashboard_list_get',
  madrid_case_list_get: 'api_v1_madrid_case_list_get',
  madrid_case_get: 'api_v1_madrid_case_get',
  madrid_case_post: 'api_v1_madrid_case_post',
  madrid_case_put: 'api_v1_madrid_case_put',
  madrid_case_delete: 'api_v1_madrid_case_delete',
});
