// Core
import {
  all, call, takeEvery,
} from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetTariffsWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callGetAutocompleteOptionsWorker,
  callTariffsTypesWorker,
  callDeleteTariffWorker,
} from './workers';
import types from '../types';

function* watchGetTariffs() {
  yield takeEvery(asyncTypes.GET_PRICES_TARIFFS_ASYNC, callGetTariffsWorker);
}
function* watchGetInitialState() {
  yield takeEvery(
    asyncTypes.GET_PRICES_TARIFFS_INITIAL_STATE_ASYNC,
    callGetInitialStateWorker,
  );
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_PRICES_TARIFFS_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_PRICES_TARIFFS_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_PRICES_TARIFFS_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_PRICES_TARIFFS_SORTING, callSetToLocalDataWorker);
}
function* watchSetColumnWidths() {
  yield takeEvery(types.SET_PRICES_TARIFFS_COLUMN_WIDTHS, callSetToLocalDataWorker);
}
function* watchSetColumnOrder() {
  yield takeEvery(types.SET_PRICES_TARIFFS_COLUMN_ORDER, callSetToLocalDataWorker);
}
function* watchSetHiddenColumnNames() {
  yield takeEvery(types.SET_PRICES_TARIFFS_HIDDEN_COLUMN_NAMES, callSetToLocalDataWorker);
}

function* watchDeleteTariff() {
  yield takeEvery(asyncTypes.SET_DELETE_PRICES_TARIFF_ASYNC, callDeleteTariffWorker);
}

function* watchGetAutocompleteOptions() {
  yield takeEvery(
    asyncTypes.GET_PRICES_TARIFFS_AUTOCOMPLETE_OPTIONS_ASYNC,
    callGetAutocompleteOptionsWorker,
  );
}

function* watchTariffsTypes() {
  yield takeEvery(
    asyncTypes.SET_PRICES_TARIFFS_TYPES_ASYNC,
    callTariffsTypesWorker,
  );
}

export function* watchersPricesTariffs() {
  yield all([
    call(watchGetTariffs),
    call(watchSetCurrentPage),
    call(watchSetPageSize),
    call(watchSetFilters),
    call(watchSetSorting),
    call(watchDeleteTariff),
    call(watchGetInitialState),
    call(watchGetAutocompleteOptions),
    call(watchTariffsTypes),
    call(watchSetColumnWidths),
    call(watchSetColumnOrder),
    call(watchSetHiddenColumnNames),
  ]);
}
