// Core
import { call, put, select } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';
import filter from 'lodash/filter';

// config
import { api } from '../../../../config/api';
import { messageDataUpdated } from '../../../../config/globalConfig';
import selectors from '../../../../config/selectors';

// init
import i18n from '../../../../init/i18n';

// actions
import actions from '../../action';
import helpersActions from '../../../helpers/action';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../ui/_helpers/setNotificationMessage';

export function* callDeleteApplicantWorker() {
  const { setDeleteApplicant, setApplicantsList, setApplicantsData } = actions;

  yield put(setDeleteApplicant({
    pendingDelete: true,
  }));

  const applicantIdDelete = yield select(selectors.applicants.applicantIdDelete);

  const response = yield call(api.applicants.deleteApplicant, applicantIdDelete);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
      yield put(setDeleteApplicant({ pendingDelete: false }));
      return;
    }
    const applicants = yield select(selectors.applicants.list);
    yield put(setApplicantsData({
      openFormAddApplicant: false,
    }));
    yield put(setApplicantsData({
      id: '',
    }));
    yield put(setApplicantsList(filter(
      applicants.toJS(),
      (applicant) => applicant.id !== applicantIdDelete,
    )));
    yield put(helpersActions.setModal({ isModalOpen: false }));
    yield put(setSuccessMessage(i18n.t(messageDataUpdated), response.status.toString()));
  }

  yield put(setDeleteApplicant({
    pendingDelete: false,
  }));
}
