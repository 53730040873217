// Core
import { call, put } from 'redux-saga/effects';

// lodash
import isNumber from 'lodash/isNumber';
import isObject from 'lodash/isObject';

// config
import { api } from '../../../../config/api';

// actions
import action from '../../action';
import helpersActions from '../../../helpers/action';

// helpers
import { getObjectReverseFormatDate } from '../../../../_helpers/formatterDate';

export function* callGetDepartmentDocumentByIdWorker({ payload: id }) {
  const {
    setDepartmentDocumentById, setDepartmentDocumentEntityList,
  } = action;
  yield put(setDepartmentDocumentById({
    pending: true,
  }));

  const response = yield call(api.departmentDocuments.getDepartmentDocumentById, id);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    yield put(setDepartmentDocumentById({
      data: {
        ...data,
        ...getObjectReverseFormatDate(data.documentDate, 'documentDate'),
        ...getObjectReverseFormatDate(data.receivingDate, 'receivingDate'),
      },
    }));
    yield put(setDepartmentDocumentEntityList({
      ...data.trademarks ? { trademarks: data.trademarks } : {},
      ...data.utility ? { utility: data.utility } : {},
      ...data.inventions ? { inventions: data.inventions } : {},
      ...data.industrial ? { industrial: data.industrial } : {},
      ...data.orders ? { orders: data.orders } : {},
      ...data.clients && isNumber(data.clients) ? {
        clients: [{
          id: data.clients,
          label: data.clients,
        }],
      } : {},
      ...data.clients && isObject(data.clients) ? { clients: [data.clients] } : {},
    }));
    const { signs = [] } = data;
    yield put(helpersActions.setSignList(signs));
  }

  yield put(setDepartmentDocumentById({
    pending: false,
  }));
}
