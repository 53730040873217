// core
import React, { memo, useState } from 'react';
import { compose } from 'recompose';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';

// lodash
import {
  isEmpty, map, isEqual,
} from 'lodash';

// ui
import {
  TextField, InputAdornment, IconButton, Grid,
} from '@material-ui/core';

// icon
import {
  Search as SearchIcon,
  Clear as ClearIcon,
} from '@material-ui/icons';

// parts
import selectors from '../../../../../../engine/config/selectors';
import AllClasses from '../../../../Rebounds/Rebound/components/AllClasses';

// actions
import reboundsActionAsync from '../../../../../../engine/core/tradeMarks/rebounds/saga/asyncAction';
import reboundsAction from '../../../../../../engine/core/tradeMarks/rebounds/action';
import proposalActions from '../../../../../../engine/core/tradeMarks/proposal/action';
import Modal from '../../../../../../components/Modal/Modal';

function AllClassesModal(props) {
  const {
    isOpen, getSearchRebound, getProposalById, reboundById,
    selectMktuClasses, tmId, pendingPostRebound,
    pendingPutRebound, langMktuValue,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');

  const handleClose = () => {
    dispatch(proposalActions.setAllClassesModalIsOpen(false));
  };
  const handleSubscribe = () => {
    if (isEmpty(selectMktuClasses.toJS())) {
      dispatch(proposalActions.setAllClassesModalIsOpen(false));
      return;
    }

    const selection = {};
    map(selectMktuClasses.toJS(), (selectMktuClass) => {
      selection[selectMktuClass.classNum] = selectMktuClass?.name;
    });
    dispatch(reboundsActionAsync[!isEmpty(reboundById.toJS()) ? 'putReboundByIdAsync' : 'postReboundAsync']({
      ...reboundById.toJS(),
      ...!isEmpty(tmId) ? {
        trademarks: Number(tmId.hashId),
      } : {},
      ...!isEmpty(getProposalById.toJS()) && !isEmpty(tmId) && isEmpty(reboundById.toJS()) ? {
        title: getProposalById.toJS().internalTitle || getProposalById.toJS().title,
      } : {},
      selection,
      reload: true,
    }));
  };

  const handleChangeSearch = (e) => {
    setSearchValue(e.target.value);
    if (isEmpty(e.target.value)) {
      dispatch(reboundsAction.setSearchRebound({}));
    }
  };

  const handleSearch = () => {
    if (!isEmpty(searchValue)) {
      if (!isEmpty(getSearchRebound.toJS()) && !isEmpty(getSearchRebound.toJS().items)) {
        setSearchValue('');
        dispatch(reboundsAction.setSearchRebound({}));
      } else {
        dispatch(reboundsActionAsync.searchReboundAsync({
          search: searchValue,
          lang: langMktuValue,
        }));
      }
    }
  };
  return (
    <Modal
      title={t('Classes')}
      isModalOpen={isOpen}
      handleCloseModal={handleClose}
      maxWidth="lg"
      handleSend={handleSubscribe}
      pending={pendingPostRebound || pendingPutRebound}
    >
      <>
        <Grid item xs={12} md={3} mb={5}>
          <TextField
            name="search"
            id="search"
            label={t('Search')}
            margin="normal"
            variant="standard"
            type="text"
            my={1}
            value={searchValue}
            fullWidth
            onKeyDown={(event) => {
              // enter
              if (event.keyCode === 13) {
                event.preventDefault();
                handleSearch();
              }
            }}
            onChange={handleChangeSearch}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleSearch}
                  >
                    {!isEmpty(getSearchRebound.toJS())
                    && !isEmpty(getSearchRebound.toJS().items)
                      ? <ClearIcon />
                      : <SearchIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <AllClasses />
      </>
    </Modal>
  );
}

AllClassesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  selectMktuClasses: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  getSearchRebound: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  tmId: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  getProposalById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  reboundById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingPostRebound: PropTypes.bool.isRequired,
  pendingPutRebound: PropTypes.bool.isRequired,
  langMktuValue: PropTypes.string,
};

AllClassesModal.defaultProps = {
  tmId: {},
  langMktuValue: '',
};

function mapStateToProps(state) {
  return {
    isOpen: selectors.tradeMarksProposal.allClassesModalIsOpen(state),
    getSearchRebound: selectors.rebounds.getSearchRebound(state),
    selectMktuClasses: selectors.tradeMarksProposal.selectMktuClasses(state),
    getProposalById: selectors.tradeMarksProposal.getProposalById(state),
    reboundById: selectors.rebounds.getReboundById(state),
    pendingPostRebound: selectors.rebounds.pendingPostRebound(state),
    pendingPutRebound: selectors.rebounds.pendingPutRebound(state),
    langMktuValue: selectors.form.getFormValues(state, 'ProposalAdd').get('langMktu'),
  };
}

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.isOpen, nextProps.isOpen)
  && isEqual(prevProps.tmId, nextProps.tmId)
  && isEqual(prevProps.pendingPostRebound, nextProps.pendingPostRebound)
  && isEqual(prevProps.pendingPutRebound, nextProps.pendingPutRebound)
  && isEqual(prevProps.selectMktuClasses, nextProps.selectMktuClasses)
  && isEqual(prevProps.reboundById, nextProps.reboundById)
  && isEqual(prevProps.getSearchRebound, nextProps.getSearchRebound)
  && isEqual(prevProps.langMktuValue, nextProps.langMktuValue)
  && isEqual(prevProps.getProposalById, nextProps.getProposalById);
}

export default compose(connect(mapStateToProps, null))(memo(AllClassesModal, areEqual));
