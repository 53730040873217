// Helpers
import actionCreator from '../../_helpers/actionCreator';
// Types
import types from './types';

const actions = Object.freeze({
  setPendingGetProposalById(pending) {
    return actionCreator(types.SET_PENDING_GET_SET_INVENTIONS_PROPOSAL_BY_ID, pending);
  },
  setProposalData(searchesData) {
    return actionCreator(types.SET_INVENTIONS_PROPOSAL_DATA, searchesData);
  },
  setCurrentPage(currentPage) {
    return actionCreator(types.SET_INVENTIONS_PROPOSAL_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return actionCreator(types.SET_INVENTIONS_PROPOSAL_FILTERS, filters);
  },
  setSorting(sorting) {
    return actionCreator(types.SET_INVENTIONS_PROPOSAL_SORTING, sorting);
  },
  setColumnWidths(data) {
    return actionCreator(types.SET_INVENTIONS_PROPOSAL_COLUMN_WIDTHS, data);
  },
  setColumnOrder(data) {
    return actionCreator(types.SET_INVENTIONS_PROPOSAL_COLUMN_ORDER, data);
  },
  setHiddenColumnNames(data) {
    return actionCreator(types.SET_INVENTIONS_PROPOSAL_HIDDEN_COLUMN_NAMES, data);
  },
  setPageSize(pageSize) {
    return actionCreator(types.SET_INVENTIONS_PROPOSAL_PAGE_SIZE, pageSize);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_INVENTIONS_PROPOSAL_INITIAL_STATE, initialState);
  },
  setReload(reload) {
    return actionCreator(types.SET_INVENTIONS_PROPOSAL_RELOAD, reload);
  },
  setDeleteProposal(data) {
    return actionCreator(types.SET_DELETE_INVENTION_PROPOSAL, data);
  },
  setAutocompleteOptions(options) {
    return actionCreator(types.SET_INVENTION_PROPOSAL_AUTOCOMPLETE_OPTIONS, options);
  },
  setPendingAutocompleteOptions(pending) {
    return actionCreator(types.SET_PENDING_INVENTION_PROPOSAL_AUTOCOMPLETE_OPTIONS, pending);
  },
  setProposalDataById(data) {
    return actionCreator(types.SET_INVENTION_PROPOSAL_DATA_BY_ID, data);
  },
  setUploadFiles(payload) {
    return actionCreator(types.SET_INVENTION_PROPOSAL_UPLOAD_FILES, payload);
  },
  setSignList(list) {
    return actionCreator(types.SET_INVENTION_PROPOSAL_SIGN_LIST, list);
  },
  setSignPending(pending) {
    return actionCreator(types.SET_INVENTION_PROPOSAL_SIGN_PENDING, pending);
  },
  setSignIsModalOpen(isOpen) {
    return actionCreator(types.SET_INVENTION_PROPOSAL_SIGN_MODAL_IS_OPEN, isOpen);
  },
  setPendingPostProposal(pending) {
    return actionCreator(types.SET_INVENTION_PENDING_POST_PROPOSAL, pending);
  },
  setPendingPutProposal(payload) {
    return actionCreator(types.SET_INVENTION_PENDING_PUT_PROPOSAL, payload);
  },
  setPendingRegistryByNumber(pending) {
    return actionCreator(types.SET_INVENTION_PENDING_GET_REGISTRY_BY_NUMBER, pending);
  },
  setRegistryByNumber(data) {
    return actionCreator(types.SET_INVENTION_REGISTRY_BY_NUMBER, data);
  },
  setNextNumber(data) {
    return actionCreator(types.SET_INVENTION_PROPOSAL_NEXT_NUMBER, data);
  },
});

export default actions;
