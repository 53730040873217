// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const asyncActions = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_ACTION_TYPES_ASYNC, params);
  },
  getActionTypeByIdAsync(id) {
    return actionCreator(asyncTypes.GET_ACTION_TYPE_BY_ID_ASYNC, id);
  },
  deleteActionTypeAsync(id) {
    return actionCreator(asyncTypes.DELETE_ACTION_TYPE_ASYNC, id);
  },
  postActionTypeAsync(data) {
    return actionCreator(asyncTypes.POST_ACTION_TYPE_ASYNC, data);
  },
  putActionTypeByIdAsync(data) {
    return actionCreator(asyncTypes.PUT_ACTION_TYPE_BY_ID_ASYNC, data);
  },
});

export default asyncActions;
