const asyncTypes = Object.freeze({
  GET_INVENTIONS_PROPOSAL_ASYNC: 'GET_INVENTIONS_PROPOSAL_ASYNC',
  GET_INVENTIONS_PROPOSAL_BY_ID_ASYNC: 'GET_INVENTIONS_PROPOSAL_BY_ID_ASYNC',
  GET_INVENTIONS_PROPOSAL_INITIAL_STATE_ASYNC: 'GET_INVENTIONS_PROPOSAL_INITIAL_STATE_ASYNC',
  DELETE_INVENTION_PROPOSAL_ASYNC: 'DELETE_INVENTION_PROPOSAL_ASYNC',
  GET_INVENTION_AUTOCOMPLETE_OPTIONS_ASYNC: 'GET_INVENTION_AUTOCOMPLETE_OPTIONS_ASYNC',
  PUT_INVENTION_PROPOSAL_ASYNC: 'PUT_INVENTION_PROPOSAL_ASYNC',
  POST_INVENTION_PROPOSAL_ASYNC: 'POST_INVENTION_PROPOSAL_ASYNC',
  GET_INVENTION_REGISTRY_BY_NUMBER_ASYNC: 'GET_INVENTION_REGISTRY_BY_NUMBER_ASYNC',
  GET_INVENTIONS_PROPOSAL_NEXT_NUMBER_ASYNC: 'GET_INVENTIONS_PROPOSAL_NEXT_NUMBER_ASYNC',
  PUT_INVENTIONS_PROPOSAL_CLONE_ASYNC: 'PUT_INVENTIONS_PROPOSAL_CLONE_ASYNC',
});

export default asyncTypes;
