// Core
import { List, Map } from 'immutable';

// Instruments
import types from './types';
import globalStateKeys from '../../config/globalStateKeys';

export const initialState = Map({
  data: Map({
    items: List(),
    pending: false,
    totalCount: 0,
  }),
  responsibleList: Map(),
});

export const stateKeys = Object.freeze({
  controller: 'settings',
  responsibleList: 'responsibleList',
});

export const settingsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_SETTINGS_DATA: {
      return state.mergeIn([globalStateKeys.data], payload);
    }
    case types.MERGE_SETTINGS_RESPONSIBLE_LIST: {
      const { entityName, items } = payload;
      return state.mergeIn([stateKeys.responsibleList], Map({ [entityName]: List(items) }));
    }
    case types.SET_SETTINGS_RESPONSIBLE_LIST: {
      return state.setIn([stateKeys.responsibleList], Map(payload));
    }
    default:
      return state;
  }
};
