// Core
import { put } from 'redux-saga/effects';
import { List } from 'immutable';

// lodash
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import filter from 'lodash/filter';

// helpers
import { splitArrayToChunks } from '../../../../../_helpers/splitArrays';

// actions
import actions from '../../action';

export function* callSetTabsWorker({ payload: results }) {
  const { setTabs } = actions;

  const nationalType = 'national';
  const madridType = 'madrid';
  const resultsNational = filter(results, { type: nationalType });
  const resultsMadrid = filter(results, { type: madridType });
  const groupNational = !isEmpty(resultsNational) ? splitArrayToChunks(resultsNational, 20) : [];
  const groupMadrid = !isEmpty(resultsMadrid) ? splitArrayToChunks(resultsMadrid, 20) : [];

  yield put(setTabs(nationalType, {
    filtered: List(resultsNational),
    items: List(groupNational),
    totalCount: !isEmpty(resultsNational) ? size(resultsNational) : 0,
    pageSize: 0,
  }));

  yield put(setTabs(madridType, {
    filtered: List(resultsMadrid),
    items: List(groupMadrid),
    totalCount: !isEmpty(resultsMadrid) ? size(resultsMadrid) : 0,
    pageSize: 0,
  }));
}
