// Core
import { apply, put } from 'redux-saga/effects';

// config
import { api } from '../../../../config/api';
import { listEntityName } from '../../../../config/listTablesName';

// actions
import actions from '../../action';

const entity = listEntityName.paymentReceivers;

export function* callGetPaymentReceiversWorker({ payload: params }) {
  yield put(actions.setPaymentReceiversData({ pending: true }));
  const response = yield apply(api,
    api.paymentReceivers.getPaymentReceivers, [{ ...params, entity }]);

  let data = {
    items: [],
    totalCount: 0,
    pending: false,
  };

  if (response && response.status >= 200 && response.status < 400) {
    data = Object.assign(data, response.data);
  }
  yield put(actions.setPaymentReceiversData(data));
  yield put(actions.setReload(false));
}
