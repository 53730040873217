const asyncTypes = Object.freeze({
  GET_PROPOSAL_ASYNC: 'GET_PROPOSAL_ASYNC',
  GET_PROPOSAL_INITIAL_STATE_ASYNC: 'GET_PROPOSAL_INITIAL_STATE_ASYNC',
  GET_TRADE_MARKS_PROPOSAL_AUTOCOMPLETE_OPTIONS_ASYNC: 'GET_TRADE_MARKS_PROPOSAL_AUTOCOMPLETE_OPTIONS_ASYNC',
  GET_REGISTRY_BY_NUMBER_ASYNC: 'GET_REGISTRY_BY_NUMBER_ASYNC',
  GET_GET_CLASSES_ASYNC: 'GET_GET_CLASSES_ASYNC',
  GET_CLASS_BY_ID_ASYNC: 'GET_CLASS_BY_ID_ASYNC',
  GET_FIND_COMPANY_ASYNC: 'GET_FIND_COMPANY_ASYNC',
  POST_PROPOSAL_ASYNC: 'POST_PROPOSAL_ASYNC',
  GET_MKTU_BY_ID_PROPOSAL_ASYNC: 'GET_MKTU_BY_ID_PROPOSAL_ASYNC',
  DELETE_PROPOSAL_ASYNC: 'DELETE_PROPOSAL_ASYNC',
  POST_TRADEMARKS_SING_ASYNC: 'POST_TRADEMARKS_SING_ASYNC',
  POST_MKTU_TEMPLATE_ASYNC: 'POST_MKTU_TEMPLATE_ASYNC',
  GET_TRADEMARKS_PROPOSAL_BY_ID_ASYNC: 'GET_TRADEMARKS_PROPOSAL_BY_ID_ASYNC',
  PUT_PROPOSAL_ASYNC: 'PUT_PROPOSAL_ASYNC',
  POST_CREATE_IMAGE_ASYNC: 'POST_CREATE_IMAGE_ASYNC',
  POST_ADD_DOCUMENTS_ASYNC: 'POST_ADD_DOCUMENTS_ASYNC',
  GET_TRADEMARKS_PROPOSAL_NEXT_NUMBER_ASYNC: 'GET_TRADEMARKS_PROPOSAL_NEXT_NUMBER_ASYNC',
  PUT_TRADEMARKS_PROPOSAL_CLONE_ASYNC: 'PUT_TRADEMARKS_PROPOSAL_CLONE_ASYNC',
});

export default asyncTypes;
