// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const asyncActions = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_DOCUMENT_TEMPLATES_ASYNC, params);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_DOCUMENT_TEMPLATES_INITIAL_STATE_ASYNC, path);
  },
  deleteDocumentTemplateAsync() {
    return actionCreator(asyncTypes.DELETE_DOCUMENT_TEMPLATE_ASYNC);
  },
  postDocumentTemplatesAsync(data) {
    return actionCreator(asyncTypes.POST_DOCUMENT_TEMPLATES_ASYNC, data);
  },
  getDocumentTemplatesByIdAsync(id) {
    return actionCreator(asyncTypes.GET_DOCUMENT_TEMPLATES_BY_ID_ASYNC, id);
  },
  putDocumentTemplatesByIdAsync(data) {
    return actionCreator(asyncTypes.PUT_DOCUMENT_TEMPLATES_BY_ID_ASYNC, data);
  },
  getAutocompleteOptionsAsync(params) {
    return actionCreator(asyncTypes.GET_DOCUMENT_TEMPLATES_AUTOCOMPLETE_OPTIONS_ASYNC, params);
  },
});

export default asyncActions;
