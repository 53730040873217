// core
import React, {
  memo, useEffect, useState, useCallback, useMemo,
} from 'react';
import {
  reduxForm, Field, Form, change,
} from 'redux-form/immutable';
import { compose } from 'recompose';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';

import styled from 'styled-components';

// lodash
import { map, isEmpty, filter } from 'lodash';

// ui
import {
  CircularProgress, Button as MuiButton,
  IconButton, Tooltip,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import { Archive } from '@material-ui/icons';

// actions
import helpersActions from '../../engine/core/helpers/action';
import integrationsAsyncActions from '../../engine/core/company/integration/saga/asyncAction';
import receivingAsyncActions from '../../engine/core/receiving/saga/asyncAction';

// config
import selectors from '../../engine/config/selectors';

// parts
import Select from '../../ui/Form/Select';
import Modal from '../Modal/Modal';
import DialogActions from '../Modal/components/DialogActions';
import accessList from '../../engine/config/accessList';
import { useAccessList } from '../../ui/_hooks/useAccessList';

// style
const Button = styled(MuiButton)(spacing);

function CheckDocuments(props) {
  const {
    integrationsData, isModalOpen, pendingIntegrationsData, pathname,
    handleSubmit, destroy, pendingPostCheckDocuments, iconButton,
  } = props;
  const [getIntegrations, setGetIntegrations] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const accessIntegrationList = useAccessList(accessList.authorityIntegration_list_get);

  const filterIntegrations = useMemo(() => (
    filter(integrationsData.toJS().items, 'receive')
  ), [integrationsData]);

  const handleCloseModal = () => {
    dispatch(helpersActions.setModal({ isModalOpenCheckDocuments: false }));
    destroy();
  };

  const handleGetIntegrations = () => {
    dispatch(integrationsAsyncActions.getListAsync());
    setGetIntegrations(true);
  };

  const handleSendAsync = useCallback((params) => {
    dispatch(receivingAsyncActions.postCheckDocumentsAsync(params));
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(filterIntegrations) && getIntegrations) {
      if (filterIntegrations.length === 1) {
        handleSendAsync({
          authorityIntegration: [filterIntegrations?.[0]?.id],
        });
      } else {
        dispatch(change('checkDocuments', 'authorityIntegration', 'all'));
        dispatch(helpersActions.setModal({ isModalOpenCheckDocuments: true }));
      }
      setGetIntegrations(false);
    }
  }, [
    dispatch, getIntegrations, filterIntegrations,
    setGetIntegrations, handleSendAsync,
  ]);

  const handleSubmits = (formData) => {
    const json = formData.toJS();
    handleSendAsync({
      ...!isEmpty(json?.authorityIntegration) && json.authorityIntegration === 'all' ? {
        authorityIntegration: map(filterIntegrations, (item) => item.id),
      } : {
        authorityIntegration: [json?.authorityIntegration],
      },
    });
  };

  const getItems = useMemo(() => (
    [
      { value: 'all', name: t('All') },
      ...filterIntegrations,
    ]
  ), [
    filterIntegrations, t,
  ]);

  return (
    <>
      {iconButton ? (
        <Tooltip title={t('Get documents')}>
          <IconButton
            style={{ color: '#4caf50' }}
            disabled={!accessIntegrationList}
            onClick={handleGetIntegrations}
          >
            <Archive fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          variant="contained"
          color="primary"
          disabled={!accessIntegrationList}
          onClick={handleGetIntegrations}
        >
          {(getIntegrations && pendingIntegrationsData) || pendingPostCheckDocuments
            ? <CircularProgress size={25} color="inherit" />
            : t('GET DOCUMENTS')}
        </Button>
      )}
      {(!iconButton || (iconButton && pathname !== '/receiving')) && (
        <Modal
          title={t('Get documents')}
          isModalOpen={isModalOpen}
          handleCloseModal={handleCloseModal}
          displayDialogActions={false}
        >
          <Form onSubmit={handleSubmit(handleSubmits)}>
            <Field
              name="authorityIntegration"
              id="authorityIntegration"
              label={t('Integration')}
              labelId="authorityIntegration"
              my={2}
              items={getItems}
              fullWidth
              component={Select}
            />
            <DialogActions
              pending={pendingPostCheckDocuments}
              handleCloseModal={handleCloseModal}
            />
          </Form>
        </Modal>
      )}
    </>
  );
}

CheckDocuments.propTypes = {
  integrationsData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  pendingPostCheckDocuments: PropTypes.bool.isRequired,
  pendingIntegrationsData: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  destroy: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  iconButton: PropTypes.bool,
  pathname: PropTypes.string.isRequired,
};

CheckDocuments.defaultProps = {
  iconButton: false,
};

function mapStateToProps(state) {
  return {
    isModalOpen: selectors.helpers.isModalOpenCheckDocuments(state),
    pendingPostCheckDocuments: selectors.receiving.pendingPostCheckDocuments(state),
    integrationsData: selectors.integration.integrationsData(state),
    pendingIntegrationsData: selectors.integration.pending(state),
    pathname: selectors.router.pathname(state),
  };
}

function mapDispatchToProps() {
  return {};
}

export default compose(
  reduxForm({
    form: 'checkDocuments',
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(memo(CheckDocuments));
