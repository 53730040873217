// Core
import { call, put } from 'redux-saga/effects';
import { List } from 'immutable';

// config
import { api } from '../../../../../config/api';

// actions
import employeesActions from '../../action';

export function* callGetKeysDataWorker() {
  const {
    setKeysData,
  } = employeesActions;
  yield put(setKeysData({
    pending: true,
  }));

  const response = yield call(api.company.getKeys);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    const { items } = data;
    yield put(setKeysData({
      data: List(items),
    }));
  }

  yield put(setKeysData({
    pending: false,
  }));
}
