// Core
import { apply, put } from 'redux-saga/effects';

// config
import { api } from '../../../../../../config/api';
import { listEntityName } from '../../../../../../config/listTablesName';

// actions
import tableActions from '../../action';

const entity = listEntityName.clients;

export function* callGetClientsWorker({ payload: params }) {
  yield put(tableActions.setClientsData({ pending: true }));

  const response = yield apply(api, api.company.getClients, [{ ...params, entity }]);

  let data = {
    items: [],
    totalCount: 0,
    pending: false,
  };

  if (response && response.status >= 200 && response.status < 400) {
    data = Object.assign(data, response.data);
  }
  yield put(tableActions.setClientsData(data));
  yield put(tableActions.setReload(false));
}
