export default Object.freeze({
  SET_PENDING_IMPORT_OBJECTS: 'SET_PENDING_IMPORT_OBJECTS',
  SET_SPECIAL_DOCUMENTS: 'SET_SPECIAL_DOCUMENTS',
  SET_PENDING_SPECIAL_DOCUMENTS: 'SET_PENDING_SPECIAL_DOCUMENTS',
  SET_IMPORT_SELECTED_OBJECTS: 'SET_IMPORT_SELECTED_OBJECTS',
  MERGE_IMPORT_SELECTED_OBJECTS: 'MERGE_IMPORT_SELECTED_OBJECTS',
  SET_IMPORT_IS_MODAL_OPEN: 'SET_IMPORT_IS_MODAL_OPEN',
  MERGE_IMPORT_ENTITY_LIST: 'MERGE_IMPORT_ENTITY_LIST',
  SET_IMPORT_ENTITY_LIST: 'SET_IMPORT_ENTITY_LIST',
  SET_PENDING_POST_IMPORT_EXCEL: 'SET_PENDING_POST_IMPORT_EXCEL',
  SET_PENDING_POST_EXPORT: 'SET_PENDING_POST_EXPORT',
  SET_IMPORT_IS_MODAL_OPEN_OBJECT: 'SET_IMPORT_IS_MODAL_OPEN_OBJECT',
  SET_IMPORT_ENTITY_LIST_NUMBER: 'SET_IMPORT_ENTITY_LIST_NUMBER',
  MERGE_IMPORT_ENTITY_LIST_NUMBER: 'MERGE_IMPORT_ENTITY_LIST_NUMBER',
});
