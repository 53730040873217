// Core
import { Map, List } from 'immutable';

// Instruments
import { isNil } from 'lodash';
import types from './types';

// config
import globalStateKeys from '../../config/globalStateKeys';

const initialState = Map({
  itemByHashId: Map({
    data: {},
    pending: false,
    pendingPut: false,
  }),
  delete: Map({
    pendingDelete: false,
    id: '',
  }),
  items: Map({
    data: List(),
    pending: false,
    pendingPost: false,
    isModalOpen: false,
    goToListPage: false,
  }),
  data: Map({
    filters: List([{ columnName: 'status', value: 1 }]),
    reload: false,
  }),
  pendingActionsAllList: List(),
  disableReload: false,
  disableNotifications: false,
  entityList: Map(),
  isModalOpenAction: false,
});

export const stateKeys = Object.freeze({
  controller: 'pendingActions',
  pendingActionsAllList: 'pendingActionsAllList',
  disableReload: 'disableReload',
  isModalOpenAction: 'isModalOpenAction',
  disableNotifications: 'disableNotifications',
});

export const pendingActionsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_PENDING_ACTION_BY_ID: {
      return state.mergeIn([globalStateKeys.itemByHashId], payload);
    }
    case types.SET_PENDING_ACTIONS: {
      return state.mergeIn([globalStateKeys.items], payload);
    }
    case types.SET_DELETE_PENDING_ACTION: {
      return state.mergeIn([globalStateKeys.delete], payload);
    }
    case types.SET_PENDING_ACTION_CALENDAR_RELOAD: {
      return state.setIn([globalStateKeys.data, globalStateKeys.reload], payload);
    }
    case types.SET_PENDING_ACTION_CALENDAR_FILTERS: {
      const filters = payload.filter((item) => !isNil(item.value));
      return state.setIn([globalStateKeys.data, globalStateKeys.filters], List(filters));
    }
    case types.SET_PENDING_ACTION_CALENDAR_INITIAL_STATE: {
      const { filters } = payload;
      const reload = true;
      const currentFiltering = filters.length
        ? filters
        : initialState.getIn([globalStateKeys.data, globalStateKeys.filters]);
      return state
        .setIn([globalStateKeys.data, globalStateKeys.filters], List(currentFiltering))
        .setIn([globalStateKeys.data, globalStateKeys.reload], reload);
    }
    case types.SET_PENDING_ACTIONS_ALL_LIST: {
      return state.setIn([stateKeys.pendingActionsAllList], List(payload));
    }
    case types.SET_PENDING_ACTIONS_DISABLE_RELOAD: {
      return state.setIn([stateKeys.disableReload], payload);
    }
    case types.MERGE_PENDING_ACTION_ENTITY_LIST: {
      const { entityName, items } = payload;
      return state.mergeIn([globalStateKeys.entityList], Map({
        [entityName]: entityName === 'users' ? List(items) : Map(items),
      }));
    }
    case types.SET_PENDING_ACTION_ENTITY_LIST: {
      return state.setIn([globalStateKeys.entityList], Map(payload));
    }
    case types.SET_PENDING_ACTION_MODAL_OPEN: {
      return state.setIn([stateKeys.isModalOpenAction], payload);
    }
    case types.SET_PENDING_ACTIONS_DISABLE_NOTIFICATIONS: {
      return state.setIn([stateKeys.disableNotifications], payload);
    }
    default:
      return state;
  }
};
