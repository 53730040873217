// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const asyncActions = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_NOTIFICATIONS_ASYNC, params);
  },
  getNotificationByIdAsync(id) {
    return actionCreator(asyncTypes.GET_NOTIFICATION_BY_ID_ASYNC, id);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_NOTIFICATIONS_INITIAL_STATE_ASYNC, path);
  },
  deleteNotificationAsync() {
    return actionCreator(asyncTypes.DELETE_NOTIFICATION_ASYNC);
  },
  postNotificationAsync(data) {
    return actionCreator(asyncTypes.POST_NOTIFICATION_ASYNC, data);
  },
  putNotificationByIdAsync(data) {
    return actionCreator(asyncTypes.PUT_NOTIFICATION_BY_ID_ASYNC, data);
  },
  getAutocompleteOptionsAsync(params) {
    return actionCreator(asyncTypes.GET_NOTIFICATIONS_AUTOCOMPLETE_OPTIONS_ASYNC, params);
  },
});

export default asyncActions;
