// core
import React, {
  useState, useMemo, useEffect,
} from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import moment from 'moment';
import {
  Field as MuiField, Form, reduxForm, change,
} from 'redux-form/lib/immutable';
import FileInputComponent from 'react-file-input-previews-base64';
import { Map } from 'immutable';

// lodash
import {
  map, isEmpty, sortBy, find, forEach,
  filter, last,
} from 'lodash';

// ui
import {
  Grid, Card, Button as MuiButton,
  Typography as MuiTypography,
  Tooltip, IconButton,
  Drawer as MuiDrawer,
  Box as MuiBox, CircularProgress,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import {
  MailOutline, AttachFile, Delete as DeleteIcon,
  CloudDownload as CloudDownloadIcon,
} from '@material-ui/icons';

// actions
import actions from '../../../engine/core/email/email/action';
import asyncAction from '../../../engine/core/email/email/saga/asyncAction';
import helpersAsyncAction from '../../../engine/core/helpers/saga/asyncAction';
import emailTemplateAsyncAction from '../../../engine/core/email/emailTemplate/saga/asyncAction';
import uploadFileActionAsync from '../../../engine/core/uploadFile/saga/asyncAction';
import uploadFileAction from '../../../engine/core/uploadFile/action';
import helpersActions from '../../../engine/core/helpers/action';

// parts
import RenderTextField from '../../../ui/Form/RenderTextField';
import RenderQuill from '../../../ui/Form/RenderQuill';
import LoaderWithOverlay from '../../../components/LoaderWithOverlay';

// selectors
import selectors from '../../../engine/config/selectors';
import Select from '../../../ui/Form/Select';
import accessList from '../../../engine/config/accessList';
import { useAccessList } from '../../../ui/_hooks/useAccessList';
import AutocompleteEmails from '../../../ui/Form/AutocompleteEmails';
import { useEventsAutocompleteAsync } from '../../../ui/_hooks/useEventsAutocompleteAsync';
import AutocompleteGlobalSearch from '../../../ui/Form/AutocompleteGlobalSearch';

const formName = 'Chat';

const Typography = styled(MuiTypography)(spacing);
const Button = styled(MuiButton)(spacing);
const Field = styled(MuiField)(spacing);

const Drawer = styled(MuiDrawer)`
  .MuiDrawer-paper {
    max-width: 70%;
    min-width: 60%;
  }
`;
const BoxHeader = styled(MuiBox)`
  background: #f5f5f5;
  display: flex;
  justify-content: space-between;
  height: 64px;
  z-index: 2;
  box-shadow: 0 -4px 15px 3px rgb(0 0 0 / 19%);
`;

const ChatContainer = styled(Grid)`
  height: 100%;
  max-height: 100%!important;
  width: 100%;
  overflow-y: scroll;
  background: #f5f5f5;
`;

const ChatMain = styled(Grid)`
  padding: 10px 20px 56px;
`;

const ChatMessages = styled.div`
  margin-bottom: ${(props) => props.margin}px;
`;

const ChatIcon = styled.div`
  width: 36px;
  margin-right: 10px;
  margin-top: 2px;
  display: flex;
  flex-shrink: 0;
`;

const ChatMessage = styled.div`
  margin-bottom: 10px;
`;

const ChatMessageInner = styled.div`
  display: flex;
  background: ${(props) => props.background};
  position: relative;
  color: #313942;
  border: 1px solid #c3c3c3;
  border-radius: 3px;
  padding: 10px 10px 15px;
  width: 100%; 
`;

const ChatMessageBubble = styled.div`
  display: flex;
  flex-direction: column;
  color: #313942;
  flex-grow: 1;
  width: calc(100% - 46px);
  max-width: calc(100% - 46px);
`;

const ChatMessageHeader = styled.div`
  font-size: 13px;
  color: #979797;
  line-height: 20px;
  cursor: pointer;
`;

const ChatMessageBody = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
  padding-right: 5px;
  cursor: pointer;
`;

const ChatMessageAttachments = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  margin-top: 15px;
  position: relative;
  align-items: center;
  border-top: 1px solid rgba(194,194,194,.3);
`;

const BoxSubject = styled(MuiBox)`
  color: #313942;
  text-decoration: underline;
`;

const BoxBody = styled(MuiBox)`
  display: block;
  width: 100%;
`;

const FormChat = styled(Form)`
  width: 100%;
`;

const ChatMessageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const BoxBodySpan = styled(MuiBox)`
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 15px;
`;

const ChatInput = styled.div`
  position: absolute;
  left: calc(20px - 6px);
  right: calc(20px - 6px);
  bottom: 0;
  min-height: 40px;
  flex-shrink: 0;
  display: flex;
  padding: 0 15px 15px;
  user-select: none;
  max-height: calc(100% - 30px);
  border: 1px solid #c3c3c3;
  border-radius: 3px;
  box-shadow: 0 5px 10px 0 rgb(0 0 0 / 10%);
  background: #fff;
  transition: box-shadow .2s;
  z-index: 4;
`;
const TypographyFlex = styled(MuiTypography)`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

function ChatMessageComponent({
  selectedEmail, mail, pendingDownload,
}) {
  const [showContent, setShowContent] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [downloadId, setDownloadId] = useState(0);
  const [mailId, setMailId] = useState(0);

  const handleDownloadAttachment = (attachment) => {
    setMailId(mail.id);
    if (attachment?.idLocal) {
      dispatch(helpersAsyncAction.getDownloadFileAsync({
        fileId: attachment.idLocal,
        filename: attachment.fileName || attachment.filename,
      }));
      setDownloadId(attachment.idLocal);
      return;
    }
    dispatch(asyncAction.getDownloadAttachmentAsync({
      id: mail.id,
      params: { attachmentId: attachment.id },
      fileName: attachment.fileName,
    }));
    setDownloadId(attachment.id);
  };
  return (
    <ChatMessage>
      <ChatMessageInner background={mail?.senderEmail === selectedEmail?.email ? '#f5f5f5' : '#fff'}>
        <ChatIcon>
          <MailOutline />
        </ChatIcon>
        <ChatMessageBubble>
          <ChatMessageHeader onClick={() => setShowContent(!showContent)}>
            {`${mail?.date}
             ${mail?.senderEmail === selectedEmail?.email ? t('Incoming letter from') : t('Sent letter from')}:
             ${mail?.senderEmail}
             ${t('whom')}:
             ${mail?.senderEmail === selectedEmail?.email ? mail?.recipients?.[0]?.name : `${mail?.recipients?.[0]?.name} (${mail?.recipients?.[0]?.email})`}`}
          </ChatMessageHeader>
          <ChatMessageBody onClick={() => setShowContent(!showContent)}>
            <BoxSubject component="span" dangerouslySetInnerHTML={{ __html: mail?.subject }} />
            <ChatMessageContainer>
              {!showContent
                ? <BoxBodySpan component="span">{mail?.snippet}</BoxBodySpan>
                : <BoxBody dangerouslySetInnerHTML={{ __html: mail?.body }} />}
            </ChatMessageContainer>
          </ChatMessageBody>
          {!isEmpty(mail?.attachments) && (
            <ChatMessageAttachments>
              {map(mail?.attachments, (attachment, index) => {
                const id = attachment.idLocal || attachment.id;
                return !isEmpty(attachment) ? (
                  <React.Fragment key={attachment.id || index}>
                    <MuiBox mr={1}>
                      {attachment?.fileName || attachment?.filename || attachment?.id}
                    </MuiBox>
                    <IconButton
                      mx={2}
                      aria-label={t('Download')}
                      size="small"
                      disabled={pendingDownload}
                      onClick={() => handleDownloadAttachment(attachment)}
                    >
                      {pendingDownload && downloadId === id && mail.id === mailId
                        ? <CircularProgress size={20} color="inherit" />
                        : (
                          <Tooltip title={t('Download')} placement="top-start">
                            <CloudDownloadIcon size="small" />
                          </Tooltip>
                        )}
                    </IconButton>
                  </React.Fragment>
                ) : null;
              })}
            </ChatMessageAttachments>
          )}
        </ChatMessageBubble>
      </ChatMessageInner>
    </ChatMessage>
  );
}
ChatMessageComponent.propTypes = {
  mail: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  selectedEmail: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  pendingDownload: PropTypes.bool,
};

ChatMessageComponent.defaultProps = {
  mail: {},
  selectedEmail: {},
  pendingDownload: false,
};

function Chat(props) {
  const {
    isModalOpen, handleSubmit, threadsMessages, userInfo, listEmail,
    pendingThreadsMessages, uploadFile, selectedEmail, pendingDownloadFile,
    destroy, emailTemplateData, listSignatures, pendingDownload,
    autocompleteFilter, pendingPostLetter, emailMessageData, previewText,
  } = props;
  const {
    handleGetAsyncData,
  } = useEventsAutocompleteAsync();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showContent, setShowContent] = useState(true);
  const [readyInitialize, setReadyInitialize] = useState(false);
  const [files, setFiles] = useState([]);
  const [caseValue, setCaseValue] = useState({});
  const [template, setTemplate] = useState({});
  const [signature, setSignature] = useState('');
  const [defaultCaseValue, setDefaultCaseValue] = useState({});
  const [isDefault, setIsDefault] = useState(false);
  const accessPost = useAccessList(accessList.emailLetters_post);
  const accessEmailSignature = useAccessList(accessList.emailSignature_list_get);
  const accessEmailTemplate = useAccessList(accessList.emailTemplate_list_get);
  const accessEmailPreview = useAccessList(accessList.email_preview_post);

  useEffect(() => function cleanup() {
    destroy();
    dispatch(actions.setThreadsMessages([]));
    dispatch(actions.setTotalCountThreadsMessages(0));
    dispatch(actions.setListSignatures([]));
    dispatch(actions.setEmailPreviewText(''));
    dispatch(helpersActions.setAutocompleteGroupFilter({}));
  }, [destroy, dispatch]);

  useEffect(() => {
    if (accessEmailPreview && !isEmpty(template) && !isEmpty(caseValue)) {
      dispatch(asyncAction.postEmailPreviewAsync({
        id: selectedEmail.toJS()?.id,
        data: {
          body: template?.body,
          [caseValue.entity]: [caseValue.id],
        },
      }));
    }
  }, [dispatch, caseValue, accessEmailPreview, template, selectedEmail]);
  useEffect(() => {
    if (!isEmpty(previewText)) {
      dispatch(change(formName, 'body', `${previewText}${signature}`));
    }
  }, [dispatch, signature, previewText]);

  useEffect(() => {
    if (accessEmailTemplate) dispatch(emailTemplateAsyncAction.getListAsync());
    if (accessEmailSignature) dispatch(asyncAction.getEmailSignaturesAsync());
  }, [dispatch, accessEmailSignature, accessEmailTemplate]);

  const messages = useMemo(() => (
    sortBy(threadsMessages.toJS(), (item) => moment(item.date, 'DD.MM.YYYY HH:mm'))
  ), [threadsMessages]);

  useEffect(() => {
    if (!isEmpty(listSignatures.toJS()) && !isEmpty(userInfo)) {
      const findSignature = find(listSignatures.toJS(),
        (item) => item?.employee.id === userInfo.id);
      dispatch(change(formName, 'body', findSignature?.body || ''));
      setSignature(findSignature?.body || '');
    }
  }, [dispatch, listSignatures, userInfo]);

  useEffect(() => {
    if (!isEmpty(messages) && !isEmpty(emailMessageData.toJS().items) && !isDefault) {
      const findItem = find(emailMessageData.toJS().items, { id: last(messages)?.id });
      if (!isEmpty(findItem)) {
        const value = findItem?.trademarks?.[0] || findItem?.industrial?.[0]
        || findItem?.inventions?.[0] || findItem?.utility?.[0] || {};
        setDefaultCaseValue(value);
        setCaseValue(value);
        setIsDefault(true);
      }
    }
  }, [dispatch, messages, emailMessageData, defaultCaseValue, isDefault]);

  useEffect(() => {
    if (!isEmpty(messages) && !readyInitialize && !isEmpty(selectedEmail.toJS())) {
      dispatch(change(formName, 'to', messages[0]?.senderEmail === selectedEmail.toJS()?.email
        ? [messages[0]?.recipients?.[0]?.email] : [messages[0]?.senderEmail]));
      dispatch(change(formName, 'subject', messages[0]?.subject || ''));
      dispatch(change(formName, 'from', selectedEmail.toJS()?.id || ''));
      setReadyInitialize(true);
      setShowContent(false);
    }
  }, [dispatch, messages, readyInitialize, selectedEmail]);

  useEffect(() => {
    if (isEmpty(messages) && (
      (!isEmpty(listEmail.toJS()) && listEmail.size === 1) || !isEmpty(selectedEmail.toJS()))) {
      dispatch(change(formName, 'from', selectedEmail.toJS()?.id || listEmail.toJS()?.[0]?.id));
    }
  }, [dispatch, messages, listEmail, selectedEmail]);

  const getEmails = (list) => map(list, (item) => item.split('<').pop().split('>').shift());

  const handleSubmits = (formData) => {
    const json = formData.toJS();
    dispatch(asyncAction.postEmailSendAsync({
      id: json.from,
      data: {
        ...json,
        from: selectedEmail.toJS()?.email,
        ...!isEmpty(json.to) ? {
          to: getEmails(json.to),
        } : {},
        ...!isEmpty(json.cc) ? {
          cc: getEmails(json.cc),
        } : {},
        ...!isEmpty(json.bcc) ? {
          bcc: getEmails(json.bcc),
        } : {},
        ...!isEmpty(files) ? {
          attachments: map(files, 'fileId'),
        } : {},
        ...!isEmpty(caseValue?.caseNumber) ? {
          subject: `#${caseValue.caseNumber} ${json.subject || ''}`,
        } : {},
      },
    }));
  };

  const handleChangeTemplate = (e) => {
    const findTemplate = find(emailTemplateData.toJS().items, { id: e.target.value });
    setTemplate(findTemplate || {});
    if (!isEmpty(findTemplate)) {
      dispatch(change(formName, 'subject', findTemplate.subject || ''));
      dispatch(change(formName, 'body', `${findTemplate.body || ''}${signature}`));
    }
  };

  const handleClose = () => {
    dispatch(actions.setIsModalOpenMessage(false));
  };
  const handleUploadFile = async (uploadFiles) => {
    forEach(uploadFiles, (file) => {
      dispatch(uploadFileActionAsync.uploadFileAsync({
        file: {
          file: file.base64,
          originalName: file.name,
        },
      }));
    });
  };
  const handleDeleteFile = (file) => {
    setFiles(filter(files, (item) => item.fileId !== file.fileId));
  };

  useEffect(() => {
    if (!isEmpty(uploadFile.toJS())) {
      setFiles([
        ...files,
        uploadFile.toJS(),
      ]);
      dispatch(uploadFileAction.setUploadFile({
        file: Map(),
      }));
    }
  }, [dispatch, uploadFile, files]);

  const listEmails = useMemo(() => {
    const filterList = filter(autocompleteFilter.toJS()?.users || [],
      (item) => !isEmpty(item.email));
    return map(filterList, (item) => `${item.name}<${item.email}>`);
  }, [autocompleteFilter]);

  const handleClearSearch = () => {
    dispatch(helpersActions.setAutocompleteGroupFilter({}));
  };
  const handleChangeEntity = (item) => {
    setCaseValue(item);
  };

  return (
    <Drawer
      anchor="right"
      open={isModalOpen}
      onClose={handleClose}
    >
      {pendingThreadsMessages ? (
        <LoaderWithOverlay />
      ) : (
        <>
          <BoxHeader display="flex" flexDirection="row" alignItems="center">
            <Typography variant="h6" ml={4}>{!isEmpty(messages) ? messages[0]?.subject : ''}</Typography>
          </BoxHeader>

          <ChatContainer
            ref={(component) => {
              if (component) component.scrollTop = component?.scrollHeight || 0; // eslint-disable-line
            }}
            container
            component={Card}
          >
            <ChatMain item xs={12}>
              <ChatMessages margin={showContent ? 400 : 100}>
                {!isEmpty(messages) && (
                  <>
                    {map(messages, (mail) => (
                      <ChatMessageComponent
                        mail={mail}
                        key={mail.id}
                        selectedEmail={selectedEmail.toJS()}
                        pendingDownload={pendingDownload || pendingDownloadFile}
                      />
                    ))}
                  </>
                )}
              </ChatMessages>
              {accessPost && (
                <ChatInput>
                  <FormChat onSubmit={handleSubmit(handleSubmits)}>
                    <Field
                      mt={2}
                      name="to"
                      id="to"
                      label={t('Email for')}
                      margin="none"
                      variant="standard"
                      fullWidth
                      disabled={!isEmpty(messages)}
                      type="text"
                      multiple
                      getAsyncData={handleGetAsyncData}
                      handleClearSearch={handleClearSearch}
                      options={listEmails}
                      component={AutocompleteEmails}
                      onClickField={() => setShowContent(true)}
                    />
                    {showContent && (
                      <>
                        <Field
                          name="cc"
                          id="cc"
                          label={t('Copy')}
                          margin="none"
                          variant="standard"
                          fullWidth
                          type="text"
                          multiple
                          getAsyncData={handleGetAsyncData}
                          handleClearSearch={handleClearSearch}
                          options={listEmails}
                          component={AutocompleteEmails}
                        />
                        <Field
                          name="bcc"
                          id="bcc"
                          label={t('Bcc')}
                          margin="none"
                          variant="standard"
                          fullWidth
                          type="text"
                          multiple
                          getAsyncData={handleGetAsyncData}
                          handleClearSearch={handleClearSearch}
                          options={listEmails}
                          component={AutocompleteEmails}
                        />
                        <Field
                          name="subject"
                          id="subject"
                          label={t('Topic')}
                          margin="none"
                          variant="standard"
                          fullWidth
                          type="text"
                          component={RenderTextField}
                        />
                        <Field
                          name="from"
                          id="from"
                          label={t('From')}
                          labelId="from"
                          component={Select}
                          items={listEmail.toJS()}
                          disabled={!isEmpty(messages)}
                          margin="none"
                          fullWidth
                        />
                        <AutocompleteGlobalSearch
                          label={t('Tie the case')}
                          handleChange={handleChangeEntity}
                          defaultValue={defaultCaseValue}
                          helperText={t('Enter the № case, № application, № registration or name')}
                          disableIcon
                          disableClear
                          entities={
                            ['trademarks', 'utility', 'inventions', 'industrial']
                          }
                        />
                        {!isEmpty(emailTemplateData.toJS().items) && (
                          <Field
                            name="template"
                            id="template"
                            label={t('Template')}
                            labelId="template"
                            component={Select}
                            items={emailTemplateData.toJS().items}
                            margin="none"
                            onChange={handleChangeTemplate}
                            fullWidth
                          />
                        )}
                        <Field
                          name="body"
                          id="body"
                          labelId="body"
                          label={t('Message')}
                          margin="none"
                          my={2}
                          fullWidth
                          type="text"
                          component={RenderQuill}
                        />
                        {!isEmpty(files) && (
                          <Grid item xs={12}>
                            {map(files, (file, index) => (
                              <TypographyFlex key={index}>
                                <MuiBox component="span">
                                  {file?.filename}
                                </MuiBox>
                                <IconButton
                                  aria-label="Delete"
                                  size="small"
                                  onClick={() => handleDeleteFile(file)}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </TypographyFlex>
                            ))}
                          </Grid>
                        )}
                        <MuiBox display="flex" flexWrap="wrap">
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            mr={2}
                            disabled={pendingPostLetter}
                          >
                            {pendingPostLetter ? <CircularProgress size={20} color="primary" /> : t('SEND')}
                          </Button>
                          <Button
                            onClick={() => setShowContent(false)}
                            color="primary"
                            variant="outlined"
                          >
                            {t('CANCEL')}
                          </Button>

                          <FileInputComponent
                            name="logo"
                            imagePreview={false}
                            labelStyle={{ display: 'none' }}
                            parentStyle={{ margin: 0 }}
                            callbackFunction={handleUploadFile}
                            accept="file"
                            buttonComponent={(
                              <Button
                                variant="text"
                                color="primary"
                              >
                                <AttachFile mr={2} />
                                {t('Add files')}
                              </Button>
                            )}
                          />
                        </MuiBox>
                      </>
                    )}
                  </FormChat>
                </ChatInput>
              )}
            </ChatMain>
          </ChatContainer>
        </>
      )}
    </Drawer>
  );
}

Chat.displayName = 'Chat';

Chat.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  pendingThreadsMessages: PropTypes.bool.isRequired,
  pendingDownloadFile: PropTypes.bool.isRequired,
  pendingDownload: PropTypes.bool.isRequired,
  pendingPostLetter: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  destroy: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  threadsMessages: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  emailTemplateData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  listSignatures: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  userInfo: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  listEmail: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  uploadFile: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  selectedEmail: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  autocompleteFilter: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  emailMessageData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  previewText: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    isModalOpen: selectors.email.isModalOpenMessage(state),
    pendingThreadsMessages: selectors.email.pendingThreadsMessages(state),
    threadsMessages: selectors.email.threadsMessages(state),
    emailTemplateData: selectors.emailTemplate.emailTemplateData(state),
    listSignatures: selectors.email.listSignatures(state),
    pendingPostLetter: selectors.email.pendingPostLetter(state),
    userInfo: selectors.user.userInfo(state),
    listEmail: selectors.email.listEmail(state),
    uploadFile: selectors.uploadFile.uploadFile(state),
    selectedEmail: selectors.email.selectedEmail(state),
    pendingDownload: selectors.email.pendingDownloadAttachment(state),
    pendingDownloadFile: selectors.helpers.pendingDownloadFile(state),
    autocompleteFilter: selectors.helpers.autocompleteFilter(state),
    emailMessageData: selectors.email.emailMessageData(state),
    previewText: selectors.email.previewText(state),
  };
}

export default compose(
  reduxForm({
    form: formName,
  }),
  connect(mapStateToProps, null),
)(Chat);
