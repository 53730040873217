// core
import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

// ui
import {
  Box,
  Button, ButtonGroup, Divider as MuiDivider,
  Typography,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import { Add as AddIcon } from '@material-ui/icons';

// parts
import NotificationsTable from './components/NotificationsTable';

// hooks
import { useNotifications } from './_hooks/useNotifications';
import actions from '../../engine/core/notifications/action';
import accessList from '../../engine/config/accessList';
import { useAccessList } from '../../ui/_hooks/useAccessList';

// styles
const Divider = styled(MuiDivider)(spacing);

function Notifications() {
  useNotifications();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const accessPost = useAccessList(accessList.notifications_post);

  return (
    <>
      <Helmet title={t('Notifications rules')} />
      <Typography variant="h3" gutterBottom display="inline">
        {t('Notifications rules')}
      </Typography>
      <Divider my={6} />

      {accessPost && (
        <Box mb={2}>
          <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                dispatch(actions.setNotificationById({
                  isModalOpen: true,
                }));
              }}
            >
              {t('ADD')}
              <AddIcon />
            </Button>
          </ButtonGroup>
        </Box>
      )}

      <NotificationsTable />
    </>
  );
}

export default Notifications;
