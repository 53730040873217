// Core
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Helmet from 'react-helmet';
import { Typography, Divider as MuiDivider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';

import ProposalTable from './ProposalTable';
import { useProposalList } from './_hooks/useProposalList';

const Divider = styled(MuiDivider)(spacing);

function Proposal() {
  const { t } = useTranslation();
  useProposalList();

  return (
    <>
      <Helmet title={t('Trade marks')} />
      <Typography variant="h3" gutterBottom display="inline">
        {t('Trade marks')}
      </Typography>
      <Divider my={6} />
      <ProposalTable />
    </>
  );
}

Error.displayName = 'Proposal';

Error.propTypes = {};

Proposal.defaultProps = {};

function mapStateToProps() {
  return {};
}

function mapDispatchToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Proposal);
