// Core
import { all, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callSignInWorker,
  callGetUserInfoWorker,
  callLogOutWorker,
  callAuthCleanUpWorker,
  callResetPasswordWorker,
  callPutCurrentLanguageWorker,
} from './workers';

function* watchSignIn() {
  yield takeEvery(asyncTypes.SIGN_IN_ASYNC, callSignInWorker);
}
function* watchGetUserInfo() {
  yield takeEvery(asyncTypes.GET_USER_INFO_ASYNC, callGetUserInfoWorker);
}
function* watchAuthCleanUp() {
  yield takeEvery(asyncTypes.AUTH_CLEAN_UP, callAuthCleanUpWorker);
}
function* watchLogOut() {
  yield takeEvery(asyncTypes.LOG_OUT_ASYNC, callLogOutWorker);
}
function* watchResetPassword() {
  yield takeEvery(asyncTypes.RESET_PASSWORD_ASYNC, callResetPasswordWorker);
}
function* watchPutCurrentLanguage() {
  yield takeEvery(asyncTypes.SET_USER_LANGUAGE_ASYNC, callPutCurrentLanguageWorker);
}
export function* watchersUser() {
  yield all([
    watchSignIn(),
    watchGetUserInfo(),
    watchAuthCleanUp(),
    watchLogOut(),
    watchResetPassword(),
    watchPutCurrentLanguage(),
  ]);
}
