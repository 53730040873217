// Core
import { apply, put } from 'redux-saga/effects';

// Engine
import { push } from 'connected-react-router';
import { api, authService } from '../../../../config/api';
import localData from '../../../../config/localData';

// Instruments
import userActionAsync from '../asyncAction';
import { routersLink } from '../../../../../routes';

export function* callLogOutWorker({ payload: serverLogOut }) {
  const refreshToken = yield apply(authService, authService.getRefreshToken);
  if (refreshToken && serverLogOut) {
    yield apply(api, api.user.logout, [{ refreshToken }]);
  }
  yield apply(localData, localData.clearStorage);
  yield put(userActionAsync.authCleanUp());
  yield put(push(routersLink.users.signIn));
}
