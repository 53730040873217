const asyncTypes = Object.freeze({
  GET_MONITORING_ASYNC: 'GET_MONITORING_ASYNC',
  GET_MONITORING_INITIAL_STATE_ASYNC: 'GET_MONITORING_INITIAL_STATE_ASYNC',
  DELETE_MONITORING_ASYNC: 'DELETE_MONITORING_ASYNC',
  SET_NUMBER_PER_PAGE_ASYNC: 'SET_NUMBER_PER_PAGE_ASYNC',
  GET_MONITORING_REPORT_DATA_ASYNC: 'GET_MONITORING_REPORT_DATA_ASYNC',
  GET_MONITORING_BY_ID_ASYNC: 'GET_MONITORING_BY_ID_ASYNC',
  POST_MONITORING_ASYNC: 'POST_MONITORING_ASYNC',
  PUT_MONITORING_BY_ID_ASYNC: 'PUT_MONITORING_BY_ID_ASYNC',
});

export default asyncTypes;
