// Core
import React, { useEffect, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

// lodash
import { find, isEqual } from 'lodash';

// parts
import DxTable from '../../../../../../ui/Table/DxTable';

// actions
import actions from '../../../../../../engine/core/prices/tariffs/tariff/action';
import asyncActions from '../../../../../../engine/core/prices/tariffs/tariff/saga/asyncAction';
import helpersActions from '../../../../../../engine/core/helpers/action';
import currenciesAsyncAction from '../../../../../../engine/core/currencies/currenciesList/saga/asyncAction';
import currenciesActions from '../../../../../../engine/core/currencies/currenciesList/action';

// config
import selectors from '../../../../../../engine/config/selectors';
import ConfirmModal from '../../../../../../components/ConfirmModal';

import { listTablesName, listEntityName } from '../../../../../../engine/config/listTablesName';
import accessList from '../../../../../../engine/config/accessList';
import { useAccessList } from '../../../../../../ui/_hooks/useAccessList';

const tableName = listTablesName.tariff;
const entityName = listEntityName.tariff;

function TariffTable(props) {
  const {
    pendingDelete, data, totalCount, pending, currentPage, pageSize,
    id, checkboxes, filters, sorting, currenciesData, accessEdit,
  } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const accessGetList = useAccessList(accessList.currencies_list_get);

  const basePriseCode = useMemo(() => {
    const findCurrency = find(currenciesData.toJS().items, 'default');
    return findCurrency?.code || '';
  }, [currenciesData]);

  const columns = [
    { name: 'id', title: t('ID') },
    { name: 'name', title: t('Name') },
    { name: 'type', title: t('Type') },
    { name: 'baseField', title: t('Base Field') },
    { name: 'calcFrom', title: t('Calc From') },
    { name: 'basePrice', title: `${t('Price in')} ${basePriseCode}` },
  ];

  useEffect(() => {
    if (accessGetList) dispatch(currenciesAsyncAction.getListAsync());
  }, [dispatch, accessGetList]);

  useEffect(() => function cleanup() {
    dispatch(currenciesActions.setCurrenciesData({
      items: [],
      totalCount: 0,
      pending: false,
    }));
  }, [dispatch]);

  // Filtering
  const setFilters = (getFilters) => dispatch(actions.setFilters(getFilters));

  // Sorting
  const onSortingChange = (getSorting) => dispatch(actions.setSorting(getSorting));

  // Paging
  const onCurrentPage = (page) => dispatch(actions.setCurrentPage(page));
  const onPageSize = (size) => dispatch(actions.setPageSize(size));

  return (
    <>
      <DxTable
        name="TariffTable"
        isLoading={id ? pending : false}
        columns={columns}
        rows={id ? data.toJS().items : []}
        totalCount={totalCount}
        pageSize={id ? pageSize : 0}
        currentPage={currentPage}
        onPageSize={onPageSize}
        onCurrentPage={onCurrentPage}
        // checkboxes ------------------------
        tableName={tableName}
        reloadTable={actions.setReload}
        {...accessEdit ? {
          selection: checkboxes.toJS(),
        } : {}}
        onSelectionChange={(checkboxSelect) => {
          dispatch(helpersActions.setCheckbox({
            tableName,
            checkboxes: checkboxSelect,
          }));
        }}
        // ------------------------
        filtersAction={setFilters}
        filters={filters}
        entityName={entityName}
        onSortingChange={onSortingChange}
        sorting={sorting}
        disableHiddenColumn
        disableColumnOrder
        disableColumnWidth
        disableExport
      />

      <ConfirmModal
        buttonSendText="DELETE"
        pending={pendingDelete}
        handleSend={() => dispatch(asyncActions.deleteTariffTable())}
      />
    </>
  );
}

TariffTable.displayName = 'TariffTable';

TariffTable.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingDelete: PropTypes.bool.isRequired,
  pending: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalCount: PropTypes.number,
  id: PropTypes.string,
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  sorting: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  checkboxes: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  currenciesData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  accessEdit: PropTypes.bool.isRequired,
};

TariffTable.defaultProps = {
  totalCount: 0,
  id: '',
};

function mapStateToProps(state) {
  return {
    data: selectors.pricesTariff.pricesTariffData(state),
    pending: selectors.pricesTariff.pending(state),
    pendingDelete: selectors.pricesTariff.pendingDeleteTariffTable(state),

    // Paging
    totalCount: selectors.pricesTariff.totalCount(state),
    currentPage: selectors.pricesTariff.currentPage(state),
    pageSize: selectors.pricesTariff.pageSize(state),

    // Filtering
    filters: selectors.pricesTariff.filters(state),

    sorting: selectors.pricesTariff.sorting(state),

    checkboxes: selectors.helpers.getCheckboxes(state, tableName),
    currenciesData: selectors.currencies.currenciesData(state),
  };
}

function mapDispatchToProps() {
  return {};
}
function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.data, nextProps.data)
    && isEqual(prevProps.pendingDelete, nextProps.pendingDelete)
    && isEqual(prevProps.pending, nextProps.pending)
    && isEqual(prevProps.currentPage, nextProps.currentPage)
    && isEqual(prevProps.pageSize, nextProps.pageSize)
    && isEqual(prevProps.totalCount, nextProps.totalCount)
    && isEqual(prevProps.id, nextProps.id)
    && isEqual(prevProps.filters, nextProps.filters)
    && isEqual(prevProps.sorting, nextProps.sorting)
    && isEqual(prevProps.checkboxes, nextProps.checkboxes)
    && isEqual(prevProps.currenciesData, nextProps.currenciesData)
    && isEqual(prevProps.accessEdit, nextProps.accessEdit);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(React.memo(TariffTable, areEqual));
