// Core
import { all, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callPostImportObjectsWorker,
  callPostSpezDocumentsWorker,
  callPostImportExcelWorker,
  callPostExportWorker,
} from './workers';

function* watchPostImportObjects() {
  yield takeEvery(asyncTypes.POST_IMPORT_OBJECTS_ASYNC, callPostImportObjectsWorker);
}
function* watchPostImportExcel() {
  yield takeEvery(asyncTypes.POST_IMPORT_EXCEL_ASYNC, callPostImportExcelWorker);
}
function* watchPostSpezDocuments() {
  yield takeEvery(asyncTypes.POST_IMPORT_SPEZ_DOCUMENTS, callPostSpezDocumentsWorker);
}
function* watchPostExport() {
  yield takeEvery(asyncTypes.POST_EXPORT_ASYNC, callPostExportWorker);
}

export function* watchersImportObjects() {
  yield all([
    watchPostImportObjects(),
    watchPostImportExcel(),
    watchPostSpezDocuments(),
    watchPostExport(),
  ]);
}
