// core
import React, { useMemo } from 'react';
import { Field } from 'redux-form/lib/immutable';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

// lodash
import {
  isEmpty, map, sortBy,
} from 'lodash';

// parts
import AutocompleteField from '../AutocompleteField';

// config
import selectors from '../../../engine/config/selectors';

// helpers
import { getValueFormField } from '../../_helpers/getValueFormField';
import { searchParams } from './helper/searchParams';

// hooks
import { useMain } from './_hook/useMain';

function FieldDepartmentDocuments(props) {
  const {
    data, formFieldInput, autocompleteOptions,
    name, propsField, pendingDefaultList,
    pendingAutocompleteOptions, getAutocompleteLists,
    searchParamName, getAsyncData, formField, label,
  } = props;
  const { t } = useTranslation();
  const autocompleteOptionsItems = useMemo(() => autocompleteOptions.get(searchParamName), [
    autocompleteOptions, searchParamName,
  ]);
  const {
    startLoading, handleOnFocus, dataItems, checkOptions,
  } = useMain({
    formField,
    getAutocompleteLists,
    formFieldInput,
  });
  const handleGetAsyncData = (value, fieldName) => {
    getAsyncData(value, fieldName, {
      [searchParamName]: value,
      [searchParams.outNumber]: value,
    });
  };

  const dataItemsGroup = useMemo(() => {
    if (name === 'responseDocument' && !isEmpty(dataItems(data))) {
      return map(sortBy(dataItems(data), 'type'), (item) => {
        const groupTitle = item.type === 'out' ? t('Outgoing') : t('Input');
        return {
          ...item,
          groupTitle,
        };
      });
    }
    return dataItems(data);
  }, [dataItems, data, name, t]);

  return (
    <Field
      fullWidth
      changeToStore
      name={name}
      handleOnFocus={handleOnFocus(name, data)}
      loading={startLoading ? (pendingDefaultList || pendingAutocompleteOptions) : false}
      getAsyncData={handleGetAsyncData}
      label={t(label)}
      margin="normal"
      {...name === 'responseDocument' ? {
        isGroupBy: true,
      } : {}}
      options={checkOptions
        ? dataItemsGroup
        : autocompleteOptionsItems}
      component={AutocompleteField}
      {...propsField}
    />
  );
}

FieldDepartmentDocuments.displayName = 'FieldDepartmentDocuments';

FieldDepartmentDocuments.propTypes = {
  autocompleteOptions: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.bool,
  ]).isRequired,
  data: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  getAutocompleteLists: PropTypes.func.isRequired,
  pendingDefaultList: PropTypes.bool.isRequired,
  pendingAutocompleteOptions: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  propsField: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  formField: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array,
  ]),
  formFieldInput: PropTypes.string,
  searchParamName: PropTypes.string,
  getAsyncData: PropTypes.func,
  label: PropTypes.string,
};

FieldDepartmentDocuments.defaultProps = {
  formField: {},
  propsField: {},
  formFieldInput: '',
  getAsyncData: () => {},
  label: 'On the document',
  searchParamName: searchParams.inNumber,
};

function mapStateToProps(state, props) {
  const { formName, name } = props;
  const formField = getValueFormField(state, formName, name);
  const formFieldInput = getValueFormField(state, formName, `${name}Input`);

  return {
    pendingAutocompleteOptions: selectors.departmentDocuments.pendingAutocompleteOptions(state),
    pendingDefaultList: selectors.departmentDocuments.pending(state),
    autocompleteOptions: selectors.departmentDocuments.autocompleteOptions(state),
    data: selectors.departmentDocuments.departmentDocumentsData(state),
    formField: formField(),
    formFieldInput: formFieldInput(),
  };
}

export default compose(
  connect(mapStateToProps, null),
)(FieldDepartmentDocuments);
