// core
import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import {
  Field, Form, reduxForm,
} from 'redux-form/lib/immutable';

// lodash
import { isEmpty, map } from 'lodash';

// config
import selectors from '../../../engine/config/selectors';

// parts
import Modal from '../../../components/Modal/Modal';
import DialogActions from '../../../components/Modal/components/DialogActions';
import RenderTextField from '../../../ui/Form/RenderTextField';
import Loader from '../../../components/Loader';

// actions
import asyncAction from '../../../engine/core/documentTypes/category/saga/asyncAction';
import actions from '../../../engine/core/documentTypes/category/action';
import accessList from '../../../engine/config/accessList';
import { useAccessList } from '../../../ui/_hooks/useAccessList';
// import helpersActionAsync from '../../../engine/core/helpers/saga/asyncAction';
// import helpersActions from '../../../engine/core/helpers/action';
// import FieldDocumentClassifier from
// '../../../ui/Form/FieldsAutocomplete/FieldDocumentClassifier';
// import { useEventsAutocompleteAsync } from '../../../ui/_hooks/useEventsAutocompleteAsync';

function CategoriesModal(props) {
  const {
    handleSubmit, initialize,
    isModalOpen, pendingById,
    destroy, pendingPut,
    pendingPost, getDocumentTypeCategoryById,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const {
  //   getAutocompleteLists, handleGetAsyncData,
  // } = useEventsAutocompleteAsync();
  const accessPut = useAccessList(accessList.documentClassifierCategory_put);
  const accessEdit = !isEmpty(getDocumentTypeCategoryById) ? accessPut : true;

  const handleCloseModal = () => {
    dispatch(actions.setDocumentTypeCategoryById({ isModalOpen: false }));
  };

  // useEffect(() => {
  //   dispatch(helpersActionAsync.getDocumentsTypeAsync());
  // }, [dispatch]);

  useEffect(() => function cleanup() {
    destroy();
    // dispatch(helpersActions.setDocumentsType([]));
    dispatch(actions.setDocumentTypeCategoryById({
      data: {},
    }));
  }, [destroy, dispatch]);

  const handleSubmits = (formData) => {
    const json = formData.toJS();
    dispatch(asyncAction[!isEmpty(getDocumentTypeCategoryById)
      ? 'putDocumentTypeCategoryByIdAsync'
      : 'postDocumentTypeCategoryAsync']({
      ...json,
      ...!isEmpty(json?.documentClassifier) ? {
        documentClassifier: map(json.documentClassifier, 'id'),
      } : {},
    }));
  };

  useEffect(() => {
    initialize(!isEmpty(getDocumentTypeCategoryById)
      ? getDocumentTypeCategoryById : {});
  }, [
    getDocumentTypeCategoryById, initialize,
  ]);

  return (
    <Modal
      title={t('Category of document type')}
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
      displayDialogActions={false}
    >
      {!pendingById ? (
        <Form onSubmit={handleSubmit(handleSubmits)}>
          <Field
            name="name"
            id="name"
            label={t('Name')}
            margin="normal"
            variant="standard"
            fullWidth
            type="text"
            component={RenderTextField}
            disabled={!accessEdit}
          />

          <Field
            name="index"
            id="index"
            label={t('Category order')}
            margin="normal"
            variant="standard"
            fullWidth
            type="text"
            component={RenderTextField}
            disabled={!accessEdit}
          />

          {/* <Field
            name="documentClassifier"
            id="documentClassifier"
            label={t('Document classifier')}
            labelId="documentClassifier"
            my={2}
            margin="normal"
            fullWidth
            multiple
            options={documentType.toJS()}
            component={AutocompleteField}
          /> */}

          {/* <FieldDocumentClassifier
            name="documentClassifier"
            formName="CategoriesModal"
            getAutocompleteLists={getAutocompleteLists}
            getAsyncData={handleGetAsyncData}
            propsField={{
              multiple: true,
            }}
          /> */}

          <DialogActions
            pending={pendingPost || pendingPut}
            handleCloseModal={handleCloseModal}
            visibleButtonSend={accessEdit}
          />
        </Form>
      ) : <Loader position />}
    </Modal>
  );
}

CategoriesModal.displayName = 'CategoriesModal';

CategoriesModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  pendingPost: PropTypes.bool.isRequired,
  pendingById: PropTypes.bool.isRequired,
  pendingPut: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  destroy: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  getDocumentTypeCategoryById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

CategoriesModal.defaultProps = {};

function mapStateToProps(state) {
  return {
    isModalOpen: selectors.documentTypesCategory.isModalOpen(state),
    pendingPost: selectors.documentTypesCategory.pendingPostDocumentTypeCategory(state),
    pendingById: selectors.documentTypesCategory.pendingDocumentTypeCategoryById(state),
    pendingPut: selectors.documentTypesCategory.pendingPutDocumentTypeCategory(state),
    getDocumentTypeCategoryById: selectors.documentTypesCategory.getDocumentTypeCategoryById(state),
  };
}

export default compose(
  reduxForm({
    form: 'CategoriesModal',
  }),
  connect(mapStateToProps, null),
)(CategoriesModal);
