/**
 * Use when need to get value from "redux-form"
 * @parameters state, formName, name
 * */

// config
import selectors from '../../engine/config/selectors';

export const getValueFormField = (state, formName, name) => {
  const splitName = name.split('.');
  const getFormValues = selectors.form.getFormValues(state, formName);
  const selectorFormField = () => {
    if (splitName.length > 1) {
      // иногда бывает имя поля для компонента Field не одной строкой,
      // а например (name="original.trademarks")
      const fieldData = getFormValues.toJS()[splitName[0]];
      return fieldData ? fieldData[splitName[1]] : '';
    }
    return getFormValues.toJS()[name];
  };

  return selectorFormField;
};
