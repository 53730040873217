// Core
import { Map, List } from 'immutable';

// Instruments
import { isNil } from 'lodash';
import { pageSizes } from '../../config/globalConfig';
import { types } from './types';
import globalStateKeys from '../../config/globalStateKeys';

const initialState = Map({
  items: List(),
  data: Map({
    applicantId: '',
    pendingPost: false,
    pending: false,
    pendingPut: false,
    openFormAddApplicant: false,
    reload: false,
    currentPage: 0,
    filters: List(),
    sorting: List([{ columnName: 'id', direction: 'desc' }]),
    totalCount: 0,
    pageSize: pageSizes[0],
    isModalOpen: false,
    columnWidths: List(),
    columnOrder: List(),
    hiddenColumnNames: List(),
  }),
  autocompleteOptions: Map({
    items: Map(),
    pending: false,
  }),
  initialList: Map({
    items: List(),
  }),
  newApplicantsList: List(),
  newApplicant: Map(),
  newOwnersList: List(),
  newAuthorsList: List(),
  itemByHashId: {
    data: Map(),
    pending: false,
  },
  delete: Map({
    pendingDelete: false,
    id: '',
  }),
});

export const stateKeys = Object.freeze({
  controller: 'applicants',
  applicantId: 'applicantId',
  openFormAddApplicant: 'openFormAddApplicant',
  newApplicantsList: 'newApplicantsList',
  initialList: 'initialList',
  newApplicant: 'newApplicant',
  newOwnersList: 'newOwnersList',
  newAuthorsList: 'newAuthorsList',
});

export const applicantsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_APPLICANTS_LIST: {
      return state.setIn([globalStateKeys.items], List(payload));
    }
    case types.SET_APPLICANTS_DATA: {
      return state.mergeIn([globalStateKeys.data], payload);
    }
    case types.SET_APPLICANTS_MODAL_IS_OPEN: {
      return state.setIn([globalStateKeys.data, globalStateKeys.isModalOpen], payload);
    }
    case types.SET_APPLICANTS_RELOAD: {
      return state.setIn([globalStateKeys.data, globalStateKeys.reload], payload);
    }
    case types.SET_APPLICANTS_CURRENT_PAGE: {
      return state.setIn([globalStateKeys.data, globalStateKeys.currentPage], payload);
    }
    case types.SET_APPLICANTS_FILTERS: {
      const filters = payload.filter((item) => !isNil(item.value));
      return state.setIn([globalStateKeys.data, globalStateKeys.filters], List(filters));
    }
    case types.SET_APPLICANTS_SORTING: {
      return state.setIn([globalStateKeys.data, globalStateKeys.sorting], List(payload));
    }
    case types.SET_APPLICANTS_COLUMN_WIDTHS: {
      return state.setIn([globalStateKeys.data, globalStateKeys.columnWidths], List(payload));
    }
    case types.SET_APPLICANTS_COLUMN_ORDER: {
      return state.setIn([globalStateKeys.data, globalStateKeys.columnOrder], List(payload));
    }
    case types.SET_APPLICANTS_HIDDEN_COLUMN_NAMES: {
      return state.setIn([globalStateKeys.data, globalStateKeys.hiddenColumnNames], List(payload));
    }
    case types.SET_APPLICANTS_PAGE_SIZE: {
      return state.setIn([globalStateKeys.data, globalStateKeys.pageSize], payload);
    }
    case types.SET_APPLICANTS_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, sorting = [],
        columnWidths, columnOrder, hiddenColumnNames,
      } = payload;
      const reload = true;
      const currentSorting = sorting.length
        ? sorting
        : initialState.getIn([globalStateKeys.data, globalStateKeys.sorting]);
      return state
        .setIn([globalStateKeys.data, globalStateKeys.currentPage], currentPage)
        .setIn([globalStateKeys.data, globalStateKeys.filters], List(filters))
        .setIn([globalStateKeys.data, globalStateKeys.pageSize], pageSize)
        .setIn([globalStateKeys.data, globalStateKeys.totalCount], totalCount)
        .setIn([globalStateKeys.data, globalStateKeys.reload], reload)
        .setIn([globalStateKeys.data, globalStateKeys.columnWidths], List(columnWidths))
        .setIn([globalStateKeys.data, globalStateKeys.columnOrder], List(columnOrder))
        .setIn([globalStateKeys.data, globalStateKeys.hiddenColumnNames], List(hiddenColumnNames))
        .setIn([globalStateKeys.data, globalStateKeys.sorting], List(currentSorting));
    }
    case types.SET_APPLICANTS_AUTOCOMPLETE_OPTIONS: {
      return state.mergeIn([
        globalStateKeys.autocompleteOptions,
        globalStateKeys.items,
      ], Map(payload));
    }
    case types.MERGE_NEW_APPLICANTS_LIST: {
      return state.mergeIn([
        stateKeys.newApplicantsList,
      ], List(payload));
    }
    case types.SET_NEW_APPLICANTS_LIST: {
      return state.setIn([
        stateKeys.newApplicantsList,
      ], List(payload));
    }
    case types.SET_INITIAL_APPLICANTS_LIST: {
      return state.mergeIn([
        stateKeys.initialList,
      ], Map(payload));
    }
    case types.SET_PENDING_APPLICANTS_AUTOCOMPLETE_OPTIONS: {
      return state.setIn([
        globalStateKeys.autocompleteOptions,
        globalStateKeys.pending,
      ], payload);
    }
    case types.SET_NEW_APPLICANT: {
      return state.setIn([
        stateKeys.newApplicant,
      ], Map(payload));
    }
    case types.SET_APPLICANT_BY_ID: {
      return state.setIn([globalStateKeys.itemByHashId, globalStateKeys.data], Map(payload));
    }
    case types.SET_PENDING_GET_APPLICANT_BY_ID: {
      return state.setIn([globalStateKeys.itemByHashId, globalStateKeys.pending], payload);
    }
    case types.SET_DELETE_APPLICANT: {
      return state.mergeIn([globalStateKeys.delete], payload);
    }
    case types.MERGE_APPLICANT_NEW_OWNERS_LIST: {
      return state.mergeIn([stateKeys.newOwnersList], List(payload));
    }
    case types.SET_APPLICANT_NEW_OWNERS_LIST: {
      return state.setIn([stateKeys.newOwnersList], List(payload));
    }
    case types.MERGE_APPLICANT_NEW_AUTHORS_LIST: {
      return state.mergeIn([stateKeys.newAuthorsList], List(payload));
    }
    case types.SET_APPLICANT_NEW_AUTHORS_LIST: {
      return state.setIn([stateKeys.newAuthorsList], List(payload));
    }

    default:
      return state;
  }
};
