// Core
import { all, call, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetTariffTableWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callPostTariffTableWorker,
  callGetTariffTableByIdWorker,
  callPutTariffTableByIdWorker,
  callDeleteTariffTableWorker,
  callDeleteTariffWorker,
  callGetTariffIDWorker,
  callPostTariffWorker,
  callPutTariffWorker,
} from './workers';
import types from '../types';

function* watchGetTariffTable() {
  yield takeEvery(asyncTypes.GET_PRICES_TARIFF_TABLE_ASYNC, callGetTariffTableWorker);
}

function* watchGetInitialState() {
  yield takeEvery(
    asyncTypes.GET_PRICES_TARIFF_TABLE_INITIAL_STATE_ASYNC,
    callGetInitialStateWorker,
  );
}

function* watchSetFilters() {
  yield takeEvery(types.SET_TARIFF_TABLE_FILTER, callSetToLocalDataWorker);
}

function* watchSetSorting() {
  yield takeEvery(types.SET_TARIFF_TABLE_SORTING, callSetToLocalDataWorker);
}

function* watchSetCurrentPage() {
  yield takeEvery(types.SET_PRICES_TARIFF_TABLE_CURRENT_PAGE, callSetToLocalDataWorker);
}

function* watchSetPageSize() {
  yield takeEvery(types.SET_PRICES_TARIFF_TABLE_PAGE_SIZE, callSetToLocalDataWorker);
}

function* watchPostTariff() {
  yield takeEvery(asyncTypes.POST_PRICES_TARIFF_ASYNC, callPostTariffWorker);
}

function* watchPutTariff() {
  yield takeEvery(asyncTypes.PUT_PRICES_TARIFF_ASYNC, callPutTariffWorker);
}

function* watchDeleteTariff() {
  yield takeEvery(asyncTypes.DELETE_PRICES_TARIFF_ASYNC, callDeleteTariffWorker);
}

function* watchTariffID() {
  yield takeEvery(asyncTypes.GET_PRICES_TARIFF_ID, callGetTariffIDWorker);
}

function* watchPostTariffTable() {
  yield takeEvery(asyncTypes.POST_PRICES_TARIFF_TABLE_ASYNC, callPostTariffTableWorker);
}

function* watchDeleteTariffTable() {
  yield takeEvery(asyncTypes.DELETE_PRICES_TARIFF_TABLE, callDeleteTariffTableWorker);
}

function* watchGetTariffTableById() {
  yield takeEvery(
    asyncTypes.GET_PRICES_TARIFF_TABLE_BY_ID_ASYNC,
    callGetTariffTableByIdWorker,
  );
}
function* watchPutTariffTableById() {
  yield takeEvery(
    asyncTypes.PUT_PRICES_TARIFF_TABLE_BY_ID_ASYNC,
    callPutTariffTableByIdWorker,
  );
}

export function* watchersTariffTable() {
  yield all([
    call(watchGetTariffTable),
    call(watchSetCurrentPage),
    call(watchSetPageSize),
    call(watchGetInitialState),
    call(watchDeleteTariffTable),
    call(watchPostTariffTable),
    call(watchGetTariffTableById),
    call(watchPutTariffTableById),
    call(watchPostTariff),
    call(watchDeleteTariff),
    call(watchTariffID),
    call(watchPutTariff),
    call(watchSetFilters),
    call(watchSetSorting),
  ]);
}
