// core
import React, { useMemo } from 'react';
import { Field } from 'redux-form/lib/immutable';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

// parts
import AutocompleteField from '../AutocompleteField';

// config
import selectors from '../../../engine/config/selectors';

// helpers
import { getValueFormField } from '../../_helpers/getValueFormField';
import { searchParams } from './helper/searchParams';

// hooks
import { useMain } from './_hook/useMain';

function FieldDocumentTemplate(props) {
  const {
    data, formFieldInput, autocompleteOptions,
    name, propsField, pendingAutocompleteOptions,
    pendingDefaultList, getAutocompleteLists,
    searchParamName, getAsyncData, formField,
  } = props;
  const { t } = useTranslation();
  const autocompleteOptionsItems = useMemo(() => autocompleteOptions.get(searchParamName), [
    autocompleteOptions, searchParamName,
  ]);
  const {
    startLoading, handleOnFocus, dataItems, checkOptions,
  } = useMain({
    formField,
    getAutocompleteLists,
    formFieldInput,
  });

  const handleGetAsyncData = (value, fieldName) => {
    getAsyncData(value, fieldName, {
      [searchParamName]: value,
    });
  };

  return (
    <Field
      fullWidth
      changeToStore
      name={name}
      handleOnFocus={handleOnFocus(name, data)}
      loading={startLoading ? (pendingDefaultList || pendingAutocompleteOptions) : false}
      getAsyncData={handleGetAsyncData}
      label={t('Document template')}
      margin="normal"
      options={checkOptions ? dataItems(data) : autocompleteOptionsItems}
      component={AutocompleteField}
      {...propsField}
    />
  );
}

FieldDocumentTemplate.displayName = 'FieldDocumentTemplate';

FieldDocumentTemplate.propTypes = {
  autocompleteOptions: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.bool,
  ]).isRequired,
  data: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  getAutocompleteLists: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  pendingAutocompleteOptions: PropTypes.bool.isRequired,
  pendingDefaultList: PropTypes.bool.isRequired,
  propsField: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  formField: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array,
  ]),
  formFieldInput: PropTypes.string,
  searchParamName: PropTypes.string,
  getAsyncData: PropTypes.func,
};

FieldDocumentTemplate.defaultProps = {
  formField: {},
  propsField: {},
  formFieldInput: '',
  getAsyncData: () => {},
  searchParamName: searchParams.title,
};

function mapStateToProps(state, props) {
  const { formName, name } = props;
  const formField = getValueFormField(state, formName, name);
  const formFieldInput = getValueFormField(state, formName, `${name}Input`);

  return {
    pendingAutocompleteOptions: selectors.documentTemplates.pendingAutocompleteOptions(state),
    pendingDefaultList: selectors.documentTemplates.pending(state),
    autocompleteOptions: selectors.documentTemplates.autocompleteOptions(state),
    data: selectors.documentTemplates.documentTemplatesData(state),
    formField: formField(),
    formFieldInput: formFieldInput(),
  };
}

export default compose(
  connect(mapStateToProps, null),
)(FieldDocumentTemplate);
