// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import types from './types';

const actions = Object.freeze({
  setPaymentsData(paymentsData) {
    return actionCreator(types.SET_PAYMENTS_DATA, paymentsData);
  },
  setCurrentPage(currentPage) {
    return actionCreator(types.SET_PAYMENTS_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return actionCreator(types.SET_PAYMENTS_FILTERS, filters);
  },
  setSorting(sorting) {
    return actionCreator(types.SET_PAYMENTS_SORTING, sorting);
  },
  setPageSize(pageSize) {
    return actionCreator(types.SET_PAYMENTS_PAGE_SIZE, pageSize);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_PAYMENTS_INITIAL_STATE, initialState);
  },
  setReload(reload) {
    return actionCreator(types.SET_PAYMENTS_RELOAD, reload);
  },
  setDeletePayment(data) {
    return actionCreator(types.SET_DELETE_PAYMENT, data);
  },
  setPaymentsApply(data) {
    return actionCreator(types.SET_PAYMENTS_APPLY, data);
  },
  setPaymentById(data) {
    return actionCreator(types.SET_PAYMENT_BY_ID, data);
  },
  setPaymentDataById(data) {
    return actionCreator(types.SET_PAYMENT_DATA_BY_ID, data);
  },
  setCreatePayment(data) {
    return actionCreator(types.SET_CREATE_PAYMENT, data);
  },
  setColumnWidths(data) {
    return actionCreator(types.SET_PAYMENTS_COLUMN_WIDTHS, data);
  },
  setColumnOrder(data) {
    return actionCreator(types.SET_PAYMENTS_COLUMN_ORDER, data);
  },
  setHiddenColumnNames(data) {
    return actionCreator(types.SET_PAYMENTS_HIDDEN_COLUMN_NAMES, data);
  },
});

export default actions;
