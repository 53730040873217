// core
import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

// ui
import {
  Button, Divider as MuiDivider,
  Typography, ButtonGroup, Box,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import {
  Add as AddIcon,
} from '@material-ui/icons';

// parts
import CompaniesTable from './CompaniesTable';

// config
import accessList from '../../../engine/config/accessList';

// routes
import { pageLinks } from '../../../routes';

// hooks
import { useAccessList } from '../../../ui/_hooks/useAccessList';

// hooks
import { useCompanies } from './_hooks/useCompanies';

// styles
const Divider = styled(MuiDivider)(spacing);

function Companies() {
  useCompanies();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const accessPost = useAccessList(accessList.company_post);

  const handleGoToCreateOrderPage = () => {
    dispatch(push(pageLinks.company.companies.new));
  };

  return (
    <>
      <Helmet title={t('Entities')} />
      <Typography variant="h3" gutterBottom display="inline">
        {t('Entities')}
      </Typography>
      <Divider my={6} />

      {accessPost && (
        <Box mb={2}>
          <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
            <Button variant="contained" color="primary" fullWidth onClick={handleGoToCreateOrderPage}>
              {t('ADD')}
              <AddIcon />
            </Button>
          </ButtonGroup>
        </Box>
      )}

      <CompaniesTable />
    </>
  );
}

export default Companies;
