// core
import React, { useEffect } from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import { push } from 'connected-react-router';

// ui
import {
  Button, Divider as MuiDivider,
  Typography, ButtonGroup, Box,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import {
  Add as AddIcon,
} from '@material-ui/icons';

// actions
import employeesActions from '../../../engine/core/company/employees/action';

// parts
import InviteEmployeeModel from './InviteEmployeeModal';
import EmployeesTable from './EmployeesTable';

// config
import accessList from '../../../engine/config/accessList';

// routes
import { pageLinks } from '../../../routes';

// hooks
import { useAccessList } from '../../../ui/_hooks/useAccessList';
import { useEmployees } from './_hooks/useEmployees';

// selectors
import selectors from '../../../engine/config/selectors';

// styles
const Divider = styled(MuiDivider)(spacing);

function Employees(props) {
  const {
    isModalOpenInviteEmployee,
    openFieldsCreateKey,
  } = props;
  useEmployees();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hasEmployeeInvite = useAccessList(accessList.employees_invite_post);
  const hasEmployeeCreate = useAccessList(accessList.employees_post);
  const handleGoToCreateEmployeePage = () => {
    dispatch(push(pageLinks.company.createEmployee));
  };

  const handleOpenCLoseModelInviteEmployee = () => {
    dispatch(employeesActions.setEmployees({
      isModalOpen: !isModalOpenInviteEmployee,
    }));
  };

  useEffect(() => {
    if (openFieldsCreateKey) {
      dispatch(employeesActions.setKeysData({
        openFieldsCreateKey: false,
      }));
    }
  }, [
    openFieldsCreateKey,
    dispatch,
  ]);

  return (
    <>
      <Helmet title={t('Employees')} />
      <Typography variant="h3" gutterBottom display="inline">
        {t('Employees')}
      </Typography>
      <Divider my={6} />

      {(hasEmployeeCreate || hasEmployeeInvite) && (
        <Box mb={2}>
          <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
            {hasEmployeeCreate && (
              <Button variant="contained" color="primary" fullWidth onClick={handleGoToCreateEmployeePage}>
                {t('ADD')}
                <AddIcon />
              </Button>
            )}
            {hasEmployeeInvite && (
              <Button variant="contained" color="primary" fullWidth onClick={handleOpenCLoseModelInviteEmployee}>
                {t('INVITE AN EMPLOYEE')}
              </Button>
            )}
          </ButtonGroup>
        </Box>
      )}

      <EmployeesTable />

      {isModalOpenInviteEmployee && <InviteEmployeeModel />}
    </>
  );
}

Employees.displayName = 'Employees';

Employees.propTypes = {
  isModalOpenInviteEmployee: PropTypes.bool.isRequired,
  openFieldsCreateKey: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    isModalOpenInviteEmployee: selectors.employees.isModalOpenInviteEmployee(state),
    openFieldsCreateKey: selectors.employees.openFieldsCreateKey(state),
  };
}

export default compose(
  connect(mapStateToProps, null),
)(Employees);
