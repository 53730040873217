const asyncTypes = Object.freeze({
  GET_MADRID_CASE_ASYNC: 'GET_MADRID_CASE_ASYNC',
  GET_MADRID_CASE_BY_ID_ASYNC: 'GET_MADRID_CASE_BY_ID_ASYNC',
  GET_MADRID_CASE_INITIAL_STATE_ASYNC: 'GET_MADRID_CASE_INITIAL_STATE_ASYNC',
  POST_MADRID_CASE_ASYNC: 'POST_MADRID_CASE_ASYNC',
  PUT_MADRID_CASE_BY_ID_ASYNC: 'PUT_MADRID_CASE_BY_ID_ASYNC',
  DELETE_MADRID_CASE_ASYNC: 'DELETE_MADRID_CASE_ASYNC',
});

export default asyncTypes;
