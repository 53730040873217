const asyncTypes = Object.freeze({
  GET_CHARGES_ASYNC: 'GET_CHARGES_ASYNC',
  GET_CHARGES_INITIAL_STATE_ASYNC: 'GET_CHARGES_INITIAL_STATE_ASYNC',
  GET_CHARGE_BY_ID_ASYNC: 'GET_CHARGE_BY_ID_ASYNC',
  POST_CHARGE_ASYNC: 'POST_CHARGE_ASYNC',
  PUT_CHARGE_BY_ID_ASYNC: 'PUT_CHARGE_BY_ID_ASYNC',
  DELETE_CHARGE_ASYNC: 'DELETE_CHARGE_ASYNC',
  SET_SECTIONS_CHARGES_ASYNC: 'SET_SECTIONS_CHARGES_ASYNC',
  GET_CHARGES_AUTOCOMPLETE_ASYNC: 'GET_CHARGES_AUTOCOMPLETE_ASYNC',
  POST_CHARGES_PAYMENT_DETAILS: 'POST_CHARGES_PAYMENT_DETAILS',
});

export default asyncTypes;
