// Core
import { List, Map } from 'immutable';

// Instruments
import { isNil } from 'lodash';
import types from './types';
import { pageSizes } from '../../../config/globalConfig';
import globalStateKeys from '../../../config/globalStateKeys';

export const initialState = Map({
  data: Map({
    items: List(),
    currentPage: 0,
    filters: List(),
    pending: false,
    sorting: List([{ columnName: 'createdAt', direction: 'desc' }]),
    totalCount: 0,
    pageSize: pageSizes[0],
    reload: false,
    columnWidths: List(),
    columnOrder: List(),
    hiddenColumnNames: List(),
  }),
  autocompleteOptions: Map({
    items: Map(),
    pending: false,
  }),
  itemByHashId: Map({
    data: Map(),
    pending: false,
    pendingPut: false,
    pendingPost: false,
  }),
  searchTerms: Map({
    data: Map(),
    pending: false,
  }),
  delete: Map({
    pendingDelete: false,
    id: '',
  }),
  pdf: Map({
    links: Map(),
    pending: false,
  }),
  pending: false,
  isModalCreateOpen: false,
  isModalAllTermsOpen: false,
  isModalYourListTermsOpen: false,
});

export const stateKeys = Object.freeze({
  controller: 'tradeMarksRebounds',
  isModalCreateOpen: 'isModalCreateOpen',
  isModalAllTermsOpen: 'isModalAllTermsOpen',
  isModalYourListTermsOpen: 'isModalYourListTermsOpen',
  searchTerms: 'searchTerms',
  pdf: 'pdf',
  links: 'links',
});

export const tradeMarksReboundsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_REBOUNDS_DATA: {
      const { items, totalCount, pending } = payload;
      if (pending) {
        return state.setIn([globalStateKeys.data, globalStateKeys.pending], pending);
      }
      return state
        .setIn([globalStateKeys.data, globalStateKeys.pending], pending)
        .setIn([globalStateKeys.data, globalStateKeys.items], List(items))
        .setIn([globalStateKeys.data, globalStateKeys.totalCount], totalCount);
    }
    case types.SET_REBOUNDS_RELOAD: {
      return state.setIn([globalStateKeys.data, globalStateKeys.reload], payload);
    }
    case types.SET_REBOUNDS_CURRENT_PAGE: {
      return state.setIn([globalStateKeys.data, globalStateKeys.currentPage], payload);
    }
    case types.SET_REBOUNDS_FILTERS: {
      const filters = payload.filter((item) => !isNil(item.value));
      return state.setIn([globalStateKeys.data, globalStateKeys.filters], List(filters));
    }
    case types.SET_REBOUNDS_SORTING: {
      return state.setIn([globalStateKeys.data, globalStateKeys.sorting], List(payload));
    }
    case types.SET_REBOUNDS_COLUMN_WIDTHS: {
      return state.setIn([globalStateKeys.data, globalStateKeys.columnWidths], List(payload));
    }
    case types.SET_REBOUNDS_COLUMN_ORDER: {
      return state.setIn([globalStateKeys.data, globalStateKeys.columnOrder], List(payload));
    }
    case types.SET_REBOUNDS_HIDDEN_COLUMN_NAMES: {
      return state.setIn([globalStateKeys.data, globalStateKeys.hiddenColumnNames], List(payload));
    }
    case types.SET_REBOUNDS_PAGE_SIZE: {
      return state.setIn([globalStateKeys.data, globalStateKeys.pageSize], payload);
    }
    case types.SET_REBOUNDS_AUTOCOMPLETE_OPTIONS: {
      return state.mergeIn([
        globalStateKeys.autocompleteOptions,
        globalStateKeys.items,
      ], Map(payload));
    }
    case types.SET_PENDING_REBOUNDS_AUTOCOMPLETE_OPTIONS: {
      return state.setIn([
        globalStateKeys.autocompleteOptions,
        globalStateKeys.pending,
      ], payload);
    }
    case types.SET_REBOUNDS_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, sorting = [],
        columnWidths, columnOrder, hiddenColumnNames,
      } = payload;
      const reload = true;
      const currentSorting = sorting.length
        ? sorting
        : initialState.getIn([globalStateKeys.data, globalStateKeys.sorting]);
      return state
        .setIn([globalStateKeys.data, globalStateKeys.currentPage], currentPage)
        .setIn([globalStateKeys.data, globalStateKeys.filters], List(filters))
        .setIn([globalStateKeys.data, globalStateKeys.pageSize], pageSize)
        .setIn([globalStateKeys.data, globalStateKeys.totalCount], totalCount)
        .setIn([globalStateKeys.data, globalStateKeys.reload], reload)
        .setIn([globalStateKeys.data, globalStateKeys.columnWidths], List(columnWidths))
        .setIn([globalStateKeys.data, globalStateKeys.columnOrder], List(columnOrder))
        .setIn([globalStateKeys.data, globalStateKeys.hiddenColumnNames], List(hiddenColumnNames))
        .setIn([globalStateKeys.data, globalStateKeys.sorting], List(currentSorting));
    }
    case types.SET_DELETE_REBOUND: {
      return state.mergeIn([globalStateKeys.delete], payload);
    }
    case types.SET_REBOUND_BY_ID: {
      return state.setIn([globalStateKeys.itemByHashId, globalStateKeys.data], Map(payload));
    }
    case types.SET_REBOUND_PDF_BY_ID: {
      return state.setIn([stateKeys.pdf, stateKeys.links], Map(payload));
    }
    case types.SET_PENDING_REBOUND_PDF: {
      return state.setIn([stateKeys.pdf, globalStateKeys.pending], payload);
    }
    case types.SET_SEARCH_REBOUND: {
      return state.setIn([stateKeys.searchTerms, globalStateKeys.data], Map(payload));
    }
    case types.SET_PENDING_SEARCH_REBOUND: {
      return state.setIn([stateKeys.searchTerms, globalStateKeys.pending], payload);
    }
    case types.SET_PENDING_REBOUND_BY_ID: {
      return state.mergeIn([globalStateKeys.itemByHashId], payload);
    }
    case types.SET_REBOUND_MODAL_CREATE_IS_OPEN: {
      return state.setIn([stateKeys.isModalCreateOpen], payload);
    }
    case types.SET_REBOUND_MODAL_ALL_TERMS_IS_OPEN: {
      return state.setIn([stateKeys.isModalAllTermsOpen], payload);
    }
    case types.SET_REBOUND_MODAL_YOUR_LIST_TERMS_IS_OPEN: {
      return state.setIn([stateKeys.isModalYourListTermsOpen], payload);
    }
    default:
      return state;
  }
};
