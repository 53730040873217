// core
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { map } from 'lodash';

// lodash
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import filter from 'lodash/filter';

// UI
import {
  Card as MuiCard, CardContent as MuiCardContent,
  Grid, Menu, MenuItem, Box,
  Tooltip as MuiTooltip, Typography as MuiTypography,
  ListItemIcon as MuiListItemIcon, ListItemText,
} from '@material-ui/core';
import {
  Delete as DeleteIcon,
  Edit as EditIcon, Done as DoneIcon,
  ArrowForward as ArrowForwardIcon,
} from '@material-ui/icons';
import { spacing } from '@material-ui/system';
import { withStyles } from '@material-ui/core/styles';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';

// config
import selectors from '../../../../engine/config/selectors';
import { listEntityName } from '../../../../engine/config/listTablesName';
import accessList from '../../../../engine/config/accessList';
import globalStatusesAction from '../../../../engine/config/globalStatusesAction';

// actions
import pendingActions from '../../../../engine/core/pendingActions/action';
import pendingActionsAsync from '../../../../engine/core/pendingActions/saga/asyncAction';
import fieldsSettingsActionAsync from '../../../../engine/core/fieldsSettings/saga/asyncAction';
import fieldsSettingsActions from '../../../../engine/core/fieldsSettings/action';

// parts
// import Loader from '../../../../components/Loader';
import StaticTableExtraFilter from '../../../../ui/Table/components/TableExtraFilter/StaticTableExtraFilter';

// styles
import calendarStyle from './Calendar.style';

// hooks
import { useActionsCalendar } from './_hooks/useActionsCalendar';
import { useAccessList } from '../../../../ui/_hooks/useAccessList';
import { useEventsAutocompleteAsync } from '../../../../ui/_hooks/useEventsAutocompleteAsync';

// helpers
import { formName as formNameExtraFilter } from '../../../../ui/Table/components/TableExtraFilter/form';
import { pageLinks } from '../../../../routes';

const entityName = listEntityName.pendingActions;

const FullCalendarWrapper = styled.div`
  ${calendarStyle}
`;
const Card = styled(MuiCard)(spacing);
const Typography = styled(MuiTypography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const CardContent = styled(MuiCardContent)(spacing);
const ListItemIcon = styled(MuiListItemIcon)`
  min-width: 30px;
`;
const Tooltip = withStyles(() => ({
  tooltip: {
    whiteSpace: 'pre-line',
    maxWidth: 'none',
  },
}))(MuiTooltip);

function Calendar(props) {
  const {
    getPendingActions, userInfo, filters,
    // filters,
    blockRetrievalRequest, actionTypesData,
    employeesData, employeesAutocompleteOptions, usersInput,
  } = props;
  useActionsCalendar(blockRetrievalRequest);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorId, setAnchorId] = useState('');
  const [count, setCount] = useState(0);
  const { language } = userInfo;
  const locale = language === 'ua' ? 'uk' : language;
  // Advanced Search
  const {
    handleGetAsyncData, getAutocompleteLists,
  } = useEventsAutocompleteAsync();

  const accessEdit = useAccessList(accessList.pendingActions_put);
  const accessTMEdit = useAccessList(accessList.trademarks_put);
  const accessDelete = useAccessList(accessList.pendingActions_delete);
  const accessEntityFields = useAccessList(accessList.entity_fields_list_get);

  useEffect(() => {
    if (accessEntityFields) dispatch(fieldsSettingsActionAsync.getFieldsEntityAsync(entityName));
  }, [dispatch, accessEntityFields]);

  useEffect(() => function cleanup() {
    dispatch(fieldsSettingsActions.setFieldsEntity({}));
  }, [dispatch]);

  const handleChangeCalendar = (dateInfo) => {
    setCount((value) => value + 1);
    if (count > 0) {
      dispatch(pendingActions.setFilters([
        ...filter(filters, (elem) => elem.columnName !== 'finishAt'),
        {
          columnName: 'finishAt',
          value: {
            endDate: dateInfo.end,
            startDate: dateInfo.start,
            key: 'selection',
          },
        },
      ]));
    }
  };

  const renderEventContent = (eventInfo) => {
    const { extendedProps, id } = eventInfo.event;
    const handleToggle = (event) => {
      setAnchorEl(event.currentTarget);
      setAnchorId(id);
    };
    const handleClose = () => {
      setAnchorEl(null);
      setAnchorId('');
    };
    const handleToCase = () => {
      if (extendedProps.inventions?.id) {
        dispatch(push(pageLinks.inventions.edit(extendedProps.inventions.id)));
      }
      if (extendedProps.industrial?.id) {
        dispatch(push(pageLinks.industrialDesigns.edit(extendedProps.industrial.id)));
      }
      if (extendedProps.trademarks?.id) {
        dispatch(push(pageLinks.tradeMarksRoutes.proposal.edit(extendedProps.trademarks.id)));
      }
      if (extendedProps.utility?.id) {
        dispatch(push(pageLinks.utilityModels.edit(extendedProps.utility.id)));
      }
      handleClose();
    };

    const message = () => {
      const entity = extendedProps.trademarks || extendedProps.industrial
        || extendedProps.inventions || extendedProps.utility;
      return (
        `${!isEmpty(eventInfo.event.title)
          ? `${eventInfo.event.title} \n`
          : ''}${!isEmpty(entity?.caseNumber)
          ? `${t('Case')}: ${entity?.caseNumber} \n`
          : ''}${!isEmpty(entity?.applicationNumber)
          ? `${t('Application')}: ${entity?.applicationNumber} \n`
          : ''}${!isEmpty(entity?.registrationNumber)
          ? `${t('Registration')}: ${entity?.registrationNumber} \n`
          : ''}${!isEmpty(entity?.internalTitle) || !isEmpty(entity?.title)
          ? `${t('Title')}: ${entity?.internalTitle || entity?.title} \n`
          : ''}`
      );
    };
    return (
      <>
        <Tooltip title={message()} placement="top-start" arrow>
          <Typography
            aria-label="more"
            aria-controls="menu"
            aria-haspopup="true"
            variant="body1"
            onClick={handleToggle}
          >
            {extendedProps.label || eventInfo.event.title}
          </Typography>
        </Tooltip>
        {anchorId === id && (
          <Menu
            id="calendar-item-menu"
            anchorEl={anchorEl?.closest('td')}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {accessEdit && (
              <MenuItem
                onClick={() => {
                  dispatch(pendingActions.setIsModalOpen(true));
                  dispatch(pendingActionsAsync.getPendingActionByIdAsync(id));
                  handleClose();
                }}
              >
                <ListItemIcon><EditIcon fontSize="small" /></ListItemIcon>
                <ListItemText primary={t('Change')} />
              </MenuItem>
            )}
            {accessTMEdit && (
              <MenuItem
                onClick={handleToCase}
              >
                <ListItemIcon><ArrowForwardIcon fontSize="small" /></ListItemIcon>
                <ListItemText primary={t('Get to case')} />
              </MenuItem>
            )}
            {accessEdit && (
              <MenuItem
                onClick={() => {
                  dispatch(pendingActionsAsync.patchPendingActionByIdAsync({
                    id,
                    status: 2,
                  }));
                  handleClose();
                }}
              >
                <ListItemIcon><DoneIcon fontSize="small" /></ListItemIcon>
                <ListItemText primary={t('the Done')} />
              </MenuItem>
            )}
            {accessDelete && (
              <MenuItem
                onClick={() => {
                  dispatch(pendingActions.setDeletePendingAction({ id }));
                  dispatch(pendingActionsAsync.deletePendingActionAsync());
                  handleClose();
                }}
              >
                <ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon>
                <ListItemText primary={t('Delete')} />
              </MenuItem>
            )}
          </Menu>
        )}
      </>
    );
  };

  const extraFiltersFields = [
    {
      name: 'status',
      type: 'select',
      label: t('Status'),
      displayEmpty: true,
      items: globalStatusesAction,
    },
    {
      name: 'users',
      requestName: 'users',
      type: 'input',
      label: t('Person'),
      multiple: false,
      autocomplete: true,
      changeToStore: true,
      options: isEmpty(usersInput)
        ? employeesData.toJS().items
        : employeesAutocompleteOptions.get('name'),
    },
    {
      name: 'type',
      requestName: 'type',
      type: 'input',
      label: t('Type'),
      multiple: false,
      autocomplete: true,
      changeToStore: true,
      options: map(actionTypesData.toJS().items, (item) => ({ id: item.id, label: t(item.label) })),
    },
  ];
  // Filtering
  const setFilters = (getFilters) => dispatch(pendingActions.setFilters(getFilters));

  return (
    <>
      {blockRetrievalRequest && userInfo?.id && (
        <Box>
          <StaticTableExtraFilter
            openForm
            hiddenControls
            submitFieldChange
            fields={extraFiltersFields}
            filters={filters}
            filtersAction={setFilters}
            // Autocomplete
            handleGetAsyncData={handleGetAsyncData}
            handleOnFocus={getAutocompleteLists}
          />
        </Box>
      )}
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent p={6}>
              <FullCalendarWrapper>
                <FullCalendar
                  initialView="dayGridMonth"
                  events={getPendingActions.toJS()}
                  editable
                  locale={locale}
                  firstDay={1}
                  buttonText={{
                    dayGridDay: t('date'),
                    dayGridWeek: t('week'),
                    dayGridMonth: t('month'),
                  }}
                  headerToolbar={{
                    left: 'prev,next',
                    center: 'title',
                    right: 'dayGridDay,dayGridWeek,dayGridMonth',
                  }}
                  datesSet={handleChangeCalendar}
                  eventContent={renderEventContent}
                  plugins={[dayGridPlugin]}
                />
              </FullCalendarWrapper>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

Calendar.displayName = 'Calendar';

Calendar.propTypes = {
  getPendingActions: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  userInfo: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  blockRetrievalRequest: PropTypes.bool,
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  employeesData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  actionTypesData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  employeesAutocompleteOptions: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  usersInput: PropTypes.string,
};

Calendar.defaultProps = {
  blockRetrievalRequest: true,
  usersInput: '',
};

function mapStateToProps(state) {
  const getFormValues = selectors.form.getFormValues(state, formNameExtraFilter);

  return {
    getPendingActions: selectors.pendingActions.getPendingActions(state),
    userInfo: selectors.user.userInfo(state),
    filters: selectors.pendingActions.filters(state),

    employeesData: selectors.employeesTable.employeesData(state),
    actionTypesData: selectors.actionTypes.actionTypesData(state),
    employeesAutocompleteOptions: selectors.employees.autocompleteOptions(state),
    usersInput: getFormValues.toJS().usersIdInput,
    isModalOpenAction: selectors.pendingActions.isModalOpenAction(state),
  };
}

function areEqual(prevProps, nextProps) {
  return (prevProps.isModalOpenAction && nextProps.isModalOpenAction)
    || (isEqual(prevProps.blockRetrievalRequest, nextProps.blockRetrievalRequest)
    && isEqual(prevProps.employeesAutocompleteOptions, nextProps.employeesAutocompleteOptions)
    && isEqual(prevProps.employeesData, nextProps.employeesData)
    && isEqual(prevProps.actionTypesData, nextProps.actionTypesData)
    && isEqual(prevProps.filters, nextProps.filters)
    && isEqual(prevProps.getPendingActions, nextProps.getPendingActions)
    && isEqual(prevProps.usersInput, nextProps.usersInput)
    && isEqual(prevProps.userInfo, nextProps.userInfo));
}

export default compose(
  connect(mapStateToProps, null),
)(React.memo(Calendar, areEqual));
