// Core
import { call, put } from 'redux-saga/effects';

// config
import { api } from '../../../../config/api';

// actions
import action from '../../action';

export function* callGetNotificationByIdWorker({ payload: id }) {
  const {
    setNotificationById,
  } = action;
  yield put(setNotificationById({
    pending: true,
  }));

  const response = yield call(api.notifications.getNotificationById, id);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    yield put(setNotificationById({ data }));
  }

  yield put(setNotificationById({
    pending: false,
  }));
}
