// core
import React, { useEffect } from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';

// ui
import {
  Divider as MuiDivider,
  Typography,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';

// parts
import DepartmentDocumentsTable from './components/DepartmentDocumentsTable';

// actions
import actions from '../../engine/core/departmentDocuments/action';
import helpersActions from '../../engine/core/helpers/action';

// hooks
import { useDepartmentDocuments } from './_hooks/useDepartmentDocuments';

// styles
const Divider = styled(MuiDivider)(spacing);
function DepartmentDocuments() {
  useDepartmentDocuments();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => function cleanup() {
    dispatch(actions.setDepartmentDocumentsData({
      items: [],
      pending: false,
      totalCount: 0,
    }));
    dispatch(actions.setSelectedDocuments([]));
    dispatch(helpersActions.setAutocompleteGroupFilter({}));
  }, [dispatch]);

  return (
    <>
      <Helmet title={t('The department documents')} />
      <Typography variant="h3" gutterBottom display="inline">
        {t('The department documents')}
      </Typography>
      <Divider my={6} />

      <DepartmentDocumentsTable />
    </>
  );
}

DepartmentDocuments.displayName = 'DepartmentDocuments';

DepartmentDocuments.propTypes = {};

function mapStateToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, null),
)(DepartmentDocuments);
