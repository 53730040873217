// Core
import { call, put } from 'redux-saga/effects';

// config
import { api } from '../../../../../config/api';

// actions
import action from '../../action';

export function* callGetEmailTemplateByIdWorker({ payload: id }) {
  const {
    setEmailTemplateById, setEmailTemplateDataById,
  } = action;
  yield put(setEmailTemplateById({
    pending: true,
  }));

  const response = yield call(api.emailTemplate.getEmailTemplateById, id);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    yield put(setEmailTemplateDataById(data));
  }

  yield put(setEmailTemplateById({
    pending: false,
  }));
}
