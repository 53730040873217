// core
import React, {
  memo, useMemo,
} from 'react';
import { spacing } from '@material-ui/system';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import { Field, change } from 'redux-form/immutable';

// lodash
import {
  isEqual, map, find,
} from 'lodash';

// UI
import {
  CardContent, Grid as MuiGrid,
  Typography as MuiTypography, Card as MuiCard,
} from '@material-ui/core';

// parts
import AutocompleteField from '../../../../../ui/Form/AutocompleteField';
import RenderTextField from '../../../../../ui/Form/RenderTextField';
import Select from '../../../../../ui/Form/Select';
import ButtonSubmit from '../../../../../ui/Button/ButtonSubmit';

// helpers
import { countryOptions } from '../../../../../ui/_helpers/country';
import { getPostalAddress } from '../../../../../ui/_helpers/getPostalAddress';

// config
import selectors from '../../../../../engine/config/selectors';

// styles
const Card = styled(MuiCard)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);

function CardMailingAddress(props) {
  const {
    formName, formFields, pending, newApplicantsList,
    allCompanies, disabled,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const allMailingAddresses = useMemo(() => ([
    ...map([...allCompanies.toJS(), ...newApplicantsList.toJS()], (item, index) => ({
      ...item,
      value: index.toString(),
    })),
  ]), [allCompanies, newApplicantsList]);

  const setPostalAddressData = (event) => {
    if (!event.target.value) {
      dispatch(change(formName, formFields.dataPostalAddressFullName, null));
      dispatch(change(formName, formFields.dataPostalAddressCity, null));
      dispatch(change(formName, formFields.dataPostalAddressPhone, null));
      dispatch(change(formName, formFields.dataPostalAddressEmail, null));
      dispatch(change(formName, formFields.dataPostalAddressPostcode, null));
      dispatch(change(formName, formFields.dataPostalAddressAddress, null));
      dispatch(change(formName, formFields.dataPostalAddressCountry, null));
      return;
    }
    const findValue = allMailingAddresses?.[event.target.value];
    const {
      postalAddress, address, phone, email, name, addresses,
    } = findValue;
    const addressCompany = getPostalAddress(addresses);
    const currentAddress = postalAddress || address || addressCompany;
    dispatch(change(formName, formFields.dataPostalAddressFullName, postalAddress?.recipient
      || addressCompany?.recipient || name));
    dispatch(change(formName, formFields.dataPostalAddressCity, currentAddress?.city));
    dispatch(change(formName, formFields.dataPostalAddressPhone, phone?.[0]));
    dispatch(change(formName, formFields.dataPostalAddressEmail, email));
    dispatch(change(formName, formFields.dataPostalAddressPostcode, currentAddress?.postcode
      || currentAddress?.postalIndex));
    dispatch(change(formName, formFields.dataPostalAddressAddress, currentAddress?.address));
    dispatch(change(formName, formFields.dataPostalAddressCountry,
      find(countryOptions, { abbreviation: currentAddress?.country })));
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h2" gutterBottom>
          {t('Mailing address')}
        </Typography>

        <Grid container spacing={4}>
          <Grid container item xs={12}>
            <Grid item xs={12} md={4}>
              <Field
                name="mailingAddress"
                id="mailingAddress"
                label={t('Status')}
                labelId="mailingAddress"
                my={1}
                items={allMailingAddresses}
                displayEmpty
                textEmpty="Select"
                fullWidth
                component={Select}
                onChange={setPostalAddressData}
                disabled={disabled}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name={formFields.dataPostalAddressFullName}
              id={formFields.dataPostalAddressFullName}
              label={t('Recipient')}
              margin="normal"
              variant="standard"
              my={1}
              fullWidth
              type="text"
              component={RenderTextField}
              disabled={disabled}
            />
            <Field
              multiple={false}
              name={formFields.dataPostalAddressCountry}
              id={formFields.dataPostalAddressCountry}
              component={AutocompleteField}
              type="text"
              label={t('Country')}
              margin="normal"
              fullWidth
              options={countryOptions}
              disabled={disabled}
            />

            <Field
              name={formFields.dataPostalAddressCity}
              id={formFields.dataPostalAddressCity}
              label={t('City')}
              margin="normal"
              variant="standard"
              my={1}
              fullWidth
              type="text"
              component={RenderTextField}
              disabled={disabled}
            />
            <Field
              name={formFields.dataPostalAddressPostcode}
              id={formFields.dataPostalAddressPostcode}
              label={t('Postal code')}
              margin="normal"
              variant="standard"
              my={1}
              fullWidth
              type="text"
              component={RenderTextField}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name={formFields.dataPostalAddressAddress}
              id={formFields.dataPostalAddressAddress}
              label={t('Address')}
              margin="normal"
              variant="standard"
              my={1}
              fullWidth
              type="text"
              component={RenderTextField}
              disabled={disabled}
            />
            <Field
              name={formFields.dataPostalAddressPhone}
              id={formFields.dataPostalAddressPhone}
              label={t('Phone')}
              margin="normal"
              variant="standard"
              my={1}
              fullWidth
              type="text"
              component={RenderTextField}
              disabled={disabled}
            />
            <Field
              name={formFields.dataPostalAddressEmail}
              id={formFields.dataPostalAddressEmail}
              label={t('Email')}
              margin="normal"
              variant="standard"
              my={1}
              fullWidth
              type="text"
              component={RenderTextField}
              disabled={disabled}
            />

          </Grid>
          {!disabled && (
            <Grid item xs={12}>
              <ButtonSubmit pending={pending} />
            </Grid>
          )}

        </Grid>
      </CardContent>
    </Card>
  );
}
CardMailingAddress.propTypes = {
  formName: PropTypes.string.isRequired,
  formFields: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pending: PropTypes.bool.isRequired,
  allCompanies: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  newApplicantsList: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  disabled: PropTypes.bool,
};

CardMailingAddress.defaultProps = {
  disabled: false,
};

function mapStateToProps(state) {
  return {
    // company
    allCompanies: selectors.companiesTable.allCompanies(state),
    newApplicantsList: selectors.applicants.getApplicantNewList(state),
  };
}

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.formFields, nextProps.formFields)
  && isEqual(prevProps.formName, nextProps.formName)
  && isEqual(prevProps.allCompanies, nextProps.allCompanies)
  && isEqual(prevProps.newApplicantsList, nextProps.newApplicantsList)
  && isEqual(prevProps.disabled, nextProps.disabled)
  && isEqual(prevProps.pending, nextProps.pending);
}

export default compose(
  connect(mapStateToProps, null),
)(memo(CardMailingAddress, areEqual));
