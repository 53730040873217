// Core
import { apply, put } from 'redux-saga/effects';

// lodash
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

// Engine
import { api } from '../../../../config/api';
import localData from '../../../../config/localData';
import userActions from '../../action';
import i18n, { locale } from '../../../../init/i18n';

export function* callGetUserInfoWorker() {
  yield put(userActions.setUserPending(true));
  const response = yield apply(api, api.user.getUserInfo);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    const { frontendSettings } = data;

    if (!isEmpty(frontendSettings)) {
      yield apply(localData, localData.setItems, [frontendSettings]);
    }
    const userLocale = get(data, 'language');

    if (userLocale && userLocale !== i18n.language) {
      const currentLocale = locale[userLocale] || userLocale;
      yield apply(i18n, i18n.changeLanguage, [currentLocale]);
    }
    yield put(userActions.setUserInfo(data));
  }
  yield put(userActions.setUserPending(false));
}
