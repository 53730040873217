// core
import React, {
  useState, useEffect, memo, useMemo,
} from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import { push } from 'connected-react-router';

// lodash
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import isEqual from 'lodash/isEqual';
import filter from 'lodash/filter';
import find from 'lodash/find';
import map from 'lodash/map';
import includes from 'lodash/includes';

// ui
import {
  Button, CardContent, Grid, Box,
  Typography, Card as MuiCard, Menu, Link, MenuItem, Tabs, Tab,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import { Add as AddIcon } from '@material-ui/icons';

// actions
import helpersActions from '../../../../engine/core/helpers/action';
import helpersActionsAsync from '../../../../engine/core/helpers/saga/asyncAction';
import chargesActions from '../../../../engine/core/charges/action';
import chargesActionsAsync from '../../../../engine/core/charges/saga/asyncAction';
import ordersActions from '../../../../engine/core/company/orders/action';
import ordersActionsAsync from '../../../../engine/core/company/orders/saga/asyncAction';

// parts
import ChargesTabs from './components/ChargesTabs';
import OrdersTable from './components/OrdersTable';
import AddChargesModal from '../../../../components/AddChargesModal/AddChargesModal';

// config
import selectors from '../../../../engine/config/selectors';
import globalStateKeys from '../../../../engine/config/globalStateKeys';

// routes
import { pageLinks } from '../../../../routes';

// reducers
import { initialState } from '../../../../engine/core/charges/reducer';
import TableModal from '../../../../ui/Table/components/TableModalChange/TableModal';
import { listTablesName } from '../../../../engine/config/listTablesName';
import accessList from '../../../../engine/config/accessList';
import { useAccessList } from '../../../../ui/_hooks/useAccessList';

// styles
const Card = styled(MuiCard)(spacing);
const LinkChosen = styled(Link)`
  border-bottom: 1px dashed;
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
`;

function ProposalPayments(props) {
  const {
    match, isModalOpenAddCharges,
    pendingPostCharge, chargesSelections,
    filtersCharges, filtersOrders, chargesTabs,
    defaultFilters, entityName, clients,
  } = props;
  const { params } = match;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [valueTab, setValueTab] = useState(0);
  const [simpleMenu, setSimpleMenu] = useState(null);
  const getChargesSelections = chargesSelections.toJS();
  const accessChargesPost = useAccessList(accessList.charges_post);
  const accessOrdersPost = useAccessList(accessList.orders_create);

  // Модалка для изменения
  const [isModalOpen, handleModalClose] = useState(false);

  const toggleMenu = (event) => {
    setSimpleMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setSimpleMenu(null);
  };

  const fieldsForModal = [];

  const handleSendAddChargesModal = (json) => {
    if (!isEmpty(json) && !isEmpty(params)) {
      dispatch(chargesActionsAsync.postChargeAsync({
        ...json,
        [entityName]: Number(params.hashId),
        ...clients,
      }));
    }
  };

  const handleGoToOrdersByEntityId = () => {
    dispatch(helpersActions.setLastObject({ entityId: params.hashId, entityName }));
    dispatch(push(pageLinks.company.orders.new));
  };

  const handleCreateOrder = () => {
    const filterCharges = filter(getChargesSelections.fullSectionsData,
      (item) => includes(getChargesSelections.collectedSections, item.id) && item.includeToBill);
    const idsChargesSelections = map(filterCharges, 'id');
    dispatch(ordersActionsAsync.postOrderAsync({
      charges: idsChargesSelections,
      stay: true,
      reload: true,
    }));
    closeMenu();
  };

  const handleDeleteCharges = () => {
    const filterId = filter(getChargesSelections.collectedSections, (item) => {
      const foundItem = find(valueTab === 3
        ? chargesTabs.toJS().work
        : chargesTabs.toJS().all,
      { id: item });
      return foundItem && !foundItem.orders;
    });
    dispatch(helpersActionsAsync.deleteMultipleAsync({
      body: {
        Charges: filterId,
      },
      callBack: () => {
        const dataInitialState = initialState.get(globalStateKeys.selections);
        dispatch(chargesActions.setSelections(dataInitialState.toJS()));
        dispatch(chargesActions.setReload(true));
      },
    }));
    closeMenu();
  };

  useEffect(() => function cleanup() {
    const dataInitialState = initialState.get(globalStateKeys.selections);
    dispatch(chargesActions.setSelections(dataInitialState.toJS()));
  }, [dispatch]);

  const handlePayed = () => {
    forEach(getChargesSelections.fullSectionsData, (item) => {
      if (item.sum > item.sumPayed) {
        dispatch(helpersActionsAsync.putMultipleAsync({
          body: {
            entityName: 'Charges',
            entityIds: [item.id],
            fields: {
              sumPayed: item.sum,
            },
          },
          callBack: () => {
            dispatch(chargesActions.setReload(true));
          },
        }));
      }
    });
    closeMenu();
  };

  const handleAddCosts = () => {
    dispatch(helpersActions.setModal({
      isModalOpenAddCharges: true,
    }));
  };

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);

    switch (newValue) {
      case 1: {
        dispatch(chargesActions.setFilters([
          ...defaultFilters,
          { columnName: 'type', value: newValue },
        ]));
        break;
      }
      case 2: {
        dispatch(chargesActions.setFilters([
          ...defaultFilters,
          { columnName: 'type', value: newValue },
        ]));
        break;
      }
      case 3: {
        dispatch(chargesActions.setFilters(defaultFilters));
        break;
      }
      default: {
        dispatch(chargesActions.setFilters(defaultFilters));
        break;
      }
    }
  };

  useEffect(() => {
    if (!isEmpty(params)) {
      dispatch(chargesActions.setFilters(defaultFilters));
      dispatch(ordersActions.setFilters(defaultFilters));
    }
  }, [
    dispatch, params, defaultFilters,
  ]);

  useEffect(() => () => {
    dispatch(chargesActions.setFilters([]));
    dispatch(ordersActions.setFilters([]));
  }, [dispatch]);

  const getObjectType = useMemo(() => {
    switch (entityName) {
      case 'trademarks': {
        return '1';
      }
      case 'industrial': {
        return '2';
      }
      case 'inventions':
      case 'utility': {
        return '3';
      }
      default:
        return '';
    }
  }, [entityName]);

  return (
    <>
      <Card mb={6}>
        <CardContent>
          <Typography variant="h6" paragraph>
            {t('Costs')}
          </Typography>
          {accessChargesPost && (
            <Box mb={3}>
              <Button
                color="primary"
                variant="outlined"
                onClick={handleAddCosts}
              >
                {t('ADD')}
                <AddIcon />
              </Button>
            </Box>
          )}

          <Tabs
            mt={2}
            value={valueTab}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label={t('All')} />
            <Tab label={t('Services')} />
            <Tab label={t('Fees')} />
            <Tab label={t('Works')} />
          </Tabs>
          {!isEmpty(params) && !isEmpty(filtersCharges) ? (
            <ChargesTabs tab={valueTab} match={match} />
          ) : <Box mt={3} mb={3}>{t('No data')}</Box>}

          {Boolean(getChargesSelections.chosen) && (
            <Grid container spacing={6}>
              <Grid item xs={12} md={4}>
                <Typography>
                  {`${t('Chosen')}: ${getChargesSelections.chosen} ${t('in the amount of')} ${getChargesSelections.sum} ${t('UAH')}`}
                </Typography>

                <LinkChosen to="/" onClick={toggleMenu} aria-owns="simple-menu" aria-haspopup="true">
                  {t('With the chosen ones')}
                </LinkChosen>

                <Menu
                  id="simple-menu"
                  anchorEl={simpleMenu}
                  open={Boolean(simpleMenu)}
                  onClose={closeMenu}
                >
                  <MenuItem
                    onClick={handleCreateOrder}
                  >
                    {t('Create order')}
                  </MenuItem>
                  <MenuItem onClick={handlePayed}>
                    {t('Payed')}
                  </MenuItem>
                  <MenuItem
                    disabled={!fieldsForModal.length}
                    onClick={() => handleModalClose(true)}
                  >
                    {t('Change')}
                  </MenuItem>
                  <MenuItem onClick={handleDeleteCharges}>
                    {t('Delete')}
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>

      <Card mb={6}>
        <CardContent>
          <Typography variant="h6" paragraph>
            {t('Orders')}
          </Typography>
          {!isEmpty(params) && accessOrdersPost && (
            <Box mb={3}>
              <Button
                color="primary"
                variant="outlined"
                onClick={handleGoToOrdersByEntityId}
              >
                {t('ADD')}
                <AddIcon />
              </Button>
            </Box>
          )}

          {(!isEmpty(params) && !isEmpty(filtersOrders)) && <OrdersTable match={match} />}
        </CardContent>
      </Card>

      {isModalOpenAddCharges && (
        <AddChargesModal
          objectType={getObjectType}
          pending={pendingPostCharge}
          handleSend={handleSendAddChargesModal}
        />
      )}

      { /* Модалка изменений. */
        isModalOpen && (
          <TableModal
            isModal={isModalOpen}
            handleModalClose={handleModalClose}
            fieldsForModal={fieldsForModal}
            tableName={listTablesName.charges}
            reloadTable={chargesActions.setReload}
            checkboxes={getChargesSelections.collectedSections}
            handleSimpleMenuClose={setSimpleMenu}
          />
        )
      }
    </>
  );
}

ProposalPayments.propTypes = {
  filtersCharges: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  defaultFilters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  entityName: PropTypes.string.isRequired,
  filtersOrders: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  chargesSelections: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingPostCharge: PropTypes.bool.isRequired,
  isModalOpenAddCharges: PropTypes.bool.isRequired,
  match: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  clients: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  chargesTabs: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

ProposalPayments.defaultProps = {
  match: {},
  clients: {},
};

function mapStateToProps(state) {
  return {
    isModalOpenAddCharges: selectors.helpers.isModalOpenAddCharges(state),
    pendingPostCharge: selectors.charges.pendingPostCharge(state),
    filtersCharges: selectors.charges.filters(state),
    filtersOrders: selectors.ordersTable.filters(state),
    chargesSelections: selectors.charges.selections(state),
    chargesTabs: selectors.charges.tabs(state),
  };
}

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.isModalOpenAddCharges, nextProps.isModalOpenAddCharges)
    && isEqual(prevProps.pendingPostCharge, nextProps.pendingPostCharge)
    && isEqual(prevProps.chargesSelections, nextProps.chargesSelections)
    && isEqual(prevProps.filtersOrders, nextProps.filtersOrders)
    && isEqual(prevProps.defaultFilters, nextProps.defaultFilters)
    && isEqual(prevProps.entityName, nextProps.entityName)
    && isEqual(prevProps.chargesTabs, nextProps.chargesTabs)
    && isEqual(prevProps.filtersCharges, nextProps.filtersCharges);
}

export default compose(
  connect(mapStateToProps, null),
)(memo(ProposalPayments, areEqual));
