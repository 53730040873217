// Core
import { call, put } from 'redux-saga/effects';
import { List } from 'immutable';

// lodash
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';

// config
import { api } from '../../../../../config/api';
import { messageRequestSent } from '../../../../../config/globalConfig';

// actions
import searchesActions from '../../action';
import actionsAsync from '../asyncAction';

// init
import i18n from '../../../../../init/i18n';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../../ui/_helpers/setNotificationMessage';

export function* callPostQuickSearchWorker({ payload: searchData }) {
  const { setQuickSearchData } = searchesActions;
  const { setTabsAsync } = actionsAsync;

  yield put(setQuickSearchData({
    pendingPost: true,
  }));

  const response = yield call(api.trademarks.postQuickSearch, searchData);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      const { items } = data;
      yield put(setSuccessMessage(i18n.t(messageRequestSent), response.status.toString()));
      yield put(setQuickSearchData({
        items: List(items),
        isEmpty: isEmpty(items),
      }));
      yield put(setTabsAsync(items));
    }
  }

  yield put(setQuickSearchData({
    pendingPost: false,
  }));
}
