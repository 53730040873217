// core
import React from 'react';
import { Field } from 'redux-form/lib/immutable';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

// parts
import AutocompleteChips from '../AutocompleteChips';

// config
import selectors from '../../../engine/config/selectors';

// helpers
import { getValueFormField } from '../../_helpers/getValueFormField';

// hook
import { useMain } from './_hook/useMain';

function FieldTags(props) {
  const {
    data, formFieldInput, pendingDefaultList, handleEditTag,
    name, propsField, label, handleCreateNewTag,
    getAutocompleteLists, formField, handleDeleteTag,
  } = props;
  const {
    startLoading, handleOnFocus, dataItems,
  } = useMain({
    formField,
    getAutocompleteLists,
    formFieldInput,
  });
  const { t } = useTranslation();

  return (
    <Field
      fullWidth
      changeToStore
      name={name}
      label={t(label)}
      margin="normal"
      loading={startLoading ? pendingDefaultList : false}
      handleOnFocus={handleOnFocus(name, data)}
      options={dataItems(data)}
      handleCreateNew={handleCreateNewTag}
      handleEditTag={handleEditTag}
      handleDeleteTag={handleDeleteTag}
      component={AutocompleteChips}
      {...propsField}
    />
  );
}

FieldTags.displayName = 'FieldTags';

FieldTags.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  getAutocompleteLists: PropTypes.func.isRequired,
  handleCreateNewTag: PropTypes.func.isRequired,
  handleEditTag: PropTypes.func.isRequired,
  handleDeleteTag: PropTypes.func.isRequired,
  pendingDefaultList: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  propsField: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  formField: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array,
  ]),
  formFieldInput: PropTypes.string,
  label: PropTypes.string,
};

FieldTags.defaultProps = {
  formField: {},
  propsField: {},
  formFieldInput: '',
  label: 'Tags',
};

function mapStateToProps(state, props) {
  const { formName, name } = props;
  const formField = getValueFormField(state, formName, name);
  const formFieldInput = getValueFormField(state, formName, `${name}Input`);

  return {
    data: selectors.tags.tagsData(state),
    pendingDefaultList: selectors.tags.pendingData(state),
    formField: formField(),
    formFieldInput: formFieldInput(),
  };
}

export default compose(
  connect(mapStateToProps, null),
)(FieldTags);
