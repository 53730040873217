// core
import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

// ui
import {
  Divider as MuiDivider, Typography,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';

// parts
import LoggingTable from './LoggingTable';
import LogModal from './LogModal';

// hook
import { useLogging } from './_hooks/useLogging';

// selectors
import selectors from '../../engine/config/selectors';

// styles
const Divider = styled(MuiDivider)(spacing);

function Logging(props) {
  const { isModalOpen } = props;
  useLogging();
  const { t } = useTranslation();

  return (
    <>
      <Helmet title={t('Action log')} />
      <Typography variant="h3" gutterBottom display="inline">
        {t('Action log')}
      </Typography>
      <Divider my={6} />

      <LoggingTable />

      {isModalOpen && (
        <LogModal isTable />
      )}
    </>
  );
}

Logging.displayName = 'Logging';

Logging.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    isModalOpen: selectors.logging.isModalOpen(state),
  };
}

export default compose(
  connect(mapStateToProps, null),
)(Logging);
