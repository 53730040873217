// Helpers
import actionCreator from '../../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const actionsAsync = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_PRICES_CATEGORIES_ASYNC, params);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_PRICES_CATEGORIES_INITIAL_STATE_ASYNC, path);
  },
  getCategoryByIdAsync(id) {
    return actionCreator(asyncTypes.GET_PRICES_CATEGORY_BY_ID_ASYNC, id);
  },
  postCategoryAsync(data) {
    return actionCreator(asyncTypes.POST_PRICES_CATEGORY_ASYNC, data);
  },
  putCategoryAsync(data) {
    return actionCreator(asyncTypes.PUT_PRICES_CATEGORY_BY_ID_ASYNC, data);
  },
  deleteCategoryAsync() {
    return actionCreator(asyncTypes.DELETE_PRICES_CATEGORY_ASYNC);
  },
});

export default actionsAsync;
