// Core
import React, { useMemo } from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { List, Map } from 'immutable';

// UI
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import MuiChip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import SelectComponent from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

// lodash
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import find from 'lodash/find';

const Chip = styled(MuiChip)`
  margin: 2px;
`;

const ChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

function Select(props) {
  const {
    items, input, labelId, textEmpty,
    label, margin, displayEmpty, multiple,
    meta: { touched, invalid },
    ...rest
  } = props;

  const { t } = useTranslation();
  const inputValue = useMemo(() => (
    input.value.toJS ? input.value.toJS() : input.value
  ), [input]);
  const getItems = (item) => (
    <MenuItem
      key={item.value || item.id || item.title || item.name}
      value={item.value || item.id}
    >
      {multiple ? (
        <>
          <Checkbox checked={includes(inputValue, item.id)} color="primary" />
          {item.name || item.label}
        </>
      ) : (
        item.name || item.label || item.email || item.title
      )}
    </MenuItem>
  );
  const checkIsImmutable = () => {
    if (List.isList(items) || Map.isMap(items)) {
      return (
        !isEmpty(items.toJS()) && map(items.toJS(), (item) => getItems(item))
      );
    }
    return (
      !isEmpty(items) && map(items, (item) => getItems(item))
    );
  };
  return (
    <FormControl
      fullWidth
      margin={margin}
      error={touched && invalid}
      {...rest}
    >
      { label && (
        <InputLabel
          id={labelId}
          {...displayEmpty ? {
            shrink: true,
          } : {}}
        >
          {label}
        </InputLabel>
      ) }
      <SelectComponent
        {...input}
        multiple={multiple}
        value={multiple && !inputValue ? [] : inputValue}
        labelId={labelId}
        displayEmpty={displayEmpty}
        {...multiple ? {
          renderValue: (selected) => (
            <ChipsContainer>
              {map(selected, (id) => {
                const findItem = find(items, { id });
                return (<Chip key={id} label={findItem.name} />);
              })}
            </ChipsContainer>
          ),
        } : {}}
      >
        {displayEmpty && <MenuItem value="">{t(textEmpty)}</MenuItem>}
        {checkIsImmutable()}
      </SelectComponent>
    </FormControl>
  );
}

Select.displayName = 'Select';

Select.defaultProps = {
  margin: 'normal',
  displayEmpty: false,
  textEmpty: 'All',
  multiple: false,
};

Select.propTypes = {
  items: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  margin: PropTypes.string,
  labelId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.array,
      PropTypes.object,
    ]),
  }).isRequired,
  displayEmpty: PropTypes.bool,
  multiple: PropTypes.bool,
  textEmpty: PropTypes.string,
  meta: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

export default Select;
