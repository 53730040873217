// Core
import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// import { List, Map } from 'immutable';

// UI
import {
  Paper as MuiPaper, Button, Checkbox as MuiCheckbox, ListItemText,
  ListItemIcon as MuiListItemIcon, ListItem, List as MuiList, Grid,
  Typography as MuiTypography, Divider, TextField,
} from '@material-ui/core';

// lodash
import includes from 'lodash/includes';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';
import filter from 'lodash/filter';
import find from 'lodash/find';

const TypographyTitle = styled(MuiTypography)`
  padding: 10px;
  background-color: rgb(224, 224, 224);
  border-radius: 4px 4px 0 0;
`;

const Paper = styled(MuiPaper)`
  box-shadow: 0 0 14px 0 rgb(53 64 82 / 20%) !important;
`;

const Checkbox = styled(MuiCheckbox)`
  padding: 4px;
`;

const ListItemIcon = styled(MuiListItemIcon)`
  min-width: 36px;
`;

const List = styled(MuiList)`
  width: 350px;
  height: 400px;
  overflow: auto;
`;

const SearchContainer = styled.div`
  margin: 5px;
`;

function not(checked, list) {
  return filter(checked, (item) => !find(list, { id: item.id }));
}

function intersection(checked, list) {
  return filter(checked, (item) => find(list, { id: item.id }));
}

function CustomList(props) {
  const {
    items, text, checked, handleToggle,
  } = props;
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };
  return (
    <Paper>
      <TypographyTitle variant="h6">
        {t(text)}
      </TypographyTitle>
      <Divider />
      <SearchContainer>
        <TextField
          onChange={handleChange}
          name="search"
          id="search"
          label={t('Search')}
          margin="none"
          variant="standard"
          fullWidth
          type="text"
        />
      </SearchContainer>
      <List dense component="div" role="list">
        {filter(items, (item) => includes(item?.title, searchValue)).map((item) => {
          const labelId = `transfer-list-item-${item.id}-label`;
          return (
            <ListItem
              key={item.id}
              role="listitem"
              button
              onClick={handleToggle(item)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={!!find(checked, { id: item.id })}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={item.title} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );
}

CustomList.propTypes = {
  items: PropTypes.oneOfType([PropTypes.array]).isRequired,
  checked: PropTypes.oneOfType([PropTypes.array]).isRequired,
  handleToggle: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

function TransferList(props) {
  const {
    allList, selectedList, handleChangeAllList, handleChangeSelectedList,
  } = props;
  const [checked, setChecked] = useState([]);
  const sortedAllList = useMemo(() => sortBy(allList, 'title'), [allList]);
  const sortedSelectedList = useMemo(() => sortBy(selectedList, 'title'), [selectedList]);

  const leftChecked = intersection(checked, sortedAllList);
  const rightChecked = intersection(checked, sortedSelectedList);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleAllRight = () => {
    handleChangeSelectedList(sortedSelectedList.concat(sortedAllList));
    handleChangeAllList([]);
  };

  const handleCheckedRight = () => {
    handleChangeSelectedList(sortedSelectedList.concat(leftChecked));
    handleChangeAllList(not(sortedAllList, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    handleChangeAllList(sortedAllList.concat(rightChecked));
    handleChangeSelectedList(not(sortedSelectedList, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    handleChangeAllList(sortedAllList.concat(sortedSelectedList));
    handleChangeSelectedList([]);
  };

  return (
    <Grid container spacing={6} alignItems="center">
      <Grid item>
        <CustomList
          items={sortedAllList}
          text="Available permissions"
          checked={checked}
          handleToggle={handleToggle}
        />
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllRight}
            disabled={sortedAllList.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllLeft}
            disabled={sortedSelectedList.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <CustomList
          items={sortedSelectedList}
          text="Chosen permissions"
          checked={checked}
          handleToggle={handleToggle}
        />
      </Grid>
    </Grid>
  );
}

TransferList.displayName = 'TransferList';

TransferList.defaultProps = {
  allList: [],
  selectedList: [],
};

TransferList.propTypes = {
  allList: PropTypes.oneOfType([
    PropTypes.array,
  ]),
  selectedList: PropTypes.oneOfType([
    PropTypes.array,
  ]),
  handleChangeAllList: PropTypes.func.isRequired,
  handleChangeSelectedList: PropTypes.func.isRequired,
};

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.allList, nextProps.allList)
    && isEqual(prevProps.selectedList, nextProps.selectedList);
}

export default React.memo(TransferList, areEqual);
