// /* eslint-disable */
// Core
import React, { useEffect, useState, useMemo } from 'react';
import {
  BrowserRouter as Router, Route, Switch, Redirect,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';

// lodash
import {
  isEmpty, has, map, includes, filter, reduce,
} from 'lodash';

// routers
import {
  authLayoutRoutes,
  allRoutes,
} from './index';

// parts
import DashboardLayout from '../layouts/Dashboard';
import AuthLayout from '../layouts/Auth';
import Page404 from '../pages/Auth/Page404';
import Page500 from '../pages/Auth/Page500';

// engine
import { history } from '../engine/init/midlware';
import selectors from '../engine/config/selectors';

// actions
import helpersActionAsync from '../engine/core/helpers/saga/asyncAction';
import userActionAsync from '../engine/core/user/saga/asyncAction';
import asyncActionsSetting from '../engine/core/settings/saga/asyncAction';
import asyncActionsCompanies from '../engine/core/company/profile/saga/asyncAction';
import accessList from '../engine/config/accessList';

const childRoutes = (Layout, routes) => (
  map(routes, ({
    children, path, component: Component,
  }, index) => (
    children ? (
      // Route item with children
      map(children, ({ path: childrenPath, component: ComponentChildren }, i) => (
        <Route
          key={i}
          path={childrenPath}
          exact
          render={(props) => (
            <Layout>
              <ComponentChildren {...props} />
            </Layout>
          )}
        />
      ))
    ) : (
      // Route item without children
      <Route
        key={index}
        path={path}
        exact
        render={(props) => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    )
  ))
);

const Routes = () => {
  const dispatch = useDispatch();
  const [readyUserInfo, setReadyUserInfo] = useState(false);
  const userInfo = useSelector(selectors.user.userInfo);
  useEffect(() => {
    if (!readyUserInfo
      && !isEmpty(userInfo)
      && !has(userInfo, 'id')) {
      setReadyUserInfo(true);
      dispatch(userActionAsync.getUserInfoAsync());
    }
  }, [readyUserInfo, userInfo, setReadyUserInfo, dispatch]);

  useEffect(() => {
    if (has(userInfo, 'id')) {
      const { permissions } = userInfo;
      if (includes(permissions, accessList.settings_list_get)) {
        dispatch(asyncActionsSetting.getSettingsAsync());
      }
      if (includes(permissions, accessList.entity_statuses_get)) {
        dispatch(helpersActionAsync.getObjectStatusesAsync());
      }
      if (includes(permissions, accessList.company_list_get)) {
        dispatch(asyncActionsCompanies.getAllCompaniesAsync());
      }
      dispatch(helpersActionAsync.getInitialStateAsync({ url: 'selectedCompany' }));
    }
  }, [dispatch, userInfo]);

  useEffect(() => {
    dispatch(helpersActionAsync.getInitialStateAsync({ url: 'visibleSidebar' }));
  }, [dispatch]);

  const filterRouters = useMemo(() => {
    if (has(userInfo, 'permissions')) {
      return reduce(allRoutes, (acc, item) => {
        const children = item.children ? filter(item.children,
          (elem) => includes(userInfo.permissions, elem.accessName)) : [];
        if (!isEmpty(children) || includes(userInfo.permissions, item.accessName)) {
          acc.push({
            ...item,
            ...!isEmpty(children) ? {
              children,
            } : {},
          });
        }
        return acc;
      }, []);
    }
    return allRoutes;
  }, [userInfo]);

  return (
    <Router>
      <ConnectedRouter history={history}>
        <Switch>
          {childRoutes(AuthLayout, authLayoutRoutes)}
          {childRoutes(DashboardLayout, filterRouters)}

          <Route exact component={Page404} path="/404" />
          <Route exact component={Page500} path="/500" />

          <Redirect to="/404" />
        </Switch>
      </ConnectedRouter>

    </Router>
  );
};

export default Routes;
