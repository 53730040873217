import React from 'react';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import {
  Divider as MuiDivider, Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';
import { useTable } from './_hooks/useTable';
import Table from './components/Table';

// styles
const Divider = styled(MuiDivider)(spacing);

const Receiving = () => {
  useTable();
  const { t } = useTranslation();

  return (
    <>
      <Helmet title={t('Receiving')} />
      <Typography variant="h3" gutterBottom display="inline">
        {t('Receiving')}
      </Typography>
      <Divider my={6} />

      <Table />
    </>
  );
};

export default Receiving;
