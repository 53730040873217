// Core
import { call, put } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';
import pickBy from 'lodash/pickBy';

// config
import { api } from '../../../../config/api';
import { messageRequestSuccess } from '../../../../config/globalConfig';

// init
import i18n from '../../../../init/i18n';

// actions
import actions from '../../action';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../ui/_helpers/setNotificationMessage';

export function* callPutChargeByIdWorker({ payload }) {
  const { setChargeById, setReload } = actions;

  yield put(setChargeById({
    pendingPut: true,
  }));

  const filterChargeData = pickBy(payload, (value, key) => key !== 'reload');
  const response = yield call(api.charges.putChargeById, filterChargeData);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      yield put(setSuccessMessage(i18n.t(messageRequestSuccess), response.status.toString()));
      if (payload.reload) {
        yield put(setReload(true));
      }
    }
  }

  yield put(setChargeById({
    pendingPut: false,
  }));
}
