export default Object.freeze({
  SET_CURRENCY_RATES_DATA: 'SET_CURRENCY_RATES_DATA',
  SET_CURRENCY_RATE_PENDING_POST: 'SET_CURRENCY_RATE_PENDING_POST',
  SET_CURRENCY_RATES_RELOAD: 'SET_CURRENCY_RATES_RELOAD',
  SET_CURRENCY_RATES_CURRENT_PAGE: 'SET_CURRENCY_RATES_CURRENT_PAGE',
  SET_CURRENCY_RATES_FILTERS: 'SET_CURRENCY_RATES_FILTERS',
  SET_CURRENCY_RATES_SORTING: 'SET_CURRENCY_RATES_SORTING',
  SET_CURRENCY_RATES_PAGE_SIZE: 'SET_CURRENCY_RATES_PAGE_SIZE',
  SET_CURRENCY_RATES_INITIAL_STATE: 'SET_CURRENCY_RATES_INITIAL_STATE',
  SET_DELETE_CURRENCY_RATE: 'SET_DELETE_CURRENCY_RATE',
  SET_CURRENCY_RATES_AUTOCOMPLETE_OPTIONS: 'SET_CURRENCY_RATES_AUTOCOMPLETE_OPTIONS',
  SET_PENDING_CURRENCY_RATES_AUTOCOMPLETE_OPTIONS: 'SET_PENDING_CURRENCY_RATES_AUTOCOMPLETE_OPTIONS',
  SET_CURRENCY_RATES_IS_MODAL_OPEN: 'SET_CURRENCY_RATES_IS_MODAL_OPEN',
  SET_CURRENCY_RATES_COLUMN_WIDTHS: 'SET_CURRENCY_RATES_COLUMN_WIDTHS',
  SET_CURRENCY_RATES_COLUMN_ORDER: 'SET_CURRENCY_RATES_COLUMN_ORDER',
  SET_CURRENCY_RATES_HIDDEN_COLUMN_NAMES: 'SET_CURRENCY_RATES_HIDDEN_COLUMN_NAMES',
});
