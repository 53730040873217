// Core
import { call, put } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../../config/api';

// init
import i18n from '../../../../../init/i18n';

// actions
import clientsActions from '../../action';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../../ui/_helpers/setNotificationMessage';

export function* callPostSentCredentialsWorker({ payload: data }) {
  const { setPendingSentCredentials } = clientsActions;

  yield put(setPendingSentCredentials(true));

  const response = yield call(api.company.postSentCredentials, data);

  if (response && response.status >= 200 && response.status < 400) {
    if (has(response.data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      yield put(setSuccessMessage(i18n.t('Request sent!'), response.status.toString()));
    }
  }

  yield put(setPendingSentCredentials(false));
}
