// core
import React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 100px;
  }
`;

function RenderQuill(props) {
  const {
    label,
    labelId,
    input: {
      value, onChange, name, onFocus, onDragStart, onDrop,
    },
    disabled,
    ...rest
  } = props;
  return (
    <QuillWrapper>
      <ReactQuill
        theme="snow"
        placeholder={label}
        id={labelId}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onDragStart={onDragStart}
        onDrop={onDrop}
        name={name}
        readOnly={disabled}
        {...rest}
      />
    </QuillWrapper>
  );
}

RenderQuill.displayName = 'RenderQuill';

RenderQuill.propTypes = {
  labelId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  input: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  disabled: PropTypes.bool,
};

RenderQuill.defaultProps = {
  input: {},
  disabled: false,
};

export default RenderQuill;
