// Core
import { put, select } from 'redux-saga/effects';
import moment from 'moment';

// lodash
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';

// Engine
import localData from '../../../../config/localData';
import { setInitialPageState } from '../../../../../ui/_helpers/setInitialPageState';
import selectors from '../../../../config/selectors';

// action
import actions from '../../action';

export function* callGetInitialStateWorker({ payload: url }) {
  const lists = yield localData.getItem(url);
  const initialPageState = setInitialPageState(lists || {});

  const userInfo = yield select(selectors.user.userInfo);
  yield put(actions.setInitialState({
    ...initialPageState,
    ...(isEmpty(initialPageState.filters) && !isEmpty(userInfo) && userInfo.id) ? {
      filters: [
        { columnName: 'status', value: 1 },
        { columnName: 'users', value: { id: userInfo.id, label: userInfo.name } },
        {
          columnName: 'finishAt',
          value: {
            endDate: moment().endOf('month').format(),
            startDate: moment().startOf('month').format(),
            key: 'selection',
          },
        },
      ],
    } : {
      filters: [
        ...filter(initialPageState.filters, (elem) => elem.columnName !== 'finishAt'),
        {
          columnName: 'finishAt',
          value: {
            endDate: moment().endOf('month').format(),
            startDate: moment().startOf('month').format(),
            key: 'selection',
          },
        },
      ],
    },
  }));
}
