// Core
import { call, put } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../config/api';

// actions
import actions from '../../action';
import helpersActions from '../../../helpers/action';

// helpers
import { setErrorMessage } from '../../../../../ui/_helpers/setNotificationMessage';

export function* callGetApplicantByIdWorker({ payload: params }) {
  const {
    setApplicantById, setPendingGetApplicantById,
    setNewApplicant,
  } = actions;

  const { id, isModal = false } = params;
  yield put(setPendingGetApplicantById(true));

  const response = yield call(api.applicants.getApplicantById, id);
  if (response && response.status >= 200 && response.status < 400) {
    yield put(setPendingGetApplicantById(false));
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
      return;
    }
    if (isModal) {
      yield put(helpersActions.setModal({ isModalOpenAddApplicant: true }));
      yield put(setApplicantById(data));
      return;
    }
    yield put(setNewApplicant(data));
  }
  yield put(setPendingGetApplicantById(false));
}
