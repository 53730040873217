// Core
import React, {
  useEffect,
} from 'react';
import { connect, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

// Lodash
import {
  map, isEmpty,
} from 'lodash';

// parts
import DxTable from '../../../ui/Table/DxTable';
import Send from '../../../components/Send/Send';

// actions
import actions from '../../../engine/core/sending/action';
import departmentDocumentsActions from '../../../engine/core/departmentDocuments/action';

// config
import selectors from '../../../engine/config/selectors';

import { listTablesName, listEntityName } from '../../../engine/config/listTablesName';
import helpersActions from '../../../engine/core/helpers/action';

const tableName = listTablesName.outdocs;
const entityName = listEntityName.outdocs;

function SendingTable(props) {
  const {
    data, totalCount, pending,
    currentPage, filters, documentIdSend,
    pageSize, checkboxes, sorting,
    columnWidths, columnOrder, hiddenColumnNames,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => function cleanup() {
    dispatch(actions.setSendingData({
      items: [],
      pending: false,
      totalCount: 0,
    }));
    dispatch(departmentDocumentsActions.setDocumentIdSend(''));
  }, [dispatch]);

  const columns = [
    { name: 'id', title: t('ID') },
    { name: 'documents', title: t('Documents') },
    { name: 'sendingLog', title: t('Status') },
    { name: 'sentAt', title: t('Sent') },
    { name: 'trademarks', title: t('Trade marks') },
    { name: 'industrial', title: t('Industrial designs') },
    { name: 'inventions', title: t('inventions') },
    { name: 'utility', title: t('Utility models') },
  ];

  const tableColumnExtensions = [
    { columnName: 'id', width: 50 },
    { columnName: 'documents', width: '15rem' },
    { columnName: 'sendingLog', width: '10rem' },
    { columnName: 'sentAt', width: '15rem' },
    { columnName: 'trademarks', width: '10rem' },
    { columnName: 'industrial', width: '10rem' },
    { columnName: 'inventions', width: '10rem' },
    { columnName: 'utility', width: '10rem' },
  ];

  // Paging
  const onCurrentPage = (page) => dispatch(actions.setCurrentPage(page));
  const onPageSize = (size) => dispatch(actions.setPageSize(size));

  // Filtering
  const setFilters = (getFilters) => dispatch(actions.setFilters(getFilters));

  // Sorting
  const onSortingChange = (getSorting) => dispatch(actions.setSorting(getSorting));
  const onColumnWidthsChange = (getColumnWidths) => dispatch(
    actions.setColumnWidths(getColumnWidths),
  );
  const onColumnOrderChange = (getColumnOrder) => dispatch(
    actions.setColumnOrder(getColumnOrder),
  );
  const onHiddenColumnNamesChange = (getHiddenColumnNames) => dispatch(
    actions.setHiddenColumnNames(getHiddenColumnNames),
  );

  return (
    <>
      <DxTable
        name="SendingTable"
        isLoading={pending}
        columns={columns}
        entityName={entityName}
        rows={data.toJS().items}
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageSize={onPageSize}
        // checkboxes ------------------------
        tableName={tableName}
        reloadTable={actions.setReload}
        selection={checkboxes.toJS()}
        onSelectionChange={(checkboxSelect) => {
          dispatch(helpersActions.setCheckbox({
            tableName,
            checkboxes: checkboxSelect,
          }));
        }}
        // ------------------------
        onCurrentPage={onCurrentPage}
        filtersAction={setFilters}
        filters={filters}
        sorting={sorting}
        onSortingChange={onSortingChange}
        tableColumnExtensions={!isEmpty(columnWidths) && columnWidths.length === columns.length
          ? columnWidths : tableColumnExtensions}
        onColumnWidthsChange={onColumnWidthsChange}
        columnOrder={!isEmpty(columnOrder) ? columnOrder : map(columns, 'name')}
        onColumnOrderChange={onColumnOrderChange}
        hiddenColumnNames={hiddenColumnNames}
        onHiddenColumnNamesChange={onHiddenColumnNamesChange}
      />
      <Send
        visibleButton={false}
        type="outdocs"
        entityIds={[documentIdSend]}
      />
    </>
  );
}

SendingTable.displayName = 'SendingTable';

SendingTable.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  totalCount: PropTypes.number.isRequired,
  pending: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  sorting: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  pageSize: PropTypes.number.isRequired,
  checkboxes: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  columnWidths: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  columnOrder: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  hiddenColumnNames: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  documentIdSend: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

function mapStateToProps(state) {
  return {
    data: selectors.sending.sendingData(state),
    pending: selectors.sending.pending(state),

    // Paging
    totalCount: selectors.sending.totalCount(state),
    currentPage: selectors.sending.currentPage(state),
    pageSize: selectors.sending.pageSize(state),

    // Filtering
    filters: selectors.sending.filters(state),

    sorting: selectors.sending.sorting(state),

    // ColumnWidths
    columnWidths: selectors.sending.columnWidths(state),
    columnOrder: selectors.sending.columnOrder(state),
    hiddenColumnNames: selectors.sending.hiddenColumnNames(state),

    checkboxes: selectors.helpers.getCheckboxes(state, tableName),
    documentIdSend: selectors.departmentDocuments.documentIdSend(state),
  };
}

export default compose(
  connect(mapStateToProps, null),
)(SendingTable);
