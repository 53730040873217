// core
import React, { useState, memo } from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { List } from 'immutable';
import { compose } from 'recompose';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// lodash
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import find from 'lodash/find';

// ui
import {
  ListItemIcon as MuiListItemIcon,
  Menu, MenuItem, ListItemText,
  IconButton, CircularProgress, Tooltip,
} from '@material-ui/core';
import {
  MoreVert as MoreVertIcon, RemoveRedEye as RemoveRedEyeIcon, Undo as UndoIcon,
} from '@material-ui/icons';

// actions
import helpersActionAsync from '../../engine/core/helpers/saga/asyncAction';
import sendingAsyncAction from '../../engine/core/sending/saga/asyncAction';
import receivingAsyncActions from '../../engine/core/receiving/saga/asyncAction';

// config
import selectors from '../../engine/config/selectors';
import { listTablesName } from '../../engine/config/listTablesName';

const ListItemIcon = styled(MuiListItemIcon)`
  min-width: 30px;
`;

function IconMenuTable(props) {
  const {
    menuItems, actionsProps, pendingDownloadFile, tableName,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const list = List(menuItems).toJS();

  const handleToggle = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDownload = (row, item) => {
    setAnchorEl(null);
    if (item.outDocs) {
      dispatch(sendingAsyncAction.getDownloadOutDocsAsync(row));
      return;
    }
    if (item.inDocs) {
      dispatch(receivingAsyncActions.getDownloadInDocsAsync(row));
      return;
    }
    dispatch(helpersActionAsync.getDownloadFileAsync(row.file || row.pdf));
  };
  const handleReview = () => {
    const findItem = find(menuItems, 'review');
    if (findItem) findItem.handleClick(actionsProps);
  };
  const handleRevert = () => {
    const findItem = find(menuItems, 'revert');
    if (findItem) findItem.handleClick(actionsProps);
  };

  return (
    <>
      {tableName !== listTablesName.logging && (
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleToggle}
          size="small"
        >
          <MoreVertIcon fontSize="small" />
        </IconButton>
      )}
      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {!isEmpty(list) && map(list, (item, index) => (
          !isEmpty(item) && (
            item.download ? (
              (!isEmpty(actionsProps.row.file) || item.outDocs || item.inDocs
                || !isEmpty(actionsProps.row.pdf)) && (
                <MenuItem
                  key={index}
                  onClick={() => handleDownload(actionsProps.row, item)}
                >
                  <ListItemIcon>
                    <IconButton
                      size="small"
                      disabled={pendingDownloadFile}
                    >
                      {pendingDownloadFile
                        ? <CircularProgress size={24} color="inherit" />
                        : item.icon}
                    </IconButton>
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                </MenuItem>
              )
            ) : (
              ((!isEmpty(item.checkParams)
                && actionsProps.row[item.checkParams.key] === item.checkParams.value)
                || isEmpty(item.checkParams)) && (
                <MenuItem
                  key={index}
                  onClick={() => {
                    setAnchorEl(null);
                    if (item.handleClick) item.handleClick(actionsProps);
                  }}
                >
                  <ListItemIcon>
                    {!isEmpty(item.iconSend) && !isEmpty(actionsProps.row.signs)
                      ? item.iconSend
                      : item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={!isEmpty(item.titleSend) && !isEmpty(actionsProps.row.signs)
                      ? item.titleSend
                      : item.title}
                  />
                </MenuItem>
              )
            )
          )
        ))}
      </Menu>
      {(tableName === listTablesName.documents || tableName === listTablesName.trademarksDocumentsIn
        || tableName === listTablesName.logging || tableName === listTablesName.indocs
        || tableName === listTablesName.trademarksDocumentsOut || tableName === 'CostsOrder' || tableName === 'DocumentsOrder') && (
          <Tooltip title={t('Review')} placement="top-start">
            <IconButton
              aria-label="view"
              aria-controls="view"
              onClick={handleReview}
              size="small"
            >
              <RemoveRedEyeIcon fontSize="small" />
            </IconButton>
          </Tooltip>
      )}
      {tableName === listTablesName.logging && (
        <Tooltip title={t('Revert')} placement="top-start">
          <IconButton
            aria-label="view"
            aria-controls="view"
            onClick={handleRevert}
            size="small"
          >
            <UndoIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
}

IconMenuTable.propTypes = {
  menuItems: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  actionsProps: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingDownloadFile: PropTypes.bool.isRequired,
  tableName: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    pendingDownloadFile: selectors.helpers.pendingDownloadFile(state),
  };
}

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.menuItems, nextProps.menuItems)
    && isEqual(prevProps.pendingDownloadFile, nextProps.pendingDownloadFile)
    && isEqual(prevProps.tableName, nextProps.tableName)
    && isEqual(prevProps.actionsProps, nextProps.actionsProps);
}

export default compose(
  connect(mapStateToProps, null),
)(memo(IconMenuTable, areEqual));
