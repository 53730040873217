export default Object.freeze({
  SET_LOGGING_DATA: 'SET_LOGGING_DATA',
  SET_LOGGING_BY_ID: 'SET_LOGGING_BY_ID',
  SET_LOGGING_RELOAD: 'SET_LOGGING_RELOAD',
  SET_LOGGING_CURRENT_PAGE: 'SET_LOGGING_CURRENT_PAGE',
  SET_LOGGING_FILTERS: 'SET_LOGGING_FILTERS',
  SET_LOGGING_SORTING: 'SET_LOGGING_SORTING',
  SET_LOGGING_PAGE_SIZE: 'SET_LOGGING_PAGE_SIZE',
  SET_LOGGING_INITIAL_STATE: 'SET_LOGGING_INITIAL_STATE',
  SET_DELETE_LOGGING: 'SET_DELETE_LOGGING',
  SET_LOGGING_IS_MODAL_OPEN: 'SET_LOGGING_IS_MODAL_OPEN',
  SET_LOGGING_DATA_BY_ID: 'SET_LOGGING_DATA_BY_ID',
  SET_LOGGING_COLUMN_WIDTHS: 'SET_LOGGING_COLUMN_WIDTHS',
  SET_LOGGING_COLUMN_ORDER: 'SET_LOGGING_COLUMN_ORDER',
  SET_LOGGING_HIDDEN_COLUMN_NAMES: 'SET_LOGGING_HIDDEN_COLUMN_NAMES',
});
