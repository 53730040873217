// Helpers
import actionCreator from '../../../../../../_helpers/actionCreator';

// Types
import asyncTypes from './asyncTypes';

const asyncActions = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_PROPOSAL_DOCUMENTS_OUT_ASYNC, params);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_PROPOSAL_DOCUMENTS_OUT_INITIAL_STATE_ASYNC, path);
  },
  postDeleteDocumentsOutAsync() {
    return actionCreator(asyncTypes.POST_DELETE_DOCUMENTS_OUT_ASYNC);
  },
});

export default asyncActions;
