// core
import React, { useMemo } from 'react';
import { Field } from 'redux-form/lib/immutable';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

// lodash
import isEmpty from 'lodash/isEmpty';

// parts
import AutocompleteField from '../AutocompleteField';

// config
import selectors from '../../../engine/config/selectors';

// helpers
import { getValueFormField } from '../../_helpers/getValueFormField';
import { searchParams } from './helper/searchParams';
import { fieldsAutocomplete } from './helper/form';
import { filterParams } from './helper/filterParams';

// hooks
import { useMain } from './_hook/useMain';

function FieldTradeMarksProposal(props) {
  const {
    data, fieldClient, autocompleteOptions,
    name, formFieldInput, propsField,
    pendingAutocompleteOptions, getAutocompleteLists,
    pendingDefaultList, searchParamName,
    getAsyncData, formField,
  } = props;
  const { t } = useTranslation();
  const autocompleteOptionsItems = useMemo(() => autocompleteOptions.get(!isEmpty(fieldClient)
    ? filterParams.clientsId
    : searchParamName), [
    autocompleteOptions, fieldClient, searchParamName,
  ]);
  const {
    startLoading, handleOnFocus, dataItems, checkOptions,
  } = useMain({
    formField,
    getAutocompleteLists,
    formFieldInput,
  });

  const handleGetAsyncData = (value, fieldName) => {
    getAsyncData(value, fieldName, propsField.multiple
    && !isEmpty(fieldClient) ? {
        [filterParams.clientsId]: fieldClient.id,
        [searchParamName]: value,
        [searchParams.applicationNumber]: value,
        [searchParams.registrationNumber]: value,
      } : {
        [searchParamName]: value,
        [searchParams.applicationNumber]: value,
        [searchParams.registrationNumber]: value,
      });
  };

  return (
    <Field
      fullWidth
      changeToStore
      name={name}
      loading={startLoading ? (pendingDefaultList || pendingAutocompleteOptions) : false}
      handleOnFocus={handleOnFocus(name, data)}
      getAsyncData={handleGetAsyncData}
      label={t('Trade marks')}
      margin="normal"
      options={checkOptions
        ? dataItems(data)
        : autocompleteOptionsItems}
      component={AutocompleteField}
      {...propsField}
    />
  );
}

FieldTradeMarksProposal.displayName = 'FieldTradeMarksProposal';

FieldTradeMarksProposal.propTypes = {
  autocompleteOptions: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.bool,
  ]).isRequired,
  data: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  getAutocompleteLists: PropTypes.func.isRequired,
  pendingAutocompleteOptions: PropTypes.bool.isRequired,
  pendingDefaultList: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  formField: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  formFieldInput: PropTypes.string,
  propsField: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  fieldClient: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array,
  ]),
  searchParamName: PropTypes.string,
  getAsyncData: PropTypes.func,
};

FieldTradeMarksProposal.defaultProps = {
  formField: {},
  fieldClient: {},
  propsField: {},
  formFieldInput: '',
  getAsyncData: () => {},
  searchParamName: searchParams.caseNumber,
};

function mapStateToProps(state, props) {
  const { formName, name } = props;
  const formField = getValueFormField(state, formName, name);
  const formFieldInput = getValueFormField(state, formName, `${name}Input`);
  const fieldClient = getValueFormField(state, formName, fieldsAutocomplete.clients);

  return {
    pendingAutocompleteOptions: selectors.tradeMarksProposal.pendingAutocompleteOptions(state),
    pendingDefaultList: selectors.tradeMarksProposal.pending(state),
    autocompleteOptions: selectors.tradeMarksProposal.autocompleteOptions(state),
    data: selectors.tradeMarksProposal.proposalData(state),
    formField: formField(),
    formFieldInput: formFieldInput(),
    fieldClient: fieldClient(),
  };
}

export default compose(
  connect(mapStateToProps, null),
)(FieldTradeMarksProposal);
