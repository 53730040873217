// Core
import { call, put } from 'redux-saga/effects';
import { Map } from 'immutable';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../config/api';
import { messageFileUploaded } from '../../../../config/globalConfig';

// init
import i18n from '../../../../init/i18n';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../ui/_helpers/setNotificationMessage';

// actions
import action from '../../action';

export function* callUploadFileWorker({ payload }) {
  const {
    file, type, callBack, onUploadProgress,
  } = payload;
  const {
    setUploadFile,
  } = action;
  yield put(setUploadFile({
    pendingPost: true,
  }));
  const response = yield call(api.uploadFile, file, onUploadProgress);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
      if (callBack) {
        callBack(false);
      }
    } else {
      yield put(setUploadFile({
        file: Map({ ...data, base64: file.file }),
        type,
      }));
      yield put(setSuccessMessage(i18n.t(messageFileUploaded), response.status.toString()));
      if (callBack) {
        callBack();
      }
    }
  }

  yield put(setUploadFile({
    pendingPost: false,
  }));
}
