// Core
import { all, call, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetMktuTemplatesWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callDeleteMktuTemplateWorker,
  callGetMktuTemplateByIdWorker,
  callGetMktuTemplatePdfByIdWorker,
  callPostMktuTemplatesWorker,
  callPutMktuTemplateByIdWorker,
  callGetAutocompleteOptionsWorker,
} from './workers';
import types from '../types';

function* watchGetMktuTemplates() {
  yield takeEvery(asyncTypes.GET_MKTU_TEMPLATES_ASYNC, callGetMktuTemplatesWorker);
}
function* watchGetMktuTemplateByIdAsync() {
  yield takeEvery(asyncTypes.GET_MKTU_TEMPLATE_BY_ID_ASYNC, callGetMktuTemplateByIdWorker);
}
function* watchGetMktuTemplatePdfByIdAsync() {
  yield takeEvery(asyncTypes.GET_MKTU_TEMPLATE_PDF_BY_ID_ASYNC, callGetMktuTemplatePdfByIdWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_MKTU_TEMPLATES_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_MKTU_TEMPLATES_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_MKTU_TEMPLATES_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_MKTU_TEMPLATES_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_MKTU_TEMPLATES_SORTING, callSetToLocalDataWorker);
}
function* watchSetColumnWidths() {
  yield takeEvery(types.SET_MKTU_TEMPLATES_COLUMN_WIDTHS, callSetToLocalDataWorker);
}
function* watchSetColumnOrder() {
  yield takeEvery(types.SET_MKTU_TEMPLATES_COLUMN_ORDER, callSetToLocalDataWorker);
}
function* watchSetHiddenColumnNames() {
  yield takeEvery(
    types.SET_MKTU_TEMPLATES_HIDDEN_COLUMN_NAMES,
    callSetToLocalDataWorker,
  );
}

function* watchPostMktuTemplates() {
  yield takeEvery(asyncTypes.POST_MKTU_TEMPLATES_ASYNC, callPostMktuTemplatesWorker);
}

function* watchPutMktuTemplateById() {
  yield takeEvery(asyncTypes.PUT_MKTU_TEMPLATE_BY_ID_ASYNC, callPutMktuTemplateByIdWorker);
}

function* watchDeleteMktuTemplate() {
  yield takeEvery(asyncTypes.DELETE_MKTU_TEMPLATE_ASYNC, callDeleteMktuTemplateWorker);
}

function* watchGetAutocompleteOptions() {
  yield takeEvery(
    asyncTypes.GET_MKTU_TEMPLATES_AUTOCOMPLETE_OPTIONS_ASYNC,
    callGetAutocompleteOptionsWorker,
  );
}

export function* watchersMktuTemplatesTable() {
  yield all([
    call(watchGetMktuTemplates),
    call(watchSetCurrentPage),
    call(watchSetPageSize),
    call(watchSetFilters),
    call(watchSetSorting),
    call(watchSetColumnWidths),
    call(watchSetColumnOrder),
    call(watchSetHiddenColumnNames),
    call(watchGetInitialState),
    call(watchPostMktuTemplates),
    call(watchPutMktuTemplateById),
    call(watchDeleteMktuTemplate),
    call(watchGetMktuTemplateByIdAsync),
    call(watchGetMktuTemplatePdfByIdAsync),
    call(watchGetAutocompleteOptions),
  ]);
}
