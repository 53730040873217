// Core
import { apply, put } from 'redux-saga/effects';
import { List } from 'immutable';

// lodash
import map from 'lodash/map';

// config
import { api } from '../../../../config/api';
import { listEntityName } from '../../../../config/listTablesName';

// helpers
import { getObjectReverseFormatDate } from '../../../../_helpers/formatterDate';

// actions
import pendingActions from '../../action';

const entity = listEntityName.pendingActions;

export function* callGetPendingActionsWorker({ payload: params }) {
  const {
    setPendingActions, setReload,
  } = pendingActions;
  yield put(setPendingActions({
    pending: true,
  }));
  const response = yield apply(api,
    api.pendingActions.getPendingActionsTable, [{ ...params, entity }]);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    yield put(setPendingActions({
      data: List(map(data.items, (item) => {
        const {
          title, createdAt, finishAt,
          status, label, id, type,
        } = item;

        return {
          ...item,
          id,
          title,
          label,
          createdAt,
          ...type?.colors ? {
            color: type.colors,
          } : {},
          ...status ? {
            status: `${status}`,
          } : {},
          ...getObjectReverseFormatDate(finishAt, 'start'),
          ...getObjectReverseFormatDate(finishAt, 'end'),
        };
      })),
    }));
  }

  yield put(setReload(false));
  yield put(setPendingActions({
    pending: false,
  }));
}
