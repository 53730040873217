// Core
import { call, put } from 'redux-saga/effects';

// config
import { api } from '../../../../config/api';

// actions
import actions from '../../action';

export function* callGetProductByIdWorker({ payload: id }) {
  const {
    setProductById, setProductDataById,
  } = actions;
  yield put(setProductById({
    pending: true,
  }));

  const response = yield call(api.products.getProductById, id);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    yield put(setProductDataById(data));
  }

  yield put(setProductById({
    pending: false,
  }));
}
