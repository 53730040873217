// core
import React from 'react';
import styled from 'styled-components';

// ui
import { CircularProgress } from '@material-ui/core';

const Root = styled.div`
    background: rgba(256, 256, 256, 0.5);
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
    position: absolute;
  top:0;
  left: 0;
  right: 0;
  bottom: 0; 
`;

function Loader() {
  return (
    <Root>
      <CircularProgress m={2} color="secondary" />
    </Root>
  );
}

export default Loader;
