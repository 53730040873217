export default Object.freeze({
  SET_EMAIL_MESSAGES_DATA: 'SET_EMAIL_MESSAGES_DATA',
  SET_EMAIL_MESSAGES_RELOAD: 'SET_EMAIL_MESSAGES_RELOAD',
  SET_EMAIL_MESSAGES_CURRENT_PAGE: 'SET_EMAIL_MESSAGES_CURRENT_PAGE',
  SET_EMAIL_MESSAGES_FILTERS: 'SET_EMAIL_MESSAGES_FILTERS',
  SET_EMAIL_MESSAGES_PAGE_SIZE: 'SET_EMAIL_MESSAGES_PAGE_SIZE',
  SET_EMAIL_MESSAGES_SORTING: 'SET_EMAIL_MESSAGES_SORTING',
  SET_EMAIL_MESSAGES_COLUMN_WIDTHS: 'SET_EMAIL_MESSAGES_COLUMN_WIDTHS',
  SET_EMAIL_MESSAGES_COLUMN_ORDER: 'SET_EMAIL_MESSAGES_COLUMN_ORDER',
  SET_EMAIL_MESSAGES_HIDDEN_COLUMN_NAMES: 'SET_EMAIL_MESSAGES_HIDDEN_COLUMN_NAMES',
  SET_EMAIL_MESSAGES_INITIAL_STATE: 'SET_EMAIL_MESSAGES_INITIAL_STATE',
  SET_EMAIL_MESSAGES_IS_MODAL_OPEN: 'SET_EMAIL_MESSAGES_IS_MODAL_OPEN',
  SET_EMAIL_THREADS_MESSAGES: 'SET_EMAIL_THREADS_MESSAGES',
  SET_PENDING_EMAIL_THREADS_MESSAGES: 'SET_PENDING_EMAIL_THREADS_MESSAGES',
  SET_EMAIL_TOTAL_COUNT_THREADS_MESSAGES: 'SET_EMAIL_TOTAL_COUNT_THREADS_MESSAGES',
  MERGE_EMAIL_THREADS_MESSAGES: 'MERGE_EMAIL_THREADS_MESSAGES',
  SET_EMAIL_NEXT_PAGE_THREADS_MESSAGES: 'SET_EMAIL_NEXT_PAGE_THREADS_MESSAGES',

  SET_PENDING_POST_SEND_EMAIL: 'SET_PENDING_POST_SEND_EMAIL',
  SET_PREVIEW_TEXT_EMAIL: 'SET_PREVIEW_TEXT_EMAIL',
  SET_PENDING_POST_PREVIEW_EMAIL: 'SET_PENDING_POST_PREVIEW_EMAIL',
  SET_PENDING_DOWNLOAD_ATTACHMENT: 'SET_PENDING_DOWNLOAD_ATTACHMENT',
  SET_IS_MODAL_OPEN_LINK_ENTITY: 'SET_IS_MODAL_OPEN_LINK_ENTITY',
  SET_SELECTED_EMAIL: 'SET_SELECTED_EMAIL',

  SET_EMAIL_SIGNATURES_LIST: 'SET_EMAIL_SIGNATURES_LIST',
  SET_EMAIL_SIGNATURES_IS_MODAL_OPEN: 'SET_EMAIL_SIGNATURES_IS_MODAL_OPEN',
  SET_EMAIL_SIGNATURES_DATA: 'SET_EMAIL_SIGNATURES_DATA',
  SET_EMAIL_SIGNATURES_PENDING: 'SET_EMAIL_SIGNATURES_PENDING',
  SET_EMAIL_SIGNATURES_PENDING_BY_ID: 'SET_EMAIL_SIGNATURES_PENDING_BY_ID',
  SET_EMAIL_SIGNATURES_PENDING_PUT: 'SET_EMAIL_SIGNATURES_PENDING_PUT',
  SET_EMAIL_SIGNATURES_PENDING_POST: 'SET_EMAIL_SIGNATURES_PENDING_POST',
  SET_EMAIL_SIGNATURES_PENDING_DELETE: 'SET_EMAIL_SIGNATURES_PENDING_DELETE',

  SET_EMAIL_LIST: 'SET_EMAIL_LIST',
  SET_EMAIL_IS_MODAL_OPEN: 'SET_EMAIL_IS_MODAL_OPEN',
  SET_EMAIL_DATA: 'SET_EMAIL_DATA',
  SET_EMAIL_PENDING: 'SET_EMAIL_PENDING',
  SET_EMAIL_PENDING_PUT: 'SET_EMAIL_PENDING_PUT',
  SET_EMAIL_PENDING_POST: 'SET_EMAIL_PENDING_POST',
  SET_EMAIL_PENDING_BY_ID: 'SET_EMAIL_PENDING_BY_ID',
  SET_EMAIL_PENDING_DELETE: 'SET_EMAIL_PENDING_DELETE',
  SET_EMAIL_PROVIDER: 'SET_EMAIL_PROVIDER',
  SET_PENDING_EMAIL_PROVIDER: 'SET_PENDING_EMAIL_PROVIDER',

  SET_EMAIL_LETTERS_LIST: 'SET_EMAIL_LETTERS_LIST',
  SET_EMAIL_LETTERS_IS_MODAL_OPEN: 'SET_EMAIL_LETTERS_IS_MODAL_OPEN',
  SET_EMAIL_LETTERS_DATA: 'SET_EMAIL_LETTERS_DATA',
  SET_EMAIL_LETTERS_FILTERS: 'SET_EMAIL_LETTERS_FILTERS',
  SET_EMAIL_LETTERS_CURRENT_PAGE: 'SET_EMAIL_LETTERS_CURRENT_PAGE',
  SET_EMAIL_LETTERS_TOTAL_COUNT: 'SET_EMAIL_LETTERS_TOTAL_COUNT',
  SET_EMAIL_LETTERS_PAGE_SIZE: 'SET_EMAIL_LETTERS_PAGE_SIZE',
  SET_EMAIL_LETTERS_RELOAD: 'SET_EMAIL_LETTERS_RELOAD',
  SET_EMAIL_LETTERS_PENDING: 'SET_EMAIL_LETTERS_PENDING',
  SET_EMAIL_LETTERS_PENDING_POST: 'SET_EMAIL_LETTERS_PENDING_POST',
  SET_EMAIL_LETTERS_PENDING_BY_ID: 'SET_EMAIL_LETTERS_PENDING_BY_ID',
  SET_EMAIL_LETTERS_PENDING_DELETE: 'SET_EMAIL_LETTERS_PENDING_DELETE',
});
