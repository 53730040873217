const asyncTypes = Object.freeze({
  GET_DOCUMENT_TEMPLATES_ASYNC: 'GET_DOCUMENT_TEMPLATES_ASYNC',
  GET_DOCUMENT_TEMPLATES_INITIAL_STATE_ASYNC: 'GET_DOCUMENT_TEMPLATES_INITIAL_STATE_ASYNC',
  DELETE_DOCUMENT_TEMPLATE_ASYNC: 'DELETE_DOCUMENT_TEMPLATE_ASYNC',
  POST_DOCUMENT_TEMPLATES_ASYNC: 'POST_DOCUMENT_TEMPLATES_ASYNC',
  GET_DOCUMENT_TEMPLATES_BY_ID_ASYNC: 'GET_DOCUMENT_TEMPLATES_BY_ID_ASYNC',
  PUT_DOCUMENT_TEMPLATES_BY_ID_ASYNC: 'PUT_DOCUMENT_TEMPLATES_BY_ID_ASYNC',
  GET_DOCUMENT_TEMPLATES_AUTOCOMPLETE_OPTIONS_ASYNC: 'GET_DOCUMENT_TEMPLATES_AUTOCOMPLETE_OPTIONS_ASYNC',
});

export default asyncTypes;
