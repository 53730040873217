// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const asyncActions = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_DOCUMENT_TYPES_ASYNC, params);
  },
  getDocumentTypeByIdAsync(id) {
    return actionCreator(asyncTypes.GET_DOCUMENT_TYPE_BY_ID_ASYNC, id);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_DOCUMENT_TYPES_INITIAL_STATE_ASYNC, path);
  },
  deleteDocumentTypeAsync() {
    return actionCreator(asyncTypes.DELETE_DOCUMENT_TYPE_ASYNC);
  },
  postDocumentTypeAsync(data) {
    return actionCreator(asyncTypes.POST_DOCUMENT_TYPE_ASYNC, data);
  },
  putDocumentTypeByIdAsync(data) {
    return actionCreator(asyncTypes.PUT_DOCUMENT_TYPE_BY_ID_ASYNC, data);
  },
  getAutocompleteOptionsAsync(params) {
    return actionCreator(asyncTypes.GET_DOCUMENT_TYPES_AUTOCOMPLETE_OPTIONS_ASYNC, params);
  },
});

export default asyncActions;
