// Core
import { put } from 'redux-saga/effects';
// Engine
import localData from '../../../../config/localData';
import actions from '../../action';

export function* callGetInitialStateWorker({ payload: url }) {
  const lists = yield localData.getItem(url);
  yield put(actions.setInitialState(lists || { filters: {} }));
}
