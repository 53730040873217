// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import types from './types';

const actions = Object.freeze({
  setMktuTemplatesData(calculationsData) {
    return actionCreator(types.SET_MKTU_TEMPLATES_DATA, calculationsData);
  },
  setCurrentPage(currentPage) {
    return actionCreator(types.SET_MKTU_TEMPLATES_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return actionCreator(types.SET_MKTU_TEMPLATES_FILTERS, filters);
  },
  setSorting(sorting) {
    return actionCreator(types.SET_MKTU_TEMPLATES_SORTING, sorting);
  },
  setColumnWidths(data) {
    return actionCreator(types.SET_MKTU_TEMPLATES_COLUMN_WIDTHS, data);
  },
  setColumnOrder(data) {
    return actionCreator(types.SET_MKTU_TEMPLATES_COLUMN_ORDER, data);
  },
  setHiddenColumnNames(data) {
    return actionCreator(types.SET_MKTU_TEMPLATES_HIDDEN_COLUMN_NAMES, data);
  },
  setPageSize(pageSize) {
    return actionCreator(types.SET_MKTU_TEMPLATES_PAGE_SIZE, pageSize);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_MKTU_TEMPLATES_INITIAL_STATE, initialState);
  },
  setReload(reload) {
    return actionCreator(types.SET_MKTU_TEMPLATES_RELOAD, reload);
  },
  setDeleteMktuTemplate(data) {
    return actionCreator(types.SET_DELETE_MKTU_TEMPLATE, data);
  },
  setMktuTemplatesById(data) {
    return actionCreator(types.SET_MKTU_TEMPLATES_BY_ID, data);
  },
  setMktuTemplatePdfById(pdf) {
    return actionCreator(types.SET_MKTU_TEMPLATE_PDF_BY_ID, pdf);
  },
  setPendingMktuTemplatePdf(pending) {
    return actionCreator(types.SET_PENDING_MKTU_TEMPLATE_PDF, pending);
  },
  setPendingMktuTemplateById(pending) {
    return actionCreator(types.SET_PENDING_MKTU_TEMPLATE_BY_ID, pending);
  },
  setModalAllTermsOpen(isOpen) {
    return actionCreator(types.SET_MKTU_TEMPLATE_MODAL_ALL_TERMS_IS_OPEN, isOpen);
  },
  setModalYourListTermsOpen(isOpen) {
    return actionCreator(types.SET_MKTU_TEMPLATE_MODAL_YOUR_LIST_TERMS_IS_OPEN, isOpen);
  },
  setAutocompleteOptions(options) {
    return actionCreator(types.SET_MKTU_TEMPLATE_AUTOCOMPLETE_OPTIONS, options);
  },
  setPendingAutocompleteOptions(pending) {
    return actionCreator(types.SET_PENDING_MKTU_TEMPLATE_AUTOCOMPLETE_OPTIONS, pending);
  },
});

export default actions;
