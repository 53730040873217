// core
import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// ui
import {
  Button, Dialog,
  DialogContent, DialogTitle,
  DialogActions, CircularProgress,
  Grid, IconButton as MuiIconButton,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

const IconButtonDesc = styled(MuiIconButton)`
  position: absolute;
  top: ${(props) => props.theme.spacing(2)}px;
  right: ${(props) => props.theme.spacing(2)}px;
  padding: 9px;
`;

function NewConfirmModal(props) {
  const {
    handleSend, isModalOpen,
    dialogTitle, description, buttonSendText,
    buttonCancelText, pending, handleClose,
  } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth
      open={isModalOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
    >
      <DialogTitle id="form-dialog-title">
        {dialogTitle ? (
          <Grid container>
            <Grid item xs={6} container alignItems="center">
              {t(dialogTitle)}
            </Grid>
            <Grid item xs={6} container justify="flex-end">
              <MuiIconButton aria-label="close" onClick={handleClose}>
                <Close />
              </MuiIconButton>
            </Grid>
          </Grid>
        ) : (
          <IconButtonDesc aria-label="close" onClick={handleClose}>
            <Close />
          </IconButtonDesc>
        )}
      </DialogTitle>
      <DialogContent>
        {t(description)}
      </DialogContent>
      <DialogActions>
        {buttonSendText && (
          <Button
            variant="contained"
            disabled={pending}
            color="primary"
            onClick={handleSend}
          >
            {pending ? <CircularProgress size={20} color="primary" /> : t(buttonSendText)}
          </Button>
        )}
        <Button
          onClick={handleClose}
          color="primary"
          variant="outlined"
        >
          {t(buttonCancelText)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

NewConfirmModal.displayName = 'NewConfirmModal';

NewConfirmModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSend: PropTypes.oneOfType([
    PropTypes.func,
  ]),
  description: PropTypes.string,
  dialogTitle: PropTypes.string,
  buttonSendText: PropTypes.string,
  buttonCancelText: PropTypes.string,
  pending: PropTypes.bool,
};

NewConfirmModal.defaultProps = {
  handleSend: () => {},
  pending: false,
  dialogTitle: '',
  buttonSendText: 'CONFIRM',
  buttonCancelText: 'CANCEL',
  description: 'Are you sure you want to delete?',
};

export default NewConfirmModal;
