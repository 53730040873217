// core
import React, {
  useEffect, useState, useMemo,
} from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import {
  Field, Form, reduxForm, change,
} from 'redux-form/immutable';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { Map } from 'immutable';
import { compose } from 'recompose';
import { Link as MuiLink } from 'react-router-dom';

// lodash
import {
  isEmpty, map, isNil, filter, find, isArray,
  forEach, reduce, uniqBy, sum, sortBy,
  replace, includes,
} from 'lodash';

// ui
import {
  Button as MuiButton, CardContent, Divider as MuiDivider, Box,
  Typography, Card as MuiCard, CircularProgress, Grid as MuiGrid,
  IconButton as MuiIconButton, FormControlLabel, Radio,
  ListItem as MuiListItem, List as MuiList, ButtonGroup, Tooltip,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import {
  Close as CloseIcon,
  Description as MuiDescription,
  CloudDownload as CloudDownloadIcon,
  NavigateBefore as NavigateBeforeIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  Update as UpdateIcon,
} from '@material-ui/icons';
import { DropzoneAreaBase } from 'material-ui-dropzone';

// helpers
import { formName, fieldsAutocomplete, formFields } from '../helper/form';

// parts
import Loader from '../../../components/Loader';
import ProgressBar from '../../../components/ProgressBar';
import Select from '../../../ui/Form/Select';
import renderTextField from '../../../ui/Form/RenderTextField';
import AutocompleteField from '../../../ui/Form/AutocompleteField';
import DatePickers from '../../../ui/Form/DatePickers';
import RadioButton from '../../../ui/Form/RadioButton';
import FieldDepartmentDocuments from '../../../ui/Form/FieldsAutocomplete/FieldDepartmentDocuments';
import FieldDocumentTemplate from '../../../ui/Form/FieldsAutocomplete/FieldDocumentTemplate';
import Sign from '../../../components/Sign/Sign';
import AutocompleteGlobalSearch from '../../../ui/Form/AutocompleteGlobalSearch';
import Send from '../../../components/Send/Send';
import TableEntitiesModal from '../../../components/TableEntitiesModal/TableEntitiesModal';
import DropZoneAreaModal from '../../../components/DropZoneAreaModal/DropZoneAreaModal';
import DocViewerModal from '../../../components/DocViewerModal/DocViewerModal';
import ChipEntity from '../../../ui/Chip/ChipEntity';

// actions
import departmentDocumentsActionAsync from '../../../engine/core/departmentDocuments/saga/asyncAction';
import departmentDocumentsAction from '../../../engine/core/departmentDocuments/action';
import helpersActionAsync from '../../../engine/core/helpers/saga/asyncAction';
import helpersActions from '../../../engine/core/helpers/action';
import uploadFileActionAsync from '../../../engine/core/uploadFile/saga/asyncAction';
import uploadFileAction from '../../../engine/core/uploadFile/action';
import docTypCategoryAction from '../../../engine/core/documentTypes/category/action';
import docTypCategoryAsyncAction from '../../../engine/core/documentTypes/category/saga/asyncAction';

// hooks
import { useEventsAutocompleteAsync } from '../../../ui/_hooks/useEventsAutocompleteAsync';

// config
import selectors from '../../../engine/config/selectors';
import globalDocumentType from '../../../engine/config/globalDocumentType';

// routes
import { pageLinks } from '../../../routes';
import ProposalCalendar from '../../../components/ProposalCalendar/ProposalCalendar';
import accessList from '../../../engine/config/accessList';
import { useAccessList } from '../../../ui/_hooks/useAccessList';

// styles
const Divider = styled(MuiDivider)(spacing);
const Description = styled(MuiDescription)(spacing);
const Card = styled(MuiCard)(spacing);
const IconButton = styled(MuiIconButton)(spacing);
const Button = styled(MuiButton)(spacing);
const Grid = styled(MuiGrid)(spacing);

const List = styled(MuiList)`
  display: flex;
  flex-wrap: wrap;
`;
const ListItem = styled(MuiListItem)`
  width: auto;
  padding-left: 0;
`;
const Link = styled(MuiLink)`
  text-decoration: underline;
  color: #1976d2;
  display: flex;
  align-items: center;
`;
const TypographySub = styled(Typography)`
  color: ${(props) => props.theme.palette.grey['500']};
`;

function DepartmentDocument(props) {
  const {
    initialize, handleSubmit, pendingDepartmentDocumentById, isUserProfile,
    pendingPutDepartmentDocumentById, getDepartmentDocumentById, valid, match,
    pendingPostDepartmentDocument, documentType, predefinedInputs,
    fieldChoice, signList, uploadFile, uploadFileType, getNextNumber, lastObject,
    entityList, selectedCompany, allCompanies, fieldType, pendingDownloadFile,
    isModalOpenTableEntities, pendingPreviewFileById, docTypeCategoriesData,
    isModalOpenDocViewer, checkboxClose,
  } = props;
  const { t } = useTranslation();
  const {
    getAutocompleteLists, handleGetAsyncData,
  } = useEventsAutocompleteAsync();
  const dispatch = useDispatch();
  const [readyDepartmentDocument, setReadyDepartmentDocument] = useState(false);
  const [readyInitialize, setReadyInitialize] = useState(false);
  const [readyDopTypes, setReadyDopTypes] = useState(false);
  const [urlFile, setUrlFile] = useState('');
  const [defaultId, setDefaultId] = useState(37);
  const [fileId, setFileId] = useState(0);
  const [progress, setProgress] = useState(0);
  const [progressAdditions, setProgressAdditions] = useState(0);
  const { params } = match;
  const accessPut = useAccessList(accessList.documents_put);
  const accessDownload = useAccessList(accessList.files_download_get);
  const accessNextNumber = useAccessList(accessList.documents_next_number_get);
  const accessDocTypeList = useAccessList(accessList.documentClassifier_list_get);
  const accessDocTypeCategoryList = useAccessList(accessList.documentClassifierCategory_list_get);
  const accessCompanyList = useAccessList(accessList.company_list_get);
  const accessEdit = !isEmpty(params) ? accessPut : true;

  const modifyToIds = (items) => (!isEmpty(items)
    ? filter(map(items.toJS ? items.toJS() : items, (item) => item.id), (elem) => elem)
    : []);

  // const modifyOriginal = (original) => {
  //   const identityOriginal = pickBy(original);
  //   const originalKeys = keys(identityOriginal);
  //   const originalValuesIds = map(identityOriginal, (key) => key.toJS ? key.toJS().id : key.id);

  //   return zipObject(originalKeys, originalValuesIds);
  // };

  useEffect(() => {
    if (!isModalOpenDocViewer && urlFile) {
      setUrlFile('');
    }
  }, [isModalOpenDocViewer, urlFile]);

  useEffect(() => {
    if (checkboxClose) {
      dispatch(departmentDocumentsActionAsync.getDepartmentDocumentByIdAsync(params.hashId));
      dispatch(helpersActions.setCheckboxClose(false));
    }
  }, [dispatch, checkboxClose, params.hashId]);

  useEffect(() => function cleanup() {
    dispatch(helpersActions.setSignList([]));
    dispatch(departmentDocumentsAction.setDepartmentDocumentById({
      data: {},
    }));
    dispatch(helpersActions.setPredefinedInputs({}));
    dispatch(helpersActions.setLastObject({}));
    dispatch(helpersActions.setDocumentsType([]));
    dispatch(helpersActions.setAutocompleteGroupOptions({ items: [] }));
    dispatch(departmentDocumentsAction.setDepartmentDocumentEntityList({}));
    dispatch(docTypCategoryAction.setDocumentTypesCategoryData({
      items: [], pending: false, totalCount: 0,
    }));
  }, [dispatch]);

  const handleSubmits = (formData) => {
    const json = formData.toJSON();

    if (!isEmpty(json)) {
      const checkParams = !isEmpty(getDepartmentDocumentById)
        && !isEmpty(params) && !isEmpty(params.hashId);
      // const original = json.original ? json.original.toJSON() : false;
      const placeholders = !isEmpty(json.placeholders) ? json.placeholders.toJS() : {};
      const additionalFilesDocumentClassifier = !isEmpty(json.additionalFilesDocumentClassifier)
        ? json.additionalFilesDocumentClassifier.toJS() : {};
      const responseDocument = json[fieldsAutocomplete.responseDocument];
      const { file } = getDepartmentDocumentById;
      const { additionalFiles } = getDepartmentDocumentById;

      const trademarks = entityList.toJS()[fieldsAutocomplete.trademarks];
      const industrial = entityList.toJS()[fieldsAutocomplete.industrial];
      const inventions = entityList.toJS()[fieldsAutocomplete.inventions];
      const utility = entityList.toJS()[fieldsAutocomplete.utility];
      const orders = entityList.toJS()[fieldsAutocomplete.orders];
      const clients = entityList.toJS()[fieldsAutocomplete.clients];

      const template = json[fieldsAutocomplete.template];

      delete json.responseDocumentInput;
      delete json.choice;
      delete json.file;
      delete json.additionalFiles;
      delete json.additionalDocuments;
      delete json.additionalFilesDocumentClassifier;
      delete json.placeholders;
      dispatch(departmentDocumentsActionAsync[checkParams
        ? 'putDepartmentDocumentByIdAsync'
        : 'postDepartmentDocumentAsync']({
        ...json,
        ...checkParams ? {
          id: getDepartmentDocumentById.id,
        } : {},
        ...!isEmpty(json.documentClassifier) ? {
          documentClassifier: json.documentClassifier.toJS
            ? json.documentClassifier.toJS().id
            : json.documentClassifier.id,
        } : {},
        ...!isEmpty(placeholders) && fieldChoice === 'true' ? {
          placeholders: reduce(Object.keys(placeholders), (acc, item) => {
            acc[replace(item, /\_/g, '.')] = {// eslint-disable-line
              ...getDepartmentDocumentById.placeholders?.[replace(item, /\_/g, '.')],// eslint-disable-line
              value: placeholders[item],
            };
            return acc;
          }, {}),
        } : {},
        ...!isEmpty(template) && fieldChoice === 'true' ? {
          [fieldsAutocomplete.template]: template.toJS ? template.toJS().id : template.id,
        } : {
          [fieldsAutocomplete.template]: null,
        },
        ...fieldChoice === 'false' ? {
          file: !isEmpty(file) ? file.id || file.fileId || file.base64 || file.link || '' : '',
        } : {},
        additionalFiles: !isEmpty(additionalFiles)
          ? map(additionalFiles, (elem) => elem.id || elem.fileId || elem.base64 || elem.link)
          : [],
        ...!isEmpty(additionalFilesDocumentClassifier) ? {
          additionalFilesDocumentClassifier: reduce(additionalFilesDocumentClassifier,
            (acc, value, key) => ([
              ...acc,
              ...!isEmpty(value) ? [{ fileId: key, typeId: value?.id }] : [],
            ]), []),
        } : {},
        ...responseDocument ? {
          [fieldsAutocomplete.responseDocument]: responseDocument.toJS
            ? responseDocument.toJS().id : responseDocument.id,
        } : {
          [fieldsAutocomplete.responseDocument]: null,
        },
        // ...Boolean(original) && {
        //   original: {
        //     ...modifyOriginal(original),
        //     place: original.place === 'true' ? 'office' : 'non-office',
        //   },
        // },
        ...!isEmpty(clients) ? {
          [fieldsAutocomplete.clients]: clients.toJS ? clients.toJS()[0].id : clients[0].id,
        } : {
          [fieldsAutocomplete.clients]: null,
        },
        [fieldsAutocomplete.trademarks]: modifyToIds(trademarks),
        [fieldsAutocomplete.industrial]: modifyToIds(industrial),
        [fieldsAutocomplete.inventions]: modifyToIds(inventions),
        [fieldsAutocomplete.utility]: modifyToIds(utility),
        [fieldsAutocomplete.orders]: modifyToIds(orders),
        ...!isEmpty(lastObject.toJS()) && lastObject.toJS().entityName
          && lastObject.toJS().entityId ? {
            params: lastObject.toJS(),
          } : {},
      }));
    }
  };

  const handleDeleteAdditionalFiles = (id) => () => {
    dispatch(departmentDocumentsAction.setDepartmentDocumentById({
      data: {
        ...getDepartmentDocumentById,
        additionalFiles: filter(getDepartmentDocumentById.additionalFiles,
          (file) => file.fileId !== id),
      },
    }));
    dispatch(change(formName, `additionalFilesDocumentClassifier.${id}`, null));
  };

  const handleDeleteMainFile = () => {
    dispatch(departmentDocumentsAction.setDepartmentDocumentById({
      data: {
        ...getDepartmentDocumentById,
        file: {},
      },
    }));
  };

  const getTitlePage = useMemo(() => {
    if (!isEmpty(getDepartmentDocumentById) && !isUserProfile) {
      return getDepartmentDocumentById.title;
    }

    return t('Add document');
  }, [t, getDepartmentDocumentById]);// eslint-disable-line

  const handleUploadFiles = (field) => (files) => {
    if (!isEmpty(files)) {
      // eslint-disable-next-line no-undef
      if (field === formFields.additionalFiles) {
        const arrayProgress = [];
        forEach(files, (file, i) => {
          dispatch(uploadFileActionAsync.uploadFileAsync({
            file: {
              file: file.data,
              originalName: file.file?.name,
            },
            type: 'additional',
            onUploadProgress: (event) => {
              arrayProgress[i] = Math.round((100 * event.loaded) / event.total);
              const newProgress = Math.round(sum(arrayProgress) / arrayProgress.length);
              setProgressAdditions(newProgress || 0);
              if (newProgress === 100) {
                dispatch(helpersActions.setModal({ isModalOpenDropZone: false }));
                setProgressAdditions(0);
              }
            },
          }));
        });
        return;
      }
      dispatch(uploadFileActionAsync.uploadFileAsync({
        file: {
          file: files[0]?.data,
          originalName: files[0]?.file?.name,
        },
        type: 'main',
        onUploadProgress: (event) => {
          const actualProgress = Math.round((100 * event.loaded) / event.total);
          setProgress(actualProgress);
          if (actualProgress === 100) {
            setProgress(0);
          }
        },
      }));
    }
  };

  useEffect(() => {
    if (!isEmpty(uploadFile.toJS()) && uploadFileType === 'main') {
      dispatch(departmentDocumentsAction.setDepartmentDocumentById({
        data: {
          ...getDepartmentDocumentById,
          file: uploadFile.toJS(),
        },
      }));
      dispatch(uploadFileAction.setUploadFile({
        file: Map(),
      }));
      setProgress(0);
    }
    if (!isEmpty(uploadFile.toJS()) && uploadFileType === 'additional') {
      dispatch(departmentDocumentsAction.setDepartmentDocumentById({
        data: {
          ...getDepartmentDocumentById,
          additionalFiles: [
            ...getDepartmentDocumentById.additionalFiles || [],
            {
              ...uploadFile.toJS(),
            },
          ],
        },
      }));
      const findDocType = find(documentType.toJS(), { id: 27 });
      if (!isEmpty(findDocType)) {
        dispatch(change(
          formName,
          `additionalFilesDocumentClassifier.${uploadFile.toJS().fileId}`,
          findDocType,
        ));
      }
      dispatch(uploadFileAction.setUploadFile({
        file: Map(),
      }));
    }
  }, [dispatch, uploadFile, uploadFileType, documentType, setProgress]);// eslint-disable-line
  useEffect(() => {
    if (accessDocTypeCategoryList) dispatch(docTypCategoryAsyncAction.getListAsync());
  }, [dispatch, accessDocTypeCategoryList]);
  useEffect(() => {
    if (!isModalOpenTableEntities && accessDocTypeList) {
      dispatch(helpersActionAsync.getDocumentsTypeAsync({
        'objectType:typeRequest:isNull': 1,
        ...!isEmpty(entityList.toJS()) ? {
          objectType: [
            ...!isEmpty(entityList.toJS().trademarks) ? [
              1,
            ] : [],
            ...!isEmpty(entityList.toJS().industrial) ? [
              2,
            ] : [],
            ...(!isEmpty(entityList.toJS().inventions) || !isEmpty(entityList.toJS().utility)) ? [
              3,
            ] : [],
          ],
        } : {},
        connector: { 1: 'or' },
        type: fieldType || 'out',
        limit: 10000,
      }));
    }
  }, [dispatch, fieldType, isModalOpenTableEntities, entityList, accessDocTypeList]);

  useEffect(() => {
    if (isEmpty(params) && accessNextNumber) {
      dispatch(departmentDocumentsActionAsync.getNextNumberAsync({
        type: 'out',
        field: 'outNumber',
      }));
    }
  }, [
    dispatch, params, accessNextNumber,
  ]);

  useEffect(() => {
    if (!readyDepartmentDocument && isEmpty(getDepartmentDocumentById)
      && !isEmpty(params) && !isEmpty(params.hashId)) {
      setReadyDepartmentDocument(true);
      dispatch(departmentDocumentsActionAsync.getDepartmentDocumentByIdAsync(params.hashId));
    }
  }, [
    dispatch, getDepartmentDocumentById, params,
    readyDepartmentDocument, setReadyDepartmentDocument,
  ]);

  useEffect(() => {
    if (!isEmpty(params) && getDepartmentDocumentById.type
      && !isEmpty(documentType.toJS()) && !readyDopTypes
      && getDepartmentDocumentById.type !== fieldType) {
      dispatch(helpersActions.setDocumentsType([]));
      setReadyDopTypes(true);
      setTimeout(() => dispatch(change(formName, 'type', getDepartmentDocumentById.type)));
    }
  }, [dispatch, getDepartmentDocumentById, documentType, readyDopTypes, setReadyDopTypes]);// eslint-disable-line

  useEffect(() => {
    if (!readyInitialize && !isEmpty(documentType.toJS())
      && !isEmpty(getDepartmentDocumentById)
      && !isEmpty(params) && !isEmpty(params.hashId)
      && getDepartmentDocumentById.type === fieldType) {
      setReadyInitialize(true);
      let placeholders = {};
      if (!isEmpty(getDepartmentDocumentById.placeholders)) {
        const uniqPlaceholders = uniqBy(Object.values(getDepartmentDocumentById.placeholders), 'label');
        const filterPlaceholders = filter(uniqPlaceholders, (item) => !isArray(item) && !includes(item.label, 'onshow'));
        placeholders = reduce(filterPlaceholders, (acc, item) => {
          acc[replace(item.label, /\./g, '_')] = item.value;
          return acc;
        }, {});
      }
      let additionalFilesDocumentClassifier = {};
      if (!isEmpty(getDepartmentDocumentById.additionalFilesDocumentClassifier)) {
        additionalFilesDocumentClassifier = reduce(
          getDepartmentDocumentById.additionalFilesDocumentClassifier, (acc, item) => {
            const findDocType = find(documentType.toJS(), { id: item.typeId });
            return ({
              ...acc,
              ...!isEmpty(findDocType) ? { [item.fileId]: findDocType } : {},
            }
            );
          }, {},
        );
      }

      initialize({
        ...getDepartmentDocumentById,
        ...!isEmpty(getDepartmentDocumentById.responseDocument) ? {
          responseDocument: getDepartmentDocumentById.responseDocument,
        } : {},
        ...!isEmpty(getDepartmentDocumentById.company) ? {
          company: getDepartmentDocumentById.company.id,
        } : {},
        ...!isEmpty(placeholders) ? {
          placeholders,
        } : {},
        ...!isEmpty(additionalFilesDocumentClassifier) ? {
          additionalFilesDocumentClassifier,
        } : {},
        choice: isEmpty(getDepartmentDocumentById.template)
          && !isEmpty(getDepartmentDocumentById.file) ? 'false' : 'true',
      });
    }
  }, [
    params, initialize, fieldType,
    getDepartmentDocumentById, setReadyInitialize,
    readyInitialize, documentType,
  ]);

  useEffect(() => {
    if (isEmpty(getDepartmentDocumentById) && isEmpty(params)) {
      initialize({
        documentDate: new Date(),
        type: 'out',
        choice: 'true',
        // original: {
        //   place: 'true',
        // },
      });
    }
  }, [
    params, initialize, getDepartmentDocumentById,
  ]);

  const dataItemsGroup = useMemo(() => {
    const sortedCategories = sortBy(docTypeCategoriesData.toJS().items, 'index');
    const documentTypeWithCategory = reduce(sortedCategories, (acc, item) => {
      forEach(item.documentClassifier || [], (elem) => {
        if (elem.type === fieldType) acc.push({ ...elem, groupTitle: item.name });
      });
      return acc;
    }, []);
    const filteredDocType = filter(documentType.toJS(), (item) => !item.category);
    return [
      ...documentTypeWithCategory,
      ...map(filteredDocType, (item) => ({ ...item, groupTitle: t('No category') })),
    ];
  }, [documentType, t, docTypeCategoriesData, fieldType]);

  useEffect(() => {
    if (!isEmpty(dataItemsGroup) && isEmpty(params) && defaultId) {
      const findDocType = find(dataItemsGroup, { id: defaultId });
      setDefaultId(0);
      if (!isEmpty(findDocType)) dispatch(change(formName, 'documentClassifier', findDocType));
    }
  }, [
    dataItemsGroup, dispatch, params, defaultId, setDefaultId,
  ]);
  useEffect(() => {
    if (!isEmpty(getNextNumber) && isEmpty(params)) {
      dispatch(change(formName, 'outNumber', getNextNumber));
      dispatch(departmentDocumentsAction.setDepartmentDocumentNextNumber(''));
    }
  }, [
    getNextNumber, dispatch, params,
  ]);

  useEffect(() => {
    if (isEmpty(params) && !isEmpty(allCompanies.toJS())) {
      dispatch(change(formName, 'company', selectedCompany || 1));
    }
  }, [dispatch, selectedCompany, params, allCompanies]);

  useEffect(() => {
    if ((!isEmpty(predefinedInputs.toJS()) && predefinedInputs.toJS().entity === 'documents'
      && !isEmpty(predefinedInputs.toJS().values)) && isEmpty(params)) {
      forEach(Object.keys(predefinedInputs.toJS().values), (key) => {
        dispatch(change(formName, key, predefinedInputs.toJS().values[key]));
      });
      dispatch(helpersActions.setPredefinedInputs({}));
    }
  }, [
    predefinedInputs, dispatch, params,
  ]);

  const modifyToIdsParams = (items) => (!isEmpty(items)
    ? map(items.toJS ? items.toJS() : items, 'id')
    : []);

  const handleAutocompleteLists = (fieldsName) => {
    getAutocompleteLists(fieldsName, {
      'trademarks.id': modifyToIdsParams(entityList.toJS().trademarks),
      'industrial.id': modifyToIdsParams(entityList.toJS().industrial),
      'inventions.id': modifyToIdsParams(entityList.toJS().inventions),
      'utility.id': modifyToIdsParams(entityList.toJS().utility),
      type: fieldType === 'out' ? 'in' : 'out',
      sortBy: 'createdAt',
      sortDir: 'asc',
      limit: 50,
      page: 1,
    });
  };
  const handleSign = () => {
    dispatch(helpersActions.setPermissionSign(true));
    handleSubmit();
  };
  const linkBackToObject = useMemo(() => {
    if (!isEmpty(lastObject.toJS()) && lastObject.toJS().entityName && lastObject.toJS().entityId) {
      switch (lastObject.toJS().entityName) {
        case 'trademarks': {
          return pageLinks.tradeMarksRoutes.proposal.edit(lastObject.toJS().entityId);
        }
        case 'inventions': {
          return pageLinks.inventions.edit(lastObject.toJS().entityId);
        }
        case 'utility': {
          return pageLinks.utilityModels.edit(lastObject.toJS().entityId);
        }
        case 'industrial': {
          return pageLinks.industrialDesigns.edit(lastObject.toJS().entityId);
        }
        case 'orders': {
          return pageLinks.company.orders.edit(lastObject.toJS().entityId);
        }
        default: {
          return pageLinks.documents.departmentDocuments.all;
        }
      }
    }
    return pageLinks.documents.departmentDocuments.all;
  }, [lastObject]);

  const handleChangeEntity = (item) => {
    if (!isEmpty(item)) {
      dispatch(departmentDocumentsAction.mergeDepartmentDocumentEntityList({
        entityName: item.entity,
        items: item.entity === 'clients'
          ? [item]
          : uniqBy([
            ...!isEmpty(entityList.toJS()) && !isEmpty(entityList.toJS()[item.entity]) ? [
              ...entityList.toJS()[item.entity],
            ] : [],
            item,
          ], 'id'),
      }));
    }
  };

  const handleDeleteEntity = (entityName, id) => {
    if (entityName && id) {
      dispatch(departmentDocumentsAction.mergeDepartmentDocumentEntityList({
        entityName,
        items: [
          ...!isEmpty(entityList.toJS()) && !isEmpty(entityList.toJS()[entityName]) ? [
            ...filter(entityList.toJS()[entityName], (item) => item.id !== id),
          ] : [],
        ],
      }));
    }
  };
  const handleDownloadFile = (file) => {
    dispatch(helpersActionAsync.getDownloadFileAsync(file));
    setFileId(file.fileId);
  };
  const handleViewFile = (file) => {
    dispatch(helpersActionAsync.getPreviewFileByIdAsync(file.fileId));
    setFileId(file.fileId);
  };
  const handleSelectDocuments = (documents) => {
    const filteredDocuments = filter(documents, 'file');
    if (!isEmpty(filteredDocuments)) {
      dispatch(departmentDocumentsAction.setDepartmentDocumentById({
        data: {
          ...getDepartmentDocumentById,
          additionalFiles: [
            ...getDepartmentDocumentById.additionalFiles || [],
            ...map(filteredDocuments, 'file'),
          ],
        },
      }));
      forEach(filteredDocuments, (document) => {
        dispatch(change(
          formName,
          `additionalFilesDocumentClassifier.${document.fileId}`,
          document.documentClassifier,
        ));
      });
    }
    dispatch(helpersActions.setModal({ isModalOpenTableEntities: false }));
  };
  const filteredPlaceholders = useMemo(() => {
    if (!isEmpty(getDepartmentDocumentById.placeholders)) {
      const uniqPlaceholders = uniqBy(Object.values(getDepartmentDocumentById.placeholders), 'label');
      const findPlaceholder = find(uniqPlaceholders, { label: 'content' });
      return [
        ...filter(uniqPlaceholders, (item) => !isArray(item) && !includes(item.label, 'onshow') && item.label !== 'content'),
        ...!isEmpty(findPlaceholder) ? [
          findPlaceholder,
        ] : [],
      ];
    }
    return [];
  }, [getDepartmentDocumentById]);

  return !pendingDepartmentDocumentById ? (
    <>
      <Helmet title={t('Document')} />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h3" gutterBottom display="inline">
          {t(getTitlePage)}
        </Typography>
        <Link to={linkBackToObject}>
          <NavigateBeforeIcon size="small" color="primary" />
          {!isEmpty(lastObject.toJS()) ? t('Get back to business') : t('Up to the list')}
        </Link>
      </Box>

      <Divider my={6} />

      <Form onSubmit={handleSubmit(handleSubmits)}>
        <Card mb={6}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              {t('Basic information')}
            </Typography>

            <Grid container spacing={6}>
              <Grid item xs={12} md={4}>
                <Field
                  name="type"
                  id="type"
                  label={t('Type')}
                  labelId="type"
                  my={2}
                  items={globalDocumentType}
                  fullWidth
                  component={Select}
                  disabled={!accessEdit}
                />

                <Field
                  id="documentDate"
                  labelId="documentDate"
                  name="documentDate"
                  fullWidth
                  label={t('Document date')}
                  component={DatePickers}
                  disabled={!accessEdit}
                />

                <Field
                  name="outNumber"
                  id="outNumber"
                  label={t('Out number')}
                  margin="normal"
                  variant="standard"
                  my={2}
                  fullWidth
                  type="text"
                  component={renderTextField}
                  disabled={!accessEdit}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Field
                  name="documentClassifier"
                  id="documentClassifier"
                  label={t('Document classifier')}
                  labelId="documentClassifier"
                  my={2}
                  margin="normal"
                  fullWidth
                  isGroupBy
                  options={dataItemsGroup}
                  component={AutocompleteField}
                  disabled={!accessEdit}
                />

                <Field
                  id="receivingDate"
                  labelId="receivingDate"
                  name="receivingDate"
                  fullWidth
                  label={t('Received At')}
                  component={DatePickers}
                  disabled={!accessEdit}
                />

                <Field
                  name="inNumber"
                  id="inNumber"
                  label={t('In number')}
                  margin="normal"
                  variant="standard"
                  my={2}
                  fullWidth
                  type="text"
                  component={renderTextField}
                  disabled={!accessEdit}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Field
                  id="title"
                  name="title"
                  label={t('Title')}
                  variant="standard"
                  margin="normal"
                  fullWidth
                  my={2}
                  component={renderTextField}
                  disabled={!accessEdit}
                />

                <Field
                  id="group"
                  name="group"
                  label={t('Group')}
                  variant="standard"
                  margin="normal"
                  fullWidth
                  my={2}
                  disabled
                  component={renderTextField}
                />

                <FieldDepartmentDocuments
                  name={fieldsAutocomplete.responseDocument}
                  formName={formName}
                  getAutocompleteLists={handleAutocompleteLists}
                  getAsyncData={handleGetAsyncData}
                  propsField={{ disabled: !accessEdit }}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  multiline
                  name="notes"
                  id="notes"
                  margin="normal"
                  variant="outlined"
                  label={t('Comment')}
                  my={2}
                  fullWidth
                  component={renderTextField}
                  rows={6}
                  disabled={!accessEdit}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card mb={6}>
          <CardContent>
            <Typography variant="h6" paragraph>
              {t('Connections')}
            </Typography>
            <Grid container spacing={6}>
              <Grid item xs={12} md={6}>
                <AutocompleteGlobalSearch
                  label={t('Add connections')}
                  handleChange={handleChangeEntity}
                  helperText={t('Enter the name of the customer, № case, № application, № registration or name, № order')}
                  disableIcon
                  entities={
                    ['trademarks', 'utility', 'inventions', 'industrial', 'clients', 'orders']
                  }
                  disabled={!accessEdit}
                />
              </Grid>
              {!isEmpty(entityList.toJS()) && (
                map(Object.keys(entityList.toJS()), (entity, index) => {
                  if (!isEmpty(entityList.toJS()[entity])) {
                    return (
                      <Grid item xs={12} key={index}>
                        <TypographySub variant="subtitle1" gutterBottom>
                          {t(entity)}
                        </TypographySub>
                        {map(entityList.toJS()[entity], (item) => (
                          <ChipEntity
                            key={item.id}
                            id={item.id}
                            entity={entity}
                            label={item.label || item.title || item.id}
                            handleDelete={handleDeleteEntity}
                            disabled={!accessEdit}
                          />
                        ))}
                      </Grid>
                    );
                  }
                  return null;
                })
              )}
            </Grid>
          </CardContent>
        </Card>

        <Card mb={6}>
          <CardContent>
            <Typography variant="h6" paragraph>
              {t('Files')}
            </Typography>
            {isModalOpenDocViewer && <DocViewerModal url={urlFile} />}
            {(!isEmpty(getDepartmentDocumentById.additionalFiles)
            || !isEmpty(getDepartmentDocumentById?.file)) ? (
              <>
                <Grid container>
                  {!isEmpty(getDepartmentDocumentById)
                  && !isEmpty(getDepartmentDocumentById.file) && (
                    <Grid item xs={12}>
                      <>
                        <Typography variant="subtitle2">
                          {t('Main file')}
                        </Typography>
                        <List>
                          <ListItem>
                            <Description mr={1} />
                            <Box mr={1}>
                              {getDepartmentDocumentById.file.filename}
                            </Box>
                            <IconButton
                              ml={2}
                              aria-label="Review"
                              size="small"
                              disabled={pendingPreviewFileById}
                              onClick={() => handleViewFile(getDepartmentDocumentById.file)}
                            >
                              {fileId === getDepartmentDocumentById.file?.fileId
                              && pendingPreviewFileById
                                ? <CircularProgress size={24} color="inherit" />
                                : (
                                  <Tooltip title={t('Review')} placement="top-start">
                                    <RemoveRedEyeIcon size="small" />
                                  </Tooltip>
                                )}
                            </IconButton>
                            {accessDownload && (
                              <IconButton
                                mx={2}
                                aria-label={t('Download')}
                                size="small"
                                disabled={pendingDownloadFile}
                                onClick={() => handleDownloadFile(getDepartmentDocumentById.file)}
                              >
                                {fileId === getDepartmentDocumentById.file?.fileId
                                && pendingDownloadFile
                                  ? <CircularProgress size={24} color="inherit" />
                                  : (
                                    <Tooltip title={t('Download')} placement="top-start">
                                      <CloudDownloadIcon size="small" />
                                    </Tooltip>
                                  )}
                              </IconButton>
                            )}
                            {accessEdit && (
                              <IconButton
                                aria-label="Delete"
                                size="small"
                                onClick={handleDeleteMainFile}
                              >
                                <Tooltip title={t('Delete')} placement="top-start">
                                  <CloseIcon fontSize="small" />
                                </Tooltip>
                              </IconButton>
                            )}
                          </ListItem>
                        </List>
                      </>
                    </Grid>
                  )}
                  {(!isEmpty(getDepartmentDocumentById.additionalFiles)) && (
                    <Grid item xs={12}>
                      <>
                        <Typography variant="subtitle2">
                          {t('Additional')}
                        </Typography>
                        <List>
                          {!isEmpty(getDepartmentDocumentById.additionalFiles) && (
                            map(getDepartmentDocumentById.additionalFiles, (file, index) => (
                              <Grid key={index} container spacing={4} alignItems="flex-end">
                                <Grid item xs={12} md={6}>
                                  <Box display="flex" flexDirection="row" alignItems="center">
                                    <Description mr={1} />
                                    <Box mr={1} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                      {file?.filename}
                                    </Box>
                                    <IconButton
                                      ml={2}
                                      aria-label="Review"
                                      size="small"
                                      disabled={pendingPreviewFileById}
                                      onClick={() => handleViewFile(file)}
                                    >
                                      {fileId === file?.fileId && pendingPreviewFileById
                                        ? <CircularProgress size={24} color="inherit" />
                                        : (
                                          <Tooltip title={t('Review')} placement="top-start">
                                            <RemoveRedEyeIcon size="small" />
                                          </Tooltip>
                                        )}
                                    </IconButton>
                                    {accessDownload && (
                                      <IconButton
                                        mx={2}
                                        aria-label={t('Download')}
                                        size="small"
                                        disabled={pendingDownloadFile}
                                        onClick={() => handleDownloadFile(file)}
                                      >
                                        {fileId === file?.fileId && pendingDownloadFile
                                          ? <CircularProgress size={24} color="inherit" />
                                          : (
                                            <Tooltip title={t('Download')} placement="top-start">
                                              <CloudDownloadIcon size="small" />
                                            </Tooltip>
                                          )}
                                      </IconButton>
                                    )}
                                    {accessEdit && (
                                      <IconButton
                                        aria-label="Delete"
                                        size="small"
                                        onClick={handleDeleteAdditionalFiles(file?.fileId)}
                                      >
                                        <Tooltip title={t('Delete')} placement="top-start">
                                          <CloseIcon fontSize="small" />
                                        </Tooltip>
                                      </IconButton>
                                    )}
                                  </Box>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                  <Field
                                    multiple={false}
                                    name={`additionalFilesDocumentClassifier.${file.fileId}`}
                                    id={`additionalFilesDocumentClassifier.${file.fileId}`}
                                    labelId={`additionalFilesDocumentClassifier.${file.fileId}`}
                                    component={AutocompleteField}
                                    type="text"
                                    label={t('Type')}
                                    margin="none"
                                    fullWidth
                                    options={documentType.toJS()}
                                    disabled={!accessEdit}
                                    // required
                                    // validate={validators.required}
                                  />
                                </Grid>
                              </Grid>
                            ))
                          )}
                        </List>
                      </>
                    </Grid>
                  )}
                </Grid>
              </>
              ) : (
                <Typography variant="subtitle1">
                  {t('No files yet')}
                </Typography>
              )}
          </CardContent>
        </Card>

        <Card mb={6}>
          <CardContent>
            <Typography variant="h6" paragraph>
              {t('Contents')}
            </Typography>

            <Grid item xs={12}>
              <Field
                aria-label={formFields.choice}
                name={formFields.choice}
                component={RadioButton}
                disabled={!accessEdit}
                row
              >
                <FormControlLabel value="true" control={<Radio />} label={t('Create')} />
                <FormControlLabel value="false" control={<Radio />} label={t('Download')} />
              </Field>
            </Grid>
            {fieldChoice === 'true' ? (
              <>
                <Grid item xs={12} md={4}>
                  <FieldDocumentTemplate
                    name={fieldsAutocomplete.template}
                    getAutocompleteLists={getAutocompleteLists}
                    getAsyncData={handleGetAsyncData}
                    formName={formName}
                    propsField={{ disabled: !accessEdit }}
                  />
                </Grid>
                {!isEmpty(filteredPlaceholders) && (
                  <Grid container spacing={4}>
                    {map(filteredPlaceholders, (placeholder, index) => (
                      <Grid
                        item
                        xs={12}
                        {...placeholder.label !== 'content' ? { lg: 3, md: 6 } : {}}
                        key={index}
                      >
                        <Field
                          name={`placeholders.${replace(placeholder.label, /\./g, '_')}`}
                          id={`placeholders.${replace(placeholder.label, /\./g, '_')}`}
                          label={placeholder.label}
                          margin="normal"
                          fullWidth
                          type="text"
                          disabled={!accessEdit}
                          component={!isEmpty(placeholder.options) && isArray(placeholder.options)
                            ? Select : renderTextField}
                          {
                            ...!isEmpty(placeholder.options) && isArray(placeholder.options) ? {
                              labelId: `placeholders.${placeholder.label}`,
                              items: map(placeholder.options,
                                (item) => ({ value: item, name: item })),
                            } : {}
                          }
                          {...placeholder.label !== 'content' ? {
                            variant: 'standard',
                          } : {
                            variant: 'outlined',
                            multiline: true,
                            rows: 5,
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                )}
              </>
            ) : (
              <Box mt={2} mb={2}>
                {progress ? (
                  <ProgressBar value={progress} />
                ) : (
                  <>
                    {accessEdit && (
                      <DropzoneAreaBase
                        showPreviewsInDropzone={false}
                        showAlerts={false}
                        clearOnUnmount
                        maxFileSize={21000000}
                        filesLimit={1}
                        mt={4}
                        onAdd={handleUploadFiles(formFields.file)}
                      />
                    )}
                  </>
                )}
              </Box>
            )}

            {accessEdit && (
              <Box mt={4}>
                <ButtonGroup color="primary" aria-label="contained primary button group">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => dispatch(helpersActions.setModal({
                      isModalOpenTableEntities: true,
                    }))}
                  >
                    {t('SELECT ADDITIONS')}
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => dispatch(helpersActions.setModal({
                      isModalOpenDropZone: true,
                    }))}
                  >
                    {t('DOWNLOAD ADDITIONS')}
                  </Button>
                </ButtonGroup>
              </Box>
            )}
            {isModalOpenTableEntities && (
              <TableEntitiesModal
                handleSend={handleSelectDocuments}
              />
            )}

            <DropZoneAreaModal
              dropZoneKey={Math.random()}
              handleChange={handleUploadFiles(formFields.additionalFiles)}
              progress={progressAdditions}
            />

          </CardContent>
        </Card>

        {!isNil(params.hashId) && (
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" paragraph>
                {t('Calendar')}
              </Typography>
              <ProposalCalendar
                match={match}
                actionId="documents.id:typeRequest:="
                actionName="documents"
              />
            </CardContent>
          </Card>
        )}

        {accessCompanyList && (
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {t('Legal person')}
              </Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <Field
                    name="company"
                    id="company"
                    label={t('Legal person')}
                    labelId="company"
                    my={1}
                    items={allCompanies.toJS()}
                    fullWidth
                    component={Select}
                    disabled={!accessEdit}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}

        {!isEmpty(signList.toJS()) && !isNil(params.hashId) && (
          <Card mb={6}>
            <CardContent>
              <Typography variant="h2" gutterBottom>
                {t('Signed')}
              </Typography>
              {map(signList.toJS(), (sign, index) => (
                <Typography key={index} gutterBottom>
                  <Box
                    component="span"
                    fontWeight="fontWeightBold"
                  >
                    {sign.signer || sign.users.name}
                    ,
                    {sign.csk}
                  </Box>
                  {' '}
                  (
                  {sign.date}
                  )
                </Typography>
              ))}
            </CardContent>
          </Card>
        )}

        {!isNil(params.hashId) && !isEmpty(getDepartmentDocumentById.outDocs) && (
          <>
            {map(getDepartmentDocumentById.outDocs, (doc, index) => (
              doc.sendingLog?.[0]?.receipt ? (
                <Card mb={6} key={index}>
                  <CardContent>
                    <Typography variant="h2" gutterBottom>
                      {t('Sending')}
                    </Typography>
                    <Box>
                      <Typography gutterBottom>
                        <Box component="span" fontWeight="fontWeightBold">{`${t('Sent')}: `}</Box>
                        {doc.sentAt}
                      </Typography>
                      {!isEmpty(doc.sendingLog) && (
                        <>
                          <Typography gutterBottom>
                            <Box component="span" fontWeight="fontWeightBold">{`${t('Status')}: `}</Box>
                            {doc.sendingLog?.[0]?.status}
                          </Typography>
                          <Typography gutterBottom>
                            <Box component="span" fontWeight="fontWeightBold">{`${t('Result')}: `}</Box>
                            {doc.sendingLog?.[0]?.message}
                          </Typography>
                        </>
                      )}
                      {!isEmpty(doc.files) && (
                        <Typography gutterBottom>
                          <Box component="span" fontWeight="fontWeightBold">{`${t('Receipt')}: `}</Box>
                          <IconButton
                            ml={2}
                            aria-label="Review"
                            size="small"
                            disabled={urlFile}
                            onClick={() => {
                              setUrlFile(doc.sendingLog?.[0]?.receipt);
                              dispatch(helpersActions.setModal({ isModalOpenDocViewer: true }));
                            }}
                          >
                            <Tooltip title={t('Review')} placement="top-start">
                              <RemoveRedEyeIcon size="small" />
                            </Tooltip>
                          </IconButton>
                        </Typography>
                      )}
                    </Box>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => dispatch(
                        departmentDocumentsActionAsync.getDepartmentDocumentByIdAsync(
                          params.hashId,
                        ),
                      )}
                    >
                      <UpdateIcon size="small" mr={2} />
                      {`   ${t('UPDATE')}`}
                    </Button>
                  </CardContent>
                </Card>
              ) : null
            ))}
          </>
        )}

        {accessEdit && (
          <Box mt={3}>
            <Button
              mr={2}
              variant="contained"
              color="primary"
              type="submit"
              disabled={!valid || pendingPutDepartmentDocumentById || pendingPostDepartmentDocument}
            >
              {pendingPutDepartmentDocumentById || pendingPostDepartmentDocument
                ? <CircularProgress size={25} color="inherit" />
                : t('SAVE')}
            </Button>
          </Box>
        )}
      </Form>
      {accessEdit && (
        <Box mt={3}>
          {(isEmpty(signList.toJS()) || isNil(params.hashId)) && (
            <>
              <Button
                disabled={isNil(params.hashId)}
                mr={2}
                variant="contained"
                color="primary"
                onClick={handleSign}
              >
                {t('SIGN')}
              </Button>
              <Sign
                files={[
                  getDepartmentDocumentById?.file?.fileId,
                  ...getDepartmentDocumentById?.additionalFiles
                    ? map(getDepartmentDocumentById?.additionalFiles, 'fileId')
                    : [],
                ]}
                type="documents"
                dataAllFiles={[{
                  id: params.hashId,
                  files: [
                    getDepartmentDocumentById?.file?.fileId,
                    ...getDepartmentDocumentById?.additionalFiles
                      ? map(getDepartmentDocumentById?.additionalFiles, 'fileId')
                      : [],
                  ],
                }]}
              />
            </>
          )}
          <Send
            disabledButton={isNil(params.hashId)}
            type="documents"
            entityIds={[params.hashId]}
          />
        </Box>
      )}
    </>
  ) : <Loader />;
}

DepartmentDocument.displayName = 'DepartmentDocument';

DepartmentDocument.propTypes = {
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  getDepartmentDocumentById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingDepartmentDocumentById: PropTypes.bool.isRequired,
  pendingPutDepartmentDocumentById: PropTypes.bool.isRequired,
  pendingDepartmentDocument: PropTypes.bool.isRequired,
  pendingPostDepartmentDocument: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  documentType: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  match: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  signList: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  isModalOpenTableEntities: PropTypes.bool.isRequired,
  fieldChoice: PropTypes.string,
  isUserProfile: PropTypes.bool,
  uploadFile: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  uploadFileType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  getNextNumber: PropTypes.string.isRequired,
  predefinedInputs: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  lastObject: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  entityList: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  selectedCompany: PropTypes.string.isRequired,
  allCompanies: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  fieldType: PropTypes.string,
  pendingDownloadFile: PropTypes.bool.isRequired,
  pendingPreviewFileById: PropTypes.bool.isRequired,
  isModalOpenDocViewer: PropTypes.bool.isRequired,
  docTypeCategoriesData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  checkboxClose: PropTypes.bool.isRequired,
};

DepartmentDocument.defaultProps = {
  match: {},
  isUserProfile: false,
  uploadFileType: null,
  fieldType: 'out',
  fieldChoice: '',
};

function mapStateToProps(state) {
  return {
    getDepartmentDocumentById: selectors.departmentDocuments.getDepartmentDocumentById(state),
    pendingDepartmentDocumentById:
      selectors.departmentDocuments.pendingDepartmentDocumentById(state),
    pendingPutDepartmentDocumentById:
      selectors.departmentDocuments.pendingPutDepartmentDocumentById(state),
    pendingDepartmentDocument: selectors.departmentDocuments.pendingDepartmentDocument(state),
    pendingPostDepartmentDocument:
      selectors.departmentDocuments.pendingPostDepartmentDocument(state),
    entityList: selectors.departmentDocuments.entityList(state),
    documentType: selectors.helpers.documentsType(state),
    fieldChoice: selectors.form.getFormValues(state, formName).get(formFields.choice),
    fieldType: selectors.form.getFormValues(state, formName).get('type'),
    signList: selectors.helpers.signList(state),
    uploadFile: selectors.uploadFile.uploadFile(state),
    uploadFileType: selectors.uploadFile.uploadFileType(state),
    getNextNumber: selectors.departmentDocuments.getNextNumber(state),
    predefinedInputs: selectors.helpers.predefinedInputs(state),
    lastObject: selectors.helpers.lastObject(state),
    pendingDownloadFile: selectors.helpers.pendingDownloadFile(state),
    selectedCompany: selectors.helpers.selectedCompany(state),
    isModalOpenTableEntities: selectors.helpers.isModalOpenTableEntities(state),
    allCompanies: selectors.companiesTable.allCompanies(state),
    pendingPreviewFileById: selectors.helpers.pendingPreviewFileById(state),
    isModalOpenDocViewer: selectors.helpers.isModalOpenDocViewer(state),
    docTypeCategoriesData: selectors.documentTypesCategory.documentTypesCategoryData(state),
    checkboxClose: selectors.helpers.checkboxClose(state),
  };
}

export default compose(
  reduxForm({
    form: 'DepartmentDocument',
  }),
  connect(mapStateToProps, null),
)(DepartmentDocument);
