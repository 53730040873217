// Core
import {
  useCallback, useEffect, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

// lodash
import isEmpty from 'lodash/isEmpty';

// actions
import asyncActions from '../../../../../engine/core/pendingActions/saga/asyncAction';
import pendingActions from '../../../../../engine/core/pendingActions/action';

// config
import selectors from '../../../../../engine/config/selectors';
import { listEntityName } from '../../../../../engine/config/listTablesName';

// Hook
import { useParamsRequest } from '../../../../../ui/_hooks/useParamsRequest';

const entityName = listEntityName.pendingActions;

export function useActionsCalendar(blockRetrievalRequest) {
  const filters = useSelector(selectors.pendingActions.filters);
  const reload = useSelector(selectors.pendingActions.reload);
  const userInfo = useSelector(selectors.user.userInfo);
  const routeKey = useSelector(selectors.router.key);
  const path = useSelector(selectors.router.pathname);
  const fieldsEntity = useSelector(selectors.fieldsSetting.fieldsEntity);
  const dispatch = useDispatch();
  const { paramsByFilter } = useParamsRequest({ filters, entityName });

  const getListAsync = useCallback(() => {
    const params = {
      ...paramsByFilter,
    };
    dispatch(asyncActions.getPendingActionsAsync(params));
  }, [paramsByFilter, dispatch]);

  const getInitialStateAsync = useCallback(() => {
    dispatch(asyncActions.getInitialStateAsync(path));
    dispatch(pendingActions.setPendingActionById({
      data: {},
    }));
  }, [dispatch, path]);

  const getInitialState = useRef(true);

  useEffect(() => {
    if (blockRetrievalRequest) {
      if (getInitialState.current && userInfo?.id) {
        getInitialStateAsync();
        getInitialState.current = false;
      }

      if (reload && !isEmpty(fieldsEntity.toJS())) {
        getListAsync();
      }
    }
  }, [getListAsync, blockRetrievalRequest,
    getInitialStateAsync, userInfo,
    reload, fieldsEntity, routeKey]);
}
