// Core
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

// Lodash
import {
  map, isEmpty,
} from 'lodash';

// parts
import DxTable from '../../../ui/Table/DxTable';

// actions
import actions from '../../../engine/core/paymentReceivers/action';
import asyncActions from '../../../engine/core/paymentReceivers/saga/asyncAction';
import helpersActions from '../../../engine/core/helpers/action';

// config
import selectors from '../../../engine/config/selectors';
import ConfirmModal from '../../../components/ConfirmModal';

import { listTablesName, listEntityName } from '../../../engine/config/listTablesName';
import accessList from '../../../engine/config/accessList';

const tableName = listTablesName.paymentReceivers;
const entityName = listEntityName.paymentReceivers;

function PaymentReceiversTable(props) {
  const {
    pendingDelete, data, totalCount, pending, currentPage, pageSize, checkboxes,
    filters, sorting, isModalOpen, columnWidths, columnOrder, hiddenColumnNames,
  } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const columns = [
    { name: 'id', title: t('ID') },
    { name: 'name', title: t('Name') },
    { name: 'code', title: t('Code') },
    { name: 'bank', title: t('Bank') },
    { name: 'mfo', title: t('MFO') },
    { name: 'iban', title: t('IBAN') },
  ];

  const tableColumnExtensions = [
    { columnName: 'id', width: 50 },
    { columnName: 'name', width: '20rem' },
    { columnName: 'code', width: '15rem' },
    { columnName: 'bank', width: '15rem' },
    { columnName: 'mfo', width: '15rem' },
    { columnName: 'iban', width: '20rem' },
  ];

  // Paging
  const onCurrentPage = (page) => dispatch(actions.setCurrentPage(page));
  const onPageSize = (size) => dispatch(actions.setPageSize(size));

  // Filtering
  const setFilters = (getFilters) => dispatch(actions.setFilters(getFilters));

  // Sorting
  const onSortingChange = (getSorting) => dispatch(actions.setSorting(getSorting));
  const onColumnWidthsChange = (getColumnWidths) => dispatch(
    actions.setColumnWidths(getColumnWidths),
  );
  const onColumnOrderChange = (getColumnOrder) => dispatch(
    actions.setColumnOrder(getColumnOrder),
  );
  const onHiddenColumnNamesChange = (getHiddenColumnNames) => dispatch(
    actions.setHiddenColumnNames(getHiddenColumnNames),
  );

  return (
    <>
      <DxTable
        name="PaymentReceiversTable"
        isLoading={pending}
        columns={columns}
        rows={data.toJS().items}
        totalCount={totalCount}
        pageSize={pageSize}
        // checkboxes ------------------------
        tableName={tableName}
        reloadTable={actions.setReload}
        selection={checkboxes.toJS()}
        onSelectionChange={(checkboxSelect) => {
          dispatch(helpersActions.setCheckbox({
            tableName,
            checkboxes: checkboxSelect,
          }));
        }}
        // ------------------------
        currentPage={currentPage}
        onPageSize={onPageSize}
        onCurrentPage={onCurrentPage}
        filtersAction={setFilters}
        filters={filters}
        entityName={entityName}
        sorting={sorting}
        onSortingChange={onSortingChange}
        isModalOpen={isModalOpen}
        tableColumnExtensions={!isEmpty(columnWidths) && columnWidths.length === columns.length
          ? columnWidths : tableColumnExtensions}
        onColumnWidthsChange={onColumnWidthsChange}
        columnOrder={!isEmpty(columnOrder) ? columnOrder : map(columns, 'name')}
        onColumnOrderChange={onColumnOrderChange}
        hiddenColumnNames={hiddenColumnNames}
        onHiddenColumnNamesChange={onHiddenColumnNamesChange}
        accessEdit={accessList.paymentReceivers_put}
        accessDelete={accessList.paymentReceivers_delete}
      />

      <ConfirmModal
        buttonSendText="DELETE"
        pending={pendingDelete}
        handleSend={() => {
          dispatch(asyncActions.deletePaymentReceivers());
        }}
      />
    </>
  );
}

PaymentReceiversTable.displayName = 'PaymentReceiversTable';

PaymentReceiversTable.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingDelete: PropTypes.bool.isRequired,
  pending: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalCount: PropTypes.number,
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  sorting: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  checkboxes: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  columnWidths: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  columnOrder: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  hiddenColumnNames: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
};

PaymentReceiversTable.defaultProps = {
  totalCount: 0,
};

function mapStateToProps(state) {
  return {
    data: selectors.paymentReceivers.paymentReceiversData(state),
    pending: selectors.paymentReceivers.pending(state),
    pendingDelete: selectors.paymentReceivers.pendingDeletePaymentReceivers(state),

    // Paging
    totalCount: selectors.paymentReceivers.totalCount(state),
    currentPage: selectors.paymentReceivers.currentPage(state),
    pageSize: selectors.paymentReceivers.pageSize(state),

    // Filtering
    filters: selectors.paymentReceivers.filters(state),

    sorting: selectors.paymentReceivers.sorting(state),

    checkboxes: selectors.helpers.getCheckboxes(state, tableName),

    // ColumnWidths
    columnWidths: selectors.paymentReceivers.columnWidths(state),
    columnOrder: selectors.paymentReceivers.columnOrder(state),
    hiddenColumnNames: selectors.paymentReceivers.hiddenColumnNames(state),

    isModalOpen: selectors.paymentReceivers.isModalOpen(state),
  };
}

function mapDispatchToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(PaymentReceiversTable);
