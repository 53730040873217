export default Object.freeze({
  SET_NOTIFICATIONS_DATA: 'SET_NOTIFICATIONS_DATA',
  SET_NOTIFICATIONS_CURRENT_PAGE: 'SET_NOTIFICATIONS_CURRENT_PAGE',
  SET_NOTIFICATIONS_PAGE_SIZE: 'SET_NOTIFICATIONS_PAGE_SIZE',
  SET_NOTIFICATIONS_FILTERS: 'SET_NOTIFICATIONS_FILTERS',
  SET_NOTIFICATIONS_SORTING: 'SET_NOTIFICATIONS_SORTING',
  SET_NOTIFICATIONS_INITIAL_STATE: 'SET_NOTIFICATIONS_INITIAL_STATE',
  SET_NOTIFICATIONS_RELOAD: 'SET_NOTIFICATIONS_RELOAD',
  SET_DELETE_NOTIFICATION: 'SET_DELETE_NOTIFICATION',
  SET_NOTIFICATION_BY_ID: 'SET_NOTIFICATION_BY_ID',
  SET_NOTIFICATIONS_AUTOCOMPLETE_OPTIONS: 'SET_NOTIFICATIONS_AUTOCOMPLETE_OPTIONS',
  SET_PENDING_NOTIFICATIONS_AUTOCOMPLETE_OPTIONS: 'SET_PENDING_NOTIFICATIONS_AUTOCOMPLETE_OPTIONS',
  SET_NOTIFICATIONS_COLUMN_WIDTHS: 'SET_NOTIFICATIONS_COLUMN_WIDTHS',
  SET_NOTIFICATIONS_COLUMN_ORDER: 'SET_NOTIFICATIONS_COLUMN_ORDER',
  SET_NOTIFICATIONS_HIDDEN_COLUMN_NAMES: 'SET_NOTIFICATIONS_HIDDEN_COLUMN_NAMES',
});
