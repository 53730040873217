// Core
import { all, call, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetSendingWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callPostOutDocsSendWorker,
  callGetDownloadFileWorker,
} from './workers';
import types from '../types';

function* watchGetSending() {
  yield takeEvery(asyncTypes.GET_SENDING_ASYNC, callGetSendingWorker);
}
function* watchGetInitialState() {
  yield takeEvery(
    asyncTypes.GET_SENDING_INITIAL_STATE_ASYNC,
    callGetInitialStateWorker,
  );
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_SENDING_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_SENDING_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_SENDING_FILTERS, callSetToLocalDataWorker);
}

function* watchSetSorting() {
  yield takeEvery(types.SET_SENDING_SORTING, callSetToLocalDataWorker);
}
function* watchSetColumnWidths() {
  yield takeEvery(types.SET_SENDING_COLUMN_WIDTHS, callSetToLocalDataWorker);
}
function* watchSetColumnOrder() {
  yield takeEvery(types.SET_SENDING_COLUMN_ORDER, callSetToLocalDataWorker);
}
function* watchSetHiddenColumnNames() {
  yield takeEvery(types.SET_SENDING_HIDDEN_COLUMN_NAMES, callSetToLocalDataWorker);
}

function* watchOutDocsPostSendAsync() {
  yield takeEvery(asyncTypes.POST_OUT_DOCS_SEND_ASYNC, callPostOutDocsSendWorker);
}
function* watchGetDownloadFileAsync() {
  yield takeEvery(asyncTypes.GET_DOWNLOAD_FILE_OUT_DOCS_ASYNC, callGetDownloadFileWorker);
}

export function* watchersSendingTable() {
  yield all([
    call(watchGetSending),
    call(watchSetCurrentPage),
    call(watchSetPageSize),
    call(watchSetFilters),
    call(watchGetInitialState),
    call(watchSetSorting),
    call(watchOutDocsPostSendAsync),
    call(watchGetDownloadFileAsync),
    call(watchSetColumnWidths),
    call(watchSetColumnOrder),
    call(watchSetHiddenColumnNames),
  ]);
}
