// Core
import { select, put } from 'redux-saga/effects';

// Engine
import localData from '../../../../config/localData';
import selectors from '../../../../config/selectors';
import helpersActionsAsync from '../asyncAction';

export function* callSetSaveFrontendSettingWorker() {
  const pathname = yield select(selectors.router.pathname);
  const { id } = yield select(selectors.user.userInfo);
  const length = yield localData.getLength();
  const settings = yield localData.getSettings(pathname, length);
  yield put(helpersActionsAsync.putMultipleAsync({
    body: {
      entityName: 'users',
      entityIds: [id],
      fields: {
        frontendSettings: JSON.stringify(settings),
      },
    },
  }));
}
