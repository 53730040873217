// Core
import { apply, put } from 'redux-saga/effects';

// lodash
import { keys, has } from 'lodash';

// config
import { api } from '../../../../config/api';
import { listEntityName } from '../../../../config/listTablesName';

// actions
import actions from '../../action';

const entity = listEntityName.utility;

export function* callGetAutocompleteOptionsWorker({ payload: params }) {
  const { setAutocompleteOptions, setPendingAutocompleteOptions } = actions;

  yield put(setPendingAutocompleteOptions(true));

  const name = keys(params)[0];
  const response = yield apply(api,
    api.utilityModels.getProposalAutocomplete, [{ ...params, entity }]);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setAutocompleteOptions({ [name]: [] }));
    } else {
      const { items } = data;
      yield put(setAutocompleteOptions({ [name]: items }));
    }
  }

  yield put(setPendingAutocompleteOptions(false));
}
