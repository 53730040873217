// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const userActionAsync = Object.freeze({
  signInAsync(userData) {
    return actionCreator(asyncTypes.SIGN_IN_ASYNC, userData);
  },
  getUserInfoAsync() {
    return actionCreator(asyncTypes.GET_USER_INFO_ASYNC);
  },
  authCleanUp() {
    return actionCreator(asyncTypes.AUTH_CLEAN_UP);
  },
  logOutAsync(serverLogOut) {
    return actionCreator(asyncTypes.LOG_OUT_ASYNC, serverLogOut);
  },
  resetPasswordAsync(data) {
    return actionCreator(asyncTypes.RESET_PASSWORD_ASYNC, data);
  },
  changeCurrentLanguage(data) {
    return actionCreator(asyncTypes.SET_USER_LANGUAGE_ASYNC, data);
  },
});

export default userActionAsync;
