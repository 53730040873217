// Core
import { call, put } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';

// helpers
import { setSuccessMessage, setErrorMessage } from '../../../../../ui/_helpers/setNotificationMessage';

// config
import { api } from '../../../../config/api';
import i18n from '../../../../init/i18n';
import { messageRequestSuccess } from '../../../../config/globalConfig';

export function* callPutSettingWorker({ payload }) {
  const response = yield call(api.settings.putSettingById, payload);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      yield put(setSuccessMessage(i18n.t(messageRequestSuccess), response.status.toString()));
    }
  }
}
