// Core
import { Map } from 'immutable';

// Instruments
import types from './types';
import { authService } from '../../config/api';
import { types as typesUI } from '../ui/types';

const userInfo = authService.getUserInfo() || {};

const initialState = Map({
  userInfo: {
    ...userInfo,
  },
  pending: false,
  pendingResetPassword: false,
});

export const stateKeys = Object.freeze({
  controller: 'user',
  userInfo: 'userInfo',
  pendingResetPassword: 'pendingResetPassword',
});

export const userReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_USER_INFO: {
      return state.mergeIn([stateKeys.userInfo], payload);
    }
    case types.SET_USER_PENDING: {
      return state.set('pending', payload);
    }
    case types.SET_USER_PENDING_RESET_PASSWORD: {
      return state.set(stateKeys.pendingResetPassword, payload);
    }
    case typesUI.SET_INITIAL_APPLICATION_STATE: {
      return state.setIn([stateKeys.userInfo], {});
    }
    default:
      return state;
  }
};
