// Core
import { put, select } from 'redux-saga/effects';

// lodash
import map from 'lodash/map';
import find from 'lodash/find';
import reduce from 'lodash/reduce';
import actions from '../../action';

// config
import selectors from '../../../../config/selectors';

export function* callSetSelectionsWorker({ payload }) {
  const {
    setSelections,
  } = actions;
  const {
    selected,
  } = payload;
  const paymentAllocationsData = yield select(selectors.paymentAllocations.paymentAllocationsData);

  const getSum = (selections) => {
    const fullSectionsData = map(selections.fullSectionsData, (data) => data.charge.sum);
    return reduce(fullSectionsData, (sum, n) => sum + n, 0);
  };
  const joinFullSelectionsData = (selections) => {
    const found = (id) => find(paymentAllocationsData.toJS().items, { id });
    return map(selections.all, (id) => found(id));
  };

  yield put(setSelections({
    all: selected,
  }));

  const paymentAllocationsSelections = yield select(selectors.paymentAllocations.selections);
  yield put(setSelections({
    chosen: paymentAllocationsSelections.toJS().all.length,
    fullSectionsData: joinFullSelectionsData(paymentAllocationsSelections.toJS()),
  }));

  const updatePaymentAllocationsSelections = yield select(selectors.paymentAllocations.selections);
  yield put(setSelections({
    sum: getSum(updatePaymentAllocationsSelections.toJS()),
  }));
}
