// Core
import { all, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetCurrenciesWorker,
  callPutCurrencyByIdWorker,
  callPostCurrencyWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callDeleteCurrencyWorker,
  callGetAutocompleteOptionsWorker,
  callPostCurrencyCalcWorker,
} from './workers';
import types from '../types';

function* watchGetCurrencies() {
  yield takeEvery(asyncTypes.GET_CURRENCIES_ASYNC, callGetCurrenciesWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_CURRENCIES_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchPostCurrency() {
  yield takeEvery(asyncTypes.POST_CURRENCY_ASYNC, callPostCurrencyWorker);
}
function* watchPostCurrencyCalc() {
  yield takeEvery(asyncTypes.POST_CURRENCY_CALC_ASYNC, callPostCurrencyCalcWorker);
}
function* watchPutCurrencyById() {
  yield takeEvery(asyncTypes.PUT_CURRENCY_BY_ID_ASYNC, callPutCurrencyByIdWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_CURRENCIES_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_CURRENCIES_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_CURRENCIES_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_CURRENCIES_SORTING, callSetToLocalDataWorker);
}
function* watchSetColumnWidths() {
  yield takeEvery(types.SET_CURRENCIES_COLUMN_WIDTHS, callSetToLocalDataWorker);
}
function* watchSetColumnOrder() {
  yield takeEvery(types.SET_CURRENCIES_COLUMN_ORDER, callSetToLocalDataWorker);
}
function* watchSetHiddenColumnNames() {
  yield takeEvery(types.SET_CURRENCIES_HIDDEN_COLUMN_NAMES, callSetToLocalDataWorker);
}
function* watchDeleteCurrency() {
  yield takeEvery(asyncTypes.DELETE_CURRENCY_ASYNC, callDeleteCurrencyWorker);
}
function* watchGetAutocompleteOptions() {
  yield takeEvery(
    asyncTypes.GET_CURRENCIES_AUTOCOMPLETE_OPTIONS_ASYNC,
    callGetAutocompleteOptionsWorker,
  );
}

export function* watchersCurrencies() {
  yield all([
    watchGetCurrencies(),
    watchGetInitialState(),
    watchPostCurrency(),
    watchPutCurrencyById(),
    watchSetCurrentPage(),
    watchSetPageSize(),
    watchSetFilters(),
    watchSetSorting(),
    watchSetColumnWidths(),
    watchSetColumnOrder(),
    watchSetHiddenColumnNames(),
    watchDeleteCurrency(),
    watchGetAutocompleteOptions(),
    watchPostCurrencyCalc(),
  ]);
}
