// Core
export const formName = 'ImportObjects';

export const formFields = Object.freeze({
  client: 'client',
  tradeMarksApplicationNumber: 'tradeMarksApplicationNumber',
  tradeMarksRegistrationNumber: 'tradeMarksRegistrationNumber',
  industrialDesignsApplicationNumber: 'industrialDesignsApplicationNumber',
  industrialDesignsRegistrationNumber: 'industrialDesignsRegistrationNumber',
  utilityModelsApplicationNumber: 'utilityModelsApplicationNumber',
  utilityModelsRegistrationNumber: 'utilityModelsRegistrationNumber',
  inventionsApplicationNumber: 'inventionsApplicationNumber',
  inventionsRegistrationNumber: 'inventionsRegistrationNumber',
});
