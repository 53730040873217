export default Object.freeze({
  SET_PAYMENT_RECEIVERS_DATA: 'SET_PAYMENT_RECEIVERS_DATA',
  SET_PAYMENT_RECEIVERS_CURRENT_PAGE: 'SET_PAYMENT_RECEIVERS_CURRENT_PAGE',
  SET_PAYMENT_RECEIVERS_PAGE_SIZE: 'SET_PAYMENT_RECEIVERS_PAGE_SIZE',
  SET_PAYMENT_RECEIVERS_INITIAL_STATE: 'SET_PAYMENT_RECEIVERS_INITIAL_STATE',
  SET_PAYMENT_RECEIVERS_RELOAD: 'SET_PAYMENT_RECEIVERS_RELOAD',
  SET_PAYMENT_RECEIVERS_MODAL_OPEN: 'SET_PAYMENT_RECEIVERS_MODAL_OPEN',
  SET_PAYMENT_RECEIVERS_BY_ID: 'SET_PAYMENT_RECEIVERS_BY_ID',
  SET_DELETE_PAYMENT_RECEIVERS: 'SET_DELETE_PAYMENT_RECEIVERS',
  SET_PAYMENT_RECEIVERS_FILTER: 'SET_PAYMENT_RECEIVERS_FILTER',
  SET_PAYMENT_RECEIVERS_SORTING: 'SET_PAYMENT_RECEIVERS_SORTING',
  SET_PAYMENT_RECEIVERS_COLUMN_WIDTHS: 'SET_PAYMENT_RECEIVERS_COLUMN_WIDTHS',
  SET_PAYMENT_RECEIVERS_COLUMN_ORDER: 'SET_PAYMENT_RECEIVERS_COLUMN_ORDER',
  SET_PAYMENT_RECEIVERS_HIDDEN_COLUMN_NAMES: 'SET_PAYMENT_RECEIVERS_HIDDEN_COLUMN_NAMES',
});
