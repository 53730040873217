/* eslint-disable */
// Core
import React, { memo, useEffect } from 'react';
import { change, resetSection } from 'redux-form/immutable';
import { useDispatch } from 'react-redux';
import { List, Map } from 'immutable';

// lodash
import {
  findIndex, find, map, isEqual, isEmpty,
  isNull, filter,
} from 'lodash';

// UI
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  TextField, CircularProgress, Checkbox,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

// Icons
import {
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon, CheckBox as CheckBoxIcon,
} from '@material-ui/icons';
import { delayCallTimeout } from '../../engine/config/globalConfig';

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

const StyledAutocomplete = withStyles({
  groupLabel: {
    fontWeight: 'bold',
  },
})(Autocomplete);

function AutocompleteField(props) {
  const {
    multiple, label, getAsyncData, options: getOptions, fieldValue,
    loading, getSelectOption, input, margin, defaultValue, handleChange,
    meta: {touched, invalid, error}, id,

    handleOnFocus, handleInputChange, meta, changeToStore, isGroupBy,
    disableLabel, required, disabled,
    ...rest
  } = props;

  const dispatch = useDispatch();
  const options = filter(getOptions,(option) => option?.label || option?.title || option?.name || option?.id);

  const [inputValue, setInputValue] = React.useState('');
  const [value, setValue] = React.useState(multiple ? [] : null);
  const [isSetDefaultValue, setIsSetDefaultValue] = React.useState(false);
  const timerId = React.useRef(0);
  const handleOnInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    if (event?.type === 'change') {
      if (timerId.current) {
        clearTimeout(timerId.current);
      }
      if (newInputValue.length >= 3 && getAsyncData) {
        timerId.current = setTimeout(() => {
          getAsyncData(newInputValue, input.name, event);
        }, delayCallTimeout);
      }
    }

    if (changeToStore) {
      dispatch(change(meta.form, `${input.name}Input`, newInputValue));
    }

    if (handleInputChange) {
      handleInputChange(newInputValue);
    }
  };
  const handleOnChange = (event, newInputValue) => {
    setValue(newInputValue);
    getSelectOption(newInputValue);
    input.onChange(newInputValue);
    if (changeToStore) {
      dispatch(resetSection(meta.form, `${input.name}Input`));
    }
    handleChange(event, newInputValue);
  };
  useEffect(() => {
    if (input.value === '') {
      setValue(multiple ? [] : null);
      setIsSetDefaultValue(false);
    }
    if (!isSetDefaultValue) {
      if (List.isList(input.value) || Map.isMap(input.value) ) {
        if (!isEmpty(options)) {
          let currentOptions = multiple ? [] : null;
          if (multiple) {
            currentOptions = map(input.value.toJS(), (option) => {
              return find(options, option)
            });
          } else {
            currentOptions = options[findIndex(options, input.value.toJS())];
          }
          setValue(currentOptions);
          setIsSetDefaultValue(true);
        } else {
          setValue(input.value.toJS());
          setIsSetDefaultValue(true);
        }
      }else if(!isEmpty(input.value)) {
        setValue(input.value);
        setIsSetDefaultValue(true);

      }else if(!isEmpty(defaultValue)) {
        setValue(defaultValue);
        setIsSetDefaultValue(true);

      }
    }
  }, [input.value, defaultValue]);
  const handleGetOptionSelected = (option, value) => {
    return   (isEqual(value, option));

  };
  const handlerGetOptionLabel = (option) => (option?.label || option?.title || option?.name || String(option?.id));

  return (
    <StyledAutocomplete
      options={options}
      value={isNull(fieldValue) ? fieldValue : fieldValue || value}
      multiple={multiple}
      disableCloseOnSelect={multiple}
      getOptionLabel={handlerGetOptionLabel}
      inputValue={inputValue}
      getOptionSelected={handleGetOptionSelected}
      loading={loading}
      onChange={handleOnChange}
      onInputChange={handleOnInputChange}
      onFocus={handleOnFocus}
      disabled={disabled}
      fullWidth
      {...isGroupBy ? {
        groupBy: (option) => option.groupTitle,
      } : {}}
      {...getAsyncData ? {
        filterOptions: (options) => options,
      } : {}}
      id={id}
      renderOption={(option, { selected }) => (
        <>
          {multiple && (
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
          )}
          {option.label || option.title || option.name || option.id}
        </>
      )}

      renderInput={(params) => {
        return (
          <TextField
            {...params}
            variant="standard"
            {...!disableLabel ? {
              label,
            } : {}}
            required={required}
            placeholder={label}
            margin={margin}
            id={id}
            autoComplete="off"
            error={touched && invalid}
            helperText={touched && error}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )
      }}
    />
  );
}

AutocompleteField.defaultProps = {
  multiple: false,
  loading: false,
  isGroupBy: false,
  required: false,
  disableLabel: false,
  disabled: false,
  label: '',
  options: [],
  input: {
    onChange: () => {
    }
  },
  meta: {
    touched: false,
    invalid: false,
    error: false,
  },
  handleChange: () => {},
  getSelectOption: () => {},
  handleOnFocus: () => {},
  handleInputChange: () => {},
};

export default memo(AutocompleteField)

