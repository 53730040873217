// Core
import { call, put } from 'redux-saga/effects';

// config
import { api } from '../../../../../config/api';

// actions
import action from '../../action';

export function* callGetWorksWorker({ payload: params }) {
  const {
    setWorksData,
  } = action;
  yield put(setWorksData({
    pending: true,
  }));

  const response = yield call(api.works.getWorks, params);

  if (response && response.status >= 200 && response.status < 400) {
    yield put(setWorksData(response.data));
  }

  yield put(setWorksData({
    pending: false,
  }));
}
