// Core
import { all, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetClientByIdWorker,
  callPutClientByIdWorker,
  callGetClientsAutocompleteWorker,
  callDeleteClientWorker,
  callPostClientWorker,
  callPostMergeClientsWorker,
  callPostSentCredentialsWorker,
} from './workers';

function* watchGetClientById() {
  yield takeEvery(asyncTypes.GET_CLIENT_BY_ID_ASYNC, callGetClientByIdWorker);
}

function* watchPutClientById() {
  yield takeEvery(asyncTypes.PUT_CLIENT_BY_ID_ASYNC, callPutClientByIdWorker);
}
function* watchClientsAutocomplete() {
  yield takeEvery(asyncTypes.GET_CLIENTS_AUTOCOMPLETE_ASYNC, callGetClientsAutocompleteWorker);
}

function* watchDeleteClient() {
  yield takeEvery(asyncTypes.DELETE_CLIENT_ASYNC, callDeleteClientWorker);
}

function* watchPostClient() {
  yield takeEvery(asyncTypes.POST_CLIENT_ASYNC, callPostClientWorker);
}

function* watchPostMergeClients() {
  yield takeEvery(asyncTypes.POST_MERGE_CLIENTS_ASYNC, callPostMergeClientsWorker);
}

function* watchPostSentCredentials() {
  yield takeEvery(asyncTypes.POST_CLIENTS_SENT_CREDENTIALS_ASYNC, callPostSentCredentialsWorker);
}

export function* watchersClients() {
  yield all([
    watchGetClientById(),
    watchPutClientById(),
    watchClientsAutocomplete(),
    watchDeleteClient(),
    watchPostClient(),
    watchPostMergeClients(),
    watchPostSentCredentials(),
  ]);
}
