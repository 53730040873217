// Core
import { apply, put } from 'redux-saga/effects';
import i18n, { locale } from '../../../../init/i18n';

// Engine
import { api, authService } from '../../../../config/api';

// actions
import userActions from '../../action';
import userActionsAsync from '../asyncAction';

export function* callSignInWorker({ payload: userData }) {
  const { rememberMe } = userData;
  yield put(userActions.setUserPending(true));

  if (rememberMe) {
    sessionStorage.setItem('rememberMe', 'true');
  } else {
    sessionStorage.setItem('rememberMe', 'false');
  }
  const response = yield apply(api, api.user.signIn, [userData]);
  if (response && response.status === 200) {
    const userInfo = { rememberMe, ...response.data };

    const currentLocale = locale[userInfo.language];
    if (i18n.language !== currentLocale) {
      yield apply(i18n, i18n.changeLanguage, [currentLocale]);
    }

    yield apply(authService, authService.setUserInfo, [response.data]);
    yield put(userActions.setUserInfo(response.data));
    yield put(userActionsAsync.getUserInfoAsync());
  }
  yield put(userActions.setUserPending(false));
}
