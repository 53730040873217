// Core
import { all, call, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetDocumentsTypeWorker,
  callPostSendWorker,
  callGetObjectStatusesWorker,
  callPostAutocompleteGroupWorker,
  callDeleteMultipleWorker,
  callPutMultipleWorker,
  callPostSignWorker,
  callPostSupportTicketsWorker,
  callGetInitialVisibleSidebarWorker,
  callSetToLocalDataWorker,
  callPostCreatePacketWorker,
  callGetBase64Worker,
  callGetImagesBase64Worker,
  callPostCalcPatentsRenewalWorker,
  callGetDownloadFileWorker,
  callGetPreviewFileByIdWorker,
  callGetDownloadExportWorker,
  callSetSaveFrontendSettingWorker,
} from './workers';
import types from '../types';

function* watchGetDocumentsTypeState() {
  yield takeEvery(asyncTypes.GET_DOCUMENTS_TYPE_ASYNC, callGetDocumentsTypeWorker);
}
function* watchPostSendAsync() {
  yield takeEvery(asyncTypes.POST_SEND_ASYNC, callPostSendWorker);
}
function* watchGetObjectStatusesAsync() {
  yield takeEvery(asyncTypes.GET_OBJECT_STATUSES_ASYNC, callGetObjectStatusesWorker);
}
function* watchPostAutocompleteGroupAsync() {
  yield takeEvery(asyncTypes.POST_AUTOCOMPLETE_GROUP_ASYNC, callPostAutocompleteGroupWorker);
}
function* watchDeleteMultipleAsync() {
  yield takeEvery(asyncTypes.DELETE_MULTIPLE_ASYNC, callDeleteMultipleWorker);
}
function* watchPutMultipleAsync() {
  yield takeEvery(asyncTypes.PUT_MULTIPLE_ASYNC, callPutMultipleWorker);
}
function* watchPostSignAsync() {
  yield takeEvery(asyncTypes.POST_SIGN_ASYNC, callPostSignWorker);
}
function* watchPostCreatePacketAsync() {
  yield takeEvery(asyncTypes.POST_CREATE_PACKET_ASYNC, callPostCreatePacketWorker);
}
function* watchGetBase64Async() {
  yield takeEvery(asyncTypes.GET_FILES_BASE_64_ASYNC, callGetBase64Worker);
}
function* watchGetImagesBase64Async() {
  yield takeEvery(asyncTypes.GET_IMAGES_BASE_64_ASYNC, callGetImagesBase64Worker);
}
function* watchPostSupportTicketsAsync() {
  yield takeEvery(asyncTypes.POST_SUPPORT_TICKETS_ASYNC, callPostSupportTicketsWorker);
}
function* watchPostCalcPatentsRenewalAsync() {
  yield takeEvery(asyncTypes.POST_CALC_PATENTS_RENEWAL_ASYNC, callPostCalcPatentsRenewalWorker);
}
function* watchGetInitialVisibleSidebar() {
  yield takeEvery(
    asyncTypes.GET_INITIAL_VISIBLE_SIDEBAR_ASYNC,
    callGetInitialVisibleSidebarWorker,
  );
}
function* watchGetDownloadFileAsync() {
  yield takeEvery(asyncTypes.GET_DOWNLOAD_FILE_ASYNC, callGetDownloadFileWorker);
}
function* watchGetDownloadExportAsync() {
  yield takeEvery(asyncTypes.GET_DOWNLOAD_EXPORT_ASYNC, callGetDownloadExportWorker);
}
function* watchGetPreviewFileByIdAsync() {
  yield takeEvery(asyncTypes.GET_PREVIEW_FILE_BY_ID_ASYNC, callGetPreviewFileByIdWorker);
}
function* watchSetVisibleSidebar() {
  yield takeEvery(types.SET_VISIBLE_SIDEBAR, callSetToLocalDataWorker);
}
function* watchCurrentLongMenu() {
  yield takeEvery(types.SET_CURRENT_LONG_MENU, callSetToLocalDataWorker);
}
function* watchCreatePacket() {
  yield takeEvery(types.SET_SIGNATURE_INFO, callSetToLocalDataWorker);
}
function* watchSelectedCompany() {
  yield takeEvery(types.SET_SELECTED_COMPANY, callSetToLocalDataWorker);
}
function* watchSetSaveFrontendSetting() {
  yield takeEvery(asyncTypes.SET_SAVE_FRONTEND_SETTING, callSetSaveFrontendSettingWorker);
}

export function* watchersHelpers() {
  yield all([
    call(watchGetDocumentsTypeState),
    call(watchPostSendAsync),
    call(watchGetObjectStatusesAsync),
    call(watchPostAutocompleteGroupAsync),
    call(watchDeleteMultipleAsync),
    call(watchPutMultipleAsync),
    call(watchPostSignAsync),
    call(watchPostSupportTicketsAsync),
    call(watchGetInitialVisibleSidebar),
    call(watchSetVisibleSidebar),
    call(watchCurrentLongMenu),
    call(watchPostCreatePacketAsync),
    call(watchCreatePacket),
    call(watchGetBase64Async),
    call(watchGetImagesBase64Async),
    call(watchPostCalcPatentsRenewalAsync),
    call(watchSelectedCompany),
    call(watchGetDownloadFileAsync),
    call(watchGetPreviewFileByIdAsync),
    call(watchGetDownloadExportAsync),
    call(watchSetSaveFrontendSetting),
  ]);
}
