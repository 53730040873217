// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const asyncActions = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_PAYMENT_RECEIVERS_ASYNC, params);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_PAYMENT_RECEIVERS_INITIAL_STATE_ASYNC, path);
  },
  postPaymentReceiversAsync(data) {
    return actionCreator(asyncTypes.POST_PAYMENT_RECEIVERS_ASYNC, data);
  },
  getPaymentReceiversByIdAsync(id) {
    return actionCreator(asyncTypes.GET_PAYMENT_RECEIVERS_BY_ID_ASYNC, id);
  },
  deletePaymentReceivers() {
    return actionCreator(asyncTypes.DELETE_PAYMENT_RECEIVERS);
  },
  putPaymentReceiversByIdAsync(data) {
    return actionCreator(asyncTypes.PUT_PAYMENT_RECEIVERS_BY_ID_ASYNC, data);
  },
});

export default asyncActions;
