// Core
import React, { useCallback, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';

// lodash
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import forEach from 'lodash/forEach';

// parts
import { Add as AddIcon } from '@material-ui/icons';
import Button from '@material-ui/core/Button';

// parts
import DxTable from '../../ui/Table/DxTable';
import TableExtraFilter from '../../ui/Table/components/TableExtraFilter/TableExtraFilter';
import ConfirmModal from '../../components/ConfirmModal';

// reducers
import { initialState } from '../../engine/core/industrialDesigns/reducer';

// actions
import actions from '../../engine/core/industrialDesigns/action';
import helpersActions from '../../engine/core/helpers/action';
import asyncAction from '../../engine/core/industrialDesigns/saga/asyncAction';
import depDocActions from '../../engine/core/departmentDocuments/action';
import helpersAsyncActions from '../../engine/core/helpers/saga/asyncAction';
import tagsActionAsync from '../../engine/core/tags/saga/asyncAction';
import tagsActions from '../../engine/core/tags/action';

// hooks
import { useAccessList } from '../../ui/_hooks/useAccessList';

// config
import selectors from '../../engine/config/selectors';
import accessList from '../../engine/config/accessList';
import globalStateKeys from '../../engine/config/globalStateKeys';
import { pageLinks } from '../../routes';

import { listTablesName, listEntityName } from '../../engine/config/listTablesName';

const tableName = listTablesName.industrial;
const entityName = listEntityName.industrial;

function IndustrialDesignsTable(props) {
  const {
    proposalData, totalCount, pending, successDetBase64,
    currentPage, filters, sorting, isModalOpenAction,
    pageSize, pendingDeleteProposal, checkboxes,
    columnWidths, columnOrder, hiddenColumnNames, isModalOpenAddTag,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const accessPost = useAccessList(accessList.industrial_post);
  const accessTagsList = useAccessList(accessList.tags_list_get);

  const columns = [
    { name: 'id', title: t('ID') },
    { name: 'caseNumber', title: t('Case number') },
    { name: 'title', title: t('Title'), customField: 'titleObject' },
    { name: 'firstImage', title: t('Image') },
    { name: 'clients', title: t('Client'), customField: 'objectTable' },
    { name: 'jurisdictions', title: t('Jurisdiction') },
    { name: 'applicationNumber', title: t('Application number') },
    { name: 'applicationDate', title: t('Application date') },
    { name: 'registrationNumber', title: t('Patent number') },
    { name: 'registrationDate', title: t('Registration date') },
    { name: 'renewal', title: t('Validity') },
    { name: 'status', title: t('Status') },
    { name: 'boost', title: t('Term') },
    { name: 'tags', title: t('Tags'), customField: 'tagsObject' },
    { name: 'notes', title: t('Note') },
    { name: 'pendingActions', title: t('Action up to') },
    { name: 'lastDocuments', title: t('Last documents') },
    { name: 'applicants', title: t('Applicants') },
    { name: 'holders', title: t('Owners') },
  ];

  const editingStateColumnExtensionsCosts = [
    { columnName: 'id', editingEnabled: false },
    { columnName: 'applicationDate', editingEnabled: false },
    { columnName: 'registrationDate', editingEnabled: false },
    { columnName: 'renewal', editingEnabled: false },
    { columnName: 'title', editingEnabled: false },
    { columnName: 'firstImage', editingEnabled: false },
    { columnName: 'status', editingEnabled: false },
    { columnName: 'caseNumber', editingEnabled: false },
    { columnName: 'applicationNumber', editingEnabled: false },
    { columnName: 'registrationNumber', editingEnabled: false },
    { columnName: 'lastDocuments', editingEnabled: false },
    { columnName: 'pendingActions', editingEnabled: false },
    { columnName: 'clients', editingEnabled: false },
    { columnName: 'boost', editingEnabled: false },
    { columnName: 'jurisdictions', editingEnabled: false },
    { columnName: 'applicants', editingEnabled: false },
    { columnName: 'holders', editingEnabled: false },
    { columnName: 'tags' },
    { columnName: 'notes' },
  ];

  const tableColumnExtensions = [
    { columnName: 'id', width: 50 },
    { columnName: 'applicationDate', width: '9rem' },
    { columnName: 'registrationDate', width: '9rem' },
    { columnName: 'renewal', width: '9rem' },
    { columnName: 'caseNumber', width: '9rem' },
    { columnName: 'registrationNumber', width: '7rem' },
    { columnName: 'applicationNumber', width: '7rem' },
    { columnName: 'lastDocuments', width: '8rem' },
    { columnName: 'pendingActions', width: '8rem' },
    { columnName: 'title', width: '15rem' },
    { columnName: 'firstImage', width: '10rem' },
    { columnName: 'status', width: '8rem' },
    { columnName: 'boost', width: '5rem' },
    { columnName: 'tags', width: '10rem' },
    { columnName: 'notes', width: '15rem' },
    { columnName: 'clients', width: '8rem' },
    { columnName: 'jurisdictions', width: '10rem' },
    { columnName: 'applicants', width: '10rem' },
    { columnName: 'holders', width: '10rem' },
  ];

  const handleEditingChange = ({ changed }) => {
    const values = Object.values(changed)[0];
    const ids = Object.keys(changed);
    if (!isEmpty(values)) {
      const filterData = map(proposalData.toJS().items, (item) => (
        item.id === Number(ids[0]) ? { ...item, ...values } : item));
      dispatch(actions.setProposalData({
        items: filterData,
        totalCount: filterData.length,
        pending: false,
      }));
      dispatch(helpersAsyncActions.putMultipleAsync({
        body: {
          entityName: 'industrial',
          entityIds: ids,
          fields: values.tags ? {
            tags: map(values.tags, (item) => item.id),
          } : values,
        },
      }));
    }
  };

  useEffect(() => {
    if (!isEmpty(proposalData.toJS().items) && successDetBase64) {
      forEach(proposalData.toJS().items, (item) => {
        if (item.firstImage) {
          dispatch(helpersAsyncActions.getImagesBase64Async({
            id: item.id,
            fileId: item.firstImage,
          }));
        }
      });
      dispatch(helpersActions.setSuccessDetBase64(false));
    }
  }, [dispatch, proposalData, successDetBase64]);

  useEffect(() => {
    if (accessTagsList) dispatch(tagsActionAsync.getListAsync());
  }, [dispatch, accessTagsList]);

  useEffect(() => function cleanup() {
    dispatch(tagsActions.setTagsData({ items: [] }));
    dispatch(helpersActions.setImagesWithBase64Clear());
  }, [dispatch]);

  const fieldsForModal = [
    {
      name: 'status',
      type: 'select',
      label: t('Status'),
    },
    {
      name: 'client',
      type: 'autocompleteClient',
      label: t('Customer Search / Selection'),
    },
    {
      name: 'person',
      type: 'autocompleteEmployee',
      label: t('Search / Select employee'),
    },
    {
      name: 'jurisdictions',
      type: 'autocompleteJurisdictions',
      label: t('Jurisdiction'),
    },
    {
      name: 'company',
      type: 'selectCompany',
      label: t('Legal person'),
    },
    {
      name: 'boost',
      type: 'checkbox',
      label: t('Expedited application'),
    },
    {
      name: 'eApplication',
      type: 'checkbox',
      label: t('Electronic application'),
    },
  ];

  // Paging
  const onCurrentPage = (page) => dispatch(actions.setCurrentPage(page));
  const onPageSize = (size) => dispatch(actions.setPageSize(size));
  // Filtering
  const setFilters = (getFilters) => dispatch(actions.setFilters(getFilters));
  // Sorting
  const onSortingChange = (getSorting) => dispatch(actions.setSorting(getSorting));
  const onColumnWidthsChange = (getColumnWidths) => dispatch(
    actions.setColumnWidths(getColumnWidths),
  );
  const onColumnOrderChange = (getColumnOrder) => dispatch(
    actions.setColumnOrder(getColumnOrder),
  );
  const onHiddenColumnNamesChange = (getHiddenColumnNames) => dispatch(
    actions.setHiddenColumnNames(getHiddenColumnNames),
  );
  // Reset filters
  const resetFilters = useCallback(() => {
    const dataInitialState = initialState.get(globalStateKeys.data);
    dispatch(actions.setFilters(dataInitialState.get(globalStateKeys.filters).toJS()));
    dispatch(actions.setSorting(dataInitialState.get(globalStateKeys.sorting).toJS()));
    dispatch(actions.setCurrentPage(dataInitialState.get(globalStateKeys.currentPage)));
  }, [dispatch]);

  const createDocument = useCallback(() => {
    const selectedEntity = filter(
      proposalData.toJS().items, (item) => includes(checkboxes.toJS(), item.id),
    );
    dispatch(depDocActions.setDepartmentDocumentEntityList({ industrial: selectedEntity }));
    dispatch(push(pageLinks.documents.departmentDocuments.new));
  }, [proposalData, checkboxes, dispatch]);

  return (
    <>
      <TableExtraFilter
        filters={filters}
        filtersAction={setFilters}
        resetFilters={resetFilters}
      >
        {accessPost && (
        <Button
          mr={2}
          variant="contained"
          component={Link}
          to={pageLinks.industrialDesigns.new}
          color="primary"
        >
          {t('ADD')}
          <AddIcon />
        </Button>
        )}

      </TableExtraFilter>
      <DxTable
        name="ProposalTable"
        isLoading={pending}
        columns={columns}
        rows={proposalData.toJS().items}
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageSize={onPageSize}
        onCurrentPage={onCurrentPage}
        filtersAction={setFilters}
        filters={filters}
        entityName={entityName}
        tableColumnExtensions={!isEmpty(columnWidths) && columnWidths.length === columns.length
          ? columnWidths : tableColumnExtensions}
        onColumnWidthsChange={onColumnWidthsChange}
        columnOrder={!isEmpty(columnOrder) ? columnOrder : map(columns, 'name')}
        onColumnOrderChange={onColumnOrderChange}
        hiddenColumnNames={hiddenColumnNames}
        onHiddenColumnNamesChange={onHiddenColumnNamesChange}
        // checkboxes ------------------------
        fieldsForModal={fieldsForModal}
        tableName={tableName}
        reloadTable={actions.setReload}
        createDocument={createDocument}
        selection={checkboxes.toJS()}
        onSelectionChange={(checkboxSelect) => {
          dispatch(helpersActions.setCheckbox({
            tableName,
            checkboxes: checkboxSelect,
          }));
        }}
        // ------------------------
        isModalOpen={isModalOpenAction || isModalOpenAddTag}
        sorting={sorting}
        onSortingChange={onSortingChange}
        editingExtensions={editingStateColumnExtensionsCosts}
        onEditingChange={handleEditingChange}
        accessEdit={accessList.industrial_put}
        accessDelete={accessList.industrial_delete}
      />

      <ConfirmModal
        buttonSendText="DELETE"
        pending={pendingDeleteProposal}
        handleSend={() => {
          dispatch(asyncAction.deleteProposalAsync());
        }}
      />
    </>
  );
}

IndustrialDesignsTable.displayName = 'IndustrialDesignsTable';

IndustrialDesignsTable.propTypes = {
  proposalData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  totalCount: PropTypes.number.isRequired,
  pending: PropTypes.bool.isRequired,
  pendingDeleteProposal: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  sorting: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  columnWidths: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  columnOrder: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  hiddenColumnNames: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  pageSize: PropTypes.number.isRequired,
  checkboxes: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  isModalOpenAction: PropTypes.bool.isRequired,
  isModalOpenAddTag: PropTypes.bool.isRequired,
  successDetBase64: PropTypes.bool.isRequired,
};

IndustrialDesignsTable.defaultProps = {};

function mapStateToProps(state) {
  return {
    proposalData: selectors.industrialDesignsProposal.proposalData(state),
    pending: selectors.industrialDesignsProposal.pending(state),
    pendingDeleteProposal: selectors.industrialDesignsProposal.pendingDeleteProposal(state),
    // Paging
    totalCount: selectors.industrialDesignsProposal.totalCount(state),
    currentPage: selectors.industrialDesignsProposal.currentPage(state),
    pageSize: selectors.industrialDesignsProposal.pageSize(state),

    // Filtering
    filters: selectors.industrialDesignsProposal.filters(state),

    // Sorting
    sorting: selectors.industrialDesignsProposal.sorting(state),

    // ColumnWidths
    columnWidths: selectors.industrialDesignsProposal.columnWidths(state),
    columnOrder: selectors.industrialDesignsProposal.columnOrder(state),
    hiddenColumnNames: selectors.industrialDesignsProposal.hiddenColumnNames(state),
    isModalOpenAction: selectors.pendingActions.isModalOpenAction(state),
    isModalOpenAddTag: selectors.tags.isModalOpen(state),

    checkboxes: selectors.helpers.getCheckboxes(state, tableName),
    successDetBase64: selectors.helpers.successDetBase64(state),
  };
}

function mapDispatchToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(IndustrialDesignsTable);
