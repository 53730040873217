// Core
import { all, call, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetEmployeesWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
} from './workers';
import types from '../types';

function* watchGetEmployees() {
  yield takeEvery(asyncTypes.GET_EMPLOYEES_ASYNC, callGetEmployeesWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_EMPLOYEES_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_EMPLOYEES_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_EMPLOYEES_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_EMPLOYEES_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_EMPLOYEES_SORTING, callSetToLocalDataWorker);
}
function* watchSetColumnWidths() {
  yield takeEvery(types.SET_EMPLOYEES_COLUMN_WIDTHS, callSetToLocalDataWorker);
}
function* watchSetColumnOrder() {
  yield takeEvery(types.SET_EMPLOYEES_COLUMN_ORDER, callSetToLocalDataWorker);
}
function* watchSetHiddenColumnNames() {
  yield takeEvery(types.SET_EMPLOYEES_HIDDEN_COLUMN_NAMES, callSetToLocalDataWorker);
}

export function* watchersEmployeesTable() {
  yield all([
    call(watchGetEmployees),
    call(watchSetCurrentPage),
    call(watchSetPageSize),
    call(watchSetFilters),
    call(watchSetSorting),
    call(watchGetInitialState),
    call(watchSetColumnWidths),
    call(watchSetColumnOrder),
    call(watchSetHiddenColumnNames),
  ]);
}
