// Core
import { call, put } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../../config/api';

// actions
import actions from '../../action';

// helpers
import { setErrorMessage } from '../../../../../../ui/_helpers/setNotificationMessage';

export function* callPostCurrencyCalcWorker({ payload }) {
  const { setPendingPostCurrencyCalc, setCurrencyCalc } = actions;

  yield put(setPendingPostCurrencyCalc(true));
  const response = yield call(api.currencies.postCurrencyCalc, payload);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
      yield put(setPendingPostCurrencyCalc(false));
      return;
    }
    yield put(setCurrencyCalc(data.sum));
  }

  yield put(setPendingPostCurrencyCalc(false));
}
