// core
import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import FileInputComponent from 'react-file-input-previews-base64';
import {
  Field, Form, reduxForm, change,
} from 'redux-form/lib/immutable';
import styled from 'styled-components';

// lodash
import {
  isEmpty, last,
} from 'lodash';

// ui
import {
  Button as MuiButton, Box as MuiBox,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';

// icon
import { CloudUpload as MuiCloudUpload } from '@material-ui/icons';

// config
import selectors from '../../engine/config/selectors';

// parts
import RenderTextField from '../../ui/Form/RenderTextField';
import Modal from '../../components/Modal/Modal';
import DialogActions from '../../components/Modal/components/DialogActions';

// actions
import asyncAction from '../../engine/core/jurisdictions/saga/asyncAction';
import actions from '../../engine/core/jurisdictions/action';
import accessList from '../../engine/config/accessList';
import { useAccessList } from '../../ui/_hooks/useAccessList';

// style
const Button = styled(MuiButton)(spacing);
const Box = styled(MuiBox)(spacing);
const CloudUpload = styled(MuiCloudUpload)(spacing);

const Icon = styled.img`
  width: 30px;
  margin-top: 14px;
  margin-left: 10px;
`;

function JurisdictionModal(props) {
  const {
    handleSubmit,
    isModalOpen,
    initialize,
    destroy,
    getJurisdictionById,
    pendingPostJurisdiction,
    pendingPutJurisdictionById,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [readyInitialize, setReadyInitialize] = useState(false);
  const [uploadIcon, setUploadIcon] = useState('');
  const accessPut = useAccessList(accessList.jurisdictions_put);
  const accessEdit = !isEmpty(getJurisdictionById.toJS()) ? accessPut : true;

  const handleCloseModal = () => {
    dispatch(actions.setIsModalOpen(false));
  };
  useEffect(() => function cleanup() {
    destroy();
    dispatch(actions.setJurisdictionDataById({}));
    setUploadIcon('');
  }, [destroy, dispatch]);

  const handleSubmits = (formData) => {
    const json = formData.toJS();
    delete json.iconTitle;
    delete json.icon;
    dispatch(asyncAction[!isEmpty(getJurisdictionById.toJS())
      ? 'putJurisdictionByIdAsync'
      : 'postJurisdictionAsync']({
      ...json,
      ...!isEmpty(getJurisdictionById.toJS()) ? {
        id: getJurisdictionById.toJS().id,
      } : {},
      ...getJurisdictionById.toJS().icon !== uploadIcon ? {
        icon: uploadIcon || '',
      } : {},
    }));
  };

  useEffect(() => {
    if (!isEmpty(getJurisdictionById.toJS()) && !readyInitialize) {
      initialize({
        ...getJurisdictionById.toJS(),
        ...getJurisdictionById.toJS().icon ? {
          iconTitle: last(getJurisdictionById.toJS().icon.split('/')),
        } : {},
      });
      if (getJurisdictionById.toJS().icon) setUploadIcon(getJurisdictionById.toJS().icon);
      setReadyInitialize(true);
    }
  }, [
    initialize, getJurisdictionById, setReadyInitialize,
    readyInitialize,
  ]);

  const handleUploadFile = (file) => {
    if (!isEmpty(file)) {
      setUploadIcon(file.base64);
      dispatch(change('JurisdictionModal', 'iconTitle', file.name));
    }
  };

  return (
    <Modal
      title={t('Jurisdiction')}
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
      displayDialogActions={false}
    >
      <Form onSubmit={handleSubmit(handleSubmits)}>
        <Field
          name="name"
          id="name"
          label={t('Name')}
          margin="normal"
          variant="standard"
          my={2}
          fullWidth
          type="text"
          component={RenderTextField}
          inputProps={{ maxLength: 200 }}
          disabled={!accessEdit}
        />
        <Field
          name="code"
          id="code"
          label={t('Code')}
          margin="normal"
          variant="standard"
          my={2}
          fullWidth
          type="text"
          component={RenderTextField}
          inputProps={{ maxLength: 10 }}
          disabled={!accessEdit}
        />
        <Box display="flex" alignItems="center" mb={2}>
          {accessEdit && (
            <FileInputComponent
              name="icon"
              imagePreview={false}
              labelStyle={{ display: 'none' }}
              parentStyle={{
                display: 'flex', width: '100%', marginTop: 14, alignItems: 'flex-end',
              }}
              multiple={false}
              callbackFunction={handleUploadFile}
              accept="file"
              textBoxVisible
              disabled={!accessEdit}
              textFieldComponent={(
                <Field
                  name="iconTitle"
                  label={t('Image')}
                  component={RenderTextField}
                  margin="none"
                  fullWidth
                  disabled={!accessEdit}
                />
              )}
              buttonComponent={(
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  disabled={!accessEdit}
                >
                  <>
                    <CloudUpload mr={2} />
                    {' UPLOAD'}
                  </>
                </Button>
              )}
            />
          )}
          {uploadIcon && (
            <Icon
              src={uploadIcon}
              alt={t('Image')}
            />
          )}
        </Box>
        <DialogActions
          pending={pendingPostJurisdiction || pendingPutJurisdictionById}
          handleCloseModal={handleCloseModal}
          visibleButtonSend={accessEdit}
        />
      </Form>
    </Modal>
  );
}

JurisdictionModal.displayName = 'JurisdictionModal';

JurisdictionModal.propTypes = {
  getJurisdictionById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  pendingPostJurisdiction: PropTypes.bool.isRequired,
  pendingPutJurisdictionById: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  destroy: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
};

JurisdictionModal.defaultProps = {};

function mapStateToProps(state) {
  return {
    getJurisdictionById: selectors.jurisdiction.getJurisdictionById(state),
    isModalOpen: selectors.jurisdiction.isModalOpen(state),
    pendingPutJurisdictionById: selectors.jurisdiction.pendingPutJurisdictionById(state),
    pendingPostJurisdiction: selectors.jurisdiction.pendingPostJurisdiction(state),
  };
}

export default compose(
  reduxForm({
    form: 'JurisdictionModal',
  }),
  connect(mapStateToProps, null),
)(JurisdictionModal);
