// Core
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

// Lodash
import {
  map, isEmpty,
} from 'lodash';

import DxTable from '../../../ui/Table/DxTable';
import ConfirmModal from '../../../components/ConfirmModal';

// actions
import actions from '../../../engine/core/prices/tariffs/action';
import asyncAction from '../../../engine/core/prices/tariffs/saga/asyncAction';
import helpersActions from '../../../engine/core/helpers/action';

// config
import selectors from '../../../engine/config/selectors';

// hooks
import { useCleanUpDefaultFilters } from '../../../ui/_hooks/useCleanUpDefaultFilters';

import { listTablesName, listEntityName } from '../../../engine/config/listTablesName';
import accessList from '../../../engine/config/accessList';

const tableName = listTablesName.tariffs;
const entityName = listEntityName.tariffs;

function TariffsTable(props) {
  const {
    tariffsData, totalCount, pending,
    currentPage, filters, sorting,
    pageSize, pendingDeleteTariff, checkboxes,
    columnWidths, columnOrder, hiddenColumnNames,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { tariffsResetFilters } = useCleanUpDefaultFilters();

  const columns = [
    { name: 'id', title: t('ID') },
    { name: 'name', title: t('Title') },
    { name: 'costscategories', title: t('Category') },
    { name: 'objectType', title: t('Object type') },
    { name: 'code', title: t('Code') },
  ];

  const tableColumnExtensions = [
    { columnName: 'id', width: 50 },
    { columnName: 'name', width: '35rem' },
    { columnName: 'costscategories', width: '30rem' },
    { columnName: 'objectType', width: '15rem' },
    { columnName: 'code', width: '10rem' },
  ];

  // Paging
  const onCurrentPage = (page) => dispatch(actions.setCurrentPage(page));
  const onPageSize = (size) => dispatch(actions.setPageSize(size));
  // Filtering
  const setFilters = (getFilters) => dispatch(actions.setFilters(getFilters));
  // Sorting
  const onSortingChange = (getSorting) => dispatch(actions.setSorting(getSorting));
  const onColumnWidthsChange = (getColumnWidths) => dispatch(
    actions.setColumnWidths(getColumnWidths),
  );
  const onColumnOrderChange = (getColumnOrder) => dispatch(
    actions.setColumnOrder(getColumnOrder),
  );
  const onHiddenColumnNamesChange = (getHiddenColumnNames) => dispatch(
    actions.setHiddenColumnNames(getHiddenColumnNames),
  );

  useEffect(() => function cleanup() {
    tariffsResetFilters();
  }, [tariffsResetFilters]);

  return (
    <>
      <DxTable
        name="TariffsTable"
        isLoading={pending}
        columns={columns}
        rows={tariffsData.toJS().items}
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageSize={onPageSize}
        onCurrentPage={onCurrentPage}
        filtersAction={setFilters}
        filters={filters}
        entityName={entityName}
        tableColumnExtensions={!isEmpty(columnWidths) && columnWidths.length === columns.length
          ? columnWidths : tableColumnExtensions}
        onColumnWidthsChange={onColumnWidthsChange}
        columnOrder={!isEmpty(columnOrder) ? columnOrder : map(columns, 'name')}
        onColumnOrderChange={onColumnOrderChange}
        hiddenColumnNames={hiddenColumnNames}
        onHiddenColumnNamesChange={onHiddenColumnNamesChange}
        // checkboxes ------------------------
        tableName={tableName}
        reloadTable={actions.setReload}
        selection={checkboxes.toJS()}
        onSelectionChange={(checkboxSelect) => {
          dispatch(helpersActions.setCheckbox({
            tableName,
            checkboxes: checkboxSelect,
          }));
        }}
        // ------------------------
        sorting={sorting}
        onSortingChange={onSortingChange}
        accessEdit={accessList.costs_put}
        accessDelete={accessList.costs_delete}
      />

      <ConfirmModal
        buttonSendText="DELETE"
        pending={pendingDeleteTariff}
        handleSend={() => {
          dispatch(asyncAction.deleteTariffAsync());
        }}
      />
    </>
  );
}

TariffsTable.displayName = 'TariffsTable';

TariffsTable.propTypes = {
  tariffsData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  totalCount: PropTypes.number.isRequired,
  pending: PropTypes.bool.isRequired,
  pendingDeleteTariff: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  sorting: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  pageSize: PropTypes.number.isRequired,
  checkboxes: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  columnWidths: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  columnOrder: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  hiddenColumnNames: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
};

TariffsTable.defaultProps = {
};

function mapStateToProps(state) {
  return {
    tariffsData: selectors.pricesTariffs.tariffsData(state),
    pending: selectors.pricesTariffs.pending(state),
    pendingDeleteTariff: selectors.pricesTariffs.pendingDeleteTariff(state),

    // Paging
    totalCount: selectors.pricesTariffs.totalCount(state),
    currentPage: selectors.pricesTariffs.currentPage(state),
    pageSize: selectors.pricesTariffs.pageSize(state),

    // Filtering
    filters: selectors.pricesTariffs.filters(state),

    // Sorting
    sorting: selectors.pricesTariffs.sorting(state),

    // ColumnWidths
    columnWidths: selectors.pricesTariffs.columnWidths(state),
    columnOrder: selectors.pricesTariffs.columnOrder(state),
    hiddenColumnNames: selectors.pricesTariffs.hiddenColumnNames(state),

    checkboxes: selectors.helpers.getCheckboxes(state, tableName),
  };
}

function mapDispatchToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(TariffsTable);
