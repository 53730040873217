// Core
import { all, call, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetSettingsWorker,
  callPutSettingWorker,
} from './workers';

function* watchGetSettings() {
  yield takeEvery(asyncTypes.GET_SETTINGS_ASYNC, callGetSettingsWorker);
}
function* watchPutSettingWorker() {
  yield takeEvery(asyncTypes.PUT_SETTING_ASYNC, callPutSettingWorker);
}

export function* watchersSettingsTable() {
  yield all([
    call(watchGetSettings),
    call(watchPutSettingWorker),
  ]);
}
