// core
import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import {
  Field, Form, reduxForm,
} from 'redux-form/lib/immutable';

// config
import selectors from '../../../engine/config/selectors';

// parts
import AutocompleteField from '../../../ui/Form/AutocompleteField';
import Modal from '../../../components/Modal/Modal';
import DialogActions from '../../../components/Modal/components/DialogActions';
import { currenciesOptions } from '../../../ui/_helpers/currencies';

// actions
import asyncAction from '../../../engine/core/currencies/currenciesList/saga/asyncAction';
import actions from '../../../engine/core/currencies/currenciesList/action';

function CurrencyModal(props) {
  const {
    handleSubmit,
    isModalOpen,
    destroy,
    pendingPost,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(actions.setIsModalOpen(false));
  };
  useEffect(() => function cleanup() {
    destroy();
  }, [destroy]);

  const handleSubmits = (formData) => {
    const json = formData.toJS();
    dispatch(asyncAction.postCurrencyAsync({
      name: json.currency?.name,
      code: json.currency?.code,
      sign: json.currency?.sign,
      default: false,
    }));
  };

  return (
    <Modal
      title={t('Currency')}
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
      displayDialogActions={false}
    >
      <Form onSubmit={handleSubmit(handleSubmits)}>
        <Field
          multiple={false}
          name="currency"
          id="currency"
          component={AutocompleteField}
          type="text"
          label={t('Currency')}
          margin="dense"
          required
          fullWidth
          options={currenciesOptions}
          md={5}
        />

        <DialogActions
          pending={pendingPost}
          handleCloseModal={handleCloseModal}
        />
      </Form>
    </Modal>
  );
}

CurrencyModal.displayName = 'CurrencyModal';

CurrencyModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  pendingPost: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  destroy: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
};

CurrencyModal.defaultProps = {};

function mapStateToProps(state) {
  return {
    isModalOpen: selectors.currencies.isModalOpen(state),
    pendingPost: selectors.currencies.pendingPostCurrency(state),
  };
}

export default compose(
  reduxForm({
    form: 'CurrencyModal',
  }),
  connect(mapStateToProps, null),
)(CurrencyModal);
