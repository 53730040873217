// Core
import { apply, put, delay } from 'redux-saga/effects';

// Engine
import { push } from 'connected-react-router';
import { api } from '../../../../config/api';
import userActions from '../../action';
import { routersLink } from '../../../../../routes';
import i18n from '../../../../init/i18n';
// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../ui/_helpers/setNotificationMessage';

export function* callResetPasswordWorker({ payload: userData }) {
  yield put(userActions.setUserPendingResetPassword(true));
  const response = yield apply(api, api.user.resetPassword, [userData]);
  if (response && response.status >= 200 && response.status < 400) {
    const statusTitle = response.statusText;

    if (response.data.error) {
      yield put(setErrorMessage(response.data.error.message, statusTitle));
    } else if (response.data.success) {
      yield put(setSuccessMessage(i18n.t('Confirm the request to change your password, an email with a link has been sent to your email'), statusTitle));
      yield delay(0);
      yield put(push(routersLink.users.password_change));
    }
  }
  yield put(userActions.setUserPendingResetPassword(false));
}
