// Core
import { apply, put } from 'redux-saga/effects';

// config
import { api } from '../../../../../config/api';
import { listEntityName } from '../../../../../config/listTablesName';

// actions
import actions from '../../action';

const entity = listEntityName.emailLetters;

export function* callGetEmailMessagesWorker({ payload: params }) {
  yield put(actions.setEmailMessagesData({ pending: true }));

  const response = yield apply(api, api.email.getEmailMessages, [{ ...params, entity }]);

  let data = {
    items: [],
    totalCount: 0,
    pending: false,
  };

  if (response && response.status >= 200 && response.status < 400) {
    data = Object.assign(data, response.data);
  }

  yield put(actions.setEmailMessagesData(data));
  yield put(actions.setReload(false));
}
