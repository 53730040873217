// Core
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

// lodash
import { isEmpty, map } from 'lodash';

// actions
import actions from '../../engine/core/jurisdictions/action';
import actionAsync from '../../engine/core/jurisdictions/saga/asyncAction';
import helpersActions from '../../engine/core/helpers/action';

// parts
import DxTable from '../../ui/Table/DxTable';
import ConfirmModal from '../../components/ConfirmModal';

// config
import selectors from '../../engine/config/selectors';

// hooks
import { listTablesName, listEntityName } from '../../engine/config/listTablesName';
import accessList from '../../engine/config/accessList';

const tableName = listTablesName.jurisdictions;
const entityName = listEntityName.jurisdictions;

function JurisdictionsTable(props) {
  const {
    jurisdictionsData, totalCount, pending,
    currentPage, filters, sorting,
    pageSize, pendingDeleteJurisdiction,
    checkboxes, isModalOpen, columnWidths,
    columnOrder, hiddenColumnNames,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const columns = [
    { name: 'id', title: t('ID') },
    { name: 'name', title: t('Name'), customField: 'nameJurisdictions' },
    { name: 'code', title: t('Code') },
  ];

  const tableColumnExtensions = [
    { columnName: 'id', width: 50 },
    { columnName: 'name', width: '40rem' },
    { columnName: 'code', width: '40rem' },
  ];

  useEffect(() => function cleanup() {
    dispatch(actions.setJurisdictionsData({
      items: [],
      totalCount: 0,
      pending: false,
    }));
  }, [dispatch]);

  // Paging
  const onCurrentPage = (page) => dispatch(actions.setCurrentPage(page));
  const onPageSize = (size) => dispatch(actions.setPageSize(size));
  // Filtering
  const setFilters = (getFilters) => dispatch(actions.setFilters(getFilters));

  // Sorting
  const onSortingChange = (getSorting) => dispatch(
    actions.setSorting(getSorting),
  );
  const onColumnWidthsChange = (getColumnWidths) => dispatch(
    actions.setColumnWidths(getColumnWidths),
  );
  const onColumnOrderChange = (getColumnOrder) => dispatch(
    actions.setColumnOrder(getColumnOrder),
  );
  const onHiddenColumnNamesChange = (getHiddenColumnNames) => dispatch(
    actions.setHiddenColumnNames(getHiddenColumnNames),
  );

  return (
    <>
      <DxTable
        name="Jurisdictions"
        isLoading={pending}
        columns={columns}
        rows={jurisdictionsData.toJS().items}
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageSize={onPageSize}
        onCurrentPage={onCurrentPage}
        filtersAction={setFilters}
        onSortingChange={onSortingChange}
        filters={filters}
        // checkboxes ------------------------
        tableName={tableName}
        reloadTable={actions.setReload}
        selection={checkboxes.toJS()}
        onSelectionChange={(checkboxSelect) => {
          dispatch(helpersActions.setCheckbox({
            tableName,
            checkboxes: checkboxSelect,
          }));
        }}
        // ------------------------
        entityName={entityName}
        sorting={sorting}
        isModalOpen={isModalOpen}
        tableColumnExtensions={!isEmpty(columnWidths) && columnWidths.length === columns.length
          ? columnWidths : tableColumnExtensions}
        onColumnWidthsChange={onColumnWidthsChange}
        columnOrder={!isEmpty(columnOrder) ? columnOrder : map(columns, 'name')}
        onColumnOrderChange={onColumnOrderChange}
        hiddenColumnNames={hiddenColumnNames}
        onHiddenColumnNamesChange={onHiddenColumnNamesChange}
        accessEdit={accessList.jurisdictions_put}
        accessDelete={accessList.jurisdictions_delete}
      />

      <ConfirmModal
        buttonSendText="DELETE"
        pending={pendingDeleteJurisdiction}
        handleSend={() => {
          dispatch(actionAsync.deleteJurisdictionAsync());
        }}
      />
    </>
  );
}

JurisdictionsTable.displayName = 'JurisdictionsTable';

JurisdictionsTable.propTypes = {
  jurisdictionsData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  totalCount: PropTypes.number.isRequired,
  pending: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  sorting: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  columnWidths: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  columnOrder: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  hiddenColumnNames: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  pageSize: PropTypes.number.isRequired,
  pendingDeleteJurisdiction: PropTypes.bool.isRequired,
  checkboxes: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    jurisdictionsData: selectors.jurisdiction.jurisdictionsData(state),
    pending: selectors.jurisdiction.pending(state),
    pendingDeleteJurisdiction: selectors.jurisdiction.pendingDeleteJurisdiction(state),
    isModalOpen: selectors.jurisdiction.isModalOpen(state),

    // Paging
    totalCount: selectors.jurisdiction.totalCount(state),
    currentPage: selectors.jurisdiction.currentPage(state),
    pageSize: selectors.jurisdiction.pageSize(state),

    // Filtering
    filters: selectors.jurisdiction.filters(state),

    // Sorting
    sorting: selectors.jurisdiction.sorting(state),

    // ColumnWidths
    columnWidths: selectors.jurisdiction.columnWidths(state),
    columnOrder: selectors.jurisdiction.columnOrder(state),
    hiddenColumnNames: selectors.jurisdiction.hiddenColumnNames(state),

    checkboxes: selectors.helpers.getCheckboxes(state, tableName),
  };
}

function mapDispatchToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(JurisdictionsTable);
