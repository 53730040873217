// core
import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

// ui
import {
  Divider as MuiDivider,
  Typography,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';

// parts
import IndustrialDesignsTable from './IndustrialDesignsTable';

// hooks
import { useIndustrialDesigns } from './_hooks/useIndustrialDesigns';

// styles
const Divider = styled(MuiDivider)(spacing);

function IndustrialDesigns() {
  useIndustrialDesigns();
  const { t } = useTranslation();

  return (
    <>
      <Helmet title={t('Industrial designs')} />
      <Typography variant="h3" gutterBottom display="inline">
        {t('Industrial designs')}
      </Typography>
      <Divider my={6} />

      <IndustrialDesignsTable />
    </>
  );
}

IndustrialDesigns.displayName = 'IndustrialDesigns';

IndustrialDesigns.propTypes = {};

function mapStateToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, null),
)(IndustrialDesigns);
