// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const asyncActions = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_RECEIVING_ASYNC, params);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_RECEIVING_INITIAL_STATE_ASYNC, path);
  },
  delete() {
    return actionCreator(asyncTypes.DELETE_RECEIVING);
  },
  postCheckDocumentsAsync(id) {
    return actionCreator(asyncTypes.POST_CHECK_DOCUMENTS_ASYNC, id);
  },
  getDownloadInDocsAsync(path) {
    return actionCreator(asyncTypes.GET_DOWNLOAD_FILE_IN_DOCS_ASYNC, path);
  },
});

export default asyncActions;
