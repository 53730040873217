// Core
import { List, Map } from 'immutable';

// Instruments
import { isNil } from 'lodash';
import types from './types';
import { pageSizes } from '../../config/globalConfig';
import globalStateKeys from '../../config/globalStateKeys';

export const initialState = Map({
  data: Map({
    items: List(),
    currentPage: 0,
    filters: List(),
    pending: false,
    sorting: List([{ columnName: 'createdAt', direction: 'asc' }]),
    totalCount: 0,
    pageSize: pageSizes[0],
    reload: false,
    columnWidths: List(),
    columnOrder: List(),
    hiddenColumnNames: List(),
  }),
  itemByHashId: Map({
    data: Map(),
    pending: false,
    pendingPut: false,
  }),
  pendingPostProposal: false,
  autocompleteOptions: Map({
    items: Map(),
    pending: false,
  }),
  delete: Map({
    pendingDelete: false,
    id: '',
  }),
  pending: false,
  pendingGetProposalById: false,
  pendingPutProposal: false,
  uploadFiles: List(),
  pendingGetRegistryByNumber: false,
  registryByNumber: Map(),
  getNextNumber: '',
});

export const stateKeys = Object.freeze({
  controller: 'utilityModelsProposal',
  uploadFiles: 'uploadFiles',
  pendingPostProposal: 'pendingPostProposal',
  pendingPutProposal: 'pendingPutProposal',
  pendingGetRegistryByNumber: 'pendingGetRegistryByNumber',
  registryByNumber: 'registryByNumber',
});

export const utilityModelsProposalReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_UTILITY_MODELS_PROPOSAL_DATA: {
      const { items, totalCount, pending } = payload;
      if (pending) {
        return state.setIn([globalStateKeys.data, globalStateKeys.pending], pending);
      }
      return state
        .setIn([globalStateKeys.data, globalStateKeys.pending], pending)
        .setIn([globalStateKeys.data, globalStateKeys.items], List(items))
        .setIn([globalStateKeys.data, globalStateKeys.totalCount], totalCount);
    }
    case types.SET_UTILITY_MODELS_PROPOSAL_RELOAD: {
      return state.setIn([globalStateKeys.data, globalStateKeys.reload], payload);
    }
    case types.SET_UTILITY_MODELS_PROPOSAL_CURRENT_PAGE: {
      return state.setIn([globalStateKeys.data, globalStateKeys.currentPage], payload);
    }
    case types.SET_UTILITY_MODELS_PROPOSAL_FILTERS: {
      const filters = payload.filter((item) => !isNil(item.value));
      return state.setIn([globalStateKeys.data, globalStateKeys.filters], List(filters));
    }
    case types.SET_UTILITY_MODELS_PROPOSAL_SORTING: {
      return state.setIn([globalStateKeys.data, globalStateKeys.sorting], List(payload));
    }
    case types.SET_UTILITY_MODELS_PROPOSAL_COLUMN_WIDTHS: {
      return state.setIn([globalStateKeys.data, globalStateKeys.columnWidths], List(payload));
    }
    case types.SET_UTILITY_MODELS_PROPOSAL_COLUMN_ORDER: {
      return state.setIn([globalStateKeys.data, globalStateKeys.columnOrder], List(payload));
    }
    case types.SET_UTILITY_MODELS_PROPOSAL_HIDDEN_COLUMN_NAMES: {
      return state.setIn([globalStateKeys.data, globalStateKeys.hiddenColumnNames], List(payload));
    }
    case types.SET_UTILITY_MODELS_PROPOSAL_PAGE_SIZE: {
      return state.setIn([globalStateKeys.data, globalStateKeys.pageSize], payload);
    }
    case types.SET_UTILITY_MODELS_PROPOSAL_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, sorting = [],
        columnWidths, columnOrder, hiddenColumnNames,
      } = payload;
      const reload = true;
      const currentSorting = sorting.length
        ? sorting
        : initialState.getIn([globalStateKeys.data, globalStateKeys.sorting]);
      return state
        .setIn([globalStateKeys.data, globalStateKeys.currentPage], currentPage)
        .setIn([globalStateKeys.data, globalStateKeys.filters], List(filters))
        .setIn([globalStateKeys.data, globalStateKeys.pageSize], pageSize)
        .setIn([globalStateKeys.data, globalStateKeys.totalCount], totalCount)
        .setIn([globalStateKeys.data, globalStateKeys.reload], reload)
        .setIn([globalStateKeys.data, globalStateKeys.columnWidths], List(columnWidths))
        .setIn([globalStateKeys.data, globalStateKeys.columnOrder], List(columnOrder))
        .setIn([globalStateKeys.data, globalStateKeys.hiddenColumnNames], List(hiddenColumnNames))
        .setIn([globalStateKeys.data, globalStateKeys.sorting], List(currentSorting));
    }
    case types.SET_DELETE_UTILITY_MODEL_PROPOSAL: {
      return state.mergeIn([globalStateKeys.delete], payload);
    }
    case types.SET_UTILITY_MODELS_PROPOSAL_AUTOCOMPLETE_OPTIONS: {
      return state.mergeIn([
        globalStateKeys.autocompleteOptions,
        globalStateKeys.items,
      ], Map(payload));
    }
    case types.SET_PENDING_UTILITY_MODELS_PROPOSAL_AUTOCOMPLETE_OPTIONS: {
      return state.setIn([
        globalStateKeys.autocompleteOptions,
        globalStateKeys.pending,
      ], payload);
    }
    case types.SET_UTILITY_MODELS_PROPOSAL_DATA_BY_ID: {
      return state.setIn([globalStateKeys.itemByHashId, globalStateKeys.data], Map(payload));
    }
    case types.SET_PENDING_GET_SET_UTILITY_MODELS_PROPOSAL_BY_ID: {
      return state.setIn([globalStateKeys.pendingGetProposalById], payload);
    }
    case types.SET_UTILITY_MODELS_PROPOSAL_UPLOAD_FILES: {
      return state.setIn([stateKeys.uploadFiles], List(payload));
    }
    case types.SET_UTILITY_MODELS_PENDING_PUT_PROPOSAL: {
      return state.setIn([stateKeys.pendingPutProposal], payload);
    }
    case types.SET_UTILITY_MODELS_PENDING_POST_PROPOSAL: {
      return state.setIn([stateKeys.pendingPostProposal], payload);
    }
    case types.SET_UTILITY_MODELS_PENDING_GET_REGISTRY_BY_NUMBER: {
      return state.setIn([stateKeys.pendingGetRegistryByNumber], payload);
    }
    case types.SET_UTILITY_MODELS_REGISTRY_BY_NUMBER: {
      return state.setIn([stateKeys.registryByNumber], Map(payload));
    }
    case types.SET_UTILITY_MODELS_PROPOSAL_NEXT_NUMBER: {
      return state.setIn([globalStateKeys.getNextNumber], payload);
    }
    default:
      return state;
  }
};
