// Core
import { call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../../config/api';
import { messageRequestSuccess } from '../../../../../config/globalConfig';

// routes
import { pageLinks } from '../../../../../../routes';

// actions
import mktuTemplateActions from '../../action';

// init
import i18n from '../../../../../init/i18n';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../../ui/_helpers/setNotificationMessage';

export function* callPostMktuTemplatesWorker({ payload: mktuTemplate }) {
  const { setPendingMktuTemplateById } = mktuTemplateActions;

  yield put(setPendingMktuTemplateById({
    pendingPost: true,
  }));
  const response = yield call(api.trademarks.postMktuTemplate, mktuTemplate);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      const { id } = data;
      yield put(push(pageLinks.tradeMarksRoutes.mktuTemplates.edit(id)));
      yield put(setSuccessMessage(i18n.t(messageRequestSuccess), response.status.toString()));
    }
  }

  yield put(setPendingMktuTemplateById({
    pendingPost: false,
  }));
}
