// Helpers
import actionCreator from '../../_helpers/actionCreator';
// Types
import types from './types';

const actions = Object.freeze({
  setPaymentReceiversById(data) {
    return actionCreator(types.SET_PAYMENT_RECEIVERS_BY_ID, data);
  },
  setPaymentReceiversData(data) {
    return actionCreator(types.SET_PAYMENT_RECEIVERS_DATA, data);
  },
  setCurrentPage(currentPage) {
    return actionCreator(types.SET_PAYMENT_RECEIVERS_CURRENT_PAGE, currentPage);
  },
  setDeletePaymentReceivers(data) {
    return actionCreator(types.SET_DELETE_PAYMENT_RECEIVERS, data);
  },
  setPageSize(pageSize) {
    return actionCreator(types.SET_PAYMENT_RECEIVERS_PAGE_SIZE, pageSize);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_PAYMENT_RECEIVERS_INITIAL_STATE, initialState);
  },
  setReload(reload) {
    return actionCreator(types.SET_PAYMENT_RECEIVERS_RELOAD, reload);
  },
  setModalOpen(modalOpen) {
    return actionCreator(types.SET_PAYMENT_RECEIVERS_MODAL_OPEN, modalOpen);
  },
  setFilters(filters) {
    return actionCreator(types.SET_PAYMENT_RECEIVERS_FILTER, filters);
  },
  setSorting(sorting) {
    return actionCreator(types.SET_PAYMENT_RECEIVERS_SORTING, sorting);
  },
  setColumnWidths(data) {
    return actionCreator(types.SET_PAYMENT_RECEIVERS_COLUMN_WIDTHS, data);
  },
  setColumnOrder(data) {
    return actionCreator(types.SET_PAYMENT_RECEIVERS_COLUMN_ORDER, data);
  },
  setHiddenColumnNames(data) {
    return actionCreator(types.SET_PAYMENT_RECEIVERS_HIDDEN_COLUMN_NAMES, data);
  },
});

export default actions;
