// Core
import { call, put } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../../config/api';
import { messageRequestSent } from '../../../../../config/globalConfig';

// init
import i18n from '../../../../../init/i18n';

// actions
import helpersActions from '../../../../helpers/action';
import departmentDocumentsActionAsync from '../../../../departmentDocuments/saga/asyncAction';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../../ui/_helpers/setNotificationMessage';

export function* callPostInvoiceWorker({ payload: invoice }) {
  const response = yield call(api.company.postInvoice, invoice);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      yield put(setSuccessMessage(i18n.t(messageRequestSent), response.status.toString()));
      yield put(helpersActions.setModal({ isModalOpenTableEntities: false }));
      yield put(departmentDocumentsActionAsync.getListAsync({ ordersId: invoice.order }));
    }
  }
}
