// Core
import {
  useCallback, useEffect, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

// lodash
import isEmpty from 'lodash/isEmpty';

// actions
import asyncActions from '../../../engine/core/departmentDocuments/saga/asyncAction';
import departmentDocumentsActions from '../../../engine/core/departmentDocuments/action';

// config
import selectors from '../../../engine/config/selectors';
import { listEntityName } from '../../../engine/config/listTablesName';

// hook
import { useParamsRequest } from '../../../ui/_hooks/useParamsRequest';

const entityName = listEntityName.documents;

export function useDepartmentDocuments() {
  const currentPage = useSelector(selectors.departmentDocuments.currentPage);
  const limit = useSelector(selectors.departmentDocuments.pageSize);
  const filters = useSelector(selectors.departmentDocuments.filters);
  const sorting = useSelector(selectors.departmentDocuments.sorting);
  const reload = useSelector(selectors.departmentDocuments.reload);
  const selectedCompany = useSelector(selectors.helpers.selectedCompany);
  const routeKey = useSelector(selectors.router.key);
  const path = useSelector(selectors.router.pathname);
  const fieldsEntity = useSelector(selectors.fieldsSetting.fieldsEntity);
  const dispatch = useDispatch();

  const { orderBy, paramsByFilter } = useParamsRequest({ filters, sorting, entityName });

  const getListAsync = useCallback(() => {
    // if (hasAccessList) {
    const params = {
      ...paramsByFilter,
      ...selectedCompany ? {
        companyId: selectedCompany,
      } : {},
      ...orderBy,
      limit,
      page: currentPage + 1,
    };
    dispatch(asyncActions.getListAsync(params));
    // }
  }, [paramsByFilter, selectedCompany, currentPage, orderBy, limit, dispatch]);

  const getInitialStateAsync = useCallback(() => {
    dispatch(asyncActions.getInitialStateAsync(path));
    dispatch(departmentDocumentsActions.setDepartmentDocumentById({
      data: {},
    }));
  }, [dispatch, path]);

  const getInitialState = useRef(true);

  useEffect(() => {
    if (getInitialState.current) {
      getInitialStateAsync();
      getInitialState.current = false;
    }

    if (reload && !isEmpty(fieldsEntity.toJS())) {
      getListAsync();
    }
  }, [getListAsync, getInitialStateAsync, reload, routeKey, fieldsEntity]);
}
