// Helpers
import actionCreator from '../../_helpers/actionCreator';
// Types
import types from './types';

const jurisdictionActions = Object.freeze({
  setJurisdictionsData(data) {
    return actionCreator(types.SET_JURISDICTIONS_DATA, data);
  },
  setJurisdictionById(data) {
    return actionCreator(types.SET_JURISDICTION_BY_ID, data);
  },
  setJurisdictionDataById(data) {
    return actionCreator(types.SET_JURISDICTION_DATA_BY_ID, data);
  },
  setCurrentPage(currentPage) {
    return actionCreator(types.SET_JURISDICTIONS_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return actionCreator(types.SET_JURISDICTIONS_FILTERS, filters);
  },
  setSorting(sorting) {
    return actionCreator(types.SET_JURISDICTIONS_SORTING, sorting);
  },
  setColumnWidths(data) {
    return actionCreator(types.SET_JURISDICTIONS_COLUMN_WIDTHS, data);
  },
  setColumnOrder(data) {
    return actionCreator(types.SET_JURISDICTIONS_COLUMN_ORDER, data);
  },
  setHiddenColumnNames(data) {
    return actionCreator(types.SET_JURISDICTIONS_HIDDEN_COLUMN_NAMES, data);
  },
  setPageSize(pageSize) {
    return actionCreator(types.SET_JURISDICTIONS_PAGE_SIZE, pageSize);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_JURISDICTIONS_INITIAL_STATE, initialState);
  },
  setReload(reload) {
    return actionCreator(types.SET_JURISDICTIONS_RELOAD, reload);
  },
  setDeleteJurisdiction(data) {
    return actionCreator(types.SET_DELETE_JURISDICTION, data);
  },
  setAutocompleteOptions(data) {
    return actionCreator(types.SET_JURISDICTIONS_AUTOCOMPLETE_OPTIONS, data);
  },
  setPendingAutocompleteOptions(pending) {
    return actionCreator(types.SET_PENDING_JURISDICTIONS_AUTOCOMPLETE_OPTIONS, pending);
  },
  setIsModalOpen(data) {
    return actionCreator(types.SET_JURISDICTIONS_IS_MODAL_OPEN, data);
  },
});

export default jurisdictionActions;
