// Core
/* eslint-disable */
import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { DateRangePicker } from 'react-date-range';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';

// Helpers
import { defaultStaticRanges, defaultInputRanges } from './DataRangeStaticRanges';
import { getCurrentDateFnsLocale } from '../_helpers/getCurrentDateFnsLocale';

// Style
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

class DateRange extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    i18n: PropTypes.object,
  };

  static defaultProps = {
    endDate: new Date(),
    startDate: new Date(),
  };

  static displayName = 'DateRange';

  constructor(props) {
    super(props);
    this.state = {
      selectionRange: {
        startDate: props.startDate,
        endDate: props.endDate,
        key: 'selection',
      },
    };
  }

  onRangeChange = () => {
    const { onChange } = this.props;
    const { selectionRange } = this.state;
    if (typeof onChange === 'function') {
      onChange(selectionRange);
    }
  };

  handleSelect = (range) => {
    const { selection } = range;
    this.setState({ selectionRange: selection }, this.onRangeChange);
  };

  getCurrentLocale = () => {
    const { i18n } = this.props;
    return getCurrentDateFnsLocale(i18n.language);
  };


  render() {
    const { selectionRange } = this.state;

    return (
      <DateRangePicker
        locale={this.getCurrentLocale()}
        ranges={[selectionRange]}
        onChange={this.handleSelect}

        staticRanges={defaultStaticRanges}

        inputRanges={defaultInputRanges}
      />
    );
  }
}

export default compose(
  withTranslation(),
)(DateRange);
