// core
import React, {
  useEffect, useState, useMemo, memo,
} from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import {
  Field, Form, formValueSelector, reduxForm, change,
} from 'redux-form/immutable';

// lodash
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import find from 'lodash/find';

// ui
import {
  Button, Grid, InputAdornment, Tooltip,
  FormControlLabel, Radio,
  CircularProgress, IconButton,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';

// config
import selectors from '../../engine/config/selectors';

// parts
import Modal from '../Modal/Modal';
import DialogActions from '../Modal/components/DialogActions';
import renderTextField from '../../ui/Form/RenderTextField';
import AutocompleteField from '../../ui/Form/AutocompleteField';
import FieldClient from '../../ui/Form/FieldsAutocomplete/FieldClient';
import RadioButton from '../../ui/Form/RadioButton';

// actions
import helpersActions from '../../engine/core/helpers/action';
import proposalAsyncActions from '../../engine/core/tradeMarks/proposal/saga/asyncAction';
import proposalActions from '../../engine/core/tradeMarks/proposal/action';
import applicantsActionAsync from '../../engine/core/applicants/saga/asyncAction';
import applicantsAction from '../../engine/core/applicants/action';

// helpers
import { formName } from './helpers/form';
import { countryOptions } from '../../ui/_helpers/country';

// hooks
import { useEventsAutocompleteAsync } from '../../ui/_hooks/useEventsAutocompleteAsync';
import accessList from '../../engine/config/accessList';
import { useAccessList } from '../../ui/_hooks/useAccessList';

const formSelector = formValueSelector(formName);

function AddApplicantModal(props) {
  const {
    handleSubmit, companyData, fieldInnCode, isModalOpenAddApplicant,
    destroy, pendingPutApplicant, pendingPostApplicant, companyPending,
    fieldFis, editApplicant, initialize, client, handleClose, handleSend,
    fieldCountry, isTable, isProposal, getApplicantById, visibleClient,
    entityApplicantModal,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [readyInitialize, setReadyInitialize] = useState(false);
  const [editInitialize, setEditInitialize] = useState(false);
  const [notFoundCode, setNotFoundCode] = useState(false);
  const accessPut = useAccessList(accessList.applicants_put);
  const accessFindCompany = useAccessList(accessList.company_helpers_find_get);
  const accessEdit = !isEmpty(getApplicantById.toJS()) ? accessPut : true;

  const {
    getAutocompleteLists,
    handleGetAsyncData,
  } = useEventsAutocompleteAsync(visibleClient);

  const handleCloseModal = () => {
    destroy();
    dispatch(helpersActions.setModal({
      isModalOpenAddApplicant: false,
    }));
    dispatch(applicantsAction.setApplicantById({}));
    handleClose();
    setReadyInitialize(false);
  };

  const getRegistryByNumber = (number) => {
    if (accessFindCompany) dispatch(proposalAsyncActions.getFindCompanyAsync({ code: number }));
  };

  const handleSubmits = (formData) => {
    const json = formData.toJSON();
    const data = {
      ...json,
      ...json.clients ? {
        clients: json.clients.toJS ? json.clients.toJS().id : json.clients.id,
      } : {},
      fis: json.fis === 'true',
      ...!isEmpty(json.address) && !isEmpty(json.address.toJS()) ? {
        address: {
          ...json.address.toJS(),
          country: json.address.toJS()?.country?.abbreviation,
        },
      } : {},
      ...isProposal ? {
        isProposal,
      } : {},
      ...isTable ? {
        isTable,
      } : {},
    };
    delete data.clientsInput;
    if (!isEmpty(editApplicant)) {
      handleSend(data);
    } else {
      dispatch(applicantsActionAsync[!isEmpty(getApplicantById.toJS())
        ? 'putApplicantAsync'
        : 'postApplicantAsync'](data));
    }
  };

  useEffect(() => {
    if (!isEmpty(companyData.toJS())) {
      if (!isEmpty(companyData.toJS().error)) {
        setNotFoundCode(true);
      } else {
        const parseCompanyData = companyData.toJS();
        const street = !isNil(parseCompanyData?.streettype) && !isNil(parseCompanyData?.street) ? `${parseCompanyData?.streettype || ''} ${parseCompanyData?.street},` : '';
        const building = !isNil(parseCompanyData?.building) ? `будинок ${parseCompanyData?.building},` : '';
        const room = !isNil(parseCompanyData?.roomtype) && !isNil(parseCompanyData?.room) ? `${parseCompanyData?.roomtype} ${parseCompanyData?.room}` : '';
        const obl = !isNil(parseCompanyData?.obl) ? `${parseCompanyData?.obl} область,` : '';
        const region = !isNil(parseCompanyData?.region) ? `${parseCompanyData?.region} район,` : '';
        const city = !isNil(parseCompanyData?.citytype) && !isNil(parseCompanyData?.cityname)
          ? `${parseCompanyData?.citytype || ''} ${parseCompanyData?.cityname || ''}` : '';

        dispatch(change(formName, 'name', parseCompanyData.name || ''));
        dispatch(change(formName, 'fis', `${parseCompanyData?.fis}` || ''));
        dispatch(change(formName, 'address.city', `${obl}${obl ? ' ' : ''}${region}${region ? ' ' : ''}${city}` || ''));
        dispatch(change(formName, 'address.address', `${street}${street ? ' ' : ''}${building}${building ? ' ' : ''}${room}` || ''));
        dispatch(change(formName, 'address.postalIndex', `${parseCompanyData?.index}` || ''));
        dispatch(change(formName, 'address.country', { label: 'Ukraine', abbreviation: 'UA' }));
        setNotFoundCode(false);
      }
      dispatch(proposalActions.setCompany({}));
    }
  }, [dispatch, companyData]);

  useEffect(() => {
    if (!readyInitialize && isEmpty(editApplicant)) {
      setReadyInitialize(true);
      dispatch(change(formName, 'address.country', { label: 'Ukraine', abbreviation: 'UA' }));
      dispatch(change(formName, 'fis', 'true'));
    }
  }, [
    dispatch, readyInitialize, setReadyInitialize,
    editApplicant,
  ]);

  useEffect(() => {
    if (!isEmpty(editApplicant)) {
      initialize({
        ...editApplicant,
        fis: !isNil(editApplicant?.fis) ? `${editApplicant?.fis}` : 'true',
        address: {
          ...editApplicant.address,
          country: find(countryOptions, { abbreviation: editApplicant.address?.country }),
        },
      });
    }
  }, [
    editApplicant, initialize,
  ]);

  useEffect(() => {
    if (
      !editInitialize && !isEmpty(getApplicantById.toJS()) && isTable && isEmpty(editApplicant)
    ) {
      setEditInitialize(true);
      initialize({
        ...getApplicantById.toJS(),
        fis: !isNil(getApplicantById.toJS()?.fis) ? `${getApplicantById.toJS()?.fis}` : 'true',
        address: {
          ...getApplicantById.toJS().address,
          country: find(countryOptions, { abbreviation: getApplicantById.toJS().address?.country }),
        },
      });
    }
  }, [
    isTable, getApplicantById, editApplicant, initialize, editInitialize,
  ]);

  useEffect(() => {
    if (!isEmpty(client)) {
      dispatch(change(formName, 'clients', client));
    }
  }, [
    dispatch, client,
  ]);

  const handleChangeInnCode = () => {
    if (notFoundCode) {
      setNotFoundCode(false);
    }
  };

  const submitForm = (event) => {
    handleSubmit();
    event.preventDefault();
  };

  const checkCountry = useMemo(() => {
    const country = fieldCountry?.toJS ? fieldCountry.toJS() : fieldCountry;
    return !isEmpty(country) && country.abbreviation !== 'UA';
  }, [
    fieldCountry,
  ]);

  return (
    <Modal
      title={t(entityApplicantModal || 'Applicant')}
      isModalOpen={isModalOpenAddApplicant}
      handleCloseModal={handleCloseModal}
      displayDialogActions={false}
    >
      <Form onSubmit={handleSubmit(handleSubmits)}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            {isEmpty(editApplicant) && visibleClient && (
              <FieldClient
                name="clients"
                label="Customer Search / Selection"
                getAutocompleteLists={getAutocompleteLists}
                getAsyncData={handleGetAsyncData}
                formName={formName}
                propsField={{ disabled: !accessEdit }}
              />
            )}

            <Field name="fis" row component={RadioButton} disabled={!accessEdit}>
              <FormControlLabel value="true" control={<Radio />} label={t('Individual person')} />
              <FormControlLabel value="false" control={<Radio />} label={t('Legal person')} />
            </Field>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Field
              multiple={false}
              name="address.country"
              id="address.country"
              component={AutocompleteField}
              type="text"
              label={t('Country')}
              margin="normal"
              fullWidth
              options={countryOptions}
              disabled={!accessEdit}
            />

            <Field
              id="address.city"
              name="address.city"
              label={t('City')}
              variant="standard"
              margin="normal"
              fullWidth
              my={2}
              component={renderTextField}
              disabled={!accessEdit}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Field
              name="innCode"
              id="innCode"
              label={t('USREOU')}
              margin="normal"
              variant="standard"
              fullWidth
              type="text"
              component={renderTextField}
              {...notFoundCode ? {
                helperText: t('Not found'),
              } : {}}
              onChange={handleChangeInnCode}
              disabled={!accessEdit}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {fieldInnCode ? (
                      <Tooltip
                        title={t('Find in the national register')}
                        arrow
                      >
                        <IconButton
                          onClick={() => {
                            getRegistryByNumber(fieldInnCode);
                          }}
                        >
                          {companyPending ? <CircularProgress size={20} color="inherit" /> : <SearchIcon />}
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <IconButton disabled>
                        <SearchIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />

            <Field
              id="address.postalIndex"
              name="address.postalIndex"
              label={t('Index')}
              variant="standard"
              margin="normal"
              fullWidth
              my={2}
              component={renderTextField}
              disabled={!accessEdit}
            />
          </Grid>

          <Grid item xs={12}>
            <Field
              name="name"
              id="name"
              label={fieldFis === 'true' ? t('Name') : t('Title')}
              margin="normal"
              variant="standard"
              my={2}
              fullWidth
              type="text"
              component={renderTextField}
              disabled={!accessEdit}
            />
            <Field
              id="address.address"
              name="address.address"
              label={t('Address')}
              variant="standard"
              margin="normal"
              fullWidth
              my={2}
              component={renderTextField}
              disabled={!accessEdit}
            />
            {checkCountry && (
              <>
                <Field
                  name="originalAddress.name"
                  id="originalAddress.name"
                  label={t('Name in the language of origin')}
                  margin="normal"
                  variant="standard"
                  my={2}
                  fullWidth
                  type="text"
                  component={renderTextField}
                  disabled={!accessEdit}
                />
                <Field
                  id="originalAddress.address"
                  name="originalAddress.address"
                  label={t('Address in the language of origin')}
                  variant="standard"
                  margin="normal"
                  fullWidth
                  my={2}
                  component={renderTextField}
                  disabled={!accessEdit}
                />
              </>
            )}
          </Grid>
        </Grid>

        <DialogActions
          dialogActionsChildren={(
            <>
              {accessEdit && (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={pendingPutApplicant || pendingPostApplicant}
                  onClick={submitForm}
                >
                  {pendingPutApplicant || pendingPostApplicant
                    ? <CircularProgress size={25} color="inherit" />
                    : t('CONFIRM')}
                </Button>
              )}
              <Button
                variant="outlined"
                onClick={handleCloseModal}
                color="primary"
              >
                {t('CANCEL')}
              </Button>
            </>
          )}
        />
      </Form>
    </Modal>
  );
}

AddApplicantModal.propTypes = {
  destroy: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  isModalOpenAddApplicant: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  fieldInnCode: PropTypes.string,
  fieldFis: PropTypes.string,
  companyData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  getApplicantById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingPutApplicant: PropTypes.bool.isRequired,
  pendingPostApplicant: PropTypes.bool.isRequired,
  entityApplicantModal: PropTypes.string.isRequired,
  companyPending: PropTypes.bool.isRequired,
  editApplicant: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  client: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  handleSend: PropTypes.func,
  handleClose: PropTypes.func,
  fieldCountry: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  isTable: PropTypes.bool,
  isProposal: PropTypes.bool,
  visibleClient: PropTypes.bool,
};

AddApplicantModal.defaultProps = {
  fieldInnCode: '',
  fieldFis: 'true',
  editApplicant: {},
  client: {},
  fieldCountry: {},
  handleSend: () => {},
  handleClose: () => {},
  isTable: false,
  isProposal: false,
  visibleClient: true,
};

function mapStateToProps(state) {
  return {
    isModalOpenAddApplicant: selectors.helpers.isModalOpenAddApplicant(state),
    pendingPostApplicant: selectors.applicants.pendingPostApplicant(state),
    getApplicantById: selectors.applicants.getApplicantById(state),
    pendingPutApplicant: selectors.applicants.pendingPutApplicant(state),
    companyData: selectors.tradeMarksProposal.company(state),
    companyPending: selectors.tradeMarksProposal.companyPending(state),
    entityApplicantModal: selectors.helpers.entityApplicantModal(state),
    fieldInnCode: formSelector(state, 'innCode'),
    fieldFis: formSelector(state, 'fis'),
    fieldCountry: formSelector(state, 'address.country'),
  };
}

export default compose(
  reduxForm({
    form: formName,
  }),
  connect(mapStateToProps, null),
)(memo(AddApplicantModal));
