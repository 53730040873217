// Core
import { call, put } from 'redux-saga/effects';

// config
import { api } from '../../../../../../config/api';

// actions
import action from '../../action';

export function* callGetTariffTableByIdWorker({ payload: id }) {
  const {
    setTariffTableById,
    setModalOpen,
  } = action;
  yield put(setTariffTableById({
    pending: true,
  }));

  const response = yield call(api.prices.getTariffTableById, id);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    yield put(setTariffTableById({ data }));
    yield put(setModalOpen(true));
  }

  yield put(setTariffTableById({
    pending: false,
  }));
}
