// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import types from './types';

const worksActions = Object.freeze({
  setWorksData(worksList) {
    return actionCreator(types.SET_PRICES_WORKS_LIST, worksList);
  },
  setDeleteWork(work) {
    return actionCreator(types.SET_DELETE_PRICES_WORK, work);
  },
  setPutWork(data) {
    return actionCreator(types.SET_PUT_PRICES_WORK, data);
  },
  setGetWorkById(data) {
    return actionCreator(types.SET_PRICES_WORK_BY_ID, data);
  },
});

export default worksActions;
