// Core
import { all, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetLoggingWorker,
  callGetLoggingByIdWorker,
  callPutLoggingByIdWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callDeleteLoggingWorker,
} from './workers';
import types from '../types';

function* watchGetLogging() {
  yield takeEvery(asyncTypes.GET_LOGGING_ASYNC, callGetLoggingWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_LOGGING_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchGetLoggingById() {
  yield takeEvery(asyncTypes.GET_LOGGING_BY_ID_ASYNC, callGetLoggingByIdWorker);
}
function* watchPutLoggingById() {
  yield takeEvery(asyncTypes.PUT_LOGGING_BY_ID_ASYNC, callPutLoggingByIdWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_LOGGING_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_LOGGING_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_LOGGING_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_LOGGING_SORTING, callSetToLocalDataWorker);
}
function* watchSetColumnWidths() {
  yield takeEvery(types.SET_LOGGING_COLUMN_WIDTHS, callSetToLocalDataWorker);
}
function* watchSetColumnOrder() {
  yield takeEvery(types.SET_LOGGING_COLUMN_ORDER, callSetToLocalDataWorker);
}
function* watchSetHiddenColumnNames() {
  yield takeEvery(types.SET_LOGGING_HIDDEN_COLUMN_NAMES, callSetToLocalDataWorker);
}
function* watchDeleteLogging() {
  yield takeEvery(asyncTypes.DELETE_LOGGING_ASYNC, callDeleteLoggingWorker);
}

export function* watchersLogging() {
  yield all([
    watchGetLogging(),
    watchGetInitialState(),
    watchGetLoggingById(),
    watchPutLoggingById(),
    watchSetCurrentPage(),
    watchSetPageSize(),
    watchSetFilters(),
    watchSetSorting(),
    watchSetColumnWidths(),
    watchSetColumnOrder(),
    watchSetHiddenColumnNames(),
    watchDeleteLogging(),
  ]);
}
