// Core
import React, {
  useEffect, useState, memo, useCallback,
} from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import Helmet from 'react-helmet';
import {
  Field, Form, reduxForm,
} from 'redux-form/immutable';
import * as PropTypes from 'prop-types';
import { Link as MuiLinkRouter } from 'react-router-dom';

// ui
import {
  Typography, Divider as MuiDivider, ButtonGroup,
  CardContent, Box, Button as MuiButton,
  Grid as MuiGrid, Card as MuiCard, CircularProgress,
} from '@material-ui/core';
import {
  Add as AddIcon,
  NavigateBefore as NavigateBeforeIcon,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';

// lodash
import {
  find, map, isEmpty, filter, includes, uniq,
} from 'lodash';

// parts
import Loader from '../../../../components/Loader';
import RenderTextField from '../../../../ui/Form/RenderTextField';
import Select from '../../../../ui/Form/Select';
import DatePickers from '../../../../ui/Form/DatePickers';
import DxTable from '../../../../ui/Table/DxTable';
import TableEntitiesModal from '../../../../components/TableEntitiesModal/TableEntitiesModal';

// actions
import actionAsync from '../../../../engine/core/tradeMarks/madridCase/saga/asyncAction';
import action from '../../../../engine/core/tradeMarks/madridCase/action';
import helpersAction from '../../../../engine/core/helpers/action';
import tradeMarksActions from '../../../../engine/core/tradeMarks/proposal/action';
import tradeMarksAsyncActions from '../../../../engine/core/tradeMarks/proposal/saga/asyncAction';

// config
import selectors from '../../../../engine/config/selectors';
import accessList from '../../../../engine/config/accessList';

// helpers
import { pageLinks } from '../../../../routes';
import { useAccessList } from '../../../../ui/_hooks/useAccessList';
import BasicMadridCaseModal from './BasicMadridCaseModal';

// styles
const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Grid = styled(MuiGrid)(spacing);

const LinkRouter = styled(MuiLinkRouter)`
  text-decoration: underline;
  color: #1976d2;
  display: flex;
  align-items: center;
`;
// const TypographyFlex = styled(Typography)`
//   display: flex;
//   align-items: center;
//   margin-bottom: 8px;
// `;
const TypographySub = styled(Typography)`
  color: ${(props) => props.theme.palette.grey['500']};
`;
// const Avatar = styled(MuiAvatar)`
//   width: 200px;
//   height: auto;
//   .MuiAvatar-img {
//     width: 100%;
//     height: 100%;
//   }
// `;

function MadridCase(props) {
  const {
    handleSubmit, initialize, pendingPostMadridCase, proposalData,
    getMadridCaseById, match, isModalOpenTableEntities, isModalOpen,
    pendingPutMadridCase, pendingMadridCaseById, objectStatuses,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { params } = match;
  const [isInitialize, setIsInitialize] = useState(false);
  const [isList, setIsList] = useState(false);
  const [readyMadridCase, setReadyMadridCase] = useState(false);
  const [listTrademarks, setListTrademarks] = useState([]);
  const [basicTrademarks, setBasicTrademarks] = useState({});
  const [selectionTrademarks, setSelectionTrademarks] = useState([]);
  const pending = pendingPostMadridCase || pendingPutMadridCase;
  const accessPut = useAccessList(accessList.madrid_case_put);
  const accessTrademarksListGet = useAccessList(accessList.trademarks_list_get);
  const accessEdit = !isEmpty(params) ? accessPut : true;

  useEffect(() => function cleanup() {
    const emptyData = { items: [], totalCount: 0, pending: false };
    dispatch(action.setMadridCaseDataById({}));
    dispatch(tradeMarksActions.setProposalData(emptyData));
  }, [dispatch]);

  useEffect(() => {
    if (!readyMadridCase && isEmpty(getMadridCaseById.toJS()) && !isEmpty(params)) {
      setReadyMadridCase(true);
      dispatch(actionAsync.getMadridCaseByIdWorker(params.hashId));
    }
  }, [dispatch, getMadridCaseById, params, readyMadridCase]);

  useEffect(() => {
    if (!isEmpty(params) && accessTrademarksListGet) {
      dispatch(tradeMarksAsyncActions.getListAsync(
        { limit: 100, page: 1, 'madridCases.id': params.hashId },
      ));
    }
  }, [dispatch, params, accessTrademarksListGet]);

  useEffect(() => {
    if (!isInitialize && isEmpty(getMadridCaseById.toJS()) && isEmpty(params)) {
      initialize({
        status: 1,
      });
      setIsInitialize(true);
    }
  }, [getMadridCaseById, initialize, isInitialize, params]);

  useEffect(() => {
    if (!isInitialize && !isEmpty(getMadridCaseById.toJS()) && !isEmpty(params)) {
      initialize({
        ...getMadridCaseById.toJS(),
      });
      setIsInitialize(true);
    }
  }, [getMadridCaseById, initialize, isInitialize, params]);

  useEffect(() => {
    if (!isList && !isEmpty(getMadridCaseById.toJS()?.relations)
      && !isEmpty(params) && !isEmpty(proposalData.toJS().items)) {
      setIsList(true);
      const arrayTM = map(getMadridCaseById.toJS()?.relations || [],
        (id) => find(proposalData.toJS().items, { id }) || { id });
      setBasicTrademarks(find(arrayTM, { madridType: 'basic' }) || arrayTM[0] || {});
      setListTrademarks(arrayTM);
      const emptyData = { items: [], totalCount: 0, pending: false };
      dispatch(tradeMarksActions.setProposalData(emptyData));
    }
  }, [dispatch, getMadridCaseById, isList, params, proposalData]);

  const handleSubmits = (formData) => {
    const json = formData.toJS();
    dispatch(actionAsync[!isEmpty(params) ? 'putMadridCaseByIdAsync' : 'postMadridCaseAsync']({
      ...json,
      relations: uniq([basicTrademarks.id, ...map(listTrademarks, 'id')]),
    }));
  };

  const handleDeleteTrademarks = () => {
    const filterList = filter(listTrademarks, (item) => !includes(selectionTrademarks, item.id));
    setListTrademarks(filterList);
    if (!find(filterList, { id: basicTrademarks.id })) {
      setBasicTrademarks({});
      dispatch(action.setModalAddOpen(true));
    }
    setSelectionTrademarks([]);
  };

  const handleSelectTrademarks = (list) => {
    const filterList = filter(list, (item) => !find(listTrademarks, { id: item.id }));
    const allList = [...listTrademarks, ...filterList];
    dispatch(helpersAction.setModal({ isModalOpenTableEntities: false }));
    if (allList.length > 1) {
      dispatch(action.setModalAddOpen(true));
    } else {
      setBasicTrademarks(allList[0]);
    }
    setListTrademarks(allList);
  };

  const handleAddEntity = () => {
    dispatch(helpersAction.setModal({ isModalOpenTableEntities: true }));
  };

  const handleSetBasicTM = useCallback((trademark) => {
    setBasicTrademarks(trademark);
    dispatch(action.setModalAddOpen(false));
  }, [dispatch]);

  const handleCloseBasicTM = useCallback(() => {
    setBasicTrademarks((prev) => {
      const next = !isEmpty(prev) ? prev : listTrademarks[0];
      return next || {};
    });
  }, [listTrademarks]);

  const columnsTrademarks = [
    { name: 'id', title: t('ID'), customField: 'tmID' },
    { name: 'caseNumber', title: t('Case number') },
    { name: 'title', title: t('Image'), customField: 'titleLogo' },
    { name: 'classes', title: t('Classes') },
    { name: 'jurisdictions', title: t('Jurisdiction') },
    { name: 'applicationNumber', title: t('Application number') },
    { name: 'applicationDate', title: t('Application date') },
    { name: 'registrationDate', title: t('Registration date') },
    { name: 'madridType', title: t('Type') },
    { name: 'status', title: t('Status') },
    { name: 'lastDocuments', title: t('Input document') },
    { name: 'pendingActions', title: t('Action up to') },
    { name: 'applicants', title: t('Applicants') },
    { name: 'holders', title: t('Owners') },
  ];

  const columnsModalTrademarks = [
    { name: 'id', title: t('ID') },
    { name: 'caseNumber', title: t('Case number') },
    { name: 'jurisdictions', title: t('Jurisdiction') },
    { name: 'madridType', title: t('Type') },
    { name: 'status', title: t('Status') },
    { name: 'lastDocuments', title: t('Input document') },
    { name: 'pendingActions', title: t('Action up to') },
  ];

  return !pendingMadridCaseById ? (
    <>
      <Helmet title={t('Madrid application')} />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h3" gutterBottom display="inline">
          {t('Madrid application')}
        </Typography>
        <LinkRouter to={pageLinks.tradeMarksRoutes.madridCases.all}>
          <NavigateBeforeIcon size="small" color="primary" />
          {t('Up to the list')}
        </LinkRouter>
      </Box>
      <Divider my={6} />

      {isModalOpenTableEntities && (
        <TableEntitiesModal
          handleSend={handleSelectTrademarks}
          titleModal="Select trademarks"
          entities="trademarks"
          showTableExtraFilter={false}
          columns={columnsModalTrademarks}
        />
      )}
      {isModalOpen && (
        <BasicMadridCaseModal
          options={listTrademarks}
          handleSend={handleSetBasicTM}
          handleClose={handleCloseBasicTM}
        />
      )}

      <Form onSubmit={handleSubmit(handleSubmits)}>
        <Card mb={6}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              {t('Basic information')}
            </Typography>

            <Grid container spacing={6}>
              <Grid item xs={12} md={4}>
                <Field
                  name="regNumber"
                  id="regNumber"
                  label={t('Number')}
                  margin="normal"
                  variant="standard"
                  fullWidth
                  type="text"
                  component={RenderTextField}
                  disabled={!accessEdit}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Field
                  name="status"
                  id="status"
                  label={t('Status')}
                  labelId="status"
                  items={objectStatuses}
                  fullWidth
                  component={Select}
                  disabled={!accessEdit}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Field
                  name="publicationDate"
                  id="publicationDate"
                  labelId="publicationDate"
                  label={t('Date of publication')}
                  margin="normal"
                  variant="standard"
                  fullWidth
                  // type="text"
                  // formatDate="dd.MM.yyyy"
                  component={DatePickers}
                  disabled={!accessEdit}
                />
              </Grid>

              {!isEmpty(basicTrademarks) && (
                <Grid item xs={12}>
                  <TypographySub variant="subtitle1" gutterBottom>
                    {t('Base trademark')}
                  </TypographySub>
                  <DxTable
                    name="TrademarksMadridCase"
                    columns={columnsTrademarks}
                    rows={[basicTrademarks]}
                    // tableColumnExtensions={tableColumnExtensionsCosts}
                    isLoading={false}
                    disablePaging
                    disableHiddenColumn
                    disableColumnOrder
                    disableColumnWidth
                    disableExport
                    disableMenu
                  />
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>

        <Card mb={6}>
          <CardContent>
            <Typography variant="h6" paragraph>
              {t('Cases')}
            </Typography>

            {accessEdit && (
              <Box mt={4}>
                <ButtonGroup color="primary" aria-label="contained primary button group">
                  {accessTrademarksListGet && (
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleAddEntity}
                    >
                      {t('ADD')}
                      <AddIcon />
                    </Button>
                  )}
                  {!isEmpty(selectionTrademarks) && (
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleDeleteTrademarks}
                    >
                      {t('DELETE SELECTED')}
                    </Button>
                  )}
                </ButtonGroup>
              </Box>
            )}
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <DxTable
                  name="TrademarksMadridCase"
                  columns={columnsTrademarks.filter((el) => el.name !== 'title')}
                  rows={listTrademarks}
                  // tableColumnExtensions={tableColumnExtensionsCosts}
                  isLoading={false}
                  disablePaging
                  {...accessEdit ? {
                    selection: selectionTrademarks,
                    onSelectionChange: setSelectionTrademarks,
                  } : {}}
                  disableHiddenColumn
                  disableColumnOrder
                  disableColumnWidth
                  disableExport
                  disableMenu
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Box mt={2}>
          {accessEdit && (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              mr={2}
            >
              {pending ? <CircularProgress size={25} color="inherit" /> : t('SAVE')}
            </Button>
          )}
        </Box>
      </Form>
    </>
  ) : <Loader />;
}

MadridCase.displayName = 'MadridCase';

MadridCase.propTypes = {
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  getMadridCaseById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingMadridCaseById: PropTypes.bool.isRequired,
  pendingPostMadridCase: PropTypes.bool.isRequired,
  pendingPutMadridCase: PropTypes.bool.isRequired,
  match: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  isModalOpenTableEntities: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  objectStatuses: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  proposalData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

MadridCase.defaultProps = {
  match: {},
};

function mapStateToProps(state) {
  return {
    getMadridCaseById: selectors.madridCase.getMadridCaseById(state),
    pendingMadridCaseById: selectors.madridCase.getPendingMadridCaseById(state),
    pendingPostMadridCase: selectors.madridCase.pendingPostMadridCase(state),
    pendingPutMadridCase: selectors.madridCase.pendingPutMadridCase(state),
    isModalOpen: selectors.madridCase.isModalAddOpen(state),
    objectStatuses: selectors.helpers.getObjectStatuses(state),
    isModalOpenTableEntities: selectors.helpers.isModalOpenTableEntities(state),
    proposalData: selectors.tradeMarksProposal.proposalData(state),
  };
}

export default compose(
  reduxForm({
    form: 'MadridCase',
  }),
  connect(mapStateToProps, null),
)(memo(MadridCase));
