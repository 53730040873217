// Core
import {
  useCallback, useEffect, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

// lodash
import { isEmpty } from 'lodash';

// actions
import asyncActions from '../../../../../engine/core/pendingActions/table/saga/asyncAction';
import pendingActions from '../../../../../engine/core/pendingActions/action';

// config
import selectors from '../../../../../engine/config/selectors';
import { listEntityName } from '../../../../../engine/config/listTablesName';

// Hook
import { useParamsRequest } from '../../../../../ui/_hooks/useParamsRequest';

const entityName = listEntityName.pendingActions;

export function useNotificationsTasks() {
  const currentPage = useSelector(selectors.pendingActionsTable.currentPage);
  const limit = useSelector(selectors.pendingActionsTable.pageSize);
  const filters = useSelector(selectors.pendingActionsTable.filters);
  const sorting = useSelector(selectors.pendingActionsTable.sorting);
  const reload = useSelector(selectors.pendingActionsTable.reload);
  const disableReload = useSelector(selectors.pendingActions.disableReload);
  const routeKey = useSelector(selectors.router.key);
  const path = useSelector(selectors.router.pathname);
  const fieldsEntity = useSelector(selectors.fieldsSetting.fieldsEntity);
  const dispatch = useDispatch();
  const { orderBy, paramsByFilter } = useParamsRequest({ filters, sorting, entityName });

  const getListAsync = useCallback(() => {
    const params = {
      ...paramsByFilter,
      ...orderBy,
      limit,
      page: currentPage + 1,
    };
    dispatch(asyncActions.getListAsync(params));
  }, [paramsByFilter, currentPage, orderBy, limit, dispatch]);

  const getInitialStateAsync = useCallback(() => {
    dispatch(asyncActions.getInitialStateAsync(path));
    dispatch(pendingActions.setPendingActionById({
      data: {},
    }));
  }, [dispatch, path]);

  const getInitialState = useRef(true);

  useEffect(() => {
    if (getInitialState.current && !disableReload) {
      getInitialStateAsync();
      getInitialState.current = false;
    }

    if (reload && !isEmpty(fieldsEntity.toJS())) {
      getListAsync();
    }
  }, [getListAsync,
    getInitialStateAsync, disableReload,
    reload, fieldsEntity, routeKey]);
}
