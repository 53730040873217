// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import types from './types';

const actions = Object.freeze({
  setProductCategoryById(data) {
    return actionCreator(types.SET_PRODUCT_CATEGORY_BY_ID, data);
  },
  setPendingProductCategoryData(pending) {
    return actionCreator(types.SET_PENDING_PRODUCT_CATEGORY_DATA, pending);
  },
  setProductCategoryData(data) {
    return actionCreator(types.SET_PRODUCT_CATEGORY_DATA, data);
  },
  setPendingDeleteProductCategory(data) {
    return actionCreator(types.SET_PENDING_DELETE_PRODUCT_CATEGORY, data);
  },
  setIsModalOpen(data) {
    return actionCreator(types.SET_PRODUCT_CATEGORY_IS_MODAL_OPEN, data);
  },
  setDefaultName(name) {
    return actionCreator(types.SET_PRODUCT_CATEGORY_DEFAULT_NAME, name);
  },
  setNewProductCategory(name) {
    return actionCreator(types.SET_NEW_PRODUCT_CATEGORY, name);
  },
});

export default actions;
