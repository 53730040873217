// Core
import React, { useCallback, memo } from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';

// lodash
import isEqual from 'lodash/isEqual';

// UI
import {
  Typography, Paper as MuiPaper,
} from '@material-ui/core';
import {
  Grid, PagingPanel, Table,
  TableFilterRow, TableHeaderRow,
  TableSelection,
} from '@devexpress/dx-react-grid-material-ui';
import {
  PagingState, SelectionState,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedFiltering,
  FilteringState,
} from '@devexpress/dx-react-grid';

// styles
const Paper = styled(MuiPaper)`
  .MuiInputBase-root {
    font-size: 14px;
  }
`;

function SimpleTable(props) {
  const {
    rows,
    columns,
    selection,
    onSelectionChange,
    tableColumnExtensions,
  } = props;
  const getRowId = useCallback((row) => row.id, []);

  return (
    <Paper>
      <Grid
        rows={rows}
        columns={columns}
        getRowId={getRowId}
      >
        <PagingState
          defaultCurrentPage={0}
          defaultPageSize={20}
        />
        <FilteringState defaultFilters={[]} />

        <SelectionState
          selection={selection}
          onSelectionChange={onSelectionChange}
        />

        <IntegratedFiltering />
        <IntegratedPaging />

        <Table
          columnExtensions={tableColumnExtensions}
        />

        <TableHeaderRow
          showSortingControls={false}
          titleComponent={({ children }) => (
            <Typography noWrap>
              <strong>{children}</strong>
            </Typography>
          )}
        />
        <TableFilterRow />

        <IntegratedSelection />

        <TableSelection
          showSelectAll
        />

        <PagingPanel
          messages={{ rowsPerPage: '' }}
          pageSizes={[20, 50, 100]}
        />
      </Grid>
    </Paper>
  );
}

SimpleTable.defaultProps = {
  rows: [],
  columns: [],
  selection: [],
  tableColumnExtensions: [],
};
SimpleTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  selection: PropTypes.arrayOf(PropTypes.string),
  onSelectionChange: PropTypes.func.isRequired,
  tableColumnExtensions: PropTypes.arrayOf(PropTypes.object),
};

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.columns, nextProps.columns)
  && isEqual(prevProps.name, nextProps.name)
  && isEqual(prevProps.rows, nextProps.rows)
  && isEqual(prevProps.selection, nextProps.selection)
  && isEqual(prevProps.tableColumnExtensions, nextProps.tableColumnExtensions);
}

export default memo(SimpleTable, areEqual);
