// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import types from './types';

const employeesActions = Object.freeze({
  setEmployeeById(employee) {
    return actionCreator(types.SET_EMPLOYEE_BY_ID, employee);
  },
  setResponsibleEmployeeById(employee) {
    return actionCreator(types.SET_RESPONSIBLE_EMPLOYEE_BY_ID, employee);
  },
  setEmployees(employeesData) {
    return actionCreator(types.SET_EMPLOYEES, employeesData);
  },
  setDeleteEmployee(data) {
    return actionCreator(types.SET_DELETE_EMPLOYEE, data);
  },
  setAutocompleteOptions(employeesData) {
    return actionCreator(types.SET_EMPLOYEES_AUTOCOMPLETE_OPTIONS, employeesData);
  },
  setPendingAutocompleteOptions(pending) {
    return actionCreator(types.SET_PENDING_EMPLOYEES_AUTOCOMPLETE_OPTIONS, pending);
  },
  setKeysData(keysData) {
    return actionCreator(types.SET_KEYS_DATA, keysData);
  },
  setCskData(cskData) {
    return actionCreator(types.SET_CSK_DATA, cskData);
  },
  setListResponsible(data) {
    return actionCreator(types.SET_LIST_RESPONSIBLE, data);
  },
});

export default employeesActions;
