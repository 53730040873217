export default Object.freeze({
  SET_CLIENTS_DATA: 'SET_CLIENTS_DATA',
  SET_CLIENTS_CURRENT_PAGE: 'SET_CLIENTS_CURRENT_PAGE',
  SET_CLIENTS_PAGE_SIZE: 'SET_CLIENTS_PAGE_SIZE',
  SET_CLIENTS_FILTERS: 'SET_CLIENTS_FILTERS',
  SET_CLIENTS_SORTING: 'SET_CLIENTS_SORTING',
  SET_CLIENTS_INITIAL_STATE: 'SET_CLIENTS_INITIAL_STATE',
  SET_CLIENTS_RELOAD: 'SET_CLIENTS_RELOAD',
  SET_CLIENTS_COLUMN_WIDTHS: 'SET_CLIENTS_COLUMN_WIDTHS',
  SET_CLIENTS_COLUMN_ORDER: 'SET_CLIENTS_COLUMN_ORDER',
  SET_CLIENTS_HIDDEN_COLUMN_NAMES: 'SET_CLIENTS_HIDDEN_COLUMN_NAMES',
});
