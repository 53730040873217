// Helpers
import actionCreator from '../../../_helpers/actionCreator';
// Types
import types from './types';

const clientsActions = Object.freeze({
  setClientsData(clientsData) {
    return actionCreator(types.SET_CLIENT_DATA, clientsData);
  },
  setClientById(client) {
    return actionCreator(types.SET_CLIENT_BY_ID, client);
  },
  setDeleteClient(data) {
    return actionCreator(types.SET_DELETE_CLIENT, data);
  },
  setAutocompleteOptions(client) {
    return actionCreator(types.SET_CLIENT_AUTOCOMPLETE_OPTIONS, client);
  },
  setPendingAutocompleteOptions(pending) {
    return actionCreator(types.SET_PENDING_CLIENT_AUTOCOMPLETE_OPTIONS, pending);
  },
  setPendingPostMerge(pending) {
    return actionCreator(types.SET_PENDING_POST_MERGE_CLIENTS, pending);
  },
  setIsModalOpenMerge(pending) {
    return actionCreator(types.SET_IS_MODAL_OPEN_MERGE_CLIENTS, pending);
  },
  setIsModalOpenAddress(pending) {
    return actionCreator(types.SET_IS_MODAL_OPEN_ADDRESS_CLIENTS, pending);
  },
  setPendingSentCredentials(pending) {
    return actionCreator(types.SET_PENDING_CLIENTS_SENT_CREDENTIALS, pending);
  },
});

export default clientsActions;
