// Helpers
import actionCreator from '../../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const asyncActions = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_SEARCHES_ASYNC, params);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_SEARCHES_INITIAL_STATE_ASYNC, path);
  },
  getSearchByIdAsync(id) {
    return actionCreator(asyncTypes.GET_SEARCH_BY_ID_ASYNC, id);
  },
  setTabsAsync(results) {
    return actionCreator(asyncTypes.SET_TABS_ASYNC, results);
  },
  setNumberPerPageAsync(nameTab, count) {
    return actionCreator(asyncTypes.SET_NUMBER_PER_PAGE_ASYNC, {
      nameTab, count,
    });
  },
  postSearchAsync(searchData) {
    return actionCreator(asyncTypes.POST_SEARCH_ASYNC, searchData);
  },
  postQuickSearch(searchData) {
    return actionCreator(asyncTypes.POST_QUICK_SEARCH_ASYNC, searchData);
  },
  putSearchByIdAsync(searchData) {
    return actionCreator(asyncTypes.PUT_SEARCH_BY_ID_ASYNC, searchData);
  },
  getSearchReportAsync(id) {
    return actionCreator(asyncTypes.GET_SEARCH_REPORT_DATA_ASYNC, id);
  },
  deleteSearchAsync() {
    return actionCreator(asyncTypes.DELETE_SEARCH_ASYNC);
  },
});

export default asyncActions;
