// Helpers
import actionCreator from '../../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const asyncActions = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_REBOUNDS_ASYNC, params);
  },
  getReboundsAutocompleteAsync(params) {
    return actionCreator(asyncTypes.GET_REBOUNDS_AUTOCOMPLETE_ASYNC, params);
  },
  getReboundByIdWorker(id) {
    return actionCreator(asyncTypes.GET_REBOUND_BY_ID_ASYNC, id);
  },
  getReboundPdfByIdWorker(id) {
    return actionCreator(asyncTypes.GET_REBOUND_PDF_BY_ID_ASYNC, id);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_REBOUNDS_INITIAL_STATE_ASYNC, path);
  },
  postReboundAsync(rebound) {
    return actionCreator(asyncTypes.POST_REBOUND_ASYNC, rebound);
  },
  searchReboundAsync(search) {
    return actionCreator(asyncTypes.SEARCH_REBOUND_ASYNC, search);
  },
  putReboundByIdAsync(rebound) {
    return actionCreator(asyncTypes.PUT_REBOUND_BY_ID_ASYNC, rebound);
  },
  deleteReboundAsync() {
    return actionCreator(asyncTypes.DELETE_REBOUND_ASYNC);
  },
  deleteReboundByIdAsync(id) {
    return actionCreator(asyncTypes.DELETE_REBOUND_BY_ID_ASYNC, id);
  },
});

export default asyncActions;
