// Helpers
import actionCreator from '../../_helpers/actionCreator';
// Types
import types from './types';

const actions = Object.freeze({
  setTagById(data) {
    return actionCreator(types.SET_TAGS_BY_ID, data);
  },
  setPendingTagsData(pending) {
    return actionCreator(types.SET_PENDING_TAGS_DATA, pending);
  },
  setTagsData(data) {
    return actionCreator(types.SET_TAGS_DATA, data);
  },
  setPendingDeleteTag(data) {
    return actionCreator(types.SET_PENDING_DELETE_TAG, data);
  },
  setIsModalOpen(data) {
    return actionCreator(types.SET_TAGS_IS_MODAL_OPEN, data);
  },
  setDefaultName(name) {
    return actionCreator(types.SET_TAG_DEFAULT_NAME, name);
  },
  setNewTag(name) {
    return actionCreator(types.SET_NEW_TAG, name);
  },
});

export default actions;
