// Налаштування бібліотеки
export const euSettings = {
  language: 'uk',
  encoding: 'utf-8',
  httpProxyServiceURL: 'https://staging.lexberry.pro/proxy',
  directAccess: true,
  CAs: '/data/CAs.json',
  CACertificates: '/data/CACertificates.p7b',
  allowedKeyMediaTypes: [
    'е.ключ ІІТ Алмаз-1К',
    'е.ключ ІІТ Кристал-1',
    'ID-карта громадянина (БЕН)',
    'е.ключ ІІТ Алмаз-1К (PKCS#11)',
    'е.ключ ІІТ Кристал-1 (PKCS#11)',
  ],
};
