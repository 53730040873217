// Core
/* eslint-disable */
import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import get from 'lodash/get';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

// UI
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

// Icons
import ClearIcon from '@material-ui/icons/Clear';
import DateRangeIcon from '@material-ui/icons/DateRange';

// Parts
import DataRangeModal from './DataRangeModal';


// _helpers
import { formatDate, isSame } from '../_helpers/moment';

class RenderDateRange extends Component {
  static propTypes = {
    clearButton: PropTypes.bool,
    filters: PropTypes.object,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    t: PropTypes.func,
  };

  static defaultProps = {
    clearButton: true,
    filters: {},
    fullWidth: true,
    disabled: false,
    onChange: () => {

    }
  };

  state = {
    showRangeModal: false,
  };

  handleDataRangeToggle = () => {
    const { disabled } = this.props;
    if (disabled) {
      return;
    }
    this.setState(prevState => ({
      showRangeModal: !prevState.showRangeModal,
    }));
  };

  onDateRangeChange = (ranges) => {
    if (ranges && ranges?.endDate !== ranges?.startDate) {
      this.setState(prevState => ({
        showRangeModal: false,
      }));
    }
    const { onChange } = this.props;
    if (typeof onChange === 'function') {
      onChange(ranges);
    }
  };

  computedValue = () => {
    const { filters } = this.props;

    const endDate = get(filters, 'value.endDate', new Date());
    const startDate = get(filters, 'value.startDate', new Date());

    const formattedStartDate = formatDate(startDate, 'DD.MM.YYYY');
    const formattedEndDate = formatDate(endDate, 'DD.MM.YYYY');

    switch (true) {
      case !filters:
      case get(filters, 'value') === null: {
        return '';
      }
      case isSame(formattedStartDate, formattedEndDate): {
        return formatDate(startDate, 'DD.MM.YYYY');
      }
      default: {
        return `${formatDate(startDate, 'DD.MM.YYYY')} - ${formatDate(endDate, 'DD.MM.YYYY')}`;
      }
    }
  };

  clearField = (e) => {
    e.stopPropagation();
    this.onDateRangeChange(null);
  };

  render() {
    const { showRangeModal } = this.state;

    const {
      clearButton, fullWidth, filters, onChange, t, disabled,
    } = this.props;

    const endDate = get(filters, 'value.endDate', new Date());
    const startDate = get(filters, 'value.startDate', new Date());
    const value = this.computedValue();
    return (
      <>
        <TextField
          fullWidth={fullWidth}
          placeholder={t('Filter')}
          onChange={onChange}
          onClick={this.handleDataRangeToggle}
          disabled={disabled}
          autoComplete="off"
          InputProps={{
            value,
            readOnly: true,
            startAdornment: (
              <InputAdornment position="end">
                <span >
                  <DateRangeIcon />
                </span>
              </InputAdornment>
            ),
            endAdornment: clearButton && (value !== '') && (
              <InputAdornment position="end">
                <IconButton  aria-label="Clear filter" onClick={this.clearField} component="span">
                  <ClearIcon  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <DataRangeModal
          disabled={disabled}
          title={t('Filter by date')}
          open={showRangeModal}
          handleDataRangeToggle={this.handleDataRangeToggle}
          onDateRangeChange={this.onDateRangeChange}
          startDate={startDate}
          endDate={endDate}
        />
      </>
    );
  }
}

export default compose(
  withTranslation(),
)(RenderDateRange);
