// Core
import React, {
  useEffect, memo, useState, useMemo, useCallback,
} from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// lodash
import isEmpty from 'lodash/isEmpty';

// ui
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';

// actions
// import pendingActionsAsync from '../../engine/core/pendingActions/saga/asyncAction';
import tableAsyncActions from '../../engine/core/pendingActions/table/saga/asyncAction';

// parts
import ActionsPage from '../../pages/ActionsPage/ActionsPage';

// config
import selectors from '../../engine/config/selectors';

function ProposalCalendar(props) {
  const {
    match, filters, actionId, filtersAll,
    reloadTable, disableFilterId,
  } = props;
  const dispatch = useDispatch();
  const { params } = match;
  const { t } = useTranslation();
  const [defaultStatus, setDefaultStatus] = useState(true);
  const blockRetrievalRequest = false;

  const paramsByFilter = useMemo(() => (
    JSON.parse(JSON.stringify(filters)).reduce((acc, { columnName, value }) => {
      acc[columnName] = value;
      return acc;
    }, {})
  ), [filters]);

  const getList = useCallback(() => {
    dispatch(tableAsyncActions.getListAsync({
      ...!disableFilterId ? {
        [actionId]: params.hashId,
      } : {},
      ...filtersAll,
      ...paramsByFilter,
      ...defaultStatus ? {
        status: 1,
      } : {},
      isCard: true,
    }));
  }, [
    dispatch, actionId, params, paramsByFilter,
    defaultStatus, disableFilterId, filtersAll,
  ]);

  useEffect(() => {
    if (!isEmpty(params.hashId)) {
      getList();
      if (defaultStatus) setDefaultStatus(false);
    }
  }, [params]);// eslint-disable-line

  useEffect(() => {
    if (reloadTable) {
      getList();
    }
  }, [reloadTable]);// eslint-disable-line

  return (
    <>
      {
        !isEmpty(params.hashId)
        && <ActionsPage match={match} blockRetrievalRequest={blockRetrievalRequest} />
      }
      {isEmpty(params.hashId) && (
        <Card mb={6}>
          <CardContent>
            {t('No data')}
          </CardContent>
        </Card>
      )}
    </>
  );
}

ProposalCalendar.displayName = 'ProposalCalendar';

ProposalCalendar.propTypes = {
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  match: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  filtersAll: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  actionId: PropTypes.string.isRequired,
  // reload: PropTypes.bool.isRequired,
  reloadTable: PropTypes.bool.isRequired,
  disableFilterId: PropTypes.bool,
};

ProposalCalendar.defaultProps = {
  match: {},
  disableFilterId: false,
  filtersAll: {},
};

function mapStateToProps(state) {
  return {
    filters: selectors.pendingActionsTable.filters(state),
    // isModalOpenAction: selectors.pendingActions.isModalOpenAction(state),
    // reload: selectors.pendingActions.reload(state),
    reloadTable: selectors.pendingActionsTable.reload(state),
  };
}

export default compose(
  connect(mapStateToProps, null),
)(memo(ProposalCalendar));
