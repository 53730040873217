/* eslint-disable */
// Core
import {
  reduce, isArray, isObject, isNumber, isEmpty,
  pickBy, split, keys, omit, indexOf, isString,
} from 'lodash';

// _helpers
import { formatDate, formatDateByFilter } from './moment';

// Data

/**
 * Create URL params from array of values
 * e.g [{ value: 1 }, { value: 2 }] will convert to string '1,2'
 * @params {Array.<value: Number>} fieldValue
 * @return {string|undefined}
 * */
export function convertSelectFieldToUrlParams(fieldValue = [], key = '') {
  if (Array.isArray(fieldValue) && fieldValue.length > 0) {
    if (key.slice(-2) === 'Id') {
      return fieldValue.reduce((acc, { id }) => {
        acc.push(id);
        return acc;
      }, []);
    }
    return fieldValue.reduce((acc, item) => {
      acc.push(isObject(item) ? item.label : item);
      return acc;
    }, []);
  }
  if (typeof fieldValue === 'object') {
    if (key.slice(-2) === 'id') {
      return fieldValue.id;
    }
    if (fieldValue.value === undefined) {
      return fieldValue.label;
    }
    return fieldValue.value;
  }
  if (typeof fieldValue === 'number' || typeof fieldValue === 'string') {
    return fieldValue.toString();
  }
  if (typeof fieldValue === 'boolean') {
    return fieldValue;
  }
}

/**
 * Create URL params from array of values
 * e.g [{ columnName: name, direction: asc }] will convert to string 'name ASC'
 * @params {Array.<columnName: String>, direction: <('asc'|'desc')>} sorting
 * @return {string|undefined}
 * */
export function convertSortingObjectToUrlParams(sorting = []) {
  if (Array.isArray(sorting) && sorting.length > 0) {
    const { columnName, direction } = sorting[0];
    return ({
      sortBy: columnName,
      sortDir: direction,
    });
  }
}

/**
 * Create Array of values [datetimeFrom, datetimeTo]
 * @param {{ startDate: String, endDate: String }} datetime
 * @param {String} format - date format to be converted to
 * @return {String[]}
 * */
export function convertDateTimeFieldToUrlParams(datetime, format = 'YYYY-MM-DD') {
  let startDate;
  let endDate;
  if (datetime) {
    const { start, end } = formatDateByFilter(datetime.startDate, datetime.endDate)
    startDate = start;
    endDate = end;
  }
  return [startDate, endDate];
}

const fields = Object.freeze({
  limit: 'limit',
  offset: 'offset',
  page: 'page',
  orderBy: 'orderBy',
  sortBy: 'sortby',
  sortDir: 'sortdir',
});

/**
 * Index datetime FROM convertDateTimeFieldToUrlParams() array
 * @constant {number}
 * */
const FROM = 0;

/**
 * Index datetime TO convertDateTimeFieldToUrlParams() array
 * @constant {number}
 * */
const TO = 1;

const sortingFields = Object.freeze({
  // lastDocuments: 'documents.receivingDate',
  clients: 'clients.name',
  jurisdictions: 'jurisdictions.name',
  tags: 'tags.name',
  // name: 'clients.name',
  industrial: 'industrial.caseNumber',
  inventions: 'inventions.caseNumber',
  trademarks: 'trademarks.caseNumber',
  utility: 'utility.caseNumber',
  documentClassifier: 'documentClassifier.name',
});

const filterFields = Object.freeze({
  lastDocuments: 'documents.receivingDate',
  'query.keywords': 'query',
  'query.classes': 'query',
  'group.id': 'group.title',
  clientsApplicant: 'clients.id',
  clientName: 'clients.name',
  costscategories: 'category.id',
  clientsId: 'clients.id',
  trademarksId: 'trademarks.id',
  utilityId: 'utility.id',
  inventionsId: 'inventions.id',
  industrialId: 'industrial.id',
});

const typeFields = Object.freeze({
  '=': 'eq',
  '!=': 'not eq',
  'like': 'like',
  'not like': 'not like',
  in: 'in',
  isNull: 'is null',
  between: 'between',
  '>': 'gt',
  '<': 'lt',
});

export function convertJSObjectToUrlParams(args) {
  const filteredArgs = omit(args || {}, ['entity', 'limit', 'page', 'sortDir', 'sortBy', 'connector', 'caseNumberGroup']);
  const keysArgs = keys(filteredArgs);
  return reduce(args, (result, value, key) => {
    const trimKey = split(key, ':indexRequest:')[0];
    const typeRequest = split(trimKey, ':typeRequest:')[1];
    const getSliceKey = split(trimKey, ':typeRequest:')[0];
    const splitKey = split(getSliceKey, '.')[1] || split(getSliceKey, '.')[0];
    const newKey = filterFields[getSliceKey] ? filterFields[getSliceKey] : getSliceKey;
    const filterIndex = keysArgs.length > 1 ? indexOf(keysArgs, key) : '';
    const textIndex = isNumber(filterIndex) ? `[${filterIndex}]` : `[${args.entity}.${newKey}]`;
    switch (splitKey) {
      case 'caseNumberGroup':
      case 'connector': {
        break;
      }
      case 'entity': {
        if (keysArgs.length > 1) {
          result['simple'] = '0';
        }
        break;
      }
      case 'limit':
      case 'page':
      case 'sortDir': {
        result[fields[trimKey]] = value;
        break;
      }
      case 'sortBy': {
        result[fields[trimKey]] = `${args.entity}.${sortingFields[value] ? sortingFields[value] : value}`;
        break;
      }
      case 'file': {
        result[splitKey] = value;
        break;
      }
      case 'actionDate':
      case 'registrationDate':
      case 'applicationDate':
      case 'createdAt':
      case 'paymentDate':
      case 'documentDate':
      case 'expiryDate':
      case 'expiration':
      case 'dateSent':
      case 'finishAt':
      case 'pendingActions':
      case 'sendAt':
      case 'date':
      case 'applicationAt':
      case 'lastDocuments':
      case 'lastCheckAt':
      case 'nextAction': {
        const arrayDate = isString(value) ? value.split(' - ') : convertDateTimeFieldToUrlParams(value);
        if (isNumber(filterIndex)) result[`filter${textIndex}[field]`] = `${args.entity}.${newKey}`;
        result[`filter${textIndex}[type]`] = typeFields[typeRequest] || typeFields.between;
        result[`filter${textIndex}[x]`] = arrayDate[FROM];
        result[`filter${textIndex}[y]`] = arrayDate[TO];
        if (filterIndex) result[`filter${textIndex}[connector]`] = args.connector?.[filterIndex] || 'and';
        break;
      }
      case 'renewal': {
        const arrayDate = isString(value) ? value.split(' - ') : convertDateTimeFieldToUrlParams(value);
        if (isNumber(filterIndex)) result[`filter${textIndex}[field]`] = `${args.entity}.${newKey}`;
        result[`filter[${args.entity}.renewalDateTo][type]`] = 'lt';
        result[`filter[${args.entity}.renewalDateTo][x]`] = arrayDate[FROM];
        result[`filter[${args.entity}.renewalDateFrom][type]`] = 'gt';
        result[`filter[${args.entity}.renewalDateFrom][x]`] = arrayDate[TO];
        if (filterIndex) result[`filter${textIndex}[connector]`] = args.connector?.[filterIndex] || 'and';
        break;
      }

      default: {
        const convertedValue = convertSelectFieldToUrlParams(value, getSliceKey);
        if (isArray(value) && isEmpty(value)) break;
        if (newKey === 'classes') {
          result[`filter${textIndex}`] = convertedValue;
          break;
        }
        if (isNumber(filterIndex)) result[`filter${textIndex}[field]`] = `${args.entity}.${newKey}`;
        result[`filter${textIndex}[type]`] = typeFields[typeRequest] || typeFields['='];
        if (typeRequest !== 'isNull') result[`filter${textIndex}[x]`] = convertedValue;
        if (filterIndex) result[`filter${textIndex}[connector]`] = args.connector?.[filterIndex] || 'and';
      }
    }

    // Иногда проходят фильтры с значениями (undefined, null). Фильтрация на корректные данные
    return pickBy(result, (value) => {
      const checkString = isString(value) && value;
      const checkObject = !isArray(value) && isObject(value) && !isEmpty(value);
      const checkArray = isArray(value) && value[0];
      const checkNumber = isNumber(value);
      const checkIsBoll = typeof value === 'boolean' && value;
      return checkString || checkObject || checkArray || checkNumber || checkIsBoll;
    });
  }, {});
}

export function convertJSObjectToSearchUrlParams(args) {
  return reduce(args, (result, value, key) => {
    switch (key) {
      case 'entity': {
        break;
      }
      default: {
        result['search'] = `${args.entity}.${key}`;
        result[`filter[${args.entity}.${key}]`] = convertSelectFieldToUrlParams(value);
        break;
      }
    }
    return result;
  }, {});
}
