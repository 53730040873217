// Helpers
import actionCreator from '../../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const asyncActions = Object.freeze({
  getListMessagesAsync(params) {
    return actionCreator(asyncTypes.GET_EMAIL_MESSAGES_ASYNC, params);
  },
  getMessagesInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_EMAIL_MESSAGES_INITIAL_STATE_ASYNC, path);
  },
  getThreadsMessagesAsync(params) {
    return actionCreator(asyncTypes.GET_EMAIL_THREADS_MESSAGES_ASYNC, params);
  },
  getCheckNewAsync(params) {
    return actionCreator(asyncTypes.GET_EMAIL_CHECK_NEW_ASYNC, params);
  },
  getCheckEmailAsync(params) {
    return actionCreator(asyncTypes.GET_CHECK_EMAIL_ASYNC, params);
  },

  postEmailSendAsync(data) {
    return actionCreator(asyncTypes.POST_EMAIL_SEND_ASYNC, data);
  },

  postEmailPreviewAsync(data) {
    return actionCreator(asyncTypes.POST_EMAIL_PREVIEW_ASYNC, data);
  },
  getDownloadAttachmentAsync(data) {
    return actionCreator(asyncTypes.GET_EMAIL_DOWNLOAD_ATTACHMENT_ASYNC, data);
  },

  getEmailSignaturesAsync(params) {
    return actionCreator(asyncTypes.GET_EMAIL_SIGNATURES_ASYNC, params);
  },
  getEmailSignatureByIdAsync(id) {
    return actionCreator(asyncTypes.GET_EMAIL_SIGNATURES_BY_ID_ASYNC, id);
  },
  deleteEmailSignatureAsync(id) {
    return actionCreator(asyncTypes.DELETE_EMAIL_SIGNATURES_ASYNC, id);
  },
  postEmailSignatureAsync(data) {
    return actionCreator(asyncTypes.POST_EMAIL_SIGNATURES_ASYNC, data);
  },
  putEmailSignatureByIdAsync(data) {
    return actionCreator(asyncTypes.PUT_EMAIL_SIGNATURES_BY_ID_ASYNC, data);
  },

  getEmailsAsync(params) {
    return actionCreator(asyncTypes.GET_EMAIL_ASYNC, params);
  },
  getEmailByIdAsync(id) {
    return actionCreator(asyncTypes.GET_EMAIL_BY_ID_ASYNC, id);
  },
  deleteEmailAsync(id) {
    return actionCreator(asyncTypes.DELETE_EMAIL_ASYNC, id);
  },
  postEmailAsync(data) {
    return actionCreator(asyncTypes.POST_EMAIL_ASYNC, data);
  },
  putEmailByIdAsync(data) {
    return actionCreator(asyncTypes.PUT_EMAIL_BY_ID_ASYNC, data);
  },

  getEmailLettersAsync(params) {
    return actionCreator(asyncTypes.GET_EMAIL_LETTERS_ASYNC, params);
  },
  getEmailLetterByIdAsync(id) {
    return actionCreator(asyncTypes.GET_EMAIL_LETTERS_BY_ID_ASYNC, id);
  },
  deleteEmailLetterAsync() {
    return actionCreator(asyncTypes.DELETE_EMAIL_LETTERS_ASYNC);
  },
  postEmailLetterAsync(data) {
    return actionCreator(asyncTypes.POST_EMAIL_LETTERS_ASYNC, data);
  },

});

export default asyncActions;
