// Core
import { put, select } from 'redux-saga/effects';

// lodash
import map from 'lodash/map';
import filter from 'lodash/filter';
import find from 'lodash/find';
import reduce from 'lodash/reduce';
import uniqBy from 'lodash/uniqBy';
import isArray from 'lodash/isArray';

// config
import selectors from '../../../../config/selectors';

// Engine
import actions from '../../action';

export function* callSetSelectionsWorker({ payload }) {
  const {
    setSelections,
  } = actions;
  const {
    selected, tab, child, checked,
  } = payload;
  const chargesData = yield select(selectors.charges.chargesData);

  const getSelections = () => {
    switch (tab) {
      case 1: {
        return {
          fees: selected,
        };
      }
      case 2: {
        return {
          services: selected,
        };
      }
      case 3: {
        return {
          works: selected,
        };
      }
      default:
        return {
          all: selected,
        };
    }
  };

  const getSum = (selections) => {
    const fullSectionsData = map(selections.fullSectionsData, (data) => data.sum);
    return reduce(fullSectionsData, (sum, n) => sum + Number(n), 0);
  };
  const removeDuplicates = (items) => uniqBy(items);
  const filterUnnecessaryData = (items) => filter(items, (item, key) => {
    const notWorks = (isArray(item) && key !== 'works');
    const notCollectedSections = (isArray(item) && key !== 'collectedSections');
    const notFullSectionsData = (isArray(item) && key !== 'fullSectionsData');
    return notWorks && notCollectedSections && notFullSectionsData;
  });
  const getCounts = (selections) => {
    const counts = map(filterUnnecessaryData(selections), (value) => value.length);
    return reduce(counts, (sum, n) => sum + Number(n), 0);
  };

  const joinSelectionsIds = (selections) => {
    const filterChildren = filterUnnecessaryData(selections);
    return removeDuplicates(reduce(filterChildren, (result, value) => [
      ...result, ...value,
    ], []));
  };

  const joinFullSelectionsData = (childrenIds) => {
    const found = (id) => find(chargesData.toJS().items, { id });
    return map(childrenIds, (id) => found(id));
  };

  // tab "works"
  if (child) {
    const chargesSelections = yield select(selectors.charges.selections);
    const { worksChildren } = chargesSelections.toJS();
    const selectionChildren = !checked
      ? filter(worksChildren, (chargeId) => chargeId !== child.id)
      : worksChildren;
    yield put(setSelections({
      worksChildren: [
        ...selectionChildren,
        ...checked ? [child.id] : [],
      ],
    }));
    const updateChargesSelections = yield select(selectors.charges.selections);
    const collectedSections = joinSelectionsIds(updateChargesSelections.toJS());
    yield put(setSelections({
      chosen: getCounts(updateChargesSelections.toJS()),
      collectedSections,
      fullSectionsData: joinFullSelectionsData(collectedSections),
    }));

    const updateChargesSelections2 = yield select(selectors.charges.selections);
    yield put(setSelections({
      sum: getSum(updateChargesSelections2.toJS()),
    }));

    return;
  }

  yield put(setSelections(getSelections()));

  // tab "works"
  if (tab === 3) {
    const chargesSelections = yield select(selectors.charges.selections);
    const tabs = yield select(selectors.charges.tabs);
    const parentsWorks = map(chargesSelections.toJS().works,
      (id) => find(tabs.toJS().works, { id }));
    const childrenIds = map(parentsWorks, (parent) => map(parent.children, (item) => item.id));
    yield put(setSelections({
      worksChildren: joinSelectionsIds(childrenIds),
    }));
  }

  const chargesSelections = yield select(selectors.charges.selections);
  const collectedSections = joinSelectionsIds(chargesSelections.toJS());
  yield put(setSelections({
    chosen: getCounts(chargesSelections.toJS()),
    collectedSections,
    fullSectionsData: joinFullSelectionsData(collectedSections),
  }));

  const updateChargesSelections = yield select(selectors.charges.selections);
  yield put(setSelections({
    sum: getSum(updateChargesSelections.toJS()),
  }));
}
