// Core
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import Helmet from 'react-helmet';
import { reduxForm } from 'redux-form/immutable';
import * as PropTypes from 'prop-types';
// import { List } from 'immutable';
import { Link as MuiLink } from 'react-router-dom';

// ui
import {
  Typography as MuiTypography, Divider as MuiDivider,
  CardContent, Grid, Card as MuiCard, CircularProgress,
  IconButton as MuiIconButton, Box, Button,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';

// icon
import {
  Add as AddIcon, NavigateBefore as NavigateBeforeIcon,
  Delete as DeleteIcon, Edit as EditIcon,
} from '@material-ui/icons';

// lodash
import {
  map, isEmpty,
} from 'lodash';

// parts
import SignatureModal from './SignatureModal';
import EmailTemplateModal from './EmailTemplateModal';
import EmailModal from './EmailModal';
import ConfirmModal from '../../../components/ConfirmModal';

// actions
import actions from '../../../engine/core/email/email/action';
import asyncAction from '../../../engine/core/email/email/saga/asyncAction';
import emailTemplateAsyncAction from '../../../engine/core/email/emailTemplate/saga/asyncAction';
import emailTemplateActions from '../../../engine/core/email/emailTemplate/action';
import employeesTableActions from '../../../engine/core/company/employees/table/action';
import employeesTableAsyncActions from '../../../engine/core/company/employees/table/saga/asyncAction';
import helpersActions from '../../../engine/core/helpers/action';

// config
import selectors from '../../../engine/config/selectors';

// routes
import { pageLinks } from '../../../routes';
import accessList from '../../../engine/config/accessList';
import { useAccessList } from '../../../ui/_hooks/useAccessList';

// hooks
// import { useEventsAutocompleteAsync } from '../../ui/_hooks/useEventsAutocompleteAsync';

// styles
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const IconButton = styled(MuiIconButton)(spacing);
const TypographySub = styled(MuiTypography)`
  color: ${(props) => props.theme.palette.grey['500']};
`;
const TypographyFlex = styled(MuiTypography)`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;
const Link = styled(MuiLink)`
  text-decoration: underline;
  color: #1976d2;
  display: flex;
  align-items: center;
`;

const formName = 'Settings';

function MailSettings(props) {
  const {
    listSignatures, isModalOpenSignature, isModalOpenTemplate,
    emailTemplateData, isModalOpenEmail, listEmail, pendingDeleteEmail,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [deleteId, setDeleteId] = useState(false);
  const accessEmailSignaturePost = useAccessList(accessList.emailSignature_post);
  const accessEmailSignaturePut = useAccessList(accessList.emailSignature_put);
  const accessEmailSignatureDelete = useAccessList(accessList.emailSignature_delete);
  const accessEmailTemplatePost = useAccessList(accessList.emailTemplate_post);
  const accessEmailTemplatePut = useAccessList(accessList.emailTemplate_put);
  const accessEmailTemplateDelete = useAccessList(accessList.emailTemplate_delete);
  const accessEmailPost = useAccessList(accessList.email_post);
  const accessEmailCheckHost = useAccessList(accessList.email_check_host_post);
  const accessEmailPut = useAccessList(accessList.email_put);
  const accessEmailDelete = useAccessList(accessList.email_delete);

  useEffect(() => function cleanup() {
    dispatch(actions.setListSignatures([]));
    dispatch(actions.setEmails([]));
    dispatch(emailTemplateActions.setEmailTemplateData({
      items: [],
      totalCount: 0,
      pending: false,
    }));
    dispatch(employeesTableActions.setEmployeesData({
      items: [],
      totalCount: 0,
      pending: false,
    }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(asyncAction.getEmailSignaturesAsync());
    dispatch(emailTemplateAsyncAction.getListAsync());
    dispatch(asyncAction.getEmailsAsync());
    dispatch(employeesTableAsyncActions.getListAsync());
  }, [dispatch]);

  const handleNewSignature = () => {
    dispatch(actions.setIsModalOpenSignature(true));
  };

  const handleEditSignature = (signature) => {
    dispatch(asyncAction.getEmailSignatureByIdAsync(signature.id));
    dispatch(actions.setIsModalOpenSignature(true));
  };

  const handleDeleteSignature = (signature) => {
    dispatch(asyncAction.deleteEmailSignatureAsync(signature.id));
  };

  const handleNewTemplate = () => {
    dispatch(emailTemplateActions.setEmailTemplateById({ isModalOpen: true }));
  };

  const handleEditTemplate = (template) => {
    dispatch(emailTemplateAsyncAction.getEmailTemplateByIdAsync(template.id));
    dispatch(emailTemplateActions.setEmailTemplateById({ isModalOpen: true }));
  };

  const handleDeleteTemplate = (template) => {
    dispatch(emailTemplateActions.setDeleteEmailTemplate({ id: template.id }));
    dispatch(emailTemplateAsyncAction.deleteEmailTemplateAsync());
  };

  const handleNewMailbox = () => {
    dispatch(actions.setIsModalOpenEmail(true));
  };

  const handleEditEmail = (email) => {
    dispatch(asyncAction.getEmailByIdAsync(email.id));
    dispatch(actions.setIsModalOpenEmail(true));
  };

  const handleDeleteEmail = (email) => {
    dispatch(helpersActions.setModal({ isModalOpen: true }));
    setDeleteId(email.id);
  };

  return (
    <>
      {isModalOpenSignature && <SignatureModal />}

      {isModalOpenTemplate && <EmailTemplateModal />}

      {isModalOpenEmail && <EmailModal />}

      <ConfirmModal
        buttonSendText="DELETE"
        pending={pendingDeleteEmail}
        description="Are you sure you want to delete the email box?"
        handleSend={() => dispatch(asyncAction.deleteEmailAsync(deleteId))}
      />

      <Helmet title={t('Settings')} />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h3" gutterBottom display="inline">
          {t('Settings')}
        </Typography>
        <Link to={pageLinks.email.all}>
          <NavigateBeforeIcon size="small" color="primary" />
          {t('Up to the list')}
        </Link>
      </Box>

      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            {t('Email boxes connected')}
          </Typography>
          <Grid container spacing={6}>
            {!isEmpty(listEmail.toJS()) && (
              <Grid item xs={12}>
                {map(listEmail.toJS(), (email, index) => (
                  <TypographyFlex key={index}>
                    <Box component="span">
                      {email?.email}
                    </Box>
                    {accessEmailPut && (
                      <IconButton
                        ml={4}
                        aria-label="Edit"
                        size="small"
                        onClick={() => handleEditEmail(email)}
                        disabled={pendingDeleteEmail}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    )}
                    {accessEmailDelete && (
                      <IconButton
                        aria-label="Delete"
                        size="small"
                        onClick={() => handleDeleteEmail(email)}
                        disabled={pendingDeleteEmail}
                      >
                        {pendingDeleteEmail && deleteId === email.id
                          ? <CircularProgress size={20} color="inherit" />
                          : <DeleteIcon fontSize="small" />}
                      </IconButton>
                    )}
                  </TypographyFlex>
                ))}
              </Grid>
            )}
            {accessEmailPost && accessEmailCheckHost && (
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleNewMailbox()}
                >
                  {t('ADD MAIL')}
                  <AddIcon />
                </Button>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>

      <Card mb={6}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            {t('Letter templates')}
          </Typography>
          <TypographySub variant="subtitle1" gutterBottom>
            {t('You can pre-create email templates and use them when sending to the client. Once added, the templates will become available when you send the email')}
          </TypographySub>
          <Grid container spacing={6}>
            {!isEmpty(emailTemplateData.toJS().items) && (
              <Grid item xs={12}>
                {map(emailTemplateData.toJS().items, (template, index) => (
                  <TypographyFlex key={index}>
                    <Box component="span">
                      {template?.subject}
                    </Box>
                    {accessEmailTemplatePut && (
                      <IconButton
                        ml={4}
                        aria-label="Edit"
                        size="small"
                        onClick={() => handleEditTemplate(template)}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    )}
                    {accessEmailTemplateDelete && (
                      <IconButton
                        aria-label="Delete"
                        size="small"
                        onClick={() => handleDeleteTemplate(template)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    )}
                  </TypographyFlex>
                ))}
              </Grid>
            )}
            {accessEmailTemplatePost && (
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleNewTemplate()}
                >
                  {t('ADD TEMPLATE')}
                  <AddIcon />
                </Button>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>

      <Card mb={6}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            {t('Signatures')}
          </Typography>
          <TypographySub variant="subtitle1" gutterBottom>
            {t('Create multiple signatures for your business, for customers and for partners')}
          </TypographySub>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              {map(listSignatures.toJS(), (signature, index) => (
                <TypographyFlex key={index}>
                  <Box component="span">
                    {signature?.employee?.name}
                  </Box>
                  {accessEmailSignaturePut && (
                    <IconButton
                      ml={4}
                      aria-label="Edit"
                      size="small"
                      onClick={() => handleEditSignature(signature)}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  )}
                  {accessEmailSignatureDelete && (
                    <IconButton
                      aria-label="Delete"
                      size="small"
                      onClick={() => handleDeleteSignature(signature)}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  )}
                </TypographyFlex>
              ))}
            </Grid>
            {accessEmailSignaturePost && (
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNewSignature}
                >
                  {t('NEW SIGNATURE')}
                  <AddIcon />
                </Button>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

MailSettings.displayName = 'MailSettings';

MailSettings.propTypes = {
  listSignatures: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  isModalOpenSignature: PropTypes.bool.isRequired,
  isModalOpenTemplate: PropTypes.bool.isRequired,
  isModalOpenEmail: PropTypes.bool.isRequired,
  pendingDeleteEmail: PropTypes.bool.isRequired,
  emailTemplateData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  listEmail: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

MailSettings.defaultProps = {
  // fieldResponsible: {},
};

function mapStateToProps(state) {
  return {
    listSignatures: selectors.email.listSignatures(state),
    isModalOpenSignature: selectors.email.isModalOpenSignature(state),
    emailTemplateData: selectors.emailTemplate.emailTemplateData(state),
    isModalOpenTemplate: selectors.emailTemplate.isModalOpen(state),
    listEmail: selectors.email.listEmail(state),
    isModalOpenEmail: selectors.email.isModalOpenEmail(state),
    pendingDeleteEmail: selectors.email.pendingDeleteEmail(state),
  };
}

export default compose(
  reduxForm({
    form: formName,
  }),
  connect(mapStateToProps, null),
)(MailSettings);
