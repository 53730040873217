// Core
import { call, put } from 'redux-saga/effects';

// config
import { api } from '../../../../../config/api';

// actions
import action from '../../action';

export function* callGetEmailLetterByIdWorker({ payload: id }) {
  const {
    setLetterData, setPendingByIdLetter,
  } = action;
  yield put(setPendingByIdLetter(true));

  const response = yield call(api.emailLetters.getEmailLetterById, id);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    yield put(setLetterData(data));
  }

  yield put(setPendingByIdLetter(false));
}
