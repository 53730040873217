// Core
import { Map } from 'immutable';

// Instruments
import { types } from './types';

// config
import globalStateKeys from '../../config/globalStateKeys';

const initialState = Map({
  data: Map({}),
  fieldsEntity: Map(),
});

export const stateKeys = Object.freeze({
  controller: 'fieldsSetting',
  fieldsEntity: 'fieldsEntity',
});

export const fieldsSettingReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_FIELDS_SETTING_DATA: {
      return state.setIn([globalStateKeys.data], Map(payload));
    }
    case types.SET_FIELDS_ENTITY: {
      return state.setIn([stateKeys.fieldsEntity], Map(payload));
    }

    default:
      return state;
  }
};
