const asyncTypes = Object.freeze({
  GET_CURRENCIES_ASYNC: 'GET_CURRENCIES_ASYNC',
  GET_CURRENCIES_INITIAL_STATE_ASYNC: 'GET_CURRENCIES_INITIAL_STATE_ASYNC',
  POST_CURRENCY_ASYNC: 'POST_CURRENCY_ASYNC',
  PUT_CURRENCY_BY_ID_ASYNC: 'PUT_CURRENCY_BY_ID_ASYNC',
  DELETE_CURRENCY_ASYNC: 'DELETE_CURRENCY_ASYNC',
  GET_CURRENCIES_AUTOCOMPLETE_OPTIONS_ASYNC: 'GET_CURRENCIES_AUTOCOMPLETE_OPTIONS_ASYNC',
  POST_CURRENCY_CALC_ASYNC: 'POST_CURRENCY_CALC_ASYNC',
});

export default asyncTypes;
