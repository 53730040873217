// Core
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

// lodash
import {
  isEmpty, map, find,
} from 'lodash';

// actions
import actions from '../../../engine/core/documentTypes/category/action';
import actionAsync from '../../../engine/core/documentTypes/category/saga/asyncAction';
import helpersActions from '../../../engine/core/helpers/action';

// parts
import DxTable from '../../../ui/Table/DxTable';
import ConfirmModal from '../../../components/ConfirmModal';
import CategoriesModal from './CategoriesModal';

// config
import selectors from '../../../engine/config/selectors';

// hooks
import { listTablesName, listEntityName } from '../../../engine/config/listTablesName';
import { useDocumentTypesCategories } from './_hooks/useDocumentTypesCategories';
import accessList from '../../../engine/config/accessList';

const tableName = listTablesName.documentTypesCategories;
const entityName = listEntityName.documentTypesCategories;

function CategoriesTable(props) {
  const {
    categoriesData, totalCount, pending,
    currentPage, filters, sorting,
    pageSize, pendingDelete,
    checkboxes, isModalOpen, columnWidths,
    columnOrder, hiddenColumnNames,
  } = props;
  useDocumentTypesCategories();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const columns = [
    { name: 'id', title: t('ID') },
    { name: 'name', title: t('Name') },
    { name: 'index', title: t('Category order') },
  ];
  const editingStateColumnExtensionsCosts = [
    { columnName: 'id', editingEnabled: false },
    { columnName: 'name' },
    { columnName: 'index' },
  ];

  const tableColumnExtensions = [
    { columnName: 'id', width: 50 },
    { columnName: 'name', width: '20rem' },
    { columnName: 'index', width: '10rem' },
  ];

  const handleEditingChange = ({ changed }) => {
    const values = Object.values(changed)[0];
    const ids = Object.keys(changed);
    if (!isEmpty(values)) {
      const filterData = map(categoriesData.toJS().items, (item) => (
        item.id === Number(ids[0]) ? { ...item, ...values } : item));
      dispatch(actions.setDocumentTypesCategoryData({
        items: filterData,
        totalCount: filterData.length,
        pending: false,
      }));
      const findItem = find(categoriesData.toJS().items, { id: Number(ids[0]) });
      dispatch(actionAsync.putDocumentTypeCategoryByIdAsync({
        id: findItem?.id,
        name: findItem?.name,
        index: findItem?.index,
        ...!isEmpty(findItem?.documentClassifier) ? {
          documentClassifier: map(findItem.documentClassifier, 'id'),
        } : {},
        ...values,
      }));
      // return;
      // dispatch(helpersAsyncAction.putMultipleAsync({
      //   body: {
      //     entityName: 'documentTypeCategory',
      //     entityIds: ids,
      //     fields: values,
      //   },
      // }));
    }
  };

  // Paging
  const onCurrentPage = (page) => dispatch(actions.setCurrentPage(page));
  const onPageSize = (size) => dispatch(actions.setPageSize(size));
  // Filtering
  const setFilters = (getFilters) => dispatch(actions.setFilters(getFilters));

  // Sorting
  const onSortingChange = (getSorting) => dispatch(
    actions.setSorting(getSorting),
  );
  const onColumnWidthsChange = (getColumnWidths) => dispatch(
    actions.setColumnWidths(getColumnWidths),
  );
  const onColumnOrderChange = (getColumnOrder) => dispatch(
    actions.setColumnOrder(getColumnOrder),
  );
  const onHiddenColumnNamesChange = (getHiddenColumnNames) => dispatch(
    actions.setHiddenColumnNames(getHiddenColumnNames),
  );

  return (
    <>
      <DxTable
        name="Categories"
        isLoading={pending}
        columns={columns}
        rows={categoriesData.toJS().items}
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageSize={onPageSize}
        onCurrentPage={onCurrentPage}
        filtersAction={setFilters}
        onSortingChange={onSortingChange}
        filters={filters}
        // checkboxes ------------------------
        tableName={tableName}
        reloadTable={actions.setReload}
        selection={checkboxes.toJS()}
        onSelectionChange={(checkboxSelect) => {
          dispatch(helpersActions.setCheckbox({
            tableName,
            checkboxes: checkboxSelect,
          }));
        }}
        // ------------------------
        entityName={entityName}
        editingExtensions={editingStateColumnExtensionsCosts}
        onEditingChange={handleEditingChange}
        sorting={sorting}
        isModalOpen={isModalOpen}
        tableColumnExtensions={!isEmpty(columnWidths) && columnWidths.length === columns.length
          ? columnWidths : tableColumnExtensions}
        onColumnWidthsChange={onColumnWidthsChange}
        columnOrder={!isEmpty(columnOrder) ? columnOrder : map(columns, 'name')}
        onColumnOrderChange={onColumnOrderChange}
        hiddenColumnNames={hiddenColumnNames}
        onHiddenColumnNamesChange={onHiddenColumnNamesChange}
        accessEdit={accessList.documentClassifierCategory_put}
        accessDelete={accessList.documentClassifierCategory_delete}
      />

      <ConfirmModal
        buttonSendText="DELETE"
        pending={pendingDelete}
        handleSend={() => {
          dispatch(actionAsync.deleteDocumentTypeCategoryAsync());
        }}
      />
      {isModalOpen && <CategoriesModal />}
    </>
  );
}

CategoriesTable.displayName = 'CategoriesTable';

CategoriesTable.propTypes = {
  categoriesData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  totalCount: PropTypes.number.isRequired,
  pending: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  sorting: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  columnWidths: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  columnOrder: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  hiddenColumnNames: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  pageSize: PropTypes.number.isRequired,
  pendingDelete: PropTypes.bool.isRequired,
  checkboxes: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    categoriesData: selectors.documentTypesCategory.documentTypesCategoryData(state),
    pending: selectors.documentTypesCategory.pending(state),
    pendingDelete: selectors.documentTypesCategory.pendingDeleteDocumentTypeCategory(state),
    isModalOpen: selectors.documentTypesCategory.isModalOpen(state),

    // Paging
    totalCount: selectors.documentTypesCategory.totalCount(state),
    currentPage: selectors.documentTypesCategory.currentPage(state),
    pageSize: selectors.documentTypesCategory.pageSize(state),

    // Filtering
    filters: selectors.documentTypesCategory.filters(state),

    // Sorting
    sorting: selectors.documentTypesCategory.sorting(state),

    // ColumnWidths
    columnWidths: selectors.documentTypesCategory.columnWidths(state),
    columnOrder: selectors.documentTypesCategory.columnOrder(state),
    hiddenColumnNames: selectors.documentTypesCategory.hiddenColumnNames(state),

    checkboxes: selectors.helpers.getCheckboxes(state, tableName),
  };
}

function mapDispatchToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(CategoriesTable);
