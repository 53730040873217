// Helpers
import actionCreator from '../../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const employeesActionAsync = Object.freeze({
  getEmployeeByIdAsync(id) {
    return actionCreator(asyncTypes.GET_EMPLOYEE_BY_ID_ASYNC, id);
  },
  getResponsibleEmployeeByIdAsync(id) {
    return actionCreator(asyncTypes.GET_RESPONSIBLE_EMPLOYEE_BY_ID_ASYNC, id);
  },
  putEmployeeByIdAsync(employee) {
    return actionCreator(asyncTypes.PUT_EMPLOYEE_BY_ID_ASYNC, employee);
  },
  postEmployeeAsync(employee) {
    return actionCreator(asyncTypes.POST_EMPLOYEE_ASYNC, employee);
  },
  postInviteEmployeeAsync(employee) {
    return actionCreator(asyncTypes.POST_INVITE_EMPLOYEE_ASYNC, employee);
  },
  getEmployeeAutocomplete(text) {
    return actionCreator(asyncTypes.GET_EMPLOYEE_AUTOCOMPLETE_ASYNC, text);
  },
  getKeysDataAsync() {
    return actionCreator(asyncTypes.GET_KEYS_DATA_ASYNC);
  },
  postKeyDataAsync(keyData) {
    return actionCreator(asyncTypes.POST_KEY_DATA_ASYNC, keyData);
  },
  deleteKeyAsync(keyId) {
    return actionCreator(asyncTypes.DELETE_KEY_ASYNC, keyId);
  },
  deleteEmployeeAsync() {
    return actionCreator(asyncTypes.DELETE_EMPLOYEE_ASYNC);
  },
  cskDataAsync(keyData) {
    return actionCreator(asyncTypes.CSK_DATA_ASYNC, keyData);
  },
});

export default employeesActionAsync;
