// Core
import { all, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetUsersFiltersWorker,
  callPostUsersFilterWorker,
  callDeleteUsersFilterWorker,
} from './workers';

function* watchGetUsersFilters() {
  yield takeEvery(asyncTypes.GET_USERS_FILTERS_ASYNC, callGetUsersFiltersWorker);
}
function* watchPostUsersFilter() {
  yield takeEvery(asyncTypes.POST_USERS_FILTER_ASYNC, callPostUsersFilterWorker);
}
function* watchDeleteUsersFilter() {
  yield takeEvery(asyncTypes.DELETE_USERS_FILTER_ASYNC, callDeleteUsersFilterWorker);
}

export function* watchersUsersFilters() {
  yield all([
    watchGetUsersFilters(),
    watchPostUsersFilter(),
    watchDeleteUsersFilter(),
  ]);
}
