// core
import React, {
  useEffect, useState, useMemo,
} from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import {
  Field, Form, reduxForm, change,
} from 'redux-form/lib/immutable';
import styled from 'styled-components';

// lodash
import {
  isEmpty, includes,
} from 'lodash';

// ui
import {
  Grid as MuiGrid, Typography as MuiTypography,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';

// config
import selectors from '../../../engine/config/selectors';

// parts
import RenderTextField from '../../../ui/Form/RenderTextField';
import Modal from '../../../components/Modal/Modal';
import DialogActions from '../../../components/Modal/components/DialogActions';
import Select from '../../../ui/Form/Select';
import LoaderWithOverlay from '../../../components/LoaderWithOverlay';
import Checkbox from '../../../ui/Form/Checkbox';

// actions
import asyncAction from '../../../engine/core/email/email/saga/asyncAction';
import actions from '../../../engine/core/email/email/action';
import { validators } from '../../../ui/_helpers/validation';

// style
const Grid = styled(MuiGrid)(spacing);
const TypographySub = styled(MuiTypography)`
  color: ${(props) => props.theme.palette.grey['500']};
  margin-top: 10px;
`;

const listProtocols = [
  { name: 'SSL', value: 'ssl' },
  { name: 'STARTTLS', value: 'starttls' },
  { name: 'TLS', value: 'tls' },
];

const formName = 'EmailModal';

function EmailModal(props) {
  const {
    handleSubmit, isModalOpen, initialize, destroy,
    emailById, pendingPost, pendingPut, valid, pendingEmailProvider,
    emailValues, pendingEmailById, userInfo, emailProvider,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [readyInitialize, setReadyInitialize] = useState(false);
  const [open, setOpen] = useState(false);

  const handleCloseModal = () => {
    dispatch(actions.setIsModalOpenEmail(false));
  };
  useEffect(() => function cleanup() {
    destroy();
    dispatch(actions.setEmailData({}));
    dispatch(actions.setEmailProvider(''));
  }, [destroy, dispatch]);

  const handleSubmits = (formData) => {
    const json = formData.toJS();
    delete json.essence;
    delete json.field;
    dispatch(asyncAction[!isEmpty(emailById.toJS())
      ? 'putEmailByIdAsync'
      : 'postEmailAsync']({
      ...!isEmpty(emailById.toJS()) ? {
        ...emailById.toJS(),
      } : {},
      ...json,
      employee: userInfo.id || 1,
    }));
  };

  useEffect(() => {
    if (!isEmpty(emailById.toJS()) && !readyInitialize) {
      initialize(emailById.toJS().type === 1
        ? {
          email: emailById.toJS().email,
          ...emailById.toJS()?.fromName ? { fromName: emailById.toJS()?.fromName } : {},
          ...emailById.toJS()?.parameters?.group ? { parameters: { group: true } } : {},
        } : emailById.toJS());
      setReadyInitialize(true);
    }
  }, [initialize, emailById, readyInitialize]);

  const getTitle = useMemo(() => (
    !isEmpty(emailById.toJS()) ? emailById.toJS().email : t('Mailbox')
  ), [emailById, t]);

  const handleContinue = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (!isEmpty(emailValues) && includes(emailValues, '@') && valid) {
      dispatch(asyncAction.getCheckEmailAsync({ email: emailValues }));
    }
  }, [dispatch, emailValues, valid]);

  useEffect(() => {
    if (isEmpty(emailById.toJS()) && !open && !isEmpty(userInfo.name)) {
      dispatch(change(formName, 'fromName', userInfo.name));
    }
  }, [dispatch, emailById, userInfo, open]);

  return (
    <Modal
      title={getTitle}
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
      displayDialogActions={false}
    >
      {pendingEmailById ? (
        <LoaderWithOverlay />
      ) : (
        <Form onSubmit={handleSubmit(handleSubmits)}>
          {isEmpty(emailById.toJS()) && (
            <>
              <TypographySub variant="subtitle1" gutterBottom>
                {t('Connect a corporate shared mailbox that customers receive requests from, or a personal mailbox from one of your employees.')}
              </TypographySub>
              <Field
                validate={validators.notRequiredEmail}
                required
                name="email"
                id="email"
                label={t('Email')}
                margin="none"
                variant="standard"
                my={2}
                fullWidth
                type="email"
                component={RenderTextField}
              />
            </>
          )}
          {(open || !isEmpty(emailById.toJS())) && (
            <Field
              name="fromName"
              id="fromName"
              label={t('Sender name')}
              margin="normal"
              variant="standard"
              my={2}
              fullWidth
              type="text"
              required
              component={RenderTextField}
            />
          )}
          {(open || (!isEmpty(emailById.toJS()) && emailById.toJS().type !== 1)) && (
            <>
              <Field
                name="parameters.imap.password"
                id="parameters.imap.password"
                label={`${t('Password')} IMAP`}
                margin="normal"
                variant="standard"
                my={2}
                fullWidth
                type="text"
                required
                component={RenderTextField}
              />
              <Field
                name="parameters.imap.hostname"
                id="parameters.imap.hostname"
                label={t('IMAP server')}
                margin="normal"
                variant="standard"
                my={2}
                fullWidth
                type="text"
                component={RenderTextField}
                required
              />
              <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                  <Field
                    name="parameters.imap.port"
                    id="parameters.imap.port"
                    label={t('Port')}
                    margin="normal"
                    variant="standard"
                    fullWidth
                    type="text"
                    component={RenderTextField}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Field
                    name="parameters.imap.enc"
                    id="parameters.imap.enc"
                    label={t('Protocol')}
                    labelId="imap.enc"
                    component={Select}
                    items={listProtocols}
                    margin="normal"
                    displayEmpty
                    textEmpty={t('Without encryption')}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Field
                name="parameters.smtp.password"
                id="parameters.smtp.password"
                label={`${t('Password')} SMTP`}
                margin="normal"
                variant="standard"
                my={2}
                fullWidth
                type="text"
                required
                component={RenderTextField}
              />
              <Field
                name="parameters.smtp.hostname"
                id="parameters.smtp.hostname"
                label={t('SMTP server')}
                margin="normal"
                variant="standard"
                my={2}
                fullWidth
                type="text"
                component={RenderTextField}
                required
              />
              <Grid container spacing={4} mb={3}>
                <Grid item xs={12} md={4}>
                  <Field
                    name="parameters.smtp.port"
                    id="parameters.smtp.port"
                    label={t('Port')}
                    margin="normal"
                    variant="standard"
                    fullWidth
                    type="text"
                    component={RenderTextField}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Field
                    name="parameters.smtp.enc"
                    id="parameters.smtp.enc"
                    label={t('Protocol')}
                    labelId="smtp.enc"
                    component={Select}
                    items={listProtocols}
                    margin="normal"
                    displayEmpty
                    textEmpty={t('Without encryption')}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </>
          )}
          {(open || !isEmpty(emailById.toJS())) && (
            <Field
              name="parameters.group"
              id="parameters.group"
              type="checkbox"
              fullWidth={false}
              label={t('Group letters into chains')}
              component={Checkbox}
            />
          )}

          <DialogActions
            buttonTextSend={(emailProvider === 'gmail' || open || !isEmpty(emailById.toJS())) ? 'CONFIRM' : 'CONTINUE'}
            pending={pendingPost || pendingPut || pendingEmailProvider}
            disableButtonSend={isEmpty(emailValues) || !valid}
            handleCloseModal={handleCloseModal}
            {...(emailProvider === 'gmail' || open || !isEmpty(emailById.toJS())) ? {} : { handleSend: handleContinue }}
          />
        </Form>
      )}
    </Modal>
  );
}

EmailModal.displayName = 'EmailModal';

EmailModal.propTypes = {
  emailById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  pendingPost: PropTypes.bool.isRequired,
  pendingPut: PropTypes.bool.isRequired,
  pendingEmailById: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  destroy: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  emailValues: PropTypes.string,
  userInfo: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  emailProvider: PropTypes.string.isRequired,
  pendingEmailProvider: PropTypes.bool.isRequired,
};

EmailModal.defaultProps = {
  emailValues: '',
};

function mapStateToProps(state) {
  return {
    emailById: selectors.email.emailById(state),
    isModalOpen: selectors.email.isModalOpenEmail(state),
    pendingPut: selectors.email.pendingPutEmail(state),
    pendingPost: selectors.email.pendingPostEmail(state),
    pendingEmailById: selectors.email.pendingEmailById(state),
    emailValues: selectors.form.getFormValues(state, formName).get('email'),
    userInfo: selectors.user.userInfo(state),
    emailProvider: selectors.email.emailProvider(state),
    pendingEmailProvider: selectors.email.pendingEmailProvider(state),
  };
}

export default compose(
  reduxForm({
    form: formName,
  }),
  connect(mapStateToProps, null),
)(EmailModal);
