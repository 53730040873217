// Core
import { List, Map } from 'immutable';

// lodash
import { keys, values } from 'lodash';

// Instruments
import types from './types';

// config
import globalStateKeys from '../../config/globalStateKeys';

export const initialState = Map({
  documentsType: List(),
  pendingDocumentsType: false,
  modal: Map({
    isModalOpen: false,
    isModalOpenAddCharges: false,
    isModalOpenDocuments: false,
    isModalOpenDocumentTemplates: false,
    isModalOpenSupport: false,
    isModalOpenRegistry: false,
    isModalOpenAddClient: false,
    isModalOpenAddApplicant: false,
    isModalOpenDropZone: false,
    isModalOpenSend: false,
    isModalOpenCheckDocuments: false,
    isModalOpenDocViewer: false,
    isModalOpenTableEntities: false,
  }),
  objectStatuses: Map({
    items: List(),
    pending: false,
  }),
  autocompleteGroup: Map({
    items: List(),
    pendingPost: false,
    totalCount: 0,
  }),
  autocompleteFilter: Map(),
  multiple: Map({
    pendingDelete: false,
    pendingPut: false,
  }),
  checkboxes: Map(),
  longMenu: Map(),
  sign: {
    pending: false,
    items: List(),
    isModalOpen: false,
    permissionSign: false,
  },
  pendingSend: false,
  supportTickets: Map({
    data: Map(),
    pendingPost: false,
  }),
  visibleSidebar: true,
  signatureInfo: Map(),
  filesWithBase64: List(),
  imagesWithBase64: List(),
  selectedEntities: List(),
  predefinedInputs: Map(),
  lastObject: Map(),
  entityApplicantModal: '',
  notPendingGetById: false,
  initialLongMenu: false,
  pendingPostRenewal: false,
  isOpenNotification: false,
  allNotificationList: List(),
  selectedCompany: '',
  pendingDownloadFile: false,
  pendingDownloadExport: false,
  checkboxClose: false,
  startSend: false,
  previewFileById: Map({
    file: '',
    pending: false,
  }),
  tableName: '',
  successDetBase64: false,
});

export const stateKeys = Object.freeze({
  controller: 'helpers',
  longMenu: 'longMenu',
  tableName: 'tableName',
  isModalOpenAddCharges: 'isModalOpenAddCharges',
  isModalOpenDocuments: 'isModalOpenDocuments',
  isModalOpenDocumentTemplates: 'isModalOpenDocumentTemplates',
  isModalOpenSupport: 'isModalOpenSupport',
  isModalOpenRegistry: 'isModalOpenRegistry',
  isModalOpenAddClient: 'isModalOpenAddClient',
  isModalOpenAddApplicant: 'isModalOpenAddApplicant',
  isModalOpenDropZone: 'isModalOpenDropZone',
  isModalOpenSend: 'isModalOpenSend',
  isModalOpenCheckDocuments: 'isModalOpenCheckDocuments',
  isModalOpenDocViewer: 'isModalOpenDocViewer',
  isModalOpenTableEntities: 'isModalOpenTableEntities',
  entityApplicantModal: 'entityApplicantModal',
  documentsType: 'documentsType',
  objectStatuses: 'objectStatuses',
  autocompleteGroup: 'autocompleteGroup',
  autocompleteFilter: 'autocompleteFilter',
  pendingDocumentsType: 'pendingDocumentsType',
  supportTickets: 'supportTickets',
  visibleSidebar: 'visibleSidebar',
  signatureInfo: 'signatureInfo',
  filesWithBase64: 'filesWithBase64',
  imagesWithBase64: 'imagesWithBase64',
  predefinedInputs: 'predefinedInputs',
  lastObject: 'lastObject',
  permissionSign: 'permissionSign',
  notPendingGetById: 'notPendingGetById',
  allNotificationList: 'allNotificationList',
  initialLongMenu: 'initialLongMenu',
  pendingPostRenewal: 'pendingPostRenewal',
  selectedCompany: 'selectedCompany',
  pendingSend: 'pendingSend',
  pendingDownloadFile: 'pendingDownloadFile',
  pendingDownloadExport: 'pendingDownloadExport',
  isOpenNotification: 'isOpenNotification',
  checkboxClose: 'checkboxClose',
  startSend: 'startSend',
  previewFileById: 'previewFileById',
  file: 'file',
  selectedEntities: 'selectedEntities',
  successDetBase64: 'successDetBase64',
});

export const helpersReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_DOCUMENTS_TYPE: {
      return state.setIn([stateKeys.documentsType], List(payload));
    }
    case types.SET_PENDING_DOCUMENTS_TYPE: {
      return state.setIn([
        stateKeys.pendingDocumentsType,
      ], payload);
    }
    case types.SET_MODAL: {
      return state.mergeIn([globalStateKeys.modal], payload);
    }
    case types.SET_OBJECT_STATUSES: {
      return state.mergeIn([stateKeys.objectStatuses], payload);
    }
    case types.SET_AUTOCOMPLETE_GROUP: {
      return state.mergeIn([stateKeys.autocompleteGroup], Map(payload));
    }
    case types.MERGE_AUTOCOMPLETE_FILTER: {
      return state.mergeIn([stateKeys.autocompleteFilter], Map({
        [keys(payload)[0]]: List(values(payload)[0]),
      }));
    }
    case types.SET_AUTOCOMPLETE_FILTER: {
      return state.setIn([stateKeys.autocompleteFilter], Map(payload));
    }
    case types.SET_MULTIPLE: {
      return state.mergeIn([globalStateKeys.multiple], Map(payload));
    }
    case types.SET_CURRENT_LONG_MENU: {
      const [entity, index, data] = payload;
      return state.setIn([stateKeys.longMenu, entity, globalStateKeys.items], Map({
        [index]: data || true,
      }));
    }
    case types.SET_OPEN_LONG_MENU: {
      const [entity, isOpen] = payload;
      return state.setIn([stateKeys.longMenu, entity, globalStateKeys.isOpen], isOpen);
    }
    case types.SET_CLEANUP_LONG_MENU: {
      return state.setIn([stateKeys.longMenu], Map());
    }
    case types.SET_SUPPORT_TICKETS_DATA: {
      return state.setIn([stateKeys.supportTickets, globalStateKeys.data], Map(payload));
    }
    case types.SET_PENDING_POST_SUPPORT_TICKETS: {
      return state.setIn([
        stateKeys.supportTickets,
        globalStateKeys.pendingPost,
      ], payload);
    }
    case types.SET_PENDING_SEND: {
      return state.setIn([stateKeys.pendingSend], payload);
    }
    case types.SET_SIGN_PENDING: {
      return state.setIn([
        globalStateKeys.sign,
        globalStateKeys.pending,
      ], payload);
    }
    case types.SET_SIGN_LIST: {
      return state.setIn([
        globalStateKeys.sign,
        globalStateKeys.items,
      ], List(payload));
    }
    case types.SET_SIGN_MODAL_IS_OPEN: {
      return state.setIn([
        globalStateKeys.sign,
        globalStateKeys.isModalOpen,
      ], payload);
    }
    case types.SET_PERMISSION_SIGN: {
      return state.setIn([
        globalStateKeys.sign,
        stateKeys.permissionSign,
      ], payload);
    }
    case types.SET_CHECKBOX_CLEAR: {
      return state.setIn(['checkboxes'], Map({}));
    }
    case types.SET_CHECKBOX: {
      const { tableName, checkboxes } = payload;
      return state.mergeIn(['checkboxes'], Map({ [tableName]: List(checkboxes) }));
    }
    case types.SET_VISIBLE_SIDEBAR: {
      return state.setIn([stateKeys.visibleSidebar], payload);
    }
    case types.SET_SIGNATURE_INFO: {
      return state.setIn([stateKeys.signatureInfo], Map(payload));
    }
    case types.SET_SIGNATURE_INFO_STATE_CLEAR: {
      return state.setIn([stateKeys.signatureInfo], Map({}));
    }
    case types.SET_FILES_WITH_BASE_64: {
      return state.mergeIn([stateKeys.filesWithBase64], List(payload));
    }
    case types.SET_FILES_WITH_BASE_64_CLEAR: {
      return state.setIn([stateKeys.filesWithBase64], List([]));
    }
    case types.SET_IMAGES_WITH_BASE_64: {
      return state.mergeIn([stateKeys.imagesWithBase64], List(payload));
    }
    case types.SET_IMAGES_WITH_BASE_64_CLEAR: {
      return state.setIn([stateKeys.imagesWithBase64], List([]));
    }
    case types.SET_PREDEFINED_INPUTS: {
      return state.setIn([stateKeys.predefinedInputs], Map(payload));
    }
    case types.SET_ENTITY_APPLICANT_MODAL: {
      return state.setIn([stateKeys.entityApplicantModal], payload);
    }
    case types.SET_LAST_OBJECT: {
      return state.setIn([stateKeys.lastObject], Map(payload));
    }
    case types.SET_HELPERS_TABLE_NAME: {
      return state.setIn([stateKeys.tableName], payload);
    }
    case types.SET_NOT_PENDING_GET_BY_ID: {
      return state.setIn([stateKeys.notPendingGetById], payload);
    }
    case types.SET_ALL_NOTIFICATION_LIST: {
      return state.setIn([stateKeys.allNotificationList], List(payload));
    }
    case types.SET_SELECTED_ENTITIES: {
      return state.setIn([stateKeys.selectedEntities], List(payload));
    }
    case types.SET_INITIAL_LONG_MENU: {
      return state.setIn([stateKeys.initialLongMenu], payload);
    }
    case types.SET_PENDING_POST_RENEWAL: {
      return state.setIn([stateKeys.pendingPostRenewal], payload);
    }
    case types.SET_SELECTED_COMPANY: {
      return state.setIn([stateKeys.selectedCompany], payload);
    }
    case types.SET_PENDING_DOWNLOAD_FILE: {
      return state.setIn([stateKeys.pendingDownloadFile], payload);
    }
    case types.SET_PENDING_DOWNLOAD_EXPORT: {
      return state.setIn([stateKeys.pendingDownloadExport], payload);
    }
    case types.SET_IS_OPEN_NOTIFICATION: {
      return state.setIn([stateKeys.isOpenNotification], payload);
    }
    case types.SET_CHECKBOX_CLOSE: {
      return state.setIn([stateKeys.checkboxClose], payload);
    }
    case types.SET_ON_START_SEND: {
      return state.setIn([stateKeys.startSend], payload);
    }
    case types.SET_PREVIEW_FILE_BY_ID: {
      return state.mergeIn([stateKeys.previewFileById], Map(payload));
    }
    case types.SET_SUCCESS_GET_BASE_64: {
      return state.setIn([stateKeys.successDetBase64], payload);
    }
    default:
      return state;
  }
};
