// Core
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';

// Lodash
import {
  map, isEmpty,
} from 'lodash';

// UI
import {
  Button, Box,
} from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';

// parts
import DxTable from '../../../ui/Table/DxTable';
import ConfirmModal from '../../../components/ConfirmModal';

// actions
import helpersActions from '../../../engine/core/helpers/action';
import actions from '../../../engine/core/products/action';
import actionAsync from '../../../engine/core/products/saga/asyncAction';

// config
import selectors from '../../../engine/config/selectors';
import accessList from '../../../engine/config/accessList';
import { listTablesName, listEntityName } from '../../../engine/config/listTablesName';

// hooks
import { useAccessList } from '../../../ui/_hooks/useAccessList';

// routes
import { pageLinks } from '../../../routes';

const tableName = listTablesName.products;
const entityName = listEntityName.products;

function ProductsTable(props) {
  const {
    productsData, totalCount, pending,
    currentPage, filters, sorting,
    pageSize, pendingDelete, checkboxes,
    columnWidths, columnOrder, hiddenColumnNames,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const accessPost = useAccessList(accessList.products_post);

  const columns = [
    { name: 'id', title: t('ID') },
    { name: 'name', title: t('Title') },
    { name: 'category', title: t('Category'), customField: 'productTable' },
    { name: 'jurisdictions', title: t('Jurisdictions'), customField: 'productTable' },
    { name: 'trademarks', title: t('Trade marks'), customField: 'documents' },
    { name: 'industrial', title: t('Industrial designs'), customField: 'documents' },
    { name: 'inventions', title: t('inventions'), customField: 'documents' },
    { name: 'utility', title: t('Utility models'), customField: 'documents' },
  ];

  const tableColumnExtensions = [
    { columnName: 'id', width: 50 },
    { columnName: 'name', width: '15rem' },
    { columnName: 'category', width: '10rem' },
    { columnName: 'jurisdictions', width: '10rem' },
    { columnName: 'trademarks', width: '10rem' },
    { columnName: 'industrial', width: '10rem' },
    { columnName: 'inventions', width: '10rem' },
    { columnName: 'utility', width: '10rem' },
  ];

  // Paging
  const onCurrentPage = (page) => dispatch(actions.setCurrentPage(page));
  const onPageSize = (size) => dispatch(actions.setPageSize(size));
  // Filtering
  const setFilters = (getFilters) => dispatch(actions.setFilters(getFilters));
  // Sorting
  const onSortingChange = (getSorting) => dispatch(actions.setSorting(getSorting));
  const onColumnWidthsChange = (value) => dispatch(actions.setColumnWidths(value));
  const onColumnOrderChange = (getColumnOrder) => dispatch(actions.setColumnOrder(getColumnOrder));
  const onHiddenColumnNamesChange = (value) => dispatch(actions.setHiddenColumnNames(value));

  const handleGoToCreateProductPage = () => {
    dispatch(push(pageLinks.products.new));
  };

  return (
    <>
      {accessPost && (
        <Box mb={3}>
          <Button variant="contained" color="primary" onClick={handleGoToCreateProductPage}>
            {t('ADD')}
            <AddIcon />
          </Button>
        </Box>
      )}

      <DxTable
        name="ProductsTable"
        isLoading={pending}
        columns={columns}
        rows={productsData.toJS().items}
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageSize={onPageSize}
        onCurrentPage={onCurrentPage}
        filtersAction={setFilters}
        // checkboxes ------------------------
        entityName={entityName}
        tableName={tableName}
        reloadTable={actions.setReload}
        selection={checkboxes.toJS()}
        onSelectionChange={(checkboxSelect) => {
          dispatch(helpersActions.setCheckbox({
            tableName,
            checkboxes: checkboxSelect,
          }));
        }}
        // ------------------------
        filters={filters}
        sorting={sorting}
        onSortingChange={onSortingChange}
        tableColumnExtensions={!isEmpty(columnWidths) && columnWidths.length === columns.length
          ? columnWidths : tableColumnExtensions}
        onColumnWidthsChange={onColumnWidthsChange}
        columnOrder={!isEmpty(columnOrder) ? columnOrder : map(columns, 'name')}
        onColumnOrderChange={onColumnOrderChange}
        hiddenColumnNames={hiddenColumnNames}
        onHiddenColumnNamesChange={onHiddenColumnNamesChange}
        editComponentWidth={60}
        accessEdit={accessList.products_put}
        accessDelete={accessList.products_delete}
      />

      <ConfirmModal
        buttonSendText="DELETE"
        pending={pendingDelete}
        handleSend={() => {
          dispatch(actionAsync.deleteDepartmentDocumentAsync());
        }}
      />
    </>
  );
}

ProductsTable.displayName = 'ProductsTable';

ProductsTable.propTypes = {
  productsData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pending: PropTypes.bool.isRequired,
  pendingDelete: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  sorting: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  columnWidths: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  columnOrder: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  hiddenColumnNames: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  pageSize: PropTypes.number.isRequired,
  totalCount: PropTypes.number,
  checkboxes: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

ProductsTable.defaultProps = {
  totalCount: 0,
};

function mapStateToProps(state) {
  return {
    productsData: selectors.products.productsData(state),
    pending: selectors.products.pending(state),
    pendingDelete: selectors.products.pendingDeleteProduct(state),
    totalCount: selectors.products.totalCount(state),
    currentPage: selectors.products.currentPage(state),
    pageSize: selectors.products.pageSize(state),
    filters: selectors.products.filters(state),
    sorting: selectors.products.sorting(state),
    columnWidths: selectors.products.columnWidths(state),
    columnOrder: selectors.products.columnOrder(state),
    hiddenColumnNames: selectors.products.hiddenColumnNames(state),

    checkboxes: selectors.helpers.getCheckboxes(state, tableName),
  };
}

function mapDispatchToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(ProductsTable);
