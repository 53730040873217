// core
import React, { useEffect } from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';

// ui
import {
  Divider as MuiDivider, Typography,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';

// actions
import applicantsActions from '../../../engine/core/applicants/action';

// parts
import ClientsTable from './ClientsTable';

// hooks
import { useClients } from './_hooks/useClients';

// config
import selectors from '../../../engine/config/selectors';

// styles
const Divider = styled(MuiDivider)(spacing);

function Clients(props) {
  const {
    openFormAddApplicant,
  } = props;
  useClients();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (openFormAddApplicant) {
      dispatch(applicantsActions.setApplicantsData({
        openFormAddApplicant: false,
      }));
    }
  }, [dispatch, openFormAddApplicant]);

  return (
    <>
      <Helmet title={t('Clients')} />
      <Typography variant="h3" gutterBottom display="inline">
        {t('Clients')}
      </Typography>
      <Divider my={6} />

      <ClientsTable />
    </>
  );
}

Clients.displayName = 'Employees';

Clients.propTypes = {
  // isModalOpenInviteEmployee: PropTypes.bool.isRequired,
  openFormAddApplicant: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    // isModalOpenInviteEmployee: selectors.employees.isModalOpenInviteEmployee(state),
    openFormAddApplicant: selectors.applicants.openFormAddApplicant(state),
  };
}

export default compose(
  connect(mapStateToProps, null),
)(Clients);
