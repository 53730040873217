import { uk, enUS } from 'date-fns/locale';
import { locale } from '../../engine/init/i18n';

export function getCurrentDateFnsLocale(lang) {
  let currentLocale;
  switch (locale[lang]) {
    case locale.en: {
      currentLocale = enUS;
      break;
    }
    default: {
      currentLocale = uk;
    }
  }
  return currentLocale;
}
