// Core
import { call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';

// lodash
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';

// config
import { api } from '../../../../config/api';
import { messageRequestSuccess } from '../../../../config/globalConfig';

// init
import i18n from '../../../../init/i18n';

// actions
import departmentDocumentsActions from '../../action';
import helpersActions from '../../../helpers/action';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../ui/_helpers/setNotificationMessage';
import { formatDate, getValueFormatDate } from '../../../../_helpers/formatterDate';

// routes
import { pageLinks } from '../../../../../routes';

export function* callPostDepartmentDocumentWorker({ payload: document }) {
  const { setDepartmentDocumentById, setAdditionalDocuments } = departmentDocumentsActions;

  yield put(setDepartmentDocumentById({
    pendingPost: true,
  }));

  const filterDocumentData = pickBy(document, (value, key) => key !== 'params');

  const response = yield call(api.departmentDocuments.postDepartmentDocument, {
    ...filterDocumentData,
    ...!isEmpty(document.receivingDate) ? {
      receivingDate: getValueFormatDate(formatDate(document.receivingDate), 'receivingDate'),
    } : {},
  });

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
      yield put(setDepartmentDocumentById({
        pendingPost: false,
      }));
      return;
    }
    const { id } = data;
    yield put(push(pageLinks.documents.departmentDocuments.edit(id)));
    yield put(setSuccessMessage(i18n.t(messageRequestSuccess), response.status.toString()));
    yield put(setAdditionalDocuments([]));
    if (document.params) yield put(helpersActions.setLastObject(document.params));
  }

  yield put(setDepartmentDocumentById({
    pendingPost: false,
  }));
}
