// Core
import { apply, put } from 'redux-saga/effects';

// config
import { api } from '../../../../../config/api';

// actions
import actions from '../../action';

export function* callGetMktuTemplatePdfByIdWorker({ payload: id }) {
  yield put(actions.setPendingMktuTemplatePdf(true));

  const response = yield apply(api, api.trademarks.getMktuTemplatePdfById, [id]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    yield put(actions.setMktuTemplatePdfById(data.links));
  }

  yield put(actions.setPendingMktuTemplatePdf(false));
}
