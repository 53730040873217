/* eslint-disable */
// core
import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { rgba, darken } from 'polished';
import { NavLink as RouterNavLink, withRouter } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import '../vendor/perfect-scrollbar.css';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// lodash
import {
  isNil, map, includes,
  filter, has, reduce, isEmpty,
} from 'lodash';

// UI
import {
  Collapse, Drawer as MuiDrawer, Dialog, DialogTitle,
  List as MuiList, ListItem, ListItemText, Button,
  Typography, IconButton as MuiIconButton, DialogActions,
  DialogContent, 
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import {
  ExpandLess, ExpandMore, ChevronLeft as ChevronLeftIcon,
  Close,
} from '@material-ui/icons';
// import { green } from '@material-ui/core/colors';

// routes
import { sidebarRoutes as routes } from '../routes/index';
import selectors from '../engine/config/selectors';

import DefaultLogo from '../ui/_helpers/img/userLogo.svg';
// import { useAccessList } from '../ui/_hooks/useAccessList';

// actions
import importAsyncAction from '../engine/core/importObjects/saga/asyncAction';
import ConfirmModal from './ConfirmModal';

const NavLink = React.forwardRef((props, ref) => (
  <RouterNavLink innerRef={ref} {...props} />
));
const IconButton = styled(MuiIconButton)`
  padding-left: ${(props) => props.theme.spacing(12)}px;
`;
const IconButtonDesc = styled(MuiIconButton)`
  position: absolute;
  top: ${(props) => props.theme.spacing(2)}px;
  right: ${(props) => props.theme.spacing(2)}px;
  padding: 9px;
`;

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
  background-color: ${(props) => props.theme.sidebar.background};
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)}px;
  padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
  .MuiTypography-root::before {
    content: '';
    display: block;
  }
`;

const Brand = styled(ListItem)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)}px;
  padding-right: ${(props) => props.theme.spacing(6)}px;
  cursor: default;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ${(props) => props.theme.breakpoints.up('sm')} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const UserLogo = styled.img`
    max-width: 200px;
    max-height: 40px;`;

// const BrandChip = styled(Chip)`
//   background-color: ${green[700]};
//   border-radius: 5px;
//   color: ${(props) => props.theme.palette.common.white};
//   font-size: 60%;
//   height: 20px;
//   margin-left: 2px;
//   margin-bottom: 1px;
//   padding: 4px 0;

//   span {
//     padding-left: ${(props) => props.theme.spacing(1.5)}px;
//     padding-right: ${(props) => props.theme.spacing(1.5)}px;
//   }
// `;
// MuiTypography-root
const Category = styled(ListItem)`
  ${spacing};
  padding-top: ${(props) => props.theme.spacing(3)}px;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
  padding-left: ${(props) => props.theme.spacing(6)}px;
  padding-right: ${(props) => props.theme.spacing(5)}px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) => darken(0.05, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) => props.theme.sidebar.color};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    font-weight: ${(props) => props.theme.sidebar.category.fontWeight};
    padding: 0 ${(props) => props.theme.spacing(4)}px;
  }
`;

const CategoryIconLess = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const Link = styled(ListItem)`
  padding-left: ${(props) => props.theme.spacing(15)}px;
  padding-top: ${(props) => props.theme.spacing(2)}px;
  padding-bottom: ${(props) => props.theme.spacing(2)}px;

  span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
  }

  &:hover span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) => darken(0.06, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const LinkText = styled(ListItemText)`
  color: ${(props) => props.theme.sidebar.color};
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

// const LinkBadge = styled(Chip)`
//   font-size: 11px;
//   font-weight: ${(props) => props.theme.typography.fontWeightBold};
//   height: 20px;
//   position: absolute;
//   right: 12px;
//   top: 8px;
//   background: ${(props) => props.theme.sidebar.badge.background};

//   span.MuiChip-label,
//   span.MuiChip-label:hover {
//     cursor: pointer;
//     color: ${(props) => props.theme.sidebar.badge.color};
//     padding-left: ${(props) => props.theme.spacing(2)}px;
//     padding-right: ${(props) => props.theme.spacing(2)}px;
//   }
// `;

// const CategoryBadge = styled(LinkBadge)`
//   top: 12px;
// `;

const SidebarSection = styled(Typography)`
  color: ${(props) => props.theme.sidebar.color};
  padding: ${(props) => props.theme.spacing(4)}px
    ${(props) => props.theme.spacing(6)}px ${(props) => props.theme.spacing(1)}px;
  opacity: 0.5;
  display: block;
  text-transform: uppercase;
  margin-top: ${(props) => props.theme.spacing(6)}px;
`;

// const SidebarFooter = styled.div`
//   background-color: ${(props) => props.theme.sidebar.footer.background} !important;
//   padding: ${(props) => props.theme.spacing(2.75)}px
//     ${(props) => props.theme.spacing(4)}px;
//   border-right: 1px solid rgba(0, 0, 0, 0.12);
// `;

// const SidebarFooterText = styled(Typography)`
//   color: ${(props) => props.theme.sidebar.footer.color};
// `;

// const SidebarFooterSubText = styled(Typography)`
//   color: ${(props) => props.theme.sidebar.footer.color};
//   font-size: .725rem;
//   display: block;
//   padding: 1px;
// `;

// const StyledBadge = styled(Badge)`
//   margin-right: ${(props) => props.theme.spacing(1)}px;

//   span {
//     background-color: ${(props) => props.theme.sidebar.footer.online.background};
//     border: 1.5px solid ${(props) => props.theme.palette.common.white};
//     height: 12px;
//     width: 12px;
//     border-radius: 50%;
//   }
// `;

function SidebarCategory(props) {
  const {
    name, icon, classes, isOpen,
    isCollapsable, ...rest
  } = props;
  const { t } = useTranslation();

  return (
    <Category {...rest}>
      {icon}
      <CategoryText>{t(name)}</CategoryText>
      {isCollapsable ? (
        isOpen ? (
          <CategoryIconMore/>
        ) : (
          <CategoryIconLess/>
        )
      ) : null}
    </Category>
  );
}

function SidebarLink({ name, to }) {
  const { t } = useTranslation();

  return (
    <Link
      button
      dense
      component={NavLink}
      exact
      to={to}
      activeClassName="active"
    >
      <LinkText>{t(name)}</LinkText>
    </Link>
  );
}

function Sidebar(props) {
  const {
    classes, staticContext, location, onClose, ...rest
  } = props;
  const userInfo = useSelector(selectors.user.userInfo);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const confirmExport = () => {
    dispatch(importAsyncAction.postExportAsync());
    setIsModalOpen(false);
  };

  const filterRouters = useMemo(() => {
    return reduce(routes, (acc, item) => {
      const children = item.children ? filter(item.children,
        (elem) => includes(userInfo.permissions, elem.accessName) && !elem.hide) : [];
      if (!isEmpty(children) || (includes(userInfo.permissions, item.accessName) && !item.children)) {
        acc.push({
          ...item,
          ...!isEmpty(children) ? {
            children,
          } : {},
        });
      }
      return acc;
    }, []);
  }, [userInfo]);

  const getPathnameChildren = reduce(filterRouters, (acc, item) => {
    const children = item.children ? map(item.children, 'path') : [];
    if (!isEmpty(children)) {
      acc.push({
        path: item.path,
        children,
      });
    }
    return acc;
  }, []);

  const initOpenRoutes = () => reduce(getPathnameChildren, (acc, item) => {
    acc[item.path] = includes(item.children, location.pathname);
    return acc;
  }, {});

  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes());

  const toggle = (path) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      (item) => openRoutes[path]
      || setOpenRoutes((openRoutes) => ({ ...openRoutes, [item]: false })),
    );
    // Toggle selected element
    setOpenRoutes((openRoutes) => ({ ...openRoutes, [path]: !openRoutes[path] }));
  };

  return (
    <Drawer variant="permanent" {...rest} onClose={onClose}>
      <Brand>
        <UserLogo
          src={isNil(userInfo?.logo) ? DefaultLogo : userInfo?.logo}/>
        <IconButton color="inherit" size="small" aria-label="close" onClick={onClose}>
          <ChevronLeftIcon />
        </IconButton>
      </Brand>
      <Scrollbar>
        <List disablePadding>
          <Items>
            {map(filterRouters, (category, index) => (
              <React.Fragment key={index}>
                {Boolean(category.header) && (
                  <SidebarSection>{t(category.header)}</SidebarSection>
                )}
                {category.children ? (
                  <React.Fragment key={index}>
                    <SidebarCategory
                      isOpen={!openRoutes[category.path]}
                      isCollapsable
                      name={category.id}
                      icon={category.icon}
                      {...category.pathTradeMarks ? {
                        to: category.pathTradeMarks,
                        component: NavLink,
                        onMouseEnter: () => !openRoutes[category.path] && toggle(category.path),
                      } : {}}
                      button
                      onClick={() => toggle(category.path)}
                    />

                    <Collapse
                      in={openRoutes[category.path]}
                      timeout="auto"
                      unmountOnExit
                    >
                      {map(category.children, (route, i) => (
                        <SidebarLink
                          key={i}
                          name={route.name}
                          to={route.path}
                          icon={route.icon}
                        />
                      ))}
                    </Collapse>
                  </React.Fragment>
                ) : (
                  <SidebarCategory
                    isCollapsable={false}
                    name={category.id}
                    icon={category.icon}
                    {...category.type === 'button' ? {
                      onClick: () => setIsModalOpen(true),
                      button: true,
                    } : {
                      to: category.path,
                      component: NavLink,
                      exact: true,
                      activeClassName: 'active',
                    }}
                    mb={category.marginBottom ? 6 : 0}
                  />
                )}
              </React.Fragment>
            ))}
          </Items>
        </List>
      </Scrollbar>

      <Dialog
        fullWidth
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="xs"
      >
        <DialogTitle id="form-dialog-title">
          <IconButtonDesc aria-label="close" onClick={() => setIsModalOpen(false)}>
            <Close />
          </IconButtonDesc>
        </DialogTitle>
        <DialogContent>
          {t('Start exporting all data to zip archive? It will take some time.')}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={confirmExport}
          >
            {t('CONFIRM')}
          </Button>
          <Button
            onClick={() => setIsModalOpen(false)}
            color="primary"
            variant="outlined"
          >
            {t('CANCEL')}
          </Button>
        </DialogActions>
      </Dialog>
    </Drawer>
  );
}

export default withRouter(Sidebar);
