// Core
import { all, call, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetProductCategoryWorker,
  callDeleteProductCategoryWorker,
  callPostProductCategoryWorker,
  callGetProductCategoryByIdWorker,
  callPutProductCategoryByIdWorker,
} from './workers';

function* watchGetProductCategory() {
  yield takeEvery(asyncTypes.GET_PRODUCT_CATEGORY_ASYNC, callGetProductCategoryWorker);
}

function* watchDeleteProductCategory() {
  yield takeEvery(asyncTypes.DELETE_PRODUCT_CATEGORY_ASYNC, callDeleteProductCategoryWorker);
}

function* watchPostProductCategory() {
  yield takeEvery(asyncTypes.POST_PRODUCT_CATEGORY_ASYNC, callPostProductCategoryWorker);
}

function* watchGetProductCategoryById() {
  yield takeEvery(asyncTypes.GET_PRODUCT_CATEGORY_BY_ID_ASYNC, callGetProductCategoryByIdWorker);
}

function* watchPutProductCategoryById() {
  yield takeEvery(asyncTypes.PUT_PRODUCT_CATEGORY_BY_ID_ASYNC, callPutProductCategoryByIdWorker);
}

export function* watchersProductCategory() {
  yield all([
    call(watchGetProductCategory),
    call(watchDeleteProductCategory),
    call(watchPostProductCategory),
    call(watchGetProductCategoryById),
    call(watchPutProductCategoryById),
  ]);
}
