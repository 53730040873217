// Core
import { call, put } from 'redux-saga/effects';
// import { List } from 'immutable';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../config/api';
import { messageRequestSuccess } from '../../../../config/globalConfig';

// actions
import pendingActions from '../../action';
import pendingTableActions from '../../table/action';

// init
import i18n from '../../../../init/i18n';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../ui/_helpers/setNotificationMessage';

export function* callPostPendingActionWorker({ payload: action }) {
  const {
    setPendingActions, setIsModalOpen, setReload, setDisableNotifications,
  } = pendingActions;

  yield put(setPendingActions({
    pendingPost: true,
  }));

  const response = yield call(api.pendingActions.postPendingAction, action);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      yield put(pendingTableActions.setReload(true));
      yield put(setReload(true));
      yield put(setIsModalOpen(false));
      yield put(setDisableNotifications(false));
      yield put(setSuccessMessage(i18n.t(messageRequestSuccess), response.status.toString()));
    }
  }

  yield put(setPendingActions({
    pendingPost: false,
  }));
}
