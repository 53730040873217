// Instruments
import { types } from './types';
import actionCreator from '../../_helpers/actionCreator';

const actions = Object.freeze({
  setFieldsSetting(data) {
    return actionCreator(types.SET_FIELDS_SETTING_DATA, data);
  },
  setFieldsEntity(data) {
    return actionCreator(types.SET_FIELDS_ENTITY, data);
  },
});

export default actions;
