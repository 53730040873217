import React, { useState, useEffect } from 'react';
import moment from 'moment';
import buildSettings from './buildSettings.json';

const buildDateGreaterThan = (latestDate, currentDate) => {
  const momLatestDateTime = moment(latestDate);
  const momCurrentDateTime = moment(currentDate);

  if (momLatestDateTime.isAfter(momCurrentDateTime)) {
    return true;
  }
  return false;
};

function withClearCache(Component) {
  function ClearCacheComponent(props) {
    const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);

    const refreshCacheAndReload = () => {
      if (caches) {// eslint-disable-line
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then((names) => {// eslint-disable-line
          for (const name of names) {// eslint-disable-line
            caches.delete(name);// eslint-disable-line
          }
        });
      }
      // delete browser cache and hard reload
      window.location.reload(true);
    };

    useEffect(() => {
      fetch('/meta.json')
        .then((response) => response.json())
        .then((meta) => {
          const latestVersionDate = meta.buildDate;
          const currentVersionDate = buildSettings.buildDate;

          const shouldForceRefresh = buildDateGreaterThan(
            latestVersionDate,
            currentVersionDate,
          );
          if (shouldForceRefresh) {
            setIsLatestBuildDate(false);
            refreshCacheAndReload();
          } else {
            setIsLatestBuildDate(true);
          }
        });
    }, []);

    return (
      <>
        {isLatestBuildDate ? <Component {...props} /> : null}
      </>
    );
  }

  return ClearCacheComponent;
}

export default withClearCache;
