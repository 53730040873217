// Core
import React, {
  memo, useEffect, useState, useCallback,
} from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import { Map } from 'immutable';

// lodash
import isObject from 'lodash/isObject';
import map from 'lodash/map';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

// UI
import {
  TableInlineCellEditing,
} from '@devexpress/dx-react-grid-material-ui';
import {
  Select as MuiSelect, MenuItem,
  Checkbox as MuiCheckbox,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';

// config
import selectors from '../../../../engine/config/selectors';
import globalStatusesAction from '../../../../engine/config/globalStatusesAction';

// helpers
import { formatDate, getValueFormatDate } from '../../../../engine/_helpers/formatterDate';

// parts
import AutocompleteChips from '../../../Form/AutocompleteChips';

// hooks
import { useEventsAutocompleteAsync } from '../../../_hooks/useEventsAutocompleteAsync';

// actions
import tagsAction from '../../../../engine/core/tags/action';
import tagsAsyncAction from '../../../../engine/core/tags/saga/asyncAction';

// styles
const Checkbox = styled(MuiCheckbox)(spacing);
export const Select = styled(MuiSelect)`
  width: 100%;
`;

function TableInlineEditingCell(props) {
  const {
    column, value, editingEnabled, onValueChange, onBlur,
  } = props;
  const {
    getAutocompleteLists,
  } = useEventsAutocompleteAsync(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isNewTag, setIsNewTag] = useState(false);
  const [defaultFlag, setDefaultFlag] = useState(false);
  const employeesData = useSelector(selectors.employeesTable.employeesData);
  const currenciesData = useSelector(selectors.currencies.currenciesData);
  const tagsData = useSelector(selectors.tags.tagsData);
  const newTag = useSelector(selectors.tags.newTag);

  useEffect(() => {
    if ((column.name === 'default' || column.name === 'includeToBill') && !defaultFlag) {
      onValueChange(!value);
      setDefaultFlag(true);
      setTimeout(() => {
        onBlur();
      });
    }
  }, [
    column, value, onBlur, onValueChange,
    defaultFlag, setDefaultFlag,
  ]);

  useEffect(() => {
    if (!isEmpty(newTag.toJS())) {
      onValueChange([...value || [], newTag.toJS()]);
      setTimeout(() => {
        onBlur();
      });
      dispatch(tagsAction.setNewTag({}));
    }
  }, [onBlur, dispatch, onValueChange, newTag, value]);

  const handleCreateNewTag = useCallback((newValue) => {
    dispatch(tagsAction.setIsModalOpen(true));
    if (newValue) dispatch(tagsAction.setDefaultName(newValue));
    setIsNewTag(true);
  }, [setIsNewTag, dispatch]);
  const handleEditTag = useCallback((tag) => {
    if (tag) {
      dispatch(tagsAction.setTagById({ data: Map(tag) }));
      dispatch(tagsAction.setIsModalOpen(true));
    }
  }, [dispatch]);
  const handleDeleteTag = useCallback((tag) => {
    if (tag && tag.id) dispatch(tagsAsyncAction.deleteTagAsync(tag.id));
  }, [dispatch]);

  switch (column.name) {
    case 'status': {
      const { colSpan } = props;
      const onChange = (e) => {
        onValueChange(e.target.value);
        setTimeout(() => {
          onBlur();
        });
      };
      if (column.customField === 'statusAction') {
        return (
          <TableInlineCellEditing.Cell colSpan={colSpan} {...props}>
            <Select
              disabled={!editingEnabled}
              value={value}
              onChange={onChange}
            >
              {map(globalStatusesAction, (option, index) => (
                <MenuItem key={index} value={option.value}>{option.name}</MenuItem>
              ))}
            </Select>
          </TableInlineCellEditing.Cell>
        );
      }
      return <TableInlineCellEditing.Cell {...props} />;
    }
    case 'users': {
      const { colSpan } = props;
      const onChange = (e) => {
        onValueChange(e.target.value);
        setTimeout(() => {
          onBlur();
        });
      };
      if (column.customField === 'usersAction') {
        return (
          <TableInlineCellEditing.Cell colSpan={colSpan} {...props}>
            <Select
              disabled={!editingEnabled}
              value={isObject(value) ? value.id : value}
              onChange={onChange}
            >
              {map(employeesData.toJS().items, (employee, index) => (
                <MenuItem key={index} value={employee.id}>
                  {employee.name || employee.email}
                </MenuItem>
              ))}
            </Select>
          </TableInlineCellEditing.Cell>
        );
      }
      return <TableInlineCellEditing.Cell {...props} />;
    }
    case 'rateType': {
      const { colSpan } = props;
      const onChange = (e) => {
        onValueChange(e.target.value);
        setTimeout(() => {
          onBlur();
        });
      };
      return (
        <TableInlineCellEditing.Cell colSpan={colSpan} {...props}>
          <Select
            disabled={!editingEnabled}
            value={value || ''}
            onChange={onChange}
            displayEmpty
          >
            <MenuItem value="fixed">{t('Fixed rate')}</MenuItem>
            <MenuItem value="real">{t('The real course')}</MenuItem>
          </Select>
        </TableInlineCellEditing.Cell>
      );
    }
    case 'currency': {
      const { colSpan } = props;
      const onChange = (e) => {
        onValueChange(e.target.value);
        setTimeout(() => {
          onBlur();
        });
      };
      return (
        <TableInlineCellEditing.Cell colSpan={colSpan} {...props}>
          {editingEnabled && (
            <Select
              disabled={!editingEnabled}
              value={isObject(value) ? value.id : value}
              onChange={onChange}
            >
              {map(currenciesData.toJS().items, (currency, index) => (
                <MenuItem key={index} value={currency.id}>
                  {currency.name || currency.code}
                </MenuItem>
              ))}
            </Select>
          )}
        </TableInlineCellEditing.Cell>
      );
    }
    case 'includeToBill':
    case 'default': {
      const { colSpan } = props;
      const onChange = (e) => {
        onValueChange(e.target.checked);
        setTimeout(() => {
          onBlur();
        });
      };
      return (
        <TableInlineCellEditing.Cell colSpan={colSpan} {...props}>
          <Checkbox
            disabled={!editingEnabled}
            checked={value || false}
            onChange={onChange}
            color="primary"
            py={0}
          />
        </TableInlineCellEditing.Cell>
      );
    }
    case 'tags': {
      const { colSpan } = props;
      const onChange = (e, newValue) => {
        onValueChange(newValue);
        setTimeout(() => {
          onBlur();
        });
      };

      if (column.customField === 'tagsObject') {
        return (
          <TableInlineCellEditing.Cell colSpan={colSpan} {...props}>
            <AutocompleteChips
              disabled={!editingEnabled}
              multiple
              fullWidth
              input={{
                name: 'tags',
                onChange: () => {},
              }}
              disableLabel
              defaultValue={value}
              label={t('Tags')}
              options={tagsData.toJS().items}
              handleOnFocus={() => isEmpty(tagsData.toJS().items) && (
                getAutocompleteLists(['tags'])
              )}
              defaultOpen
              handleCreateNew={handleCreateNewTag}
              handleEditTag={handleEditTag}
              handleDeleteTag={handleDeleteTag}
              handleChange={onChange}
              handleOnBlur={() => !isNewTag && onBlur()}
            />
          </TableInlineCellEditing.Cell>
        );
      }
      return <TableInlineCellEditing.Cell {...props} />;
    }
    case 'finishAt': {
      const { colSpan } = props;
      const onChange = (date) => {
        if (Date.parse(date)) {
          onValueChange(getValueFormatDate(formatDate(date.toISOString()), 'finishAt'));
          setTimeout(() => {
            onBlur();
          });
        }
      };
      if (column.customField === 'finishAtAction') {
        return (
          <TableInlineCellEditing.Cell colSpan={colSpan} {...props}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disabled={!editingEnabled}
                disableToolbar
                variant="inline"
                onChange={onChange}
                value={value.split('.').reverse().join('-')}
                format="dd.MM.yyyy"
                margin="normal"
                autoComplete="off"
                onBlur={onBlur}
                autoOk
                fullWidth
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </TableInlineCellEditing.Cell>
        );
      }
      return <TableInlineCellEditing.Cell {...props} />;
    }
    default: {
      return <TableInlineCellEditing.Cell {...props} />;
    }
  }
}

TableInlineEditingCell.displayName = 'TableInlineEditingCell';

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.row, nextProps.row)
  && isEqual(prevProps.column, nextProps.column)
  && isEqual(prevProps.autoFocus, nextProps.autoFocus)
  && isEqual(prevProps.editingEnabled, nextProps.editingEnabled)
  && isEqual(prevProps.value, nextProps.value);
}

export default memo(TableInlineEditingCell, areEqual);
