// Core
import { apply, put } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../config/api';

// actions
import actions from '../../action';

// helpers
import { setErrorMessage } from '../../../../../ui/_helpers/setNotificationMessage';

export function* callGetImagesBase64Worker({ payload: params }) {
  const response = yield apply(api, api.files.getBase64, [{ file: params.fileId }]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
      yield put(actions.setImagesWithBase64Clear());
    } else {
      yield put(actions.setImagesWithBase64([
        {
          ...params,
          ...response.data,
        },
      ]));
    }
  }
}
