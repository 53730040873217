// Core
import { all, call, takeEvery } from 'redux-saga/effects';
// Types
import asyncTypes from './asyncTypes';
// Workers
import {
  callGetApplicantsListWorker,
  callPostApplicantWorker,
  callPutApplicantWorker,
  callDeleteApplicantWorker,
  callGetApplicantAutocompleteWorker,
  callGetApplicantsInitialListWorker,
  callGetApplicantByIdWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
} from './workers';
import { types } from '../types';

function* watchApplicantsListWorker() {
  yield takeEvery(asyncTypes.GET_APPLICANTS_LIST_ASYNC, callGetApplicantsListWorker);
}

function* watchPostApplicantWorker() {
  yield takeEvery(asyncTypes.POST_APPLICANT_ASYNC, callPostApplicantWorker);
}

function* watchPutApplicantWorker() {
  yield takeEvery(asyncTypes.PUT_APPLICANT_ASYNC, callPutApplicantWorker);
}

function* watchDeleteApplicantWorker() {
  yield takeEvery(asyncTypes.DELETE_APPLICANT_ASYNC, callDeleteApplicantWorker);
}

function* watchGetApplicantAutocompleteWorker() {
  yield takeEvery(asyncTypes.GET_APPLICANT_AUTOCOMPLETE_ASYNC, callGetApplicantAutocompleteWorker);
}

function* watchApplicantsInitialListWorker() {
  yield takeEvery(asyncTypes.GET_APPLICANTS_INITIAL_LIST_ASYNC, callGetApplicantsInitialListWorker);
}

function* watchGetApplicantByIdWorker() {
  yield takeEvery(asyncTypes.GET_APPLICANT_BY_ID_ASYNC, callGetApplicantByIdWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_APPLICANTS_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_APPLICANTS_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_APPLICANTS_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_APPLICANTS_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_APPLICANTS_SORTING, callSetToLocalDataWorker);
}
function* watchSetColumnWidths() {
  yield takeEvery(types.SET_APPLICANTS_COLUMN_WIDTHS, callSetToLocalDataWorker);
}
function* watchSetColumnOrder() {
  yield takeEvery(types.SET_APPLICANTS_COLUMN_ORDER, callSetToLocalDataWorker);
}
function* watchSetHiddenColumnNames() {
  yield takeEvery(types.SET_APPLICANTS_HIDDEN_COLUMN_NAMES, callSetToLocalDataWorker);
}

export function* watchersApplicants() {
  yield all([
    call(watchApplicantsListWorker),
    call(watchPostApplicantWorker),
    call(watchPutApplicantWorker),
    call(watchDeleteApplicantWorker),
    call(watchGetApplicantAutocompleteWorker),
    call(watchApplicantsInitialListWorker),
    call(watchGetApplicantByIdWorker),
    call(watchSetCurrentPage),
    call(watchSetPageSize),
    call(watchSetFilters),
    call(watchSetSorting),
    call(watchGetInitialState),
    call(watchSetColumnWidths),
    call(watchSetColumnOrder),
    call(watchSetHiddenColumnNames),
  ]);
}
