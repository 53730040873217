// Core
import { call, put } from 'redux-saga/effects';

// config
import { api } from '../../../../config/api';

// actions
import actions from '../../action';

export function* callGetSystemMessageByIdWorker({ payload: id }) {
  const {
    setSystemMessageById, setSystemMessageDataById,
  } = actions;
  yield put(setSystemMessageById({
    pending: true,
  }));

  const response = yield call(api.systemMessage.getSystemMessageById, id);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    yield put(setSystemMessageDataById(data));
  }

  yield put(setSystemMessageById({
    pending: false,
  }));
}
