// Core
import { List, Map } from 'immutable';

// Instruments
import { isNil } from 'lodash';
import { pageSizes } from '../../../../../config/globalConfig';
import globalStateKeys from '../../../../../config/globalStateKeys';
import types from './types';

export const initialState = Map({
  itemByHashId: {
    data: {},
    pending: false,
    pendingPut: false,
    pendingPost: false,
  },
  data: Map({
    items: List(),
    currentPage: 0,
    filters: List(),
    pending: false,
    sorting: List([{ columnName: 'documentDate', direction: 'desc' }]),
    totalCount: 0,
    pageSize: pageSizes[0],
    reload: false,
  }),
  autocompleteOptions: Map({
    items: Map(),
  }),
  delete: Map({
    pending: false,
    data: Map(),
  }),
  pending: false,
  selectedDocuments: List(),
  entity: Map({
    id: '',
    name: '',
  }),
});

export const stateKeys = Object.freeze({
  controller: 'proposalDocumentsOut',
  selectedDocuments: 'selectedDocuments',
  entity: 'entity',
});

export const proposalDocumentsOutReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_PROPOSAL_DOCUMENTS_OUT_DATA: {
      const { items, totalCount, pending } = payload;
      if (pending) {
        return state.setIn([globalStateKeys.data, globalStateKeys.pending], pending);
      }
      return state
        .setIn([globalStateKeys.data, globalStateKeys.pending], pending)
        .setIn([globalStateKeys.data, globalStateKeys.items], List(items))
        .setIn([globalStateKeys.data, globalStateKeys.totalCount], totalCount);
    }
    case types.SET_PROPOSAL_DOCUMENTS_OUT_RELOAD: {
      return state.setIn([globalStateKeys.data, globalStateKeys.reload], payload);
    }
    case types.SET_PROPOSAL_DOCUMENTS_OUT_CURRENT_PAGE: {
      return state.setIn([globalStateKeys.data, globalStateKeys.currentPage], payload);
    }
    case types.SET_PROPOSAL_DOCUMENTS_OUT_FILTERS: {
      const filters = payload.filter((item) => !isNil(item.value));
      return state.setIn([globalStateKeys.data, globalStateKeys.filters], List(filters));
    }
    case types.SET_PROPOSAL_DOCUMENTS_OUT_SORTING: {
      return state.setIn([globalStateKeys.data, globalStateKeys.sorting], List(payload));
    }
    case types.SET_PROPOSAL_DOCUMENTS_OUT_PAGE_SIZE: {
      return state.setIn([globalStateKeys.data, globalStateKeys.pageSize], payload);
    }
    case types.SET_PROPOSAL_DOCUMENTS_OUT_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, sorting = [],
      } = payload;
      const reload = true;
      const currentSorting = sorting.length
        ? sorting
        : initialState.getIn([globalStateKeys.data, globalStateKeys.sorting]);
      return state
        .setIn([globalStateKeys.data, globalStateKeys.currentPage], currentPage)
        .setIn([globalStateKeys.data, globalStateKeys.filters], List(filters))
        .setIn([globalStateKeys.data, globalStateKeys.pageSize], pageSize)
        .setIn([globalStateKeys.data, globalStateKeys.totalCount], totalCount)
        .setIn([globalStateKeys.data, globalStateKeys.reload], reload)
        .setIn([globalStateKeys.data, globalStateKeys.sorting], List(currentSorting));
    }
    case types.SET_PROPOSAL_DOCUMENTS_OUT_BY_ID: {
      return state.mergeIn([globalStateKeys.itemByHashId], payload);
    }
    case types.SET_PENDING_DELETE_DOCUMENTS_OUT: {
      return state.setIn([globalStateKeys.delete, globalStateKeys.pending], payload);
    }
    case types.SET_DELETE_DOCUMENTS_DATA_OUT: {
      return state.setIn([globalStateKeys.delete, globalStateKeys.data], Map(payload));
    }
    case types.SET_DOCUMENTS_OUT_SELECTED_DOCUMENTS: {
      return state.setIn([stateKeys.selectedDocuments], List(payload));
    }
    case types.SET_DOCUMENTS_OUT_ENTITY_NAME: {
      return state.mergeIn([stateKeys.entity], Map(payload));
    }
    default:
      return state;
  }
};
