// core
import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import { push } from 'connected-react-router';

// ui
import {
  Button, Divider as MuiDivider,
  Typography, ButtonGroup, Box,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import { Add as AddIcon } from '@material-ui/icons';

// parts
import MadridCasesTable from './components/MadridCasesTable';

// hooks
import { useMadridCases } from './_hooks/useMadridCases';
import { useAccessList } from '../../../ui/_hooks/useAccessList';

// routes
import { pageLinks } from '../../../routes';

// config
import accessList from '../../../engine/config/accessList';

// styles
const Divider = styled(MuiDivider)(spacing);

function MadridCases() {
  useMadridCases();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const accessPost = useAccessList(accessList.mktu_post);

  return (
    <>
      <Helmet title={t('Madrid')} />
      <Typography variant="h3" gutterBottom display="inline">
        {t('Madrid')}
      </Typography>
      <Divider my={6} />

      {accessPost && (
        <Box mb={2}>
          <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                dispatch(push(pageLinks.tradeMarksRoutes.madridCases.new));
              }}
            >
              {t('ADD')}
              <AddIcon />
            </Button>
          </ButtonGroup>
        </Box>
      )}

      <MadridCasesTable />
    </>
  );
}

MadridCases.displayName = 'MadridCases';

MadridCases.propTypes = {};

function mapStateToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, null),
)(MadridCases);
