// Core
import { call, put } from 'redux-saga/effects';

// config
import { api } from '../../../../../config/api';

// actions
import actions from '../../action';

export function* callGetCategoryByIdWorker({ payload: id }) {
  const {
    setCategoryById, setCategoryDataById,
  } = actions;
  yield put(setCategoryById({
    pending: true,
  }));

  const response = yield call(api.prices.getCategoryById, id);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    yield put(setCategoryDataById(data));
  }

  yield put(setCategoryById({
    pending: false,
  }));
}
