// Helpers
import actionCreator from '../../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const integrationActionAsync = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_INTEGRATIONS_ASYNC, params);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_INTEGRATIONS_INITIAL_STATE_ASYNC, path);
  },
  getIntegrationByIdAsync(id) {
    return actionCreator(asyncTypes.GET_INTEGRATION_BY_ID_ASYNC, id);
  },
  postIntegrationAsync(data) {
    return actionCreator(asyncTypes.POST_INTEGRATION_ASYNC, data);
  },
  putIntegrationByIdAsync(data) {
    return actionCreator(asyncTypes.PUT_INTEGRATION_BY_ID_ASYNC, data);
  },
  deleteIntegrationAsync() {
    return actionCreator(asyncTypes.DELETE_INTEGRATION_ASYNC);
  },
  getAutocompleteOptionsAsync(params) {
    return actionCreator(asyncTypes.GET_INTEGRATIONS_AUTOCOMPLETE_OPTIONS_ASYNC, params);
  },
  postCheckFtpAsync(data) {
    return actionCreator(asyncTypes.POST_INTEGRATIONS_CHECK_FTP_ASYNC, data);
  },
});

export default integrationActionAsync;
