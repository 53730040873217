// Helpers
import actionCreator from '../../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const asyncActions = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_CALCULATIONS_ASYNC, params);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_CALCULATIONS_INITIAL_STATE_ASYNC, path);
  },
  deleteCalculationAsync() {
    return actionCreator(asyncTypes.DELETE_CALCULATION_ASYNC);
  },
});

export default asyncActions;
