// Core
import { put } from 'redux-saga/effects';

// Engine
import localData from '../../../../../config/localData';
import actions from '../../action';
import { setInitialPageState } from '../../../../../../ui/_helpers/setInitialPageState';

export function* callGetInitialStateWorker({ payload: url }) {
  const lists = yield localData.getItem(url);
  const initialPageState = setInitialPageState(lists || {});
  yield put(actions.setInitialState(initialPageState));
}
