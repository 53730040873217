// Core
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { push } from 'connected-react-router';
import { spacing } from '@material-ui/system';
import * as PropTypes from 'prop-types';

// lodash
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';

// ui
import {
  Typography, Divider as MuiDivider,
  Tabs as MuiTabs, Tab, Grid, Box, Button,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';

// actions
import categoryActionsAsync from '../../../engine/core/prices/category/saga/asyncAction';
import categoryActions from '../../../engine/core/prices/category/action';
import actions from '../../../engine/core/prices/tariffs/action';

// parts
import TariffsTable from './TariffsTable';

// hooks
import { useTariffs } from './_hooks/useTariffs';
import { pageLinks } from '../../../routes';
import selectors from '../../../engine/config/selectors';
import accessList from '../../../engine/config/accessList';
import { useAccessList } from '../../../ui/_hooks/useAccessList';

// styles
const Divider = styled(MuiDivider)(spacing);
const Tabs = styled(MuiTabs)(spacing);

function Tariffs({ valueTabFilter }) {
  useTariffs();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [valueTab, setValueTab] = useState(0);
  const accessPost = useAccessList(accessList.costs_post);
  const accessCategoryList = useAccessList(accessList.costsCategories_list_get);

  const handleChangeTab = (event, newValue) => {
    dispatch(actions.setFilters([
      { columnName: 'type', value: `${newValue + 1}` },
    ]));
  };

  useEffect(() => {
    if (!isEmpty(valueTabFilter)) {
      const filter = find(valueTabFilter, { columnName: 'type' });
      setValueTab(filter?.value ? +filter.value - 1 : 0);
    }
  }, [valueTabFilter]);

  useEffect(() => {
    if (accessCategoryList) {
      dispatch(categoryActionsAsync.getListAsync({
        sortBy: 'createdAt',
        sortDir: 'asc',
        limit: 100,
        page: 1,
      }));
    }
  }, [dispatch, accessCategoryList]);

  useEffect(() => function cleanup() {
    dispatch(categoryActions.setCategoriesData({
      items: [],
      totalCount: 0,
      pending: false,
    }));
  }, [dispatch]);

  return (
    <>
      <Helmet title={t('Tariffs')} />
      <Typography variant="h3" gutterBottom display="inline">
        {t('Tariffs')}
      </Typography>

      <Divider my={6} />

      {accessPost && (
        <Box mb={2}>
          <Grid item xs={12} sm={6} container alignItems="center">
            <Button
              variant="contained"
              color="secondary"
              ml={2}
              onClick={() => dispatch(push(pageLinks.prices.tariffs.new))}
            >
              {t('ADD')}
              <Add />
            </Button>
          </Grid>
        </Box>
      )}

      <Tabs
        mb={5}
        value={valueTab}
        onChange={handleChangeTab}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label={t('Services')} />
        <Tab label={t('Fees')} />
      </Tabs>

      <TariffsTable />
    </>
  );
}

Tariffs.displayName = 'Tariffs';

Tariffs.propTypes = {
  valueTabFilter: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
};

Tariffs.defaultProps = {};

function mapStateToProps(state) {
  return {
    valueTabFilter: selectors.pricesTariffs.filters(state),
  };
}

function mapDispatchToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Tariffs);
