export default Object.freeze({
  SET_DASHBOARD_FILTERS: 'SET_DASHBOARD_FILTERS',
  SET_DASHBOARD_RELOAD: 'SET_DASHBOARD_RELOAD',
  SET_DASHBOARD_IS_OPEN_FILTERS: 'SET_DASHBOARD_IS_OPEN_FILTERS',
  SET_DASHBOARD_INITIAL_STATE: 'SET_DASHBOARD_INITIAL_STATE',
  SET_DASHBOARD_PENDING: 'SET_DASHBOARD_PENDING',
  SET_DASHBOARD_ACTION_IN_CALENDAR: 'SET_DASHBOARD_ACTION_IN_CALENDAR',
  SET_DASHBOARD_OBJECTS: 'SET_DASHBOARD_OBJECTS',
  SET_DASHBOARD_OBJECTS_BY_CLIENT: 'SET_DASHBOARD_OBJECTS_BY_CLIENT',
  SET_DASHBOARD_OBJECTS_BY_COUNTRIES: 'SET_DASHBOARD_OBJECTS_BY_COUNTRIES',
  SET_DASHBOARD_OBJECTS_BY_STATUS: 'SET_DASHBOARD_OBJECTS_BY_STATUS',
  SET_DASHBOARD_OBJECTS_BY_COUNTRIES_STATUS: 'SET_DASHBOARD_OBJECTS_BY_COUNTRIES_STATUS',
});
