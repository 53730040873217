const asyncTypes = Object.freeze({
  GET_PENDING_ACTIONS_ASYNC: 'GET_PENDING_ACTIONS_ASYNC',
  GET_PENDING_ACTION_BY_ID_ASYNC: 'GET_PENDING_ACTION_BY_ID_ASYNC',
  PUT_PENDING_ACTION_BY_ID_ASYNC: 'PUT_PENDING_ACTION_BY_ID_ASYNC',
  POST_PENDING_ACTION_ASYNC: 'POST_PENDING_ACTION_ASYNC',
  DELETE_PENDING_ACTION_ASYNC: 'DELETE_PENDING_ACTION_ASYNC',
  GET_PENDING_ACTIONS_INITIAL_STATE_ASYNC: 'GET_PENDING_ACTIONS_INITIAL_STATE_ASYNC',
  PATCH_PENDING_ACTION_BY_ID_ASYNC: 'PATCH_PENDING_ACTION_BY_ID_ASYNC',
});

export default asyncTypes;
