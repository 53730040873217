// Engine
import { pageSizes } from '../../engine/config/globalConfig';

export function setInitialPageState(args) {
  const {
    currentPage = 0, filters = [], pageSize = pageSizes[0], totalCount = 0, sorting = [],
    columnWidths = [], columnOrder = [], hiddenColumnNames = [],
  } = args;

  return {
    currentPage,
    filters,
    pageSize,
    sorting,
    totalCount,
    columnWidths,
    columnOrder,
    hiddenColumnNames,
  };
}
