// Helpers
import actionCreator from '../../_helpers/actionCreator';
// Types
import types from './types';

const taxesActions = Object.freeze({
  setTaxesData(data) {
    return actionCreator(types.SET_TAXES_DATA, data);
  },
  setTaxById(data) {
    return actionCreator(types.SET_TAX_BY_ID, data);
  },
  setTaxDataById(data) {
    return actionCreator(types.SET_TAX_DATA_BY_ID, data);
  },
  setCurrentPage(currentPage) {
    return actionCreator(types.SET_TAXES_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return actionCreator(types.SET_TAXES_FILTERS, filters);
  },
  setSorting(sorting) {
    return actionCreator(types.SET_TAXES_SORTING, sorting);
  },
  setColumnWidths(data) {
    return actionCreator(types.SET_TAXES_COLUMN_WIDTHS, data);
  },
  setColumnOrder(data) {
    return actionCreator(types.SET_TAXES_COLUMN_ORDER, data);
  },
  setHiddenColumnNames(data) {
    return actionCreator(types.SET_TAXES_HIDDEN_COLUMN_NAMES, data);
  },
  setPageSize(pageSize) {
    return actionCreator(types.SET_TAXES_PAGE_SIZE, pageSize);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_TAXES_INITIAL_STATE, initialState);
  },
  setReload(reload) {
    return actionCreator(types.SET_TAXES_RELOAD, reload);
  },
  setDeleteTax(data) {
    return actionCreator(types.SET_DELETE_TAXES, data);
  },
  setIsModalOpen(data) {
    return actionCreator(types.SET_TAXES_IS_MODAL_OPEN, data);
  },
});

export default taxesActions;
