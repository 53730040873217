// Core
import { all, call, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetSearchesWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callGetSearchByIdWorker,
  callSetNumberPerPageWorker,
  callPostSearchWorker,
  callPutSearchByIdWorker,
  callGetSearchReportWorker,
  callDeleteSearchWorker,
  callPostQuickSearchWorker,
  callSetTabsWorker,
} from './workers';
import types from '../types';

function* watchGetSearches() {
  yield takeEvery(asyncTypes.GET_SEARCHES_ASYNC, callGetSearchesWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_SEARCHES_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_SEARCHES_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_SEARCHES_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_SEARCHES_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_SEARCHES_SORTING, callSetToLocalDataWorker);
}
function* watchSetColumnWidths() {
  yield takeEvery(types.SET_SEARCHES_COLUMN_WIDTHS, callSetToLocalDataWorker);
}
function* watchSetColumnOrder() {
  yield takeEvery(types.SET_SEARCHES_COLUMN_ORDER, callSetToLocalDataWorker);
}
function* watchSetHiddenColumnNames() {
  yield takeEvery(
    types.SET_SEARCHES_HIDDEN_COLUMN_NAMES,
    callSetToLocalDataWorker,
  );
}

function* watchGetSearchById() {
  yield takeEvery(asyncTypes.GET_SEARCH_BY_ID_ASYNC, callGetSearchByIdWorker);
}

function* watchSetNumberPerPage() {
  yield takeEvery(asyncTypes.SET_NUMBER_PER_PAGE_ASYNC, callSetNumberPerPageWorker);
}

function* watchPostSearch() {
  yield takeEvery(asyncTypes.POST_SEARCH_ASYNC, callPostSearchWorker);
}

function* watchPutSearchById() {
  yield takeEvery(asyncTypes.PUT_SEARCH_BY_ID_ASYNC, callPutSearchByIdWorker);
}

function* watchGetSearchReport() {
  yield takeEvery(asyncTypes.GET_SEARCH_REPORT_DATA_ASYNC, callGetSearchReportWorker);
}

function* watchDeleteSearch() {
  yield takeEvery(asyncTypes.DELETE_SEARCH_ASYNC, callDeleteSearchWorker);
}

function* watchPostQuickSearch() {
  yield takeEvery(asyncTypes.POST_QUICK_SEARCH_ASYNC, callPostQuickSearchWorker);
}

function* watchSetTabs() {
  yield takeEvery(asyncTypes.SET_TABS_ASYNC, callSetTabsWorker);
}

export function* watchersSearchesTable() {
  yield all([
    call(watchGetSearches),
    call(watchSetCurrentPage),
    call(watchSetPageSize),
    call(watchSetFilters),
    call(watchSetSorting),
    call(watchSetColumnWidths),
    call(watchSetColumnOrder),
    call(watchSetHiddenColumnNames),
    call(watchGetInitialState),
    call(watchGetSearchById),
    call(watchSetNumberPerPage),
    call(watchPostSearch),
    call(watchPutSearchById),
    call(watchGetSearchReport),
    call(watchDeleteSearch),
    call(watchPostQuickSearch),
    call(watchSetTabs),
  ]);
}
