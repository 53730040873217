// Core
import { Map, List } from 'immutable';

// Instruments
import types from './types';

// config
import globalStateKeys from '../../../config/globalStateKeys';

// helpers
import CAs from '../../../../ui/_helpers/CAs';

const initialState = Map({
  itemByHashId: Map({
    data: Map(),
    pending: false,
    pendingPut: false,
  }),
  items: {
    data: List(),
    pending: false,
    pendingPost: false,
    isModalOpen: false,
    goToListPage: false,
  },
  delete: Map({
    pendingDelete: false,
    id: '',
  }),
  autocompleteOptions: Map({
    items: Map(),
    pending: false,
  }),
  keys: Map({
    data: List(),
    pending: false,
    pendingPost: false,
    pendingDelete: false,
    openFieldsCreateKey: false,
  }),
  csk: {
    data: List(CAs),
    pending: false,
  },
  responsibleEmployeeById: Map({}),
  listResponsible: List(),
});

export const stateKeys = Object.freeze({
  controller: 'employees',
  keys: 'keys',
  csk: 'csk',
  openFieldsCreateKey: 'openFieldsCreateKey',
  responsibleEmployeeById: 'responsibleEmployeeById',
  listResponsible: 'listResponsible',
});

export const employeesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_EMPLOYEE_BY_ID: {
      return state.mergeIn([globalStateKeys.itemByHashId], Map({
        ...payload,
        ...payload.data ? {
          data: Map(payload.data),
        } : {},
      }));
    }
    case types.SET_RESPONSIBLE_EMPLOYEE_BY_ID: {
      return state.setIn([stateKeys.responsibleEmployeeById], Map(payload));
    }
    case types.SET_EMPLOYEES: {
      return state.mergeIn([globalStateKeys.items], payload);
    }
    case types.SET_EMPLOYEES_AUTOCOMPLETE_OPTIONS: {
      return state.mergeIn([
        globalStateKeys.autocompleteOptions,
        globalStateKeys.items,
      ], Map(payload));
    }
    case types.SET_PENDING_EMPLOYEES_AUTOCOMPLETE_OPTIONS: {
      return state.setIn([
        globalStateKeys.autocompleteOptions,
        globalStateKeys.pending,
      ], payload);
    }
    case types.SET_KEYS_DATA: {
      return state.mergeIn([stateKeys.keys], payload);
    }
    case types.SET_CSK_DATA: {
      return state.mergeIn([stateKeys.csk], payload);
    }
    case types.SET_DELETE_EMPLOYEE: {
      return state.mergeIn([globalStateKeys.delete], payload);
    }
    case types.SET_LIST_RESPONSIBLE: {
      return state.setIn([stateKeys.listResponsible], List(payload));
    }
    default:
      return state;
  }
};
