// Core
import { Map, List } from 'immutable';

// Instruments
import types from './types';

// config
import globalStateKeys from '../../config/globalStateKeys';

const initialState = Map({
  data: Map({
    file: Map(),
    pendingPost: false,
  }),
  uploadFiles: List(),
});

export const stateKeys = Object.freeze({
  controller: 'uploadFile',
  file: 'file',
});

export const uploadFileReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_UPLOAD_FILE: {
      return state.mergeIn([globalStateKeys.data], payload);
    }
    case types.SET_ALL_UPLOAD_FILES: {
      return state.setIn([globalStateKeys.uploadFiles], List(payload));
    }
    default:
      return state;
  }
};
