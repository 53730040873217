// Helpers
import actionCreator from '../../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const asyncActions = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_PRODUCT_CATEGORY_ASYNC, params);
  },
  getProductCategoryByIdAsync(id) {
    return actionCreator(asyncTypes.GET_PRODUCT_CATEGORY_BY_ID_ASYNC, id);
  },
  deleteProductCategoryAsync(id) {
    return actionCreator(asyncTypes.DELETE_PRODUCT_CATEGORY_ASYNC, id);
  },
  postProductCategoryAsync(data) {
    return actionCreator(asyncTypes.POST_PRODUCT_CATEGORY_ASYNC, data);
  },
  putProductCategoryByIdAsync(data) {
    return actionCreator(asyncTypes.PUT_PRODUCT_CATEGORY_BY_ID_ASYNC, data);
  },
});

export default asyncActions;
