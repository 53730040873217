// Core
import { call, put } from 'redux-saga/effects';

// config
import { api } from '../../../../../config/api';

// actions
import actions from '../../action';

export function* callGetIntegrationByIdWorker({ payload: id }) {
  const {
    setIntegrationById, setIntegrationDataById,
  } = actions;
  yield put(setIntegrationById({
    pending: true,
  }));

  const response = yield call(api.integration.getIntegrationById, id);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    yield put(setIntegrationDataById(data));
  }

  yield put(setIntegrationById({
    pending: false,
  }));
}
