export const formNameTariff = 'Tariff';
export const formNameTariffTable = 'TariffTable';

export const fields = Object.freeze({

  // redux-form
  name: 'name',
  billName: 'billName',
  type: 'type',
  category: 'category',
  inventions: 'inventions',
  objectType: 'objectType',
  term: 'term',
  code: 'code',
  termFromDocument: 'termFromDocument',
  paymentReceivers: 'paymentReceivers',
});
