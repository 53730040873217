// Helpers
import actionCreator from '../../_helpers/actionCreator';
// Types
import types from './types';

const pendingActions = Object.freeze({
  setPendingActionById(action) {
    return actionCreator(types.SET_PENDING_ACTION_BY_ID, action);
  },
  setPendingActions(actionsData) {
    return actionCreator(types.SET_PENDING_ACTIONS, actionsData);
  },
  setDeletePendingAction(data) {
    return actionCreator(types.SET_DELETE_PENDING_ACTION, data);
  },
  setFilters(filters) {
    return actionCreator(types.SET_PENDING_ACTION_CALENDAR_FILTERS, filters);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_PENDING_ACTION_CALENDAR_INITIAL_STATE, initialState);
  },
  setReload(reload) {
    return actionCreator(types.SET_PENDING_ACTION_CALENDAR_RELOAD, reload);
  },
  setPendingActionsAllList(data) {
    return actionCreator(types.SET_PENDING_ACTIONS_ALL_LIST, data);
  },
  setDisableReload(data) {
    return actionCreator(types.SET_PENDING_ACTIONS_DISABLE_RELOAD, data);
  },
  mergePendingActionEntityList(data) {
    return actionCreator(types.MERGE_PENDING_ACTION_ENTITY_LIST, data);
  },
  setPendingActionEntityList(data) {
    return actionCreator(types.SET_PENDING_ACTION_ENTITY_LIST, data);
  },
  setIsModalOpen(data) {
    return actionCreator(types.SET_PENDING_ACTION_MODAL_OPEN, data);
  },
  setDisableNotifications(data) {
    return actionCreator(types.SET_PENDING_ACTIONS_DISABLE_NOTIFICATIONS, data);
  },
});

export default pendingActions;
