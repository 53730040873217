// Core
import { all, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetCompaniesWorker,
  callGetCompanyByIdWorker,
  callPutCompanyByIdWorker,
  callPostCompanyWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callDeleteCompanyWorker,
  callGetAutocompleteOptionsWorker,
  callPostCheckFtpWorker,
  callGetAllCompaniesWorker,
} from './workers';
import types from '../types';

function* watchGetCompanies() {
  yield takeEvery(asyncTypes.GET_COMPANIES_ASYNC, callGetCompaniesWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_COMPANIES_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchGetCompanyById() {
  yield takeEvery(asyncTypes.GET_COMPANY_BY_ID_ASYNC, callGetCompanyByIdWorker);
}
function* watchPostCompany() {
  yield takeEvery(asyncTypes.POST_COMPANY_ASYNC, callPostCompanyWorker);
}
function* watchPutCompanyById() {
  yield takeEvery(asyncTypes.PUT_COMPANY_BY_ID_ASYNC, callPutCompanyByIdWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_COMPANIES_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_COMPANIES_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_COMPANIES_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_COMPANIES_SORTING, callSetToLocalDataWorker);
}
function* watchSetColumnWidths() {
  yield takeEvery(types.SET_COMPANIES_COLUMN_WIDTHS, callSetToLocalDataWorker);
}
function* watchSetColumnOrder() {
  yield takeEvery(types.SET_COMPANIES_COLUMN_ORDER, callSetToLocalDataWorker);
}
function* watchSetHiddenColumnNames() {
  yield takeEvery(types.SET_COMPANIES_HIDDEN_COLUMN_NAMES, callSetToLocalDataWorker);
}
function* watchDeleteCompany() {
  yield takeEvery(asyncTypes.DELETE_COMPANY_ASYNC, callDeleteCompanyWorker);
}

function* watchGetAutocompleteOptions() {
  yield takeEvery(
    asyncTypes.GET_COMPANIES_AUTOCOMPLETE_OPTIONS_ASYNC,
    callGetAutocompleteOptionsWorker,
  );
}

function* watchPostCheckFtp() {
  yield takeEvery(asyncTypes.POST_CHECK_FTP_ASYNC, callPostCheckFtpWorker);
}

function* watchGetAllCompanies() {
  yield takeEvery(asyncTypes.GET_ALL_COMPANIES_ASYNC, callGetAllCompaniesWorker);
}

export function* watchersCompany() {
  yield all([
    watchGetCompanies(),
    watchGetInitialState(),
    watchGetCompanyById(),
    watchPostCompany(),
    watchPutCompanyById(),
    watchSetCurrentPage(),
    watchSetPageSize(),
    watchSetFilters(),
    watchSetSorting(),
    watchSetColumnWidths(),
    watchSetColumnOrder(),
    watchSetHiddenColumnNames(),
    watchDeleteCompany(),
    watchGetAutocompleteOptions(),
    watchPostCheckFtp(),
    watchGetAllCompanies(),
  ]);
}
