// Core
import { all, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callPostPendingActionWorker,
  callPutPendingActionByIdWorker,
  callGetPendingActionsWorker,
  callGetPendingActionByIdWorker,
  callDeletePendingActionWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callPatchPendingActionByIdWorker,
} from './workers';
import types from '../types';

function* watchGetPendingActionByIdActions() {
  yield takeEvery(asyncTypes.GET_PENDING_ACTION_BY_ID_ASYNC, callGetPendingActionByIdWorker);
}

function* watchGetPendingActions() {
  yield takeEvery(asyncTypes.GET_PENDING_ACTIONS_ASYNC, callGetPendingActionsWorker);
}

function* watchPutPendingActionById() {
  yield takeEvery(asyncTypes.PUT_PENDING_ACTION_BY_ID_ASYNC, callPutPendingActionByIdWorker);
}

function* watchPatchPendingActionById() {
  yield takeEvery(asyncTypes.PATCH_PENDING_ACTION_BY_ID_ASYNC, callPatchPendingActionByIdWorker);
}

function* watchPostPendingAction() {
  yield takeEvery(asyncTypes.POST_PENDING_ACTION_ASYNC, callPostPendingActionWorker);
}

function* watchDeletePendingAction() {
  yield takeEvery(asyncTypes.DELETE_PENDING_ACTION_ASYNC, callDeletePendingActionWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_PENDING_ACTIONS_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_PENDING_ACTION_CALENDAR_FILTERS, callSetToLocalDataWorker);
}
export function* watchersPendingActions() {
  yield all([
    watchGetPendingActionByIdActions(),
    watchPatchPendingActionById(),
    watchGetPendingActions(),
    watchPutPendingActionById(),
    watchPostPendingAction(),
    watchDeletePendingAction(),
    watchGetInitialState(),
    watchSetFilters(),
  ]);
}
