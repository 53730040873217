// Helpers
import actionCreator from '../../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const asyncActions = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_DOCUMENT_TYPES_CATEGORY_ASYNC, params);
  },
  getDocumentTypeCategoryByIdAsync(id) {
    return actionCreator(asyncTypes.GET_DOCUMENT_TYPE_CATEGORY_BY_ID_ASYNC, id);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_DOCUMENT_TYPES_CATEGORY_INITIAL_STATE_ASYNC, path);
  },
  deleteDocumentTypeCategoryAsync(id) {
    return actionCreator(asyncTypes.DELETE_DOCUMENT_TYPE_CATEGORY_ASYNC, id);
  },
  postDocumentTypeCategoryAsync(data) {
    return actionCreator(asyncTypes.POST_DOCUMENT_TYPE_CATEGORY_ASYNC, data);
  },
  putDocumentTypeCategoryByIdAsync(data) {
    return actionCreator(asyncTypes.PUT_DOCUMENT_TYPE_CATEGORY_BY_ID_ASYNC, data);
  },
  getAutocompleteOptionsAsync(params) {
    return actionCreator(asyncTypes.GET_DOCUMENT_TYPES_CATEGORY_AUTOCOMPLETE_OPTIONS_ASYNC, params);
  },
});

export default asyncActions;
