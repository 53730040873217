// Core
import { apply, select, put } from 'redux-saga/effects';
import isNil from 'lodash/isNil';

// Engine
import localData from '../../../../config/localData';
import selectors from '../../../../config/selectors';
import types from '../../types';
import actions from '../../action';
import helpersActionsAsync from '../../../helpers/saga/asyncAction';

export function* callSetToLocalDataWorker({ type, payload }) {
  const pathname = yield select(selectors.router.pathname);
  const data = {
    currentPage: yield select(selectors.sending.currentPage),
    filters: yield select(selectors.sending.filters),
    pageSize: yield select(selectors.sending.pageSize),
    totalCount: yield select(selectors.sending.totalCount),
    sorting: yield select(selectors.sending.sorting),
    columnWidths: (yield select(selectors.sending.columnWidths)),
    columnOrder: (yield select(selectors.sending.columnOrder)),
    hiddenColumnNames: (yield select(selectors.sending.hiddenColumnNames)),
  };

  let currentData;
  yield put(actions.setReload(true));
  switch (type) {
    case types.SET_SENDING_CURRENT_PAGE: {
      currentData = { currentPage: payload };
      break;
    }
    case types.SET_SENDING_PAGE_SIZE: {
      currentData = { pageSize: payload };
      break;
    }
    case types.SET_SENDING_FILTERS: {
      const { currentPage } = data;
      const filters = payload.filter(({ value }) => !isNil(value));
      if (currentPage) {
        yield put(actions.setCurrentPage(0));
      }
      currentData = { filters };
      break;
    }
    case types.SET_SENDING_SORTING: {
      currentData = { sorting: payload };
      break;
    }
    case types.SET_SENDING_COLUMN_WIDTHS: {
      currentData = { columnWidths: payload };
      break;
    }
    case types.SET_SENDING_COLUMN_ORDER: {
      currentData = { columnOrder: payload };
      break;
    }
    case types.SET_SENDING_HIDDEN_COLUMN_NAMES: {
      currentData = { hiddenColumnNames: payload };
      break;
    }
    default: {
      break;
    }
  }

  data.currentPage = yield select(selectors.sending.currentPage);
  yield apply(localData, localData.setItem, [pathname, { ...data, ...currentData }]);

  switch (type) {
    case types.SET_SENDING_COLUMN_WIDTHS:
    case types.SET_SENDING_COLUMN_ORDER:
    case types.SET_SENDING_HIDDEN_COLUMN_NAMES: {
      yield put(helpersActionsAsync.setSaveFrontendSetting());
      break;
    }
    default: {
      break;
    }
  }
}
