// Core
import { createLogger } from 'redux-logger';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
// Instruments
export const history = createBrowserHistory();
const loggerStatus = process.env.REACT_APP_REDUX_LOGER;

const logger = createLogger({
  duration: true,
  collapsed: true,
  colors: {
    title: () => '#139BFE',
    prevState: () => '#1C5FAF',
    action: () => '#149945',
    nextState: () => '#A47104',
    error: () => '#ff0005',
  },
});

const dev = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'local';
const sagaMiddleWare = createSagaMiddleware();
const reactRouterMiddleware = routerMiddleware(history);
const middleware = [sagaMiddleWare, reactRouterMiddleware];

if (dev && loggerStatus === 'true') {
  middleware.push(logger);
}

export { sagaMiddleWare, dev, middleware };
