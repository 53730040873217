import React from 'react';
import styled from 'styled-components';

import AssignmentIcon from '@material-ui/icons/Assignment';
import ImportExportOutlinedIcon from '@material-ui/icons/ImportExportOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import {
  SvgIcon as MuiSvgIcon,
} from '@material-ui/core';

// Pages
// Auth components
import {
  Users, Calendar, Sliders,
  Grid, FileText, File, Send,
} from 'react-feather';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import SignIn from '../pages/Auth/SignIn';
import ActionsPage from '../pages/ActionsPage/ActionsPage';

// Company components
import NotificationsTasks from '../pages/ActionsPage/components/NotificationsTasks/NotificationsTasks';
import Company from '../pages/Company/Company/Company';
import Companies from '../pages/Company/Companies/Companies';
import Clients from '../pages/Company/Clients/Clients';
import Client from '../pages/Company/Client/Client';
import Import from '../pages/ImportObjects/Import';
import DocumentTemplates from '../pages/DocumentTemplates/DocumentTemplates';
import DepartmentDocuments from '../pages/DepartmentDocuments/DepartmentDocuments';
import DepartmentDocument from '../pages/DepartmentDocuments/components/DepartmentDocument';
import Applicants from '../pages/Company/Applicants/Applicants';
import Integrations from '../pages/Company/Integrations/Integrations';
import Employees from '../pages/Company/Employees/Employees';
import Employee from '../pages/Company/Employee/Employee';
import Payments from '../pages/Company/Payments/Payments';
import Payment from '../pages/Company/Payment/Payment';
import Orders from '../pages/Company/Orders/Orders';
import Order from '../pages/Company/Order/Order';
import ResetPassword from '../pages/Auth/ResetPassword';
import Proposal from '../pages/TradeMarks/Proposal/Proposal';
import MadridCases from '../pages/TradeMarks/MadridCase/MadridCases';
import MadridCase from '../pages/TradeMarks/MadridCase/components/MadridCase';
import Rebounds from '../pages/TradeMarks/Rebounds/Rebounds';
import Rebound from '../pages/TradeMarks/Rebounds/Rebound/Rebound';
import MktuTemplates from '../pages/TradeMarks/MktuTemplates/MktuTemplates';
import MktuTemplate from '../pages/TradeMarks/MktuTemplates/MktuTemplate/MktuTemplate';
import Searches from '../pages/TradeMarks/Searches/Searches';
import Categories from '../pages/Prices/Categories/Categories';
import EmailMessages from '../pages/Email/Email/EmailMessages';
import MailSettings from '../pages/Email/MailSettings/MailSettings';
import Currencies from '../pages/Currencies/Currencies/Currencies';
import CurrencyRates from '../pages/Currencies/CurrencyRates/CurrencyRates';
import Tariffs from '../pages/Prices/Tariffs/Tariffs';
import Works from '../pages/Prices/Works/Works';
import DocumentTypes from '../pages/DocumentTypes/DocumentTypes';
import Notifications from '../pages/Notifications/Notifications';
import Jurisdictions from '../pages/Jurisdictions/Jurisdictions';
import Logging from '../pages/Logging/Logging';
import Taxes from '../pages/Taxes/Taxes';
import Tax from '../pages/Taxes/components/Tax';
import SearchAddEdit from '../pages/TradeMarks/Searches/Search/Search';
import Monitoring from '../pages/TradeMarks/Monitoring/Monitoring';
import MonitoringDetail from '../pages/TradeMarks/Monitoring/MonitoringDetail/MonitoringDetail';
// import Calculations from '../pages/TradeMarks/Calculations/Calculations';
import Inventions from '../pages/Inventions/Inventions';
import UtilityModels from '../pages/UtilityModels/UtilityModels';
import IndustrialDesigns from '../pages/IndustrialDesigns/IndustrialDesigns';
import User from '../pages/Profile/User';
import PaymentReceivers from '../pages/PaymentReceivers/PaymentReceivers';
import TradeMarksProposalTab from '../pages/TradeMarks/Proposal/ProposalTab/ProposalTab';
import UtilityModelsTab from '../pages/UtilityModels/UtilityModelsTab/UtilityModelsTab';
import InventionsTab from '../pages/Inventions/InventionsTab/InventionsTab';
import IndustrialDesignsTab from '../pages/IndustrialDesigns/IndustrialDesignsTab/IndustrialDesignsTab';
import Tariff from '../pages/Prices/Tariffs/Tariff/Tariff';
import Sending from '../pages/Sending/Sending';
import Receiving from '../pages/Receiving/Receiving';
import Settings from '../pages/Settings/Settings';
import SystemMessage from '../pages/SystemMessage/SystemMessage';
import Products from '../pages/Products/Products';
import Product from '../pages/Products/components/Product';
import Dashboard from '../pages/Dashboard/Dashboard';

// icons
import { ReactComponent as BuildingIcon } from '../ui/_helpers/img/building.svg';
import { ReactComponent as CertificateIcon } from '../ui/_helpers/img/certificate.svg';
import { ReactComponent as TrademarkIcon } from '../ui/_helpers/img/trademark.svg';
import { ReactComponent as BoxIcon } from '../ui/_helpers/img/box.svg';
import { ReactComponent as AtomIcon } from '../ui/_helpers/img/atom-alt.svg';

// config
import accessList from '../engine/config/accessList';

function replaceHashId(path, id, hashId) {
  const reg = new RegExp(`:${hashId || 'hashId'}`, 'g');

  return path.replace(reg, id);
}

const SvgIcon = styled(MuiSvgIcon)`
  opacity: 1!important;
`;

export const routersLink = Object.freeze({
  errors: {
    status401: '/401',
    status404: '/404',
    status500: '/500',
  },
  users: {
    auth: '/auth',
    signIn: '/sign-in',
    resetPassword: '/reset-password',
  },
  actions: {
    controller: '/calendar',
    // Child
    actions: '/pending-actions',
    table: '/pending-actions-table',
    action: '/:hashId',
  },
  profile: {
    controller: '/profile',
    // Child
    user: '/user',
  },
  company: {
    controller: '/company',
    // Child
    employees: {
      controller: '/employees',
      create: '/employee-create',
      employee: '/:hashId',
    },
    applicants: '/applicants',
    integrations: '/integrations',
    companies: '/companies',
    clients: {
      controller: '/clients',
      client: '/:hashId',
    },
    payments: {
      controller: '/payments',
      payment: '/:hashId',
    },
    orders: {
      controller: '/orders',
      order: '/:hashId',
      entityOrder: '/entity/:entityName/:entityId',
    },
  },
  documents: {
    controller: '/documents',
    // Child
    documentTemplates: '/templates',
    departmentDocuments: '/department-documents',
    departmentDocument: '/:hashId',
    entityDocuments: '/entity/:entityName/:entityId',
  },
  tradeMarksRoutes: {
    controller: '/trade-marks',
    // Child
    id: '/:hashId',
    calculations: '/calculations',
    madridCases: {
      controller: '/madrid-cases',
      madridCase: '/:hashId',
    },
    rebounds: {
      controller: '/rebounds',
      rebound: '/:hashId',
    },
    mktuTemplates: {
      controller: '/mktu-template',
      mktuTemplate: '/:hashId',
    },
    searches: {
      controller: '/searches',
      quick: '/quick',
      search: '/:hashId',
    },
    actions: {
      calendar: '/calendar',
      list: '/list',
    },
    monitoring: {
      controller: '/monitoring',
      monitoringDetail: '/:hashId',
    },
  },
  industrialDesigns: {
    controller: '/industrial-designs',
  },
  inventions: {
    controller: '/inventions',
  },
  products: {
    controller: '/products',
  },
  utilityModels: {
    controller: '/utility-models',
  },
  currencies: {
    controller: '/currencies',
    currencyRates: '/currency-rates',
  },
  prices: {
    controller: '/prices',

    // Child
    categories: '/categories',
    tariffs: '/tariffs',
    id: '/:hashId',
    works: '/works',
  },
  documentTypes: {
    controller: '/document-types',
  },
  paymentReceivers: {
    controller: '/payment-receivers',
  },
  notifications: {
    controller: '/notifications',
    tasks: '/tasks',
  },
  systemMessage: {
    controller: '/system-message',
  },
  jurisdictions: {
    controller: '/jurisdictions',
  },
  logging: {
    controller: '/logging',
  },
  taxes: {
    controller: '/taxes',
    tax: '/:hashId',
  },
  email: {
    controller: '/email',
    settings: '/settings',
  },
  sending: {
    controller: '/sending',
  },
  receiving: {
    controller: '/receiving',
  },
  dashboard: {
    controller: '/',
  },

  //
  globalPath: {
    new: '/new',
    edit: '/edit',
    show: '/show',
    id: '/:hashId',
  },
  importObjects: '/import',
  settings: '/settings',
});

export const pageLinks = Object.freeze({
  actions: {
    new: `${routersLink.actions.actions}${routersLink.globalPath.new}`,
    edit: (id) => `${routersLink.actions.actions}${routersLink.globalPath.edit}${replaceHashId(routersLink.actions.action, id)}`,
    table: routersLink.actions.table,
    calendar: routersLink.actions.controller,
  },
  company: {
    clients: {
      new: `${routersLink.company.clients.controller}${routersLink.globalPath.new}`,
      all: `${routersLink.company.clients.controller}`,
      edit: (id) => `${routersLink.company.clients.controller}${routersLink.globalPath.edit}${replaceHashId(routersLink.company.clients.client, id)}`,
    },
    applicants: `${routersLink.company.applicants}`,
    integrations: `${routersLink.company.integrations}`,
    employees: {
      all: `${routersLink.company.employees.controller}`,
      edit: (id) => `${routersLink.company.employees.controller}${routersLink.globalPath.edit}${replaceHashId(routersLink.company.employees.employee, id)}`,
    },
    companies: {
      new: `${routersLink.company.companies}${routersLink.globalPath.new}`,
      all: `${routersLink.company.companies}`,
      edit: (id) => `${routersLink.company.companies}${routersLink.globalPath.edit}${replaceHashId(routersLink.globalPath.id, id)}`,
    },
    orders: {
      new: `${routersLink.company.orders.controller}${routersLink.globalPath.new}`,
      all: `${routersLink.company.orders.controller}`,
      edit: (id) => `${routersLink.company.orders.controller}${routersLink.globalPath.edit}${replaceHashId(routersLink.company.orders.order, id)}`,
    },
    createEmployee: routersLink.company.employees.create,
    payments: {
      all: `${routersLink.company.payments.controller}`,
      new: `${routersLink.company.payments.controller}${routersLink.globalPath.new}`,
      edit: (id) => {
        const paymentsController = routersLink.company.payments.controller;
        const { edit } = routersLink.globalPath;
        const hashId = routersLink.company.payments.payment;
        const paymentId = id ? replaceHashId(hashId, id) : hashId;

        return `${paymentsController}${edit}${paymentId}`;
      },
    },

  },
  profile: {
    user: `${routersLink.profile.controller}${routersLink.profile.user}${routersLink.globalPath.edit}`,
  },
  tradeMarksRoutes: {
    proposal: {
      all: routersLink.tradeMarksRoutes.controller,
      new: `${routersLink.tradeMarksRoutes.controller}${routersLink.globalPath.new}`,
      newTab: (hash) => `${routersLink.tradeMarksRoutes.controller}${routersLink.globalPath.new}#${hash}`,
      edit: (id, hash) => {
        const { controller } = routersLink.tradeMarksRoutes;
        const { edit } = routersLink.globalPath;
        const tmId = replaceHashId(routersLink.globalPath.id, id);
        const hashTab = hash ? `#${hash}` : '';

        return `${controller}${edit}${tmId}${hashTab}`;
      },
    },
    calculations: `${routersLink.tradeMarksRoutes.controller}${routersLink.tradeMarksRoutes.calculations}`,
    madridCases: {
      all: `${routersLink.tradeMarksRoutes.controller}${routersLink.tradeMarksRoutes.madridCases.controller}`,
      new: `${routersLink.tradeMarksRoutes.controller}${routersLink.tradeMarksRoutes.madridCases.controller}${routersLink.globalPath.new}`,
      edit: (id) => `${routersLink.tradeMarksRoutes.controller}${routersLink.tradeMarksRoutes.madridCases.controller}${routersLink.globalPath.edit}${replaceHashId(routersLink.tradeMarksRoutes.madridCases.madridCase, id)}`,
    },
    rebounds: {
      all: `${routersLink.tradeMarksRoutes.controller}${routersLink.tradeMarksRoutes.rebounds.controller}`,
      new: `${routersLink.tradeMarksRoutes.controller}${routersLink.tradeMarksRoutes.rebounds.controller}${routersLink.globalPath.new}`,
      edit: (id) => `${routersLink.tradeMarksRoutes.controller}${routersLink.tradeMarksRoutes.rebounds.controller}${routersLink.globalPath.edit}${replaceHashId(routersLink.tradeMarksRoutes.rebounds.rebound, id)}`,
    },
    mktuTemplates: {
      all: `${routersLink.tradeMarksRoutes.controller}${routersLink.tradeMarksRoutes.mktuTemplates.controller}`,
      new: `${routersLink.tradeMarksRoutes.controller}${routersLink.tradeMarksRoutes.mktuTemplates.controller}${routersLink.globalPath.new}`,
      edit: (id) => `${routersLink.tradeMarksRoutes.controller}${routersLink.tradeMarksRoutes.mktuTemplates.controller}${routersLink.globalPath.edit}${replaceHashId(routersLink.tradeMarksRoutes.mktuTemplates.mktuTemplate, id)}`,
    },
    searches: {
      all: `${routersLink.tradeMarksRoutes.controller}${routersLink.tradeMarksRoutes.searches.controller}`,
      new: `${routersLink.tradeMarksRoutes.controller}${routersLink.tradeMarksRoutes.searches.controller}${routersLink.globalPath.new}`,
      quick: `${routersLink.tradeMarksRoutes.controller}${routersLink.tradeMarksRoutes.searches.controller}${routersLink.tradeMarksRoutes.searches.quick}`,
      edit: (id) => {
        const { controller } = routersLink.tradeMarksRoutes;
        const searchesController = routersLink.tradeMarksRoutes.searches.controller;
        const { edit } = routersLink.globalPath;
        const hashId = routersLink.tradeMarksRoutes.searches.search;
        const searchId = id ? replaceHashId(hashId, id) : hashId;

        return `${controller}${searchesController}${edit}${searchId}`;
      },

    },
    monitoring: {
      all: `${routersLink.tradeMarksRoutes.controller}${routersLink.tradeMarksRoutes.monitoring.controller}`,
      new: `${routersLink.tradeMarksRoutes.controller}${routersLink.tradeMarksRoutes.monitoring.controller}${routersLink.globalPath.new}`,
      edit: (id) => `${routersLink.tradeMarksRoutes.controller}${routersLink.tradeMarksRoutes.monitoring.controller}${routersLink.globalPath.edit}${replaceHashId(routersLink.tradeMarksRoutes.monitoring.monitoringDetail, id)}`,
    },
  },
  industrialDesigns: {
    all: routersLink.industrialDesigns.controller,
    new: `${routersLink.industrialDesigns.controller}${routersLink.globalPath.new}`,
    edit: (id, hash) => `${routersLink.industrialDesigns.controller}${routersLink.globalPath.edit}${replaceHashId(routersLink.globalPath.id, id)}${hash ? `#${hash}` : ''}`,
  },
  inventions: {
    all: routersLink.inventions.controller,
    new: `${routersLink.inventions.controller}${routersLink.globalPath.new}`,
    edit: (id, hash) => `${routersLink.inventions.controller}${routersLink.globalPath.edit}${replaceHashId(routersLink.globalPath.id, id)}${hash ? `#${hash}` : ''}`,
  },

  utilityModels: {
    all: routersLink.utilityModels.controller,
    new: `${routersLink.utilityModels.controller}${routersLink.globalPath.new}`,
    edit: (id, hash) => `${routersLink.utilityModels.controller}${routersLink.globalPath.edit}${replaceHashId(routersLink.globalPath.id, id)}${hash ? `#${hash}` : ''}`,
  },
  products: {
    all: routersLink.products.controller,
    new: `${routersLink.products.controller}${routersLink.globalPath.new}`,
    edit: (id) => `${routersLink.products.controller}${routersLink.globalPath.edit}${replaceHashId(routersLink.globalPath.id, id)}`,
  },
  prices: {
    categories: {
      all: `${routersLink.prices.controller}${routersLink.prices.categories}`,
    },
    tariffs: {
      all: `${routersLink.prices.controller}${routersLink.prices.tariffs}`,
      new: `${routersLink.prices.controller}${routersLink.prices.tariffs}${routersLink.globalPath.new}`,
      edit: (id) => `${routersLink.prices.controller}${routersLink.prices.tariffs}${routersLink.globalPath.edit}${replaceHashId(routersLink.globalPath.id, id)}`,
    },
    works: {
      all: `${routersLink.prices.controller}${routersLink.prices.works}`,
    },
  },
  currencies: {
    all: `${routersLink.currencies.controller}`,
  },
  currencyRates: {
    all: `${routersLink.currencies.currencyRates}`,
  },
  email: {
    all: `${routersLink.email.controller}`,
    settings: `${routersLink.email.controller}${routersLink.email.settings}`,
  },
  jurisdictions: {
    all: routersLink.jurisdictions.controller,
  },
  logging: {
    all: routersLink.logging.controller,
  },
  taxes: {
    all: routersLink.taxes.controller,
    edit: (id) => `${routersLink.taxes.controller}${routersLink.globalPath.edit}${replaceHashId(routersLink.taxes.tax, id)}`,
  },
  documentTypes: {
    all: routersLink.documentTypes.controller,
  },
  notifications: {
    all: routersLink.notifications.controller,
    tasks: `${routersLink.notifications.controller}${routersLink.notifications.tasks}`,
  },
  systemMessage: {
    all: routersLink.systemMessage.controller,
  },
  dashboard: {
    all: routersLink.dashboard.controller,
  },

  documents: {
    departmentDocuments: {
      new: `${routersLink.documents.controller}${routersLink.globalPath.new}`,
      all: `${routersLink.documents.controller}`,
      edit: (id) => `${routersLink.documents.controller}${routersLink.globalPath.edit}${replaceHashId(routersLink.documents.departmentDocument, id)}`,
    },
    documentTemplates: {
      all: `${routersLink.documents.controller}${routersLink.documents.documentTemplates}`,
    },
  },
});

const authRoutes = {
  id: 'Auth',
  path: routersLink.users.auth,
  icon: <Users />,
  children: [
    {
      path: routersLink.users.signIn,
      name: 'Sign In',
      component: SignIn,
      type: 'link',
    },
    {
      path: routersLink.users.resetPassword,
      name: 'Reset Password',
      component: ResetPassword,
      type: 'link',
    },
  ],
  component: null,
};

const profileUserRoutes = {
  id: 'Profile',
  path: 'profile',
  icon: <Grid />,
  children: [
    {
      path: pageLinks.profile.user,
      name: 'User',
      type: 'link',
      component: User,
      accessName: accessList.users_put,
    },
  ],
  component: null,
};

const dashboardRoutes = {
  id: 'Dashboard',
  path: pageLinks.dashboard.all,
  icon: <Sliders />,
  component: Dashboard,
  accessName: accessList.dashboard_list_get,
};

const ActionsRoutes = {
  id: 'Calendar',
  path: pageLinks.actions.calendar,
  icon: <Calendar />,
  component: ActionsPage,
  accessName: accessList.pendingActions_list_get,
};

const ActionsTableRoutes = {
  id: 'Action table',
  type: 'link',
  path: pageLinks.actions.table,
  component: ActionsPage,
  accessName: accessList.pendingActions_list_get,
};

const receiving = {
  id: 'Receiving',
  type: 'link',
  path: routersLink.receiving.controller,
  component: Receiving,
  accessName: accessList.outDocs_list_get,
  icon: <CallReceivedIcon />,
};

const sending = {
  id: 'Sending',
  type: 'link',
  icon: <Send />,
  path: routersLink.sending.controller,
  component: Sending,
  accessName: accessList.search_list_get,
};

const email = {
  path: pageLinks.email.all,
  id: 'Email',
  type: 'link',
  component: EmailMessages,
  icon: <MailOutlineIcon />,
  accessName: accessList.emailLetters_list_get,
};

const mailSettings = {
  id: 'MailSettings',
  path: pageLinks.email.settings,
  type: 'link',
  component: MailSettings,
  accessName: accessList.email_list_get,
};

const tradeMarksRoutes = {
  id: 'Trade marks',
  path: routersLink.tradeMarksRoutes.controller,
  pathTradeMarks: pageLinks.tradeMarksRoutes.proposal.all,
  header: 'OIP management',
  icon: <SvgIcon><TrademarkIcon /></SvgIcon>,
  type: 'link',
  component: Proposal,
  accessName: accessList.trademarks_list_get,
  children: [
    {
      path: pageLinks.tradeMarksRoutes.proposal.all,
      name: 'List',
      type: 'link',
      component: Proposal,
      accessName: accessList.trademarks_list_get,
    },
    {
      path: pageLinks.tradeMarksRoutes.proposal.new,
      name: 'New application',
      type: 'link',
      component: TradeMarksProposalTab,
      hide: true,
      accessName: accessList.trademarks_post,
    },
    {
      path: `${routersLink.tradeMarksRoutes.controller}${routersLink.globalPath.edit}${routersLink.globalPath.id}`,
      name: 'Edit application',
      type: 'link',
      component: TradeMarksProposalTab,
      hide: true,
      accessName: accessList.trademarks_get,
    },
    {
      path: pageLinks.tradeMarksRoutes.madridCases.all,
      name: 'Madrid',
      type: 'link',
      component: MadridCases,
      accessName: accessList.madrid_case_list_get,
    },
    {
      path: pageLinks.tradeMarksRoutes.madridCases.new,
      name: 'New MadridCase',
      type: 'link',
      component: MadridCase,
      hide: true,
      accessName: accessList.madrid_case_post,
    },
    {
      path: `${routersLink.tradeMarksRoutes.controller}${routersLink.tradeMarksRoutes.madridCases.controller}${routersLink.globalPath.edit}${routersLink.tradeMarksRoutes.madridCases.madridCase}`,
      name: 'Edit MadridCase',
      type: 'link',
      component: MadridCase,
      hide: true,
      accessName: accessList.madrid_case_get,
    },
    // {
    //   path: pageLinks.tradeMarksRoutes.calculations,
    //   name: 'Calculations',
    //   type: 'link',
    //   component: Calculations,
    //   accessName: accessList.estimates_list_get,
    // },
    {
      path: pageLinks.tradeMarksRoutes.rebounds.all,
      name: 'Selection of ICGS',
      type: 'link',
      component: Rebounds,
      accessName: accessList.mktu_list_get,
    },
    {
      path: pageLinks.tradeMarksRoutes.rebounds.new,
      name: 'New rebound',
      type: 'link',
      component: Rebound,
      hide: true,
      accessName: accessList.mktu_post,
    },
    {
      path: `${routersLink.tradeMarksRoutes.controller}${routersLink.tradeMarksRoutes.rebounds.controller}${routersLink.globalPath.edit}${routersLink.tradeMarksRoutes.rebounds.rebound}`,
      name: 'Edit rebound',
      type: 'link',
      component: Rebound,
      hide: true,
      accessName: accessList.mktu_get,
    },
    {
      path: pageLinks.tradeMarksRoutes.mktuTemplates.all,
      name: 'Selection templates of ICGS',
      type: 'link',
      component: MktuTemplates,
      accessName: accessList.mktuTemplate_list_get,
    },
    {
      path: pageLinks.tradeMarksRoutes.mktuTemplates.new,
      name: 'New template of ICGS',
      type: 'link',
      component: MktuTemplate,
      hide: true,
      accessName: accessList.mktuTemplate_post,
    },
    {
      path: `${routersLink.tradeMarksRoutes.controller}${routersLink.tradeMarksRoutes.mktuTemplates.controller}${routersLink.globalPath.edit}${routersLink.tradeMarksRoutes.mktuTemplates.mktuTemplate}`,
      name: 'Edit template of ICGS',
      type: 'link',
      component: MktuTemplate,
      hide: true,
      accessName: accessList.mktuTemplate_get,
    },
    {
      path: pageLinks.tradeMarksRoutes.searches.all,
      name: 'Search',
      type: 'link',
      component: Searches,
      accessName: accessList.search_list_get,
    },
    {
      path: pageLinks.tradeMarksRoutes.searches.new,
      name: 'New search',
      type: 'link',
      component: SearchAddEdit,
      hide: true,
      accessName: accessList.search_post,
    },
    {
      path: pageLinks.tradeMarksRoutes.searches.quick,
      name: 'Quick search',
      type: 'link',
      component: SearchAddEdit,
      hide: true,
      accessName: accessList.quick_search_post,
    },
    {
      path: pageLinks.tradeMarksRoutes.searches.edit(),
      name: 'Edit search',
      type: 'link',
      component: SearchAddEdit,
      hide: true,
      accessName: accessList.search_get,
    },
    {
      path: pageLinks.tradeMarksRoutes.monitoring.all,
      name: 'Monitoring',
      type: 'link',
      component: Monitoring,
      accessName: accessList.monitoring_list_get,
    },
    {
      path: pageLinks.tradeMarksRoutes.monitoring.new,
      name: 'New monitoring',
      type: 'link',
      component: MonitoringDetail,
      hide: true,
      accessName: accessList.monitoring_post,
    },
    {
      path: `${routersLink.tradeMarksRoutes.controller}${routersLink.tradeMarksRoutes.monitoring.controller}${routersLink.globalPath.edit}${routersLink.tradeMarksRoutes.monitoring.monitoringDetail}`,
      name: 'Edit monitoring',
      type: 'link',
      component: MonitoringDetail,
      hide: true,
      accessName: accessList.monitoring_get,
    },
  ],
};

const industrialDesignsRoutes = {
  id: 'Industrial designs',
  path: routersLink.industrialDesigns.controller,
  icon: <SvgIcon><CertificateIcon /></SvgIcon>,
  type: 'link',
  component: IndustrialDesigns,
  accessName: accessList.industrial_list_get,
};
const industrialDesignsCreateRoutes = {
  path: pageLinks.industrialDesigns.new,
  name: 'New industrial designs',
  type: 'link',
  component: IndustrialDesignsTab,
  accessName: accessList.industrial_post,
};
const industrialDesignsEditRoutes = {
  path: `${routersLink.industrialDesigns.controller}${routersLink.globalPath.edit}${routersLink.globalPath.id}`,
  name: 'Edit industrial designs',
  type: 'link',
  component: IndustrialDesignsTab,
  accessName: accessList.industrial_get,
};

const utilityModelsRoutes = {
  id: 'Utility models',
  path: routersLink.utilityModels.controller,
  icon: <SvgIcon><AtomIcon /></SvgIcon>,
  type: 'link',
  component: UtilityModels,
  accessName: accessList.utility_list_get,
};
const utilityModelsCreateRoutes = {
  path: pageLinks.utilityModels.new,
  name: 'New utility models',
  type: 'link',
  component: UtilityModelsTab,
  accessName: accessList.utility_post,
};
const utilityModelsEditRoutes = {
  path: `${routersLink.utilityModels.controller}${routersLink.globalPath.edit}${routersLink.globalPath.id}`,
  name: 'Edit utility models',
  type: 'link',
  component: UtilityModelsTab,
  accessName: accessList.utility_get,
};

const inventionsRoutes = {
  id: 'Inventions',
  path: routersLink.inventions.controller,
  icon: <SvgIcon><AtomIcon /></SvgIcon>,
  type: 'link',
  component: Inventions,
  accessName: accessList.inventions_list_get,
};
const inventionsCreateRoutes = {
  path: pageLinks.inventions.new,
  name: 'New inventions',
  type: 'link',
  component: InventionsTab,
  accessName: accessList.inventions_post,
};
const inventionsEditRoutes = {
  path: `${routersLink.inventions.controller}${routersLink.globalPath.edit}${routersLink.globalPath.id}`,
  name: 'Edit inventions',
  type: 'link',
  component: InventionsTab,
  accessName: accessList.inventions_get,
};

const productsRoutes = {
  id: 'Products',
  path: routersLink.products.controller,
  icon: <SvgIcon><BoxIcon /></SvgIcon>,
  type: 'link',
  component: Products,
  accessName: accessList.products_list_get,
};
const productsCreateRoutes = {
  path: pageLinks.products.new,
  name: 'New product',
  type: 'link',
  component: Product,
  accessName: accessList.products_post,
};
const productsEditRoutes = {
  path: `${routersLink.products.controller}${routersLink.globalPath.edit}${routersLink.globalPath.id}`,
  name: 'Edit product',
  type: 'link',
  component: Product,
  accessName: accessList.products_get,
};

const departmentDocumentsRoutes = {
  header: 'Documents',
  path: pageLinks.documents.departmentDocuments.all,
  id: 'Documents',
  type: 'link',
  component: DepartmentDocuments,
  icon: <FileText />,
  accessName: accessList.documents_list_get,
};

const departmentDocumentCreateRoutes = {
  path: pageLinks.documents.departmentDocuments.new,
  name: 'Create document',
  type: 'link',
  component: DepartmentDocument,
  accessName: accessList.documents_post,
};

const departmentDocumentEditRoutes = {
  path: `${routersLink.documents.controller}${routersLink.globalPath.edit}${routersLink.documents.departmentDocument}`,
  name: 'Edit document',
  type: 'link',
  component: DepartmentDocument,
  accessName: accessList.documents_get,
};

const documentTemplates = {
  path: pageLinks.documents.documentTemplates.all,
  id: 'Document templates',
  type: 'link',
  component: DocumentTemplates,
  icon: <File />,
  accessName: accessList.documentsTemplate_list_get,
};

const documentTypesRoutes = {
  id: 'Document types',
  path: pageLinks.documentTypes.all,
  type: 'link',
  component: DocumentTypes,
  icon: <FileText />,
  accessName: accessList.documentClassifier_list_get,
  marginBottom: true,
};

const company = {
  id: 'Company',
  path: pageLinks.company.companies.all,
  icon: <SvgIcon><BuildingIcon /></SvgIcon>,
  accessName: accessList.company_list_get,
  children: [
    {
      name: 'Entities',
      path: pageLinks.company.companies.all,
      type: 'link',
      component: Companies,
      accessName: accessList.company_list_get,
    },
    {
      name: 'Create company',
      path: pageLinks.company.companies.new,
      type: 'link',
      component: Company,
      accessName: accessList.company_post,
      hide: true,
    },
    {
      name: 'Company',
      path: `${routersLink.company.companies}${routersLink.globalPath.edit}${routersLink.globalPath.id}`,
      type: 'link',
      component: Company,
      accessName: accessList.company_get,
      hide: true,
    },
    {
      name: 'Employees',
      path: `${routersLink.company.employees.controller}`,
      type: 'link',
      component: Employees,
      accessName: accessList.employees_list_get,
    },
    {
      name: 'Create employee',
      path: pageLinks.company.createEmployee,
      type: 'link',
      component: Employee,
      accessName: accessList.employees_invite_post,
      hide: true,
    },
    {
      name: 'Employee',
      path: `${routersLink.company.employees.controller}${routersLink.globalPath.edit}${routersLink.company.employees.employee}`,
      type: 'link',
      component: Employee,
      accessName: accessList.employees_post,
      hide: true,
    },
    {
      name: 'Clients',
      path: pageLinks.company.clients.all,
      type: 'link',
      component: Clients,
      accessName: accessList.clients_list_get,
    },
    {
      name: 'Create customer',
      path: pageLinks.company.clients.new,
      type: 'link',
      component: Client,
      accessName: accessList.clients_post,
      hide: true,
    },
    {
      name: 'Client',
      path: `${routersLink.company.clients.controller}${routersLink.globalPath.edit}${routersLink.company.clients.client}`,
      type: 'link',
      component: Client,
      accessName: accessList.clients_get,
      hide: true,
    },
    {
      name: 'Applicants',
      path: `${routersLink.company.applicants}`,
      type: 'link',
      component: Applicants,
      accessName: accessList.applicants_list_get,
    },
    {
      name: 'Orders',
      path: pageLinks.company.orders.all,
      type: 'link',
      component: Orders,
      accessName: accessList.orders_list_get,
    },
    {
      name: 'Create order',
      path: pageLinks.company.orders.new,
      type: 'link',
      component: Order,
      accessName: accessList.orders_create,
      hide: true,
    },
    {
      name: 'Order',
      path: `${routersLink.company.orders.controller}${routersLink.globalPath.edit}${routersLink.company.orders.order}`,
      type: 'link',
      component: Order,
      accessName: accessList.orders_get,
      hide: true,
    },
    {
      name: 'Payments',
      path: pageLinks.company.payments.all,
      type: 'link',
      component: Payments,
      accessName: accessList.payments_list_get,
    },
    {
      name: 'Payment',
      path: pageLinks.company.payments.new,
      type: 'link',
      component: Payment,
      accessName: accessList.payments_post,
      hide: true,
    },
    {
      name: 'Payment',
      path: pageLinks.company.payments.edit(),
      type: 'link',
      component: Payment,
      accessName: accessList.payments_get,
      hide: true,
    },
    {
      name: 'Integrations',
      path: `${routersLink.company.integrations}`,
      type: 'link',
      component: Integrations,
      accessName: accessList.authorityIntegration_list_get,
    },
  ],
  component: null,
};

const settings = {
  id: 'Settings',
  path: routersLink.settings,
  icon: <SettingsIcon />,
  accessName: accessList.costsCategories_list_get,
  children: [
    {
      path: pageLinks.prices.categories.all,
      name: 'Categories price',
      type: 'link',
      component: Categories,
      accessName: accessList.costsCategories_list_get,
    },
    {
      path: pageLinks.prices.tariffs.all,
      name: 'Tariffs',
      type: 'link',
      component: Tariffs,
      accessName: accessList.costs_list_get,
    },
    {
      name: 'Add tariff',
      path: pageLinks.prices.tariffs.new,
      type: 'link',
      component: Tariff,
      accessName: accessList.costs_post,
      hide: true,
    },
    {
      path: pageLinks.prices.works.all,
      name: 'Works',
      type: 'link',
      component: Works,
      accessName: accessList.works_list_get,
    },
    {
      path: `${routersLink.prices.controller}${routersLink.prices.tariffs}${routersLink.globalPath.edit}${routersLink.globalPath.id}`,
      name: 'Edit',
      type: 'link',
      component: Tariff,
      accessName: accessList.costs_get,
      hide: true,
    },
    {
      path: pageLinks.currencies.all,
      name: 'Currencies',
      type: 'link',
      component: Currencies,
      accessName: accessList.currencies_list_get,
    },
    {
      path: pageLinks.currencyRates.all,
      name: 'Courses currencies',
      type: 'link',
      component: CurrencyRates,
      accessName: accessList.currencyRates_list_get,
    },
    {
      name: 'Taxes',
      path: pageLinks.taxes.all,
      type: 'link',
      component: Taxes,
      accessName: accessList.taxes_list_get,
    },
    {
      path: `${routersLink.taxes.controller}${routersLink.globalPath.edit}${routersLink.globalPath.id}`,
      name: 'Edit tax',
      type: 'link',
      component: Tax,
      accessName: accessList.taxes_get,
      hide: true,
    },
    {
      name: 'Payment Receivers',
      path: routersLink.paymentReceivers.controller,
      type: 'link',
      component: PaymentReceivers,
      accessName: accessList.paymentReceivers_list_get,
    },
    {
      name: 'Notifications rules',
      path: pageLinks.notifications.all,
      type: 'link',
      component: Notifications,
      accessName: accessList.notifications_list_get,
    },
    {
      name: 'Jurisdictions',
      path: pageLinks.jurisdictions.all,
      type: 'link',
      component: Jurisdictions,
      accessName: accessList.jurisdictions_list_get,
    },
    {
      name: 'Settings',
      path: routersLink.settings,
      type: 'link',
      component: Settings,
      accessName: accessList.settings_list_get,
    },
  ],
  component: null,
};

const logging = {
  id: 'Action log',
  path: pageLinks.logging.all,
  icon: <AssignmentIcon />,
  accessName: accessList.logging_list_get,
  type: 'link',
  component: Logging,
};

const notificationsTasksRoutes = {
  id: 'NotificationsTasks',
  path: pageLinks.notifications.tasks,
  type: 'link',
  component: NotificationsTasks,
  accessName: accessList.pendingActions_list_get,
};

const systemMessageRoutes = {
  id: 'SystemMessage',
  path: pageLinks.systemMessage.all,
  type: 'link',
  component: SystemMessage,
  accessName: accessList.systemMessage_list_get,
};

const importObjectsRoutes = {
  id: 'Import',
  path: routersLink.importObjects,
  type: 'link',
  component: Import,
  icon: <ImportExportOutlinedIcon />,
  accessName: accessList.entity_import_post,
};

const exportRoutes = {
  id: 'Export',
  type: 'button',
  path: '/export',
  icon: <ImportExportOutlinedIcon />,
  accessName: accessList.entity_export_post,
};

// Routes using the Dashboard layout
export const allRoutes = [
  dashboardRoutes,
  ActionsRoutes,
  ActionsTableRoutes,
  receiving,
  sending,
  email,
  mailSettings,

  tradeMarksRoutes,
  industrialDesignsRoutes,
  industrialDesignsCreateRoutes,
  industrialDesignsEditRoutes,
  utilityModelsRoutes,
  utilityModelsCreateRoutes,
  utilityModelsEditRoutes,
  inventionsRoutes,
  inventionsCreateRoutes,
  inventionsEditRoutes,
  productsRoutes,
  productsCreateRoutes,
  productsEditRoutes,

  departmentDocumentsRoutes,
  departmentDocumentCreateRoutes,
  departmentDocumentEditRoutes,
  documentTemplates,
  documentTypesRoutes,

  company,
  settings,
  logging,

  importObjectsRoutes,
  exportRoutes,
  notificationsTasksRoutes,
  systemMessageRoutes,
  profileUserRoutes,
];
// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [
  dashboardRoutes,
  ActionsRoutes,
  receiving,
  sending,
  email,

  tradeMarksRoutes,
  industrialDesignsRoutes,
  utilityModelsRoutes,
  inventionsRoutes,
  productsRoutes,

  departmentDocumentsRoutes,
  documentTemplates,
  documentTypesRoutes,

  company,
  settings,
  logging,
  importObjectsRoutes,
  exportRoutes,
];
