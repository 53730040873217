// Helpers
import actionCreator from '../../_helpers/actionCreator';
// Types
import types from './types';

const actions = Object.freeze({
  setDocumentTypeById(data) {
    return actionCreator(types.SET_DOCUMENT_TYPE_BY_ID, data);
  },
  setDocumentTypesData(data) {
    return actionCreator(types.SET_DOCUMENT_TYPES_DATA, data);
  },
  setCurrentPage(currentPage) {
    return actionCreator(types.SET_DOCUMENT_TYPES_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return actionCreator(types.SET_DOCUMENT_TYPES_FILTERS, filters);
  },
  setSorting(sorting) {
    return actionCreator(types.SET_DOCUMENT_TYPES_SORTING, sorting);
  },
  setColumnWidths(data) {
    return actionCreator(types.SET_DOCUMENT_TYPES_COLUMN_WIDTHS, data);
  },
  setColumnOrder(data) {
    return actionCreator(types.SET_DOCUMENT_TYPES_COLUMN_ORDER, data);
  },
  setHiddenColumnNames(data) {
    return actionCreator(types.SET_DOCUMENT_TYPES_HIDDEN_COLUMN_NAMES, data);
  },
  setPageSize(pageSize) {
    return actionCreator(types.SET_DOCUMENT_TYPES_PAGE_SIZE, pageSize);
  },
  setInitialState(initialState) {
    return actionCreator(types.SET_DOCUMENT_TYPES_INITIAL_STATE, initialState);
  },
  setReload(reload) {
    return actionCreator(types.SET_DOCUMENT_TYPES_RELOAD, reload);
  },
  setDeleteDocumentType(data) {
    return actionCreator(types.SET_DELETE_DOCUMENT_TYPE_DOCUMENT, data);
  },
  setAutocompleteOptions(options) {
    return actionCreator(types.SET_DOCUMENT_TYPES_AUTOCOMPLETE_OPTIONS, options);
  },
});

export default actions;
