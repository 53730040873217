// Core
import { apply, put } from 'redux-saga/effects';

// config
import { api } from '../../../../config/api';

// actions
import actions from '../../action';

export function* callGetDashboardWorker({ payload: params }) {
  yield put(actions.setPending(true));
  const response = yield apply(api, api.dashboard.getDashboard, [params]);

  if (response && response.status >= 200 && response.status < 400) {
    yield put(actions.setActionInCalendar(response.data?.numberActionInCalendar || []));
    yield put(actions.setObjects(response.data?.numberObjects || {}));
    yield put(actions.setObjectsByClient(response.data?.numberObjectsByClient || []));
    yield put(actions.setObjectsByCountries(response.data?.numberObjectsByCountries || []));
    yield put(actions.setObjectsByStatus(response.data?.numberObjectsByStatus || []));
    yield put(actions.setObjectsByCountriesAndStatus(
      response.data?.numberObjectsByCountriesAndStatus || {},
    ));
  }
  yield put(actions.setReload(false));
  yield put(actions.setPending(false));
}
