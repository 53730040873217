// Core
import { apply, put } from 'redux-saga/effects';

// lodash
import { has, pickBy } from 'lodash';

// config
import { api } from '../../../../config/api';
import { listEntityName } from '../../../../config/listTablesName';

// actions
import asyncAction from '../asyncAction';
import actions from '../../action';

const entity = listEntityName.applicants;

export function* callGetApplicantsInitialListWorker({ payload: params }) {
  const {
    setApplicantsInitialList,
    setPendingAutocompleteOptions,
  } = actions;
  yield put(setPendingAutocompleteOptions(true));

  const filterParams = pickBy(params, (value, key) => key !== 'getList');

  const response = yield apply(api, api.applicants.getApplicants, [{ ...filterParams, entity }]);
  if (response && response.status >= 200 && response.status < 400) {
    yield put(setPendingAutocompleteOptions(false));
    const { data } = response;
    if (has(data, 'error')) {
      return;
    }
    const { items, totalCount } = data;
    if (params.getList && totalCount === 0) {
      yield put(asyncAction.getApplicantsInitialListAsync({
        sortBy: 'createdAt',
        sortDir: 'asc',
        limit: 50,
        page: 1,
      }));
      return;
    }
    yield put(setApplicantsInitialList({ items }));
  }

  yield put(setPendingAutocompleteOptions(false));
}
