// Core
import { put, select } from 'redux-saga/effects';
import { List } from 'immutable';

// helpers
import { splitArrayToChunks } from '../../../../../_helpers/splitArrays';

// config
import selectors from '../../../../../config/selectors';

// actions
import actions from '../../action';

export function* callSetNumberPerPageWorker({ payload }) {
  const {
    nameTab, count,
  } = payload;
  const {
    setTabs,
  } = actions;
  const searchTabs = yield select(selectors.tradeMarksMonitoring.monitoringTabs);

  yield put(setTabs(nameTab, {
    numberPerPage: count,
    pageSize: 0,
    items: List(splitArrayToChunks(searchTabs[nameTab].filtered.toJS(), count)),
  }));
}
