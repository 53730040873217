// core
import React, { memo, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import styled from 'styled-components';

// lodash
import {
  uniq, map, find, isEmpty,
} from 'lodash';

// ui
import {
  TextField as MuiTextField,
} from '@material-ui/core';

// config
import selectors from '../../../../../engine/config/selectors';

// parts
import Loader from '../../../../../components/Loader';
import Modal from '../../../../../components/Modal/Modal';

// actions
import mktuTemplatesAction from '../../../../../engine/core/tradeMarks/mktuTemplates/action';
import proposalActions from '../../../../../engine/core/tradeMarks/proposal/action';
import proposalAsyncActions from '../../../../../engine/core/tradeMarks/proposal/saga/asyncAction';

const TextField = styled(MuiTextField)`
  .MuiInputBase-root {
    min-height: 300px;
    align-items: start;
  }
`;

function YourListTermsModal(props) {
  const {
    classById, isModalOpen, mktuClass,
    pendingGetClassById, selectMktuClasses,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [readyGetTerms, setReadyGetTerms] = useState(false);
  const [selectedTerms, setSelectedTerms] = useState('');

  const handleCloseModal = () => {
    setSelectedTerms('');
    dispatch(proposalActions.setClassById({}));
    dispatch(mktuTemplatesAction.setModalYourListTermsOpen(false));
  };

  useEffect(() => {
    if (!isEmpty(classById.toJS()) && readyGetTerms) {
      setSelectedTerms(Object.values(classById.toJS()).join('; '));
    }
  }, [
    classById, readyGetTerms, setSelectedTerms,
  ]);

  useEffect(() => {
    if (!isModalOpen) setReadyGetTerms(false);
  }, [
    isModalOpen,
  ]);

  useEffect(() => {
    if (isModalOpen && !readyGetTerms && !isEmpty(mktuClass)
    && !isEmpty(selectMktuClasses.toJS())) {
      const findMktuClass = find(selectMktuClasses.toJS(), { id: mktuClass.id });
      if (!isEmpty(findMktuClass) && isEmpty(classById.toJS())) {
        if (findMktuClass.name === 'All') {
          dispatch(proposalAsyncActions.getClassByIdAsync({
            id: mktuClass.classNum,
            lang: 'ua',
          }));
        } else {
          setSelectedTerms(findMktuClass.name);
        }
        setReadyGetTerms(true);
      }
    }
  }, [
    dispatch, mktuClass, selectMktuClasses, classById, isModalOpen,
    readyGetTerms, setReadyGetTerms, setSelectedTerms,
  ]);

  const handleSubmits = () => {
    if (selectedTerms.length) {
      const findMktuClass = find(selectMktuClasses.toJS(), { id: mktuClass.id });
      const uniqTermsArray = uniq(selectedTerms.split(';'));
      const filterTermArray = uniqTermsArray.filter((term) => term.trim() !== '');
      const trimTermArray = map(filterTermArray, (term) => term.trim());
      const mktuClassWithTerms = {
        ...mktuClass,
        name: trimTermArray.join('; '),
      };
      if (isEmpty(findMktuClass)) {
        dispatch(proposalActions.setSelectMktuClasses([
          ...selectMktuClasses,
          mktuClassWithTerms,
        ]));
      } else {
        dispatch(proposalActions.setSelectMktuClasses([
          ...selectMktuClasses.toJS().filter(
            (selectMktuClass) => (selectMktuClass.id !== mktuClass.id),
          ),
          mktuClassWithTerms,
        ]));
      }
    }
    handleCloseModal();
  };

  const handleChange = (event) => {
    setSelectedTerms(event.target.value);
  };

  return (
    <Modal
      title={`${t('Class')} ${mktuClass.classNum}`}
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
      maxWidth="md"
      handleSend={handleSubmits}
      dialogProps={{
        scroll: 'paper',
      }}
    >
      {!pendingGetClassById
        ? (
          <TextField
            id="filled-multiline-flexible"
            label={t('Terms')}
            multiline
            value={selectedTerms}
            fullWidth
            variant="outlined"
            helperText={t('Insert your list separated by semicolons')}
            onChange={handleChange}
          />
        )
        : <Loader />}
    </Modal>
  );
}

YourListTermsModal.displayName = 'YourListTermsModal';

YourListTermsModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  pendingGetClassById: PropTypes.bool.isRequired,
  mktuClass: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  classById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  selectMktuClasses: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

YourListTermsModal.defaultProps = {
  mktuClass: {},
};

function mapStateToProps(state) {
  return {
    isModalOpen: selectors.mktuTemplates.isModalYourListTermsOpen(state),
    pendingGetClassById: selectors.tradeMarksProposal.pendingGetClassById(state),
    classById: selectors.tradeMarksProposal.classById(state),
    selectMktuClasses: selectors.tradeMarksProposal.selectMktuClasses(state),
  };
}

export default compose(connect(mapStateToProps, null))(memo(YourListTermsModal));
