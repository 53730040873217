// core
import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';

// ui
import {
  Button, Divider as MuiDivider,
  Typography, Box,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import {
  Add as AddIcon,
} from '@material-ui/icons';

// actions
import actions from '../../../engine/core/currencies/currenciesList/action';

// parts
import CurrenciesTable from './CurrenciesTable';
import CurrencyModal from './CurrencyModal';

// hook
import { useCurrencies } from './_hooks/useCurrencies';

// selectors
import selectors from '../../../engine/config/selectors';
import accessList from '../../../engine/config/accessList';
import { useAccessList } from '../../../ui/_hooks/useAccessList';

// styles
const Divider = styled(MuiDivider)(spacing);

function Currencies(props) {
  const { isModalOpen } = props;
  useCurrencies();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const accessPost = useAccessList(accessList.currencies_post);

  const handleOpenModal = () => {
    dispatch(actions.setIsModalOpen(true));
  };

  return (
    <>
      <Helmet title={t('Currencies')} />
      <Typography variant="h3" gutterBottom display="inline">
        {t('Currencies')}
      </Typography>
      <Divider my={6} />

      {accessPost && (
        <Box mb={2}>
          <Button variant="contained" color="primary" onClick={handleOpenModal}>
            {t('ADD')}
            <AddIcon />
          </Button>
        </Box>
      )}

      <CurrenciesTable />

      {isModalOpen && (
        <CurrencyModal />
      )}
    </>
  );
}

Currencies.displayName = 'Currencies';

Currencies.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    isModalOpen: selectors.currencies.isModalOpen(state),
  };
}

export default compose(
  connect(mapStateToProps, null),
)(Currencies);
