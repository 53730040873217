// Core
import { call, put } from 'redux-saga/effects';

// lodash
import map from 'lodash/map';

// config
import { api } from '../../../../../config/api';

// actions
import clientsActions from '../../action';

export function* callGetClientByIdWorker({ payload: id }) {
  const {
    setClientById,
  } = clientsActions;
  yield put(setClientById({
    pending: true,
  }));

  const response = yield call(api.company.getClientById, id);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    const { contactPersons } = data;
    yield put(setClientById({
      data: {
        ...data,
        ...contactPersons ? {
          contactPersons: map(contactPersons, (person) => ({
            ...person,
            id: Math.random(),
          })),
        } : {},
      },
      pending: false,
    }));
  }
}
