// Core
import { apply, put } from 'redux-saga/effects';

// lodash
import {
  has,
} from 'lodash';

// config
import { api } from '../../../../config/api';
import { messageRequestSuccess } from '../../../../config/globalConfig';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../ui/_helpers/setNotificationMessage';
import i18n from '../../../../init/i18n';

// actions
import actions from '../../action';

export function* callPutProposalCloneByIdWorker({ payload: params }) {
  const response = yield apply(api, api.utilityModels.putProposalCloneById, [params.id]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data: responseData } = response;
    if (has(responseData, 'error')) {
      yield put(setErrorMessage(responseData.error, response.status.toString()));
      return;
    }
    if (!params.isLast) {
      yield put(actions.setReload(true));
      yield put(setSuccessMessage(i18n.t(messageRequestSuccess), response.status.toString()));
    }
  }
}
