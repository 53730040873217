// Core
import { all, call, takeEvery } from 'redux-saga/effects';

// Parts
import asyncTypes from './asyncTypes';

// Workers
import {
  callGetPendingActionsTableWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callSetClearFiltersWorker,
} from './workers';
import types from '../types';

function* watchGetPendingActionsTable() {
  yield takeEvery(asyncTypes.GET_PENDING_ACTION_ASYNC, callGetPendingActionsTableWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_PENDING_ACTION_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_PENDING_ACTION_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_PENDING_ACTION_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_PENDING_ACTION_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_PENDING_ACTION_SORTING, callSetToLocalDataWorker);
}
function* watchSetClearFilters() {
  yield takeEvery(types.SET_PENDING_ACTION_CLEAR_FILTERS, callSetClearFiltersWorker);
}
function* watchSetColumnWidths() {
  yield takeEvery(types.SET_PENDING_ACTION_COLUMN_WIDTHS, callSetToLocalDataWorker);
}
function* watchSetColumnOrder() {
  yield takeEvery(types.SET_PENDING_ACTION_COLUMN_ORDER, callSetToLocalDataWorker);
}
function* watchSetHiddenColumnNames() {
  yield takeEvery(types.SET_PENDING_ACTION_HIDDEN_COLUMN_NAMES, callSetToLocalDataWorker);
}

export function* watchersPendingActionsTable() {
  yield all([
    call(watchGetPendingActionsTable),
    call(watchSetCurrentPage),
    call(watchSetPageSize),
    call(watchSetFilters),
    call(watchSetSorting),
    call(watchGetInitialState),
    call(watchSetClearFilters),
    call(watchSetColumnWidths),
    call(watchSetColumnOrder),
    call(watchSetHiddenColumnNames),
  ]);
}
