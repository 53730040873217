// Core
import { call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { change } from 'redux-form/lib/immutable';

// lodash
import has from 'lodash/has';
import pickBy from 'lodash/pickBy';

// config
import { api } from '../../../../../config/api';
import { messageRequestSuccess } from '../../../../../config/globalConfig';

// init
import i18n from '../../../../../init/i18n';

// actions
import clientsActions from '../../action';
import helpersActions from '../../../../helpers/action';
import applicantsAsyncActions from '../../../../applicants/saga/asyncAction';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../../../ui/_helpers/setNotificationMessage';
import { getPostalAddress } from '../../../../../../ui/_helpers/getPostalAddress';

// routes
import { pageLinks } from '../../../../../../routes';

export function* callPostClientWorker({ payload: client }) {
  const { setClientsData } = clientsActions;

  yield put(setClientsData({
    pendingPost: true,
  }));

  const filterParams = pickBy(client, (value, key) => key !== 'isModal' && key !== 'createApplicant');

  const response = yield call(api.company.postClient, filterParams);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    yield put(setClientsData({ pendingPost: false }));
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
      return;
    }
    if (client.isModal) {
      yield put(change('ProposalAdd', 'clients', null));
      if (client.createApplicant) {
        yield put(applicantsAsyncActions.postApplicantAsync({
          address: getPostalAddress(filterParams.addresses),
          fis: true,
          name: filterParams.name,
          innCode: filterParams.innCode,
          clients: data.id,
          isModal: true,
        }));
      } else {
        yield put(setSuccessMessage(i18n.t(messageRequestSuccess), response.status.toString()));
        yield put(helpersActions.setModal({ isModalOpenAddClient: false }));
      }
      yield put(change('ProposalAdd', 'clients', { ...filterParams, id: data.id }));
    } else {
      yield put(setSuccessMessage(i18n.t(messageRequestSuccess), response.status.toString()));
      yield put(push(pageLinks.company.clients.all));
    }
  }
}
