export default Object.freeze({
  SET_PRICES_CATEGORIES_DATA: 'SET_PRICES_CATEGORIES_DATA',
  SET_DELETE_PRICES_CATEGORY: 'SET_DELETE_PRICES_CATEGORY',
  SET_PRICES_CATEGORY_BY_ID: 'SET_PRICES_CATEGORY_BY_ID',
  SET_PRICES_CATEGORY_DATA_BY_ID: 'SET_PRICES_CATEGORY_DATA_BY_ID',
  SET_PRICES_CATEGORIES_RELOAD: 'SET_PRICES_CATEGORIES_RELOAD',
  SET_PRICES_CATEGORIES_CURRENT_PAGE: 'SET_PRICES_CATEGORIES_CURRENT_PAGE',
  SET_PRICES_CATEGORIES_FILTERS: 'SET_PRICES_CATEGORIES_FILTERS',
  SET_PRICES_CATEGORIES_SORTING: 'SET_PRICES_CATEGORIES_SORTING',
  SET_PRICES_CATEGORIES_COLUMN_WIDTHS: 'SET_PRICES_CATEGORIES_COLUMN_WIDTHS',
  SET_PRICES_CATEGORIES_COLUMN_ORDER: 'SET_PRICES_CATEGORIES_COLUMN_ORDER',
  SET_PRICES_CATEGORIES_HIDDEN_COLUMN_NAMES: 'SET_PRICES_CATEGORIES_HIDDEN_COLUMN_NAMES',
  SET_PRICES_CATEGORIES_PAGE_SIZE: 'SET_PRICES_CATEGORIES_PAGE_SIZE',
  SET_PRICES_CATEGORIES_INITIAL_STATE: 'SET_PRICES_CATEGORIES_INITIAL_STATE',
  SET_PRICES_CATEGORIES_IS_MODAL_OPEN: 'SET_PRICES_CATEGORIES_IS_MODAL_OPEN',
});
