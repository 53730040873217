// Core
import { apply, select, put } from 'redux-saga/effects';

// lodash
import isNil from 'lodash/isNil';

// Engine
import localData from '../../../../../config/localData';
import selectors from '../../../../../config/selectors';
import types from '../../types';
import actions from '../../action';
import helpersActionsAsync from '../../../../helpers/saga/asyncAction';

export function* callSetToLocalDataWorker({ type, payload }) {
  const pathname = yield select(selectors.router.pathname);
  const data = {
    currentPage: yield select(selectors.emailTemplate.currentPage),
    filters: (yield select(selectors.emailTemplate.filters)),
    pageSize: yield select(selectors.emailTemplate.pageSize),
    totalCount: yield select(selectors.emailTemplate.totalCount),
    sorting: (yield select(selectors.emailTemplate.sorting)),
    columnWidths: (yield select(selectors.emailTemplate.columnWidths)),
    columnOrder: (yield select(selectors.emailTemplate.columnOrder)),
    hiddenColumnNames: (yield select(selectors.emailTemplate.hiddenColumnNames)),
  };

  let currentData;
  yield put(actions.setReload(true));
  switch (type) {
    case types.SET_EMAIL_TEMPLATE_CURRENT_PAGE: {
      currentData = { currentPage: payload };
      break;
    }
    case types.SET_EMAIL_TEMPLATE_PAGE_SIZE: {
      currentData = { pageSize: payload };
      break;
    }
    case types.SET_EMAIL_TEMPLATE_FILTERS: {
      const { currentPage } = data;
      const filters = payload.filter(({ value }) => !isNil(value));
      if (currentPage) {
        yield put(actions.setCurrentPage(0));
      }
      currentData = { filters };
      break;
    }
    case types.SET_EMAIL_TEMPLATE_SORTING: {
      currentData = { sorting: payload };
      break;
    }
    case types.SET_EMAIL_TEMPLATE_COLUMN_WIDTHS: {
      currentData = { columnWidths: payload };
      break;
    }
    case types.SET_EMAIL_TEMPLATE_COLUMN_ORDER: {
      currentData = { columnOrder: payload };
      break;
    }
    case types.SET_EMAIL_TEMPLATE_HIDDEN_COLUMN_NAMES: {
      currentData = { hiddenColumnNames: payload };
      break;
    }
    default: {
      break;
    }
  }

  data.currentPage = yield select(selectors.emailTemplate.currentPage);
  yield apply(localData, localData.setItem, [pathname, { ...data, ...currentData }]);

  switch (type) {
    case types.SET_EMAIL_TEMPLATE_COLUMN_WIDTHS:
    case types.SET_EMAIL_TEMPLATE_COLUMN_ORDER:
    case types.SET_EMAIL_TEMPLATE_HIDDEN_COLUMN_NAMES: {
      yield put(helpersActionsAsync.setSaveFrontendSetting());
      break;
    }
    default: {
      break;
    }
  }
}
