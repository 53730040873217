// core
import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';

// ui
import { Divider as MuiDivider, Typography } from '@material-ui/core';
import { spacing } from '@material-ui/system';

// parts
import SendingTable from './components/SendingTable';

// hooks
import { useSending } from './_hooks/useSending';

// styles
const Divider = styled(MuiDivider)(spacing);

function Sending() {
  useSending();
  const { t } = useTranslation();

  return (
    <>
      <Helmet title={t('Sending')} />
      <Typography variant="h3" gutterBottom display="inline">
        {t('Sending')}
      </Typography>
      <Divider my={6} />

      <SendingTable />
    </>
  );
}

Sending.displayName = 'Sending';

export default Sending;
