const asyncTypes = Object.freeze({
  GET_PRICES_CATEGORIES_ASYNC: 'GET_PRICES_CATEGORIES_ASYNC',
  PUT_PRICES_CATEGORY_BY_ID_ASYNC: 'PUT_PRICES_CATEGORY_BY_ID_ASYNC',
  POST_PRICES_CATEGORY_ASYNC: 'POST_PRICES_CATEGORY_ASYNC',
  DELETE_PRICES_CATEGORY_ASYNC: 'DELETE_PRICES_CATEGORY_ASYNC',
  GET_PRICES_CATEGORIES_INITIAL_STATE_ASYNC: 'GET_PRICES_CATEGORIES_INITIAL_STATE_ASYNC',
  GET_PRICES_CATEGORY_BY_ID_ASYNC: 'GET_PRICES_CATEGORY_BY_ID_ASYNC',
});

export default asyncTypes;
