// Helpers
import actionCreator from '../../../../../_helpers/actionCreator';
// Types
import asyncTypes from './asyncTypes';

const clientsActionAsync = Object.freeze({
  getListAsync(params) {
    return actionCreator(asyncTypes.GET_CLIENTS_ASYNC, params);
  },
  getInitialStateAsync(path) {
    return actionCreator(asyncTypes.GET_CLIENTS_INITIAL_STATE_ASYNC, path);
  },
});

export default clientsActionAsync;
