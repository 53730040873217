// Core
import { store } from 'react-notifications-component';
import { apply } from 'redux-saga/effects';
// Components

export function* callSetNotificationMessage({ payload: message }) {
  const dismissable = Boolean(message.dismissable);
  const clickDismiss = message.clickDismiss === undefined ? true : Boolean(message.clickDismiss);
  const closeButton = message.closeButton === undefined ? true : Boolean(message.closeButton);
  const messageDuration = message.duration;
  const duration = dismissable ? messageDuration : 0;
  const options = {
    title: message.title,
    message: message.message,
    type: message.type,
    insert: 'top',
    container: 'bottom-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration,
      showIcon: closeButton,
      click: clickDismiss,
      pauseOnHover: true,
    },
    width: 257,
    slidingEnter: {
      duration: 100,
      timingFunction: 'ease-in',
      delay: 0,
    },
    slidingExit: {
      duration: 100,
      timingFunction: 'ease-out',
      delay: 0,
    },
  };

  yield apply(store, store.addNotification, [options]);
}
