// Core
import { call, put } from 'redux-saga/effects';

// lodash
import has from 'lodash/has';

// config
import { api } from '../../../../../config/api';

// actions
import action from '../../action';
import asyncAction from '../asyncAction';

// helpers
import { setErrorMessage } from '../../../../../../ui/_helpers/setNotificationMessage';

export function* callPutEmailSignatureByIdWorker({ payload }) {
  const { setIsModalOpenSignature, setPendingPutSignature } = action;
  const { getEmailSignaturesAsync } = asyncAction;

  yield put(setPendingPutSignature(true));

  const response = yield call(api.emailSignatures.putEmailSignatureById, payload);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    if (has(data, 'error')) {
      yield put(setErrorMessage(data.error, response.status.toString()));
    } else {
      yield put(setIsModalOpenSignature(false));
      yield put(getEmailSignaturesAsync());
    }
  }

  yield put(setPendingPutSignature(false));
}
