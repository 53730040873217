// Core
import { apply, put, select } from 'redux-saga/effects';
import { initialize } from 'redux-form/lib/immutable';

// lodash
import {
  isNil, isEmpty, has, values, find, map,
} from 'lodash';

// config
import { api } from '../../../../config/api';
import selectors from '../../../../config/selectors';

// actions
import actions from '../../action';
import uploadFileActions from '../../../uploadFile/action';
import actionsApplicants from '../../../applicants/action';
import helpersActions from '../../../helpers/action';
import employeesAsyncActions from '../../../company/employees/saga/asyncAction';

// helpers
import { setErrorMessage } from '../../../../../ui/_helpers/setNotificationMessage';
import { formFields } from '../../../../../pages/IndustrialDesigns/helper/form';
import { countryOptions } from '../../../../../ui/_helpers/country';
import { getObjectReverseFormatDate } from '../../../../_helpers/formatterDate';

export function* callGetProposalByIdWorker({ payload: params }) {
  const permissionSign = yield select(selectors.helpers.notPendingGetById);
  const documentType = yield select(selectors.helpers.documentsType);
  if (!permissionSign) yield put(actions.setPendingGetProposalById(true));

  const response = yield apply(api, api.industrialDesigns.getProposalById, [params]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data: responseData } = response;
    if (has(responseData, 'error')) {
      yield put(setErrorMessage(responseData.error, response.status.toString()));
    } else {
      const {
        data, title, signs = [], status, images, documents, company, isColor, publication,
      } = responseData;
      const {
        postalAddress, additionalApplicationData, applicants, authors, attorney,
        holders, relatedAppsRegs, priority, standardCharacters, idAttorney,
      } = data;
      const formData = {
        ...responseData,
        data: {
          ...data,
          standardCharacters: `${standardCharacters}`,
          relatedAppsRegs: {
            parentNumDateIsCheck: !isEmpty(relatedAppsRegs?.parentNumDate),
            parentNumDate: relatedAppsRegs?.parentNumDate,
            variantNumDateIsCheck: !isEmpty(relatedAppsRegs?.variantNumDate),
            variantNumDate: relatedAppsRegs?.variantNumDate,
          },
          postalAddress: {
            ...postalAddress,
            country: find(countryOptions, { abbreviation: postalAddress?.country }),
          },
          additionalApplicationData,
          priority: {
            ...priority,
            type: !isNil(priority?.type) ? priority?.type : 'noPriority',
          },
          attorney: {
            ...attorney,
            ...!isEmpty(attorney?.postalAddress) ? {
              postalAddress: {
                ...attorney.postalAddress,
                country: find(countryOptions, { abbreviation: attorney.postalAddress?.country }),
              },
            } : {},
          },
        },
        publication: {
          ...publication,
          ...getObjectReverseFormatDate(responseData.publication?.date, 'date'),
        },
        [formFields.title]: title,
        [formFields.status]: status,
        ...getObjectReverseFormatDate(responseData.applicationDate, formFields.applicationDate),
        ...getObjectReverseFormatDate(responseData.expiryDate, formFields.expiryDate),
        ...getObjectReverseFormatDate(responseData.registrationDate, formFields.registrationDate),
        ...getObjectReverseFormatDate(responseData.renewalDateFrom, formFields.renewalDateFrom),
        ...getObjectReverseFormatDate(responseData.renewalDateTo, formFields.renewalDateTo),
        ...getObjectReverseFormatDate(
          responseData.initialPublication,
          formFields.initialPublication,
        ),
        ...!isEmpty(company) ? {
          company: company.id,
        } : {},
        ...!isNil(isColor) ? {
          isColor: `${isColor}`,
        } : {},
        ...!isEmpty(values(documents)) && !isEmpty(documentType.toJS()) ? {
          documents: map(values(documents), (file) => ({
            type: find(documentType.toJS(), { id: file.type.id }) || file.type,
          })),
        } : {
          documents: [],
        },

      };
      delete formData.data.idAttorney;
      yield put(actions.setProposalDataById(formData));
      yield put(initialize('ProposalAdd', formData, formFields));
      yield put(uploadFileActions.setAllUploadFiles(values(documents)));
      yield put(helpersActions.setSignList(signs));
      yield put(actionsApplicants.setNewAuthorsListData(authors));
      yield put(actionsApplicants.setNewOwnersListData(holders));
      yield put(actionsApplicants.setNewApplicantsListData(applicants));
      yield put(actions.setNewImageVariant(images));
      if (idAttorney) {
        yield put(employeesAsyncActions.getResponsibleEmployeeByIdAsync(idAttorney));
      }
    }
  }
  yield put(helpersActions.setNotPendingGetById(false));
  yield put(actions.setPendingGetProposalById(false));
}
