export default Object.freeze({
  SET_PRICES_TARIFF_TABLE_DATA: 'SET_PRICES_TARIFF_TABLE_DATA',
  SET_PRICES_TARIFF_TABLE_CURRENT_PAGE: 'SET_PRICES_TARIFF_TABLE_CURRENT_PAGE',
  SET_PRICES_TARIFF_TABLE_PAGE_SIZE: 'SET_PRICES_TARIFF_TABLE_PAGE_SIZE',
  SET_PRICES_TARIFF_TABLE_INITIAL_STATE: 'SET_PRICES_TARIFF_TABLE_INITIAL_STATE',
  SET_PRICES_TARIFF_TABLE_RELOAD: 'SET_PRICES_TARIFF_TABLE_RELOAD',
  SET_PRICES_TARIFF_TABLE_MODAL_OPEN: 'SET_PRICES_TARIFF_TABLE_MODAL_OPEN',
  SET_PRICES_TARIFF_TABLE_BY_ID: 'SET_PRICES_TARIFF_TABLE_BY_ID',
  SET_DELETE_PRICES_TARIFF_TABLE: 'SET_DELETE_PRICES_TARIFF_TABLE',
  SET_TARIFF_TABLE_FILTER: 'SET_TARIFF_TABLE_FILTER',
  SET_TARIFF_TABLE_SORTING: 'SET_TARIFF_TABLE_SORTING',
  SET_GO_TO_PAGE_TARIFFS: 'SET_GO_TO_PAGE_TARIFFS',
  SET_PENDING: 'SET_PENDING',
  SET_PENDING_PUT: 'SET_PENDING_PUT',
  SET_PENDING_POST: 'SET_PENDING_POST',
  SET_PRICES_TARIFF_EDIT_ITEM: 'SET_PRICES_TARIFF_EDIT_ITEM',
  SET_PRICES_TARIFF_HASH_ID: 'SET_PRICES_TARIFF_HASH_ID',
  SET_CONFIRM_MODAL_OPEN: 'SET_CONFIRM_MODAL_OPEN',
});
