// Core
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

// lodash
import { isEmpty, map } from 'lodash';

// parts
import DxTable from '../../../../ui/Table/DxTable';

// actions
import actions from '../../../../engine/core/tradeMarks/madridCase/action';

// config
import selectors from '../../../../engine/config/selectors';
import ConfirmModal from '../../../../components/ConfirmModal';
import asyncAction from '../../../../engine/core/tradeMarks/madridCase/saga/asyncAction';
import helpersActions from '../../../../engine/core/helpers/action';

import { listTablesName, listEntityName } from '../../../../engine/config/listTablesName';
import accessList from '../../../../engine/config/accessList';

const tableName = listTablesName.madridCase;
const entityName = listEntityName.madridCase;

function MadridCasesTable(props) {
  const {
    madridCaseData, totalCount, pending,
    currentPage, filters, sorting,
    pageSize, pendingDeleteMadridCase, checkboxes,
    columnWidths, columnOrder, hiddenColumnNames,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const columns = [
    { name: 'id', title: t('ID') },
    { name: 'regNumber', title: t('Number') },
    { name: 'status', title: t('Status') },
    { name: 'publicationDate', title: t('Date of publication') },
    // { name: 'relations', title: t('Cases') },
  ];

  const tableColumnExtensions = [
    { columnName: 'id', width: 50 },
    { columnName: 'regNumber', width: '20rem' },
    { columnName: 'status', width: '15rem' },
    { columnName: 'publicationDate', width: '20rem' },
    // { columnName: 'relations', width: '15rem' },
  ];

  // Paging
  const onCurrentPage = (page) => dispatch(actions.setCurrentPage(page));
  const onPageSize = (size) => dispatch(actions.setPageSize(size));
  // Filtering
  const setFilters = (getFilters) => dispatch(actions.setFilters(getFilters));
  // Sorting
  const onSortingChange = (getSorting) => dispatch(actions.setSorting(getSorting));
  const onColumnWidthsChange = (getColumnWidths) => dispatch(
    actions.setColumnWidths(getColumnWidths),
  );
  const onColumnOrderChange = (getColumnOrder) => dispatch(
    actions.setColumnOrder(getColumnOrder),
  );
  const onHiddenColumnNamesChange = (getHiddenColumnNames) => dispatch(
    actions.setHiddenColumnNames(getHiddenColumnNames),
  );

  return (
    <>
      <DxTable
        name="MadridCasesTable"
        isLoading={pending}
        columns={columns}
        rows={madridCaseData.toJS().items}
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageSize={onPageSize}
        onCurrentPage={onCurrentPage}
        filtersAction={setFilters}
        filters={filters}
        // checkboxes ------------------------
        tableName={tableName}
        entityName={entityName}
        reloadTable={actions.setReload}
        selection={checkboxes.toJS()}
        onSelectionChange={(checkboxSelect) => {
          dispatch(helpersActions.setCheckbox({
            tableName,
            checkboxes: checkboxSelect,
          }));
        }}
        // ------------------------
        sorting={sorting}
        onSortingChange={onSortingChange}
        tableColumnExtensions={!isEmpty(columnWidths) && columnWidths.length === columns.length
          ? columnWidths : tableColumnExtensions}
        onColumnWidthsChange={onColumnWidthsChange}
        columnOrder={!isEmpty(columnOrder) ? columnOrder : map(columns, 'name')}
        onColumnOrderChange={onColumnOrderChange}
        hiddenColumnNames={hiddenColumnNames}
        onHiddenColumnNamesChange={onHiddenColumnNamesChange}
        accessDelete={accessList.madrid_case_delete}
      />

      <ConfirmModal
        buttonSendText="DELETE"
        pending={pendingDeleteMadridCase}
        handleSend={() => {
          dispatch(asyncAction.deleteMadridCaseAsync());
        }}
      />
    </>
  );
}

MadridCasesTable.displayName = 'MadridCasesTable';

MadridCasesTable.propTypes = {
  madridCaseData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  totalCount: PropTypes.number.isRequired,
  pending: PropTypes.bool.isRequired,
  pendingDeleteMadridCase: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  filters: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  sorting: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  pageSize: PropTypes.number.isRequired,
  checkboxes: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  columnWidths: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  columnOrder: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  hiddenColumnNames: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
};

MadridCasesTable.defaultProps = {};

function mapStateToProps(state) {
  return {
    madridCaseData: selectors.madridCase.madridCaseData(state),
    pending: selectors.madridCase.pending(state),
    pendingDeleteMadridCase: selectors.madridCase.pendingDeleteMadridCase(state),

    // Paging
    totalCount: selectors.madridCase.totalCount(state),
    currentPage: selectors.madridCase.currentPage(state),
    pageSize: selectors.madridCase.pageSize(state),

    // Filtering
    filters: selectors.madridCase.filters(state),

    // Sorting
    sorting: selectors.madridCase.sorting(state),

    // ColumnWidths
    columnWidths: selectors.madridCase.columnWidths(state),
    columnOrder: selectors.madridCase.columnOrder(state),
    hiddenColumnNames: selectors.madridCase.hiddenColumnNames(state),

    checkboxes: selectors.helpers.getCheckboxes(state, tableName),
  };
}

function mapDispatchToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(MadridCasesTable);
