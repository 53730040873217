// Core
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import Helmet from 'react-helmet';
import {
  Field, Form, reduxForm, change,
} from 'redux-form/immutable';
import * as PropTypes from 'prop-types';
import { Link as MuiLink } from 'react-router-dom';

// ui
import {
  Typography, Divider as MuiDivider, Tooltip,
  CardContent, Button, Box, IconButton,
  Grid, Card as MuiCard, CircularProgress,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';
import {
  Close as CloseIcon,
  NavigateBefore as NavigateBeforeIcon,
  Edit as EditIcon,
} from '@material-ui/icons';

// lodash
import {
  isEmpty, map, isNull, filter, find,
} from 'lodash';

// parts
import RenderTextField from '../../../ui/Form/RenderTextField';
import Loader from '../../../components/Loader';
import Select from '../../../ui/Form/Select';

// actions
import asyncAction from '../../../engine/core/taxes/saga/asyncAction';
import actions from '../../../engine/core/taxes/action';
import categoryAsyncActions from '../../../engine/core/prices/category/saga/asyncAction';
import categoryActions from '../../../engine/core/prices/category/action';
import jurisdictionsActions from '../../../engine/core/jurisdictions/action';
import jurisdictionsActionsAsync from '../../../engine/core/jurisdictions/saga/asyncAction';

// config
import selectors from '../../../engine/config/selectors';

// helpers
import { pageLinks } from '../../../routes';
import accessList from '../../../engine/config/accessList';
import { useAccessList } from '../../../ui/_hooks/useAccessList';

// styles
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Link = styled(MuiLink)`
  text-decoration: underline;
  color: #1976d2;
  display: flex;
  align-items: center;
`;

const formName = 'TaxForm';

function Tax(props) {
  const {
    handleSubmit, match, getTaxById, formValues, categoriesData,
    pendingTaxById, initialize, pendingPutTaxById, destroy, jurisdictionsData,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { params } = match;
  const [readyTaxById, setReadyTaxById] = useState(false);
  const [openFields, setOpenFields] = useState(false);
  const [readyInitialize, setReadyInitialize] = useState(false);
  const [rateIndex, setRateIndex] = useState(null);
  const [rates, setRates] = useState([]);
  const accessPut = useAccessList(accessList.taxes_put);
  const accessEdit = !isEmpty(params) ? accessPut : true;
  const accessCategoriesList = useAccessList(accessList.costsCategories_list_get);
  const accessJurisdictionsList = useAccessList(accessList.jurisdictions_list_get);

  const handleSubmits = (formData) => {
    const json = formData.toJS();
    dispatch(asyncAction.putTaxByIdAsync({
      id: params.hashId,
      ...json,
      rates,
    }));
  };

  useEffect(() => {
    if (accessCategoriesList) dispatch(categoryAsyncActions.getListAsync());
    if (accessJurisdictionsList) dispatch(jurisdictionsActionsAsync.getListAsync());
  }, [dispatch, accessJurisdictionsList, accessCategoriesList]);

  useEffect(() => function cleanup() {
    const emptyData = { items: [], totalCount: 0, pending: false };
    destroy();
    dispatch(categoryActions.setCategoriesData(emptyData));
    dispatch(jurisdictionsActions.setJurisdictionsData(emptyData));
    dispatch(actions.setTaxDataById({}));
  }, [destroy, dispatch]);

  useEffect(() => {
    if (!isEmpty(params) && !readyTaxById) {
      setReadyTaxById(true);
      dispatch(asyncAction.getTaxByIdAsync(params.hashId));
    }
  }, [
    dispatch, params, readyTaxById, setReadyTaxById,
  ]);

  useEffect(() => {
    if (!readyInitialize && !isEmpty(getTaxById.toJS())) {
      setReadyInitialize(true);
      initialize({
        name: getTaxById.toJS().name || '',
        ...!isEmpty(getTaxById.toJS().costsCategories) ? {
          costsCategories: map(getTaxById.toJS().costsCategories, 'id'),
        } : {},
      });
      setRates(getTaxById.toJS().rates || []);
    }
  }, [
    initialize, readyInitialize, setRates,
    setReadyInitialize, getTaxById,
  ]);

  const handleAddRate = () => {
    if (formValues.toJS().country && formValues.toJS().rate) {
      setRates(!isNull(rateIndex)
        ? map(rates, (item, index) => ({
          ...item,
          ...index === rateIndex ? {
            country: formValues.toJS().country,
            rate: formValues.toJS().rate,
          } : {},
        }))
        : [
          ...rates,
          {
            country: formValues.toJS().country,
            rate: formValues.toJS().rate,
            // tax: 1,
          },
        ]);
    }
    dispatch(change(formName, 'country', ''));
    dispatch(change(formName, 'rate', ''));
    setOpenFields(false);
    setRateIndex(null);
  };

  const handleEditRate = (index, rate) => {
    setRateIndex(index);
    dispatch(change(formName, 'country', rate.country));
    dispatch(change(formName, 'rate', rate.rate));
    setOpenFields(true);
  };

  const handleDeleteRate = (index) => {
    setRates(filter(rates, (item, i) => i !== index));
  };

  return !pendingTaxById ? (
    <>
      <Helmet title={t('Tax')} />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h3" gutterBottom display="inline">
          {t('Tax')}
        </Typography>
        <Link to={pageLinks.taxes.all}>
          <NavigateBeforeIcon size="small" color="primary" />
          {t('Up to the list')}
        </Link>
      </Box>
      <Divider my={6} />

      <Form onSubmit={handleSubmit(handleSubmits)}>
        <Card mb={6}>
          <CardContent>
            <Typography variant="h4" gutterBottom>
              {t('General information')}
            </Typography>
            <Grid container spacing={4} alignItems="flex-end">
              <Grid item xs={12} md={4}>
                <Field
                  name="name"
                  id="name"
                  label={t('Name')}
                  margin="normal"
                  variant="standard"
                  my={2}
                  fullWidth
                  type="text"
                  component={RenderTextField}
                  required
                  disabled={!accessEdit}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  name="costsCategories"
                  id="costsCategories"
                  label={t('Categories')}
                  labelId="costsCategories"
                  items={categoriesData.toJS().items}
                  fullWidth
                  multiple
                  component={Select}
                  disabled={!accessEdit}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card mb={6}>
          <CardContent>
            <Typography variant="h4" gutterBottom>
              {t('Rates')}
            </Typography>
            {!isEmpty(rates) ? (
              <Grid container spacing={4}>
                {map(rates, (rate, index) => {
                  const findJurisdiction = find(jurisdictionsData.toJS().items,
                    { id: Number(rate.country) });
                  return (
                    <Grid key={index} item xs={12} md={6}>
                      <Box display="flex" flexDirection="row" alignItems="center">
                        <Box mr={1} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {`${findJurisdiction?.name || rate.country}: ${rate.rate}`}
                        </Box>
                        {accessEdit && (
                          <IconButton
                            ml={2}
                            aria-label="Edit"
                            size="small"
                            onClick={() => handleEditRate(index, rate)}
                          >
                            <Tooltip title={t('Edit')} placement="top-start">
                              <EditIcon size="small" />
                            </Tooltip>
                          </IconButton>
                        )}
                        {accessEdit && (
                          <IconButton
                            aria-label="Delete"
                            size="small"
                            onClick={() => handleDeleteRate(index)}
                          >
                            <Tooltip title={t('Delete')} placement="top-start">
                              <CloseIcon fontSize="small" />
                            </Tooltip>
                          </IconButton>
                        )}
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Typography variant="subtitle1">
                {t('No rates yet')}
              </Typography>
            )}

            {!openFields && accessEdit && (
              <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={3}>
                  <Button
                    mt={2}
                    variant="outlined"
                    color="primary"
                    onClick={() => setOpenFields(true)}
                  >
                    {t('ADD')}
                  </Button>
                </Grid>
              </Grid>
            )}

            {openFields && (
              <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={3}>
                  <Field
                    name="country"
                    id="country"
                    label={t('Country')}
                    labelId="country"
                    items={jurisdictionsData.toJS().items}
                    margin="none"
                    fullWidth
                    component={Select}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Field
                    name="rate"
                    id="rate"
                    label={t('Rate')}
                    margin="none"
                    variant="standard"
                    my={1}
                    fullWidth
                    type="text"
                    component={RenderTextField}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <Button
                    mt={2}
                    variant="outlined"
                    color="primary"
                    onClick={handleAddRate}
                  >
                    {t('SAVE')}
                  </Button>
                </Grid>
              </Grid>
            )}
          </CardContent>
        </Card>

        {accessEdit && (
          <Box mt={3}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={pendingPutTaxById || openFields}
            >
              {pendingPutTaxById ? <CircularProgress size={25} color="inherit" /> : t('SAVE')}
            </Button>
          </Box>
        )}
      </Form>
    </>
  ) : <Loader />;
}

Tax.propTypes = {
  getTaxById: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  pendingTaxById: PropTypes.bool.isRequired,
  pendingPutTaxById: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  initialize: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  match: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  formValues: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  destroy: PropTypes.oneOfType([
    PropTypes.func,
  ]).isRequired,
  categoriesData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  jurisdictionsData: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

Tax.defaultProps = {
  formValues: {},
  match: {},
};

function mapStateToProps(state) {
  return {
    getTaxById: selectors.taxes.getTaxById(state),
    pendingTaxById: selectors.taxes.pendingTaxById(state),
    pendingPutTaxById: selectors.taxes.pendingPutTaxById(state),
    formValues: selectors.form.getFormValues(state, formName),
    categoriesData: selectors.pricesCategory.categoriesData(state),
    jurisdictionsData: selectors.jurisdiction.jurisdictionsData(state),
  };
}

export default compose(
  reduxForm({
    form: formName,
  }),
  connect(mapStateToProps, null),
)(Tax);
