// Core
import { apply, put } from 'redux-saga/effects';

// config
import { api } from '../../../../config/api';
import { listEntityName } from '../../../../config/listTablesName';

// actions
import actions from '../../action';

const entity = listEntityName.tags;

export function* callGetActionTypesWorker({ payload: params }) {
  yield put(actions.setPendingActionTypesData(true));
  const response = yield apply(api, api.actionTypes.getActionTypes, [{ ...params, entity }]);

  if (response && response.status >= 200 && response.status < 400) {
    yield put(actions.setActionTypesData(response.data));
  }
  yield put(actions.setPendingActionTypesData(false));
}
